import { Chip, useTheme } from '@persuit/ui-components'
import { UseCase } from '@persuit/ui-graphql/generated'
import { USE_CASE_LABEL_MAP } from '../../../../../src/common/data/use-cases'

export type UseCaseLabelProps = {
	useCase?: UseCase | null
	dummy?: boolean
}

const getLabelText = (dummy: boolean, useCase?: UseCase | null): string | undefined => {
	if (dummy) return 'Use case'
	if (!useCase) return undefined
	return USE_CASE_LABEL_MAP[useCase]
}

export const UseCaseLabel = ({ useCase, dummy = false }: UseCaseLabelProps) => {
	const theme = useTheme()
	const label = getLabelText(dummy, useCase)
	if (!label) return null

	return (
		<Chip
			label={label}
			sx={{
				color:
					useCase === 'rateReview' ? theme.palette.premium.darkHue : theme.palette.primary.main,
				background:
					useCase === 'rateReview'
						? theme.palette.premium.lightestHue
						: theme.palette.primary.lighterHue,
			}}
		/>
	)
}
