import { PROPOSAL_STATES, RfpProposalState } from '../data/rfp-proposal-states'

type Proposal = { status: RfpProposalState }

const isDraft = (proposal: Proposal) => {
	if (!proposal) return false

	return isDraftStatus(proposal.status)
}

const isNotDraft = (proposal: Proposal) => {
	if (!proposal) return false

	return !isDraft(proposal)
}

const isNotDraftStatus = (proposalStatus: RfpProposalState) => {
	return !isDraftStatus(proposalStatus)
}

const isDraftStatus = (proposalStatus: RfpProposalState) => {
	if (
		proposalStatus === PROPOSAL_STATES.DRAFT ||
		proposalStatus === PROPOSAL_STATES.DRAFT_REVISION
	) {
		return true
	} else {
		return false
	}
}

export { isDraft, isNotDraft, isNotDraftStatus }
