// @ts-strict-ignore
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { grey } from '@mui/material/colors'
import isNumber from 'lodash/fp/isNumber'
import constants from '../../../../common/data/pricing-models'
import formatPriceCurrency from '../../../utils/price-and-currency'
import { PricingCardItemNumbering } from '@persuit/ui-shared-components'
import { styled, Typography, Spacer } from '@persuit/ui-components'

const StyledItem = styled('div')`
	border-bottom: 1px solid ${grey[500]};
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
`
const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 50%;
`
const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	gap: 8px;
	width: 50% !important;
`
const StyledMiniCellFee = styled('div')`
	white-space: nowrap;
	width: 194px;
	text-decoration-thickness: 2px;
`
const StyledMiniCellModel = styled('div')`
	width: 150px;
	text-decoration-thickness: 2px;
`
const StyledMiniCellHrs = styled('div')`
	width: 100px;
	text-decoration-thickness: 2px;
`

const firmItem = {
	none: 'No Preference',
	notApplicable: 'Not Applicable',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	collaredFee: 'Collared Fee',
	contingencyFee: 'Contingency Fee',
	contingencyPercentage: 'Contingency Fee (%)',
	discountPercentage: 'Discount (%)',
	successFee: 'Success Fee',
	hourlyRates: 'Rate Card',
	hourlyRate: 'Hourly Rate',
	other: 'Other (see description)',
	estimate: 'Budgeting Estimate',
	included: 'No charge',
	afa: 'Fee Arrangements',
	rates: 'Rates',
	includedInItemTotal: 'No charge',
}
const firmSubItem = {
	none: 'No Preference',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	contingencyFee: 'Contingency Fee',
	successFee: 'Success Fee',
	other: 'Other (see description)',
	estimate: 'Budgeting Estimate',
	includedInItemTotal: 'No charge',
	afa: 'Fee Arrangements',
}

function displayPricingModel(rate) {
	if (rate.pricingModel) {
		if (rate.firmSupplied) {
			return firmItem[rate.pricingModel]
		} else if (!rate.firmSupplied) {
			return firmSubItem[rate.pricingModel]
		}

		return '-'
	}
}

export type SubItemsListItemsProps = {
	combinedRates: {
		rates: {
			label: string
			percentage: number | null
			pricingModel: string
			hours: number | null
			rate: number | null
		}[]
	}
	phaseIndex: number
	phaseDuration: string
	currency: string
}
const SubItemsListItems = ({
	combinedRates,
	phaseIndex,
	phaseDuration,
	currency,
}: SubItemsListItemsProps) => {
	return (
		<>
			{combinedRates &&
				combinedRates.rates.map((rate, i) => {
					const isItemPercentage = rate.pricingModel === constants.DISCOUNTPERCENTAGE
					const formatItemDuration = rate.hours
						? `${rate.hours} ${phaseDuration === 'months' ? 'mo' : 'hrs'}`
						: '-'
					const formatItemRate = rate.rate ? formatPriceCurrency(currency, rate.rate) : '-'
					const formatItemPercentage = isNumber(rate.percentage) ? `${rate.percentage}%` : '-'
					return (
						<div role="listitem" key={`SubItemsList${i}`}>
							<StyledItem>
								<StyledItemLeft>
									<Typography aria-label={rate.label}>
										<PricingCardItemNumbering
											parentIndex={phaseIndex}
											childIndex={i}
											typographyProps={{
												sx: {
													padding: '10px 10px 5px 0px',
												},
												variant: 'body1',
											}}
										/>
										{rate.label ? rate.label : '-'}
									</Typography>
								</StyledItemLeft>

								<StyledItemRight key={i}>
									<StyledMiniCellFee>
										<Typography align="right" data-testid="sub-item-rate">
											{isItemPercentage ? formatItemPercentage : formatItemRate}
										</Typography>
									</StyledMiniCellFee>
									<StyledMiniCellModel>
										<Typography data-testid="sub-item-model">
											{displayPricingModel(rate)}
										</Typography>
									</StyledMiniCellModel>
									<StyledMiniCellHrs>
										<Typography align="right" data-testid="sub-item-hours">
											{!isItemPercentage && formatItemDuration}
										</Typography>
									</StyledMiniCellHrs>
								</StyledItemRight>
							</StyledItem>
							<Spacer space={1} />
						</div>
					)
				})}
		</>
	)
}

export default SubItemsListItems
