import { PureComponent } from 'react'
import { Box } from '@persuit/ui-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FieldArray, formValueSelector } from 'redux-form'

import { calculateTotalCategoryWeight } from '../../../../../../../utils/scorecard'

import { ScorecardCategoryField } from './score-fields'

class ScorecardCategoryFields extends PureComponent {
	render() {
		const { fields, formValues, scoreCategories } = this.props

		const totalWeight = calculateTotalCategoryWeight(scoreCategories)

		return (
			<Box>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
					role="list"
					aria-label="Categories"
				>
					{fields.map((member, index) => {
						return (
							<FieldArray
								key={index}
								name={`${member}.subcategories`}
								component={ScorecardCategoryField}
								props={{
									scores: formValues[index],
									scoreCategory: scoreCategories[index],
									totalWeight,
								}}
							/>
						)
					})}
				</Box>
			</Box>
		)
	}
}

const selector = formValueSelector('proposalScoresForm')
const mapStateToProps = (state) => ({
	formValues: selector(state, 'scores'),
})

export default compose(connect(mapStateToProps))(ScorecardCategoryFields)
