import { Fragment } from 'react'

import {
	Box,
	Paper,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@persuit/ui-components'

import formatCurrency from './auction-price-format'

import NamwolfLogo from '../../../namwolf/namwolf-logo'

import {
	generateAuctionSummaryData,
	generateComparisonValueAuctionSummaryData,
} from './calculations'
import { OnlineStatusIndicator } from '../../../online-status-indicator'

const AuctionSummaryTable = ({
	orgIdToColourMapping,
	currency,
	responses,
	request,
	auctionInProgress,
}) => {
	const totalPriceRequired = request.detail.totalPriceRequired
	const auctionSummaryData = totalPriceRequired
		? generateAuctionSummaryData({ request, responses })
		: generateComparisonValueAuctionSummaryData({ request, responses })

	return (
		<Fragment>
			<Paper elevation={1}>
				<Box pb={1}>
					<Box m={2} pt={1}>
						<Typography gutterBottom={true} variant="h3XSmall">
							Summary
						</Typography>
					</Box>

					<TableContainer>
						<Table>
							<caption>This table has a summary of the current auction bids.</caption>
							<TableHead>
								<TableRow>
									<TableCell>Firm</TableCell>
									<TableCell align="right">Start price</TableCell>
									<TableCell align="right">End price</TableCell>
									<TableCell align="right">Reduction</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{auctionSummaryData.map((priceDrop, index) => {
									return (
										<TableRow key={index}>
											<TableCell component="th" scope="row">
												<Box display="flex" alignItems="center" gap={1}>
													<Box
														width="20px"
														height="20px"
														bgcolor={orgIdToColourMapping[priceDrop.orgId]}
														aria-hidden={true}
													/>
													{auctionInProgress && (
														<Box display="inline-block" sx={{ transform: 'translateY(2px)' }}>
															<OnlineStatusIndicator rfpId={request._id} orgId={priceDrop.orgId} />
														</Box>
													)}
													<Typography variant="body1Semibold">{priceDrop.orgName}</Typography>

													{priceDrop.isNamwolfMember && <NamwolfLogo />}
												</Box>
											</TableCell>
											<TableCell align="right">
												{priceDrop.starting && formatCurrency(priceDrop.starting, currency)}
											</TableCell>
											<TableCell align="right">
												{priceDrop.ending && formatCurrency(priceDrop.ending, currency)}
											</TableCell>
											<TableCell align="right">
												<Typography variant="body1">
													{Math.round(priceDrop.dropPercentage)}%
												</Typography>
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Paper>
		</Fragment>
	)
}

export default AuctionSummaryTable
