// @ts-strict-ignore
import DialogWizardListOption from '../../../dialog-wizard-list-option'
import { useTheme, RevisePriceIcon } from '@persuit/ui-components'
import { useProposalQuickRevisionStore } from '@persuit/ui-shared-components'
import { WIZARD_STEPS } from '../steps'
import { isEmpty } from 'lodash'

type OptionQuickPriceRevisionStartProps = {
	setStep: (step: string) => void
	hasRevisionRequest?: boolean
	pricingModelNotSelected?: boolean
	pricingModelNotApplicable?: boolean
}

export const OptionQuickPriceRevisionStart = ({
	hasRevisionRequest,
	pricingModelNotSelected,
	pricingModelNotApplicable,
	setStep,
}: OptionQuickPriceRevisionStartProps) => {
	const theme = useTheme()

	const { pricingItemLookup, noValidItems, totalPriceNotRequired } = useProposalQuickRevisionStore(
		({ pricingItemLookup, totalPriceNotRequired }, selectors) => ({
			pricingItemLookup,
			noValidItems: selectors.noValidItems(),
			totalPriceNotRequired,
		}),
	)

	const totalPriceRequired = !totalPriceNotRequired
	const totalPriceNotFilled =
		totalPriceRequired && (pricingModelNotSelected || pricingModelNotApplicable)
	const noTotalPrice = totalPriceNotRequired || totalPriceNotFilled

	const proposalPending = totalPriceRequired && pricingModelNotSelected

	const isDisabled = hasRevisionRequest || (noTotalPrice && noValidItems) || proposalPending

	return (
		<DialogWizardListOption
			dataTestid="revise-price-option"
			icon={<RevisePriceIcon width={24} height={24} fill={theme.palette.grey600} />}
			primaryText={'Quick Price Revision'}
			bullets={getDisplayBullets({
				hasRevisionRequest,
				pricingModelNotSelected,
				pricingModelNotApplicable,
				totalPriceNotRequired,
				noValidItems,
			})}
			dataTrackid={'option-revise-price'}
			onClick={() =>
				(!totalPriceNotRequired && noValidItems) || !isEmpty(pricingItemLookup)
					? setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES)
					: setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_RATES)
			}
			disabled={isDisabled}
		/>
	)
}

const getDisplayBullets = ({
	hasRevisionRequest,
	pricingModelNotSelected,
	pricingModelNotApplicable,
	totalPriceNotRequired,
	noValidItems,
}) => {
	if (totalPriceNotRequired && noValidItems) {
		return [
			'Cannot quick revise pricing because this request doesn’t require a total price or have any pricing items',
		]
	}

	if (!totalPriceNotRequired && pricingModelNotSelected && noValidItems) {
		return [
			'Cannot revise proposal price becaue there is no total pricing model selected and no pricing items',
		]
	}

	if (!totalPriceNotRequired && pricingModelNotApplicable && noValidItems) {
		return [
			'Cannot revise proposal price because pricing model is set to ‘Not Applicable’ and there are no pricing items.',
		]
	}

	if (hasRevisionRequest) {
		return [
			'Cannot revise proposal price because there is an outstanding proposal revision request from the client.',
		]
	}

	if (!totalPriceNotRequired && noValidItems) {
		return ['Update the total price']
	}

	// Default message
	return ['Update the total price, item prices, and rates.']
}
