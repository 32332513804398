import { gql } from '@persuit/ui-graphql'

const saveTracking = gql`
	mutation ($payload: SaveTrackingInput!) {
		saveTracking(payload: $payload) {
			success
		}
	}
`

export default saveTracking
