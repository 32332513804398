import { graphql, useQuery } from '@persuit/ui-graphql'
import { COUNTRIES } from '../../../../../src/common/data/template-tags'
import compact from 'lodash/fp/compact'
import find from 'lodash/fp/find'
import partition from 'lodash/fp/partition'
import { GetTemplateCountries_GetSaliLocationDataQuery } from '@persuit/ui-graphql/generated'
import keyBy from 'lodash/fp/keyBy'
import snakeCase from 'lodash/fp/snakeCase'
import { isNotNil } from '@persuit/common-utils'

export type NewTemplateCountryInfo = {
	id: string
	code: string
	name: string
	searchKey: string
}

const alignedTemplateCountryListWithSaliCountries = COUNTRIES.map(
	(country): NewTemplateCountryInfo => {
		if (country.name === 'USA') {
			return { ...country, name: 'United States of America', code: '', searchKey: 'us' }
		}
		if (country.name === 'UK') {
			return { ...country, name: 'United Kingdom', code: '', searchKey: 'uk' }
		}
		return {
			...country,
			code: '',
			searchKey: country.id,
		}
	},
)

const mapExistingCountryToSaliCountries =
	(existingCountryList: NewTemplateCountryInfo[]) =>
	(
		saliCountries: GetTemplateCountries_GetSaliLocationDataQuery['getSaliLocationData'],
	): NewTemplateCountryInfo[] => {
		// Partition the data into top countries and other countries
		const [topCountries, otherCountries] = partition(
			(country) => !!find({ name: country.name }, existingCountryList),
			saliCountries,
		)

		// Map top countries to ensure their order matches the topCountryNames array
		const orderedTopCountries = compact(
			existingCountryList
				.map((country) => {
					const foundTopCountry = find({ name: country.name }, topCountries)
					if (!foundTopCountry) {
						return {
							name: country.name,
							code: country.id,
							id: country.id,
							searchKey: country.searchKey,
						}
					}

					return {
						name: foundTopCountry.name,
						code: foundTopCountry.code,
						id: country.id,
						searchKey: country.searchKey,
					}
				})
				.filter(isNotNil),
		)

		const mappedOtherCountries = otherCountries.map((country) => ({
			...country,
			id: country.code,
			searchKey: snakeCase(country.name),
		}))

		return [...orderedTopCountries, ...mappedOtherCountries]
	}

const getTemplateCountries = mapExistingCountryToSaliCountries(
	alignedTemplateCountryListWithSaliCountries,
)

const GET_SALI_COUNTRIES = graphql(`
	query GetTemplateCountries_GetSaliLocationData {
		getSaliLocationData {
			code
			name
		}
	}
`)

const useTemplateCountries = () => {
	const { data, loading, error } = useQuery(GET_SALI_COUNTRIES)

	if (loading || error) {
		return {
			loading,
			error,
			templateCountries: [] as NewTemplateCountryInfo[],
			templateCountryLookup: {} as Record<NewTemplateCountryInfo['id'], NewTemplateCountryInfo>,
			templateCountryLookupBySearchKey: {} as Record<
				NewTemplateCountryInfo['searchKey'],
				NewTemplateCountryInfo
			>,
		}
	}

	const saliCountries = data?.getSaliLocationData ?? []
	const templateCountries = getTemplateCountries(saliCountries)
	const templateCountryLookup = keyBy<NewTemplateCountryInfo>('id', templateCountries)
	const templateCountryLookupBySearchKey = keyBy<NewTemplateCountryInfo>(
		'searchKey',
		templateCountries,
	)

	return {
		loading,
		error,
		templateCountries,
		templateCountryLookup,
		templateCountryLookupBySearchKey,
	}
}

export default useTemplateCountries
