import { TimezoneSelect, TimezoneSelectProps } from './timezone-select'
import { useFormComponent, RHFFormComponentProps } from '../../../utilities'

export type FormTimezoneSelectProps = RHFFormComponentProps<
	number,
	Omit<TimezoneSelectProps, 'timezoneId'>
>

export const FormTimezoneSelect = ({
	rules,
	name,
	defaultValue,
	...props
}: FormTimezoneSelectProps) => {
	const {
		field: { onChange, onBlur, value, ref, name: fieldName },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<TimezoneSelect
			onTimezoneSelected={onChange}
			onBlur={onBlur}
			name={fieldName}
			ref={ref}
			isError={!!error?.message}
			errorHelperText={error?.message}
			{...props}
			timezoneId={value}
		/>
	)
}
