// @ts-strict-ignore
import * as React from 'react'
import { Box, BoxProps, Typography, TypographyProps } from '@persuit/ui-components'

type PricingCardItemNumberingProps = {
	parentIndex?: number
	childIndex?: number
	containerProps?: BoxProps
	typographyProps?: TypographyProps
	style?: React.CSSProperties
}

const getNumberText = (parentIndex, childIndex) =>
	childIndex !== undefined ? `${parentIndex + 1}.${childIndex + 1}` : `${parentIndex + 1}.`

export const PricingCardItemNumbering = ({
	parentIndex,
	childIndex,
	containerProps,
	typographyProps,
	style,
}: PricingCardItemNumberingProps) => {
	const isParentIndexInvalid =
		(typeof parentIndex === 'number' && parentIndex < 0) ||
		parentIndex === undefined ||
		parentIndex === null ||
		isNaN(parentIndex)

	const isChildIndexInvalid =
		(typeof childIndex === 'number' && childIndex < 0) ||
		childIndex === null ||
		(childIndex !== undefined && isNaN(childIndex))

	if (isParentIndexInvalid || isChildIndexInvalid) {
		return <Box data-testid="item-numbering" />
	}

	return (
		<Box component="span" padding="10px 10px 5px 10px" {...containerProps}>
			<Typography
				variant="body2"
				component="span"
				data-testid="item-numbering"
				{...typographyProps}
				style={style}
			>
				{getNumberText(parentIndex, childIndex)}
			</Typography>
		</Box>
	)
}

PricingCardItemNumbering.displayName = 'ItemNumbering'
