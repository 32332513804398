import { Fragment } from 'react'
import { Box, Typography, PopoverHelp, Alert, AlertTitle } from '@persuit/ui-components'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { calculateComparisonValue } from './calculate-comparison-value'
import { ResponseToDeliverable, DeliverableV2 } from '@persuit/ui-graphql/generated'
import { isEmpty } from 'lodash/fp'
import { requestValidForComparisonValue } from 'packages/ui-shared-components/src/common-util'

type ComparisonValueInfoProps = {
	totalPriceRequired: boolean
	deliverables: Array<DeliverableV2>
	responseToDeliverables: Array<ResponseToDeliverable>
	comparisonValue: number
	rfpCurrency: string
	isDetailView?: boolean
}

export const ComparisonValueInfo = ({
	totalPriceRequired,
	deliverables,
	responseToDeliverables,
	comparisonValue,
	rfpCurrency,
	isDetailView,
}: ComparisonValueInfoProps) => {
	if (totalPriceRequired) return null
	if (isEmpty(deliverables) || !requestValidForComparisonValue(deliverables)) return null
	if (isEmpty(responseToDeliverables)) return null

	const proposalComparisonValue = isDetailView
		? comparisonValue
		: calculateComparisonValue(responseToDeliverables)

	return (
		<Fragment>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Alert severity="info" role="status">
					<AlertTitle>This request uses the comparison value for price ranking.</AlertTitle>
					<Typography>
						When the total price is not requested a comparison value will be auto-calculated. This
						is provided for comparative purposes only.
					</Typography>
				</Alert>
			</Box>

			<Box display="flex" my={3}>
				<Box sx={{ width: '50%' }}>
					<Box display="flex" alignItems="center">
						<Typography variant="h3XSmall">Comparison Value</Typography>

						{isDetailView && (
							<PopoverHelp
								role="tooltip"
								ml={1}
								content={
									<div>
										<Typography variant="caption" sx={{ fontWeight: 'bold' }}>
											This request is using comparison value
										</Typography>
										<br />
										<Typography variant="caption">
											When the total price is not requested a comparison value will be
											auto-calculated. This is provided for comparative purposes only.
										</Typography>
									</div>
								}
								triggerButtonProps={{
									'aria-label': 'Comparison value help',
								}}
								triggerButtonSize="small"
							/>
						)}
					</Box>
					<Box>
						<Typography variant="body1">
							This is the auto-sum of pricing items excluding hourly rates and percentage items.
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography variant="h3XSmall" component="p">
						{formatPriceWithCurrency(rfpCurrency, proposalComparisonValue)}
					</Typography>
				</Box>
			</Box>
		</Fragment>
	)
}
