import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	List,
	Typography,
	useTheme,
} from '@persuit/ui-components'
import NamwolfLogo from '../../../../components/namwolf/namwolf-logo'
import { FirmListUser } from './firm-list-user'
import { FirmUser, Person } from '../types'
import { parsePerson } from '../utils'

function filterAndSortContacts(
	firmId: string,
	favourites: string[],
	contacts: FirmUser[],
): Person[] {
	// Filter contacts by firmId
	const filteredContacts = contacts
		.filter((contact) => contact?.org && contact.org._id === firmId)
		.map((contactUser) => {
			return parsePerson(contactUser, favourites)
		})

	// Sort contacts, placing favourites at the top
	const sortedContacts = filteredContacts.sort((a, b) => {
		if (!a._id || !b._id) return 0

		const aFavourite = favourites.includes(a?._id)
		const bFavourite = favourites.includes(b?._id)

		if (aFavourite && !bFavourite) {
			return -1
		} else if (!aFavourite && bFavourite) {
			return 1
		} else {
			return 0
		}
	})

	return sortedContacts
}

export type FirmListFirmProps = {
	firmId: string
	firmName: string
	favourites: string[]
	contacts: FirmUser[]
	isNamwolfMember?: boolean | null
}

export const FirmListFirm = ({
	firmName,
	firmId,
	favourites,
	contacts,
	isNamwolfMember,
}: FirmListFirmProps) => {
	const theme = useTheme()
	const sortedFirmContacts = filterAndSortContacts(firmId, favourites, contacts)

	return (
		<Accordion
			elevation={0}
			sx={{
				mt: 1,
				p: 1,
				width: '100%',
				border: `1px solid ${theme.palette.action.focus}`,
			}}
		>
			<AccordionSummary disableContainerClick={true}>
				<Box display="grid" gridTemplateColumns="2fr 1fr" gap={1}>
					<Typography component="span" display="inline-flex" alignItems="center">
						{firmName}

						<Box display="flex" ml={1}>
							{!!isNamwolfMember && <NamwolfLogo />}
						</Box>
					</Typography>
					<Typography>{sortedFirmContacts.length} contacts</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				{sortedFirmContacts.length > 0 && (
					<List aria-label="Firm contacts list" sx={{ p: 0, m: 0 }}>
						{sortedFirmContacts.map((contactUser) => {
							return (
								<FirmListUser
									key={contactUser._id}
									person={contactUser}
									isNamwolfMember={!!isNamwolfMember}
								/>
							)
						})}
					</List>
				)}
			</AccordionDetails>
		</Accordion>
	)
}
