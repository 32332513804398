import { hasFeedbackItem } from './has-feedback'

//format the feedback so it matches the gql mutation
export const formatFeedback = (proposal, feedback) => {
	return {
		status: proposal.proposalStatus,
		subject: proposal.subject,
		ratings: {
			strategy: feedback.strategy,
			pricing: feedback.pricing,
			expertise: feedback.expertise,
			diversity: feedback.diversity,
		},
		comment: feedback.comment,
	}
}

export const getFormatFeedbackArray =
	({ formState, hasFeedbackItem, formatFeedback }) =>
	(acc, proposal) => {
		const proposalFeedback = formState[proposal._id]
		if (!hasFeedbackItem(proposalFeedback)) {
			return acc
		}
		const formattedFeedback = formatFeedback(proposal, proposalFeedback)

		acc.push(formattedFeedback)

		return acc
	}

const addFeedbackToProposals = (firmsAndProposals, formState) => {
	const formattedFirmsAndProposals = firmsAndProposals.reduce((inviteAcc, invite) => {
		const formattedFeedbackArray = invite.proposals.reduce(
			getFormatFeedbackArray({ formState, hasFeedbackItem, formatFeedback }),
			[],
		)
		invite.feedback = formattedFeedbackArray

		inviteAcc.push(invite)

		return inviteAcc
	}, [])

	return formattedFirmsAndProposals
}

export default addFeedbackToProposals
