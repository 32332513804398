import { useMutation } from '@apollo/client'
import { styled, useSnackbar } from '@persuit/ui-components'
import { trackWithPendo } from '../../../../track-event'
import eliminateActionTypes from '../../../../../common/data/eliminate-action-types'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils'
import notificationOptions from './notification-option-enums'
import { ELIMINATE_FIRM_MUTATION } from '../option-select-firm/confirm-mark-complete'

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`
const sendFirmEmailsBullets = [
	'The unsuccessful firm will be notified.',
	'All proposals from this firm will be marked as unsuccessful.',
	'The firm’s dashboard will indicate the unsuccessful status of the proposal.',
]

const dontSendFirmEmailsBullets = [
	'The unsuccessful firm will not be notified.',
	'All proposals from this firm will be marked as unsuccessful.',
	'The firm’s dashboard will indicate the unsuccessful status of the proposal.',
]

const notificationOptionText = new Map()
	.set(notificationOptions.SEND_ELIMINATE_FIRM_NOTIFICATIONS, 'Notify unsuccessful firm')
	.set(notificationOptions.DONT_SEND_ELIMINATE_FIRM_NOTIFICATIONS, 'Do not send notifications')

function OptionEliminateFirmConfirm({
	onCancel,
	onDone,
	onPrev,
	firmId,
	firmName,
	requestId,
	fetchResponses,
	openProposalFeedbackFormSingleFirm = null,
	value: { sendFirmEmailsSelected },
}) {
	const [eliminateFirm, { loading, error }] = useMutation(ELIMINATE_FIRM_MUTATION)
	const { openSnackbar } = useSnackbar()
	const sendFirmEmails =
		sendFirmEmailsSelected === notificationOptions.SEND_ELIMINATE_FIRM_NOTIFICATIONS ? true : false
	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Mark firm as unsuccessful',
				[stepIndex(defaultSteps, 'ACTIONS')]: notificationOptionText.get(sendFirmEmailsSelected),
			}}
			isWorking={loading}
			onConfirm={async () => {
				await eliminateFirm({
					variables: {
						payload: {
							requestId,
							orgIds: [firmId],
							action: eliminateActionTypes.ELIMINATE,
							sendFirmEmails,
						},
					},
				})
				trackWithPendo('manage_proposal::mark_firm_unsuccessful', { requestId, firmId })
				openSnackbar(`${firmName} marked as unsuccessful`)
				onDone()
				await fetchResponses() // Reload isEliminated value from resolver

				if (openProposalFeedbackFormSingleFirm) {
					openProposalFeedbackFormSingleFirm()
				}
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Are you sure you want to mark this firm as unsuccessful?</strong>
			<StyledList>
				{sendFirmEmails ? (
					<>
						{sendFirmEmailsBullets.map((content, index) => (
							<li key={index}>
								<Text component="p" type="body1" black={true}>
									{content}
								</Text>
							</li>
						))}
					</>
				) : (
					<>
						{dontSendFirmEmailsBullets.map((content, index) => (
							<li key={index}>
								<Text component="p" type="body1" black={true}>
									{content}
								</Text>
							</li>
						))}
					</>
				)}
			</StyledList>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionEliminateFirmConfirm
