// eslint-disable-next-line no-restricted-imports -- Please upgrade
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = ['Once uploaded, firm can download the final engagement letter.']

function OptionUploadFinalLoeStart({ onNext, disabled }) {
	return (
		<DialogWizardListOption
			dataTestid="upload-final-loe-option"
			icon={<AttachFileIcon />}
			primaryText="Upload Final Engagement Letter"
			bullets={bullets}
			onClick={onNext}
			disabled={disabled}
		/>
	)
}

export default OptionUploadFinalLoeStart
