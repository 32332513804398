import * as React from 'react'
import { Box, BoxProps, useTheme } from '@persuit/ui-components'

export type CardIconBackerProps = BoxProps & {
	children: React.ReactNode
	filled: boolean
}

export const CardIconBacker = ({ children, filled, ...rest }: CardIconBackerProps) => {
	const theme = useTheme()

	const color = theme.palette.success.darkHue

	return (
		<Box
			width="24px"
			height="24px"
			border={`1px solid ${color}`}
			borderRadius="24px"
			bgcolor={filled ? color : undefined}
			display="flex"
			justifyContent="center"
			alignItems="center"
			color={filled ? theme.palette.primary.contrastText : color}
			{...rest}
		>
			{children}
		</Box>
	)
}
