import { Link as RRLink, useParams } from 'react-router-dom'
import rfpProposalStates from '../../../../common/data/rfp-proposal-states'
import useTranslation from '../../../custom-hooks/translation-hook'
import { ThumbUpIcon, LocalOfferIcon, Box, List, ListItem, Link } from '@persuit/ui-components'

const RfpActions = ({ proposals }) => {
	const { requestId } = useParams()
	const { t } = useTranslation()

	return (
		<List disablePadding={true} aria-label="Proposals">
			{proposals.map((proposal, index) => {
				const link = `/${t('code')}/request/${requestId}/proposals/view/${proposal._id}/`

				return (
					<ListItem
						disablePadding={true}
						key={index}
						sx={{
							width: '100%',
							mb: 1,
							...(index < proposals.length - 1
								? {
										borderBottom: `1px dashed`,
										borderColor: 'form.borderActive',
								  }
								: undefined),
						}}
					>
						<Link component={RRLink} to={link} sx={{ width: '100%', display: 'block' }}>
							<Box display="flex">
								<Box>
									<LocalOfferIcon
										aria-hidden="true"
										size="medium"
										color="primary"
										sx={{
											mr: 1,
										}}
									/>
								</Box>
								<div>
									{proposal.subject}
									{proposal.status === rfpProposalStates.ACCEPTED && (
										<Box>
											<ThumbUpIcon size="medium" color="primary" aria-hidden="true" />
											&nbsp;{t('rfp.proposals.status.selected')}
										</Box>
									)}
								</div>
							</Box>
						</Link>
					</ListItem>
				)
			})}
		</List>
	)
}
RfpActions.defaultProps = {
	proposals: [],
}

export default RfpActions
