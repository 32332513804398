import { flow, pull, map, getOr, __, join } from 'lodash/fp'

export const diversityDefinitionMapping = {
	women: 'Women',
	lgbtqia: 'Members of LGBTQIA+ Community',
	minorities: 'Racial and Ethnic Minorities',
	disabilities: 'Persons with Disabilities',
	veterans: 'Veterans',
	neurodivergent: 'Neurodiverse Individuals',
}

// Produce a comma separated string with values mapped to diversity definitions
export const mapDiversityDefinitions = flow(
	// The __ is a placeholder for the value of the item in the array being mapped
	map(getOr('', __, diversityDefinitionMapping)),
	// Pull out any items that could not be mapped
	pull(''),
	join(', '),
)

export const constructDiversityDefinition = (definitions = [], { hidePlaceholder } = {}) => {
	const mappedDefinitions = mapDiversityDefinitions(definitions)
	if (!mappedDefinitions && hidePlaceholder) {
		return null
	}
	if (!mappedDefinitions) {
		return 'Definition of Diverse is not set in organisation settings'
	}

	return `Diversity criteria: ${mappedDefinitions}.`
}
