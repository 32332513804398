// @ts-strict-ignore
import { useState, useRef, useEffect, useCallback } from 'react'

import * as React from 'react'
import { isNotNil } from '@persuit/common-utils'
import {
	Box,
	InfoOutlinedIcon,
	Typography,
	useTheme,
	useSnackbar,
	PopoverHelp,
} from '@persuit/ui-components'
import { graphql, useQuery, useMutation } from '@persuit/ui-graphql'
import { debounce } from 'lodash'
import savingsCalculationMethods from '../../../../../common/data/savings-calculation-methods'
import formatCurrency from '../../../../utils/format-currency'

// TODO: Move this to a reusable package somewhere
import currencies from '../../../../../common/data/currencies'

import { OverrideForm } from './override-form'
import { OverrideButton } from './override-button'
import { OverriddenSavings } from './overridden-savings'
import { TOOLTIPS } from './tooltips'
import { useSelector } from 'react-redux'
import { isEmpty, isNil } from 'lodash/fp'
import { requestValidForComparisonValue } from 'packages/ui-shared-components/src/common-util'

type SavingsSummaryProps = {
	requestId: string
}

export const SavingsSummary = ({ requestId }: SavingsSummaryProps) => {
	const theme = useTheme()

	return (
		<Box>
			<Typography variant="h2XSmall">Savings</Typography>
			<Box border={`1px solid ${theme.palette.divider}`} borderRadius="4px" p={3} mt={2}>
				<SavingsSummaryContent requestId={requestId} />
			</Box>
		</Box>
	)
}

const SAVINGS_QUERY = graphql(`
	query SavingsSummary_RfpData($rfpId: ID!) {
		getRfp(_id: $rfpId) {
			_id
			includedInAnalytics
			useCase
			rateReview {
				currency
			}
			detail {
				currency
				totalPriceRequired
				deliverablesV2 {
					__typename
					... on Deliverable {
						_id
						pricingPreferences
					}
					... on PricingGroup {
						_id
						deliverables {
							_id
							pricingPreferences
						}
					}
				}
			}
			savings {
				persuitSavings
				startingBid {
					bidValue
					bidSetting
				}
				selectedBid
				savingsStatus
				override {
					adjustedSavings
					adjustedAgreedFee
					note
				}
			}
			group {
				_id
				name
			}
			grants
		}
	}
`)

const SAVE_OVERRIDES = graphql(`
	mutation SavingsSummary_SaveOverrides($input: SaveOverriddenSavingsInput!) {
		saveOverriddenSavings(input: $input) {
			_id
			savings {
				persuitSavings
				startingBid {
					bidValue
					bidSetting
				}
				selectedBid
				savingsStatus
				override {
					adjustedSavings
					adjustedAgreedFee
					note
				}
			}
		}
	}
`)

const REVERT_OVERRIDES = graphql(`
	mutation SavingsSummary_RevertOverrides($rfpId: ID!) {
		revertOverriddenSavings(rfpId: $rfpId) {
			_id
			savings {
				persuitSavings
				startingBid {
					bidValue
					bidSetting
				}
				selectedBid
				savingsStatus
				override {
					adjustedSavings
					adjustedAgreedFee
					note
				}
			}
		}
	}
`)

const SAVE_NOTES = graphql(`
	mutation SavingsSummary_SaveNotes($rfpId: ID!, $note: String!) {
		saveAdjustedSavingsNote(rfpId: $rfpId, note: $note) {
			_id
			savings {
				persuitSavings
				startingBid {
					bidValue
					bidSetting
				}
				selectedBid
				savingsStatus
				override {
					adjustedSavings
					adjustedAgreedFee
					note
				}
			}
		}
	}
`)

const getSavingsValue = (adjustedSavings, persuitSavings) => {
	if (!isNil(adjustedSavings)) {
		return Math.round(adjustedSavings)
	}

	if (persuitSavings) {
		const savings = persuitSavings <= 0 ? 0 : Math.round(persuitSavings)
		return Math.round(savings)
	}

	return 0
}

const calculatePercentage = (numerator, denominator) => {
	if (numerator === 0) {
		return 0
	}

	if (denominator === 0) {
		return 0
	}

	const percentage = (numerator / denominator) * 100
	return percentage
}

const getSavingsReference = (savingsCalculationMethod, selectedBidStatus) => {
	if (
		savingsCalculationMethod === savingsCalculationMethods.RELATIVE_TO_MEDIAN_PRICE &&
		selectedBidStatus === 'no_selected_bid'
	) {
		return 'Median starting bid - Median final bid'
	}

	switch (savingsCalculationMethod) {
		case savingsCalculationMethods.RELATIVE_TO_AVERAGE_PRICE:
			return 'Average starting bid - Selected bid'

		case savingsCalculationMethods.RELATIVE_TO_HIGHEST_PRICE:
			return 'Highest starting bid - Selected bid'

		case savingsCalculationMethods.RELATIVE_TO_MEDIAN_PRICE:
			return 'Median starting bid - Selected bid'

		default:
			return 'Average starting bid - Selected bid'
	}
}

type SavingsSummaryContentProps = {
	requestId: string
}

const getOverriddenSavingsTooltip = (isManager) => {
	if (isManager) {
		return TOOLTIPS.overriddenSavings
	}

	return TOOLTIPS.nonManagerOverriddenSavings
}

const SavingsSummaryContent = ({ requestId }: SavingsSummaryContentProps) => {
	const overrideButtonRef = React.useRef<HTMLButtonElement>()
	const theme = useTheme()
	const isUAMToggledOn = true // dev-5192.user-access-management

	const { openSnackbar } = useSnackbar()
	const [showOverrideForm, setShowOverrideForm] = useState(false)
	const { orgName } = useSelector((state: any) => ({
		orgName: state.auth.user.org.name,
	}))

	const [overrideSavings] = useMutation(SAVE_OVERRIDES)
	const [revertSavings] = useMutation(REVERT_OVERRIDES)
	const [saveNote] = useMutation(SAVE_NOTES)

	const handleSaveNote = debounce(
		useCallback(
			async (note: string) => {
				await saveNote({ variables: { rfpId: requestId, note } })
				openSnackbar('Successfully auto-saved')
			},
			[requestId, saveNote, openSnackbar],
		),
		1500,
	)

	const { data } = useQuery(SAVINGS_QUERY, {
		variables: {
			rfpId: requestId,
		},
		fetchPolicy: 'cache-and-network',
	})

	const rfp = data?.getRfp

	if (!rfp) {
		return null
	}

	const canEditRfpAnalytics = rfp.grants.includes('EDIT_ANALYTICS')

	if (!rfp.includedInAnalytics) {
		return (
			<Box maxWidth="800px" margin="auto">
				<Typography align="center">
					<strong>Savings not tracked</strong>
				</Typography>
				<Typography align="center">
					Please note, your organization has chosen to not track savings from this request in
					company-wide analytics.
					<br />
					This request may contain test, duplicate, cancelled or distorted data.
				</Typography>
			</Box>
		)
	}

	const neverHaveSavings = rfp?.savings?.savingsStatus === 'total_price_not_required'

	const rfpSavings = rfp.savings

	if (!rfpSavings) {
		return null
	}

	const selectedBidStatus = rfp.savings.savingsStatus
	const overriddenSavings = rfpSavings.override
	const currency =
		(rfp?.useCase === 'rateReview' ? rfp?.rateReview?.currency : rfp.detail?.currency) ?? ''
	const totalPriceRequired = rfp.detail?.totalPriceRequired
	const deliverables = rfp.detail?.deliverablesV2 ?? []

	const savings = getSavingsValue(overriddenSavings?.adjustedSavings, rfpSavings?.persuitSavings)
	const startingPrice = Math.round(
		overriddenSavings
			? overriddenSavings.adjustedSavings + overriddenSavings.adjustedAgreedFee
			: rfpSavings.startingBid?.bidValue ?? 0,
	)
	const percentageSavings = calculatePercentage(savings, startingPrice)

	const bidTypeDescription = getSavingsReference(
		rfp.savings.startingBid.bidSetting,
		selectedBidStatus,
	)

	const openToProposals = rfp.savings.savingsStatus === 'rfp_open_to_proposals'

	const analyticsBasedOnComparisonValue =
		!totalPriceRequired && !isEmpty(deliverables) && requestValidForComparisonValue(deliverables)

	const groupName = rfp?.group?.name ?? ''

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="center"
				flexDirection="column"
				alignItems="center"
				gap={2}
			>
				<Typography variant="h3XSmall">
					{neverHaveSavings && !overriddenSavings
						? 'Enter savings'
						: overriddenSavings && !openToProposals
						? 'Adjusted savings'
						: 'Estimated savings'}
				</Typography>

				<Box display="flex" flexDirection="column" alignItems="center">
					{!(neverHaveSavings && !savings) && (
						<Box display="flex" alignItems="center">
							<Box borderRight={`1px solid ${theme.palette.divider}`} pr={2}>
								<Typography
									data-testid="estimated-savings-value"
									variant="h1"
									component="p"
									color="secondary"
								>
									{openToProposals ? (
										`${currencies[currency].symbol}_${currency}`
									) : (
										<AnimatedCounter
											value={savings}
											formatValue={(value) => {
												if (value === 0) {
													return `${currencies[currency].symbol}0 ${currency}`
												}
												return formatCurrency(value, currency)
											}}
										/>
									)}
								</Typography>
							</Box>
							<Box
								ml={2}
								// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
								bgcolor="rgba(178, 223, 219, 0.5)"
								borderRadius="16px"
								padding="4px 8px"
							>
								<Typography
									data-testid="estimated-savings-percentage"
									variant="h1"
									component="p"
									color="secondary"
								>
									{openToProposals ? (
										'_'
									) : (
										<AnimatedCounter
											value={percentageSavings}
											formatValue={(value) => value.toFixed(1)}
										/>
									)}
									%
								</Typography>
							</Box>
							<Box display="flex" alignItems="center" ml={2}>
								<PopoverHelp
									content={
										overriddenSavings
											? getOverriddenSavingsTooltip(canEditRfpAnalytics)
											: TOOLTIPS.persuitSavings
									}
									triggerIcon={InfoOutlinedIcon}
									triggerButtonProps={{
										size: 'small',
										'aria-label': 'Savings explanation',
									}}
								/>
							</Box>
						</Box>
					)}

					{openToProposals ? (
						<Typography
							style={{ maxWidth: 320 }}
							variant="body2"
							color="text.secondary"
							align="center"
						>
							Select a proposal to see savings or wait until all proposals have been submitted for
							an estimation.
						</Typography>
					) : overriddenSavings || showOverrideForm ? null : (
						<OverrideButton
							onClick={() => setShowOverrideForm(true)}
							overrideButtonRef={overrideButtonRef}
							neverHaveSavings={neverHaveSavings}
							canEditRfpAnalytics={canEditRfpAnalytics}
						/>
					)}
				</Box>
			</Box>

			{showOverrideForm ? (
				<OverrideForm
					neverHaveSavings={neverHaveSavings}
					currency={currency}
					onSubmit={async ({ adjustedFee, adjustedSavings }) => {
						await overrideSavings({
							variables: {
								input: {
									adjustedAgreedFee: adjustedFee,
									adjustedSavings,
									rfpId: requestId,
								},
							},
						})
						setShowOverrideForm(false)
					}}
					onCancel={() => {
						setShowOverrideForm(false)
					}}
				/>
			) : overriddenSavings && !openToProposals ? (
				<OverriddenSavings
					note={overriddenSavings.note}
					currency={currency}
					persuitSavings={rfp.savings.persuitSavings ?? 0}
					adjustedFee={overriddenSavings.adjustedAgreedFee}
					adjustedSavings={overriddenSavings.adjustedSavings}
					bidTypeDescription={bidTypeDescription}
					onOverrideClick={() => setShowOverrideForm(true)}
					onRevert={async () => {
						await revertSavings({ variables: { rfpId: requestId } })
						setTimeout(() => overrideButtonRef?.current?.focus(), 100)
					}}
					onNotesChange={(note) => handleSaveNote(note)}
					neverHaveSavings={neverHaveSavings}
					rfpGrants={rfp.grants ?? []}
				/>
			) : (
				<Box>
					{!neverHaveSavings && (
						<Box mt={2} gridArea="hintText" display="flex" justifyContent="center">
							<Typography variant="body2">
								<Typography color="text.secondary" variant="body2" component="span">
									{isUAMToggledOn ? groupName : orgName} has set the savings calculation as{' '}
								</Typography>
								{bidTypeDescription}{' '}
								{isNotNil(rfpSavings.selectedBid) &&
								isNotNil(rfpSavings.startingBid.bidValue) &&
								rfp.savings.savingsStatus !== 'no_selected_bid' ? (
									<span>
										({formatCurrency(startingPrice, currency)} -{' '}
										{formatCurrency(rfpSavings.selectedBid, currency)})
									</span>
								) : null}
							</Typography>
						</Box>
					)}
				</Box>
			)}
			{analyticsBasedOnComparisonValue && (
				<Box display={'flex'} flexDirection={'column'} textAlign={'center'} mt={3}>
					<Typography variant="body2Semibold" color={'text.secondary'}>
						*Estimation is based on Comparison Value
					</Typography>
					<Typography variant="body2" color={'text.secondary'}>
						Comparison value is the sum of pricing items that the firm has provided with the
						exception of hourly rates, rate card, and percentage items.
					</Typography>
				</Box>
			)}
		</Box>
	)
}

type AnimatedCounterProps = {
	value: number
	animationTime?: number
	formatValue?: (value: number) => React.ReactNode
}

const AnimatedCounter = ({
	value,
	animationTime = 500,
	formatValue = (value) => value,
}: AnimatedCounterProps) => {
	const currentValueRef = useRef(value)
	const [animatedValue, setAnimatedValue] = useState(value)

	useEffect(() => {
		const startTime = performance.now()
		const startingValue = currentValueRef.current
		let stop = false
		const cleanup = () => {
			stop = true
		}

		if (Math.abs(value - startingValue) < 0.00001) {
			return cleanup
		}

		const animate = () => {
			const elapsedTime = performance.now() - startTime
			const percentage = elapsedTime / animationTime

			if (percentage < 1) {
				const newValue = startingValue + (value - startingValue) * percentage
				currentValueRef.current = newValue
				setAnimatedValue(newValue)
				if (!stop) {
					requestAnimationFrame(animate)
				}
			} else {
				currentValueRef.current = value
				setAnimatedValue(value)
			}
		}

		animate()

		return cleanup
	}, [value])

	return <span>{formatValue(animatedValue)}</span>
}
