import {
	CircularProgress as CircularProgressMui,
	CircularProgressProps as CircularProgressPropsMui,
} from '@mui/material'
import { Box } from '../box'

export type CircularProgressProps = CircularProgressPropsMui & {
	trackColor?: string
}

export const CircularProgress = ({ trackColor, ...rest }: CircularProgressProps) => {
	const defaultCircularProgress = <CircularProgressMui aria-label="Loading" {...rest} />

	if (!trackColor) return defaultCircularProgress

	return (
		<Box width="40px" height="40px" display="relative">
			<Box position="absolute" width="40px" height="40px" color={trackColor}>
				<CircularProgressMui {...rest} color="inherit" value={100} aria-hidden={true} />
			</Box>
			<Box position="absolute" width="40px" height="40px">
				{defaultCircularProgress}
			</Box>
		</Box>
	)
}
