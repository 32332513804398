// @ts-strict-ignore
import { ClientItemPriceModel } from '../types'

export const ClientItemPricingModelStrings: Record<ClientItemPriceModel, string> = {
	none: 'No Preference',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	collaredFee: 'Collared Fee',
	contingencyFee: 'Contingency Fee',
	discountPercentage: 'Discount (%)',
	contingencyPercentage: 'Contingency Fee (%)',
	successFee: 'Success Fee',
	hourlyRates: 'Rate Card',
	hourlyRate: 'Hourly Rate',
	other: 'Other (see instructions)',
	estimate: 'Budgeting Estimate',
	included: 'No charge',
	afa: 'Alternative Fee Arrangements',
	rates: 'Rates',
	['']: '',
}

export const getPricingModelString = (pricingPreferences: string) => {
	if (!(pricingPreferences in ClientItemPricingModelStrings)) return ''
	return ClientItemPricingModelStrings[pricingPreferences]
}
