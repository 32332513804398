import * as React from 'react'

import { Tooltip as MTooltip, TooltipProps as MTooltipProps } from '@mui/material'

export type TooltipProps = {
	/**
	 * If disabled, the tooltip will no longer appear and the children will be rendered as if the Tooltip does not exist
	 */
	disabled?: boolean
	title?: string | React.ReactNode | null
} & Omit<MTooltipProps, 'title'>

export const Tooltip = ({ title, disabled = false, children, ...props }: TooltipProps) =>
	disabled || !title ? (
		children
	) : (
		<MTooltip aria-label={undefined} {...props} title={title}>
			{children}
		</MTooltip>
	)
