import { forwardRef } from 'react'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateHeaderCopyTemplateMutation } from '@persuit/ui-graphql/hooks'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import {
	MenuItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	FileCopyOutlinedIcon,
	useSnackbar,
	MenuItemProps,
} from '@persuit/ui-components'

graphql(`
	mutation RfpTemplateHeaderCopyTemplate($templateId: ID!) {
		copyRfpTemplate(_id: $templateId) {
			_id
		}
	}
`)

export type CopyMenuItemProps = {
	templateId: string
	isUploading?: boolean
} & MenuItemProps

export const CopyMenuItem = forwardRef(
	({ templateId, isUploading = false, ...props }: CopyMenuItemProps, ref) => {
		const { path } = useRouteMatch()
		const history = useHistory()
		const [copyTemplate] = useRfpTemplateHeaderCopyTemplateMutation()
		const { openSnackbar } = useSnackbar()

		const handleCopy = async () => {
			const response = await copyTemplate({ variables: { templateId } })
			const newTemplateId = response.data?.copyRfpTemplate?._id ?? ''
			if (newTemplateId) {
				openSnackbar('Template copied')
				const uri = generatePath(path, {
					lng: 'en',
					templateId: newTemplateId,
				})
				history.push(uri)
			}
		}

		return (
			<Tooltip title={isUploading ? 'Please wait while file uploads' : ''} ref={ref}>
				<MenuItem disabled={isUploading} onClick={handleCopy} aria-label="Copy Template" {...props}>
					<ListItemIcon>
						<FileCopyOutlinedIcon color="primary" fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="COPY" primaryTypographyProps={{ color: 'primary' }} />
				</MenuItem>
			</Tooltip>
		)
	},
)

CopyMenuItem.displayName = 'CopyMenuItem'
