import { gql } from '@persuit/ui-graphql'

const addUserToFavourites = gql`
	mutation AddUserToFavourites($userId: ID!) {
		addUserToFavourites(userId: $userId) {
			_id
			favourites
			emailAddresses {
				email
			}
		}
	}
`

export default addUserToFavourites
