import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk'
import { LDClient } from 'launchdarkly-js-client-sdk'

export type UserConfig = {
	user?: {
		id?: string
		email?: string
		impersonated?: boolean
	}
	org?: {
		id?: string
	}
}

type UseFeatureToggleClientPayload = {
	client?: LDClient
	/**
	 * Due to PII concern, we are ONLY allowed to track the following property:
	 * user id, user email, org id
	 * */
	identify?: (config?: UserConfig) => void
	/**
	 * Potential client initialisation error
	 */
	error?: Error
}

export function useFeatureToggleClient(): UseFeatureToggleClientPayload {
	const client = useLDClient()
	const error = useLDClientError()
	const identify = (config?: UserConfig) => {
		client?.identify({
			kind: 'user',
			key: config?.user?.id,
			email: config?.user?.email,
			orgId: config?.org?.id ?? '',
			impersonated: config?.user?.impersonated ?? false,
		})
	}

	return { client, identify, error }
}
