import { useState, useMemo } from 'react'
import { isNotNil } from '@persuit/common-utils'
import { refocusBeforeRemoval } from '@persuit/ui-utils'
import {
	Autocomplete,
	Box,
	Button,
	ListItem,
	ListItemText,
	SROnly,
	TextField,
	useSnackbar,
	useTheme,
} from '@persuit/ui-components'

import Fuse from 'fuse.js'
import emailValidator from 'email-validator'

import { NamwolfLabel } from '../../../../components/namwolf/namwolf-label'
import NamwolfLogo from '../../../../components/namwolf/namwolf-logo'
import { PanelPreferredFirmLogo } from '../../../../components/panel-preferred-firm/panel-preferred-firm-logo'
import { ApprovedFirmLogo } from '../../../../components/approved-firm/approved-firm-logo'
import { ApprovedFirmLabel } from '../../../../components/approved-firm/approved-firm-label'
import { PanelPreferredFirmLabel } from '../../../../components/panel-preferred-firm/panel-preferred-firm-label'
import { KnownPerson, UnknownPerson } from '../types'
import { Chip } from '../../../../containers/sharing/chip'

export type Person = KnownPerson | UnknownPerson

export type SharingAutoCompleteProps = {
	people: Person[]
	selectedPeople: Person[]
	pending: Record<string, boolean>
	setPending: (update: Record<string, boolean>) => void
	inviteError: string | undefined
	firmLists: Array<{
		_id: string
		name: string
		isPanel: boolean
		approved: boolean
		firms: { org: { _id?: string | null | undefined } }[]
	}>
}

export const SharingAutoComplete = ({
	selectedPeople,
	firmLists = [],
	people,
	pending,
	setPending,
	inviteError,
}: SharingAutoCompleteProps) => {
	const theme = useTheme()
	const { openSnackbar } = useSnackbar()
	const [highlightedOption, setHighlightedOption] = useState<Person | null>(null)
	const notInvitedPeople = useMemo(
		() => people.filter(({ _id }) => !selectedPeople.find((p) => p._id === _id)),
		[people, selectedPeople],
	)
	const pendingPeople = useMemo(() => {
		const filteredPeople = people
			.filter((item) => pending[item._id])
			.filter((p): p is KnownPerson => p.__type__ === 'known')
		const emailKeys = Object.keys(pending).filter((key) => key.includes('@'))
		const emailPeople = emailKeys.map((emailKey) => {
			return {
				email: emailKey,
				__type__: 'unknown',
				label: emailKey,
			} as UnknownPerson
		})
		return [...filteredPeople, ...emailPeople]
	}, [people, pending])

	const searchPeople = useMemo(() => initialiseSearchEngine(notInvitedPeople), [notInvitedPeople])

	const [open, setOpen] = useState(false)
	const [showMoreTags, setShowMoreTags] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [error, setError] = useState(false)

	const selectUser = (id: string) => {
		setPending({
			...pending,
			[id]: true,
		})

		openSnackbar('Contact Selected')
	}

	const deselectUser = (id: string) => {
		delete pending[id]
		setPending(pending)

		openSnackbar('Contact Unselected')
	}

	//Generate sets of panel/preferred and approved firms
	const panelOrPreferredFirms = new Set()
	const approvedFirms = new Set()

	firmLists.forEach((firmList) => {
		const isApproved = !!firmList.approved
		const isPanel = !!firmList.isPanel
		if (isApproved || isPanel) {
			firmList.firms.filter(Boolean).forEach(({ org }) => {
				if (!org) {
					return
				}
				if (isApproved) {
					approvedFirms.add(org?._id)
				}
				if (isPanel) {
					panelOrPreferredFirms.add(org?._id)
				}
			})
		}
	})
	const validateEmail = (email: string) =>
		!selectedPeople.find((p) => p.email === email) && emailValidator.validate(email)

	// filter the autocomplete list when the input changes (also by default, have
	// the first item in the autocomplete list selected by simulating focus. This
	// ensures that if the user presses enter with a partially matching query, the
	// item from the the autocomplete is selected instead of adding an unknown
	// item)
	const onInputChange = (value: string, { enter = false }: { enter?: boolean } = {}) => {
		if (value === '') {
			setInputValue('')
			return
		}

		const oldValue = inputValue
		const cleanValue = value.replace(/\n\r/g, '')
		const splitValues = cleanValue.split(/[,;]/)

		function submitValues(splitValues: string[], suffix?: string) {
			const invalidValues: string[] = []
			const validValues: (string | Person)[] = []

			splitValues.forEach((value) => {
				const trimmedValue = value.trim()
				// accept a pasted email match to an existing user only if the email is
				// an exact match
				const suggestion = people.find((person) => person.email === trimmedValue)

				if (suggestion) {
					validValues.push(suggestion)
				} else if (validateEmail(trimmedValue)) {
					validValues.push(trimmedValue)
				} else {
					// Push the original value, not the trimmed one to avoid changing the
					// input value. We want the new input value to be the same as before
					// minus the valid values.
					invalidValues.push(value)
				}
			})

			if (validValues.length > 0) {
				const additionalPendingValues = validValues.reduce((acc: Record<string, boolean>, item) => {
					if (typeof item === 'string') {
						acc[item] = true
					} else if (typeof item === 'object' && item.__type__ === 'known') {
						acc[item._id] = true
					}
					return acc
				}, {})

				setPending({
					...pending,
					...additionalPendingValues,
				})
			}
			setInputValue([...invalidValues, suffix].filter(isNotNil).join(','))
			setError(invalidValues.length > 0)
		}

		// Handle copy paste and Enter. In this case we assume that the user wants all inputs to be added. We detemine that the user copy pasted if the number of characters changes by more than one.
		if (value.length - oldValue.length > 1 || enter) {
			submitValues(splitValues)
		}
		// No separators, user is still typing.
		else if (splitValues.length === 1) {
			setInputValue(value)
			setError(false)
		}
		// Multiple emails manually typed. If the user is typing multiple emails it
		// is possible that one of the previous emails is invalid and they are still
		// typing the last email so attempt to add all the emails but the last one
		else if (splitValues.length > 1) {
			const lastValue = splitValues[splitValues.length - 1]
			submitValues(splitValues.slice(0, splitValues.length - 1), lastValue)
		}
	}

	const getErrorText = () => {
		if (inviteError) {
			return inviteError
		} else if (error) {
			return 'Please enter a valid email address'
		} else {
			return null
		}
	}

	return (
		<Autocomplete
			sx={{ flexBasis: 0, flexGrow: 1 }}
			inputValue={inputValue}
			onHighlightChange={(e, option) => setHighlightedOption(option)}
			value={pendingPeople}
			onChange={(e, value, reason, details) => {
				const option = details?.option
				if (reason === 'selectOption' && option) {
					selectUser(option._id)
				} else if (reason === 'removeOption' && option) {
					deselectUser(option._id || option.email)
				}
			}}
			multiple={true}
			disableClearable={true}
			onOpen={() => setOpen(true)}
			onClose={() => {
				setHighlightedOption(null)
				setOpen(false)
			}}
			open={open && !error}
			noOptionsText={
				<Box
					role="alert"
					aria-live="polite"
					sx={{ display: 'flex', alignItems: 'baseline', gap: 5 }}
				>
					<span>No matching firm contacts</span>
					<span
						aria-hidden={true}
						style={{
							fontWeight: '500',
							fontStyle: 'italic',
							color: theme.palette.grey900,
						}}
					>
						Press Enter (↵), Comma (,) or Semicolon (;) to select input
					</span>
					<SROnly>Press Enter, Comma, or Semicolon to select input</SROnly>
				</Box>
			}
			options={notInvitedPeople}
			getOptionLabel={(person) => person.email}
			filterOptions={(options, state) => searchPeople(state.inputValue)}
			limitTags={4}
			renderTags={(options, getTagProps, { limitTags }) => {
				const showAllTags = showMoreTags

				return (
					<Box
						sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
						role="list"
						id="firm-chip-list"
						aria-label="Invited firm contacts"
						// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
						tabIndex={0}
					>
						{options.slice(0, showAllTags ? undefined : limitTags).map((item, index) => {
							if (item.__type__ === 'unknown') {
								const unknownPerson = item as UnknownPerson
								return (
									<Box key={unknownPerson.email} role="listitem">
										<Chip
											chipProps={{
												id: `firm-chip-${index}`,
												...getTagProps({ index }),
												tabIndex: 0,
												'aria-label': `unregistered contact, ${[
													unknownPerson.email,
													unknownPerson.label,
												]
													.filter(Boolean)
													.join(', ')}`,
												sx: (theme) => ({ background: theme.palette.teal50 }),
											}}
											onDelete={() => {
												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.getElementById('firm-chip-list'),
													getItemByIndex: (index) => document.getElementById(`firm-chip-${index}`),
												})
												deselectUser(unknownPerson._id || unknownPerson.email)
											}}
											primaryText={unknownPerson.label ?? unknownPerson.email}
											secondaryText={unknownPerson.label}
											hideStar={!unknownPerson.label}
										/>
									</Box>
								)
							} else {
								const knownPerson = item as KnownPerson
								const isApprovedFirm = approvedFirms.has(knownPerson.org._id)
								const isPanelOrPreferredFirm = panelOrPreferredFirms.has(knownPerson.org._id)
								return (
									<Box key={knownPerson.email} role="listitem">
										<Chip
											key={knownPerson.email}
											chipProps={{
												id: `firm-chip-${index}`,
												...getTagProps({ index }),
												tabIndex: 0,
											}}
											onDelete={() => {
												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.getElementById('firm-chip-list'),
													getItemByIndex: (index) => document.getElementById(`firm-chip-${index}`),
												})
												deselectUser(knownPerson._id || knownPerson.email)
											}}
											userId={knownPerson._id}
											primaryText={knownPerson.name}
											secondaryText={knownPerson.org.name}
											isApprovedFirm={isApprovedFirm}
											isPanelOrPreferredFirm={isPanelOrPreferredFirm}
											icon={
												<>
													{isApprovedFirm && <ApprovedFirmLogo />}
													{isPanelOrPreferredFirm && <PanelPreferredFirmLogo />}
													{knownPerson.org.isNamwolfMember && <NamwolfLogo />}
												</>
											}
											isFavourite={knownPerson.isFavourite}
											hideStar={false}
										/>
									</Box>
								)
							}
						})}
						{limitTags && options.length - limitTags > 0 ? (
							<Box role="listitem">
								<Button
									variant="text"
									color="primary"
									onClick={(e) => {
										e.stopPropagation()
										setShowMoreTags((show) => !show)
									}}
								>
									{showAllTags ? 'SHOW LESS' : `+ ${Math.max(0, options.length - limitTags)} MORE`}
								</Button>
							</Box>
						) : null}
					</Box>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					id="email-to-add"
					label="Enter email address"
					InputLabelProps={{ shrink: true }}
					variant="outlined"
					error={error || Boolean(inviteError)}
					helperText={getErrorText()}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							// If there is a highlighted option, assume the user wants to use
							// the default behavior of selecting that item instead of trying
							// to parse the input into contacts
							if (highlightedOption) {
								setInputValue('')
							} else {
								onInputChange(inputValue, { enter: true })
							}
						}
					}}
					onChange={(e) => {
						onInputChange(e.currentTarget.value)
					}}
					onBlur={() => {
						if (!error) {
							onInputChange('')
						}
					}}
					InputProps={{
						...params.InputProps,
					}}
					inputProps={{
						...params.inputProps,
						style: { ...params.inputProps.style, minWidth: '60%' },
						'aria-label': 'emails to add',
					}}
					data-testid="bcc-input-field"
				/>
			)}
			renderOption={(props, item) => {
				if (item.__type__ === 'unknown') return null

				const knownPerson = item as KnownPerson
				const { _id, name, org, email } = knownPerson

				return (
					<ListItem key={_id} {...props} data-testid={`${email}`}>
						<ListItemText
							sx={{ m: 0 }}
							primary={name}
							secondary={
								<span>
									{email}
									<br />
									<em>{org.name}</em>
									<Box display="flex" gap={1} pt={1}>
										{approvedFirms.has(org._id) && <ApprovedFirmLabel />}
										{panelOrPreferredFirms.has(org._id) && <PanelPreferredFirmLabel />}
										{org.isNamwolfMember && <NamwolfLabel />}
									</Box>
								</span>
							}
						/>
					</ListItem>
				)
			}}
		/>
	)
}

const initialiseSearchEngine = (people: Person[]) => {
	const fuse = new Fuse(
		people.filter((p): p is KnownPerson => p.__type__ === 'known'),
		{
			shouldSort: true,
			threshold: 0,
			ignoreLocation: true,
			keys: ['name', 'org.name', 'email'],
		},
	)

	return (query: string): Person[] => {
		const limit = 5

		if (!query) {
			return people.slice(0, limit)
		}

		return fuse
			.search(query, { limit })
			.sort((a, b) => (a.item.name > b.item.name ? 1 : a.item.name < b.item.name ? -1 : 0))
			.map((result) => result.item)
	}
}
