import { ga } from '@persuit/ui-analytics'
import { Box, Button, EditIcon, LockIcon, styled, Typography } from '@persuit/ui-components'
import { SectionalFormContainer } from '../sectional-form'
import { useState, useEffect, Fragment, useId } from 'react'
import { useParams } from 'react-router-dom'
import { RfpSectionNavigation } from './rfp-section-navigation'

const LockedSection = styled('div')`
	width: 100%;
	padding: 8px;
	border-radius: 5px 5px 0px 0px;
	background-color: ${(props) => props.theme.palette.primary.lighterHue};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	height: 72px;
`

function getSectionalLock(sectionalLocks, sectionName) {
	if (!(sectionName in sectionalLocks)) return null
	return sectionalLocks[sectionName]
}

function getLockedText(sectionalLock, userId) {
	if (sectionalLock.userId === userId)
		return <Typography>You are editing this section in a different tab</Typography>
	return (
		<Fragment>
			<Typography>
				This section is currently being edited by <b>{sectionalLock.displayName}</b>
			</Typography>
			<Typography variant="caption">Come back later</Typography>
		</Fragment>
	)
}

const sendGaLockEvent = () => {
	ga({
		label: 'NEW_RFP_FORM',
		page: 'RFP_LOCKED_SECTION',
		event: 'OPENED_LOCKED_SECTION',
	})
}

const LockableSectionInner = ({
	children,
	sectionName,
	sectionalLocks,
	instanceId,
	tryLock,
	userId,
	rfp,
	enabledSections,
}) => {
	const [shouldSendGaEvent, setShouldSendGaEvent] = useState(true)

	useEffect(() => {
		tryLock(sectionName)
		// OnMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const lockDescriptionId = useId()

	const sectionalLock = getSectionalLock(sectionalLocks, sectionName, instanceId)

	if (!sectionalLock || sectionalLock.formInstanceId === instanceId)
		return (
			<Fragment>
				{children}
				{rfp && (
					<RfpSectionNavigation
						currentSection={sectionName}
						rfp={rfp}
						enabledSections={enabledSections}
					/>
				)}
			</Fragment>
		)

	const lockedByYou = sectionalLock.userId === userId

	if (!lockedByYou && shouldSendGaEvent) {
		sendGaLockEvent()
		setShouldSendGaEvent(false)
	}

	return (
		<SectionalFormContainer focusOnMount={true} p={0}>
			<LockedSection>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<LockIcon color="primary" aria-hidden="true" />
					<Typography
						variant="body1"
						id={lockDescriptionId}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{getLockedText(sectionalLock, userId)}
					</Typography>
				</Box>
				<Box>
					{lockedByYou && (
						<Button
							color="primary"
							startIcon={<EditIcon />}
							onClick={() => tryLock(sectionName)}
							aria-describedby={lockDescriptionId}
						>
							Edit here
						</Button>
					)}
				</Box>
			</LockedSection>
			{rfp && (
				<RfpSectionNavigation
					currentSection={sectionName}
					rfp={rfp}
					enabledSections={enabledSections}
				/>
			)}
		</SectionalFormContainer>
	)
}

export const RfpLockableSection = ({
	children,
	sectionName,
	sectionalLocks,
	instanceId,
	tryLock,
	rfp,
	userId,
	enabledSections,
	defaultSection,
}) => {
	const { section } = useParams()
	const useSection = section ?? defaultSection

	if (useSection !== sectionName) return null
	return (
		<LockableSectionInner
			sectionName={sectionName}
			sectionalLocks={sectionalLocks}
			instanceId={instanceId}
			rfp={rfp}
			tryLock={tryLock}
			userId={userId}
			enabledSections={enabledSections}
		>
			{children}
		</LockableSectionInner>
	)
}
