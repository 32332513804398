import Fuse from 'fuse.js'
import { useState, useEffect, useRef } from 'react'
import { Box, Pagination, useTheme } from '@persuit/ui-components'
import { useInvitablePeople } from '../../../../custom-hooks'
import { PeopleSearch } from './people-search'
import { SearchResult } from './people-search-list-item'
import { useDebounce } from '@persuit/ui-hooks'
import { getInvitablePeople } from '../utils'
import { Person, User } from '../types'
import { InvitablePeopleSortOrder } from '@persuit/ui-graphql/generated'

export type PeopleSearchProps = {
	addToFavourites?: (userId: string) => void
	deselectUserId?: (id: string) => void
	selectUserId?: (id: string) => void
	onTabChange: () => void
	userFavourites: string[]
	user: User
	inviteError: string | undefined
}

export const AllContactsForm = ({
	onTabChange,
	user,
	userFavourites,
	inviteError,
}: PeopleSearchProps) => {
	const theme = useTheme()

	const [page, setPage] = useState<number>(1)
	const [totalCount, setTotalCount] = useState<number>(0)
	const [hasContacts, setHasContacts] = useState<boolean>(true)
	const [contactSortOrder, setContactSortOrder] = useState<InvitablePeopleSortOrder>('ASC')
	const [search, _setSearch] = useState('')
	const setSearch = (...args: Parameters<typeof _setSearch>) => {
		_setSearch(...args)
		setPage(1)
	}
	const hasContactsSet = useRef(false)
	const debouncedSearchTerm = useDebounce(search, 1000)

	const people = useInvitablePeople({
		includeColleagues: false,
		page,
		pageSize: 10,
		search: debouncedSearchTerm,
		sortOrder: contactSortOrder,
	})

	useEffect(() => {
		people.refetch({
			includeColleagues: false,
			page,
			pageSize: 10,
			search: debouncedSearchTerm,
			sortOrder: contactSortOrder,
		})
	}, [page, debouncedSearchTerm, people, contactSortOrder])

	useEffect(() => {
		if (people.data?.getInvitablePeople.totalCount !== undefined) {
			setTotalCount(people?.data?.getInvitablePeople?.totalCount ?? 0)
		}
	}, [people.data])

	const invitablePeople = getInvitablePeople(
		people.data?.getInvitablePeople.users ?? [],
		user,
		user?.org?._id ?? null,
		userFavourites,
	)

	const searchResults = searchFirms(invitablePeople, {
		searchTerm: debouncedSearchTerm,
		highlightColor: `${theme.palette.primary.lighterHue}`,
	})

	const peopleLoading = people.loading

	const showNoResultsMessage = search !== '' && !peopleLoading && invitablePeople.length === 0

	const pageCount = Math.ceil(totalCount / 10)

	useEffect(() => {
		if (!peopleLoading && !hasContactsSet.current) {
			if (invitablePeople.length > 0) {
				setHasContacts(true)
			} else {
				setHasContacts(false)
			}
			hasContactsSet.current = true
		}
	}, [peopleLoading, invitablePeople])

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between" gap={3}>
			<Box mt={4} height="100%">
				<PeopleSearch
					searchResults={searchResults}
					onTabChange={onTabChange}
					onSearchChange={(newSearchTerm: string) => setSearch(newSearchTerm)}
					searchTerm={search}
					showNoResultsMessage={showNoResultsMessage}
					hasContacts={hasContacts}
					totalCount={totalCount}
					peopleLoading={peopleLoading}
					inviteError={inviteError}
					contactSortOrder={contactSortOrder}
					setContactSortOrder={setContactSortOrder}
				/>
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center">
				{pageCount > 1 && (
					<Pagination
						count={pageCount}
						page={page}
						size="large"
						aria-label="Pagination"
						showFirstButton={true}
						showLastButton={true}
						onChange={(_, value) => setPage(value)}
					/>
				)}
			</Box>
		</Box>
	)
}

type SearchPeopleInput = {
	searchTerm: string
	highlightColor: string
}

const searchFirms = (
	data: Person[],
	{ searchTerm, highlightColor }: SearchPeopleInput,
): SearchResult[] => {
	if (!searchTerm.trim()) {
		return data.map((f) => ({
			...f,
			nameHighlight: f.name,
			orgNameHighlight: f.org.name,
			titleHighlight: f.title,
			locationHighlight: f.location,
		}))
	}

	const fuse = new Fuse(data, {
		includeMatches: true,
		threshold: 0,
		ignoreLocation: true,
		keys: ['name', 'org.name', 'title', 'location'],
	})
	const searchResults = fuse.search(searchTerm.trim())

	type Match = {
		key?: string
		value?: string
		indices: readonly (readonly [number, number])[]
	}

	function getHighlight(match: Match | undefined, input: string): React.ReactNode {
		if (!match) return input

		const { indices, key } = match

		if (indices.length === 0 || !key) return input

		return (
			<Box component="span" color="text.secondary">
				{indices.map((location, index, array) => {
					const start = location[0]
					const end = location[1]
					const singleCharacter = end - start === 0
					const prevEnd = array[index - 1]?.[1]
					const first = index === 0
					const last = index === array.length - 1

					return (
						<>
							{input.slice(first ? 0 : prevEnd + 1, start)}
							{singleCharacter && <span>{input.slice(start, end + 1)}</span>}
							{!singleCharacter && (
								<span
									style={{
										color: 'black',
										backgroundColor: highlightColor,
									}}
								>
									{input.slice(start, end + 1)}
								</span>
							)}
							{last && input.slice(end + 1)}
						</>
					)
				})}
			</Box>
		)
	}

	// Process the Fuse matches and format the matches with highlights
	return searchResults.map((result) => {
		const matches = result.matches ?? []
		const orgNameMatch = matches.find((match) => match.key === 'org.name')
		const nameMatch = matches.find((match) => match.key === 'name')
		const titleMatch = matches.find((match) => match.key === 'title')
		const locationMatch = matches.find((match) => match.key === 'location')

		return {
			...result.item,
			orgNameHighlight: getHighlight(orgNameMatch, result.item.org.name),
			nameHighlight: getHighlight(nameMatch, result.item.name),
			titleHighlight: getHighlight(titleMatch, result.item.title ?? ''),
			locationHighlight: getHighlight(locationMatch, result.item.location ?? ''),
		}
	})
}
