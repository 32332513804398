import { useQuery, graphql } from '@persuit/ui-graphql'
import {
	Box,
	KeyboardArrowLeftIcon,
	LoadingSpinner,
	Divider,
	IconButton,
	Typography,
} from '@persuit/ui-components'
import {
	MessagingParticipantsGetChannelInfoQuery,
	MessagingParticipantsGetSessionInfoQuery,
} from '@persuit/ui-graphql/generated'
import { UserList } from './user-list'

const GET_CHANNEL_INFO = graphql(`
	query MessagingParticipantsGetChannelInfo($channelId: ID!) {
		channel(channelId: $channelId) {
			id
			type
			orgs {
				firm {
					id
					name
				}
				client {
					id
					name
				}
			}
			members {
				userId
				orgId
				email
				name {
					first
					last
				}
				removed
			}
		}
	}
`)

const GET_SESSION_INFO = graphql(`
	query MessagingParticipantsGetSessionInfo {
		getSessionInfo {
			user {
				_id
				org {
					_id
					orgType
				}
			}
		}
	}
`)

export type ParticipantsPaneProps = {
	channelId: string
	closeParticipantsPane: () => void
}

export const getChannelName = (
	channelInfo: MessagingParticipantsGetChannelInfoQuery,
	sessionInfo: MessagingParticipantsGetSessionInfoQuery,
): string => {
	const {
		channel: {
			type,
			orgs: { firm, client },
		},
	} = channelInfo

	const fallBackName = 'Channel messages'

	if (type === 'PRIVATE_FIRM_CHANNEL') return firm?.name ?? fallBackName
	if (type === 'PRIVATE_CLIENT_CHANNEL') return client?.name ?? fallBackName
	if (type === 'CLIENT_TO_FIRM_CHANNEL') {
		if (sessionInfo?.getSessionInfo?.user?.org?.orgType === 'CLIENT')
			return firm?.name ?? fallBackName

		if (sessionInfo?.getSessionInfo?.user?.org?.orgType === 'FIRM')
			return client?.name ?? fallBackName
	}

	return fallBackName
}

export type ChannelUserList = {
	orgName: string
	currentOrg: boolean
	users: Array<{ name: { first: string; last: string }; email: string; currentUser: boolean }>
}

export const createUserLists = (
	channelInfo: MessagingParticipantsGetChannelInfoQuery,
	sessionInfo: MessagingParticipantsGetSessionInfoQuery,
): Array<ChannelUserList> => {
	const channel = channelInfo?.channel
	if (!channel) return []

	const firmName = channel.orgs.firm?.name ?? 'Firm'
	const firmOrgId = channel.orgs.firm?.id ?? ''
	const currentUserId = sessionInfo?.getSessionInfo?.user?._id
	const firmList = channel.members.filter(
		(member) => member?.orgId === firmOrgId && !member?.removed,
	)
	const firmObject: ChannelUserList = {
		orgName: firmName,
		currentOrg: firmOrgId === sessionInfo?.getSessionInfo?.user?.org?._id,
		users: firmList
			.map((member) => ({
				name: member?.name ?? { first: '', last: '' },
				email: member?.email ?? '',
				currentUser: member?.userId === currentUserId,
			}))
			.sort((a, b) =>
				`${a.name.first} ${a.name.last}`.localeCompare(`${b.name.first} ${b.name.last}`),
			),
	}

	const clientName = channel.orgs.client?.name ?? 'Client'
	const clientOrgId = channel.orgs.client?.id ?? ''
	const clientList = channel.members.filter(
		(member) => member?.orgId === clientOrgId && !member?.removed,
	)
	const clientObject: ChannelUserList = {
		orgName: clientName,
		currentOrg: clientOrgId === sessionInfo?.getSessionInfo?.user?.org?._id,
		users: clientList
			.map((member) => ({
				name: member?.name ?? { first: '', last: '' },
				email: member?.email ?? '',
				currentUser: member?.userId === currentUserId,
			}))
			.sort((a, b) =>
				`${a.name.first} ${a.name.last}`.localeCompare(`${b.name.first} ${b.name.last}`),
			),
	}

	if (channel.type === 'PRIVATE_FIRM_CHANNEL') return [firmObject]
	if (channel.type === 'PRIVATE_CLIENT_CHANNEL') return [clientObject]
	if (channel.type === 'CLIENT_TO_FIRM_CHANNEL') {
		if (sessionInfo?.getSessionInfo?.user?.org?.orgType === 'CLIENT')
			return [firmObject, clientObject]

		if (sessionInfo?.getSessionInfo?.user?.org?.orgType === 'FIRM')
			return [clientObject, firmObject]
	}

	return []
}

export const ParticipantsPane = ({ channelId, closeParticipantsPane }: ParticipantsPaneProps) => {
	const { data: sessionData } = useQuery(GET_SESSION_INFO)

	const { data: channelData } = useQuery(GET_CHANNEL_INFO, {
		variables: {
			channelId,
		},
	})

	if (!sessionData || !channelData)
		return (
			<Box width="100%" height="100%">
				<LoadingSpinner />
			</Box>
		)

	const channelName = getChannelName(channelData, sessionData)
	const numParticipants =
		channelData?.channel?.members?.filter((member) => !member?.removed)?.length ?? 0
	const userLists = createUserLists(channelData, sessionData)

	return (
		<Box width="100%" display="grid" gridTemplateColumns="100%" gridTemplateRows="auto 1fr">
			<Box gridRow={1} gridColumn={1} width="100%" display="flex" flexDirection="column">
				<Box display="flex" flexDirection="row" alignItems="center" gap={1} p={2}>
					<IconButton
						onClick={closeParticipantsPane}
						title="Back to messages"
						ref={(node) => node?.focus()}
					>
						<KeyboardArrowLeftIcon color="primary" />
					</IconButton>
					<Box display="flex" flexDirection="column">
						<Typography variant="caption" noWrap={true}>
							{channelName.toUpperCase()}
						</Typography>
						<Typography variant="body1Semibold">{numParticipants} Participants</Typography>
					</Box>
				</Box>
				<Divider />
			</Box>
			<Box gridRow={2} gridColumn={1} width="100%" height="100%">
				{userLists.map((userList, index) => (
					<UserList {...userList} key={index} />
				))}
			</Box>
		</Box>
	)
}
