import { Button, ButtonProps } from '../../button'
import { MicrosoftIcon } from '../../../icons'

export const SignInWithMicrosoftButton = (props: ButtonProps) => (
	<Button
		variant="secondary"
		LinkComponent="a"
		href="/auth/azure"
		fullWidth={true}
		startIcon={<MicrosoftIcon />}
		size="medium"
		{...props}
	>
		Sign in with Microsoft
	</Button>
)
