import axios from 'axios'

export const convertToWord = (htmlString: string) => {
	return axios.post('/en/convert/word', htmlString, {
		headers: {
			'Content-Type': 'text/html',
		},
		responseType: 'blob',
	})
}
