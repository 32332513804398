import { SxProps, Theme } from '@mui/material'
import { Typography } from '../typography'

type PLargeProps = {
	sx: SxProps<Theme> | undefined
	text: string
	color: string
}

const PLarge = ({ sx, text, color }: PLargeProps) => {
	return (
		<Typography variant="h1Large" component="p" color={color} sx={sx}>
			{text}
		</Typography>
	)
}

export { PLarge }
