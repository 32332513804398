export const PROPOSAL_STATES = {
	ACCEPTED: 'accepted',
	DRAFT_REVISION_DISCARDED: 'draft-revision-discarded',
	DRAFT_REVISION: 'draft-revision',
	DRAFT: 'draft',
	EDIT: 'edit',
	FINALIZED: 'finalized',
	REVISED: 'revised',
	SENT: 'sent',
	UNDER_REVISION: 'under-revision',
	VIEW: 'view',
	WITHDRAWN: 'withdrawn',

	// faux statuses not found in the prod DB
	COMPETITOR: 'competitor',
	EXAMPLE: 'example',
} as const

export type RfpProposalState = (typeof PROPOSAL_STATES)[keyof typeof PROPOSAL_STATES]

export const allowedActions = {
	edit: [
		PROPOSAL_STATES.UNDER_REVISION,
		PROPOSAL_STATES.DRAFT_REVISION,
		PROPOSAL_STATES.FINALIZED,
		PROPOSAL_STATES.DRAFT,
		PROPOSAL_STATES.EDIT,
		PROPOSAL_STATES.ACCEPTED,
		PROPOSAL_STATES.WITHDRAWN,
		PROPOSAL_STATES.REVISED,
		PROPOSAL_STATES.SENT,
	],
	accept: [PROPOSAL_STATES.FINALIZED],
	unaccept: [PROPOSAL_STATES.ACCEPTED],
	revisePrice: [PROPOSAL_STATES.FINALIZED],
	reviseProposal: [PROPOSAL_STATES.FINALIZED],
	discardProposalDraftRevision: [PROPOSAL_STATES.DRAFT_REVISION],
	withdraw: [PROPOSAL_STATES.FINALIZED],
	editScorecard: [
		PROPOSAL_STATES.FINALIZED,
		// the follow are not intentionally allow. they exist for parity with the legacy checks
		PROPOSAL_STATES.DRAFT,
		PROPOSAL_STATES.EDIT,
		PROPOSAL_STATES.ACCEPTED,
		PROPOSAL_STATES.WITHDRAWN,
		PROPOSAL_STATES.DRAFT_REVISION,
		PROPOSAL_STATES.DRAFT_REVISION_DISCARDED,
		PROPOSAL_STATES.UNDER_REVISION,
		PROPOSAL_STATES.REVISED,
		PROPOSAL_STATES.SENT,
	],
} as const

export const values = Object.values(PROPOSAL_STATES)

export default PROPOSAL_STATES
