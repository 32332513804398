// @ts-strict-ignore
import { useState, useEffect, useCallback, useRef } from 'react'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Typography, Box, useTheme } from '@persuit/ui-components'
import { isEmpty } from 'lodash/fp'

import SelectProposalWizard from '../dialog-wizard/manage-proposal'
import { WizardSteps } from '../dialog-wizard/manage-proposal/wizard'

import {
	useStore,
	useActions,
	StoreProvider,
	ComparisonTableRequest,
	ComparisonTableResponse,
	SortObject,
} from './store'
import {
	PinHighlightsRow,
	ColumnHeaderRow,
	TotalPriceRow,
	SummaryRow,
	ScorecardSection,
	DiversitySection,
	QuestionsSection,
	PricingSection,
	ComparisonValueRow,
	SRColumnHeaderRow,
} from './rows'
import { FloatingWidget } from './proposal-analysis/floating-widget'
import ActionBar from './action-bar/action-bar'
import { NegotiateDialog } from './negotiate-dialog'
import { useUser, useUAMEnabled } from '@persuit/ui-auth'
import { ReviseProposalProvider } from '../../custom-hooks/firm/use-revise-proposal'

import { PrintEffects } from './use-print'
import { requestValidForComparisonValue } from '@persuit/ui-shared-components'
import { AverageRateRow } from './rows/average-rate-row/average-rate-row'

type ResponseCompareContentProps = {
	requestObject: any
	fetchResponses: () => Promise<[]>
	/** Max height for the compare table grid */
	gridMaxHeight?: string
	progressViewExpanded?: boolean
	collapseRfpProgressView: () => void
	toggleRfpProgressView: () => void
	setShowProposalFeedbackFormAll: (state: boolean) => void
	setShowProposalFeedbackFormSingleFirm: (state: boolean) => void
	setSelectingProposal: (proposal: ComparisonTableResponse | null) => void
	selectingProposal?: ComparisonTableResponse | null
	showExampleResponse: boolean
	auctionInProgress: boolean
}

const ResponseComparisonContent = ({
	requestObject,
	fetchResponses,
	progressViewExpanded,
	toggleRfpProgressView,
	setShowProposalFeedbackFormAll,
	setShowProposalFeedbackFormSingleFirm,
	setSelectingProposal,
	selectingProposal = null,
	gridMaxHeight,
	showExampleResponse,
	auctionInProgress,
}: ResponseCompareContentProps) => {
	const theme = useTheme()
	const { user } = useUser()
	const [compactHeader, setCompactHeader] = useState(false)

	const compactHeaderTriggerRef = useRef<HTMLDivElement | null>(null)
	const resizeRootRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const el = compactHeaderTriggerRef.current

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setCompactHeader(false)
					} else {
						setCompactHeader(true)
					}
				})
			},
			{ threshold: 0, root: resizeRootRef.current },
		)

		if (el) {
			observer.observe(el)
		}

		return () => {
			if (el) {
				observer.unobserve(el)
			}
		}
	}, [])

	const {
		request,
		displayedResponses,
		originalResponses,
		isClient,
		fullscreen,
		showIntelligenceColumn,
	} = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		fullscreen: state.fullscreen,
		isClient: !state.isFirm,
		displayedResponses: s.displayedResponses(),
		request: state.request,
		originalResponses: state.responses,
	}))

	const proposalAnalyzerEnabled = user?.org?.settings?.isProposalAnalyserEnabled
	const [showManageProposalWizard, setShowManageProposalWizard] = useState(false)

	const handleSelectProposal = useCallback(
		(proposal: ComparisonTableResponse) => {
			setSelectingProposal(proposal)
			setShowManageProposalWizard(true)
		},
		[setSelectingProposal],
	)

	useEffect(() => {
		document.body.style.overflow = fullscreen ? 'hidden' : 'auto'
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [fullscreen])

	const isRateReview = request?.useCase === 'rateReview'

	const showPriceType = () => {
		if (isRateReview) return null

		const { totalPriceRequired, deliverablesV2 } = request.detail

		if (totalPriceRequired) {
			return 'totalPrice'
		}
		if (!isEmpty(deliverablesV2) && requestValidForComparisonValue(deliverablesV2)) {
			return 'comparisonValue'
		} else {
			return null
		}
	}

	const showPriceTypeConst = showPriceType()

	return (
		<>
			<Box
				ref={resizeRootRef}
				sx={{
					...(fullscreen
						? {
								zIndex: 100,
								position: 'fixed',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								background: 'white',
								paddingTop: '9rem',
								overflow: 'auto',
						  }
						: undefined),

					'@media only print': {
						position: 'unset',
						padding: 0,
					},
				}}
			>
				<Box
					sx={{
						...(fullscreen
							? {
									paddingX: '2rem',
							  }
							: undefined),
					}}
				>
					<ActionBar
						onToggleFullScreen={toggleRfpProgressView}
						isDraftProposal={showExampleResponse}
					/>
				</Box>

				{displayedResponses && !displayedResponses.length ? (
					<Typography variant="h5">
						{isClient ? 'No proposals to display' : "You haven't submitted a response yet."}
					</Typography>
				) : null}

				{displayedResponses && !!displayedResponses.length && (
					<Box
						id="response-compare-wrapper"
						sx={{
							'@media only screen': {
								maxHeight: gridMaxHeight || '80vh',
							},
							'@media only print': {
								'::-webkit-scrollbar': {
									display: 'none',
								},
							},
							...(!fullscreen
								? {
										overflow: 'auto',
										[`@media screen`]: {
											maxWidth: progressViewExpanded ? '60vw' : '90vw',
										},
								  }
								: { margin: '0 2rem' }),
							overflow: 'auto',
						}}
					>
						<Box
							component="table"
							cellPadding={0}
							cellSpacing={0}
							sx={{
								// Use nth child here since a row can have th and td children.
								[`& td:nth-child(${showIntelligenceColumn ? '2n + 3' : '2n + 2'})`]: {
									background: theme.palette.grey50,
								},
								[`& th:nth-child(${showIntelligenceColumn ? '2n + 3' : '2n + 2'})`]: {
									background: theme.palette.grey50,
								},
								[`& td:nth-child(${showIntelligenceColumn ? '2n + 4' : '2n + 3'})`]: {
									background: theme.palette.grey100,
								},
								[`& th:nth-child(${showIntelligenceColumn ? '2n + 4' : '2n + 3'})`]: {
									background: theme.palette.grey100,
								},
								height: '100%',
								borderCollapse: 'separate',
								boxSizing: 'border-box',
								position: 'relative',
							}}
						>
							<Box component="thead" sx={{ zIndex: 500 }}>
								<PinHighlightsRow />

								<SRColumnHeaderRow />
							</Box>

							<ColumnHeaderRow
								compactHeader={compactHeader}
								requestObject={requestObject}
								handleSelectProposal={handleSelectProposal}
								auctionInProgress={auctionInProgress}
								showPriceType={showPriceTypeConst}
							/>

							{/* This empty div is the trigger for setting compactHeader */}
							<Box component="tr">
								<Box
									component="td"
									ref={compactHeaderTriggerRef}
									style={{ position: 'sticky', left: '0px' }}
								/>
							</Box>

							{showPriceTypeConst === 'totalPrice' && <TotalPriceRow />}
							{showPriceTypeConst === 'comparisonValue' && <ComparisonValueRow />}

							<AverageRateRow />
							<SummaryRow />
							<ScorecardSection />
							{!isRateReview && <PricingSection />}
							<QuestionsSection />
							{!isRateReview && <DiversitySection />}
						</Box>
					</Box>
				)}
			</Box>

			{showManageProposalWizard && selectingProposal && (
				<SelectProposalWizard
					proposal={selectingProposal}
					proposals={originalResponses}
					rfp={request}
					isFirmEliminated={false}
					fetchResponses={fetchResponses}
					handleClose={() => setShowManageProposalWizard(false)}
					openProposalFeedbackFormAll={() => setShowProposalFeedbackFormAll(true)}
					openProposalFeedbackFormSingleFirm={() => setShowProposalFeedbackFormSingleFirm(true)}
					startingStep={WizardSteps.OPTION_SELECT_FIRM_OPTIONS}
					wizardName="Select"
				/>
			)}

			<ResponseCompareNegotiateDialog />

			{proposalAnalyzerEnabled && <FloatingWidget />}

			<PrintEffects />
		</>
	)
}

export type ResponseCompareProps = {
	request: ComparisonTableRequest
	enablePinning?: boolean
	isFirm?: boolean
	responses: ComparisonTableResponse[]
	showExampleResponse?: boolean
} & ResponseCompareContentProps

export function ResponseCompare({ enablePinning, ...rest }: ResponseCompareProps) {
	const { loading } = useFeatureToggles()
	const { user } = useUser()
	const { enabled: isUAMToggledOn, loading: uamToggleIsLoading } = useUAMEnabled()
	if (loading || uamToggleIsLoading) {
		return null
	}

	const initalSort: SortObject =
		!rest.request?.detail.totalPriceRequired &&
		!requestValidForComparisonValue(rest.request.detail.deliverablesV2)
			? { type: 'firmName', order: 'asc' }
			: rest.request?.detail.totalPriceRequired
			? { type: 'totalPrice', order: 'asc' }
			: { type: 'comparisonValue', order: 'asc' }

	return (
		<StoreProvider
			initialState={{
				proposalAnalyzerEnabled: !!user?.org?.settings?.isProposalAnalyserEnabled,
				responses: rest.responses,
				request: rest.request,
				disabled: !enablePinning,
				isFirm: Boolean(rest.isFirm),
				showExampleResponse: Boolean(rest.showExampleResponse),
				sort: initalSort,
				isUAMToggledOn,
			}}
			mergeState={({ initialState }) => ({
				disabled: initialState.disabled,
				responses: initialState.responses,
				request: initialState.request,
				isFirm: initialState.isFirm,
				showExampleResponse: initialState.showExampleResponse,
				isUAMToggledOn,
			})}
		>
			<ReviseProposalProvider>
				<ResponseComparisonContent {...rest} />
			</ReviseProposalProvider>
		</StoreProvider>
	)
}

const ResponseCompareNegotiateDialog = () => {
	const { negotiateResponse } = useActions()
	const { request, negotiatingResponse } = useStore((state) => ({
		request: state.request,
		negotiatingResponse: state.negotiatingResponse,
	}))

	return negotiatingResponse ? (
		<NegotiateDialog
			request={{ _id: request._id, status: request.status }}
			response={negotiatingResponse}
			currency={request.detail.currency ?? ''}
			handleClose={() => negotiateResponse(null)}
		/>
	) : null
}
