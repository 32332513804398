import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { ProfileProps } from './my-profile-menu'

import {
	StarIcon,
	Chip,
	BarChartIcon,
	DescriptionIcon,
	BubbleChartIcon,
	PostAddIcon,
	GroupsIcon,
	PriceBenchmarkingIcon,
	RatesSectionIcon,
	CrownIcon,
	FirmFinderIcon,
	PanelManagementIcon,
	useTheme,
} from '@persuit/ui-components'

import { pageUrl as templatesPageUrl } from '@client/pages/create-request-from-template'

import { NavItem, NewChip, NavBar } from './nav-components'
import { FeatureToggle } from '../feature-toggle'
import { useUserDismissibles } from '@persuit/ui-hooks'

type NavProps = {
	canCreateTemplate: boolean
	showAnalytics: boolean
	canCreateRfp: boolean
}

type ClientNavbarProps = {
	notificationCount: number
	profileProps: ProfileProps
	navProps: NavProps
}

export const ClientNavbar = ({ notificationCount, navProps, profileProps }: ClientNavbarProps) => {
	const { toggles } = useFeatureToggles()
	const { dismissibles, dismiss } = useUserDismissibles()
	const theme = useTheme()

	const { canCreateTemplate, showAnalytics, canCreateRfp } = navProps

	return (
		<NavBar notificationCount={notificationCount} profileProps={profileProps}>
			{({ closeDrawer, isImpersonated }) => (
				<>
					{canCreateRfp && (
						<NavItem to={templatesPageUrl()} onClick={closeDrawer} icon={<PostAddIcon />}>
							Create Request
						</NavItem>
					)}
					{canCreateRfp && toggles['dev-5754.company-rate-review'] && (
						<NavItem
							data-trackid="client-nav-rate-review"
							to={templatesPageUrl({
								filters: { useCaseIds: ['rateReview'], matterTypeIds: [], countryIds: [] },
							})}
							onClick={() => {
								closeDrawer()
								dismiss('LeftNavRateReviewNewChip')
							}}
							icon={<RatesSectionIcon />}
						>
							Rate Review Request
							<CrownIcon sx={{ color: theme.palette.premium.main, width: '17px', ml: 1 }} />
							{!dismissibles.has('LeftNavRateReviewNewChip') && <NewChip />}
						</NavItem>
					)}
					{/* Show Manage Templates only if the user is a client */}
					{canCreateTemplate && (
						<NavItem to={`/en/templates/`} onClick={closeDrawer} icon={<DescriptionIcon />}>
							Manage Templates
						</NavItem>
					)}
					{isImpersonated && (
						// This route is only available to admins impersonating a client user
						<NavItem to={`/en/loe-templates/`} onClick={closeDrawer} icon={<DescriptionIcon />}>
							Engagement Letter Templates
						</NavItem>
					)}
					{showAnalytics && (
						<NavItem
							to={`/en/analytics-dashboards/`}
							onClick={closeDrawer}
							icon={<BubbleChartIcon />}
						>
							Insights
						</NavItem>
					)}
					{showAnalytics && (
						<NavItem
							to={`/en/real-time-business-review/opportunity-loss/`}
							onClick={closeDrawer}
							icon={<StarIcon />}
						>
							Real-time Business Review
						</NavItem>
					)}
					{toggles['dev-7712-should-cost'] ? (
						<NavItem
							data-trackid="client-nav-price-benchmarking"
							to={`/en/price-benchmarking/`}
							onClick={closeDrawer}
							icon={
								<PriceBenchmarkingIcon
									style={{ position: 'absolute', top: 0, left: '4px', margin: '12px' }}
								/>
							}
						>
							Price Benchmarking{' '}
						</NavItem>
					) : null}
					{showAnalytics && (
						<NavItem to={`/en/program-benchmarking/`} onClick={closeDrawer} icon={<BarChartIcon />}>
							Program Benchmarking
						</NavItem>
					)}
					{showAnalytics && toggles['dev-5915-team-performance-benchmarking'] && (
						<FeatureToggle
							data-trackid="client-nav-team-performance"
							name="ftEnableQlikDashboards"
							whenOn={
								<NavItem to={`/en/team-performance/`} onClick={closeDrawer} icon={<GroupsIcon />}>
									Team Performance
									<NewChip />
								</NavItem>
							}
							whenOff={null}
						/>
					)}
					{toggles['dev-4604.firm-recommender-nav'] ? (
						<NavItem
							to={`/en/firm-recommender/`}
							onClick={closeDrawer}
							icon={
								<FirmFinderIcon
									style={{ position: 'absolute', top: 0, left: '4px', margin: '12px' }}
								/>
							}
						>
							Firm Recommender
							<Chip size="small" label="BETA" color="primary" style={{ marginLeft: '0.5rem' }} />
						</NavItem>
					) : null}
					{toggles['dev-8710.panel-management'] ? (
						<NavItem
							to={`/en/panel-management/`}
							onClick={closeDrawer}
							icon={
								<PanelManagementIcon
									style={{ position: 'absolute', top: 0, left: '4px', margin: '12px' }}
								/>
							}
						>
							Panel Management
							<Chip size="small" label="NEW" color="primary" style={{ marginLeft: '0.5rem' }} />
						</NavItem>
					) : null}
				</>
			)}
		</NavBar>
	)
}
