// @ts-strict-ignore
import { useState } from 'react'
import {
	Box,
	InvitationListIcon,
	LoadingSpinner,
	SROnly,
	Tabs,
	Tab,
	useConfirmDialog,
	useSnackbar,
	useTheme,
	Dialog,
	SendIcon,
	Typography,
	Alert,
} from '@persuit/ui-components'
import { graphql, useMutation, useQuery } from '@persuit/ui-graphql'
import { AllContactsForm } from './all-contacts-form'
import { FirmListsFormContainer } from './firm-lists-form'
import { InviteByEmailForm } from './invite-by-email-form'
import { InviteErrors } from './invite-errors'
import { ContactsAddedSectionTab, parseRows } from './contacts-added-section'
import { useDispatch } from '@client/store'
import allowTypes from '../../../../common/data/allow-types'
import rfpStates from '../../../../common/data/rfp-states'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { NamwolfDialog } from './namwolf-dialog'
import { FirmRecommenderLink } from '@client/pages/panel-management/edit-list/edit-list-add-firms/add-firms-data-grid/custom-subcomponents/grid-custom-footer'
import unregisteredUserListState from '../../../../common/data/unregistered-user-list-states'
import unregisteredUserStates from '../../../../common/data/unregistered-user-states'
import { ContactsAddedGrid } from './contacts-added-section/contacts-added-grid'
import { authActions, orgActions } from '../../../actions'
import {
	REVOKE_MUTATION,
	INVITE_UNREG_FIRM_MUTATION,
	SHARE_MUTATION,
} from '../../../containers/sharing/sharing-control.graphql'
import { SearchResult } from './all-contacts-form/people-search-list-item'
import {
	getSelectedUnknownUsers,
	getSelectedKnownUsers,
	mapShareItems,
	getCleanUserFavorites,
} from './utils'
import { MappedSharedItem, Person, RfpType, TabIndexes } from './types'
import { InviteProvider, useInviteContext } from './invite-context'

const GET_SESSION_QUERY = graphql(`
	query InviteTabsGetSessionInfo {
		getSessionInfo {
			groups {
				_id
				settings {
					firmLists {
						_id
						name
						isPanel
						approved
						firms {
							org {
								_id
							}
						}
					}
				}
			}
			user {
				_id
				favourites
				org {
					_id
				}
			}
		}
	}
`)

const getTabIndexes = (): TabIndexes => {
	return {
		ALL_CONTACTS: 0,
		FIRM_LISTS: 1,
		EMAIL: 2,
		CONTACTS_ADDED: 3,
	}
}

type InviteTabsProps = {
	rfp: RfpType
	inviteError: string | undefined
	refetchErrors: () => Promise<void>
	toggleInviteDialog?: () => void
	showInviteDialog?: boolean
	refetchRfp?: () => Promise<void>
}

export const InviteTabs = ({
	rfp,
	inviteError,
	refetchErrors,
	toggleInviteDialog,
	refetchRfp,
	showInviteDialog = false,
}: InviteTabsProps) => {
	const [tabIndex, setTabIndex] = useState(0)
	const theme = useTheme()
	const { toggles } = useFeatureToggles()
	const isPanelsEnabled = toggles['dev-8710.panel-management']

	const { openConfirmDialog } = useConfirmDialog()

	const [share] = useMutation(SHARE_MUTATION)
	const [clientInviteUnregFirmToPublishedRFP] = useMutation(INVITE_UNREG_FIRM_MUTATION)
	const [revoke] = useMutation(REVOKE_MUTATION)
	const { data: getSessionData, loading: loadingGetSessionInfo } = useQuery(GET_SESSION_QUERY, {
		fetchPolicy: 'cache-and-network',
	})
	const dispatch = useDispatch()

	const isDraftRfp = rfp.status === rfpStates.DRAFT

	if (!getSessionData || loadingGetSessionInfo) {
		return <LoadingSpinner />
	}

	const user = getSessionData.getSessionInfo.user ?? {}
	const userOrgId = user?.org?._id ?? null
	const userFavorites = getCleanUserFavorites(user.favourites)
	const selectedUnknownUsers = getSelectedUnknownUsers(
		rfp,
		unregisteredUserStates,
		unregisteredUserListState,
	)
	const selectedKnownUsers = getSelectedKnownUsers(rfp, userOrgId, allowTypes, userFavorites)
	const selectedUsers = [...selectedKnownUsers, ...selectedUnknownUsers]
	const tabs = getTabIndexes()

	async function updateUserOrg() {
		const orgs = await dispatch(orgActions.getOrgs())
		// If a new org was created it was added to the users panel
		const updatedUserOrg = orgs.find((o) => {
			return o._id === userOrgId
		})
		// update user local org with new panel to see the new org in the To search
		return dispatch(authActions.updateUserOrg(updatedUserOrg))
	}

	// This processes the share
	async function handleShare(mappedItems: MappedSharedItem[]) {
		// This needs to run after a share has been successfully added (i.e.
		// update mattermost, invitable people and panel firms, if applicable)
		function handlePostShare() {
			// This applies is a new org was auto-registered (adds it the
			// user's org's panel and refetches it)
			updateUserOrg()
		}

		const entityStatus = rfp.status

		let shareResolverUsers: MappedSharedItem[] = []
		let unregFirmResolverEmails: string[] = []

		if (entityStatus === rfpStates.DRAFT) {
			// Temporarily need to split these up while refactoring sharing.
			// When its a draft reg and unreg firm users will still got to the share resolver
			// When its published reg firm users goto share and unreg goes to clientInviteUnregFirmToPublishedRFP resolver
			shareResolverUsers = mappedItems
		} else {
			// May be sharing if we are correcting an amendable error where the user tries to invite their colleague instead of sharing.
			shareResolverUsers = mappedItems.filter(
				({ list, _id }) =>
					// invite registered users
					(list === 'to' && _id) ||
					// share with unregistered or registered users
					list === 'shared',
			)
			// Data for the new sharing path - unregistered firm users invited to a published RFP
			unregFirmResolverEmails = mappedItems
				.filter(({ list, _id }) => list === 'to' && !_id)
				.map(({ email }) => email)
				.filter((email) => email !== undefined) as string[]
		}

		// Temporarily calling 2 mutation queries. unregisteredEmails is the new one
		if (unregFirmResolverEmails.length !== 0) {
			try {
				await clientInviteUnregFirmToPublishedRFP({
					variables: {
						id: rfp._id,
						unregisteredEmails: unregFirmResolverEmails,
					},
				})

				// Kick off any functions that need to run after
				// a share has been successfully added
				handlePostShare()
			} catch (error) {
				openConfirmDialog({
					title: 'Please enter valid email addresses',
					content: <InviteErrors error={error} />,
					actions: [{ label: 'Ok', close: true }],
				})
			}
		}

		// Still call the old share resolver for existing users
		// invites for registered users
		if (shareResolverUsers.length !== 0) {
			try {
				await share({
					variables: {
						id: rfp._id,
						users: shareResolverUsers,
					},
				})

				// Kick off any functions that need to run after
				// a share has been successfully added
				handlePostShare()
			} catch (error) {
				if (refetchRfp) {
					refetchRfp()
				}
				openConfirmDialog({
					title: 'Please enter valid email addresses.',
					content: <InviteErrors error={error} />,
					actions: [{ label: 'Ok', close: true }],
				})
			}
		}
		// refetch invite form errors
		if (refetchErrors) {
			refetchErrors()
		}
	}

	const onAddUsers = async (people: (SearchResult | Person | string)[]) => {
		const items = mapShareItems(people)

		await handleShare(items)
	}

	const onRemoveUsers = async (userIdsToRemove: string[]) => {
		await revoke({
			variables: {
				rfpId: rfp._id,
				userIds: userIdsToRemove,
			},
		})
		// refetch invite form errors
		if (refetchErrors) {
			refetchErrors()
		}
	}

	const firmLists = (getSessionData?.getSessionInfo?.groups ?? []).reduce((acc, group) => {
		const firmLists = group?.settings?.firmLists ?? []
		return [...firmLists, ...acc]
	}, [])

	const InvitationListTabLabel = () => {
		const { isDraftRfp, usersToAdd, selectedUsers } = useInviteContext()
		const label: number = isDraftRfp ? selectedUsers.length : usersToAdd.length

		return (
			<Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
				Invitation List
				{label > 0 && (
					<>
						<Box
							sx={{
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.primary.contrastText,
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '24px',
								width: '33px',
								height: '24px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '20px',
							}}
						>
							{label}
						</Box>
						<SROnly>{` ${label === 1 ? 'contact' : 'contacts'} invited.`}</SROnly>
					</>
				)}
			</Box>
		)
	}

	const content = (
		<Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
			{isDraftRfp && (
				<Box mb={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography variant="h2XSmall" component="h1">
						Invite contacts
					</Typography>
					<NamwolfDialog />
				</Box>
			)}
			<Alert severity="info" sx={{ whiteSpace: 'pre-line' }}>
				<Typography>
					Please <b>add contacts to the Invitation List</b> before clicking <b>SEND</b>.
				</Typography>
				<Typography>Invitations will only be sent when you send the request.</Typography>
			</Alert>
			<Box sx={{ width: '100%' }}>
				<Tabs
					sx={(theme) => ({
						borderBottom: `1px solid ${theme.palette.neutral.lightHue}`,
					})}
					value={tabIndex}
					onChange={(_, newValue) => setTabIndex(newValue)}
					indicatorColor="primary"
					textColor="primary"
				>
					<Tab
						label="All contacts"
						id="invites-all-contacts"
						aria-controls="tabpanel-all-contacts"
						data-trackid="tab-invites-all-contacts"
					/>
					<Tab
						label="Firm lists"
						id="invites-firm-lists"
						aria-controls="tabpanel-firm-lists"
						data-trackid="tab-invites-firm-lists"
					/>
					<Tab
						label="Add by email"
						id="invites-add-by-email"
						aria-controls="tabpanel-add-by-email"
						data-trackid="tab-invites-add-by-email"
					/>
					<Tab
						label={<InvitationListTabLabel />}
						id="invitation-list"
						aria-controls="tabpanel-invitation-list"
						data-trackid="tab-invitation-list"
						icon={<InvitationListIcon />}
						iconPosition="start"
					/>
				</Tabs>

				{tabIndex === tabs.ALL_CONTACTS && (
					<Box role="tabpanel" id="tabpanel-all-contacts" aria-labelledby="invites-all-contacts">
						<AllContactsForm
							user={user}
							userFavourites={userFavorites}
							onTabChange={() => setTabIndex(tabs.EMAIL)}
							inviteError={inviteError}
						/>
					</Box>
				)}
				{tabIndex === tabs.FIRM_LISTS && (
					<Box role="tabpanel" id="tabpanel-firm-lists" aria-labelledby="invites-firm-lists">
						<FirmListsFormContainer inviteError={inviteError} />
					</Box>
				)}
				{tabIndex === tabs.EMAIL && (
					<Box role="tabpanel" id="tabpanel-add-by-email" aria-labelledby="invites-add-by-email">
						<InviteByEmailForm
							firmLists={firmLists}
							userFavorites={userFavorites}
							user={user}
							rfpId={rfp._id}
							handleShare={handleShare}
							inviteError={inviteError}
						/>
					</Box>
				)}
				{tabIndex === tabs.CONTACTS_ADDED && (
					<Box role="tabpanel" id="tabpanel-invitation-list" aria-labelledby="invitation-list">
						<ContactsAddedSectionTab
							setTabIndex={setTabIndex}
							inviteError={inviteError}
							tabs={tabs}
						/>
					</Box>
				)}
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" mt={3}>
				<FirmRecommenderLink
					firmRecommenderIsEnabled={isPanelsEnabled}
					message="Not sure which firms to invite?"
				/>
			</Box>
		</Box>
	)

	return (
		<InviteProvider
			onAdd={onAddUsers}
			onRemove={onRemoveUsers}
			isDraftRfp={isDraftRfp}
			selectedUsers={selectedUsers}
		>
			{!isDraftRfp ? (
				<InviteDialog
					showInviteDialog={showInviteDialog}
					toggleInviteDialog={toggleInviteDialog}
					onAddUsers={onAddUsers}
					content={content}
				/>
			) : (
				content
			)}
		</InviteProvider>
	)
}

const InviteDialog = ({ showInviteDialog, toggleInviteDialog, onAddUsers, content }) => {
	const { usersToAdd, isDraftRfp } = useInviteContext()
	const { openConfirmDialog } = useConfirmDialog()
	const { openSnackbar } = useSnackbar()

	const handleSendPublished = async () => {
		await onAddUsers(usersToAdd)
		toggleInviteDialog()
		openSnackbar(
			`${
				usersToAdd.length === 1 ? 'Invitation' : 'Invitations'
			} sent to everyone on the Invitation List`,
		)
	}
	const rows = parseRows(usersToAdd, {})

	const onSend = () => {
		const confirmDialogContent = (
			<ContactsAddedGrid rows={rows} isShoppingCartView={true} isDraftRfp={isDraftRfp} />
		)

		openConfirmDialog({
			size: 'md',
			title: 'Send Invitation(s)',
			content: confirmDialogContent,
			actions: [
				{ type: 'secondary', label: 'Cancel', close: true },
				{ label: 'Confirm', close: true, action: () => handleSendPublished() },
			],
		})
	}

	return (
		<Dialog
			open={showInviteDialog}
			title="Invite Contacts"
			size="lg"
			onClose={toggleInviteDialog}
			actions={[
				{
					label: 'Close',
					variant: 'text',
					onClick: toggleInviteDialog,
				},
				{
					label: 'Send',
					variant: 'primary',
					onClick: onSend,
					startIcon: <SendIcon />,
					disabled: usersToAdd.length === 0,
				},
			]}
		>
			{content}
		</Dialog>
	)
}
