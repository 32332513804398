import { createContext } from 'react'
import { RfpTemplateFormRoutes } from '../types'

export const RoutesContext = createContext<RfpTemplateFormRoutes>({
	alreadyPublished: {
		label: '',
		uriFn: () => '',
	},
	postPublish: {
		uriFn: () => '',
	},
	back: {
		label: '',
		uriFn: () => '',
	},
	postDelete: {
		label: '',
		uriFn: () => '',
	},
})
