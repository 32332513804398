import { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import constants from '../../../../common/data/pricing-models'
import useTranslation from '../../../custom-hooks/translation-hook'
import Spacer from '../../layout/spacer.jsx'
import { getOrEmptyArray } from '../../../utils/lenses'
import RateCardList from './rate-card-list'
import SubItemsList from './sub-items-list'
import SubItemsListCell from './sub-items-list-cell'
import SubItemsPoints from './sub-items-points'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { grey } from '@mui/material/colors'
import { Typography } from '@persuit/ui-components'

const CollapsedView = ({
	cellView,
	showTotal,
	HeaderIncluded,
	Header,
	requestedRates,
	allowFirmAddRate,
	responseToDeliverable,
	pricingPreferences,
	phaseIndex,
	currency,
	phaseDuration,
	rfpr,
	rateCardData,
	showHeader,
	detailedView,
}) => {
	const { t } = useTranslation()

	const subItemPricingLabels = {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		discountPercentage: 'Discount (%)',
		successFee: 'Success Fee',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		includedInItemTotal: 'No charge',
		afa: 'Fee Arrangements',
	}

	const hasResponseToDeliverable = !isEmpty(responseToDeliverable)
	// for displaying client requested items in request preview
	const requestItems = requestedRates.map((r) => {
		return {
			left: r.label,
			right: r && r.pricingPreferences ? subItemPricingLabels[r.pricingPreferences] : undefined,
		}
	})

	const averageRate = responseToDeliverable?.averageRate?.rate
	const responseToRates = getOrEmptyArray('responseToRates', responseToDeliverable)
	const firmSuppliedRates = getOrEmptyArray('firmSuppliedRates', responseToDeliverable)
	const willHaveSubItems = !isEmpty(responseToRates) || !isEmpty(firmSuppliedRates)

	const specialConditionToShowABorderStyle =
		pricingPreferences !== constants.RATECARD &&
		pricingPreferences !== constants.HOURLYRATE &&
		willHaveSubItems &&
		!cellView
			? { borderBottom: `1px solid ${grey[500]}`, paddingBottom: '3px' }
			: {}

	const isNormalSubItemWithCellView =
		pricingPreferences !== constants.RATECARD &&
		pricingPreferences !== constants.HOURLYRATE &&
		cellView &&
		hasResponseToDeliverable

	const isNormalSubItemWithoutCellView =
		pricingPreferences !== constants.RATECARD &&
		pricingPreferences !== constants.HOURLYRATE &&
		!cellView &&
		hasResponseToDeliverable

	const isNonRateCardWithoutResponse =
		pricingPreferences !== constants.RATECARD && !hasResponseToDeliverable && !isEmpty(requestItems)

	const isRateCardWithoutResponse =
		pricingPreferences === constants.RATECARD &&
		!hasResponseToDeliverable &&
		!isEmpty(requestedRates)

	const rateCardWithFirmResponse =
		pricingPreferences === constants.RATECARD && hasResponseToDeliverable

	const showGenericHeader = pricingPreferences !== constants.INCLUDEDINTOTAL && showHeader

	const showIncludedHeader = pricingPreferences === constants.INCLUDEDINTOTAL && showHeader

	return (
		<Fragment>
			<div data-testid="pricing-card-items" style={specialConditionToShowABorderStyle}>
				{/* Show header with total price for all pricing types except for "included in total" */}
				{showGenericHeader && Header}
				{/* Display Included translation if total pricing model is included in total */}
				{showIncludedHeader && HeaderIncluded}
				<div>
					{/* normal sub items (not rate card or hourly rate) with firm response */}
					{isNormalSubItemWithCellView && (
						<SubItemsListCell
							requestedRates={requestedRates}
							phaseIndex={phaseIndex}
							t={t}
							responseToDeliverable={responseToDeliverable}
							currency={currency}
							phaseDuration={phaseDuration}
						/>
					)}
					{isNormalSubItemWithoutCellView && (
						<SubItemsList
							data-testid="sub-items-list"
							requestedRates={requestedRates}
							phaseIndex={phaseIndex}
							t={t}
							responseToDeliverable={responseToDeliverable}
							currency={currency}
							showTotal={showTotal}
							phaseDuration={phaseDuration}
							rfpr={rfpr}
						/>
					)}
					{/* non rate card without response */}
					{/* SM: I dont know why we render rate card list for NON rate card. really confusing */}
					{isNonRateCardWithoutResponse && (
						<RateCardList items={requestItems} phaseIndex={phaseIndex} />
					)}
					{/* rate card without response */}
					{isRateCardWithoutResponse && (
						<div data-testid="rate-card-without-response">
							{!!requestedRates.length && (
								<Fragment>
									<Typography variant="caption" color="text.secondary">
										Rates requested for:
									</Typography>
									<Spacer space={1} />
									<SubItemsPoints requestedRates={requestedRates} />
								</Fragment>
							)}
							{allowFirmAddRate && (
								<Typography variant="caption" color="text.secondary">
									Additional rates such as Timekeeper name, Category can be added
								</Typography>
							)}
						</div>
					)}
					{/* Rate card with firm response */}
					{rateCardWithFirmResponse && (
						<RateCardList
							items={rateCardData}
							phaseIndex={phaseIndex}
							detailedView={detailedView}
							averageRate={averageRate}
							currency={currency}
						/>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default CollapsedView
