/**
 * This list is HTML language code, sorted by most used language
 */
export const CONTENT_LANGS = [
	{ title: 'English', code: 'en' },
	{ title: 'Chinese', code: 'zh' },
	{ title: 'Spanish', code: 'es' },
	{ title: 'French', code: 'fr' },
	{ title: 'German', code: 'de' },
	{ title: 'Hindi', code: 'hi' },
	{ title: 'Russian', code: 'ru' },
	{ title: 'Bengali (Bangla)', code: 'bn' },
	{ title: 'Portuguese', code: 'pt' },
	{ title: 'Indonesian', code: 'id' },
	{ title: 'Urdu', code: 'ur' },
	{ title: 'Japanese', code: 'ja' },
	{ title: 'Arabic', code: 'ar' },
	{ title: 'Swahili (Kiswahili)', code: 'sw' },
	{ title: 'Marathi', code: 'mr' },
	{ title: 'Telugu', code: 'te' },
	{ title: 'Turkish', code: 'tr' },
	{ title: 'Tamil', code: 'ta' },
	{ title: 'Vietnamese', code: 'vi' },
	{ title: 'Korean', code: 'ko' },
	{ title: 'Italian', code: 'it' },
	{ title: 'Polish', code: 'pl' },
	{ title: 'Ukrainian', code: 'uk' },
	{ title: 'Malay', code: 'ms' },
	{ title: 'Persian (Farsi)', code: 'fa' },
	{ title: 'Thai', code: 'th' },
	{ title: 'Romanian', code: 'ro' },
	{ title: 'Dutch', code: 'nl' },
	{ title: 'Greek', code: 'el' },
	{ title: 'Hungarian', code: 'hu' },
	{ title: 'Czech', code: 'cs' },
	{ title: 'Swedish', code: 'sv' },
	{ title: 'Finnish', code: 'fi' },
	{ title: 'Danish', code: 'da' },
	{ title: 'Hebrew', code: 'he' },
	{ title: 'Norwegian', code: 'no' },
	{ title: 'Slovak', code: 'sk' },
	{ title: 'Bulgarian', code: 'bg' },
	{ title: 'Lithuanian', code: 'lt' },
	{ title: 'Latvian (Lettish)', code: 'lv' },
	{ title: 'Slovenian', code: 'sl' },
	{ title: 'Estonian', code: 'et' },
	{ title: 'Icelandic', code: 'is' },
	{ title: 'Irish', code: 'ga' },
	{ title: 'Maltese', code: 'mt' },
	{ title: 'Luxembourgish', code: 'lb' },
	{ title: 'Macedonian', code: 'mk' },
	{ title: 'Albanian', code: 'sq' },
	{ title: 'Georgian', code: 'ka' },
	{ title: 'Armenian', code: 'hy' },
	{ title: 'Kazakh', code: 'kk' },
	{ title: 'Azerbaijani', code: 'az' },
	{ title: 'Belarusian', code: 'be' },
	{ title: 'Burmese', code: 'my' },
	{ title: 'Lao', code: 'lo' },
	{ title: 'Nepali', code: 'ne' },
	{ title: 'Tajik', code: 'tg' },
	{ title: 'Uzbek', code: 'uz' },
	{ title: 'Khmer', code: 'km' },
	{ title: 'Sinhala', code: 'si' },
	{ title: 'Tibetan', code: 'bo' },
	{ title: 'Pashto, Pushto', code: 'ps' },
	{ title: 'Kurdish', code: 'ku' },
	{ title: 'Zulu', code: 'zu' },
	{ title: 'Xhosa', code: 'xh' },
	{ title: 'Sesotho', code: 'st' },
	{ title: 'Tswana', code: 'tn' },
	{ title: 'Shona', code: 'sn' },
	{ title: 'Yoruba', code: 'yo' },
	{ title: 'Igbo', code: 'ig' },
	{ title: 'Amharic', code: 'am' },
	{ title: 'Tigrinya', code: 'ti' },
	{ title: 'Somali', code: 'so' },
	{ title: 'Kinyarwanda (Rwanda)', code: 'rw' },
	{ title: 'Kirundi', code: 'rn' },
	{ title: 'Lingala', code: 'ln' },
	{ title: 'Chichewa, Chewa, Nyanja', code: 'ny' },
	{ title: 'Akan', code: 'ak' },
	{ title: 'Twi', code: 'tw' },
	{ title: 'Ewe', code: 'ee' },
	{ title: 'Fula, Fulah, Pulaar, Pular', code: 'ff' },
	{ title: 'Hausa', code: 'ha' },
	{ title: 'Haitian Creole', code: 'ht' },
	{ title: 'Bislama', code: 'bi' },
	{ title: 'Marshallese', code: 'mh' },
	{ title: 'Nauru', code: 'na' },
	{ title: 'Samoan', code: 'sm' },
	{ title: 'Tongan', code: 'to' },
	{ title: 'Fijian', code: 'fj' },
	{ title: 'Maori', code: 'mi' },
	{ title: 'Tahitian', code: 'ty' },
	{ title: 'Chamorro', code: 'ch' },
	{ title: 'Palauan', code: 'pau' },
	{ title: 'Aymara', code: 'ay' },
	{ title: 'Guarani', code: 'gn' },
	{ title: 'Quechua', code: 'qu' },
	{ title: 'Afar', code: 'aa' },
	{ title: 'Abkhazian', code: 'ab' },
	{ title: 'Avestan', code: 'ae' },
	{ title: 'Avaric', code: 'av' },
	{ title: 'Bambara', code: 'bm' },
	{ title: 'Bashkir', code: 'ba' },
	{ title: 'Breton', code: 'br' },
	{ title: 'Chuvash', code: 'cv' },
	{ title: 'Cornish', code: 'kw' },
	{ title: 'Cree', code: 'cr' },
	{ title: 'Divehi, Dhivehi, Maldivian', code: 'dv' },
	{ title: 'Dzongkha', code: 'dz' },
	{ title: 'Esperanto', code: 'eo' },
	{ title: 'Faroese', code: 'fo' },
	{ title: 'Galician', code: 'gl' },
	{ title: 'Gaelic (Scottish)', code: 'gd' },
	{ title: 'Gaelic (Manx)', code: 'gv' },
	{ title: 'Greenlandic', code: 'kl' },
	{ title: 'Herero', code: 'hz' },
	{ title: 'Hiri Motu', code: 'ho' },
	{ title: 'Ido', code: 'io' },
	{ title: 'Inuktitut', code: 'iu' },
	{ title: 'Inupiak', code: 'ik' },
	{ title: 'Interlingua', code: 'ia' },
	{ title: 'Interlingue', code: 'ie' },
	{ title: 'Kalaallisut, Greenlandic', code: 'kl' },
	{ title: 'Kannada', code: 'kn' },
	{ title: 'Kanuri', code: 'kr' },
	{ title: 'Kwanyama', code: 'kj' },
	{ title: 'Komi', code: 'kv' },
	{ title: 'Kongo', code: 'kg' },
	{ title: 'Limburgish (Limburger)', code: 'li' },
	{ title: 'Luba-Katanga', code: 'lu' },
	{ title: 'Luganda, Ganda', code: 'lg' },
	{ title: 'Manx', code: 'gv' },
	{ title: 'Moldavian', code: 'mo' },
	{ title: 'Navajo', code: 'nv' },
	{ title: 'Ndonga', code: 'ng' },
	{ title: 'Northern Ndebele', code: 'nd' },
	{ title: 'Norwegian bokmål', code: 'nb' },
	{ title: 'Norwegian nynorsk', code: 'nn' },
	{ title: 'Nuosu', code: 'ii' },
	{ title: 'Ojibwe', code: 'oj' },
	{ title: 'Old Church Slavonic, Old Bulgarian', code: 'cu' },
	{ title: 'Oriya', code: 'or' },
	{ title: 'Ossetian', code: 'os' },
	{ title: 'Pāli', code: 'pi' },
	{ title: 'Romansh', code: 'rm' },
	{ title: 'Sami', code: 'se' },
	{ title: 'Sango', code: 'sg' },
	{ title: 'Sanskrit', code: 'sa' },
	{ title: 'Serbo-Croatian', code: 'sh' },
	{ title: 'Setswana', code: 'tn' },
	{ title: 'Sichuan Yi', code: 'ii' },
	{ title: 'Sindhi', code: 'sd' },
	{ title: 'Siswati', code: 'ss' },
	{ title: 'Southern Ndebele', code: 'nr' },
	{ title: 'Sundanese', code: 'su' },
	{ title: 'Swati', code: 'ss' },
	{ title: 'Tagalog', code: 'tl' },
	{ title: 'Tatar', code: 'tt' },
	{ title: 'Twi', code: 'tw' },
	{ title: 'Uyghur', code: 'ug' },
	{ title: 'Venda', code: 've' },
	{ title: 'Volapük', code: 'vo' },
	{ title: 'Walloon', code: 'wa' },
	{ title: 'Welsh', code: 'cy' },
	{ title: 'Western Frisian', code: 'fy' },
	{ title: 'Wolof', code: 'wo' },
	{ title: 'Yiddish', code: 'yi, ji' },
	{ title: 'Zhuang, Chuang', code: 'za' },
]
