const MailtoIcon = ({ size, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height={size}
		viewBox="0 0 24 24"
		width={size}
		// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
		fill="#000000"
		{...props}
	>
		<g>
			<rect fill="none" height="24" width="24" />
			<path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h9v-2H4V8l8,5l8-5v5h2V6C22,4.9,21.1,4,20,4z M12,11L4,6h16L12,11z M19,15l4,4 l-4,4v-3h-4v-2h4V15z" />
		</g>
	</svg>
)
MailtoIcon.defaultProps = {
	size: 24,
}

export default MailtoIcon
