import { useState } from 'react'
import moment from 'moment'

const useForceUpdate = () => {
	const [, setTime] = useState(moment())

	return () => {
		setTime(moment())
	}
}

export default useForceUpdate
