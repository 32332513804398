// eslint-disable-next-line no-restricted-imports -- Please upgrade
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'All proposals from this firm will be marked as unsuccessful.',
	'Manage notifications in the next step.',
]

function OptionEliminateFirmStart({ onNext, disableMarkFirmUnsuccessfulRadio }) {
	return (
		<DialogWizardListOption
			dataTestid="mark-firm-unsuccessful-option"
			icon={<RemoveCircleIcon />}
			primaryText="Mark firm as unsuccessful"
			bullets={bullets}
			onClick={onNext}
			disabled={disableMarkFirmUnsuccessfulRadio}
		/>
	)
}

export default OptionEliminateFirmStart
