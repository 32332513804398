import {
	PushPinIcon,
	OutlinedPushPinIcon,
	ToggleButton,
	Box,
	UnfoldLessIcon,
	UnfoldMoreIcon,
	Tooltip,
	ScreenOnly,
} from '@persuit/ui-components'

import { useStore, useActions } from '../store'

type ColumnActionsProps = {
	proposalId: string
}

export const ColumnActions = ({ proposalId }: ColumnActionsProps) => {
	const { togglePinProposal, toggleWideProposal } = useActions()
	const { isPinned, isWide, disabled } = useStore((state, s) => ({
		isPinned: s.isPinned(proposalId),
		isWide: s.isWide(proposalId),
		disabled: state.disabled,
	}))

	if (disabled) {
		return null
	}

	const pinnedLabel = isPinned ? 'Unpin proposal' : 'Pin to compare'
	const pinnedProposalDataTrackId = isPinned
		? 'iconbutton-pin-proposal'
		: 'iconbutton-unpin-proposal'
	const wideLabel = isWide ? 'Collapse this proposal' : 'Expand this proposal'
	const expandProposalDataTrackId = isWide
		? 'iconbutton-collapse-proposal'
		: 'iconbutton-expand-proposal'

	return (
		<ScreenOnly>
			<Box
				data-testid={`pinning-widening`}
				id={`${proposalId}-column-actions`}
				className={`${isPinned ? 'pinned' : ''}`}
			>
				<Tooltip title={pinnedLabel}>
					<ToggleButton
						value={pinnedLabel}
						data-trackid={pinnedProposalDataTrackId}
						selected={isPinned}
						aria-label={pinnedLabel}
						size="small"
						sx={{
							border: 'none',
							color: 'primary.main',
							padding: '2px',
							'&.Mui-selected': {
								color: 'primary.main', // Otherwise MUI overrides the sx colour
							},
						}}
						onClick={() => togglePinProposal(proposalId)}
					>
						{isPinned ? <PushPinIcon /> : <OutlinedPushPinIcon />}
					</ToggleButton>
				</Tooltip>

				<Tooltip title={wideLabel}>
					<ToggleButton
						value={wideLabel}
						data-trackid={expandProposalDataTrackId}
						selected={isWide}
						aria-label={wideLabel}
						size="small"
						sx={{
							border: 'none',
							marginLeft: '4px',
							transform: 'rotate(90deg)',
							color: 'primary.main',
							padding: '2px',
						}}
						onClick={() => toggleWideProposal(proposalId)}
					>
						{isWide ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
					</ToggleButton>
				</Tooltip>
			</Box>
		</ScreenOnly>
	)
}
