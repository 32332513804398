import numeral from 'numeral'
import isEmpty from 'lodash/isEmpty'

const numberFormat = '0.0'

/*
 * Calculates the total score for a category
 *
 * Returns the average score accross subcategory
 *
 * Takes a category containing subcategory scores
 * eg.
 *		_id: 1,
 *		label: 'Knowledge',
 *		weight: 30,
 *		subcategories: [
 *			{
 *				_id: 11,
 *				label: 'Understanding of GSK',
 *			},
 *			{
 *				_id: 12,
 *				label: 'Subject Areas expertise',
 *			},
 *			{
 *				_id: 13,
 *				label: 'Continued involvement',
 *			},
 *		],
 *
 *
 * Ignores any subcategory without a score when calculating the average
 * For example scores [2,4,null] will return an average of (2 + 4) / 2 === 3
 *
 * If there are no scores for the subcategories eg. [null, null, null] it returns null;
 */
function calculateCategoryScore(category) {
	if (!category || !category.subcategories) {
		return null
	}

	// Count of subcategories not including those without a score
	const scoredSubcategoryCount = category.subcategories
		// .map(subcategoryId => categoryScores[subcategoryId])
		.reduce((memo, subcategory) => {
			if (subcategory.score) {
				memo++
			}

			return memo
		}, 0)

	// Early return if no subcategories have scores
	if (scoredSubcategoryCount === 0) {
		return null
	}

	// Calculate the total score accross all scored categories
	const categoryTotal = category.subcategories.reduce((memo, subcategory) => {
		const score = subcategory.score

		if (score) {
			memo += score
		}

		return memo
	}, 0)

	const score = categoryTotal / scoredSubcategoryCount

	const formattedScore = numeral(score).format(numberFormat)

	return formattedScore
}

// Takes an array of weighted score categories and totals up the weights
function calculateTotalOfWeights(scoreCategories) {
	if (!scoreCategories) {
		return 0
	}

	return scoreCategories
		.map((category) => category.weight)
		.reduce((memo, weight) => {
			if (weight) {
				memo += weight
			}

			return memo
		}, 0)
}

// Returns total weights of each category that has scores
// Any category for which there are no scores is excluded from the calculation
function calculateTotalWeightsOfScoredCategories(scorecard, scoreCategories) {
	return scorecard.reduce((memo, category) => {
		const categoryScore = calculateCategoryScore(category)

		if (categoryScore && scoreCategories) {
			const c = scoreCategories.find(({ _id }) => _id.toString() === category.categoryId.toString())

			if (c && c.weight) {
				memo += c.weight
			}
		}

		return memo
	}, 0)
}

// Takes the scorecard values and the categories and returns the total weighted score
function calculateTotalWeightedScore(scorecard, scoreCategories) {
	if (!scorecard) {
		return null
	}

	const totalCategoryScores = scorecard.reduce((memo, category) => {
		const categoryScore = calculateCategoryScore(category)

		// Only add the score if it's not null
		if (categoryScore !== null) {
			memo[category.categoryId.toString()] = categoryScore
		}

		return memo
	}, {})

	// Early return if there are no scores at all
	if (isEmpty(totalCategoryScores)) {
		return null
	}

	const totalWeights = calculateTotalOfWeights(scoreCategories)

	// Unscored categories need to have their corresponding
	// category weighting excluded from the final calculation
	const totalWeightsOfScoredCategories = calculateTotalWeightsOfScoredCategories(
		scorecard,
		scoreCategories,
	)

	// Early return if the total weights is 0
	if (totalWeightsOfScoredCategories === 0) {
		return null
	}

	// Get the score for each category, take category weighting into account and sum them all
	const weightedScore = Object.keys(totalCategoryScores)
		.map((categoryId) => {
			const score = totalCategoryScores[categoryId.toString()]

			const category = scoreCategories.find(({ _id }) => _id.toString() === categoryId.toString())

			if (!category || !category.weight) {
				return 0
			}

			return score * (category.weight / totalWeights)
		})
		.reduce((memo, score) => {
			if (score) {
				memo += score
			}

			return memo
		}, 0)

	// Final calculation
	const totalWeightedScore = weightedScore * (totalWeights / totalWeightsOfScoredCategories)

	// Formatting with one decimal points as needed
	// Eg 3.00 becomes 3
	// Eg. 3.33333 becomes 3.3
	const formattedTotalWeightedScore = numeral(totalWeightedScore).format(numberFormat)

	return formattedTotalWeightedScore
}

// Used to generate the values for the proposal score form
// Takes the scorecard values and the categories themselves
// Returns a value for each subcategory in each category of the `scoreCategories`
// The value will either be the real value from the `scorecard` or null
function generateInitialFormValues(scorecard, scoreCategories) {
	return scoreCategories.map((cat) => {
		const rawScores = scorecard
			? scorecard.find((p) => p.categoryId.toString() === cat._id.toString())
			: null

		// Cloning to a new object otherwise errors are thrown for attempting to
		// write to a read-only object
		const scores = rawScores ? { ...rawScores } : null

		// If there is no score for this category then create a new category score object
		if (!scores) {
			return {
				categoryId: cat._id.toString(),
				subcategories: cat.subcategories.map((subcat) => {
					return {
						subcategoryId: subcat._id.toString(),
						score: null,
					}
				}),
			}
		}

		const scoreCategory = scoreCategories.find(
			({ _id }) => _id.toString() === scores.categoryId.toString(),
		)

		if (scoreCategory && scoreCategory.subcategories) {
			scores.subcategories = scoreCategory.subcategories.map((subcategory) => {
				const scoreSubcategory = scores.subcategories.find(({ subcategoryId }) => {
					return subcategoryId.toString() === subcategory._id.toString()
				})

				// If there is no score for this subcategory then create a new sub category score object
				if (!scoreSubcategory) {
					return {
						subcategoryId: subcategory._id.toString(),
						score: null,
					}
				}

				return scoreSubcategory
			})
		}

		return scores
	})
}

// Takes a weight and the total weight
// Returns out what that weight is as a percentage of the total
function calculateCategoryWeightAsPercentage(weight, totalWeight) {
	if (!weight) {
		weight = 0
	}

	const weightPercentage = weight / totalWeight

	return weightPercentage ? numeral(weightPercentage * 100).format('0') : '0'
}

// Get the total weight of all categories
// Eg. Cat 1 with weight of 5 and Cat 2 with weight of 10 will return 15
function calculateTotalCategoryWeight(scoreCategories) {
	if (!scoreCategories) {
		return 0
	}

	return scoreCategories.reduce((memo, category) => {
		const categoryWeight = category.weight

		if (categoryWeight && categoryWeight > 0) {
			memo += parseInt(categoryWeight, 10)
		}

		return memo
	}, 0)
}

export {
	calculateCategoryScore,
	calculateTotalWeightedScore,
	calculateCategoryWeightAsPercentage,
	calculateTotalCategoryWeight,
	generateInitialFormValues,
}
