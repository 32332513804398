import {
	MenuItem,
	ListItemIcon,
	ListItemText,
	PublishIcon,
	MenuItemProps,
} from '@persuit/ui-components'

type PublishMenuItemProps = {
	handlePublish: () => void
	handleMenuClose: () => void
} & MenuItemProps

export const PublishMenuItem = ({
	handlePublish,
	handleMenuClose,
	...props
}: PublishMenuItemProps) => {
	const handleClick = () => {
		handleMenuClose()
		handlePublish()
	}

	return (
		<MenuItem {...props} onClick={handleClick} aria-label="Publish Template">
			<ListItemIcon>
				<PublishIcon color="primary" fontSize="small" />
			</ListItemIcon>
			<ListItemText primary="PUBLISH" primaryTypographyProps={{ color: 'primary' }} />
		</MenuItem>
	)
}
