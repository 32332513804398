import { Fragment, useId } from 'react'
import { RichTextView, Spacer, Typography, Box } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import isEmpty from 'lodash/fp/isEmpty'
import { DeepPick } from '@persuit/common-utils'
import { HeadlineSeparator } from '../headline-separator'
import { Rfp } from '../../../types'

export type RfpDetailRatesSectionDocType = DeepPick<Rfp, 'rateReview' | 'useCase'>

type RfpDetailRatesSectionProps = {
	document: RfpDetailRatesSectionDocType
}

export const RfpDetailRatesSection = ({ document }: RfpDetailRatesSectionProps) => {
	const { toggles, loading } = useFeatureToggles()

	const rateReview = document?.rateReview ?? null
	const requestNamedTimekeepers = rateReview?.requestNamedTimekeepers
	const namedTimekeepersSettings = rateReview?.namedTimekeepersSettings
	const detailsAndAssumptions = rateReview?.details ?? ''
	const timekeepers = rateReview?.timekeepers ?? []
	const timekeepersId = useId()
	const timekeepersFieldsId = useId()
	const namedTimekeepersFieldsId = useId()
	const useCase = document?.useCase

	if (isEmpty(rateReview)) return null

	if (useCase !== 'rateReview') return null

	if (loading) return null

	return (
		<Fragment>
			<HeadlineSeparator headline="Rates" />

			{!isEmpty(detailsAndAssumptions) && (
				<Fragment>
					<RichTextView content={detailsAndAssumptions} />
					<Spacer space={3} shape="row" />
				</Fragment>
			)}

			<Box sx={{ color: 'text.secondary', ml: 3, fontStyle: 'italic' }}>
				<Typography id={timekeepersId}>
					Rate card rates have been requested for these timekeeper levels:
				</Typography>

				<ol aria-labelledby={timekeepersId}>
					{timekeepers.map((timekeeper, i) => (
						<li key={i}>{timekeeper.timekeeperLabel}</li>
					))}
				</ol>

				<Spacer space={3} shape="row" />

				<Typography id={timekeepersFieldsId}>
					For each of the above timekeeper levels you have been asked to provide:
				</Typography>

				<ul aria-labelledby={timekeepersFieldsId}>
					<li>Last year's hours (If applicable)</li>
					<li>Current rate (If applicable)</li>
					<li>New standard rate</li>
					<li>Proposed rate</li>
				</ul>

				{requestNamedTimekeepers && toggles['dev-10144.named-timekeepers'] && (
					<>
						<Typography id={namedTimekeepersFieldsId}>
							Individual timekeepers can be provided for the timekeeper levels. For each of the
							individual timekeepers you have been asked to provide:
						</Typography>
						<Typography>
							<ul aria-labelledby={namedTimekeepersFieldsId}>
								<li>
									Individual timekeeper details{' '}
									{namedTimekeepersSettings?.requestGraduationYear === 'required' &&
									namedTimekeepersSettings?.requestBillingId === 'required'
										? null
										: '(If applicable)'}
								</li>
								<li>Last year's hours (If applicable)</li>
								<li>Current rate (If applicable)</li>
								<li>New standard rate</li>
								<li>Proposed rate</li>
							</ul>
						</Typography>
					</>
				)}
			</Box>
		</Fragment>
	)
}
