import capitalize from 'lodash/fp/capitalize'

type GetCardTitleParams = {
	sectionName: string
	titleOverride?: string
}

export function getCardTitle({ sectionName, titleOverride }: GetCardTitleParams): string {
	if (titleOverride) return titleOverride
	return capitalize(sectionName)
}
