import { CURRENCIES, CurrencyCodes } from '../currencies'

const TOTAL_CURRENCIES = Object.keys(CURRENCIES).length

const isValidCurrency = (currency: string): currency is CurrencyCodes => currency in CURRENCIES

export const getMultiCurrencyNames = (currencies: string[]): 'Any currencies' | string => {
	const isAnyInvalidCurrency = currencies.some((currency) => !isValidCurrency(currency))

	if (isAnyInvalidCurrency) {
		throw new Error(`Invalid currency, ${JSON.stringify(currencies)}`)
	}

	if (currencies.length === TOTAL_CURRENCIES) {
		return 'Any currencies'
	}

	if (currencies.length <= 4) {
		return currencies.join(', ')
	}

	const first4Currencies = currencies.slice(0, 4).join(', ')
	const remainingCurrencies = `+${currencies.slice(4).length} more`

	return `${first4Currencies} ${remainingCurrencies}`
}

export const isAnyCurrencies = (value: string): value is 'Any currencies' => {
	return value === 'Any currencies'
}
