import { useState } from 'react'
import { UseCaseLabel } from '../../use-case'
import { ChangeUseCaseDialog } from './change-use-case-dialog'
import { Box, Button } from '@persuit/ui-components'
import { UseCase } from '@persuit/ui-graphql/generated'

type UseCaseFormProps = {
	changeUseCase?: (useCase: UseCase) => Promise<void>
	useCase: UseCase | null
	useSingleCurrency?: boolean
}

export const UseCaseForm = ({ changeUseCase, useCase, useSingleCurrency }: UseCaseFormProps) => {
	const [changeUseCaseDialogOpen, setChangeUseCaseDialogOpen] = useState(!useCase ? true : false)

	const saveUseCase = (useCase: UseCase) => {
		if (changeUseCase) changeUseCase(useCase)
		setChangeUseCaseDialogOpen(false)
	}

	const goBackToUseCaseSelection = () => {
		setChangeUseCaseDialogOpen(true)
	}

	return (
		<>
			{useCase && (
				<Box display={'flex'} justifyContent="space-between" gap={1} flexWrap="wrap">
					<UseCaseLabel useCase={useCase}></UseCaseLabel>
					{changeUseCase && (
						<Button variant="outlined" onClick={() => setChangeUseCaseDialogOpen(true)}>
							Change
						</Button>
					)}
				</Box>
			)}

			<ChangeUseCaseDialog
				saveUseCase={saveUseCase}
				useCase={useCase}
				forceClose={() => setChangeUseCaseDialogOpen(false)}
				onClose={() => {
					useCase ? setChangeUseCaseDialogOpen(false) : null
				}}
				goBackToUseCaseSelection={goBackToUseCaseSelection}
				open={changeUseCaseDialogOpen}
				useSingleCurrency={useSingleCurrency}
			/>
		</>
	)
}
