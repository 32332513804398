export const scrollToElementWithId = (id: string | undefined | null) => {
	if (!id) {
		return
	}

	const el = document.getElementById(id)

	if (!el) {
		return
	}

	el?.focus()
	el?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
}
