import { Fragment } from 'react'
import { Typography, Paper, Box, Chip } from '@persuit/ui-components'
import { MessageList } from '../panes/channel-pane/message-list'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'
import { getChannelName } from '../util'
import { ChannelAvatar } from '../panes/channel-pane/channel-avatar'

const MESSAGE_EXPORT_CHANNEL_FRAGMENT = graphql(`
	fragment MessageExportChannelChannel on Channel {
		id
		type
		orgs {
			firm {
				id
				name
			}
			client {
				id
				name
			}
		}
		members {
			userId
			name {
				first
				last
			}
		}
		...MessagesChannelPaneMessageListChannel
	}
`)

const MESSAGE_EXPORT_SESSION_FRAGMENT = graphql(`
	fragment MessageExportChannelSession on SessionInfo {
		isImpersonated
		user {
			org {
				_id
			}
		}
		...MessagesChannelPaneMessageListSession
	}
`)

export type ChannelProps = {
	channel: FragmentType<typeof MESSAGE_EXPORT_CHANNEL_FRAGMENT>
	session: FragmentType<typeof MESSAGE_EXPORT_SESSION_FRAGMENT>
	showSystemMessages: boolean
	showChannelMembers: boolean
}

export const Channel = ({
	channel,
	session,
	showSystemMessages,
	showChannelMembers,
}: ChannelProps) => {
	const channelData = getFragment(MESSAGE_EXPORT_CHANNEL_FRAGMENT, channel)
	const { type, orgs, members } = channelData
	const sessionData = getFragment(MESSAGE_EXPORT_SESSION_FRAGMENT, session)
	const { user } = sessionData

	const currentOrgId = user?.org?._id ?? ''

	const channelName = getChannelName(type, orgs, currentOrgId, 'Channel messages')
	const channelTypeName =
		type === 'CLIENT_TO_FIRM_CHANNEL' ? 'EXTERNAL CHANNEL' : 'INTERNAL CHANNEL'

	return (
		<Paper elevation={3}>
			<Box p={2} width="100%" display="flex" justifyContent="space-between">
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<ChannelAvatar channelName={channelName} channelType={type} />
					<Box display="flex" flexDirection="column">
						<Typography variant="caption">{channelTypeName}</Typography>
						<Typography variant="body1Semibold">{channelName}</Typography>
					</Box>
				</Box>

				{showChannelMembers && (
					<Box width="50%" textAlign="right">
						<Typography variant="subtitle2" gutterBottom={true}>
							All members
						</Typography>

						<Box display="flex" flexWrap="wrap" justifyContent="flex-end" gap={1}>
							{members.map((member, index) => {
								if (!member) return null
								const firstName = member?.name?.first ?? ''
								const lastName = member?.name?.last ?? ''
								const name = `${firstName} ${lastName}`

								const isLastUserInList = index === members.length - 1

								return (
									<Fragment key={member.userId}>
										<Box display="block" displayPrint="none">
											<Chip label={name} />
										</Box>

										<Box display="none" displayPrint="block">
											<span>{name}</span>
											{!isLastUserInList && <span>,&nbsp;</span>}
										</Box>
									</Fragment>
								)
							})}
						</Box>
					</Box>
				)}
			</Box>

			<MessageList
				channel={channelData}
				session={sessionData}
				showSystemMessages={showSystemMessages}
			/>
		</Paper>
	)
}
