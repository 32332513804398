// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { styled } from '@persuit/ui-components'
import { participateEventReasonsText } from '../../../../common/data/rfp-invite-events'
import { grey200, grey700 } from '../../../theme/persuit-colors'
import { getOrEmptyArray, getOrEmptyString } from '../../../utils/lenses'
import Spacer from '../../layout/spacer.jsx'
import Text from '../../text.jsx'

const DetailLayout = styled('div')`
	box-sizing: border-box;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: ${grey200};
	padding: 16px;
	border-radius: 4px;
`

const HeaderContainer = styled('div')`
	display: flex;
	gap: 12px;
	align-items: center;
	color: ${grey700};
`

const NotParticipatingOutput = ({ participateState }) => {
	const participateEnumReasons = getOrEmptyArray('enumReasons', participateState)
	const participateCustomReason = getOrEmptyString('customReason', participateState)

	return (
		<DetailLayout>
			<HeaderContainer>
				<RemoveCircleIcon />
				<Typography variant="body1" color="text.primary">
					Unable to Participate
				</Typography>
			</HeaderContainer>
			<div>
				<Spacer space={1} />
				<Text>Firm has chosen not to participate in this Request.</Text>
				<Spacer space={3} />
				<Text>Reason(s)</Text>
				<ul>
					{participateEnumReasons.map((reason) => (
						<li key={reason}>
							<Text>{participateEventReasonsText[reason]}</Text>
						</li>
					))}
					{Boolean(participateCustomReason) && (
						<li>
							<Text>{participateCustomReason}</Text>
						</li>
					)}
				</ul>
			</div>
		</DetailLayout>
	)
}

export default NotParticipatingOutput
