// @ts-strict-ignore
import { Button, Dialog, Typography } from '@persuit/ui-components'
import ProposalFeedbackDisplay from '../../display'
import addFeedbackToProposals from '../utils/add-feedback-to-proposals'
import ConfirmButton from '../confirm-button'
import { FormState } from '../..'

type ProposalFeedbackPreviewDialogProps = {
	open: boolean
	onClose: () => void
	handleClose: () => void
	formState: FormState
	rfpId: string
	fetchResponses: () => Promise<unknown>
	firmsAndProposals: any[]
}

export const ProposalFeedbackPreviewDialog = ({
	open,
	onClose,
	firmsAndProposals,
	formState,
	rfpId,
	handleClose,
	fetchResponses,
}: ProposalFeedbackPreviewDialogProps) => {
	const orgFeedbackArray = addFeedbackToProposals(firmsAndProposals, formState)

	const actions = (
		<>
			<Button onClick={onClose}>Back</Button>
			<ConfirmButton
				rfpId={rfpId}
				handleClose={handleClose}
				formState={formState}
				fetchResponses={fetchResponses}
			/>
		</>
	)

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Your Firms Want Your Feedback"
			size="md"
			actions={actions}
		>
			<Typography>This is what your firm(s) will see when they recieve your feedback.</Typography>
			<Typography mb={1}>
				Firms will be notified through an email as well as through PERSUIT.
			</Typography>
			{orgFeedbackArray.map((f) => (
				<ProposalFeedbackDisplay
					key={f.orgId}
					orgName={f.orgName}
					feedback={f.feedback}
					expanded={true}
					showProposalDetails={true}
				/>
			))}
		</Dialog>
	)
}
