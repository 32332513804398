import * as React from 'react'

import { ButtonProps as MuiButtonProps, ButtonTypeMap } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { DataAttributes } from '../../utilities'

type CustomVariant = 'primary' | 'secondary' | 'text' | 'contained' | 'outlined'

export type ButtonProps<
	D extends React.ElementType = ButtonTypeMap['defaultComponent'],
	// eslint-disable-next-line @typescript-eslint/ban-types
	P = {
		loading?: boolean
		destructive?: boolean
	},
> = DataAttributes<MuiButtonProps<D, P>>

const variantMap: Record<CustomVariant, ButtonProps['variant']> = {
	primary: 'contained',
	secondary: 'outlined',
	//existing variants
	text: 'text',
	contained: 'contained',
	outlined: 'outlined',
}

type ButtonComponentType = {
	<
		D extends React.ElementType = ButtonTypeMap['defaultComponent'],
		// eslint-disable-next-line @typescript-eslint/ban-types
		P = {},
	>(
		props: ButtonProps<D, P>,
	): JSX.Element
	displayName: string
}

export const Button = React.forwardRef(
	<C extends React.ElementType>(props: ButtonProps<C>, ref?: React.Ref<HTMLButtonElement>) => {
		const { variant, endIcon, startIcon, loading, destructive, ...rest } = props
		//map custom variants
		const muiVariant: MuiButtonProps['variant'] = variant ? variantMap[variant] : variant
		//set loading icon position based on icon position
		const loadingPosition = startIcon ? 'start' : endIcon ? 'end' : 'center'
		return (
			<LoadingButton
				ref={ref}
				loading={loading}
				loadingPosition={loadingPosition}
				startIcon={startIcon}
				endIcon={endIcon}
				variant={muiVariant}
				className={[
					destructive ? 'Button-destructive' : '',
					startIcon || endIcon ? 'Button-icon' : '',
				].join(' ')}
				{...rest}
			/>
		)
	},
) as ButtonComponentType

Button.displayName = 'Button'
