// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box, Typography } from '@mui/material'

export const GridCustomNoResultsOverlay = () => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}>
			<Typography variant="body2Semibold">Sorry, no results found.</Typography>
			<Typography variant="body2">
				Try searching for a specific keyword within the firm title.
			</Typography>
		</Box>
	)
}
