type PendoInitializeInput = {
	visitorUniqueId: string
	accountUniqueId: string
	email: string
	orgType: string
	roleWithinOrganization: string
	geoData?: Record<string, unknown>
	orgId: string
	orgName: string
}

function initialize({
	visitorUniqueId,
	accountUniqueId,
	email,
	orgType = 'UNKNOWN',
	roleWithinOrganization,
	geoData = {},
	orgId,
	orgName,
}: PendoInitializeInput) {
	try {
		const shouldDisablePendo = localStorage.getItem('pendo-mode') === 'disabled'
		const useAnonymousPendoUser = localStorage.getItem('pendo-mode') === 'anonymous'

		if (!window.pendo || shouldDisablePendo) {
			console.info('SKIPPED PENDO initialization')
			return
		}

		if (useAnonymousPendoUser) {
			window.pendo.initialize({
				visitor: {
					orgType,
				},
			})
			console.info('PENDO initialized - Anonymous user')
			return
		}

		window.pendo.initialize({
			visitor: {
				id: visitorUniqueId, // Required if user is logged in
				email, // Recommended if using Pendo Feedback, or NPS Email
				// full_name:    // Recommended if using Pendo Feedback
				// role:         // Optional
				// You can add any additional visitor level key-values here,
				// as long as it's not one of the above reserved names.
				orgId,
				orgName,
				orgType,
				roleWithinOrganization,
				...geoData,
			},

			account: {
				id: accountUniqueId, // Highly recommended
				// name:         // Optional
				// is_paying:    // Recommended if using Pendo Feedback
				// monthly_value:// Recommended if using Pendo Feedback
				// planLevel:    // Optional
				// planPrice:    // Optional
				// creationDate: // Optional

				// You can add any additional account level key-values here,
				// as long as it's not one of the above reserved names.
			},
		})
		console.info('PENDO initialized')
	} catch (error) {
		console.info('Failed to initialize the PENDO analytics')
	}
}

export const pendo = {
	initialize,
}
