import { memo } from 'react'
import {
	Card,
	Box,
	DragHandler,
	ListIcon,
	MoreVertIcon,
	IconButton,
	TopLeftErrorOutlineIcon,
	TextField,
} from '@persuit/ui-components'
import { PricingListContextProviderProps } from '../../pricing-list-context'

type PricingGroupOverlayProps = {
	id: string
	title: string
	errors: PricingListContextProviderProps['errors']
	groupIndex: number
}

export const PricingGroupOverlay = memo(
	({ id, title, groupIndex, errors }: PricingGroupOverlayProps) => {
		const pricingGroupError = errors?.pricingGroupErrors?.find(
			(error) => error.index === groupIndex,
		)

		return (
			<Box mb={2}>
				{pricingGroupError && <TopLeftErrorOutlineIcon />}
				<Card>
					<Box p="1rem">
						<Box display="flex" alignItems={'center'}>
							<DragHandler />
							<Box ml=".5rem" display="flex" alignItems={'center'}>
								<ListIcon />
							</Box>
							<Box ml="1rem" flexGrow={1}>
								<TextField
									fullWidth={true}
									label="Group title"
									error={!!pricingGroupError?.title}
									helperText={pricingGroupError?.title}
									id={`pricingGroups-overlay-${id}-title`}
									placeholder="Group title"
									value={title}
								/>
							</Box>
							<Box display="flex" alignItems={'center'}>
								<IconButton color="primary" size="large">
									<MoreVertIcon />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</Card>
			</Box>
		)
	},
	(prevProps, nextProps) => prevProps.id !== nextProps.id,
)

PricingGroupOverlay.displayName = 'PricingGroupOverlay'
