import { Box, ProposalEditIcon, Button } from '@persuit/ui-components'
import { reviseProposal as canReviseProposal } from '../../../../../../common/auth/policies/rfp-proposal'
import requestStates from '../../../../../../common/data/rfp-states'
import getReopenedStatusForInvite from '../../../../rfp/get-reopened-status-for-invite'
import { useSelector } from '@client/store'
import { useReviseProposal } from '../../../../../custom-hooks/firm/use-revise-proposal'

export const ReviseProposalButton = ({ request, requestObject, proposal }) => {
	const { handleReviseProposal } = useReviseProposal({
		proposal,
		request,
		requestObjectRefetch: requestObject.refetch,
	})

	const allowReviseProposal = useSelector((state) => {
		const {
			auth: { user, scope },
		} = state

		return canReviseProposal(user, scope, proposal)
	})

	if (proposal.isEliminated) return null

	const { status: requestStatus } = request
	const firmId = proposal?.org?._id

	const requestIsFinalized = requestStatus === requestStates.FINALIZED
	const isRfpReopenedForFirm = !!firmId && getReopenedStatusForInvite(request, firmId)
	const isRequestOpen = requestIsFinalized || isRfpReopenedForFirm

	// Show revise proposal only if the user has access and the request is open
	const showReviseProposal = allowReviseProposal && isRequestOpen

	// Early return (don't show the button) if revise proposal is not permitted
	if (!showReviseProposal) {
		return null
	}

	return (
		<Box marginTop="2" display="flex" justifyContent="center">
			<Button onClick={handleReviseProposal} startIcon={<ProposalEditIcon />} variant="contained">
				Revise
			</Button>
		</Box>
	)
}
