import { SvgIcon, SvgIconProps } from '../components/svg'

export const ChartBarIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.875 6.25H5.16346V17.25H1.875V6.25ZM1.875 0.75H5.16346V5.15H1.875V0.75ZM12.8365 12.85H16.125V17.25H12.8365V12.85ZM12.8365 9.55H16.125V11.75H12.8365V9.55ZM7.35577 9.55H10.6442V17.25H7.35577V9.55ZM7.35577 5.15H10.6442V8.45H7.35577V5.15Z"
				fill="currentColor"
			/>
		</SvgIcon>
	)
}
