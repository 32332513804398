import { Tooltip, useTheme } from '@persuit/ui-components'

type PanelPreferredFirmLogoProps = {
	disabled?: boolean
}

export const PanelPreferredFirmLogo = ({ disabled = false }: PanelPreferredFirmLogoProps) => {
	const theme = useTheme()

	return (
		<Tooltip title="Panel / Preferred">
			<svg
				width={16}
				height={16}
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg"
				style={{ opacity: disabled ? 0.5 : 1, borderRadius: theme.spacing(0.25) }}
			>
				<rect
					x={0}
					width={16}
					height={16}
					rx={4}
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					fill="rgb(83, 83, 89)"
				/>
				<text
					aria-hidden={true}
					x={8.25}
					y={8.8}
					fill="white"
					textAnchor="middle"
					dominantBaseline="middle"
					style={{
						fontSize: 12,
						fontWeight: 500,
					}}
				>
					P
				</text>
			</svg>
		</Tooltip>
	)
}
