// @ts-strict-ignore
import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	ListIcon,
	styled,
	Typography,
	Button,
} from '@persuit/ui-components'
import { isNil, size, sortBy, isEmpty } from 'lodash/fp'
import { useState } from 'react'
import * as React from 'react'
import useTranslation from '../../../custom-hooks/translation-hook'
import formatPriceCurrency from '../../../utils/price-and-currency'
import {
	areHoursEqual,
	arePriceModelEqual,
	arePricesEqual,
	areValuesEqual,
} from '../suggestion-change-detection'
import pricingModels, { clientPhaseModel } from '../../../../common/data/pricing-models'
import { OriginalOrSuggestedText } from './original-or-suggested-text'
import RowContents, { StyledTableRow } from './row-contents'
import { scrollToElementWithId } from '@persuit/ui-utils'
import { AssumptionDialog } from './assumption-dialog'
import { RfprDeliverable } from '@persuit/ui-graphql/generated'

//TODO: When current and suggested total price are same and when
// suggested pricing item values are same as current then the default
// suggested table is not shown. Ideal solution would be to fix the reducer to
// not show store any values when the values are same

const StyledTableContainer = styled(TableContainer)`
	&.MuiTableContainer-root {
		width: auto;
		border: 1px solid #c3c3c3;
		padding: 1em;
	}

	& td {
		border-top: 1px solid ${({ theme }) => theme.palette.divider};
		border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
	}

	& th {
		border-top: none;
		border-bottom: none;
	}
`

type EllipsisProps = {
	maxWidth: string | number
}

const Ellipsis = styled('div')<EllipsisProps>`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
	& > * {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
	}
`

const getPriceText = (currency, price) => {
	if (!isNil(price)) {
		const formattedPrice = formatPriceCurrency(currency, price)
		return formattedPrice
	}

	return ''
}

const getPricingModelSelectValue = (pricingItemModel) => {
	const [pricingModelSelectValue] = [pricingItemModel, clientPhaseModel.NONE].filter(
		(value) => value !== '',
	)

	return pricingModelSelectValue
}

type RowContentsForPercentagePricingItemsProps = {
	indent?: boolean
	deliverableId?: string
	deliverableNumber: number
	deliverableTitle: string
	percentage: number
	rfprPercentage: number
	pricingModel: string
	suggestedCommentButton: React.ReactNode
}

const RowContentsForPercentagePricingItems = ({
	deliverableId,
	deliverableNumber,
	deliverableTitle,
	percentage,
	rfprPercentage,
	pricingModel,
	indent,
	suggestedCommentButton,
}: RowContentsForPercentagePricingItemsProps) => {
	const { t } = useTranslation()
	const isPricingItemPercentageSame = areValuesEqual(percentage, rfprPercentage)

	const percentageText = !isNil(percentage) ? `${percentage}%` : ''
	const rowTitle = `${deliverableNumber}. ${deliverableTitle}`

	return (
		<RowContents
			indent={indent}
			interactive={true}
			key={deliverableId}
			scrollTargetId={deliverableId}
			label={rowTitle}
			cell1={<Ellipsis maxWidth="360px">{rowTitle}</Ellipsis>}
			cell2={
				<OriginalOrSuggestedText
					dataTestid="suggested-item-price"
					isTextSame={isPricingItemPercentageSame}
					currentText={percentageText}
					suggestedText={`${rfprPercentage}%`}
				/>
			}
			cell3={
				<Ellipsis maxWidth="100px">
					<Typography data-testid="current-percentage-item-model" variant="body1">
						{t(`feeBreakdown.pricingModels.item.${getPricingModelSelectValue(pricingModel)}`)}
					</Typography>
				</Ellipsis>
			}
			cell4={null}
			cell5={<OriginalOrSuggestedText suggestedText={suggestedCommentButton} />}
		/>
	)
}

type RowContentsForRateCardProps = {
	indent?: boolean
	deliverableId?: string
	deliverableNumber: number
	deliverableTitle: string
	rfprRates: any[]
	renderAssumptionsDialog?: (...args: any[]) => React.ReactNode
	currency: string
	suggestedCommentButton: React.ReactNode
}

const RowContentsForRateCard = ({
	indent,
	deliverableId,
	deliverableNumber,
	deliverableTitle,
	rfprRates,
	currency,
	suggestedCommentButton,
}: RowContentsForRateCardProps) => {
	const sortedRfprRates = sortBy(['rateItemNumber'], rfprRates)
	const rowTitle = `${deliverableNumber}. ${deliverableTitle}`

	return (
		<React.Fragment>
			<RowContents
				indent={indent}
				interactive={true}
				key={deliverableId}
				scrollTargetId={deliverableId}
				label={rowTitle}
				cell1={<Ellipsis maxWidth="360px">{rowTitle}</Ellipsis>}
				cell2={null}
				cell3={
					<Ellipsis maxWidth="100px">
						<OriginalOrSuggestedText
							dataTestid="suggested-item-model"
							isTextSame={false}
							currentText={'Rate Card'}
							suggestedText={''}
						/>
					</Ellipsis>
				}
				cell4={null}
				cell5={<OriginalOrSuggestedText suggestedText={suggestedCommentButton} />}
			/>
			{sortedRfprRates.map(
				({ label, rateId, rate, rfprRate, firmSuppliedRateId, rateItemNumber }) => {
					const isRateAndRfprRateSame = arePricesEqual(rate, rfprRate)
					const title = `${deliverableNumber}.${rateItemNumber}. ${label}`
					return (
						<RowContents
							indent={indent}
							interactive={true}
							key={rateId || firmSuppliedRateId}
							scrollTargetId={deliverableId}
							label={title}
							cell1={
								<Box pl="1em">
									<Ellipsis maxWidth="360px">{title}</Ellipsis>
								</Box>
							}
							cell2={
								<OriginalOrSuggestedText
									dataTestid="suggested-item-price"
									isTextSame={isRateAndRfprRateSame}
									currentText={getPriceText(currency, rate)}
									suggestedText={getPriceText(currency, rfprRate)}
								/>
							}
							cell3={null}
							cell4={null}
							cell5={null}
						/>
					)
				},
			)}
		</React.Fragment>
	)
}

type SuggestedChangesBodyProps = {
	isAnyPricingItemChanged: boolean
	currency: string
	totalPriceValues: any
	pricingItems: any[]
	handleAssumptionDialogOpen: (originalAssumption: string, updatedAssumption: string) => void
}

const SuggestedChangesBody = ({
	isAnyPricingItemChanged,
	currency,
	totalPriceValues,
	pricingItems,
	handleAssumptionDialogOpen,
}: SuggestedChangesBodyProps) => {
	const {
		t,
		translations: [tCurrentTotalPriceModel, tSuggestedTotalPriceModel],
	} = useTranslation([
		{ path: `feeBreakdown.pricingModels.card.${totalPriceValues.totalPricingModel}` },
		{
			path: `feeBreakdown.pricingModels.card.${totalPriceValues.rfprTotalPricingModel}`,
		},
	] as any)

	const isTotalPriceSame = arePricesEqual(
		totalPriceValues.totalPrice,
		totalPriceValues.rfprTotalPrice,
	)

	const isTotalPricingModelSame = arePriceModelEqual(
		totalPriceValues.totalPricingModel,
		totalPriceValues.rfprTotalPricingModel,
	)

	const isSuggestedTotalPriceSameAsCurrentTotalPrice = isTotalPriceSame && isTotalPricingModelSame

	const renderPricingItem = ({
		indent = false,
		deliverableId,
		deliverableTitle,
		deliverableNumber,
		price,
		rfprPrice,
		percentage,
		rfprPercentage,
		pricingModel,
		rfprPricingModel,
		hours,
		rfprHours,
		comment,
		rfprComment,
		rfprRates,
	}) => {
		const suggestedCommentButton = (
			<>
				{!isEmpty(rfprComment) && rfprComment !== comment ? (
					<Button
						onClick={(e) => {
							e.stopPropagation()
							handleAssumptionDialogOpen(comment, rfprComment)
						}}
						color="error"
						size="small"
						data-testid="view-original-assumption"
						aria-label="Click to view original & updated assumption"
					>
						Updated
					</Button>
				) : null}
			</>
		)

		if (
			[pricingModels.CONTINGENCYPERCENTAGE, pricingModels.DISCOUNTPERCENTAGE].includes(pricingModel)
		) {
			return (
				<RowContentsForPercentagePricingItems
					deliverableId={deliverableId}
					deliverableNumber={deliverableNumber}
					deliverableTitle={deliverableTitle}
					percentage={percentage}
					rfprPercentage={rfprPercentage}
					pricingModel={pricingModel}
					indent={indent}
					suggestedCommentButton={suggestedCommentButton}
				/>
			)
		}

		const isPriceItemPriceSame = arePricesEqual(price, rfprPrice)
		const isPriceItemModelSame = arePriceModelEqual(pricingModel, rfprPricingModel)
		const isPriceItemHoursSame = areHoursEqual(hours, rfprHours)

		if (pricingModel === pricingModels.RATECARD) {
			return (
				<RowContentsForRateCard
					indent={indent}
					deliverableId={deliverableId}
					deliverableNumber={deliverableNumber}
					deliverableTitle={deliverableTitle}
					rfprRates={rfprRates}
					currency={currency}
					suggestedCommentButton={suggestedCommentButton}
				/>
			)
		}

		const rowTitle = `${deliverableNumber}. ${deliverableTitle}`

		return (
			<RowContents
				indent={indent}
				interactive={true}
				key={deliverableId}
				label={rowTitle}
				scrollTargetId={deliverableId}
				cell1={<Ellipsis maxWidth="360px">{rowTitle}</Ellipsis>}
				cell2={
					<OriginalOrSuggestedText
						dataTestid="suggested-item-price"
						isTextSame={isPriceItemPriceSame}
						currentText={getPriceText(currency, price)}
						suggestedText={getPriceText(currency, rfprPrice)}
					/>
				}
				cell3={
					<Ellipsis maxWidth="100px">
						<OriginalOrSuggestedText
							dataTestid="suggested-item-model"
							isTextSame={isPriceItemModelSame}
							currentText={t(
								`feeBreakdown.pricingModels.item.${getPricingModelSelectValue(pricingModel)}`,
							)}
							suggestedText={t(`feeBreakdown.pricingModels.item.${rfprPricingModel}`)}
						/>
					</Ellipsis>
				}
				cell4={
					<OriginalOrSuggestedText
						dataTestid="suggested-item-hours"
						isTextSame={isPriceItemHoursSame}
						currentText={hours}
						suggestedText={rfprHours}
					/>
				}
				cell5={<OriginalOrSuggestedText suggestedText={suggestedCommentButton} />}
			/>
		)
	}

	return (
		<React.Fragment>
			{!isSuggestedTotalPriceSameAsCurrentTotalPrice && (
				<RowContents
					interactive={true}
					scrollTargetId="total-price-table"
					label={'Total price'}
					cell1={<Typography variant="body2">Total price</Typography>}
					cell2={
						<OriginalOrSuggestedText
							dataTestid="suggested-total-price-value"
							isTextSame={isTotalPriceSame}
							currentText={getPriceText(currency, totalPriceValues.totalPrice)}
							suggestedText={getPriceText(currency, totalPriceValues.rfprTotalPrice)}
						/>
					}
					cell3={
						<Ellipsis maxWidth="100px">
							<OriginalOrSuggestedText
								dataTestid="suggested-total-price-model"
								isTextSame={isTotalPricingModelSame}
								currentText={tCurrentTotalPriceModel}
								suggestedText={tSuggestedTotalPriceModel}
							/>
						</Ellipsis>
					}
				/>
			)}
			{isAnyPricingItemChanged &&
				pricingItems.map((item) => {
					if (item.__typename === 'PricingGroup') {
						if (item.deliverables.length === 0) {
							return null
						}

						return (
							<>
								<StyledTableRow onClick={() => scrollToElementWithId(item._id)}>
									<TableCell colSpan={5}>
										<Box display="flex" alignItems="center" fontWeight="bold">
											<ListIcon fontSize="small" style={{ marginRight: '4px' }} />
											{item.title}
										</Box>
									</TableCell>
								</StyledTableRow>

								{item.deliverables.map((item) => {
									return renderPricingItem({ ...item, indent: true })
								})}
							</>
						)
					}

					return renderPricingItem(item)
				})}
		</React.Fragment>
	)
}

type SuggestedChangesHeadProps = {
	phaseDuration: any
}

const SuggestedChangesHead = ({ phaseDuration }: SuggestedChangesHeadProps) => (
	<RowContents
		cell2={'Fee / Rate'}
		cell3={'Fee type'}
		cell4={phaseDuration === 'months' ? 'Months' : 'Hours'}
		cell5={'Assumptions'}
	/>
)

type SuggestedChangesTableProps = {
	totalPriceValues: any
	pricingItems: RfprDeliverable[]
	currency: string
	renderAssumptionsDialog: (...args: any[]) => React.ReactNode
	phaseDuration: any
}

const SuggestedChangesTable = ({
	totalPriceValues,
	pricingItems,
	currency,
	phaseDuration,
}: SuggestedChangesTableProps) => {
	const isAnyPricingItemChanged = size(pricingItems) > 0

	const [assumption, setAssumption] = useState({
		isOpen: false,
		originalAssumption: '',
		updatedAssumption: '',
	})

	function handleAssumptionDialogOpen(originalAssumption: string, updatedAssumption: string) {
		setAssumption({
			isOpen: true,
			originalAssumption,
			updatedAssumption,
		})
	}
	function handleAssumptionDialogClose() {
		setAssumption({
			isOpen: false,
			originalAssumption: '',
			updatedAssumption: '',
		})
	}

	const { isOpen, originalAssumption, updatedAssumption } = assumption

	return (
		<>
			<AssumptionDialog
				isOpen={isOpen}
				originalAssumption={originalAssumption}
				updatedAssumption={updatedAssumption}
				handleClose={handleAssumptionDialogClose}
			/>
			<StyledTableContainer>
				<Typography variant="h3XSmall" mt={1}>
					Suggested changes
				</Typography>
				<Table size="small">
					<TableHead>
						<SuggestedChangesHead phaseDuration={phaseDuration} />
					</TableHead>
					<TableBody>
						<SuggestedChangesBody
							isAnyPricingItemChanged={isAnyPricingItemChanged}
							currency={currency}
							totalPriceValues={totalPriceValues}
							pricingItems={pricingItems}
							handleAssumptionDialogOpen={handleAssumptionDialogOpen}
						/>
					</TableBody>
					<caption>List of suggested changes to a proposal</caption>
				</Table>
			</StyledTableContainer>
		</>
	)
}

SuggestedChangesTable.defaultProps = {
	totalPriceValues: {},
	pricingItems: [],
	currency: '',
}

export default SuggestedChangesTable
