// @ts-strict-ignore
import { useState } from 'react'
import { Button, Dialog, useConfirmDialog } from '@persuit/ui-components'
import { Rfp, Response } from '@persuit/ui-graphql/generated'
import hasFeedback from './form/utils/has-feedback'
import isEmpty from 'lodash/isEmpty'
import { ProposalFeedbackPreviewDialog } from './form/preview'
import Form from './form'
import ConfirmButton from './form/confirm-button'
import {
	arrangeFirmsAndProposals,
	arrangeFirmsAndProposalsSingleFirm,
} from './form/utils/arrange-firms-and-proposals'

export const getInitialFormState = (firmsAndProposals) => {
	const initialFeedbackValue = {
		strategy: 'unanswered',
		pricing: 'unanswered',
		expertise: 'unanswered',
		diversity: 'unanswered',
		comment: null,
	}

	const initialFeedbackValues = firmsAndProposals.reduce((acc, org) => {
		const proposalItems = org.proposals.reduce((acc, proposal) => {
			return { ...acc, [proposal._id.toString()]: { ...initialFeedbackValue } }
		}, {})

		return {
			...acc,
			...proposalItems,
		}
	}, {})

	return initialFeedbackValues
}

type ProposalFeedbackProps = {
	rfp: Rfp
	proposals: Response[]
	handleClose: () => void
	fetchResponses: () => Promise<unknown>
	firmId?: string
}

type FeedbackValue = {
	strategy: string
	pricing: string
	expertise: string
	diversity: string
	comment: string | null
}

export type FormState = {
	[proposalId: string]: FeedbackValue
}

export const ProposalFeedbackDialog = ({
	rfp,
	proposals,
	handleClose,
	fetchResponses,
	firmId = '',
}: ProposalFeedbackProps) => {
	const invites = rfp.invites ?? []
	const firmsAndProposals = firmId
		? arrangeFirmsAndProposalsSingleFirm(invites, proposals, firmId)
		: arrangeFirmsAndProposals(invites, proposals)

	const [formState, setFormState] = useState<FormState>(() =>
		getInitialFormState(firmsAndProposals),
	)
	const [showPreview, setShowPreview] = useState(false)
	const { openConfirmDialog } = useConfirmDialog()

	const isSubmittable = hasFeedback(formState)

	// If all proposals have feedback dont open dialog.
	// maybe possible with select -> unselect -> re-select edge case.
	if (isEmpty(firmsAndProposals)) {
		return null
	}

	const openPreview = () => setShowPreview(true)
	const closePreview = () => setShowPreview(false)
	const openSkip = () =>
		openConfirmDialog({
			title: 'Skip Sending Feedback',
			content:
				'Are you sure you want to skip sending feedback to your firm? You can choose to do this at a later stage from their proposal details.',
			actions: [
				{ type: 'secondary', label: 'back', close: true },
				{ type: 'primary', label: 'Skip', close: true, action: handleClose },
			],
		})

	const actions = (
		<>
			<Button onClick={openSkip}>Skip</Button>
			<Button
				onClick={openPreview}
				sx={{
					ml: 'auto',
				}}
				disabled={!isSubmittable}
			>
				Preview
			</Button>
			<ConfirmButton
				rfpId={rfp._id}
				handleClose={handleClose}
				formState={formState}
				isSubmittable={isSubmittable}
				fetchResponses={fetchResponses}
			/>
		</>
	)
	return (
		<>
			<Dialog
				open={true}
				size="lg"
				actions={actions}
				onClose={handleClose}
				title="Firms Want Your Feedback"
			>
				<Form
					rfp={rfp}
					firmsAndProposals={firmsAndProposals}
					setFormState={setFormState}
					formState={formState}
				/>
			</Dialog>
			<ProposalFeedbackPreviewDialog
				open={showPreview}
				onClose={closePreview}
				firmsAndProposals={firmsAndProposals}
				formState={formState}
				rfpId={rfp._id}
				handleClose={handleClose}
				fetchResponses={fetchResponses}
			/>
		</>
	)
}
