import * as React from 'react'
import { useFeatureToggles, UseFeatureTogglesConfig, UseFeatureTogglePayload } from '../hooks'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export type WithFeatureTogglesHOCProps = {
	togglesState: UseFeatureTogglePayload
	featureToggleClient?: LDClient
}

export function withFeatureToggles(config: UseFeatureTogglesConfig = {}) {
	return function withFeatureTogglesHOC<P>(
		WrappedComponent: React.ComponentType<P & WithFeatureTogglesHOCProps>,
	) {
		const Component = (props: P) => {
			const togglesState = useFeatureToggles(config)
			const featureToggleClient = useLDClient()

			return (
				<WrappedComponent
					togglesState={togglesState}
					featureToggleClient={featureToggleClient}
					{...props}
				/>
			)
		}

		Component.displayName = `withFeatureTogglesHOC(${WrappedComponent.displayName})`

		return Component
	}
}
