import { Maybe, UseCase } from '@persuit/ui-graphql/generated'
import { RfpSection } from './types'

export type UseIsSectionEnabledArgs = {
	isDiversitySectionEnabled: boolean
	rfp: {
		useCase?: Maybe<UseCase>
		ccRequired?: Maybe<boolean>
	}
	isNewRfp: boolean
}

const newRfpUnsupportedSections: readonly RfpSection[] = ['clarification']
const updateRfpUnsupportedSections: readonly RfpSection[] = [
	'timeline',
	'tracking',
	'scorecard',
	'invite',
]

export function getIsSectionEnabled({
	isDiversitySectionEnabled,
	rfp: { useCase, ccRequired },
	isNewRfp,
}: UseIsSectionEnabledArgs): (section: RfpSection) => boolean {
	const unsupportedSections = isNewRfp ? newRfpUnsupportedSections : updateRfpUnsupportedSections

	return (section) => {
		if (unsupportedSections.includes(section)) {
			return false
		}

		switch (section) {
			case 'pricing':
				return useCase !== 'rateReview'
			case 'rates':
				return useCase === 'rateReview'
			case 'diversity':
				return isDiversitySectionEnabled && useCase !== 'rateReview'
			case 'conflict':
				return useCase !== 'rateReview' && (isNewRfp || Boolean(ccRequired))
			default:
				return true
		}
	}
}
