import { mapRecord } from '@persuit/common-utils'
import { createStore } from '@persuit/ui-state'

type ExpandCollapseState = {
	expandedState: {
		[key: string]: boolean
	}
}

const store = createStore(
	({ expandedState }: ExpandCollapseState): ExpandCollapseState => ({ expandedState }),
	(set) => ({
		expand: (id: string) =>
			set(({ expandedState }) => ({
				expandedState: {
					...expandedState,
					[id]: true,
				},
			})),
		collapse: (id: string) =>
			set(({ expandedState }) => ({
				expandedState: {
					...expandedState,
					[id]: false,
				},
			})),
		expandAll: () =>
			set(({ expandedState }) => ({
				expandedState: mapRecord(expandedState, () => true),
			})),
		collapseAll: () =>
			set(({ expandedState }) => ({
				expandedState: mapRecord(expandedState, () => false),
			})),
	}),
	{
		isAllExpanded: ({ expandedState }) =>
			!Object.values(expandedState).some((expanded) => !expanded),
		isAllCollapsed: ({ expandedState }) =>
			!Object.values(expandedState).some((expanded) => expanded),
		isExpanded: ({ expandedState }: ExpandCollapseState, id: string) => !!expandedState[id],
	},
)

export const {
	Provider: ExpandCollapseProvider,
	useStore: useExpandCollapseStore,
	useActions: useExpandCollapseActions,
} = store
