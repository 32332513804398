import { useRef, useEffect } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { deepPurple800 } from '../theme/persuit-colors'
import injectSheet from '../injectSheet'
import Spacer from './layout/spacer.jsx'
import Box from './box.jsx'

const loadingSpinnerLogoPathLength = '182' // The SVG loading image has a path length of 182

// Use spinnerStatic = true to display without animation.
// Use case is on heavy rendering where the DOM is frozen and the animation halts.

const LoadingSpinner = ({ classes, t, spinnerStatic = false, onLongLoad }) => {
	// Use ref to ensure the callback is always up to date without rerunning the useEffect which would reset the timer
	const onLongLoadRef = useRef(onLongLoad)
	onLongLoadRef.current = onLongLoad

	// Run a callback if the loading spinner is loading for too long
	useEffect(() => {
		if (onLongLoadRef.current) {
			const timerId = setTimeout(() => onLongLoadRef.current(), 10000)
			return () => clearTimeout(timerId)
		}
	}, [])

	return (
		<div className={classes.container}>
			<Box top={1} bottom={1} left={4} right={4}>
				<svg width="62.6px" height="84.6px" viewBox="0 0 31.3 42.3">
					<path
						fill="none"
						className={spinnerStatic ? classes.spinnerStatic : classes.spinnerPath}
						d="M7.8,16.9v-10h8.6c4.7,0,7.2,2.8,7.2,6.8
					c0,3.9-2.4,6.7-7.2,6.7L1,20.4V1h15.6c8.3,0,13.8,4.3,13.8,12.7c0,8.2-5.4,12.6-13.8,12.6H7.8v15H1V24.6"
					/>
				</svg>

				<Spacer direction="column" space={1} />
				<span className={classes.loadingText}>{t('loading')}</span>
			</Box>
		</div>
	)
}

LoadingSpinner.styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		marginTop: '1em',
		textAlign: 'center',
	},
	spinnerPath: {
		stroke: deepPurple800,
		strokeDasharray: loadingSpinnerLogoPathLength,
		strokeDashoffset: 0,
		animationName: 'animate-loading-spinner',
		animationDuration: '3.5s',
		animationTimingFunction: 'ease-in-out',
		animationIterationCount: 'infinite',
	},
	loadingText: {
		textTransform: 'uppercase',
		marginTop: '0.5em',
		fontSize: '1.3em',
		color: deepPurple800,
	},

	// Keyframes are always global with JSS
	'@keyframes animate-loading-spinner': {
		'0%': {
			// Entire stroke offset (not visible) in forward direction
			strokeDashoffset: loadingSpinnerLogoPathLength,
		},

		'50%': {
			// Entire stroke visible
			strokeDashoffset: 0,
		},

		'100%': {
			// Entire stroke offset (not visible) in backward direction
			strokeDashoffset: `-${loadingSpinnerLogoPathLength}`,
		},
	},

	spinnerStatic: {
		stroke: deepPurple800,
		// Entire stroke visible
		strokeDashoffset: 0,
	},
}

export default compose(injectSheet(LoadingSpinner.styles), withTranslation())(LoadingSpinner)
