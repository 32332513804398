import { RichTextView, styled } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Box from '@mui/material/Box'
import { pink25 } from '../../../../theme/persuit-colors'

const StyledSuggestionBox = styled(Box)`
	background-color: ${pink25};
	padding: 16px 16px 10px 16px;
`

const DisplayBox = ({ suggestedAssumptions }) => {
	return (
		<StyledSuggestionBox data-testid="rfpr-item-assumptions">
			<RichTextView content={suggestedAssumptions} />
		</StyledSuggestionBox>
	)
}

export default DisplayBox
