import { Box, Typography, useTheme } from '@persuit/ui-components'
import {
	combinedRates,
	priceRange,
	isPercentagePricingModel,
	formatPercentageValueWithZero,
} from '../common/utilities'
import constants from '../../../../common/data/pricing-models'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import useTranslation from '../../../custom-hooks/translation-hook'
import StrikeThroughPrice, { hasStrikeThroughPrice } from './strikethrough-price'
import { ResponseToDeliverable } from '@persuit/ui-graphql/generated'
import { PriceDelta } from './phase-delta'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type PricingCardItemHeaderProps = {
	pricingModel: string
	responseToDeliverable: Pick<
		ResponseToDeliverable,
		'price' | 'percentage' | 'delta' | 'averageRate'
	>
	currency: string
	hours?: string
	phaseDuration?: string
	showStrikethrough?: boolean
}

function formatTotalPrice(currency: string, deliverableTotalPrice?: string) {
	return deliverableTotalPrice ? formatPriceWithCurrency(currency, deliverableTotalPrice) : '-'
}

export const PricingCardItemHeader = ({
	pricingModel,
	responseToDeliverable,
	currency,
	hours,
	phaseDuration,
	showStrikethrough = false,
}: PricingCardItemHeaderProps) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const { toggles } = useFeatureToggles()

	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const isPercentage = isPercentagePricingModel(pricingModel)

	const isPriceRange =
		pricingModel === constants.HOURLYRATES &&
		responseToDeliverable &&
		responseToDeliverable.price === null

	const isPriceModelNotNullOrIncluded =
		pricingModel && pricingModel !== constants.NONE && pricingModel !== constants.INCLUDEDINTOTAL

	const isRateCard = pricingModel === constants.RATECARD
	const isNotRateCardOrHourlyRate = !isRateCard && pricingModel !== constants.HOURLYRATE && hours
	const averageRate = responseToDeliverable.averageRate?.rate
	const averageRateDelta = responseToDeliverable.delta?.price

	function renderRateCardAverageRate() {
		if (isRateCard && typeof averageRate === 'number') {
			const previousAverageRate = averageRateDelta
				? responseToDeliverable.delta?.direction === 'up'
					? averageRate - averageRateDelta
					: averageRate + averageRateDelta
				: averageRate

			const deltaDirection = responseToDeliverable.delta?.direction
			const deltaPercentage = responseToDeliverable.delta?.percentage

			return (
				<Box mb={3} height={100}>
					<Typography variant="h3XSmall" fontWeight="500" component="p">
						{formatPriceWithCurrency(currency, averageRate)}
					</Typography>
					{averageRateDelta ? (
						<Box>
							<Typography strikeThrough={true} strikeThroughColor={theme.palette.error.main}>
								{formatPriceWithCurrency(currency, previousAverageRate, true, '0,0.[00]')}
							</Typography>
						</Box>
					) : null}
					<Typography variant="body2">Average rate</Typography>
					{averageRateDelta && deltaDirection && deltaPercentage ? (
						<PriceDelta
							formattedDifferenceAmount={formatPriceWithCurrency(currency, averageRateDelta)}
							differencePercentage={Number(deltaPercentage)}
							direction={deltaDirection}
							pricingModel={pricingModel}
						/>
					) : null}
				</Box>
			)
		} else {
			return null
		}
	}

	return (
		<Box maxWidth="80%" display="flex" flexDirection="row" justifyContent="space-between">
			<Box data-testid="header" mb={1}>
				{isRateCardAuctionEnabled ? renderRateCardAverageRate() : null}
				{pricingModel !== constants.INCLUDEDINTOTAL && (
					<Typography
						variant={isPriceRange && isRateCardAuctionEnabled ? 'body1Semibold' : 'h3XSmall'}
						fontWeight="500"
						component="p"
						data-testid="deliverable-price"
					>
						{isPercentage && formatPercentageValueWithZero(responseToDeliverable.percentage)}
						{!isPercentage && (
							<>
								{isPriceRange &&
									priceRange(
										pricingModel,
										combinedRates(responseToDeliverable),
										responseToDeliverable,
										currency,
									)}

								{!isPriceRange && formatTotalPrice(currency, responseToDeliverable?.price ?? '')}
							</>
						)}
					</Typography>
				)}
				{showStrikethrough && (
					<StrikeThroughPrice responseToDeliverable={responseToDeliverable} currency={currency} />
				)}
				{/* The firm cannot choose no preference (selected by client) don't show */}
				{isPriceModelNotNullOrIncluded && (
					<Typography display="block" variant="body2" data-testid="deliverable-model">
						{t(`feeBreakdown.pricingModels.firmCard.${pricingModel}`)}
					</Typography>
				)}
				{pricingModel === constants.INCLUDED && (
					<Typography variant="body1Semibold" fontSize="20px" data-testid="deliverable-model">
						{t(`feeBreakdown.pricingModels.firmCard.${pricingModel}`)}
					</Typography>
				)}
				{/* dont show "Hrs" for hourly rate or rate card */}
				{isNotRateCardOrHourlyRate && !isPercentage && (
					<Typography display="block" variant="body2" data-testid="deliverable-hours">
						{hours} {phaseDuration === 'months' ? 'Months' : 'Hrs'}
					</Typography>
				)}

				{/* Spacers to maintain alignment if a particular item is missing */}
				{!isRateCard && showStrikethrough && !hasStrikeThroughPrice(responseToDeliverable) ? (
					<Box sx={{ height: '24px' }} aria-hidden={true} />
				) : null}
			</Box>
		</Box>
	)
}
