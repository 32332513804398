import {
	FieldValues,
	UseControllerProps,
	useFormContext,
	useController,
	UseControllerReturn,
} from 'react-hook-form'
import { OpaqueFieldName } from '..'

export type FormFieldProps<T> = {
	/** Opaque name value for type validation, consider using `field.name` instead */
	name: string | OpaqueFieldName<T>
	defaultValue?: T
}

export type RHFFormComponentProps<Value, Props = unknown> = Omit<Props, 'name' | 'defaultValue'> &
	UseFormComponentProps<Value>

export type UseFormComponentProps<T> = Omit<
	UseControllerProps,
	'name' | 'defaultValue' | 'control'
> &
	FormFieldProps<T>

export const useFormComponent = <T,>({ name, defaultValue, rules }: UseFormComponentProps<T>) => {
	const formContext = useFormContext()

	if (!formContext?.control) {
		throw new Error('Missing FormProvider')
	}

	const { control } = formContext

	const controllerProps = useController({
		name: name as string,
		control,
		defaultValue,
		rules,
	})

	return {
		...controllerProps,
		field: { ...controllerProps.field, required: !!rules?.required },
	}
}

type FormComponentProps<T> = UseFormComponentProps<T> & {
	children: (props: UseControllerReturn<FieldValues, string>) => React.ReactElement
}

export const FormComponent = <T,>({ children, ...rest }: FormComponentProps<T>) =>
	children(useFormComponent(rest))
