// @ts-strict-ignore
const states = {
	CHOICE: 'choice',
	LONG: 'long',
	MEDIUM: 'medium',
	SHORT: 'short',
	NUMERIC: 'numeric',
	PERCENTAGE: 'percentage',
	PRICE: 'price',
	SINGLE_SELECT: 'singleSelect',
	MULTIPLE_SELECT: 'multipleSelect',
	SCALE: 'scale',
}

export type QuestionTypes = (typeof states)[keyof typeof states]

const values = Object.keys(states).map((key) => states[key])

export { values, states as default }
