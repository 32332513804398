import { DateTimePicker, DateTimePickerProps } from './date-time-picker'
import { useFormComponent, RHFFormComponentProps } from '../../../utilities'

export type FormDateTimePickerProps = RHFFormComponentProps<
	string,
	Omit<DateTimePickerProps, 'value'>
>

export const FormDateTimePicker = ({
	rules,
	name,
	defaultValue,
	...props
}: FormDateTimePickerProps) => {
	const {
		field,
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<DateTimePicker
			name={field.name}
			error={!!error?.message}
			helperText={error?.message}
			{...props}
			required={props.required || field.required}
			onChange={(date) => {
				field.onChange(date)
				props.onChange?.(date)
			}}
			value={field.value}
		/>
	)
}
