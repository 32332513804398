import 'date-fns'
import { useState, useRef } from 'react'

import IconButton from '@mui/material/IconButton'

import {
	DateTimePicker as MuiDateTimePicker,
	DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers'

import { TextFieldVariants } from '@mui/material/TextField'

import { multiSectionDigitalClockClasses } from '@mui/x-date-pickers/MultiSectionDigitalClock'
import { useTheme } from '../../..'
import { Box, BoxProps } from '../../box'
import { noop } from '@persuit/common-utils'
import { CalendarIcon } from '../../../icons/calendar-icon'

export type DateTimePickerProps = {
	dateFormat?: string
	disabled?: boolean
	fullWidth?: boolean
	id: string
	label: string
	name?: string
	onChange?: (date: Date | null) => void
	value?: Date | null
	datePickerOnly?: boolean
	containerProps?: BoxProps
	error?: boolean
	helperText?: string
	required?: boolean
	variant?: TextFieldVariants
	disablePast?: boolean
}

export const DateTimePicker = ({
	fullWidth = false,
	id,
	value: originalValue,
	onChange = noop,
	label,
	error,
	name,
	dateFormat,
	disabled = false,
	datePickerOnly = false,
	containerProps,
	helperText,
	required,
	variant = 'standard',
	disablePast = false,
}: DateTimePickerProps) => {
	const theme = useTheme()
	const [isOpen, setIsOpen] = useState(false)
	const value = originalValue ? originalValue : null
	const inputRef = useRef<HTMLInputElement>(null)

	const Picker = datePickerOnly ? MuiDatePicker : MuiDateTimePicker

	return (
		<Box display="flex" alignItems="center" {...containerProps}>
			<IconButton
				aria-label={`Choose ${label}`}
				disabled={disabled}
				onClick={() => setIsOpen(true)}
				size="large"
			>
				<CalendarIcon style={{ color: theme.palette.primary.main }} />
			</IconButton>

			<Picker
				label={label}
				disabled={disabled}
				open={isOpen}
				format={dateFormat ? dateFormat : datePickerOnly ? 'yyyy/MM/dd' : 'yyyy/MM/dd hh:mm a'}
				value={value}
				disablePast={disablePast}
				onOpen={() => setIsOpen(true)}
				onClose={() => {
					setIsOpen(false)
					inputRef.current?.focus()
				}}
				onChange={(date) => {
					// ensure that onChange is always called with a valid date or null
					const newDate = date instanceof Date && !isNaN(date.getTime()) ? date : null
					if (newDate === null && value === null) {
						return
					}
					onChange(newDate)
				}}
				slotProps={{
					textField: {
						id,
						name,
						helperText,
						error,
						fullWidth,
						required,
						inputProps: {
							style: {
								textTransform: 'lowercase',
							},
						},
						ref: inputRef,
						onClick: () => {
							inputRef.current?.focus()
							setIsOpen(true)
						},
						variant,
					},
					desktopPaper: {
						sx: {
							[`.${multiSectionDigitalClockClasses.root}`]: {
								width: '220px',
							},
						},
					},
					inputAdornment: {
						sx: {
							display: 'none',
						},
					},
					popper: {
						'aria-label': datePickerOnly ? 'Date picker' : 'Date time picker',
					},
				}}
			/>
		</Box>
	)
}
