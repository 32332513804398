import { isBefore } from 'date-fns'
import assign from 'lodash/fp/assign'
import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import groupBy from 'lodash/fp/groupBy'
import head from 'lodash/fp/head'
import last from 'lodash/fp/last'
import map from 'lodash/fp/map'
import max from 'lodash/fp/max'
import mean from 'lodash/fp/mean'
import min from 'lodash/fp/min'
import omit from 'lodash/fp/omit'
import partition from 'lodash/fp/partition'
import reduce from 'lodash/fp/reduce'
import sortBy from 'lodash/fp/sortBy'
import calculateAuctionPriceDrop from '../../../../../common/analytics/calculate-auction-price-drop'
import calcaulateAuctionComparisonValueDrop from '../../../../../common/analytics/calculate-auction-comparison-value-drop'
import responseStates from '../../../../../common/data/rfp-proposal-states'
import { getOrEmptyArray } from '../../../../utils/lenses'

const filterWithdrawnResponses = (showWithdrawn) => (response) => {
	return showWithdrawn || response.status !== responseStates.WITHDRAWN
}

const filterEliminatedResponses = (filterEliminated) => (response) => {
	return filterEliminated || !response.isEliminated
}

const filterRevisedResponses = (response) => {
	return response.status !== responseStates.REVISED
}

// Filter out withdrawn proposals (based on showWithdrawn bool)
// Filter out revised responses
const getFilteredResponses = ({ showEliminated, showWithdrawn, responses }) => {
	return compose(
		filter(filterEliminatedResponses(showEliminated)),
		filter(filterWithdrawnResponses(showWithdrawn)),
		filter(filterRevisedResponses),
	)(responses)
}

// Append all the bids from the proposal to the accumulator
const reduceResponsesToChartData = (acc, response) => {
	const orgName = response.org.name

	const allPriceRevisions = response.totalPrices.map((bid) => {
		const bidTime = bid.createdAt
		const priceValue = bid.totalPriceValue

		return {
			time: bidTime,
			[orgName]: priceValue,
			orgId: response.org._id,
		}
	})

	acc.push(...allPriceRevisions)

	return acc
}

// Append all the bids from the proposal to the accumulator
const reduceComparisonValueResponsesToChartData = (acc, response) => {
	const orgName = response.org.name

	const allPriceRevisions = response.comparisonValues.map((bid) => {
		const bidTime = bid.createdAt
		const comparisonValue = bid.totalComparisonValue

		return {
			time: bidTime,
			[orgName]: comparisonValue,
			orgId: response.org._id,
		}
	})

	acc.push(...allPriceRevisions)

	return acc
}

// Produces an array of data ready for the chart
const generateChartData = ({ auctionEnd, auctionStart, responses, totalPriceRequired }) => {
	// Create an array of chart data for all of bids across all of the proposals
	const chartData = compose(
		sortBy('time'),
		reduce(
			totalPriceRequired ? reduceResponsesToChartData : reduceComparisonValueResponsesToChartData,
			[],
		),
	)(responses)

	// Special data point for the start of the auction
	const auctionStartDataPoint = generateAuctionStartDataPoint({
		responses,
		auctionStart,
		totalPriceRequired,
	})

	// Special data point for the end of the auction
	const auctionEndDataPoint = generateAuctionEndDataPoint({
		responses,
		auctionEnd,
		totalPriceRequired,
	})

	return sortBy('time')([...chartData, auctionStartDataPoint, auctionEndDataPoint])
}

export const generateRatesChartData = ({ auctionEnd, auctionStart, responses }) => {
	const chartData = responses
		.reduce((acc, cur) => {
			const orgName = cur.org.name
			const revisions = [
				{ proposalDate: cur.createdAt, rate: cur.averageRate.rate },
				...cur.averageRate.history,
			]

			const averageRateHistory = revisions.map(({ proposalDate, rate }) => {
				return {
					time: proposalDate,
					[orgName]: rate,
					orgId: cur.org._id,
				}
			})

			return [...acc, ...averageRateHistory]
		}, [])
		.sort((a, b) => a.time - b.time)

	// Special data point for the start of the auction
	const auctionStartDataPoint = generateAuctionRatesStartDataPoint({
		chartData,
		auctionStart,
	})

	// Special data point for the end of the auction
	const auctionEndDataPoint = generateAuctionRatesEndDataPoint({
		chartData,
		auctionEnd,
	})

	return [...chartData, auctionStartDataPoint, auctionEndDataPoint].sort((a, b) => a.time - b.time)
}

const generateAuctionRatesStartDataPoint = ({ chartData, auctionStart }) => {
	return compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidAtAuctionStart(auctionStart), []),
		groupBy('orgId'),
		sortBy('time'),
	)(chartData)
}

const generateAuctionRatesEndDataPoint = ({ chartData, auctionEnd }) => {
	const isBeforeAuctionEnd = ({ time }) => time <= auctionEnd

	return compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidsAtAuctionEnd(auctionEnd), []),
		groupBy('orgId'),
		sortBy('time'),
		filter(isBeforeAuctionEnd),
	)(chartData)
}

// Find the bid that's before the start of the auction
// (or the first one after the start if there are none before the start)
const findBidAtAuctionStart = (auctionStart) => (acc, bidsByOrg) => {
	// Bids are already sorted by time
	// Split the bids into before the auction start and after the auction start
	const [bidsBeforeStart, bidsAfterStart] = partition((bid) => {
		return bid.time <= auctionStart
	})(bidsByOrg)

	// Either grab the bid closest to the start before the start
	// OR
	// Grab the bid cloest to the start but after the start
	const startBidForOrg = last(bidsBeforeStart) || head(bidsAfterStart)

	acc.push({ ...startBidForOrg, time: auctionStart })

	return acc
}

// Generate a data point for the auction start time
// Gives a slice of data for that specific point in time
// Even though no bids have occured at that exact time
// Finds the price of each bid at the instant the auction started
//
// The start data is defined to be the bid when the auction started (from before the auction start)
// OR
// In the less likely case when there is no bid before the auction start, it's
// the bid nearest to the auction start
const generateAuctionStartDataPoint = ({ responses, auctionStart, totalPriceRequired }) => {
	return compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidAtAuctionStart(auctionStart), []),
		groupBy('orgId'),
		sortBy('time'),
		reduce(
			totalPriceRequired ? reduceResponsesToChartData : reduceComparisonValueResponsesToChartData,
			[],
		),
	)(responses)
}

const findBidsAtAuctionEnd = (auctionEnd) => (acc, bidsByOrg) => {
	// Bids are already sorted by time
	const startBidForOrg = last(bidsByOrg)

	acc.push({ ...startBidForOrg, time: auctionEnd })

	return acc
}

// Generate a data point for the auction end time
// Gives a slice of data for that specific point in time
// Even though no bids have occured at that exact time
// Finds the price of each bid at the instant the auction ended
const generateAuctionEndDataPoint = ({ responses, auctionEnd, totalPriceRequired }) => {
	const isBeforeAuctionEnd = ({ time }) => time <= auctionEnd

	return compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidsAtAuctionEnd(auctionEnd), []),
		groupBy('orgId'),
		sortBy('time'),
		filter(isBeforeAuctionEnd),
		reduce(
			totalPriceRequired ? reduceResponsesToChartData : reduceComparisonValueResponsesToChartData,
			[],
		),
	)(responses)
}

// Produces computed and summarised auction data for each response
const generateAuctionSummaryData = ({ request, responses }) => {
	const mapPriceDrop = (response) => {
		const { startingPrice, endingPrice, priceDrop } = calculateAuctionPriceDrop(request, response)

		return {
			orgName: response.org.name,
			orgId: response.org._id,
			isNamwolfMember: response.org.isNamwolfMember,
			dropPercentage: startingPrice ? (100 * priceDrop) / startingPrice : null,
			drop: priceDrop,
			starting: startingPrice,
			ending: endingPrice,
		}
	}

	return compose(sortBy('ending'), map(mapPriceDrop))(responses)
}

const generateComparisonValueAuctionSummaryData = ({ request, responses }) => {
	const mapPriceDrop = (response) => {
		const { startingPrice, endingPrice, priceDrop } = calcaulateAuctionComparisonValueDrop(
			request,
			response,
		)

		return {
			orgName: response.org.name,
			orgId: response.org._id,
			isNamwolfMember: response.org.isNamwolfMember,
			dropPercentage: startingPrice ? (100 * priceDrop) / startingPrice : null,
			drop: priceDrop,
			starting: startingPrice,
			ending: endingPrice,
		}
	}

	return compose(sortBy('ending'), map(mapPriceDrop))(responses)
}

// Average price drop across all responses
const calculatePriceReduction = ({ proposalPriceData }) => {
	// Get the average of all the price reductions
	const priceReduction = compose(mean, map('priceDrop'))(proposalPriceData)

	const mapPriceDropsToPercentages = ({ priceDrop, startingPrice }) => {
		return (100 * priceDrop) / startingPrice
	}

	// Get the average of all the price reductions as a percentage
	const priceReductionPerc = compose(mean, map(mapPriceDropsToPercentages))(proposalPriceData)

	return {
		priceReductionPerc,
		priceReduction,
	}
}

// The spread between highest and lowest bid at the end of the auction
const calculateEndingSpread = ({ proposalPriceData }) => {
	const endingPrices = map('endingPrice')(proposalPriceData)

	const { spread: endingSpread, spreadPerc: endingSpreadPerc } = calculateSpread({
		prices: endingPrices,
	})

	return {
		endingSpread,
		endingSpreadPerc,
	}
}

// The spread between highest and lowest bid at the start of the auction
const calculateStartingSpread = ({ proposalPriceData }) => {
	const startingPrices = map('startingPrice')(proposalPriceData)

	const { spread: startingSpread, spreadPerc: startingSpreadPerc } = calculateSpread({
		prices: startingPrices,
	})

	return {
		startingSpread,
		startingSpreadPerc,
	}
}

// The spread between highest and lowest prices
// As an absolute value and as a percentage
const calculateSpread = ({ prices }) => {
	// The minimum across all prices
	const minimumPrice = min(prices)
	// The maxmimum across all prices
	const maximumPrice = max(prices)

	const spread = maximumPrice - minimumPrice
	const spreadPerc = (spread / minimumPrice) * 100

	return {
		spread,
		spreadPerc,
	}
}

const calculateKPIs = ({ request, responses }) => {
	const totalPriceRequired = request?.detail?.totalPriceRequired
	const proposalPriceData = map((response) => {
		return totalPriceRequired
			? calculateAuctionPriceDrop(request, response)
			: calcaulateAuctionComparisonValueDrop(request, response)
	})(responses)

	const { endingSpread, endingSpreadPerc } = calculateEndingSpread({
		proposalPriceData,
	})

	const { startingSpread, startingSpreadPerc } = calculateStartingSpread({
		proposalPriceData,
	})

	const { priceReduction, priceReductionPerc } = calculatePriceReduction({
		proposalPriceData,
	})

	return {
		startingSpreadPerc,
		startingSpread,
		endingSpreadPerc,
		priceReductionPerc,
		priceReduction,
		endingSpread,
	}
}

// Count the total number of times prices were revised accross all proposals
const getTotalPriceRevisions = ({ request, responses }) => {
	const auctionStart = request.proposalsDueBy

	const countValidAuctionRevisions = (totalAcc, response) => {
		const totalPrices = getOrEmptyArray('totalPrices', response)

		const validResponseTotalPriceRevisions = totalPrices.reduce(
			(validTotalPriceRevisionCount, { totalPriceValue, createdAt }, i) => {
				const isBeforeAuction = isBefore(createdAt, auctionStart)
				const noPriceChange = i && totalPrices[i - 1].totalPriceValue === totalPriceValue
				// If the price revision came before the auction started
				// or if it is unchanged from the last, it is not a valid revision
				if (isBeforeAuction || noPriceChange) return validTotalPriceRevisionCount

				return validTotalPriceRevisionCount + 1
			},
			0,
		)

		return totalAcc + validResponseTotalPriceRevisions
	}

	return reduce(countValidAuctionRevisions, 0)(responses)
}

// Count the total number of times comparisonValues were revised accross all proposals
const getComparisonValueRevisions = ({ request, responses }) => {
	const auctionStart = request.proposalsDueBy

	const countValidAuctionRevisions = (totalAcc, response) => {
		const comparisonValues = getOrEmptyArray('comparisonValues', response)

		const validResponseComparisonValueRevisions = comparisonValues.reduce(
			(validComparisonValueRevisionCount, { totalComparisonValue, createdAt }, i) => {
				const isBeforeAuction = isBefore(createdAt, auctionStart)
				const noComparisonValueChange =
					i && comparisonValues[i - 1].totalComparisonValue === totalComparisonValue
				// If the price revision came before the auction started
				// or if it is unchanged from the last, it is not a valid revision
				if (isBeforeAuction || noComparisonValueChange) return validComparisonValueRevisionCount

				return validComparisonValueRevisionCount + 1
			},
			0,
		)

		return totalAcc + validResponseComparisonValueRevisions
	}

	return reduce(countValidAuctionRevisions, 0)(responses)
}

export {
	calculateKPIs,
	findBidAtAuctionStart,
	findBidsAtAuctionEnd,
	generateAuctionSummaryData,
	generateComparisonValueAuctionSummaryData,
	generateChartData,
	getFilteredResponses,
	getTotalPriceRevisions,
	getComparisonValueRevisions,
}
