import { Fragment } from 'react'
import { styled, RateReviewIcon } from '@persuit/ui-components'
import inviteStates from '../../../common/data/rfp-invite-states'
import proposalStates from '../../../common/data/rfp-proposal-states'
import { getOrEmptyString } from '../../utils/lenses'
import Spacer from '../layout/spacer.jsx'
import Button from '../button.jsx'

const StyledFeedbackIcon = styled(RateReviewIcon)`
	&.MuiSvgIcon-root {
		fill: ${(props) => props.theme.palette.primary.main};
	}
`

const getInvite = (rfp, orgId) => {
	const invite = rfp.invites.find((invite) => orgId === invite.item._id)

	return invite
}

export const getShowProposalFeedbackButton = (proposal, inviteStatus) => {
	if (proposal.feedback) {
		return false
	}

	if (proposal.status !== proposalStates.ACCEPTED && inviteStatus !== inviteStates.ELIMINATED) {
		return false
	}

	return true
}

const ProposalFeedbackButton = ({ openProposalFeedbackForm, rfp, proposal, orgId }) => {
	const invite = getInvite(rfp, orgId)
	const inviteStatus = getOrEmptyString('status', invite)

	const showProposalFeedbackButton = getShowProposalFeedbackButton(proposal, inviteStatus)

	if (!showProposalFeedbackButton) {
		return null
	}

	return (
		<Fragment>
			<Spacer direction="row" space={2} />
			<Button
				dataTestid="proposal-feedback-button"
				label="Provide Feedback"
				type="secondary"
				icon={<StyledFeedbackIcon />}
				onClick={() => openProposalFeedbackForm()}
			/>
		</Fragment>
	)
}

export default ProposalFeedbackButton
