// @ts-strict-ignore
import { useId } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
	ScreenOnly,
	SettingsIcon,
	Box,
	IconButton,
	DialogDeprecated,
	DialogTitle,
} from '@persuit/ui-components'
import { ScorecardCategory } from '@persuit/ui-shared-components'
import { proposalScoreActions } from '../../../../../actions/index.js'

import { ToggleShowSubcategories } from '../components/toggle-show-subcategories'

import Categories from './categories.jsx'
import { ScoreCardHeaderForm } from './scorecard-header-form'
import { useActions, useStore } from '../../../store'

type ScorecardHeaderCellProps = {
	otherProposalsScoreBeingEdited: boolean
	rfpId: string
	scoreCategories: ScorecardCategory[]
	showEditCategoriesForm: boolean
	toggleShowCategoriesForm: () => void
}

export const ScorecardHeaderCell = ({
	toggleShowCategoriesForm,
	otherProposalsScoreBeingEdited,
	rfpId,
	scoreCategories,
	showEditCategoriesForm,
}: ScorecardHeaderCellProps) => {
	const { currentSort, isClient } = useStore((state) => ({
		currentSort: state.sort,
		isClient: !state.isFirm,
	}))
	const { setSort } = useActions()

	const sortOrder = currentSort?.type === 'scorecard' ? currentSort.order : null

	const sortScorecard = (order: 'asc' | 'desc') => {
		setSort({
			type: 'scorecard',
			order,
		})
	}

	const noCategories = !scoreCategories || scoreCategories.length === 0
	const categoriesId = useId()

	const actionButtons = (
		<Box display="flex" alignItems="center">
			<DialogDeprecated
				open={showEditCategoriesForm}
				onClose={toggleShowCategoriesForm}
				fullScreen={true}
			>
				<DialogTitle>Scorecard form</DialogTitle>
				{showEditCategoriesForm && (
					<ScoreCardHeaderForm
						scoreCategories={scoreCategories}
						rfpId={rfpId}
						closeDialog={toggleShowCategoriesForm}
					/>
				)}
			</DialogDeprecated>

			{!otherProposalsScoreBeingEdited && (
				<ScreenOnly>
					<IconButton
						aria-label="Edit scorecard categories and weights"
						color="primary"
						onClick={toggleShowCategoriesForm}
						size="small"
					>
						<SettingsIcon />
					</IconButton>
				</ScreenOnly>
			)}

			{!noCategories && !otherProposalsScoreBeingEdited && (
				<ToggleShowSubcategories controlId={categoriesId} />
			)}
		</Box>
	)

	return (
		<Categories
			categoriesId={categoriesId}
			scoreCategories={scoreCategories}
			actionButtons={actionButtons}
			onSort={sortScorecard}
			sortOrder={sortOrder}
			isClient={isClient}
		/>
	)
}

const mapStateToProps = (state) => ({
	otherProposalsScoreBeingEdited: state.proposalScores.proposalScoreBeingEdited,
	showEditCategoriesForm: state.proposalScores.showEditCategoriesForm,
})

const mapDispatchToProps = {
	toggleShowCategoriesForm: proposalScoreActions.toggleShowCategoriesForm,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ScorecardHeaderCell) as any
