import * as React from 'react'
import { Typography, Box, styled } from '@persuit/ui-components'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const TextWithMinWidth = styled(Typography)`
	white-space: nowrap;
`

type RateCardListProps = {
	items: {
		left?: React.ReactNode
		right?: React.ReactNode
		rightStrikeThrough: React.ReactNode
	}[]
	phaseIndex: number
	averageRate?: number | null
	currency?: string
	detailedView?: boolean
}

/**
 * Used in Request detail view for showing rate card
 * Looks like this:
 * item 1_____________________________________pricing model
 */
const RateCardList = ({
	items,
	phaseIndex,
	averageRate,
	currency,
	detailedView,
}: RateCardListProps) => {
	const { toggles } = useFeatureToggles()
	// Show rate card average in Proposal Detail view
	const showRateCardAverage =
		detailedView && averageRate && currency && toggles['dev-9900.rate-card-auction-improvements']

	return (
		<Box data-testid="rate-card-list" role="list" aria-label="Rates">
			{items.map((item, i) => (
				<Box
					key={i}
					role="listitem"
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr auto auto',
						gap: 1,
						alignItems: 'center',
					}}
				>
					<Box>
						<Typography variant="body2">
							{phaseIndex + 1}.{i + 1}
							<span style={{ marginRight: '4px' }} /> {item.left ? item.left : '-'}
						</Typography>
					</Box>

					<Box>
						{item.rightStrikeThrough && (
							<Typography strikeThrough={true} variant="body2">
								{item.rightStrikeThrough}
							</Typography>
						)}
					</Box>

					<Box data-testid="rate-card-list-item">
						{item.right && <TextWithMinWidth variant="body2">{item.right}</TextWithMinWidth>}
					</Box>
				</Box>
			))}
			{showRateCardAverage && (
				<Box
					sx={(theme) => ({
						borderTop: `2px solid ${theme.palette.border.divider}`,
						mt: 2,
					})}
				>
					<Box display={'flex'} justifyContent={'space-between'} py={2}>
						<Typography variant="h4" component="span">
							Average rate
						</Typography>
						<Typography variant="h4" component="span">
							{formatPriceWithCurrency(currency, averageRate, true, '0,0.[00]')}
						</Typography>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default RateCardList
