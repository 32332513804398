import { Fragment } from 'react'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import { format, isEqual } from 'date-fns'
import { Typography, Box, styled } from '@persuit/ui-components'
import { RfpSystemClarification } from '../../../../types'

const StyledTable = styled('table')`
	width: 100%;
	border-collapse: collapse;
	text-align: center;
`

const StyledHeaderCell = styled('th')<{ isNew?: boolean }>`
	border: 1px solid ${({ theme }) => theme.palette.form.borderResting};
	padding: 0.5em;
	background: ${({ theme, isNew }) =>
		isNew ? theme.palette.yellow.bright : theme.palette.action.selected};
`

const StyledDataCell = styled('td')`
	border: 1px solid ${({ theme }) => theme.palette.form.borderResting};
	padding: 0.5em;
`

type ClarificationTableRowProps = {
	oldDateString?: string
	newDateString?: string
	headerText: string
}

const ClarificationTableRow = ({
	oldDateString,
	newDateString,
	headerText,
}: ClarificationTableRowProps) => {
	if (!oldDateString || !newDateString || isEmpty(oldDateString) || isEmpty(newDateString))
		return null

	const oldDate = new Date(oldDateString)
	const newDate = new Date(newDateString)

	if (isEqual(oldDate, newDate)) return null

	return (
		<tr>
			<StyledHeaderCell role="rowheader" scope="row">
				<Typography>{headerText}</Typography>
			</StyledHeaderCell>
			<StyledDataCell>
				<Typography>{oldDateString}</Typography>
			</StyledDataCell>
			<StyledDataCell sx={{ bgcolor: 'yellow.lightestHue' }}>
				<Typography>{newDateString}</Typography>
			</StyledDataCell>
		</tr>
	)
}

type DueDateChangedNonAuctionClarificationProps = {
	systemClarification?: RfpSystemClarification
}

export const DueDateChangedNonAuctionClarification = ({
	systemClarification,
}: DueDateChangedNonAuctionClarificationProps) => {
	const oldDueDate = getOr('', 'previousValues.dueDate', systemClarification)
	const newDueDate = getOr('', 'newValues.dueDate', systemClarification)
	const formattedOldDate = format(new Date(oldDueDate), 'd MMMM yyyy, h:mm a')
	const formattedNewDate = format(new Date(newDueDate), 'd MMMM yyyy, h:mm a')
	return (
		<Fragment>
			<Typography variant="h4">
				<b>Proposals DUE BY settings have been updated</b>
			</Typography>

			<StyledTable>
				<Box
					component="caption"
					sx={{
						textAlign: 'left',
					}}
				>
					<Typography variant="body2">Please see the updated information below</Typography>
				</Box>
				<tr>
					<td />
					<StyledHeaderCell>
						<Typography variant="body1Semibold">ORIGINAL</Typography>
					</StyledHeaderCell>
					<StyledHeaderCell isNew={true}>
						<Typography variant="body1Semibold">NEW</Typography>
					</StyledHeaderCell>
				</tr>
				<ClarificationTableRow
					oldDateString={formattedOldDate}
					newDateString={formattedNewDate}
					headerText="Proposals due by"
				/>
			</StyledTable>
		</Fragment>
	)
}
