export const SORT_LABELS = {
	aToZSortColumnMenu: {
		asc: 'Sort in ascending alphabetical order (A to Z)',
		desc: 'Sort in descending alphabetical order (Z to A)',
	},
	lowestToHighestColumnMenu: {
		asc: 'Sort from lowest to highest',
		desc: 'Sort from highest to lowest',
	},
	earliestToLatestColumnMenu: {
		asc: 'Sort from earliest to latest',
		desc: 'Sort from latest to earliest',
	},
}
