// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import isFunction from 'lodash/fp/isFunction'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const useSectionalContainer = (
	formData: object,
	refetchData: () => Promise<void>,
	refetchErrors?: () => Promise<void>,
): { loading: boolean; error: Error | null } => {
	const { anchor } = useParams<{ anchor: string }>()
	const [internalLoading, setLoading] = useState(true)
	const [error, setError] = useState<Error | null>(null)

	const refetchErrorsSafe = async () => {
		if (isFunction(refetchErrors)) await refetchErrors()
	}

	// Handle the refetch on mount
	useEffect(() => {
		;(async () => {
			try {
				await Promise.all([refetchData(), refetchErrorsSafe()])
				setLoading(false)
			} catch (e) {
				setError(e)
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Handle the scroll into view by anchor
	useEffect(() => {
		if (!internalLoading && anchor) {
			//timeout added for the item to finish expansion before the scroll navigates to it
			setTimeout(() => {
				const element = document.getElementById(anchor)
				if (element) element.scrollIntoView()
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchor, internalLoading])

	return { loading: internalLoading || isEmpty(formData), error }
}
