import { createContext, useContext } from 'react'
import { PricingItemFieldPath, useFieldArray } from '../../pricing-form-utils'

export type PricingSubItemListContextValue = {
	pricingSubItemsFieldArray: ReturnType<typeof usePricingSubItemsFieldArray> & {
		createPricingSubItem: () => void
	}
	pricingItemIndex: number
	pricingItemFieldPath: PricingItemFieldPath
}

export const PricingSubItemListContext = createContext<PricingSubItemListContextValue | undefined>(
	undefined,
)

export const usePricingSubItemsFieldArray = (path: PricingItemFieldPath) =>
	useFieldArray({
		name: `${path}.rates`,
	})

export const usePricingSubItemListContext = () => {
	const pricingSubItemListContext = useContext(PricingSubItemListContext)
	if (!pricingSubItemListContext)
		throw new Error('usePricingSubItemListContext must be within a PricingSubItemListContext')
	return pricingSubItemListContext
}
