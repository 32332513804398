// Either Monad (Left or Right) - names of the classes below are common
// in functional programming (keeping with convention here)

// Left represents an error path
class Left {
	constructor(val) {
		this._val = val
	}

	// Attempts to chain a Left with a reduce operation is a noop
	reduce() {
		// Do nothing
		return this
	}

	either(_, leftFn) {
		return leftFn(this._val)
	}
}

// Right represents the happy path
class Right {
	constructor(val) {
		this._val = val
	}

	either(rightFn) {
		return rightFn(this._val)
	}
}

export { Left, Right }
