import { gql } from '@apollo/client'

export default gql`
	query getNotes($rfpId: String!, $firmId: String!) {
		getNotes(rfpId: $rfpId, firmId: $firmId) {
			id
			createdByName {
				first
				last
			}
			updatedAt
			noteContents
			isOwnNote
		}
	}
`
