import { isValidElement } from 'react'
import classnames from 'classnames'
import { compose } from 'redux'

// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { fade } from 'material-ui/utils/colorManipulator'

// eslint-disable-next-line no-restricted-imports -- Please upgrade
import FlatButton from 'material-ui/FlatButton'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import RaisedButton from 'material-ui/RaisedButton'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import IconButton from 'material-ui/IconButton'

import { dangerRed, white } from '../theme/persuit-colors'
import getMuiTheme from '../theme/get-mui-theme'
import persuitTheme from '../theme/persuit-theme'
import injectSheet from '../injectSheet'
import InlineHelp from './form-inputs/inline-help.jsx'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import withTheme from '@mui/styles/withTheme'

// This is the flat button hover color style from MUI
const flatButtonColor = getMuiTheme().flatButton.buttonFilterColor
const flatButtonHoverColor = fade(flatButtonColor, 0.2)

const Button = ({
	backgroundColor,
	className,
	classes,
	disabled,
	toolTipText,
	fullWidth,
	helpText,
	icon: Icon,
	label,
	labelColor,
	noCaps,
	onClick,
	style,
	submit,
	type,
	ariaLabel,
	forceHoverState, // Makes the button look like it's in hover state. Only works on flat button for now
	labelPosition,
	danger,
	dataTestid,
	theme,
	dataTrackid,
}) => {
	let button = null

	// icon prop can be either a React component or an actual instance of a React element
	// Deal with the two cases
	// Eg. AndroidIcon
	// OR
	// <AndroidIcon style={blah} />
	let icon = Icon
	if (icon && !isValidElement(Icon)) {
		// Default colour for icons is colorSemanticPrimaryMain
		icon = <Icon className={classes.iconStyle} />
	}

	const stopPropagationOnClick = (e) => {
		e.stopPropagation()
		if (onClick) {
			onClick(e)
		}
	}

	const buttonProps = {
		className: classnames(className),
		disabled,
		toolTipText,
		fullWidth,
		icon,
		label,
		onClick: stopPropagationOnClick,
		style,
		backgroundColor,
		labelColor,
		labelPosition,
		'aria-label': ariaLabel,
		'data-testid': dataTestid,
		'data-trackid': dataTrackid,
	}

	if (submit) {
		buttonProps.type = 'submit'
	}

	switch (type) {
		case 'secondary':
			if (danger) {
				buttonProps.backgroundColor = dangerRed
				buttonProps.labelColor = white
			}
			button = (
				<RaisedButton
					{...buttonProps}
					labelColor={buttonProps.labelColor || theme.palette.primary.main}
					title={toolTipText}
				/>
			)
			break

		case 'tertiary':
			button = (
				<FlatButton
					primary={true}
					labelStyle={{
						color: !disabled && (labelColor || theme.palette.primary.main),
						textTransform: noCaps ? 'none' : 'uppercase',
					}}
					hoverColor={flatButtonHoverColor}
					{...buttonProps}
					backgroundcolor={forceHoverState ? flatButtonHoverColor : backgroundColor}
					disabled={disabled}
					title={toolTipText}
				/>
			)
			break
		case 'icon': {
			const {
				// Icon button has different props than normal buttons so we extract some
				// 1. icon as a child rather than a prop
				icon,
				// 2. fullWidth isn't supported
				fullWidth,

				...iconButtonProps
			} = buttonProps

			button = (
				<IconButton onClick={onClick} disabled={disabled} {...iconButtonProps} size="large">
					{icon}
				</IconButton>
			)
			break
		}
		case 'primary':
		default:
			button = <RaisedButton primary={true} {...buttonProps} title={toolTipText} />
	}

	if (helpText) {
		return (
			<InlineHelp message={helpText} alignVerticalCenter={true} inline={!fullWidth}>
				{button}
			</InlineHelp>
		)
	}

	return button
}

Button.defaultProps = {
	danger: false,
	disabled: false,
	fullWidth: false,
	noCaps: false,
	submit: false,
}

Button.styles = {
	iconStyle: persuitTheme.button.iconButton,
}

export default compose(injectSheet(Button.styles), withTheme)(Button)
