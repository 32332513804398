// @ts-strict-ignore
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { RfpQuestionOptionError } from '../../../types'
import {
	Box,
	Button,
	TextField,
	FormTextField,
	Typography,
	Tooltip,
	AddIcon,
	SortableVerticalList,
	SortableListItem,
	SortableDragHandler,
	IconButton,
	DeleteIcon,
} from '@persuit/ui-components'
import { getOrGenerateUUID } from '@persuit/common-utils'

const MAX_CHARS_SCALE = 30
const MAX_CHARS = 200

type OptionsField = {
	id: string
	originalOptionId?: string
	value: string
}

const getOptionError = (
	optionErrors: RfpQuestionOptionError[],
	index: number,
): RfpQuestionOptionError | null => {
	return optionErrors && optionErrors.length !== 0
		? optionErrors.find((optionError) => index === optionError.index) ?? null
		: null
}

const getMaxLength = (type) => {
	if (type === 'scale') {
		return MAX_CHARS_SCALE
	}

	return MAX_CHARS
}

const AddOptionButton = ({
	append,
	isDisabled = false,
}: {
	append?: ({ value }: { value: string }) => void
	isDisabled?: boolean
}) => {
	return (
		<Button
			color="primary"
			variant="outlined"
			onClick={
				append
					? () => {
							append({
								value: '',
							})
					  }
					: undefined
			}
			startIcon={<AddIcon />}
			disabled={isDisabled}
		>
			Add option
		</Button>
	)
}

const DisabledQuestionOptions = ({ fields }) => {
	return (
		<>
			{fields.map((field, index) => {
				return (
					<Tooltip
						key={index}
						title="Once set, the Option cannot be changed"
						placement="bottom-start"
					>
						<Box key={field.id} mb={2}>
							<TextField
								id={field.id}
								label={`Option ${index + 1}`}
								value={field.value}
								disabled={true}
							/>
						</Box>
					</Tooltip>
				)
			})}
			<Tooltip title="Once set, Options cannot be added or deleted" placement="bottom-start">
				<AddOptionButton isDisabled={true} />
			</Tooltip>
		</>
	)
}

export const ValueSelectForm = ({ parentName, optionErrors, isOriginalQuestion, type }) => {
	const methods = useFormContext()
	const { control } = methods

	const {
		fields: rawFields,
		append,
		move,
		remove,
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: `${parentName}.options`, // unique name for your Field Array
	})

	const fields = rawFields as OptionsField[]

	useEffect(() => {
		// Add two empty fields when the component mounts, if there are no fields
		if (fields.length === 0) {
			append({ value: '' }, { shouldFocus: false })
			append({ value: '' }, { shouldFocus: false })
		}
	}, [append, fields.length])

	const maxLength = getMaxLength(type)

	if (isOriginalQuestion) {
		return DisabledQuestionOptions({ fields })
	}

	const removeInput = (i: number) => () => {
		remove(i)
	}

	const getPosition = (id) => fields.findIndex((q) => getOrGenerateUUID(q) === id) + 1
	const fieldsCount = fields.length
	const fieldsMap = new Map(fields.map((option) => [option.id, option]))
	const getDraggableOptionValue = (id: string) =>
		`${getPosition(id)} ${fieldsMap.get(`${id}`)?.value ?? ''}`

	return (
		<>
			<Typography variant="body1Semibold" mb={2}>
				Add options
			</Typography>
			<SortableVerticalList
				items={fields.map(({ id, value }) => ({ id, value }))}
				onMove={move}
				accessibility={{
					announcements: {
						onDragStart({ active }) {
							return `Picked up draggable option ${getDraggableOptionValue(
								`${active.id}`,
							)}. It is in position ${getPosition(active.id)} of ${fieldsCount}`
						},
						onDragOver({ active, over }) {
							if (over) {
								return `Draggable option ${getDraggableOptionValue(
									`${active.id}`,
								)} was moved into option ${getPosition(over.id)} of ${fieldsCount}`
							}
						},
						onDragEnd({ active, over }) {
							if (over) {
								return `Draggable option ${getDraggableOptionValue(
									`${active.id}`,
								)} was dropped at option ${getPosition(over.id)} of ${fieldsCount}`
							}
						},
						onDragCancel({ active }) {
							return `Dragging was cancelled. Draggable option ${getDraggableOptionValue(
								`${active.id}`,
							)} was dropped.`
						},
					},
					screenReaderInstructions: {
						draggable: `To pick up a draggable option, press space or enter.
							While dragging, use the arrow keys to move the option in up/down direction.
							Press space or enter again to drop the option in its new position, or press escape to cancel.`,
					},
				}}
			>
				{fields.map((field, index) => {
					return (
						<SortableListItem id={field.id} key={field.id}>
							{({ attributes }) => (
								<Box key={field.id} mb={2} display="flex" alignItems="center">
									<SortableDragHandler
										id={field.id}
										aria-label={`Drag handler for option ${index + 1}`}
										{...attributes}
										sx={{ mr: 1 }}
									/>
									<FormTextField
										id={field.id}
										required={true}
										label={`Option ${index + 1} (max ${maxLength} chars)`}
										error={!!getOptionError(optionErrors, index)?.value ?? false}
										helperText={getOptionError(optionErrors, index)?.value ?? null}
										name={`${parentName}.options.${index}.value`}
										inputProps={{
											maxLength,
											'aria-label': `Option ${index + 1} (max ${maxLength} chars)`,
										}}
										sx={{
											width: maxLength === MAX_CHARS_SCALE ? '30%' : '80%',
										}}
									/>
									{fields.length > 2 && (
										<IconButton
											sx={{
												m: 1,
											}}
											onClick={removeInput(index)}
											aria-label={`Delete option ${index + 1}`}
											title={`Delete option ${index + 1}`}
											color="primary"
										>
											<DeleteIcon />
										</IconButton>
									)}
								</Box>
							)}
						</SortableListItem>
					)
				})}
			</SortableVerticalList>
			<AddOptionButton append={append} />
		</>
	)
}
