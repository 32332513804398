import { format } from 'date-fns'

export const makeFormatMessageAtSafe =
	(formatString: string, fallback = '') =>
	(messageAt?: string): string => {
		if (!messageAt) return fallback
		try {
			const epoch = parseInt(messageAt, 10)
			return format(epoch, formatString)
		} catch (e) {
			return fallback
		}
	}
