import * as React from 'react'

import {
	ProposalQuickRevisionStoreProvider,
	getPhaseValidDeliverables,
	getRateCardDeliverables,
} from '@persuit/ui-shared-components'

type OptionQuickPriceRevisionContainerProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deliverables: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	proposal: any
	children: React.ReactNode
	currency: string
	totalPriceNotRequired: boolean
}

export const OptionQuickPriceRevisionContainer = ({
	deliverables,
	proposal,
	currency,
	children,
	totalPriceNotRequired,
}: OptionQuickPriceRevisionContainerProps) => {
	const responseToDeliverables = proposal?.responseToDeliverables ?? []

	const pricingItemLookup = getPhaseValidDeliverables({
		deliverables,
		responseToDeliverables,
	})
	const rateItemLookup = getRateCardDeliverables({
		deliverables,
		responseToDeliverables,
	})

	return (
		<ProposalQuickRevisionStoreProvider
			initialState={{
				deliverables,
				proposal,
				currency,
				pricingItemLookup,
				rateItemLookup,
				totalPriceValue: proposal?.totalPriceValue ?? 0,
				totalPriceNotRequired,
			}}
		>
			{children}
		</ProposalQuickRevisionStoreProvider>
	)
}
