import { v4 as uuid } from 'uuid'

/**
 * Return _id, id or uuid property of a given object
 * Otherwise return a new uuid
 */
export const getOrGenerateUUID = (object?: Record<string, unknown>): string => {
	if (!object) {
		return uuid()
	}

	if (object._id && typeof object._id === 'string') {
		return object._id
	} else if (object.uuid && typeof object.uuid === 'string') {
		return object.uuid
	} else if (object.id && typeof object.id === 'string') {
		return object.id
	} else {
		return uuid()
	}
}
