import { forwardRef, useState, useEffect } from 'react'

export {
	gridClasses,
	GridColumnMenuContainer,
	gridNumberComparator,
	GridTripleDotsVerticalIcon,
	GridFooterContainer,
	useGridApiContext,
	useGridSelector,
	gridRowCountSelector,
	gridExpandedSortedRowEntriesSelector,
	selectedGridRowsCountSelector,
	gridFilterModelSelector,
	gridSortModelSelector,
	GridToolbarContainer,
	GridToolbarQuickFilter,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	useGridApiRef,
	GridEditInputCell,
	GridColumnMenuColumnsItem,
	GridRow,
} from '@mui/x-data-grid-premium'
export type {
	GridApi,
	GridValidRowModel,
	GridCellParams,
	GridColDef,
	GridColType,
	GridColTypeDef,
	GridColumnMenuProps,
	GridRenderCellParams,
	GridRowId,
	GridSortDirection,
	GridSortItem,
	GridSortModel,
	GridColumnHeaderParams,
	GridFooterContainerProps,
	GridToolbarProps,
	GridToolbarContainerProps,
	GridToolbarQuickFilterProps,
	GridPreProcessEditCellProps,
	GridRenderEditCellParams,
	GridRowModel,
	GridActionsColDef,
	GridRowParams,
	GridRowModelUpdate,
	GridRowProps,
} from '@mui/x-data-grid-premium'

import {
	DataGridPremium as Grid,
	DataGridPremiumProps as Props,
	GridApi,
} from '@mui/x-data-grid-premium'

import { SROnly } from '../sr-only'

import { isNotNil } from '@persuit/common-utils'

export type DataGridPremiumProps = Props

export const DataGridPremium = forwardRef<HTMLDivElement, DataGridPremiumProps>(
	({ 'aria-label': ariaLabel, apiRef, columns, ...props }: DataGridPremiumProps, ref) => {
		const [api, setApi] = useState<GridApi | null>(null)

		return (
			<>
				{api && props.cellSelection && <SelectionAnnouncements api={api} />}

				<Grid
					ref={(container) => {
						if (container && ariaLabel) {
							const grid = container?.querySelector(`[role="grid"]`)
							grid?.setAttribute('aria-label', ariaLabel)
						}

						if (typeof ref === 'function') {
							ref(container)
						} else if (ref && typeof ref === 'object') {
							ref.current = container
						}
					}}
					{...props}
					apiRef={
						((ref: GridApi | null) => {
							setApi(ref)
							if (apiRef) {
								apiRef.current = ref as any
							}
						}) as any
					}
					columns={columns}
				/>
			</>
		)
	},
)

type SelectionAnnouncementsProps = {
	api: GridApi
}

/**
 * Renders an alert to annouce changes in the cell selection state
 */
export const SelectionAnnouncements = ({ api }: SelectionAnnouncementsProps) => {
	const [selectionRange, setSelectionRange] = useState<{
		rowIndexStart: number
		rowIndexEnd: number
		columnIndexStart: number
		columnIndexEnd: number
	} | null>(null)

	useEffect(() => {
		if (api) {
			const unsub = api.subscribeEvent('cellSelectionChange', (selection) => {
				if (!selection || Object.keys(selection).length === 0) return

				const rows = api.getAllRowIds()
				const rowIndexes = Object.keys(selection)
					.map((rowId) => rows.findIndex((id) => id === rowId))
					.filter(isNotNil)

				const rowIndexStart = Math.min(...rowIndexes)
				const rowIndexEnd = Math.max(...rowIndexes)

				const firstRow = Object.values(selection)[0]
				const columnIndexes = Object.keys(firstRow).map((colKey) => api.getColumnIndex(colKey))
				const columnIndexStart = Math.min(...columnIndexes)
				const columnIndexEnd = Math.max(...columnIndexes)

				setSelectionRange({
					rowIndexStart,
					rowIndexEnd,
					columnIndexStart,
					columnIndexEnd,
				})
			})
			return unsub
		}
	}, [api])

	return (
		<>
			{selectionRange &&
				(selectionRange.rowIndexStart !== selectionRange.rowIndexEnd ||
					selectionRange.columnIndexStart !== selectionRange.columnIndexEnd) && (
					<SROnly role="alert">
						{`Selection from column ${selectionRange.columnIndexStart}, row ${selectionRange.rowIndexStart} to column ${selectionRange.columnIndexEnd}, row ${selectionRange.rowIndexEnd}`}
					</SROnly>
				)}
		</>
	)
}
