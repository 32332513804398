import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from './autocomplete'
import { RHFFormComponentProps, useFormComponent } from '../../utilities'
import { CheckBoxOutlineBlankIcon, CheckBoxIcon } from '../../icons'
import { TextField } from '../text-field'
import { Checkbox } from '../checkbox'

export type FormMultiAutocompleteProps<T> = RHFFormComponentProps<
	T[],
	Omit<AutocompleteProps<T, true, false, false>, 'renderInput' | 'getOptionLabel'> & {
		renderInput?: AutocompleteProps<T, true, false, false>['renderInput']
		getOptionLabel: NonNullable<AutocompleteProps<T, true, false, false>['getOptionLabel']>
		label: string
	}
>

export const FormMultiAutocomplete = <T,>({
	name,
	options,
	defaultValue,
	getOptionLabel,
	renderInput,
	label,
	onChange: onChangeProp,
	...props
}: FormMultiAutocompleteProps<T>) => {
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
	})
	const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			{...params}
			error={!!error?.message}
			helperText={error?.message}
			variant="outlined"
			label={label}
		/>
	)

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
	const checkedIcon = <CheckBoxIcon fontSize="small" />

	return (
		<Autocomplete
			getOptionLabel={getOptionLabel}
			disableCloseOnSelect={true}
			renderInput={renderInput ?? defaultRenderInput}
			renderOption={(props, option: T, { selected }) => (
				<li {...props}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{getOptionLabel(option)}
				</li>
			)}
			value={value}
			multiple={true}
			{...props}
			onChange={(event, newValue, reason, details) => {
				onChange(newValue)
				onChangeProp?.(event, newValue, reason, details)
			}}
			options={options}
		/>
	)
}
