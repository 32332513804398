import { useId, useState } from 'react'
import * as React from 'react'
import {
	Accordion,
	AccordionDetails,
	AccordionDetailsProps,
	AccordionProps,
	AccordionSummary,
	AccordionSummaryProps,
} from '../accordion'
import { Box, BoxProps } from '../box'
import { useTheme } from '../../theme'
import { TopLeftErrorOutlineIcon } from '../../icons'
import { SvgIconProps } from '../svg'
import { SROnly } from '../sr-only'

export type ExpandableCardProps = {
	expandIconButtonLabel?: AccordionSummaryProps['iconButtonLabel']
	/**
	 * Accordion summary props, required for accessibility purpose.
	 * id is meant for AccordionSummary while
	 *
	 * It is also beneficial to provide aria-label if you are going to have deleteIcon, dragIcon as button.
	 * See the tests for more information.
	 */
	summaryProps?: {
		'aria-label'?: string
		'data-testid'?: string
	}
	/** Prefix content in Accordion summary */
	summaryPrefix?: React.ReactNode
	/**
	 * Accordion summary content, displayed in the middle of prefix & suffix
	 */
	summary: React.ReactNode
	/** Suffix content in Accordion summary */
	summarySuffix?: React.ReactNode
	/**
	 * Default to false
	 */
	hideSummaryOnExpand?: boolean
	/**
	 * Default to false
	 */
	hideSummaryPrefixOnExpand?: boolean
	/**
	 * Default to false
	 */
	hideSummarySuffixOnExpand?: boolean
	/**
	 * Accordion details, can either be a ReactNode or a function that return ReactNode
	 */
	details: React.ReactNode | (() => React.ReactNode)
	detailsProps?: Partial<AccordionDetailsProps>
	/**
	 * Should the card be expanded by default. Default = false
	 */
	defaultExpanded?: boolean
	/**
	 * Should the card be expanded if it's controlled
	 */
	expanded?: boolean
	/**
	 * onToggle function to be triggered if component is controlled
	 */
	onToggle?: () => void
	/**
	 * should show left border when focussed on card. Default = true
	 */
	borderOnFocus?: boolean
	hasError?: boolean
	errorIconProps?: SvgIconProps
	containerProps?: BoxProps
	/**
	 * Should render accordion details when collapse. Default false
	 */
	shouldRenderDetailsOnCollapse?: boolean
	// set the size of the expand/collapse IconButton
	expandIconSize?: 'small' | 'medium' | 'large'
} & Omit<AccordionProps, 'children'>

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* We have to disable the above two rules because we want to maintain old behavior
 * where ExpandableCard title is clickable to expand/collapse.
 * However, we need to have generic way to prevent onClick events from bubling up
 * by wrapping children container with e.stopPropagation() which those two accessibility rules doesn't like */
export const ExpandableCard = ({
	expandIconButtonLabel,
	summaryPrefix,
	summarySuffix,
	summaryProps,
	summary,
	details,
	detailsProps,
	defaultExpanded = false,
	hideSummaryPrefixOnExpand = false,
	hideSummaryOnExpand = false,
	hideSummarySuffixOnExpand = false,
	hasError,
	errorIconProps,
	containerProps,
	expanded,
	onToggle,
	borderOnFocus = true,
	shouldRenderDetailsOnCollapse = false,
	expandIconSize = 'large',
	...rest
}: ExpandableCardProps) => {
	const isControlled = expanded !== undefined
	const [isDefaultExpanded, setIsDefaultExpanded] = useState(defaultExpanded)
	const [borderLeftColor, setBorderLeftColor] = React.useState('transparent')
	const theme = useTheme()

	const handleChange = () => {
		if (isControlled && typeof onToggle === 'function') return onToggle()
		setIsDefaultExpanded(!isDefaultExpanded)
	}

	const isOpen = isControlled && typeof onToggle === 'function' ? expanded : isDefaultExpanded
	const shouldHideSummary = hideSummaryOnExpand && isOpen
	const shouldHideSummaryPrefix = hideSummaryPrefixOnExpand && isOpen
	const shouldHideSummarySuffix = hideSummarySuffixOnExpand && isOpen
	const errorDescriptionId = useId()

	return (
		/** Adding onClick event to support  */
		<Box
			position="relative"
			borderRadius="5px"
			sx={{
				borderLeft: '3px solid',
				borderLeftColor,
			}}
			onFocus={() => {
				if (borderOnFocus) setBorderLeftColor(theme.palette.primary.main)
			}}
			onBlur={() => {
				if (borderOnFocus) setBorderLeftColor('transparent')
			}}
			{...containerProps}
		>
			{hasError && (
				<Box position="relative">
					<TopLeftErrorOutlineIcon aria-hidden={true} {...errorIconProps} />
					<SROnly id={errorDescriptionId}>Error presents - </SROnly>
				</Box>
			)}
			<Accordion
				expanded={isOpen}
				onChange={handleChange}
				{...rest}
				sx={{
					'& .MuiExpansionPanelSummary-content': {
						margin: 0,
					},
					...rest.sx,
				}}
			>
				<AccordionSummary
					iconButtonLabel={expandIconButtonLabel}
					data-testid={summaryProps?.['data-testid']}
					IconButtonProps={{
						'data-testid': 'expand-icon',
						'aria-label': summaryProps?.['aria-label'] ?? 'Expand',
						size: expandIconSize,
						color: 'primary',
					}}
				>
					{({ headerId }) => (
						<Box display="flex" justifyContent="space-between" flexGrow={1}>
							<Box display="flex" alignItems="center" flexGrow={1}>
								{shouldHideSummaryPrefix ? <SROnly>{summaryPrefix}</SROnly> : summaryPrefix}

								<Box component="span" flexGrow="1" id={headerId}>
									{shouldHideSummary ? <SROnly>{summary}</SROnly> : summary}
								</Box>
							</Box>
							<Box display="flex" alignItems="center">
								{shouldHideSummarySuffix ? <SROnly>{summarySuffix}</SROnly> : summarySuffix}
							</Box>
						</Box>
					)}
				</AccordionSummary>

				<AccordionDetails
					unmountOnExit={!shouldRenderDetailsOnCollapse}
					aria-labelledby={hasError ? errorDescriptionId : undefined}
				>
					{typeof details === 'function' ? details() : details}
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}

ExpandableCard.displayName = 'ExpandableCard'
