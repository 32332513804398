import { Box, styled, Typography, Spacer } from '@persuit/ui-components'
import constants from '../../../../common/data/pricing-models'
import { PricingCardItemNumbering } from '@persuit/ui-shared-components'
import {
	isPercentagePricingModel,
	formatPercentageValueWithZero,
	formatDuration,
} from '../common/utilities.js'
import formatPriceCurrency from '../../../utils/price-and-currency'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { grey } from '@mui/material/colors'

const StlyedItem = styled('div')`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${grey[500]};
`
const StlyedItemLeft = styled('div')`
	align-content: flex-start;
`
const StlyedItemRight = styled('div')`
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	justify-content: space-between;
	width: 100%;
`
const StlyedItemTop = styled('div')`
	display: flex;
	justify-content: space-between;
	width: 100%;
`
const StlyedItemBottom = styled('div')`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

/*

Used in a comparitor cell only
Looks like this:

Item 1                  $Fee
Pricing Model___________50 hrs
Item 2                  $Fee
Pricing Model___________30 hrs

*/

const SubItemsListCell = ({ phaseIndex, responseToDeliverable, currency, t, phaseDuration }) => {
	const firmSuppliedRates = responseToDeliverable.firmSuppliedRates
		? responseToDeliverable.firmSuppliedRates
		: []
	const responseToRates = responseToDeliverable.responseToRates
		? responseToDeliverable.responseToRates
		: []
	const combinedRates = {
		total: {
			rate: responseToDeliverable.rate,
			hours: responseToDeliverable.hours,
			pricingModel: responseToDeliverable.pricingModel
				? responseToDeliverable.pricingModel
				: 'none',
		},
		rates: [
			...responseToRates,
			...firmSuppliedRates.map((fsr) => ({ ...fsr, firmSupplied: true })),
		],
	}

	function displayPricingModel(rate) {
		if (rate.pricingModel) {
			if (rate.firmSupplied) {
				return t(`feeBreakdown.pricingModels.firmItem.${rate.pricingModel}`)
			} else if (!rate.firmSupplied) {
				return t(`feeBreakdown.pricingModels.firmSubItem.${rate.pricingModel}`)
			}
		}

		return '-'
	}

	if (!combinedRates || combinedRates.rates.length === 0) {
		return null
	}

	return (
		<div data-testid="sub-items-list-cell" role="list" aria-label="Sub-items">
			{combinedRates &&
				combinedRates.rates.map((r, i) => {
					let rate = ''
					if (r.rate && r.pricingModel !== constants.INCLUDEDINITEMTOTAL) {
						rate = formatPriceCurrency(currency, r.rate, false)
					}
					if (!r.rate && r.pricingModel !== constants.INCLUDEDINITEMTOTAL) {
						rate = '-'
					}

					const isPercentage = isPercentagePricingModel(r.pricingModel)
					const itemPercentage = formatPercentageValueWithZero(r.percentage)

					return (
						<Box key={`SubItemsListCell-${i}`} role="listitem">
							<StlyedItem>
								{/* left  */}
								<StlyedItemLeft>
									<PricingCardItemNumbering
										parentIndex={phaseIndex}
										childIndex={i}
										style={{ padding: '0px 10px 5px 0px' }}
									/>
								</StlyedItemLeft>

								{/* right */}
								<StlyedItemRight>
									{/* top */}
									<StlyedItemTop>
										{/* left */}
										<Typography variant="body2" style={{ wordBreak: 'break-word' }}>
											{r.label ? r.label : '-'}
										</Typography>
										{/* right */}
										<Typography
											data-testid="sub-item-price"
											variant="body2"
											style={{ paddingLeft: '1rem' }}
										>
											{isPercentage ? itemPercentage : rate}
										</Typography>
									</StlyedItemTop>

									{/* bottom */}
									<StlyedItemBottom>
										{/* left */}
										<Typography variant="body2" color="text.secondary">
											{displayPricingModel(r)}
										</Typography>

										{/* right */}
										{!isPercentage && (
											<Typography variant="body2" color="text.secondary">
												{formatDuration(r.hours, phaseDuration)}
											</Typography>
										)}
									</StlyedItemBottom>
								</StlyedItemRight>
							</StlyedItem>
							<Spacer />
						</Box>
					)
				})}
		</div>
	)
}

export default SubItemsListCell
