import * as React from 'react'

import {
	useTheme as useThemeMUI,
	createTheme,
	ThemeProvider as MUIThemeProvider,
	StyledEngineProvider,
	styled,
	css,
	alpha,
} from '@mui/material/styles'
import { theme as defaultTheme } from './theme-config'
import type { PersuitTheme } from './theme-config'

import GlobalStyles from '@mui/material/GlobalStyles'

import { default as MUIWithStyles } from '@mui/styles/withStyles'

import { default as MUIMakeStyles } from '@mui/styles/makeStyles'

import { LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GlobalCSS } from './global-css'

export type ThemeProviderProps = {
	children: React.ReactNode
	theme?: Partial<PersuitTheme>
	includeGlobalStyles?: boolean
}

export const ThemeProvider = ({
	children,
	includeGlobalStyles = true,
	theme = defaultTheme,
}: ThemeProviderProps) => {
	return (
		<StyledEngineProvider>
			<MUIThemeProvider theme={theme}>
				{includeGlobalStyles && GlobalCSS}
				<LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
			</MUIThemeProvider>
		</StyledEngineProvider>
	)
}

export function useTheme<T = PersuitTheme>(): T {
	return useThemeMUI<T>()
}

/** @deprecated Please make use of `styled` or `sx` API instead. JSS is now deprecated and will be removed in future version */
export const withStyles = MUIWithStyles
/** @deprecated Please make use of `styled` or `sx` API instead. JSS is now deprecated and will be removed in future version */
export const makeStyles = MUIMakeStyles

export { theme, themeOptions } from './theme-config'
export type { PersuitTheme } from './theme-config'
export { createTheme }
export { styled, GlobalStyles, css, alpha }
