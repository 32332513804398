import { useState, useEffect, Fragment } from 'react'
import { RfpPreview } from '../../rfp-preview'
import {
	LoadingSpinner,
	styled,
	Button,
	IconButton,
	CloseIcon,
	Drawer,
	PictureAsPdfIcon,
	PrintOnly,
	Box,
	Divider,
	useTheme,
	Typography,
} from '@persuit/ui-components'
import { RfpTemplate } from '../types'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'

const Container = styled('div')`
	width: 60vw;
	height: auto;
`

const Header = styled('div')`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
	color: ${(props) => props.theme.palette.primary.main};
`

const StyledDrawer = styled(Drawer)`
	@media print {
		display: none;
	}
`

type PreviewDrawerProps = {
	previewOpen: boolean
	closePreview: () => void
	refetchRfpTemplate: () => void
	rfpTemplate: RfpTemplate
}

export const PreviewDrawer = ({
	previewOpen,
	closePreview,
	rfpTemplate: _rfpTemplate,
	refetchRfpTemplate,
}: PreviewDrawerProps) => {
	const theme = useTheme()
	const [loading, setLoading] = useState(false)
	const [renderPrint, setRenderPrint] = useState(false)
	const rfpTemplate = rfpWithDefaultValues(_rfpTemplate)

	useEffect(() => {
		const loadData = async () => {
			setRenderPrint(false)
			setLoading(true)
			await refetchRfpTemplate()
			setLoading(false)
		}
		if (previewOpen) loadData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previewOpen])

	useEffect(() => {
		if (renderPrint) {
			window.print()
			setRenderPrint(false)
		}
	}, [renderPrint])

	const isSystemTemplate = rfpTemplate.templateType === 'system'

	return (
		<Fragment>
			<StyledDrawer
				anchor="right"
				open={previewOpen}
				onClose={closePreview}
				aria-busy={loading ? 'true' : 'false'}
			>
				<Container>
					<Header sx={{ p: 2 }}>
						<Box display="flex" alignItems="center">
							<IconButton onClick={closePreview} aria-label="Close preview drawer" size="large">
								<StyledCloseIcon />
							</IconButton>
							<Divider
								orientation="vertical"
								sx={{ borderColor: theme.palette.black, margin: '0px 16px', height: '32px' }}
							></Divider>
							<Box>
								<Typography variant="body1Semibold">Template preview</Typography>
								<Typography variant="body1">{rfpTemplate.templateTitle}</Typography>
							</Box>
						</Box>

						{!isSystemTemplate && (
							<Button
								variant="text"
								color="primary"
								startIcon={<PictureAsPdfIcon />}
								onClick={() => setRenderPrint(true)}
								disabled={loading}
							>
								Print/Pdf
							</Button>
						)}
					</Header>
					<Divider></Divider>
					{loading ? (
						<LoadingSpinner />
					) : (
						<RfpPreview document={rfpTemplate} containerProps={{ p: 2 }} isTemplate={true} />
					)}
				</Container>
			</StyledDrawer>
			{renderPrint && (
				<PrintOnly>
					<RfpPreview document={rfpTemplate} isTemplate={true} />
				</PrintOnly>
			)}
		</Fragment>
	)
}

/**
 * Set default values to avoid non null issues, could potentially remove most of this when the schema is updated
 */
function rfpWithDefaultValues(rfp: RfpTemplate) {
	return {
		...rfp,
		rateReview: {
			...rfp.rateReview,
			requestNamedTimekeepers: rfp.rateReview?.requestNamedTimekeepers ?? false,
			details: rfp?.rateReview?.details ?? '',
			currency: rfp?.rateReview?.currency ?? '',
			currencies: [],
			timekeepers:
				rfp?.rateReview?.timekeepers?.filter(isNotNil).map((timekeeper) => ({
					_id: timekeeper._id,
					timekeeper: timekeeper.timekeeper ?? '',
					timekeeperLabel: timekeeper.timekeeperLabel ?? '',
				})) ?? [],
		},
		detail: {
			...rfp.detail,
			files:
				rfp.detail?.files
					?.filter(isNotNil)
					.filter(isNotNilProp('_id'))
					.map((file) => ({
						...file,
						filename: file.filename ?? '',
						fileStatus: file.fileStatus ?? '',
					})) ?? [],
			deliverables: [
				...(rfp.detail?.deliverables?.map((deliverable) => ({
					...deliverable,
					rates: deliverable.rates?.filter(isNotNil) ?? [],
				})) ?? []),
			],
			deliverablesV2: [
				...(rfp.detail?.deliverablesV2?.map((deliverable) => {
					if (deliverable.__typename === 'Deliverable') {
						return {
							...deliverable,
							rates: deliverable.rates?.filter(isNotNil) ?? [],
						}
					}
					return {
						...deliverable,
						deliverables: deliverable.deliverables.map((innerDeliverable) => ({
							...innerDeliverable,
							rates: innerDeliverable.rates?.filter(isNotNil) ?? [],
						})),
					}
				}) ?? []),
			],
			questionGroups:
				rfp.detail?.questionGroups?.filter(isNotNil).map((questionGroup) => ({
					_id: questionGroup._id,
					hidden: !!questionGroup.hidden,
					name: questionGroup.name ?? '',
				})) ?? [],
			questions:
				rfp.detail?.questions?.filter(isNotNil).map((question) => ({
					...question,
					_id: question._id ?? '',
					type: question.type ?? '',
					groupId: question.groupId ?? '',
				})) ?? [],
			diversity: {
				...rfp.detail?.diversity,
				diversityDefinitions: rfp.detail?.diversity?.diversityDefinitions?.filter(isNotNil) ?? [],
			},
		},
		counterParties: rfp.counterParties?.filter(isNotNil) ?? [],
		files:
			rfp.files
				?.filter(isNotNil)
				.filter(isNotNilProp('_id'))
				.map((file) => ({
					...file,
					filename: file.filename ?? '',
					fileStatus: file.fileStatus ?? '',
				})) ?? [],
	}
}
