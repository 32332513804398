// @ts-strict-ignore
import { useMemo, useEffect, useState, useRef } from 'react'
import {
	DndContext,
	SortableContext,
	SortableList,
	DragOverlay,
	Paper,
	Typography,
	useFormContext,
	Box,
	ExpandCollapseProvider,
} from '@persuit/ui-components'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { getOrGenerateUUID } from '@persuit/common-utils'
import {
	rectIntersection,
	pointerWithin,
	DragEndEvent,
	DragOverEvent,
	defaultDropAnimationSideEffects,
	Active,
} from '@dnd-kit/core'
import { createPortal } from 'react-dom'
import type { Modifier } from '@dnd-kit/core'

import {
	RfpQuestionError,
	RfpQuestion,
	RfpQuestionGroup,
	RfpQuestionGroupError,
} from '../../../types'
import { useQuestionGroupsFieldArray, useQuestionFieldArray } from './question-list-form-utils'
import { ExpandCollapseButtons } from './expand-collapse-buttons'
import { QuestionOverlay } from './question-overlay'
import { GroupOverlay } from './question-group-overlay'
import { QuestionGroup } from './question-group'
import { QuestionListActionButtons } from './question-list-action-buttons'

type ID = string | number

type RfpQuestionGroupForm = RfpQuestionGroup & {
	id: string
	name: string
}

type RfpQuestionForm = RfpQuestion & {
	id: string
	name: string
}

type QuestionListProps = {
	questionErrors: RfpQuestionError[] | null
	questionGroupErrors: RfpQuestionGroupError[] | null
}

const enrichItems = (items) =>
	(items ?? []).map((item) => ({
		id: getOrGenerateUUID(item),
		...item,
	}))

const getValuesWithIds = (
	values,
): { questions: RfpQuestionForm[]; questionGroups: RfpQuestionGroupForm[] } => {
	const questions = enrichItems(values.questions)
	const questionGroups = enrichItems(values.questionGroups)
	return { questions, questionGroups }
}

const DRAGGING_GROUPED_ITEM_OUT_TOP_THRESHOLD = 30
const DRAGGING_GROUPED_ITEM_OUT_BOTTOM_THRESHOLD = 40

export const QuestionList = ({ questionErrors, questionGroupErrors }: QuestionListProps) => {
	const methods = useFormContext()
	const getValues = methods.getValues
	const { move: moveQuestions, replace: replaceQuestions } = useQuestionFieldArray()
	const {
		move: moveGroups,
		remove: removeGroup,
		append: appendQuestionGroup,
		insert: insertQuestionGroup,
		replace: replaceGroups,
	} = useQuestionGroupsFieldArray()
	const { append: appendQuestion } = useQuestionFieldArray()

	const { questions, questionGroups } = getValuesWithIds(getValues())

	const { questionsMap } = questions.reduce(
		(acc, question, index) => {
			acc.questionsMap.set(question.id, {
				item: question,
				index,
				groupIndex: acc.questionsInGroupMap.get(question.groupId)?.length ?? 0,
			})
			acc.questionsInGroupMap.set(question.groupId, [
				...(acc.questionsInGroupMap.get(question.groupId) ?? []),
				question.id,
			])
			return acc
		},
		{ questionsMap: new Map(), questionsInGroupMap: new Map() },
	)
	const questionGroupsMap = new Map(
		questionGroups.map((questionGroup, index) => [
			questionGroup.id,
			{ item: questionGroup, index },
		]),
	)

	const initialExpandedState = useMemo(
		() =>
			questions.reduce((acc, curr) => {
				acc[getOrGenerateUUID(curr)] = false
				return acc
			}, {}),
		[questions],
	)

	const expandedState = initialExpandedState

	const duplicateGroup = (groupId) => {
		const newGroupId = `tmp_${getOrGenerateUUID()}`
		const questionGroupToClone = questionGroupsMap.get(groupId)

		if (!questionGroupToClone) {
			return
		}

		const existingIndex = findContainerIndexById(groupId)

		insertQuestionGroup(existingIndex + 1, {
			_id: newGroupId,
			name: `(COPY) ${questionGroupToClone.item.name}`,
			hidden: questionGroupToClone.item.hidden,
			isNewGroup: true,
		})

		// Duplicate questions
		questions
			.filter((question) => question.groupId === groupId)
			.map(({ id, originalQuestionId, options, ...question }) => {
				return {
					...question,
					options: (options ?? []).map(({ _id, originalOptionId, ...option }) => ({
						...option,
					})) as any,
				}
			})
			.forEach((question) => {
				const newId = getOrGenerateUUID()
				appendQuestion({
					...question,
					_id: newId,
					groupId: newGroupId,
					isNewQuestion: true,
				})
			})
	}

	const [isDragging, setIsDragging] = useState(false)
	const [activeDragTarget, setActiveDragTarget] = useState<Active | null>(null)
	const states = Object.values(expandedState)
	const allExpanded = states.every((v) => v === true)
	const shouldScrollCollapseExpandBox = useRef(false)

	useEffect(() => {
		if (shouldScrollCollapseExpandBox.current && allExpanded) {
			document.getElementById('expand-collapse-box')?.scrollIntoView()
			shouldScrollCollapseExpandBox.current = false
		}
	}, [allExpanded])

	const deleteGroup = (groupIndex) => {
		const currentGroup = questionGroups[groupIndex]
		const remainingQuestions = questions.filter(({ groupId }) => {
			return groupId !== currentGroup.id
		})
		replaceQuestions(remainingQuestions)
		removeGroup(groupIndex)
	}

	/**
	 * Return container through provided ID. A container can be a question group or floating question
	 */
	const findContainerById = (
		id?: ID,
	): {
		container: any
		containerType?: 'question' | 'group'
	} => {
		if (!id) {
			return { container: null }
		}
		const question = questionsMap.get(id.toString())
		if (question) {
			return {
				container: question.item,
				containerType: 'question',
			}
		}

		const group = questionGroupsMap.get(id.toString())
		if (group) {
			return { container: group.item, containerType: 'group' }
		}
		return { container: null }
	}

	/**
	 * Return container's index through provided ID. A container can be a question group or floating question
	 */
	const findContainerIndexById = (id?: ID): number => {
		if (!id) {
			return -1
		}
		const question = questionsMap.get(id.toString())
		if (question) {
			return question.index
		}

		const group = questionGroupsMap.get(id.toString())
		if (group) {
			return group.index
		}
		return -1
	}

	// Drops questions (moves them around)
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event

		setActiveDragTarget(null)
		setIsDragging(false)

		if (!over) {
			return
		}

		// Get the actual items
		const { container: activeItem, containerType: activeContainerType } = findContainerById(
			active.id,
		)
		const { container: overItem } = findContainerById(over.id)

		if (!activeItem || !overItem) {
			return
		}

		if (activeContainerType === 'group') {
			const activeIndex = findContainerIndexById(active.id)
			const overIndex = findContainerIndexById(over.id)
			moveGroups(activeIndex, overIndex)
			return
		}

		if (activeContainerType === 'question') {
			const { container: activeItemGroup } = findContainerById(activeItem.groupId)
			if (!activeItemGroup?.hidden) {
				return
			}
			// Remove hidden groups without questions
			const cleanGroups = questionGroups.filter((questionGroup) => {
				return (
					!questionGroup.hidden || !!questions.find(({ groupId }) => groupId === questionGroup.id)
				)
			})
			replaceGroups(cleanGroups)
		}
	}

	// Move between groups
	const handleDragOver = (event: DragOverEvent) => {
		const { active, over } = event

		if (!over) {
			return
		}
		// Get the actual items
		const { container: activeItem, containerType: activeContainerType } = findContainerById(
			active.id,
		)
		const { container: overItem, containerType: overContainerType } = findContainerById(over.id)

		if (!activeItem || !overItem) {
			return
		}
		// Handle moving groups around floating questions
		if (activeContainerType === 'group') {
			if (overContainerType === 'question') {
				const { container: overItemGroup } = findContainerById(overItem.groupId)
				if (overItemGroup?.hidden) {
					const activeGroupIdx = findContainerIndexById(activeItem.id)
					const overItemGroupIdx = findContainerIndexById(overItemGroup.id)
					moveGroups(activeGroupIdx, overItemGroupIdx)
				}
			}
			return
		}

		// Handle same group
		if (overContainerType === 'question') {
			// If same group
			if (activeItem.groupId === overItem.groupId) {
				const activeIndex = findContainerIndexById(activeItem.id)
				const overIndex = findContainerIndexById(overItem.id)
				if (activeIndex === -1 || overIndex === -1 || activeIndex === overIndex) {
					return
				}
				moveQuestions(activeIndex, overIndex)
				return
			}

			// Move floating questions
			const { container: activeGroup } = findContainerById(activeItem.groupId)
			const { container: overItemGroup } = findContainerById(overItem.groupId)
			if (activeGroup?.hidden && overItemGroup?.hidden) {
				const activeGroupIdx = findContainerIndexById(activeGroup.id)
				const overItemGroupIdx = findContainerIndexById(overItemGroup.id)
				moveGroups(activeGroupIdx, overItemGroupIdx)
			}
		}

		// Move to a different group
		if (overContainerType === 'group') {
			if (activeItem.groupId !== overItem.id) {
				const clonedQuestions = [...questions].filter(({ id }) => id !== activeItem.id)
				const updatedQuestions = [...clonedQuestions, { ...activeItem, groupId: overItem.id }]
				replaceQuestions(updatedQuestions)

				// Remove hidden groups without questions
				const cleanGroups = questionGroups.filter((questionGroup) => {
					return (
						!questionGroup.hidden ||
						!!updatedQuestions.find(({ groupId }) => groupId === questionGroup.id)
					)
				})
				replaceGroups(cleanGroups)
			}
		}
	}

	const handleDragMove = (event) => {
		const { active, over } = event

		if (!over) {
			return
		}

		const { container: activeItem, containerType: activeContainerType } = findContainerById(
			active.id,
		)
		const { container: overItem, containerType: overContainerType } = findContainerById(over.id)

		if (!overItem) {
			return
		}
		if (activeContainerType === 'group') {
			return
		}
		const { container: activeGroup } = findContainerById(activeItem?.groupId)
		if (activeContainerType === 'question' && activeGroup?.hidden) {
			return
		}
		// Move item out of the group
		const isAbove =
			over.rect.top - (active.rect.current?.translated?.top ?? 0) >=
			DRAGGING_GROUPED_ITEM_OUT_TOP_THRESHOLD
		const isBelow =
			Math.abs((active.rect.current?.translated?.top ?? 0) - over.rect.bottom) <=
			DRAGGING_GROUPED_ITEM_OUT_BOTTOM_THRESHOLD

		if (isAbove || isBelow) {
			const getInsertAt = (overItem) => {
				if (overContainerType === 'question') {
					const { container: overItemGroup } = findContainerById(overItem.groupId)
					if (overItemGroup?.hidden) {
						return findContainerIndexById(overItemGroup.id)
					}
					return -1
				}
				return findContainerIndexById(overItem.id)
			}
			const overIndex = getInsertAt(overItem)
			if (overIndex === -1) {
				return
			}

			const newGroupId = `tmp_${getOrGenerateUUID()}`
			insertQuestionGroup(isBelow ? overIndex + 1 : overIndex, {
				_id: newGroupId,
				name: '',
				hidden: true,
				isNewGroup: true,
			})

			const itemsWithoutActive = [...questions].filter(
				(item) => !activeItem || item.id !== activeItem.id,
			)

			const updatedActiveItem = {
				...activeItem,
				groupId: newGroupId,
			} as RfpQuestionForm

			const updatedQuestions = [...itemsWithoutActive, updatedActiveItem]
			replaceQuestions(updatedQuestions)
		}
		return
	}

	const getItemNumber = (id: ID): string => {
		const { container, containerType } = findContainerById(id)

		if (!container) {
			return ''
		}

		if (containerType === 'question') {
			const question = container
			const questionGroupIndex = questionsMap.get(question.id)?.groupIndex
			const { container: group } = findContainerById(question.groupId)
			const groupIndex = findContainerIndexById(question.groupId)
			const questionGroupNumber = group?.hidden ? undefined : groupIndex + 1
			const questionNumber = group?.hidden ? groupIndex + 1 : questionGroupIndex + 1
			if (questionGroupNumber) {
				return `${questionGroupNumber}.${questionNumber}.`
			}
			return `${questionNumber}.`
		}
		if (containerType === 'group') {
			const group = container

			const groupIndex = findContainerIndexById(group.id)
			return `${groupIndex + 1}.`
		}

		return ''
	}

	const renderListItemOverlay = (active: Active) => {
		const { id } = active
		const { container, containerType } = findContainerById(id)

		if (!container) {
			return
		}

		if (containerType === 'question') {
			const question = container
			// typeguard
			const isQuestionType = question && 'groupId' in question
			if (!question || !isQuestionType) {
				return
			}
			const questionGroupIndex = questionsMap.get(question.id)?.groupIndex
			const { container: group } = findContainerById(question.groupId)
			const groupIndex = findContainerIndexById(question.groupId)
			return (
				<QuestionOverlay
					questionHeaderProps={{
						questionGroupNumber: group?.hidden ? undefined : groupIndex + 1,
						questionNumber: group?.hidden ? groupIndex + 1 : questionGroupIndex + 1,
						title: question.title,
						type: question.type,
					}}
					id={question.id}
				/>
			)
		}
		if (containerType === 'group') {
			const group = container
			// typeguard, group doesnt have groupId.
			const isGroupType = group && !('groupId' in group)
			if (!isGroupType) {
				return
			}

			const groupIndex = findContainerIndexById(group.id)
			return (
				<GroupOverlay
					groupHeaderProps={{
						name: `${groupIndex + 1}. ${group.name}`,
					}}
					id={group.id}
				/>
			)
		}

		return null
	}

	const getDraggableTitle = (id: ID): string => {
		const { containerType } = findContainerById(id)
		if (containerType === 'question') {
			return `question ${getItemNumber(id)} ${questionsMap.get(`${id}`)?.item?.title ?? ''}`
		}

		if (containerType === 'group') {
			return `question group ${getItemNumber(id)} ${
				questionGroupsMap.get(`${id}`)?.item?.name ?? ''
			}`
		}
		return ''
	}

	const items = questionGroups
		.map((questionGroup) => {
			if (questionGroup.hidden) {
				return questions.find(({ groupId }) => groupId === questionGroup.id)
			}
			return questionGroup
		})
		.filter(Boolean)

	return (
		<DndContext
			modifiers={[restrictToParentElementOnKeyboard, restrictToVerticalAxis]}
			collisionDetection={(args) => {
				const pointerCollisions = pointerWithin(args)
				const rectIntersectionCollisions = rectIntersection(args)

				// First, let's see if there are any collisions with the rect intersections
				if (rectIntersectionCollisions.length > 0) {
					return rectIntersection(args)
				} else {
					// If there are no collisions with the rect, return pointer collisions
					return pointerCollisions
				}
			}}
			onDragCancel={() => {
				setActiveDragTarget(null)
				setIsDragging(false)
			}}
			onDragStart={(event) => {
				setActiveDragTarget(event.active)
				setIsDragging(true)
			}}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			onDragMove={handleDragMove}
			accessibility={{
				announcements: {
					onDragStart({ active }) {
						return `Picked up draggable ${getDraggableTitle(
							active.id,
						)}. It is in position ${getItemNumber(active.id)}`
					},
					onDragOver({ active, over }) {
						if (over) {
							return `Draggable ${getDraggableTitle(
								active.id,
							)} was moved into position ${getItemNumber(over.id)}`
						}
					},
					onDragEnd({ active, over }) {
						if (over) {
							return `Draggable ${getDraggableTitle(
								active.id,
							)} was dropped at position ${getItemNumber(over.id)}`
						}
					},
					onDragCancel({ active }) {
						return `Dragging was cancelled. Draggable ${getDraggableTitle(active.id)} was dropped.`
					},
				},
				screenReaderInstructions: {
					draggable: `To pick up a draggable question, press space or enter.
							While dragging, use the arrow keys to move the question in up/down direction.
							Press space or enter again to drop the question in its new position, or press escape to cancel.`,
				},
			}}
		>
			<ExpandCollapseProvider initialState={{ expandedState: initialExpandedState }}>
				<Paper
					component={Box}
					padding={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb={1}
					data-testid="questions-container"
				>
					<Box pl={1}>
						<Typography variant="h1XSmall">Questions</Typography>
					</Box>

					{questions.length > 0 && (
						<Box>
							<ExpandCollapseButtons />
						</Box>
					)}
				</Paper>
				<SortableContext items={items as any} id="root">
					<SortableList>
						{items.map((group, index) => {
							if (!group) {
								return null
							}
							return (
								<QuestionGroup
									key={group.id}
									id={group.id}
									index={index}
									item={group}
									deleteGroup={() => deleteGroup(index)}
									duplicateGroup={() => duplicateGroup(group.id)}
									activeDragId={activeDragTarget?.id as string}
									questionErrors={questionErrors ? questionErrors : []}
									questionGroupErrors={questionGroupErrors ? questionGroupErrors : []}
								/>
							)
						})}
					</SortableList>
				</SortableContext>
				{createPortal(
					<DragOverlay
						dropAnimation={{
							sideEffects: defaultDropAnimationSideEffects({
								styles: {
									active: {
										opacity: '1',
									},
									dragOverlay: {
										opacity: '0.5',
									},
								},
							}),
						}}
					>
						{isDragging && activeDragTarget ? renderListItemOverlay(activeDragTarget) : null}
					</DragOverlay>,
					document.body,
				)}
				<QuestionListActionButtons
					appendQuestion={appendQuestion}
					appendQuestionGroup={appendQuestionGroup}
				/>
			</ExpandCollapseProvider>
		</DndContext>
	)
}

/** When we are using keyboard, restrict the movement within parent only to avoid confusing screen reader users  */
const restrictToParentElementOnKeyboard: Modifier = (props) => {
	const event = props.activatorEvent as KeyboardEvent
	if (event?.code === 'Enter' || event?.code === 'Space') {
		return restrictToParentElement(props)
	}

	return props.transform
}
