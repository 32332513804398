// @ts-strict-ignore
import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
	TextField,
	InputAdornment,
	IconButton,
	SearchIcon,
	CloseIcon as ClearIcon,
} from '@persuit/ui-components'

export const Search = ({ searchTerm, onChange }) => {
	const [value, setValue] = useState('')
	const debouncedOnChange = useDebouncedCallback(onChange, 500)

	useEffect(() => {
		if (searchTerm !== value) {
			setValue(searchTerm)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	useEffect(() => {
		if (searchTerm !== value) {
			debouncedOnChange(value)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedOnChange, value])

	return (
		<div
			style={{
				padding: '1.5rem 2.25rem 0',
			}}
		>
			<TextField
				label="Search"
				placeholder="Search"
				fullWidth={true}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon
								style={{
									// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
									fill: '#999',
								}}
							/>
						</InputAdornment>
					),
					endAdornment: value ? (
						<InputAdornment position="end">
							<IconButton color="primary" onClick={() => setValue('')} size="large">
								<ClearIcon />
							</IconButton>
						</InputAdornment>
					) : undefined,
				}}
			/>
		</div>
	)
}

Search.defaultProps = {
	onChange: () => {},
}
