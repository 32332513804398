import { cloneElement, ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import GET_OWN_ORG_QUERY from '../../graphql/queries/getOwnOrg-gql'
import LoadingSpinner from '../../components/loading-spinner.jsx'
import { Unauthorized } from '@persuit/ui-shared-components'
import { RequireGrant } from '@persuit/ui-auth'

type SettingsProps = {
	children: ReactElement
}

const SettingsPage = ({ children }: SettingsProps) => {
	const { loading, error, data } = useQuery(GET_OWN_ORG_QUERY)

	if (loading) {
		return <LoadingSpinner />
	}

	if (error) {
		return <Unauthorized redirectPath={`/en/`} buttonLabel="Go to dashboard" />
	}

	return (
		<RequireGrant grant="UPDATE_SETTINGS" fallback={true}>
			{cloneElement(children, {
				ownOrg: data.getOwnOrg,
			})}
		</RequireGrant>
	)
}

export default SettingsPage
