// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { omitDeep } from '@persuit/common-utils'

// This function iterates the errors returned,
// Removing the __typename fields, and any non-populated
// or null fields. Further, if it is a valid field with errors
// then we copy the object to ensure that the object references do not match
// This is important for components down the tree to detect the updates
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function copyValidationErrors(errorsRaw?: Record<string, any> | null) {
	if (isEmpty(errorsRaw) || errorsRaw === null || errorsRaw === undefined) return null
	const errors = omitDeep(errorsRaw, '__typename') as Record<string, null | string | object>
	return Object.keys(errors).reduce((acc, key) => {
		if (errors[key] === null) return acc
		if (typeof errors[key] !== 'object') return acc
		if (Array.isArray(errors[key])) acc[key] = [...(errors[key] as [])]
		else
			acc[key] = omitDeep(
				{ ...(errors[key] as Record<string, null | string | object>) },
				'__typename',
			)
		return acc
	}, {})
}
