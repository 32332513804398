// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { ErrorCardData, ErrorCardTransformSectionHandler } from '../../../../sectional-form'

export const makeGenericHandlerMaker =
	({ getCardTitle, getCardLink }) =>
	({
		sectionName,
		path = '',
		templateId = '',
		titleOverride = '',
	}): ErrorCardTransformSectionHandler =>
	(sectionErrors: Record<string, string>): Array<ErrorCardData> => {
		if (isEmpty(sectionErrors)) return []
		const title = getCardTitle({ sectionName, titleOverride })
		const link = getCardLink({ path, templateId, sectionName })

		return Object.keys(sectionErrors)
			.filter((fieldName) => sectionErrors[fieldName])
			.map((fieldName) => {
				return {
					title,
					errorMessages: [sectionErrors[fieldName]],
					subSections: [],
					link,
				}
			})
	}
