import { useQuery } from '@apollo/client'
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	LoadingSpinner,
	styled,
	Button,
	RichTextView,
} from '@persuit/ui-components'
import { format } from 'date-fns'
import { head, isEmpty, partition } from 'lodash/fp'

import { Fragment } from 'react'
import { connect } from 'react-redux'
import { getOrEmptyString } from '../../../utils/lenses'
import { Unauthorized } from '@persuit/ui-shared-components'
import GET_NOTES_QUERY from './get-notes-query'

const StyledCardHeader = styled(CardHeader)`
	& .MuiCardHeader-action {
		align-self: flex-end;
	}
`

const StyledAvatar = styled(Avatar)`
	&.MuiAvatar-root {
		background-color: ${(props) => props.theme.palette.primary.main};
	}
`

const StyledDivider = styled(Divider)`
	height: '2px';
`

const getInitials = (firstName, lastName) => {
	const firstInitial = head(firstName)
	const lastInitial = head(lastName)

	return `${firstInitial}${lastInitial}`
}
const getNotesFormattedDate = (dateInMS) => format(new Date(dateInMS), 'PPpp')

const OwnNote = ({ ownNote, currentUserFullName, currentUserInitials, openAddNoteDialog }) => {
	if (!ownNote) {
		return (
			<Card data-testid="add-own-note">
				<StyledCardHeader
					avatar={<StyledAvatar aria-hidden="true">{currentUserInitials}</StyledAvatar>}
					action={
						<Button
							data-testid="add-note-button"
							variant="outlined"
							onClick={() => openAddNoteDialog()}
						>
							Add note
						</Button>
					}
					title={currentUserFullName}
				/>
			</Card>
		)
	}

	return (
		<Card data-testid="edit-own-note">
			<CardHeader
				avatar={
					<StyledAvatar aria-hidden="true">
						{getInitials(ownNote.createdByName.first, ownNote.createdByName.last)}
					</StyledAvatar>
				}
				action={
					<Button
						data-testid="edit-note-button"
						variant="outlined"
						onClick={() => openAddNoteDialog(ownNote.noteContents)}
					>
						Edit
					</Button>
				}
				title={`${ownNote.createdByName.first} ${ownNote.createdByName.last}`}
				subheader={getNotesFormattedDate(ownNote.updatedAt)}
			/>
			<CardContent>
				<RichTextView content={ownNote.noteContents} />
			</CardContent>
		</Card>
	)
}
const OwnNoteWithRedux = connect((state) => {
	const firstName = getOrEmptyString('auth.user.name.first', state)
	const lastName = getOrEmptyString('auth.user.name.last', state)
	const currentUserFullName = `${firstName} ${lastName}`

	return {
		currentUserFullName,
		currentUserInitials: getInitials(firstName, lastName),
	}
})(OwnNote)

const OtherNotes = ({ otherNotes }) => {
	if (isEmpty(otherNotes)) {
		return null
	}

	return (
		<Fragment>
			<StyledDivider />
			{otherNotes.map(({ id, createdByName, updatedAt, noteContents }) => (
				<Card key={id} data-testid="other-notes">
					<CardHeader
						avatar={
							<Avatar
								aria-hidden="true"
								sx={(theme) => ({ backgroundColor: theme.palette.grey600 })}
							>
								{getInitials(createdByName.first, createdByName.last)}
							</Avatar>
						}
						title={`${createdByName.first} ${createdByName.last}`}
						subheader={getNotesFormattedDate(updatedAt)}
					/>
					<CardContent>
						<RichTextView content={noteContents} />
					</CardContent>
				</Card>
			))}
		</Fragment>
	)
}

const NoteList = ({ notes, openAddNoteDialog }) => {
	const [ownNoteArray, otherNotes] = partition(({ isOwnNote }) => isOwnNote === true, notes)
	const ownNote = head(ownNoteArray)

	return (
		<Fragment>
			<OwnNoteWithRedux ownNote={ownNote} openAddNoteDialog={openAddNoteDialog} />
			<OtherNotes otherNotes={otherNotes} />
		</Fragment>
	)
}

const NotesQuery = ({ rfpId, firmId, openAddNoteDialog }) => {
	const { data, loading, error } = useQuery(GET_NOTES_QUERY, {
		variables: { rfpId, firmId },
		fetchPolicy: 'cache-and-network',
	})

	if (loading === true) {
		return <LoadingSpinner />
	}

	if (error) {
		console.error('FirmCompanyProfileFormQuery error', error)
		return <Unauthorized redirectPath={`/en/${rfpId}/matter/view`} buttonLabel="Go to request" />
	}

	const notes = data.getNotes

	return <NoteList notes={notes} openAddNoteDialog={openAddNoteDialog} />
}
export default NotesQuery
