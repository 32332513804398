import {
	Box,
	BoxProps,
	Button,
	ChevronLeftIcon,
	ChevronRightIcon,
	Dialog,
	IconButton,
	Tab,
	Tabs,
	Typography,
} from '@persuit/ui-components'
// import { Tab } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useActions, useStore } from '../store'
import { NegotiateButton } from '@client/components/rfp/new-proposal-cab/negotiate-button'
import { useState } from 'react'
import { NamedTimekeepersContent } from './named-timekeepers-content'
import { RateCardsContent } from './rate-cards-content'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

export type TabPanelProps = {
	index: number
	value: number
	children: React.ReactNode
} & BoxProps

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
	return (
		<Box role="tabpanel" hidden={value !== index} {...other}>
			{value === index && children}
		</Box>
	)
}

export const ProposalDetailDialog = () => {
	const { toggles } = useFeatureToggles()
	const isNamedTimekeepersToggleOn = toggles['dev-10144.named-timekeepers']
	const history = useHistory()
	const { open, data, currency, rfp, showArrows } = useStore((state, s) => ({
		showArrows: s.filteredRowProposals().length > 1,
		rfp: state.rfp,
		open: !!state.proposalDetailId,
		data: s.proposalDetailData(),
		currency: state.rfp.rateReview?.currency ?? '',
		timekeepers: state.rfp.rateReview?.timekeepers ?? [],
	}))
	const [tabValue, setTabValue] = useState(0)

	const { closeProposalDetail, nextProposalDetail, previousProposalDetail } = useActions()

	const proposal = data?.proposal

	if (!proposal) return null

	const handleTabValueChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	const dialogTitle = (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			{showArrows && (
				<IconButton onClick={previousProposalDetail} aria-label="go to previous proposal">
					<ChevronLeftIcon />
				</IconButton>
			)}

			<Typography variant="h2XSmall" aria-live="polite">
				{proposal?.org?.name}
			</Typography>

			{showArrows && (
				<IconButton onClick={nextProposalDetail} aria-label="go to next proposal">
					<ChevronRightIcon />
				</IconButton>
			)}
		</Box>
	)

	const dialogActions = (
		<>
			<Button onClick={closeProposalDetail} sx={{ mr: 'auto' }}>
				Close
			</Button>

			<Box>
				<Button
					sx={{ mr: 1 }}
					onClick={() => history.push(`/en/request/${rfp._id}/proposals/view/${proposal._id}/`)}
					variant="secondary"
				>
					View Proposal
				</Button>
				<NegotiateButton
					requestId={rfp._id}
					request={rfp}
					response={proposal}
					currency={currency}
					isFirmEliminated={proposal.isEliminated}
					ButtonProps={{
						variant: 'primary',
					}}
				/>
			</Box>
		</>
	)

	return (
		<Dialog
			open={open}
			onClose={closeProposalDetail}
			size="lg"
			data-testid="proposal-detail-dialog"
			title={dialogTitle}
			actions={dialogActions}
		>
			{isNamedTimekeepersToggleOn ? (
				<>
					<Tabs value={tabValue} onChange={handleTabValueChange}>
						<Tab label="Rate cards" id="tab-rate-cards" aria-controls="tab-panel-rate-cards" />
						<Tab
							label="Individual timekeepers"
							id="tab-individual-timekeepers"
							aria-controls="tab-panel-individual-timekeepers"
						/>
					</Tabs>
					<TabPanel value={tabValue} index={0} id="tab-panel-rate-cards">
						<RateCardsContent
							rateCards={proposal?.rateCards ?? []}
							proposalId={proposal?._id ?? ''}
							proposalUpdatedAt={proposal.updatedAt}
							currency={currency}
						/>
					</TabPanel>
					<TabPanel value={tabValue} index={1} id="tab-panel-individual-timekeepers">
						<NamedTimekeepersContent />
					</TabPanel>
				</>
			) : (
				<RateCardsContent
					rateCards={proposal?.rateCards ?? []}
					proposalId={proposal?._id ?? ''}
					proposalUpdatedAt={proposal.updatedAt}
					currency={currency}
				/>
			)}
		</Dialog>
	)
}
