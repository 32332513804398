// @ts-strict-ignore
export const TOOLTIPS = {
	adjustedSavings: 'Adjusted savings is the total amount you expect to save on this request.',
	adjustedAgreedFee: 'Adjusted agreed fee is the total amount you expect to spend on this request.',
	baselineFee: (bidTypeDescription) =>
		`The baseline fee is the amount you're comparing against to determine your savings. The chosen baseline for your company is ${bidTypeDescription}. As you have entered an adjusted savings value and an adjusted agreed fee, we have auto-calculated this new baseline on your behalf.`,
	notes:
		'Consider including helpful information for your colleagues about how you calculated this number, what timeframe does it pertain to, etc.',
	overriddenSavings:
		'Only managers from this request’s group can edit savings. Adjusted savings and adjusted agreed fees will flow into reporting and become aggregated with matters that still include savings estimates to provide accurate total metrics.',
	nonManagerOverriddenSavings:
		'The savings for this request has been adjusted by this request group’s Manager.',
	persuitSavings:
		'Before a firm is selected, the lowest bid will be used to estimate savings. In the case of multiple selected proposals, the average of the total prices will be used to estimate savings. Savings is only calculated on those firms where the selected bid is lower than the comparison bid.',
	nonManagerOverrideSavingsButton: 'Only Managers from this request’s group can override savings.',
}
