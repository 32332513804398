import { FileScanState } from './types'

export const checkFileStatusThreat = (fileStatus?: FileScanState | null) =>
	fileStatus === 'virus' || fileStatus === 'notallowed' || fileStatus === 'scanerror'

export const checkFileStatusQueued = (fileStatus?: FileScanState | null) => fileStatus === 'queued'

export const checkFileStatusOversize = (fileStatus?: FileScanState | null) =>
	fileStatus === 'oversize'

export const checkFileStatusClean = (fileStatus?: FileScanState | null) => fileStatus === 'clean'
