import {
	MenuItem,
	ListItemIcon,
	ListItemText,
	VisibilityIcon,
	MenuItemProps,
} from '@persuit/ui-components'

type PreviewMenuItemProps = {
	openPreview: () => void
	handleMenuClose: () => void
} & MenuItemProps

export const PreviewMenuItem = ({
	openPreview,
	handleMenuClose,
	...props
}: PreviewMenuItemProps) => {
	const handleClick = () => {
		handleMenuClose()
		openPreview()
	}

	return (
		<MenuItem {...props} onClick={handleClick}>
			<ListItemIcon>
				<VisibilityIcon color="primary" fontSize="small" />
			</ListItemIcon>
			<ListItemText primary="PREVIEW" primaryTypographyProps={{ color: 'primary' }} />
		</MenuItem>
	)
}
