import { useRef } from 'react'
import {
	DndContext as OrgDndContext,
	DndContextProps as OrgDndContextProps,
	useSensors,
	useSensor,
	MouseSensor,
	TouchSensor,
	KeyboardSensor,
	closestCenter,
	Announcements,
	PointerSensor,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

export type DndContextProps = OrgDndContextProps

/**
 * Suggest you consider customise accessibility announcements props to fit with the use case's context to maximize accessibility benefits.
 * See https://docs.dndkit.com/guides/accessibility
 */
export const DndContext = ({ children, ...props }: OrgDndContextProps) => {
	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)
	const isFirstAnnouncement = useRef(true)

	const defaultAnnouncements: Announcements = {
		onDragStart({ active }) {
			return `Picked up draggable item ${active.id}.`
		},
		onDragOver({ active, over }) {
			if (over) {
				return `Draggable item ${active.id} was moved over droppable area ${over.id}.`
			}

			return `Draggable item ${active.id} is no longer over a droppable area.`
		},
		onDragEnd({ active, over }) {
			if (over) {
				return `Draggable item ${active.id} was dropped over droppable area ${over.id}`
			}

			return `Draggable item ${active.id} was dropped.`
		},
		onDragCancel({ active }) {
			return `Dragging was cancelled. Draggable item ${active.id} was dropped.`
		},
	}

	return (
		<OrgDndContext
			collisionDetection={closestCenter}
			sensors={sensors}
			modifiers={[restrictToVerticalAxis]}
			{...props}
			accessibility={{
				announcements: props.accessibility
					? {
							onDragStart(...args) {
								return props.accessibility?.announcements?.onDragStart(...args)
							},
							onDragOver(...args) {
								// In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
								// The first `onDragOver` event therefore doesn't need to be announced, because it is called
								// immediately after the `onDragStart` announcement and is redundant.
								if (isFirstAnnouncement.current === true) {
									isFirstAnnouncement.current = false
									return
								}

								return props.accessibility?.announcements?.onDragOver(...args)
							},
							onDragEnd(...args) {
								isFirstAnnouncement.current = true

								return props.accessibility?.announcements?.onDragEnd(...args)
							},
							onDragCancel(...args) {
								isFirstAnnouncement.current = true

								return props.accessibility?.announcements?.onDragCancel(...args)
							},
					  }
					: defaultAnnouncements,
				screenReaderInstructions: props.accessibility?.screenReaderInstructions,
			}}
		>
			{children}
		</OrgDndContext>
	)
}

export { DragOverlay, useDndContext } from '@dnd-kit/core'
