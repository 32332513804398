import { Fragment, Component } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import FileSaver from 'file-saver'

import { CircularProgress, Button, FileDownloadIcon } from '@persuit/ui-components'
import Dialog from './dialog'

class ExportToExcel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			isGeneratingExcel: false,
		}
	}

	dismissError = () => {
		this.setState({
			hasError: false,
		})
	}

	handleExport = async () => {
		const { exportToExcel, data, name } = this.props

		this.setState({ isGeneratingExcel: true })

		try {
			const result = await exportToExcel({
				variables: data,
			})

			this.setState({ isGeneratingExcel: false })

			const fileData = result.data[Object.keys(result.data)[0]].data
			const buffer = Buffer.from(fileData, 'base64')

			FileSaver.saveAs(new Blob([buffer], { type: '' }), `${name}.xlsx`)
		} catch (error) {
			console.error('Excel export failed', error)
			this.setState({
				hasError: true,
				isGeneratingExcel: false,
			})
		}
	}

	render() {
		const { t } = this.props

		const { hasError, isGeneratingExcel } = this.state

		return (
			<Fragment>
				<Button
					startIcon={
						isGeneratingExcel ? (
							<CircularProgress size="1rem" />
						) : (
							<FileDownloadIcon color={'primary'} />
						)
					}
					label={t('rfp.buttons.exportToXLSX.button')}
					variant="text"
					color="primary"
					onClick={this.handleExport}
					disabled={isGeneratingExcel}
				>
					Export to Excel
				</Button>

				{hasError && (
					<Dialog
						title={t('rfp.buttons.exportToXLSX.errorDialogTitle')}
						open={true}
						cancelLabel={t('rfp.buttons.exportToXLSX.errorDialogButton')}
						onCancel={this.dismissError}
					>
						{t('rfp.buttons.exportToXLSX.errorDialogContent')}
					</Dialog>
				)}
			</Fragment>
		)
	}
}

export default compose(withTranslation())(ExportToExcel)
