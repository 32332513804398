import { getOr, isNil } from 'lodash/fp'
import { Typography, Box } from '@persuit/ui-components'

import useTranslation from '../../../custom-hooks/translation-hook'
import { grey500 } from '../../../theme/persuit-colors'
import { getOrEmptyString, getOrNull } from '../../../utils/lenses'
import formatPriceCurrency from '../../../utils/price-and-currency'
import Spacer from '../../layout/spacer.jsx'
import getRateCardLabel from './get-rate-card-label'
import { PriceDelta } from './phase-delta'
import CollapsedView from './pricing-card-item-collapsed'
import ExpandedView from './pricing-card-item-expanded'
import { PricingCardItemHeader } from './pricing-card-item-header'
import StrikeThroughPrice from './strikethrough-price'
import { PricingItemRanking } from './pricing-item-ranking'
import pricingModels from '../../../../common/data/pricing-models'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const getFormattedPriceDelta = (pricingModel = '', currency, delta) => {
	if (
		[pricingModels.CONTINGENCYPERCENTAGE, pricingModels.DISCOUNTPERCENTAGE].includes(pricingModel)
	) {
		return ''
	}

	return formatPriceCurrency(currency, getOrNull('price', delta))
}

// TODO: Refactor code and write unit tests
// FYI: this is used on both request and proposal details views plus proposal comparitor
const PricingCardItemsView = ({
	allowFirmAddRate,
	isProposalRevised,
	requestedRates,
	pricingPreferences, // pricingPreferences is the client pricing model selection
	phaseIndex,
	responseToDeliverable,
	currency,
	expandedView,
	detailedView,
	cellView,
	showTotal,
	showHeader,
	phaseDuration,
	isCompetitor,
	rfpr,
	rank,
}) => {
	const { t } = useTranslation()
	const { toggles } = useFeatureToggles()

	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const pricingModel = responseToDeliverable
		? responseToDeliverable.pricingModel
		: pricingPreferences

	const hours = responseToDeliverable ? responseToDeliverable.hours : null

	// prepare values for rate card
	const responseToRates =
		responseToDeliverable && responseToDeliverable.responseToRates
			? responseToDeliverable.responseToRates
			: []
	const firmSuppliedRates =
		responseToDeliverable && responseToDeliverable.firmSuppliedRates
			? responseToDeliverable.firmSuppliedRates
			: []

	const rateCardData = [...responseToRates, ...firmSuppliedRates].map((r) => {
		let requestedRate = undefined
		if (r.rateId) {
			// find the associate firm rate
			requestedRate = requestedRates ? requestedRates.find((rr) => r.rateId === rr._id) : null
		}

		const rateCardLabel = getRateCardLabel({
			rate: r,
			requestedRate,
			isCompetitor,
			pricingModel,
			redactedRateLabel: <span style={{ color: grey500 }}>Other rate</span>,
		})

		const rfprRates = getOr([], 'rfprRates', rfpr)
		let foundRfprRate

		// suggested change to response to rate
		if (r.rateId) {
			foundRfprRate = rfprRates.find((rfprRate) => rfprRate.rateId === r.rateId)
		} else {
			foundRfprRate = rfprRates.find((rfprRate) => rfprRate.firmSuppliedRateId === r._id)
		}

		if (foundRfprRate) {
			return {
				left: rateCardLabel,
				rightStrikeThrough: formatPriceCurrency(currency, r.rate),
				right: !isNil(foundRfprRate.rate)
					? formatPriceCurrency(currency, foundRfprRate.rfprRate)
					: undefined,
			}
		} else {
			return {
				left: rateCardLabel,
				right: r.rate ? formatPriceCurrency(currency, r.rate) : undefined,
			}
		}
	})

	const delta = getOrNull('delta', responseToDeliverable)
	const formattedPriceDelta = getFormattedPriceDelta(pricingModel, currency, delta)
	const priceDeltaPercentage = getOrNull('percentage', delta)
	const priceDeltaDirection = getOrEmptyString('direction', delta)
	const shouldHideRateCardRanking =
		!isRateCardAuctionEnabled && pricingModel === pricingModels.RATECARD

	const CellHeader = (
		<Box display="flex" flexDirection="row" justifyContent="space-between">
			<Box flexGrow="1">
				<PricingCardItemHeader
					pricingModel={pricingModel}
					responseToDeliverable={responseToDeliverable}
					currency={currency}
					hours={hours}
					phaseDuration={phaseDuration}
					showStrikethrough={true}
				/>
				<Box minHeight="20px">
					{!!delta && pricingModel !== pricingModels.RATECARD && (
						<PriceDelta
							pricingModel={pricingModel}
							formattedDifferenceAmount={formattedPriceDelta}
							differencePercentage={priceDeltaPercentage}
							direction={priceDeltaDirection}
						/>
					)}
				</Box>
			</Box>
			{shouldHideRateCardRanking ? null : <PricingItemRanking rank={rank} />}
		</Box>
	)

	// Header for Included in Total pricing item
	const HeaderIncluded = (
		<div data-testid="header-included">
			<Typography variant="body1Semibold" fontSize="20px">
				{t(`feeBreakdown.pricingModels.firmCard.${pricingPreferences}`)}
			</Typography>
			<StrikeThroughPrice responseToDeliverable={responseToDeliverable} currency={currency} />
			<Typography display="block" variant="body2">
				{hours ? `${hours} ${phaseDuration === 'months' ? 'Months' : 'Hrs'}` : '-'}
			</Typography>
			<Spacer space={3} />
			{!!delta && (
				<PriceDelta
					formattedDifferenceAmount={formattedPriceDelta}
					differencePercentage={priceDeltaPercentage}
					direction={priceDeltaDirection}
				/>
			)}
			<Spacer space={2} />
		</div>
	)

	if (expandedView) {
		return (
			<ExpandedView
				showHeader={showHeader}
				allowFirmAddRate={allowFirmAddRate}
				isProposalRevised={isProposalRevised}
				responseToDeliverable={responseToDeliverable}
				pricingPreferences={pricingPreferences}
				requestedRates={requestedRates}
				phaseIndex={phaseIndex}
				currency={currency}
				phaseDuration={phaseDuration}
				rfpr={rfpr}
				rateCardData={rateCardData}
				showTotal={showTotal}
				Header={
					<PricingCardItemHeader
						pricingModel={pricingModel}
						responseToDeliverable={responseToDeliverable}
						currency={currency}
						hours={hours}
						phaseDuration={phaseDuration}
						showStrikethrough={false}
						rank={rank}
					/>
				}
				rank={rank}
			/>
		)
	}

	// Column (colapsed) Cell view
	// Hourly Rate only shows header
	return (
		<CollapsedView
			cellView={cellView}
			showTotal={showTotal}
			showHeader={showHeader}
			allowFirmAddRate={allowFirmAddRate}
			isProposalRevised={isProposalRevised}
			responseToDeliverable={responseToDeliverable}
			pricingPreferences={pricingPreferences}
			requestedRates={requestedRates}
			phaseIndex={phaseIndex}
			currency={currency}
			phaseDuration={phaseDuration}
			rfpr={rfpr}
			rateCardData={rateCardData}
			HeaderIncluded={HeaderIncluded}
			Header={CellHeader}
			detailedView={detailedView}
		/>
	)
}
PricingCardItemsView.defaultProps = {
	short: false,
	expandedView: false,
	cellView: false,
	showTotal: false,
	showHeader: true,
}
export default PricingCardItemsView
