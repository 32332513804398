import has from 'lodash/fp/has'
import isEmpty from 'lodash/fp/isEmpty'

export const getDiversityAnswer = (diversityAnswers, answerNumber) => {
	if (isEmpty(diversityAnswers)) {
		return null
	}

	const answer = `answer${answerNumber}`

	return has(answer, diversityAnswers) ? diversityAnswers[answer] : { answer: null }
}
