import { Box, Typography } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { graphql, useQuery } from '@persuit/ui-graphql'
import { logger } from '@persuit/ui-logger'

function renderPercentage(val: number | null): string {
	if (typeof val !== 'number') return '-'
	return `${val > 0 ? '+' : ''}${(val * 100).toFixed(2)}%`
}

const GET_PROPOSAL_BY_ID = graphql(`
	query FirmWeightedChangeForMultiCurrency_GetResponse($proposalId: ID!) {
		getResponse(responseId: $proposalId) {
			_id
			rateCardsWeightedChange
		}
	}
`)

type FirmWeightedChangeForMultiCurrencyProps = {
	proposalId: string
}

const FirmWeightedChangeForMultiCurrency = ({
	proposalId,
}: FirmWeightedChangeForMultiCurrencyProps) => {
	const { data, loading, error } = useQuery(GET_PROPOSAL_BY_ID, {
		variables: { proposalId },
	})

	if (loading) {
		return null
	}

	if (error) {
		logger.error(error)
		return <div>Error loading component</div>
	}

	const proposal = data?.getResponse
	const weightedAverage = proposal?.rateCardsWeightedChange ?? null

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', pb: 2, pt: 1 }}>
			{weightedAverage !== null && (
				<Typography align="center" gutterBottom={true} variant="body2">
					Firm weighted change
					<Typography pl={3} color="text.secondary" variant="body2">
						{renderPercentage(weightedAverage)}
					</Typography>
				</Typography>
			)}
		</Box>
	)
}

type FirmWeightedAverageTextProps = {
	proposalId: string
	weightedAverage: number | null
}

export const FirmWeightedChangeText = ({
	proposalId,
	weightedAverage,
}: FirmWeightedAverageTextProps) => {
	const { toggles } = useFeatureToggles()
	const isMultiCurrencyToggleEnabled = toggles['dev-10954.multi-currency']

	if (!isMultiCurrencyToggleEnabled) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 2, pt: 1 }}>
				{weightedAverage !== null && (
					<Typography align="center" gutterBottom={true} variant="body2">
						Firm weighted change:{' '}
						<Typography color="text.secondary" variant="body2">
							{renderPercentage(weightedAverage)}
						</Typography>
					</Typography>
				)}
			</Box>
		)
	}

	return <FirmWeightedChangeForMultiCurrency proposalId={proposalId} />
}
