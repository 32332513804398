import { DataGrid, Divider, Radio, Spacer } from '@persuit/ui-components'

// eslint-disable-next-line no-restricted-imports
import { GridRowId } from '@mui/x-data-grid-pro'
import { ExternalApiMatter } from '../sync-tracking-fields-wizard.types'

type SelectMatterPanelProps = {
	searchedMatters: ExternalApiMatter[]
	selectedMatter: ExternalApiMatter | null
	setSelectedMatter: (selectedMatter: ExternalApiMatter | null) => void
}

export const SelectMatterPanel = ({
	searchedMatters,
	selectedMatter,
	setSelectedMatter,
}: SelectMatterPanelProps) => {
	const onRowSelect = (externalId: GridRowId) => {
		const selectedMatter = searchedMatters.find((matter) => matter.externalId === externalId)
		setSelectedMatter(selectedMatter ? selectedMatter : null)
	}

	const generateMatterRows = () => {
		if (!searchedMatters) return []

		const matterRows = searchedMatters.map((matter) => ({
			id: matter.externalId,
			matterNumber: matter.matterNumber,
			matterName: matter.matterName,
		}))

		return matterRows
	}

	return (
		<>
			<Divider />
			<Spacer space={2} />
			<div data-trackid="table-select-external-matter">
				<DataGrid
					rows={generateMatterRows()}
					columns={[
						{
							field: 'selectMatter',
							width: 70,
							disableColumnMenu: true,
							disableReorder: true,
							filterable: false,
							resizable: false,
							sortable: false,
							renderHeader: () => <></>,
							renderCell: (cellValue) => {
								return (
									<Radio
										id={`select-radio-button-${cellValue.id}`}
										name={`select-radio-${cellValue.id}`}
										checked={!!selectedMatter && selectedMatter.externalId === cellValue.id}
										onClick={() => onRowSelect(cellValue.id)}
										size="small"
										data-testid="select-external-matter-radio-button"
										aria-label="select-external-matter-radio-button"
									/>
								)
							},
						},
						{
							field: 'matterNumber',
							headerName: 'Matter Number',
							width: 200,
							disableReorder: true,
						},
						{ field: 'matterName', headerName: 'Matter Name', flex: 1, disableReorder: true },
					]}
					pageSize={10}
					rowsPerPageOptions={[10]}
					density="compact"
					checkboxSelection={false}
					disableSelectionOnClick={true}
					disableColumnSelector={true}
					autoHeight={true}
				/>
			</div>
			<Spacer space={2} />
		</>
	)
}
