import { SvgIcon, SvgIconProps } from '@mui/material'

export const ReviseProposalIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<g>
			<path d="M18.51,10.32v1.75c1.96,0.69,3.36,2.55,3.36,4.74c0,2.78-2.26,5.04-5.04,5.04c-2.78,0-5.04-2.26-5.04-5.04 c0-1.39,0.58-2.64,1.49-3.54l2.7,2.7v-5.88h-5.88l1.97,1.97c-1.22,1.22-1.97,2.89-1.97,4.74c0,3.71,3.01,6.71,6.72,6.71 c3.71,0,6.72-3,6.72-6.71C23.55,13.68,21.4,11.07,18.51,10.32z" />
			<path d="M15.99,10.09v5.88l-2.7-2.7c-0.92,0.91-1.49,2.15-1.49,3.54c0,2.78,2.26,5.04,5.04,5.04c2.78,0,5.04-2.26,5.04-5.04 c0-2.19-1.4-4.06-3.36-4.74v-1.75c2.9,0.75,5.04,3.36,5.04,6.49c0,3.71-3.01,6.71-6.72,6.71c-3.71,0-6.72-3-6.72-6.71 c0-1.86,0.76-3.53,1.97-4.74l-1.97-1.97H15.99 M16.21,9.87h-0.22h-5.88H9.57l0.38,0.38l1.82,1.82c-1.22,1.29-1.88,2.97-1.88,4.74 c0,3.82,3.11,6.93,6.94,6.93c3.83,0,6.94-3.11,6.94-6.93c0-3.16-2.14-5.92-5.21-6.71l-0.28-0.07v0.29v1.75v0.16l0.15,0.05 c1.92,0.68,3.21,2.5,3.21,4.53c0,2.65-2.16,4.81-4.81,4.81c-2.65,0-4.81-2.16-4.81-4.81c0-1.2,0.45-2.33,1.27-3.22l2.54,2.54 l0.38,0.38v-0.54v-5.88V9.87L16.21,9.87z" />
			<g>
				<path d="M8.94,16.87c0-1.7,0.54-3.32,1.53-4.68L9.3,11.02l-0.38-0.38L7.21,8.93h2.41h0.54h5.88h0.22h0.38L9.59,1.88 c-0.31-0.31-0.74-0.5-1.21-0.5H2.34c-0.95,0-1.72,0.78-1.72,1.72v6.03c0,0.47,0.19,0.9,0.51,1.22l7.76,7.76 c0.06,0.06,0.12,0.11,0.19,0.15C8.99,17.81,8.94,17.35,8.94,16.87z M3.63,5.68c-0.72,0-1.29-0.58-1.29-1.29 c0-0.72,0.58-1.29,1.29-1.29c0.72,0,1.29,0.58,1.29,1.29C4.93,5.11,4.35,5.68,3.63,5.68z" />
			</g>
		</g>
	</SvgIcon>
)
