import { TableRow, TableRowProps } from './row'
import { Box, Typography, useTheme } from '@persuit/ui-components'

type TableProps = {
	title: string
	rowData: Array<TableRowProps['rowData']>
}

export const Table = ({ title, rowData }: TableProps) => {
	const theme = useTheme()
	// Check if any of the rows have subRow data
	const tableHasSubRowData = rowData.some((row) => row.subRows && row.subRows.length > 0)

	return (
		<Box>
			<Typography
				display="block"
				variant="body2"
				color="text.secondary"
				style={{
					borderBottom: `1px solid ${theme.palette.primary.main}`,
					paddingBottom: '0.5em',
				}}
			>
				{title}
			</Typography>

			{rowData.map((row, index) => (
				<TableRow key={index} rowData={row} tableHasSubRowData={tableHasSubRowData} />
			))}
		</Box>
	)
}
