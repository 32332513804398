import { useState } from 'react'
import * as React from 'react'
import { Chip } from '../chip'
import { Box, BoxProps } from '../box'
import { TextField, TextFieldProps } from '../text-field'
import { CancelIcon } from '../../icons'

type MultiTagOption = string

export type MultiTagProps = TextFieldProps & {
	disabled?: boolean
	value?: MultiTagOption[]
	/** Split input string by comma to multiple tags. Default: false */
	splitInputIntoMultipleTags?: boolean
	/** Default to: [','] */
	splitIdentifiers?: string[]
	onChange?: (options: MultiTagOption[]) => void
	containerProps?: BoxProps
	chipContainerProps?: BoxProps
}

export const MultiTag = ({
	disabled,
	value = [],
	onChange = () => undefined,
	containerProps,
	chipContainerProps,
	helperText,
	splitInputIntoMultipleTags = false,
	splitIdentifiers = [','],
	...props
}: MultiTagProps) => {
	const [searchText, setSearchText] = useState('')

	function onNewRequest(searchText: string) {
		if (!searchText) return
		if (splitInputIntoMultipleTags) {
			// 0 or more spaces followed by split specifiers or a space followed by 0 or
			// more spaces and, when found, removes the spaces and the
			// split specifiers from the string
			const splitValues = searchText
				.split(new RegExp(`\\s*(?:[${splitIdentifiers.join('')}]|$|\\s)\\s*`, 'g'))
				.filter((splitValue) => !!splitValue)

			// Remove any empty strings
			const filteredValues = splitValues.filter((splitValue) => !!splitValue)

			// Remove any duplicates by initialising a Set
			const uniqueValues = [...Array.from(new Set(filteredValues))] as string[]

			// Remove any duplicates relative to the existing values
			const uniqueNewValues = uniqueValues.filter((uniqueValue) => !value.includes(uniqueValue))

			const newValues = [...value, ...uniqueNewValues]
			onChangeHandler(newValues)
		} else {
			onChangeHandler([...value, searchText])
		}

		setSearchText('')
	}

	function onChangeHandler(value: MultiTagOption[]) {
		const mappedValues = value.map((v) => v)
		onChange(mappedValues)
	}

	function removeValue(toBeRemoved: MultiTagOption) {
		onChangeHandler(value.filter((v) => v !== toBeRemoved))
	}

	function renderValue(value: MultiTagOption, index: number) {
		const onRequestDelete = disabled ? undefined : () => removeValue(value)

		return (
			<Box key={index} mr={1} mt={1}>
				<Chip deleteIcon={<CancelIcon />} onDelete={onRequestDelete} label={value} />
			</Box>
		)
	}

	function handleKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
		switch (e.key) {
			case 'Enter': {
				if (searchText) {
					onNewRequest(searchText)
				}
				return
			}
		}
	}

	return (
		<Box display="flex" flexDirection="column" width="100%" {...containerProps}>
			<TextField
				onBlur={(e) => {
					e.preventDefault()
					onNewRequest(searchText)
				}}
				onChange={(e) => {
					setSearchText(e.currentTarget.value)
				}}
				value={searchText}
				onKeyDown={handleKeydown}
				{...props}
				helperText={helperText || `Press "Enter" to confirm`}
			/>
			{value.length > 0 && (
				<Box display="flex" flexWrap="wrap" {...chipContainerProps}>
					{value.map(renderValue)}
				</Box>
			)}
		</Box>
	)
}

MultiTag.displayName = 'MultiTag'
