import { graphql, useMutation, useQuery } from '@persuit/ui-graphql'
import { UserDismissible } from '@persuit/ui-graphql/generated'

const GET_SESSION_QUERY = graphql(`
	query UserDismissibleGetSessionInfo {
		getSessionInfo {
			user {
				_id
			}
		}
	}
`)

const getUserQuery = graphql(`
	query GetUser($userId: ID) {
		getUser(userId: $userId) {
			_id
			dismissibles
		}
	}
`)

const SAVE_USER_DISMISSIBLES = graphql(`
	mutation saveUserDismissibles($payload: UserDismissiblesInput!) {
		saveUserDismissibles(payload: $payload) {
			_id
			dismissibles
		}
	}
`)

export const useUserDismissibles = () => {
	const { data: sessionData, loading: sessionInfoLoading } = useQuery(GET_SESSION_QUERY)
	const userId = sessionData?.getSessionInfo.user._id

	const { data: userData, loading: userDataLoading } = useQuery(getUserQuery, {
		variables: { userId },
		skip: !userId,
	})

	const userDismissibles = userData?.getUser?.dismissibles ?? []
	const [saveDismissiblesMutation] = useMutation(SAVE_USER_DISMISSIBLES)

	const dismiss = async (dismissible: UserDismissible) => {
		await saveDismissiblesMutation({
			variables: {
				payload: { dismissible },
			},
			optimisticResponse: {
				saveUserDismissibles: {
					dismissibles: [...userDismissibles, dismissible],
				},
			},
		})
	}

	return {
		dismissibles: new Set(userDismissibles),
		dismissiblesLoading: sessionInfoLoading || userDataLoading,
		dismiss,
	}
}
