import * as React from 'react'
import { CustomTrackingField, CustomTrackingFieldValues } from './types'
import { isNotNil } from '@persuit/common-utils'
import {
	FormTextField,
	PopoverHelp,
	Box,
	FormNumberFieldCleave,
	FormDateTimePicker,
	FormRichTextEditor,
} from '@persuit/ui-components'
import { CheckboxHierarchy } from './checkbox-hierarchy'
import { useFormContext } from '../form-utils'

type CustomTrackingFieldListProps = {
	customTrackingFields: CustomTrackingField[]
	fieldNamePrefix: string
	errors: {
		message: string
		index: number
	}[]
}

export const CustomTrackingFieldList = ({
	customTrackingFields,
	errors,
}: CustomTrackingFieldListProps) => {
	const { getValues } = useFormContext()
	const { customFields } = getValues()

	return (
		<Box>
			{customTrackingFields.map((customField, index) => {
				if (customField.isArchived) {
					return null
				}

				const dataIndex = (customFields ?? []).findIndex(
					(data) => data?.customTrackingFieldId === customField._id,
				)

				const formIndex = typeof dataIndex !== 'undefined' && dataIndex !== -1 ? dataIndex : index

				const errorMessage = errors.find((error) => error.index === index)?.message ?? ''

				let customFieldComponent: React.ReactNode = null

				switch (customField.fieldType) {
					case 'HIERARCHY': {
						// Filter out archived values
						const filteredValues =
							customField?.values
								?.filter(isNotNil)
								.reduce<CustomTrackingFieldValues[]>((acc, value) => {
									if (value.isArchived) {
										return acc
									}

									const childValues =
										value?.values?.filter(isNotNil).filter((child) => !child.isArchived) ?? []

									acc.push({
										...value,
										values: childValues,
									})

									return acc
								}, []) || []

						customFieldComponent = (
							<CheckboxHierarchy
								id={customField._id}
								name={`tracking.customFields.${formIndex}.hierarchyValue`}
								label={customField.name ?? ''}
								values={filteredValues}
								required={customField.mandatory}
								error={errorMessage}
							/>
						)
						break
					}

					case 'PLAIN_TEXT': {
						customFieldComponent = (
							<FormTextField
								id={customField._id}
								name={`tracking.customFields.${formIndex}.plainTextValue`}
								label={customField.name}
								fullWidth={true}
								required={customField.mandatory}
								error={!!errorMessage}
								helperText={errorMessage}
							/>
						)
						break
					}

					case 'NUMERIC': {
						customFieldComponent = (
							<FormNumberFieldCleave
								id={customField._id}
								name={`tracking.customFields.${formIndex}.numericValue`}
								fullWidth={true}
								label={customField?.name}
								required={customField?.mandatory}
								error={!!errorMessage}
								helperText={errorMessage}
							/>
						)
						break
					}

					case 'RICH_TEXT': {
						customFieldComponent = (
							<FormRichTextEditor
								id={customField._id}
								label={customField.name ?? ''}
								name={`tracking.customFields.${formIndex}.richTextValue`}
								height={200}
								characterCount={true}
								maxChars={5000}
								containerProps={{
									flexGrow: 1,
								}}
								error={!!errorMessage}
								helperText={errorMessage}
								simplified={true}
								required={customField?.mandatory}
							/>
						)
						break
					}

					case 'DATE': {
						customFieldComponent = (
							<FormDateTimePicker
								id={customField._id}
								label={customField.name ?? ''}
								name={`tracking.customFields.${formIndex}.dateValue`}
								fullWidth={true}
								error={!!errorMessage}
								helperText={errorMessage}
								dateFormat={'yyyy/MM/dd'}
								datePickerOnly={true}
								containerProps={{
									flexGrow: 1,
								}}
								required={customField.mandatory}
							/>
						)
						break
					}

					default: {
						return null
					}
				}

				return (
					<Box key={customField._id || index} mt={4} display="flex" alignItems="center">
						{customFieldComponent}
						{customField?.description && (
							<PopoverHelp
								content={customField.description}
								triggerButtonProps={{
									'aria-label': `${customField.name} description`,
								}}
							/>
						)}
					</Box>
				)
			})}
		</Box>
	)
}
