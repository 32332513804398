import { useId } from 'react'
import { Typography } from '@persuit/ui-components'

import { useStore } from '../../store'

import { RowHeaderCell, BaseIntelligenceCell, Row } from '../components'
import { proposalSummaryRowId, proposalSummaryCellHeaders } from '../accessibilty'

import { SummaryCell } from './summary-cell'

export const SummaryRow = () => {
	const { responses, showIntelligenceColumn } = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
	}))

	const titleId = useId()

	return (
		<Row>
			<RowHeaderCell cellProps={{ id: proposalSummaryRowId, 'aria-labelledby': titleId }}>
				<Typography id={titleId}>Proposal Summary</Typography>
			</RowHeaderCell>

			{showIntelligenceColumn && <BaseIntelligenceCell cellProps={{ 'aria-hidden': true }} />}

			{responses.map((response, index, array) => {
				return (
					<SummaryCell
						showRightBorder={index === array.length - 1}
						headers={proposalSummaryCellHeaders(response._id)}
						key={response._id}
						responseId={response._id}
						summaryOfResponse={response.summaryOfProposal}
						filesCount={response.files ? response.files.length : null}
					/>
				)
			})}
		</Row>
	)
}
