// @ts-strict-ignore
import { useState } from 'react'
import { ga } from '@persuit/ui-analytics'
import { useMutation, useQuery } from '@persuit/ui-graphql'
import { isNotNilProp } from '@persuit/common-utils'

import { isEmpty } from 'lodash/fp'
import { Box, Button, useConfirmDialog, useSnackbar } from '@persuit/ui-components'

import { useSelector, useDispatch } from '@client/store'

import useTranslation from '../../custom-hooks/translation-hook'

import allowTypes from '../../../common/data/allow-types'
import rfpStates from '../../../common/data/rfp-states'
import sharingErrors from '../../../common/data/sharing-errors'
import unregisteredUserListState from '../../../common/data/unregistered-user-list-states'
import unregisteredUserStates from '../../../common/data/unregistered-user-states'

import { authActions, orgActions } from '../../actions'
import LoadingSpinner from '../../components/loading-spinner.jsx'
import { useInvitablePeople } from '../../custom-hooks'

import {
	REVOKE_MUTATION,
	GET_OWN_ORG_QUERY,
	INVITE_UNREG_FIRM_MUTATION,
	SHARE_MUTATION,
	GET_SESSION_QUERY,
} from './sharing-control.graphql'

import { SharingAutoComplete, Person, KnownPerson, UnknownPerson } from './sharing-autocomplete'
import { PeopleSearchDialog } from './people-search-dialog'

export type SharingControlProps = {
	entity: {
		_id: string
		createdBy: {
			_id: string
		}
		org: { _id: string }
		allow: Array<{
			org: string
			type: string
			kind: string
			addedBySystem: boolean
			item: {
				_id: string
				name: { first: string; last: string }
				email: string
				org: {
					_id: string
					isNamwolfMember: boolean
					name: string
				}
			}
		}>
		unregisteredUsers: Array<{
			_id: string
			email: string
			list: string
			createdByOrg: unknown
			status: string
		}>
		status: string
	}
	postSave: () => void
	label?: string
	suppressSnack?: boolean
}

export const SharingControl = ({
	entity,
	postSave,
	label,
	suppressSnack = false,
}: SharingControlProps) => {
	const { openConfirmDialog } = useConfirmDialog()
	const { openSnackbar } = useSnackbar()

	const [share] = useMutation(SHARE_MUTATION)
	const [clientInviteUnregFirmToPublishedRFP] = useMutation(INVITE_UNREG_FIRM_MUTATION)
	const [revoke] = useMutation(REVOKE_MUTATION)
	const getOwnOrg = useQuery(GET_OWN_ORG_QUERY)
	const { data: getSessionData, loading: loadingGetSessionInfo } = useQuery(GET_SESSION_QUERY)

	const dispatch = useDispatch()

	const [showPeopleSearchDialog, setShowPeopleSearchDialog] = useState(false)

	const people = useInvitablePeople({ includeColleagues: true })

	if (people.loading || getOwnOrg.loading || loadingGetSessionInfo) {
		return <LoadingSpinner />
	}

	if (!getSessionData) {
		return null
	}

	const user = getSessionData.getSessionInfo.user ?? {}
	const userGroup = user.group ?? undefined
	const userOrg = user.org ?? {}
	const userOrgId = user?.org?._id ?? null

	if (isEmpty(user) || !user._id || isEmpty(userOrg)) {
		return <LoadingSpinner />
	}

	const userFavorites = user.favourites ?? []

	async function updateUserOrg() {
		const orgs = await dispatch(orgActions.getOrgs())
		// If a new org was created it was added to the users panel
		const updatedUserOrg = orgs.find((o) => {
			return o._id === userOrgId
		})
		// update user local org with new panel to see the new org in the To search
		return dispatch(authActions.updateUserOrg(updatedUserOrg))
	}

	const selectedUnknownUsers: UnknownPerson[] = (entity.unregisteredUsers ?? [])
		.filter(({ status }) => status === unregisteredUserStates.OPEN)
		.filter(
			({ list, createdByOrg }) =>
				list === unregisteredUserListState.TO ||
				(list === unregisteredUserListState.SHARED && createdByOrg !== entity.org._id),
		)
		.map((item) => ({
			...item,
			__type__: 'unknown',
		}))

	const isFavourite = (userId: string) => userFavorites.includes(userId)

	const selectedKnownUsers: KnownPerson[] = entity.allow
		// Filter out users colleagues
		.filter(({ org }) => {
			return (
				// invite firm people
				org !== userOrgId
			)
		})
		// Filter out revoked invites
		.filter(({ type }) => type !== allowTypes.REVOKED)
		.filter(({ addedBySystem }) => !addedBySystem)
		// Map invited people into the shape the SharingComponent expects
		.map(({ item }) => ({
			__type__: 'known',
			_id: item._id,
			name: item.name,
			email: item.email,
			org: item.org,
			isFavourite: isFavourite(item._id),
		}))

	// Alot of these checks are just incorrect GraphQL schema types
	const invitablePeople = (people.data?.getInvitablePeople?.users ?? [])
		// workaround for bad data in db - users without local accounts
		.filter(isNotNilProp('email'))
		// fitler out current user
		.filter(({ _id }) => _id !== user._id)
		.filter(
			({ org }) =>
				// only invite firm people
				org?._id !== userOrgId,
		)
		.filter(isNotNilProp('_id'))
		// just to type errors
		.map(({ name, org, ...rest }) => ({
			...rest,
			__type__: 'known' as const,
			isFavourite: isFavourite(rest._id.toString()),
			name: name ? name : null,
			org:
				org && org._id
					? {
							_id: org._id ?? '',
							isNamwolfMember: !!org.isNamwolfMember,
							name: org.name ?? '',
					  }
					: null,
		}))
		.filter(isNotNilProp('org'))
		.filter(isNotNilProp('name'))

	const shouldShowConfirmation = [
		rfpStates.FINALIZED,
		rfpStates.CLOSED,
		rfpStates.COMPLETED,
	].includes(entity.status as unknown as any)

	// This processes the share
	async function handleShare(mappedItems: MappedSharedItem[]) {
		// This needs to run after a share has been successfully added (i.e.
		// update mattermost, invitable people and panel firms, if applicable)
		function handlePostShare() {
			if (!suppressSnack) openSnackbar('Invited Succesfully')

			// This applies is a new org was auto-registered (adds it the
			// user's org's panel and refetches it)
			updateUserOrg()

			// This applies if a new user was created - update the list
			// of invitable people to contain the newly created user
			people.refetch()
		}

		const entityStatus = entity.status

		let shareResolverUsers: MappedSharedItem[] = []
		let unregFirmResolverEmails: string[] = []

		if (entityStatus === rfpStates.DRAFT) {
			// Temporarily need to split these up while refactoring sharing.
			// When its a draft reg and unreg firm users will still got to the share resolver
			// When its published reg firm users goto share and unreg goes to clientInviteUnregFirmToPublishedRFP resolver
			shareResolverUsers = mappedItems
		} else {
			// May be sharing if we are correcting an amendable error where the user tries to invite their colleague instead of sharing.
			shareResolverUsers = mappedItems.filter(
				({ list, _id }) =>
					// invite registered users
					(list === 'to' && _id) ||
					// share with unregistered or registered users
					list === 'shared',
			)
			// Data for the new sharing path - unregistered firm users invited to a published RFP
			unregFirmResolverEmails = mappedItems
				.filter(({ list, _id }) => list === 'to' && !_id)
				.map(({ email }) => email)
		}

		// Temporarily calling 2 mutation queries. unregisteredEmails is the new one
		if (unregFirmResolverEmails.length !== 0) {
			try {
				await clientInviteUnregFirmToPublishedRFP({
					variables: {
						id: entity._id,
						unregisteredEmails: unregFirmResolverEmails,
					},
				})

				// Kick off any functions that need to run after
				// a share has been successfully added
				handlePostShare()
			} catch (error) {
				openConfirmDialog({
					title: 'Please enter valid email addresses',
					content: <InviteErrors error={error} />,
					actions: [{ label: 'Ok', close: true }],
				})
			}
		}

		// Still call the old share resolver for existing users
		// invites for registered users
		if (shareResolverUsers.length !== 0) {
			try {
				await share({
					variables: {
						id: entity._id,
						users: shareResolverUsers,
					},
				})

				// Kick off any functions that need to run after
				// a share has been successfully added
				handlePostShare()
			} catch (error) {
				const amendableError = extractAmmendableError(error)
				if (amendableError) {
					openConfirmDialog({
						title: 'Allow this user to correctly access this request',
						content: (
							<div>
								You cannot invite your own organization to a request. Please confirm if you would
								like to share this request with your colleague instead.
								<ul>
									{amendableError.users.map((item) => (
										<li key={item.email}>{item.email}</li>
									))}
								</ul>
							</div>
						),
						actions: [
							{ type: 'secondary', label: 'Cancel', close: true },
							{
								label: 'Confirm',
								close: true,
								action: () => handleShare(amendableError.users),
							},
						],
					})
				} else {
					openConfirmDialog({
						title: 'Please enter valid email addresses.',
						content: <InviteErrors error={error} />,
						actions: [{ label: 'Ok', close: true }],
					})
				}
			}
		}
	}

	return (
		<div>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
				{label && (
					<Button
						variant="outlined"
						data-testid="bcc-button"
						onClick={() => {
							ga({
								label: 'FEATURE_USAGE',
								page: 'COLLEAGUE_SHARE',
								event: 'BCC_SHARE',
							})
							setShowPeopleSearchDialog(true)
						}}
					>
						{label}
					</Button>
				)}
				<SharingAutoComplete
					people={invitablePeople}
					selectedPeople={[...selectedKnownUsers, ...selectedUnknownUsers]}
					panelFirmTags={getOwnOrg.data?.getOwnOrg?.panelFirmTags ?? []}
					onSelect={(people) => {
						const items = mapShareItems(people)
						if (!shouldShowConfirmation) {
							handleShare(items)
						} else {
							openConfirmDialog({
								title: 'Are you sure you want to add the selected people to this request?',
								actions: [
									{ type: 'secondary', label: 'Cancel', close: true },
									{ label: 'Add', close: true, action: () => handleShare(items) },
								],
								content: (
									<div>
										The following people will be given access to this request and notified
										immediately:
										<ul>
											{items.map((item) => (
												<li key={item.email}>{item.email}</li>
											))}
										</ul>
									</div>
								),
							})
						}
					}}
					onDelete={async (person: Person) => {
						if (selectedUnknownUsers.find((p) => p._id === person._id)) {
							const unknownPerson = person
							await revoke({
								variables: {
									rfpId: entity._id,
									userIds: [unknownPerson._id],
								},
							})
							if (!suppressSnack) openSnackbar('Invite Revoked')
						} else if (selectedKnownUsers.find((p) => p._id === person._id)) {
							const knownPerson = person as KnownPerson
							const revokeShare = async () => {
								await revoke({
									variables: {
										rfpId: entity._id,
										userIds: [knownPerson._id],
									},
								})
								if (!suppressSnack) openSnackbar('Invite Revoked')
							}

							if (entity.status === rfpStates.DRAFT) {
								revokeShare()
							} else {
								openConfirmDialog({
									title: 'Are you sure you want to remove access from this person?',
									content: `${knownPerson.name.first} ${knownPerson.name.last} will not be able to view this Request anymore.`,
									actions: [
										{ type: 'secondary', label: 'Cancel', close: true },
										{ label: 'Confirm', close: true, action: revokeShare },
									],
								})
							}
						}
					}}
				/>
			</Box>

			{showPeopleSearchDialog && (
				<PeopleSearchDialog
					entity={entity}
					isOpen={true}
					togglePeopleSearchDialog={() => setShowPeopleSearchDialog(false)}
					showConfirmation={shouldShowConfirmation}
					postSave={postSave}
					user={{ _id: user._id, org: userOrg, group: userGroup }}
				/>
			)}
		</div>
	)
}

type MappedSharedItem = {
	_id?: string
	email: string
	list: typeof unregisteredUserListState.TO
}

// This maps the share items into the shape expected by the
// sharing resolver (note: that the shape is the same for
// unregistered users and registered users)
function mapShareItems(items: Array<string | { _id: string; email: string }>): MappedSharedItem[] {
	return items.map((item) => ({
		_id: typeof item === 'string' ? undefined : item._id,
		email: typeof item === 'string' ? item : item.email,
		list: unregisteredUserListState.TO,
	}))
}

type InviteErrorProps = {
	error: any
}
// Convenience function to format and translate any errors for displaying
// them in a dialog
const InviteErrors = ({ error }: InviteErrorProps) => {
	const { t } = useTranslation()
	const allowableEmailSuffixes = useSelector(
		(state) => state.auth.user?.org?.allowableEmailSuffix ?? [],
	)

	if (!error) {
		return null
	}

	// The custom error data sent back from the sharing resolver is contained here
	// if there any errors occurred
	const errorData: any = error?.graphQLErrors?.[0]?.extensions || null

	if (!errorData) {
		return null
	}

	// Append errors for existing user shares (group invalid user shares by error)
	const existingUserErrors = errorData?.erroneousExistingUsers
		? Object.keys(errorData.erroneousExistingUsers).reduce((acc, errorKey) => {
				const errorForUser = errorData.erroneousExistingUsers[errorKey]
				const errorMessage = t(`sharing.${errorForUser.error}`)

				acc[errorMessage] = {
					...(acc[errorMessage] || {}),
					[errorForUser.user.email]: true,
				}

				return acc
		  }, {})
		: []

	// Append errors for unregistered user shares (group invalid user shares by error)
	const unregisteredUserErrors = errorData?.erroneousUnregisteredUsers
		? Object.keys(errorData?.erroneousUnregisteredUsers).reduce((acc, errorKey) => {
				const errorForUser = errorData.erroneousUnregisteredUsers[errorKey]
				const errorMessage = t(`sharing.${errorForUser.error}`)

				acc[errorMessage] = {
					...(acc[errorMessage] || {}),
					[errorForUser.user.email]: true,
				}

				return acc
		  }, {})
		: []

	// Construct allowable email suffixes for displaying in the error dialog
	const allowableEmailMessage =
		allowableEmailSuffixes.length > 0
			? t('sharing.trusted-domains', { domains: allowableEmailSuffixes.join(', ') })
			: ''

	return (
		<div>
			{/*
					Show all existing user errors (single error message followed by each
					invalid share
				*/}
			{Object.keys(existingUserErrors).map((existingUserError) => {
				const userEmails = Object.keys(existingUserErrors[existingUserError])

				const showAllowableEmailMessage =
					existingUserError === t(`sharing.${sharingErrors.ANY_SHARING_OUTSIDE_TRUSTED_DOMAIN}`) ||
					existingUserError === t(`sharing.${sharingErrors.CLIENT_SHARING_WITH_ANOTHER_ORG}`)

				return (
					<div key={existingUserError}>
						{existingUserError}&nbsp;{showAllowableEmailMessage && allowableEmailMessage}
						<ul>
							{userEmails.map((email) => (
								<li key={email}>{email}</li>
							))}
						</ul>
					</div>
				)
			})}

			{/*
					Show all unregistered user errors (single error message followed by each
					invalid share
				*/}
			{Object.keys(unregisteredUserErrors).map((unregisteredUserError) => {
				const userEmails = Object.keys(unregisteredUserErrors[unregisteredUserError])

				const showAllowableEmailMessage =
					unregisteredUserError ===
						t(`sharing.${sharingErrors.ANY_SHARING_OUTSIDE_TRUSTED_DOMAIN}`) ||
					unregisteredUserError === t(`sharing.${sharingErrors.CLIENT_SHARING_WITH_ANOTHER_ORG}`)

				return (
					<div key={unregisteredUserError}>
						{unregisteredUserError}&nbsp;{showAllowableEmailMessage && allowableEmailMessage}
						<ul>
							{userEmails.map((email) => (
								<li key={email}>{email}</li>
							))}
						</ul>
					</div>
				)
			})}
		</div>
	)
}

function extractAmmendableError(error: any) {
	// The custom error data sent back from the sharing resolver is contained here
	// if there any errors occurred
	const errorData = error?.graphQLErrors?.[0]?.extensions

	if (!errorData) {
		return null
	}

	// An error can only be amended if a user has attempted to share with a single invalid
	// existing user (amending multiple errors is not considered)
	if (
		Object.keys(errorData?.erroneousExistingUsers).length === 1 &&
		Object.keys(errorData?.erroneousUnregisteredUsers).length === 0
	) {
		const errorObj =
			errorData.erroneousExistingUsers[Object.keys(errorData?.erroneousExistingUsers)[0]]
		const error = errorObj.error
		const email = errorObj.user.email

		// If an error was received from the server that a client user is attempting
		// to INVITE a colleague then this will amend that to a SHARE.
		if (error === sharingErrors.CLIENT_INVITING_OWN_ORG) {
			return {
				error,
				users: [{ email, list: unregisteredUserListState.SHARED }],
			}
		}
	}
}
