const PROPOSAL_PAGES = {
	HOME: 'HOME',
	VIEW_REQUEST: 'VIEW_REQUEST',
	VIEW_PROPOSAL: 'VIEW_PROPOSAL',
	EDIT_PROPOSAL: 'EDIT_PROPOSAL',
	PROPOSAL_PREVIEW_COMPARE: 'PROPOSAL_PREVIEW_COMPARE',
	PROPOSAL_PREVIEW_DETAIL: 'PROPOSAL_PREVIEW_DETAIL',
	PROPOSAL_PREVIEW: 'PROPOSAL_PREVIEW',
	VIEW_AUCTION: 'auction',
	BACK: 'back',
} as const

const values = Object.values(PROPOSAL_PAGES)

type PagesType = (typeof PROPOSAL_PAGES)[keyof typeof PROPOSAL_PAGES]

export { PROPOSAL_PAGES, PagesType, values, PROPOSAL_PAGES as default }
