import { SvgIcon, SvgIconProps } from '../components/svg'

export const PriceBenchmarkingIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M 8.6162 5.2667 C 7.1029 4.8733 6.6162 4.4667 6.6162 3.8333 C 6.6162 3.1067 7.2896 2.6 8.4162 2.6 C 9.6029 2.6 10.0429 3.1667 10.0829 4 H 11.5562 C 11.5096 2.8533 10.8096 1.8 9.4162 1.46 V 0 H 7.4162 V 1.44 C 6.1229 1.72 5.0829 2.56 5.0829 3.8467 C 5.0829 5.3867 6.3562 6.1533 8.2162 6.6 C 9.8829 7 10.2162 7.5867 10.2162 8.2067 C 10.2162 8.6667 9.8896 9.4 8.4162 9.4 C 7.0429 9.4 6.5029 8.7867 6.4296 8 H 4.9629 C 5.0429 9.46 6.1362 10.28 7.4162 10.5533 V 12 H 9.4162 V 10.5667 C 10.7162 10.32 11.7496 9.5667 11.7496 8.2 C 11.7496 6.3067 10.1296 5.66 8.6162 5.2667 Z"
				fill="white"
			/>
			<path
				d="M 19.88 20.47 C 20.32 19.77 20.58 18.96 20.58 18.08 C 20.58 15.59 18.57 13.58 16.08 13.58 C 13.59 13.58 11.58 15.59 11.58 18.08 C 11.58 20.57 13.59 22.58 16.07 22.58 C 16.95 22.58 17.77 22.32 18.46 21.88 L 20.58 24 L 22 22.58 L 19.88 20.47 Z M 16.08 20.58 C 14.7 20.58 13.58 19.46 13.58 18.08 C 13.58 16.7 14.7 15.58 16.08 15.58 C 17.46 15.58 18.58 16.7 18.58 18.08 C 18.58 19.46 17.46 20.58 16.08 20.58 Z M 15.72 12.08 C 14.98 12.1 14.27 12.26 13.62 12.53 L 13.07 11.7 L 9.27 17.88 L 6.26 14.36 L 2.63 20.17 L 1 19 L 6 11 L 9 14.5 L 13 8 L 15.72 12.08 Z M 18.31 12.58 C 17.67 12.3 16.98 12.13 16.26 12.09 L 21.38 4 L 23 5.18 L 18.31 12.58 Z"
				fill="white"
			/>
		</SvgIcon>
	)
}
