import { Maybe, UseCase } from '@persuit/ui-graphql/generated'
import { RfpSection } from './types'

const labelMap = {
	clarification: 'Clarification',
	summary: 'Summary',
	pricing: 'Pricing',
	rates: 'Rates',
	questions: 'Questions',
	diversity: 'Diversity',
	conflict: 'Conflict Check',
	tracking: 'Tracking (internal use only)',
	scorecard: 'Scorecard (internal use only)',
	invite: 'Invite',
}

export function getSectionLabel(
	useCase: Maybe<UseCase> | undefined,
	section: RfpSection,
	panelManagementIsEnabled: boolean = false,
): string {
	if (section === 'timeline') {
		return getTimelineLabel(useCase)
	} else if (section === 'invite' && panelManagementIsEnabled) {
		return 'Invite Contacts'
	} else {
		return labelMap[section]
	}
}

function getTimelineLabel(useCase?: Maybe<UseCase>): string {
	return useCase === 'rateReview' ? 'Timeline' : 'Timeline | Reverse Auction'
}
