import { useState, useEffect, useMemo } from 'react'
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { LoadingSpinner } from '@persuit/ui-components'
import { Unauthorized, ErrorPage } from '../../common-components'
import { AlreadyPublished } from '../already-published'
import { copyValidationErrors } from '../../sectional-form/copy-validation-errors'
import { Layout } from '../layout'
import { RfpTemplateFormRoutes, RfpTemplate } from '../types'
import { RoutesContext } from '../routes-context'
import { logger } from '@persuit/ui-logger'
// eslint-disable-next-line no-restricted-imports
import {
	useRfpTemplateFormDraftErrorsQuery,
	useRfpTemplateFormRfpTemplateDraftQuery,
} from '@persuit/ui-graphql/hooks'
import { isUnauthorised } from '@persuit/ui-utils'
import { useUser } from '@persuit/ui-auth'

function getHasGQLErrors(errors: unknown[] = []) {
	return errors.reduce((acc, error) => {
		if (error) {
			acc = true
			console.error(error)
		}
		return acc
	}, false)
}

type RfpTemplateFormProps = {
	routes: RfpTemplateFormRoutes
}

export const RfpTemplateForm = ({ routes }: RfpTemplateFormProps) => {
	const { templateId, section } = useParams<{ templateId: string; section: string }>()
	const [sendMode, setSendMode] = useState(false)
	const { path } = useRouteMatch()
	const history = useHistory()

	useEffect(() => {
		setSendMode(false)
	}, [templateId])

	const {
		data: getRfpTemplateData,
		loading: getRfpTemplateLoading,
		error: getRfpTemplateError,
		refetch: getRfpTemplateRefetch,
	} = useRfpTemplateFormRfpTemplateDraftQuery({
		variables: { rfpTemplateId: templateId },
	})

	const {
		data: getRfpTemplateDraftErrorsData,
		error: getRfpTemplateDraftErrorsError,
		loading: getRfpTemplateDraftErrorsLoading,
		refetch: getRfpTemplateDraftErrorsRefetch,
	} = useRfpTemplateFormDraftErrorsQuery({
		variables: { rfpTemplateId: templateId, logErrors: false },
	})

	const loading = getRfpTemplateLoading || getRfpTemplateDraftErrorsLoading

	const dataLoaded =
		getRfpTemplateData?.getRfpTemplateDraft &&
		getRfpTemplateDraftErrorsData?.getRfpTemplateDraftErrors

	const hasGQLErrors = getHasGQLErrors([getRfpTemplateError, getRfpTemplateDraftErrorsError])

	const { user } = useUser()

	// When we have fresh errors, we force "update" (copy) them so as they can be
	// Re-applied to the fields, as it is valid to update the errors, but them be "unchanged"
	// We only need copy them down to the section level, as that is where the useEffect checks
	// for "updated" data
	const ErrorsUpdatedAt = getRfpTemplateDraftErrorsData?.getRfpTemplateDraftErrors?.updatedAt ?? 0

	const cleanValidationErrors = useMemo(() => {
		const errors = getRfpTemplateDraftErrorsData?.getRfpTemplateDraftErrors?.errors
		return copyValidationErrors(errors)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ErrorsUpdatedAt])

	// Show loading spinner when loading and there is no data, otherwise we can show the existing stale data
	if (loading && !dataLoaded) return <LoadingSpinner />

	// Check if users are not authorised to access the rfp
	if (isUnauthorised(getRfpTemplateError) || !getRfpTemplateData?.getRfpTemplateDraft) {
		return <Unauthorized redirectPath={`/en`} buttonLabel="Go to dashboard" />
	}

	// Render error page if there is other error
	if (hasGQLErrors || !user?._id) {
		if (hasGQLErrors) {
			logger.error('RFP_TEMPLATE_FORM_INVALID_STATE', {
				extra: {
					hasRfpData: String(!!getRfpTemplateData),
					hasValidateData: String(!!getRfpTemplateDraftErrorsData),
					hasError: String(hasGQLErrors),
					getRfpTemplateError: JSON.stringify(getRfpTemplateError),
					getRfpTemplateDraftErrorsError: JSON.stringify(getRfpTemplateDraftErrorsError),
				},
			})
		}
		return <ErrorPage />
	}

	const rfpDetail: RfpTemplate['detail'] = getRfpTemplateData?.getRfpTemplateDraft?.detail
	const rawRfp = getRfpTemplateData.getRfpTemplateDraft

	const rfpTemplate = {
		...rawRfp,
		templateTitle: rawRfp?.templateTitle ?? '',
		detail: rfpDetail,
	}

	const isPublished = Boolean(rfpTemplate?.templatePublished)

	if (isPublished) return <AlreadyPublished rfpTemplate={rfpTemplate} routes={routes} />

	const validateRfpTemplateWrapper = async () => {
		await getRfpTemplateDraftErrorsRefetch({ logErrors: !sendMode })
		if (!sendMode) setSendMode(true)
	}
	const isSystemTemplate = rfpTemplate?.templateType === 'system'

	// Disable diverstiry section if on a system template
	const isDiversitySectionEnabled = !isSystemTemplate
		? user.group?.settings.isDiversityQuestionnaireFeatureEnabled
		: false

	if (!isDiversitySectionEnabled && section === 'diversity') {
		const redirectPath = generatePath(path, {
			lng: 'en',
			templateId,
			section: 'detail',
		})
		history.push(redirectPath)
	}

	const refetchErrors = sendMode
		? async () => {
				await getRfpTemplateDraftErrorsRefetch()
		  }
		: undefined

	const refetchRfpTemplate = async () => {
		await getRfpTemplateRefetch()
	}

	return (
		<RoutesContext.Provider value={routes}>
			<Layout
				rfpTemplate={rfpTemplate}
				validateRfpTemplate={validateRfpTemplateWrapper}
				validationErrors={sendMode ? cleanValidationErrors : null}
				refetchErrors={refetchErrors}
				userId={user?._id}
				refetchRfpTemplate={refetchRfpTemplate}
				sendMode={sendMode}
				isDiversitySectionEnabled={!!isDiversitySectionEnabled}
			/>
		</RoutesContext.Provider>
	)
}
