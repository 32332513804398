import isEmpty from 'lodash/fp/isEmpty'
import { Typography, Box } from '@persuit/ui-components'

type ErrorMessagesProps = {
	errorMessages: Array<string>
}

export const ErrorMessages = ({ errorMessages }: ErrorMessagesProps) => {
	if (isEmpty(errorMessages)) return null
	return (
		<Box component="ul" p={0} color="error.main">
			{errorMessages
				.filter((errorMessage) => !isEmpty(errorMessage))
				.map((errorMessage) => (
					<Box key={errorMessage} component="li" color="error.main">
						<Typography display="inline" color="error">
							{errorMessage}
						</Typography>
					</Box>
				))}
		</Box>
	)
}
