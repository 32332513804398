import { styled, TableRow as MUITableRow, TableCell as MUITableCell } from '@persuit/ui-components'

export const TableRow = styled(MUITableRow)`
	&:nth-of-type(odd) {
		background-color: ${(props) => props.theme.palette.neutral.lightestHue};
	}
`

export const TableCell = styled(MUITableCell)`
	text-align: ${(props) => props.align ?? 'center'};
	border: 1px solid;
	border-color: ${(props) => props.theme.palette.background.appMain};
`

export const TableCellHeader = styled(MUITableCell)`
	text-align: ${(props) => props.align ?? 'center'};
	border: 1px solid;
	border-color: ${(props) => props.theme.palette.background.appMain};
	border-bottom-color: ${(props) => props.theme.palette.form.borderActive};
`
