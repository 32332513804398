/**
 * Do not edit directly
 * Generated on Thu, 18 Jul 2024 19:44:50 GMT
 */

export const colorComponentTextHeading = '#000000'
export const colorComponentTextPrimary = '#000000'
export const colorComponentTextSecondary = '#535359'
export const colorComponentTextWhite = '#ffffff'
export const colorComponentTextInfo = '#004a76'
export const colorComponentTextSuccess = '#086e00'
export const colorComponentTextWarning = '#ab5301'
export const colorComponentTextError = '#ba1a1a'
export const colorComponentTextDisabled = '#6b6b77'
export const colorComponentSurfacePrimary = '#ffffff'
export const colorComponentSurfaceSecondary = '#fafafa'
export const colorComponentSurfaceBackdrop = '#000000'
export const colorComponentBorderMain = '#b9b9bf'
export const colorComponentBorderDivider = '#d1d1d5'
export const colorComponentBorderFocus = '#7b6ac1'
export const colorCoreAmber10 = '#ab5301'
export const colorCoreAmber20 = '#ff8b00'
export const colorCoreAmber30 = '#ffb55c'
export const colorCoreAmber40 = '#fff2e2'
export const colorCoreBlack = '#000000'
export const colorCoreBlack50 = '#000000'
export const colorCoreBlack80 = '#000000'
export const colorCoreBlue10 = '#002338'
export const colorCoreBlue20 = '#003353'
export const colorCoreBlue30 = '#004a76'
export const colorCoreBlue40 = '#00639b'
export const colorCoreBlue50 = '#007cc2'
export const colorCoreBlue60 = '#0097ea'
export const colorCoreBlue70 = '#52b2ff'
export const colorCoreBlue80 = '#97cbff'
export const colorCoreBlue90 = '#cee5ff'
export const colorCoreBlue95 = '#e8f2ff'
export const colorCoreBlue99 = '#f4f9ff'
export const colorCoreGold10 = '#705d00'
export const colorCoreGold20 = '#b19500'
export const colorCoreGold30 = '#ffdd88'
export const colorCoreGold40 = '#f9efd6'
export const colorCoreGreen10 = '#012200'
export const colorCoreGreen20 = '#023900'
export const colorCoreGreen30 = '#045300'
export const colorCoreGreen40 = '#086e00'
export const colorCoreGreen50 = '#008a02'
export const colorCoreGreen60 = '#34a624'
export const colorCoreGreen70 = '#52c23e'
export const colorCoreGreen80 = '#6edf57'
export const colorCoreGreen90 = '#aaf39a'
export const colorCoreGreen95 = '#dcfad1'
export const colorCoreGreen99 = '#f7ffee'
export const colorCoreGrey10 = '#1c1c1e'
export const colorCoreGrey20 = '#38383c'
export const colorCoreGrey30 = '#535359'
export const colorCoreGrey40 = '#6b6b77'
export const colorCoreGrey50 = '#8b8b95'
export const colorCoreGrey60 = '#a2a2aa'
export const colorCoreGrey70 = '#b9b9bf'
export const colorCoreGrey80 = '#d1d1d5'
export const colorCoreGrey90 = '#e7e7e7'
export const colorCoreGrey95 = '#f3f3f3'
export const colorCoreGrey99 = '#fafafa'
export const colorCoreGreyVariant10 = '#141d1c'
export const colorCoreGreyVariant20 = '#293231'
export const colorCoreGreyVariant30 = '#3f4947'
export const colorCoreGreyVariant40 = '#56605f'
export const colorCoreGreyVariant50 = '#6f7977'
export const colorCoreGreyVariant60 = '#899391'
export const colorCoreGreyVariant70 = '#a3adab'
export const colorCoreGreyVariant80 = '#bec9c6'
export const colorCoreGreyVariant90 = '#dae5e2'
export const colorCoreGreyVariant95 = '#e9f3f1'
export const colorCoreGreyVariant99 = '#f4fefc'
export const colorCorePink10 = '#3f001b'
export const colorCorePink20 = '#650030'
export const colorCorePink30 = '#8f0046'
export const colorCorePink40 = '#ba005d'
export const colorCorePink50 = '#e02576'
export const colorCorePink60 = '#ff4a8f'
export const colorCorePink70 = '#ff84aa'
export const colorCorePink80 = '#ffb1c5'
export const colorCorePink90 = '#ffd9e1'
export const colorCorePink95 = '#ffecef'
export const colorCorePink99 = '#fff3f5'
export const colorCorePurple10 = '#1e1641'
export const colorCorePurple20 = '#2b1576'
export const colorCorePurple30 = '#4a398c'
export const colorCorePurple40 = '#6251a6'
export const colorCorePurple50 = '#7b6ac1'
export const colorCorePurple60 = '#9584dd'
export const colorCorePurple70 = '#b09ffa'
export const colorCorePurple80 = '#cbbeff'
export const colorCorePurple90 = '#e6deff'
export const colorCorePurple95 = '#f4eeff'
export const colorCorePurple99 = '#fbf8ff'
export const colorCoreRed10 = '#410002'
export const colorCoreRed20 = '#93000a'
export const colorCoreRed30 = '#93000a'
export const colorCoreRed40 = '#ba1a1a'
export const colorCoreRed50 = '#de3730'
export const colorCoreRed60 = '#ff5449'
export const colorCoreRed70 = '#ff897d'
export const colorCoreRed80 = '#ffb4ab'
export const colorCoreRed90 = '#ffdad6'
export const colorCoreRed95 = '#ffedea'
export const colorCoreRed99 = '#fff6f5'
export const colorCoreTeal10 = '#00201e'
export const colorCoreTeal20 = '#003734'
export const colorCoreTeal30 = '#00504b'
export const colorCoreTeal40 = '#006a64'
export const colorCoreTeal50 = '#00857e'
export const colorCoreTeal60 = '#00a299'
export const colorCoreTeal70 = '#25beb5'
export const colorCoreTeal80 = '#4ddcd4'
export const colorCoreTeal90 = '#a0ede8'
export const colorCoreTeal95 = '#ccfbf8'
export const colorCoreTeal99 = '#f7ffee'
export const colorCoreWhite = '#ffffff'
export const colorCoreYellow10 = '#261b00'
export const colorCoreYellow20 = '#4c3600'
export const colorCoreYellow30 = '#9d6c23'
export const colorCoreYellow40 = '#bc9331'
export const colorCoreYellow50 = '#edb62f'
export const colorCoreYellow60 = '#f5c800'
export const colorCoreYellow70 = '#f8d548'
export const colorCoreYellow80 = '#ffe23a'
export const colorCoreYellow90 = '#ffec7d'
export const colorCoreYellow95 = '#fff5bc'
export const colorCoreYellow99 = '#fffae3'
export const colorSemanticActionActive = '#6b6b77'
export const colorSemanticActionDisabled = '#b9b9bf'
export const colorSemanticActionDisabledBg = '#e7e7e7'
export const colorSemanticActionFocus = '#d1d1d5'
export const colorSemanticActionHover = '#fafafa'
export const colorSemanticActionSelected = '#f3f3f3'
export const colorSemanticBackgroundAppMain = '#f3f3f3'
export const colorSemanticBackgroundBackdropOverlay = '#000000'
export const colorSemanticBackgroundContentMain = '#ffffff'
export const colorSemanticBackgroundGreyLabel = '#535359'
export const colorSemanticBackgroundInfo = '#f4f9ff'
export const colorSemanticBackgroundNavigationMain = '#2b1576'
export const colorSemanticBackgroundWarning = '#fffae3'
export const colorSemanticCommonBlack = '#000000'
export const colorSemanticCommonBlackTransparentDark = '#000000'
export const colorSemanticCommonBlackTransparentLight = '#000000'
export const colorSemanticCommonWhite = '#ffffff'
export const colorSemanticDividerMain = '#bec9c6'
export const colorSemanticErrorContrastText = '#ffffff'
export const colorSemanticErrorDarkHue = '#93000a'
export const colorSemanticErrorDarkerHue = '#410002'
export const colorSemanticErrorLightHue = '#ff897d'
export const colorSemanticErrorLighterHue = '#ffdad6'
export const colorSemanticErrorLightestHue = '#ffedea'
export const colorSemanticErrorMain = '#ba1a1a'
export const colorSemanticFormBorderActive = '#8b8b95'
export const colorSemanticFormBorderResting = '#bec9c6'
export const colorSemanticInfoContrastText = '#ffffff'
export const colorSemanticInfoDarkHue = '#004a76'
export const colorSemanticInfoDarkerHue = '#002338'
export const colorSemanticInfoLightHue = '#52b2ff'
export const colorSemanticInfoLighterHue = '#cee5ff'
export const colorSemanticInfoLightestHue = '#e8f2ff'
export const colorSemanticInfoMain = '#004a76'
export const colorSemanticNeutralDarkHue = '#535359'
export const colorSemanticNeutralDarkerHue = '#38383c'
export const colorSemanticNeutralDarkestHue = '#1c1c1e'
export const colorSemanticNeutralFaint = '#fafafa'
export const colorSemanticNeutralLightHue = '#d1d1d5'
export const colorSemanticNeutralLighterHue = '#e7e7e7'
export const colorSemanticNeutralLightestHue = '#f3f3f3'
export const colorSemanticNeutralMain = '#6b6b77'
export const colorSemanticPremiumDarkHue = '#705d00'
export const colorSemanticPremiumLightHue = '#ffdd88'
export const colorSemanticPremiumLightestHue = '#f9efd6'
export const colorSemanticPremiumMain = '#b19500'
export const colorSemanticPrimaryContrastText = '#ffffff'
export const colorSemanticPrimaryDarkHue = '#2b1576'
export const colorSemanticPrimaryDarkerHue = '#1e1641'
export const colorSemanticPrimaryLightHue = '#7b6ac1'
export const colorSemanticPrimaryLighterHue = '#e6deff'
export const colorSemanticPrimaryLightestHue = '#f4eeff'
export const colorSemanticPrimaryMain = '#4a398c'
export const colorSemanticSecondaryContrastText = '#ffffff'
export const colorSemanticSecondaryDarkHue = '#003734'
export const colorSemanticSecondaryDarkerHue = '#00201e'
export const colorSemanticSecondaryLightHue = '#25beb5'
export const colorSemanticSecondaryLighterHue = '#a0ede8'
export const colorSemanticSecondaryLightestHue = '#ccfbf8'
export const colorSemanticSecondaryMain = '#00504b'
export const colorSemanticSuccessContrastText = '#ffffff'
export const colorSemanticSuccessDarkHue = '#045300'
export const colorSemanticSuccessDarkerHue = '#012200'
export const colorSemanticSuccessLightHue = '#aaf39a'
export const colorSemanticSuccessLighterHue = '#ccfbf8'
export const colorSemanticSuccessLightestHue = '#f7ffee'
export const colorSemanticSuccessMain = '#086e00'
export const colorSemanticWarningContrastText = '#000000'
export const colorSemanticWarningDarkHue = '#ab5301'
export const colorSemanticWarningLightHue = '#ffb55c'
export const colorSemanticWarningLightestHue = '#fff2e2'
export const colorSemanticWarningMain = '#ff8b00'
export const colorSemanticYellowBright = '#ffec7d'
export const colorSemanticYellowLightestHue = '#fffae3'
