import { Typography, Button, Box, useTheme } from '@persuit/ui-components'
import { RfpTemplate, RfpTemplateFormRoutes } from '../types'
import { DeepPick } from '@persuit/common-utils'

type AlreadyPublishedProps = {
	rfpTemplate: DeepPick<RfpTemplate, '_id' | 'templateTitle'>
	routes: RfpTemplateFormRoutes
}

export const AlreadyPublished = ({ rfpTemplate, routes }: AlreadyPublishedProps) => {
	const theme = useTheme()
	const templateId = rfpTemplate?._id ?? ''
	const titleDisplay = rfpTemplate?.templateTitle ?? 'Untitled template'
	const backUri = routes.alreadyPublished.uriFn(templateId)
	const backLabel = routes.alreadyPublished.label

	return (
		<Box
			width="100%"
			height="50%"
			display="grid"
			gridTemplateRows="100%"
			gridTemplateColumns="100%"
		>
			<Box
				gridRow="1"
				gridColumn="1"
				bgcolor={theme.palette.white}
				width="600px"
				display="flex"
				justifySelf="center"
				alignSelf="center"
				flexDirection="column"
				padding={2}
				borderRadius="5px"
				boxShadow="5px 5px 10px 0px rgba(0, 0, 0, 0.3)"
				gap="12px"
			>
				<Typography variant="h3">This template has been published</Typography>
				<Typography>
					{titleDisplay} has already been published. To edit this template, visit the template page
					and use the edit button.
				</Typography>
				<Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
					<Button variant="contained" color="primary" href={backUri}>
						{backLabel}
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
