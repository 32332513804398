import { useId } from 'react'
import { Box, ExpandMoreIcon, Typography, BoxProps } from '@persuit/ui-components'

export type SectionHeaderRowProps = BoxProps & {
	id: string
	title: string
	collapsedText?: string
	expanded: boolean
	onToggle: () => void
}

export const SectionHeaderRow = ({
	expanded,
	onToggle,
	title,
	collapsedText,
	id,
	...rest
}: SectionHeaderRowProps) => {
	const descriptionId = useId()
	return (
		<Box component="tr">
			{/* Span full width of table, 999 is the maximum value */}
			<Box component="td" colSpan={999} textAlign="left" id={id}>
				<Box
					sx={{
						...rest.sx,
						display: 'flex',
						position: 'relative',
						backgroundColor: 'background.appMain',
						mt: 2,
						p: 1,
						cursor: 'pointer',
					}}
					role="button"
					onClick={() => {
						onToggle()
					}}
					onKeyDown={(e) => {
						if (e.code === 'Enter' || e.code === 'Space') {
							onToggle()
						}
					}}
					tabIndex={0}
					aria-expanded={expanded ? 'true' : 'false'}
					aria-label={`${expanded ? 'Collapse' : 'Expand'} ${title}`}
					aria-describedby={collapsedText && !expanded ? descriptionId : undefined}
				>
					<Box
						{...rest}
						sx={{
							position: 'sticky',
							left: '10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ExpandMoreIcon
							sx={{
								mr: 2,
								transform: `rotate(${expanded ? 0 : -90}deg)`,
								transition: 'transform 200ms',
							}}
						/>

						<Typography variant="h2XSmall" sx={{ fontSize: 20, minWidth: '200px', ml: 1 }}>
							{title}
						</Typography>

						{collapsedText && !expanded ? (
							<Typography variant="body2" fontStyle="italic" id={descriptionId}>
								{collapsedText}
							</Typography>
						) : null}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
