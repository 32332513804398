// @ts-strict-ignore
import { SortObject, State } from './store'
import { uniq, difference, has } from 'lodash/fp'
import { compute } from 'compute-scroll-into-view'

import {
	hiddenPinnedProposalIds,
	displayedPinnedProposalIds,
	displayedProposalIds,
} from './selectors'

import { ComparisonTableResponse } from '../store'
import { requestValidForComparisonValue } from '@persuit/ui-shared-components'

type Set = (partial: State | Partial<State> | ((state: State) => State | Partial<State>)) => void

export const createActions = (_set: Set, get: () => State) => {
	// set "middleware" for common logic
	const set: Set = (updater: (state: State) => Partial<State>) => {
		return _set((state) => {
			const newState = { ...state, ...(typeof updater === 'function' ? updater(state) : updater) }
			const pinnedProposals = displayedPinnedProposalIds(newState)

			const additionalState = {
				...newState,
				showUnpinned:
					!newState.showUnpinned && pinnedProposals.length === 0 ? true : newState.showUnpinned,
			}

			return {
				...additionalState,
			}
		})
	}

	const setShowUnpinned = (showUnpinned: boolean) => set({ showUnpinned })

	const setPinnedProposals = (updater: React.SetStateAction<string[]>) =>
		set((state) => {
			const { pinnedProposals: oldPinnedProposals, showUnpinned } = state
			const newPinnedProposals =
				typeof updater === 'function' ? updater(oldPinnedProposals) : updater

			const unpinnedProposals = difference(oldPinnedProposals, newPinnedProposals)

			if (
				!showUnpinned &&
				difference(displayedProposalIds(state), unpinnedProposals).length === 0
			) {
				setShowUnpinned(true)
			}

			const [pinnedItem] = difference(newPinnedProposals, oldPinnedProposals)

			if (pinnedItem) {
				const now = Date.now()
				let scrolled = false
				/**
				 * Scroll to the pinned item as soon as possible.
				 * If the item isn't moved within 1 second stop trying.
				 * Using a setTimeout will make the user wait an arbitrary amount of time and feel slow. Setting the timeout too short risks missing the scroll alltogether.
				 */
				const attemptScroll = () => {
					if (Date.now() - now < 1000 && !scrolled) {
						requestAnimationFrame(() => {
							const column = document.getElementById(`${pinnedItem}-column-actions`)
							if (column?.classList.contains('pinned')) {
								// Only scroll left, don't scroll vertically so the user can keep their position in the table
								for (const { el, left } of compute(column, { block: 'end', inline: 'end' })) {
									el?.scroll?.({ left, behavior: 'smooth' })
								}
								scrolled = true
							} else {
								attemptScroll()
							}
						})
					}
				}

				requestAnimationFrame(attemptScroll)
			}

			return { pinnedProposals: newPinnedProposals }
		})

	return {
		negotiateResponse: (response: ComparisonTableResponse | null) => {
			if (!response) {
				return set({ negotiatingResponse: null })
			}

			const request = get().request

			const { _id, org, totalPriceModel, totalPriceValue, isPendingPrice, hasRevisionRequest } =
				response
			const clientOrgId = request?.org?._id ?? ''
			const revisionCommentOrgId = response?.draftRevisionComment?.org?._id ?? ''
			const isRevisionCommentFromCurrentOrg =
				has('draftRevisionComment._id', response) && clientOrgId === revisionCommentOrgId

			const hasRfprExists = hasRevisionRequest || isRevisionCommentFromCurrentOrg

			const negotiatingResponse = {
				_id,
				org: { name: org?.name },
				totalPriceModel,
				totalPriceValue,
				isPendingPrice: !!isPendingPrice,
				hasRevisionRequest: !!hasRevisionRequest,
				// hasRfprExists will resume the existing RFPR draft if true
				hasRfprExists,
			}

			set({ negotiatingResponse })
		},

		setTruncateCellContents: (truncateCellContents) => set(() => ({ truncateCellContents })),

		setSort: (sort: SortObject) => set(() => ({ sort })),

		getInitialSort: (): SortObject => {
			const request = get().request

			const inititalSort: SortObject =
				!request?.detail.totalPriceRequired &&
				!requestValidForComparisonValue(request.detail.deliverablesV2)
					? { type: 'firmName', order: 'asc' }
					: request?.detail.totalPriceRequired
					? { type: 'totalPrice', order: 'asc' }
					: { type: 'comparisonValue', order: 'asc' }

			return inititalSort
		},

		toggleFullScreen: () => set(({ fullscreen }) => ({ fullscreen: !fullscreen })),

		toggleShowEliminated: () => set(({ showEliminated }) => ({ showEliminated: !showEliminated })),

		toggleShowRevised: () => set(({ showRevised }) => ({ showRevised: !showRevised })),

		toggleShowWithdrawn: () => set(({ showWithdrawn }) => ({ showWithdrawn: !showWithdrawn })),

		clearShowEliminated: () => set(() => ({ showEliminated: false })),
		setShowEliminated: () => set(() => ({ showEliminated: true })),

		clearShowRevised: () => set(() => ({ showRevised: false })),

		clearShowWithdrawn: () => set(() => ({ showWithdrawn: false })),

		toggleShowBusinessIntel: () =>
			set(({ showBusinessIntel }) => ({ showBusinessIntel: !showBusinessIntel })),

		clearPinnedProposals: () => setPinnedProposals(hiddenPinnedProposalIds(get())),

		togglePinProposal: (proposalId: string) => {
			setPinnedProposals((pinnedProposals) =>
				pinnedProposals.includes(proposalId)
					? pinnedProposals.filter((id) => id !== proposalId)
					: uniq([...pinnedProposals, proposalId]),
			)
		},

		toggleWideProposal: (proposalId: string) => {
			set(({ wideProposals }) => ({
				wideProposals: wideProposals.includes(proposalId)
					? wideProposals.filter((id) => id !== proposalId)
					: uniq([...wideProposals, proposalId]),
			}))
		},

		toggleShowUnpinned: () => set(({ showUnpinned }) => ({ showUnpinned: !showUnpinned })),

		setShowUnpinned: () => setShowUnpinned(true),

		// Intelligence
		toggleIsWideIntelligence: () =>
			set(({ isWideIntelligence }) => ({ isWideIntelligence: !isWideIntelligence })),

		// Section toggles
		// Also you can pass them a value if you don't want the toggle behaviour
		toggleShowScorecardSection: (s?: boolean) =>
			set(({ showScorecardSection }) => ({ showScorecardSection: s ?? !showScorecardSection })),

		toggleShowDiversitySection: (s?: boolean) =>
			set(({ showDiversitySection }) => ({ showDiversitySection: s ?? !showDiversitySection })),

		toggleShowQuestionsSection: (s?: boolean) =>
			set(({ showQuestionsSection }) => ({ showQuestionsSection: s ?? !showQuestionsSection })),

		toggleshowPricingSection: (s?: boolean) =>
			set(({ showPricingSection }) => ({
				showPricingSection: s ?? !showPricingSection,
			})),
	}
}
