// eslint-disable-next-line no-restricted-imports -- Please upgrade
import BlockIcon from '@mui/icons-material/Block'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = ['Unselected firm will not be notified', 'Remaining firm(s) will not be notified.']

function OptionUnselectFirmStart({ onNext }) {
	return (
		<DialogWizardListOption
			dataTestid="unselect-firm-option"
			icon={<BlockIcon />}
			primaryText="Unselect firm's proposal"
			bullets={bullets}
			onClick={onNext}
		/>
	)
}

export default OptionUnselectFirmStart
