type RfpDeliverable = {
	__typename: 'Deliverable'
	_id: string
}

type RfpGroup = {
	__typename: 'PricingGroup'
	deliverables: Array<RfpDeliverable>
}

type RevisionCommentDeliverable = {
	deliverableId: string
}

type GroupRevisionCommentDeliverablesInput = {
	rfpDeliverables: Array<RfpGroup | RfpDeliverable>
	revisionCommentDeliverables: Array<RevisionCommentDeliverable>
}

export function groupRevisionCommentDeliverables({
	rfpDeliverables,
	revisionCommentDeliverables,
}: GroupRevisionCommentDeliverablesInput) {
	let deliverableNumber = 0

	return rfpDeliverables
		.map((item) => {
			function getRfprDeliverable(rfpItem: RfpDeliverable) {
				const rfprDeliverable = revisionCommentDeliverables.find(
					(pricingItem) => pricingItem.deliverableId === rfpItem._id,
				)

				if (!rfprDeliverable) {
					return null
				}

				const deliverable = {
					...rfprDeliverable,
					deliverableNumber,
				}

				return deliverable
			}

			if (item.__typename === 'PricingGroup') {
				const deliverables = item.deliverables
					.map((item) => {
						deliverableNumber++
						return getRfprDeliverable(item)
					})
					.filter(Boolean)

				return deliverables.length === 0
					? null
					: {
							...item,
							deliverables,
					  }
			}

			deliverableNumber++
			return getRfprDeliverable(item)
		})
		.filter(Boolean)
}
