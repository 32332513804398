import { Fragment } from 'react'

import { graphql, useQuery } from '@persuit/ui-graphql'
import { isNotNil } from '@persuit/common-utils'
import {
	Box,
	Popover,
	IconButton,
	StarIcon,
	StarBorderIcon,
	CloseIcon,
	Typography,
	useTheme,
	Spacer,
	Link,
} from '@persuit/ui-components'

import { formatLocationLabel } from '../utils/location'
import { DisabledLabel, NAMWOLFLabel } from './label'
import { PanelPreferredFirmLabel } from './panel-preferred-firm/panel-preferred-firm-label'
import { ApprovedFirmLabel } from './approved-firm/approved-firm-label'

const getUserQuery = graphql(`
	query ChipExpanded_GetUser($userId: ID) {
		getUser(userId: $userId) {
			_id
			email
			title
			phone
			isDisabled
			professionalProfile {
				_id
				url
				profile
			}
			org {
				_id
				isNamwolfMember
			}
			location {
				name
				parentChain
				code
			}
		}
	}
`)

const createBrowserFriendlyUrl = (url: string) => {
	const lowerCasedUrl = url.toLowerCase()
	return lowerCasedUrl.startsWith('http://') || lowerCasedUrl.startsWith('https://')
		? url
		: `https://${lowerCasedUrl}`
}

export type ChipExpandedProps = {
	additionalText?: string[]
	isApprovedFirm: boolean
	isFavourite?: boolean
	isPanelOrPreferredFirm: boolean
	onClick?: () => void
	onDelete?: () => void
	onClose: () => void
	onRequestClose: () => void
	addToFavourites?: () => void
	primaryText: string
	secondaryText?: string
	userId?: string
	hideStar?: boolean
	expanded: boolean
	expandAnchor: null | HTMLElement
}

export const ChipExpanded = ({
	isApprovedFirm,
	isFavourite,
	isPanelOrPreferredFirm,
	hideStar,
	onClose,
	primaryText,
	secondaryText,
	expanded,
	expandAnchor,
	onRequestClose,
	addToFavourites,
	userId,
}: ChipExpandedProps) => {
	const theme = useTheme()
	const {
		loading: loadingUser,
		data: userData,
		error: userError,
	} = useQuery(getUserQuery, {
		variables: { userId: userId || null },
	})

	const professionalProfile = userData?.getUser?.professionalProfile ?? []
	const isNamwolfMember = !!userData?.getUser?.org?.isNamwolfMember

	const additionalText = loadingUser
		? ['Loading...']
		: !userError && userData?.getUser
		? [
				userData.getUser.email ?? null,
				userData.getUser.title ?? null,
				userData.getUser.phone ?? null,
				userData.getUser.location ? formatLocationLabel(userData.getUser.location) : null,
		  ].filter(isNotNil)
		: null

	const isUserDisabled = !!userData?.getUser?.isDisabled

	return (
		<Popover
			anchorEl={expandAnchor}
			open={expanded}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			onClose={onRequestClose}
			disableScrollLock={true}
			sx={{
				zIndex: theme.zIndex.tooltip,
			}}
		>
			<Box minWidth="288px" maxWidth="388px" margin="0px">
				<Box
					display="flex"
					flexWrap="nowrap"
					justifyContent="space-between"
					alignItems="center"
					bgcolor={theme.palette.grey300}
					padding="12px"
				>
					<Box flexGrow={3} flexShrink={3} flexBasis="10em">
						{isUserDisabled ? null : hideStar ? (
							<Box p="12px" />
						) : isFavourite && addToFavourites ? (
							<IconButton onClick={addToFavourites} size="large" aria-label="Remove from favorites">
								<StarIcon fontSize="large" style={{ color: theme.palette.amber600 }} />
							</IconButton>
						) : (
							<IconButton onClick={addToFavourites} size="large" aria-label="Add to favorites">
								<StarBorderIcon fontSize="large" />
							</IconButton>
						)}
					</Box>

					<Box flexGrow={1} flexShrink={1} flexBasis="20em">
						<Typography variant="h5">{primaryText}</Typography>
						{secondaryText && <Typography>{secondaryText}</Typography>}

						<Box display="flex">
							{isApprovedFirm ? <ApprovedFirmLabel mr={1} /> : null}
							{isPanelOrPreferredFirm ? <PanelPreferredFirmLabel mr={1} /> : null}
							{isNamwolfMember ? <NAMWOLFLabel /> : null}
						</Box>
					</Box>

					<Box flexGrow={3} flexShrink={3} flexBasis="10em">
						<IconButton onClick={onClose} size="large" aria-label="close">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				{additionalText && !!additionalText.length ? (
					<Box
						display="flex"
						flexWrap="nowrap"
						justifyContent="space-between"
						alignItems="centers"
						padding="12px"
					>
						<Box flexGrow={2} flexShrink={2} flexBasis="12em" />

						<Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} flexBasis="30em">
							{additionalText.map((text, i) => (
								<Typography key={`expanded-chip-text-${i}`}>{text}</Typography>
							))}
							{professionalProfile.map((data, i) => (
								<Link
									key={i}
									href={data.url ? createBrowserFriendlyUrl(data.url) : undefined}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.profile}
								</Link>
							))}
							{isUserDisabled ? (
								<Fragment>
									<Spacer />
									<DisabledLabel />
								</Fragment>
							) : null}
						</Box>
					</Box>
				) : null}
			</Box>
		</Popover>
	)
}
