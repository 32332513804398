import {
	CheckIcon,
	ListItemText,
	MenuItem,
	MenuItemProps,
	Typography,
} from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import {
	GridColumnMenuContainer,
	GridColumnMenuProps,
	GridSortDirection,
	gridSortModelSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import { getSortForField } from '../utils'

const GridCustomSortMenuItem = ({
	fieldName,
	sortDirection,
	'aria-label': ariaLabel = '',
	children,
	...rest
}: MenuItemProps & { fieldName: string; sortDirection: GridSortDirection }) => {
	const apiRef = useGridApiContext()
	const sortModel = useGridSelector(apiRef, gridSortModelSelector)

	const sortState = getSortForField(sortModel, fieldName)
	const selected = sortDirection === sortState ? true : false

	const toggleSortState = () => {
		apiRef.current.setSortModel([{ field: fieldName, sort: sortDirection }])
	}

	return (
		<MenuItem
			{...rest}
			selected={selected}
			aria-label={`${ariaLabel}, ${selected ? 'selected' : 'unselected'}`}
			onClick={() => toggleSortState()}
		>
			<ListItemText>{children}</ListItemText>
			{selected ? <CheckIcon /> : null}
		</MenuItem>
	)
}

const NumericMenu = ({ colDef }: GridColumnMenuProps) => {
	const field = colDef.field

	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<GridCustomSortMenuItem
				fieldName={field}
				sortDirection="asc"
				aria-label="Sort from lowest to highest"
			>
				Lowest to Highest
			</GridCustomSortMenuItem>
			<GridCustomSortMenuItem
				fieldName={field}
				sortDirection="desc"
				aria-label="Sort from highest to lowest"
			>
				Highest to Lowest
			</GridCustomSortMenuItem>
		</>
	)
}

const StringMenu = ({ colDef }: GridColumnMenuProps) => {
	const field = colDef.field

	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<GridCustomSortMenuItem
				fieldName={field}
				sortDirection="asc"
				aria-label="Sort in ascending alphabetical order (A to Z)"
			>
				A - Z
			</GridCustomSortMenuItem>
			<GridCustomSortMenuItem
				fieldName={field}
				sortDirection="desc"
				aria-label="Sort in descending alphabetical order (Z to A)"
			>
				Z - A
			</GridCustomSortMenuItem>
		</>
	)
}

export const GridCustomColumnMenu = (props: GridColumnMenuProps) => {
	const { colDef } = props
	const type = colDef.type
	return (
		<GridColumnMenuContainer
			{...props}
			aria-labelledby={undefined}
			aria-label={`${colDef.headerName} column sort options`}
		>
			{type === 'number' || type === 'rank' || type === 'seniority' ? (
				<NumericMenu {...props} />
			) : (
				<StringMenu {...props} />
			)}
		</GridColumnMenuContainer>
	)
}
