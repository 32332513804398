import { difference, intersection } from 'lodash/fp'
import { createSelector } from 'reselect'
import { State, ComparisonTableRequest, ComparisonTableResponse, SortObject } from './store'
import { shallowEqual } from '@persuit/ui-state'
import memoize from 'memoize-one'
import { hasActiveProposals, allFirmsEliminated } from '../utils'

import { getResponsesClient, getResponsesFirm } from '../get-responses'
import pricingModels from '@persuit/common/data/pricing-models'

export const isPinned = (state: State, proposalId: string) =>
	state.pinnedProposals.includes(proposalId)

export const isWide = (state: State, proposalId: string) => state.wideProposals.includes(proposalId)

export const displayedResponses = (state: State) => {
	const {
		showWithdrawn,
		showRevised,
		showEliminated,
		pinnedProposals,
		showUnpinned,
		request,
		responses,
		isFirm,
		showExampleResponse,
		sort,
		isUAMToggledOn,
	} = state

	return calculateDisplayedResponses({
		responses,
		showWithdrawnProposals: showWithdrawn,
		showRevisedProposals: showRevised,
		showEliminatedProposals: showEliminated,
		pinnedProposals,
		showUnpinned,
		isFirm,
		request,
		showExampleResponse,
		sort,
		isUAMToggledOn,
	})
}

type CalculateDisplayedResponsesInput = {
	isFirm: boolean
	request: ComparisonTableRequest
	responses: ComparisonTableResponse[]
	showExampleResponse: boolean
	showWithdrawnProposals: boolean
	showEliminatedProposals: boolean
	showRevisedProposals: boolean
	pinnedProposals: string[]
	showUnpinned: boolean
	sort: SortObject
	isUAMToggledOn: boolean
}

const calculateDisplayedResponses = memoize(
	(input: CalculateDisplayedResponsesInput): ComparisonTableResponse[] => {
		return input.isFirm ? getResponsesFirm(input) : getResponsesClient(input)
	},
	([newInput], [lastInput]) => shallowEqual(newInput, lastInput),
)

export const displayedProposalIds = createSelector(displayedResponses, (displayedResponses) =>
	displayedResponses.map((r) => r._id),
)

export const hiddenPinnedProposalIds = (state: State) =>
	difference(state.pinnedProposals, displayedPinnedProposalIds(state))

const pinnedProposals = (state: State) => state.pinnedProposals

export const displayedPinnedProposalIds = createSelector(
	pinnedProposals,
	displayedProposalIds,
	(pinnedProposalIds, displayedProposalIds) =>
		intersection(pinnedProposalIds, displayedProposalIds),
)

const responses = (state: State) => state.responses
const request = (state: State) => state.request
const showBusinessIntel = (state: State) => state.showBusinessIntel
const proposalAnalyzerEnabled = (state: State) => state.proposalAnalyzerEnabled
const shouldShowAverageRate = (state: State) =>
	!state.responses.every((res) => !res.averageRate?.rate)
const isRatesOnlyRequest = (state: State) =>
	state.request?.detail?.deliverablesV2?.every((deliverable) => {
		if (deliverable.__typename === 'Deliverable') {
			return (
				deliverable?.pricingPreferences === pricingModels.HOURLYRATE ||
				deliverable?.pricingPreferences === pricingModels.RATECARD
			)
		} else {
			return true
		}
	}) ?? false

const showIntelligenceColumn = createSelector(
	request,
	responses,
	showBusinessIntel,
	proposalAnalyzerEnabled,
	(request, responses, showIntel, analyzerEnabled) =>
		hasActiveProposals(responses) && !allFirmsEliminated(request) && showIntel && analyzerEnabled,
)

export const selectors = {
	isFirm: (state: State) => state.isFirm,
	isClient: (state: State) => !state.isFirm,
	isPinned,
	isWide,
	displayedResponses,
	displayedPinnedProposalIds,
	showIntelligenceColumn,
	shouldShowAverageRate,
	isRatesOnlyRequest,
}
