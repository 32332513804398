import { Fragment } from 'react'
import { Spacer, Typography } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const helpTextParagraphs = [
	{
		text: 'Choose a pricing structure for this sub-item. Setting a preference will make it easier to compare proposals.',
	},
	{
		title: 'Alternative Fee Arrangements (AFA)',
		text: '(Recommended) : Capped Fee, Fixed Fee, Success Fee.',
	},
	{
		title: 'Budgeting Estimate:',
		text: "If you're only looking for an estimate which is subject to change, select this option.",
	},
	{
		title: 'Other (see instructions):',
		text: "If you'd like the firms to follow another type of pricing stucture, list it out in the Instructions and Assumptions box. ",
	},
	{
		title: 'No charge:',
		text: 'If the fee is not applicable for this sub-item or if you’d like to not get charged, select this option.',
	},
]

const helpTextParagraphs1 = [
	{
		text: 'Choose a pricing structure for this sub-item. Setting a preference will make it easier to compare proposals.',
	},
	{
		title: 'Fee Arrangements',
		text: '(Recommended) : Capped Fee, Fixed Fee, Success Fee.',
	},
	{
		title: 'Budgeting Estimate:',
		text: "If you're only looking for an estimate which is subject to change, select this option.",
	},
	{
		title: 'Other (see instructions):',
		text: "If you'd like the firms to follow another type of pricing stucture, list it out in the Instructions and Assumptions box. ",
	},
	{
		title: 'Discount:',
		text: 'Request or allow the firm to offer a discount in the form of a percentage.',
	},
	{
		title: 'No charge:',
		text: 'If the fee is not applicable for this sub-item or if you’d like to not get charged, select this option.',
	},
]

export const PricingSubItemContentPopover = ({
	isOriginalPricingSubItem,
}: {
	isOriginalPricingSubItem: boolean
}) => {
	const { toggles } = useFeatureToggles()

	const isToggleOn = toggles['dev-3327.contingency-percentage']

	const helpText = isToggleOn ? helpTextParagraphs1 : helpTextParagraphs

	return (
		<Fragment>
			{isOriginalPricingSubItem && (
				<Fragment>
					<Spacer />
					<Typography variant="body2">
						Once set, the pricing structure cannot be altered{' '}
					</Typography>
					<Spacer />
					<hr style={{ width: '100%' }} />
					<Spacer />
				</Fragment>
			)}

			{/*
			Iterate over each help text paragraph.
			Emit the title in bold (if there is one)

			Emit the help text always
			*/}
			{helpText.map(({ title, text }, index) => (
				<Fragment key={index}>
					<Typography variant="body2" component="p">
						{!!title && (
							<Typography
								variant="body2"
								component="span"
								style={{
									fontWeight: 'bold',
								}}
							>
								{title}{' '}
							</Typography>
						)}
						{text}
					</Typography>

					<Spacer space={2} />
				</Fragment>
			))}
		</Fragment>
	)
}
