import { ClientTotalPriceModel } from '../../../../../types'
import isEmpty from 'lodash/fp/isEmpty'
import { Typography } from '@persuit/ui-components'
import { ClientPricingModelStrings } from '../../constants'

type TotalPriceProps = {
	totalPriceRequired: boolean
	totalPricingPreference: ClientTotalPriceModel
}

const getClientPricingModelString = (totalPricingPreference: ClientTotalPriceModel) => {
	if (!(totalPricingPreference in ClientPricingModelStrings)) return ''
	return ClientPricingModelStrings[totalPricingPreference]
}

export const TotalPrice = ({ totalPriceRequired, totalPricingPreference }: TotalPriceProps) => {
	if (!totalPriceRequired || isEmpty(totalPricingPreference)) return null

	const pricingModelString = getClientPricingModelString(totalPricingPreference)

	if (isEmpty(pricingModelString) || totalPricingPreference === 'none')
		return <Typography variant="h3XSmall">Total price required</Typography>

	return (
		<Typography data-testid="rfp-total-price-model" variant="h3XSmall">
			{pricingModelString} for total
		</Typography>
	)
}
