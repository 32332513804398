// @ts-strict-ignore
import { useState, Fragment, Dispatch, SetStateAction } from 'react'
import { Rfp } from '@persuit/ui-graphql/generated'
import head from 'lodash/fp/head'
import { getOrEmptyString, getOrEmptyArray } from '../../../utils/lenses'
import { FirmAccordion } from './accordion-firm'
import { FormState } from '../'

const getHandleNext = (firmsAndProposals, handleChange, index) => {
	const nextOrgIndex = index + 1
	const nextOrg = getOrEmptyString('item.name', firmsAndProposals[nextOrgIndex])
	// dont return handle next function for the last accordion
	return firmsAndProposals.length !== nextOrgIndex ? handleChange(nextOrg, true) : null
}

export const setHandleValueChange =
	(setState, oldFormState) =>
	({ field, value, proposalId }) => {
		const newProposalState = {
			...oldFormState[proposalId],
			[field]: value,
		}

		const newFormState = {
			...oldFormState,
			[proposalId]: newProposalState,
		}

		return setState(newFormState)
	}

const AccordionItems = ({
	firmsAndProposals,
	handleValueChange,
	expanded,
	handleExpandChange,
	formState,
	rfp,
}) =>
	firmsAndProposals.map((org, index) => {
		const handleNext = getHandleNext(firmsAndProposals, handleExpandChange, index)
		const orgName = getOrEmptyString('item.name', org)
		const orgId = getOrEmptyString('item._id', org)
		const proposals = getOrEmptyArray('proposals', org)
		const accordionStatus = getOrEmptyString('accordionStatus', org)
		const isExpanded = orgName === expanded

		return (
			<FirmAccordion
				id={`firm-accordion-${index}`}
				key={orgId}
				sx={{ mb: 1 }}
				handleChange={handleExpandChange}
				handleNext={
					handleNext &&
					(() => {
						document.getElementById(`firm-accordion-${index + 1}-control`)?.focus()
						handleNext()
					})
				}
				isExpanded={isExpanded}
				accordionStatus={accordionStatus}
				proposals={proposals}
				orgName={orgName}
				handleValueChange={handleValueChange}
				formState={formState}
				rfp={rfp}
			/>
		)
	})

type ProposalFeedbackFormProps = {
	rfp: Rfp
	firmsAndProposals: any[]
	formState: FormState
	setFormState: Dispatch<SetStateAction<FormState>>
}

export const ProposalFeedbackForm = ({
	rfp,
	firmsAndProposals,
	setFormState,
	formState,
}: ProposalFeedbackFormProps) => {
	const [expanded, setExpanded] = useState(getOrEmptyString('item.name', head(firmsAndProposals)))
	const handleValueChange = setHandleValueChange(setFormState, formState)

	const handleExpandChange =
		(panel, isNext = false) =>
		(event, newExpanded) => {
			setExpanded(newExpanded || isNext ? panel : false)
		}

	return (
		<Fragment>
			<AccordionItems
				firmsAndProposals={firmsAndProposals}
				handleValueChange={handleValueChange}
				expanded={expanded}
				handleExpandChange={handleExpandChange}
				formState={formState}
				rfp={rfp}
			/>
		</Fragment>
	)
}
