import { Fragment } from 'react'
import getOr from 'lodash/fp/getOr'

import Alert from '../alert.jsx'
import Text from '../text.jsx'
import Spacer from '../layout/spacer.jsx'

export default function DialogWizardConfirmationError({ error }) {
	const errorMessage = getOr(null, 'message', error)

	if (!error) {
		return null
	}

	return (
		<Fragment>
			<Alert type="fail">
				<Text type="body2">{errorMessage}</Text>
			</Alert>
			<Spacer />
		</Fragment>
	)
}
