import { useEffect } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import isFunction from 'lodash/fp/isFunction'
import { FileListError } from './file-list-error'
import { FileList as UIFileList } from '@persuit/ui-components'

export const FileList = ({
	onChange,
	files,
	updatingFilesForDocument,
	errorFetchingFilesForDocument,
	removeFile,
}) => {
	useEffect(() => {
		if (isFunction(onChange)) onChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files])

	if (errorFetchingFilesForDocument) {
		return <FileListError errorFetchingFilesForDocument={errorFetchingFilesForDocument} />
	}

	if (isEmpty(files)) {
		return null
	}

	if (!Array.isArray(files)) {
		return null
	}

	return (
		<UIFileList
			data-testid="file-list-component"
			files={files}
			onRemoveFile={(id) => removeFile?.(id)}
			disabled={updatingFilesForDocument}
		/>
	)
}
