import { useCallback, useState, useEffect, useRef } from 'react'

type State<T> = {
	present: T
	past: T[]
	future: T[]
}

export function useHistoryState<T>(initialPresent: T, onStateChange?: (present: T) => void) {
	const isFirstEffect = useRef(true)
	const onStateChangeRef = useRef(onStateChange)
	onStateChangeRef.current = onStateChange

	const [state, setState] = useState<State<T>>({ present: initialPresent, past: [], future: [] })

	useEffect(() => {
		if (isFirstEffect.current) {
			isFirstEffect.current = false
			return
		}
		if (onStateChangeRef.current) {
			onStateChangeRef.current(state.present)
		}
	}, [state])

	const canUndo = state.past.length !== 0
	const canRedo = state.future.length !== 0

	const undo = useCallback(() => {
		if (canUndo) {
			setState(({ past, present, future }) => ({
				past: past.slice(0, past.length - 1),
				present: past[past.length - 1],
				future: [present, ...future],
			}))
		}
	}, [canUndo])

	const redo = useCallback(() => {
		if (canRedo) {
			setState(({ past, present, future }) => ({
				past: [...past, present],
				present: future[0],
				future: future.slice(1),
			}))
		}
	}, [canRedo])

	const set = useCallback((newPresent: T) => {
		setState((state) => {
			const { present, past } = state
			if (newPresent === present) {
				return state
			}

			return {
				past: [...past, present],
				present: newPresent,
				future: [],
			}
		})
	}, [])

	const clear = useCallback(() => {
		setState(({ present }) => ({ present, past: [], future: [] }))
	}, [])

	return { state: state.present, set, undo, redo, clear, canUndo, canRedo }
}
