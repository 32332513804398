import { useState, useId, useMemo } from 'react'

import { SxProps } from '@mui/material'

import { noop, isNotNil } from '@persuit/common-utils'
import { refocusBeforeRemoval } from '@persuit/ui-utils'

import { Chip } from '../../chip'
import { IconButton } from '../../icon-button'
import { Box } from '../../box'
import { Autocomplete } from '..'
import { TextField, TextFieldProps } from '../../text-field'
import { InputAdornment } from '../../input-adornment'

import { ClearIcon } from '../../../icons'

import { Provider, useStore, useActions } from './store'
import { RawValue, NestedOptions } from './nested-options'
import { ListBox } from './list-box'

export const WrappedNestedAutocomplete = <T extends RawValue>(
	props: NestedAutocompleteProps<T>,
) => {
	const { selections, onChange, disableOptionValidation } = props
	const autocompleteId = useId()

	const nestedOptions = useMemo(() => new NestedOptions(props.options), [props.options])
	const hasInvalidSelections = useMemo(() => {
		const invalidSelections = selections.filter(
			(selectedId) => !nestedOptions.hasOption(selectedId),
		)

		if (invalidSelections.length > 0) {
			if (disableOptionValidation) {
				onChange?.(selections.filter((selectedId) => nestedOptions.hasOption(selectedId)))
			}
		}

		return invalidSelections.length > 0
	}, [nestedOptions, selections, disableOptionValidation, onChange])

	if (hasInvalidSelections) {
		return null
	}

	return (
		<Provider
			initialState={{
				virtualize: props.virtualize ?? false,
				headersSelectable: !!props.headersSelectable,
				autocompleteId,
				onChange: props.onChange ?? noop,
				options: props.options,
				selections: props.selections,
			}}
			mergeState={({ currentState, initialState }) => {
				const options = new NestedOptions(initialState.options)

				return {
					...currentState,
					...initialState,
					selections: initialState.selections.filter((selectedId) => options.hasOption(selectedId)),
					options,
					filteredOptions: options.search(currentState.searchTerm),
				}
			}}
		>
			<NestedAutocomplete {...props} />
		</Provider>
	)
}

export type NestedAutocompleteProps<T extends RawValue = RawValue> = {
	disableOptionValidation?: boolean
	disabled?: boolean
	virtualize?: boolean
	showChips?: boolean
	TextFieldProps?: Partial<TextFieldProps>
	variant?: TextFieldProps['variant']
	sx?: SxProps
	size?: 'small' | 'medium'
	headersSelectable?: boolean
	open?: boolean
	label: string
	placeholder?: string
	onChange?: (selections: string[]) => void
	selections: string[]
	options: T[]
	getChipLabel?: (option: T) => string
}

export const NestedAutocomplete = <T extends RawValue>({
	disabled,
	placeholder,
	showChips = true,
	TextFieldProps,
	variant = 'outlined',
	sx,
	size,
	label,
	onChange = noop,
	open: openProp,
	getChipLabel = (option) => option.label,
}: NestedAutocompleteProps<T>) => {
	const [openState, setOpenState] = useState(false)
	const open = openProp ?? openState
	const [chipFocused, setChipFocused] = useState(false)

	const inputId = useId()

	const {
		selections,
		options,
		filteredOptions,
		visibleIds,
		highlightedId,
		searchTerm,
		autocompleteId,
		headersSelectable,
	} = useStore((state, s) => ({
		headersSelectable: state.headersSelectable,
		selections: state.selections,
		options: state.options,
		filteredOptions: s.filteredOptions(),
		visibleIds: s.visibleIds(),
		highlightedId: state.highlightedId,
		searchTerm: state.searchTerm,
		autocompleteId: state.autocompleteId,
	}))

	const {
		isField,
		isValue,
		isValueWithSubValues,
		isSubValue,
		select,
		highlightNext,
		highlightPrevious,
		setHighlighted,
		setSearchTerm,
		toggleCollapse,
		expandCollapseAll,
	} = useActions()

	const selectionsForAccessibility = selections
		.map(
			(id) =>
				options
					.array()
					.flatMap((field) => [field, ...field.values.flatMap((value) => [value, ...value.values])])
					.find((option) => option._id === id) ?? null,
		)
		.filter(isNotNil)
		.map((option) => getChipLabel(option as T))
		.filter(isNotNil)

	const selectionCount = selectionsForAccessibility.length
	const textFieldAriaLabel =
		selectionsForAccessibility.length === 0
			? `${label}, no selections.`
			: `${label}, ${selectionCount} ${
					selectionCount === 1 ? 'item' : 'items'
			  } selected, ${selectionsForAccessibility.join(', ')}.`

	return (
		<>
			<Autocomplete
				id={autocompleteId}
				open={open}
				disabled={disabled}
				onOpen={() => {
					setOpenState(true)
					// Give a chance for the listbox to open before setting the highlighted state, MUI seems to try and manipulate aria-activedescendant when the list box opens messing with highlighting
					setTimeout(() => setHighlighted(visibleIds[0]), 100)
				}}
				onClose={() => {
					setOpenState(false)
					setHighlighted('')
				}}
				size={size}
				sx={sx}
				disableCloseOnSelect={true}
				options={filteredOptions.array()}
				onInputChange={(_, search) => setSearchTerm(search)}
				inputValue={searchTerm}
				// combine the names of all children for searching
				getOptionLabel={(option) =>
					[
						option.label,
						...option.values.flatMap((value) => [
							value.label,
							...(value.values ?? []).flatMap((subValue) => subValue.label),
						]),
					].join(' ')
				}
				renderInput={(inputProps) => (
					<TextField
						{...inputProps}
						variant={variant}
						label={label}
						placeholder={placeholder}
						onBlur={(e) => {
							inputProps.inputProps.onBlur?.(e as any)
						}}
						onKeyDown={(e) => {
							const hasVisibleChildren = (id: string) =>
								filteredOptions.valueById(id).values.length > 0

							if (e.key === 'Enter') {
								if (e.ctrlKey && e.shiftKey) {
									expandCollapseAll()
								} else if (
									(isField(highlightedId) &&
										(!headersSelectable ||
											!hasVisibleChildren(highlightedId) ||
											e.metaKey ||
											e.ctrlKey ||
											e.shiftKey)) ||
									((e.metaKey || e.ctrlKey || e.shiftKey) && isValueWithSubValues(highlightedId))
								) {
									toggleCollapse(highlightedId)
								} else if (
									(isField(highlightedId) && isField(highlightedId)) ||
									isValue(highlightedId) ||
									isSubValue(highlightedId)
								) {
									select(highlightedId)
								}
							} else if (e.key === 'ArrowDown' && open) {
								return highlightNext()
							} else if (e.key === 'ArrowUp' && open) {
								return highlightPrevious()
							} else if (e.key === 'Backspace' && searchTerm === '' && !chipFocused) {
								const lastSelection = selections[selections.length - 1]
								if (lastSelection) {
									select(lastSelection)
								}
							}
						}}
						{...TextFieldProps}
						inputProps={{
							...inputProps.inputProps,
							'data-input-id': inputId,
							'aria-activedescendant': highlightedId ?? undefined,
							'aria-owns': `${autocompleteId}-listbox`,
							'aria-label': textFieldAriaLabel,
							...TextFieldProps?.inputProps,
						}}
						InputProps={{
							...inputProps.InputProps,
							endAdornment: (
								<InputAdornment position="end" style={{ position: 'absolute', right: '9px' }}>
									{selections.length > 0 ? (
										<IconButton
											aria-label="clear selection"
											size="small"
											onClick={() => onChange([])}
										>
											<ClearIcon style={{ fontSize: '1.25rem' }} />
										</IconButton>
									) : null}
									{(inputProps.InputProps.endAdornment as any)?.props?.children?.[1] ?? null}
								</InputAdornment>
							),
							startAdornment: showChips && (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selections.map((id, index) => (
										<Chip
											key={id}
											id={`${inputId}-chip-${index}`}
											label={getChipLabel(options.valueById(id) as T)}
											onFocus={() => setChipFocused(true)}
											onBlur={() => setChipFocused(false)}
											onDelete={() => {
												select(id)

												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.querySelector(
														`[data-input-id='${inputId}']`,
													) as HTMLElement,
													getItemByIndex: (index) =>
														document.getElementById(`${inputId}-chip-${index}`),
												})
											}}
										/>
									))}
								</Box>
							),
							...TextFieldProps?.InputProps,
						}}
					/>
				)}
				ListboxComponent={ListBox as any}
			/>
		</>
	)
}
