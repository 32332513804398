import { TextAccordion, Box } from '@persuit/ui-components'
import { ResponseToDeliverableHistory } from '@persuit/ui-graphql/generated'
import { firmPhaseModel } from '@persuit/common-logic'
import { RateCardHistoryTable } from './rate-card-history-table'
import { PriceItemHistoryTable } from '../price-history-table'

type PriceHistoryProps = {
	priceHistories: ResponseToDeliverableHistory[]
	currency: string
	deliverableNumber: number
	phaseDuration: string
	showPriceHistory: boolean
	responsePricingModel: string | null | undefined
}

export const PriceHistory = ({
	priceHistories,
	currency,
	deliverableNumber,
	phaseDuration,
	showPriceHistory,
	responsePricingModel,
}: PriceHistoryProps) => {
	if (!showPriceHistory) {
		return null
	}

	return (
		<Box>
			<TextAccordion title="Price History" initialExpanded={false}>
				{responsePricingModel === firmPhaseModel.RATECARD ? (
					<RateCardHistoryTable
						priceHistories={priceHistories}
						currency={currency}
						deliverableNumber={deliverableNumber}
					/>
				) : (
					<PriceItemHistoryTable
						priceHistories={priceHistories}
						currency={currency}
						phaseDuration={phaseDuration}
					/>
				)}
			</TextAccordion>
		</Box>
	)
}
