import { Box, Tooltip, Typography, TypographyProps } from '@persuit/ui-components'

export const BgColorMap = {
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	GREY: '#e0e0e0',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	GREEN: '#87e478',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	PINK: '#f8cbdd',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	YELLOW: '#eae193',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	RED: '#d95b5b',
} as const

type BaseStatusProps = {
	expanded?: boolean
	iconNode: React.ReactNode
	primaryText: React.ReactNode
	primaryTextProps?: TypographyProps
	secondaryText?: React.ReactNode
	tertiaryText?: React.ReactNode
	variant: keyof typeof BgColorMap
}

export const BaseStatus = ({
	expanded = true,
	iconNode,
	primaryText,
	primaryTextProps,
	secondaryText,
	tertiaryText,
	variant = 'GREY',
}: BaseStatusProps) => {
	return (
		<Tooltip title={primaryText} placement="top" describeChild={true} arrow={true}>
			<Box
				className="BaseContainer"
				bgcolor={BgColorMap[variant]}
				display="flex"
				alignItems="center"
				width="fit-content"
				borderRadius="5px"
				marginLeft={!expanded ? '4px' : undefined}
				marginRight={!expanded ? '4px' : undefined}
			>
				<Box display="flex" alignItems="center" padding={expanded ? '10px' : '5px'}>
					{iconNode}
				</Box>

				{expanded && (
					<Box padding="8px 16px 8px 0">
						<Typography
							variant="body2"
							data-testid="base-status-primary"
							{...primaryTextProps}
							sx={{ fontWeight: 500, ...primaryTextProps?.sx }}
							aria-hidden={true} // The Tooltip adds title=primaryText to its child.
						>
							{primaryText}
						</Typography>

						{secondaryText && (
							<Typography display="block" variant="body2" data-testid="base-status-secondary">
								{secondaryText}
							</Typography>
						)}

						{tertiaryText && (
							<Typography display="block" variant="body2" data-testid="base-status-tertiary">
								{tertiaryText}
							</Typography>
						)}
					</Box>
				)}
			</Box>
		</Tooltip>
	)
}
