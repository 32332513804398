import { useFileUploadXHR } from '../file-upload-xhr'
import { useFileMediator } from '../file-mediator'
import { useFileScan } from '../file-gql-adapter/file-gql-scan'

export const FileWrapperDetached = ({ children, files, onChange }) => {
	const { scannedFiles } = useFileScan({ files, onScanResult: onChange })

	const { fileList, addFiles, removeFile } = useFileMediator({
		files: scannedFiles,
		sync: onChange,
	})

	const { uploadFiles, uploadError, isUploading } = useFileUploadXHR({
		handleUploadedFiles: addFiles,
	})

	return children({
		fileList,
		removeFile,
		uploadFiles,
		isUploading,
		uploadError,
	})
}
