import { InputBaseComponentProps } from '@mui/material'
import { TextField, TextFieldProps } from '../text-field'
import { useFormComponent, RHFFormComponentProps } from '../../utilities'
import Cleave from 'cleave.js/react'
import { isNil } from 'lodash/fp'
import { InputAdornment } from '../input-adornment'
import { getCurrencySymbol } from '@persuit/common-utils'
import { Typography } from '../typography'

const MAX_INTEGER_LENGTH = 15

export type FormNumberFieldCleaveProps = RHFFormComponentProps<
	number,
	{
		/** What to set the value to when the user clicks away from an empty input */
		emptyValue?: number
		allowNegative?: boolean
		/** Will replace 0 with the empty value when the user clicks away. Default to be false. */
		replaceZero?: boolean
		decimalScale?: number
		integerScale?: number
		maxValue?: number
		currency?: string | null
	} & TextFieldProps
>

type CleaveInputProps = InputBaseComponentProps

const CleaveInput = (props: CleaveInputProps) => {
	return <Cleave options={{}} {...props} />
}

export const FormNumberFieldCleave = ({
	rules,
	name,
	defaultValue,
	emptyValue,
	replaceZero = false,
	allowNegative,
	decimalScale,
	integerScale,
	inputProps,
	maxValue,
	currency,
	InputProps,
	...props
}: FormNumberFieldCleaveProps) => {
	const {
		field: { onChange, onBlur, value, ref, name: fieldName, required },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})
	const currencyInputPrefix = currency ? (
		<InputAdornment position="start" disableTypography={true}>
			<Typography color={props.disabled ? 'text.disabled' : 'text.primary'}>
				{getCurrencySymbol(currency)}
			</Typography>
		</InputAdornment>
	) : undefined

	return (
		<TextField
			{...props}
			required={props.required || required}
			onChange={(e) => {
				const syntheticEvent = e as unknown as { target: { rawValue: string } }
				const value = parseFloat(syntheticEvent.target.rawValue)
				onChange?.(isNaN(value) ? null : value)
			}}
			onBlur={() => {
				{
					onBlur()
					if (emptyValue !== undefined && value === null) {
						onChange(emptyValue)
						return
					}

					const shouldReplaceZero = replaceZero === true && value === 0
					if (emptyValue !== undefined && shouldReplaceZero) {
						onChange(emptyValue)
						return
					}

					if (emptyValue !== undefined && !isNil(maxValue) && value > maxValue) {
						onChange(emptyValue)
						return
					}
				}
			}}
			InputProps={{
				...InputProps,
				inputComponent: CleaveInput,
				inputProps: {
					options: {
						numeral: true,
						numeralPositiveOnly: allowNegative ? false : true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: decimalScale ? decimalScale : 0,
						numeralIntegerScale: integerScale ? integerScale : MAX_INTEGER_LENGTH,
					},
					...inputProps,
				},
				startAdornment: currencyInputPrefix,
			}}
			InputLabelProps={{
				shrink: currencyInputPrefix
					? true
					: typeof value !== 'undefined' && value !== null
					? true
					: false,
			}}
			value={value === undefined ? undefined : value === null ? '' : `${value}`}
			name={fieldName}
			inputRef={ref}
			error={!!error?.message}
			helperText={error?.message}
			{...props}
		/>
	)
}
