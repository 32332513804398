import { useState } from 'react'
import {
	Box,
	Button,
	InfoOutlinedIcon,
	Typography,
	NumberField,
	NumberFieldProps,
	Slide,
	Zoom,
	PopoverHelp,
} from '@persuit/ui-components'
import { isNotNil, isNil } from '@persuit/common-utils'
import { TOOLTIPS } from '../tooltips'
import { currencySymbol } from '../../../../../utils/price-and-currency'

type Values = {
	adjustedFee: number
	adjustedSavings: number
}

export type OverrideFormProps = {
	currency: string
	onSubmit: (values: Values) => unknown | Promise<unknown>
	onCancel: () => void
	neverHaveSavings: boolean
}

export const OverrideForm = ({
	onSubmit,
	onCancel,
	currency,
	neverHaveSavings,
}: OverrideFormProps) => {
	const [playingEnterAnimation, setPlayingEnterAnimation] = useState(true)
	const [formSection, setFormSection] = useState<'savings' | 'fee'>('savings')
	const [fee, setFee] = useState<number | null>(null)
	const [savings, setSavings] = useState<number | null>(null)
	const [disableSubmit, setDisableSubmit] = useState(false)

	const canSubmit = isNotNil(fee) && isNotNil(savings)
	const nextButtonDisabled = isNil(savings)

	const submitOverrides = async () => {
		if (canSubmit) {
			try {
				setDisableSubmit(true)
				await onSubmit({ adjustedFee: fee, adjustedSavings: savings })
			} finally {
				setDisableSubmit(false)
			}
		}
	}

	const savingsInput = (
		<Box display="flex" justifyContent="center" alignItems="center">
			<CustomNumberField
				key="savings"
				id="adjusted-savings"
				label="Adjusted savings"
				currencyCode={currency}
				value={savings}
				onChange={(value) => setSavings(value)}
				onKeyDown={(e) => {
					if (e.code === 'Enter' && !nextButtonDisabled) {
						setFormSection('fee')
					}
				}}
			/>
			<Box ml={2} display="flex" alignItems="center">
				<PopoverHelp
					content={TOOLTIPS.adjustedSavings}
					triggerIcon={InfoOutlinedIcon}
					triggerButtonProps={{
						size: 'small',
						'aria-label': 'Adjusted savings explanation',
					}}
				/>
			</Box>
		</Box>
	)

	return (
		<Box>
			<Box display="flex" justifyContent="center" mt={4}>
				<Box position="relative">
					{playingEnterAnimation ? (
						<Zoom
							key="savings"
							in={formSection === 'savings'}
							appear={true}
							onEntered={() => setPlayingEnterAnimation(false)}
						>
							{savingsInput}
						</Zoom>
					) : (
						<Slide
							key="savings"
							direction="right"
							in={formSection === 'savings'}
							appear={false}
							unmountOnExit={true}
							style={{
								position: formSection === 'savings' ? 'static' : 'absolute',
								top: 0,
								left: 0,
							}}
						>
							{savingsInput}
						</Slide>
					)}
					<Slide
						key="fee"
						direction="left"
						in={formSection === 'fee'}
						appear={false}
						unmountOnExit={true}
						style={{
							position: formSection === 'fee' ? 'static' : 'absolute',
							top: 0,
							left: 0,
						}}
					>
						<Box display="flex" justifyContent="center" alignItems="center">
							<CustomNumberField
								key="fee"
								id="adjusted-fee"
								label="Adjusted agreed fee"
								currencyCode={currency}
								value={fee}
								onChange={(value) => setFee(value)}
								onKeyDown={(e) => {
									if (e.code === 'Enter') {
										submitOverrides()
									}
								}}
							/>
							<Box ml={2} display="flex" alignItems="center">
								<PopoverHelp
									content={TOOLTIPS.adjustedAgreedFee}
									triggerIcon={InfoOutlinedIcon}
									triggerButtonProps={{
										size: 'small',
										'aria-label': 'Adjusted agreed fee explanation',
									}}
								/>
							</Box>
						</Box>
					</Slide>
				</Box>
			</Box>

			<Box display="flex" justifyContent="center" mt={2}>
				<Typography
					align="center"
					variant="body2"
					color="text.secondary"
					sx={{ maxWidth: '540px' }}
				>
					This number will flow into reporting and become aggregated with matters that still include
					default PERSUIT savings estimates to provide accurate total metrics.
				</Typography>
			</Box>
			<Box display="flex" justifyContent="center" mt={2}>
				{formSection === 'fee' ? (
					<Box mr={2}>
						<Button color="primary" onClick={() => setFormSection('savings')}>
							Back
						</Button>
					</Box>
				) : null}

				<Button variant="text" color="primary" onClick={() => onCancel()}>
					Cancel
				</Button>

				{formSection === 'savings' ? (
					<Box ml={2}>
						<Button
							disabled={nextButtonDisabled}
							variant="outlined"
							color="primary"
							onClick={() => setFormSection('fee')}
						>
							Next
						</Button>
					</Box>
				) : (
					<Box ml={2}>
						<Button
							variant="contained"
							color="primary"
							disabled={!canSubmit || disableSubmit}
							onClick={submitOverrides}
						>
							{neverHaveSavings ? 'Enter Savings' : 'Override Savings'}
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	)
}

type CustomNumberFieldProps = NumberFieldProps & {
	currencyCode: string
}

const CustomNumberField = ({ currencyCode, ...rest }: CustomNumberFieldProps) => (
	<NumberField
		{...rest}
		required={true}
		color="primary"
		// Given the prior action to get access to this field it seems that this is the clear primary action and this
		// Autofocus is proper
		// eslint-disable-next-line jsx-a11y/no-autofocus
		autoFocus={true}
		size="small"
		variant="outlined"
		positiveOnly={true}
		decimalPlaces={0}
		style={{ minWidth: 300 }}
		InputProps={{
			startAdornment: <Box>{currencySymbol(currencyCode)}</Box>,
		}}
	/>
)
