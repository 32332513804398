// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Divider from '@mui/material/Divider'

import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'

import DialogWizardListOption from '../../dialog-wizard-list-option'
import notificationOptions from './notification-option-enums'
import { stepIndex } from '../../utils'

const option1Bullets = [
	'Selected firm will be notified.',
	'Remaining firms will be marked as unsuccessful and be notified of this.',
	'You will be unable to select additional firms.',
]

const option2Bullets = [
	'Remaining firms will not be notified.',
	'You will still be able to select additional firms.',
]

const option3Bullets = [
	'Selected firm will not be notified.',
	'Remaining firm(s) will not be notified.',
	'You will still be able to select additional firms.',
]

function OptionSelectFirmOptions({
	onChange,
	onPrev,
	onNext,
	onCancel,
	value: { notifyOtherFirms },
	disableMarkRemainingFirmsUnsuccessfulRadio,
}) {
	const onSelectOption = (value) => () => {
		onChange({
			notificationOption: value,
		})
		onNext()
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'ACTIONS'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Select firm',
			}}
			onPrev={onPrev}
			onCancel={onCancel}
		>
			<Divider />
			<DialogWizardListOption
				dataTestid="notify-other-firms-option"
				primaryText="Notify selected firm and remaining firm(s)"
				bullets={option1Bullets}
				disabled={notifyOtherFirms === true || disableMarkRemainingFirmsUnsuccessfulRadio}
				onClick={onSelectOption(notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT)}
			/>
			<DialogWizardListOption
				dataTestid="dont-notify-other-firms-option"
				primaryText="Notify selected firm only"
				bullets={option2Bullets}
				disabled={notifyOtherFirms === false}
				onClick={onSelectOption(notificationOptions.SUCCESSFUL_FIRM_AND_CLIENT)}
			/>
			<DialogWizardListOption
				dataTestid="dont-notify"
				primaryText="Do not send notifications"
				bullets={option3Bullets}
				disabled={notifyOtherFirms === false}
				onClick={onSelectOption(notificationOptions.SEND_TO_NONE)}
			/>
		</DialogWizardContent>
	)
}

export default OptionSelectFirmOptions
