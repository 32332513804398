import { useRef } from 'react'

/**
 * Returns whether this is the first render of this component
 */
// Here's a very simple hook that returns true the first time the component
// is rendered, then false after that
export function useIsFirstRender(): boolean {
	const isFirstRenderRef = useRef(true)
	const isFirstRender = isFirstRenderRef.current

	isFirstRenderRef.current = false

	return isFirstRender
}
