import { isNotNil } from '@persuit/common-utils'

// Columns
export const responseColumnId = (responseId: string) => `proposal-col:${responseId}`
export const intelligenceColumnId = 'intelligence-col'

// Firm name
export const firmNameRowId = 'firm-name-row'

// Total Price
export const totalPriceRowId = 'total-price-row'
export const totalPriceCellHeaders = (responseId: string) =>
	combine(responseColumnId(responseId), totalPriceRowId)
export const totalPriceIntelligenceCellHeaders = combine(intelligenceColumnId, totalPriceRowId)

// Proposal Summary
export const proposalSummaryRowId = 'summary-row'
export const proposalSummaryCellHeaders = (responseId: string) =>
	combine(responseColumnId(responseId), proposalSummaryRowId)

// Comparison Value
export const comparisonValueRowId = 'comparison-value-row'
export const comparisonValueCellHeaders = (responseId: string) =>
	combine(responseColumnId(responseId), comparisonValueRowId)

// Average rate
export const averageRateRowId = 'average-rate-row'
export const averageRateCellHeaders = (responseId: string) =>
	combine(responseColumnId(responseId), averageRateRowId)

// Scorecard
export const scorecardRowId = 'scorecard-row'
export const scorecardSectionId = 'scorecard-section'
export const scorecardCellHeaders = (responseId: string) =>
	combine(responseColumnId(responseId), scorecardRowId)

// Pricing
export const pricingRowId = (deliverableId: string) => `deliverable-row:${deliverableId}`
export const pricingGroupRowId = (groupId?: string | null) =>
	groupId ? `pricing-group-row:${groupId}` : null
export const pricingSectionId = 'pricing-section'
type PricingCellHeadersInput = {
	responseId: string
	deliverableId: string
	groupId?: string | null
}
export const pricingCellHeaders = ({
	responseId,
	deliverableId,
	groupId,
}: PricingCellHeadersInput) =>
	combine(responseColumnId(responseId), pricingGroupRowId(groupId), pricingRowId(deliverableId))
type PricingIntelligenceCellHeadersInput = {
	deliverableId: string
	groupId?: string | null
}
export const pricingIntelligenceCellHeaders = ({
	deliverableId,
	groupId,
}: PricingIntelligenceCellHeadersInput) =>
	combine(intelligenceColumnId, pricingGroupRowId(groupId), pricingRowId(deliverableId))

// Questions
export const questionRowId = (questionId: string) => `question-row:${questionId}`
export const questionGroupRowId = (groupId?: string | null) =>
	groupId ? `question-group-row:${groupId}` : null
export const questionsSectionId = 'questions-section'
type QuestionCellHeadersInput = {
	responseId: string
	questionId: string
}
export const questionCellHeaders = ({ responseId, questionId }: QuestionCellHeadersInput) =>
	combine(responseColumnId(responseId), questionRowId(questionId))
export const questionIntelligenceCellHeaders = (questionId: string) =>
	combine(intelligenceColumnId, questionRowId(questionId))

// Diversity
export const diversitySectionId = 'diversity-section'
export const diversityRowId = (diversityId: string) => `diversity-row:${diversityId}`
type DiversityCellHeadersInput = {
	responseId: string
	diversityId: string
}
export const diversityCellHeaders = ({ responseId, diversityId }: DiversityCellHeadersInput) =>
	combine(responseColumnId(responseId), diversityRowId(diversityId))

// Utils
function combine(...ids: (string | null)[]): string {
	return ids.filter(isNotNil).join(' ')
}
