import { graphql } from '@persuit/ui-graphql'

const saveRfpProposalScorecard = graphql(`
	mutation SaveRfpProposalScorecard($_id: ID!, $scorecard: [ScorecardInput]!) {
		saveRfpProposalScorecard(_id: $_id, scorecard: $scorecard) {
			...RfpProposal
		}
	}
`)

export default saveRfpProposalScorecard
