import * as React from 'react'

export const PersuitLogo = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		x="0px"
		y="0px"
		width="208px"
		height="20px"
		viewBox="0 0 260 25"
		aria-label="PERSUIT Logo"
		id="persuit-logo"
		{...props}
	>
		<path
			fill="currentColor"
			d="M9.097,0.921H0V12.66l9.022-0.005c2.723,0,4.478-1.659,4.478-4.225c0-2.632-1.713-4.271-4.478-4.271H3.749 v5.629H4.85V5.264h4.173c2.146,0,3.381,1.151,3.381,3.167c0,1.983-1.234,3.131-3.381,3.131l-7.926,0.004V2.018h8 c4.458,0,7.018,2.339,7.018,6.413c0,4.047-2.56,6.372-7.018,6.372H3.749v8.255H1.097v-8.611H0v9.711h4.85v-8.255h4.247 c5.083,0,8.12-2.796,8.12-7.472C17.217,3.727,14.18,0.921,9.097,0.921"
		/>

		<polygon
			fill="currentColor"
			points="38.405,0.92 38.405,11.175 51.854,11.175 51.854,13.361 38.405,13.361 38.405,24.157 53.96,24.157 53.96,19.703 43.178,19.703 43.178,16.234 42.08,16.234 42.08,20.802 52.86,20.802 52.86,23.058 39.501,23.058 39.501,14.461 52.95,14.461 52.95,10.077 39.501,10.077 39.501,2.018 52.86,2.018 52.86,4.235 42.08,4.235 42.08,8.314 43.178,8.314 43.178,5.338 53.96,5.338 53.96,0.92"
		/>

		<path
			fill="currentColor"
			d="M92.867,8.105c0-4.505-3.022-7.185-8.083-7.185h-9.092V12.04h9.019c2.739,0,4.443-1.51,4.443-3.935 c0-2.434-1.704-3.945-4.443-3.945h-5.272v5.025h1.099V5.264h4.174c2.128,0,3.347,1.034,3.347,2.842c0,1.802-1.219,2.839-3.347,2.839 h-7.922V2.019h7.995c4.438,0,6.984,2.217,6.984,6.087c0,4.161-2.826,5.471-5.193,5.84l-0.874,0.13l6.318,8.984h-2.908l-6.162-8.98 h-3.511v8.98h-2.649v-9.245h-1.097v10.343h4.845v-8.981h1.832l6.165,8.981h5.6l-6.54-9.295 C91.006,14.044,92.867,11.673,92.867,8.105"
		/>

		<path
			fill="currentColor"
			d="M166.827,10.062V2.018h2.649v6.286h1.101V0.921h-4.848v10.275h1.098h2.649v3.61 c0,5.478-3.029,8.611-8.318,8.611c-5.287,0-8.32-3.149-8.32-8.644V2.018h2.649v12.36c0,5.607,3.085,6.788,5.671,6.788 c2.587,0,5.669-1.181,5.669-6.788v-1.4h-1.098v1.4c0,3.831-1.495,5.687-4.571,5.687s-4.569-1.855-4.569-5.687V0.921h-4.853v13.854 c0,6.1,3.525,9.743,9.422,9.743c5.896,0,9.419-3.628,9.419-9.711v-4.178l0.007-0.566H166.827z"
		/>

		<polygon
			fill="currentColor"
			points="192.776,0.92 192.776,9.57 193.874,9.57 193.874,2.018 196.525,2.018 196.525,8.903 196.525,11.128 196.525,11.332 192.776,11.332 192.776,12.169 192.776,24.157 197.624,24.157 197.624,14.209 196.525,14.209 196.525,23.058 193.874,23.058 193.874,12.434 197.452,12.434 197.624,12.434 197.624,11.128 197.624,8.903 197.624,0.92 "
		/>

		<polygon
			fill="currentColor"
			points="218.811,0.92 218.811,5.338 224.181,5.338 224.181,4.235 219.911,4.235 219.911,2.018 237.273,2.018 237.273,4.235 226.165,4.235 226.165,24.157 231.016,24.157 231.016,7.114 229.917,7.114 229.917,23.058 227.264,23.058 227.264,5.338 238.368,5.338 238.368,0.92 "
		/>

		<polygon
			fill="currentColor"
			points="251.1,0.97 251.1,1.584 252.458,1.584 252.458,5.058 253.151,5.058 253.151,1.584 254.509,1.584 254.509,0.97"
		/>

		<polygon
			fill="currentColor"
			points="259.229,0.97 257.996,4.11 256.75,0.97 255.958,0.97 255.958,5.058 256.616,5.058 256.616,2.216 257.74,5.058 258.233,5.058 259.334,2.216 259.334,5.058 260,5.058 260,0.97"
		/>

		<path
			fill="currentColor"
			d="M124.907,10.599c0,0-1.292-0.296-2.042-0.469l-1.223-0.349l-0.999,3.439l-0.899-0.217 c-3.428-0.806-5.094-2.575-5.094-5.413c0-3.54,2.874-6.01,6.984-6.01c4.014,0,6.721,1.777,7.692,5.029h-2.661 c-0.822-1.912-2.578-2.958-4.995-2.958c-2.675,0-4.551,1.485-4.551,3.612c0,1.422,0.708,2.479,2.239,3.082l0.417-1.023 c-1.357-0.557-1.555-1.373-1.555-2.059c0-1.506,1.388-2.516,3.449-2.516c2.646,0,3.689,1.407,4.096,2.59l0.129,0.37h4.823 l-0.144-0.666c-0.899-4.172-4.158-6.561-8.94-6.561c-4.688,0-8.083,2.986-8.083,7.108c0,3.374,2,5.556,5.942,6.484l2.02,0.496 l1.033-3.397l1.779,0.413c0.104,0.025,0.204,0.05,0.307,0.07l0.14-0.528l-0.139,0.528c2.281,0.589,4.997,2.023,4.997,5.523 c0,3.681-2.948,6.16-7.346,6.16c-4.2,0-7.192-2.003-8.142-5.395h2.743c0.839,2.146,2.741,3.319,5.398,3.319 c2.94,0,4.843-1.504,4.843-3.832c0-1.967-0.846-2.862-3.127-3.402l-0.268,1.148c1.792,0.432,2.291,1.297,2.291,2.254 c0,1.71-1.397,2.733-3.739,2.733c-1.623,0-3.716-0.51-4.489-2.935l-0.12-0.386h-4.889l0.126,0.655 c0.834,4.347,4.344,6.934,9.372,6.934c5.053,0,8.448-2.912,8.448-7.255C130.733,13.896,128.666,11.564,124.907,10.599"
		/>
	</svg>
)
