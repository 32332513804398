import { useState } from 'react'
import * as React from 'react'
import { Box, BoxProps } from '../box'
import { IconButton, IconButtonProps } from '../icon-button'
import { Popover as MUIPopover, PopoverProps as MUIPopoverProps } from './popover'
import { Typography } from '../typography'
import { CancelIcon, HelpOutlineIcon } from '../../icons'
import { useTheme } from '../../theme'

export type PopoverHelpProps = {
	/**
	 * This is the point on the anchor where the popover's
	 * `anchorEl` will attach to.  Default: { vertical: 'bottom', horizontal: 'center' }
	 *
	 * Options:
	 * vertical: [top, center, bottom];
	 * horizontal: [left, center, right].
	 */
	anchorOrigin?: MUIPopoverProps['anchorOrigin']

	/**
	 * This is the point on the popover which
	 * will attach to the anchor's origin. Default: { vertical: 'top', horizontal: 'center' }
	 *
	 * Options:
	 * vertical: [top, center, bottom, x(px)];
	 * horizontal: [left, center, right, x(px)].
	 */
	transformOrigin?: MUIPopoverProps['transformOrigin']

	/**
	 * Content as string or renderable JSX element
	 */
	content?: string | JSX.Element

	/**
	 * Passing custom props for Box container of popover content
	 */
	contentContainerProps?: BoxProps

	/**
	 * @deprecated Use content prop instead
	 */
	message?: string | JSX.Element

	/**
	 * Passing custom trigger icon
	 */
	triggerIcon?: typeof CancelIcon

	/**
	 * Passing props to trigger button. Encourage you to use this to give trigger button at least a name for accessibility reason.
	 */
	triggerButtonProps: IconButtonProps & {
		'aria-label': string
	}

	/**
	 * Passing custom props for container of Popover trigger
	 */
	triggerContainerProps?: BoxProps

	/**
	 * Passing custom trigger button size
	 */
	triggerButtonSize?: IconButtonProps['size']

	/**
	 * @deprecated Use triggerIcon instead
	 */
	HelpIcon?: typeof CancelIcon
} & BoxProps

export const PopoverHelp = ({
	message = '',
	HelpIcon,
	content = '',
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'center',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'center',
	},
	triggerContainerProps,
	triggerIcon,
	triggerButtonProps,
	contentContainerProps,
	triggerButtonSize,
	...props
}: PopoverHelpProps) => {
	const popoverContent = content || message
	const Icon = triggerIcon || HelpIcon || HelpOutlineIcon

	const [anchorEl, setAnchorEl] = useState<Element | null>(null)

	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (e: React.SyntheticEvent) => {
		e.stopPropagation()
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	const theme = useTheme()

	return (
		<Box {...triggerContainerProps} {...props}>
			<IconButton
				{...triggerButtonProps}
				onClick={handleClick}
				size={triggerButtonSize ? triggerButtonSize : 'large'}
			>
				{open && <CancelIcon color="primary" />}
				{!open && <Icon color="primary" />}
			</IconButton>

			<MUIPopover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				sx={{
					zIndex: theme.zIndex.tooltip,
				}}
			>
				<Box
					width={'470px'}
					display={'flex'}
					border={`1px solid ${theme.palette.primary.main}`}
					bgcolor={theme.palette.primary.lighterHue}
					borderRadius="3pxpx"
					{...contentContainerProps}
				>
					<Box
						bgcolor={theme.palette.primary.main}
						padding="12px"
						display="flex"
						justifyContent="center"
						alignItems={'center'}
					>
						<Icon
							style={{
								color: 'white',
							}}
						/>
					</Box>
					<Box padding="0.5rem" display="flex" flexDirection="column">
						{/* If the message prop is a string chuck it in a nice <Text/> component */}
						{typeof popoverContent === 'string' && (
							<Typography variant="body2">{popoverContent}</Typography>
						)}

						{/* If the message prop is a non-string but is renderable then output it without wrapping it */}
						{typeof popoverContent !== 'string' && popoverContent}
					</Box>
				</Box>
			</MUIPopover>
		</Box>
	)
}

PopoverHelp.displayName = 'PopoverHelp'
