// @ts-strict-ignore
import { useState } from 'react'

import { gql, useMutation } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import { IconButton } from '@persuit/ui-components'

const FEEDBACK_MUTATION = gql`
	mutation proposalAnalysisFeedback(
		$rfpId: ID!
		$proposalIds: [ID!]!
		$cacheType: String!
		$thumbsUp: Boolean!
	) {
		proposalAnalysisFeedback(
			rfpId: $rfpId
			proposalIds: $proposalIds
			cacheType: $cacheType
			thumbsUp: $thumbsUp
		)
	}
`

const getAnswersByQuestionId = (proposals: any) => {
	const answersByQuestionId = (proposals || []).reduce((acc, proposal: any) => {
		proposal.answersToQuestions.forEach((answerToQuestion: any) => {
			if (!acc[answerToQuestion.questionId]) {
				acc[answerToQuestion.questionId] = []
			}
			acc[answerToQuestion.questionId].push(answerToQuestion._id)
		})
		return acc
	}, {})
	return answersByQuestionId
}

const getItemsByDeliverableId = (proposals: any) => {
	const itemsByDeliverableId = (proposals || []).reduce((acc, proposal: any) => {
		proposal.responseToDeliverables.forEach((responseToDeliverable: any) => {
			if (!acc[responseToDeliverable.deliverableId]) {
				acc[responseToDeliverable.deliverableId] = []
			}
			acc[responseToDeliverable.deliverableId].push(responseToDeliverable._id)
		})
		return acc
	}, {})
	return itemsByDeliverableId
}

const thumbpsUpFn =
	({ thumbsUpState, setThumbsUp, feedbackMutation, rfpId, proposals, thumbsUp }) =>
	async () => {
		if (thumbsUpState === thumbsUp) {
			return
		}

		const proposalIds = proposals.map(({ _id }) => _id)
		const answersByQuestionId = getAnswersByQuestionId(proposals)
		const itemsByDeliverableId = getItemsByDeliverableId(proposals)

		await Promise.all([
			...Object.keys(answersByQuestionId).map((questionId) => {
				feedbackMutation({
					variables: {
						rfpId,
						proposalIds: answersByQuestionId[questionId],
						cacheType: 'answerCompare',
						thumbsUp,
					},
				})
			}),
			...Object.keys(itemsByDeliverableId).map((deliverableId) => {
				feedbackMutation({
					variables: {
						rfpId,
						proposalIds: itemsByDeliverableId[deliverableId],
						cacheType: 'priceItemCompare',
						thumbsUp,
					},
				})
			}),
			feedbackMutation({
				variables: { rfpId, proposalIds, cacheType: 'totalPriceCompare', thumbsUp },
			}),
			feedbackMutation({
				variables: { rfpId, proposalIds, cacheType: 'scopeOfWorkCompare', thumbsUp },
			}),
		])
		setThumbsUp(thumbsUp)
		return
	}

export const Feedback = ({ rfpId, proposals }) => {
	const [thumbsUp, setThumbsUp] = useState(null)

	const [proposalAnalysisFeedback] = useMutation(FEEDBACK_MUTATION)

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ marginRight: '0.5rem' }}>Was this helpful?</div>
			<IconButton
				color="secondary"
				onClick={thumbpsUpFn({
					thumbsUpState: thumbsUp,
					setThumbsUp,
					feedbackMutation: proposalAnalysisFeedback,
					rfpId,
					proposals,
					thumbsUp: true,
				})}
			>
				{thumbsUp === true ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
			</IconButton>
			<IconButton
				color="secondary"
				onClick={thumbpsUpFn({
					thumbsUpState: thumbsUp,
					setThumbsUp,
					feedbackMutation: proposalAnalysisFeedback,
					rfpId,
					proposals,
					thumbsUp: false,
				})}
			>
				{thumbsUp === false ? (
					<ThumbDownAltIcon data-trackid="button-thumb-down-ai-analysis" />
				) : (
					<ThumbDownOffAltIcon data-trackid="button-thumb-up-ai-analysis" />
				)}
			</IconButton>
		</div>
	)
}
