// @ts-strict-ignore
import { graphql, useQuery } from '@persuit/ui-graphql'
import { TimekeepersForm_GetTimekeepersQuery } from '@persuit/ui-graphql/generated'
import {
	Box,
	Button,
	DeleteIcon,
	FormControl,
	IconButton,
	SortableDragHandler,
	SortableListItem,
	SortableVerticalList,
	Typography,
	AddIcon,
	FieldArrayWithId,
} from '@persuit/ui-components'

import { getOrGenerateUUID, isNotNilProp } from '@persuit/common-utils'
import { TimekeeperAutocomplete, TimekeeperAutocompleteProps } from './timekeeper-autocomplete'

import { RatesFormValues, useTimekeeperFieldArray } from '../rates-form-utils'
import differenceBy from 'lodash/fp/differenceBy'

export type SelectableRateReviewTimeKeeperProps = {
	selectableRateReviewTimeKeepers: TimekeeperAutocompleteProps['options']
}

const GET_TIMEKEEPERS = graphql(`
	query TimekeepersForm_GetTimekeepers {
		getRateReviewTimeKeepers {
			_id
			label
		}
	}
`)

const getSelectableTimekeepers = (
	timekeepers: TimekeepersForm_GetTimekeepersQuery['getRateReviewTimeKeepers'],
	timekeeperFields: FieldArrayWithId<RatesFormValues, 'timekeepers', 'id'>[],
) => {
	const mappedTimekeepers = timekeepers.map((tk) => ({
		timekeeperId: tk._id,
		timekeeperLabel: tk.label,
	}))

	const diffSelectableTimekeepers = differenceBy(
		'timekeeperLabel',
		mappedTimekeepers,
		timekeeperFields,
	)

	return diffSelectableTimekeepers
}

export const Timekeepers = () => {
	const { data: timekeeperData, loading: loadingTimekeepers } = useQuery(GET_TIMEKEEPERS, {
		fetchPolicy: 'network-only',
	})
	const {
		append,
		remove,
		fields: timekeeperFields,
		move: moveTimekeeper,
	} = useTimekeeperFieldArray()

	if (loadingTimekeepers || !timekeeperData) return null

	const selectableRateReviewTimeKeepers = getSelectableTimekeepers(
		timekeeperData.getRateReviewTimeKeepers,
		timekeeperFields,
	)

	const canRemoveTimekeeperField = timekeeperFields.length > 1

	const removeTimekeeper = (index) => {
		if (canRemoveTimekeeperField) {
			remove(index)
		}
	}

	// Accessibility stuff
	const getPosition = (id) => timekeeperFields.findIndex((tk) => getOrGenerateUUID(tk) === id) + 1
	const timekeepersCount = timekeeperFields.length
	const timekeeperFieldsMap = new Map(
		selectableRateReviewTimeKeepers.map((timekeeper) => {
			return [timekeeper.timekeeperId, timekeeper]
		}),
	)

	const getDraggableTimekeeperTitle = (id: string) => {
		const tk = timekeeperFieldsMap.get(id)

		return tk
			? `${getPosition(id)} ${timekeeperFieldsMap.get(id)?.timekeeperLabel ?? ''}`
			: `${getPosition(id)}`
	}

	const emptyTimekeeper = { timekeeperId: '', timekeeperLabel: '' }

	return (
		<>
			<FormControl fullWidth={true}>
				<SortableVerticalList
					items={timekeeperFields}
					onMove={moveTimekeeper}
					accessibility={{
						announcements: {
							onDragStart({ active }) {
								return `Picked up draggable timekeeper ${getDraggableTimekeeperTitle(
									`${active.id}`,
								)}. It is in position ${getPosition(active.id)} of ${timekeepersCount}`
							},
							onDragOver({ active, over }) {
								if (over) {
									return `Draggable timekeeper ${getDraggableTimekeeperTitle(
										`${active.id}`,
									)} was moved into position ${getPosition(over.id)} of ${timekeepersCount}`
								}
							},
							onDragEnd({ active, over }) {
								if (over) {
									return `Draggable timekeeper ${getDraggableTimekeeperTitle(
										`${active.id}`,
									)} was dropped at position ${getPosition(over.id)} of ${timekeepersCount}`
								}
							},
							onDragCancel({ active }) {
								return `Dragging was cancelled. Draggable timekeeper ${getDraggableTimekeeperTitle(
									`${active.id}`,
								)} was dropped.`
							},
						},
						screenReaderInstructions: {
							draggable: `To pick up a draggable timekeeper, press space or enter.
							While dragging, use the arrow keys to move the timekeeper in up/down direction.
							Press space or enter again to drop the timekeeper in its new position, or press escape to cancel.`,
						},
					}}
				>
					{timekeeperFields.filter(isNotNilProp('timekeeperLabel')).map((field, index) => (
						<SortableListItem key={field.id} id={field.id}>
							{() => (
								<Box display="flex" sx={{ mt: '1em' }}>
									<Box sx={{ height: '56px', display: 'flex', alignItems: 'center' }}>
										<SortableDragHandler id={field.id} />

										<Box mx={1}>
											<Typography variant="body1">{`${index + 1}.`}</Typography>
										</Box>
									</Box>

									<TimekeeperAutocomplete
										nullValue={emptyTimekeeper}
										options={selectableRateReviewTimeKeepers}
										timekeeperIndex={index}
									/>

									{canRemoveTimekeeperField && !field.originalTimekeeperId && (
										<Box sx={{ height: '56px', display: 'flex', alignItems: 'center' }}>
											<IconButton
												aria-label={`Delete time keeper ${index + 1}`}
												onClick={() => removeTimekeeper(index)}
											>
												<DeleteIcon />
											</IconButton>
										</Box>
									)}
								</Box>
							)}
						</SortableListItem>
					))}
				</SortableVerticalList>
				<Box sx={{ mt: '1em' }}>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => append(emptyTimekeeper)}
						startIcon={<AddIcon />}
					>
						Add Timekeeper
					</Button>
				</Box>
			</FormControl>
		</>
	)
}
