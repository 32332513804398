import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import language from './language'
import messaging from './messaging'
import proposalScores from './proposal-scores'
import ui from './ui'
import userActivity from './user-activity'

export default combineReducers({
	auth,
	form: formReducer,
	language,
	ui,
	userActivity,
	proposalScores,
	messaging,
})
