import fileScanStates from './data/file-scan-states'

//////////////////////////////////////////////////////////////////
// checks to use on array of files

// returns true if at least 1 file is queued
export function checkFilesQueued(files = []) {
	return files.some((f) => checkFileStatusQueued(f.fileStatus))
}

// returns true if at least 1 file are not clean
export function checkFilesThreat(files = []) {
	return files.some((f) => checkFileStatusThreat(f.fileStatus))
}

// returns true if at least 1 file is oversize
export function checkFilesOversize(files = []) {
	return files.some((f) => checkFileStatusOversize(f.fileStatus))
}

// returns true if all files are clean
export function checkFilesClean(files = []) {
	return files.every((f) => checkFileStatusClean(f.fileStatus))
}

//////////////////////////////////////////////////////////////////
// checks to use on single files

// returns true if all files are not clean
export function checkFileStatusThreat(fileStatus = '') {
	if (
		fileStatus === fileScanStates.VIRUS ||
		fileStatus === fileScanStates.NOTALLOWED ||
		fileStatus === fileScanStates.SCANERROR
	) {
		return true
	}
	return false
}

// returns true if file is clean
export function checkFileStatusClean(fileStatus = '') {
	return fileStatus === fileScanStates.CLEAN ? true : false
}

// returns true if file is too large (50MB)
export function checkFileStatusOversize(fileStatus = '') {
	return fileStatus === fileScanStates.OVERSIZE ? true : false
}

// returns true if file is queued
export function checkFileStatusQueued(fileStatus = '') {
	return fileStatus === fileScanStates.QUEUED ? true : false
}
