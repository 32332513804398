import { memo, forwardRef, useEffect } from 'react'
import {
	BoxProps,
	FormProvider,
	useForm,
	Autocomplete,
	TextField,
	Divider,
	Box,
	ListItem,
	List,
	ListSubheader,
	RHFFormComponentProps,
	useFormComponent,
	Typography,
} from '@persuit/ui-components'
import { DEFAULT_PRICING_MODELS_OPTIONS } from './pricing-model-select-config'
import { pricingModels } from './pricing-models'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type PricingModelSelectProps = RHFFormComponentProps<
	string,
	{
		type?: keyof typeof pricingModels
		hasValidationErrors?: string
		options?: typeof DEFAULT_PRICING_MODELS_OPTIONS
		containerProps?: BoxProps
		showContingencyFeeOption?: boolean
		emptyLabel?: string
		label?: string
		id?: string
		title?: string
		fullWidth?: boolean
		required?: boolean
	}
>

type FlattenedOption = {
	value: string
	type: string
	group: string
}

export const PricingModelSelect = memo(
	({
		showContingencyFeeOption = false,
		id,
		name,
		options = DEFAULT_PRICING_MODELS_OPTIONS,
		type = 'form',
		hasValidationErrors,
		defaultValue = 'fixedFee',
		disabled,
		emptyLabel = 'Please choose an option',
		title,
		fullWidth = true,
		required = false,
		...props
	}: PricingModelSelectProps) => {
		const {
			field: { onChange, value, ref },
		} = useFormComponent({
			name,
			defaultValue,
		})
		const { toggles } = useFeatureToggles()
		const isToggleOn = toggles['dev-3327.contingency-percentage']
		const optionedType =
			isToggleOn && type !== 'firmCard' && type.startsWith('firm') ? `${type}WithDiscount` : type
		const pricingModelOptions = options[optionedType] || []

		const flattenedPricingOptions: FlattenedOption[] = pricingModelOptions.flatMap(
			(item): FlattenedOption[] => {
				if (item.type === 'DIVIDER') {
					return [] // Skip DIVIDERs
				} else if (item.type === 'OPTION_HEADER') {
					// Map children to include group from parent OPTION_HEADER
					return item.children.map((child) => ({
						value: child.value!,
						type: child.type,
						group: item.value,
					}))
				} else {
					// Regular OPTION items
					return [
						{
							value: item.value!,
							type: item.type,
							group: `ungrouped-${item.value}`,
						},
					]
				}
			},
		)

		const CustomListBox = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(
			function ListBoxComponent(props, ref) {
				const { children, ...other } = props

				return (
					<List {...other} style={{ padding: 0 }} ref={ref}>
						<ListItem sx={{ px: 2, pt: 2, pb: 1 }}>
							<Typography variant="body2" color="text.secondary">
								{emptyLabel}
							</Typography>
						</ListItem>
						{children}
					</List>
				)
			},
		)

		return (
			<>
				<Autocomplete
					id={id}
					disabled={disabled}
					value={value ? flattenedPricingOptions.find((option) => option.value === value) : value}
					onChange={(_, newValue: FlattenedOption) => {
						onChange(newValue.value)
					}}
					title={title}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								inputRef={ref}
								inputProps={{
									...params.inputProps,
									'data-testid': 'pricing-model-select',
								}}
								error={!!hasValidationErrors}
								required={required}
								helperText={hasValidationErrors}
								label={isToggleOn && !props.label ? 'Fee type' : props.label ?? 'Pricing'}
								disabled={disabled}
							/>
						)
					}}
					options={flattenedPricingOptions}
					groupBy={(option) => option?.group}
					getOptionLabel={(option: FlattenedOption): string => {
						return `${pricingModels[type][option.value]}`
					}}
					renderGroup={(params) => (
						<>
							<List key={params.key} sx={{ p: 0 }}>
								{!params.group.startsWith('ungrouped') && (
									<ListSubheader sx={{ lineHeight: '30px' }}>
										{!isToggleOn && params.group === 'afa'
											? 'Alternative Fee Arrangements'
											: pricingModels[type][params.group]}
									</ListSubheader>
								)}
								<ListItem sx={{ p: 1 }}>
									<Box width="100%">{params.children}</Box>
								</ListItem>
							</List>
							<Divider />
						</>
					)}
					renderOption={(props, option) => {
						if (
							!showContingencyFeeOption &&
							isToggleOn &&
							type !== 'form' &&
							type !== 'card' &&
							option.value === 'contingencyFee'
						) {
							return (
								<Box
									component="li"
									data-testid="contingency-pricing-model-option"
									aria-hidden="true"
									p={0}
									style={{ display: 'none' }}
									{...props}
								>
									{pricingModels[type][option.value]}
								</Box>
							)
						}

						return (
							<Box component="li" data-testid="pricing-model-option" p={0} {...props}>
								{pricingModels[type][option.value]}
							</Box>
						)
					}}
					ListboxComponent={CustomListBox}
					fullWidth={fullWidth}
					clearOnBlur={true}
					disableClearable={true}
					{...props}
					slotProps={{
						paper: {
							sx: {
								minWidth: '250px',
							},
						},
					}}
				/>
			</>
		)
	},
)

PricingModelSelect.displayName = 'PricingModelSelect'

type PricingModelSelectAdapterProps = Omit<PricingModelSelectProps, 'name' | 'onChange'> & {
	onChange?: (value: string) => void
	value?: string
}

export const PricingModelSelectAdapter = memo(
	({ defaultValue, value, onChange, ...rest }: PricingModelSelectAdapterProps) => {
		const methods = useForm<{ pricing: string }>({
			mode: 'onChange',
			defaultValues: {
				pricing: defaultValue,
			},
		})

		methods.watch((values, { name, type }) => {
			if (name === 'pricing' && type === 'change') {
				if (values.pricing) onChange?.(values.pricing)
			}
		})

		useEffect(() => {
			if (value) {
				methods.setValue('pricing', value)
			}
		}, [methods, value])

		return (
			<FormProvider {...methods}>
				<PricingModelSelect name="pricing" {...rest} />
			</FormProvider>
		)
	},
)
