import { gql, useMutation } from '@apollo/client'
import { trackWithPendo } from '../../../../track-event'
import SummaryList from '../../dialog-wizard-list-summary'
import eliminateActionTypes from '../../../../../common/data/eliminate-action-types'
import Spacer from '../../../layout/spacer.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import notificationOptions from './notification-option-enums'
import { stepIndex } from '../../utils'
import { useSnackbar } from '@persuit/ui-components'

const ELIMINATE_FIRM_MUTATION = gql`
	mutation eliminateFirm($payload: EliminateInput!) {
		eliminateFirm(payload: $payload) {
			_id
		}
	}
`

const ACCEPT_PROPOSAL_MUTATION = gql`
	mutation acceptResponse(
		$responseId: ID!
		$notificationOption: AcceptResponseNotificationOption!
	) {
		acceptResponse(responseId: $responseId, notificationOption: $notificationOption) {
			_id
		}
	}
`

const notificationOptionText = new Map()
	.set(notificationOptions.SEND_TO_NONE, 'Do not send notifications')
	.set(
		notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT,
		'Notify selected firm and remaining firm(s)',
	)
	.set(notificationOptions.SUCCESSFUL_FIRM_AND_CLIENT, 'Notify selected firm only')

function OptionSelectFirmConfirm({
	onCancel,
	onDone,
	onPrev,
	firmId,
	proposalId,
	firmName,
	requestId,
	fetchResponses,
	openProposalFeedbackFormAll = null,
	openProposalFeedbackFormSingleFirm = null,
	value: { notificationOption },
}) {
	const [acceptResponse, { loading: loadingAcceptMutation, error: errorAcceptMutation }] =
		useMutation(ACCEPT_PROPOSAL_MUTATION)
	const [eliminateFirm, { loading: loadingEliminateMutation, error: errorEliminateMutation }] =
		useMutation(ELIMINATE_FIRM_MUTATION)

	const { openSnackbar } = useSnackbar()
	const eliminateOtherFirms = async () => {
		await eliminateFirm({
			variables: {
				payload: {
					requestId,
					orgIds: [firmId],
					action: eliminateActionTypes.ELIMINATE_ALL_EXCEPT,
					sendFirmEmails: true,
				},
			},
		})
		openSnackbar('Remaining firms marked as unsuccessful')
	}

	const selectProposal = async () => {
		await acceptResponse({
			variables: { responseId: proposalId, notificationOption },
		})
		openSnackbar(`Selected ${firmName}`)
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Select firm',
				[stepIndex(defaultSteps, 'ACTIONS')]: notificationOptionText.get(notificationOption),
			}}
			isWorking={loadingAcceptMutation || loadingEliminateMutation}
			onConfirm={async () => {
				if (
					notificationOption === notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT
				) {
					// SM: Wrapping these 2 mutations in a single promise seems to prevent the page from reloading
					// Needed for the proposal feedback dialog to launch
					await Promise.all([selectProposal(), eliminateOtherFirms()])
					trackWithPendo('manage_proposal::mark_firm_successful_eliminate_others', {
						firmId,
						proposalId,
						notificationOption,
					})
					onDone()

					await fetchResponses() // Reload value from resolver

					if (openProposalFeedbackFormAll) {
						openProposalFeedbackFormAll()
					}
				} else {
					//Select single proposal
					await selectProposal()
					trackWithPendo('manage_proposal::mark_firm_successful', {
						firmId,
						proposalId,
						notificationOption,
					})

					onDone()

					await fetchResponses() // Reload value from resolver

					if (openProposalFeedbackFormSingleFirm) {
						openProposalFeedbackFormSingleFirm()
					}
				}
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={errorAcceptMutation || errorEliminateMutation} />
			{notificationOption === notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT && (
				<SummaryList
					primaryText={<strong>Select firm and send notifications</strong>}
					bullets={[
						'Selected firm will be notified.',
						'Remaining firms will be marked as unsuccessful and notified of this.',
						'You will be unable to select additional firms.',
						'You will be able to provide feedback to your firm(s) after confirming',
					]}
					icon={null}
					dataTestid="notify-other-firms-confirm"
				/>
			)}
			{notificationOption === notificationOptions.SUCCESSFUL_FIRM_AND_CLIENT && (
				<SummaryList
					primaryText={<strong>Select firm and send notification</strong>}
					bullets={[
						'Remaining firms will not be notified.',
						'You will still be able to select additional firms.',
						'You will be able to provide feedback to your firm after confirming',
					]}
					icon={null}
					dataTestid="dont-notify-other-firms-confirm"
				/>
			)}
			{notificationOption === notificationOptions.SEND_TO_NONE && (
				<SummaryList
					primaryText={<strong>Select firm without sending notifications</strong>}
					bullets={[
						'Selected firm will not be notified. The firm’s dashboard will have an indication that the proposal has been selected.',
						'Remaining firm(s) will not be notified.',
						'You will still be able to select additional firms.',
						'You will be able to provide feedback to your firm after confirming',
					]}
					icon={null}
					dataTestid="dont-notify-confirm"
				/>
			)}
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionSelectFirmConfirm
