import { gql } from '@persuit/ui-graphql'

const rfpRemoved = gql`
	subscription rfpRemoved {
		rfpRemoved {
			_id
		}
	}
`

export default rfpRemoved
