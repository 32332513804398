// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { grey } from '@mui/material/colors'
import { isNil, map } from 'lodash/fp'

import { PercentageText, Typography, styled, Spacer } from '@persuit/ui-components'
import constants from '../../../../common/data/pricing-models'
import { getOrEmptyArray, getOrNull } from '../../../utils/lenses'
import formatPriceCurrency from '../../../utils/price-and-currency'
import {
	arePricesEqual,
	areValuesEqual,
} from '../../rfp-request-proposal-revision/suggestion-change-detection'
import RfprItem from './rfpr-item'
import SubItemsListItems from './sub-items-list-items'
import { isPercentagePricingModel, formatDuration } from '../common/utilities'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 50%;
`

const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	gap: 8px;
	width: 50% !important;
`

const StyledTotalItem = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding-top: ${({ hasNoRates }) => {
		if (hasNoRates) return '8px'
	}};
	border-spacing: 10px;
`

const StyledSubItemWrapper = styled('div')`
	border-bottom: ${({ hasItems }) => {
		if (hasItems) return `1px solid ${grey[500]}`
	}};
`

const StyledMiniCellTotalFee = styled('div')`
	width: 194px;
	white-space: nowrap;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`
const StyledMiniCellTotalModel = styled('div')`
	width: 150px;
`
const StyledMiniCellTotalHrs = styled('div')`
	width: 100px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`

/*

Used for subitems where there are responses (except rate card)

Looks like this:

item 1______________________$10,000________Fixed Fee_____30 Hrs
item 2______________________$20,000________Capped Fee____40 Hrs
Item Total                  $30,000        Fixed Fee     70 Hrs


*/

const isSame = (varA, varB) => {
	if (!varA || !varB) {
		return false
	}

	return varA.toString() === varB.toString()
}

const checkIfSuggestionsPresent = (rfprPrice, rfprPricingModel, rfprHours, rfprPercentage) => {
	if (!isNil(rfprPrice)) {
		return true
	}

	if (!isNil(rfprPricingModel)) {
		return true
	}

	if (!isNil(rfprHours)) {
		return true
	}

	if (!isNil(rfprPercentage)) {
		return true
	}

	return false
}

const SubItemsList = ({
	phaseIndex,
	responseToDeliverable,
	currency,
	showTotal,
	t,
	phaseDuration,
	rfpr,
}) => {
	const firmSuppliedRates =
		responseToDeliverable && responseToDeliverable.firmSuppliedRates
			? responseToDeliverable.firmSuppliedRates
			: []
	const responseToRates = getOrEmptyArray('responseToRates', responseToDeliverable) || []
	const mappedFirmSuppliedRates = map(
		(fsr) => ({
			...fsr,
			firmSupplied: true,
			rate: fsr.pricingModel !== constants.INCLUDEDINITEMTOTAL ? fsr.rate : null,
		}),
		firmSuppliedRates,
	)
	const combinedRates = {
		total: {
			price:
				// show price except for "Included in total"
				responseToDeliverable.pricingModel !== constants.INCLUDEDINTOTAL
					? responseToDeliverable.price
					: null,
			hours: responseToDeliverable.hours,
			percentage: responseToDeliverable.percentage,
			pricingModel: responseToDeliverable.pricingModel,
		},
		rates: [...responseToRates, ...mappedFirmSuppliedRates],
	}

	const rfprPrice = getOrNull('rfprPrice', rfpr)
	const rfprPercentage = getOrNull('rfprPercentage', rfpr)
	const rfprPricingModel = getOrNull('rfprPricingModel', rfpr)
	const rfprHours = getOrNull('rfprHours', rfpr)

	const totalPrice = getOrNull('total.price', combinedRates)
	const totalPercentage = getOrNull('total.percentage', combinedRates)
	const totalPricingModel = getOrNull('total.pricingModel', combinedRates)
	const totalHours = getOrNull('total.hours', combinedRates)

	const samePrice = arePricesEqual(totalPrice, rfprPrice)
	const samePercentageSame = areValuesEqual(totalPercentage, rfprPercentage)
	const samePricingModel = rfprPricingModel ? isSame(rfprPricingModel, totalPricingModel) : true
	const sameHours = rfprHours ? isSame(rfprHours, totalHours) : true

	// 0 is not the same as null
	const hasRfpr = checkIfSuggestionsPresent(rfprPrice, rfprPricingModel, rfprHours, rfprPercentage)
	const hasSubItems = combinedRates && combinedRates.rates.length > 0

	const isTotalPercentage = isPercentagePricingModel(totalPricingModel)
	const formatTotalPrice = totalPrice ? formatPriceCurrency(currency, totalPrice) : '-'
	const formatTotalDuration = formatDuration(totalHours, phaseDuration)

	return (
		<div data-testid="sub-items-list">
			<StyledSubItemWrapper hasItems={hasSubItems} role="list" aria-label="Sub-items">
				{hasSubItems && <Spacer space={4} />}
				<SubItemsListItems
					combinedRates={combinedRates}
					phaseIndex={phaseIndex}
					phaseDuration={phaseDuration}
					currency={currency}
				/>
				{/* total */}
				{showTotal && (
					<StyledTotalItem
						hasNoRates={combinedRates && combinedRates.rates.length === 0}
						role="listitem"
					>
						<StyledItemLeft>
							{combinedRates && combinedRates.rates.length > 0 && (
								<Typography variant="body1" weight="medium">
									Subtotal
								</Typography>
							)}
						</StyledItemLeft>
						<StyledItemRight>
							<StyledMiniCellTotalFee>
								{isTotalPercentage ? (
									<Typography
										strikeThrough={!samePercentageSame}
										data-testid="item-subtotal-percentage"
									>
										<PercentageText value={totalPercentage} />
									</Typography>
								) : (
									<Typography strikeThrough={!samePrice} data-testid="item-subtotal-rate">
										{formatTotalPrice}
									</Typography>
								)}
							</StyledMiniCellTotalFee>
							<StyledMiniCellTotalModel>
								<Typography strikeThrough={!samePricingModel} data-testid="item-subtotal-model">
									{totalPricingModel
										? t(`feeBreakdown.pricingModels.firmCard.${totalPricingModel}`)
										: '-'}
								</Typography>
							</StyledMiniCellTotalModel>
							<StyledMiniCellTotalHrs>
								<Typography strikeThrough={!sameHours} data-testid="item-subtotal-hours">
									{!isTotalPercentage && formatTotalDuration}
								</Typography>
							</StyledMiniCellTotalHrs>
						</StyledItemRight>
					</StyledTotalItem>
				)}
			</StyledSubItemWrapper>

			{hasRfpr && (
				<RfprItem
					rfprPricingModel={rfprPricingModel}
					rfprPrice={rfprPrice}
					rfprPercentage={rfprPercentage}
					rfprHours={rfprHours}
					originalPrice={totalPrice}
					originalPercentage={totalPercentage}
					originalPricingModel={totalPricingModel}
					originalHours={totalHours}
					phaseDuration={phaseDuration}
					currency={currency}
				/>
			)}
		</div>
	)
}

SubItemsList.defaultProps = {
	hasBoxShadow: false,
	showTotal: false,
}
SubItemsList.displayName = 'SubItemsList'
export default SubItemsList
