import has from 'lodash/fp/has'
import map from 'lodash/fp/map'
import proposalStates from '../../../../common/data/rfp-proposal-states'
import rfpStates from '../../../../common/data/rfp-states'
import { getOrEmptyString, getOrFalse } from '../../../utils/lenses'
import getReopenedStatusForInvite from '../../rfp/get-reopened-status-for-invite'
import { disableSelectProposalButton } from '../../../rules'

export const checkOtherFirmsEliminated = ({ invites = [], firmIds = [] }) => {
	const getFirmId = getOrEmptyString('item._id')
	const getFirmEliminatedStatus = getOrFalse('state.eliminate.eliminated')

	return invites
		.filter((invite) => !firmIds.includes(getFirmId(invite)))
		.every((invite) => getFirmEliminatedStatus(invite) === true)
}

export const getCurrentAndAcceptedFirms = (proposals = [], firmId = '') => {
	const accepted = proposals.filter((proposal) => proposal.status === proposalStates.ACCEPTED)
	return [firmId, ...map('org._id', accepted)]
}

export const firmHasAnyAcceptedProposals = ({ firmId, proposals }) => {
	const firmProposals = proposals.filter(({ org }) => org === firmId)
	return firmProposals.some(({ status }) => status === proposalStates.ACCEPTED)
}

export const getWizardProps = ({ proposal = {}, rfp = {}, proposals = [] }) => {
	const {
		_id: proposalId,
		org: { name: firmName, _id: firmId, isNamwolfMember },
		totalPriceValue,
		totalPriceModel,
		status,
		isPendingPrice,
	} = proposal

	const {
		_id: requestId,
		useCase,
		status: requestStatus,
		invites,
		detail: { currency },
		auction,
		proposalsDueBy,
		auctionEnd,
	} = rfp

	const isRfpReopenedForFirm = getReopenedStatusForInvite(rfp, firmId)

	const invite = invites.find(({ item }) => item._id === firmId)
	const isFirmEliminated = getOrFalse('state.eliminate.eliminated', invite)

	const currentAndAcceptedFirms = getCurrentAndAcceptedFirms(proposals, firmId)
	const disableMarkRemainingFirmsUnsuccessfulRadio = checkOtherFirmsEliminated({
		invites,
		firmIds: currentAndAcceptedFirms,
	})

	// enable the accept option only for finalized proposals
	// disable the accept option for rfi->rfp proposals not updated with a total price
	const disableSelectFirmRadio = disableSelectProposalButton(proposal, isFirmEliminated)
	const disableMarkFirmUnsuccessfulRadio = firmHasAnyAcceptedProposals({
		firmId: proposal.org,
		proposals,
	})

	const clientOrgId = getOrEmptyString('org._id', rfp)
	const revisionCommentOrgId = getOrEmptyString('draftRevisionComment.org._id', proposal)
	const isRevisionCommentFromCurrentOrg =
		has('draftRevisionComment._id', proposal) && clientOrgId === revisionCommentOrgId

	const rfprExists = proposal.hasRevisionRequest || isRevisionCommentFromCurrentOrg

	// The create RFPR option needs be disabled if:
	// * There is an existing RFPR (clients are only allowed one at a time)
	// OR
	// * The RFP is in the COMPLETED state
	// OR
	// * Proposal is selected
	const disableRequestProposalRevsionRadio =
		isFirmEliminated ||
		rfprExists ||
		requestStatus === rfpStates.COMPLETED ||
		status === proposalStates.ACCEPTED

	return {
		requestId,
		currency,
		useCase,
		proposalId,
		proposalStatus: status,
		requestStatus,
		firmId,
		firmName,
		invite,
		isNamwolfMember,
		isRfpReopenedForFirm,
		totalPriceValue,
		totalPriceModel,
		disableRequestProposalRevsionRadio,
		disableMarkRemainingFirmsUnsuccessfulRadio,
		disableSelectFirmRadio,
		disableMarkFirmUnsuccessfulRadio,
		isPendingPrice,
		auction,
		proposalsDueBy,
		auctionEnd,
	}
}
