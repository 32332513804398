import { Box } from '../box'
import { Typography } from '../typography'

export type StatusTextProps = {
	text: string
	color?: string
	bgcolor?: string
}

export const StatusText = ({ text, color, bgcolor }: StatusTextProps) => {
	return (
		<Box color={color} bgcolor={bgcolor} paddingX="0.5rem" width="auto" borderRadius="5px">
			<Typography variant="caption">
				<b>{text.toUpperCase()}</b>
			</Typography>
		</Box>
	)
}

StatusText.displayName = 'StatusText'
