import { Deliverable, PricingGroup } from '../types'
import { createRHFUtils } from '@persuit/ui-components'

export type PricingFormValues = {
	currency?: string | null
	priceDescription?: string | null
	totalPriceModel?: string | null
	totalPriceRequired?: boolean | null
	isFirmAllowedToChangeTotalPriceModel: boolean
	pricingItems: (Partial<Deliverable> | Partial<PricingGroup>)[]
}

const { useFieldArray, useWatch, useFormContext } = createRHFUtils<PricingFormValues>()

export const usePricingItemsFieldArray = () =>
	useFieldArray({
		name: 'pricingItems',
	})

export { useFieldArray, useWatch, useFormContext }
