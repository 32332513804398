import { useEffect } from 'react'
import { useHasChanged } from './use-has-changed'

export type UseWhenChangedProps = {
	/** The value to monitor */
	value: unknown

	/**
	 * Whether the first render of the component is considered a change.
	 * Defaults to true
	 */
	notifyOnFirstRender?: boolean

	/**
	 * The function to call when the value has changed
	 */
	callback: () => void
}

/**
 * Calls callback whenever the value changes
 */
export function useWhenChanged({ value, notifyOnFirstRender, callback }: UseWhenChangedProps) {
	const hasChanged = useHasChanged({
		value,
		initialReturnValue: notifyOnFirstRender ?? true,
	})

	useEffect(() => {
		if (hasChanged) {
			callback()
		}
	}, [callback, hasChanged])
}
