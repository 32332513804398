import { useStore } from '../../store'

import { copyToClipboard } from '../../utils'
import { PlaceholderLoading } from '../../intelligence/placeholder-loading'
import {
	shortenArrForSummary,
	formatExtendedSummaryForClipboard,
} from '../../intelligence/intelligence-column-utils'
import { useDeliverableIntelligence } from '../../intelligence/intelligence-hook'

import { ExpandableIntelligenceCell, ExpandableIntelligenceCellProps } from '../components'

import { Error, formatExtendedSummary, getMaxSummaryLength } from '../../intelligence/components'

type PricingIntelligenceCellProps = Pick<
	ExpandableIntelligenceCellProps,
	'cellProps' | 'sx' | 'headers'
> & {
	deliverable: any
}

export const PricingIntelligenceCell = ({ deliverable, ...rest }: PricingIntelligenceCellProps) => {
	const { isWide, request } = useStore((state) => ({
		isWide: state.isWideIntelligence,
		request: state.request,
	}))

	const intelligence = useDeliverableIntelligence({
		rfpId: request._id,
		deliverableId: deliverable._id,
	})

	const summary = intelligence?.intelligence ?? null
	const loading = intelligence?.loading ?? true
	const error = intelligence?.error ?? false

	const maxSummaryLength = getMaxSummaryLength(isWide)

	return (
		<ExpandableIntelligenceCell
			{...rest}
			expandedContent={
				<>
					{loading && <PlaceholderLoading />}
					{error && <Error code={error} />}
					{!error && !loading && summary && formatExtendedSummary(summary)}
				</>
			}
			expandedTitle={deliverable.deliverableTitle}
			copyToClipboard={async () => {
				return await copyToClipboard(
					formatExtendedSummaryForClipboard(deliverable.deliverableTitle, summary),
				)
			}}
			copyToClipboardDisabled={!!error || loading}
			data-trackId={deliverable.deliverableTitle}
		>
			{loading && <PlaceholderLoading />}
			{error && <Error code={error} />}
			{!error && !loading && formatExtendedSummary(shortenArrForSummary(summary, maxSummaryLength))}
		</ExpandableIntelligenceCell>
	)
}
