import { useRef } from 'react'
import {
	Box,
	Typography,
	SROnly,
	CloseIcon,
	IconButton,
	ScreenOnly,
	EditIcon,
	Spacer,
} from '@persuit/ui-components'
import { useDispatch, useSelector } from '@client/store'
import { formValueSelector } from 'redux-form'
import proposalScoreActions from '../../../../../actions/proposal-scores'
import { calculateTotalWeightedScore } from '../../../../../utils/scorecard'
import { RfpClient_RfpDeprecatedFragment } from '@persuit/ui-graphql/generated'
import proposalStates from '../../../../../../common/data/rfp-proposal-states'
import { ToggleShowSubcategories } from '../components/toggle-show-subcategories'

type ScoreCardCellHeaderProps = {
	scoreCategories: RfpClient_RfpDeprecatedFragment['scoreCategories']
	responseId: string
	proposalStatus: string
}

export const ScoreCardCellHeader = ({
	scoreCategories,
	responseId,
	proposalStatus,
}: ScoreCardCellHeaderProps) => {
	const dispatch = useDispatch()
	const { formValues, editMode, otherProposalsScoreBeingEdited } = useSelector((state) => ({
		formValues: formValueSelector('proposalScoresForm')(state, 'scores'),
		editMode: state.proposalScores.idOfProposalBeingEdited === responseId,
		otherProposalsScoreBeingEdited: state.proposalScores.proposalScoreBeingEdited,
	}))

	const totalWeightedScore = calculateTotalWeightedScore(formValues, scoreCategories) || 'N/A'
	const hasScoreCategories = scoreCategories && scoreCategories.length > 0
	const proposalWithdrawn = proposalStatus === proposalStates.WITHDRAWN
	const scoreCategoriesId = `${responseId}-categories`
	const openFormBtnRef = useRef<HTMLButtonElement>(null)
	const closeFormBtnRef = useRef<HTMLButtonElement>(null)

	return (
		<Box minWidth={'280px'}>
			{editMode ? (
				<Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							height: '73px',
						}}
					>
						<Typography fontSize="24px" data-testid="scorecard-total-weighted-score">
							<SROnly>Overall score: </SROnly>
							{totalWeightedScore}
						</Typography>

						<IconButton
							onClick={() => {
								dispatch(proposalScoreActions.closeProposalScoreForm())
								setTimeout(() => {
									openFormBtnRef.current?.focus()
								}, 100)
							}}
							size="small"
							color="primary"
							aria-label="Close scorecard form"
							ref={closeFormBtnRef}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			) : hasScoreCategories && !otherProposalsScoreBeingEdited && !proposalWithdrawn ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						position: 'relative',
						height: '38px',
					}}
				>
					<ScreenOnly>
						<IconButton
							onClick={() => {
								dispatch(proposalScoreActions.openProposalScoreForm(responseId))
								dispatch(proposalScoreActions.openProposalScoreSubcategories())
								setTimeout(() => {
									closeFormBtnRef.current?.focus()
								}, 100)
							}}
							aria-label="Edit scores"
							title="Edit scores"
							size="small"
							ref={openFormBtnRef}
						>
							<EditIcon color="primary" />
						</IconButton>
					</ScreenOnly>
					<ToggleShowSubcategories controlId={scoreCategoriesId} />
				</Box>
			) : (
				<Spacer space={4.8} />
			)}
		</Box>
	)
}
