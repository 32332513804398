// eslint-disable-next-line no-restricted-imports -- Please upgrade
import FormControlLabel from '@mui/material/FormControlLabel'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Switch from '@mui/material/Switch'

const SwitchWithLabel = ({ label, checked, onChange, disabled = false }) => (
	<FormControlLabel
		label={label}
		control={<Switch color="primary" />}
		checked={checked}
		onChange={(e) => onChange(e.target.checked)}
		disabled={disabled}
	/>
)

SwitchWithLabel.displayName = 'SwitchWithLabel'

export default SwitchWithLabel
