import { ReactNode } from 'react'
import {
	Box,
	useSnackbar,
	IconButton,
	DeleteIcon,
	Typography,
	useConfirmDialog,
} from '@persuit/ui-components'
import { refocusOnRemoval } from '../../../pricing-form-utils'
import { usePricingSubItemListContext } from '../../pricing-sub-item-list-context'

type PricingSubItemContentProps = {
	dragHandler?: ReactNode
	subItemIndex: number
	subItemNumber?: ReactNode
	subItemTitle?: ReactNode
	subItemPricingModel?: ReactNode
	onRemove?: () => void
	isRateCard?: boolean
}

export const PricingSubItemContent = ({
	dragHandler,
	subItemIndex,
	subItemNumber,
	subItemTitle,
	subItemPricingModel,
	onRemove,
	isRateCard,
}: PricingSubItemContentProps) => {
	const { openSnackbar } = useSnackbar()
	const { openConfirmDialog } = useConfirmDialog()
	const { pricingItemIndex } = usePricingSubItemListContext()

	return (
		<Box
			display="flex"
			p="10px 0"
			alignItems="center"
			position="relative"
			data-testid="sub-item-card"
			aria-label="Sub-item of "
		>
			{/* TODO: enable sorting when we have a back end solution to adding sub items */}
			{dragHandler && <Box alignSelf="flex-end">{dragHandler}</Box>}
			{subItemNumber && (
				<Box alignSelf="flex-end" data-testid="numbering">
					{subItemNumber}
				</Box>
			)}
			{subItemTitle}
			{subItemPricingModel && (
				<Box
					p="0 10px"
					width="30%"
					display="flex"
					alignItems="flex-end"
					data-testid="sub-item-pricing-model"
				>
					{subItemPricingModel}
				</Box>
			)}
			{onRemove && (
				<IconButton
					color="primary"
					onClick={(e) => {
						e.stopPropagation() // prevent propagation onClick the icon button
						openConfirmDialog({
							size: 'sm',
							title: isRateCard ? 'Delete entry' : 'Delete sub-item',
							content: (
								<>
									<Typography variant="body1" mb={0.75}>
										{isRateCard
											? 'Are you sure you want to delete this rate card entry?'
											: 'Are you sure you want to delete this sub-item?'}
									</Typography>
									<Typography variant="body2">You will not be able to view it again</Typography>
								</>
							),
							actions: [
								{ label: 'Cancel', type: 'secondary', close: true },
								{
									label: 'Delete',
									type: 'primary',
									action: () => {
										refocusOnRemoval({
											currentIndex: subItemIndex,
											isSubItem: true,
											subItemParentIndex: pricingItemIndex,
										})
										onRemove()
										openSnackbar(
											isRateCard ? 'Rate item has been deleted.' : 'Sub-item has been deleted.',
										)
									},
								},
							],
						})
					}}
					aria-label={`Delete sub-item ${subItemIndex + 1}`}
					data-testid="delete-sub-item-icon"
					size="large"
				>
					<DeleteIcon />
				</IconButton>
			)}
		</Box>
	)
}

PricingSubItemContent.displayName = 'PricingSubItemContent'
