export const shortenArrForSummary = (data, maxLength) => {
	const summaryLength = data?.summary?.length ?? 0
	if (summaryLength >= maxLength) {
		return {
			summary: `${data.summary.substring(0, maxLength)}...`,
		}
	}
	let currentLength = summaryLength

	if (data.firms) {
		const result = data.firms.reduce(
			(acc, firm) => {
				const pointsArray = (firm?.points ?? []).reduce((acc, item) => {
					// If we've hit our char limit, don't bother adding anything more to the summary
					if (currentLength >= maxLength) {
						return acc
					}
					// If we're about to hit our char limit, trim the string down and add an ellipses
					if (currentLength + item.length >= maxLength) {
						const shortened = item.substring(0, currentLength + item.length - maxLength)

						currentLength = currentLength + item.length
						return [...acc, `${shortened}...`]
					}
					// Otherwise just return the full string
					currentLength = currentLength + item.length

					return [...acc, item]
				}, [])

				if (pointsArray.length > 0) {
					acc.firms.push({ firmName: firm.firmName, points: pointsArray })
					return acc
				}

				return acc
			},
			{ firms: [] },
		)

		return {
			summary: data.summary ? data.summary : undefined,
			...result,
		}
	}

	return {
		summary: data.summary ? data.summary : undefined,
	}
}

export const formatExtendedSummaryForClipboard = (title, data) => {
	let copyText = [title].concat([''])
	if (data.summary) {
		copyText = copyText.concat(data.summary)
		copyText = copyText.concat([''])
	}

	if (data.firms) {
		data.firms.forEach((firm) => {
			if (firm.firmName) {
				copyText = copyText.concat(firm.firmName)
			}

			if (firm.points) {
				copyText = copyText.concat(firm.points.map((point) => `- ${point}`))
			}

			copyText = copyText.concat([''])
		})
	}

	return copyText.join('\n')
}

export const getTrackId = (value) => {
	if (!value || typeof value !== 'string') {
		return ''
	}
	const trimmedValue = value.trim()
	const valueToLower = trimmedValue.toLowerCase()
	const withoutSpecialChars = valueToLower.replace(/[^\w\s]|_/g, '')
	const hyphenatedLower = withoutSpecialChars.replace(/\s+/g, '-')

	return hyphenatedLower
}
