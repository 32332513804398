import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Chip,
	Divider,
	List,
	ListItem,
	Typography,
	useTheme,
} from '@persuit/ui-components'
import { FirmListFirm } from './firm-list-firm'
import { FirmUser, SelectedFirmList } from '../types'

export type FirmListCardProps = SelectedFirmList & {
	expanded: boolean
	handleAccordionChange: (isExpanded: boolean) => void
	contacts: FirmUser[]
	favourites: string[]
}

type ListTypeLabelProps = { label: string; showCondition: boolean }

const ListTypeLabel = ({ label, showCondition }: ListTypeLabelProps) => {
	const theme = useTheme()
	if (!showCondition) return null

	return (
		<Chip
			label={label}
			sx={{
				color: theme.palette.primary.main,
				background: theme.palette.primary.lighterHue,
				mr: 1,
			}}
		/>
	)
}

type ListTypesProps = {
	approved: boolean
	isPanel: boolean
}

const ListTypes = ({ approved, isPanel }: ListTypesProps) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
			<ListTypeLabel label="Approved" showCondition={approved} />
			<ListTypeLabel label="Panel" showCondition={isPanel} />
		</Box>
	)
}

export const FirmListCard = ({
	name,
	isPanel,
	approved,
	description,
	instructions,
	firms,
	expanded,
	handleAccordionChange,
	favourites,
	contacts,
}: FirmListCardProps) => {
	const theme = useTheme()

	return (
		<Accordion
			onChange={handleAccordionChange}
			expanded={expanded}
			elevation={0}
			sx={{
				mt: 3,
				p: 1,
				width: '100%',
				border: `1px solid ${theme.palette.action.focus}`,
			}}
		>
			<Box
				sx={{
					p: 2,
					display: 'grid',
					gridTemplateColumns: '1fr auto',
					minWidth: 0,
				}}
			>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: '3fr 1fr 1fr',
						gap: 1,
					}}
				>
					<Box>
						<Typography fontWeight="medium" mb={1}>
							{name}
						</Typography>

						<Typography>{description}</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							flex: 1,
							minWidth: 0,
						}}
					>
						<ListTypes approved={approved} isPanel={isPanel} />
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							flex: 1,
							minWidth: 0,
							gap: 0.5,
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
							<Typography variant="body1">{firms.length} firms</Typography>
						</Box>
					</Box>
				</Box>
				<AccordionSummary
					iconButtonLabel={(expanded) => `${expanded ? `Collapse` : `Expand`} ${name}`}
				/>
			</Box>
			<AccordionDetails>
				<Divider />
				{instructions && (
					<Box mb={1}>
						<Accordion elevation={0} data-trackid={`${name}-firm-list-instructions`}>
							<AccordionSummary aria-label={`${name}-instructions`} id={`${name}-instructions`}>
								<Typography color="primary" fontWeight="medium">
									INSTRUCTIONS
								</Typography>
							</AccordionSummary>
							<AccordionDetails>{instructions}</AccordionDetails>
						</Accordion>
						<Divider />
					</Box>
				)}
				{firms.length > 0 && (
					<List aria-label="Firms list" sx={{ p: 0, m: 0 }}>
						{firms.map(({ org }) => (
							<ListItem key={org._id} sx={{ p: 0, m: 0 }}>
								<FirmListFirm
									firmName={org.name ?? ''}
									firmId={org._id ?? ''}
									contacts={contacts}
									favourites={favourites}
									isNamwolfMember={org.isNamwolfMember}
								/>
							</ListItem>
						))}
					</List>
				)}
			</AccordionDetails>
		</Accordion>
	)
}
