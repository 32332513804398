// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Divider from '@mui/material/Divider'

import { useState } from 'react'
import { DialogWizard } from '../dialog-wizard'
import DialogWizardContent from '../dialog-wizard-content'
import DialogWizardEnableStepIf from '../dialog-wizard-enable-step-if'
import { defaultSteps } from '../dialog-wizard-steps'
import { OptionReviseProposalConfirm, OptionReviseProposalStart } from './option-revise-proposal'
import {
	OptionQuickPriceRevisionStart,
	OptionQuickPriceRevisionContainer,
	OptionQuickPriceRevisionSteps,
} from './option-quick-price-revision'
import { getWizardProps } from './utils'
import { WIZARD_STEPS } from './steps'
import { WIZARD_STEPS as QUICK_PRICE_REVISION_STEPS } from './option-quick-price-revision/steps'

function ReviseProposalWizard({
	proposalId,
	hasRevisionRequest,
	pricingModelNotSelected,
	pricingModelNotApplicable,
	handleClose,
	navigateToPage,
	useCase,
}) {
	const [wizardStep, setWizardStep] = useState(WIZARD_STEPS.START)

	const width = [
		QUICK_PRICE_REVISION_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES,
		QUICK_PRICE_REVISION_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_RATES,
		QUICK_PRICE_REVISION_STEPS.OPTION_QUICK_PRICE_REVISION_CONFIRM,
	].includes(wizardStep)
		? 'lg'
		: undefined

	return (
		<DialogWizard wizardName="Revise Proposal" width={width}>
			{/* Step 1 */}
			<DialogWizardEnableStepIf condition={wizardStep === WIZARD_STEPS.START}>
				<DialogWizardContent steps={defaultSteps} activeStep={'START'} onCancel={handleClose}>
					<Divider />
					{useCase === 'rateReview' ? null : (
						<OptionQuickPriceRevisionStart
							setStep={setWizardStep}
							hasRevisionRequest={hasRevisionRequest}
							pricingModelNotSelected={pricingModelNotSelected}
							pricingModelNotApplicable={pricingModelNotApplicable}
						/>
					)}

					<OptionReviseProposalStart
						onNext={() => setWizardStep(WIZARD_STEPS.OPTION_REVISE_PROPOSAL_CONFIRM)}
					/>
				</DialogWizardContent>
			</DialogWizardEnableStepIf>

			{/* All steps of option quick price revision */}
			<OptionQuickPriceRevisionSteps
				currentStep={wizardStep}
				setStep={setWizardStep}
				handleClose={handleClose}
				navigateToPage={navigateToPage}
			/>

			{/* Step 3 - Revise proposal */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_REVISE_PROPOSAL_CONFIRM}
			>
				<OptionReviseProposalConfirm
					onPrev={() => setWizardStep(WIZARD_STEPS.START)}
					onDone={handleClose}
					onCancel={handleClose}
					proposalId={proposalId}
					navigateToPage={navigateToPage}
				/>
			</DialogWizardEnableStepIf>
		</DialogWizard>
	)
}

const ReviseProposalWizardWrapper = ({
	handleClose,
	currency,
	navigateToPage,
	isRequestTotalPriceRequired,
	getWizardProps,
	proposal,
	request,
}) => {
	const wizardProps = getWizardProps(proposal, isRequestTotalPriceRequired, request)
	const { useCase } = request

	return (
		<OptionQuickPriceRevisionContainer
			deliverables={request?.detail?.deliverablesV2 ?? []}
			proposal={proposal}
			currency={currency}
			totalPriceNotRequired={wizardProps.totalPriceNotRequired}
		>
			<ReviseProposalWizard
				{...wizardProps}
				handleClose={handleClose}
				currency={currency}
				navigateToPage={navigateToPage}
				useCase={useCase}
			/>
		</OptionQuickPriceRevisionContainer>
	)
}

ReviseProposalWizardWrapper.defaultProps = {
	getWizardProps,
	handleClose: () => {},
	navigateToPage: () => {},
}

export default ReviseProposalWizardWrapper
