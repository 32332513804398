import * as React from 'react'

export const PersuitLogoSmall = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		x="0px"
		y="0px"
		width="30px"
		height="20px"
		viewBox="0 0 20 25"
		aria-label="PERSUIT Logo"
		id="persuit-logo"
		{...props}
	>
		<path
			fill="currentColor"
			d="M9.097,0.921H0V12.66l9.022-0.005c2.723,0,4.478-1.659,4.478-4.225c0-2.632-1.713-4.271-4.478-4.271H3.749 v5.629H4.85V5.264h4.173c2.146,0,3.381,1.151,3.381,3.167c0,1.983-1.234,3.131-3.381,3.131l-7.926,0.004V2.018h8 c4.458,0,7.018,2.339,7.018,6.413c0,4.047-2.56,6.372-7.018,6.372H3.749v8.255H1.097v-8.611H0v9.711h4.85v-8.255h4.247 c5.083,0,8.12-2.796,8.12-7.472C17.217,3.727,14.18,0.921,9.097,0.921"
		/>
	</svg>
)
