import { ApolloError, useQuery } from '@apollo/client'
import getSessionInfoQuery from '../../graphql/queries/getSessionInfo-gql'

export function useUserRoles(): {
	roles: readonly string[]
	loading: boolean
	error?: ApolloError
} {
	const { data, loading, error } = useQuery(getSessionInfoQuery, { fetchPolicy: 'cache-first' })

	return {
		loading,
		error,
		roles: data?.getSessionInfo.user.roles,
	}
}
