import cond from 'lodash/fp/cond'
import constant from 'lodash/fp/constant'
import matches from 'lodash/fp/matches'
import stubTrue from 'lodash/fp/stubTrue'
import getOr from 'lodash/fp/getOr'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import isDate from 'date-fns/isDate'
import i18next from 'i18next'
import DueDateTicker from '../../due-date-ticker'
import ClientStatusTypes from '../client-status-types'

const isDateInFuture = (lens) => (dates) => {
	const date = lens(dates)
	if (!isDate(date)) {
		return ''
	}

	return isFuture(date)
}

const isAuctionInProgress = ({ auctionStart, auctionEnd }) =>
	isPast(auctionStart) && isFuture(auctionEnd)

const getTertiaryText = ({
	declinedCount,
	totalInviteCount,
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	lastBid,
	totalAcceptedProposalCount,
	totalSubmittedProposalCount,
	isRequestClosed,
	isRequestCompleted,
}) => {
	if (declinedCount === totalInviteCount) {
		return 'All firms unable to participate'
	}

	if (isAuctionInProgress({ auctionStart, auctionEnd })) {
		if (!isRequestClosed && !isRequestCompleted) {
			return lastBid
		}
	}

	if (totalAcceptedProposalCount >= 1) {
		return 'Firm selected'
	}

	if (totalSubmittedProposalCount === 0 && isPast(initialProposalsDueBy)) {
		return 'No proposals received'
	}

	return null
}

const getReopenedInviteStatus = (reOpenInvitesCount) =>
	reOpenInvitesCount > 0
		? i18next.t('reopenIndividually.client.statusText', { count: reOpenInvitesCount })
		: null

const getSecondaryText = ({
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	isRequestClosed,
	reOpenInvitesCount,
}) => {
	if (isRequestClosed) {
		return getReopenedInviteStatus(reOpenInvitesCount)
	}

	return cond([
		[
			isDateInFuture(getOr(undefined, 'initialProposalsDueBy')),
			constant(<DueDateTicker dueDate={initialProposalsDueBy} />),
		],
		[
			isDateInFuture(getOr(undefined, 'auctionStart')),
			constant(<DueDateTicker prefix={'Auction starts'} dueDate={auctionStart} />),
		],
		[
			isDateInFuture(getOr(undefined, 'auctionEnd')),
			constant(<DueDateTicker prefix={'Ends'} dueDate={auctionEnd} />),
		],
	])({ initialProposalsDueBy, auctionStart, auctionEnd })
}

const getWithdrawnSecondaryText = ({ totalSubmittedProposalCount }) => {
	return (
		cond([
			[totalSubmittedProposalCount >= 1, constant('Proposals received')],
			[totalSubmittedProposalCount === 0, constant('No proposals received')],
		])({ totalSubmittedProposalCount }) || null
	)
}

const getAuctionProps = ({
	requestStatus,
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	lastBid,
	totalAcceptedProposalCount,
	totalSubmittedProposalCount,
	totalInviteCount,
	declinedCount,
	isRequestClosed,
	isRequestCompleted,
	reOpenInvitesCount,
	updatedAt,
}) =>
	cond([
		[
			matches({ requestStatus: ClientStatusTypes.DRAFT }),
			constant({ status: requestStatus, statusLine2: updatedAt }),
		],
		[
			matches({ requestStatus: ClientStatusTypes.OPEN_TO_PROPOSALS }),
			constant({
				status: ClientStatusTypes.OPEN_TO_PROPOSALS,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
				}),
				statusLine3: getTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
					isRequestCompleted,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.EVALUATING }),
			constant({
				status: ClientStatusTypes.EVALUATING,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
					reOpenInvitesCount,
				}),
				statusLine3: getTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
					isRequestCompleted,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.AWAITING_AUCTION }),
			constant({
				status: ClientStatusTypes.AWAITING_AUCTION,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
					reOpenInvitesCount,
				}),
				statusLine3: getTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
					isRequestCompleted,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.AUCTION_IN_PROGRESS }),
			constant({
				status: ClientStatusTypes.AUCTION_IN_PROGRESS,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
				}),
				statusLine3: getTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
					isRequestCompleted,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.COMPLETED }),
			constant({
				status: ClientStatusTypes.COMPLETED,
				statusLine3: getTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
					isRequestCompleted,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.WITHDRAWN }),
			constant({
				status: ClientStatusTypes.WITHDRAWN,
				statusLine2: getWithdrawnSecondaryText({
					totalSubmittedProposalCount,
				}),
			}),
		],
		[stubTrue, constant({ status: '' })],
	])({
		requestStatus,
	})

export default getAuctionProps
