import responseStates from '../../../../common/data/rfp-proposal-states'
import { formatPriceWithCurrency, isNotNil } from '@persuit/common-utils'
import { TotalPriceOrComparisonValueDataType } from './types'

type Response = {
	status: string
	comparisonValue?: string | null
	originalComparisonValue?: string | null
}

export const comparisonValuePresentaionData = (
	response: Response,
	currency: string,
): TotalPriceOrComparisonValueDataType => {
	const { comparisonValue, originalComparisonValue, status } = response

	const isExample = status === responseStates.EXAMPLE
	const isDraft = status === responseStates.DRAFT
	const shouldShowCurrentValue = isNotNil(comparisonValue)

	const shouldShowOriginalValue =
		shouldShowCurrentValue &&
		isNotNil(originalComparisonValue) &&
		parseFloat(originalComparisonValue) !== parseFloat(comparisonValue) &&
		!isDraft

	const totalValue = () => {
		if (!shouldShowCurrentValue) return ''

		return formatPriceWithCurrency(currency, isExample ? 'XX,000' : comparisonValue)
	}

	const originalValue = () => {
		if (!shouldShowOriginalValue) return ''

		return formatPriceWithCurrency(currency, originalComparisonValue)
	}

	return {
		title: 'Comp. Price:',
		originalValue: originalValue(),
		currentValue: totalValue(),
	}
}
