import { useState, ReactNode } from 'react'
import { createStore } from '@persuit/ui-state'
import { Dialog, DialogProps, DialogSize } from '../dialog'
import { Button, ButtonProps } from '../button'

type OpenConfirmDialogInput = {
	size?: DialogSize
	title: string
	content?: ReactNode
	actions: Array<{
		type?: 'primary' | 'secondary'
		icon?: ReactNode
		label: string
		action?: (args: { close: () => void }) => Promise<void> | void
		close?: boolean
		buttonProps?: ButtonProps
	}>
	dialogProps?: Partial<DialogProps>
}

type State = {
	dialogData: OpenConfirmDialogInput | null
	dialogOpen: boolean
}

const { Provider, useActions, useStore } = createStore(
	{ dialogData: null, dialogOpen: false } as State,
	(set) => ({
		openConfirmDialog: (input: OpenConfirmDialogInput) =>
			set({ dialogData: input, dialogOpen: true }),
		closeConfirmDialog: () => set({ dialogOpen: false }),
	}),
)

export const useConfirmDialog = useActions

type ConfirmDialogProviderProps = {
	children: ReactNode
}

export const ConfirmDialogProvider = ({ children }: ConfirmDialogProviderProps) => {
	return (
		<Provider>
			<ConfirmDialog />
			{children}
		</Provider>
	)
}

const ConfirmDialog = () => {
	const data = useStore((state) => state.dialogData)
	const dialogOpen = useStore((state) => state.dialogOpen)
	const [loading, setLoading] = useState(false)
	const { closeConfirmDialog } = useActions()

	const { size = 'sm', actions, title, content, dialogProps } = data ?? {}

	const dialogActions = (
		<>
			{actions?.map(({ label, icon, type = 'primary', action, close, buttonProps }) => {
				return (
					<Button
						key={label}
						startIcon={icon}
						color="primary"
						variant={type === 'primary' ? 'contained' : 'text'}
						loading={type === 'primary' ? loading : false}
						onClick={async () => {
							if (close) {
								closeConfirmDialog()
							}

							setLoading(true)
							await action?.({ close: closeConfirmDialog })
							setLoading(false)
							closeConfirmDialog()
						}}
						{...buttonProps}
					>
						{label}
					</Button>
				)
			})}
		</>
	)

	return (
		<Dialog
			open={dialogOpen}
			onClose={closeConfirmDialog}
			size={size}
			title={title}
			actions={dialogActions}
			{...dialogProps}
		>
			{content}
		</Dialog>
	)
}
