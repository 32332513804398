export default function validateRichTextLength({ richText, maxLength }) {
	if (!richText) {
		return true
	}

	const contentWithoutCarriageReturn = richText.replace(/<[^>]*>/g, '')
	const carriageReturnsMatched = richText.match(/<br \/>/g, '')
	const carriageReturns = carriageReturnsMatched ? carriageReturnsMatched.length : 0
	//carriage returns count as a character
	const richTextLength = contentWithoutCarriageReturn.length + carriageReturns

	return richTextLength <= maxLength
}
