import { SvgIcon, SvgIconProps } from '../components/svg'

export const OpenLockIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 16 22" {...props}>
			<path
				d="M8 16.5C9.1 16.5 10 15.6 10 14.5C10 13.4 9.1 12.5 8 12.5C6.9 12.5 6 13.4 6 14.5C6 15.6 6.9 16.5 8 16.5ZM14 7.5H13V5.5C13 2.74 10.76 0.5 8 0.5C5.24 0.5 3 2.74 3 5.5H4.9C4.9 3.79 6.29 2.4 8 2.4C9.71 2.4 11.1 3.79 11.1 5.5V7.5H2C0.9 7.5 0 8.4 0 9.5V19.5C0 20.6 0.9 21.5 2 21.5H14C15.1 21.5 16 20.6 16 19.5V9.5C16 8.4 15.1 7.5 14 7.5ZM14 19.5H2V9.5H14V19.5Z"
				fill="#004A76"
			/>
		</SvgIcon>
	)
}
