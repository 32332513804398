import { HistoryIcon } from '@persuit/ui-components'
import { BaseStatus } from '@client/components/base-status'
import { grey700 } from '../../../../client/theme/persuit-colors.js'

type RequestReOpenStatesProps = {
	expanded?: boolean
}

export const RequestReOpenStates = ({ expanded = true }: RequestReOpenStatesProps) => (
	<BaseStatus
		expanded={expanded}
		iconNode={<HistoryIcon sx={{ color: grey700 }} fontSize={expanded ? undefined : 'small'} />}
		primaryText={'Request reopened for this firm'}
		variant={'GREY'}
	/>
)
