import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { DonutSmallIcon, PriceBenchmarkingIcon } from '@persuit/ui-components'
import { ProfileProps } from './my-profile-menu'
import { NavItem, NavBar } from './nav-components'

type FirmNavbarProps = {
	notificationCount: number
	showPerfPageForRealFirmUser: boolean
	profileProps: ProfileProps
}

export const FirmNavbar = ({
	notificationCount,
	showPerfPageForRealFirmUser,
	profileProps,
}: FirmNavbarProps) => {
	const { toggles } = useFeatureToggles()
	return (
		<NavBar notificationCount={notificationCount} profileProps={profileProps}>
			{({ closeDrawer }) => (
				<>
					{/* Show performance link for a firm user with manager role is the current user. Non impersonation mode */}
					{showPerfPageForRealFirmUser && (
						<NavItem to="/en/performance/" onClick={closeDrawer} icon={<DonutSmallIcon />}>
							Performance
						</NavItem>
					)}
					{toggles['dev-10971-should-cost-for-firms'] ? (
						<NavItem
							data-trackid="client-nav-price-benchmarking"
							to={`/en/price-benchmarking/`}
							onClick={closeDrawer}
							icon={
								<PriceBenchmarkingIcon
									style={{ position: 'absolute', top: 0, left: '4px', margin: '12px' }}
								/>
							}
						>
							Price Benchmarking{' '}
						</NavItem>
					) : null}
				</>
			)}
		</NavBar>
	)
}
