import { removeHtmlTags } from '@persuit/ui-utils'

export const calculateRichTextCharacter = (input: string) => {
	if (!input) return 0

	const contentLengthWithoutHTMLTags = removeHtmlTags(input).length
	const carriageReturns = input.match(/<br \/>/g)

	const carriageReturnCount = !carriageReturns ? 0 : carriageReturns.length

	return contentLengthWithoutHTMLTags + carriageReturnCount
}
