import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { UnionToIntersection, SimplifyType, ValueOf, IsAny } from '@persuit/common-utils'

type AnyObject = Record<string, any>

type CleanFragment<T> = Omit<T, ' $fragmentName' | ' $fragmentRefs'>

type FragmentReference = { ' $fragmentRefs'?: unknown }

type FragmentRefs<T extends FragmentReference> = NonNullable<T[' $fragmentRefs']>

// If given a object with a fragment reference, unmask the fragment reference and combine it with the rest of the object. Otherwise do nothing.
type UnmaskNestedFragment<T> = T extends FragmentReference
	? CleanFragment<T> & UnionToIntersection<UnmaskNestedFragment<ValueOf<FragmentRefs<T>>>>
	: T

// Unmask one level of fragment referencing then recursively check the resulting object and unmask any found fragment references.
type Mapper<T> = UnmaskNestedFragment<T> extends infer Z
	? {
			[K in keyof Z]: IsAny<NonNullable<Z[K]>> extends true
				? Z[K]
				: NonNullable<Z[K]> extends AnyObject
				? Mapper<Z[K]>
				: Z[K]
	  }
	: never

// Allows unmasking to handle different wrapper types
type Normalize<T extends FragmentReference | TypedDocumentNode | AnyObject> =
	T extends TypedDocumentNode<infer R> ? R : T

/**
 * Can be used to unmask
 * - Fragment types
 * - typeof fragment values from graphql(`...`)
 * - Query result types which may have fragments
 * - typeof query values from `graphql('...')`
 */
export type UnmaskFragment<T extends TypedDocumentNode | AnyObject> = SimplifyType<
	Mapper<Normalize<T>>
>

export function fragment<T extends FragmentReference>(data: UnmaskFragment<T>): T {
	return data as any
}

type UnmaskTypedDocumentNode<T> = T extends TypedDocumentNode<infer D, infer R>
	? D extends AnyObject
		? TypedDocumentNode<UnmaskFragment<D>, R>
		: never
	: never

export function unmaskNode<T>(documentNode: T): UnmaskTypedDocumentNode<T> {
	return documentNode as any
}

/**
 * The below can be used as a test case for modifying this utility
 */

// type Example = {
// 	' $fragmentRefs'?:
// 		| {
// 				MessagesChannelPaneMessageListChannelFragment: {
// 					type: string
// 					messages: Array<
// 						| ({ id: string; isBot: boolean } & {
// 								' $fragmentRefs'?: {
// 									MessagesChannelPaneHumanMessageFragment: {
// 										messageAt: string
// 										message?: string | null
// 										createdBy?: { name?: { first: string; last: string } | null } | null
// 									}
// 									MessagesChannelPaneSystemMessageFragment: {
// 										messageAt: string
// 										botMessage?: {
// 											translationKey?: string | null
// 											translationReplacements?: any | null
// 										} | null
// 									}
// 								}
// 						  })
// 						| null
// 					>
// 				} & { ' $fragmentName'?: 'MessagesChannelPaneMessageListChannelFragment' }
// 		  }
// 		| undefined
// }

// type Result = UnmaskFragment<Example>

// type Expected = {
// 	type: string
// 	messages: ({
// 		id: string
// 		isBot: boolean
// 		messageAt: string
// 		message?: string | null | undefined
// 		createdBy?:
// 			| {
// 					name?:
// 						| {
// 								first: string
// 								last: string
// 						  }
// 						| null
// 						| undefined
// 			  }
// 			| null
// 			| undefined
// 		botMessage?:
// 			| {
// 					translationKey?: string | null
// 					translationReplacements?: any | null
// 			  }
// 			| null
// 			| undefined
// 	} | null)[]
// 	' $fragmentName'?: 'MessagesChannelPaneMessageListChannelFragment' | undefined
// }
