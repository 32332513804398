import isEmpty from 'lodash/fp/isEmpty'
import { Box, Typography, useTheme } from '@persuit/ui-components'
import { RfpClarification } from '../../../../types'
import { ClarificationItem } from '../clarification-item'

type ClarificationListProps = {
	clarifications: Array<RfpClarification>
}

export const ClarificationList = ({ clarifications }: ClarificationListProps) => {
	const theme = useTheme()
	const clarificationTitle = clarifications.length === 1 ? 'Clarification' : 'Clarifications'
	if (isEmpty(clarifications)) return null

	return (
		<>
			<Typography variant="h2XSmall">{clarificationTitle}</Typography>
			<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
				{clarifications.map((clarification, index) => (
					<ClarificationItem
						key={clarification._id}
						clarification={clarification}
						clarificationNumber={index + 1}
					/>
				))}
			</Box>
		</>
	)
}
