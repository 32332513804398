import { Fragment } from 'react'
import { Box, Typography, useTheme, FormNumberFieldCleave } from '@persuit/ui-components'
import { formatPriceWithCurrency, getCurrencySymbol } from '@persuit/common-utils'
import { Delta } from '../delta'
import { useStore } from '../../store'

export type RateCardItemProps = {
	index: number
	currency: string
	originalRate: number
	title: string
	deliverableIndex: number
	id: string
	editable?: boolean
}

export const RateCardItem = ({
	index,
	currency,
	originalRate,
	title,
	deliverableIndex,
	id,
	editable = true,
}: RateCardItemProps) => {
	const theme = useTheme()
	const currentRate = useStore((state) => state.rateItemFormState[id] ?? originalRate)
	if (originalRate === 0) return null

	return (
		<Fragment>
			<Box>
				<Typography>
					{deliverableIndex}.{index + 1}
				</Typography>
			</Box>
			<Box>
				<Typography>{title}</Typography>
			</Box>

			<Box role="cell" data-testid="current-rate">
				<Typography color="text.secondary">
					{formatPriceWithCurrency(currency, originalRate)}
				</Typography>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				gap={theme.spacing(0.5)}
				role="cell"
				data-testid="updated-rate"
			>
				{editable ? (
					<>
						<Typography>{getCurrencySymbol(currency)}</Typography>
						<FormNumberFieldCleave
							name={id}
							id={id}
							label=""
							decimalScale={4}
							inputProps={{
								'aria-label': `${title} input`,
							}}
							emptyValue={originalRate}
							replaceZero={true}
						/>
						<Typography>{currency}</Typography>
					</>
				) : (
					<Typography color="text.secondary">
						{formatPriceWithCurrency(currency, currentRate)}
					</Typography>
				)}
			</Box>

			<Box role="cell" data-testid="pricing-change-delta">
				<Delta newNumber={currentRate} originalNumber={originalRate} currency={currency} />
			</Box>
		</Fragment>
	)
}
