import { graphql } from '@persuit/ui-graphql'

export const REVOKE_MUTATION = graphql(`
	mutation SharingControl_RevokeUsersFromRfp($rfpId: ID!, $userIds: [ID!]!) {
		revokeUsersFromRfp(rfpId: $rfpId, userIds: $userIds) {
			... on Rfp {
				...RfpClient_RfpDEPRECATED
			}
		}
	}
`)

export const SHARE_MUTATION = graphql(`
	mutation SharingControl_Share($id: ID!, $users: [ShareInput]!) {
		share(id: $id, users: $users) {
			... on Rfp {
				...RfpClient_RfpDEPRECATED
			}
		}
	}
`)

export const INVITE_UNREG_FIRM_MUTATION = graphql(`
	mutation SharingControl_InviteUnregFirm($id: ID!, $unregisteredEmails: [String]) {
		clientInviteUnregFirmToPublishedRFP(id: $id, unregisteredEmails: $unregisteredEmails) {
			... on Rfp {
				...RfpClient_RfpDEPRECATED
			}
		}
	}
`)

export const GET_OWN_ORG_QUERY = graphql(`
	query SharingControl_GetOwnOrg {
		getOwnOrg {
			_id
			panelFirmTags {
				firmId
				type
				groupId
			}
		}
	}
`)

export const GET_SESSION_QUERY = graphql(`
	query SharingControlGetSessionInfo {
		getSessionInfo {
			user {
				_id
				favourites
				org {
					_id
					groups {
						_id
						name
					}
					panelFirmTags {
						firmId
						type
						groupId
					}
				}
				group {
					_id
					name
				}
			}
		}
	}
`)
