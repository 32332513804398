import { MultiTag, MultiTagProps } from './multi-tag'
import { useFormComponent, RHFFormComponentProps } from '../../utilities'

export type FormMultiTagProps = RHFFormComponentProps<Array<unknown>, Omit<MultiTagProps, 'value'>>

export const FormMultiTag = ({ rules, name, defaultValue = [], ...props }: FormMultiTagProps) => {
	const {
		field,
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<MultiTag
			{...props}
			required={props.required || field.required}
			onChange={field.onChange}
			value={field.value}
			name={field.name}
			inputRef={field.ref}
			error={!!error?.message}
			helperText={error?.message}
		/>
	)
}
