import { Typography, Box, Button, Tooltip } from '@persuit/ui-components'
import { TOOLTIPS } from './tooltips'

type OverrideButtonProps = {
	onClick?: () => void
	overrideButtonRef?: any
	neverHaveSavings: boolean
	canEditRfpAnalytics: boolean
}

export const OverrideButton = ({
	onClick,
	overrideButtonRef,
	neverHaveSavings,
	canEditRfpAnalytics,
}: OverrideButtonProps) => {
	return (
		<Box display="flex" alignItems="center" gap={2} p={1}>
			{!neverHaveSavings && (
				<Typography variant="body2" style={{ color: 'text.secondary' }}>
					Need to adjust?
				</Typography>
			)}
			{canEditRfpAnalytics ? (
				<Button variant="outlined" color="primary" onClick={onClick} ref={overrideButtonRef}>
					{neverHaveSavings ? 'Enter Savings' : 'Override Savings'}
				</Button>
			) : (
				<Tooltip title={TOOLTIPS.nonManagerOverrideSavingsButton}>
					<Box>
						<Button variant="text" color="primary" disabled={true}>
							{neverHaveSavings ? 'Enter Savings' : 'Override Savings'}
						</Button>
					</Box>
				</Tooltip>
			)}
		</Box>
	)
}
