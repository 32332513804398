import { memo, useEffect, useState } from 'react'
import { Box } from '../box'
import { Button } from '../button'
import { AddIcon } from '../../icons'
import {
	DataGridPremium,
	useGridApiRef,
	GridCellParams,
	GridValidRowModel,
	GridColDef,
	GridRowProps,
	GridRow,
} from './data-grid-premium'
import { noop } from '@persuit/common-utils'
import { useDescription } from '@persuit/ui-hooks'

import {
	useUndoRedoKeyboardListener,
	useClearSelectionListener,
	expandClipboard,
} from './grid-utils'

export type PastedRow = { [key: string]: null | string }

export type CopyPasteDataGridProps<TableRow extends GridValidRowModel> = {
	minRows?: number
	parsePastedRow: (pastedRow: PastedRow, existingRow?: TableRow) => TableRow
	getEmptyRow: () => TableRow
	getRowId: (tableRow: TableRow) => string
	rows: TableRow[]
	onChange: (rows: TableRow[]) => void
	onUndo?: () => void
	onRedo?: () => void
	canUndo?: boolean
	canRedo?: boolean
	columns: GridColDef[]
	isCellEditable?: (params: Pick<GridCellParams<TableRow>, 'field' | 'row'>) => boolean
	processRowUpdate?: (newRow: TableRow, oldRow: TableRow | null) => TableRow
	rowHeight?: number
	columnHeaderHeight?: number
	maxHeight?: number
	'aria-label': string
}

/** Required to set an id attr to the data-grid row component */
const CustomRow = (props: GridRowProps) => <GridRow id={props.rowId} {...props} />

function CopyPasteDataGridImplementation<TableRow extends GridValidRowModel>({
	minRows = 3,
	rows: rawRows,
	onChange,
	parsePastedRow,
	getEmptyRow,
	getRowId,
	columns,
	canRedo = true,
	canUndo = true,
	onRedo = noop,
	onUndo = noop,
	isCellEditable,
	processRowUpdate,
	rowHeight = 50,
	columnHeaderHeight = 60,
	maxHeight = Infinity,
	'aria-label': ariaLabel,
}: CopyPasteDataGridProps<TableRow>) {
	/** This variable is for E2E testing purposes only */
	const disableVirtualization = localStorage.getItem('datagrid-virtualization-disabled') === 'yes'
	const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)
	const apiRef = useGridApiRef()

	const paddedRows = (rows: TableRow[]): TableRow[] => {
		const rowsWithIds = rows
		if (rows.length >= minRows) return rows
		const extraRowsRequired = minRows - rows.length
		return [...rowsWithIds, ...Array(extraRowsRequired).fill(null).map(getEmptyRow)]
	}

	const getCurrentRows = () =>
		Object.values(apiRef.current.state.rows.dataRowIdToModelLookup) as TableRow[]

	const parsedRows = paddedRows(rawRows)

	useUndoRedoKeyboardListener({
		onUndo,
		onRedo,
		canUndo,
		canRedo,
		element: gridElement,
	})

	useClearSelectionListener({
		gridApiRef: apiRef,
		element: gridElement,
		callback: () => onChange(getCurrentRows()),
		isCellEditable,
	})

	const [fixHeight, setFixHeight] = useState(false)
	const rowsBeforeScrolling = Math.floor((maxHeight - columnHeaderHeight) / rowHeight)

	useEffect(() => {
		setFixHeight(!!rowsBeforeScrolling && parsedRows.length > rowsBeforeScrolling)
	}, [parsedRows, maxHeight, rowsBeforeScrolling])

	const descriptionId = useDescription(
		`Shift and arrows to select. Can copy with ctrl + c.  Can paste with ctrl + v`,
	)

	return (
		<Box>
			<Box
				sx={{
					height: fixHeight
						? `${columnHeaderHeight + rowHeight * rowsBeforeScrolling}px`
						: undefined,
				}}
			>
				<DataGridPremium
					disableVirtualization={disableVirtualization}
					rowHeight={rowHeight}
					columnHeaderHeight={columnHeaderHeight}
					autoHeight={!fixHeight}
					ref={(el) => {
						setGridElement(el)
						if (el && ariaLabel) {
							const grid = el?.querySelector(`[role="grid"]`)
							grid?.setAttribute('aria-label', ariaLabel)
							grid?.setAttribute('aria-describedby', descriptionId)
						}
					}}
					sx={(theme) => ({
						border: `1px solid ${theme.palette.border.divider}`,
						'--DataGrid-rowBorderColor': theme.palette.border.divider,
						[`[role="gridcell"], [role="columnheader"]`]: {
							borderRight: '1px solid var(--DataGrid-rowBorderColor)',
						},
						[`[role="gridcell"]:has(> .base-cell)`]: {
							padding: 0,
						},
						[`[role="gridcell"]:has(> .data-grid-error):not(.Mui-selected)`]: {
							background: theme.palette.error.lightestHue,
						},
						[`[role="gridcell"]:has(> .data-grid-disabled):not(.Mui-selected)`]: {
							background: theme.palette.action.disabledBackground,
						},
					})}
					isCellEditable={isCellEditable}
					disableColumnSorting={true}
					disableColumnReorder={true}
					disableColumnMenu={true}
					hideFooter={true}
					columns={columns}
					getRowId={getRowId}
					rows={parsedRows}
					apiRef={apiRef}
					rowSelection={false}
					disableRowSelectionOnClick={true}
					ignoreValueFormatterDuringExport={true}
					cellSelection={true}
					processRowUpdate={(newRow, oldRow) => {
						const rows = getCurrentRows()
						const index = rows.findIndex((row) => getRowId(row) === getRowId(newRow))
						const updatedNewRow = processRowUpdate ? processRowUpdate(newRow, oldRow) : newRow
						const updatedRows = [...rows.slice(0, index), updatedNewRow, ...rows.slice(index + 1)]
						onChange(updatedRows)
						return updatedNewRow
					}}
					onBeforeClipboardPasteStart={async ({ data }) => {
						if (data.length === 0) return Promise.reject()

						const cellSelectionModel = apiRef.current.getCellSelectionModel()
						const selectedRows = Object.keys(cellSelectionModel).length
						const selectedCols =
							selectedRows === 0 ? 0 : Object.keys(Object.values(cellSelectionModel)[0]).length
						const expandedData = expandClipboard(data, { rows: selectedRows, cols: selectedCols })

						const state = apiRef.current.state
						const rowIds = state.rows.dataRowIds
						const focusedCell = state.focus?.cell
						if (!focusedCell) return Promise.reject()

						const lastRowIndex = rowIds.length - 1
						const startRowIndex = rowIds.findIndex((rowId) => focusedCell.id === rowId)
						const startColIndex = state.columns.orderedFields.findIndex(
							(col) => col === focusedCell.field,
						)
						const endRowIndex = startRowIndex + expandedData.length - 1
						const endColIndex = startColIndex + expandedData[0].length - 1

						const pasteRowCount = expandedData.length
						const endPasteIndex = startRowIndex + pasteRowCount - 1
						const overflowRows = endPasteIndex - lastRowIndex

						const currentRows = getCurrentRows()
						const cols = state.columns.orderedFields

						const processRow = processRowUpdate
							? (row: TableRow) => processRowUpdate(row, null)
							: (row: TableRow) => row

						const updatedRows = currentRows.map((row, rowIndex) => {
							if (rowIndex < startRowIndex) return row
							if (rowIndex > endRowIndex) return row

							const newRow = { ...row }

							cols.forEach((_field, colIndex) => {
								const field = _field as keyof TableRow
								const col = row[field]
								if (colIndex < startColIndex) return col
								if (colIndex > endColIndex) return col

								function parsePastedField(field: keyof TableRow, data: string) {
									return parsePastedRow({ [field]: data }, row)[field]
								}

								newRow[field] = parsePastedField(
									field,
									expandedData[rowIndex - startRowIndex][colIndex - startColIndex],
								)
							})

							return processRow(newRow)
						})

						const extraRows = overflowRows > 0 ? expandedData.slice(-1 * overflowRows) : []
						const newOverflowRows = extraRows
							.map((row) =>
								Object.fromEntries(
									cols.map((field, index) => [
										field,
										index >= startColIndex && index < startColIndex + row.length
											? row[index - startColIndex]
											: null,
									]),
								),
							)
							.map((row) => parsePastedRow(row))
							.map(processRow)

						onChange([...updatedRows, ...newOverflowRows])

						// Disable default paste handling
						return Promise.reject()
					}}
					slots={{
						row: CustomRow,
					}}
				/>
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
				<Button
					startIcon={<AddIcon />}
					variant="outlined"
					onClick={() => onChange([...getCurrentRows(), getEmptyRow()])}
				>
					Add row
				</Button>
			</Box>
		</Box>
	)
}

export const CopyPasteDataGrid = memo(
	CopyPasteDataGridImplementation,
) as typeof CopyPasteDataGridImplementation
