import { ChangeEvent } from 'react'
import * as React from 'react'
import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '../text-field'
import { Override } from '../../utilities'
import Cleave from 'cleave.js/react'

export type NumberFieldProps = Override<
	Omit<MUITextFieldProps, 'multiline'>,
	{
		value?: number | null
		label: MUITextFieldProps['label']
		id?: MUITextFieldProps['id']
		min?: number
		max?: number
		step?: number
		required?: boolean
		/**
		 * Will be called whenever the value changes.
		 * The value will be a number unless the input is empty in which case it will be null.
		 */
		onChange?: (value: number | null, event: ChangeEvent<HTMLInputElement>) => void
	} & InputMaskProps
>

export const NumberField = React.forwardRef(
	(
		{ variant = 'standard', value, min, max, step, required, onChange, ...props }: NumberFieldProps,
		ref: React.Ref<HTMLDivElement>,
	) => {
		return (
			<MUITextField
				{...props}
				required={required}
				value={value === undefined ? undefined : value === null ? '' : `${value}`}
				variant={variant}
				onChange={(e) => {
					const value = parseFloat(e.target.value.replace(/,/g, ''))
					onChange?.(isNaN(value) ? null : value, e as ChangeEvent<HTMLInputElement>)
				}}
				ref={ref}
				inputProps={{
					min,
					max,
					step,
					required,
					inputMode: 'numeric',
					/** Number of decimal places */
					decimalPlaces: props.decimalPlaces,
					/** Number of integers before decimal */
					integerPlaces: props.integerPlaces,
					positiveOnly: props.positiveOnly,
					showCommas: props.showCommas,
					...props.inputProps,
				}}
				InputProps={{
					inputComponent: InputMask as any,
					...props.InputProps,
				}}
			/>
		)
	},
)

NumberField.displayName = 'NumberField'

type InputMaskProps = {
	/** Number of decimal places */
	decimalPlaces?: number
	/** Number of integers before decimal */
	integerPlaces?: number
	positiveOnly?: boolean
	showCommas?: boolean
}

const InputMask = ({
	decimalPlaces = 13,
	// This number includes decimal places. This will value reduce the max value that this
	// input will allow. However, it is unlikely that a user will want to input fractions
	// of a cent when inputting a number in the trillions.
	integerPlaces = 15,
	positiveOnly = false,
	showCommas = true,
	...rest
}: InputMaskProps) => (
	<Cleave
		{...rest}
		options={{
			numeral: showCommas,
			numeralPositiveOnly: positiveOnly,
			numeralThousandsGroupStyle: 'thousand',
			numeralDecimalScale: decimalPlaces,
			numeralIntegerScale: integerPlaces,
		}}
	/>
)
