import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
	Box,
} from '@persuit/ui-components'

import { Fragment } from 'react'

import NoteList from './notes-list'

const ViewNotesDialog = ({ rfpId, firmId, firmName, handleCloseDialog, openAddNoteDialog }) => {
	return (
		<Fragment>
			<DialogTitle data-testid="view-notes-dialog">Notes</DialogTitle>
			<DialogContent>
				<Typography variant="body2Semibold" display="block">
					{firmName}
				</Typography>
				<Typography variant="body2" display="block">
					Notes are for internal purposes and visible to your colleagues
				</Typography>

				<Box mt={2}>
					<NoteList rfpId={rfpId} firmId={firmId} openAddNoteDialog={openAddNoteDialog} />
				</Box>
			</DialogContent>
			<DialogActions>
				<Button label="Close" onClick={handleCloseDialog}>
					Close
				</Button>
			</DialogActions>
		</Fragment>
	)
}

export default ViewNotesDialog
