import { SvgIcon, SvgIconProps } from '../components/svg'

export const CalendarIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M5.5 10.5H8.5V13.5H5.5V10.5Z" fill="#4A398C" />
			<path d="M13.5 10.5H10.5V13.5H13.5V10.5Z" fill="#4A398C" />
			<path d="M15.5 10.5H18.5V13.5H15.5V10.5Z" fill="#4A398C" />
			<path d="M8.5 16H5.5V19H8.5V16Z" fill="#4A398C" />
			<path d="M10.5 16H13.5V19H10.5V16Z" fill="#4A398C" />
			<path d="M18.5 16H15.5V19H18.5V16Z" fill="#4A398C" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.8 2.18182H19.7V0H17.5V2.18182H6.5V0H4.3V2.18182H3.2C1.99 2.18182 1 3.16364 1 4.36364V21.8182C1 23.0182 1.99 24 3.2 24H20.8C22.01 24 23 23.0182 23 21.8182V4.36364C23 3.16364 22.01 2.18182 20.8 2.18182ZM20.8 21.8182H3.2V7.63636H20.8V21.8182Z"
				fill="#4A398C"
			/>
		</SvgIcon>
	)
}
