// @ts-strict-ignore
import { size } from 'lodash/fp'

export const requestValidForComparisonValue = (deliverables): boolean => {
	if (size(deliverables) === 0) return false
	return deliverables.some(
		(deliverableOrPricingGroup) =>
			(isDeliverable(deliverableOrPricingGroup) &&
				isValidPricingModelForComparisonValue(deliverableOrPricingGroup)) ||
			(isPricingGroup(deliverableOrPricingGroup) &&
				deliverableOrPricingGroup.deliverables.length > 0 &&
				deliverableOrPricingGroup.deliverables.some((deliverable) =>
					isValidPricingModelForComparisonValue(deliverable),
				)),
	)
}

const isDeliverable = (pricingItem) => {
	return pricingItem.__typename === 'Deliverable' || pricingItem.__typename === 'DraftDeliverable'
}

const isPricingGroup = (pricingItem) => {
	return (
		pricingItem.__typename === 'PricingGroup' || pricingItem.__typename === 'RfpUpdatePricingGroup'
	)
}

const isValidPricingModelForComparisonValue = (pricingItem): boolean => {
	return [
		'cappedFee',
		'collaredFee',
		'contingencyFee',
		'fixedFee',
		'successFee',
		'estimate',
		'other',
		'none',
	].includes(pricingItem.pricingPreferences ?? '')
}
