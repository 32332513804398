import { Switch, SwitchProps } from './switch'
import { useFormComponent, RHFFormComponentProps } from '../../utilities'
import { FormGroup, FormControlLabel, FormControlLabelProps } from '../form'

export type FormSwitchProps = RHFFormComponentProps<boolean, Omit<SwitchProps, 'defaultChecked'>> &
	Pick<FormControlLabelProps, 'label' | 'required' | 'disabled'>

export const FormSwitch = ({
	name,
	label,
	disabled,
	required,
	defaultValue,
	...props
}: FormSwitchProps) => {
	const {
		field: { onChange, value },
	} = useFormComponent({
		name,
		defaultValue,
	})

	return (
		<FormGroup>
			<FormControlLabel
				label={label}
				disabled={disabled}
				required={required}
				control={
					<Switch {...props} onChange={onChange} value={value} defaultChecked={defaultValue} />
				}
			/>
		</FormGroup>
	)
}
