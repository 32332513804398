import { styled } from '@persuit/ui-components'

const StyledPendoHelp = styled('div')`
	position: fixed;
	bottom: 2rem;
	left: 2rem;
	z-index: 999;
`

const PendoHelp = () => {
	return <StyledPendoHelp data-testid="pendo-always-on-help" />
}

export default PendoHelp
