import classnames from 'classnames'
import injectSheet from '../injectSheet'
import persuitTheme from '../theme/persuit-theme'
import { teal500, pink600, dangerRed, black } from '../theme/persuit-colors'

const textTypes = [
	'body1',
	'body2',
	'caption',
	'display1',
	'display2',
	'display3',
	'display4',
	'headline',
	'subheading1',
	'subheading2',
	'title',
]

const Text = ({
	align,
	children,
	classes,
	className,
	component,
	grey,
	italics,
	marginBottom,
	noWrap,
	strikeThrough,
	red,
	pink,
	purple,
	sheet, // Extract sheet so it doesn't end up on the <hx> tag
	teal,
	type,
	weight,
	black,
	...props
}) => {
	let Component = 'span'

	if (
		type === 'display1' ||
		type === 'display2' ||
		type === 'display3' ||
		type === 'display4' ||
		type === 'headline'
	) {
		Component = 'h1'
	}
	if (type === 'title') {
		Component = 'h2'
	}
	if (type === 'subheading1') {
		Component = 'h4'
	}
	if (type === 'subheading2') {
		Component = 'h3'
	}
	if (type === 'body1') {
		Component = 'p'
	}
	if (type === 'body2') {
		Component = 'aside'
	}
	if (type === 'caption') {
		Component = 'span'
	}
	if (type === 'formLabel') {
		Component = 'div'
	}

	Component = component ? component : Component

	return (
		<Component
			className={classnames(className, classes[type], {
				[classes.noMarginBottom]: !marginBottom && type !== 'formLabel',
				[classes.noWrap]: noWrap,
				[classes.strikeThrough]: strikeThrough,
				[classes[`align-${align}`]]: align,
				[classes[`fontWeight-${weight}`]]: weight,

				[classes.grey]: grey,
				[classes.red]: red,
				[classes.pink]: pink,
				[classes.purple]: purple,
				[classes.teal]: teal,
				[classes.black]: black,
				[classes.italics]: italics,
			})}
			type={type}
			{...props}
		>
			{children}
		</Component>
	)
}

Text.styles = (theme) => ({
	display1: {
		margin: '-0.5rem 0 4rem -0.07em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '2.125rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '2.5rem',
	},
	display2: {
		margin: '-0.5rem 0 4rem -0.07em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '2.813rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '3rem',
	},
	display3: {
		margin: '-8px 0 64px -0.07em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '3.5rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '3.5rem',
	},
	display4: {
		margin: '-1rem 0 3.5rem -0.085em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '7rem',
		fontWeight: persuitTheme.fontWeight.light,
		lineHeight: '7rem',
	},
	body1: {
		margin: '-0.313rem 0 0.813rem -0.06em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '0.875rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '1.5rem',
	},
	body2: {
		margin: '-0.25rem 0 0.75rem 0',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '0.875rem',
		fontWeight: persuitTheme.fontWeight.medium,
		lineHeight: '1.5rem',
	},
	caption: {
		margin: '-0.5rem 0 1rem -0.04em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '0.75rem',
		fontWeight: persuitTheme.fontWeight.medium,
		lineHeight: '1.25rem',
	},
	headline: {
		margin: '-0.5rem 0 1rem -0.06em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '1.5rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '2rem',
	},
	italics: {
		fontStyle: 'italic',
	},
	title: {
		margin: '-0.5rem 0 1rem -0.05em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '1.25rem',
		fontWeight: persuitTheme.fontWeight.medium,
		lineHeight: '2rem',
	},
	subheading1: {
		margin: '-0.313rem 0 0.813rem -0.06em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '0.938rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '1.5rem',
	},
	subheading2: {
		margin: '-0.5rem 0 1rem -0.06em',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		fontSize: '1rem',
		fontWeight: persuitTheme.fontWeight.regular,
		lineHeight: '1.75rem',
	},
	// Copying styles for MUI form label
	formLabel: {
		fontSize: '14px',
		// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
		color: 'rgba(0, 0, 0, 0.7)',
		margin: '0 0 8px 0',
	},
	noMarginBottom: {
		marginBottom: '0px',
	},
	noWrap: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	strikeThrough: {
		textDecoration: 'line-through',
	},
	'align-left': {
		textAlign: 'left',
	},
	'align-center': {
		textAlign: 'center',
	},
	'align-right': {
		textAlign: 'right',
	},
	'align-justify': {
		textAlign: 'justify',
	},

	grey: {
		color: theme.palette.text.secondary,
	},

	red: {
		color: dangerRed,
	},

	pink: {
		color: pink600,
	},

	purple: {
		color: theme.palette.primary.main,
	},

	teal: {
		color: teal500,
	},

	black: {
		color: black,
	},
})

// add additional font weight classes for weight override
Object.keys(persuitTheme.fontWeight).forEach((weight) => {
	Text.styles[`fontWeight-${weight}`] = {
		fontWeight: `${persuitTheme.fontWeight[weight]} !important`,
	}
})

Text.defaultProps = {
	grey: false,
	marginBottom: false,
	noWrap: false,
	red: false,
	strikeThrough: false,
	pink: false,
	purple: false,
	teal: false,
	black: false,
	type: 'body1',
}

/**
 * @deprecated
 * This component is very outdated and is no longer aligned with our typography framework.
 * Please use Typography from @persuit/ui-components
 */
export default injectSheet(Text.styles)(Text)

export { textTypes }
