import { Box, SxProps, useTheme } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import {
	gridFilterModelSelector,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import NamwolfLogo from '../../../../../../components/namwolf/namwolf-logo'

export const GridCustomCellWithLogo = ({
	value,
	row,
	sx,
}: GridRenderCellParams & { sx?: SxProps }) => {
	const theme = useTheme()

	const apiRef = useGridApiContext()
	const filterModel = useGridSelector(apiRef, gridFilterModelSelector)

	const isNamwolfMember = row.isNamwolfMember

	const quickFilterValue = filterModel.quickFilterValues!.join(' ').toLowerCase()
	const matchIndex = value.toLowerCase().indexOf(quickFilterValue)

	if (
		!filterModel.quickFilterValues ||
		filterModel.quickFilterValues.length === 0 ||
		matchIndex < 0
	) {
		return (
			<Box sx={{ ...sx, display: 'inline-flex', alignItems: 'center' }}>
				{value}
				{isNamwolfMember && <NamwolfLogo style={{ marginLeft: '10px' }} />}
			</Box>
		)
	}

	const start = value.slice(0, matchIndex)
	const match = value.slice(matchIndex, matchIndex + quickFilterValue.length + 1)
	const end = value.slice(matchIndex + quickFilterValue.length + 1)

	return (
		<Box sx={{ ...sx, display: 'inline-flex', alignItems: 'center' }}>
			{start}
			<span style={{ backgroundColor: `${theme.palette.primary.lighterHue}` }}>{match}</span>
			{end}
			{isNamwolfMember && <NamwolfLogo style={{ marginLeft: '10px' }} />}
		</Box>
	)
}
