import { BaseStatus } from '@client/components/base-status'
import { ReviseProposalIcon } from '@persuit/ui-components'

type RevisionRequiredProps = {
	expanded?: boolean
}

const RevisionRequired = ({ expanded = true }: RevisionRequiredProps) => {
	return (
		<BaseStatus
			expanded={expanded}
			iconNode={<ReviseProposalIcon fontSize={expanded ? undefined : 'small'} />}
			primaryText={'AWAITING REVISION'}
			variant={'GREY'}
		/>
	)
}

export default RevisionRequired
