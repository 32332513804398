import { Box, useTheme } from '@persuit/ui-components'

import { useStore } from '../../store'

export const PinHighlightsRow = () => {
	const theme = useTheme()
	const { pinnedProposalCount, responseCount, showIntelligenceColumn } = useStore((state, s) => ({
		pinnedProposalCount: s.displayedPinnedProposalIds().length,
		responseCount: s.displayedResponses().length,
		showIntelligenceColumn: s.showIntelligenceColumn(),
	}))

	return (
		<Box
			aria-hidden={true}
			component="tr"
			sx={{
				height: '10px',
				position: 'sticky',
				top: '0px',
				zIndex: 120,
			}}
		>
			{/* Offset, the first column is a header column and the intelligence column could be the 2nd if enabled */}
			<Box
				component="td"
				colSpan={showIntelligenceColumn ? 2 : 1}
				sx={{ background: 'white !important' }}
			/>

			{Array(pinnedProposalCount)
				.fill(null)
				.map((_, index, array) => (
					<Box key={index} component="td" sx={{ background: 'white !important' }}>
						<Box
							sx={{
								height: '100%',
								borderTopLeftRadius: index === 0 ? '1rem' : undefined,
								borderTopRightRadius: index + 1 === array.length ? '1rem' : undefined,
								background: theme.palette.primary.main,
							}}
						/>
					</Box>
				))}

			{responseCount - pinnedProposalCount > 0 && (
				<Box
					component="td"
					sx={{ background: 'white !important' }}
					colSpan={responseCount - pinnedProposalCount}
				/>
			)}
		</Box>
	)
}
