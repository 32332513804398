import { ProposalNamedTimekeeper } from '@persuit/ui-graphql/generated'
import { timekeeperCalculations } from '../rate-card'

export type NamedTimekeeperRowData = {
	id: string
	timekeeperLevel?: string
	firstName?: string
	lastName?: string
	location?: string
	graduationYear?: number
	lastYearsHours?: number
	currentRate?: number
	newStandardRate?: number
	proposedRate?: number
	discountFromStandard: number | null
	changeFromLastYear: number | null
	spendImpact: number | null
	timekeeperId: string
	comment: string
}

export const getNamedTimekeeperRowData = (
	namedTimekeepers: Omit<
		ProposalNamedTimekeeper,
		'createdAt' | 'updatedAt' | 'isCurrentRateApplicable' | 'isLastYearHoursApplicable'
	>[],
): NamedTimekeeperRowData[] => {
	const namedTimekeeperRows = namedTimekeepers.map((ntk) => {
		const { discountFromStandard, spendImpact, changeFromLastYear } = timekeeperCalculations({
			currentRate: ntk.currentRate,
			lyHour: ntk.lastYearHours,
			proposedRate: ntk.proposedRate,
			standardRate: ntk.standardRate,
		})

		const timekeeperId = ntk.billingId ? ntk.billingId : '-'

		return {
			...ntk,
			id: ntk._id,
			timekeeperLevel: ntk.jobTitle ?? undefined,
			firstName: ntk.firstName ?? undefined,
			lastName: ntk.lastName ?? undefined,
			location: ntk.locationLabel ?? '',
			graduationYear: ntk.graduationYear ?? undefined,
			lastYearsHours: ntk.lastYearHours ?? undefined,
			currentRate: ntk.currentRate ?? undefined,
			newStandardRate: ntk.standardRate ?? undefined,
			proposedRate: ntk.proposedRate ?? undefined,
			discountFromStandard,
			changeFromLastYear,
			spendImpact,
			timekeeperId,
			comment: ntk?.comment ?? '',
		}
	})

	return namedTimekeeperRows
}
