import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from './autocomplete'
import { RHFFormComponentProps, useFormComponent } from '../../utilities'
import { TextField, TextFieldProps } from '../text-field'

export type FormAutocompleteProps<T> = RHFFormComponentProps<
	T,
	Omit<AutocompleteProps<T, false, boolean, false>, 'renderInput' | 'getOptionLabel'> & {
		renderInput?: AutocompleteProps<T, false, boolean, false>['renderInput']
		getOptionLabel: (option: T) => string
		label: string
		nullValue?: T
		/** @deprecated use getOptionValue instead */
		mapValue?: (newValue: T, oldValue: T) => T
		getOptionValue?: (newValue: T, oldValue: T) => T
	} & {
		shrinkLabel?: boolean
		required?: boolean
		variant?: TextFieldProps['variant']
	}
>

export const FormAutocomplete = <T,>({
	shrinkLabel,
	name,
	options,
	defaultValue,
	nullValue,
	getOptionLabel,
	renderInput,
	label,
	variant,
	required,
	onChange: onChangeProp,
	mapValue = (newValue) => newValue,
	getOptionValue = (newValue) => newValue,
	rules,
	...props
}: FormAutocompleteProps<T>) => {
	const getValue = getOptionValue ?? mapValue
	const {
		field: { onChange, value, ref, required: fieldRequired },
		fieldState: { error },
	} = useFormComponent({
		rules,
		name,
		defaultValue,
	})

	const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			variant={variant}
			required={required || fieldRequired}
			inputRef={ref}
			error={!!error?.message}
			helperText={error?.message}
			label={label}
			placeholder={props.placeholder}
			{...params}
			InputLabelProps={{ shrink: shrinkLabel, ...params.InputLabelProps }}
		/>
	)

	return (
		<Autocomplete
			getOptionLabel={getOptionLabel}
			renderInput={renderInput ?? defaultRenderInput}
			value={value}
			{...props}
			onChange={(event, newValue, reason, details) => {
				const nextValue = newValue === null && nullValue !== undefined ? nullValue : newValue
				const mappedValue =
					nextValue === null || typeof nextValue === 'string'
						? nextValue
						: getValue(nextValue, value)
				onChange(mappedValue)
				onChangeProp?.(event, newValue, reason, details)
			}}
			options={options}
		/>
	)
}
