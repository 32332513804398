// @ts-strict-ignore
import { RateReviewSuggestedChangesTable } from '@client/components/rfp-request-proposal-revision/rate-review-suggested-changes-table'
import { RateReviewFormValues } from '@client/components/rfp-request-proposal-revision/rate-review-suggested-changes-table/get-suggested-changes'
import { getNamedTimekeeperSuggestions } from '@client/components/rfp-request-proposal-revision/rate-review/get-default-rate-review-values'
import { isNotNil } from '@persuit/common-utils'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	RichTextView,
	Typography,
} from '@persuit/ui-components'
import { useQuery } from '@persuit/ui-graphql'
import { RevisionComment, RfpAndProposalQuery } from '@persuit/ui-graphql/generated'
import flattenDeep from 'lodash/fp/flattenDeep'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import isNil from 'lodash/fp/isNil'
import keyBy from 'lodash/fp/keyBy'
import map from 'lodash/fp/map'
import size from 'lodash/fp/size'
import moment from 'moment'
import richTextLength from '../../../../common/validators/tiny-inline-string-length'
import { getReponsesQueryDEPRECATED } from '../../../graphql/queries/getResponses-ts-version'
import SuggestedChangesTable from '../../rfp-request-proposal-revision/suggested-changes-table'
import ViewAssumptionDialog from './view-assumptions-dialog'

const revisionCommentHasNoSuggestedChanges = ({
	rfprTotalPrice,
	rfprTotalPricingModel,
	deliverables,
	rateReview,
	namedTimekeepers,
}) => {
	return [
		isEmpty(rfprTotalPrice),
		isEmpty(rfprTotalPricingModel),
		isEmpty(deliverables),
		isEmpty(rateReview),
		isEmpty(namedTimekeepers),
	].every((val) => val === true)
}

const createRateCardLookup: (proposals: any[]) => Record<string, unknown> = flow([
	map('rateCards'),
	flattenDeep,
	keyBy('_id'),
])

const prepareSuggestedChanges = (
	rfp,
	proposalsFromQuery,
	revisionComment,
): RateReviewFormValues[] => {
	if (rfp.useCase !== 'rateReview') {
		return []
	}
	const proposals = !isNil(rfp.proposals) ? rfp.proposals : proposalsFromQuery
	if (size(proposals) === 0) {
		return []
	}
	const rateCardLookup = createRateCardLookup(proposals)
	const rateReview = rfp?.rateReview
	const timekeeperLookup = keyBy('_id', rateReview?.timekeepers ?? [])

	const suggestedTimekeeperRates = revisionComment.rateReview.map((rateCard) => {
		const originalRateCard = rateCardLookup[rateCard.rateCardId] as any
		const timekeepers = rateCard.timekeepers.map((tk) => {
			const originalTimekeeper = timekeeperLookup[tk.timekeeperId]
			return {
				timekeeperId: tk.timekeeperId,
				suggestedRate: tk.suggestedRate,
				timekeeperLabel: originalTimekeeper.timekeeperLabel,
			}
		})
		const timekeepersWithSuggestedRate = timekeepers
			.map((tk) => ({ ...tk, suggestedRate: tk?.suggestedRate ?? null }))
			.filter((tk) => tk.suggestedRate)

		const mappedSuggestedTimekeeperRates: RateReviewFormValues = {
			timekeepers: timekeepersWithSuggestedRate,
			currency: originalRateCard?.currency ?? rateReview?.currency,
			originalDescription: rateReview?.details ?? '',
			rateCardId: rateCard.rateCardId,
			assumptions: rateCard.assumptions ?? '',
			location: originalRateCard.location,
			practiceAreas: originalRateCard.practiceAreas,
		}

		return mappedSuggestedTimekeeperRates
	})

	const suggestedTimekeeperRatesWithTimekeeperChanges = suggestedTimekeeperRates.filter(
		(tk) => tk.timekeepers.length > 0,
	)

	return suggestedTimekeeperRatesWithTimekeeperChanges
}

export type RevisionCommentsListItemProps = {
	rfp: RfpAndProposalQuery['rfp']
	proposal: RfpAndProposalQuery['proposal']
	currency: string
	revisionComment: RevisionComment
	phaseDuration: any
}

export const RevisionCommentsListItem = ({
	rfp,
	proposal,
	currency,
	revisionComment,
	phaseDuration,
}: RevisionCommentsListItemProps) => {
	const { data, loading } = useQuery(getReponsesQueryDEPRECATED, {
		variables: {
			requestId: rfp?._id ?? '',
		},
		skip: !rfp?._id,
		fetchPolicy: 'cache-only',
	})

	if (loading) {
		return null
	}

	// filter out competitor response while retaining type
	const proposalsFromQuery = (data?.getResponses ?? [])
		.map((proposal) => (proposal?.__typename === 'Response' ? proposal : null))
		.filter(isNotNil)
		.filter((p) => p.proposalFamilyId === proposal?.proposalFamilyId)

	const {
		comment,
		org: { name: orgName },
		updatedAt,
	} = revisionComment

	const totalPriceValues = {
		totalPrice: revisionComment.totalPrice,
		rfprTotalPrice: revisionComment.rfprTotalPrice,
		totalPricingModel: revisionComment.totalPricingModel,
		rfprTotalPricingModel: revisionComment.rfprTotalPricingModel,
	}

	const isClientOrg = (revisionComment?.org?.orgType ?? '') === 'CLIENT'
	const deliverables = revisionComment.deliverables ?? []

	// Check if there actually is a revision comment
	// Check if rich text actually contains content.
	// Don't want to render empty tags that TinyMCE sometimes produces
	const hasRevisionComment = !!comment && richTextLength(comment) > 0

	const suggestedChanges = prepareSuggestedChanges(rfp, proposalsFromQuery, revisionComment)

	const namedTimekeeperSuggestions = getNamedTimekeeperSuggestions({
		namedTimekeeperValues: revisionComment?.namedTimekeepers ?? [],
		proposalNamedTimekeepers: proposal?.namedTimekeepers ?? [],
		currency: rfp?.rateReview?.currency ?? '',
		proposalStatus: proposal?.status ?? '',
	})
	const updatedDate = moment(updatedAt).format('D MMMM YYYY, h:mmA')

	const noSuggestedChanges = revisionCommentHasNoSuggestedChanges({
		deliverables,
		...totalPriceValues,
		rateReview: suggestedChanges,
		namedTimekeepers: namedTimekeeperSuggestions,
	})
	const showSuggestedChangesTable = isClientOrg && !noSuggestedChanges

	return (
		<Accordion sx={{ mb: 2 }}>
			<AccordionSummary
				iconButtonLabel={(expanded) =>
					`${expanded ? 'Collapse' : 'Expand'} revision history for ${orgName} at ${updatedDate}`
				}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>{orgName}</Box>
					<Box>{updatedDate}</Box>
				</Box>
			</AccordionSummary>

			<AccordionDetails>
				{showSuggestedChangesTable &&
					(rfp?.useCase === 'rateReview' ? (
						<RateReviewSuggestedChangesTable
							suggestedChanges={suggestedChanges}
							namedTimekeeperSuggestions={namedTimekeeperSuggestions}
						/>
					) : (
						<SuggestedChangesTable
							pricingItems={deliverables}
							totalPriceValues={totalPriceValues}
							currency={currency}
							phaseDuration={phaseDuration}
							renderAssumptionsDialog={({ suggestedAssumptions }) => {
								return (
									suggestedAssumptions && (
										<ViewAssumptionDialog suggestedAssumptions={suggestedAssumptions} />
									)
								)
							}}
						/>
					))}

				{!hasRevisionComment && (
					<Typography variant="body1" fontSize="italic" mt={2}>
						No comment
					</Typography>
				)}

				{hasRevisionComment && (
					<RichTextView content={comment} data-testid="revision-comments" mt={2} />
				)}
			</AccordionDetails>
		</Accordion>
	)
}
