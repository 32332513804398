import { SvgIcon, SvgIconProps } from '../components/svg'

export const TabularIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.8665 0.726562H13.1332L13.0939 0.726564H11.6272L11.6665 0.726562H4.33317L4.2939 0.726564H2.82724L2.8665 0.726562H2.13317C1.3265 0.726562 0.666504 1.38111 0.666504 2.18111V6.63202V8.08535V10.1387V11.592V13.8175C0.666504 14.6175 1.3265 15.272 2.13317 15.272H13.8665C14.6732 15.272 15.3332 14.6175 15.3332 13.8175L15.3332 11.592V10.1387V8.08535V6.63202L15.3332 2.18111C15.3332 1.38111 14.6732 0.726562 13.8665 0.726562ZM13.8665 10.1387V8.08535H10.8931L10.8931 10.1387H13.8665ZM9.43981 10.1387L9.43981 8.08535H6.49984L6.49984 10.1387H9.43981ZM5.0465 10.1387H2.13317V8.08535H5.0465L5.0465 10.1387ZM6.49984 6.63202H9.43981V4.36293H6.49984V6.63202ZM5.0465 4.36293V6.63202H2.13317V4.36293H5.0465ZM5.0465 11.592V13.8175H2.13317V11.592H5.0465ZM6.49984 13.8175V11.592H9.43981V13.8175H6.49984ZM13.8665 6.63202H10.8931V4.36293H13.8665V6.63202ZM13.8665 13.8175H10.8931V11.592H13.8665V13.8175Z"
				fill="currentColor"
			/>
		</SvgIcon>
	)
}
