// @ts-strict-ignore
import {
	AttachMoneyIcon,
	Box,
	CheckIcon,
	Divider,
	DnsIcon,
	GroupWorkIcon,
	ListIcon,
	NotesIcon,
	PersonAddAlt1Icon,
	RatesSectionIcon,
	SettingsIcon,
	Spacer,
	SROnly,
	StarHalfIcon,
	Typography,
	useTheme,
} from '@persuit/ui-components'
import { Maybe, UseCase } from '@persuit/ui-graphql/generated'
import { isNotNil, PartialKeys } from '@persuit/common-utils'
import { getSectionLabel } from '../utils'
import { NavItem, NavItemProps } from './nav-item'
import { useNavigationContext } from './navigation'

import allowTypes from '@common/data/allow-types'
import unregisteredUserListState from '@common/data/unregistered-user-list-states'
import unregisteredUserStates from '@common/data/unregistered-user-states'
import {
	getSelectedUnknownUsers,
	getSelectedKnownUsers,
} from '@client/pages/new-rfp-form/invite-section/utils'

type PrefilledNavItemProps = PartialKeys<NavItemProps, 'icon' | 'title'> & {
	useCase?: UseCase | null
}

export const SummaryNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'summary')} icon={<NotesIcon />} {...props} />
)

export const RatesNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'rates')} icon={<RatesSectionIcon />} {...props} />
)

type Deliverable = {
	deliverableTitle?: string | null
}

type PricingNavItemProps = {
	useCase?: Maybe<UseCase>
	items: Array<
		| ({
				type: 'item'
		  } & Deliverable)
		| {
				type: 'group'
				title: string
				deliverables: Deliverable[]
		  }
	>
}

export const PricingNavItem = ({ useCase, items }: PricingNavItemProps) => {
	const { itemProps, errors } = useNavigationContext()
	const pricingErrors = errors?.pricing as
		| null
		| undefined
		| {
				groups: { index }[]
				deliverables: { index }[]
		  }
	let itemIndex = -1
	let groupIndex = -1

	return (
		<NavItem
			title={getSectionLabel(useCase, 'pricing')}
			icon={<AttachMoneyIcon />}
			{...itemProps('pricing', {
				subItems: items
					.map((pricingItem) => {
						if (pricingItem.type === 'item') {
							itemIndex++
							return {
								anchor: `pricing_item_${itemIndex}`,
								prefix: `${itemIndex + 1}.`,
								title: pricingItem?.deliverableTitle ?? '',
								error: !!pricingErrors?.deliverables?.find((error) => error.index === itemIndex),
							}
						} else if (pricingItem.type === 'group') {
							groupIndex++
							return {
								anchor: `pricing_group_${groupIndex}`,
								prefix: <ListIcon fontSize="inherit" />,
								placeholder: 'Untitled Group',
								prefixTitle: 'Group',
								title: pricingItem.title ?? 'Untitled Group',
								error: !!pricingErrors?.groups?.find((error) => error.index === groupIndex),
								subItems: pricingItem.deliverables.map((item) => {
									itemIndex++
									return {
										anchor: `pricing_item_${itemIndex}`,
										prefix: `${itemIndex + 1}.`,
										title: item?.deliverableTitle ?? '',
										error: !!pricingErrors?.deliverables?.find(
											(error) => error.index === itemIndex,
										),
									}
								}),
							}
						}
					})
					.filter(isNotNil),
			})}
		/>
	)
}

type QuestionsNavItemProps = {
	useCase?: Maybe<UseCase>
	questions: { title: string; groupId: string }[]
	questionGroups: { _id: string; hidden?: boolean | null | undefined; name: string }[]
}

export const QuestionsNavItem = ({ useCase, questions, questionGroups }: QuestionsNavItemProps) => {
	const { itemProps, errors } = useNavigationContext()
	const questionErrors = errors?.questions as undefined | null | { index: number }[]
	const groupErrors = errors?.questionGroups as undefined | null | { index: number }[]
	return (
		<NavItem
			title={getSectionLabel(useCase, 'questions')}
			icon={<DnsIcon />}
			{...itemProps('questions', {
				subItems: questionGroups
					.filter(
						(questionGroup) =>
							!questionGroup.hidden ||
							questions.find(({ groupId }) => groupId === questionGroup._id),
					)
					.reduce((acc: any[], questionGroup, index) => {
						const questionsForGroup = questions
							.map((question, questionIndex) => ({
								...question,
								error: !!questionErrors?.find((error) => error.index === questionIndex),
							}))
							.filter((question) => question.groupId === questionGroup._id)
						if (questionGroup.hidden) {
							questionsForGroup.forEach((question, questionIndex) => {
								acc.push({
									prefix: `${index + 1 + questionIndex}.`,
									placeholder: 'Untitled question',
									anchor: `question_item_${index + 1}`,
									title: question.title,
									error: !!question.error,
								})
							})
							return acc
						}
						acc.push({
							prefix: `${index + 1}.`,
							placeholder: 'Untitled Group',
							anchor: `question_group_${index + 1}`,
							title: questionGroup?.name ?? '',
							error:
								!!questionsForGroup.find((question) => !!question.error) ||
								!!groupErrors?.find((group) => group.index === index),
							subItems: questionsForGroup.map((question, questionIndex) => ({
								prefix: `${index + 1}.${questionIndex + 1}.`,
								anchor: `question_item_${index + 1}_${questionIndex + 1}`,
								title: question?.title ?? '',
								error: !!question.error,
							})),
						})
						return acc
					}, []),
			})}
		/>
	)
}

const InvitedContactsCounter = ({ invitedContacts }: { invitedContacts: number }) => {
	const theme = useTheme()
	if (invitedContacts > 0)
		return (
			<>
				<Box
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						fontWeight: 400,
						fontSize: '16px',
						lineHeight: '24px',
						width: '33px',
						height: '24px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '20px',
					}}
					aria-hidden={true}
				>
					{invitedContacts}
				</Box>
				<SROnly>{` ${invitedContacts === 1 ? 'contact' : 'contacts'} invited.`}</SROnly>
			</>
		)
	else return null
}

export const InviteContactsNavItem = ({
	rfp,
	userOrgId,
	userFavorites,
	useCase,
	...props
}: PrefilledNavItemProps & {
	rfp: any
	userOrgId: string
	userFavorites: string[]
}) => {
	const selectedUnknownUsers = getSelectedUnknownUsers(
		rfp,
		unregisteredUserStates,
		unregisteredUserListState,
	)
	const selectedKnownUsers = getSelectedKnownUsers(rfp, userOrgId, allowTypes, userFavorites)
	const selectedUsers = [...selectedKnownUsers, ...selectedUnknownUsers]

	return (
		<NavItem
			title={getSectionLabel(useCase, 'invite', true)}
			icon={<PersonAddAlt1Icon />}
			adornment={<InvitedContactsCounter invitedContacts={selectedUsers.length} />}
			{...props}
		/>
	)
}

export const DiversityNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'diversity')} icon={<GroupWorkIcon />} {...props} />
)

export const TimelineNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'timeline')} icon={<SettingsIcon />} {...props} />
)

export const ConflictNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'conflict')} icon={<CheckIcon />} {...props} />
)

export const ScorecardNavItem = ({ useCase, ...props }: PrefilledNavItemProps) => (
	<NavItem title={getSectionLabel(useCase, 'scorecard')} icon={<StarHalfIcon />} {...props} />
)

export type NavGroupProps = {
	title: string
	subtitle?: string
}

export const NavGroup = ({ title, subtitle }: NavGroupProps) => {
	return (
		<Box aria-label={`${title} group`}>
			<Typography variant="button">{title}</Typography>
			<Spacer shape="column" space={4} />
			<Typography variant="body2" color="text.secondary">
				{subtitle}
			</Typography>
		</Box>
	)
}

export const NavDivider = () => (
	<Box marginTop="16px" marginBottom="16px">
		<Divider />
	</Box>
)
