import { format } from 'date-fns'

import {
	Box,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	TableContainer,
	SxProps,
	Typography,
} from '@persuit/ui-components'

import { formatPrice } from '../utils'

type Row = {
	updatedAt: number
	timekeeperLabel: string
	proposedRate: number
	previousProposedRate: number
	currency: string
	previousCurrency: string
}

export type RateCardPriceHistoryProps = {
	sx?: SxProps
	rowData: Row[]
	ariaLabel?: string
}

export const RateCardPriceHistory = ({ rowData, sx, ariaLabel }: RateCardPriceHistoryProps) => {
	return (
		<TableContainer sx={sx}>
			<Table
				aria-label={ariaLabel ?? 'rate card historical prices'}
				sx={(theme) => ({
					borderCollapse: 'collapse',
					'& td, & th': {
						border: `1px solid ${theme.palette.grey300}`,
					},
				})}
			>
				<TableHead>
					<TableRow
						sx={(theme) => ({
							borderCollapse: 'collapse',
							'& th': {
								border: `1px solid ${theme.palette.grey200}`,
								borderBottom: `1px solid ${theme.palette.grey500}`,
							},
							'& tr:nth-child(2n)': {
								background: theme.palette.grey100,
							},
						})}
					>
						<TableCell align="center" sx={{ width: '8em' }}>
							Last updated
						</TableCell>
						<TableCell align="left">Timekeeper levels</TableCell>
						<TableCell align="right">Price</TableCell>
					</TableRow>
				</TableHead>

				<TableBody
					sx={(theme) => ({
						'& tr:nth-child(2n + 1)': {
							background: theme.palette.grey100,
						},
					})}
				>
					{rowData.map(
						(
							{
								updatedAt,
								proposedRate,
								timekeeperLabel,
								previousProposedRate,
								currency,
								previousCurrency,
							},
							index,
							array,
						) => {
							const prevItem = array[index - 1]
							const prevPrice = formatPrice(previousCurrency, previousProposedRate)
							const currentPrice = formatPrice(currency, proposedRate)

							return (
								<TableRow key={`${timekeeperLabel}:${updatedAt}`}>
									<TableCell align="center" sx={{ width: '8em', whiteSpace: 'nowrap' }}>
										{prevItem?.updatedAt === updatedAt
											? null
											: format(updatedAt, 'dd MMM y, hh:mma')}
									</TableCell>
									<TableCell align="left">{timekeeperLabel}</TableCell>
									<TableCell align="right">
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: 2,
												whiteSpace: 'nowrap',
											}}
										>
											<span aria-label={`Price updated from ${prevPrice} to ${currentPrice}`}>
												<Typography component="del" strikeThrough={true} fontSize="inherit">
													{prevPrice}
												</Typography>
												&nbsp;
												<Box component="ins" sx={{ textDecoration: 'none' }}>
													{currentPrice}
												</Box>
											</span>
										</Box>
									</TableCell>
								</TableRow>
							)
						},
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
