import { CurrencySelect, CurrencySelectProps } from './currency-select'
import { useFormComponent, RHFFormComponentProps } from '../../utilities'

export type FormCurrencySelectProps = RHFFormComponentProps<
	string,
	Omit<CurrencySelectProps, 'onChange' | 'value'>
>

export const FormCurrencySelect = ({
	name,
	defaultValue,
	rules,
	...props
}: FormCurrencySelectProps) => {
	const {
		field,
		fieldState: { error },
	} = useFormComponent({
		rules,
		name,
		defaultValue,
	})

	const errorMessage = error?.message
	const hasError = !!errorMessage

	return (
		<CurrencySelect
			inputRef={field.ref}
			required={field.required}
			error={hasError}
			helperText={error?.message}
			{...props}
			value={field.value}
			onChange={(e) => {
				field.onChange(e)
			}}
		/>
	)
}
