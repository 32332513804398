import Alert from '@client/components/alert'
import Box from '@client/components/box'
import Dialog from '@client/components/dialog'
import Spacer from '@client/components/layout/spacer'
import Text from '@client/components/text'
import useTranslation from '@client/custom-hooks/translation-hook'
import persuitTheme from '@client/theme/persuit-theme'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Container } from '@mui/material'
import { graphql, useMutation, useQuery } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Paper from 'material-ui/Paper'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RfpTabs } from './rfp-tabs'
import { RfpTimeline } from './rfp-timeline'
import { ShareRequestInfo } from './share-request-info'

const RFP_TRACKING_FIELDS_QUERY = graphql(`
	query RfpTracking_GetAnalytics($rfpId: ID!) {
		getAnalytics(requestId: $rfpId) {
			...RfpTimeline_Analytics
			...ShareRequestInfo_Analytics
			...RfpTabs_Analytics

			headline: listingTitle
			grants
		}
	}
`)

const RFP_TRACKING_FIELDS_REQUEST_SHARE_MUTATION = graphql(`
	mutation RfpTracking_RequestShare($rfpId: ID!) {
		requestShare(requestId: $rfpId) {
			success
		}
	}
`)

export const RfpTracking = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { requestId } = useParams() as { requestId: string }
	const [showRequestShareDialog, setShowRequestShareDialog] = useState(false)
	const [requestShare] = useMutation(RFP_TRACKING_FIELDS_REQUEST_SHARE_MUTATION, {
		variables: { rfpId: requestId },
		refetchQueries: [RFP_TRACKING_FIELDS_QUERY],
	})
	const { data, loading, error } = useQuery(RFP_TRACKING_FIELDS_QUERY, {
		variables: { rfpId: requestId },
	})

	const rfp = data?.getAnalytics?.[0]

	if (loading) {
		return null
	} else if (error || !rfp) {
		return <Alert type="fail">Unable to load RFP details</Alert>
	} else if (rfp.grants.includes('VIEW')) {
		// They have full access to the RFP, so redirect them to the main edit page
		history.push(`/${t('code')}/request/${requestId}/proposals/edit/`)
	}

	return (
		<Container
			fixed={true}
			style={{
				maxWidth: '972px',
				flex: '1 1',
			}}
		>
			<Box top={2} bottom={2}>
				<ShareRequestInfo requestShare={() => setShowRequestShareDialog(true)} rfpFragment={rfp} />
			</Box>
			<Paper style={{ maxWidth: persuitTheme.grid.breakpoints.md.containerWidth }}>
				<Box top={3} right={2} bottom={2} left={2}>
					<Text type="headline">{rfp.headline}</Text>
				</Box>
			</Paper>
			<RfpTimeline rfpFragment={rfp} />
			<Spacer direction="column" space={4} />
			<RfpTabs rfpFragment={rfp} />
			<Dialog
				onCancel={() => setShowRequestShareDialog(false)}
				onConfirm={async () => {
					await requestShare()
					setShowRequestShareDialog(false)
				}}
				open={showRequestShareDialog}
				title={t('sharing.requests.dialog.title')}
				cancelLabel={t('rfp.open.dialog.cancel')}
				confirmLabel={t('sharing.requests.dialog.submit')}
			/>
		</Container>
	)
}
