import { isEmpty } from 'lodash'
import trackingFieldTypes from '../../common/data/custom-tracking-field-types'

import validateRichTextLength from './validate-rich-text-length'

const mandatoryMessage = (customField) => {
	if (customField.name) return `Custom tracking field "${customField.name}" is required.`
	return 'Custom tracking field is required.'
}

const FIELD_VALIDATORS = {
	[trackingFieldTypes.HIERARCHY]: (customField, finalize) => {
		if (!customField.mandatory || !finalize) return null
		if (!customField.hierarchyValue || isEmpty(customField.hierarchyValue))
			return {
				hierarchyValue: mandatoryMessage(customField),
			}
		return null
	},

	[trackingFieldTypes.PLAIN_TEXT]: (customField, finalize) => {
		if (!customField.mandatory || !finalize) return null
		if (!customField.plainTextValue || !customField.plainTextValue.trim())
			return {
				plainTextValue: mandatoryMessage(customField),
			}
		return null
	},

	[trackingFieldTypes.NUMERIC]: (customField, finalize) => {
		if (!customField.mandatory || !finalize) return null
		if (!('numericValue' in customField) || customField.numericValue === null)
			return {
				numericValue: mandatoryMessage(customField),
			}
		return null
	},

	[trackingFieldTypes.RICH_TEXT]: (customField, finalize) => {
		if (!('richTextValue' in customField) || !customField.richTextValue) {
			//If it doesn't have a value
			if (customField.mandatory && finalize)
				return {
					richTextValue: mandatoryMessage(customField),
				}
		} else {
			// If it does have a value
			if (!validateRichTextLength({ richText: customField.richTextValue, maxLength: 5000 }))
				return {
					richTextValue: 'Custom rich text field(s) contains too many characters',
				}
		}
		return null
	},

	[trackingFieldTypes.DATE]: (customField, finalize) => {
		if (!customField.mandatory || !finalize) return null
		if (!('dateValue' in customField) || customField.dateValue === null)
			return {
				dateValue: mandatoryMessage(customField),
			}
		return null
	},
}

function validateField(customField, finalize) {
	if (customField.isArchived) return null
	if (!(customField.fieldType in FIELD_VALIDATORS)) return null

	return FIELD_VALIDATORS[customField.fieldType](customField, finalize)
}

export default function validateCustomTrackingFields(customTrackingFields, finalize = false) {
	const errors = customTrackingFields.reduce((errorAcc, customField) => {
		errorAcc.push(validateField(customField, finalize))
		return errorAcc
	}, [])

	// If at least one error exists return the entire array
	if (errors.find((error) => error)) {
		return errors
	}

	// Otherwise return null so that the callee can easily check for errors
	return null
}
