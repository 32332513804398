import { Button } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import DialogActions from '@mui/material/DialogActions'

export type DialogWizardActionsProps = {
	onPrev?: () => void
	onNext?: () => void
	onConfirm?: () => void
	confirmDataTrackId?: string
	onCancel?: () => void
	isWorking?: boolean
	disableConfirmButton?: boolean
}

export const DialogWizardActions = ({
	onPrev,
	onNext,
	onConfirm,
	onCancel,
	isWorking,
	disableConfirmButton,
	confirmDataTrackId,
}: DialogWizardActionsProps) => {
	return (
		<DialogActions style={{ justifyContent: 'flex-start' }}>
			{onPrev && (
				<Button onClick={onPrev} loading={isWorking}>
					Back
				</Button>
			)}
			<Button
				disabled={isWorking}
				onClick={onCancel}
				style={{
					marginLeft: 'auto',
				}}
			>
				Cancel
			</Button>
			{onNext && (
				<Button
					data-testid="dialog-wizard-next-button"
					loading={isWorking}
					variant="primary"
					onClick={onNext}
				>
					Next
				</Button>
			)}
			{onConfirm && (
				<Button
					data-testid="dialog-wizard-confirm-button"
					loading={isWorking}
					disabled={disableConfirmButton}
					color="primary"
					variant="contained"
					onClick={onConfirm}
					data-trackid={confirmDataTrackId}
				>
					Confirm
				</Button>
			)}
		</DialogActions>
	)
}
