import { useId } from 'react'
import { Box, FormCheckbox, Spacer, Typography, PopoverHelp } from '@persuit/ui-components'
import { FormProvider } from 'react-hook-form'
import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'
import { FormSectionBaseProps } from '../../rfp-template-form/types'
import { RfpDetailDiversity } from '../../rfp-detail/components/diversity'
import { DiversityDefinitionType } from '../../types'

export type DiversityFormSectionData = {
	diversityRequired: boolean
}

type DiversityFormProps = FormSectionBaseProps<DiversityFormSectionData> & {
	diversityDefinitions: Array<DiversityDefinitionType>
}

export const DiversityForm = ({
	sectionData,
	saveSection,
	diversityDefinitions,
}: DiversityFormProps) => {
	const methods = useSectionalForm(sectionData, saveSection)
	const diversityRequired = methods.watch('diversityRequired')
	const contentId = useId()

	return (
		<SectionalFormContainer label="Diversity section" focusOnMount={true}>
			<FormProvider {...methods}>
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Diversity</Typography>
					<Spacer shape="row" space={3} />
					<Box sx={{ width: '100%', padding: '0 5px' }}>
						<Box>
							<Box width="40%">
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<FormCheckbox
										name="diversityRequired"
										label="Include diversity questions"
										checkboxProps={{
											color: 'primary',
											inputProps: {
												'aria-expanded': diversityRequired,
												'aria-controls': contentId,
											},
										}}
										color="primary"
									/>
									<PopoverHelp
										content={
											'Track the diversity of your invited firms. Firms will be required to answer four pre-defined diversity-related questions'
										}
										triggerButtonProps={{ 'aria-label': 'Diversity information' }}
									/>
								</Box>
							</Box>
							{diversityRequired && (
								<RfpDetailDiversity
									id={contentId}
									role="region"
									aria-label="Diversity questions"
									definitions={diversityDefinitions}
									questionTitleProps={{
										component: 'h2',
									}}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</FormProvider>
		</SectionalFormContainer>
	)
}
