import map from 'lodash/fp/map'
import { omitDeep, omitDeepArray } from '@persuit/common-utils'
import { Deliverable, PricingGroup, Rate } from '../types'

const cleanupRates = (itemPricingModel?: string | null, rates: Rate[] = []) => {
	if (itemPricingModel === 'hourlyRates') {
		return map((rate) => {
			delete rate.pricingPreferences
			return rate
		}, rates)
	}

	return rates
}

export const purifyDeliverable = (deliverable: Partial<Deliverable>): Deliverable => {
	const cleanedDeliverable = omitDeep(deliverable, 'uuid')
	const rates = cleanupRates(cleanedDeliverable.pricingPreferences, cleanedDeliverable.rates)

	return {
		_id: cleanedDeliverable._id,
		originalDeliverableId: cleanedDeliverable.originalDeliverableId,
		deliverableTitle: cleanedDeliverable.deliverableTitle,
		pricingPreferences: cleanedDeliverable.pricingPreferences,
		deliverable: cleanedDeliverable.deliverable,
		rates: rates ? (omitDeepArray(rates, '__typename') as unknown as Rate[]) : [],
		allowFirmAddRate: cleanedDeliverable.allowFirmAddRate,
		firmRateCardItemPlaceholder: cleanedDeliverable.firmRateCardItemPlaceholder,
	}
}

export const purifyGroup = (group: Partial<PricingGroup>): PricingGroup => {
	return {
		...group,
		deliverables: (group.deliverables ?? []).map(purifyDeliverable),
		__typename: undefined,
		uuid: undefined,
	}
}
