import moment from 'moment'
import { Box, Chip, Typography, useTheme } from '@persuit/ui-components'
import { Table } from '../../../../containers/dashboards/client-dashboard/requests-view/request-card/details/status-content/request-card-data/table'

const formatText = (text: string | string[] | null | undefined): string | string[] => {
	if (!text) return ''
	return text
}

const formatDate = (date: Date | null | undefined): string => {
	if (!date) return ''
	return moment(date).format('DD MMM, YYYY')
}

type SelectedValuePreviewProps = {
	title: string
	content: string | string[]
}

const SelectedValuePreview = ({ title, content }: SelectedValuePreviewProps) => {
	const rowData = Array.isArray(content)
		? content.map((item) => ({ label: item }))
		: [{ label: content }]
	return (
		<Box>
			<Table title={title} rowData={rowData} />
		</Box>
	)
}

type ListTypeLabelProps = { label: string; showCondition: boolean }

export const ListTypeLabel = ({ label, showCondition }: ListTypeLabelProps) => {
	const theme = useTheme()
	if (!showCondition) return null

	return (
		<Chip
			aria-label={label}
			label={label}
			sx={{
				color: theme.palette.primary.main,
				background: theme.palette.primary.lighterHue,
				mr: 1,
			}}
		/>
	)
}

type ListTypesProps = {
	approved: boolean
	panel: boolean
}

const ListTypes = ({ approved, panel }: ListTypesProps) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
			<ListTypeLabel label="Approved" showCondition={approved} />
			<ListTypeLabel label="Panel" showCondition={panel} />
		</Box>
	)
}

export const ListName = ({
	panel,
	approved,
	listName,
	listId,
}: {
	panel: boolean | undefined
	approved: boolean | undefined
	listName: string
	listId: string
}) => {
	return (
		<Box>
			<Typography variant="h4" component="p" id={listId}>
				{listName}
			</Typography>
			<ListTypes approved={!!approved} panel={!!panel} />
		</Box>
	)
}

export const ListDetails = ({
	listDescription,
	instructions,
	listEstablishmentDate,
	listRefreshDate,
	firmList,
}: {
	listDescription: string | undefined
	instructions: string | undefined
	listEstablishmentDate: Date | null | undefined
	listRefreshDate: Date | null | undefined
	firmList: string[]
}) => {
	const formattedFirmList = formatText(firmList)
	const formattedListDescription = formatText(listDescription)
	const formattedInstructions = formatText(instructions)
	const formattedListEstablishmentDate = formatDate(listEstablishmentDate)
	const formattedListRefreshDate = formatDate(listRefreshDate)

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gridColumnGap: '58px',
			}}
		>
			<SelectedValuePreview title="Firm names" content={formattedFirmList} />
			<Box sx={{ display: 'grid', gridColumnStart: 2, gap: 3 }}>
				<SelectedValuePreview title="List description" content={formattedListDescription} />
				<SelectedValuePreview title="List instructions" content={formattedInstructions} />
				<SelectedValuePreview
					title="List establishment date"
					content={formattedListEstablishmentDate}
				/>
				<SelectedValuePreview title="List refresh date" content={formattedListRefreshDate} />
			</Box>
		</Box>
	)
}
