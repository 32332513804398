import { useId } from 'react'
import * as React from 'react'
import { Radio, RadioProps, RadioGroup, RadioGroupProps } from './radio'
import { FormControl, FormControlLabel, FormControlProps, FormHelperText, FormLabel } from '../form'
import { RHFFormComponentProps, useFormComponent } from '../../utilities'

export type FormRadioProps = RHFFormComponentProps<
	string | number,
	Partial<FormControlProps<'fieldset'>> & {
		/** Unique id for accessibility purpose */
		id: string
		/** Meaningful label for accessibility purpose */
		label: string | null | ((props: { id: string }) => React.ReactNode)
		helperText?: string
		values: {
			value: string | number
			label: string | React.ReactNode
		}[]

		radioGroupProps?: RadioGroupProps
		radioProps?: RadioProps
	}
>

export const FormRadio = ({
	id,
	name,
	defaultValue,
	helperText,
	label,
	error: controlledError,
	values,
	radioGroupProps,
	radioProps,
	rules,
	...props
}: FormRadioProps) => {
	const labelId = useId()
	const {
		field: { onChange, value, ref, name: fieldName },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	const labelNode = !label ? null : typeof label === 'string' ? (
		<FormLabel component="legend" id={labelId}>
			{label}
		</FormLabel>
	) : (
		label({ id: labelId })
	)

	return (
		<FormControl error={!!error?.message || controlledError} component="fieldset" {...props}>
			{labelNode}

			<RadioGroup
				id={id}
				aria-labelledby={labelId}
				onChange={onChange}
				value={value}
				name={fieldName}
				ref={ref}
				defaultValue={defaultValue}
				{...radioGroupProps}
			>
				{values.map((val, idx) => (
					<FormControlLabel
						key={`${id}-${idx}`}
						value={val.value}
						label={val.label}
						control={<Radio color="primary" {...radioProps} />}
					/>
				))}
			</RadioGroup>
			<FormHelperText>{helperText || error?.message}</FormHelperText>
		</FormControl>
	)
}
