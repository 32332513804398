// @ts-strict-ignore
import { useId, useState } from 'react'
import {
	Menu,
	MenuItem,
	useSnackbar,
	Typography,
	LibraryAddIcon,
	DeleteIcon,
	WrapTextIcon,
	FolderOpenIcon,
	ListItemIcon,
	ListItemText,
	Tooltip,
	KebabButton,
	useConfirmDialog,
} from '@persuit/ui-components'
import { usePricingListContext } from '../../pricing-list-context'
import { PricingItemMoveToGroupDialog } from './pricing-item-move-to-group-dialog'
import { usePricingItemFieldPath, useWatch } from '../../pricing-form-utils'

type PricingItemMoreActionsMenuProps = {
	index: number
	groupIndex?: number
	hasGroups?: boolean
	removeItem: () => void
	ungroupItem?: () => void
	movePricingItemIntoGroup?: (groupId: string) => void
	pricingItemIndex: number
}

export const PricingItemMoreActionsMenu = ({
	index,
	groupIndex,
	hasGroups,
	removeItem,
	ungroupItem,
	movePricingItemIntoGroup,
	pricingItemIndex,
}: PricingItemMoreActionsMenuProps) => {
	const moreActionsButtonId = useId()
	const moreActionsMenuId = useId()
	const { openSnackbar } = useSnackbar()

	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null)

	const handleActionBtnClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		setActionAnchorEl(event.currentTarget)
	}
	const handleActionMenuClose = (event?) => {
		event?.stopPropagation()
		setActionAnchorEl(null)
	}

	const [moveToGroupDialogOpen, setMoveToGroupDialogOpen] = useState(false)

	const moreActionsMenuOpen = Boolean(actionAnchorEl)
	const { duplicatePricingItem } = usePricingListContext()

	const { path } = usePricingItemFieldPath(index, groupIndex)
	const isInGroup = path.includes('deliverables')
	const isOriginalPricingItem = !!useWatch({
		name: `${path}.originalDeliverableId`,
	})
	const { openConfirmDialog } = useConfirmDialog()

	const DeleteListItem = (
		<ListItemText
			primary="delete"
			primaryTypographyProps={{ color: 'primary', variant: 'button' }}
		/>
	)

	const pricingItemPosition = pricingItemIndex + 1

	return (
		<>
			<KebabButton
				id={moreActionsButtonId}
				onClick={handleActionBtnClick}
				aria-label={`Pricing item ${pricingItemPosition} actions`}
				aria-controls={moreActionsMenuOpen ? moreActionsMenuId : undefined}
			/>
			{moreActionsMenuOpen && (
				<Menu
					id={moreActionsMenuId}
					open={Boolean(actionAnchorEl)}
					onClose={handleActionMenuClose}
					anchorEl={actionAnchorEl}
					aria-labelledby={moreActionsButtonId}
				>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation()
							if (!isOriginalPricingItem) {
								openConfirmDialog({
									title: 'Are you sure you want to delete this item?',
									content: (
										<Typography variant="body1" component="span">
											This cannot be undone.
										</Typography>
									),
									actions: [
										{ label: 'Cancel', type: 'secondary', close: true },
										{
											label: 'Delete',
											type: 'primary',
											close: true,
											action: () => {
												removeItem()
												openSnackbar('Item has been deleted.')
											},
										},
									],
								})
							}
						}}
						disabled={isOriginalPricingItem}
						disableTouchRipple={isOriginalPricingItem}
						focusRipple={!isOriginalPricingItem}
						aria-label="Delete item"
						sx={{
							pointerEvents: 'auto !important',
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<ListItemIcon>
							<DeleteIcon color="primary" fontSize="small" />
						</ListItemIcon>
						{isOriginalPricingItem ? (
							<Tooltip
								title="Once set, pricing items cannot be deleted."
								arrow={true}
								placement="top"
							>
								{DeleteListItem}
							</Tooltip>
						) : (
							DeleteListItem
						)}
					</MenuItem>

					{isInGroup ? (
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								openConfirmDialog({
									title: 'Are you sure you want to ungroup this item?',
									content: null,
									actions: [
										{ label: 'Cancel', type: 'secondary', close: true },
										{
											label: 'Ungroup',
											type: 'primary',
											close: true,
											action: () => {
												if (ungroupItem) {
													ungroupItem()
													openSnackbar('Item has been ungrouped')
												}
											},
										},
									],
								})
							}}
							aria-label="Ungroup item"
						>
							<ListItemIcon>
								<WrapTextIcon color="primary" fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary="ungroup"
								primaryTypographyProps={{ color: 'primary', variant: 'button' }}
							/>
						</MenuItem>
					) : (
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								setMoveToGroupDialogOpen(true)
							}}
							aria-label="Move item to a group"
							disabled={!hasGroups}
						>
							<ListItemIcon>
								<FolderOpenIcon color="primary" fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary="move to a group"
								primaryTypographyProps={{ color: 'primary', variant: 'button' }}
							/>
						</MenuItem>
					)}

					<MenuItem
						onClick={(e) => {
							e.stopPropagation()
							if (typeof groupIndex !== 'undefined') {
								duplicatePricingItem(groupIndex, index)
							} else {
								duplicatePricingItem(index)
							}
							handleActionMenuClose()
						}}
						aria-label="Duplicate item"
					>
						<ListItemIcon>
							<LibraryAddIcon color="primary" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary="duplicate"
							primaryTypographyProps={{ color: 'primary', variant: 'button' }}
						/>
					</MenuItem>
				</Menu>
			)}

			<PricingItemMoveToGroupDialog
				fieldPath={path}
				pricingItemIndex={pricingItemIndex}
				movePricingItemIntoGroup={movePricingItemIntoGroup}
				onClose={() => {
					setMoveToGroupDialogOpen(false)
				}}
				open={moveToGroupDialogOpen && !isInGroup}
			/>
		</>
	)
}
