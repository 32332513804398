import { SvgIcon, SvgIconProps } from '../components/svg'

type WiderWindowIconProps = SvgIconProps

export const WiderWindowIcon = ({ ...props }: WiderWindowIconProps) => {
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M1.11582 6.5C0.498132 6.5 0 5.99664 0 5.37248V1.62752C0 1.00336 0.498132 0.5 1.11582 0.5H22.8842C23.5019 0.5 24 1.00336 24 1.62752V5.31208C24 5.93624 23.5019 6.4396 22.8842 6.4396C22.2665 6.4396 21.7684 5.93624 21.7684 5.31208V2.7651H2.24159V5.36242C2.24159 5.98658 1.74346 6.48993 1.12578 6.48993L1.11582 6.5Z" />
				<path d="M3.62 7.84C3.84 7.61 4.13 7.5 4.43 7.5C4.75 7.5 5.04 7.64 5.25 7.85C5.46 8.07 5.59 8.35 5.59 8.67C5.59 8.99 5.46 9.28 5.25 9.49L3.93 10.84H9.84C10.14 10.84 10.44 10.95 10.67 11.18C10.9 11.41 11 11.71 11 12C11 12.29 10.9 12.59 10.67 12.82C10.44 13.05 10.14 13.16 9.84 13.16H3.93L5.26 14.52C5.49 14.75 5.59 15.04 5.59 15.33C5.59 15.65 5.46 15.94 5.25 16.15C5.04 16.36 4.75 16.5 4.43 16.5C4.11 16.5 3.82 16.36 3.61 16.15L0.34 12.82C0.24 12.73 0.15 12.6 0.08 12.43C0.02 12.28 0 12.14 0 12C0 11.86 0.02 11.71 0.08 11.57C0.15 11.4 0.25 11.27 0.34 11.18L3.62 7.84Z" />
				<path d="M20.38 16.16C20.16 16.39 19.87 16.5 19.57 16.5C19.25 16.5 18.96 16.36 18.75 16.15C18.54 15.93 18.41 15.65 18.41 15.33C18.41 15.01 18.54 14.72 18.75 14.51L20.07 13.16H14.16C13.86 13.16 13.56 13.05 13.33 12.82C13.1 12.59 13 12.29 13 12C13 11.71 13.1 11.41 13.33 11.18C13.56 10.95 13.86 10.84 14.16 10.84H20.07L18.74 9.48C18.51 9.25 18.41 8.96 18.41 8.67C18.41 8.35 18.54 8.06 18.75 7.85C18.96 7.64 19.25 7.5 19.57 7.5C19.89 7.5 20.18 7.64 20.39 7.85L23.66 11.18C23.76 11.27 23.85 11.4 23.92 11.57C23.98 11.72 24 11.86 24 12C24 12.14 23.98 12.29 23.92 12.43C23.85 12.6 23.75 12.73 23.66 12.82L20.38 16.16Z" />
				<path d="M0 18.6275C0 18.0034 0.498132 17.5 1.11582 17.5L1.12578 17.5101C1.74346 17.5101 2.24159 18.0134 2.24159 18.6376V21.2349H21.7684V18.6879C21.7684 18.0638 22.2665 17.5604 22.8842 17.5604C23.5019 17.5604 24 18.0638 24 18.6879V22.3725C24 22.9966 23.5019 23.5 22.8842 23.5H1.11582C0.498132 23.5 0 22.9966 0 22.3725V18.6275Z" />
			</g>
		</SvgIcon>
	)
}
