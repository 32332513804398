import { useId } from 'react'
import { Box, Typography } from '@persuit/ui-components'
import { useActions, useStore } from '../../store'
import {
	comparisonValueCellHeaders,
	comparisonValueRowId,
	totalPriceIntelligenceCellHeaders,
} from '../accessibilty'

import { RowHeaderCell, Row } from '../components'
import { ComparisonValueCell } from './comparison-value-cell'
import { SortButton, SortOrder } from '../components/sort-button'
import { ComparisonValueIntelligenceCell } from './comparison-value-intelligence-cell'

export const ComparisonValueRow = () => {
	const { request, responses, showIntelligenceColumn, currentSort, isClient } = useStore(
		(state, s) => ({
			showIntelligenceColumn: s.showIntelligenceColumn(),
			responses: s.displayedResponses(),
			request: state.request,
			currentSort: state.sort,
			isClient: !state.isFirm,
		}),
	)

	const { setSort } = useActions()

	const titleId = useId()

	const {
		detail: { currency },
	} = request

	const sortOrder = currentSort?.type === 'comparisonValue' ? currentSort.order : null

	const sortByComparisonValue = (order: SortOrder) => {
		setSort({
			type: 'comparisonValue',
			order,
		})
	}

	return (
		<Row>
			<RowHeaderCell cellProps={{ id: comparisonValueRowId, 'aria-labelledby': titleId }}>
				<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
					<Typography variant="body1" id={titleId}>
						Comparison Value
					</Typography>
					{isClient && <SortButton sortOrder={sortOrder} onClick={sortByComparisonValue} />}
				</Box>

				<Typography variant="caption">
					This is the auto-sum of pricing items excluding hourly rates and percentage items. This
					will be used to provide a price ranking.
				</Typography>
			</RowHeaderCell>

			{showIntelligenceColumn && (
				<ComparisonValueIntelligenceCell headers={totalPriceIntelligenceCellHeaders} />
			)}

			{responses.map((response) => {
				return (
					<ComparisonValueCell
						headers={comparisonValueCellHeaders(response._id)}
						key={response._id}
						responseId={response._id}
						currency={currency!}
						response={response}
					/>
				)
			})}
		</Row>
	)
}
