import orderBy from 'lodash/orderBy'
import proposalStates from '../../../../../common/data/rfp-proposal-states'
import inviteStates from '../../../../../common/data/rfp-invite-states'
import { getOrEmptyString, getOrNull } from '../../../../utils/lenses.js'

export const findProposalsForInvite = (proposal, invite) => {
	return proposal.org._id.toString() === invite.item._id.toString()
}

// These are the 3 states a proposal can be in to give feedback
export const getFinalizedAndAcceptedAndUnderRevision = (proposal) => {
	return (
		proposal.status === proposalStates.FINALIZED ||
		proposal.status === proposalStates.ACCEPTED ||
		proposal.status === proposalStates.UNDER_REVISION
	)
}

export const getProposalStatus = (proposal, invite) => {
	if (invite.status === inviteStates.ELIMINATED) {
		return Object.assign({ proposalStatus: 'Unsuccessful' }, proposal)
	}

	if (proposal.status === proposalStates.ACCEPTED) {
		return Object.assign({ proposalStatus: 'Selected' }, proposal)
	}

	return Object.assign({ proposalStatus: '' }, proposal)
}

export const removeProposalsWithFeedback = (acc, proposal) => {
	const hasFeedback = getOrNull('feedback', proposal)
	if (!hasFeedback) {
		acc.push(proposal)
	}
	return acc
}

export const getInvitesWithProposals = (invites, proposals) => {
	return invites.map((invite) => {
		// Add the proposals for this invite
		return {
			...invite,
			orgId: getOrEmptyString('item._id', invite),
			orgName: getOrEmptyString('item.name', invite),
			proposals: proposals
				//Find the proposals for the invite
				.filter((proposal) => findProposalsForInvite(proposal, invite))
				//We only want finalized and accepted proposals
				.filter(getFinalizedAndAcceptedAndUnderRevision)
				//adding new status property for the UI to use
				.map((proposal) => getProposalStatus(proposal, invite))
				//remove proposals with feedback
				.reduce(removeProposalsWithFeedback, []),
		}
	})
}

export const removeInvitesWithNoProposals = (invitesWithProposals) => {
	return invitesWithProposals.reduce((acc, invite) => {
		if (invite.proposals.length === 0) {
			return acc
		}
		acc.push(invite)
		return acc
	}, [])
}

export const getFormattedInvitesAndProposals = (invitesWithNoProposalsRemoved) => {
	return invitesWithNoProposalsRemoved.map((invite) => {
		if (invite.proposals.some((proposal) => proposal.status === proposalStates.ACCEPTED)) {
			return Object.assign({ accordionStatus: 'Selected' }, invite)
		} else if (invite.status === inviteStates.ELIMINATED) {
			return Object.assign({ accordionStatus: 'Unsuccessful' }, invite)
		}

		return invite
	})
}

export const getSortInvites = (invitesAndProposals) => {
	// Sort with accordionStatus 'Unsuccessful' invites first
	// Sort alphabetically
	const sortedByNameAndStatus = orderBy(
		invitesAndProposals,
		['accordionStatus', 'item.name'],
		['desc', 'asc'],
	)

	return sortedByNameAndStatus
}

const getInvite = (invites, firmId) => {
	return invites.filter((invite) => invite.item._id.toString() === firmId)
}

export const arrangeFirmsAndProposals = (invites, proposals) => {
	// Match up invited firm orgs with their corresponding proposals
	const invitesWithProposals = getInvitesWithProposals(invites, proposals)

	// remove firms with no proposals
	const invitesWithNoProposalsRemoved = removeInvitesWithNoProposals(invitesWithProposals)
	// Add new property to the invite for the top level accordion UI
	const formattedInvitesAndProposals = getFormattedInvitesAndProposals(
		invitesWithNoProposalsRemoved,
	)

	// Sort invites
	const sortedInvitesAndProposals = getSortInvites(formattedInvitesAndProposals)

	return sortedInvitesAndProposals
}

export const arrangeFirmsAndProposalsSingleFirm = (invites, proposals, firmId) => {
	const foundInvite = getInvite(invites, firmId)

	return arrangeFirmsAndProposals(foundInvite, proposals)
}
