// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box } from '@mui/material'

import { styled } from '@persuit/ui-components'
import rfpProposalStates from '../../../common/data/rfp-proposal-states'
import persuitTheme from '../../theme/persuit-theme'
import { getOrNull } from '../../utils/lenses'
import Spacer from '../layout/spacer.jsx'
import { FirmStatus } from '../status'
import ProposalStatus from './rfp-proposal-status.jsx'

const StyledContainer = styled('div')`
	border-bottom: 1px solid ${persuitTheme.borderColor.subtle};
	padding-bottom: 0.5em;
	display: block;
	@media (min-width: 400px) {
		display: flex;
		justify-content: space-between;
	}
`

const ProposalHeader = ({ rfp, proposal, isFirmEliminated }) => {
	const status = getOrNull('status', proposal)
	if (
		isFirmEliminated &&
		status !== rfpProposalStates.REVISED &&
		status !== rfpProposalStates.WITHDRAWN
	) {
		return (
			<Box display="flex" data-testid="proposal-header-eliminated-firm-status">
				<FirmStatus rfp={rfp} rfpProposal={proposal} />
			</Box>
		)
	}

	const isFinalizedProposal = status === rfpProposalStates.FINALIZED

	return (
		<StyledContainer>
			{!isFinalizedProposal && (
				<div>
					<Spacer space={3} />
					<ProposalStatus status={status} hasParent={proposal && !!proposal.originalId} />
				</div>
			)}
		</StyledContainer>
	)
}

export default ProposalHeader
