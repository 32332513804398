import * as React from 'react'
import {
	Box,
	BoxProps,
	SROnly,
	Typography,
	StarIcon,
	Star13Icon,
	Star23Icon,
} from '@persuit/ui-components'
import numeral from 'numeral'

import getColor from '../../../../../../utils/star-colors'

type StarRatingProps = {
	value: number
	scale: number
}

/*
 * Seperates the value into a whole number and remainder
 * the remainder is used to know which thirds bucket it belongs to
 * example: 2.3 seperates into a whole of 2 and remainder of 0.3 returning 2 stars and a 1/3 star
 */
export const StarRating = ({ value, scale }: StarRatingProps) => {
	const color = getColor(value)
	const stars: Array<React.ReactNode> = []
	const whole = Math.floor(value)
	const remainder = value - whole

	// whole stars
	for (let i = 0; i < whole; i++) {
		stars.push(
			<StarIcon
				key={`star-full-${i}`}
				sx={{
					margin: `${-4 * scale}px ${-2 * scale}px`,
					height: `${24 * scale}px`,
					width: `${24 * scale}px`,
					color,
				}}
			/>,
		)
	}

	// return whole number
	if (remainder <= 0.15) {
		return <>{stars}</>
	}
	// return 1/3 of a star
	else if (remainder >= 0.15 && remainder <= 0.45) {
		stars.push(
			<Star13Icon
				key={`star-third-${remainder}`}
				sx={{
					margin: `${-4 * scale}px ${-2 * scale}px`,
					height: `${24 * scale}px`,
					width: `${24 * scale}px`,
					color,
				}}
			/>,
		)
	}
	// return 2/3 of a star
	else if (remainder <= 0.75) {
		stars.push(
			<Star23Icon
				key={`star-2-thirds-${remainder}`}
				sx={{
					margin: `${-4 * scale}px ${-2 * scale}px`,
					height: `${24 * scale}px`,
					width: `${24 * scale}px`,
					color,
				}}
			/>,
		)
	}
	// return whole star
	else if (remainder <= 0.95) {
		stars.push(
			<Star13Icon
				key={`star-full-${remainder}`}
				sx={{
					margin: `${-4 * scale}px ${-2 * scale}px`,
					height: `${24 * scale}px`,
					width: `${24 * scale}px`,
					color,
				}}
			/>,
		)
	}
	return <>{stars}</>
}

type StarProps = {
	sx: BoxProps['sx']
	value: number
	scale: number
	variant: any
	fontSize: number
	color: string
	label: string
}

export const Stars = ({ value, scale = 1, variant, fontSize, color, sx, label }: StarProps) => {
	const width = '40%'

	return (
		<Box sx={sx}>
			<Box aria-hidden="true">
				{value ? (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							variant={variant}
							fontSize={fontSize}
							color={color}
							textAlign="right"
							data-testid="proposal-score-number"
							sx={{ mr: 1, width }}
						>
							{value >= 1 && numeral(value).format('0.0')}
						</Typography>
						<Box sx={{ flexGrow: 1 }}>
							<StarRating value={value} scale={scale} />
						</Box>
					</Box>
				) : (
					<Typography
						variant={variant}
						fontSize={fontSize}
						color={color}
						textAlign="right"
						sx={{ width, visibility: value === 0 ? 'hidden' : undefined }}
					>
						N/A
					</Typography>
				)}
			</Box>
			<SROnly>{`${label}: ${value}`}</SROnly>
		</Box>
	)
}
