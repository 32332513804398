import { useId } from 'react'
import {
	Box,
	FormCheckbox,
	FormTextField,
	FormMultiTag,
	Spacer,
	Typography,
	PopoverHelp,
	FormRichTextEditor,
	FormFileUploadList,
	FileScanState,
	Form,
	Tooltip,
} from '@persuit/ui-components'
import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'

export type File = {
	_id: string
	filename?: string | null
	fileStatus?: FileScanState | null
	length?: number | null
}

export type ConflictCheckFormValues = {
	ccRequired: boolean
	legalEntity?: string | null
	counterParties: string[]
	ccInformation: string | null
	files: File[]
}

export type ConflictCheckFormProps = {
	conflictCheckRequired?: boolean
	refetchFiles: () => Promise<File[]>
	saveSection: (input: ConflictCheckFormValues) => Promise<void>
	sectionData: ConflictCheckFormValues
	sectionErrors: null | {
		files?: string
	}
	isSystemTemplate?: boolean
}

export const ConflictCheckForm = ({
	conflictCheckRequired,
	sectionData,
	saveSection,
	refetchFiles,
	sectionErrors,
	isSystemTemplate = false,
}: ConflictCheckFormProps) => {
	const methods = useSectionalForm(sectionData, saveSection, sectionErrors)
	const isConflictCheckRequired = methods.watch('ccRequired')
	const contentId = useId()
	const checkboxDescriptionId = useId()

	return (
		<SectionalFormContainer label="Conflict check section" focusOnMount={true}>
			<Form methods={methods}>
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Conflict Check</Typography>
					<Spacer shape="row" space={1} />

					{conflictCheckRequired && (
						<span style={{ display: 'none' }} id={checkboxDescriptionId}>
							Conflict check cannot be disabled as it has been enabled
						</span>
					)}

					<Tooltip
						disabled={!conflictCheckRequired}
						title="Conflict check cannot be disabled as it has been enabled"
						arrow={true}
						placement="top"
					>
						<FormCheckbox
							disabled={conflictCheckRequired ? true : undefined}
							name="ccRequired"
							label="Include Conflict Check / T&Cs / NDA"
							checkboxProps={{
								color: 'primary',
								inputProps: {
									'aria-expanded': isConflictCheckRequired,
									'aria-controls': contentId,
									'aria-describedby': checkboxDescriptionId,
								},
							}}
							color="primary"
						/>
					</Tooltip>

					{isConflictCheckRequired && (
						<Box id={contentId}>
							<Box display="flex" alignItems="baseline">
								<FormTextField
									name="legalEntity"
									id="legal-entity-field"
									required={true}
									label="Legal entity requesting conflict check / T&Cs / NDA"
									fullWidth={true}
								/>
								<PopoverHelp
									content={
										<Typography variant="body2">
											This is what people will see before they have cleared conflicts and/or
											accepted terms and/or signed an NDA. Firms need to clear conflicts and/or
											accept terms and/or sign an NDA before viewing the matter details.
										</Typography>
									}
									triggerButtonProps={{ 'aria-label': 'More information on legal entity' }}
								/>
							</Box>
							<Spacer shape="row" space={2} />
							{!isSystemTemplate && (
								<Box display="flex" alignItems="baseline">
									<FormMultiTag
										id="counterParties"
										name="counterParties"
										label="Counter party name"
									/>
									<PopoverHelp
										content={
											<Typography variant="body2">
												Enter the complete names of any counter parties that the law firms will need
												to clear conflicts against.
											</Typography>
										}
										triggerButtonProps={{ 'aria-label': 'More information on counter parties' }}
									/>
								</Box>
							)}

							<Spacer shape="row" space={2} />
							<Box display="flex" flexDirection="row" alignItems="baseline">
								<FormRichTextEditor
									id="cc-information"
									label="I want the firms to consider the following when clearing conflicts and/or accepting terms and/or signing an NDA"
									name="ccInformation"
									height={380}
									containerProps={{ flexGrow: 1 }}
								/>
								<PopoverHelp
									message={
										<Typography variant="body2">
											Include any information that you would like the firm to take into account when
											clearing conflicts and/or accepting terms and/or signing an NDA. For example,
											if the matter is very sensitive and you want to avoid both legal and
											commercial conflicts, or you do not want the firms to approach any
											counter-party without first obtaining your permission.
										</Typography>
									}
									triggerButtonProps={{
										'aria-label': 'More information on conflict check information',
									}}
								/>
							</Box>
							<Spacer space={2} />

							{!isSystemTemplate && (
								<FormFileUploadList refetchFiles={refetchFiles} name="files" multiple={true} />
							)}
						</Box>
					)}
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
