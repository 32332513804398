import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Box, Tooltip, Brightness1Icon } from '@persuit/ui-components'
import { colorCoreGreen80, colorCoreGrey60 } from '@persuit/ui-components/tokens'

const GET_FIRM_USERS_VIEWING_RFP_QUERY = gql`
	query GetFirmsViewingRfp($rfpId: ID!, $orgId: ID!) {
		getFirmsViewingRfp(rfpId: $rfpId, orgId: $orgId)
	}
`

type OnlineStatusIndicatorContent = {
	loading: boolean
	error: boolean
	firmViewingRfp: boolean
}

export const OnlineStatusIndicatorContent = ({
	loading,
	error,
	firmViewingRfp,
}: OnlineStatusIndicatorContent) => {
	const isFirmViewing = !loading && !error && firmViewingRfp

	return (
		<Box ml={1}>
			<Tooltip
				title={isFirmViewing ? 'Currently watching the auction' : 'Not watching the auction'}
			>
				<Brightness1Icon
					sx={{
						color: isFirmViewing ? colorCoreGreen80 : colorCoreGrey60,
						fontSize: '1rem',
					}}
				/>
			</Tooltip>
		</Box>
	)
}

export const OnlineStatusIndicator = ({ rfpId, orgId }: { rfpId: string; orgId: string }) => {
	// Poll every 30 seconds. Firms update their status every 30 seconds also.
	const { data, loading, error } = useQuery(GET_FIRM_USERS_VIEWING_RFP_QUERY, {
		variables: { rfpId, orgId },
		pollInterval: 30000,
	})

	const firmViewingRfp = data?.getFirmsViewingRfp
	const hasGqlError = !!error

	return (
		<OnlineStatusIndicatorContent
			loading={loading}
			error={hasGqlError}
			firmViewingRfp={firmViewingRfp}
		/>
	)
}
