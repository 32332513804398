import { print as printGQL } from 'graphql'

import axios from '../axios'
import getUserActivityQuery from '../graphql/queries/getUserActivity-gql'
import saveUserActivityMutation from '../graphql/mutations/saveUserActivity-gql'

const FETCH_USER_ACTIVITY = 'FETCH_USER_ACTIVITY'
const SAVED_USER_ACTIVITY = 'SAVED_USER_ACTIVITY'

function getUserActivity() {
	return (dispatch) => {
		return axios
			.post('/graphql/', {
				query: printGQL(getUserActivityQuery),
			})
			.then((res) => {
				const userActivity = res.data.data.getUserActivity

				return dispatch({
					type: FETCH_USER_ACTIVITY,
					userActivity,
				})
			})
	}
}

function saveUserActivity() {
	return (dispatch, getState) => {
		const userActivityState = getState().userActivity
		const _id = userActivityState ? userActivityState._id : null

		return axios
			.post('/graphql/', {
				query: printGQL(saveUserActivityMutation),
				variables: {
					_id,
				},
			})
			.then((res) => {
				const userActivity = res.data.data.saveUserActivity
				return dispatch({
					type: SAVED_USER_ACTIVITY,
					userActivity,
				})
			})
	}
}

export default {
	FETCH_USER_ACTIVITY,
	SAVED_USER_ACTIVITY,

	getUserActivity,
	saveUserActivity,
}
