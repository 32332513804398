import { forwardRef, LegacyRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { noop } from '@persuit/common-utils'
import STYLE from '../style'
import { useTheme } from '../../../theme'
import { Box, BoxProps } from '../../box'
import { Typography } from '../../typography'

import { FormLabel } from '@mui/material'
import { CharacterCount } from '../character-count'
import { calculateRichTextCharacter } from '../calculate-rich-text-character'
import { CONTENT_LANGS } from '../content-langs'

export type RichTextEditorProps = {
	/** Unique id required for accessibility purpose */
	id: string
	label: string
	/** Hide visual display of the label but still rendering it for accessibility purpose  */
	hideLabel?: boolean
	/** Sets the height in pixels of the text area */
	height: number
	/** Set to true to have the text area auto focus on render. Defaults to false. */
	autoFocus?: boolean
	/** Sets the name of the text area */
	name: string
	onBlur?: (content: string) => void
	onChange?: (content: string) => void
	value?: string
	error?: boolean
	helperText?: string
	containerProps?: BoxProps
	characterCount?: boolean
	maxChars?: number
	/** Remove advanced items like table, image and only provide basic rich text editing tools */
	simplified?: boolean
	required?: boolean
	//** Adjusts the core behaviour to call this function on enter, and block input to the editor. Doesn't affect modified enter (i.e. SHIFT + ENTER) */
	onEnter?: () => void
}

export const RichTextEditor = forwardRef(
	(
		{
			id,
			label,
			height,
			autoFocus = false,
			name = '',
			onBlur = noop,
			onChange,
			value,
			error,
			helperText,
			hideLabel = false,
			containerProps,
			characterCount = false,
			maxChars,
			simplified = false,
			required = false,
			onEnter,
		}: RichTextEditorProps,
		ref: LegacyRef<Editor>,
	) => {
		const isTest = process.env.NODE_ENV === 'test'
		const { palette } = useTheme()
		const contentLength = calculateRichTextCharacter(value ?? '')

		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<Box
				{...containerProps}
				// Prevent Escape to bubble up the chain causing undesired effect, especially in MUI Dialog
				onKeyDown={(e) => {
					if (e.code === 'Escape') {
						e.stopPropagation()
					}
				}}
			>
				<FormLabel
					htmlFor={id}
					sx={{
						display: !isTest && hideLabel ? 'none' : undefined,
						color: error ? palette.error.main : undefined,
					}}
					required={required}
					aria-hidden={isTest ? undefined : 'true'}
				>
					{label}
				</FormLabel>
				<Box border={error ? `1px solid ${palette.error.main}` : undefined} mt={0.5}>
					<Editor
						ref={ref}
						textareaName={name}
						onBlur={(_, editor) => {
							const content = editor.getContent()
							onBlur(content)
						}}
						onKeyPress={(e) => {
							e.stopPropagation()
						}}
						onKeyDown={(e) => {
							e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && onEnter?.()
						}}
						onEditorChange={onChange}
						value={value}
						id={id}
						init={{
							setup: onEnter
								? (editor) => {
										editor.on('keydown', (e) => {
											if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) e.preventDefault()
										})
								  }
								: undefined,
							auto_focus: autoFocus ? true : undefined,
							skin: 'persuit',
							height,
							body_class: 'rich-text',
							block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4',
							branding: false,
							browser_spellcheck: true,
							content_style: STYLE,
							elementpath: false,
							entity_encoding: 'raw',
							plugins: 'lists powerpaste table advlist fullscreen help autoresize',
							min_height: simplified ? undefined : 200,
							max_height: 520,
							fullscreen_native: false,
							powerpaste_html_import: 'clean',
							powerpaste_word_import: 'clean',
							powerpaste_googledocs_import: 'clean',
							powerpaste_allow_local_images: false,
							invalid_elements: 'img, a',
							invalid_styles: 'color background font-size',
							advlist_bullet_styles: '',
							advlist_number_styles: 'upper-alpha,lower-alpha,upper-roman,lower-roman',
							resize: true,
							autoresize_bottom_margin: simplified ? 0 : undefined,
							menubar: false,
							toolbar_mode: 'wrap',
							toolbar: simplified
								? 'language | undo redo | bold italic underline | bullist numlist blockquote help'
								: 'language | undo redo styles | bold italic underline | backcolor | alignleft aligncenter alignright | bullist numlist outdent indent | table | fullscreen help',
							content_langs: CONTENT_LANGS,
							iframe_aria_text: `${label}${
								required ? ', required' : ''
							}, rich text area. Press Alt-0 for help`,
						}}
					/>
				</Box>
				<Box
					display="flex"
					justifyContent={
						helperText && characterCount ? 'space-between' : helperText ? 'flex-start' : 'flex-end'
					}
					alignItems="center"
				>
					{helperText && (
						<Typography
							variant="caption"
							color={error ? 'error.main' : 'text.secondary'}
							role="alert"
						>
							{helperText}
						</Typography>
					)}
					{characterCount && (
						<Box textAlign="right">
							<CharacterCount
								contentLength={calculateRichTextCharacter(value ?? '')}
								validateChars={contentLength > (maxChars ?? 0) && Boolean(maxChars)}
								maxChars={maxChars}
							/>
						</Box>
					)}
				</Box>
			</Box>
		)
	},
)

RichTextEditor.displayName = 'RichTextEditor'
