// @ts-strict-ignore
import { useState, useEffect, useId } from 'react'
import {
	KeyboardDoubleArrowRightIcon,
	KeyboardDoubleArrowLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	Box,
	DialogDeprecated,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Alert,
	Typography,
	LinearProgress,
	ZipFileIcon,
	DialogClose,
	useSnackbar,
} from '@persuit/ui-components'
import { useMutation, graphql, useQuery, useSubscription } from '@persuit/ui-graphql'

import { downloadFile } from '@persuit/ui-utils'

import { FirmList } from './firm-list'

import { difference } from 'lodash'

const FIRMS_PER_SPREADSHEET = 198

const RATE_REVIEW_EXCEL_EXPORT = graphql(`
	mutation ExcelExportDialog_RateReviewExcelExport($rfpId: ID!, $firmOrgIds: [ID!]!) {
		rateReviewExcelExport(rfpId: $rfpId, firmOrgIds: $firmOrgIds) {
			reportId
		}
	}
`)

const GET_FILE_URL = graphql(`
	query ExcelExportDialog_GetFileUrl($fileId: ID!) {
		getFileUrl(fileId: $fileId) {
			url
			status
		}
	}
`)

type Firm = {
	_id: string
	name: string
}

type ExcelExportDialogProps = {
	rfpId: string
	firms: Firm[]
	open: boolean
	onClose: () => void
}

const GET_RFP = graphql(`
	query GetRfp_RateReviewExport($rfpId: ID!) {
		getRfp(_id: $rfpId) {
			_id
			headline
		}
	}
`)

export const ExcelExportDialog = ({ firms, onClose, open, rfpId }: ExcelExportDialogProps) => {
	const { data } = useQuery(GET_RFP, { variables: { rfpId }, fetchPolicy: 'cache-only' })
	const [createExcelExport, { error: reportError, loading: reportLoading, reset: resetMutation }] =
		useMutation(RATE_REVIEW_EXCEL_EXPORT, {
			fetchPolicy: 'network-only',
			onError: () => {
				setStep('selection')
			},
		})

	const [availableSearchTerm, setAvailableSearchTerm] = useState('')
	const [selectedSearchTerm, setSelectedSearchTerm] = useState('')

	const [availableOptions, setAvailableOptions] = useState<Firm[]>(firms)
	const [selectedOptions, setSelectedOptions] = useState<Firm[]>([])

	const [availableSelections, setAvailableSelections] = useState<Firm[]>([])
	const [selectedSelections, setSelectedSelections] = useState<Firm[]>([])

	const rfpHeadline = data?.getRfp?.headline ?? ''

	const firmListStyles = {
		sx: {
			flexGrow: 1,
			flexBasis: 0,
			border: '1px solid',
			borderColor: 'divider',
		},
		listProps: { sx: { maxHeight: 'calc(80vh - 400px)', minHeight: '200px' } },
	}

	const resetState = () => {
		resetMutation()
		setSelectedSearchTerm('')
		setAvailableSearchTerm('')
		setAvailableOptions(firms)
		setAvailableSelections([])
		setSelectedOptions([])
		setSelectedSelections([])
	}

	const close = () => {
		onClose()
		setTimeout(() => {
			resetState()
			setStep('selection')
		}, 500)
	}

	const [step, setStep] = useState<'selection' | 'preparing' | 'download'>('selection')
	const [fileurl, setFileurl] = useState('')
	const [reportId, setReportId] = useState('')

	async function handleCreate() {
		try {
			setStep('preparing')
			const result = await createExcelExport({
				variables: { rfpId, firmOrgIds: selectedOptions.map((firm) => firm._id) },
			})
			if (result.data) {
				setReportId(result.data.rateReviewExcelExport.reportId)
			}
		} catch (e) {
			// Do nothing, error state is handled by useMutation
		}
	}

	const buttonContainerId = useId()
	const focusButtonContainer = () => document.getElementById(buttonContainerId)?.focus()

	const selectionContent = (
		<>
			<DialogTitle variant="h2XSmall">Create Excel Export</DialogTitle>

			<DialogContent sx={{ pt: 0, maxWidth: 'min(80vw, 1100px)' }}>
				<Alert severity="info">
					<Typography variant="body1Semibold">
						Your export may be delivered via multiple files
					</Typography>
					<Typography>
						Should your export have more than 200 firms, these will be downloaded as multiple files
						within a zipped folder. However each file begins with summary and raw output tabs that
						consolidate data from all firms.
					</Typography>
				</Alert>

				<Box sx={{ display: 'flex', mt: 3 }}>
					<FirmList
						{...firmListStyles}
						searchTerm={availableSearchTerm}
						onSearchTermChange={setAvailableSearchTerm}
						title="Available firm(s)"
						searchLabel="Search available firms"
						searchPlaceholder="Search available firms..."
						options={availableOptions}
						value={availableSelections.map(({ _id }) => _id)}
						onChange={(firms) => setAvailableSelections(firms)}
					/>

					<Box
						sx={{ mx: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
						id={buttonContainerId}
						tabIndex={-1}
					>
						<Button
							aria-label="Move all available firms to selected firms"
							size="small"
							variant="outlined"
							endIcon={<KeyboardDoubleArrowRightIcon />}
							disabled={availableOptions.length === 0 || !!availableSearchTerm}
							onClick={() => {
								setAvailableOptions([])
								setSelectedOptions([...selectedOptions, ...availableOptions])
								setAvailableSelections([])
								focusButtonContainer()
							}}
						>
							Move All
						</Button>
						<Button
							aria-label="Move selected available firms to selected firms"
							size="small"
							variant="outlined"
							disabled={availableSelections.length === 0}
							endIcon={<ChevronRightIcon />}
							sx={{ mt: 2 }}
							onClick={() => {
								setAvailableOptions(difference(availableOptions, availableSelections))
								setSelectedOptions([...selectedOptions, ...availableSelections])
								setAvailableSelections([])
								focusButtonContainer()
							}}
						>
							Move Selected
						</Button>

						<Button
							aria-label="Move selected selected firms to available firms"
							size="small"
							variant="outlined"
							disabled={selectedSelections.length === 0}
							sx={{ mt: 8 }}
							startIcon={<ChevronLeftIcon />}
							onClick={() => {
								setSelectedOptions(difference(selectedOptions, selectedSelections))
								setAvailableOptions([...availableOptions, ...selectedSelections])
								setSelectedSelections([])
								focusButtonContainer()
							}}
						>
							Move Selected
						</Button>
						<Button
							aria-label="Move all selected firms to available firms"
							sx={{ mt: 2 }}
							size="small"
							variant="outlined"
							disabled={selectedOptions.length === 0 || !!selectedSearchTerm}
							startIcon={<KeyboardDoubleArrowLeftIcon />}
							onClick={() => {
								setAvailableOptions([...availableOptions, ...selectedOptions])
								setSelectedOptions([])
								setSelectedSelections([])
								focusButtonContainer()
							}}
						>
							Move All
						</Button>
					</Box>

					<FirmList
						{...firmListStyles}
						searchTerm={selectedSearchTerm}
						onSearchTermChange={setSelectedSearchTerm}
						title="Selected firm(s)"
						searchLabel="Search selected firms"
						searchPlaceholder="Search selected firms..."
						options={selectedOptions}
						value={selectedSelections.map(({ _id }) => _id)}
						onChange={(firms) => setSelectedSelections(firms)}
					/>
				</Box>

				{reportError ? (
					<Alert severity="error" sx={{ mt: 1 }}>
						There was an issue generating this file. Please try again or contact your customer
						success manager.
					</Alert>
				) : null}
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Cancel</Button>
				<Button
					variant="contained"
					onClick={handleCreate}
					disabled={selectedOptions.length === 0 || reportLoading}
					data-trackid="rate-review-create-excel-export-button"
				>
					Create
				</Button>
			</DialogActions>
		</>
	)

	return (
		<DialogDeprecated open={open} PaperProps={{ sx: { maxWidth: 'lg' } }}>
			{step === 'selection' ? (
				<>
					<DialogClose onClick={close} />
					{selectionContent}
				</>
			) : step === 'preparing' ? (
				<PreparingDialog
					reportId={reportId ?? ''}
					reportCount={Math.ceil(selectedOptions.length / FIRMS_PER_SPREADSHEET)}
					onReady={(fileurl) => {
						setFileurl(fileurl)
						setStep('download')
						setReportId('')
					}}
				/>
			) : step === 'download' ? (
				<>
					<DialogClose onClick={close} />
					<DownloadDialog downloadLink={fileurl} rfpHeadline={rfpHeadline} />
				</>
			) : null}
		</DialogDeprecated>
	)
}

type PreparingDialogProps = {
	reportCount: number
	onReady: (fileurl: string) => void
	reportId: string
}

const RATE_REVIEW_EXPORT_URL = graphql(`
	subscription rateReviewExportUrl($fileId: ID!) {
		rateReviewExcelExportUrl(fileId: $fileId) {
			reportId
			signedUrl
			__typename
		}
	}
`)

const PreparingDialog = ({ reportCount, onReady, reportId }: PreparingDialogProps) => {
	useQuery(GET_FILE_URL, {
		pollInterval: 2000,
		variables: {
			fileId: reportId,
		},
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const { status, url } = data.getFileUrl
			if (status === 'SUCCESS' && url) {
				onReady(url)
			}
		},
	})

	useSubscription(RATE_REVIEW_EXPORT_URL, {
		variables: { fileId: reportId },
	})

	const [message, setMessage] = useState(`Generating ${reportCount} report(s)`)
	const [dots, setDots] = useState(3)

	useEffect(() => {
		setTimeout(() => setMessage('Preparing your document'), 3000)
	}, [])

	useEffect(() => {
		const id = setInterval(() => setDots((dots) => (dots % 3) + 1), 1000)
		return () => clearInterval(id)
	}, [])

	return (
		<>
			<DialogTitle variant="h2XSmall">Downloading...</DialogTitle>

			<DialogContent sx={{ pb: 4, pt: 0, minWidth: '500px' }}>
				<Typography variant="body2" color="primary.main">
					{message}
					{'.'.repeat(dots)}
				</Typography>
				<LinearProgress />
			</DialogContent>
		</>
	)
}

type DownloadDialogProps = {
	downloadLink: string
	rfpHeadline: string
}

const DownloadDialog = ({ downloadLink, rfpHeadline }: DownloadDialogProps) => {
	const { openSnackbar } = useSnackbar()
	const [downloading, setDownloading] = useState(false)

	const fileName = `${rfpHeadline} - Rate-review.zip`

	return (
		<>
			<DialogTitle variant="h2XSmall">Download complete</DialogTitle>

			<DialogContent sx={{ pb: 4, pt: 0, minWidth: '500px' }}>
				<Box
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
				>
					<Box sx={{ display: 'flex' }}>
						<ZipFileIcon sx={{ mr: 1 }} />
						<Typography>{fileName}</Typography>
					</Box>
					<Button
						disabled={downloading}
						variant="contained"
						onClick={async () => {
							try {
								setDownloading(true)
								await downloadFile(downloadLink, fileName)
							} catch (e) {
								openSnackbar(
									'There was an issue generating this file. Please try again or contact your customer success manager.',
									{ variant: 'error' },
								)
							} finally {
								setDownloading(false)
							}
						}}
					>
						Download
					</Button>
				</Box>
			</DialogContent>
		</>
	)
}
