import { format, toDate } from 'date-fns'
import filter from 'lodash/fp/filter'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import length from 'lodash/fp/size'
import countProposals from '../../../common/count-proposals'
import CompletionType from '../../../common/data/request-completion-sources'
import { participateEvents } from '../../../common/data/rfp-invite-events'
import ProposalStates from '../../../common/data/rfp-proposal-states'
import RfpStates from '../../../common/data/rfp-states'
import {
	getOrEmptyArray,
	getOrEmptyString,
	getOrFalse,
	getOrNull,
	getOrUndefined,
} from '../../utils/lenses'

export const getFirmCanParticipate = (rfp) => {
	const conflictCheckResponse = getOrNull('invites[0].state.conflict.response', rfp)
	const participateResponse = getOr(
		participateEvents.UNANSWERED,
		'invites[0].state.participate.response',
		rfp,
	)

	return (
		conflictCheckResponse !== false && participateResponse !== participateEvents.NOT_PARTICIPATING
	)
}

export const isFirmEliminated = (proposal) => getOrFalse('isEliminated', proposal)

const getRequestDates = (rfp = {}, isAuction) => {
	if (isAuction) {
		return {
			auctionStart: toDate(getAuctionStart(rfp)),
			auctionEnd: toDate(getAuctionEnd(rfp)),
			initialProposalsDueBy: toDate(getInitialProposalsDueBy(rfp)),
		}
	}

	return {
		proposalsDueBy: toDate(getProposalsDueBy(rfp)),
	}
}

const getLastBidTime = (proposals = []) => {
	const validProposalStatuses = [
		ProposalStates.FINALIZED,
		ProposalStates.ACCEPTED,
		ProposalStates.COMPETITOR,
	]
	const validProposals = proposals
		.filter(({ status }) => validProposalStatuses.includes(status))
		.sort((left, right) => right.lastBidTime - left.lastBidTime)

	if (length(validProposals) < 1) {
		return 'No bids yet'
	}

	if (head(validProposals).lastBidTime) {
		const bid = format(new Date(head(validProposals).lastBidTime), 'p')

		return `Last bid: ${bid}`
	}

	return ''
}

const getRfpStatus = getOrEmptyString('status')
const getRfpDerivedStatus = getOrEmptyString('derivedStatus')
const getIsAuction = getOrFalse('auction')
const getInitialProposalsDueBy = getOrUndefined('initialProposalsDueBy')
const getProposalsDueBy = getOrUndefined('proposalsDueBy')
const getAuctionEnd = getOrUndefined('auctionEnd')
const getAuctionStart = getOrUndefined('proposalsDueBy')
const getMarkedCompleteManually = getOrUndefined('completedBy')
const getInvites = getOrEmptyArray('invites')
const getProposals = getOrEmptyArray('proposals')
const getReopenedStatusForFirm = getOrFalse('invites[0].isReOpened') // Will work for RFP fetched on firm side

export const isRfpDerivedStatusEliminated = ({ derivedStatus }) => derivedStatus === 'ELIMINATED'

export const mapServerDataToFirmStatusProps = ({ rfp, rfpProposal }) => {
	const request = {
		status: getRfpDerivedStatus(rfp),
		lastBid: getLastBidTime(rfp.proposals),
		isAuction: getIsAuction(rfp),
		markedCompleteManually: getMarkedCompleteManually(rfp) === CompletionType.USER,
		isRequestClosed: getRfpStatus(rfp) === RfpStates.CLOSED,
		...getRequestDates(rfp, getIsAuction(rfp)),
	}

	const proposal = {
		status: getOrUndefined('status', rfpProposal),
	}
	const firm = {
		canParticipate: getFirmCanParticipate(rfp),
		isEliminated: isRfpDerivedStatusEliminated(rfp) || isFirmEliminated(rfpProposal),
		isRequestReopenedForFirm: getReopenedStatusForFirm(rfp),
	}

	return { request, firm, proposal }
}

export const mapServerDataToClientStatusProps = ({ rfp }) => {
	const invites = getInvites(rfp)
	const proposalList = getProposals(rfp)
	const totalInviteCount = length(invites)
	const getConflictCheckResponse = getOrNull('state.conflict.response')
	const declinedCount = length(
		filter((invite) => getConflictCheckResponse(invite) === false, invites),
	)
	const acceptedCount = length(filter({ status: ProposalStates.ACCEPTED }, proposalList))
	const reOpenInvitesCount = length(filter({ isReOpened: true }, invites))
	const updatedAt = `Last saved: ${format(new Date(rfp.updatedAt), 'd MMM p')}`

	const request = {
		status: getRfpDerivedStatus(rfp),
		lastBid: getLastBidTime(rfp.proposals),
		isAuction: getIsAuction(rfp),
		markedCompleteManually: getMarkedCompleteManually(rfp),
		isDraft: getRfpStatus(rfp) === RfpStates.DRAFT,
		isRequestClosed: getRfpStatus(rfp) === RfpStates.CLOSED,
		isRequestCompleted: getRfpStatus(rfp) === RfpStates.COMPLETED,
		updatedAt,
		...getRequestDates(rfp, getIsAuction(rfp)),
	}

	const proposals = {
		// countProposals
		totalSubmittedProposalCount: countProposals(proposalList),
		totalAcceptedProposalCount: acceptedCount,
	}

	const firms = { totalInviteCount, declinedCount, acceptedCount, reOpenInvitesCount }

	return { request, firms, proposals }
}

// Ugly logic ugly code
export const mapServerDataToProposalProps = (rfp) => {
	const proposalList = getProposals(rfp)

	if (proposalList.length === 0) {
		return ''
	}

	const hasAcceptedProposals = proposalList.some(({ status }) => status === ProposalStates.ACCEPTED)
	if (hasAcceptedProposals) {
		return ProposalStates.ACCEPTED
	}
	const hasSentProposals = proposalList.some(({ status }) => status === ProposalStates.FINALIZED)
	if (hasSentProposals) {
		return ProposalStates.SENT
	}

	const hasDraftsOnly = proposalList.some(({ status }) => status === ProposalStates.DRAFT)
	if (hasDraftsOnly) {
		return ProposalStates.DRAFT
	}

	return ''
}
