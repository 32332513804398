// @ts-strict-ignore
import * as React from 'react'
import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'
import { Typography, Form, FormProps, Box, Chip, Link, Alert } from '@persuit/ui-components'
import { omitDeep } from '@persuit/common-utils'
import { Categories } from './components/scorecard-categories'

export const parseScorecardData = (categories) => {
	const parsedScoreCard = categories.map((category) => {
		const weight = parseInt(category.weight, 10)
		const parsedWeight = Number.isInteger(weight) ? weight : 0
		const integerWeight = Object.assign(category, { weight: parsedWeight })
		return omitDeep(integerWeight, '__typename')
	})

	return parsedScoreCard
}

export type ScorecardSubcategory = {
	_id?: string | null
	label?: string | null
}

export type ScorecardCategory = {
	weight?: number | null
	label?: string | null
	subcategories?: ScorecardSubcategory[] | null
}

export type ScorecardFormValues = {
	scoreCategories: ScorecardCategory[]
	/** When in auto props, the form is used in RFP form context, which has autosave and floating error boxes.
	 * When in manual mode, it's up to the consumer to dictate how the form behave including initiating RHF methods and submit.
	 */
	mode?: 'AUTO' | 'MANUAL'
}

type AutoProps = {
	saveSection: (values: { scoreCategories: ScorecardCategory[] }) => void
	sectionData: ScorecardFormValues
	sectionErrors:
		| {
				label: string | null
				index: number
				subcategories: {
					label: string
					index: number
				}[]
		  }[]
		| null
}

export type ScorecardFormProps = (
	| AutoProps
	| Omit<FormProps<ScorecardFormValues>, 'formOptions'>
) & {
	showHeading?: boolean
}
export const ScorecardForm = ({ showHeading = true, ...props }: ScorecardFormProps) => {
	let formNode: React.ReactNode = null
	const headingNode = showHeading ? (
		<Box display="flex" gap={3} alignItems="center">
			<Typography variant="h1XSmall">Scorecard</Typography>
			<Chip label="INTERNAL USE ONLY" color="primary" />
		</Box>
	) : null
	const infoNode = (
		<Alert severity="info">
			<Typography>
				Use this optional section to evaluate proposals. You'll still be able to create or modify
				the scorecard after publishing.
			</Typography>
			<Link
				target="_blank"
				href="https://support.persuit.com/hc/en-us/articles/360050994673-How-do-I-use-the-Scorecard"
			>
				Learn more
			</Link>
		</Alert>
	)

	function isAutoMode(props: ScorecardFormProps): props is AutoProps {
		if (typeof (props as AutoProps).saveSection !== 'undefined') return true
		return false
	}

	if (isAutoMode(props)) {
		const { saveSection, sectionData, sectionErrors } = props
		const handleSave = async (values: ScorecardFormValues) => {
			const valuesWithoutNewCategoryKey = parseScorecardData(values.scoreCategories)

			await saveSection({
				scoreCategories: valuesWithoutNewCategoryKey,
			})
		}

		// Intentional given the form operate in two modes
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const methods = useSectionalForm(sectionData, handleSave)

		formNode = (
			<Form methods={methods}>
				<Box display="flex" flexDirection="column" gap={2}>
					{headingNode}
					{infoNode}
					<Categories errors={sectionErrors} />
				</Box>
			</Form>
		)
	} else {
		formNode = (
			<Form {...props}>
				<Box display="flex" flexDirection="column" gap={2}>
					{headingNode}
					{infoNode}
					<Categories errors={[]} />
				</Box>
			</Form>
		)
	}

	return (
		<SectionalFormContainer label="Scorecard section" focusOnMount={true}>
			{formNode}
		</SectionalFormContainer>
	)
}
