import numeral from 'numeral'
import isNumber from 'lodash/fp/isNumber'
import { firmTotalModel as firmTotalPricingModels } from '../../../../common/data/pricing-models'
import { currencySymbol as getCurrencySymbol } from '../../../utils/price-and-currency'

export const combinedRates = (responseToDeliverable) => {
	if (!responseToDeliverable) {
		return []
	}

	return [
		...(responseToDeliverable.responseToRates || []),
		...(responseToDeliverable.firmSuppliedRates || []),
	]
}

// total price value
// calculate a range from min to max for rate card

export const priceRange = (pricingModel, combinedRates, responseToDeliverable, currency) => {
	const decimalFormat = '0,0.[0000]'
	const currencyCode = currency
	const currencySymbol = getCurrencySymbol(currency)

	// flatten rates for min/max functions (Excluding empty rates)
	const rates = combinedRates.filter((rtr) => rtr.rate).map((rtr) => Number(rtr.rate))
	// For legacy proposals
	if (!rates.length && responseToDeliverable && responseToDeliverable.price) {
		return `${currencySymbol}${numeral(responseToDeliverable.price).format(
			decimalFormat,
		)} ${currencyCode}`
	}
	if (!rates.length) {
		return `-`
	}

	// if only one rate return it (not range)
	if (rates.length === 1) {
		const rate = numeral(rates[0]).format(decimalFormat)

		return `${currencySymbol}${rate} ${currencyCode}`
	}

	// range
	const min = numeral(Math.min(...rates)).format(decimalFormat)
	const max = numeral(Math.max(...rates)).format(decimalFormat)
	// otherwise return the range
	return `${currencySymbol}${min} - ${currencySymbol}${max} ${currencyCode}`
}

export const formatPercentageValueWithZero = (percentageValue) => {
	if (isNumber(percentageValue)) {
		return `${percentageValue}%`
	}

	if (percentageValue === 'XX') {
		return `${percentageValue}%`
	}

	return '-'
}

export const isPercentagePricingModel = (pricingModel) => {
	return [
		firmTotalPricingModels.DISCOUNTPERCENTAGE,
		firmTotalPricingModels.CONTINGENCYPERCENTAGE,
	].includes(pricingModel)
}

export const formatDuration = (totalHours, phaseDuration) => {
	if (totalHours && phaseDuration === 'months') {
		return `${totalHours} mo`
	}

	if (totalHours && phaseDuration !== 'months') {
		return `${totalHours} hrs`
	}

	return '-'
}
