import { Fragment } from 'react'
import { Typography, styled } from '@persuit/ui-components'
import { clientPhaseModel } from '../../../../common/data/pricing-models'
import useTranslation from '../../../custom-hooks/translation-hook'
import { getOrEmptyString, getOrNull } from '../../../utils/lenses'
import RfprItem from '../../pricing-card-detail-view/components/rfpr-item'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 40%;
`
const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 54.5% !important;
	gap: 8px;
`

const StyledExcludedFromScope = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-spacing: 10px;
`

const ExcludedFromScope = ({ rfpr, currency, rateCardView }) => {
	const { t } = useTranslation()

	const pricingModel = getOrEmptyString('pricingModel', rfpr)

	if (pricingModel === clientPhaseModel.RATECARD) {
		return rateCardView
	}

	const rfprPrice = getOrNull('rfprPrice', rfpr)
	const rfprPricingModel = getOrNull('rfprPricingModel', rfpr)
	const rfprHours = getOrNull('rfprHours', rfpr)
	const rfprPercentage = getOrNull('rfprPercentage', rfpr)
	const hasRfpr = rfprPrice || rfprPricingModel || rfprHours || rfprPercentage

	return (
		<Fragment>
			<StyledExcludedFromScope>
				<StyledItemLeft></StyledItemLeft>
				<StyledItemRight>
					<div>
						<Typography strikeThrough={hasRfpr} data-testid="item-subtotal-rate">
							{t('rfpProposalDetail.excluded')}
						</Typography>
					</div>
				</StyledItemRight>
			</StyledExcludedFromScope>
			{hasRfpr && (
				<RfprItem
					rfprPrice={rfprPrice}
					originalPricingModel={pricingModel}
					rfprPercentage={rfprPercentage}
					rfprPricingModel={rfprPricingModel}
					rfprHours={rfprHours}
					currency={currency}
				/>
			)}
		</Fragment>
	)
}

export default ExcludedFromScope
