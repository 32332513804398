import { Fragment, useId } from 'react'
import { Rfp } from '../../../types'
import { FileDownloadList, Spacer, Typography, RichTextView } from '@persuit/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { DeepPick } from '@persuit/common-utils'
import { HeadlineSeparator } from '../headline-separator'

export type RfpDetailConflictSectionDocType = DeepPick<
	Rfp,
	'ccRequired' | 'ccInformation' | 'legalEntity' | 'counterParties' | 'files' | 'useCase'
>

type RfpDetailConflictSectionProps = {
	document: RfpDetailConflictSectionDocType
}

export const RfpDetailConflictSection = ({ document }: RfpDetailConflictSectionProps) => {
	const ccRequired = document?.ccRequired ?? false
	const ccInformation = document?.ccInformation ?? ''
	const files = document?.files ?? []
	const legalEntity = document?.legalEntity ?? ''
	const counterParties = document?.counterParties ?? []
	const counterPartiesId = useId()

	if (document?.useCase === 'rateReview') return null

	if (!ccRequired) return null
	return (
		<Fragment>
			<HeadlineSeparator headline="Conflict and Participation Information" />

			{!isEmpty(ccInformation) && (
				<Fragment>
					<RichTextView data-testid="conflict-check-description" content={ccInformation} />
					<Spacer space={3} shape="row" />
				</Fragment>
			)}

			<Typography
				variant="body2"
				sx={{
					fontStyle: 'italic',
				}}
			>
				On behalf of:
			</Typography>
			<Typography variant="body1Semibold" component="p">
				{legalEntity}
			</Typography>
			<Spacer space={3} shape="row" />

			{!isEmpty(counterParties) && (
				<Fragment>
					<Typography
						id={counterPartiesId}
						variant="body2"
						sx={{
							fontStyle: 'italic',
						}}
					>
						Counter {counterParties.length === 1 ? 'party' : 'parties'}:
					</Typography>
					<ul aria-labelledby={counterPartiesId}>
						{counterParties.map((party, i) => (
							<li aria-label="counter party" key={i}>
								<Typography variant="body1Semibold" component="p">
									{party}
								</Typography>
							</li>
						))}
					</ul>
				</Fragment>
			)}

			{files && files.length !== 0 ? (
				<>
					<Spacer space={1} shape="row" />
					<FileDownloadList files={files} label="Downloadable files for conflict check" />
				</>
			) : null}
		</Fragment>
	)
}
