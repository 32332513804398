import { Fragment } from 'react'
import { DeepPick } from '@persuit/common-utils'
import { Rfp, RfpClarification } from '../../../../types'
import isEmpty from 'lodash/fp/isEmpty'
import { Spacer } from '@persuit/ui-components'
import { ClarificationList } from '../clarification-list'

export type RfpDetailClarificationSectionDocType = DeepPick<Rfp, 'detail.clarifications'>

type RfpDetailClarificationSectionProps = {
	document: RfpDetailClarificationSectionDocType
}

export const RfpDetailClarificationSection = ({ document }: RfpDetailClarificationSectionProps) => {
	const clarifications = document?.detail?.clarifications ?? ''

	if (isEmpty(clarifications)) return null

	return (
		<Fragment>
			<ClarificationList clarifications={clarifications as Array<RfpClarification>} />
			<Spacer space={4} shape="row" />
		</Fragment>
	)
}
