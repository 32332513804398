import { useState } from 'react'
import { Box, BoxProps } from '../../box'
import { FileError, FileErrorProps } from '../file-error'
import { FormHelperText } from '../../form'
import { FileUploader, FileUploaderProps } from '../file-uploader'
import { FileList, FileListProps } from '../file-list'
import { pollUntil } from '@persuit/common-utils'
import { useFormComponent, RHFFormComponentProps } from '../../../utilities'

type FileRepresentation = FileListProps['files'][number]

export type FormFileUploadListProps = RHFFormComponentProps<
	FileRepresentation[],
	{
		pollingInterval?: number
		uploadFiles?: FileUploaderProps<FileRepresentation>['uploadFiles']
		onChange?: (files: FileRepresentation[]) => void | Promise<unknown>
		disabled?: boolean
		multiple?: boolean
		helperText?: string
		containerProps?: BoxProps
		errorProps?: FileErrorProps
		refetchFiles?: () => Promise<FileRepresentation[]>
	}
>

export function FormFileUploadList({
	rules,
	uploadFiles,
	defaultValue,
	name,
	helperText,
	containerProps,
	errorProps,
	disabled: _disabled,
	multiple,
	onChange,
	refetchFiles,
	pollingInterval = 1000,
}: FormFileUploadListProps) {
	const [error, setError] = useState(false)
	const [localDisabled, setLocalDisabled] = useState(false)
	const disabled = localDisabled || _disabled

	const { field, fieldState } = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	const files = field.value

	async function handleChange(fileRepresentations: FileRepresentation[]) {
		try {
			setLocalDisabled(true)
			setError(false)
			field.onChange(fileRepresentations)

			await onChange?.(fileRepresentations)

			if (!refetchFiles || fileRepresentations.every((rep) => rep.fileStatus !== 'queued')) return

			const scannedFiles = await pollUntil({
				maxTries: 120,
				pollingInterval,
				pollingFunction: refetchFiles,
				doneCondition: (files) =>
					fileRepresentations.every((rep) => {
						const matchingFile = files.find((file) => file._id === rep._id)
						return matchingFile && matchingFile.fileStatus !== 'queued'
					}),
			})

			field.onChange(
				fileRepresentations
					.map((rep) => scannedFiles.find((file) => file._id === rep._id))
					.filter((file) => file !== undefined),
			)
		} catch (e) {
			setError(true)
		} finally {
			setLocalDisabled(false)
		}
	}

	return (
		<Box {...containerProps}>
			<FileList files={files} onChange={handleChange} disabled={disabled} />

			<FileUploader
				uploadFiles={uploadFiles}
				files={files}
				disabled={disabled}
				onError={() => setError(true)}
				onChange={handleChange}
				multiple={multiple}
			/>

			{helperText ? <FormHelperText>{helperText}</FormHelperText> : null}

			{error || fieldState.error ? (
				<FileError {...errorProps}>
					{fieldState?.error?.message ||
						'An error occurred during upload. One or more files failed to upload.'}
				</FileError>
			) : null}
		</Box>
	)
}
