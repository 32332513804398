import { Left, Right } from './either'

// A simple transducer class. The basic idea is to process an array efficiently
// by combining multiple filters and maps into a single reducer function using
// transducers
class Transducer {
	constructor(val, passThroughData) {
		// Store the value to be iterated over internally. This will be boxed
		// using an Either monad to handle any errors that occur during
		// processing
		this._val = val

		// Options that get passed to each map/filter in the transducer chain
		this.passThroughData = passThroughData

		// Keep track of functions to apply to the data in an array (to be composed
		// when applying)
		this.fns = []
	}

	// Useful for shaping the input before processing
	pre(fn) {
		this._val = fn(this._val, this.passThroughData)
		return this
	}

	map(fn) {
		this.fns.push(makeMapTransducer(fn))
		// Return self so that chaining is possible
		return this
	}

	filter(fn) {
		this.fns.push(makeFilterTransducer(fn))
		// Return self so that chaining is possible
		return this
	}

	// Combines all maps/filters into a single reducer function and applies
	// it to the internal _val value
	apply(customReducerFn) {
		// Check if the result of a .pre call was a Left - if so return early (no
		// need to process anything)
		if (Left.prototype.isPrototypeOf(this._val)) {
			return this._val
		}

		// Make sure data is iterable before proceeding
		if (!Array.isArray(this._val)) {
			return new Left('Data is not iterable')
		}

		// Compose transducers into a single transducer function
		const combinedTransducer = (x) =>
			// This is basically like a "compose"
			this.fns.reduceRight((acc, transducer) => transducer(acc, this.passThroughData), x)

		// Default reducer function (transform values in an array)
		const mapReducer = (acc, item) => {
			// Pass through if an error has been encountered anywhere in the
			// reducer chain
			if (Left.prototype.isPrototypeOf(acc)) {
				return acc
			}

			// Otherwise keep going on the happy path
			return new Right([...acc._val, item])
		}

		// customReducerFn is an optional reducer function that can be used to override
		// the mapReducer above.
		// NOTE: the customReducerFn or mapReducer gets applied AFTER all the maps and
		// filters have been applied to each item in the _val array
		const reducer = combinedTransducer(customReducerFn || mapReducer)

		// Finally, apply the reducer to the _val value. The inital value is
		// an empty array boxed in a Right
		return this._val.reduce(reducer, new Right([]))
	}

	// Calling "either" after a transduce operation makes error handling explicit
	// No need to manually put in try/catches everywhere, just pass in a
	// leftFn for error handling
	either(rightFn, leftFn) {
		const result = this.apply()

		return result.either(rightFn, leftFn)
	}
}

const makeMapTransducer = (fn) => {
	// step is the next reducer function in the chain
	return (step, optionalDataToPassThrough) => {
		// Returns a reducer function
		return (acc, item) => {
			// Apply the mapping function, fn, and pass the result to the
			// next reducer function if no errors occurred
			const intermediateResult = fn(item, optionalDataToPassThrough)
			if (Left.prototype.isPrototypeOf(intermediateResult)) {
				// Don't pass through to the next reducer, exit early
				return intermediateResult
			}

			return step(acc, intermediateResult)
		}
	}
}

const makeFilterTransducer = (fn) => {
	// step is the next reducer function in the chain
	return (step, optionalDataToPassThrough) => {
		// Returns a reducer function
		return (acc, item) => {
			// Apply the filter function, fn, and pass the result to the
			// next reducer function only if there were no errors and the
			// filter returned true, otherwise skip the next reducer (it doesn't
			// need to see the item)
			const intermediateResult = fn(item, optionalDataToPassThrough)
			if (Left.prototype.isPrototypeOf(intermediateResult)) {
				// Don't pass through to the next reducer, exit early
				return intermediateResult
			}

			return intermediateResult ? step(acc, item) : acc
		}
	}
}

export default Transducer
