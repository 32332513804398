import {
	Box,
	FormCheckbox,
	FormDateTimePicker,
	FormSelect,
	FormTimezoneSelect,
	MenuItem,
	PopoverHelp,
	Spacer,
	TimezoneAbbreviation,
	Typography,
	Alert,
	AlertTitle,
} from '@persuit/ui-components'
import { UseCase } from '@persuit/ui-graphql/generated'
import { Fragment, useId } from 'react'
import { FormProvider } from 'react-hook-form'
import { FormSectionBaseProps } from '../../rfp-template-form/types'
import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'

import { AuctionDurationPicker } from './auction-duration-picker'

export type AuctionFormSectionData = {
	auction: boolean
	auctionFirmVisibility: string | null
	timezoneId?: number | null
	initialProposalsDueBy?: Date | null
	proposalsDueBy?: Date | null
	/** Duration in minutes */
	auctionDuration?: number | null
}

type AuctionFormProps = FormSectionBaseProps<AuctionFormSectionData> & {
	title?: string
	useCase?: UseCase
	includeTimeline?: boolean
	sectionErrors?: null | Record<keyof AuctionFormSectionData, string>
	addAuctionToExistingRfp?: boolean
	possibleBadAuction?: boolean
}

export const AuctionForm = ({
	title,
	useCase,
	sectionData,
	sectionErrors,
	saveSection,
	includeTimeline = false,
	addAuctionToExistingRfp = false,
	possibleBadAuction = false,
}: AuctionFormProps) => {
	const methods = useSectionalForm(sectionData, saveSection, sectionErrors)
	const disableAuction = useCase === 'rateReview'
	const auction = methods.watch('auction') && !disableAuction
	const timelineSectionId = useId()
	const auctionSectionId = useId()
	const timezoneId = methods.watch('timezoneId')

	return (
		<SectionalFormContainer label="Timeline | Reverse Auction section" focusOnMount={true}>
			<FormProvider {...methods}>
				<Box display="flex" flexDirection="column">
					{!addAuctionToExistingRfp && (
						<>
							<Typography variant="h1XSmall">{title}</Typography>
							<Spacer shape="row" space={2} />
						</>
					)}

					{!addAuctionToExistingRfp && auction && possibleBadAuction && (
						<>
							<Alert role="alert" severity="warning">
								<AlertTitle>Adjust your pricing setup for best auction results</AlertTitle>
								<Typography>
									This is an auction request, but your current pricing setup may produce less than
									ideal results. To fix this, enable the total price or add a non-rate pricing item.
								</Typography>
							</Alert>
							<Spacer space={2} />
						</>
					)}

					<Box>
						{!disableAuction && !addAuctionToExistingRfp && (
							<Box display="flex" justifyContent="space-between" alignItems="center" pl={1.5}>
								<FormCheckbox
									name="auction"
									label="Reverse Auction"
									checkboxProps={{
										color: 'primary',
										inputProps: {
											'aria-controls': `${
												auction && includeTimeline ? `${timelineSectionId} ` : ''
											}${auction ? auctionSectionId : ''}`,
											'aria-expanded': auction ? 'true' : 'false',
										},
									}}
									color="primary"
								/>
								<PopoverHelp
									content={
										'A PERSUIT Auction is visible to everyone added to this Request. Rival bids appear anonymously to participating firms. You can set the auction date and duration. Once the auction is enabled it cannot be undone.'
									}
									triggerButtonProps={{ 'aria-label': 'Reverse auction help' }}
								/>
							</Box>
						)}

						{includeTimeline ? (
							<>
								<FormTimezoneSelect name="timezoneId" required={true} />

								{auction ? (
									<Box display="flex" alignItems="baseline" mt={3} id={timelineSectionId}>
										<FormDateTimePicker
											id="initialProposalsDueBy"
											name={'initialProposalsDueBy'}
											label="Proposals due date"
											disabled={!timezoneId}
											required={true}
										/>

										<Box display="flex" alignItems="center" ml={1}>
											<TimezoneAbbreviation timezoneId={timezoneId ?? null} />
										</Box>

										<PopoverHelp
											triggerButtonProps={{ 'aria-label': 'proposals due date help' }}
											content={
												<Typography variant="body2">
													<Typography
														display="block"
														variant="body2"
														gutterBottom={true}
														style={{ fontWeight: 500 }}
													>
														Proposals due date
													</Typography>
													This date can be changed later. This is just a benchmark due date for
													firms to submit their proposals on time. Firms can still submit proposals
													after this due date.
												</Typography>
											}
										/>
									</Box>
								) : null}

								<Box display="flex" alignItems="baseline" mt={3}>
									<FormDateTimePicker
										id="proposalsDueBy"
										name="proposalsDueBy"
										label={auction ? 'Auction start date' : 'Proposals due by'}
										disabled={!timezoneId}
										required={true}
									/>

									<Box display="flex" alignItems="center" ml={1}>
										<TimezoneAbbreviation timezoneId={timezoneId ?? null} />
									</Box>

									<PopoverHelp
										triggerButtonProps={{
											'aria-label': auction ? 'auction start date help' : 'proposals due by help',
										}}
										content={
											<Typography variant="body2">
												{auction ? (
													<>
														<Typography
															gutterBottom={true}
															variant="body2"
															display="block"
															style={{ fontWeight: 500 }}
														>
															Auction start date
														</Typography>
														This date can be changed later. Firms can continue to submit proposals
														during the Reverse Auction.
													</>
												) : (
													<>Proposal from Firms are due by the published time and date.</>
												)}
											</Typography>
										}
									/>
								</Box>

								{auction ? (
									<Box mt={3}>
										<AuctionDurationPicker />
									</Box>
								) : null}
							</>
						) : null}

						{auction ? (
							<Box display="flex" flexDirection="row" mt={2} pl={1.5} id={auctionSectionId}>
								<Box display="flex" flexDirection="column" width="60%">
									<FormSelect
										id="auction-firm-visibility-select"
										name="auctionFirmVisibility"
										label="Choose what the firms will see during auction"
									>
										<MenuItem value="rank">Price ranking only</MenuItem>
										<MenuItem value="rankAndLowestPrice">Price ranking and lowest bid</MenuItem>
										<MenuItem value="rankAndPrice">
											Price ranking and each competitor firm's current bid
										</MenuItem>
									</FormSelect>
								</Box>
								<PopoverHelp
									content={
										<Fragment>
											<Typography variant="body2">
												Firms will not be able to see competitor firm names. They can however see
												the following:
											</Typography>
											<Spacer shape="row" space={1} />
											<Typography variant="body2">
												<strong>Price ranking only:</strong> Firms will only be able to see where
												they rank amongst other competitors.
											</Typography>
											<Spacer shape="row" space={1} />
											<Typography variant="body2">
												<strong>Price ranking and lowest bid:</strong> Firms will be able to see
												their own rank. Firms will also be able to compare their 'Total Price' or
												'Comparison value' and 'Item' prices only against the lowest bidding firm.
												They will not be able to see the details of other firm's proposal.
											</Typography>
											<Spacer shape="row" space={1} />
											<Typography variant="body2">
												<strong>Price ranking and each competitor firm's current bid: </strong>{' '}
												Firms will be able to see all ranking based on 'Total Price' or 'Comparison
												value' and also competitor firm's item pricing. They will not be able to see
												additional details in the proposals.
											</Typography>
										</Fragment>
									}
									triggerButtonProps={{ 'aria-label': 'What firm will see during auction help' }}
								/>
							</Box>
						) : null}
					</Box>
				</Box>
			</FormProvider>
		</SectionalFormContainer>
	)
}
