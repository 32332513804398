// @ts-strict-ignore
import { useId, useEffect } from 'react'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Box, BoxProps, Button, Typography, useTheme, Tooltip } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro'
import { formatPriceWithCurrency, isNotNil } from '@persuit/common-utils'
import { useDescription } from '@persuit/ui-hooks'

import { useStore, useActions } from '../store'
import { SORT_LABELS } from './constants'

const formatPrice = (currency: string, amount: number) =>
	formatPriceWithCurrency(currency, amount, false, '0,0.00')

export type RateCellProps = BoxProps & {
	/** @deprecated will be removed once multi currency is enabled */
	currency: string
	revisedRate?: number | null
	revisedDelta?: number | null
	proposedRate: number | null
	delta: number | null
	/** Force the component to show revision rate. Or else, it will default to store config */
	shouldShowRevisionRates?: boolean
}

export const RateCell = ({
	proposedRate,
	delta,
	revisedDelta,
	revisedRate,
	currency: _currency,
	shouldShowRevisionRates,
	...rest
}: RateCellProps) => {
	const theme = useTheme()
	const { toggles } = useFeatureToggles()
	const isMultiCurrencyEnabled = toggles['dev-10954.multi-currency']
	const selectedCurrency = useStore((state) => state.currencyFilter)
	// _currency is deprecated and will be removed once multi currency is enabled
	const currency = (isMultiCurrencyEnabled ? selectedCurrency : _currency) ?? _currency
	const showRevisions = useStore((state) => state.showProposalRevisions)
	const showRevisionRates =
		(shouldShowRevisionRates || showRevisions) &&
		isNotNil(revisedRate) &&
		proposedRate !== revisedRate

	const getDeltaColor = (delta: number) =>
		delta === 0 ? 'black' : delta > 0 ? theme.palette.error.main : theme.palette.success.main

	const getPriceString = (price: number | null | undefined) =>
		price ? formatPrice(currency, price) : null
	const getDeltaString = (delta: number | null | undefined) =>
		typeof delta === 'number' ? `${delta > 0 ? '+' : ''}${(delta * 100).toFixed(1)}%` : null

	const proposedRateString = getPriceString(proposedRate)
	const deltaString = getDeltaString(delta)
	const revisedProposedRateString = getPriceString(revisedRate)
	const revisedDeltaString = getDeltaString(revisedDelta)

	return (
		<Box {...rest}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography
					mr={2}
					variant="body2"
					aria-label={
						proposedRateString
							? `Proposed rate: ${proposedRateString}`
							: 'No proposed rate provided'
					}
				>
					{proposedRateString ?? '-'}
				</Typography>
				{typeof delta === 'number' ? (
					<Typography
						variant="body2"
						sx={{ color: getDeltaColor(delta), whiteSpace: 'nowrap' }}
						aria-label={deltaString ? `Percentage change: ${deltaString}` : ''}
					>
						{deltaString}
					</Typography>
				) : null}
			</Box>

			{!showRevisionRates ? null : (
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						mr={2}
						variant="body2"
						strikeThrough={true}
						aria-label={
							revisedProposedRateString
								? `Previous proposed rate: ${revisedProposedRateString}`
								: ''
						}
					>
						{revisedProposedRateString ?? '-'}
					</Typography>
					{typeof revisedDelta === 'number' ? (
						<Typography
							variant="body2"
							sx={{
								color: getDeltaColor(revisedDelta),
								whiteSpace: 'nowrap',
								textDecoration: 'line-through',
							}}
							aria-label={
								revisedDeltaString ? `Previous percentage change: ${revisedDeltaString}` : ''
							}
						>
							{revisedDeltaString}
						</Typography>
					) : null}
				</Box>
			)}
		</Box>
	)
}

export const HeaderCell = ({ colDef, field }: GridColumnHeaderParams) => {
	const sortState = useStore((state) => state.sortState)

	function sortDescription() {
		if (sortState?.field !== field || !sortState.direction) return 'unsorted'
		if (field.startsWith('timekeepers')) {
			return SORT_LABELS.timekeepers[sortState.type ?? 'rate'][sortState.direction]
		}
		return SORT_LABELS[field][sortState.direction]
	}

	const menuInstructionsId = useDescription('Press CTRL + Enter to open column menu')

	const label = `${colDef.headerName}, ${sortDescription()}`

	useEffect(() => {
		const columnHeader = document.querySelector(`[role="columnheader"][data-field="${field}"]`)

		columnHeader?.setAttribute('aria-describedby', menuInstructionsId)

		const sortIconButton = columnHeader?.querySelector(`[aria-label="Sort"]`)
		sortIconButton?.setAttribute('aria-hidden', 'true')

		const menuIconButton = columnHeader?.querySelector(`[aria-label="Menu"]`)
		menuIconButton?.setAttribute('aria-label', 'filter menu')
	}, [menuInstructionsId, field])

	return (
		<>
			<Tooltip title={colDef.headerName} aria-label={label}>
				<Box sx={{ maxWidth: '100%' }}>
					<Typography
						variant="body2Semibold"
						aria-label={label}
						sx={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							display: 'block',
						}}
					>
						{colDef.headerName}
					</Typography>
				</Box>
			</Tooltip>
		</>
	)
}

type FirmCellProps = {
	firmName: string
	firmId?: string | null
}

export const FirmCell = ({ firmName, firmId }: FirmCellProps) => {
	const { openProposalDetail } = useActions()
	const containerId = useId()
	const labelId = useId()

	useEffect(() => {
		const container = document.getElementById(containerId)
		const cell = container?.closest(`[role="gridcell"]`)
		cell?.setAttribute('aria-labelledby', labelId)
	}, [firmId, containerId, labelId])

	return (
		<Box id={containerId}>
			<Typography
				id={labelId}
				gutterBottom={true}
				variant="body2Semibold"
				display="block"
				sx={{
					textWrap: 'wrap',
					overflow: 'hidden',
					// supported except in IE where fallback will be regular wrapped text
					display: '-webkit-box',
					'-webkit-box-orient': 'vertical',
					'-webkit-line-clamp': '2',
					lineClamp: '2',
				}}
			>
				{firmName}
			</Typography>
			{firmId && (
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={() => openProposalDetail(firmId)}
					aria-label={`click to view proposal details of ${firmName}`}
					data-trackid="rates-table-view-details"
				>
					View Details
				</Button>
			)}
		</Box>
	)
}
