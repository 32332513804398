import cond from 'lodash/fp/cond'
import constant from 'lodash/fp/constant'
import matches from 'lodash/fp/matches'
import stubTrue from 'lodash/fp/stubTrue'
import isFuture from 'date-fns/isFuture'
import i18next from 'i18next'
import DueDateTicker from '../../due-date-ticker'
import FirmStatusTypes from '../firm-status-types'

const getSecondaryText = ({ isRequestReopenedForFirm }) => {
	if (isRequestReopenedForFirm) {
		return i18next.t('reopenIndividually.firm.statusText')
	}
}

const getSecondaryWithdrawnText = ({ canFirmParticipate, proposalStatus }) => {
	if (!canFirmParticipate) {
		return 'Unable to participate'
	}

	if (proposalStatus === 'sent') {
		return 'Proposal sent'
	}

	return 'Proposal not sent'
}

const getNonAuctionProps = ({
	requestStatus,
	proposalStatus,
	requestProposalsDueBy,
	isRequestReopenedForFirm,
	isFirmEliminated,
	canFirmParticipate,
}) =>
	cond([
		[matches({ isFirmEliminated: true }), constant({ status: FirmStatusTypes.ELIMINATED })],
		[
			matches({ canFirmParticipate: false }),
			constant({ status: FirmStatusTypes.DECLINED_TO_PARTICIPATE }),
		],
		[
			matches({ requestStatus: FirmStatusTypes.DECLINED_TO_PARTICIPATE }),
			constant({ status: requestStatus }),
		],
		[
			matches({ requestStatus: FirmStatusTypes.DRAFT }),
			constant({
				status: requestStatus,
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.OPEN_TO_PROPOSALS }),
			constant({
				status: requestStatus,
				statusLine2: isFuture(new Date(requestProposalsDueBy)) && (
					<DueDateTicker dueDate={requestProposalsDueBy} />
				),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.EVALUATING }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryText({ isRequestReopenedForFirm }),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.COMPLETED }),
			constant({
				status: requestStatus,
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.WITHDRAWN }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryWithdrawnText({ canFirmParticipate, proposalStatus }),
			}),
		],
		[stubTrue, constant({ status: '' })],
	])({
		requestStatus,
		isFirmEliminated,
		canFirmParticipate,
	})

export default getNonAuctionProps
