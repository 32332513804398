import { getOrUndefined, getOrEmptyString, getOrTrue, getOrFalse } from '../../../../utils/lenses'
import getAuctionProps from './get-auction-props'
import getNonAuctionProps from './get-non-auction-props'

export default (request, firm, proposalStatus) =>
	getOrFalse('isAuction', request)
		? getAuctionProps({
				isFirmEliminated: getOrFalse('isEliminated', firm),
				canFirmParticipate: getOrTrue('canParticipate', firm),
				requestStatus: getOrEmptyString('status', request),
				proposalStatus,
				initialProposalsDueBy: getOrUndefined('initialProposalsDueBy', request),
				auctionStart: getOrUndefined('auctionStart', request),
				auctionEnd: getOrUndefined('auctionEnd', request),
				lastBid: getOrUndefined('lastBid', request),
				isRequestClosed: getOrFalse('isRequestClosed', request),
				isRequestReopenedForFirm: getOrFalse('isRequestReopenedForFirm', firm),
		  })
		: getNonAuctionProps({
				isFirmEliminated: getOrFalse('isEliminated', firm),
				canFirmParticipate: getOrTrue('canParticipate', firm),
				requestStatus: getOrEmptyString('status', request),
				proposalStatus,
				requestProposalsDueBy: getOrUndefined('proposalsDueBy', request),
				isRequestReopenedForFirm: getOrFalse('isRequestReopenedForFirm', firm),
		  })
