import { Box, Button, ButtonProps } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { getKebabCase } from './grid-custom-cell-with-anchor'
import { Person } from '../../../types'

type AddedContact = {
	_id: string
	contactName: string
	contactTitle: string | null
	firmName: string
	contactLocation: string | null
	isNamwolfMember: boolean
	type: 'known' | 'unknown'
	pendingRemoval: boolean
	addedBySystem: boolean
	selectedUser: Person
}

export const GridCustomCellWithButton = ({
	row,
	tabIndex,
	buttonProps,
	buttonLabel,
	handleButtonClick,
}: GridRenderCellParams & {
	buttonProps?: ButtonProps
	buttonLabel: string
	handleButtonClick: (person: AddedContact) => Promise<void> | void
}) => {
	const itemName = `${row.contactName} ${row.firmName}`
	return (
		<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
			<Button
				{...buttonProps}
				size="small"
				tabIndex={tabIndex}
				aria-label={`${buttonLabel} ${itemName}`}
				onClick={async () => await handleButtonClick(row)}
				data-trackid={`button-contacts-added-${getKebabCase(buttonLabel)}`}
			>
				{buttonLabel}
			</Button>
		</Box>
	)
}
