import { useEffect } from 'react'
import { Box, PopoverHelp, Tooltip, FormTextField } from '@persuit/ui-components'
import { PricingCardItemNumbering } from '../../../../pricing'
import { PricingModelSelect } from '../../../../common-components'
import { PricingItemPricingPopoverContent } from './pricing-item-pricing-popover-content'
import { usePricingListContext } from '../../../pricing-list-context'
import { PricingItemFieldPath, useFormContext, useWatch } from '../../../pricing-form-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type PricingItemBodyProps = {
	id: string
	fieldPath: PricingItemFieldPath
	hasValidationErrors?: string | null
	isOriginalPricingItem?: boolean
}

export const PricingItemBody = ({
	id,
	hasValidationErrors,
	isOriginalPricingItem = false,
	fieldPath,
}: PricingItemBodyProps) => {
	const { getPricingItemIndex } = usePricingListContext()
	const { setValue } = useFormContext()
	const { toggles } = useFeatureToggles()

	const itemPricingModel = useWatch({ name: `${fieldPath}.pricingPreferences` })
	useEffect(() => {
		if (itemPricingModel === 'hourlyRate' || itemPricingModel === 'contingencyPercentage') {
			setValue(`${fieldPath}.rates`, [])
		}
	}, [itemPricingModel, setValue, fieldPath])

	const pricingItemPricingModelSelect = (
		<PricingModelSelect
			fullWidth={true}
			data-testid="pricing-item-model"
			type={toggles['dev-3327.contingency-percentage'] ? 'item-dev-3327' : 'item'}
			id={`item-pricing-model-${id}`}
			name={`${fieldPath}.pricingPreferences`}
			// Original pricing items cannot be deleted
			// Newly introduced items can be deleted
			disabled={isOriginalPricingItem}
		/>
	)

	return (
		<Box display="flex" alignItems="center" width="100%" data-testid="pricing-item-body">
			<Box position="relative" top="7px">
				<PricingCardItemNumbering
					parentIndex={getPricingItemIndex(id)}
					data-testid="item-header-numbering"
				/>
			</Box>
			<Box width="60%" margin="10px" data-testid="item-header-title">
				<FormTextField
					name={`${fieldPath}.deliverableTitle`}
					required={true}
					error={!!hasValidationErrors}
					helperText={hasValidationErrors}
					id={`item-pricing-title-${id}`}
					label="Item title"
					fullWidth={true}
					inputProps={{ maxLength: '200', 'data-testid': 'pricing-item-title' }} // Maximum length of item title is 200 characters
				/>
			</Box>
			<Box display="flex" width="30%" margin="10px" data-testid="item-header-pricing-model">
				{isOriginalPricingItem ? (
					<Tooltip title="Once set, the pricing structure cannot be altered" arrow={true}>
						<Box width={'100%'}>{pricingItemPricingModelSelect}</Box>
					</Tooltip>
				) : (
					pricingItemPricingModelSelect
				)}
				<PopoverHelp
					triggerButtonProps={{
						'aria-label': 'Pricing model help',
					}}
					content={
						<PricingItemPricingPopoverContent isOriginalPricingItem={isOriginalPricingItem} />
					}
					data-testid="item-header-pricing-model-help"
				/>
			</Box>
		</Box>
	)
}
