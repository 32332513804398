import { graphql, useQuery } from '@persuit/ui-graphql'
import { Response } from '@persuit/ui-graphql/generated'
import { RateCard } from '@persuit/ui-shared-components'
import { calculateFirmWeightedAverage } from '../column-definitions'
import { useState } from 'react'
import { useStore } from '../store'
import type { RateCard as RateCardType } from '../store'
import { FirmWeightedChangeText } from './firm-weighted-change-text'

type RateCardsContentProps = {
	rateCards: RateCardType[]
	proposalId: Response['_id']
	proposalUpdatedAt: Response['updatedAt']
	currency: string
}

const GET_PRICE_HISTORY = graphql(`
	query ProposalDetailDialog_GetRateCardPriceHistory($proposalId: ID!) {
		getRfpProposal(_id: $proposalId) {
			_id
			rateCards {
				currency
				_id
				historicalPrices {
					currency
					previousCurrency
					previousProposedRate
					proposedRate
					timekeeperLabel
					updatedAt
				}
			}
		}
	}
`)

export const RateCardsContent = ({
	rateCards,
	proposalId,
	proposalUpdatedAt,
	currency,
}: RateCardsContentProps) => {
	const { data: historyData } = useQuery(GET_PRICE_HISTORY, {
		variables: { proposalId },
		skip: !proposalId,
	})
	const { timekeepers } = useStore((state) => ({
		timekeepers: state.rfp.rateReview?.timekeepers ?? [],
	}))
	const [openRateCardId, setOpenRateCardId] = useState<string | null>(null)
	const weightedAverage = calculateFirmWeightedAverage(rateCards)

	return (
		<>
			<FirmWeightedChangeText weightedAverage={weightedAverage} proposalId={proposalId} />
			{rateCards.map((rateCard, index) => (
				<RateCard
					key={rateCard._id}
					open={openRateCardId === rateCard._id}
					onChange={(open) => setOpenRateCardId(open ? rateCard._id : null)}
					index={index}
					currency={currency}
					timekeepers={timekeepers}
					rateCard={{ ...rateCard, updatedAt: proposalUpdatedAt }}
					historicalPrices={
						historyData?.getRfpProposal?.rateCards?.find((card) => card._id === rateCard._id)
							?.historicalPrices ?? []
					}
					sx={{ mb: 2 }}
				/>
			))}
		</>
	)
}
