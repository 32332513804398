import { useId } from 'react'
import * as React from 'react'
import { useToggle } from '@persuit/ui-hooks'

import {
	Typography,
	TypographyProps,
	Box,
	Link,
	PopoverHelp,
	Checkbox,
	FormControlLabel,
	Accordion,
	AccordionProps,
	AccordionSummary,
	AccordionDetails,
} from '@persuit/ui-components'

import NamwolfLogo from '../../../components/namwolf/namwolf-logo'

type FirmData = {
	_id: string
	name: string
	memberCount: number
}

type FirmListsProps = {
	onSelectFirm: (firmId: string) => void
	onClearFirm: () => void
	selectedFirmId: string
	otherFirms: FirmData[]
	namwolfFirms: FirmData[]
}

export const FirmLists = ({
	namwolfFirms,
	otherFirms,
	selectedFirmId,
	onSelectFirm,
	onClearFirm,
}: FirmListsProps) => {
	const [namwolfExpanded, toggleNamwolfExpanded] = useToggle(false || namwolfFirms.length === 0)
	const [otherExpanded, toggleOtherExpanded] = useToggle(false || otherFirms.length === 0)

	return (
		<>
			<FirmGroup
				sx={{ mb: 1 }}
				title="Namwolf Firms"
				expanded={namwolfExpanded}
				toggleExpanded={toggleNamwolfExpanded}
				firms={namwolfFirms}
				onClearFirm={onClearFirm}
				onSelectFirm={onSelectFirm}
				selectedFirmId={selectedFirmId}
				getFirmIcons={() => <>{<NamwolfLogo />}</>}
				summary={
					<Box display="flex" alignItems="center">
						<Summary count={namwolfFirms.length} title="NAMWOLF Members" />

						<PopoverHelp
							triggerButtonSize="small"
							triggerButtonProps={{ 'aria-label': 'What is a NAMWOLF firm?' }}
							content={
								<>
									<Typography>
										National Association of Minority & Women Owned Law Firms is a non-profit trade
										association whose mission is to promote diversity in the legal profession by
										fostering successful relationships among pre-eminent minority and women owned
										law firms and private/public entities.
									</Typography>
									<Link href="https://namwolf.org/law-firm-members/" target="_blank" sx={{ mt: 2 }}>
										Open NAMWOLF Directory
									</Link>
								</>
							}
						/>
					</Box>
				}
			/>

			<FirmGroup
				sx={{ mb: 1 }}
				title="Other Firms"
				expanded={otherExpanded}
				toggleExpanded={toggleOtherExpanded}
				firms={otherFirms}
				onClearFirm={onClearFirm}
				onSelectFirm={onSelectFirm}
				selectedFirmId={selectedFirmId}
			/>
		</>
	)
}

type FirmGroupProps = {
	sx: AccordionProps['sx']
	title: string
	expanded: boolean
	toggleExpanded: () => void
	selectedFirmId: string
	firms: FirmData[]
	onSelectFirm: (id: string) => void
	onClearFirm: (id: string) => void
	getFirmIcons?: (id: string) => React.ReactNode
	summary?: React.ReactNode
}

const FirmGroup = ({
	sx,
	title,
	expanded,
	toggleExpanded,
	selectedFirmId,
	firms,
	onSelectFirm,
	onClearFirm,
	summary,
	getFirmIcons,
}: FirmGroupProps) => {
	const firmCount = firms.length
	const hasSelectedFirm = !!firms.find((firm) => firm._id === selectedFirmId)
	const selectedMessageId = useId()

	return (
		<Accordion expanded={expanded} onChange={toggleExpanded} sx={sx}>
			<AccordionSummary
				IconButtonProps={{
					'aria-describedby': selectedMessageId,
				}}
				iconButtonLabel={expanded ? `Collapse ${title}` : `Expand ${title}`}
				sx={{ position: 'relative' }}
				showIconButtonBadge={hasSelectedFirm && !expanded}
				iconButtonBadgeProps={{
					color: 'info',
					badgeContent: '',
					size: 'small',
				}}
			>
				{hasSelectedFirm ? (
					<span id={selectedMessageId} style={{ display: 'none' }}>
						1 contact selected
					</span>
				) : null}
				{summary ?? <Summary count={firmCount} title={title} />}
			</AccordionSummary>
			<AccordionDetails role={firmCount === 0 ? 'region' : 'list'}>
				{firmCount === 0 ? (
					<Typography variant="body2">No members found</Typography>
				) : (
					firms.map(({ _id, name, memberCount }) => (
						<Box key={_id} role="listitem">
							<FirmCheckItem
								firmName={name}
								selected={selectedFirmId === _id}
								count={memberCount}
								onSelect={() => onSelectFirm(_id)}
								onRemove={() => onClearFirm(_id)}
								icon={getFirmIcons?.(_id)}
							/>
						</Box>
					))
				)}
			</AccordionDetails>
		</Accordion>
	)
}

type SummaryProps = {
	count: number
	title: string
} & TypographyProps

const Summary = ({ title, count }: SummaryProps) => (
	<Typography variant="body1" component="span" aria-label={title}>
		{`${title} (${count})`}
	</Typography>
)

type FirmCheckItemProps = {
	count: number
	firmName: string
	onSelect: () => void
	onRemove: () => void
	selected: boolean
	icon?: React.ReactNode
}

export const FirmCheckItem = ({
	count,
	firmName,
	onSelect,
	onRemove,
	selected,
	icon,
}: FirmCheckItemProps) => (
	<FormControlLabel
		control={
			<Checkbox onClick={selected ? onRemove : onSelect} checked={selected} color="primary" />
		}
		label={
			<Box>
				<Typography variant="body2">{firmName}</Typography>
				<Typography
					variant="body2"
					sx={{ mx: 0.5, verticalAlign: 'middle', display: 'inline-block' }}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>{icon}</Box>
				</Typography>
				<Typography variant="body2">({count || 0})</Typography>
			</Box>
		}
	/>
)
