import { useId } from 'react'
import * as React from 'react'
import { Button, ButtonProps } from '../button'
import { ButtonGroup, ButtonGroupProps } from '../button-group'
import { Menu } from '../menu'
import { MenuItem } from '../menu-item'
import { ArrowDropDownIcon } from '../../icons'
import { styled } from '../../theme'

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
	boxShadow: 'none',
	'&.MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type)': {
		borderColor: theme.palette.primary.main,
		borderRight: 'none',
	},
	'&.MuiButtonGroup-root .MuiButtonGroup-grouped:not(:first-of-type)': {
		borderColor: theme.palette.primary.main,
	},
	'&.MuiButtonGroup-root .MuiButtonGroup-grouped': {
		'&:focus': {
			outline: '0px',
			outlineOffset: '0px',
		},
	},
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	'&.MuiMenuItem-root:hover': {
		backgroundColor: theme.palette.primary.lightestHue,
	},
	'&.MuiMenuItem-root.Mui-selected': {
		backgroundColor: theme.palette.primary.lightestHue,
	},
	'&.MuiMenuItem-root.Mui-selected:hover': {
		backgroundColor: theme.palette.primary.lightestHue,
	},
}))

export type SplitButtonProps = {
	options: Array<string>
	selectedOption?: string
	buttonProps: ButtonProps & {
		// Required for accessibility purpose
		'aria-label': string
	}
	buttonGroupProps?: ButtonGroupProps
	onItemClick: (
		event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>,
		selectedIndex: number,
	) => void
}

export const SplitButton = ({
	options,
	selectedOption = options[0],
	buttonGroupProps,
	buttonProps,
	onItemClick,
}: SplitButtonProps) => {
	const [open, setOpen] = React.useState(false)
	const id = useId()
	const anchorRef = React.useRef<HTMLDivElement>(null)
	const [selectedIndex, setSelectedIndex] = React.useState(
		selectedOption ? options.indexOf(selectedOption) : 0,
	)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: Event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}
		setOpen(false)
	}

	const menuId = `split-button-menu-${id}`
	const buttonId = `split-button-${id}`

	return (
		<React.Fragment>
			<StyledButtonGroup variant="contained" {...buttonGroupProps}>
				<Button variant="outlined" onClick={(event) => onItemClick(event, selectedIndex)}>
					{options[selectedIndex]}
				</Button>
				<Button
					size="small"
					variant="outlined"
					aria-controls={open ? menuId : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onClick={handleToggle}
					{...buttonProps}
					id={buttonId}
					ref={anchorRef}
				>
					<ArrowDropDownIcon />
				</Button>
			</StyledButtonGroup>

			<Menu
				id={menuId}
				sx={{ paddingTop: 0, paddingBottom: 0 }}
				MenuListProps={{
					'aria-labelledby': buttonId,
				}}
				open={open}
				anchorEl={anchorRef.current}
				onClose={handleClose}
			>
				{options.map((option, index) => (
					<StyledMenuItem
						key={option}
						selected={index === selectedIndex}
						onClick={(event) => {
							onItemClick(event, index)
							setSelectedIndex(index)
							setOpen(false)
						}}
					>
						{option}
					</StyledMenuItem>
				))}
			</Menu>
		</React.Fragment>
	)
}
