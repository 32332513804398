import { useEffect, useId, useState, useRef } from 'react'
import * as React from 'react'
import { Stepper, Step, StepLabel, StepContent, StepConnector } from '../stepper'
import { Typography } from '../typography'
import { Box } from '../box'
import { styled } from '../../theme'
import { ErrorIcon } from '../../icons'
import { useIsFirstRender } from '@persuit/ui-hooks'

type ModifyStep = (stepIndex: number, content: Partial<VerticalStepProps>) => void

type ContentRenderProps = (props: {
	setActiveStep: (stepIndex: number) => void
	stepIndex: number
	/** Current step data */
	step: VerticalStepProps
	/** Modify a particular step */
	modifyStep: ModifyStep
	isLast: boolean
}) => React.ReactNode

export type VerticalStepProps = {
	label: React.ReactNode
	content: React.ReactNode | ContentRenderProps
	error?: boolean
	errorText?: string
	completed?: boolean
	optional?: boolean
}

export type VerticalStepperProps = {
	/** Initial steps configuration. */
	steps: VerticalStepProps[]
	/** Initial active step. Default to 0 */
	defaultActiveStep?: number
	onChange?: (data: { steps: VerticalStepProps[]; activeStep: number }) => void
}

const CONTENT_PADDING = '19px'

const StyledStepLabel = styled(StepLabel)<{ isIncomplete?: boolean }>(
	({ theme, isIncomplete, error }) => `
	align-items: flex-start;

	svg {
		border: ${!error ? `1px solid ${theme.palette.primary.main}` : ''};
		padding: ${!error ? '1px' : '0'};
		border-radius: 100%;
		color: ${error ? theme.palette.error.main : theme.palette.primary.main};
	}

	text {
		font-size: 1rem;
		font-weight: 500;
	}

	${
		isIncomplete
			? `
			.Mui-disabled {
				svg {
					color: ${theme.palette.common.black};
					border-color: ${theme.palette.common.black};
					border-style: dashed;
				}
			}
		`
			: ''
	}
`,
)

const StyledStepConnector = styled(StepConnector)<{
	isLast: boolean
}>(
	({ isLast }) => `
	& .MuiStepConnector-lineVertical {
		height: 100%;
		visibility: ${isLast ? 'hidden' : 'inherit'};
	}
	flex: 0;
	padding-right: ${CONTENT_PADDING};
`,
)

export const VerticalStepper = ({
	steps,
	defaultActiveStep = 0,
	onChange,
}: VerticalStepperProps) => {
	const id = useId()
	const isFirstRender = useIsFirstRender()

	const [internalSteps, setInternalSteps] = useState(steps)
	const [activeStep, setActiveStep] = useState(defaultActiveStep)

	const modifyStep: ModifyStep = (stepIndex, content) => {
		const newSteps = [...internalSteps]

		newSteps[stepIndex] = {
			...newSteps[stepIndex],
			...content,
		}

		setInternalSteps(newSteps)
	}

	const contentRef = useRef<HTMLElement | null>(null)

	useEffect(() => {
		onChange?.({
			steps,
			activeStep,
		})
	}, [steps, activeStep, onChange])

	useEffect(() => {
		if (!isFirstRender) {
			contentRef.current?.focus()
		}
	}, [activeStep, isFirstRender])

	useEffect(() => {
		setInternalSteps(
			internalSteps.map((step, idx) => ({
				...step,
				...steps[idx],
			})),
		)
		// We only care about the change of passed steps props
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [steps])

	return (
		<Stepper orientation="vertical" activeStep={activeStep}>
			{internalSteps.map((step, index) => {
				const { label, content, completed, optional, error, errorText = 'Action required' } = step
				const isPending = index > activeStep && completed === undefined
				const isIncomplete = completed === false
				const isActive = index === activeStep
				const isCompleted = completed === true && !isActive && !error

				const statusText = error
					? errorText
					: isPending
					? 'Pending'
					: isActive
					? 'In progress'
					: isCompleted
					? 'Completed'
					: isIncomplete
					? 'Incomplete'
					: ''

				const stepColor = error
					? 'error.main'
					: isPending
					? 'text.disabled'
					: isIncomplete
					? 'text.primary'
					: isActive
					? 'primary.main'
					: isCompleted
					? 'success.main'
					: undefined

				const isLast = index === steps.length - 1

				const stepId = `${id}-${index}`
				const labelId = `${stepId}-label`

				return (
					<Step key={stepId} completed={isCompleted} data-testid={`step-${index}`}>
						<Box id={labelId}>
							<StyledStepLabel
								error={error}
								StepIconComponent={error ? ErrorIcon : undefined}
								isIncomplete={isIncomplete}
								sx={{
									'.Mui-completed': {
										color: stepColor,
										borderColor: stepColor,
									},
								}}
							>
								<Typography variant="caption" color="text.disabled">
									{`Step ${index + 1}${optional ? ' (optional)' : ''}`}
								</Typography>
							</StyledStepLabel>

							<Box display="flex">
								<StyledStepConnector isLast={isLast} />
								<Box>
									<Typography variant="body1Semibold" mt={-1.5}>
										{label}
									</Typography>
									<Typography variant="caption" color={stepColor}>
										{statusText}
									</Typography>
								</Box>
							</Box>
						</Box>

						<StepContent
							sx={{
								pt: 1,
								pl: CONTENT_PADDING,
							}}
						>
							<Box
								sx={{ border: '1px solid', borderColor: 'form.borderActive' }}
								borderRadius={1}
								px={3}
								py={4}
								aria-labelledby={labelId}
								tabIndex={-1}
								role="group"
								ref={contentRef}
							>
								{typeof content === 'function'
									? content({
											setActiveStep,
											modifyStep,
											stepIndex: index,
											step,
											isLast,
									  })
									: content}
							</Box>
						</StepContent>
					</Step>
				)
			})}
		</Stepper>
	)
}

VerticalStepper.displayName = 'VerticalStepper'
