// @ts-strict-ignore
import { Fragment, useEffect, useState, createContext, useContext } from 'react'
import { getOr, map } from 'lodash/fp'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client'

import { usePaginatatedQuery } from './tagger-use-pagination'
import { useTaxonomy } from './tagger-use-taxonomy'
import { useSnackbar } from '@persuit/ui-components'

const EntityTaggerContext = createContext(null)

const tabs = {
	UNTAGGED: 0,
	FULLY_TAGGED: 1,
	EXCLUDED: 2,
}

const tabIds = ['untagged', 'tagged', 'excluded']

const getCodes = ({ code }) => code

const currentYear = new Date().getFullYear()

type MappedTags = {
	service: Array<string>
	type: Array<string>
	areaOfLaw: Array<string>
	location: Array<string>
	sourcing: Array<string>
}

const getGqlData = (rawResponse) => {
	const response = rawResponse ?? {}

	if (Object.keys(response).length === 0) {
		return null
	}

	return response[Object.keys(response)[0]]
}

export const EntityTaggerProvider = ({
	getTaggedCountQuery,
	getTaggedEntitiesQuery,
	getUntaggedEntitiesQuery,
	getExcludedEntitiesQuery,
	tagEntityMutation,
	excludeEntityMutation,
	taggerBasePath,
	match,
	history,
	taxonomySlots,
	...otherProps
}) => {
	const [orgFilter, setOrgFilter] = useState([])
	const [yearFilter, setYearFilter] = useState(currentYear)
	const { openSnackbar } = useSnackbar()
	const [processing, setProcessing] = useState(false)
	const [previousTab, setPreviousTab] = useState('')
	const {
		data,
		refetch: refetchCount,
		networkStatus,
		error,
	} = useQuery(getTaggedCountQuery, {
		variables: {
			orgs: orgFilter,
			year: yearFilter,
		},
	})
	const fetchingCount = networkStatus === NetworkStatus.loading

	const tab = match.params.tab
	const untaggedFilter = match.params.filter

	const isUntaggedListView = !tab || tab === tabIds[tabs.UNTAGGED]
	const isTaggedListView = tab === tabIds[tabs.FULLY_TAGGED]
	const isExcludedListView = tab === tabIds[tabs.EXCLUDED]
	const isListView = isUntaggedListView || isTaggedListView || isExcludedListView
	const currentTabIndex = tabIds.findIndex((tabId) => tabId === tab)
	const currentTab = currentTabIndex > -1 ? currentTabIndex : 0
	const entityId = isListView ? null : tab

	useEffect(() => {
		if (isListView) {
			if (tab && untaggedFilter) {
				return setPreviousTab(`${tab}/${untaggedFilter}`)
			}
			setPreviousTab(tab || tabIds[tabs.UNTAGGED])
		}
		// eslint-disable-next-line
	}, [tab, untaggedFilter])

	const {
		data: getTaggedEntitiesData,
		refetch: refetchTaggedEntities,
		loading: fetchingTaggedEntities,
		page: taggedEntitiesPage,
		setPage: setTaggedEntitiesPage,
	} = usePaginatatedQuery({
		query: getTaggedEntitiesQuery,
		orgFilter,
		yearFilter,
	})
	const taggedEntities = getGqlData(getTaggedEntitiesData)

	const {
		data: getUntaggedEntitiesData,
		refetch: refetchUntaggedEntities,
		loading: fetchingUntaggedEntities,
		page: untaggedEntitiesPage,
		setPage: setUntaggedEntitiesPage,
	} = usePaginatatedQuery({
		query: getUntaggedEntitiesQuery,
		orgFilter,
		untaggedFilter,
		yearFilter,
	})
	const untaggedEntities = getGqlData(getUntaggedEntitiesData)

	const {
		data: getExcludedEntitiesData,
		refetch: refetchExcludedEntities,
		loading: fetchingExcludedEntities,
		page: excludedEntitiesPage,
		setPage: setExcludedEntitiesPage,
	} = usePaginatatedQuery({
		query: getExcludedEntitiesQuery,
		orgFilter,
		yearFilter,
	})
	const excludedEntities = getGqlData(getExcludedEntitiesData)

	const refetchListsAndCount = async () =>
		Promise.all([
			refetchUntaggedEntities(),
			refetchTaggedEntities(),
			refetchExcludedEntities(),
			refetchCount(),
		])

	const { fetchingTaxonomy, taxonomies } = useTaxonomy()

	const [tagEntityMutationFn] = useMutation(tagEntityMutation)
	const tagEntity =
		(entityId) =>
		async (tags, markAsComplete = false) => {
			const mappedTags = {} as MappedTags
			if (taxonomySlots.includes('areaOfLaw')) {
				mappedTags.areaOfLaw = map(getCodes, tags?.areaOfLaw ?? [])
			}
			if (taxonomySlots.includes('location')) {
				mappedTags.location = map(getCodes, tags?.location ?? [])
			}
			if (taxonomySlots.includes('type')) {
				mappedTags.type = map(getCodes, tags?.type ?? [])
			}
			if (taxonomySlots.includes('service')) {
				mappedTags.service = map(getCodes, tags?.service ?? [])
			}
			if (taxonomySlots.includes('sourcing')) {
				mappedTags.sourcing = map(getCodes, tags?.sourcing ?? [])
			}
			setProcessing(true)
			await tagEntityMutationFn({
				variables: { rfpId: entityId, tags: mappedTags, markAsComplete },
			})
			setProcessing(false)
			await refetchListsAndCount()
			openSnackbar('entity successfully tagged')
		}

	const [excludeEntityFromTagging] = useMutation(excludeEntityMutation)
	const excludeEntity = async (entityId) => {
		setProcessing(true)
		await excludeEntityFromTagging({
			variables: {
				rfpId: entityId,
			},
		})
		await refetchListsAndCount()
		setProcessing(false)
		openSnackbar('entity excluded from tagging')
	}

	if (fetchingCount) {
		return <div style={{ padding: '1.5rem' }}>Fetching data...</div>
	}

	if (fetchingTaxonomy) {
		return <div style={{ padding: '1.5rem' }}>Constructing Taxonomy...</div>
	}

	const errorCode = getOr(null, 'graphQLErrors.0.extensions.code', error)

	if (errorCode === 'FORBIDDEN') {
		return (
			<div style={{ padding: '1.5rem' }}>
				<strong>Error:</strong> This tool is not available for your account
			</div>
		)
	}

	const taggedCount = getGqlData(data)?.taggedCount
	const excludedCount = getGqlData(data)?.excludedCount

	const changeFilter = (orgIds) => setOrgFilter(orgIds)
	const closeTaggableEntity = () => history.push(`/${taggerBasePath}/${previousTab}`)

	const changeTab = (nextTab) => {
		return history.push(`/${taggerBasePath}/${tabIds[nextTab]}`)
	}
	const changeUntaggedFilter = (nextFilter) => () => {
		return history.push(`/${taggerBasePath}/untagged/${nextFilter || ''}`)
	}
	const isActiveUntaggedFilter = (untaggedFilterToCheck) => {
		return untaggedFilter === untaggedFilterToCheck
	}

	const openTaggerForEntity = (entityId) => history.push(`/${taggerBasePath}/${entityId}`)

	return (
		<Fragment>
			<EntityTaggerContext.Provider
				value={
					{
						isUntaggedListView,
						isTaggedListView,
						isExcludedListView,
						isListView,
						currentTab,
						changeTab,
						untaggedFilter,
						changeUntaggedFilter,
						isActiveUntaggedFilter,

						taggedCount,
						excludedCount,
						refetchCount,

						changeFilter,
						closeTaggableEntity,
						openTaggerForEntity,

						untaggedEntities,
						untaggedEntitiesPage,
						fetchingUntaggedEntities,
						refetchUntaggedEntities,
						setUntaggedEntitiesPage,

						taggedEntities,
						taggedEntitiesPage,
						fetchingTaggedEntities,
						refetchTaggedEntities,
						setTaggedEntitiesPage,

						excludedEntities,
						excludedEntitiesPage,
						fetchingExcludedEntities,
						refetchExcludedEntities,
						setExcludedEntitiesPage,

						excludeEntity,
						tagEntity,
						processing,

						taxonomies,
						taxonomySlots,

						entityId,

						history,

						orgFilter,
						setOrgFilter,
						yearFilter,
						setYearFilter,
					} as any
				}
				{...otherProps}
			/>
		</Fragment>
	)
}

export const useEntityTagger = () => useContext(EntityTaggerContext)
