import { useId } from 'react'
import { Box, Typography } from '@persuit/ui-components'

import { useActions, useStore } from '../../store'
import { TotalPriceIntelligenceCell } from './total-price-intelligence-cell'

import { RowHeaderCell, Row } from '../components'
import {
	totalPriceCellHeaders,
	totalPriceRowId,
	totalPriceIntelligenceCellHeaders,
} from '../accessibilty'

import { TotalPriceCell } from './total-price-cell'
import { SortButton, SortOrder } from '../components/sort-button'

export const TotalPriceRow = () => {
	const { isClient, request, responses, showIntelligenceColumn, currentSort } = useStore(
		(state, s) => ({
			showIntelligenceColumn: s.showIntelligenceColumn(),
			responses: s.displayedResponses(),
			isClient: s.isClient(),
			request: state.request,
			currentSort: state.sort,
		}),
	)

	const { setSort } = useActions()

	const titleId = useId()

	const {
		detail: { currency },
	} = request

	const sortOrder = currentSort?.type === 'totalPrice' ? currentSort.order : null

	const sortByTotalPrice = (order: SortOrder) => {
		setSort({
			type: 'totalPrice',
			order,
		})
	}

	return (
		<Row>
			<RowHeaderCell cellProps={{ id: totalPriceRowId, 'aria-labelledby': titleId }}>
				<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
					<Typography variant="body1" id={titleId}>
						Total price
					</Typography>
					{isClient && <SortButton sortOrder={sortOrder} onClick={sortByTotalPrice} />}
				</Box>

				{isClient && (
					<Typography variant="caption">
						You can ask your firms to submit a revised price on their proposal(s).
					</Typography>
				)}
			</RowHeaderCell>

			{showIntelligenceColumn && (
				<TotalPriceIntelligenceCell headers={totalPriceIntelligenceCellHeaders} />
			)}

			{responses.map((response, index, array) => {
				return (
					<TotalPriceCell
						showRightBorder={index === array.length - 1}
						key={response._id}
						headers={totalPriceCellHeaders(response._id)}
						responseId={response._id}
						currency={currency!}
						response={response}
					/>
				)
			})}
		</Row>
	)
}
