import { ComponentType } from 'react'

/** Get component name, both rendered and functional type */
export const getComponentName = (component: ComponentType | React.ReactNode) => {
	if (typeof component === 'function') {
		return component.displayName || ''
	} else {
		return typeof component === 'object'
			? (component as JSX.Element)?.type?.displayName ||
					(component as JSX.Element)?.type?.name ||
					''
			: ''
	}
}
