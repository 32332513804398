import { noop } from '@persuit/common-utils'
import { useSortable } from '../sortable'
import { DragHandler, DragHandlerProps } from '../../drag-handler'

export type SortableDragHandlerProps = DragHandlerProps & {
	/** This MUST be the id of the sortable item that this drag handler control  */
	id: string
}

export const SortableDragHandler = ({ id, onClick = noop, ...props }: SortableDragHandlerProps) => {
	const { listeners, attributes, setActivatorNodeRef, isDragging } = useSortable({
		id,
	})

	return (
		/** Required for it to works with JAWS and NVDA, see https://www.accessibility-developer-guide.com/knowledge/screen-readers/desktop/browse-focus-modes/#jaws-auto-forms-mode */
		<div role="application">
			<DragHandler
				onClick={(e) => {
					e.stopPropagation()

					onClick(e)
				}}
				onKeyDown={(e) => {
					e.preventDefault()
				}}
				ref={setActivatorNodeRef}
				{...attributes}
				{...listeners}
				{...props}
				draggable={isDragging ? 'true' : 'false'}
			/>
		</div>
	)
}
