import {
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
} from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { NamedTimekeepersPriceHistoryRow } from './named-timkeepers-price-history-row'
import { graphql, useQuery } from '@persuit/ui-graphql'

const StyledTable = styled(Table)(({ theme }) => ({
	borderCollapse: 'collapse',
	'& td, & th': {
		border: `1px solid ${theme.palette.grey300}`,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderCollapse: 'collapse',
	'& th': {
		border: `1px solid ${theme.palette.grey200}`,
		borderBottom: `1px solid ${theme.palette.grey500}`,
	},
	'& tr:nth-child(2n)': {
		background: theme.palette.grey100,
	},
}))

const StyledTableBody = styled(TableBody)(({ theme }) => ({
	'& tr:nth-child(2n + 1)': {
		background: theme.palette.grey100,
	},
}))

export type RateCardPriceHistoryProps = {
	sx?: SxProps
	currency: string
	ariaLabel?: string
	proposalId: string
}

const GET_RFP_PROPOSAL = graphql(`
	query GetResponse_NamedTimekeepersPriceHistory($proposalId: ID!) {
		getResponse(responseId: $proposalId) {
			_id
			historicalNamedTimekeepersPrices {
				namedTimekeepers {
					currency
					previousCurrency
					firstName
					lastName
					jobTitle
					proposedRate
					previousProposedRate
				}
				updatedAt
			}
		}
	}
`)

export const NamedTimekeepersPriceHistory = ({
	currency,
	sx,
	ariaLabel,
	proposalId,
}: RateCardPriceHistoryProps) => {
	const { data, loading } = useQuery(GET_RFP_PROPOSAL, { variables: { proposalId } })

	const { toggles } = useFeatureToggles()
	const isMultiCurrencyEnabled = toggles['dev-10954.multi-currency']

	if (loading) {
		return null
	}

	const historicalNamedTimekeepersPrices = data?.getResponse?.historicalNamedTimekeepersPrices ?? []

	if (historicalNamedTimekeepersPrices.length === 0) {
		return (
			<Typography>
				Individual timekeepers price history is not available for this proposal
			</Typography>
		)
	}

	return (
		<TableContainer sx={sx}>
			<StyledTable aria-label={ariaLabel ?? 'Individual timekeepers historical prices'}>
				<TableHead>
					<StyledTableRow>
						<TableCell align="center">Last updated</TableCell>
						<TableCell align="left">Timekeeper level</TableCell>
						<TableCell align="left">First name</TableCell>
						<TableCell align="left">Last name</TableCell>
						<TableCell align="right">Price</TableCell>
					</StyledTableRow>
				</TableHead>

				<StyledTableBody>
					{historicalNamedTimekeepersPrices.map(({ updatedAt, namedTimekeepers }) =>
						namedTimekeepers.map(
							(
								{
									proposedRate,
									previousProposedRate,
									jobTitle,
									firstName,
									lastName,
									currency: ntkCurrency,
									previousCurrency,
								},
								index,
							) => {
								return (
									<NamedTimekeepersPriceHistoryRow
										key={`${jobTitle}:${updatedAt}`}
										updatedAt={index === 0 ? updatedAt : null}
										proposedRate={proposedRate}
										timekeeperLabel={jobTitle}
										firstname={firstName}
										lastname={lastName}
										previousProposedRate={previousProposedRate}
										currency={isMultiCurrencyEnabled ? ntkCurrency : currency}
										previousCurrency={isMultiCurrencyEnabled ? previousCurrency : currency}
									/>
								)
							},
						),
					)}
				</StyledTableBody>
			</StyledTable>
		</TableContainer>
	)
}
