import { Paper, Box, ErrorBox, ErrorBoxProps } from '@persuit/ui-components'

export const ErrorPage = (props: ErrorBoxProps) => {
	return (
		<Box padding={3} maxWidth="500px" margin="0px auto">
			<Paper>
				<ErrorBox {...props} />
			</Paper>
		</Box>
	)
}
