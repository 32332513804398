import { CircularProgress, ErrorTwoToneIcon, Box, useTheme } from '@persuit/ui-components'

type ErrorIconProps = {
	loading: boolean
}

export const ErrorIcon = ({ loading }: ErrorIconProps) => {
	const theme = useTheme()

	if (loading)
		return (
			<Box paddingLeft="3px" paddingRight="1px">
				<CircularProgress
					color="error"
					style={{
						color: theme.palette.error.main,
					}}
					size={20}
				/>
			</Box>
		)

	return <ErrorTwoToneIcon color="error" />
}
