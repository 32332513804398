import { useEffect } from 'react'
import { useFeatureToggleClient } from '@persuit/ui-feature-toggle'
import { fs, pendo } from '@persuit/ui-analytics'
import { identify as loggerIdentify } from '@persuit/ui-logger'
import axios from 'axios'
import pick from 'lodash/pick'
import { useUser } from '@persuit/ui-auth'

export const IdentifyUser = () => {
	const { identify } = useFeatureToggleClient()
	const { user, isImpersonated, loading } = useUser()

	useEffect(() => {
		if (loading || !user || !user.org) {
			return
		}

		const { email, _id, orgRoles, org, title } = user

		const { roles = [] } = orgRoles[0] ?? {}

		const { _id: orgId, name: orgName } = org
		const orgType = org.orgType ?? 'UNKNOWN'

		if (_id && orgId && email && orgName) {
			identify?.({
				user: {
					id: _id,
					email,
					impersonated: isImpersonated,
				},
				org: {
					id: orgId,
				},
			})

			fs.identify({
				userId: _id,
				orgId,
				email,
				roles,
				isImpersonated,
			})

			loggerIdentify({ userId: _id, orgId, email, roles, isImpersonated })

			const initPendo = (geoData?: Record<string, unknown>) =>
				pendo.initialize({
					visitorUniqueId: email,
					accountUniqueId: orgName,
					orgId,
					orgName,
					email,
					orgType,
					roleWithinOrganization: title ?? '',
					geoData,
				})

			getGeoData()
				.then((geoData) => initPendo(geoData))
				.catch(() => initPendo())
		}
	}, [user, identify, loading, isImpersonated])

	return null
}

async function getGeoData() {
	try {
		const response = await axios.get<string>('https://www.cloudflare.com/cdn-cgi/trace')
		const geo = response.data.split('\n').reduce((accum, line) => {
			const [key, value] = line.split('=')
			return { ...accum, [key]: value }
		}, {})
		return pick(geo, ['colo', 'ip', 'loc'])
	} catch (error) {
		return {}
	}
}
