import { ComponentProps } from 'react'
import { styled } from '../../../theme'

export const SortableList = styled('ul')`
	list-style: none;
	padding: 0;
	margin: 0;
`

export type SortableListProps = ComponentProps<typeof SortableList>
