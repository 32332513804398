import { QueryResult, OperationVariables } from '@apollo/client'
import { useRef } from 'react'

/**
 * Shows the previous result of a query while we're waiting for the new ones
 */
export function usePreviousResultsWhileLoading<TData, TVariables extends OperationVariables>(
	results: QueryResult<TData, TVariables>,
): QueryResult<TData, TVariables> {
	const previousResults = useRef(results)

	if (!results.loading) {
		previousResults.current = results
	}

	// If the previous result was an error, fall back on the current one, even if it's
	// still loading
	if (previousResults.current?.error) {
		return results
	} else {
		return { ...previousResults.current, loading: results.loading }
	}
}
