import savingsCalculationMethods from '../../../common/data/savings-calculation-methods'

// This is used to determine what the reference value
// should be when calculating potential savings
// It is assumed that item is the same shape as the
// payload returned by the analytics resolver
function getSavingsReference(item, savingsCalculationMethod) {
	switch (savingsCalculationMethod) {
		// Calculate savings as averagePrice - acceptedPrice
		case savingsCalculationMethods.RELATIVE_TO_AVERAGE_PRICE:
			return item.averagePrice

		// Calculate savings as highestPrice - acceptedPrice
		case savingsCalculationMethods.RELATIVE_TO_HIGHEST_PRICE:
			return item.highestPrice

		// Calculate savings as medianPrice - acceptedPrice
		case savingsCalculationMethods.RELATIVE_TO_MEDIAN_PRICE:
			return item.medianPrice

		default:
			return item.averagePrice
	}
}

export default getSavingsReference
