import { useMemo } from 'react'
import { graphql, useQuery, useMutation } from '@persuit/ui-graphql'
import { useParams } from 'react-router-dom'
import { useSectionalContainer } from '../../sectional-form/use-sectional-container'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { DeepPick, isNotNil, isNotNilProp } from '@persuit/common-utils'
import { RfpTemplate } from '../types'
import { SummaryForm } from '../../rfp-forms'

const GET_FILES = graphql(`
	query RfpTemplateSummaryFiles($documentId: ID!) {
		getDetailFilesForRfpTemplate(documentId: $documentId) {
			_id
			filename
			length
			fileStatus
		}
	}
`)

const UPDATE_FILES = graphql(`
	mutation RfpTemplateSummaryFilesUpdate($documentId: ID!, $fileIds: [ID!]!) {
		updateDetailFilesForRfpTemplate(documentId: $documentId, fileIds: $fileIds)
	}
`)

const SAVE_SUMMARY = graphql(`
	mutation RfpTemplateSummarySave($payload: RfpTemplateDraftSummaryInput!, $validate: Boolean!) {
		saveRfpTemplateDraftSummary(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

export type SummarySectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<
		RfpTemplate,
		'_id' | 'requestedBy' | 'detail.scopeOfWork' | 'detail.files' | 'templateType'
	>
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	validationErrors: {
		summary?: {
			files?: string
		}
	} | null
	sendMode: boolean
}

export const SummarySection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	validationErrors,
	refetchErrors,
	sendMode,
}: SummarySectionProps) => {
	const { templateId } = useParams<{ templateId: string }>()
	const [saveSectionMutation] = useMutation(SAVE_SUMMARY)
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const [updateFiles] = useMutation(UPDATE_FILES)

	const { refetch: refetchFiles } = useQuery(GET_FILES, { variables: { documentId: templateId } })

	const sectionErrors = validationErrors?.summary ?? null
	const summaryData = useMemo(
		() => ({
			summary: rfpTemplate?.detail?.scopeOfWork ?? '',
			requestedBy: rfpTemplate?.requestedBy ?? '',
			files: (rfpTemplate?.detail?.files ?? []).filter(isNotNil).filter(isNotNilProp('_id')),
		}),
		[rfpTemplate],
	)

	const isSystemTemplate = rfpTemplate?.templateType === 'system'

	if (loading) return <LoadingSpinner />

	return (
		<>
			<PageTitle>Edit summary of template</PageTitle>
			<SummaryForm
				refetchFiles={async () =>
					(await refetchFiles()).data.getDetailFilesForRfpTemplate
						.filter(isNotNil)
						.filter(isNotNilProp('_id'))
				}
				saveSection={async ({ summary, requestedBy, files }) => {
					await Promise.all([
						isSystemTemplate
							? null
							: updateFiles({
									variables: { documentId: templateId, fileIds: files.map((file) => file._id) },
							  }),
						saveSectionMutation({
							variables: {
								payload: {
									summary,
									requestedBy,
									formInstanceId: instanceId,
									rfpTemplateId: rfpTemplate._id,
								},
								validate: sendMode,
							},
						}),
					])
				}}
				sectionData={summaryData}
				sectionErrors={sectionErrors}
				isSystemTemplate={isSystemTemplate}
				isTemplate={true}
			/>
		</>
	)
}
