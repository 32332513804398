import { useId } from 'react'

import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material'

export type ChipProps = MuiChipProps & {
	deleteDescription?: string
}

export const Chip = ({
	deleteDescription = 'Press backspace or delete to delete',
	onDelete,
	...rest
}: ChipProps) => {
	const descriptionId = useId()

	return (
		<>
			{!!onDelete && (
				<span id={descriptionId} style={{ display: 'none' }}>
					{deleteDescription}
				</span>
			)}
			<MuiChip onDelete={onDelete} aria-describedby={descriptionId} {...rest} />
		</>
	)
}
