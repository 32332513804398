// removes a  provided key from an object
// useful for removing __typename from and object (recursivly) before sending to graphql endpoint.

const omitDeep = function (obj, key) {
	if (!obj) {
		return null
	}
	const keys = Object.keys(obj)
	const newObj = {}
	keys.forEach((i) => {
		if (i !== key) {
			const val = obj[i]
			if (Array.isArray(val)) {
				newObj[i] = omitDeepArrayWalk(val, key)
			} else if (typeof val === 'object' && val !== null) {
				newObj[i] = omitDeep(val, key)
			} else {
				newObj[i] = val
			}
		}
	})
	return newObj
}

const omitDeepArrayWalk = function (arr, key) {
	return arr.map((val) => {
		if (Array.isArray(val)) {
			return omitDeepArrayWalk(val, key)
		} else if (typeof val === 'object') {
			return omitDeep(val, key)
		}
		return val
	})
}

export default omitDeep
export { omitDeepArrayWalk as omitDeepArray }
