import {
	Typography,
	useTheme,
	ArrowDropDownIcon,
	ArrowDropUpIcon,
	Box,
	PercentageText,
} from '@persuit/ui-components'

export type PercentPricingItemDeltaProps = {
	originalNumber: number
	newNumber: number
	showZero?: boolean
	isDiscountPricingItem?: boolean
}

export const PercentPricingItemDelta = ({
	originalNumber,
	newNumber,
	showZero = false,
	isDiscountPricingItem = false,
}: PercentPricingItemDeltaProps) => {
	const theme = useTheme()
	if (originalNumber === newNumber) {
		if (!showZero) return null
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography variant="body2">
					<PercentageText value={'0'} />
				</Typography>
			</Box>
		)
	}

	const difference = originalNumber - newNumber
	const absDifference = Math.abs(difference)
	const percentageChange = (absDifference * 100) / originalNumber
	const isPercentageValid = !isNaN(percentageChange) && isFinite(percentageChange)
	const Icon = difference > 0 ? ArrowDropDownIcon : ArrowDropUpIcon

	if (isDiscountPricingItem) {
		const color = difference > 0 ? theme.palette.error.main : theme.palette.success.main

		return isPercentageValid ? (
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				color={color}
				data-testid="discountItemDelta"
			>
				<Icon color="inherit" />
				<Typography variant="body2" color="inherit">
					<PercentageText value={percentageChange.toFixed(0).toString()} />
				</Typography>
			</Box>
		) : null
	}

	const color = difference > 0 ? theme.palette.success.main : theme.palette.error.main

	return isPercentageValid ? (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			color={color}
			data-testid="pricingItemDelta"
		>
			<Icon color="inherit" />
			<Typography variant="body2" color="inherit">
				<PercentageText value={percentageChange.toFixed(0).toString()} />
			</Typography>
		</Box>
	) : null
}
