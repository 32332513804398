import { memo } from 'react'
import { RichTextView, Typography } from '@persuit/ui-components'

import { Response } from '../types'
import responseStates from '../../../../../common/data/rfp-proposal-states'

import RateCardView from '../../../rfp-proposals/rfp-proposal-detail/rate-card-view.jsx'
import { ExpandableCell, Cell, CellProps } from '../components'

import { useStore } from '../../store'

type PricingCellProps = CellProps &
	Omit<CellContentProps, 'expandedView'> & {
		responseId: string
		deliverableTitle: string
		groupTitle?: string
		index: number
		responseToDeliverable?: null | { _id: string; notIncluded?: boolean | null }
	}

export const PricingCell = (props: PricingCellProps) => {
	const { deliverableTitle, responseToDeliverable, index, groupTitle } = props
	const { style, sx, responseId, headers, ...rest } = props

	if (!responseToDeliverable) {
		return null
	}

	const { notIncluded } = responseToDeliverable

	if (notIncluded) {
		return (
			<Cell responseId={responseId} sx={sx} style={style} cellProps={props.cellProps}>
				<Typography color="text.secondary">Excluded from our scope</Typography>
			</Cell>
		)
	}

	return (
		<ExpandableCell
			headers={headers}
			cellProps={props.cellProps}
			sx={sx}
			style={style}
			responseId={responseId}
			expandedTitle={`${index + 1}. ${deliverableTitle}`}
			expandedSubTitle={groupTitle}
			expandedContent={
				<CellContentMemoized responseId={responseId} {...rest} expandedView={true} />
			}
		>
			<CellContentMemoized responseId={responseId} {...rest} expandedView={false} />
		</ExpandableCell>
	)
}

type ResponseToDeliverable = NonNullable<NonNullable<Response['responseToDeliverables']>[number]>

type CellContentProps = {
	currency: string
	deliverableTitle: string
	groupTitle?: string
	index: number
	isProposalRevised: boolean
	phaseDuration?: string | null
	rank: { rank: number; tied: boolean } | null
	rates: { _id: string; label?: string | null; pricingPreferences?: string | null }[]
	responseStatus: string
	responseToDeliverable?: null | Pick<ResponseToDeliverable, '_id'> | any
	expandedView: boolean
	responseId: string
}

const CellContent = (props: CellContentProps) => {
	const {
		currency,
		rates,
		responseStatus,
		responseToDeliverable,
		index,
		phaseDuration,
		isProposalRevised,
		rank,
		expandedView,
		responseId,
	} = props

	const { isWide, truncateCellContents } = useStore((state, s) => ({
		isWide: s.isWide(responseId),
		truncateCellContents: state.truncateCellContents,
	}))

	const truncationLength = isWide ? 200 : 100
	const truncationValue = !expandedView && truncateCellContents ? truncationLength : undefined

	if (!responseToDeliverable) {
		return null
	}

	const isCompetitor = responseStatus === responseStates.COMPETITOR

	const { comment, notIncluded, pricingModel } = responseToDeliverable

	// Special case when the response is set to "Excluded from our scope"
	if (notIncluded) {
		return <Typography>Excluded from our scope</Typography>
	}

	return (
		<div>
			<RateCardView
				isProposalRevised={isProposalRevised}
				currency={currency}
				responseToDeliverable={responseToDeliverable}
				requestedRates={rates}
				phaseIndex={index}
				showTotal={expandedView}
				expandedView={expandedView}
				cellView={!expandedView}
				showHeader={true}
				phaseDuration={phaseDuration}
				pricingModel={pricingModel}
				isCompetitor={isCompetitor}
				rank={rank}
			/>
			<br />
			<RichTextView content={comment} truncationLength={truncationValue} />
		</div>
	)
}

const CellContentMemoized = memo(CellContent)
