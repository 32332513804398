import { isNotNilProp, isNotNil } from '@persuit/common-utils'

import { average } from './utils'
import { RateCard } from './types'

export type TimekeeperCellData = {
	proposedRate: number | null
	currentRate: number | null
	delta: number | null
}

/**
 * Calculates the average values across ratecards for a specific timekeeper.
 * This is a plain average.
 * Delta is only calculated using ratecards which supply a currentRate
 */
export function calculateTimekeeperCell(timekeeper: string, rateCards: RateCard[]) {
	// Find a particular timekeeper by id from a ratecard with a list of timekeeprs
	const timekeeperFromRateCard = (rateCard: RateCard) =>
		rateCard.timekeepers.find((tk) => tk.isEnabled && tk.timekeeper === timekeeper)

	const proposedRate = average(
		rateCards
			.map(timekeeperFromRateCard)
			.map((timekeeper) => timekeeper?.proposedRate)
			.filter(isNotNil)
			.filter((proposedRate) => proposedRate > 0),
	)

	const responsesWithCurrentRate = rateCards
		.filter((card) => card.isCurrentRateApplicable)
		.map(timekeeperFromRateCard)
		.filter(isNotNil)
		.filter(isNotNilProp('proposedRate'))
		.filter(isNotNilProp('currentRate'))
		.filter((tk) => tk.currentRate > 0)

	const currentRate = average(responsesWithCurrentRate.map((r) => r.currentRate))
	const avgProposedRateForCurrentRateCards = average(
		responsesWithCurrentRate.map((r) => r.proposedRate),
	)

	return {
		proposedRate,
		currentRate,
		delta:
			currentRate && avgProposedRateForCurrentRateCards
				? (avgProposedRateForCurrentRateCards - currentRate) / currentRate
				: null,
	}
}
