import { ClientItemPriceModel, ClientTotalPriceModel } from '../../../../types'

export const ClientPricingModelStrings: Record<ClientTotalPriceModel, string> = {
	none: 'No Preference',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	contingencyFee: 'Contingency Fee',
	successFee: 'Success Fee',
	hourlyRates: 'Hourly Rates',
	hourlyRate: 'Hourly Rate',
	other: 'Other see details',
	estimate: 'Estimate Only',
	notApplicable: 'Not Applicable',
	collaredFee: '',
	afa: '',
	included: '',
	includedInItemTotal: '',
	rates: '',
	['']: '',
}

export const ClientItemPricingModelStrings: Record<ClientItemPriceModel, string> = {
	none: 'No Preference',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	collaredFee: 'Collared Fee',
	contingencyFee: 'Contingency Fee',
	contingencyPercentage: 'Contingency Fee (%)',
	discountPercentage: 'Discount (%)',
	successFee: 'Success Fee',
	hourlyRates: 'Rate Card',
	hourlyRate: 'Hourly Rate',
	other: 'Other (see instructions)',
	estimate: 'Budgeting Estimate',
	included: 'No charge',
	afa: 'Fee Arrangements',
	rates: 'Rates',
	['']: '',
}

export const ClientSubItemPricingModelStrings: {
	[K in ClientTotalPriceModel]?: string
} = {
	none: 'No Preference',
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	contingencyFee: 'Contingency Fee',
	discountPercentage: 'Discount (%)',
	successFee: 'Success Fee',
	other: 'Other (see instructions)',
	estimate: 'Budgeting Estimate',
	includedInItemTotal: 'No charge',
	afa: 'Fee Arrangements',
	['']: '',
}
