import { createBatchedPromiseFunction } from '@persuit/common-utils'
import { graphql, useApolloClient } from '@persuit/ui-graphql'
import {
	SaliCity,
	SaliCountry,
	SaliState,
	UseSaliLocationData_GetSaliLocationDataQuery,
} from '@persuit/ui-graphql/generated'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHandleUnexpectedError } from '../use-handle-unexpected-error'

export type { SaliCountry, SaliCity, SaliState } from '@persuit/ui-graphql/generated'

const QUERY = graphql(`
	query UseSaliLocationData_GetSaliLocationData {
		getSaliLocationData {
			code
			name
			states {
				code
				name
				cities {
					code
					name
				}
			}
		}
	}
`)

type LocationData = UseSaliLocationData_GetSaliLocationDataQuery['getSaliLocationData']

// TODO: This works for now, in future upgrade Apollo Client and use assumeImmutableResults
let batcher: null | (() => Promise<UseSaliLocationData_GetSaliLocationDataQuery>) = null
let locationData: LocationData | null = null

function useGetLocationData() {
	const client = useApolloClient()

	if (!batcher) {
		batcher = createBatchedPromiseFunction(() =>
			client.query({ query: QUERY }).then(({ data }) => data),
		)
	}

	return async () => {
		if (!locationData && batcher) {
			const data = await batcher()
			locationData = data.getSaliLocationData
		}

		return locationData
	}
}

export const useSaliLocationData = () => {
	const [loading, setLoading] = useState(!locationData)
	const [error, setError] = useState(null)
	const [data, setData] = useState(locationData)
	const handleUnexpectedError = useHandleUnexpectedError()
	const getLocationData = useGetLocationData()

	useEffect(() => {
		getLocationData()
			.then((data) => {
				setData(data)
				setError(null)
			})
			.catch((e) => {
				setData(null)
				setError(e)
			})
			.finally(() => setLoading(false))
			.catch(handleUnexpectedError)
	}, [handleUnexpectedError])

	const countries = useMemo(() => data ?? [], [data])

	const regionById = useCallback(
		(id: string): Region | null => {
			return countries.reduce(
				(countryAcc, country) =>
					countryAcc ??
					(country.code === id
						? country
						: country.states.reduce(
								(stateAcc, state) =>
									stateAcc ??
									(state.code === id
										? state
										: state.cities.reduce(
												(cityAcc, city) => cityAcc ?? (city.code === id ? city : null),
												null as Region | null,
										  )),
								null as Region | null,
						  )),
				null as Region | null,
			)
		},
		[countries],
	)

	return { loading, data: countries, error, regionById }
}

type Region = SaliCountry | SaliState | SaliCity
