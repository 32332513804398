import { useTheme } from '@persuit/ui-components'
import { ExpandableCell, ExpandableCellProps, GridCell, GridCellProps } from './cells'
import persuitTheme from '../../../../theme/persuit-theme'

export type RowHeaderCellProps = GridCellProps<'th'>

export const RowHeaderCell = ({ sx, ...rest }: RowHeaderCellProps) => {
	const theme = useTheme()

	return (
		<GridCell
			{...rest}
			cellProps={{
				component: 'th',
				position: 'sticky',
				left: '0px',
				zIndex: '100',
				scope: 'row',
				...rest.cellProps,
			}}
			sx={{
				boxSizing: 'border-box',
				p: '1em',
				maxWidth: '320px',
				minWidth: '200px',
				borderWidth: '1px',
				[`@media (max-width: ${persuitTheme.grid.breakpoints.sm.minWidth})`]: {
					position: 'static',
				},
				background: theme.palette.white,
				borderLeft: `1px solid ${theme.palette.form.borderResting}`,
				borderRight: `1px solid ${theme.palette.form.borderResting}`,
				borderBottom: `1px solid ${theme.palette.form.borderResting}`,
				...sx,
			}}
		/>
	)
}

export type ExpandableRowHeaderCellProps = Omit<ExpandableCellProps, 'responseId' | 'column'> &
	RowHeaderCellProps

export const ExpandableRowHeaderCell = ({ sx, ...rest }: ExpandableRowHeaderCellProps) => {
	const theme = useTheme()

	return (
		<ExpandableCell
			responseId="none"
			{...rest}
			cellProps={{
				component: 'th',
				sx: {
					position: 'sticky',
					left: '0px',
					zIndex: '100',
					transformOrigin: '0 50%',
				},
				...rest.cellProps,
			}}
			sx={{
				boxSizing: 'border-box',
				p: '1em',
				maxWidth: '320px',
				borderWidth: '1px',
				[`@media (max-width: ${persuitTheme.grid.breakpoints.sm.minWidth})`]: {
					position: 'static',
				},
				background: theme.palette.white,
				borderLeft: `1px solid ${theme.palette.form.borderResting}`,
				borderRight: `1px solid ${theme.palette.form.borderResting}`,
				borderBottom: `1px solid ${theme.palette.form.borderResting}`,
				...sx,
			}}
		/>
	)
}
