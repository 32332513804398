import Text from '../text.jsx'

const RfpsExcludedFromAnalyticsText = ({ numberOfRfpExcludedInAnalytics }) => {
	if ([null, undefined, 0].includes(numberOfRfpExcludedInAnalytics)) {
		return null
	}

	return (
		<Text
			type="body1"
			italics={true}
		>{`Your organisation has chosen not to track ${numberOfRfpExcludedInAnalytics} Request(s). The Request(s) may be tests, duplicates, cancelled or may have distorted data.`}</Text>
	)
}

export default RfpsExcludedFromAnalyticsText
