/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useId, useState, useRef, useEffect } from 'react'
import {
	Box,
	Button,
	Typography,
	useTheme,
	ExpandMoreIcon,
	ExpandLessIcon,
} from '@persuit/ui-components'
import { RfpProgressStepCard } from '../rfp-progress-step-card'
import { RfpProgressMiniView } from '../rfp-progress-mini-view'
import { RfpProgress } from '@persuit/ui-graphql/generated'

type RFPProgressContainerProps = {
	title?: string
	expanded?: boolean
	onExpandClick?: () => void
	rfpProgress: RfpProgress
	maxHeight?: string
}

export const RFPProgressContainer = ({
	title = 'Progress',
	expanded,
	onExpandClick,
	rfpProgress,
	maxHeight,
}: RFPProgressContainerProps) => {
	const { palette } = useTheme()
	const [internalExpand, setInternalExpand] = useState(false)

	const isExpanded = typeof expanded !== 'undefined' ? expanded : internalExpand

	function onExpandBtnClick() {
		if (onExpandClick) {
			onExpandClick()
		} else {
			setInternalExpand(!internalExpand)
		}
	}

	const extraData = rfpProgress.extraData
	const steps = rfpProgress.steps
	const expandedId = useId()
	const expandedFocusButton = useRef<HTMLButtonElement>(null)
	const nonExpandedFocusButton = useRef<HTMLButtonElement>(null)
	const lastExpanded = useRef(isExpanded)

	useEffect(() => {
		if (isExpanded !== lastExpanded.current) {
			if (isExpanded) {
				// Move the focus to the logical button
				expandedFocusButton?.current?.focus()
			} else {
				nonExpandedFocusButton?.current?.focus()
			}

			lastExpanded.current = isExpanded
		}
	}, [isExpanded])

	return (
		<Box
			bgcolor={palette.common.white}
			borderRadius="5px"
			border={`1px solid ${palette.form.borderResting}`}
			width="100%"
			boxSizing="border-box"
			maxHeight={maxHeight}
			display="flex"
			flexDirection="column"
			aria-label="Request progress"
			tabIndex={-1}
			role="group"
		>
			{/* Non chrome browser overflow support */}
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					minHeight: 0,
				}}
			>
				<Box
					sx={{
						flex: 1,
						overflow: 'auto',
					}}
				>
					<Box borderBottom={`1px solid ${palette.form.borderResting}`} p={2}>
						<Typography variant="h2XSmall">{title}</Typography>
						<Box mt={1}>
							<RfpProgressMiniView steps={steps} />
						</Box>
					</Box>
					<Box id={expandedId}>
						{isExpanded ? (
							<>
								<Box p={1}>
									<Button
										ref={expandedFocusButton}
										onClick={onExpandBtnClick}
										fullWidth={true}
										aria-expanded={isExpanded ? 'true' : 'false'}
										aria-controls={expandedId}
									>
										<Box
											display="flex"
											justifyContent="space-between"
											width="100%"
											alignItems="center"
										>
											<Typography variant="button" color="primary">
												{`Show less`}
											</Typography>
											{<ExpandLessIcon color="primary" />}
										</Box>
									</Button>
								</Box>
								<Box
									mt={1}
									p={2}
									borderBottom={`1px solid ${palette.form.borderResting}`}
									style={{ overflowY: 'auto' }}
									role="list"
								>
									{steps.map((step, index) => (
										<RfpProgressStepCard
											step={step}
											extraData={extraData}
											index={index}
											key={index}
										/>
									))}
								</Box>
							</>
						) : null}
					</Box>
					<Box p={1}>
						<Button
							onClick={onExpandBtnClick}
							fullWidth={true}
							aria-expanded={isExpanded ? 'true' : 'false'}
							aria-controls={expandedId}
							ref={nonExpandedFocusButton}
							data-trackid={isExpanded ? undefined : 'rfp-progress-expand'}
						>
							<Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
								<Typography variant="button" color="primary">
									{isExpanded ? `Show less` : `Show more`}
								</Typography>

								{isExpanded ? (
									<ExpandLessIcon color="primary" />
								) : (
									<ExpandMoreIcon color="primary" />
								)}
							</Box>
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
