import { Button, ButtonProps } from '../../button'
import { GoogleIcon } from '../../../icons'

export const SignInWithGoogleButton = (props: ButtonProps) => (
	<Button
		variant="secondary"
		LinkComponent="a"
		href="/auth/google"
		fullWidth={true}
		startIcon={<GoogleIcon />}
		size="medium"
		{...props}
	>
		Sign in with Google
	</Button>
)
