import React from 'react'
import {
	ArrowDownwardIcon,
	DraftsIcon,
	HistoryIcon,
	ReviseProposalIcon,
	SendIcon,
	ThumbUpIcon,
	SvgIconProps,
} from '@persuit/ui-components'
import { PROPOSAL_STATES } from '../../../../common/data/rfp-proposal-states'
import { BaseStatus, BgColorMap } from '@client/components/base-status'

type ProposalStateMap = {
	[key: string]: {
		icon: React.ComponentType<{ fontSize: SvgIconProps['fontSize'] }>
		text: string
		colour: keyof typeof BgColorMap
	}
}

const proposalStateMap: ProposalStateMap = {
	[PROPOSAL_STATES.DRAFT]: { icon: DraftsIcon, text: 'PROPOSAL DRAFTED', colour: 'GREY' },
	[PROPOSAL_STATES.DRAFT_REVISION]: { icon: DraftsIcon, text: 'DRAFT REVISION', colour: 'GREY' },
	[PROPOSAL_STATES.FINALIZED]: { icon: SendIcon, text: 'PROPOSAL SENT', colour: 'GREY' },
	[PROPOSAL_STATES.SENT]: { icon: SendIcon, text: 'PROPOSAL SENT', colour: 'GREY' },
	[PROPOSAL_STATES.ACCEPTED]: { icon: ThumbUpIcon, text: 'SELECTED', colour: 'GREEN' },
	[PROPOSAL_STATES.UNDER_REVISION]: { icon: HistoryIcon, text: 'UNDER REVISION', colour: 'RED' },
	[PROPOSAL_STATES.WITHDRAWN]: { icon: ArrowDownwardIcon, text: 'WITHDRAWN', colour: 'GREY' },
	[PROPOSAL_STATES.REVISED]: { icon: ReviseProposalIcon, text: 'REVISED', colour: 'GREY' },
}

type ProposalStatesProps = {
	status: keyof typeof proposalStateMap
	expanded?: boolean
}

export const ProposalStates = ({ status, expanded = true }: ProposalStatesProps) => {
	const statusAttributes = proposalStateMap[status]
	const iconSize = expanded ? undefined : 'small'

	return (
		<>
			<BaseStatus
				expanded={expanded}
				iconNode={<statusAttributes.icon fontSize={iconSize} />}
				primaryText={statusAttributes.text}
				variant={statusAttributes.colour}
			/>
		</>
	)
}
