// @ts-strict-ignore
import { ErrorCardData, ErrorCardTransformer } from '../../types'

export const makeErrorCardDataBuilder =
	(transformers: Array<ErrorCardTransformer> = []) =>
	// TODO undo this null once the errors for rates are defined
	(errors: Record<string, object | null> = {}): Array<ErrorCardData> => {
		return transformers.reduce((acc, handler) => {
			if (!(handler.sectionName in errors)) return acc
			const result = handler.sectionHandler(errors[handler.sectionName])
			return [...acc, ...result]
		}, [])
	}
