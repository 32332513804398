import { SvgIcon, SvgIconProps } from '../components/svg'

export const PanelManagementIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<path
					d="M15.3567 8.05625C14.4304 7.6525 13.2904 7.34375 12 7.34375C10.7096 7.34375 9.56958 7.6525 8.64333 8.06417C7.78833 8.43625 7.25 9.29125 7.25 10.2254V11.5H16.75V10.2175C16.75 9.29125 16.2117 8.43625 15.3567 8.05625Z"
					fill="white"
				/>
				<path
					d="M7.25 5.95833C7.25 6.82917 6.5375 7.54167 5.66667 7.54167C4.79583 7.54167 4.08333 6.82917 4.08333 5.95833C4.08333 5.0875 4.79583 4.375 5.66667 4.375C6.5375 4.375 7.25 5.0875 7.25 5.95833Z"
					fill="white"
				/>
				<path
					d="M5.66667 8.33333C5.97542 8.33333 6.26833 8.365 6.56125 8.4125C6.24458 8.95083 6.0625 9.56833 6.0625 10.2254V11.5H2.5V10.2571C2.5 9.61583 2.88 9.04583 3.46583 8.7925C4.13875 8.49958 4.88292 8.33333 5.66667 8.33333Z"
					fill="white"
				/>
				<path
					d="M19.9167 5.95833C19.9167 6.82917 19.2042 7.54167 18.3333 7.54167C17.4625 7.54167 16.75 6.82917 16.75 5.95833C16.75 5.0875 17.4625 4.375 18.3333 4.375C19.2042 4.375 19.9167 5.0875 19.9167 5.95833Z"
					fill="white"
				/>
				<path
					d="M20.5342 8.7925C21.12 9.04583 21.5 9.61583 21.5 10.2571V11.5H17.9375V10.2254C17.9375 9.56833 17.7554 8.95083 17.4388 8.4125C17.7317 8.365 18.0246 8.33333 18.3333 8.33333C19.1171 8.33333 19.8613 8.49958 20.5342 8.7925Z"
					fill="white"
				/>
				<path
					d="M14.375 4.375C14.375 3.06083 13.3142 2 12 2C10.6858 2 9.625 3.06083 9.625 4.375C9.625 5.68917 10.6858 6.75 12 6.75C13.3142 6.75 14.375 5.68917 14.375 4.375Z"
					fill="white"
				/>
				<path
					d="M0 13.5C0 13.2239 0.223858 13 0.5 13H23.5C23.7761 13 24 13.2239 24 13.5V15.5C24 15.7761 23.7761 16 23.5 16H22V21.5C22 21.7761 21.7761 22 21.5 22H2.5C2.22386 22 2 21.7761 2 21.5L2 16H0.5C0.223857 16 0 15.7761 0 15.5V13.5Z"
					fill="white"
				/>
			</svg>
		</SvgIcon>
	)
}
