import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import i81nOptions from '../common/i18next'

i18next
	.use(initReactI18next) // magically bind react-i18next to react
	.init({
		...i81nOptions,

		resources: window.__TRANSLATIONS__,
	})

export default i18next
