import { Fragment } from 'react'
import { CircularProgress, Typography, CheckIcon, ErrorOutlineIcon } from '@persuit/ui-components'

type ValidationLineProps = {
	loading: boolean
	hasValidationErrors: boolean
}

export const ValidationLine = ({ loading, hasValidationErrors }: ValidationLineProps) => {
	if (loading) {
		return (
			<Fragment>
				<CircularProgress size={24} />
				<Typography>Checking for errors...</Typography>
			</Fragment>
		)
	}
	if (hasValidationErrors) {
		return (
			<Fragment>
				<ErrorOutlineIcon style={{ color: 'red' }} />
				<Typography>Errors found</Typography>
			</Fragment>
		)
	}

	return (
		<Fragment>
			<CheckIcon color="primary" />
			<Typography>No errors</Typography>
		</Fragment>
	)
}
