import { Box, ContentBox, ScreenOnly, Typography, Spacer } from '@persuit/ui-components'
import { ColumnActions } from '../../../column-actions'
import { colorCorePink40 } from '@persuit/ui-components/tokens'

export const RankHeader = ({
	rank,
	numResponses,
	isFirmUser,
	showLowestBid,
	isCurrentUserBidLowest,
	tiedForLowest,
	proposalId,
	showRank,
}) => {
	return (
		<ContentBox>
			<Box
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent={showRank ? 'space-between' : 'flex-end'}
				data-testid="rankContainer"
				width="100%"
				height="32px"
				left="-1em"
				top="-1em"
				padding="0 1em"
				fontWeight="bold"
				bgcolor={showRank ? 'primary.lighterHue' : undefined}
			>
				{showRank && (
					<>
						{tiedForLowest && (
							<Box>
								<Spacer />
								<Typography
									variant="body2"
									component="p"
									data-testid="currentBidText"
									sx={{
										color: colorCorePink40,
									}}
								>
									Tied for the lowest bid
								</Typography>
							</Box>
						)}
						{!tiedForLowest && (
							<Box>
								<Typography
									component="p"
									variant="body2Semibold"
									color="primary.main"
									lineHeight={1} // Line height is to squeeze the "Current lowest bid" (below) into the rank header
								>
									Ranking {rank} of {numResponses}
								</Typography>
								{isFirmUser && showLowestBid && (
									<Typography
										component="p"
										variant="body2"
										data-testid="currentBidText"
										color={colorCorePink40}
										lineHeight={1}
									>
										{isCurrentUserBidLowest
											? 'You are in the lead with the lowest bid'
											: 'Current lowest bid'}
									</Typography>
								)}
							</Box>
						)}
					</>
				)}
				<ScreenOnly>
					<ColumnActions proposalId={proposalId} />
				</ScreenOnly>
			</Box>
		</ContentBox>
	)
}
