import { gql } from '@apollo/client'
import { graphql } from '@persuit/ui-graphql'

export const ELIMINATE_FIRM_MUTATION = graphql(`
	mutation ManageFirm_EliminateFirm($payload: EliminateInput!) {
		eliminateFirm(payload: $payload) {
			_id
		}
	}
`)

export const REOPEN_RFP_FOR_FIRM = gql`
	mutation reOpenRfpForFirm($requestId: ID!, $firmOrgId: ID!) {
		reOpenRfpForFirm(requestId: $requestId, firmOrgId: $firmOrgId) {
			_id
			invites {
				isReOpened
			}
		}
	}
`

export const CLOSE_RFP_FOR_FIRM = gql`
	mutation closeRfpForFirm($_id: ID!, $firmOrgId: ID!) {
		closeRfpForFirm(_id: $_id, firmOrgId: $firmOrgId) {
			_id
			invites {
				isReOpened
			}
		}
	}
`
