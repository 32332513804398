import { graphql, useQuery } from '@persuit/ui-graphql'
import { isNotNil } from '@persuit/common-utils'
import {
	Box,
	Popover,
	IconButton,
	StarIcon,
	StarBorderIcon,
	CloseIcon,
	Typography,
	useTheme,
	Spacer,
	Link,
} from '@persuit/ui-components'

import { formatLocationLabel } from '../../utils/location'
import { DisabledLabel, NAMWOLFLabel } from '../../components/label'
import { PanelPreferredFirmLabel } from '../../components/panel-preferred-firm/panel-preferred-firm-label'
import { ApprovedFirmLabel } from '../../components/approved-firm/approved-firm-label'

import { useToggleFavorites } from './use-toggle-favourites'

const getUserQuery = graphql(`
	query ChipExpanded_GetUser($userId: ID) {
		getUser(userId: $userId) {
			_id
			email
			title
			phone
			isDisabled
			professionalProfile {
				_id
				url
				profile
			}
			org {
				_id
				isNamwolfMember
			}
			location {
				name
				parentChain
				code
			}
		}
	}
`)

const createBrowserFriendlyUrl = (url: string) => {
	const lowerCasedUrl = url.toLowerCase()
	return lowerCasedUrl.startsWith('http://') || lowerCasedUrl.startsWith('https://')
		? url
		: `https://${lowerCasedUrl}`
}

export type ChipExpandedProps = {
	primaryText: string
	secondaryText?: string

	additionalText?: string[]
	isApprovedFirm?: boolean
	isFavourite?: boolean
	isPanelOrPreferredFirm?: boolean
	userId?: string
	hideStar?: boolean

	expanded: boolean
	expandAnchor: null | HTMLElement

	onClose: () => void
}

export const ChipExpanded = ({
	isApprovedFirm = false,
	isFavourite = false,
	isPanelOrPreferredFirm = false,
	hideStar = false,
	onClose,
	primaryText,
	secondaryText,
	expanded,
	expandAnchor,
	userId,
}: ChipExpandedProps) => {
	const { toggleFavourite } = useToggleFavorites()
	const addToFavourites = () => toggleFavourite(userId)

	const theme = useTheme()
	const {
		loading: loadingUser,
		data: userData,
		error: userError,
	} = useQuery(getUserQuery, {
		variables: { userId: userId || null },
	})

	const professionalProfile = userData?.getUser?.professionalProfile
	const isNamwolfMember = !!userData?.getUser?.org?.isNamwolfMember

	const additionalText = loadingUser
		? ['Loading...']
		: !userError && userData?.getUser
		? [
				userData.getUser.email ?? null,
				userData.getUser.title ?? null,
				userData.getUser.phone ?? null,
				userData.getUser.location ? formatLocationLabel(userData.getUser.location) : null,
		  ].filter(isNotNil)
		: null

	const isUserDisabled = !!userData?.getUser?.isDisabled

	return (
		<Popover
			anchorEl={expandAnchor}
			open={expanded}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			onClose={onClose}
			disableScrollLock={true}
			sx={{
				zIndex: theme.zIndex.tooltip,
			}}
		>
			<Box minWidth="288px" maxWidth="388px" margin="0px">
				<Box
					display="flex"
					flexWrap="nowrap"
					justifyContent="space-between"
					alignItems="center"
					bgcolor={theme.palette.grey300}
					p={2}
				>
					<Box flexGrow={3} flexShrink={3} flexBasis="10em">
						{isUserDisabled || hideStar ? null : (
							<IconButton
								onClick={addToFavourites}
								size="large"
								aria-label={isFavourite ? 'Remove from favorites' : 'Add to favourites'}
							>
								{isFavourite ? (
									<StarIcon fontSize="large" style={{ color: theme.palette.amber600 }} />
								) : (
									<StarBorderIcon fontSize="large" />
								)}
							</IconButton>
						)}
					</Box>

					<Box flexGrow={1} flexShrink={1} flexBasis="20em">
						<Typography variant="body1Semibold">{primaryText}</Typography>
						{secondaryText && <Typography variant="body2">{secondaryText}</Typography>}

						<Box display="flex" alignItems="center">
							{isApprovedFirm ? <ApprovedFirmLabel mr={1} /> : null}
							{isPanelOrPreferredFirm ? <PanelPreferredFirmLabel mr={1} /> : null}
							{isNamwolfMember ? <NAMWOLFLabel /> : null}
						</Box>
					</Box>

					<Box flexGrow={3} flexShrink={3} flexBasis="10em">
						<IconButton onClick={onClose} size="large" aria-label="close">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				{!!additionalText?.length || professionalProfile?.length ? (
					<Box
						display="flex"
						flexWrap="nowrap"
						justifyContent="space-between"
						alignItems="centers"
						padding="12px"
					>
						<Box flexGrow={2} flexShrink={2} flexBasis="12em" />

						<Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} flexBasis="30em">
							{(additionalText ?? []).map((text, i) => (
								<Typography key={`expanded-chip-text-${i}`} variant="body2">
									{text}
								</Typography>
							))}

							{(professionalProfile ?? []).map((data, i) => (
								<Link
									variant="body2"
									key={i}
									href={data.url ? createBrowserFriendlyUrl(data.url) : undefined}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.profile}
								</Link>
							))}

							{isUserDisabled ? (
								<>
									<Spacer />
									<DisabledLabel />
								</>
							) : null}
						</Box>
					</Box>
				) : null}
			</Box>
		</Popover>
	)
}
