import { useTheme, Box } from '@persuit/ui-components'
import { Deliverable, DeliverableProps } from './deliverable'

export type DeliverableContainerProps = DeliverableProps & {
	floating?: boolean
}

export const DeliverableContainer = ({ floating = true, ...rest }: DeliverableContainerProps) => {
	const theme = useTheme()
	if (floating)
		return (
			<Box role="listitem" style={{ border: `1px solid ${theme.palette.grey500}` }} padding={1}>
				<Deliverable {...rest} />
			</Box>
		)

	return (
		<Box role="listitem">
			<Deliverable {...rest} />
		</Box>
	)
}
