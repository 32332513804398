import { useState } from 'react'
import {
	Dialog,
	DialogDeprecatedProps,
	TextField,
	Typography,
	SearchIcon,
	CloseIcon,
	Box,
	FormRadio,
	Form,
	IconButton,
	useSnackbar,
} from '@persuit/ui-components'
import { isGroup, PricingItemFieldPath, useFormContext, useWatch } from '../../pricing-form-utils'
import { getOrGenerateUUID } from '@persuit/common-utils'

type PricingItemMoveToGroupDialogProps = {
	fieldPath: PricingItemFieldPath
	pricingItemIndex: number
	onClose: () => void
	movePricingItemIntoGroup?: (groupId: string) => void
} & DialogDeprecatedProps

export const PricingItemMoveToGroupDialog = ({
	fieldPath,
	pricingItemIndex,
	movePricingItemIntoGroup,
	onClose,
	...props
}: PricingItemMoveToGroupDialogProps) => {
	const { getValues } = useFormContext()
	const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
	const [searchTerm, setSearchTerm] = useState('')
	const { openSnackbar } = useSnackbar()

	const onCloseInternal = () => {
		onClose()
		setSearchTerm('')
	}

	const groups = getValues().pricingItems.filter(isGroup)
	const transformedGroups = groups
		.map((group, idx) => ({
			id: getOrGenerateUUID(group),
			label: group?.title || `Group ${idx + 1}`,
			itemsCount: group.deliverables.length,
		}))
		.filter(({ label }) =>
			searchTerm ? label.toLowerCase().includes(searchTerm.toLowerCase()) : true,
		)

	const itemTitle = useWatch({
		name: `${fieldPath}.deliverableTitle`,
	})

	const dialogTitle = itemTitle || `Pricing item ${pricingItemIndex + 1}`

	return (
		<Dialog
			onClose={onCloseInternal}
			size="md"
			title={`Move "${dialogTitle}" to a group`}
			actions={[
				{
					label: 'Cancel',
					variant: 'text',
					onClick: onCloseInternal,
				},
				{
					label: 'Move Item',
					variant: 'primary',
					disabled: !selectedGroup,
					onClick: () => {
						if (selectedGroup) {
							movePricingItemIntoGroup?.(selectedGroup)
							openSnackbar('Item has been moved to group.')
							onCloseInternal()
						}
					},
				},
			]}
			{...props}
			onClick={(e) => e.stopPropagation()}
		>
			<Form>
				<Box display="flex" alignItems="center" p={2}>
					<SearchIcon />
					<Box ml="0.5rem" flexGrow={1}>
						<TextField
							fullWidth={true}
							label={null}
							id="move-item-into-group-search-input"
							placeholder="Search for group"
							value={searchTerm}
							onChange={(event) => setSearchTerm(event.currentTarget.value)}
						/>
					</Box>
					<IconButton aria-label="Clear search term" onClick={() => setSearchTerm('')} size="large">
						<CloseIcon />
					</IconButton>
				</Box>

				{transformedGroups.length === 0 && (
					<Box width="100%" textAlign="center">
						<Typography>No groups found for the search term: {searchTerm}</Typography>
					</Box>
				)}
				{transformedGroups.length !== 0 && (
					<Box
						textAlign={'left'}
						minHeight={'40vh'}
						maxHeight={'40vh'}
						overflow="hidden auto"
						p={2}
					>
						<FormRadio
							values={transformedGroups.map((group) => ({
								label: (
									<Box>
										<Box maxWidth="700px">
											<Typography variant="body1Semibold" noWrap={true} title={group.label}>
												{group.label}
											</Typography>
										</Box>
										{group.itemsCount > 0 && (
											<Typography variant="caption" color="text.secondary">
												{`${group.itemsCount} item(s)`}
											</Typography>
										)}
									</Box>
								),
								value: getOrGenerateUUID(group),
							}))}
							id={fieldPath}
							label={`${transformedGroups.length} group(s)`}
							name="group"
							radioGroupProps={{
								onChange: (event) => setSelectedGroup(event.currentTarget.value),
							}}
						/>
					</Box>
				)}
			</Form>
		</Dialog>
	)
}
