import {
	Box,
	Button,
	ClearIcon,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	List,
	LoadingSpinner,
	MenuItem,
	SearchIcon,
	Select,
	TextField,
	Typography,
} from '@persuit/ui-components'
import { PeopleSearchListItem, SearchResult } from './people-search-list-item'
import { InvitablePeopleSortOrder } from '@persuit/ui-graphql/generated'

type Group = {
	_id: string
	name: string
}

type PanelFirmTag = {
	firmId: string
	groupId: string
	type: string
}

export type Org = {
	_id: string
	groups: Group[]
	panelFirmTags: PanelFirmTag[]
}

export type PeopleSearchProps = {
	searchResults: Array<SearchResult>
	defaultGroupFilter?: string
	onTabChange: () => void
	onSearchChange: (e: string) => void
	searchTerm: string
	showNoResultsMessage: boolean
	totalCount: number
	peopleLoading: boolean
	hasContacts: boolean
	inviteError: string | undefined
	contactSortOrder: InvitablePeopleSortOrder
	setContactSortOrder: React.Dispatch<React.SetStateAction<InvitablePeopleSortOrder>>
}

export const PeopleSearch = ({
	onTabChange,
	searchResults,
	onSearchChange,
	searchTerm,
	showNoResultsMessage,
	totalCount,
	peopleLoading,
	hasContacts,
	inviteError,
	contactSortOrder,
	setContactSortOrder,
}: PeopleSearchProps) => {
	return (
		<Box>
			<Box
				sx={{
					mb: 3,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-end',
					justifyContent: 'space-between',
				}}
			>
				<Box display="grid" gridTemplateColumns="1fr auto" gap={1} width="100%">
					<TextField
						id="search-contacts"
						data-testid="search-contacts"
						onChange={(e) => onSearchChange(e.target.value)}
						value={searchTerm}
						label="Search"
						fullWidth={true}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchTerm ? (
								<IconButton
									onClick={() => onSearchChange('')}
									size="small"
									aria-label="clear search"
								>
									<ClearIcon color="primary" />
								</IconButton>
							) : null,
						}}
						error={Boolean(inviteError)}
						helperText={inviteError ? inviteError : null}
					/>
					<FormControl variant="outlined" size="medium">
						<InputLabel id="sort-all-contacts">Sort by</InputLabel>
						<Select
							id="sort-all-contacts"
							labelId={`sort-all-contacts`}
							data-testid="sort-all-contacts"
							value={contactSortOrder}
							fullWidth={true}
							variant="outlined"
							size="medium"
							autoWidth={true}
							label="Sort by"
							onChange={(e) => setContactSortOrder(e.target.value as InvitablePeopleSortOrder)}
						>
							<MenuItem value="ASC">Contact name A-Z</MenuItem>
							<MenuItem value="DESC">Contact name Z-A</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>
			{peopleLoading && (
				<Box mt={3} height="100%">
					<LoadingSpinner />
				</Box>
			)}
			{!peopleLoading && !hasContacts && (
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
					<Typography variant="body1Semibold" align="center">
						It appears you don't have any contacts yet.
					</Typography>
					<Typography variant="body2" align="center">
						Contacts can be created by your Customer Success Manager or you can also invite by
						email.
					</Typography>
					<Box sx={{ alignItems: 'center' }}>
						<Button
							variant="outlined"
							size="large"
							onClick={onTabChange}
							aria-label="Navigate to invite by email tab"
						>
							Go to invite by email
						</Button>
					</Box>
				</Box>
			)}
			{!peopleLoading && hasContacts && (
				<Box sx={{ display: 'flex' }}>
					<Box sx={{ flexGrow: 2 }}>
						{showNoResultsMessage ? (
							<Box role="alert" aria-live="polite" p={2}>
								<Typography sx={{ mb: 1 }} color="text.secondary" fontStyle="italic">
									No contacts found. Try adjusting your search criteria.
								</Typography>
							</Box>
						) : (
							<>
								{searchResults.length > 0 && (
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
										<Typography variant="h4" component="h2">
											{`${totalCount} contacts`}
										</Typography>
										<List aria-label="Firm contacts">
											{searchResults.map((person: SearchResult) => (
												<PeopleSearchListItem key={person._id} person={person} />
											))}
										</List>
									</Box>
								)}
							</>
						)}
					</Box>
				</Box>
			)}
		</Box>
	)
}
