import { Fragment } from 'react'

import { FileWrapperDetached } from '../file-wrapper/file-wrapper-detached'
import { FileUpload } from '../file-upload'

import { FileList } from '@persuit/ui-components'

export const FileUploaderDetached = ({ files, disabled, onChange }) => {
	return (
		<FileWrapperDetached files={files} onChange={onChange}>
			{({ uploadFiles, removeFile, uploadError, isUploading, fileList }) => (
				<Fragment>
					<FileList files={fileList} onRemoveFile={removeFile} />
					<FileUpload
						isUploading={isUploading}
						uploadError={uploadError}
						onChange={uploadFiles}
						disabled={disabled}
					/>
				</Fragment>
			)}
		</FileWrapperDetached>
	)
}
