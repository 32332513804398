// @ts-strict-ignore
import { SetStateAction, Dispatch } from 'react'
import { Box, Typography } from '@persuit/ui-components'
import { Rfp } from '@persuit/ui-graphql/generated'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined' // TODO: Get correct Icon?
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { green } from '@mui/material/colors' // TODO: check colors?
import { ProposalFeedbackForm } from './proposal-feedback-form'
import { FormState } from '../'

type ProposalFeedbackFormContainerProps = {
	rfp: Rfp
	firmsAndProposals: any[]
	formState: FormState
	setFormState: Dispatch<SetStateAction<FormState>>
}

const FormHeader = () => {
	return (
		<Box
			sx={(theme) => ({
				p: theme.spacing(1),
				backgroundColor: green[50], // TODO: no matching green in our theme?
				display: 'flex',
			})}
		>
			<Box style={{ padding: '8px 5px 5px 5px' }}>
				<EmojiEventsOutlinedIcon />
			</Box>
			<Box sx={{ p: 0.625 }}>
				<Typography variant="caption">
					Your feedback will help generate better value proposals for future Requests and develop a
					stronger relationship with your firm. Over time, you will be able to see detailed
					analytics of your firm's performance.
				</Typography>
			</Box>
		</Box>
	)
}

const ProposalFeedbackFormContainer = ({
	rfp,
	firmsAndProposals,
	formState,
	setFormState,
}: ProposalFeedbackFormContainerProps) => {
	return (
		<Box>
			<FormHeader />
			<ProposalFeedbackForm
				firmsAndProposals={firmsAndProposals}
				rfp={rfp}
				setFormState={setFormState}
				formState={formState}
			/>
		</Box>
	)
}

export default ProposalFeedbackFormContainer
