import { useEffect, useState } from 'react'

type EventType = keyof DocumentEventMap

const VISIBILITY_CHANGE_EVENT: EventType = 'visibilitychange'

export const useDocumentVisibility = (): {
	visible: boolean
} => {
	const [visible, setVisible] = useState(document.visibilityState === 'visible')

	function toggleVisibility() {
		if (document.visibilityState === 'visible') {
			setVisible(true)
		} else {
			setVisible(false)
		}
	}

	useEffect(() => {
		// Attach listener
		window.addEventListener(VISIBILITY_CHANGE_EVENT, toggleVisibility)

		return () => {
			// Detach listener
			window.removeEventListener(VISIBILITY_CHANGE_EVENT, toggleVisibility)
		}
	}, [])

	return {
		visible,
	}
}
