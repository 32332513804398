import { Typography, TypographyProps } from '../typography'

/**
 * Always aim to include normal H1 in every page.
 * Use this component only when h1 is required for screen readers
 * but is not practical for visual purpose.
 */
export const SROnlyH1 = (props: TypographyProps) => {
	return (
		<Typography
			{...props}
			variant="h1XSmall"
			sx={{
				...props.sx,
				position: 'absolute',
				height: '1px',
				width: '1px',
				padding: 0,
				border: 0,
				overflow: 'hidden',
				clip: 'rect(1px, 1px, 1px, 1px)',
				clipPath: 'inset(0px 0px 99.9% 99.9%)',
			}}
		/>
	)
}
