import {
	Tooltip,
	ToggleButton,
	UnfoldLessIcon,
	UnfoldMoreIcon,
	styled,
	useTheme,
	AIIcon,
	Chip,
	Box,
	Button,
	RefreshIcon,
} from '@persuit/ui-components'
import { Feedback } from './feedback'
import { GridCell, GridCellProps } from '../../components'
import { useActions } from '../../../store'

const OPEN_AI_URI = window.__OPEN_AI_URI__

const HeaderCellTitle = styled(Box)(({ theme }) => ({
	boxSizing: 'content-box',
	position: 'absolute',
	width: '100%',
	height: '3em',
	left: '-1em',
	top: '-1em',
	padding: '4px 1em',
	display: 'flex',
	alignItems: 'center',
	fontWeight: 'bold',
	flexDirection: 'row',
	background: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
}))

const HeaderCell = styled(GridCell)(({ theme }) => ({
	position: 'sticky',
	top: '10px',
	minHeight: '50px',
	zIndex: 300,
	background: theme.palette.teal50,
	borderBottom: '1px solid lightgrey',
	maxWidth: '380px',
	minWidth: '300px',
	padding: '1em',
	transition: 'background 2s',
	borderLeft: `3px solid ${theme.palette.secondary.main}`,
	borderRight: `3px solid ${theme.palette.secondary.main}`,
}))

const HeaderCellContent = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	position: relative;
`

const StyledDiv = styled(Box)`
	display: flex;
	align-items: center;
	padding-top: 1em;
`

const StyledChip = styled(Chip)(({ theme }) => ({
	justifyContent: 'center',
	background: theme.palette.secondary.contrastText,
	color: theme.palette.secondary.main,
	position: 'relative',
	top: '-1px',
	marginLeft: theme.spacing(1),
	'& > *': {
		margin: theme.spacing(0.5),
	},
}))

type IntelligenceHeaderCellProps = GridCellProps & {
	proposals: any[]
	rfpId: string
	isWide: boolean
	toggleIsWide: () => void
}

export const IntelligenceHeaderCell = ({
	isWide,
	toggleIsWide,
	rfpId,
	proposals,
	...rest
}: IntelligenceHeaderCellProps) => {
	const theme = useTheme()

	const { toggleShowBusinessIntel } = useActions()

	return (
		<HeaderCell {...rest}>
			<HeaderCellContent>
				<HeaderCellTitle>
					<AIIcon sx={{ marginRight: theme.spacing(1) }} />
					<Tooltip title="Analyze individual pricing items and questions by leveraging Open AI's cutting-edge language model, ChatGPT.">
						<div>Proposal Analyzer with AI</div>
					</Tooltip>
					<StyledChip color="primary" label="BETA" size="small" />
					<ToggleButton
						value="wide"
						selected={isWide}
						size="small"
						style={{
							border: 'none',
							marginLeft: 'auto',
							transform: 'rotate(90deg)',
							color: theme.palette.secondary.contrastText,
						}}
						onClick={toggleIsWide}
					>
						{isWide ? (
							<UnfoldLessIcon data-trackid="button-collapse-ai-summary" />
						) : (
							<UnfoldMoreIcon data-trackid="button-expand-ai-summary" />
						)}
					</ToggleButton>
				</HeaderCellTitle>
				<StyledDiv sx={{ marginTop: '4rem' }}>
					<Feedback rfpId={rfpId} proposals={proposals} />
				</StyledDiv>
				<Button
					sx={{ mt: 2 }}
					color="secondary"
					startIcon={<RefreshIcon />}
					onClick={async () => {
						toggleShowBusinessIntel()
						await fetch(`${OPEN_AI_URI}/openai/cache-clear-cells/?rfpId=${rfpId}`, {
							credentials: 'include',
						})
						toggleShowBusinessIntel()
					}}
				>
					Re-run analysis
				</Button>
			</HeaderCellContent>
		</HeaderCell>
	)
}
