import { size } from 'lodash/fp'
import { Box, Typography, VerifiedUserIcon } from '@persuit/ui-components'

const CertificationItem = ({ text, dataTestid }) => {
	return (
		<Box data-testid={dataTestid} display="flex" flexDirection="row" gap={1} alignItems="center">
			<VerifiedUserIcon aria-hidden={true} />
			<Typography>{text}</Typography>
		</Box>
	)
}

const renderText = (certificate) => {
	switch (certificate) {
		case 'MWBE':
			return 'Minority or Women Owned Business Enterprise (MWBE)'
		case 'MANSFIELD':
			return 'Mansfield Rule'
		case 'NAMWOLF':
			return 'NAMWOLF Member'
		default:
			return null
	}
}

/** @type {any} */
const CertificationsList = ({ certifications, dataTestid }) => {
	if (size(certifications) === 0) {
		return (
			<div data-testid={dataTestid}>
				<Typography>
					<i>No Diversity Certifications / Memberships available</i>
				</Typography>
			</div>
		)
	}

	// convert the certifications code to text and sort text alphabetically
	const certificationsText = certifications.map((c) => renderText(c))
	const certificationsTextSorted = certificationsText.sort((a, b) => a.localeCompare(b))

	return (
		<Box data-testid={dataTestid} display="flex" flexDirection="column" gap={1}>
			{certificationsTextSorted.map((certificationText) => (
				<CertificationItem
					dataTestid="certification-item"
					key={certificationText}
					text={certificationText}
				/>
			))}
		</Box>
	)
}

export { renderText, CertificationItem }
export default CertificationsList
