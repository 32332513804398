// @ts-strict-ignore
import { isNotNil } from '@persuit/common-utils'
import {
	Response,
	RfpAndProposalQuery,
	RfpProposalRateCard,
	RfprNamedTimekeeper,
} from '@persuit/ui-graphql/generated'
import { keyBy } from 'lodash/fp'

export type RateReviewValue = {
	rateCardId: string
	timekeepers: Array<{
		timekeeperId: string
		suggestedRate?: number | null | undefined
		timekeeperLabel?: string | null | undefined
	}>
	location?:
		| {
				country: string
				regions: string[]
		  }
		| Record<string, never>
		| null
	practiceAreas?: string[]
	assumptions?: string | null | undefined
	originalDescription?: string
	currency?: string
}

export type NamedTimekeeperSuggestion = RfprNamedTimekeeper & {
	firstName: string
	lastName: string
	proposedRate: number | null
	currency: string
}

export const getNamedTimekeeperSuggestions = ({
	namedTimekeeperValues,
	proposalNamedTimekeepers,
	currency,
	proposalStatus,
}: {
	namedTimekeeperValues: RfprNamedTimekeeper[]
	proposalNamedTimekeepers: Response['namedTimekeepers']
	currency: string
	proposalStatus?: string
}): NamedTimekeeperSuggestion[] => {
	const proposalNamedTimekeepersLookup =
		proposalStatus === 'draft-revision'
			? keyBy('originalNamedTimekeeperId', proposalNamedTimekeepers)
			: keyBy('_id', proposalNamedTimekeepers)

	const namedTimekeeperSuggestions = namedTimekeeperValues
		.map<NamedTimekeeperSuggestion | null>((ntk) => {
			if (!ntk.suggestedProposedRate) {
				return null
			}
			const proposalNamedTimekeeper = proposalNamedTimekeepersLookup[ntk.namedTimekeeperId]

			return {
				namedTimekeeperId:
					proposalStatus === 'draft-revision'
						? proposalNamedTimekeeper?.originalNamedTimekeeperId ?? ''
						: ntk.namedTimekeeperId,
				suggestedProposedRate: ntk.suggestedProposedRate,
				firstName: ntk?.firstName ?? proposalNamedTimekeeper?.firstName ?? '',
				lastName: ntk?.lastName ?? proposalNamedTimekeeper?.lastName ?? '',
				proposedRate: proposalNamedTimekeeper?.proposedRate ?? null,
				currency: proposalNamedTimekeeper?.currency ?? currency,
			}
		})
		.filter(isNotNil)

	return namedTimekeeperSuggestions
}

export const getDefaultRateReviewValues = (
	proposal: RfpAndProposalQuery['proposal'],
	rfp: RfpAndProposalQuery['rfp'],
	rateReviewValues: Array<RateReviewValue>,
	isMultiCurrencyToggleEnabled?: boolean,
) => {
	// We need to merge the possibly exising data with
	//  - The latest rate card's existence / ordering from the proposal
	//  - The latest timekeepers existence / ordering from the rfp
	//  - The latest timekeeper disabled state from the proposal

	// We turn the current exising data into lookup maps
	//  - One for the rate cards
	//  - Inside it we convert the timekeepers to maps as well

	const currentDataMap =
		rateReviewValues?.reduce(
			(acc, rateCard) => ({
				...acc,
				[rateCard.rateCardId]: {
					...rateCard,
					timekeepers: rateCard.timekeepers.reduce(
						(timekeeperAcc, timekeeper) => ({
							...timekeeperAcc,
							[timekeeper.timekeeperId]: timekeeper.suggestedRate,
						}),
						{},
					),
				},
			}),
			{},
		) ?? {}

	// We extract the latest timekeeper ordering from the RFP

	const timekeeperOrder =
		rfp?.rateReview?.timekeepers?.map((timekeeper) => ({
			timekeeperId: timekeeper._id,
			timekeeperLabel: timekeeper.timekeeperLabel,
		})) ?? []

	// We iterate the latest rate cards on the proposal

	return proposal?.rateCards?.map((rateCard: RfpProposalRateCard) => {
		// See if there is data already supplied on the RFPR form
		if (
			(rateCard.originalRateCardId && rateCard.originalRateCardId in currentDataMap) ||
			rateCard._id in currentDataMap
		) {
			// If there is we get it
			const rateCardId =
				rateCard.originalRateCardId && rateCard.originalRateCardId in currentDataMap
					? rateCard.originalRateCardId
					: rateCard._id
			const mappedCurrentRateCard = currentDataMap[rateCardId]
			// We create a map of the latest disabled state of timekeepers on the proposal
			const timekeeperDisabledMap = rateCard?.timekeepers?.reduce(
				(acc, timekeeper) => ({ ...acc, [timekeeper.timekeeper]: timekeeper.isEnabled }),
				{},
			)
			// We return a rate card populated with the pre-stored values
			// With the timekeepers mapped from the RFP for new ordering
			// Taking into account the possible disabling on this rate card
			return {
				rateCardId: mappedCurrentRateCard.rateCardId,
				assumptions: mappedCurrentRateCard.assumptions || (rateCard?.description ?? ''),
				originalDescription: rateCard?.description ?? '',
				timekeepers: timekeeperOrder.map(({ timekeeperId, timekeeperLabel }) => {
					return {
						timekeeperLabel,
						timekeeperId,
						suggestedRate: timekeeperDisabledMap[timekeeperId]
							? mappedCurrentRateCard?.timekeepers[timekeeperId] ?? null
							: null,
					}
				}),
				currency: isMultiCurrencyToggleEnabled
					? rateCard?.currency ?? ''
					: rfp?.rateReview?.currency ?? '',
				location: rateCard?.location ?? {},
				practiceAreas: rateCard?.practiceAreas ?? [],
			}
		}

		// If there is no stored data, we return a default data struct
		// Accounting for the latest timekeeper ordering
		return {
			rateCardId: rateCard._id,
			assumptions: rateCard?.description ?? '',
			originalDescription: rateCard?.description ?? '',
			timekeepers: timekeeperOrder.map(({ timekeeperId, timekeeperLabel }) => {
				return {
					timekeeperLabel,
					timekeeperId,
					suggestedRate: null,
				}
			}),
			currency: isMultiCurrencyToggleEnabled
				? rateCard?.currency ?? ''
				: rfp?.rateReview?.currency ?? '',
			location: rateCard.location,
			practiceAreas: rateCard.practiceAreas,
		}
	})
}

export const getNamedTimekeeperValues = ({
	draftRevisionCommentNamedTimekeepers,
	proposalNamedTimekeepers,
}) => {
	return proposalNamedTimekeepers.map((pntk) => {
		const draftRevisionCommentNamedTimekeeper = draftRevisionCommentNamedTimekeepers.find(
			(drcntk) => drcntk.namedTimekeeperId === pntk._id,
		)
		return {
			namedTimekeeperId: pntk._id,
			suggestedProposedRate: draftRevisionCommentNamedTimekeeper
				? draftRevisionCommentNamedTimekeeper.suggestedProposedRate
				: null,
		}
	})
}
