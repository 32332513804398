import {
	GridRenderCellParams,
	useGridApiContext,
	Box,
	Typography,
	Button,
} from '@persuit/ui-components'
import { useState, useEffect } from 'react'

export const CommentText = (params: GridRenderCellParams) => {
	const comment = (params.value ? params.value : '') as string
	const [isTruncated, setIsTruncated] = useState(true)
	const apiRef = useGridApiContext()

	useEffect(() => {
		apiRef.current.autosizeColumns({
			columns: ['comment'],
			includeHeaders: true,
			includeOutliers: true,
		})
	}, [])

	if (comment === '') {
		return '-'
	}

	const handleClick = () => {
		apiRef.current.autosizeColumns({
			columns: ['comment'],
			includeHeaders: true,
			includeOutliers: true,
		})
		setIsTruncated((prev) => !prev)
	}

	return (
		<Box display="flex" alignItems="baseline">
			{isTruncated ? (
				<>
					<Typography
						variant="body1"
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: '100%',
							maxWidth: '120px',
						}}
					>
						{comment}
					</Typography>
					{/* aria-hidden along with tabIndex hides button from screen reader */}
					<Button variant="text" onClick={handleClick} aria-hidden={true} tabIndex={-1}>
						See More
					</Button>
				</>
			) : (
				<>
					<Typography
						variant="body1"
						sx={{
							width: 'auto',
						}}
					>
						{comment}
					</Typography>
					{/* aria-hidden along with tabIndex hides button from screen reader */}
					<Button variant="text" onClick={handleClick} aria-hidden={true} tabIndex={-1}>
						See Less
					</Button>
				</>
			)}
		</Box>
	)
}
