import {
	Box,
	Typography,
	useTheme,
	FormNumberFieldCleave,
	PercentageText,
} from '@persuit/ui-components'
import { getPricingModelString } from '../../../common-util'
import { GRID_TEMPLATE_COLUMNS } from '../constants'
import { useStore } from '../../store'
import { PercentPricingItemDelta } from '../delta/percent-pricing-item-delta'

export type PercentPricingItemsProps = {
	index: number
	originalPercentage: number
	title: string
	id: string
	pricingModel: string
	editable?: boolean
}

export const PercentPricingItems = ({
	index,
	originalPercentage,
	title,
	id,
	pricingModel,
	editable = true,
}: PercentPricingItemsProps) => {
	const theme = useTheme()
	const currentPercentage = useStore((state) => state.rateItemFormState[id] ?? originalPercentage)

	return (
		<Box
			width="100%"
			display="grid"
			gridTemplateRows="auto"
			gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
			alignItems="start"
			rowGap={theme.spacing(1)}
			role="row"
		>
			<Box>
				<Typography>{index}</Typography>
			</Box>
			<Box role="cell" aria-label="Item details">
				<Typography>{title}</Typography>
				<Typography variant="caption" color="text.secondary">
					{getPricingModelString(pricingModel)}
				</Typography>
			</Box>
			<Box></Box>
			<Box></Box>
			<Box role="cell" aria-label="Current percentage">
				<Typography color="text.secondary">
					<PercentageText value={originalPercentage.toString()} />
				</Typography>
			</Box>
			<Box
				display="flex"
				alignItems="center"
				gap={theme.spacing(0.5)}
				role="cell"
				aria-label="Updated percentage"
			>
				{editable ? (
					<>
						<FormNumberFieldCleave
							name={id}
							id={id}
							label=""
							inputProps={{
								'aria-label': `${title} input`,
							}}
							decimalScale={2}
							integerScale={3}
							emptyValue={originalPercentage}
							replaceZero={true}
							maxValue={100}
						/>
						%
					</>
				) : (
					<Typography color="text.secondary">
						<PercentageText value={currentPercentage.toString()} />
					</Typography>
				)}
			</Box>
			<Box role="cell" aria-label="Percentage change delta">
				<PercentPricingItemDelta
					newNumber={currentPercentage}
					originalNumber={originalPercentage}
					isDiscountPricingItem={pricingModel === 'discountPercentage'}
				/>
			</Box>
		</Box>
	)
}
