import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'
import { createBrowserHistory } from 'history'
import { Route } from 'react-router-dom'

/**
 * Required for Sentry to work effectively with React Router v5
 * Use the this history object to pass to your BrowserRouter
 */
export const routerHistory = createBrowserHistory()

export const logger = {
	log: (error: unknown, context?: CaptureContext) =>
		Sentry.captureException(error, {
			...context,
			level: 'log',
		}),
	warn: (error: unknown, context?: CaptureContext) =>
		Sentry.captureException(error, {
			...context,
			level: 'warning',
		}),
	error: (error: unknown, context?: CaptureContext) =>
		Sentry.captureException(error, {
			...context,
			level: 'error',
		}),
}

export const SentryRoute = Sentry.withSentryRouting(Route)

type SentryIdentifyProps = {
	userId: string
	orgId: string
	email: string
	roles: string[]
	isImpersonated: boolean
}

export const identify = ({ email, userId, orgId, roles, isImpersonated }: SentryIdentifyProps) => {
	return Sentry.setUser({
		email,
		id: userId,
		orgId,
		roles,
		ip_address: '{{auto}}',
		isImpersonated,
	})
}

if (window.__SENTRY_DSN__) {
	Sentry.init({
		dsn: window.__SENTRY_DSN__,
		environment: window.__SENTRY_ENV__,
		release: process.env.APP_VERSION,
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
			new Sentry.Replay({
				// Explictly specify that everything should be masked
				maskAllText: true,
				maskAllInputs: true,
				blockAllMedia: true,
			}),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,

		// Capture Replay for 0% of all sessions,
		// plus for 100% only for production sessions with an error
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: window.__SENTRY_ENV__ === 'production' ? 1.0 : 0,
	})
	console.info('Sentry initialised successfully')
}
