import { Box, BoxProps } from '../box'

export type PrintOnlyProps = BoxProps

/**
 * Only show content for print
 */
export const PrintOnly = ({ children, ...props }: PrintOnlyProps) => (
	<Box
		sx={{
			display: 'none',
			displayPrint: 'block',
		}}
		{...props}
	>
		{children}
	</Box>
)

PrintOnly.displayName = 'PrintOnly'
