// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Drafts as DraftIcon } from '@mui/icons-material'

import { BaseStatus } from '@client/components/base-status'
import {
	OpenToProposals,
	Completed,
	Evaluating,
	AuctionInProgress,
	AwaitingAuction,
	Withdrawn,
} from '../shared-states'
import ClientStatusTypes from './client-status-types'

const Draft = ({ statusLine2 }) => (
	<BaseStatus
		iconNode={<DraftIcon />}
		primaryText={'DRAFT'}
		secondaryText={statusLine2}
		variant={'GREY'}
	/>
)

const ClientStatesMap = {
	[ClientStatusTypes.DRAFT]: ({ statusLine2 }) => <Draft statusLine2={statusLine2} />,
	[ClientStatusTypes.OPEN_TO_PROPOSALS]: ({ statusLine2, statusLine3 }) => (
		<OpenToProposals statusLine2={statusLine2} statusLine3={statusLine3} />
	),
	[ClientStatusTypes.COMPLETED]: ({ statusLine3 }) => <Completed statusLine3={statusLine3} />,
	[ClientStatusTypes.EVALUATING]: ({ statusLine2, statusLine3 }) => (
		<Evaluating statusLine2={statusLine2} statusLine3={statusLine3} />
	),
	[ClientStatusTypes.AWAITING_AUCTION]: ({ statusLine2, statusLine3 }) => (
		<AwaitingAuction statusLine2={statusLine2} statusLine3={statusLine3} />
	),
	[ClientStatusTypes.AUCTION_IN_PROGRESS]: ({ statusLine2, statusLine3 }) => (
		<AuctionInProgress statusLine2={statusLine2} statusLine3={statusLine3} />
	),
	[ClientStatusTypes.WITHDRAWN]: ({ statusLine2 }) => <Withdrawn statusLine2={statusLine2} />,
}

const ClientStates = ({ status, statusLine2, statusLine3 }) =>
	ClientStatesMap[status] ? ClientStatesMap[status]({ statusLine2, statusLine3 }) : null

export default ClientStates
