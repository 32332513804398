import { red500, lightGreen500, amber600 } from '../theme/persuit-colors'

export default function starColor(value: string | number) {
	const numberValue = Number(value)
	if (numberValue <= 2.4) {
		return red500
	} else if (numberValue <= 3.7) {
		return amber600
	} else if (numberValue <= 5) {
		return lightGreen500
	}
	return red500
}
