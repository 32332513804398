import {
	SortableContext as DndSortableContext,
	SortableContextProps as DndSortableContextProps,
	verticalListSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable'

export type SortableContextProps = DndSortableContextProps

export type UseSortableHocReturn = ReturnType<typeof useSortable>

export const SortableContext = ({ children, ...props }: SortableContextProps) => {
	return (
		<DndSortableContext strategy={verticalListSortingStrategy} {...props}>
			{children}
		</DndSortableContext>
	)
}

export { useSortable, arrayMove } from '@dnd-kit/sortable'
