import * as React from 'react'
import { Select, SelectProps } from './select'
import { FormControl, FormControlProps, FormHelperText, InputLabel } from '../form'

import { InputLabelProps } from '@mui/material/InputLabel'
import {
	OverrideNativeElementProps,
	DataAttributes,
	RHFFormComponentProps,
	useFormComponent,
} from '../../utilities'

export type FormSelectProps = RHFFormComponentProps<
	unknown,
	Partial<Omit<FormControlProps, 'onChange'>> & {
		/** Unique id for accessibility purpose */
		id: string
		/** Meaningful label for accessibility purpose */
		label: string
		variant?: InputLabelProps['variant']
		helperText?: string
		/** Given this component is a FormControl + Select, the root props is of FormControl component,
		 * selectProps is for Select component specifically */
		selectProps?: DataAttributes<OverrideNativeElementProps<SelectProps, 'input'>>
		children?: React.ReactNode
		onChange?: (value: string) => void
	}
>

/**
 * @deprecated
 * Avoid using this component since it's not very accessible. See https://github.com/mui/material-ui/issues/20583
 * If you have simple Select use case please use FormNativeSelect.
 * For more complex use case, use FormAutocomplete
 */
export const FormSelect = ({
	rules,
	id,
	name,
	defaultValue,
	helperText,
	label,
	variant = 'standard',
	selectProps,
	children,
	error: controlledError,
	onChange: onChangeProp,
	...props
}: FormSelectProps) => {
	const {
		field: { onChange, value, ref, name: fieldName, required },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<FormControl error={!!error?.message || controlledError} {...props}>
			<InputLabel id={id} variant={variant}>
				{label}
			</InputLabel>
			<Select
				labelId={id}
				label={label}
				onChange={(event) => {
					onChange(event)
					onChangeProp?.(event.target?.value ?? null)
				}}
				value={value}
				name={fieldName}
				inputRef={ref}
				inputProps={{
					...selectProps?.inputProps,
				}}
				{...selectProps}
				required={props.required || required}
			>
				{children}
			</Select>
			<FormHelperText>{helperText || error?.message}</FormHelperText>
		</FormControl>
	)
}
