export function isSupplier(user) {
	if (user.roles.find((role) => role === 'supplier')) {
		return true
	}
	return false
}

export function isRequester(user) {
	if (user.roles.find((role) => role === 'requester')) {
		return true
	}
	return false
}
