import { ExpandableCard, ExpandableCardProps } from '../../expandable-card'
import { SortableListItem, SortableListItemProps } from '../sortable-list-item'
import { SortableDragHandler, SortableDragHandlerProps } from '../sortable-drag-handler'
import { Box } from '../../box'

export type SortableExpandableCardProps = ExpandableCardProps & {
	id: string
	sortableListItemProps?: Partial<SortableListItemProps>
	sortableDragHandlerProps?: Partial<SortableDragHandlerProps>
}

export const SortableExpandableCard = ({
	id,
	summaryPrefix,
	sortableListItemProps,
	sortableDragHandlerProps,
	...props
}: SortableExpandableCardProps) => {
	return (
		<SortableListItem {...sortableListItemProps} id={id} key={id}>
			{({ attributes }) => (
				<ExpandableCard
					{...props}
					summaryPrefix={
						<Box display="flex" alignItems={'center'}>
							<SortableDragHandler id={id} {...sortableDragHandlerProps} {...attributes} />
							{summaryPrefix}
						</Box>
					}
				/>
			)}
		</SortableListItem>
	)
}
