import { Box, Button, useSnackbar } from '@persuit/ui-components'
import { downloadBlob } from '@persuit/ui-utils'
import { convertToWord } from './convert-word-api'

type ExportToWordButtonProps = {
	fileName?: string
	containerId: string
}

export const ExportToWordButton = ({
	fileName = 'Request Details',
	containerId,
}: ExportToWordButtonProps) => {
	const { openSnackbar } = useSnackbar()
	const wordFileName = `${fileName}.docx`
	return (
		<Box data-wordexport="hide">
			<Button
				data-trackid="button-export-as-word"
				variant="outlined"
				onClick={async () => {
					try {
						const html = prepareHtmlForWordConversion(containerId)
						const response = await convertToWord(html)

						downloadBlob(response?.data, wordFileName)
					} catch (err) {
						openSnackbar(`Error downloading document: ${wordFileName}`, {
							variant: 'error',
						})
					}
				}}
				sx={{
					displayPrint: 'none',
				}}
			>
				Export as Word
			</Button>
		</Box>
	)
}

function prepareHtmlForWordConversion(containerId: string) {
	const rfpPreviewNode = document.getElementById(containerId)?.cloneNode(true) as Element

	for (const node of rfpPreviewNode.querySelectorAll(`[data-wordexport]`)) {
		const wordExportConfig = node.getAttribute('data-wordexport')
		if (wordExportConfig?.includes('hide')) node.parentNode?.removeChild(node)
	}

	return `<img src="https://app.persuit.com/cdn/images/persuit-logo-no-padding.png" style="width: '200px'; height='20px'"/>${
		rfpPreviewNode.innerHTML ?? ''
	}`
}
