import { Button } from '@persuit/ui-components'
import { graphql, useMutation } from '@persuit/ui-graphql'
import { useHistory } from 'react-router-dom'
import { isUnauthorised } from '@persuit/ui-utils'
import { ApolloError } from '@apollo/client'

const CREATE_BLANK_RFP = graphql(`
	mutation TemplatesPage_CreateBlankRfp {
		createBlankRfp {
			rfpId
		}
	}
`)

export const StartFromScratchButton = () => {
	const [createBlankRfp] = useMutation(CREATE_BLANK_RFP)
	const history = useHistory()

	const handleClick = async () => {
		try {
			const { data } = await createBlankRfp()

			const rfpId = data?.createBlankRfp.rfpId

			history.push(`/en/request/${rfpId}/edit`)
		} catch (err) {
			if (err instanceof ApolloError && isUnauthorised(err)) {
				history.push('/en/unauthorized')
			}
		}
	}

	return (
		<Button
			color="primary"
			variant="outlined"
			onClick={handleClick}
			data-trackid="button-start-from-scratch"
		>
			Start From Scratch
		</Button>
	)
}
