import { Tooltip, useTheme } from '@persuit/ui-components'

export type NamwolfLogoProps = {
	disabled?: boolean
} & JSX.IntrinsicElements['img']

export default function NamwolfLogo({ disabled = false, style, ...rest }: NamwolfLogoProps) {
	const theme = useTheme()

	return (
		<Tooltip title="NAMWOLF Member">
			<img
				style={{
					width: 16,
					height: 16,
					borderRadius: theme.spacing(0.25),
					opacity: disabled ? 0.5 : 1,
					...style,
				}}
				alt="namwolf logo"
				src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC20lEQVRYhc2XT0gUURzHP292tl1b/+QuWVkkWVBkBwmqk508WRBR0KEMSygqqA4JlWgiShREEEKEIJEXu0W3OkWXCAqC/kBRhmVZYeOa/3Xc10F3d3Zm3uxgu9AP5vDm876/9+P9Hl/eE9/qKiUASECgjvxwLQWlsE22ifPEdZGsLKM4++T8cV16bps9We65pkb2bcwP16yDTCgIbqxarN+dqxeTBGKrCVXtYNmWak+9JgCBQCz+EsjUONp6h6KGJiVPf05esHsvset9RFu7PfW6c6MyexY5eBI5PcVYX5crd0aaSyld9idTbzkD6p4VHjlP4f7GLAsv7Uz49AEoarxEpO6wgi/dB7RUT4SzpwBCCJibRQhB8ak2ltcecO+pTZ/SJvO55BeAJlmwIrcPFvo48aCH2bcvQAhKzl4lXLPHMs9dn9SiyJ38fPmAnJ7EaGtk7uMb0DRWXLhBeFetWuorfPgApLcxMTmO0dKAOfABoQcpvXiL0PYaR7HWcVK7ZB+whgDkWByj+Sjm4GfEshDR5tuEtu101Wdq1T6gOfsiPPs4Hx/md3M95s9BCIUpvdJNcHO1Q++mdTszvnzAzueHhzAu15MwfiEKIkTbewhWbvWtt4ZvH7Bz88cXjOZ6EqMGIlJMtOMuwfWbFPp/9QFw5ebXTxgtDciJPwRKYkQ77qGXV+TeB1BwCcz1v8NoPU5ichwtWkass5dA2VpfWt8+kI3Pvn/FSPsJmJ1GW1lOtLMXLbbKl963D6h4cjzz+jkjnafBnEMvryCy71jufSDbfWDm5VNGrp1DmiZC0yzaHPqA15mRwPSzx8RvNiET8/nzgWx86slDRrtabBp3vZ6C0np1tveYLNypn3x0HxEuoOjQGc/84nvdBqm6ZgXWVSICOon4MIlRw3WOa7HWHGsqmB8aUHLPd4E52K9k6fC+I5oei8P//i5QJ8sd15NurRbnl2d9Fzgjt/wvwjKI6kazFwIAAAAASUVORK5CYII="
				{...rest}
			/>
		</Tooltip>
	)
}
