import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
	split,
	createHttpLink,
	ServerParseError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from 'apollo-utilities'
import { getOrEmptyString } from './utils/lenses'
import { RetryLink } from '@apollo/client/link/retry'
import { NetworkError } from '@apollo/client/errors'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { possibleTypes } from '@persuit/ui-graphql/possible-types'

const apiHost =
	process.env.NODE_ENV === 'test' ? 'http://localhost:3000/en/graphql' : '/en/graphql/'

const httpLink = createHttpLink({
	uri: apiHost,
	credentials: 'same-origin',
})

const retryLink = new RetryLink({
	delay: {
		initial: 0,
		jitter: true,
	},
	attempts: {
		max: 3,
	},
})

const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
const wsHost = `${wsProtocol}//${window.location.host}${apiHost}`

const wsLink = new GraphQLWsLink(
	createClient({
		url: wsHost,
	}),
)

function isServerParseError(error: NetworkError): error is ServerParseError {
	return error?.hasOwnProperty('statusCode') || false
}

function isOperationDefinitionNode(
	node: OperationDefinitionNode | FragmentDefinitionNode,
): node is OperationDefinitionNode {
	return node.hasOwnProperty('operation')
}

const logoutLink = onError(({ networkError, operation }) => {
	// The error link is used to intercept GQL errors in the chain.
	// Calling the forward function in this link will retry the GQL query.
	// If there are no errors this link doesn't need to return anything.
	const gqlOperation = getOrEmptyString('operationName', operation)

	// getSessionInfo is used on login page - avoid infinite loop
	if (
		networkError &&
		isServerParseError(networkError) &&
		networkError.statusCode === 401 &&
		gqlOperation !== 'getSessionInfo'
	) {
		// redirect to login
		document.location = `${window.location.protocol}//${window.location.host}/en/user/login/?sessionexpired=1`
	}
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const httpLinks = split(
	// split based on operation type
	({ query }) => {
		const node = getMainDefinition(query)

		if (isOperationDefinitionNode(node)) {
			const { kind, operation } = node
			return kind === 'OperationDefinition' && operation === 'subscription'
		} else {
			return false
		}
	},
	wsLink,
	// oldWsLink,
	httpLink,
)

const link = ApolloLink.from([retryLink, logoutLink, httpLinks])

const cacheOptions = {
	possibleTypes,
	typePolicies: {
		/*
		* Without the `merge: true` switch:
		* Empty `settings` (or `settings` without crucial data) can replace
		* the org `settings` object and then stuff breaks
		*
		* For example this can happen when:
		* 1. The users own org is fetched by `getOwnOrg` with a full settings object
		*
		* 2. Later the same org is fetched as part of the `getRfp` query
		* 	 In this query the org `settings` object only contains the `currency` key
		*
		* 3. The normalizing cache will then replace the full org settings with the
		*    minimal org settings only containing the currency info
		*
		* 4. Bits of the app that need to read other settings of the users org then break.
		*
		********************************************
		********************************************
		*
		* The default behaviour of the normalizing cache is to blindly replace the `setttings`
		*
		* The `merge` directive will make the cache merge the org settings objects
		* together rather than do a blind replacement
		*
		* `merge: true` is shorthand for something like this:
		*
		```
			merge(existing, incoming) {
				return { ...existing, ...incoming };
			}
		```
		*
		*
		* More info:
		* https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
		* https://www.apollographql.com/docs/react/caching/cache-field-behavior/#defining-a-merge-function-at-the-type-level
		*/
		Org: {
			fields: {
				settings: { merge: true },
			},
		},

		Group: {
			fields: {
				settings: { merge: true },
			},
		},

		SessionInfo: { merge: true },

		User: {
			fields: {
				requestToJoinTeam: { merge: true },
			},
		},

		Rfp: {
			fields: {
				rateReview: { merge: true },
				detail: { merge: true },
				savings: { merge: true },
				progress: { merge: true },
				tracking: { merge: true },
			},
		},
	},
}

const cache = new InMemoryCache(cacheOptions)

export const client = new ApolloClient({
	link,
	cache,
	assumeImmutableResults: true,
})

export default client
