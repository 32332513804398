// @ts-strict-ignore
import * as React from 'react'
import { useStore, useActions, SortObject, SortType } from '../store'
import diversityContent from '../../request/modules/diversity/diversity-content'
import { requestValidForComparisonValue } from '@persuit/ui-shared-components'
import {
	List,
	IconButton,
	ArrowDropDownIcon,
	TextField,
	Box,
	Typography,
	Button,
	Popover,
	ListItem,
	ListSubheader,
	ArrowUpwardIcon,
	ArrowDownwardIcon,
	ToggleButtonGroup,
	ToggleButton,
	styled,
} from '@persuit/ui-components'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const StyledToggleButton = styled(ToggleButton)`
	&.MuiToggleButtonGroup-grouped {
		border: 1px solid ${(props) => props.theme.palette.primary.main} !important;
	}
	&.Mui-selected {
		color: ${(props) => props.theme.palette.white} !important;
		background-color: ${(props) => props.theme.palette.primary.main} !important;
	}
	color: ${(props) => props.theme.palette.primary.main} !important;
	height: 30px;
	width: 80px;
	font-size: 13px;
	line-height: 22px;
	border-radius: 0;
`

export const SortProposalsDropdown = () => {
	const { setSort, getInitialSort } = useActions()
	const { request, sort, shouldShowAverageRate } = useStore((state, s) => ({
		responses: state.responses,
		request: state.request,
		sort: state.sort,
		shouldShowAverageRate: s.shouldShowAverageRate(),
	}))

	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const totalPriceRequired = request?.detail?.totalPriceRequired

	type Deliverable = {
		__typename?: 'Deliverable'
		_id: string
		deliverableTitle: string
	}

	const flattenDeliverables = (deliverables): Deliverable[] => {
		return deliverables.reduce((acc, deliverable) => {
			if (deliverable.__typename === 'Deliverable') {
				acc.push(deliverable)
			} else if (deliverable.__typename === 'PricingGroup' && deliverable.deliverables) {
				const nestedDeliverables = flattenDeliverables(deliverable.deliverables)
				acc.push(...nestedDeliverables)
			}
			return acc
		}, [] as Deliverable[])
	}

	const sortableDeliverables = flattenDeliverables(request?.detail?.deliverablesV2 ?? [])

	const scorecardCategories = request?.scoreCategories

	const sortableQuestions =
		request?.detail?.questions
			?.filter(
				(question) =>
					question?.type !== 'short' && question?.type !== 'medium' && question?.type !== 'long',
			)
			.filter(isNotNil)
			.filter(isNotNilProp('_id'))
			.filter(isNotNilProp('title')) ?? []

	const diversityRequired = request?.detail?.diversity?.diversityRequired
	const diversityQuestions = diversityRequired
		? Object.keys(diversityContent).map((key) => ({
				key,
				type: diversityContent[key].type,
				title: diversityContent[key].title,
		  }))
		: []

	const onSort = (sort: SortObject) => {
		setSort(sort)
		setAnchorEl(null)
	}

	const resetSort = () => {
		setSort(getInitialSort())
	}

	const getSelectedSortName = (sort) => {
		switch (sort.type) {
			case 'totalPrice':
				return 'Total Price (default)'
			case 'comparisonValue':
				return 'Comparison value (default)'
			case 'averageRate': {
				return 'Average rate'
			}
			case 'firmName':
				return 'Firm Name'
			case 'scorecard':
				return 'Scorecard'
			case 'pricing':
				return sortableDeliverables.find((deliverable) => deliverable._id === sort.key)
					?.deliverableTitle
			case 'questions':
				return sortableQuestions?.find((question) => question?._id === sort.key)?.title
			case 'diversity':
				return diversityQuestions.find((diversityQuestion) => diversityQuestion.key === sort.key)
					?.title
		}
	}

	const isButtonHighlighted = (buttonSort: SortObject) => {
		const { type, key, order } = sort
		return (
			type === buttonSort.type &&
			order === buttonSort.order &&
			(key === buttonSort.key || !key || !buttonSort.key)
		)
	}

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

	const handlePopoverOpen = (event: React.SyntheticEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	const selected = sort

	const shouldHideAverageRate = !isRateCardAuctionEnabled || !shouldShowAverageRate

	return (
		<>
			<TextField
				label="Sort by"
				value={getSelectedSortName(selected)}
				variant="outlined"
				onClick={handlePopoverOpen}
				InputProps={{
					inputComponent: Box as any,
					slotProps: {
						input: {
							style: {
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							},
							'aria-label': `Sorting By ${getSelectedSortName(selected)}`,
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									handlePopoverOpen(e)
								}
							},
							children: getSelectedSortName(selected),
							role: 'button',
							tabIndex: 0,
						},
						root: {
							style: { cursor: 'pointer' },
						},
					},
					endAdornment: (
						<Box display="flex" alignItems="center">
							<Box display="flex" alignItems="center">
								<Typography fontSize="13px">
									{selected.order === 'asc' ? 'ASC.' : 'DESC.'}
								</Typography>

								<Button
									variant="text"
									onClick={(e) => {
										e.stopPropagation()
										resetSort()
									}}
									aria-label="Reset sort proposals"
									data-trackid="iconbutton-reset-sort-proposals"
								>
									RESET
								</Button>

								{selected.order === 'asc' ? (
									<ArrowUpwardIcon sx={{ fontSize: '16px' }} />
								) : (
									<ArrowDownwardIcon sx={{ fontSize: '16px' }} />
								)}
								<IconButton aria-hidden={true} tabIndex={-1}>
									<ArrowDropDownIcon />
								</IconButton>
							</Box>
						</Box>
					),
				}}
			/>

			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
			>
				<List sx={{ maxWidth: '800px' }}>
					<ListItem>
						<Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
							<Typography>Firm Name</Typography>
							<ToggleButtonGroup aria-label="Firm name" sx={{ margin: '0 1em' }}>
								<StyledToggleButton
									aria-label="Sort Firm Name Alphabetically from A to Z"
									size="small"
									selected={isButtonHighlighted({ type: 'firmName', order: 'asc' })}
									onClick={() => onSort({ type: 'firmName', order: 'asc' })}
									value="Firm Name-Ascending"
								>
									A To Z
								</StyledToggleButton>
								<StyledToggleButton
									size="small"
									aria-label="Sort Firm Name Alphabetically from Z to A"
									selected={isButtonHighlighted({ type: 'firmName', order: 'desc' })}
									onClick={() => onSort({ type: 'firmName', order: 'desc' })}
									value="Firm Name-Descending"
								>
									Z To A
								</StyledToggleButton>
							</ToggleButtonGroup>
						</Box>
					</ListItem>

					{totalPriceRequired ? (
						<ListItem value="totalPrice">
							<Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
								<Typography>Total Price (default)</Typography>
								<AscDescButtonGroup
									label="Total Price"
									type="totalPrice"
									onSort={onSort}
									isButtonHighlighted={isButtonHighlighted}
								/>
							</Box>
						</ListItem>
					) : requestValidForComparisonValue(request?.detail.deliverablesV2) ? (
						<ListItem value="comparison-value">
							<Box display="flex" justifyContent="space-between" width="100%">
								<Typography>Comparison Value (default)</Typography>
								<AscDescButtonGroup
									label="Comparison Value"
									type="comparisonValue"
									onSort={onSort}
									isButtonHighlighted={isButtonHighlighted}
								/>
							</Box>
						</ListItem>
					) : null}

					{!shouldHideAverageRate ? (
						<ListItem value="averageRate">
							<Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
								<Typography>Average rate</Typography>
								<AscDescButtonGroup
									label="Average rate"
									type="averageRate"
									onSort={onSort}
									isButtonHighlighted={isButtonHighlighted}
								/>
							</Box>
						</ListItem>
					) : null}

					{scorecardCategories?.length ? (
						<ListItem value="scorecard">
							<Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
								<Typography>Scorecard</Typography>
								<AscDescButtonGroup
									label="Scorecard"
									type="scorecard"
									onSort={onSort}
									isButtonHighlighted={isButtonHighlighted}
								/>
							</Box>
						</ListItem>
					) : null}

					<Section
						type="pricing"
						label="Pricing"
						items={sortableDeliverables}
						getKey={(item) => item._id}
						getLabel={(item) => item.deliverableTitle}
						isButtonHighlighted={isButtonHighlighted}
						onSort={onSort}
					/>

					<Section
						type="questions"
						label="Questions"
						items={sortableQuestions}
						getKey={(item) => item._id}
						getLabel={(item) => item.title}
						isButtonHighlighted={isButtonHighlighted}
						onSort={onSort}
					/>

					<Section
						type="diversity"
						label="Diversity"
						items={diversityQuestions}
						getKey={(item) => item.key}
						getLabel={(item) => item.title}
						isButtonHighlighted={isButtonHighlighted}
						onSort={onSort}
					/>
				</List>
			</Popover>
		</>
	)
}

type AscDescButtonGroupProps = {
	type: SortType
	label: string
	onSort: (sort: SortObject) => void
	isButtonHighlighted: (sort: SortObject) => boolean
	sortKey?: string
}

type SectionProps<T> = {
	type: SortType
	label: string
	items: T[]
	isButtonHighlighted: (sort: SortObject) => boolean
	onSort: (sort: SortObject) => void
	getLabel: (item: T) => string
	getKey: (item: T) => string
}

const Section = <T,>({
	type,
	label,
	isButtonHighlighted,
	onSort,
	items,
	getKey,
	getLabel,
}: SectionProps<T>) => {
	if (items.length === 0) return null
	return (
		<ListItem disablePadding={true}>
			<List aria-label={label} sx={{ width: '100%' }}>
				<ListSubheader aria-hidden={true}>{label}</ListSubheader>
				{items.map((item) => {
					const key = getKey(item)
					const label = getLabel(item)

					return (
						<SortItem
							key={key}
							label={label}
							type={type}
							onSort={onSort}
							sortKey={key}
							isButtonHighlighted={isButtonHighlighted}
						/>
					)
				})}
			</List>
		</ListItem>
	)
}

type SortItemProps = {
	type: SortType
	label: string
	onSort: (sort: SortObject) => void
	isButtonHighlighted: (sort: SortObject) => boolean
	sortKey?: string
}

const SortItem = ({ type, onSort, isButtonHighlighted, label, sortKey }: SortItemProps) => {
	return (
		<ListItem>
			<Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
				<Typography>{label}</Typography>
				<AscDescButtonGroup
					label={label}
					type={type}
					onSort={onSort}
					sortKey={sortKey}
					isButtonHighlighted={isButtonHighlighted}
				/>
			</Box>
		</ListItem>
	)
}

const AscDescButtonGroup = ({
	label,
	type,
	onSort,
	sortKey,
	isButtonHighlighted,
}: AscDescButtonGroupProps) => {
	return (
		<ToggleButtonGroup aria-label={label} sx={{ margin: '0 1em' }}>
			<StyledToggleButton
				aria-label={`Sort ${label} Ascending - low to high`}
				size="small"
				selected={isButtonHighlighted({ type, key: sortKey, order: 'asc' })}
				onClick={() => onSort({ type, order: 'asc', key: sortKey ?? '' })}
				value={`${label}-ascending`}
			>
				ASC.
				<ArrowUpwardIcon style={{ width: '14px', height: '14px', marginLeft: '4px' }} />
			</StyledToggleButton>
			<StyledToggleButton
				aria-label={`Sort ${label} Descending - high to low`}
				size="small"
				selected={isButtonHighlighted({ type, key: sortKey, order: 'desc' })}
				onClick={() => onSort({ type, order: 'desc', key: sortKey ?? '' })}
				value={`${label}-descending`}
			>
				DESC.
				<ArrowDownwardIcon style={{ width: '14px', height: '14px', marginLeft: '4px' }} />
			</StyledToggleButton>
		</ToggleButtonGroup>
	)
}
