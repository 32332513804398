import { Typography } from '@persuit/ui-components'

/*
Used for rate card in request preview
If there are responses use RateCardList

Looks like this:

 * item 1
 * item 2
 * item 3

*/

const SubItemsPoints = ({ requestedRates = [] }) => {
	if (requestedRates.length === 0) {
		return null
	}

	return (
		<ul>
			{requestedRates.map((r, i) => (
				<li data-testid="rate-card-entry" key={`SubItemsPoints-${i}`}>
					<Typography variant="body2">{r.label}</Typography>
				</li>
			))}
		</ul>
	)
}

SubItemsPoints.displayName = 'SubItemsPoints'
export default SubItemsPoints
