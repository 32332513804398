import { Fragment, useState } from 'react'
import rfpProposalStates from '../../../../common/data/rfp-proposal-states'
import { DialogWizard } from '../dialog-wizard'
import DialogWizardContent from '../dialog-wizard-content'
import DialogWizardEnableStepIf from '../dialog-wizard-enable-step-if'
import { defaultSteps } from '../dialog-wizard-steps'
import { OptionCloseRequestConfirm, OptionCloseRequestStart } from './option-close-request'
import {
	OptionEliminateFirmConfirm,
	OptionEliminateFirmStart,
	OptionEliminateFirmOptions,
} from './option-eliminate-firm'
import {
	OptionGenerateLoeConfirm,
	OptionGenerateLoeConfirmDefault,
	OptionGenerateLoeOptions,
	OptionGenerateLoeStart,
} from './option-generate-loe'
import {
	OptionRequestProposalRevisionConfirm,
	OptionRequestProposalRevisionStart,
} from './option-request-proposal-revision'
import {
	OptionSelectFirmConfirmMarkComplete,
	OptionSelectFirmOptions,
	OptionSelectFirmStart,
} from './option-select-firm'
import { OptionUneliminateFirmConfirm, OptionUneliminateFirmStart } from './option-uneliminate-firm'
import { OptionUnselectFirmConfirm, OptionUnselectFirmStart } from './option-unselect-firm'
import {
	OptionUploadFinalLoeConfirm,
	OptionUploadFinalLoeOptions,
	OptionUploadFinalLoeStart,
} from './option-upload-final-loe'
import WizardHeader from './wizard-header'
import { DialogContent, Divider } from '@persuit/ui-components'

export const WizardSteps = {
	START: 'start',
	OPTION_REQUEST_PROPOSAL_REVISION_CONFIRM: 'option-request-proposal-revision-confirm',
	OPTION_SELECT_FIRM_OPTIONS: 'option-select-firm-options',
	OPTION_SELECT_FIRM_CONFIRM: 'option-select-firm-confirm',
	OPTION_UNSELECT_FIRM_CONFIRM: 'option-unselect-firm-confirm',
	OPTION_CLOSE_REQUEST_CONFIRM: 'option-close-request-confirm',
	OPTION_ELIMINATE_FIRM_OPTIONS: 'option-eliminate-firm-options',
	OPTION_ELIMINATE_FIRM_CONFIRM: 'option-eliminate-firm-confirm',
	OPTION_UNELIMINATE_FIRM_CONFIRM: 'option-uneliminate-firm-confirm',
	OPTION_GENERATE_LOE_OPTIONS: 'option-generate-loe-options',
	OPTION_GENERATE_LOE_CONFIRM: 'option-generate-loe-confirm',
	OPTION_UPLOAD_FINAL_LOE_OPTIONS: 'option-upload-final-loe-options',
	OPTION_UPLOAD_FINAL_LOE_CONFIRM: 'option-upload-final-loe-confirm',
	OPTION_UPLOAD_FINAL_LOE_CONFIRM_DEFAULT: 'option-upload-final-loe-confirm-default',
}

const defaultWizardState = {
	[WizardSteps.OPTION_SELECT_FIRM_OPTIONS]: { notifyOtherFirms: null },
	[WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS]: { sendFirmEmailsSelected: null },
}

export default function ManageProposalWizard({
	currency,
	useCase,
	isRfpReopenedForFirm,
	firmId,
	firmName,
	isFirmEliminated,
	isNamwolfMember,
	proposalId,
	proposalStatus,
	requestStatus,
	requestId,
	totalPriceModel,
	totalPriceValue,
	disableRequestProposalRevsionRadio,
	disableMarkFirmUnsuccessfulRadio,
	disableSelectFirmRadio,
	disableMarkRemainingFirmsUnsuccessfulRadio,
	fetchResponses,
	isPendingPrice,
	handleClose,
	openProposalFeedbackFormAll,
	openProposalFeedbackFormSingleFirm,
	auction,
	proposalsDueBy,
	auctionEnd,
	startingStep = WizardSteps.START,
	wizardName = 'Manage proposal',
}) {
	const [wizardStep, setWizardStep] = useState(startingStep ? startingStep : WizardSteps.START)
	const [wizardState, setWizardState] = useState(defaultWizardState)

	const setWizardStateForStep = (step) => (newValue) => {
		return setWizardState({
			...wizardState,
			[step]: newValue,
		})
	}

	const resetWizardStateForStep = (step) => {
		return setWizardState({
			...wizardState,
			[step]: defaultWizardState[step],
		})
	}

	const LoeOptions = ({ disableLoeOptions }) => {
		return (
			<Fragment>
				{useCase !== 'rateReview' && (
					<OptionGenerateLoeStart
						onNext={() => setWizardStep(WizardSteps.OPTION_GENERATE_LOE_OPTIONS)}
						disabled={disableLoeOptions}
					/>
				)}
				<OptionUploadFinalLoeStart
					onNext={() => setWizardStep(WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS)}
					disabled={disableLoeOptions}
				/>
			</Fragment>
		)
	}

	// Don't show back button when an alternative starting step was provided
	const makePrevStep = (startingStep, prevFunction) => {
		return startingStep === WizardSteps.START ? prevFunction : null
	}

	return (
		<DialogWizard wizardName={wizardName} dataTestid="manage-proposal-wizard">
			<DialogContent sx={{ overflow: 'unset' }}>
				<WizardHeader
					currency={currency}
					firmName={firmName}
					isNamwolfMember={isNamwolfMember}
					proposalStatus={proposalStatus}
					totalPriceValue={totalPriceValue}
					totalPriceModel={totalPriceModel}
					isPendingPrice={isPendingPrice}
				/>
			</DialogContent>

			{/* Step 1 */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.START}>
				<DialogWizardContent steps={defaultSteps} activeStep={'START'} onCancel={handleClose}>
					<Divider />
					<OptionRequestProposalRevisionStart
						onNext={() => setWizardStep(WizardSteps.OPTION_REQUEST_PROPOSAL_REVISION_CONFIRM)}
						requestStatus={requestStatus}
						disableRequestProposalRevsionRadio={disableRequestProposalRevsionRadio}
					/>

					{proposalStatus !== rfpProposalStates.ACCEPTED && (
						<OptionSelectFirmStart
							onNext={() => setWizardStep(WizardSteps.OPTION_SELECT_FIRM_OPTIONS)}
							disableSelectFirmRadio={disableSelectFirmRadio}
							isPendingPrice={isPendingPrice}
						/>
					)}

					{proposalStatus === rfpProposalStates.ACCEPTED && (
						<OptionUnselectFirmStart
							onNext={() => setWizardStep(WizardSteps.OPTION_UNSELECT_FIRM_CONFIRM)}
						/>
					)}

					<OptionCloseRequestStart
						onNext={() => setWizardStep(WizardSteps.OPTION_CLOSE_REQUEST_CONFIRM)}
						isRfpReopenedForFirm={isRfpReopenedForFirm}
					/>

					<LoeOptions disableLoeOptions={isFirmEliminated} />

					{isFirmEliminated === false && (
						<OptionEliminateFirmStart
							onNext={() => setWizardStep(WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS)}
							disableMarkFirmUnsuccessfulRadio={disableMarkFirmUnsuccessfulRadio}
						/>
					)}
					{isFirmEliminated === true && (
						<OptionUneliminateFirmStart
							onNext={() => setWizardStep(WizardSteps.OPTION_UNELIMINATE_FIRM_CONFIRM)}
						/>
					)}
				</DialogWizardContent>
			</DialogWizardEnableStepIf>

			{/* Step 2 - Select Firm */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_SELECT_FIRM_OPTIONS}>
				<OptionSelectFirmOptions
					value={wizardState[WizardSteps.OPTION_SELECT_FIRM_OPTIONS]}
					onChange={setWizardStateForStep(WizardSteps.OPTION_SELECT_FIRM_OPTIONS)}
					onPrev={makePrevStep(startingStep, () => {
						resetWizardStateForStep(WizardSteps.OPTION_SELECT_FIRM_OPTIONS)
						setWizardStep(WizardSteps.START)
					})}
					onNext={() => setWizardStep(WizardSteps.OPTION_SELECT_FIRM_CONFIRM)}
					onCancel={handleClose}
					disableMarkRemainingFirmsUnsuccessfulRadio={disableMarkRemainingFirmsUnsuccessfulRadio}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 2 - Eliminate Firm */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS}
			>
				<OptionEliminateFirmOptions
					onChange={setWizardStateForStep(WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS)}
					onPrev={makePrevStep(startingStep, () => {
						resetWizardStateForStep(WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS)
						setWizardStep(WizardSteps.START)
					})}
					onNext={() => setWizardStep(WizardSteps.OPTION_ELIMINATE_FIRM_CONFIRM)}
					onCancel={handleClose}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 2 - Generate LOE */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_GENERATE_LOE_OPTIONS}>
				<OptionGenerateLoeOptions
					onCancel={handleClose}
					onPrev={() => setWizardStep(WizardSteps.START)}
					onNext={() => setWizardStep(WizardSteps.OPTION_GENERATE_LOE_CONFIRM)}
					onNextDefault={() => setWizardStep(WizardSteps.OPTION_GENERATE_LOE_CONFIRM_DEFAULT)}
					onChange={setWizardStateForStep(WizardSteps.OPTION_GENERATE_LOE_OPTIONS)}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 2 - Upload Files */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS}
			>
				<OptionUploadFinalLoeOptions
					onCancel={handleClose}
					onPrev={() => setWizardStep(WizardSteps.START)}
					onNext={() => setWizardStep(WizardSteps.OPTION_UPLOAD_FINAL_LOE_CONFIRM)}
					onChange={setWizardStateForStep(WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS)}
					value={wizardState[WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS]}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Request proposal revision */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_REQUEST_PROPOSAL_REVISION_CONFIRM}
			>
				<OptionRequestProposalRevisionConfirm
					onPrev={() => setWizardStep(WizardSteps.START)}
					onDone={handleClose}
					onCancel={handleClose}
					proposalId={proposalId}
					requestId={requestId}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Select firm */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_SELECT_FIRM_CONFIRM}>
				{/* When removing this feature toggle please also delete the old confirm component
				src/client/components/dialog-wizard/manage-proposal/option-select-firm/confirm.js
				 */}
				<OptionSelectFirmConfirmMarkComplete
					value={wizardState[WizardSteps.OPTION_SELECT_FIRM_OPTIONS]}
					onPrev={() => {
						resetWizardStateForStep(WizardSteps.OPTION_SELECT_FIRM_OPTIONS)
						setWizardStep(WizardSteps.OPTION_SELECT_FIRM_OPTIONS)
					}}
					onDone={handleClose}
					onCancel={handleClose}
					proposalId={proposalId}
					firmId={firmId}
					firmName={firmName}
					requestId={requestId}
					requestStatus={requestStatus}
					fetchResponses={fetchResponses}
					openProposalFeedbackFormAll={openProposalFeedbackFormAll}
					openProposalFeedbackFormSingleFirm={openProposalFeedbackFormSingleFirm}
					auction={auction}
					proposalsDueBy={proposalsDueBy}
					auctionEnd={auctionEnd}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Unselect Firm */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_UNSELECT_FIRM_CONFIRM}>
				<OptionUnselectFirmConfirm
					onPrev={() => setWizardStep(WizardSteps.START)}
					onDone={handleClose}
					onCancel={handleClose}
					proposalId={proposalId}
					firmName={firmName}
					fetchResponses={fetchResponses}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Close request */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_CLOSE_REQUEST_CONFIRM}>
				<OptionCloseRequestConfirm
					onPrev={() => setWizardStep(WizardSteps.START)}
					onDone={handleClose}
					onCancel={handleClose}
					firmId={firmId}
					firmName={firmName}
					requestId={requestId}
					fetchResponses={fetchResponses}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Generate LOE */}
			<DialogWizardEnableStepIf condition={wizardStep === WizardSteps.OPTION_GENERATE_LOE_CONFIRM}>
				<OptionGenerateLoeConfirm
					onCancel={handleClose}
					onDone={handleClose}
					value={wizardState[WizardSteps.OPTION_GENERATE_LOE_OPTIONS]}
					onPrev={() => setWizardStep(WizardSteps.OPTION_GENERATE_LOE_OPTIONS)}
					proposalId={proposalId}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Generate LOE Default template*/}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_GENERATE_LOE_CONFIRM_DEFAULT}
			>
				<OptionGenerateLoeConfirmDefault
					onCancel={handleClose}
					onDone={handleClose}
					value={wizardState[WizardSteps.OPTION_GENERATE_LOE_OPTIONS]}
					onPrev={() => setWizardStep(WizardSteps.OPTION_GENERATE_LOE_OPTIONS)}
					proposalId={proposalId}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Upload Final LOE */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_UPLOAD_FINAL_LOE_CONFIRM}
			>
				<OptionUploadFinalLoeConfirm
					onCancel={handleClose}
					onDone={handleClose}
					value={wizardState[WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS]}
					onPrev={() => setWizardStep(WizardSteps.OPTION_UPLOAD_FINAL_LOE_OPTIONS)}
					proposalId={proposalId}
					fetchResponses={fetchResponses}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Eliminate firm */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_ELIMINATE_FIRM_CONFIRM}
			>
				<OptionEliminateFirmConfirm
					onPrev={() => {
						resetWizardStateForStep(WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS)
						setWizardStep(WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS)
					}}
					onDone={handleClose}
					onCancel={handleClose}
					firmId={firmId}
					firmName={firmName}
					requestId={requestId}
					fetchResponses={fetchResponses}
					openProposalFeedbackFormSingleFirm={openProposalFeedbackFormSingleFirm}
					value={wizardState[WizardSteps.OPTION_ELIMINATE_FIRM_OPTIONS]}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Uneliminate firm */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WizardSteps.OPTION_UNELIMINATE_FIRM_CONFIRM}
			>
				<OptionUneliminateFirmConfirm
					onPrev={() => setWizardStep(WizardSteps.START)}
					onDone={handleClose}
					onCancel={handleClose}
					firmId={firmId}
					firmName={firmName}
					requestId={requestId}
					fetchResponses={fetchResponses}
				/>
			</DialogWizardEnableStepIf>
		</DialogWizard>
	)
}
