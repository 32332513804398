import { useRef, useEffect, useMemo, forwardRef, memo, useCallback } from 'react'
import * as React from 'react'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { Box } from '../../box'

import { useStoreApi, selectors, useStore } from './store'

type VariableListProps = {
	// This is a React Component, a bit strange, but this is expected by react window
	children: React.ComponentType<ListChildComponentProps & { ref?: React.Ref<HTMLElement> }>
	virtualListRef?: (api: VariableSizeList | null) => void
}

export const ListboxComponent = forwardRef<HTMLDivElement, VariableListProps>(
	function ListboxComponent({ children: Children, virtualListRef, ...other }, ref) {
		const listRef = useRef<VariableSizeList>(null)
		const containerRef = useRef<HTMLElement>(null)

		const options = useStore((state, s) => s.filteredOptions().array())
		const itemCount = options.length
		const storeApi = useStoreApi()

		const realItemSize = useCallback(
			(index: number): number => {
				const options = selectors.filteredOptions(storeApi.getState())
				const array = options.array()
				const option = array[index]

				const estimateSize = (id: string) => {
					const FIELD_HEIGHT = 40
					const CHILD_HEIGHT = 36
					const option = options.valueById(id)
					const isCollapsed = selectors.isCollapsed(storeApi.getState(), id)

					if (isCollapsed) {
						return FIELD_HEIGHT
					}

					const height =
						FIELD_HEIGHT +
						CHILD_HEIGHT * option.values.flatMap((val) => [val, ...val.values]).length

					return height
				}

				const height =
					document.getElementById(`field-list:${option._id}`)?.clientHeight ||
					estimateSize(option._id)
				return height
			},
			[storeApi],
		)

		const getHeight = useCallback(() => {
			const options = selectors.filteredOptions(storeApi.getState()).array()
			return options.reduce((acc, option, index) => acc + realItemSize(index), 0)
		}, [storeApi, realItemSize])

		const updateVirtualSizes = useCallback(() => {
			listRef.current?.resetAfterIndex(0)
			const container = containerRef.current
			if (container) {
				container.style.height = `${getHeight()}px`
			}
		}, [getHeight])

		useEffect(() => {
			updateVirtualSizes()
		}, [options, itemCount, updateVirtualSizes])

		/**
		 * This is a React component defined in render, react-window expects the child of VariableSizeList to be a react component (not just a render prop). Because of this, there isn't a simple way to pass props to the row component, but we need to do this for dynamic sizing, render props are invalid because it causes re-rendering issues.
		 * Disable lint errors relating to defining components in render method.
		 */
		const RowComponent = useMemo(
			() =>
				memo((props: ListChildComponentProps) => {
					const { index, style } = props
					// eslint-disable-next-line react-hooks/rules-of-hooks
					const rowRef = useRef<HTMLElement>(null)

					// eslint-disable-next-line react-hooks/rules-of-hooks
					const observerRef = useRef(new ResizeObserver(updateVirtualSizes))

					// eslint-disable-next-line react-hooks/rules-of-hooks
					useEffect(() => {
						const observer = observerRef.current
						const el = rowRef.current
						if (el) {
							observer.observe(el)
						}
						return () => {
							if (el) {
								observer.unobserve(el)
							}
						}
					}, [rowRef.current, index])

					return (
						<>
							<div style={style}>
								<Children {...props} ref={rowRef} />
							</div>
						</>
					)
				}),
			[Children],
		)

		return (
			<Box ref={ref} {...other} style={{ display: 'flex', flexDirection: 'column' }}>
				<Box ref={containerRef}>
					<AutoSizer>
						{({ height, width }: any) => (
							<VariableSizeList
								style={{ scrollbarGutter: 'stable' }}
								itemData={options}
								height={height}
								width={width}
								itemKey={(index) => options[index]._id}
								ref={(api) => {
									// @ts-ignore
									listRef.current = api
									virtualListRef?.(api)
								}}
								itemSize={realItemSize}
								itemCount={itemCount}
							>
								{RowComponent}
							</VariableSizeList>
						)}
					</AutoSizer>
				</Box>
			</Box>
		)
	},
)
