import { Fragment } from 'react'
import { CircularProgress, Typography, CheckIcon, WarningIcon } from '@persuit/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import getOr from 'lodash/fp/getOr'
import { SectionalLocks } from '../../types'

type UserLineProps = {
	loading: boolean
	userId: string
	sectionalLocks: SectionalLocks
}

export function getOtherUsers(sectionalLocks: SectionalLocks, userId: string): Array<string> {
	const userMap = Object.values(sectionalLocks).reduce((acc: object, lock) => {
		const lockUserId = getOr('', 'userId', lock)
		const lockDisplayName = getOr('', 'displayName', lock)
		if (lockUserId !== userId) return { ...acc, [lockUserId]: lockDisplayName }
		return acc
	}, {})
	return Object.values(userMap)
}

export const UserLine = ({ loading, sectionalLocks, userId }: UserLineProps) => {
	if (loading) {
		return (
			<Fragment>
				<CircularProgress size={24} />
				<Typography>Checking if anyone is still editing...</Typography>
			</Fragment>
		)
	}

	const otherUsers = getOtherUsers(sectionalLocks, userId)

	if (isEmpty(otherUsers)) {
		return (
			<Fragment>
				<CheckIcon color="primary" />
				<Typography>No other users are editing</Typography>
			</Fragment>
		)
	}

	if (otherUsers.length === 1) {
		return (
			<Fragment>
				<WarningIcon style={{ color: 'orange' }} />
				<Typography>{otherUsers[0]} is currently editing</Typography>
			</Fragment>
		)
	}

	return (
		<Fragment>
			<WarningIcon style={{ color: 'orange' }} />
			<Typography>{otherUsers.join(', ')} are currently editing</Typography>
		</Fragment>
	)
}
