import { RichTextEditor, RichTextEditorProps } from '../rich-text-editor'
import { calculateRichTextCharacter } from '../calculate-rich-text-character'
import { useFormComponent, RHFFormComponentProps } from '../../../utilities'

export type FormRichTextEditorProps = RHFFormComponentProps<string, RichTextEditorProps>

export const FormRichTextEditor = ({
	rules,
	name,
	defaultValue,
	...props
}: FormRichTextEditorProps) => {
	const minLength = typeof rules?.minLength === 'number' ? rules.minLength : rules?.minLength?.value
	const minLengthMessage =
		(typeof rules?.minLength !== 'number' && rules?.minLength?.message) ||
		`Please enter more than ${minLength} characters`

	const maxLength =
		props.maxChars ??
		(typeof rules?.maxLength === 'number' ? rules.maxLength : rules?.maxLength?.value)
	const maxLengthMessage =
		(typeof rules?.maxLength !== 'number' && rules?.maxLength?.message) ||
		`Please enter less than ${maxLength} characters`

	const {
		field: { onChange, onBlur, value, ref, name: fieldName },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules: {
			...rules,
			minLength: undefined,
			maxLength: undefined,
			validate: {
				minLength: (value) =>
					minLength && calculateRichTextCharacter(value) < minLength ? minLengthMessage : undefined,
				maxLength: (value) =>
					maxLength && calculateRichTextCharacter(value) > maxLength ? maxLengthMessage : undefined,
			},
		},
	})

	return (
		<RichTextEditor
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			name={fieldName}
			ref={(editor) => ref(editor?.editor)}
			error={!!error?.message}
			helperText={error?.message}
			{...props}
		/>
	)
}
