// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import {
	ErrorCardData,
	ErrorCardDataSubSection,
	ErrorCardTransformSectionHandler,
} from 'packages/ui-shared-components/src/sectional-form'
import { RfpTemplateDraftScorecardErrors } from '../../../types'

function getSubcategoriesErrors(
	scoreCategoryErrors: RfpTemplateDraftScorecardErrors,
): Array<ErrorCardDataSubSection> {
	const subCategories = scoreCategoryErrors?.subcategories ?? []
	if (isEmpty(subCategories)) return []

	return subCategories.map((subCategory) => ({
		title: `Sub Category ${subCategory.index + 1}`,
		errorMessages: [subCategory.label],
	}))
}

function getScoreCategoryErrors(
	scoreCategoryErrors: RfpTemplateDraftScorecardErrors,
	link: string,
): ErrorCardData | null {
	const subCategoriesErrors = getSubcategoriesErrors(scoreCategoryErrors)

	const labelErrorMessage = scoreCategoryErrors?.label ?? ''

	if (isEmpty(subCategoriesErrors) && isEmpty(labelErrorMessage)) return null
	return {
		title: `Scorecard Item ${scoreCategoryErrors.index + 1}`,
		link: `${link}/scorecard_item_${scoreCategoryErrors.index}`,
		errorMessages: [labelErrorMessage].filter(Boolean),
		subSections: subCategoriesErrors,
	}
}

export function getScoreCategoriesErrors(
	sectionErrors: Array<RfpTemplateDraftScorecardErrors>,
	link: string,
): Array<ErrorCardData> {
	return sectionErrors
		.map((scoreCategoryErrors) => getScoreCategoryErrors(scoreCategoryErrors, link))
		.filter((item): item is ErrorCardData => Boolean(item))
}

export const makeScorecardHandlerMaker =
	({ getCardLink }) =>
	({ sectionName, path = '', templateId = '' }): ErrorCardTransformSectionHandler =>
	(sectionErrors: Array<RfpTemplateDraftScorecardErrors>): Array<ErrorCardData> => {
		if (isEmpty(sectionErrors)) return []
		const link = getCardLink({ path, templateId, sectionName })

		const scorecardErrors = getScoreCategoriesErrors(sectionErrors, link)
		return [...scorecardErrors]
	}
