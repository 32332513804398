import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge'

export type BadgeProps = MuiBadgeProps & {
	size?: 'small' | 'large'
}

export const Badge = ({ size = 'large', ...rest }: BadgeProps) => {
	const smallStyle = {
		minWidth: 'unset',
		padding: 0,
		width: '10px',
		height: '10px',
	}

	return (
		<MuiBadge
			{...rest}
			slotProps={
				size === 'small'
					? {
							...rest.slotProps,
							badge: {
								...rest.slotProps?.badge,
								style: {
									...smallStyle,
									...(rest.slotProps?.badge as any)?.style,
								},
							},
					  }
					: rest.slotProps
			}
		/>
	)
}
