import { format } from 'date-fns'
import { map, size } from 'lodash/fp'

import {
	styled,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	Typography,
} from '@persuit/ui-components'
import { firmPhaseModel } from '../../../../../common/data/pricing-models'
import useTranslation from '../../../../custom-hooks/translation-hook'
import formatPriceCurrency from '../../../../utils/price-and-currency'

const getFormattedHours = (hours, phaseDuration) => {
	if (!hours) {
		return '-'
	}

	return phaseDuration === 'months' ? `${hours} mo` : `${hours} hrs`
}

const getFormattedPrice = (price, currency) => {
	if (!price) {
		return '-'
	}

	return formatPriceCurrency(currency, price)
}

const getFormattedPercentage = (percentageValue) => {
	if (!percentageValue) {
		return '-'
	}

	return `${percentageValue}%`
}

const StyledTableCell = styled(TableCell)`
	&.MuiTableCell-root {
		border: none;
	}
`

const PriceHistoryTableRow = ({
	priceHistory: { price, percentage, pricingModel, hours, notIncluded, updatedAt },
	currency,
	phaseDuration,
}) => {
	const {
		translations: [tPricingModel],
	} = useTranslation([{ path: `feeBreakdown.pricingModels.firmItem.${pricingModel}` }])

	const formattedFeeOrRate = price
		? getFormattedPrice(price, currency)
		: getFormattedPercentage(percentage)
	const formattedPricingItemHours = getFormattedHours(hours, phaseDuration)
	const formattedUpdateAt = format(updatedAt, 'dd MMM yyyy, hh:mma')

	if (notIncluded) {
		return (
			<TableRow>
				<StyledTableCell colSpan={3}>
					<Typography variant="body1">Excluded from scope</Typography>
				</StyledTableCell>
				<StyledTableCell>{formattedUpdateAt}</StyledTableCell>
			</TableRow>
		)
	}

	if (pricingModel === firmPhaseModel.INCLUDED) {
		return (
			<TableRow>
				<StyledTableCell data-testid="no-charge-price">-</StyledTableCell>
				<StyledTableCell>{tPricingModel}</StyledTableCell>
				<StyledTableCell>{formattedPricingItemHours}</StyledTableCell>
				<StyledTableCell>{formattedUpdateAt}</StyledTableCell>
			</TableRow>
		)
	}

	return (
		<TableRow>
			<StyledTableCell>{formattedFeeOrRate}</StyledTableCell>
			<StyledTableCell>{tPricingModel}</StyledTableCell>
			<StyledTableCell>{formattedPricingItemHours}</StyledTableCell>
			<StyledTableCell>{formattedUpdateAt}</StyledTableCell>
		</TableRow>
	)
}

const DurationTableCell = ({ phaseDuration }) => {
	if (phaseDuration === 'months') {
		return <TableCell data-testid="months-duration">Months</TableCell>
	}
	return <TableCell>Hours</TableCell>
}

const PriceItemHistoryTable = ({ priceHistories, currency, phaseDuration }) => {
	if (size(priceHistories) === 0) {
		return null
	}

	return (
		<Table size="small" aria-label="Item price history">
			<caption>Item price history</caption>
			<TableHead>
				<TableRow>
					<TableCell>Fee/Rate</TableCell>
					<TableCell>Fee type</TableCell>
					<DurationTableCell phaseDuration={phaseDuration} />
					<TableCell>Last updated</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(
					(priceHistory) => (
						<PriceHistoryTableRow
							key={priceHistory._id}
							priceHistory={priceHistory}
							currency={currency}
							phaseDuration={phaseDuration}
						/>
					),
					priceHistories,
				)}
			</TableBody>
		</Table>
	)
}

export default PriceItemHistoryTable
