import { Fragment } from 'react'
import { Typography, styled } from '@persuit/ui-components'
import useTranslation from '../../../custom-hooks/translation-hook'
import formatCurrency from '../../../utils/format-currency'
import { getOrNull } from '../../../utils/lenses'
import RfprItem from '../../pricing-card-detail-view/components/rfpr-item'
import { arePricesEqual } from '../../rfp-request-proposal-revision/suggestion-change-detection'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 50%;
`
const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 50% !important;
	gap: 8px;
`

const StyledHourlyRate = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-spacing: 10px;
`

// Dedicate space for aligning fee, pricing model, and hours
const StyledMiniCellFee = styled('div')`
	width: 194px;
	white-space: nowrap;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`
const StyledMiniCellModel = styled('div')`
	width: 285px;
`

const HourlyRate = ({ responsePrice, currency, rfpr }) => {
	const { t } = useTranslation()
	const priceCopy = formatCurrency(responsePrice, currency)
	const rfprPrice = getOrNull('rfprPrice', rfpr)
	const samePrice = arePricesEqual(responsePrice, rfprPrice)

	return (
		<Fragment>
			<StyledHourlyRate>
				<StyledItemLeft></StyledItemLeft>
				<StyledItemRight>
					<StyledMiniCellFee>
						<Typography strikeThrough={!samePrice} data-testid="item-subtotal-rate">
							{priceCopy}
						</Typography>
					</StyledMiniCellFee>
					<StyledMiniCellModel>
						<Typography data-testid="item-subtotal-model">
							{t('rfpProposalDetail.hourlyRate')}
						</Typography>
					</StyledMiniCellModel>
				</StyledItemRight>
			</StyledHourlyRate>
			{!samePrice && (
				<RfprItem rfprPrice={rfprPrice} originalPrice={responsePrice} currency={currency} />
			)}
		</Fragment>
	)
}

export default HourlyRate
