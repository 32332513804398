import { useMemo } from 'react'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateDetailSaveMutation } from '@persuit/ui-graphql/hooks'
import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { DetailForm, DetailFormData } from './detail-form'
import { MATTER_TYPES } from '../../../../../src/common/data/template-tags'
import useTemplateCountries, { NewTemplateCountryInfo } from './use-template-countries'

graphql(`
	mutation RfpTemplateDetailSave($payload: RfpTemplateDraftDetailInput!, $validate: Boolean!) {
		saveRfpTemplateDraftDetail(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type DetailSectionProps = {
	instanceId: string
	rfpTemplate: RfpTemplate
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	validationErrors: {
		detail?: {
			templateMatterType?: string
			templateCountry?: string
		}
	} | null
	sendMode: boolean
	templateCountryLookup: Record<string, NewTemplateCountryInfo>
}

export const DetailSectionForm = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	refetchErrors,
	validationErrors,
	sendMode,
	templateCountryLookup,
}: DetailSectionProps) => {
	const [saveSectionMutation] = useRfpTemplateDetailSaveMutation()
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const sectionData: DetailFormData = useMemo(
		() => ({
			templateDescription: rfpTemplate?.templateDescription ?? '',
			templateMatterType: rfpTemplate?.templateTags?.matterType
				? MATTER_TYPES.find((matterType) => matterType.id === rfpTemplate?.templateTags?.matterType)
				: undefined,
			templateCountry: templateCountryLookup[rfpTemplate?.templateTags?.country ?? ''] ?? undefined,
		}),
		[rfpTemplate],
	)
	const sectionErrors = validationErrors?.detail

	if (loading) return <LoadingSpinner />

	const saveSection = async (formVariables: DetailFormData) => {
		await saveSectionMutation({
			variables: {
				payload: {
					templateDescription: formVariables.templateDescription,
					templateCountry: formVariables.templateCountry?.id,
					templateMatterType: formVariables.templateMatterType?.id,
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	return (
		<>
			<PageTitle>Edit details of template</PageTitle>
			<DetailForm
				saveSection={saveSection}
				sectionData={sectionData}
				sectionErrors={sectionErrors}
				useCase={rfpTemplate.useCase ?? undefined}
			/>
		</>
	)
}

export const DetailSection = (props: Omit<DetailSectionProps, 'templateCountryLookup'>) => {
	const { templateCountryLookup, loading } = useTemplateCountries()

	if (loading) {
		return null
	}

	return <DetailSectionForm {...props} templateCountryLookup={templateCountryLookup} />
}
