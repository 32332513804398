import { useEffect, ReactNode } from 'react'
import { LoadingSpinner } from '@persuit/ui-components'
import { graphql, useQuery } from '@persuit/ui-graphql'
import { logger } from '@persuit/ui-logger'
import { useLocation } from 'react-router-dom'
import { Unauthorized, SkipToContentButton } from '@persuit/ui-shared-components'
import { useSelector } from '@client/store'
import { ActivityDetector } from '../../components/activity-detector'
import { ChangePasswordDialog } from '../../components/auth/change-password-dialog'
import { Nav as NavUAM } from '../../components/uam-nav'
import { Nav } from '../../components/nav'
import PendoHelp from '../../components/pendo-help'
import { refreshUserInitiatedRequest } from '../../utils/cookieRefresh'
import { ReachableSitesReporter } from '../reachable-sites-reporter'
import { OnboardingRedirect } from './onboarding-redirect'
import { withFeatureToggleProvider, useFeatureToggles } from '@persuit/ui-feature-toggle'
import { userActivityActions } from '../../actions'
import { IdentifyUser } from './identify-user'
import { WatermarkBackground } from './watermark-background'

const ACTIVITY_DETECTOR_DEBOUNCE_DELAY = window.__ACTIVITY_DETECTOR_DEBOUNCE_DELAY_MS__ || 5000 // 5s

const GET_ORG = graphql(`
	query App_GetOwnOrg {
		getOwnOrg {
			_id
			isConfirmed
		}
	}
`)

type AppProps = {
	children: ReactNode
}

const NAV_EXCLUDED_PAGES = ['getting-started', 'messages/', 'onboarding', 'onboarding/confirmation']

const App = ({ children }: AppProps) => {
	const { loading, error, data } = useQuery(GET_ORG)
	const user = useSelector((state) => state?.auth?.user)
	const { pathname } = useLocation()

	useEffect(() => {
		if (user) {
			userActivityActions.getUserActivity()
		}
	}, [user])

	const { toggles } = useFeatureToggles({ auth: false })
	const isUamEnabled = true // dev-5192.user-access-management

	if (loading) {
		return <LoadingSpinner />
	}

	if (error || !user) {
		if (error) {
			logger.error(error)
		}
		return <Unauthorized />
	}

	const orgId = data?.getOwnOrg?._id ?? ''
	if (!orgId) {
		logger.error('orgId not present')
		return <Unauthorized />
	}

	const isNavExcludedPages = NAV_EXCLUDED_PAGES.some((path) => pathname.includes(path))

	return (
		<>
			{toggles['operation.persuit-academy']?.enabled && <WatermarkBackground />}
			<IdentifyUser />
			<ReachableSitesReporter />
			<ActivityDetector
				onActivityDetected={refreshUserInitiatedRequest}
				delay={ACTIVITY_DETECTOR_DEBOUNCE_DELAY}
			/>

			<SkipToContentButton href="#main-content" />
			<ChangePasswordDialog />
			<PendoHelp />

			<OnboardingRedirect user={user}>
				{!isNavExcludedPages && !isUamEnabled && <Nav />}
				{!isNavExcludedPages && isUamEnabled && <NavUAM />}
				<main id="main-content">{children}</main>
			</OnboardingRedirect>
		</>
	)
}

export default withFeatureToggleProvider()(App)
