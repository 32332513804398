import { noop } from '@persuit/common-utils'
import { Typography, Dialog, Box, Image } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

import { RateCell } from '../column-definitions'
import namedTimekeepersExampleImage from './named-timekeepers-example.png'

export type TableInformationProps = {
	open: boolean
	onClose?: () => void
}

export const TableInformationDialog = ({ open, onClose = noop }: TableInformationProps) => {
	const { loading, toggles } = useFeatureToggles()

	if (loading) return null

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Table information"
			actions={[{ label: 'Close', variant: 'primary', onClick: onClose }]}
			size="md"
		>
			<Box
				component="ol"
				sx={{
					'& > li': { mb: 2 },
				}}
			>
				<li>
					<Typography display="block" variant="body2Semibold">
						Blended comparison table
					</Typography>
					{toggles['dev-10954.multi-currency'] ? (
						<>
							<Typography variant="body2" gutterBottom={true} sx={{ display: 'block' }}>
								This table displays a blended average of the firm's rate cards for your timekeeper
								levels in the selected currency. The table can be filtered by the locations and
								practice areas provided by the firm as well as selected firms.
							</Typography>
							<Typography variant="body2">
								Note: This table does not convert currencies; it only shows the currencies that have
								been submitted via the rate cards.
							</Typography>
						</>
					) : (
						<Typography variant="body2">
							The table displays a blended average of the firm's rate cards. The table can be
							filtered by the locations and practice areas provided by the firm as well as by
							selected firms.
						</Typography>
					)}
				</li>
				<li>
					<Typography display="block" variant="body2Semibold">
						Weighted average change
					</Typography>
					<Typography variant="body2">
						This represents the average rate across all timekeepers, adjusted based on each
						timekeeper's hours from last year.
					</Typography>
				</li>
				<li>
					<Typography display="block" variant="body2Semibold">
						Proposed rate and percentage change
					</Typography>
					<Typography variant="body2">
						Each table cell contains the blended proposed rate and the blended percentage change.
						The percentage change represents the change from the current rate to the proposed rate
						submitted by the firms. The percentage change will only display if the current rate has
						been provided for at least 1 rate card in the proposal.
					</Typography>
					<Box
						sx={(theme) => ({
							p: 2,
							display: 'flex',
							alignItems: 'center',
							background: theme.palette.grey100,
							width: '165px',
							height: '80px',
						})}
					>
						<RateCell currency="AUD" delta={0.03} proposedRate={850.97} flexGrow={1} />
					</Box>
				</li>
				<li>
					<Typography display="block" variant="body2Semibold">
						'Show Proposal Revisions' Toggle
					</Typography>
					<Typography variant="body2">
						When toggled on, view initial firm proposals alongside the most recent proposal
						revision. Initial proposed rates and the initial 'percentage change from last year' will
						appear with a strikethrough in red, indicating they are no longer current.
					</Typography>
					<Box
						sx={(theme) => ({
							p: 2,
							display: 'flex',
							alignItems: 'center',
							background: theme.palette.grey100,
							width: '165px',
							height: '80px',
						})}
					>
						<RateCell
							currency="AUD"
							delta={0.06}
							proposedRate={1020.65}
							revisedDelta={0.04}
							revisedRate={1050.22}
							flexGrow={1}
							shouldShowRevisionRates={true}
						/>
					</Box>
				</li>
				{toggles['dev-10144.named-timekeepers'] ? (
					<li>
						<Typography display="block" variant="body2Semibold">
							View provided individual timekeeper data
						</Typography>
						<Typography variant="body2">
							The provided individual timekeeper data can be found on each of the proposals details.
							Click 'View detail' button under the firm name. Click on "Individual Timekeeper" on
							the tab in that view.
						</Typography>
						<Box sx={{ mt: 2 }}>
							<Image
								src={namedTimekeepersExampleImage}
								alt="named timekeepers example screenshot"
							/>
						</Box>
					</li>
				) : null}
			</Box>
		</Dialog>
	)
}
