export const downloadFile = async (url: string, name: string) => {
	const response = await fetch(url, { method: 'get' })
	const blob = await response.blob()

	downloadBlob(blob, name)
}

export const downloadBlob = (blob: Blob, name: string) => {
	const blobURL = URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.href = blobURL
	;(a as any).style = 'display: none'

	if (name && name.length) a.download = name
	document.body.appendChild(a)
	a.click()
	a.remove()
}
