import Box from '@client/components/box'
import useTranslation from '@client/custom-hooks/translation-hook'
import { pink400 } from '@client/theme/persuit-colors'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Paper from 'material-ui/Paper'
import { LockOpenIcon, ErrorIcon, Button } from '@persuit/ui-components'

const ShareRequestInfoFragment = graphql(`
	fragment ShareRequestInfo_Analytics on Analytics {
		hasRequestedShare
	}
`)

export type ShareRequestInfoProps = {
	rfpFragment: FragmentType<typeof ShareRequestInfoFragment>
	requestShare: () => void
}

export function ShareRequestInfo({ rfpFragment, requestShare }: ShareRequestInfoProps) {
	const { t } = useTranslation()
	const { hasRequestedShare } = getFragment(ShareRequestInfoFragment, rfpFragment)

	if (hasRequestedShare) {
		return (
			<Paper
				style={{
					borderLeft: `6px solid ${pink400}`,
					margin: '1rem 0',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<LockOpenIcon
					color="error"
					sx={{
						marginLeft: '1rem',
					}}
				/>
				<Box all={2}>{t('sharing.requests.pending.info')}</Box>
			</Paper>
		)
	} else {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ErrorIcon
					color="error"
					sx={{
						marginRight: '0.5rem',
					}}
				/>
				<div
					style={{
						flex: 1,
					}}
				>
					Tracking only access
				</div>
				<Button onClick={requestShare} startIcon={<LockOpenIcon />}>
					{t('sharing.requests.buttons.request')}
				</Button>
			</div>
		)
	}
}
