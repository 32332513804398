// @ts-strict-ignore
import { useState } from 'react'

import * as React from 'react'
import {
	Box,
	Spacer,
	Chip as MuiChip,
	ChipProps as MuiChipProps,
	styled,
} from '@persuit/ui-components'
import { ChipExpanded } from './chip-expanded'

export type ChipProps = {
	chipProps?: MuiChipProps
	className?: string
	primaryText: string
	secondaryText?: string
	icon?: React.ReactNode
	expandable?: boolean
	onClick?: () => void
	onDelete?: () => void

	// expandable chip props
	additionalText?: string[]
	isApprovedFirm?: boolean
	isPanelOrPreferredFirm?: boolean
	truncatePrimaryText?: boolean
	truncateSecondaryText?: boolean
	isFavourite?: boolean
	addToFavourites?: (userId: string) => () => void
	userId?: string
	hideStar?: boolean
}

const TruncatedText = styled('span')({
	display: 'inline-block',
	maxWidth: '9em',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
})

export const Chip = ({
	className,
	onClick,
	onDelete,
	primaryText,
	secondaryText,
	icon,
	expandable = false,

	userId,
	hideStar,
	isApprovedFirm = false,
	isFavourite,
	isPanelOrPreferredFirm = false,
	additionalText,
	addToFavourites,

	chipProps,
}: ChipProps) => {
	const [expanded, setExpanded] = useState(false)
	const [expandAnchor, setExpandAnchor] = useState(null)

	const onExpand = (event) => {
		event.preventDefault()
		setExpanded(true)
		setExpandAnchor(event.currentTarget)
	}

	const onCollapse = () => setExpanded(false)

	return (
		<>
			{expanded && (
				<ChipExpanded
					expanded={true}
					expandAnchor={expandAnchor}
					onRequestClose={onCollapse}
					onClose={onCollapse}
					primaryText={primaryText}
					secondaryText={secondaryText}
					additionalText={additionalText}
					isFavourite={isFavourite}
					hideStar={hideStar}
					userId={userId}
					addToFavourites={userId ? addToFavourites?.(userId) : undefined}
					isApprovedFirm={isApprovedFirm}
					isPanelOrPreferredFirm={isPanelOrPreferredFirm}
				/>
			)}

			<MuiChip
				{...chipProps}
				className={className}
				onClick={expandable ? onExpand : onClick}
				onDelete={onDelete}
				style={{
					marginRight: '4px',
					marginBottom: '4px',
					marginTop: '4px',
				}}
				label={
					<Box display="flex" alignItems="center">
						<TruncatedText>{primaryText}</TruncatedText>

						{secondaryText ? (
							<TruncatedText style={{ marginLeft: 4 }}>({secondaryText})</TruncatedText>
						) : null}

						{icon ? (
							<>
								<Spacer space={0.5} shape="column" />
								<Box display="flex" gap="4px" flexDirection="row">
									{icon}
								</Box>
							</>
						) : null}
					</Box>
				}
			/>
		</>
	)
}
