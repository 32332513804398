import { format } from 'date-fns'
import isEmpty from 'lodash/fp/isEmpty'
import { styled, ExpandableCard, Typography, Box } from '@persuit/ui-components'

import ProposalFeedbackDisplayContent from './feedback-content'

const HeaderContainer = styled('div')`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: -8px;
`

const OrgNameContainer = styled('span')`
	font-weight: bold;
`

const ProposalFeedbackDisplay = ({ feedback, orgName, expanded, showProposalDetails = false }) => {
	if (isEmpty(feedback)) return null
	const createdAt = feedback?.[0]?.createdAt ?? 0
	const date = format(createdAt, 'd LLLL yyyy, h:mmbbb')

	return (
		<>
			<ExpandableCard
				containerProps={{
					mb: 2,
				}}
				summary={
					<HeaderContainer>
						<OrgNameContainer>{orgName}</OrgNameContainer>
						{Boolean(createdAt) && <Typography color="text.secondary">{date}</Typography>}
					</HeaderContainer>
				}
				summaryProps={{
					'aria-label': `${orgName} feedback`,
				}}
				details={feedback.map((f, index) => (
					<Box key={index} mb={2}>
						<ProposalFeedbackDisplayContent
							showProposalDetails={showProposalDetails}
							feedback={f}
						/>
					</Box>
				))}
				defaultExpanded={expanded}
				borderOnFocus={false}
			/>
		</>
	)
}

export default ProposalFeedbackDisplay
