import formatDistance from 'date-fns/formatDistance'
import format from 'date-fns/format'
import { useState, useEffect } from 'react'
import { Typography } from '@persuit/ui-components'

type LastEditedProps = {
	lastEdited: Date
	color?: string
}

export const LastEdited = ({ lastEdited, color }: LastEditedProps) => {
	const [editTime, setEditTime] = useState(Date.now())
	const oneMinuteInMs = 60000

	//useEffect causes a re-render by resetting the state of editTime every 60 seconds
	useEffect(() => {
		const editInterval = setInterval(() => setEditTime(Date.now()), oneMinuteInMs)
		return () => {
			clearInterval(editInterval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	if (!lastEdited) return null

	const timeDistanceString = formatDistance(lastEdited, editTime, { addSuffix: true })
	const fullTimeString = format(lastEdited, 'PPPPpppp')
	return (
		<Typography
			title={fullTimeString}
			sx={{ color: color ? color : 'text.secondary' }}
			variant="body1"
		>
			{`Last edit ${timeDistanceString}`}
		</Typography>
	)
}

export default LastEdited
