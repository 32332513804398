// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { isNotNil } from '@persuit/common-utils'
import {
	ErrorCardData,
	ErrorCardDataSubSection,
	ErrorCardTransformSectionHandler,
} from '../../../../sectional-form'
import {
	RfpTemplateDraftPricingErrors,
	RfpTemplateDraftPricingDeliverableErrors,
	RfpTemplateDraftPricingRateErrors,
} from '../../../types'

export function getTotalPriceError(
	sectionErrors: RfpTemplateDraftPricingErrors,
	title: string,
	link: string,
): Array<ErrorCardData> {
	const totalPriceError = sectionErrors?.totalPriceRequired ?? ''
	if (isEmpty(totalPriceError)) return []
	return [
		{
			title,
			link,
			errorMessages: [totalPriceError],
			subSections: [],
		},
	]
}

export function getTotalPriceModelError(
	sectionErrors: RfpTemplateDraftPricingErrors,
	title: string,
	link: string,
): Array<ErrorCardData> {
	const totalPriceModelError = sectionErrors?.totalPriceModel ?? ''
	if (isEmpty(totalPriceModelError)) return []
	return [
		{
			title,
			link,
			errorMessages: [totalPriceModelError],
			subSections: [],
		},
	]
}

export function getDeliverablesErrors(
	sectionErrors: RfpTemplateDraftPricingErrors,
	link: string,
): Array<ErrorCardData> {
	const deliverables = sectionErrors?.deliverables ?? []
	if (isEmpty(deliverables)) return []

	return deliverables
		.map((deliverable) => getDeliverableErrors(deliverable, link))
		.filter((item): item is ErrorCardData => !!item)
}

export function getSubSectionErrors(
	deliverableError: RfpTemplateDraftPricingDeliverableErrors,
): Array<ErrorCardDataSubSection> {
	const subItems: Array<RfpTemplateDraftPricingRateErrors> = deliverableError?.rateErrors ?? []
	if (isEmpty(subItems)) return []

	return subItems
		.map((rateError): ErrorCardDataSubSection | null => {
			const labelError = rateError?.label ?? ''
			if (isEmpty(labelError)) return null
			return {
				title: `Sub Item ${rateError.index + 1}`,
				errorMessages: [labelError],
			}
		})
		.filter((item): item is ErrorCardDataSubSection => !!item)
}

export function getDeliverableErrors(
	deliverableError: RfpTemplateDraftPricingDeliverableErrors,
	link: string,
): ErrorCardData | null {
	const subSections = getSubSectionErrors(deliverableError)

	const errorMessages = [
		deliverableError?.deliverableTitle ?? '',
		deliverableError?.firmRateCardEmptyPlaceHolder ?? '',
		deliverableError?.rateCardEmptyError ?? '',
		deliverableError?.deliverable ?? '',
		deliverableError?.firmRateCardItemPlaceholder ?? '',
	].filter(Boolean)

	if (isEmpty(subSections) && isEmpty(errorMessages)) return null

	return {
		title: `Price Item ${deliverableError.index + 1}`,
		link: `${link}/pricing_item_${deliverableError.index}`,
		errorMessages,
		subSections,
	}
}

export function getPricingGroupErrors(
	sectionErrors: RfpTemplateDraftPricingErrors,
	link: string,
): ErrorCardData[] {
	return (sectionErrors?.groups ?? [])
		.map((group) => {
			const error = {
				title: `Pricing Group ${group.index + 1}`,
				link: `${link}/pricing_group_${group.index}`,
				errorMessages: [group.title, group.noItems].filter(isNotNil),
				subSections: [],
			}

			return error.errorMessages.length > 0 ? error : null
		})
		.filter(isNotNil)
}

type makePricingHandlerData = {
	sectionName: string
	path?: string
	templateId?: string
	titleOverride?: string
}

export const makePricingHandlerMaker =
	({ getCardTitle, getCardLink }) =>
	({
		sectionName,
		path = '',
		templateId = '',
		titleOverride = '',
	}: makePricingHandlerData): ErrorCardTransformSectionHandler =>
	(sectionErrors: RfpTemplateDraftPricingErrors): Array<ErrorCardData> => {
		if (isEmpty(sectionErrors)) return []
		const title = getCardTitle({ sectionName, titleOverride })
		const link = getCardLink({ path, templateId, sectionName })

		const totalPriceError = getTotalPriceError(sectionErrors, title, link)
		const totalPriceModelError = getTotalPriceModelError(sectionErrors, title, link)

		const pricingItemErrors = getDeliverablesErrors(sectionErrors, link)
		const pricingGroupErrors = getPricingGroupErrors(sectionErrors, link)

		return [
			...totalPriceError,
			...totalPriceModelError,
			...pricingGroupErrors,
			...pricingItemErrors,
		]
	}
