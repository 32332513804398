import * as React from 'react'
import { useParams } from 'react-router-dom'
import { SectionalLocks } from '../../sectional-form/types'
import { TemplateLockableSectionInner } from './template-lockable-section-inner'
import { RfpTemplate } from '../types'

type TemplateLockableSectionProps = {
	children: React.ReactNode
	sectionName: string
	sectionalLocks: SectionalLocks
	instanceId: string
	tryLock: (sectionName: string) => Promise<void>
	rfpTemplate: RfpTemplate
	userId: string
	isDiversitySectionEnabled: boolean
}

export const TemplateLockableSection = ({
	children,
	sectionName,
	sectionalLocks,
	instanceId,
	tryLock,
	rfpTemplate,
	userId,
	isDiversitySectionEnabled,
}: TemplateLockableSectionProps) => {
	const { section } = useParams<{ section: string }>()
	const useSection = section ? section : 'detail'

	if (useSection !== sectionName) return null
	return (
		<TemplateLockableSectionInner
			sectionName={sectionName}
			sectionalLocks={sectionalLocks}
			instanceId={instanceId}
			rfpTemplate={rfpTemplate}
			tryLock={tryLock}
			userId={userId}
			isDiversitySectionEnabled={isDiversitySectionEnabled}
		>
			{children}
		</TemplateLockableSectionInner>
	)
}
