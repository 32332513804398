import { Box, Typography, styled } from '@persuit/ui-components'
import { averageRate } from '@persuit/common-logic'
import { useStore } from '../../store'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { Delta } from '../delta'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type RateCardAverageProps = {
	rates: {
		_id: string
		rate: number
	}[]
}

const ContainerWithTopBorder = styled(Box)`
	margin-top: ${({ theme }) => theme.spacing(2)};
	border-top: 2px solid ${({ theme }) => theme.palette.neutral.main};
	padding-top: ${({ theme }) => theme.spacing(2)};
`

export const RateCardAverage = ({ rates }: RateCardAverageProps) => {
	const { toggles } = useFeatureToggles()
	const isFeatureEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const { rateItemFormState, currency } = useStore((state) => ({
		rateItemFormState: state.rateItemFormState,
		currency: state.currency,
	}))
	const liveRates = rates.map((rate) => ({
		rate: rateItemFormState[rate._id] ?? rate.rate,
	}))

	const originalRate = averageRate(rates)
	const liveAverageRate = averageRate(liveRates)

	if (!isFeatureEnabled) {
		return null
	}

	return (
		<>
			<ContainerWithTopBorder sx={{ gridColumnStart: 4 }} role="presentation">
				<Typography variant="body1Semibold">Average rate</Typography>
			</ContainerWithTopBorder>
			<ContainerWithTopBorder role="presentation" />
			<ContainerWithTopBorder display="flex" justifyContent={'space-between'}>
				<Typography variant="body1Semibold">
					{formatPriceWithCurrency(currency, liveAverageRate, {
						spaceBetween: true,
						showCurrencyCode: false,
					})}
				</Typography>
				<Typography variant="body1Semibold">{currency}</Typography>
			</ContainerWithTopBorder>
			<ContainerWithTopBorder>
				<Delta currency={currency} newNumber={liveAverageRate} originalNumber={originalRate} />
			</ContainerWithTopBorder>
		</>
	)
}
