// @ts-strict-ignore
import { useId, useState } from 'react'

import * as React from 'react'
import {
	KebabButton,
	MoreVertIcon,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	useConfirmDialog,
	DeleteIcon,
	Tooltip,
	Box,
	Alert,
	useSnackbar,
	useFormContext,
	LibraryAddIcon,
} from '@persuit/ui-components'
import { getQuestionsForGroup } from './question-group-utils'

type QuestionGroupMoreActionsMenuProps = {
	index: number
	deleteGroup: (index: number) => void
	duplicateGroup: () => void
	totalGroupItems: number
}

export const QuestionGroupMoreActionsMenu = ({
	index,
	totalGroupItems,
	deleteGroup,
	duplicateGroup,
}: QuestionGroupMoreActionsMenuProps) => {
	const { getValues } = useFormContext()

	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null)
	const handleActionBtnClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		setActionAnchorEl(event.currentTarget)
	}
	const handleActionMenuClose = (event?) => {
		event?.stopPropagation()
		setActionAnchorEl(null)
	}

	const { questions, questionGroups } = getValues()
	const questionGroup = questionGroups[index]

	const isInRfpRevisionMode = getQuestionsForGroup({ questions, questionGroup }).some(
		(item) => item.originalQuestionId,
	)

	const { openConfirmDialog } = useConfirmDialog()
	const kebabBtnId = useId()
	const menuId = useId()

	const deleteListText = (
		<ListItemText
			primary="delete"
			primaryTypographyProps={{ color: 'primary', variant: 'button' }}
		/>
	)

	const { openSnackbar } = useSnackbar()
	const menuOpen = Boolean(actionAnchorEl)
	const groupPosition = index + 1

	return (
		<>
			<KebabButton
				id={kebabBtnId}
				aria-label={`Group ${groupPosition} actions`}
				onClick={handleActionBtnClick}
				aria-controls={menuOpen ? menuId : undefined}
			>
				<MoreVertIcon />
			</KebabButton>
			<Menu
				id={menuId}
				open={menuOpen}
				onClose={handleActionMenuClose}
				anchorEl={actionAnchorEl}
				aria-labelledby={kebabBtnId}
			>
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						openConfirmDialog({
							title: 'Are you sure you want to delete this group?',
							content: totalGroupItems > 0 && (
								<Alert severity="warning" role="status">
									This will also delete question/s within this group.
								</Alert>
							),
							actions: [
								{ label: 'Cancel', type: 'secondary', close: true },
								{
									label: 'Delete',
									type: 'primary',
									icon: (
										<DeleteIcon
											sx={{
												color: 'common.white',
											}}
										/>
									),
									close: true,
									action: () => {
										if (!isInRfpRevisionMode) {
											deleteGroup(index)
											openSnackbar('Group has been deleted.')
										}
									},
								},
							],
						})
					}}
					aria-label="Delete group"
					disabled={isInRfpRevisionMode}
				>
					<ListItemIcon>
						<DeleteIcon color="primary" fontSize="small" />
					</ListItemIcon>
					{isInRfpRevisionMode ? (
						<Tooltip
							title={'Groups with pre-existing pricing items cannot be deleted.'}
							arrow={true}
						>
							<Box>{deleteListText}</Box>
						</Tooltip>
					) : (
						deleteListText
					)}
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						duplicateGroup()
						handleActionMenuClose()
					}}
					aria-label="Duplicate group"
				>
					<ListItemIcon>
						<LibraryAddIcon color="primary" fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary="duplicate"
						primaryTypographyProps={{ color: 'primary', variant: 'button' }}
					/>
				</MenuItem>
			</Menu>
		</>
	)
}
