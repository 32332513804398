// @ts-strict-ignore
import { OptionQuickPriceRevisionUpdatePrices } from '../option-quick-price-revision-update-prices'
import { OptionQuickPriceRevisionUpdateRates } from '../option-quick-price-revision-update-rates'
import { OptionQuickPriceRevisionConfirm } from '../option-quick-price-revision-confirm'
import DialogWizardEnableStepIf from '../../../dialog-wizard-enable-step-if'
import { WIZARD_STEPS } from '../steps'
import { useProposalQuickRevisionStore } from '@persuit/ui-shared-components'
import { isEmpty } from 'lodash'

type OptionQuickPriceRevisionStepsProps = {
	currentStep: string
	setStep: (string) => void
	handleClose: () => void
	navigateToPage: (page: string) => void
}

export const OptionQuickPriceRevisionSteps = ({
	currentStep,
	setStep,
	handleClose,
	navigateToPage,
}: OptionQuickPriceRevisionStepsProps) => {
	const { rateItemLookup, pricingItemLookup, noValidItems, totalPriceNotRequired } =
		useProposalQuickRevisionStore(
			({ rateItemLookup, pricingItemLookup, totalPriceNotRequired }, selectors) => ({
				rateItemLookup,
				pricingItemLookup,
				noValidItems: selectors.noValidItems(),
				totalPriceNotRequired,
			}),
		)

	return (
		<>
			{/* Step 2 Quick Price Revision Sub Step 1 - Update Prices  */}
			<DialogWizardEnableStepIf
				condition={currentStep === WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES}
			>
				<OptionQuickPriceRevisionUpdatePrices
					onPrev={() => {
						setStep(WIZARD_STEPS.START)
					}}
					onNext={() =>
						isEmpty(rateItemLookup)
							? setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_CONFIRM)
							: setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_RATES)
					}
					onCancel={handleClose}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 2 Quick Price Revision Sub Step 2 - Update Rates & Percentages  */}
			<DialogWizardEnableStepIf
				condition={currentStep === WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_RATES}
			>
				<OptionQuickPriceRevisionUpdateRates
					onPrev={() => {
						isEmpty(pricingItemLookup)
							? setStep(WIZARD_STEPS.START)
							: setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES)
					}}
					onNext={() => setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_CONFIRM)}
					onCancel={handleClose}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Quick Price Revision - Confirm */}
			<DialogWizardEnableStepIf
				condition={currentStep === WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_CONFIRM}
			>
				<OptionQuickPriceRevisionConfirm
					onPrev={() =>
						!totalPriceNotRequired && noValidItems
							? setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES)
							: noValidItems
							? setStep(WIZARD_STEPS.START)
							: isEmpty(rateItemLookup)
							? setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_PRICES)
							: setStep(WIZARD_STEPS.OPTION_QUICK_PRICE_REVISION_UPDATE_RATES)
					}
					onDone={handleClose}
					onCancel={handleClose}
					navigateToPage={navigateToPage}
				/>
			</DialogWizardEnableStepIf>
		</>
	)
}
