import { ExtractRouteParams } from 'react-router'

type MakeGetCardLinkParams = {
	generatePath: (path: string, params: ExtractRouteParams<string>) => string
}

type GetCardLinkParams = {
	path: string
	templateId: string
	sectionName: string
}

export const makeGetCardLink =
	({ generatePath }: MakeGetCardLinkParams) =>
	({ path, templateId, sectionName }: GetCardLinkParams): string | null => {
		if (path) return generatePath(path, { lng: 'en', templateId, section: sectionName })
		return null
	}
