import { Fragment, useState } from 'react'
import { DialogContent, styled, Divider, Button, SettingsIcon } from '@persuit/ui-components'

import { DialogWizard } from '../dialog-wizard'
import DialogWizardEnableStepIf from '../dialog-wizard-enable-step-if'
import DialogWizardContent from '../dialog-wizard-content'
import { defaultSteps } from '../dialog-wizard-steps'
import { getWizardProps } from './utils'
import {
	OptionReopenRequestForFirmConfirm,
	OptionReopenRequestForFirmStart,
} from './option-re-open-request-for-firm'
import {
	OptionCloseRequestForFirmConfirm,
	OptionCloseRequestForFirmStart,
} from './option-close-request-for-firm'
import {
	OptionEliminateFirmConfirm,
	OptionEliminateFirmStart,
	OptionEliminateFirmOptions,
} from './option-eliminate-firm'
import { OptionUneliminateFirmConfirm, OptionUneliminateFirmStart } from './option-uneliminate-firm'
import WizardHeader from './wizard-header'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const StyledSettingsIcon = styled(SettingsIcon)`
	&.MuiSvgIcon-root {
		fill: ${(props) => props.theme.palette.primary.main};
	}
`

const WIZARD_STEPS = {
	START: 'start',
	OPTION_REOPEN_REQUEST_FOR_FIRM: 'reopenRequestForFirm',
	OPTION_REOPEN_REQUEST_FOR_FIRM_CONFIRM: 'reopenRequestForFirmConfirm',
	OPTION_CLOSE_REQUEST_FOR_FIRM: 'closeRequestForFirm',
	OPTION_CLOSE_REQUEST_FOR_FIRM_CONFIRM: 'closeRequestForFirmConfirm',
	OPTION_ELIMINATE_FIRM: 'eliminateFirm',
	OPTION_ELIMINATE_FIRM_OPTIONS: 'eliminateFirmOptions',
	OPTION_ELIMINATE_FIRM_CONFIRM: 'eliminateFirmConfirm',
	OPTION_UNELIMINATE_FIRM_CONFIRM: 'uneliminateFirmConfirm',
}

const defaultWizardState = {
	[WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS]: { sendFirmEmailsSelected: null },
}

export function ManageFirmWizard({
	requestId,
	firmOrgId,
	isNamwolfMember,
	firmName,
	currency,
	totalPriceValue,
	totalPriceModel,
	isRfpReopenedForFirm,
	hasFirmSubmittedAnyProposal,
	isFirmProposalUnderRevision,
	disableReopenRfpOption,
	disableMarkUnsuccessfulOption,
	onClose,
	isFirmEliminated,
	startingStep = WIZARD_STEPS.START,
}) {
	const [wizardStep, setWizardStep] = useState(startingStep ? startingStep : WIZARD_STEPS.START)
	const [wizardState, setWizardState] = useState(defaultWizardState)
	const handleClose = () => onClose()

	const setWizardStateForStep = (step) => (newValue) => {
		return setWizardState({
			...wizardState,
			[step]: newValue,
		})
	}

	const resetWizardStateForStep = (step) => {
		return setWizardState({
			...wizardState,
			[step]: defaultWizardState[step],
		})
	}

	// Don't show back button when an alternative starting step was provided
	const makePrevStep = (startingStep, prevFunction) => {
		return startingStep === WIZARD_STEPS.START ? prevFunction : null
	}

	return (
		<DialogWizard wizardName="Manage Firm">
			<DialogContent>
				<WizardHeader
					firmName={firmName}
					currency={currency}
					isNamwolfMember={isNamwolfMember}
					totalPriceValue={totalPriceValue}
					totalPriceModel={totalPriceModel}
					isFirmProposalUnderRevision={isFirmProposalUnderRevision}
					hasFirmSubmittedAnyProposal={hasFirmSubmittedAnyProposal}
				/>
			</DialogContent>
			{/* Step 1 - Start */}
			<DialogWizardEnableStepIf condition={wizardStep === WIZARD_STEPS.START}>
				<DialogWizardContent steps={defaultSteps} activeStep={'START'} onCancel={handleClose}>
					<Divider />
					<OptionReopenRequestForFirmStart
						onNext={() => setWizardStep(WIZARD_STEPS.OPTION_REOPEN_REQUEST_FOR_FIRM_CONFIRM)}
						disabled={disableReopenRfpOption}
						hide={isRfpReopenedForFirm}
					/>
					<OptionCloseRequestForFirmStart
						onNext={() => setWizardStep(WIZARD_STEPS.OPTION_CLOSE_REQUEST_FOR_FIRM_CONFIRM)}
						hide={!isRfpReopenedForFirm}
					/>
					{!isFirmEliminated && (
						<OptionEliminateFirmStart
							onNext={() => setWizardStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS)}
							disabled={disableMarkUnsuccessfulOption}
						/>
					)}
					{isFirmEliminated === true && (
						<OptionUneliminateFirmStart
							onNext={() => setWizardStep(WIZARD_STEPS.OPTION_UNELIMINATE_FIRM_CONFIRM)}
						/>
					)}
				</DialogWizardContent>
			</DialogWizardEnableStepIf>

			{/* Step 2 - Eliminate Firm */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS}
			>
				<OptionEliminateFirmOptions
					onChange={setWizardStateForStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS)}
					onPrev={makePrevStep(startingStep, () => {
						resetWizardStateForStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS)
						setWizardStep(WIZARD_STEPS.START)
					})}
					onNext={() => setWizardStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_CONFIRM)}
					onCancel={handleClose}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Confirmation */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_REOPEN_REQUEST_FOR_FIRM_CONFIRM}
			>
				<OptionReopenRequestForFirmConfirm
					onPrev={() => setWizardStep(WIZARD_STEPS.START)}
					onCancel={handleClose}
					onDone={handleClose}
					firmName={firmName}
					firmOrgId={firmOrgId}
					requestId={requestId}
				/>
			</DialogWizardEnableStepIf>
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_CLOSE_REQUEST_FOR_FIRM_CONFIRM}
			>
				<OptionCloseRequestForFirmConfirm
					onPrev={() => setWizardStep(WIZARD_STEPS.START)}
					onCancel={handleClose}
					onDone={handleClose}
					firmName={firmName}
					firmOrgId={firmOrgId}
					requestId={requestId}
				/>
			</DialogWizardEnableStepIf>
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_ELIMINATE_FIRM_CONFIRM}
			>
				<OptionEliminateFirmConfirm
					onPrev={() => {
						resetWizardStateForStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS)
						setWizardStep(WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS)
					}}
					onCancel={handleClose}
					onDone={handleClose}
					value={wizardState[WIZARD_STEPS.OPTION_ELIMINATE_FIRM_OPTIONS]}
					firmName={firmName}
					firmOrgId={firmOrgId}
					requestId={requestId}
				/>
			</DialogWizardEnableStepIf>

			{/* Step 3 - Uneliminate firm */}
			<DialogWizardEnableStepIf
				condition={wizardStep === WIZARD_STEPS.OPTION_UNELIMINATE_FIRM_CONFIRM}
			>
				<OptionUneliminateFirmConfirm
					onPrev={() => setWizardStep(WIZARD_STEPS.START)}
					onDone={handleClose}
					onCancel={handleClose}
					firmId={firmOrgId}
					firmName={firmName}
					requestId={requestId}
					//fetchResponses={fetchResponses}
				/>
			</DialogWizardEnableStepIf>
		</DialogWizard>
	)
}

const WizardWrapper = ({ rfp, firmOrg, getWizardProps }) => {
	const [isDialogOpen, setDialogState] = useState(false)
	const openDialog = () => setDialogState(true)
	const closeDialog = () => setDialogState(false)
	const wizardProps = getWizardProps(rfp, firmOrg)
	const { toggles } = useFeatureToggles()
	const ftEnablePanelManagement = toggles['dev-8710.panel-management']

	return (
		<Fragment>
			<Button
				size={ftEnablePanelManagement ? 'small' : 'medium'}
				variant="outlined"
				startIcon={<StyledSettingsIcon />}
				onClick={openDialog}
				data-testid="manage-firm-button"
			>
				Manage firm
			</Button>
			{isDialogOpen && <ManageFirmWizard {...wizardProps} onClose={closeDialog} />}
		</Fragment>
	)
}

WizardWrapper.defaultProps = {
	getWizardProps,
}

export default WizardWrapper
