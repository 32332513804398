import { Box, BoxProps, Typography, Spacer, TypographyProps } from '@persuit/ui-components'
import { RfpDetailQuestion } from '../../../question'
import { DiversityDefinitionType } from '../../../../../types'
import { constructDiversityDefinition } from '../../util'

type DiversityDefinitionsProps = {
	definitions: Array<DiversityDefinitionType>
	isFirmView?: boolean
	questionTitleProps?: TypographyProps
} & BoxProps

export const RfpDetailDiversity = ({
	definitions,
	isFirmView = false,
	questionTitleProps,
	...rest
}: DiversityDefinitionsProps) => {
	return (
		<Box {...rest}>
			<Typography variant="body1Semibold" data-testid="rfp-detail-diversity">
				{constructDiversityDefinition(definitions, isFirmView)}
			</Typography>

			{!isFirmView && (
				<Typography variant="body2" sx={{ mt: 2 }}>
					Firms will be asked to fill out the following pre-defined questions (If these questions
					don't suit your diversity requirements, please ask them in the Questionnaire section):
				</Typography>
			)}

			<Spacer space={4} shape="row" />

			<Box display="flex" flexDirection="column" gap={4}>
				<RfpDetailQuestion
					index={0}
					prefix="D"
					questionTitle="Is the lead lawyer (or in the context of litigation, the first or second chair) proposed for this matter Diverse?"
					rfpQuestionType="choice"
					questionTitleProps={questionTitleProps}
				/>

				<RfpDetailQuestion
					index={1}
					prefix="D"
					questionTitle="What percentage of the scope of services will be completed by Diverse lawyers?"
					rfpQuestionType="percentage"
					questionTitleProps={questionTitleProps}
				/>

				<RfpDetailQuestion
					index={2}
					prefix="D"
					questionTitle="What percentage of business generation credit (for example origination credit) will be awarded to Diverse lawyers?"
					rfpQuestionType="percentage"
					questionTitleProps={questionTitleProps}
				/>

				<RfpDetailQuestion
					index={3}
					prefix="D"
					questionTitle="Diversity Certification / Membership"
					rfpQuestionType="certification"
					questionTitleProps={questionTitleProps}
				/>
			</Box>
		</Box>
	)
}
