import * as React from 'react'
import { useSortable, UseSortableHocReturn } from '../sortable'
import { CSS } from '@dnd-kit/utilities'
import { styled } from '../../../theme'
import { Box, BoxProps } from '../../box'

const StyledListItem = styled('li')`
	list-style: none;
	padding-left: 0;
	position: relative;
`

export type SortableListItemProps = {
	/** Required to build sortable list, id & key should be always the same */
	id: string
	/** Optional container props to customise list item's Box container  */
	containerProps?: BoxProps
	/** When passing a React.ReactNode, the whole list item will be automatically draggable.
	 * If you utilize render props pattern, you will have access to all of useSortable props for custom behaviour.
	 */
	children: React.ReactNode | ((props: UseSortableHocReturn) => React.ReactNode)
	disabled?: boolean
} & Omit<JSX.IntrinsicElements['li'], 'children'>

export const SortableListItem = ({ disabled, children, ...props }: SortableListItemProps) => {
	const sortableProps = useSortable({
		id: props.id,
		disabled,
	})

	return (
		<ListItem {...props} sortableProps={sortableProps} ref={sortableProps.setNodeRef}>
			{children}
		</ListItem>
	)
}

SortableListItem.displayName = 'SortableListItem'

type ListItemProps = SortableListItemProps & {
	sortableProps: UseSortableHocReturn
}

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
	({ children, sortableProps, containerProps, style: styleProp, ...props }, ref) => {
		const { transform, transition, isOver, attributes, isDragging, listeners } = sortableProps
		const style: React.CSSProperties = {
			transform: CSS.Translate.toString(transform),
			transition,
			cursor: isOver ? 'grab' : 'initial',
		}

		return (
			<StyledListItem
				{...props}
				ref={ref}
				style={{ zIndex: isDragging ? 10 : 'inherit', ...styleProp }}
			>
				{typeof children === 'function' ? (
					<Box style={style} {...containerProps}>
						{children(sortableProps)}
					</Box>
				) : (
					<Box {...attributes} {...listeners} style={style} {...containerProps}>
						{children}
					</Box>
				)}
			</StyledListItem>
		)
	},
)

ListItem.displayName = 'ListItem'
