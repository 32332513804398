import React, { memo } from 'react'
import { useUser } from '@persuit/ui-auth'
import responseStates from '../../../../common/data/rfp-proposal-states'
import Export from './export'
import { hasActiveProposals, allFirmsEliminated } from '../utils'
import {
	Box,
	styled,
	AIIcon,
	Button,
	useTheme,
	ScreenOnly,
	Divider,
	FullscreenExitIcon,
	FullscreenIcon,
	IconButton,
	Spacer,
} from '@persuit/ui-components'
import { useStore, useActions } from '../store'
import { SortProposalsDropdown } from './sort-proposals-dropdown'
import { ShowProposalsDropdown } from './show-proposals-dropdown'
import { PinProposalsDropdown } from './pin-proposals-dropdown'

const StyledActionContainer = styled(Box)`
	display: flex;
	margin-right: 2rem;
`

const StyledButtonContainerBigScreen = styled(Box)`
	display: none;
	@media (min-width: 1091px) {
		display: flex;
		gap: 8px;
	}
`

const StyledButtonContainerSmallScreen = styled(Box)`
	display: none;
	@media (min-width: 361px) and (max-width: 1090px) {
		display: flex;
		margin-bottom: 6px;
	}
`

const ActionBar = ({ onToggleFullScreen, isDraftProposal }) => {
	const { user } = useUser()
	const theme = useTheme()
	const hasBusinessIntelAccess = user?.org?.settings?.isProposalAnalyserEnabled

	const {
		isFirm,
		showEliminatedProposals,
		showRevisedProposals,
		showWithdrawnProposals,
		requestId,
		request,
		proposals,
		fullscreen,
		showBusinessIntel,
	} = useStore((state) => ({
		showBusinessIntel: state.showBusinessIntel,
		proposals: state.responses,
		requestId: state.request._id,
		request: state.request,
		isFirm: state.isFirm,
		showEliminatedProposals: state.showEliminated,
		showRevisedProposals: state.showRevised,
		showWithdrawnProposals: state.showWithdrawn,
		fullscreen: state.fullscreen,
	}))

	const {
		toggleFullScreen,
		toggleShowBusinessIntel,
		clearPinnedProposals,
		getInitialSort,
		setSort,
		setShowEliminated,
		clearShowWithdrawn,
		clearShowRevised,
		setShowUnpinned,
	} = useActions()

	const handleToggleFullScreen = () => {
		onToggleFullScreen()
		toggleFullScreen()
	}

	const hasWithdrawnProposals = proposals.some(({ status }) => status === responseStates.WITHDRAWN)

	const hasRevisedProposals = proposals.some(({ status }) => status === responseStates.REVISED)

	const hasEliminatedProposals = proposals.some(({ isEliminated }) => isEliminated)

	const hasAllFirmsEliminated = allFirmsEliminated(request)

	const hasBusinessIntel = hasBusinessIntelAccess //<-- controlled by feature toggle

	const showAnalyzeProposalsButton =
		hasBusinessIntel && hasActiveProposals(proposals) && !hasAllFirmsEliminated

	// Collect number of toggles.
	// Over a certain amount of toggles enables a media query to us icon buttons.
	// Similar to how the CAB collapses buttons.
	// This technique is used because tertiary buttons do not allow control of label text.
	const togglesCount = [hasWithdrawnProposals, hasRevisedProposals, hasEliminatedProposals].filter(
		(toggle) => toggle,
	).length
	const toggleLimit = 3

	const onResetAll = () => {
		//reset pinned proposals
		clearPinnedProposals()

		//reset sorting
		setSort(getInitialSort())

		//reset show proposal options
		setShowEliminated()
		clearShowWithdrawn()
		clearShowRevised()
		setShowUnpinned()
	}

	const expandBtnLabel = `${fullscreen ? 'Collapse' : 'Expand'} comparison table to full screen`

	return (
		<ScreenOnly>
			<Box display="flex" flexDirection="column">
				<Box display="grid" gridTemplateColumns="repeat(3, 30%) 7%" mt={2} mb={4} gap={'1%'}>
					{isFirm ? null : <PinProposalsDropdown />}
					{isFirm ? null : <SortProposalsDropdown />}
					{isDraftProposal ? null : (
						<ShowProposalsDropdown
							hasWithdrawnProposals={hasWithdrawnProposals}
							hasRevisedProposals={hasRevisedProposals}
							hasEliminatedProposals={hasEliminatedProposals}
						/>
					)}
					{isFirm ? null : (
						<Button variant="text" onClick={onResetAll}>
							Reset All
						</Button>
					)}
				</Box>
				<Divider />
				<Box
					mt={4}
					mb={1}
					display="flex"
					justifyContent={showAnalyzeProposalsButton ? 'space-between' : 'flex-end'}
				>
					{showAnalyzeProposalsButton && (
						<StyledActionContainer>
							<Button
								variant="outlined"
								startIcon={<AIIcon />}
								onClick={toggleShowBusinessIntel}
								color="secondary"
								sx={{ backgroundColor: showBusinessIntel ? theme.palette.teal50 : 'inherit' }}
								data-trackid="button-analyze-ai"
							>
								Analyze with AI
							</Button>
						</StyledActionContainer>
					)}

					<Box>
						{!isFirm && togglesCount < toggleLimit && (
							<Box display="flex" gap={1}>
								<Export
									requestId={requestId}
									includeWithdrawnProposals={showWithdrawnProposals}
									includeEliminatedProposals={showEliminatedProposals}
									includeRevisedProposals={showRevisedProposals}
								/>
								<Box>
									<Button
										variant="outlined"
										onClick={handleToggleFullScreen}
										startIcon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
										aria-label={expandBtnLabel}
									>
										{fullscreen ? 'Collapse' : 'Expand'}
									</Button>
								</Box>
							</Box>
						)}
						{!isFirm && togglesCount >= toggleLimit && (
							<React.Fragment>
								<StyledButtonContainerBigScreen>
									<Export
										requestId={requestId}
										includeWithdrawnProposals={showWithdrawnProposals}
										includeEliminatedProposals={showEliminatedProposals}
										includeRevisedProposals={showRevisedProposals}
									/>
									<Button
										variant="outlined"
										onClick={handleToggleFullScreen}
										startIcon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
										aria-label={expandBtnLabel}
									>
										{fullscreen ? 'Collapse' : 'Expand'}
									</Button>
								</StyledButtonContainerBigScreen>

								<StyledButtonContainerSmallScreen>
									<Export
										requestId={requestId}
										includeWithdrawnProposals={showWithdrawnProposals}
										includeEliminatedProposals={showEliminatedProposals}
										includeRevisedProposals={showRevisedProposals}
										type="icon"
									/>
									<IconButton
										onClick={handleToggleFullScreen}
										color="primary"
										aria-label={expandBtnLabel}
									>
										{fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
									</IconButton>

									<Spacer direction="row" space={2} />
								</StyledButtonContainerSmallScreen>
							</React.Fragment>
						)}
					</Box>
				</Box>
			</Box>
		</ScreenOnly>
	)
}

export default memo(ActionBar)
