import getColor from '../../utils/star-colors'
import {
	FormControl,
	IconButton,
	RadioGroup,
	Radio,
	RemoveCircleIcon,
	RemoveCircleOutlineIcon,
	StarIcon,
	StarBorderIcon,
	Box,
} from '@persuit/ui-components'

type ScoreInputProps = {
	maxScore?: number
	// Redux props
	input?: {
		value: number
		onChange: (score: null | number) => void
	}
}

export const ScoreInput = ({ maxScore = 5, input }: ScoreInputProps) => {
	const reduxValue = input?.value

	function onChange(score: string | null) {
		input?.onChange(!score ? null : Number(score))
	}

	function handleClick(score: string | null) {
		onChange(score)
	}

	const scoreArray = [...new Array(maxScore).fill(0)]

	function renderRadioIcon(checked: boolean) {
		return checked ? (
			<StarIcon
				sx={{
					fill: reduxValue ? getColor(reduxValue) : 'grey',
				}}
			/>
		) : (
			<StarBorderIcon
				sx={{
					fill: reduxValue ? getColor(reduxValue) : 'grey',
				}}
			/>
		)
	}

	return (
		<Box
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Box display="inline-block">
				<IconButton
					sx={{
						fill: 'grey',
						marginRight: '8px',
						width: '10px',
						height: '10px',
					}}
					onClick={() => handleClick(null)}
					aria-label="Remove stars"
					size="small"
				>
					{!reduxValue || reduxValue === null ? <RemoveCircleIcon /> : <RemoveCircleOutlineIcon />}
				</IconButton>
			</Box>

			<FormControl>
				<RadioGroup
					aria-label="Score"
					sx={{ flexDirection: 'row' }}
					onChange={(e) => {
						onChange(e.currentTarget.value)
					}}
					defaultValue={reduxValue}
				>
					{scoreArray.map((_, index) => {
						const radioIcon = renderRadioIcon(Number(reduxValue ?? -1) > index)
						const rating = index + 1
						return (
							<Radio
								key={index}
								icon={radioIcon}
								checkedIcon={radioIcon}
								sx={{
									mx: '-10px',
									my: -2,
								}}
								value={index + 1}
								inputProps={{
									'aria-label': `${rating} star`,
								}}
							/>
						)
					})}
				</RadioGroup>
			</FormControl>
		</Box>
	)
}
