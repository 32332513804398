import { ClientTotalPriceModel } from '../../types'

type KeyInClientTotalPriceModel = { [key in ClientTotalPriceModel]?: string }

export const pricingModels: {
	form: KeyInClientTotalPriceModel
	item: KeyInClientTotalPriceModel
	'item-dev-3327': KeyInClientTotalPriceModel
	'subItem-dev-3327': KeyInClientTotalPriceModel
	subItem: KeyInClientTotalPriceModel
	rfprSubItem: KeyInClientTotalPriceModel
	firmCard: KeyInClientTotalPriceModel
	firmItem: KeyInClientTotalPriceModel
	firmSubItem: KeyInClientTotalPriceModel
	card: KeyInClientTotalPriceModel
} = {
	// total price
	form: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		hourlyRate: 'Hourly Rate',
		hourlyRates: 'Hourly Rates',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		afa: 'Fee Arrangements',
		notApplicable: 'Not Applicable',
	},
	item: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		collaredFee: 'Collared Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		hourlyRates: 'Rate Card',
		hourlyRate: 'Hourly Rate',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		included: 'No charge',
		afa: 'Fee Arrangements',
		rates: 'Rates',
	},
	'item-dev-3327': {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		collaredFee: 'Collared Fee',
		contingencyFee: 'Contingency Fee',
		contingencyPercentage: 'Contingency Fee (%)',
		discountPercentage: 'Discount (%)',
		successFee: 'Success Fee',
		hourlyRates: 'Rate Card',
		hourlyRate: 'Hourly Rate',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		included: 'No charge',
		afa: 'Fee Arrangements',
		rates: 'Rates',
	},
	subItem: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		includedInItemTotal: 'No charge',
		afa: 'Fee Arrangements',
	},
	'subItem-dev-3327': {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		discountPercentage: 'Discount (%)',
		successFee: 'Success Fee',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		includedInItemTotal: 'No charge',
		afa: 'Fee Arrangements',
	},
	rfprSubItem: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		collaredFee: 'Collared Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		hourlyRates: 'Rate Card',
		hourlyRate: 'Hourly Rate',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		included: 'No charge',
		afa: 'Fee Arrangements',
		rates: 'Rates',
	},
	firmCard: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		collaredFee: 'Collared Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		hourlyRates: 'Rate Card',
		hourlyRate: 'Hourly Rate',
		other: 'Other (see summary)',
		estimate: 'Budgeting Estimate',
		afa: 'Fee Arrangements',
		notApplicable: 'Not Applicable',
		included: 'No charge',
	},
	firmItem: {
		none: 'No Preference',
		notApplicable: 'Not Applicable',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		collaredFee: 'Collared Fee',
		contingencyFee: 'Contingency Fee',
		contingencyPercentage: 'Contingency Fee (%)',
		discountPercentage: 'Discount (%)',
		successFee: 'Success Fee',
		hourlyRates: 'Rate Card',
		hourlyRate: 'Hourly Rate',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		included: 'No charge',
		afa: 'Fee Arrangements',
		rates: 'Rates',
		includedInItemTotal: 'No charge',
	},
	firmSubItem: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		discountPercentage: 'Discount (%)',
		successFee: 'Success Fee',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		includedInItemTotal: 'No charge',
		afa: 'Fee Arrangements',
	},
	card: {
		none: 'No Preference',
		fixedFee: 'Fixed Fee',
		cappedFee: 'Capped Fee',
		contingencyFee: 'Contingency Fee',
		successFee: 'Success Fee',
		hourlyRate: 'Hourly Rate',
		hourlyRates: 'Hourly Rates',
		other: 'Other (see instructions)',
		estimate: 'Budgeting Estimate',
		afa: 'Alternative Fee Arrangements',
		notApplicable: 'Not Applicable',
	},
}
