import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import injectSheet from '../../../injectSheet'
import Spacer from '../../layout/spacer.jsx'
import Text from '../../text.jsx'
import { Container } from '../../grid'
import allowTypes from '../../../../common/data/allow-types'
import RfpInvitedFirmItem from './rfp-invited-firm-item'
import { RfpInvitedFirmCard } from './rfp-invited-firm-card'
import { graphql, useQuery } from '@persuit/ui-graphql'
import {
	EmailIcon,
	LoadingSpinner,
	AddIcon,
	Button,
	Box,
	List,
	ListItem,
	UnfoldMoreIcon,
	UnfoldLessIcon,
} from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { FirmRecommenderLink } from '@client/pages/panel-management/edit-list/edit-list-add-firms/add-firms-data-grid/custom-subcomponents/grid-custom-footer'

const GET_SESSION_QUERY = graphql(`
	query InvitedFirmList {
		getSessionInfo {
			groups {
				_id
				settings {
					firmLists {
						_id
						name
						isPanel
						approved
						firms {
							org {
								_id
							}
						}
					}
				}
			}
		}
	}
`)

const getInvitedFirmEmails = (rfpOrg, allows) => {
	const firmEmails = allows
		.filter(({ type }) => type !== allowTypes.REVOKED)
		.filter(({ org }) => org !== rfpOrg)
		.map((allow) => allow.item?.email)
		.filter(Boolean)
	return firmEmails
}

const InvitedFirmList = ({
	classes,
	handleInviteFirm,
	showInviteDialog,
	invites,
	rfp,
	rfpStatus,
	sentTo,
	t,
	...props
}) => {
	const { data, loading } = useQuery(GET_SESSION_QUERY, {
		fetchPolicy: 'cache-and-network',
	})
	const { toggles } = useFeatureToggles()
	const ftEnablePanelManagement = toggles['dev-8710.panel-management']

	const sortedInvites = invites.sort((a, b) => {
		if (a.item.name > b.item.name) {
			return 1
		}
		if (a.item.name < b.item.name) {
			return -1
		}
		return 0
	})

	const allCardsCollapsedState = Object.fromEntries(
		sortedInvites.map((invite) => [`${invite.item._id}`, false]),
	)

	const allCardsExpandedState = Object.fromEntries(
		sortedInvites.map((invite) => [`${invite.item._id}`, true]),
	)

	const [expanded, setExpanded] = useState(allCardsCollapsedState)

	const handleExpand = (panel) => {
		setExpanded((prev) => ({ ...prev, [panel]: expanded[panel] ? false : true }))
	}

	const handleExpandAll = () => {
		setExpanded(allCardsExpandedState)
	}

	const handleCollapseAll = () => {
		setExpanded(allCardsCollapsedState)
	}

	if (!data || loading) {
		return <LoadingSpinner />
	}

	const groups = data?.getSessionInfo?.groups ?? []
	const firmLists = groups.reduce((acc, group) => {
		const lists = group?.settings?.firmLists ?? []
		return [...lists, ...acc]
	}, [])

	//Generate sets of panel/preferred and approved firms
	const panelOrPreferredFirms = new Set()
	const approvedFirms = new Set()

	firmLists.forEach((firmList) => {
		const isApproved = !!firmList.approved
		const isPanel = !!firmList.isPanel
		if (isApproved || isPanel) {
			firmList.firms.filter(Boolean).forEach(({ org }) => {
				if (!org) {
					return
				}
				if (isApproved) {
					approvedFirms.add(org?._id)
				}
				if (isPanel) {
					panelOrPreferredFirms.add(org?._id)
				}
			})
		}
	})

	return (
		<Container className={classes.container} {...props}>
			<>
				{!ftEnablePanelManagement && (
					<Box displayPrint="none">
						<Button
							variant="outlined"
							color="primary"
							onClick={handleInviteFirm}
							startIcon={<AddIcon />}
							fullWidth={true}
						>
							{t('rfp.invites.inviteMoreButton')}
						</Button>
					</Box>
				)}
				{ftEnablePanelManagement && (
					<Box display="flex" justifyContent="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<Box mb={1}>
								<Button variant="primary" onClick={showInviteDialog} startIcon={<AddIcon />}>
									Invite
								</Button>
							</Box>
							<FirmRecommenderLink
								firmRecommenderIsEnabled={true}
								message="Want to discover more firms to invite?"
							/>
						</Box>
					</Box>
				)}
			</>

			<Spacer direction="column" space={3} />

			{invites.length < 1 && <Text type="body1">{t('rfp.invites.noFirmsInvitedMessage')}</Text>}

			{ftEnablePanelManagement ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Box sx={{ mr: 'auto' }}>
							<Button
								startIcon={<EmailIcon />}
								variant="outlined"
								onClick={() => {
									const invitedFirmEmails = getInvitedFirmEmails(rfp?.org?._id ?? rfp?.org, sentTo)
									const bccString = invitedFirmEmails.join('%3B%20') // HTML encoded semi-colon(%3B) and space(%20) === %3B%20
									window.open(`mailto:?bcc=${bccString}`, '_blank')
								}}
								disabled={invites.length === 0}
							>
								Email firms{invites.length > 0 ? ` (${invites.length})` : ''}
							</Button>
						</Box>
						<Box sx={{ display: 'flex', gap: 3 }}>
							<Button
								size="small"
								variant="text"
								startIcon={<UnfoldLessIcon />}
								onClick={() => handleCollapseAll()}
								disabled={Object.values(expanded).every((value) => value === false)}
							>
								Collapse All
							</Button>
							<Button
								size="small"
								variant="text"
								startIcon={<UnfoldMoreIcon />}
								onClick={() => handleExpandAll()}
								disabled={Object.values(expanded).every((value) => value === true)}
							>
								Expand All
							</Button>
						</Box>
					</Box>
					<List
						aria-label="Firms list"
						sx={{ display: 'flex', flexDirection: 'column', p: 0, m: 0, gap: 3 }}
					>
						{sortedInvites.map((invite, index) => {
							return (
								<ListItem key={index} sx={{ p: 0, m: 0 }}>
									<RfpInvitedFirmCard
										expanded={expanded[invite.item._id]}
										handleExpand={handleExpand}
										rfp={rfp}
										invite={invite}
										isPanelOrPreferredFirm={panelOrPreferredFirms.has(invite.item._id)}
										isApprovedFirm={approvedFirms.has(invite.item._id)}
										{...invite}
									/>
								</ListItem>
							)
						})}
					</List>
				</Box>
			) : (
				<>
					{sortedInvites.map((invite, index) => {
						// filtering out invites relevant to an org
						const sentToList = sentTo.filter(
							(obj) =>
								obj.item.org._id.toString() === invite.item._id.toString() &&
								obj.type !== allowTypes.REVOKED,
						)

						return (
							<RfpInvitedFirmItem
								key={index}
								sentTo={sentToList}
								rfp={rfp}
								invite={invite}
								isApprovedFirm={approvedFirms.has(invite.item._id)}
								isPanelOrPreferredFirm={panelOrPreferredFirms.has(invite.item._id)}
								{...invite}
							/>
						)
					})}
				</>
			)}

			<Spacer direction="column" space={2} />
			{!ftEnablePanelManagement && (
				<Button
					variant="outlined"
					fullWidth={true}
					color="primary"
					onClick={handleInviteFirm}
					startIcon={<AddIcon />}
					sx={{
						displayPrint: 'none',
					}}
				>
					{t('rfp.invites.inviteMoreButton')}
				</Button>
			)}
		</Container>
	)
}
InvitedFirmList.styles = {
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	expand: {
		flex: 1,
	},
	container: {
		backgroundColor: 'white',
		padding: '1em',
	},
	accessOption: {
		fontWeight: 400,
	},
}

const TranslatedComponent = withTranslation()(InvitedFirmList)
export default injectSheet(InvitedFirmList.styles)(withTranslation()(TranslatedComponent))
