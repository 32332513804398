import { Typography, Box } from '@persuit/ui-components'

export const NewFeatureCornerBanner = () => {
	return (
		<Box position="relative">
			<Box
				position="absolute"
				top="5px"
				right="-32px"
				sx={{ transform: 'rotate(45deg)', backgroundColor: 'primary.lighterHue' }}
			>
				<Typography
					py={1}
					px={6}
					color="primary.main"
					sx={{ transform: 'rotate(-45deg)' }}
					position="relative"
					align="center"
					fontSize={12}
					fontWeight={700}
				>
					NEW
				</Typography>
			</Box>
		</Box>
	)
}
