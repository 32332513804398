import { SvgIcon, SvgIconProps } from '../components/svg'

export const FirmFinderIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 20V4C3 3.44772 3.44771 3 4 3C4.55228 3 5 3.44772 5 4L5 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20Z"
				fill="white"
			/>
			<circle cx="8" cy="16" r="1" fill="white" />
			<circle cx="9.5" cy="9.5" r="1.5" fill="white" />
			<circle cx="14.5" cy="13.5" r="1.5" fill="white" />
			<circle cx="17" cy="6" r="2" fill="white" />
		</SvgIcon>
	)
}
