// @ts-strict-ignore
import moment from 'moment'
import rfpStates from '../../../common/data/rfp-states'

/**
 * @returns The active 0 based index for the timeline stepper
 */
export const getActiveTimelineDate = (
	requestPublishedAt,
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	requestStatus,
): number => {
	if (!requestPublishedAt || !initialProposalsDueBy || !auctionStart || !auctionEnd) {
		console.error('There seems to be error with the auction dates. Please check!')
		return -1
	}

	// If the request is CLOSED manually
	if ([rfpStates.CLOSED, rfpStates.COMPLETED].includes(requestStatus ?? '')) {
		return 3
	}

	const now = moment()

	return moment(now).isBetween(requestPublishedAt, initialProposalsDueBy, null, '[)')
		? 0
		: moment(now).isSameOrAfter(initialProposalsDueBy) && moment(now).isBefore(auctionStart)
		? 1
		: moment(now).isSameOrAfter(auctionStart) && moment(now).isBefore(auctionEnd)
		? 2
		: 3
}
