import { Fragment } from 'react'
import { Typography, PercentageText, styled } from '@persuit/ui-components'
import { getOrNull } from '../../../utils/lenses'
import RfprItem from '../../pricing-card-detail-view/components/rfpr-item'
import { areValuesEqual } from '../../rfp-request-proposal-revision/suggestion-change-detection'
import useTranslation from '@client/custom-hooks/translation-hook'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 50%;
`
const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 50% !important;
	gap: 8px;
`

const StyledResponsePercentage = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-spacing: 10px;
`

// Dedicate space for aligning fee, pricing model, and hours
const StyledMiniCellFee = styled('div')`
	width: 194px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`
const StyledMiniCellModel = styled('div')`
	width: 285px;
`

const ResponsePercentage = ({ percentage, rfpr, responsePricingModel }) => {
	const { t } = useTranslation()
	const rfprPercentage = getOrNull('rfprPercentage', rfpr)
	const isPercentageSame = areValuesEqual(percentage, rfprPercentage)

	return (
		<Fragment>
			<StyledResponsePercentage>
				<StyledItemLeft></StyledItemLeft>
				<StyledItemRight>
					<StyledMiniCellFee>
						<Typography strikeThrough={!isPercentageSame} data-testid="item-subtotal-rate">
							<PercentageText value={percentage} />
						</Typography>
					</StyledMiniCellFee>
					<StyledMiniCellModel>
						<Typography data-testid="item-subtotal-model">
							{t(`feeBreakdown.pricingModels.item.${responsePricingModel}`)}
						</Typography>
					</StyledMiniCellModel>
				</StyledItemRight>
			</StyledResponsePercentage>
			{!isPercentageSame && (
				<RfprItem
					rfprPercentage={rfprPercentage}
					originalPercentage={percentage}
					originalPricingModel={responsePricingModel}
				/>
			)}
		</Fragment>
	)
}

export default ResponsePercentage
