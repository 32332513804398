import { size } from 'lodash/fp'
import isEmpty from 'lodash/fp/isEmpty'
import moment from 'moment'
import { Fragment } from 'react'
import rfpProposalStates from '../../../../common/data/rfp-proposal-states'
import { constructDiversityDefinition } from '../../../containers/settings/settings-company-profile/client-company-profile-form/diversity-definitions-mapping'
import { getProposalPublishedAtDateFromActivityLog } from '../../../utils/get-proposal-published-date-from-activity-log'
import { getOrEmptyArray, getOrFalse, getOrNull } from '../../../utils/lenses'
import { Box, RichTextView, SROnlyH1, Typography, FileDownloadList } from '@persuit/ui-components'
import Spacer from '../../layout/spacer.jsx'
import NamwolfLogo from '../../namwolf/namwolf-logo'
import ClientFeedbackDisplay from '../../proposal-feedback/display'
import { DiversityAnswersAndCertifications } from './diversity-answers'
import EngagementLetters from './engagement-letters'
import { RfpProposalDetailQuestionnaireAnswers } from './questionnaire-answers'
import { RevisionCommentsList } from './revision-comments-list'
import TotalPrice from './total-price'
import { ProposalDeliverables } from './proposal-deliverables'
import { groupRevisionCommentDeliverables } from '../../../utils/revision-comments'
import { ComparisonValueInfo } from '../comparison-value'
import { HorizontalDivider } from './horizontal-divider'
import { RfpProposalRateReview } from './rate-review'
import { useUAMEnabled } from '@persuit/ui-auth'
import { RevisionComment, RfpProposal } from '@persuit/ui-graphql/generated'
import { PricingInfo } from './pricing-info'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
// If the proposal hasRevisionRequest==true the most recent should be the rfpr. Check clientId just in case
const getRfpr = (proposal: RfpProposal, clientOrgId: string) => {
	const revisionComments = getOrEmptyArray('revisionComments', proposal)

	if (!proposal.hasRevisionRequest || revisionComments.length === 0) {
		return null
	}

	const latestComment = revisionComments[0]
	if (latestComment?.org?._id?.toString() !== clientOrgId.toString()) {
		return null
	}

	return latestComment
}

type RfpProposalDetailProps = {
	rfp: any
	proposal: any
	fetchResponses?: () => void
}

const RfpProposalDetail = ({ fetchResponses, proposal, rfp }: RfpProposalDetailProps) => {
	const {
		useCase,
		detail: {
			currency,
			deliverablesV2: deliverables,
			totalPriceRequired,
			questions,
			questionGroups,
			diversity,
		},
		rateReview,
		phaseDuration,
		org: { _id: clientOrgId, name: clientOrgName },
	} = rfp
	const {
		_id: proposalId,
		files,
		org: { name: firmName, isNamwolfMember, certifications: orgCertifications },
		group: { certifications: groupCertifications },
		responseToDeliverables,
		status,
		subject,
		summaryOfProposal,
		activityLog,
		answersToQuestions,
		totalPriceModel,
		isPendingPrice,
		revisionComments: _revisionComments,
		diversityAnswers,
		engagementLetters,
		comparisonValue,
		averageRate,
	} = proposal

	const { enabled: isUAMToggledOn } = useUAMEnabled()
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const certifications = isUAMToggledOn ? groupCertifications ?? [] : orgCertifications ?? []

	const revisionComments = _revisionComments.map((revisionComment: RevisionComment) => ({
		...revisionComment,
		deliverables: groupRevisionCommentDeliverables({
			rfpDeliverables: rfp.detail.deliverablesV2,
			revisionCommentDeliverables: revisionComment?.deliverables ?? [],
		}),
	}))
	const publishedAt = getProposalPublishedAtDateFromActivityLog(activityLog)

	const diversityDefinitions = getOrEmptyArray('detail.diversity.diversityDefinitions', rfp)
	const rfpr = getRfpr(proposal, clientOrgId)
	const shouldRenderDiversityModule = getOrFalse('diversityRequired', diversity)

	const engagementLetterFiles = getOrEmptyArray('files', engagementLetters)

	const proposalFeedback = getOrNull('feedback', proposal)

	const rfpCurrency = rfp.useCase === 'rateReview' ? rateReview.currency : currency

	return (
		<Box paddingTop={2} paddingBottom={2} paddingLeft={1} paddingRight={1} maxWidth="80vw">
			{proposalFeedback && (
				<Fragment>
					<Typography variant="h2XSmall" sx={{ marginBottom: '1rem' }}>
						Feedback on proposal
					</Typography>
					<ClientFeedbackDisplay
						expanded={true}
						feedback={[proposalFeedback]}
						orgName={clientOrgName}
					/>
					<HorizontalDivider />
				</Fragment>
			)}
			{!isEmpty(engagementLetterFiles) && (
				<Fragment>
					<Typography variant="h2XSmall" sx={{ marginBottom: '1rem' }}>
						Engagement Letters
					</Typography>
					<EngagementLetters
						engagementLetters={engagementLetters}
						proposalId={proposalId}
						fetchResponses={fetchResponses}
					/>
					<HorizontalDivider />
				</Fragment>
			)}
			{!isEmpty(revisionComments) && (
				<Fragment>
					<Typography variant="h2XSmall" sx={{ marginBottom: '1rem' }}>
						Revision history
					</Typography>
					<RevisionCommentsList
						currency={currency}
						revisionComments={revisionComments}
						phaseDuration={phaseDuration}
						rfp={rfp}
						proposal={proposal}
					/>
				</Fragment>
			)}
			<SROnlyH1>{`${firmName}'s proposal`}</SROnlyH1>
			<Typography variant="h2XSmall">
				{firmName}
				<Box display="inline-block" ml={0.5} paddingTop={1}>
					{isNamwolfMember && <NamwolfLogo />}
				</Box>
			</Typography>
			{/* Don't show the updated date when in draft */}
			{publishedAt && status !== rfpProposalStates.DRAFT && (
				<Typography variant="body2">
					Submitted:
					{moment(publishedAt.date).format('D MMMM YYYY, h:mmA')}
				</Typography>
			)}
			<HorizontalDivider />
			<Typography variant="h3XSmall" data-testid="proposal-subject" sx={{ marginBottom: '1rem' }}>
				{subject}
			</Typography>
			{/* Don't show summaryOfProposal if blank */}
			{summaryOfProposal && (
				<RichTextView data-testid="proposal-summary" content={summaryOfProposal} />
			)}
			{/************************
			 ********** Files *********
			 *************************/}
			{files && files.length > 0 && (
				<Fragment>
					<Typography variant="h2XSmall">Attachments</Typography>

					<FileDownloadList label="List of downloadable attachment files" files={files} />
				</Fragment>
			)}
			{/*********************************
			 ******* Phases/deliverables *******
			 ***********************************/}
			<Spacer space={3} />
			{(totalPriceRequired || !isEmpty(deliverables)) && (
				<Typography variant="h2XSmall">Pricing</Typography>
			)}
			{totalPriceRequired && (
				<Fragment>
					<Spacer space={3} />
					{!isPendingPrice && totalPriceRequired && totalPriceModel && (
						<TotalPrice currency={currency} rfpProposal={proposal} rfpr={rfpr} />
					)}

					{isPendingPrice && (
						<Typography variant="h3XSmall" data-testid="pending-total-price">
							Pending total price
						</Typography>
					)}
					<Spacer space={isRateCardAuctionEnabled ? 2 : 4} />
				</Fragment>
			)}
			{isRateCardAuctionEnabled ? (
				<PricingInfo
					totalPriceRequired={totalPriceRequired}
					deliverables={deliverables}
					responseToDeliverables={responseToDeliverables}
					comparisonValue={comparisonValue}
					currency={currency}
					averageRate={averageRate?.rate}
				/>
			) : (
				<ComparisonValueInfo
					totalPriceRequired={totalPriceRequired}
					deliverables={deliverables}
					responseToDeliverables={responseToDeliverables}
					comparisonValue={comparisonValue}
					rfpCurrency={currency}
					isDetailView={true}
				/>
			)}
			{useCase === 'rateReview' && (
				<RfpProposalRateReview
					proposal={proposal}
					currency={rfp.rateReview.currency}
					timekeepers={rfp.rateReview.timekeepers}
				/>
			)}
			<ProposalDeliverables
				deliverables={deliverables}
				responseToDeliverables={responseToDeliverables}
				currency={currency}
				phaseDuration={phaseDuration}
				status={status}
				rfpr={rfpr}
			/>
			<Spacer space={3} />
			{/*********************************
			 ****** Questionnaire answers ******
			 ***********************************/}
			{size(questions) > 0 && (
				<Fragment>
					<HorizontalDivider />
					<Typography variant="h2XSmall">Questions</Typography>

					<RfpProposalDetailQuestionnaireAnswers
						answersToQuestions={answersToQuestions}
						questions={questions}
						questionGroups={questionGroups}
						currency={rfpCurrency}
					/>
				</Fragment>
			)}
			{/*	Diversity Module */}
			{shouldRenderDiversityModule && (
				<Fragment>
					<Typography variant="h2XSmall" sx={{ marginBottom: '1rem' }}>
						Diversity
					</Typography>
					<Typography variant="body1">
						{constructDiversityDefinition(diversityDefinitions, { hidePlaceholder: true })}
					</Typography>
					<DiversityAnswersAndCertifications
						certifications={certifications}
						diversityAnswers={diversityAnswers}
					/>
				</Fragment>
			)}
		</Box>
	)
}

export default RfpProposalDetail
