import { useState, forwardRef } from 'react'

import { Box } from '../../box'
import { TextField } from '../../text-field'
import { IconButton } from '../../icon-button'
import { Autocomplete } from '../../autocomplete'

import { PublicIcon } from '../../../icons'

import { getTimezoneById, TIMEZONES } from '../timezone-data'

const timezoneIds = TIMEZONES.map(({ id }) => id)

export type TimezoneSelectProps = {
	id?: string
	label?: string
	timezoneId: number
	onTimezoneSelected?: (timezone: number) => void
	isError?: boolean
	errorHelperText?: string
	disabled?: boolean
	onBlur?: () => void
	name?: string
	required?: boolean
}

export const TimezoneSelect = forwardRef(function TimezoneSelectInternal(
	{
		timezoneId = 0,
		onTimezoneSelected = () => undefined,
		isError,
		errorHelperText,
		disabled,
		onBlur,
		name,
		label = 'Timezone',
		id,
		required,
	}: TimezoneSelectProps,
	ref,
) {
	const [tzValue, setTZValue] = useState(getTimezoneById(timezoneId).id)

	const handleDropdownChange = (_: unknown, newValue: number | null) => {
		if (newValue) {
			setTZValue(newValue)
			onTimezoneSelected(newValue)
		} else {
			setTZValue(0)
			onTimezoneSelected(0)
		}
	}

	return (
		<Box display="flex" alignItems="center">
			<IconButton title="Timezone" size="large" disabled={true}>
				<PublicIcon color="primary" />
			</IconButton>

			<Autocomplete
				onBlur={onBlur}
				disabled={disabled}
				autoComplete={true}
				autoHighlight={true}
				value={tzValue}
				options={timezoneIds}
				getOptionLabel={(option) => getTimezoneById(option).label}
				onChange={handleDropdownChange}
				noOptionsText="Please select a valid timezone"
				renderInput={(params) => (
					<TextField
						{...params}
						id={id}
						label={label}
						ref={ref as any}
						name={name}
						variant="standard"
						placeholder="Search city"
						error={isError}
						helperText={errorHelperText}
						required={required}
					/>
				)}
				style={{ width: 300 }}
			/>
		</Box>
	)
})
