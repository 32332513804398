import { NumberField, NumberFieldProps } from '../number-field'

type MoneyFieldProps = NumberFieldProps & {
	currencySymbol?: string
	currency?: string
}

export const MoneyField = ({ currency, currencySymbol, ...rest }: MoneyFieldProps) => {
	return (
		<NumberField
			{...rest}
			InputProps={{
				startAdornment: <span aria-hidden={true}>{currencySymbol}</span> ?? null,
				endAdornment: currency ?? null,
				...rest.InputProps,
			}}
		/>
	)
}
