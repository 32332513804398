import { Rfp } from '../../../../../types'
import { Fragment } from 'react'
import { HeadlineSeparator } from '../../../headline-separator'
import { RfpDetailDiversity } from '../rfp-detail-diversity'
import { DeepPick } from '@persuit/common-utils'

export type RfpDetailDiversitySectionDocType = DeepPick<
	Rfp,
	'detail.diversity.diversityRequired' | 'detail.diversity.diversityDefinitions' | 'useCase'
>

type RfpDetailDiversitySectionProps = {
	document: RfpDetailDiversitySectionDocType
	isFirmView?: boolean
}

export const RfpDetailDiversitySection = ({
	document,
	isFirmView = false,
}: RfpDetailDiversitySectionProps) => {
	const diversityRequired = document?.detail?.diversity?.diversityRequired ?? false
	const diversityDefinitions = document?.detail?.diversity?.diversityDefinitions ?? []

	if (document?.useCase === 'rateReview') return null

	if (!diversityRequired) return null
	return (
		<Fragment>
			<HeadlineSeparator headline="Diversity" />
			<RfpDetailDiversity definitions={diversityDefinitions} isFirmView={isFirmView} />
		</Fragment>
	)
}
