import { RateCard } from './types'
import { calculateTimekeeperCell } from './calculate-timekeeper-cell'

type TimekeeperAverageCellData = {
	currentRate: number | null
	proposedRate: number | null
	delta: number | null
}

export function calculateTimekeeperAverage(
	timekeeper: string,
	rateCards: RateCard[][],
): TimekeeperAverageCellData {
	return calculateTimekeeperCell(timekeeper, rateCards.flat())
}
