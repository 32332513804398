import { useDispatch, useSelector } from '@client/store'
import { authActions, uiActions } from '../../actions'
import { Dialog, Typography } from '@persuit/ui-components'

export const ChangePasswordDialog = () => {
	const dispatch = useDispatch()
	const open = useSelector((state) => state?.ui?.showPasswordChangeDialog ?? false)

	const handleCancelPasswordChange = () => {
		dispatch(uiActions.hidePasswordChangeDialog())
	}

	const handlePasswordChange = () => {
		dispatch(uiActions.hidePasswordChangeDialog())
		dispatch(authActions.requestPasswordChangeForCurrentUser())
	}

	return (
		<Dialog
			open={open}
			onClose={handleCancelPasswordChange}
			title="Change Password"
			actions={[
				{ variant: 'text', label: 'cancel', onClick: handleCancelPasswordChange },
				{ variant: 'primary', label: 'Confirm', onClick: handlePasswordChange },
			]}
			size="sm"
		>
			<Typography>
				By clicking Confirm below, PERSUIT will send a link that can be used to change your
				password. The link will be sent to your nominated email account.
			</Typography>
		</Dialog>
	)
}
