import { Typography, styled } from '@persuit/ui-components'

const StyledBackground = styled('div')(
	({ theme }) => `
	position: absolute;
	z-index: -1;
	background: ${theme.palette.grey100};
	display: block;
	height: 100%;
	width: 100%;
	opacity: 20%;
`,
)

const StyledDemoText = styled(Typography)<{ x: string; y: string }>(
	({ x, y }) => `
	position: fixed;
	font-size: 7.5rem;
	transform: translateX(${x}) translateY(${y}) rotate(300deg);
`,
)

const START_X_POS = -400
const START_Y_POS = -400
const INCREMENT_POS = 300

type WatermarkBackgroundProps = {
	text?: string
	textColor?: string
	totalRow?: number
	totalElementsPerRow?: number
}

export const WatermarkBackground = ({
	text = 'Demo',
	totalRow = 30,
	totalElementsPerRow = 30,
	textColor = 'error.main',
}: WatermarkBackgroundProps) => {
	return (
		<StyledBackground aria-hidden="true">
			{Array(totalRow)
				.fill(0)
				.map((_, rowIdx) =>
					Array(totalElementsPerRow)
						.fill(0)
						.map((_, idx) => (
							<StyledDemoText
								x={`${START_X_POS + INCREMENT_POS * idx}px`}
								y={`${START_Y_POS + INCREMENT_POS * rowIdx}px`}
								key={idx}
								sx={{
									color: textColor,
								}}
							>
								{text}
							</StyledDemoText>
						)),
				)}
		</StyledBackground>
	)
}
