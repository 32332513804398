import { gql } from '@persuit/ui-graphql'

const userActivity = gql`
	fragment UserActivity on UserActivity {
		_id
		createdBy
		notificationsLastViewed
	}
`

export default userActivity
