// @ts-strict-ignore
import { toString } from 'lodash/fp'
import { UseCase } from '@persuit/ui-graphql/generated'

export const USE_CASES: Array<{ name: string; id: UseCase; description: string }> = [
	{
		name: 'Rate Review Pro',
		id: 'rateReview',
		description:
			'Premium workflow to compare & negotiate rates across many firms with analytics for negotiation leverage.',
	},
	{
		name: 'Single Matter or Project RFP',
		id: 'singleMatter',
		description: 'Request proposals from law firms for a specific legal matter or project.',
	},
	{
		name: 'Portfolio or Multi-Matter RFP',
		id: 'portfolio',
		description: 'Request proposals for a range or bundle of matters or projects.',
	},
	{
		name: 'Hourly Rate Negotiation',
		id: 'hourlyRateNegotiation',
		description:
			'Request basic rate negotiation. Featuring a simple rate card. Export rates to Excel to support your off-platform analysis.',
	},
	{
		name: 'Panel or Preferred Firm/Vendor RFP',
		id: 'panel',
		description: 'Establish a preferred provider or panel program.',
	},
	{
		name: 'Secondment or Temporary Staffing',
		id: 'secondment',
		description:
			'Request proposals for secondees or other short-term staffed resources from multiple vendors or firms.',
	},
	{
		name: 'Negotiate AFA on Existing Matter',
		id: 'negotiateAfa',
		description:
			'Request to assist with negotiating an AFA with a single firm for the remaining go-forward work that’s left to be completed.',
	},
	{
		name: 'RFI or Survey',
		id: 'rfi',
		description:
			'Collect information regarding either an actual scope of work or more general inquiries.',
	},
]

export const USE_CASE_LABEL_MAP = USE_CASES.reduce(
	(map, item) => ({ ...map, [item.id]: item.name }),
	{} as Record<UseCase, string>,
)

export const useCaseTypes = USE_CASES.map((useCase) => toString(useCase.id))

export function isUseCase(value: string): value is UseCase {
	return !!USE_CASE_LABEL_MAP[value]
}
