import { gql } from '@persuit/ui-graphql'
import requestsWithUnreadsGql from '../../fragments/messaging/requests-with-unreads-gql'

const requestsWithUnreadMessages = gql`
	${requestsWithUnreadsGql}

	subscription {
		requestsWithUnreadMessages {
			...RequestsWithUnreadMessages
		}
	}
`

export default requestsWithUnreadMessages
