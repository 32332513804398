export const hasFeedbackItem = (feedbackItem) => {
	return Object.keys(feedbackItem).some(
		(key) =>
			feedbackItem[key] &&
			feedbackItem[key] !== 'notApplicable' &&
			feedbackItem[key] !== 'unanswered',
	)
}

const hasFeedback = (formState) => {
	return Object.keys(formState).some((proposalId) => {
		const feedbackItem = formState[proposalId]
		return hasFeedbackItem(feedbackItem)
	})
}

export default hasFeedback
