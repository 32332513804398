import { formatToTimeZone } from 'date-fns-timezone'
import { getTimezoneById } from '../timezone-data'

export type TimezoneAbbreviationProps = JSX.IntrinsicElements['span'] & {
	date?: Date | null
	timezoneId: number | null
}

export const TimezoneAbbreviation = ({ date, timezoneId, ...rest }: TimezoneAbbreviationProps) => {
	if (!timezoneId) {
		return null
	}

	const dt = date || new Date()

	const timezoneAbbreviation = formatToTimeZone(dt, 'z', {
		timeZone: getTimezoneById(timezoneId).key,
	})

	return (
		<span data-testid="time-zone-offset" {...rest}>
			( {timezoneAbbreviation} )
		</span>
	)
}
