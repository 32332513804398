import { createBatchedPromiseFunction } from '@persuit/common-utils'
import { graphql, useApolloClient } from '@persuit/ui-graphql'
import {
	PracticeArea,
	RateReviewServices,
	UseSaliPracticeAreaDataQuery,
} from '@persuit/ui-graphql/generated'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHandleUnexpectedError } from '../use-handle-unexpected-error'

export type { PracticeArea, RateReviewServices } from '@persuit/ui-graphql/generated'

type Data = PracticeArea | RateReviewServices

const QUERY = graphql(`
	query UseSaliPracticeAreaData {
		getRateReviewPracticeAreaData {
			code
			name
			practiceAreas {
				code
				name
			}
		}
	}
`)

type PracticeAreaData = UseSaliPracticeAreaDataQuery['getRateReviewPracticeAreaData']

// TODO: This works for now, in future upgrade Apollo Client and use assumeImmutableResults
let batcher: null | (() => Promise<UseSaliPracticeAreaDataQuery>) = null
let practiceAreaData: PracticeAreaData | null = null

function useGetPracticeAreaData() {
	const client = useApolloClient()

	if (!batcher) {
		batcher = createBatchedPromiseFunction(() =>
			client.query({ query: QUERY }).then(({ data }) => data),
		)
	}

	return async () => {
		if (!practiceAreaData && batcher) {
			const data = await batcher()
			practiceAreaData = data.getRateReviewPracticeAreaData
		}

		return practiceAreaData
	}
}

export const useSaliPracticeAreaData = () => {
	const [loading, setLoading] = useState(!practiceAreaData)
	const [error, setError] = useState(null)
	const [data, setData] = useState(practiceAreaData)
	const handleUnexpectedError = useHandleUnexpectedError()
	const getPracticeAreaData = useGetPracticeAreaData()

	useEffect(() => {
		// eslint-disable-next-line promise/catch-or-return -- setLoading() will never fail
		getPracticeAreaData()
			.then((data) => {
				setData(data)
				setError(null)
			})
			.catch((e) => {
				setData(null)
				setError(e)
			})
			.finally(() => setLoading(false))
			.catch(handleUnexpectedError)
	}, [handleUnexpectedError])

	const areas = useMemo(() => data ?? [], [data])

	const practiceAreaById = useCallback(
		(id: string): Data | null =>
			areas.reduce(
				(serviceAcc, service) =>
					serviceAcc ??
					(service.code === id
						? service
						: service.practiceAreas.reduce(
								(areaAcc, area) => areaAcc ?? (area.code === id ? area : null),
								null as null | Data,
						  )),
				null as null | Data,
			),
		[areas],
	)

	return { loading, error, data: areas, practiceAreaById }
}
