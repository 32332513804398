// @ts-strict-ignore
export const getQuestionsForGroup = ({ questions, questionGroup }) => {
	if (!questions) {
		return []
	}

	if (!questionGroup?._id && !questionGroup?.id) {
		return []
	}

	return questions.reduce((acc, item, index) => {
		if (!item) {
			return acc
		}
		if (item?.groupId !== questionGroup?.id && item?.groupId !== questionGroup?._id) {
			return acc
		}
		acc.push({
			id: item.id,
			index,
			...item,
		})
		return acc
	}, [])
}
