import { Fragment } from 'react'
import { Box, Typography, useTheme } from '@persuit/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { DeliverableContainer } from './deliverable-container'
import { DeliverableProps } from './deliverable'

export type GroupContainerProps = Pick<
	DeliverableProps,
	'currency' | 'phaseDuration' | 'rfpr' | 'isProposalRevised' | 'responseToDeliverables'
> & {
	index: number
	group: {
		_id: string
		title: string
		deliverables: Array<DeliverableProps['deliverable']>
	}
}

export const GroupContainer = ({ group, index, ...rest }: GroupContainerProps) => {
	const theme = useTheme()

	return (
		<Box role="listitem">
			<Typography variant="h6" component="h2" id={group._id}>
				{isEmpty(group.title) ? <i>Untitled Group</i> : group.title}
			</Typography>
			<Box>
				{!isEmpty(group.deliverables) && (
					<Box
						role="list"
						aria-labelledby={group._id}
						style={{ border: `1px solid ${theme.palette.grey500}` }}
						padding={1}
						display="flex"
						flexDirection="column"
						gap={theme.spacing(2)}
					>
						{group.deliverables.map((groupedDeliverable, innerIndex) => {
							return (
								<Fragment key={`D${index + innerIndex}`}>
									<DeliverableContainer
										floating={false}
										deliverable={groupedDeliverable}
										index={index + innerIndex}
										{...rest}
									/>
									{innerIndex + 1 !== group.deliverables.length && (
										<Box
											aria-hidden={true}
											width="100%"
											height="1px"
											style={{ backgroundColor: theme.palette.grey500 }}
										/>
									)}
								</Fragment>
							)
						})}
					</Box>
				)}
			</Box>
		</Box>
	)
}
