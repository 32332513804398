import { TableCell, TableRow, Box, styled, SROnly } from '@persuit/ui-components'
import { useId } from 'react'
import * as React from 'react'
import { scrollToElementWithId } from '@persuit/ui-utils'

// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
export const StyledTableRow = styled(TableRow)`
	cursor: pointer;
	&:hover {
		background-color: #f9f9f9;
	}
`

export type RowContentsProps = {
	indent?: boolean
	cell1?: React.ReactNode
	cell2?: React.ReactNode
	cell3?: React.ReactNode
	cell4?: React.ReactNode
	cell5?: React.ReactNode
	scrollTargetId?: string
	interactive?: boolean
	label?: string
}

const RowContents = ({
	indent = false,
	cell1 = null,
	cell2 = null,
	cell3 = null,
	cell4 = null,
	cell5 = null,
	scrollTargetId,
	interactive = false,
	label,
}: RowContentsProps) => {
	const descriptionId = useId()

	// If the row is interactive then we wrap it with some nice hover effects

	if (interactive) {
		return (
			<StyledTableRow onClick={() => scrollToElementWithId(scrollTargetId)}>
				<TableCell width="50%" scope="row" role="rowheader">
					<Box
						pl={indent ? '1em' : undefined}
						id={descriptionId}
						tabIndex={0}
						role="button"
						onKeyDown={(e) => {
							e.stopPropagation()
							if (e.key === 'Enter' || e.key === '') {
								scrollToElementWithId(scrollTargetId)
							}
						}}
						onClick={(e) => {
							e.stopPropagation()
							scrollToElementWithId(scrollTargetId)
						}}
						aria-label={`Click to navigate to ${label}`}
					>
						{cell1}
					</Box>
				</TableCell>
				<TableCell align="right" width="20%">
					{cell2}
				</TableCell>
				<TableCell align="left" width="10%">
					{cell3}
				</TableCell>
				<TableCell align="right" width="5%">
					{cell4}
				</TableCell>
				<TableCell align="right" width="12%">
					{cell5}
				</TableCell>
			</StyledTableRow>
		)
	}

	return (
		<TableRow>
			<TableCell width="50%">{cell1 ? cell1 : <SROnly>Blank header</SROnly>}</TableCell>
			<TableCell align="right" width="20%">
				{cell2}
			</TableCell>
			<TableCell align="left" width="10%">
				{cell3}
			</TableCell>
			<TableCell align="right" width="5%">
				{cell4}
			</TableCell>
			<TableCell align="right" width="12%">
				{cell5}
			</TableCell>
		</TableRow>
	)
}

export default RowContents
