import * as React from 'react'

import { Autocomplete as MuiAutocomplete, AutocompleteProps, ChipTypeMap } from '@mui/material'
export type {
	AutocompleteRenderInputParams,
	AutocompleteRenderGroupParams,
	AutocompleteProps,
} from '@mui/material'

export { createFilterOptions } from '@mui/material'

export function Autocomplete<
	T,
	Multiple extends boolean | undefined = false,
	DisableClearable extends boolean | undefined = false,
	FreeSolo extends boolean | undefined = false,
	ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
	multiple,
	...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>): JSX.Element {
	if (multiple) {
		return (
			<MuiAutocomplete
				multiple={multiple}
				{...rest}
				ListboxProps={{
					'aria-multiselectable': true,
					...rest.ListboxProps,
				}}
			/>
		)
	}

	return <MuiAutocomplete multiple={multiple} {...rest} />
}
