/** @deprecated use preferredAuthMethods */
const oldAuthMethods = {
	NONE: null, // if no method is preferred
	LOCAL: 'LOCAL', // no redirect to an external service
	GOOGLE: 'GOOGLE',
	MICROSOFT: 'MICROSOFT',
	SAML: 'SAML',
}

const preferredAuthMethods = {
	BASIC: 'LOCAL', // no redirect to an external service
	GOOGLE: 'GOOGLE',
	MICROSOFT: 'MICROSOFT',
	SAML: 'SAML',
}

const authUrls = {
	NONE: null, // if no method is preferred
	LOCAL: null, // no redirect to an external service
	GOOGLE: '/auth/google/',
	MICROSOFT: '/auth/azure/',
	SAML: '/auth/saml/:provider',
}

const authCallbacks = {
	NONE: null, // if no method is preferred
	LOCAL: null, // no redirect to an external service
	GOOGLE: '/auth/google/callback/',
	MICROSOFT: '/auth/azure/callback/',
	SAML: '/auth/saml/:provider/callback',
}

const firmAuthMethods = {
	SHARED_WITH_UNREGISTERED_USER: 'SHARED_WITH_UNREGISTERED_USER',
	EXISTING_ACCOUNT_NOT_ONBOARDED: 'EXISTING_ACCOUNT_NOT_ONBOARDED',
	EXISTING_ACCOUNT_ONBOARDED: 'EXISTING_ACCOUNT_ONBOARDED',
}

const authMethods = ['NONE', ...Object.values(preferredAuthMethods)]

const isSAMLOrSSOUser = (orgAuthMethod) => {
	return orgAuthMethod === 'SAML' || orgAuthMethod === 'MICROSOFT' || orgAuthMethod === 'GOOGLE'
}

export {
	preferredAuthMethods as default,
	oldAuthMethods,
	authMethods,
	authUrls,
	authCallbacks,
	firmAuthMethods,
	isSAMLOrSSOUser,
}
