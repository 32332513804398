import { Box, useTheme, Typography, Alert } from '@persuit/ui-components'
import { TotalPriceSection } from '../total-price-section'
import { PricingList } from '../pricing-list'
import { useStore } from '../../store'
import { ApolloError } from '@apollo/client'
import { ComparisonValueSection } from '../comparison-value-section'
import { AverageRateBanner } from '../average-rate-banner'

type ProposalQuickRevisionConfirmFormProps = {
	error?: ApolloError
	noChangesMade?: boolean
}

export const ProposalQuickRevisionConfirmForm = ({
	error,
	noChangesMade,
}: ProposalQuickRevisionConfirmFormProps) => {
	const theme = useTheme()

	const {
		shouldShowTotalPriceSection,
		shouldShowComparisonValueSection,
		shouldDisplayAverageRateBanner,
	} = useStore(({ proposal: { totalPriceValue }, totalPriceNotRequired }, selectors) => ({
		shouldShowTotalPriceSection: totalPriceValue !== selectors.currentTotalPriceValue(),
		shouldShowComparisonValueSection:
			totalPriceNotRequired && Boolean(selectors.currentComparisonValue()),
		shouldDisplayAverageRateBanner: selectors.shouldDisplayAverageRateBanner(),
	}))

	return (
		<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
			{noChangesMade ? (
				<Typography variant="body1">No changes made</Typography>
			) : (
				<>
					{error && (
						<Alert severity="error">
							{error.message.includes('Rfp validation')
								? 'This cannot be submitted as the request was updated. You will need to close the dialog and start again'
								: 'Error. Try again'}
						</Alert>
					)}
					<Typography variant="h3XSmall">You have made the following changes</Typography>
					{shouldShowComparisonValueSection && <ComparisonValueSection />}
					{shouldDisplayAverageRateBanner && <AverageRateBanner />}
					{shouldShowTotalPriceSection && <TotalPriceSection editable={false} />}
					<PricingList
						showPricingItem={true}
						showRateItem={true}
						onlyShowChangedItems={true}
						editable={false}
					/>
				</>
			)}
		</Box>
	)
}
