import { Typography, Box, useTheme, PopoverHelp, Divider } from '@persuit/ui-components'
import { Delta } from '../delta'
import { useStore } from '../../store'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { isEmpty } from 'lodash/fp'
import { requestValidForComparisonValue } from '../../../common-util'

export const ComparisonValueSection = () => {
	const theme = useTheme()
	const {
		originalComparisonValue,
		currentComparisonValue,
		totalPriceNotRequired,
		currency,
		deliverables,
	} = useStore(
		(
			{
				proposal: { comparisonValue },
				totalPriceNotRequired,
				currency,
				deliverables,
				pricingItemFormState,
			},
			selectors,
		) => ({
			originalComparisonValue: comparisonValue ?? 0,
			currentComparisonValue: selectors.currentComparisonValue(),
			totalPriceNotRequired,
			deliverables,
			currency,
			pricingItemFormState,
		}),
	)

	if (!totalPriceNotRequired || !originalComparisonValue) {
		return null
	}

	if (isEmpty(deliverables) || !requestValidForComparisonValue(deliverables)) return null

	return (
		<Box
			display="flex"
			border={`1px solid ${theme.palette.primary.main}`}
			bgcolor={theme.palette.primary.lightestHue}
			padding={3}
			justifyContent="center"
		>
			<Box display="flex" width={'800px'}>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems={'flex-end'}
					marginRight={3}
					flex={1}
				>
					<Box display="flex" alignItems="center">
						<Typography variant="h4" component="p">
							Comparison value
						</Typography>
						<PopoverHelp
							role="tooltip"
							content={
								<div>
									<Typography variant="caption" sx={{ fontWeight: 'bold' }}>
										This request is using comparison value
									</Typography>
									<br />
									<Typography variant="caption">
										When the total price is not requested a comparison value will be
										auto-calculated. This is provided for comparative purposes only.
									</Typography>
								</div>
							}
							triggerButtonProps={{
								'aria-label': 'Comparison value help',
							}}
							triggerButtonSize="small"
						/>
					</Box>
					<Typography variant="h1XSmall" component="p">
						{formatPriceWithCurrency(currency, currentComparisonValue)}
					</Typography>
					<Typography variant="body2">This is an auto-sum of non-hourly rate items</Typography>
				</Box>
				<Divider orientation="vertical" flexItem={true} sx={{ borderColor: theme.palette.black }} />
				<Box
					marginLeft={3}
					flex={1}
					alignItems={'flex-start'}
					flexDirection="column"
					justifyContent="center"
				>
					<Box display="flex" alignItems="center" height="34px">
						<Typography variant="h4" component="p">
							Value change
						</Typography>
					</Box>
					<Delta
						newNumber={currentComparisonValue ?? 0}
						originalNumber={originalComparisonValue}
						currency={'AUD'}
						showZero={true}
						typographyProps={{
							fontSize: theme.typography.h1XSmall.fontSize,
							lineHeight: theme.typography.h1XSmall.lineHeight,
							fontWeight: theme.typography.h1XSmall.fontWeight,
							letterSpacing: theme.typography.h1XSmall.letterSpacing,
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}
