// @ts-strict-ignore
import { useState, useId } from 'react'

import * as React from 'react'
import {
	Box,
	Spacer,
	Chip as MuiChip,
	ChipProps as MuiChipProps,
	styled,
} from '@persuit/ui-components'
import { ChipExpanded, ChipExpandedProps } from './chip-expanded'

export type ChipProps = {
	chipProps?: MuiChipProps
	primaryText: string
	secondaryText?: string
	icon?: React.ReactNode
	onDelete?: () => void
	onOpen?: () => void
	onClose?: () => void
} & Omit<ChipExpandedProps, 'expanded' | 'expandAnchor' | 'onClose'>

const TruncatedText = styled('span')({
	display: 'inline-block',
	maxWidth: '9em',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
})

export const Chip = ({
	onDelete,
	primaryText,
	secondaryText,
	icon,

	userId,
	hideStar = false,
	isApprovedFirm = false,
	isFavourite = false,
	isPanelOrPreferredFirm = false,
	additionalText,

	onOpen,
	onClose,

	chipProps,
}: ChipProps) => {
	const [expanded, setExpanded] = useState(false)
	const [expandAnchor, setExpandAnchor] = useState(null)
	const generatedId = useId()
	const chipId = chipProps?.id ?? generatedId

	const onExpand = (event) => {
		event.preventDefault()
		event.stopPropagation()
		onOpen?.()
		setExpanded(true)
		setExpandAnchor(event.currentTarget)
	}

	const onCollapse = () => {
		onClose?.()
		setExpanded(false)
	}

	return (
		<>
			{expanded && (
				<ChipExpanded
					expanded={true}
					expandAnchor={expandAnchor}
					onClose={() => {
						onCollapse()
						setTimeout(() => {
							document.getElementById(chipId)?.focus()
						}, 300)
					}}
					primaryText={primaryText}
					secondaryText={secondaryText}
					additionalText={additionalText}
					isFavourite={isFavourite}
					hideStar={hideStar}
					userId={userId}
					isApprovedFirm={isApprovedFirm}
					isPanelOrPreferredFirm={isPanelOrPreferredFirm}
				/>
			)}

			<MuiChip
				{...chipProps}
				id={chipId}
				onClick={onExpand}
				onDelete={onDelete}
				label={
					<Box display="flex" alignItems="center">
						<TruncatedText>{primaryText}</TruncatedText>

						{secondaryText ? (
							<TruncatedText style={{ marginLeft: 4 }}>({secondaryText})</TruncatedText>
						) : null}

						{icon ? (
							<>
								<Spacer space={0.5} shape="column" />
								<Box display="flex" gap="4px" flexDirection="row">
									{icon}
								</Box>
							</>
						) : null}
					</Box>
				}
			/>
		</>
	)
}
