import { Box, FormTextField, FormCheckbox, Tooltip } from '@persuit/ui-components'
import { usePricingListContext } from '../../pricing-list-context'
import { useWatch } from '../../pricing-form-utils'
import getOr from 'lodash/fp/getOr'
import { getPricingItemError } from '../pricing-sub-item-utils/get-pricing-errors'
import { usePricingSubItemListContext } from '../pricing-sub-item-list-context'

export const PricingItemListFooterOptions = () => {
	const { errors } = usePricingListContext()
	const { pricingItemFieldPath, pricingItemIndex } = usePricingSubItemListContext()

	const allowFirmAddRate = useWatch({
		name: `${pricingItemFieldPath}.allowFirmAddRate`,
	})
	// TODO: Remove this check when we have a back end solution to tracking sub items
	const originalAllowsFirmsToAddEntries = useWatch({
		name: `${pricingItemFieldPath}.originalAllowsFirmsToAddEntries`,
	})

	const pricingError = getPricingItemError(errors, pricingItemIndex)
	const rateCardPlaceHolderError =
		pricingError && getOr('', 'firmRateCardItemPlaceholder', pricingError)

	// The "Allow firms to add entries" checkbox should be disabled during an RFP revision
	// if the original RFP has this checkbox checked
	// That is to say clients can't uncheck this if it's checked in the original
	const disableAllowFirmToAddItems = originalAllowsFirmsToAddEntries

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<Box margin="0">
				<Tooltip
					title="Allow firm to add entries cannot be disabled as it has been enabled"
					arrow={true}
					disabled={!disableAllowFirmToAddItems}
				>
					<FormCheckbox
						name={`${pricingItemFieldPath}.allowFirmAddRate`}
						checkboxProps={{
							color: 'primary',
						}}
						label={'Allow firms to add entries'}
						disabled={disableAllowFirmToAddItems}
					/>
				</Tooltip>
			</Box>
			{allowFirmAddRate && (
				<Box margin="0">
					<Box marginLeft="30px">
						<FormTextField
							required={true}
							id={`suggested-format-for-firm-response-${pricingItemIndex}`}
							error={!!rateCardPlaceHolderError}
							helperText={rateCardPlaceHolderError}
							label="Suggested format for firm's response"
							placeholder="Timekeeper name, JD year"
							name={`${pricingItemFieldPath}.firmRateCardItemPlaceholder`}
							fullWidth={true}
							inputProps={{ maxLength: 50 }}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}
