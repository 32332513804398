// @ts-strict-ignore
import { useId } from 'react'
import { DialogActions, useForm, useSnackbar, Button } from '@persuit/ui-components'
import {
	ScorecardForm,
	ScorecardCategory,
	ScorecardFormValues,
	parseScorecardData,
} from '@persuit/ui-shared-components'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useScorecardHeaderCellSaveScorecardCategoriesMutation } from '@persuit/ui-graphql/hooks'

graphql(`
	mutation ScorecardHeaderCellSaveScorecardCategories(
		$_id: ID!
		$scoreCategories: [ScoreCategoriesInput]!
	) {
		saveScoreCategories(_id: $_id, scoreCategories: $scoreCategories) {
			...RfpClient_RfpDEPRECATED
		}
	}
`)

type ScoreCardHeaderFormProps = {
	scoreCategories: ScorecardCategory[]
	rfpId: string
	closeDialog: () => void
}

export const ScoreCardHeaderForm = ({
	scoreCategories,
	rfpId,
	closeDialog,
}: ScoreCardHeaderFormProps) => {
	const methods = useForm<ScorecardFormValues>({
		defaultValues: {
			scoreCategories,
			mode: 'MANUAL',
		},
	})

	const { openSnackbar } = useSnackbar()
	const [saveScorecardCategories, { loading }] =
		useScorecardHeaderCellSaveScorecardCategoriesMutation()
	const formId = useId()

	return (
		<>
			<ScorecardForm
				id={formId}
				showHeading={false}
				methods={methods}
				onSubmit={async (data) => {
					try {
						saveScorecardCategories({
							variables: {
								_id: rfpId,
								scoreCategories: parseScorecardData(data.scoreCategories ?? []),
							},
						})

						closeDialog?.()
						openSnackbar('Scorecard updated!')
					} catch (e) {
						openSnackbar(`Error updating scorecard: ${e.message}`)
					}
				}}
				noValidate={true}
			/>

			<DialogActions>
				<Button variant="outlined" onClick={closeDialog}>
					Cancel
				</Button>
				<Button
					form={formId}
					type="submit"
					variant="contained"
					disabled={loading}
					aria-busy={loading ? 'true' : 'false'}
				>
					Save
				</Button>
			</DialogActions>
		</>
	)
}
