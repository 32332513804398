import { Box, Typography, useTheme } from '@persuit/ui-components'
import { PricingList } from '../pricing-list'

export const RatePricingSection = () => {
	const theme = useTheme()

	return (
		<Box display="flex" flexDirection="column" gap={theme.spacing(1)} paddingTop={2}>
			<Typography variant="h3XSmall">Rates</Typography>
			<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
				<PricingList showRateItem={true} />
			</Box>
		</Box>
	)
}
