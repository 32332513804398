import { useId } from 'react'
import { Typography, Box, BoxProps } from '@persuit/ui-components'
import { Rate } from '../../../../../types'

type RateCardProps = BoxProps & {
	rates: Array<Rate>
	allowFirmAddRate: boolean
}

export const RateCard = ({ rates, allowFirmAddRate, ...rest }: RateCardProps) => {
	const listOfRatesId = useId()
	return (
		<Box {...rest}>
			<Typography variant="body1" id={listOfRatesId}>
				Rates requested for:
			</Typography>

			<ul aria-labelledby={listOfRatesId}>
				{rates.map((rate, i) => (
					<Typography key={i} component="li" aria-label="rate item" variant="body2">
						{rate?.label ?? ''}
					</Typography>
				))}
			</ul>

			{allowFirmAddRate && (
				<Typography variant="body2">
					Additional rates such as Timekeeper name, Category can be added
				</Typography>
			)}
		</Box>
	)
}
