import { Fragment, useState } from 'react'
import { Box, Typography } from '@persuit/ui-components'
import compose from 'lodash/fp/compose'
import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'
import zipObject from 'lodash/fp/zipObject'
import Spacer from '../../../layout/spacer.jsx'
import responseStates from '../../../../../common/data/rfp-proposal-states'
import Switch from '../../../form-inputs/switch'
import AuctionSummaryTable from './auction-summary-table'
import { AuctionActivityChart, AuctionActivityChartOld } from './auction-activity-chart/'
import KeyPerformanceIndicators from './key-performance-indicators'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { getFilteredResponses } from './calculations'
import { clientPhaseModel } from '@persuit/common-logic'

const colours = [
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#9584DD',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#04834C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#544600',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#9C9285',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#00639B',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#826D03',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#BA005D',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003734',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#34A624',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#4A398C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#086E00',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#7B1E7A',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#38383C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#1D0160',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#A9695C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#AF3800',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#00A299',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#006A64',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003734',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#606060',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#FF4A8F',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#795048',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#FF376A',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#8C8C8C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#650030',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#0097EA',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#F46C00',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003353',
	// repeating the colours to lessen the chance of using all colours
	// duplicates of a colour is better than many black ones
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#9584DD',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#04834C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#544600',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#9C9285',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#00639B',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#826D03',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#BA005D',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003734',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#34A624',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#4A398C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#086E00',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#7B1E7A',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#38383C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#1D0160',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#A9695C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#AF3800',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#00A299',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#006A64',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003734',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#606060',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#FF4A8F',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#795048',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#FF376A',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#8C8C8C',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#650030',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#0097EA',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#F46C00',
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	'#003353',
]

// Flip the argument order of zipObject
const zipObjectWithBackwardsArgs = zipObject.convert({
	rearg: true,
})

// Create a mapping between each orgID and a colour
// This lookup can be used across components and keeps all
// orgs coloured consistently
const mapOrgIdToColour = ({ invites }) => {
	return compose(
		// Join each org ID to a colour
		zipObjectWithBackwardsArgs(colours),

		// Get the org ID
		map('item._id'),

		// Sort the invites by createdAt
		// This ensures the colours are fully stable over time and newly invited firms never take the colour of existing firms
		sortBy('createdAt'),
	)(invites)
}

const hasRateItems = (deliverable) =>
	deliverable.pricingPreferences === clientPhaseModel.HOURLYRATE ||
	deliverable.pricingPreferences === clientPhaseModel.RATECARD

export const AuctionAnalytics = ({ request, responses, auctionInProgress }) => {
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const orgIdToColourMapping = mapOrgIdToColour({
		invites: request.invites,
	})

	const [showWithdrawn, setShowWithdrawn] = useState(false)

	const toggleShowWithdrawn = () => {
		setShowWithdrawn(!showWithdrawn)
	}

	const [showEliminated, setShowEliminated] = useState(false)

	const toggleShowEliminated = () => {
		setShowEliminated(!showEliminated)
	}

	const filteredResponses = getFilteredResponses({ responses, showWithdrawn, showEliminated })

	const areSomeWithdrawn = responses.some(
		(response) => response.status === responseStates.WITHDRAWN,
	)
	const areSomeEliminated = responses.some((response) => response.isEliminated)

	const deliverables = request.detail.deliverablesV2

	const isRatesOnlyRequest = deliverables.every((deliverable) =>
		deliverable.__typename === 'PricingGroup'
			? deliverable.deliverables.every(hasRateItems)
			: hasRateItems(deliverable),
	)

	return (
		<Fragment>
			<Box>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h2XSmall">Auction analytics</Typography>
					<div>
						{areSomeEliminated && (
							<Switch
								checked={showEliminated}
								onChange={toggleShowEliminated}
								label="Show unsuccessful proposals"
							/>
						)}

						{areSomeWithdrawn && (
							<Switch
								checked={showWithdrawn}
								onChange={toggleShowWithdrawn}
								label="Show withdrawn proposals"
							/>
						)}
					</div>
				</Box>
				{/* Only display the summary table if the request is not rates-only, 
				 summary data for rates-only requests are not available. */}
				{!isRatesOnlyRequest && isRateCardAuctionEnabled && (
					<>
						<AuctionSummaryTable
							currency={request.detail.currency}
							orgIdToColourMapping={orgIdToColourMapping}
							request={request}
							responses={filteredResponses}
							auctionInProgress={auctionInProgress}
						/>
						<Spacer space={2} />
					</>
				)}

				{isRateCardAuctionEnabled ? (
					<AuctionActivityChart
						currency={request.detail.currency}
						orgIdToColourMapping={orgIdToColourMapping}
						responses={filteredResponses}
						auctionEnd={request.auctionEnd}
						proposalsDueBy={request.proposalsDueBy}
						deliverables={deliverables}
						totalPriceRequired={request.detail.totalPriceRequired}
					/>
				) : (
					<AuctionActivityChartOld
						currency={request.detail.currency}
						orgIdToColourMapping={orgIdToColourMapping}
						responses={filteredResponses}
						auctionEnd={request.auctionEnd}
						proposalsDueBy={request.proposalsDueBy}
						deliverables={deliverables}
						totalPriceRequired={request.detail.totalPriceRequired}
					/>
				)}

				{/* Only display KPI's if the request is not rates-only.
				KPIs for rates-only requests are not available.*/}
				{!isRatesOnlyRequest && isRateCardAuctionEnabled && (
					<>
						<Spacer space={2} />
						<KeyPerformanceIndicators
							currency={request.detail.currency}
							responses={filteredResponses}
							request={request}
						/>
					</>
				)}
			</Box>
		</Fragment>
	)
}
