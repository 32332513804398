import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import accept from 'attr-accept'
import allowableFileTypes from '../../../../common/data/accepted-file-types'

export const filterAllowableFiles = (files) =>
	files.filter((file) => accept(file, allowableFileTypes))

export const handleUploadResult = ({ handleUploadedFiles, uploadResult }) => {
	const uploadedFiles = getOr([], 'uploadedFiles', uploadResult)
	const filesWithError = getOr([], 'filesWithError', uploadResult)

	handleUploadedFiles(uploadedFiles)

	if (!isEmpty(filesWithError)) {
		throw new Error(filesWithError)
	}
}
