import {
	white,
	grey50,
	grey100,
	grey200,
	grey300,
	grey500,
	purple500,
	deepPurple50,
	deepPurple400,
	deepPurple800,
	deepPurple900,
	red500,
} from './persuit-colors'
import { theme } from '@persuit/ui-components'

const linkColor = purple500

export const muiOverrides = {
	palette: {
		canvasColor: white,
		primary1Color: theme.palette.primary.main,
		linkColor,
		alternateTextColor: grey50,
	},

	fontFamily: 'Roboto, sans-serif',

	toolbar: {
		backgroundColor: 'none',
	},

	drawer: {
		width: 360, // Bit wider than default (default is 256)
		color: deepPurple900,
		menuItem: {
			color: deepPurple50,
			borderColor: grey500,
			hoverBackgroundColor: deepPurple800,
			hoverBorderColor: theme.palette.primary.main,
		},
	},
}

export default {
	button: {
		iconButton: {
			padding: '4px',
			borderRadius: '50%',
			color: `${theme.palette.primary.main} !important`,

			'&:hover': {
				background: grey300,
			},
		},
	},

	grid: {
		desktopGutter: 24,
		desktopGutterLess: 16,
		mobileGutter: 16,
		desktopMq: '@media (min-width: 361px)',
		breakpoints: {
			xs: {
				columns: 4,
				minWidth: '0px',
				containerWidth: '100%',
				offset: 0,
			},
			sm: {
				columns: 8,
				minWidth: '840px',
				containerWidth: '100%',
				offset: '12px',
			},
			md: {
				columns: 12,
				minWidth: '1024px',
				containerWidth: '976px',
				offset: 'calc(9.375% - 72px)',
			},
			lg: {
				columns: 12,
				minWidth: '1280px',
				containerWidth: '976px',
				offset: 'calc(18.75% - 216px)',
			},
			xl: {
				columns: 12,
				minWidth: '2560px',
				containerWidth: '976px',
				offset: '144px',
			},
		},
	},

	shadow: '0.1px 3px 10px rgba(0, 0, 0, 0.16), 0.1px 3px 10px rgba(0, 0, 0, 0.23)',
	// Elevation 4
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	shadowHover: 'rgba(0, 0, 0, 0.188235) 0.1px 10px 30px, rgba(0, 0, 0, 0.227451) 0.1px 6px 10px',

	// Default nice transition
	transition: 'all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95)',

	// Colours
	borderColor: {
		subtle: grey300,
	},

	lovelyLeftBorder: {
		borderLeft: `4px solid ${theme.palette.primary.main}`,
		borderTopLeftRadius: '4px',
		borderBottomLeftRadius: '4px',
	},

	fontWeight: {
		light: 300,
		regular: 400,
		medium: 500,
		bold: 600,
	},

	responseCompare: {
		cellBackground1: grey100,
		cellBackground2: grey200,
		cellBackground3: grey300,
		borderHightlight: deepPurple400,
	},

	linkColor,

	error: {
		color: red500,
	},

	ellipsis: {
		minWidth: '0',
		'& > *': {
			maxWidth: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},

	// This media query will adjust the CSS for non-chromium edge versions only
	edgeOnlyMediaQuery: '@supports (-ms-ime-align: auto)',
}
