// @ts-strict-ignore
import numeral from 'numeral'
import { Typography, RichTextView, Box } from '@persuit/ui-components'

import { RfpProposalState } from '../../../../../common/data/rfp-proposal-states'
import questionTypeValues from '../../../../../common/data/question-types'
import diversityTypeValues from '../../../../../common/data/diversity-types.js'
import Certifications from '../../../certifications/index.js'

import { ExpandableCell, Cell, CellProps } from '../components'
import { useStore } from '../../store'

import { getDiversityAnswer } from './diversity-utils.js'

type DiversityCellProps = CellProps & {
	type: unknown
	diversityAnswers: unknown
	// | string negates the union but is needed until types are fixed higher up
	responseStatus: RfpProposalState | string
	index: number
	certifications: unknown
	diversityTitle: string
}

const renderAnswer = (answer) => {
	if (typeof answer === 'boolean') {
		return answer ? 'Yes' : 'No'
	}
	return null
}

const renderPercentage = (answer) => {
	return answer !== 'XX' ? `${numeral(answer).format('0,0.[00000000]')}%` : `${answer}%`
}

const DiversityAnswerComponent = ({
	diversityAnswer,
	type,
	questionTypeValues,
	diversityTypeValues,
	certifications,
	truncationValue,
}) => {
	// this can happen when diversity is added editing a request.
	if (!diversityAnswer) {
		return null
	}

	if (diversityAnswer.isNa) {
		return (
			<>
				<Typography variant="body1Semibold" fontSize="20px">
					N/A
				</Typography>
				{diversityAnswer.comment && (
					<Box mt={2}>
						<RichTextView content={diversityAnswer.comment} truncationLength={truncationValue} />
					</Box>
				)}
			</>
		)
	}

	if (type === questionTypeValues.CHOICE) {
		return (
			<>
				<Typography variant="body1Semibold" fontSize="20px">
					{renderAnswer(diversityAnswer.answer)}
				</Typography>

				{diversityAnswer.comment && (
					<Box mt={2}>
						<RichTextView content={diversityAnswer.comment} truncationLength={truncationValue} />
					</Box>
				)}
			</>
		)
	}

	if (type === diversityTypeValues.PERCENTAGE) {
		return (
			<>
				{diversityAnswer.answer !== null && (
					<Typography variant="body1Semibold" fontSize="20px">
						{renderPercentage(diversityAnswer.answer)}
					</Typography>
				)}
				{diversityAnswer.comment && (
					<Box mt={2}>
						<RichTextView content={diversityAnswer.comment} truncationLength={truncationValue} />
					</Box>
				)}
			</>
		)
	}

	if (type === diversityTypeValues.CERTIFICATION) {
		return <Certifications certifications={certifications} />
	}

	return null
}

export const DiversityCell = ({
	type,
	diversityAnswers,
	responseStatus,
	index,
	certifications,
	diversityTitle,
	...rest
}: DiversityCellProps) => {
	const { isWide, truncateCellContents } = useStore((state, s) => ({
		isWide: s.isWide(rest.responseId),
		truncateCellContents: state.truncateCellContents,
	}))

	const truncationLength = isWide ? 500 : 250
	const truncationValue = truncateCellContents ? truncationLength : undefined
	const diversityAnswer = getDiversityAnswer(diversityAnswers, index + 1)
	const isCompetitor = responseStatus === 'competitor'

	if (isCompetitor) {
		return (
			<Cell {...rest}>
				<Typography>Not displayed</Typography>
			</Cell>
		)
	}

	return (
		<ExpandableCell
			expandedTitle={`D${index + 1}. ${diversityTitle}`}
			expandedContent={
				<DiversityAnswerComponent
					diversityAnswer={diversityAnswer}
					type={type}
					questionTypeValues={questionTypeValues}
					diversityTypeValues={diversityTypeValues}
					certifications={certifications}
					truncationValue={undefined}
				/>
			}
			responseId={rest.responseId}
			headers={rest.headers}
			showRightBorder={rest.showRightBorder}
		>
			<DiversityAnswerComponent
				diversityAnswer={diversityAnswer}
				type={type}
				questionTypeValues={questionTypeValues}
				diversityTypeValues={diversityTypeValues}
				certifications={certifications}
				truncationValue={truncationValue}
			/>
		</ExpandableCell>
	)
}
