import { useSnackbar } from '@persuit/ui-components'
import { useEffect } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { Grant, useUAMEnabled, useUser } from './utils'

type RequireGrantProps = {
	grant: Grant
	// The grant status to fall back on when UAM is disabled
	// 	true: The user is allowed to view the child component
	//  false: The user should be redirected
	//  undefined: Don't display anything (as if UAM isn't enabled yet). Use undefined
	//		to indicate that the value is not yet known (eg a feature toggle or user info
	//		is still loading)
	fallback?: boolean
	children: React.ReactNode
}

export const RequireGrant = ({ children, grant, fallback }: RequireGrantProps) => {
	const grantStatus = useGrantStatus({ grant, fallback })
	const { openSnackbar } = useSnackbar()
	const { push } = useHistory()

	useEffect(() => {
		if (grantStatus === false) {
			push('/en')
			openSnackbar('You do not have access to this page. Redirecting you to the dashboard', {
				variant: 'error',
			})
		}
	}, [grantStatus, openSnackbar, push])

	return grantStatus ? <>{children}</> : null
}

const useGrantStatus = ({
	grant,
	fallback,
}: Pick<RequireGrantProps, 'grant' | 'fallback'>): boolean | undefined => {
	const { enabled: uamEnabled, loading: uamToggleLoading } = useUAMEnabled()
	const { can, loading: userLoading } = useUser()

	if (uamToggleLoading || userLoading) {
		// Undefined signals we don't yet know if the user has the grant
		return undefined
	}

	return uamEnabled
		? can(grant)
		: // This could be true, false or undefined (signalling the caller doesn't yet
		  // know what the fallback value should be).
		  fallback
}
