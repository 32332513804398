type RefocusOnRemovalProps = {
	currentIndex: number
	getItemByIndex: (index: number) => HTMLElement | null
	fallback?: HTMLElement | null
}

export function refocusBeforeRemoval({
	currentIndex,
	fallback,
	getItemByIndex,
}: RefocusOnRemovalProps) {
	const focusTargetIndex = currentIndex === 0 ? 1 : currentIndex - 1
	const focusTarget = getItemByIndex(focusTargetIndex) ?? fallback
	setTimeout(() => {
		focusTarget?.focus()
	}, 100)
}
