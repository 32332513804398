import { Box, useTheme, StatusText } from '@persuit/ui-components'
import { BackButton } from '../back-button'
import { Headline } from '../headline'
import { Cab } from '../cab'
import { LastEdited } from '../../../../sectional-form'

type HeaderProps = {
	templateId: string
	templateTitle: string
	templateUpdatedAt: Date
	handlePublish: () => void
	handlePreview: () => void
	headlineError: string
	sendMode: boolean
}

export const Header = ({
	templateId,
	templateTitle,
	templateUpdatedAt,
	handlePublish,
	handlePreview,
	headlineError,
	sendMode,
}: HeaderProps) => {
	const theme = useTheme()
	return (
		<Box
			boxSizing="border-box"
			width="100%"
			height="100%"
			bgcolor={theme.palette.white}
			padding={1}
			display="grid"
			columnGap={theme.spacing(1)}
			gridTemplateRows="auto auto"
			gridTemplateColumns="auto minmax(0, 1fr) auto"
		>
			<Box gridRow="1 / 3" gridColumn="1" height="auto" alignSelf="center">
				<BackButton templateId={templateId} />
			</Box>
			<Box
				gridRow="1"
				gridColumn="2"
				width="100%"
				height="auto"
				display="flex"
				flexDirection="row"
				alignItems="center"
				gap={theme.spacing(1)}
			>
				<StatusText text="DRAFT" bgcolor={theme.palette.grey300} color={theme.palette.black} />
				<Headline
					templateId={templateId}
					templateTitle={templateTitle}
					error={headlineError}
					sendMode={sendMode}
				/>
			</Box>
			<Box
				gridRow="2"
				gridColumn="2"
				width="100%"
				height="auto"
				display="flex"
				flexDirection="row"
				alignItems="center"
				gap={theme.spacing(1)}
			>
				<LastEdited lastEdited={templateUpdatedAt} />
			</Box>
			<Box gridRow="1 / 3" gridColumn="3" height="auto" alignSelf="center">
				<Cab
					templateId={templateId}
					handlePreview={handlePreview}
					handlePublish={handlePublish}
					isUploading={false}
				/>
			</Box>
		</Box>
	)
}

export default Header
