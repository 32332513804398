import { Fragment, useState, ReactNode } from 'react'
import { DialogDeprecated, DialogClose } from '@persuit/ui-components'
import AddNoteDialog from './add-note-dialog'
import ViewNotesDialog from './view-notes-dialog'

const notesDialogPages = {
	VIEW_NOTES_DIALOG: 'VIEW_NOTES_DIALOG',
	ADD_NOTE_DIALOG: 'ADD_NOTE_DIALOG',
}

type RenderProps = {
	handleOpenDialog: () => void
}

type NotesDialogProps = {
	rfpId: string
	firmId: string
	firmName: string
	children: (props: RenderProps) => ReactNode
}

const NotesDialog = ({ rfpId, firmId, firmName, children }: NotesDialogProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [dialogPage, setDialogPage] = useState(notesDialogPages.VIEW_NOTES_DIALOG)
	const [ownNoteContents, setOwnNoteContents] = useState('')

	const handleOpenDialog = () => setIsOpen(true)
	const handleCloseDialog = () => {
		setIsOpen(false)
		setDialogPage(notesDialogPages.VIEW_NOTES_DIALOG)
	}
	const openViewNoteDialog = () => setDialogPage(notesDialogPages.VIEW_NOTES_DIALOG)
	const openAddNoteDialog = (content = '') => {
		setDialogPage(notesDialogPages.ADD_NOTE_DIALOG)
		setOwnNoteContents(content)
	}

	return (
		<Fragment>
			<DialogDeprecated open={isOpen} onClose={handleCloseDialog} maxWidth={'lg'} fullWidth={true}>
				<DialogClose onClick={handleCloseDialog} />

				{dialogPage === notesDialogPages.VIEW_NOTES_DIALOG && (
					<ViewNotesDialog
						firmName={firmName}
						rfpId={rfpId}
						firmId={firmId}
						handleCloseDialog={handleCloseDialog}
						openAddNoteDialog={openAddNoteDialog}
					/>
				)}
				{dialogPage === notesDialogPages.ADD_NOTE_DIALOG && (
					<AddNoteDialog
						firmName={firmName}
						rfpId={rfpId}
						firmId={firmId}
						openViewNoteDialog={openViewNoteDialog}
						ownNoteContents={ownNoteContents}
						setOwnNoteContents={setOwnNoteContents}
					/>
				)}
			</DialogDeprecated>

			{children({ handleOpenDialog })}
		</Fragment>
	)
}

export default NotesDialog
