import { Typography } from '@persuit/ui-components'
import formatPriceCurrency from '../../../utils/price-and-currency'
import { getOrNull, getOrFalse, getOrEmptyObject } from '../../../utils/lenses'
import { firmPhaseModel } from '../../../../common/data/pricing-models'

const getReferenceDeliverablePrice = (delta) => {
	const referenceDeliverablePrice = getOrNull('referenceProposalDeliverable.price', delta)

	if (referenceDeliverablePrice === 0) {
		return null
	}
	return referenceDeliverablePrice
}

const StrikeThroughText = ({ text }) => {
	return (
		<div data-testid="strike-through-delta">
			<Typography color="text.secondary" strikeThrough={true} data-testid="strike-through-value">
				{text}
			</Typography>
		</div>
	)
}

const StrikeThroughPrice = ({ responseToDeliverable, currency }) => {
	const delta = getOrEmptyObject('delta', responseToDeliverable)

	const referenceDeliverablePrice = getReferenceDeliverablePrice(delta)
	const referenceDeliverablePricingModel = getOrNull(
		'referenceProposalDeliverable.pricingModel',
		delta,
	)
	const currentDeliverablePricingModel = getOrNull('pricingModel', responseToDeliverable)

	const previousPriceModelIncluded = referenceDeliverablePricingModel === 'included'

	if (!hasStrikeThroughPrice(responseToDeliverable)) {
		return null
	}

	if (previousPriceModelIncluded) {
		return <StrikeThroughText text="No charge" />
	}

	if (currentDeliverablePricingModel === firmPhaseModel.INCLUDED) {
		return <StrikeThroughText text={formatPriceCurrency(currency, referenceDeliverablePrice)} />
	}

	return <StrikeThroughText text={formatPriceCurrency(currency, referenceDeliverablePrice)} />
}

export default StrikeThroughPrice

export const hasStrikeThroughPrice = (responseToDeliverable) => {
	const delta = getOrEmptyObject('delta', responseToDeliverable)

	const referenceDeliverablePrice = getReferenceDeliverablePrice(delta)
	const currentDeliverablePrice = getOrNull('price', responseToDeliverable)

	const isReferenceDeliverableExcludedFromScope = getOrFalse(
		'referenceProposalDeliverable.notIncluded',
		delta,
	)

	if (referenceDeliverablePrice === null) {
		return false
	}

	if (isReferenceDeliverableExcludedFromScope === true) {
		return false
	}

	if (parseFloat(currentDeliverablePrice, 10) === referenceDeliverablePrice) {
		return false
	}

	return true
}
