// @ts-strict-ignore
import { useState } from 'react'
import { SectionalFormContainer, useSectionalForm } from '../sectional-form'
import { TotalPriceRequiredToggle, AllowOtherPricingModelCheckbox } from './pricing-form-inputs'
import {
	PricingFormValues,
	purifyDeliverable,
	purifyGroup,
	isDeliverable,
	isGroup,
} from './pricing-form-utils'
import {
	PopoverHelp,
	Typography,
	FormRichTextEditor,
	Spacer,
	CurrencySelectForHookForm,
	Box,
	Form,
	useTheme,
	Alert,
	AlertTitle,
} from '@persuit/ui-components'
import { Deliverable, PricingFormErrors, PricingGroup } from './types'
import { PricingListContextProvider } from './pricing-list-context'
import { PricingList } from './pricing-list'
import { TotalPricingModelSelect } from './pricing-form-inputs/total-pricing-model-select'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { requestValidForComparisonValue } from '../common-util'

type SaveSectionProps = {
	currency: string | null
	priceDescription: string | null
	totalPriceRequired: boolean | null
	totalPriceModel: string | null
	isFirmAllowedToChangeTotalPriceModel: boolean
	deliverables: (Partial<Deliverable> | Partial<PricingGroup>)[]
}

export type PricingSectionData = {
	deliverablesV2: (Deliverable | PricingGroup)[]
	currency?: string | null
	priceDescription?: string | null
	totalPriceModel?: string | null
	totalPriceRequired?: boolean | null
	isFirmAllowedToChangeTotalPriceModel?: boolean | null
	originalTotalPriceRequired?: boolean
	originalAllowFirmPriceModel?: boolean
	originalTotalPricingPreference?: string
	auction: boolean
}

export type PricingFormProps = {
	pricingData: PricingSectionData
	onSave: (args: SaveSectionProps) => Promise<unknown>
	pricingErrors: PricingFormErrors | null
}

export const PricingForm = ({ onSave, pricingData, pricingErrors }: PricingFormProps) => {
	const { toggles } = useFeatureToggles()
	const isPercentPricingItemToggleOn = toggles['dev-3327.contingency-percentage']
	const rateCardAuctionImprovements = toggles['dev-9900.rate-card-auction-improvements']
	const pricingItemErrorsRaw = pricingErrors?.deliverables ?? []
	const pricingItemErrors = Array.isArray(pricingItemErrorsRaw) ? pricingItemErrorsRaw : []
	const pricingGroupErrors = pricingErrors?.groups ?? []

	const handleSave = async (values: PricingFormValues) => {
		await onSave({
			currency: values.currency ?? null,
			priceDescription: values.priceDescription ?? null,
			totalPriceRequired: values.totalPriceRequired ?? null,
			totalPriceModel: values.totalPriceModel ?? null,
			isFirmAllowedToChangeTotalPriceModel: values.isFirmAllowedToChangeTotalPriceModel,
			deliverables: values.pricingItems.map((item) => {
				if (isGroup(item)) {
					return purifyGroup(item)
				} else if (isDeliverable(item)) {
					return purifyDeliverable(item)
				} else {
					return item
				}
			}),
		})
	}

	const methods = useSectionalForm(
		{
			totalPriceRequired: pricingData.totalPriceRequired,
			totalPriceModel: pricingData.totalPriceModel || 'fixedFee',
			currency: pricingData.currency,
			priceDescription: pricingData.priceDescription,
			pricingItems: pricingData.deliverablesV2,
			isFirmAllowedToChangeTotalPriceModel:
				pricingData.isFirmAllowedToChangeTotalPriceModel ?? false,
		},
		handleSave,
		{
			totalPriceRequired: pricingErrors?.totalPriceRequired ?? '',
			totalPriceModel: pricingErrors?.totalPriceModel ?? '',
		},
	)

	const { watch, setValue } = methods
	const {
		originalTotalPriceRequired,
		originalAllowFirmPriceModel,
		originalTotalPricingPreference,
		auction,
	} = pricingData
	const theme = useTheme()

	const [totalPriceRequired, setTotalPriceRequired] = useState(pricingData.totalPriceRequired)

	const toggleTotalPriceRequired = (totalPriceRequiredToggleValue: boolean) => {
		setTotalPriceRequired(totalPriceRequiredToggleValue)
		setValue('totalPriceRequired', totalPriceRequiredToggleValue)
	}

	// Update totalPriceModel based on isFirmAllowedToChangeTotalPriceModel value
	watch((data, { name, type }) => {
		if (name === 'isFirmAllowedToChangeTotalPriceModel' && type === 'change') {
			if (
				!data.isFirmAllowedToChangeTotalPriceModel &&
				originalAllowFirmPriceModel === false &&
				originalTotalPriceRequired
			) {
				setValue('totalPriceModel', originalTotalPricingPreference)
			}
		}
	})

	const hasValidPricingItemsForComparisonValue = () => {
		return requestValidForComparisonValue(pricingData.deliverablesV2)
	}

	return (
		<SectionalFormContainer label="Pricing section" focusOnMount={true} p={0} mb={1}>
			<Form methods={methods} aria-label="Pricing Section">
				<Box display="flex" flexDirection="column">
					<Box
						display="flex"
						flexDirection="column"
						padding={2}
						borderRadius="8px"
						boxShadow="0px 2px 5px rgba(0, 0, 0, 0.15)"
						bgcolor={theme.palette.white}
					>
						<Typography variant="h1XSmall">Pricing</Typography>
						{auction && !totalPriceRequired && !hasValidPricingItemsForComparisonValue() && (
							<>
								<Spacer space={2} />
								<Alert role="alert" severity="warning">
									<AlertTitle>Adjust your pricing setup for best auction results</AlertTitle>
									<Typography>
										This is an auction request, but your current pricing setup may produce less than
										ideal results. To fix this, enable the total price or add a non-rate pricing
										item.
									</Typography>
								</Alert>
							</>
						)}
						<Spacer space={2} />
						<FormRichTextEditor
							id="price-description"
							name="priceDescription"
							label="Pricing details & assumptions"
							height={200}
						/>
						<Spacer space={2} />
						<CurrencySelectForHookForm
							id="payment-currency"
							name="currency"
							label="Currency"
							fullWidth={true}
							disableClearable={true}
						/>
						<Spacer space={3} />
						<Typography variant="body2">Total Price</Typography>
						<Spacer space={2} />
						<Box display="flex" flexDirection="column" width="100%">
							<Box
								border={1}
								m={1}
								borderColor={theme.palette.form.borderResting}
								style={{ margin: '0px', padding: '15px' }}
								width="auto"
							>
								<TotalPriceRequiredToggle
									originalTotalPriceRequired={originalTotalPriceRequired ?? false}
									error={pricingErrors?.totalPriceRequired ?? ''}
									checked={totalPriceRequired ?? false}
									onChange={toggleTotalPriceRequired}
								/>
								{!rateCardAuctionImprovements &&
								!totalPriceRequired &&
								hasValidPricingItemsForComparisonValue() ? (
									<Alert
										role="alert"
										severity="info"
										sx={{ border: `1px solid ${theme.palette.info.main}`, borderRadius: 0 }}
									>
										<AlertTitle>Comparison value</AlertTitle>
										<Typography variant="body2">
											When the total price is not requested, PERSUIT will auto-calculate a
											Comparison value based on the sum of pricing items excluding hourly rates and
											percentage items. This will be used to provide a price ranking.
										</Typography>
									</Alert>
								) : null}

								{rateCardAuctionImprovements && !totalPriceRequired && (
									<Alert
										role="alert"
										severity="info"
										sx={{ border: `1px solid ${theme.palette.info.main}`, borderRadius: 0 }}
									>
										<AlertTitle>Price ranking evaluation</AlertTitle>
										<Box display="flex" flexDirection="column" gap={2}>
											<Typography variant="body1">
												When the total price is not requested, PERSUIT will auto-calculate a
												Comparison value based on the sum of pricing items excluding hourly rates
												and percentage items. This serves as the preferred approach for providing a
												price ranking.
											</Typography>
											<Typography variant="body1">
												Alternatively, if only rates are requested, the price ranking will be
												determined by the average of all provided rates.
											</Typography>
										</Box>
									</Alert>
								)}

								{totalPriceRequired && (
									<Box
										mt={2}
										display="flex"
										justifyContent="space-between"
										paddingLeft="6px"
										paddingRight="6px"
										alignItems="center"
									>
										<Box width="60%" display="flex">
											<Box width="60%">
												<TotalPricingModelSelect
													originalTotalPriceRequired={originalTotalPriceRequired}
												/>
											</Box>
											<Spacer space={2} />
										</Box>
										<AllowOtherPricingModelCheckbox
											originalTotalPriceRequired={originalTotalPriceRequired}
											originalAllowFirmPriceModel={originalAllowFirmPriceModel}
										/>
										<PopoverHelp
											triggerButtonProps={{
												'aria-label': 'Pricing model help',
											}}
											content={
												<div>
													<Typography variant="body2">
														Choose a pricing structure for this Request. Setting a preference will
														make it easier to compare proposals.
													</Typography>
													<Spacer space={2} />

													<Typography component="span" variant="subtitle2">
														No preference:&nbsp;
													</Typography>
													<Typography component="span" variant="body2">
														Let firms choose their own pricing structure.
													</Typography>
													<Spacer space={2} />
													<Typography component="span" variant="subtitle2">
														{isPercentPricingItemToggleOn
															? 'Fee Arrangements'
															: 'Alternative Fee Arrangements (AFA)'}
														&nbsp;
													</Typography>
													<Typography component="span" variant="body2">
														(Recommended) : Capped Fee, Contingency Fee, Fixed Fee, Success Fee.
													</Typography>
													<Spacer space={2} />
													<Typography component="span" variant="subtitle2">
														Budgeting Estimate:&nbsp;
													</Typography>
													<Typography component="span" variant="body2">
														If you're only looking for an estimate which is subject to change,
														select this option.
													</Typography>
													<Spacer space={2} />
													<Typography component="span" variant="subtitle2">
														Hourly Rate:&nbsp;
													</Typography>
													<Typography component="span" variant="body2">
														If you're looking to get Hourly Rates for this Request, select this
														option.
													</Typography>
													<Spacer space={2} />
													<Typography component="span" variant="subtitle2">
														Other (see instructions):&nbsp;
													</Typography>
													<Typography component="span" variant="body2">
														If you'd like the firms to follow another type of pricing stucture, list
														it out in the Instructions and Assumptions box.
													</Typography>
												</div>
											}
										/>
									</Box>
								)}
							</Box>
							<Spacer space={2} />
						</Box>
					</Box>
					<PricingListContextProvider
						errors={{
							pricingItemErrors,
							pricingGroupErrors,
						}}
						totalPricingPreference={pricingData.totalPriceModel}
					>
						<PricingList />
					</PricingListContextProvider>
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
