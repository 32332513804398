import channelTypes from 'src/common/data/channel-types'
import { ChannelWithLastMessage, UserOrg } from './types'

export const getOrgNameByChannel = (
	channel: ChannelWithLastMessage,
	userCurrentOrg: UserOrg | null,
) => {
	const isPrivateFirmChannel = channel.type === channelTypes.PRIVATE_FIRM_CHANNEL
	const isPrivateClientChannel = channel.type === channelTypes.PRIVATE_CLIENT_CHANNEL

	const isInternalOrg =
		(isPrivateFirmChannel && channel.orgs.firm?.id === userCurrentOrg?._id) ||
		(isPrivateClientChannel && channel.orgs.client?.id === userCurrentOrg?._id)
			? true
			: false

	const isCurrentUserClient = userCurrentOrg?.orgType === 'CLIENT'
	const isCurrentUserFirm = userCurrentOrg?.orgType === 'FIRM'
	const orgName = isInternalOrg
		? isCurrentUserFirm
			? channel.orgs.firm?.name
			: isCurrentUserClient
			? channel.orgs.client?.name
			: ''
		: isCurrentUserFirm
		? channel.orgs.client?.name
		: isCurrentUserClient
		? channel.orgs.firm?.name
		: ''
	return orgName
}
