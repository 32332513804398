import {
	SxProps,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	SROnly,
	Typography,
} from '@persuit/ui-components'
import numeral from 'numeral'

import {
	timekeeperCalculations,
	getPercentageDiscount,
	getPercentageDifference,
	renderPrice,
	renderPercentage,
} from '../utils'

type RateCardTableRow = {
	_id: string
	timekeeperLabel: string
	isEnabled?: boolean
	lyHour?: number | null
	currentRate?: number | null
	standardRate?: number | null
	proposedRate?: number | null
	suggestedRate?: number | null
}

export type RateCardTableProps = {
	sx?: SxProps
	rowData: RateCardTableRow[]
	currency?: string | null
	truncated?: boolean
	ariaLabel?: string
	rateCardId: string
}

export const RateCardTable = ({
	currency,
	sx,
	rowData,
	truncated,
	ariaLabel,
	rateCardId,
}: RateCardTableProps) => {
	return (
		<TableContainer sx={sx} tabIndex={0}>
			<Table
				aria-label={ariaLabel ?? 'timekeeper rates'}
				sx={(theme) => ({
					borderCollapse: 'collapse',
					'& td, & th': {
						border: `1px solid ${theme.palette.grey300}`,
					},
				})}
			>
				<TableHead>
					<TableRow
						sx={(theme) => ({
							borderCollapse: 'collapse',
							'& th': {
								border: `1px solid ${theme.palette.grey200}`,
								borderBottom: `1px solid ${theme.palette.grey500}`,
							},
							'& tr:nth-child(2n)': {
								background: theme.palette.grey100,
							},
						})}
					>
						<TableCell
							style={{ borderLeft: 'none', borderTop: 'none', position: 'relative' }}
							sx={{ width: '200px' }}
						>
							<SROnly>Timekeepers</SROnly>
						</TableCell>
						<TableCell align="center" sx={{ width: '8em' }}>
							Last year's hours
						</TableCell>
						<TableCell align="right">Current rate</TableCell>
						<TableCell align="right">New standard rate</TableCell>
						<TableCell align="right">Proposed rate</TableCell>
						<TableCell align="center" sx={{ width: '10em' }}>
							Discount from standard
						</TableCell>
						<TableCell align="center" sx={{ width: '10em' }}>
							Change from last year
						</TableCell>
						{!truncated && (
							<>
								<TableCell align="right">Last year's spend</TableCell>
								<TableCell align="right">Spend impact</TableCell>
							</>
						)}
					</TableRow>
				</TableHead>

				<TableBody
					sx={(theme) => ({
						'& tr:nth-child(2n + 1)': {
							background: theme.palette.grey100,
						},
					})}
				>
					{rowData.map((data, index) => (
						<TimekeeperRow
							key={data.timekeeperLabel}
							truncated={truncated}
							data={data}
							currency={currency}
							index={index}
							rateCardId={rateCardId}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

type TimekeeperRowProps = {
	data: RateCardTableRow
	index: number
	currency?: string | null
	truncated?: boolean
	rateCardId: string
}

const TimekeeperRow = ({
	truncated,
	currency,
	index,
	data,
	data: {
		proposedRate,
		standardRate,
		timekeeperLabel,
		currentRate,
		isEnabled,
		lyHour,
		suggestedRate,
	},
	rateCardId,
}: TimekeeperRowProps) => {
	const timekeeperName = `${index + 1}. ${timekeeperLabel}`
	const noSuggestedRate = !suggestedRate && suggestedRate !== 0
	if (!isEnabled) {
		return (
			<TableRow key={timekeeperName}>
				<TableCell
					component="th"
					scope="row"
					sx={(theme) => ({ color: theme.palette.text.secondary })}
				>
					{timekeeperName}
					<span style={{ display: 'inline-block' }}>Timekeeper not provided</span>
				</TableCell>
				{new Array(truncated ? 6 : 8).fill(null).map((_, index) => (
					<TableCell key={index} />
				))}
			</TableRow>
		)
	}

	const { discountFromStandard, changeFromLastYear, lastYearsSpend, spendImpact } =
		timekeeperCalculations(data)

	const overridePercentages = {
		percentageDiscount: renderPercentage(getPercentageDiscount(standardRate, suggestedRate)),
		percentageChange: renderPercentage(getPercentageDifference(currentRate, suggestedRate), true),
	}

	const scrollId = `${rateCardId}_${data._id}`

	return (
		<TableRow key={timekeeperName} id={scrollId}>
			<TableCell component="th" scope="row">
				{timekeeperName}
			</TableCell>
			<TableCell align="center">
				{typeof lyHour === 'number' ? numeral(lyHour).format('0,0') : '-'}
			</TableCell>
			<TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
				{renderPrice(currentRate, currency)}
			</TableCell>
			<TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
				{renderPrice(standardRate, currency)}
			</TableCell>
			<TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
				{
					<>
						{noSuggestedRate ? null : (
							<>
								<Typography variant="body2">{renderPrice(suggestedRate, currency)}</Typography>
								<br />
							</>
						)}
						<Typography variant="body2" strikeThrough={Boolean(!noSuggestedRate)}>
							{renderPrice(proposedRate, currency)}
						</Typography>
					</>
				}
			</TableCell>
			<TableCell align="center">
				{
					<>
						{noSuggestedRate ? null : (
							<>
								<Typography variant="body2">{overridePercentages.percentageDiscount}</Typography>
								<br />
							</>
						)}
						<Typography variant="body2" strikeThrough={Boolean(!noSuggestedRate)}>
							{renderPercentage(discountFromStandard)}
						</Typography>
					</>
				}
			</TableCell>
			<TableCell align="center">
				{
					<>
						{noSuggestedRate ? null : (
							<>
								<Typography variant="body2">{overridePercentages.percentageChange}</Typography>
								<br />
							</>
						)}
						<Typography variant="body2" strikeThrough={Boolean(!noSuggestedRate)}>
							{renderPercentage(changeFromLastYear, true)}
						</Typography>
					</>
				}
			</TableCell>
			{!truncated && (
				<>
					<TableCell align="right">{renderPrice(lastYearsSpend, currency, true)}</TableCell>
					<TableCell align="right">{renderPrice(spendImpact, currency, true)}</TableCell>
				</>
			)}
		</TableRow>
	)
}
