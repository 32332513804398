import { Fragment } from 'react'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'
import { Typography } from '@persuit/ui-components'
import { DueDateTicker } from '../due-date-ticker'

export type ProgressCardWatchAuctionProps = ProgressCardProps

export const CARD_TITLE = 'Watch the auction'

const HELP_TEXT = `There is nothing you need to do to start the auction, it will proceed automatically at the start date and time. The auction end of the auction will be automatically extended if proposals come in right at the end.`

export const ProgressCardWatchAuction = ({
	index,
	step,
	extraData,
}: ProgressCardWatchAuctionProps) => {
	if (step.status === 'future')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				helpText={HELP_TEXT}
				subtitle={
					!extraData.isDraft &&
					extraData.auctionStartDate && (
						<DueDateTicker prefix="Auction starts" dueDate={extraData.auctionStartDate} />
					)
				}
			/>
		)

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				helpText={HELP_TEXT}
				guidanceText={
					'The auction has begun! Check out the proposal screen and get your popcorn ready.'
				}
			/>
		)

	if (step.status === 'completed')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				helpText={HELP_TEXT}
				subtitle={
					<Fragment>
						<Typography>
							{extraData.priceRevisions} price revisions. Check out the results in the analytics
							tab.
						</Typography>
					</Fragment>
				}
			/>
		)

	return <BaseCard title={CARD_TITLE} index={index} status={step.status} />
}
