// @ts-strict-ignore
import { useWatch } from '../../pricing-form-utils'
import { PricingModelSelect } from '../../../common-components'

type TotalPricingModelSelectProps = {
	originalTotalPriceRequired: boolean | null | undefined
}

export const TotalPricingModelSelect = ({
	originalTotalPriceRequired,
}: TotalPricingModelSelectProps) => {
	const totalPriceRequired = useWatch({
		name: 'totalPriceRequired',
	})
	const isFirmAllowedToChangeTotalPriceModel = useWatch({
		name: 'isFirmAllowedToChangeTotalPriceModel',
	})

	return (
		<PricingModelSelect
			id="totalPriceModel"
			required={!!totalPriceRequired}
			name={'totalPriceModel'}
			type="form"
			disabled={shouldDisableTotalPricingModelSelect({
				totalPriceRequired,
				isFirmAllowedToChangeTotalPriceModel,
				originalTotalPriceRequired,
			})}
		/>
	)
}

const shouldDisableTotalPricingModelSelect = ({
	originalTotalPriceRequired,
	totalPriceRequired,
	isFirmAllowedToChangeTotalPriceModel,
}) => {
	return (
		!totalPriceRequired || (originalTotalPriceRequired && !isFirmAllowedToChangeTotalPriceModel)
	)
}
