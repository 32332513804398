import { Fragment } from 'react'
import { DeepPick } from '@persuit/common-utils'
import { Rfp } from '../../../types'
import { HeadlineSeparator } from '../headline-separator'
import { RichTextView } from '@persuit/ui-components'
import isEmpty from 'lodash/fp/isEmpty'

export type RfpDetailSummarySectionDocType = DeepPick<Rfp, 'detail.scopeOfWork'>

type RfpDetailSummarySectionProps = {
	document: RfpDetailSummarySectionDocType
}

export const RfpDetailSummarySection = ({ document }: RfpDetailSummarySectionProps) => {
	const scopeOfWork = document?.detail?.scopeOfWork ?? ''

	if (isEmpty(scopeOfWork)) return null

	return (
		<Fragment>
			<HeadlineSeparator headline="Summary" />
			<RichTextView content={scopeOfWork} data-testid="rfp-summary" />
		</Fragment>
	)
}
