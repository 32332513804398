import { useState, useEffect } from 'react'
import { formatDistanceToNowStrict, isAfter } from 'date-fns'
import { Typography } from '@persuit/ui-components'

export type DueDateTickerProps = {
	prefix: string
	dueDate: number | Date
}

export const DueDateTicker = ({ prefix, dueDate }: DueDateTickerProps) => {
	const [, setTime] = useState(new Date())

	useEffect(() => {
		const updateComponentTimer = setInterval(() => {
			setTime(new Date())
		}, 1000)

		return function componentWillUnmount() {
			clearInterval(updateComponentTimer)
		}
	}, [])

	if (!dueDate) {
		return null
	}

	const currentDateObj = new Date()
	const dueDateObj = new Date(dueDate)

	if (isAfter(currentDateObj, dueDateObj)) {
		return null
	}

	const durationToDate = formatDistanceToNowStrict(dueDate, {
		addSuffix: true,
		roundingMethod: 'ceil',
	})

	return (
		<Typography>
			{prefix} {durationToDate}
		</Typography>
	)
}
