import { Box, Typography } from '@persuit/ui-components'
import { TotalPriceOrComparisonValueDataType } from '../../../presentation-data'

export const TotalPriceOrComparisonValue = ({
	title,
	originalValue,
	currentValue,
	isPending,
}: TotalPriceOrComparisonValueDataType) => {
	return (
		<Box>
			{currentValue && (
				<Typography variant="caption" marginTop="8px">
					{title}
					{originalValue && (
						<Typography variant="caption" strikeThrough={true}>
							{' '}
							{originalValue}
						</Typography>
					)}
					<Typography variant="caption" fontWeight="bold">
						{' '}
						{currentValue}
					</Typography>
				</Typography>
			)}
			{isPending && (
				<Typography variant="caption" marginTop="8px" color="error">
					{' '}
					(pending)
				</Typography>
			)}
		</Box>
	)
}
