import { graphql } from '@persuit/ui-graphql'

const reviseProposal = graphql(`
	mutation ReviseProposal($_id: ID!) {
		underRevisionProposal(_id: $_id) {
			...RfpProposal
		}
	}
`)

export default reviseProposal
