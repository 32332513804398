import proposalStates from '../../../../common/data/rfp-proposal-states'
import useTranslation from '../../../custom-hooks/translation-hook'
import formatCurrency from '../../../utils/format-currency'
import NamwolfLogo from '../../namwolf/namwolf-logo'
import { Box, HistoryIcon, Typography } from '@persuit/ui-components'

export default function ManageProposalWizardHeader({
	currency,
	firmName,
	isNamwolfMember,
	proposalStatus,
	totalPriceValue,
	totalPriceModel,
	// isPendingPrice,
}) {
	const {
		translations: [tTotalPricingModel],
	} = useTranslation([
		{
			path: `feeBreakdown.pricingModels.firmCard.${
				totalPriceModel ? totalPriceModel : 'notApplicable'
			}`,
		},
	])
	// If totalPriceModel is not selected - show empty values for total price and pricing model
	const totalPriceValueFormatted = totalPriceModel ? formatCurrency(totalPriceValue, currency) : ''
	const totalPricingModelFormatted = totalPriceModel ? tTotalPricingModel : ''

	return (
		<Box>
			<Box display="flex">
				<Typography variant="body2">{firmName}</Typography>
				<Box ml={0.5}>{isNamwolfMember && <NamwolfLogo />}</Box>
			</Box>
			{totalPriceValueFormatted && (
				<Typography variant="body1">
					{totalPriceValueFormatted}, {totalPricingModelFormatted}
				</Typography>
			)}
			{proposalStatus === proposalStates.UNDER_REVISION && (
				<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
					<HistoryIcon color="error" width="20px" />

					<Typography variant="body1" color="error">
						Under Revision
					</Typography>
				</Box>
			)}
		</Box>
	)
}
