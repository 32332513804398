import { UseCase } from '@persuit/ui-graphql/generated'

export type UseIsSectionEnabledArgs = {
	isDiversitySectionEnabled: boolean
	useCase?: UseCase
}

export type Section = (typeof allSections)[number]

export function getIsSectionEnabled({
	isDiversitySectionEnabled,
	useCase,
}: UseIsSectionEnabledArgs): (section: Section) => boolean {
	return (section) => {
		switch (section) {
			case 'pricing':
				return useCase !== 'rateReview'
			case 'rates':
				return useCase === 'rateReview'
			case 'auction':
				return useCase !== 'rateReview'
			case 'diversity':
				return isDiversitySectionEnabled && useCase !== 'rateReview'
			case 'conflict':
				return useCase !== 'rateReview'
			default:
				return true
		}
	}
}

export const allSections = [
	'detail',
	'summary',
	'rates',
	'pricing',
	'questions',
	'diversity',
	'auction',
	'conflict',
	'scorecard',
] as const
