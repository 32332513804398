import { Box, Typography } from '@persuit/ui-components'
import { useTranslation } from 'react-i18next'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'
import { makeFormatMessageAtSafe } from '../../util'

const formatMessageAtSafe = makeFormatMessageAtSafe('h:mm a')

const MESSAGE_FRAGMENT = graphql(`
	fragment MessagesChannelPaneSystemMessage on Message {
		messageAt
		botMessage {
			translationKey
			translationReplacements
		}
	}
`)

export type SystemMessageProps = {
	message: FragmentType<typeof MESSAGE_FRAGMENT>
	showTime: boolean
}

export const SystemMessage = ({ message, showTime }: SystemMessageProps) => {
	const { messageAt, botMessage } = getFragment(MESSAGE_FRAGMENT, message)
	const { t } = useTranslation()

	const date = formatMessageAtSafe(messageAt)

	const messageText = botMessage
		? t(botMessage?.translationKey ?? '', botMessage?.translationReplacements)
		: ''

	return (
		<Box
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			role="listitem"
			textAlign="center"
			mt={showTime ? 2 : 0}
		>
			{showTime && (
				<Typography color="text.secondary" variant="caption">
					{date}
				</Typography>
			)}
			<Typography color="text.secondary" variant="body1">
				<i>{messageText}</i>
			</Typography>
		</Box>
	)
}
