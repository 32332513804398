import { SvgIcon, SvgIconProps } from '../components/svg'

export const ZipFileIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_4860_57838)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.0039 7.28885H16C15.7731 7.29777 15.5468 7.25977 15.3351 7.17718C15.1233 7.0946 14.9305 6.96917 14.7686 6.80863C14.6067 6.64808 14.479 6.45581 14.3935 6.24366C14.3081 6.0315 14.2665 5.80394 14.2715 5.57498V1.48338H4.10157C4.08656 1.48364 4.07175 1.48691 4.058 1.493C4.04425 1.49909 4.03184 1.50787 4.02149 1.51884C4.00884 1.52821 3.99891 1.54082 3.99273 1.55537C3.98654 1.56992 3.98434 1.58588 3.98633 1.60158V22.4004C3.98897 22.4305 4.00132 22.4588 4.02149 22.4812C4.03128 22.4928 4.04359 22.5021 4.05747 22.5082C4.07134 22.5144 4.08643 22.5172 4.10157 22.5166H19.8906C19.9258 22.5166 19.9238 22.4989 19.9414 22.4812C19.959 22.4634 20.0078 22.426 20.0078 22.4004V7.28885H20.0039ZM21.4629 22.8673C21.4624 23.1681 21.3437 23.4564 21.1328 23.6691C20.922 23.8817 20.6361 24.0014 20.3379 24.002H3.64454C3.34633 24.0014 3.06048 23.8817 2.84961 23.6691C2.63875 23.4564 2.52005 23.1681 2.51954 22.8673V1.1347C2.51904 0.985646 2.54798 0.837989 2.60465 0.700333C2.66132 0.562678 2.74459 0.43778 2.84962 0.332923C3.06048 0.120239 3.34633 0.000522375 3.64454 0L15.0195 0C15.1925 0.00165137 15.3606 0.0581657 15.5 0.161537L21.3106 6.09505C21.3808 6.15758 21.4323 6.23869 21.4592 6.3292C21.4862 6.41972 21.4874 6.51603 21.4629 6.60724V22.8653V22.8673ZM15.625 5.45481L15.541 1.89707L19.6602 6.10687L16.1172 5.9473C16.052 5.95066 15.9869 5.94024 15.9259 5.91673C15.8649 5.89322 15.8095 5.85713 15.7632 5.81077C15.7169 5.76441 15.6806 5.7088 15.6568 5.64751C15.633 5.58621 15.6222 5.52058 15.625 5.45481Z"
					fill="black"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.31055 9.11694H22.6895C23.0371 9.11798 23.3701 9.25786 23.6157 9.50595C23.8613 9.75404 23.9995 10.0902 24 10.4408V19.3253C23.999 19.6755 23.8606 20.0111 23.615 20.2588C23.3695 20.5065 23.0367 20.6461 22.6895 20.6471H1.31055C0.962948 20.6461 0.629909 20.5062 0.384301 20.2581C0.138693 20.01 0.000514961 19.6739 0 19.3233L0 10.4408C0.00103046 10.0905 0.139436 9.75489 0.384989 9.50722C0.630541 9.25955 0.963285 9.11995 1.31055 9.11891V9.11694Z"
					fill="#4A398C"
				/>
				<path
					d="M4.41992 11.7764H9.79297V13.0175L6.3418 16.6501H9.92383V17.9857H4.08398V16.6974L7.5 13.1022H4.41992V11.7764ZM11.1406 11.7764H13.0488V17.9857H11.1328V11.7764H11.1406ZM14.6875 11.7764H17.8516C18.541 11.7764 19.0566 11.9399 19.4023 12.2709C19.748 12.6018 19.9219 13.0746 19.9219 13.6853C19.9372 13.9568 19.895 14.2285 19.798 14.4823C19.701 14.736 19.5515 14.966 19.3594 15.1569C18.9863 15.5115 18.4141 15.6868 17.6465 15.6868H16.6016V17.9857H14.6875V11.7764ZM16.6016 14.43H17.0703C17.3454 14.4558 17.6209 14.3863 17.8516 14.233C17.9232 14.1728 17.9804 14.097 18.019 14.0114C18.0575 13.9258 18.0764 13.8325 18.0742 13.7385C18.0759 13.6468 18.0595 13.5557 18.026 13.4705C17.9925 13.3853 17.9425 13.3076 17.8789 13.2421C17.75 13.1062 17.5059 13.0451 17.1465 13.0451H16.6016V14.4241V14.43Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4860_57838">
					<rect width={24} height={24} fill="white" />
				</clipPath>
			</defs>
		</SvgIcon>
	)
}
