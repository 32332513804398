import { useState, useRef, useEffect } from 'react'
import { graphql, FragmentType, getFragment } from '@persuit/ui-graphql'
import { Box, CardProps, Card } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { styled } from '@mui/material/styles'
import { TitleCard } from './title-card'
import { DescriptionCard } from './description-card'
import { NewFeatureCornerBanner } from '@persuit/ui-shared-components'
import { useUserDismissibles } from '@persuit/ui-hooks'

const RfpFragment = graphql(`
	fragment TemplateCard_RfpTemplate on RfpTemplatePublished {
		templateType
		useCase
		...TemplateDescriptionCard_RfpTemplate
		...TemplateTitleCard_RfpTemplate
	}
`)

type TemplateCardProps = {
	templateFragment: FragmentType<typeof RfpFragment>
} & CardProps

export const TemplateCard = ({ templateFragment, ...cardProps }: TemplateCardProps) => {
	const template = getFragment(RfpFragment, templateFragment)
	const systemTemplate = template.templateType === 'system'
	const [truncateWidth, setTruncateWidth] = useState<number | null>(null)
	const containerRef = useRef<HTMLElement | null>(null)
	const { dismissibles, dismiss } = useUserDismissibles()

	function updateTruncateWidth(el: HTMLElement) {
		setTruncateWidth((el.getBoundingClientRect().width - HORIZONTAL_PADDING * 2) * 0.9)
	}

	useEffect(() => {
		// measure and set the initial truncateWidth
		if (containerRef.current) {
			updateTruncateWidth(containerRef.current)
		}
	}, [])

	const showNewLabel =
		template.useCase === 'rateReview' &&
		template.templateType === 'system' &&
		!dismissibles.has('TemplateListRateReviewCard')

	return (
		<Container
			ref={containerRef}
			component={Card}
			{...cardProps}
			data-trackid="listitem-template-card"
			onPointerEnter={(e) => updateTruncateWidth(e.currentTarget)}
		>
			{showNewLabel && <NewFeatureCornerBanner />}
			<TitleCardContainer>
				<TitleCard templateFragment={template} />
			</TitleCardContainer>

			<DescriptionCardContainer>
				<DescriptionCard
					templateFragment={template}
					truncateWidth={truncateWidth}
					onPreviewOrUseTemplate={() =>
						showNewLabel ? dismiss('TemplateListRateReviewCard') : null
					}
				/>
			</DescriptionCardContainer>

			{systemTemplate ? <LeftHighlightBorder /> : null}
		</Container>
	)
}

const LeftHighlightBorder = styled(Box)`
	position: absolute;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	top: 0px;
	left: 0px;
	height: 100%;
	background: ${({ theme }) => theme.palette.primary.main};
	width: 8px;
`

const HORIZONTAL_PADDING = 32

const CardContainer = styled(Box)`
	min-height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	padding: 16px ${HORIZONTAL_PADDING}px;

	& > * {
		flex-grow: 1;
	}
`

const TitleCardContainer = styled(CardContainer)``

const DescriptionCardContainer = styled(CardContainer)`
	display: none;
`

const Container = styled(Box)`
	position: relative;
	margin-bottom: 24px;

	&:hover ${TitleCardContainer}, &:focus-within ${TitleCardContainer} {
		color: red;
		display: none;
	}

	&:hover ${DescriptionCardContainer}, &:focus-within ${DescriptionCardContainer} {
		display: flex;
	}
`
