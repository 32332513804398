import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { styled } from '@persuit/ui-components'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { trackWithPendo } from '../../../../track-event'
import { stepIndex } from '../../utils.js'
import { graphql } from '@persuit/ui-graphql'
export const SAVE_REVISION_COMMENT = graphql(`
	mutation ManageProposal_SaveRevisionComment($revisionCommentInput: RevisionCommentInput!) {
		saveRevisionComment(revisionCommentInput: $revisionCommentInput) {
			_id
		}
	}
`)

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

function OptionRequestProposalRevisionConfirm({ onCancel, onDone, onPrev, proposalId, requestId }) {
	const [saveRevisionComment, { loading, error }] = useMutation(SAVE_REVISION_COMMENT, {
		variables: { revisionCommentInput: { proposalId, rateReview: [], namedTimekeepers: [] } },
	})
	const history = useHistory()

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Negotiate',
			}}
			isWorking={loading}
			onConfirm={async () => {
				await saveRevisionComment()
				trackWithPendo('manage_proposal::ask_firm_revise_proposal', { requestId, proposalId })
				history.push(`/en/request/${requestId}/proposals/revise/${proposalId}/`)
				onDone()
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />
			<Text type="body1" black={true}>
				Negotiate with your firm by red-lining suggested changes to the proposal in the next step.
			</Text>
			<Spacer />
			<StyledList>
				<li>
					<Text type="body1" black={true}>
						This is private and confidential.
					</Text>
				</li>
				<li>
					<Text type="body1" black={true}>
						You can negotiate as many times as needed.
					</Text>
				</li>
			</StyledList>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionRequestProposalRevisionConfirm
