import { useId } from 'react'
import { Box, Typography, useTheme } from '@persuit/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { PricingGroup, Deliverable } from '../../../../../types'
import { DeliverableContainer } from '../deliverable-container'

type GroupContainerProps = {
	group: PricingGroup
	index: number
}

export const GroupContainer = ({ group, index }: GroupContainerProps) => {
	const theme = useTheme()
	const groupHeadingId = useId()

	return (
		<Box>
			<Typography
				variant="h3XSmall"
				id={groupHeadingId}
				style={{
					fontStyle: group.title ? 'inherit' : 'italic',
				}}
			>
				{group.title || 'Untitled Group'}
			</Typography>

			{!isEmpty(group.deliverables) && (
				<Box
					style={{ border: `1px solid ${theme.palette.grey500}` }}
					padding={1}
					display="flex"
					flexDirection="column"
					gap={theme.spacing(2)}
					role="group"
					aria-labelledby={groupHeadingId}
				>
					{group.deliverables.map((groupedDeliverable, innerIndex) => {
						return (
							<DeliverableContainer
								key={`D${index + innerIndex}`}
								floating={false}
								deliverable={groupedDeliverable as Deliverable}
								index={index + innerIndex}
							/>
						)
					})}
				</Box>
			)}
		</Box>
	)
}
