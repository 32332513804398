import { currencies as currenciesObj, currencyCodes } from '@persuit/app-currencies'
import formatCurrency from './format-currency'

export const currencies = Object.values(currenciesObj)
export const codes = currencyCodes
export default currenciesObj

export type Currency = keyof typeof currenciesObj

export { formatCurrency }
