import { useEffect } from 'react'
import * as React from 'react'
import { styled } from '../../theme'
import { OverrideNativeElementProps, colors } from '../../utilities'

const TitleInput = styled('input')<{
	error: EditableTitleRawProps['error']
}>(
	({ theme, error }) => `
	border: 2px solid;
	border-color: ${error ? theme.palette.error.main : theme.palette.white};
	padding: 4px 8px;
	background-color: ${theme.palette.white};
	max-width: 100%;
	font-size: ${theme.typography.h5.fontSize};
	border-radius: 4px;
	box-sizing: content-box;
	&:hover {
		border: 2px solid ${colors.transparentize(theme.palette.primary.main, 0.3)};
	}

	&:focus-visible {
		outline: none;
		border: 2px solid ${theme.palette.primary.main};
	}
`,
)

const TitleSizing = styled('span')`
	font-size: ${({ theme }) => theme.typography.h5.fontSize};
	pointer-events: none;
	padding: 4px 8px;
	position: absolute;
	visibility: hidden;
`

type EditableTitleRawProps = {
	initialValue?: string
	/** Whether the input has error */
	error?: string
	/** onChange callback whereas the title text value is returned  */
	onChange?: (value: string) => void
	/** Enforce aria-label usage for accessibility purpose */
	'aria-label': string
}

// TODO: Probably a more generic way to better type ref with Styled Components?
// See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
export type EditableTitleProps = Omit<
	OverrideNativeElementProps<EditableTitleRawProps, 'input'>,
	'ref'
>

export const EditableTitle = React.forwardRef(
	(
		{ initialValue, placeholder, onChange, error, ...inputProps }: EditableTitleProps,
		ref: React.Ref<HTMLInputElement>,
	) => {
		const [value, setValue] = React.useState('')
		const [width, setWidth] = React.useState(0)
		const spanRef = React.useRef<HTMLSpanElement>(null)

		useEffect(() => {
			setWidth(spanRef?.current?.offsetWidth || 0)
		}, [value])

		useEffect(() => {
			if (initialValue && !initialValue.match(/untitled request/i)) {
				setValue(initialValue)
			}
		}, [initialValue])

		const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
			if (e.key === 'Enter') {
				e.currentTarget.blur()
			}
		}

		return (
			<>
				<TitleInput
					data-trackid="input-untitled-request"
					data-testid="untitled-request"
					error={error}
					style={{ width }}
					onKeyDown={onKeyDown}
					onChange={(e) => setValue(e.target.value)}
					onBlur={(e) => typeof onChange === 'function' && onChange(e.target.value)}
					value={value}
					ref={ref}
					placeholder={placeholder}
					aria-invalid={error ? 'true' : 'false'}
					aria-errormessage={error}
					aria-required={inputProps.required ? 'true' : 'false'}
					{...inputProps}
				/>
				<TitleSizing ref={spanRef}>{value || placeholder}</TitleSizing>
			</>
		)
	},
)

EditableTitle.displayName = 'EditableTitle'
