import { userActivityActions } from '../actions'

const initialState = {
	notificationsLastViewed: new Date().toISOString(), // Now
}

export default function userActivityReducer(state = initialState, action) {
	switch (action.type) {
		// Save and fetch do the same thing
		case userActivityActions.SAVED_USER_ACTIVITY:
		case userActivityActions.FETCH_USER_ACTIVITY: {
			return {
				...state,
				...action.userActivity,
			}
		}

		default: {
			return state
		}
	}
}
