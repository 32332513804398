import {
	Box,
	Typography,
	Checkbox,
	StarIcon,
	StarBorderIcon,
	ListItem,
	IconButton,
	ListItemIcon,
	useTheme,
	useSnackbar,
} from '@persuit/ui-components'
import { useInviteContext } from '../invite-context'
import { Person } from '../types'
import { ViewInfoButton } from '../view-info-button'
import { hasId } from '../utils'

export type FirmListUserProps = {
	person: Person
	isNamwolfMember: boolean
}

export const FirmListUser = ({ person, isNamwolfMember }: FirmListUserProps) => {
	const theme = useTheme()
	const { usersToAdd, onAdd, onRemove, isDraftRfp, addToFavourites, selectedUsers } =
		useInviteContext()
	const { openSnackbar } = useSnackbar()
	const previouslySaved = !!selectedUsers.find((selected) => selected._id === person._id)
	const userToAdd = usersToAdd.some((userObj) => hasId(userObj) && userObj._id === person._id)

	const addUser = async () => {
		await onAdd([
			{
				...person,
				org: {
					...person.org,
					isNamwolfMember,
				},
			},
		])
		openSnackbar('A contact has been added to the Invitation List')
	}

	const removeUser = async () => {
		await onRemove([person._id])
		openSnackbar('A contact has been removed from the Invitation List')
	}

	return (
		<ListItem
			secondaryAction={
				<ViewInfoButton
					person={person}
					addUser={() => addUser()}
					removeUser={() => removeUser()}
					isAdded={previouslySaved}
					isDraftRfp={isDraftRfp}
					isNamwolfMember={isNamwolfMember}
				/>
			}
		>
			<ListItemIcon>
				<Checkbox
					edge="start"
					checked={previouslySaved || userToAdd}
					disabled={!isDraftRfp && previouslySaved}
					inputProps={{
						'aria-label': `${person.name}, ${previouslySaved ? 'selected' : 'not selected'}`,
					}}
					onChange={async (e) => {
						if (e.target.checked) {
							addUser()
						} else {
							removeUser()
						}
					}}
				/>

				<IconButton
					size="large"
					onClick={(e) => {
						e.stopPropagation()
						addToFavourites(person._id)
					}}
					aria-label={`${person.isFavourite ? 'remove from favorites' : 'add to favorites'}, ${
						person.name
					}`}
				>
					{person.isFavourite ? (
						<StarIcon sx={{ color: theme.palette.primary.main }} />
					) : (
						<StarBorderIcon sx={{ color: theme.palette.primary.main }} />
					)}
				</IconButton>
			</ListItemIcon>

			<Box>
				<Typography fontWeight="medium">{person.name}</Typography>
				<Typography variant="body2">{person.title}</Typography>
			</Box>
			{person.location && (
				<Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
					<Typography>{person.location}</Typography>
				</Box>
			)}
		</ListItem>
	)
}
