import { Link } from 'react-router-dom'

import {
	IconButton,
	HelpOutlineIcon,
	Tooltip,
	Badge,
	Box,
	NotificationsIcon,
	useTheme,
	Button,
} from '@persuit/ui-components'

import UnreadMessagesNavItem from '../messaging/unreads-nav/nav-item'
import { MyProfileMenu, ProfileProps } from './my-profile-menu'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

export type RightNavActionsProps = {
	notificationCount: number | null
	profileProps: ProfileProps
}

export const RightNavActions = ({ notificationCount, profileProps }: RightNavActionsProps) => {
	const theme = useTheme()
	const { toggles } = useFeatureToggles()

	return (
		<Box display="flex" alignItems="center">
			{toggles['operation.persuit-academy']?.enabled && (
				<Box bgcolor={'common.white'} borderRadius={'5px'}>
					<Button
						variant="outlined"
						data-trackid="button-guide-trigger"
						component={Link}
						to={toggles['operation.persuit-academy'].triggerBtnUrl ?? '#'}
					>
						{toggles['operation.persuit-academy'].triggerBtnText ?? 'Start guided walkthrough'}
					</Button>
				</Box>
			)}

			<UnreadMessagesNavItem />

			<Tooltip title="Help and FAQ">
				<IconButton
					aria-label="Help and FAQ"
					style={{ color: 'white' }}
					size="large"
					LinkComponent="a"
					target="_new"
					href="https://support.persuit.com"
				>
					<HelpOutlineIcon />
				</IconButton>
			</Tooltip>

			<Tooltip title="Notifications">
				<IconButton
					component={Link}
					to="/en/notifications/"
					aria-label={`${notificationCount} unread notifications`}
					style={{ color: 'white' }}
				>
					<Badge
						color="error"
						badgeContent={notificationCount}
						sx={{
							'& .MuiBadge-badge': {
								backgroundColor: theme.palette.error.main,
							},
						}}
					>
						<NotificationsIcon />
					</Badge>
				</IconButton>
			</Tooltip>

			<MyProfileMenu profileProps={profileProps} />
		</Box>
	)
}
