// @ts-strict-ignore
import { graphql, useMutation } from '@persuit/ui-graphql'
import FileSaver from 'file-saver'
import { useState, Fragment } from 'react'

import exportToExcelMutation from '../../../graphql/mutations/responseCompareExportToExcel-gql'
import responsePricingExportToExcelMutation from '../../../graphql/mutations/responsePricingExportToExcel-gql'

import { Button, DownloadIcon, IconButton, Dialog, Box } from '@persuit/ui-components'
import ExcelExportDialog from './export-dialog'
import ExcelExportDownloadOptions from './export-download-options'
import { usePrint } from '../use-print'

// eslint-disable-next-line no-restricted-imports
import { useRateCardExportMutation } from '@persuit/ui-graphql/hooks'

graphql(`
	mutation RateCardExport($rfpId: ID!) {
		rateCardExcelExport(rfpId: $rfpId) {
			data
		}
	}
`)

type ExportCellProps = {
	classes: Record<string, unknown>
	includeEliminatedProposals: boolean
	includeRevisedProposals: boolean
	includeWithdrawnProposals: boolean
	requestId: string
	style: Record<string, unknown>
	type: string
}

const ExportCell = ({
	includeEliminatedProposals,
	includeRevisedProposals,
	includeWithdrawnProposals,
	requestId,
	type,
}: ExportCellProps) => {
	const [hasError, setHasError] = useState(false)
	const [isGeneratingExcel, setIsGeneratingExcel] = useState(false)
	const [showDialog, setShowDialog] = useState(false)
	const [downloadOption, setDownloadOption] = useState(ExcelExportDownloadOptions.PRICING_ONLY)
	const [exportToExcel] = useMutation(exportToExcelMutation)
	const [responsePricingExportToExcel] = useMutation(responsePricingExportToExcelMutation)
	const [rateCardExcelExport] = useRateCardExportMutation()

	const dismissError = () => {
		setHasError(false)
	}

	const { print } = usePrint()

	const handleExport = async () => {
		// Special case for print/PDF
		if (downloadOption === ExcelExportDownloadOptions.PRINT_PDF) {
			// Hide the dialog
			setShowDialog(false)

			return print()
		}

		// Normal cases for Excel files

		setIsGeneratingExcel(true)

		const data = {
			rfpId: requestId,
			includeWithdrawnProposals,
			includeEliminatedProposals,
			includeRevisedProposals,
		}

		try {
			let result: any = null
			let name = ''

			if (downloadOption === ExcelExportDownloadOptions.DETAILED) {
				result = await exportToExcel({
					variables: data,
				})
				name = 'Detailed Comparison'
			} else if (downloadOption === ExcelExportDownloadOptions.PRICING_ONLY) {
				result = await responsePricingExportToExcel({
					variables: data,
				})
				name = 'Price Comparison'
			} else if (downloadOption === ExcelExportDownloadOptions.RATE_CARD_ONLY) {
				result = await rateCardExcelExport({ variables: { rfpId: requestId } })
				name = 'Rate card'
			} else {
				throw new Error('Invalid download option')
			}

			setIsGeneratingExcel(false)

			const fileData = result.data[Object.keys(result.data)[0]].data
			const buffer = Buffer.from(fileData, 'base64')

			FileSaver.saveAs(new Blob([buffer], { type: '' }), `${name}.xlsx`)
		} catch (error) {
			console.error('Excel export failed', error)
			setHasError(true)
		} finally {
			setShowDialog(false)
			setIsGeneratingExcel(false)
			setDownloadOption(ExcelExportDownloadOptions.PRICING_ONLY)
		}
	}

	const exportBtnLabel = 'Export comparison table proposals'

	return (
		<Fragment>
			<ExcelExportDialog
				handleConfirm={handleExport}
				// Don't allow the dialog to be dismissed while the excel file is being
				// generated. A way around this is to avoid passing in a handleCancel function
				handleCancel={isGeneratingExcel ? () => null : () => setShowDialog(false)}
				open={showDialog}
				downloadOption={downloadOption}
				downloadInProgress={isGeneratingExcel}
				setDownloadOption={(value: string) => setDownloadOption(value)}
			/>

			<Dialog
				title="An error occurred while attempting to export to MS Excel"
				open={hasError}
				onClose={dismissError}
				size="sm"
				actions={[{ label: 'Close', variant: 'primary', onClick: dismissError }]}
			>
				Please contact our support team at support@persuit.com for assistance.
			</Dialog>

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 'max-content',
				}}
				data-testid="excel-export-button"
			>
				{type === 'icon' ? (
					<IconButton
						color="primary"
						onClick={() => {
							setShowDialog(true)
						}}
						aria-label={exportBtnLabel}
					>
						<DownloadIcon />
					</IconButton>
				) : (
					<Button
						variant="outlined"
						startIcon={<DownloadIcon />}
						onClick={() => {
							setShowDialog(true)
						}}
						aria-label={exportBtnLabel}
					>
						EXPORT
					</Button>
				)}
			</Box>
		</Fragment>
	)
}

export default ExportCell
