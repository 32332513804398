import { Fragment } from 'react'
import { Box, Typography, styled, useTheme } from '@persuit/ui-components'
import { CombinedRates } from '../../types'
import { RateCardItem } from '../rate-card-item'
import { getPricingModelString } from '../../../common-util'
import { GRID_TEMPLATE_COLUMNS } from '../constants'
import { RateCardAverage } from './rate-card-average'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'

const RowSpanBox = styled(Box)<{ rowSpanLength: number }>`
	grid-row-start: 1;
	grid-row-end: ${(props) => props.rowSpanLength};
`

export type RateCardItemProps = {
	index: number
	currency: string
	title: string
	combinedRates: CombinedRates[]
	id: string
	pricingModel: string
	editable?: boolean
	showAverageRate?: boolean
}

export const RateCardList = ({
	index,
	currency,
	title,
	combinedRates,
	pricingModel,
	editable = true,
	showAverageRate = true,
}: RateCardItemProps) => {
	const theme = useTheme()

	return (
		<Box
			width="100%"
			display="grid"
			gridTemplateRows="auto"
			alignItems="start"
			rowGap={theme.spacing(1)}
			gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
			role="row"
		>
			<Fragment>
				<RowSpanBox rowSpanLength={combinedRates.length + 1}>
					<Typography>{index}</Typography>
				</RowSpanBox>
				<RowSpanBox rowSpanLength={combinedRates.length + 1} role="cell" aria-label="Item details">
					<Typography>{title}</Typography>
					<Typography variant="caption" color="text.secondary">
						{getPricingModelString(pricingModel)}
					</Typography>
				</RowSpanBox>
				{combinedRates.map((rateItem, rateIndex) => (
					<RateCardItem
						key={rateIndex}
						index={rateIndex}
						currency={currency}
						originalRate={rateItem.rate ?? 0}
						title={rateItem.title}
						deliverableIndex={index}
						id={rateItem._id}
						editable={editable}
					/>
				))}
				{showAverageRate && (
					<RateCardAverage rates={combinedRates.filter(isNotNil).filter(isNotNilProp('rate'))} />
				)}
			</Fragment>
		</Box>
	)
}
