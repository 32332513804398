import { SvgIcon, SvgIconProps } from '../components/svg'

export const RevisePriceIcon = (props: SvgIconProps) => (
	<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
		<switch>
			<g>
				<path d="M18.68,10.48v1.75c1.96,0.69,3.36,2.55,3.36,4.74c0,2.78-2.26,5.04-5.04,5.04c-2.78,0-5.04-2.26-5.04-5.04 c0-1.39,0.58-2.64,1.49-3.54l2.7,2.7v-5.88h-5.88l1.97,1.97c-1.22,1.22-1.97,2.89-1.97,4.74c0,3.71,3.01,6.71,6.72,6.71 c3.71,0,6.72-3,6.72-6.71C23.72,13.84,21.58,11.23,18.68,10.48z" />
				<path d="M16.17,10.25v5.88l-2.7-2.7c-0.92,0.91-1.49,2.15-1.49,3.54c0,2.78,2.26,5.04,5.04,5.04c2.78,0,5.04-2.26,5.04-5.04 c0-2.19-1.4-4.06-3.36-4.74v-1.75c2.9,0.75,5.04,3.36,5.04,6.49c0,3.71-3.01,6.71-6.72,6.71c-3.71,0-6.72-3-6.72-6.71 c0-1.86,0.76-3.53,1.97-4.74l-1.97-1.97H16.17 M16.39,10.03h-0.22h-5.88H9.75l0.38,0.38l1.82,1.82 c-1.22,1.29-1.88,2.97-1.88,4.74c0,3.82,3.11,6.93,6.94,6.93c3.83,0,6.94-3.11,6.94-6.93c0-3.16-2.14-5.92-5.21-6.71l-0.28-0.07 v0.29v1.75v0.16l0.15,0.05c1.92,0.68,3.21,2.5,3.21,4.53c0,2.65-2.16,4.81-4.81,4.81c-2.65,0-4.81-2.16-4.81-4.81 c0-1.2,0.45-2.33,1.27-3.22l2.54,2.54l0.38,0.38v-0.54v-5.88V10.03L16.39,10.03z" />
				<path d="M9.06,17.04c0-1.7,0.54-3.32,1.53-4.68l-1.17-1.17L9.04,10.8L8,9.76c-0.53-0.19-1.08-0.36-1.63-0.5 c-2.64-0.69-3.49-1.4-3.49-2.5c0-1.27,1.17-2.15,3.14-2.15c2.07,0,2.84,0.99,2.91,2.44h2.57c-0.08-2-1.3-3.84-3.73-4.43V0.08H4.28 v2.51c-2.26,0.49-4.07,1.95-4.07,4.2c0,2.69,2.22,4.02,5.47,4.8c2.91,0.7,3.49,1.72,3.49,2.8c0,0.8-0.57,2.08-3.14,2.08 c-2.4,0-3.34-1.07-3.47-2.44H0c0.14,2.55,2.05,3.98,4.28,4.45v2.52h3.49v-2.5c0.49-0.09,0.94-0.23,1.37-0.41 C9.09,17.75,9.06,17.4,9.06,17.04z" />
			</g>
		</switch>
	</SvgIcon>
)
