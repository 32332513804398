import { CardIconBacker } from './card-icon-backer'
import { Typography, CheckIcon } from '@persuit/ui-components'
import { RfpProgressStatusEnum } from '@persuit/ui-graphql/generated'

export type CardIconProps = {
	index: number
	status: RfpProgressStatusEnum
}

export const CardIcon = ({ index, status }: CardIconProps) => {
	if (status === 'completed')
		return (
			<CardIconBacker filled={true} title="Completed">
				<CheckIcon fontSize="small" />
			</CardIconBacker>
		)

	if (status === 'passed')
		return (
			<CardIconBacker filled={true}>
				<Typography variant="body2" style={{ lineHeight: '100%' }} color="inherit">
					<b>{index + 1}</b>
				</Typography>
			</CardIconBacker>
		)

	return (
		<CardIconBacker filled={false}>
			<Typography variant="body2" style={{ lineHeight: '100%' }} color="inherit">
				<b>{index + 1}</b>
			</Typography>
		</CardIconBacker>
	)
}
