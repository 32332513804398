/**
 * This function is used to refocus to reasonable target on removal of pricing group or pricing item
 */
export function refocusOnRemoval({
	currentIndex,
	isSubItem = false,
	subItemParentIndex,
}: {
	currentIndex: number
	isSubItem?: boolean
	subItemParentIndex?: number
}) {
	const focusTargetIndex = currentIndex === 0 ? 1 : currentIndex - 1

	let focusTarget: HTMLElement | null = null

	if (isSubItem && typeof subItemParentIndex !== 'undefined') {
		focusTarget =
			document.getElementById(`pricing_sub_item_${subItemParentIndex}_${focusTargetIndex}`) ??
			document.getElementById(`pricing_item_${subItemParentIndex}`)
	} else {
		focusTarget =
			document.getElementById(`pricing_item_${focusTargetIndex}`) ??
			document.getElementById(`pricing_group_${focusTargetIndex}`) ??
			document.querySelector('[aria-label="Add Item"]')
	}

	if (focusTarget) {
		setTimeout(() => {
			focusTarget?.focus()
		}, 100)
	}
}
