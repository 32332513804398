import {
	useForm,
	FormProvider,
	FieldValues,
	SubmitHandler,
	SubmitErrorHandler,
	UseFormProps,
	UseFormReturn,
} from 'react-hook-form'
import { noop } from '@persuit/common-utils'
import { Box, BoxProps } from '../../box'

export type FormProps<TFieldValues extends FieldValues = FieldValues> = Omit<BoxProps, 'onSubmit'> &
	Omit<JSX.IntrinsicElements['form'], 'onSubmit' | 'defaultValue' | 'defaultChecked' | 'method'> & {
		onSubmit?: SubmitHandler<TFieldValues>
		onSubmitError?: SubmitErrorHandler<TFieldValues>
		/**
		 * You may provide your own react-hook-form context methods here, otherwise one will be created automatically and can be customised using `formOptions`
		 */
		methods?: UseFormReturn<TFieldValues>
		/** The options you would pass to useForm() to create your form context */
		formOptions?: UseFormProps<TFieldValues>
	}

/**
 * A native <form /> element wrapped with FormProvider from react-hook-form.
 */
export function Form<TFieldValues extends FieldValues>({
	methods: propMethods,
	formOptions,
	onSubmit = noop,
	onSubmitError,
	...formProps
}: FormProps<TFieldValues>) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const methods = propMethods ?? useForm<TFieldValues>(formOptions)
	return (
		<FormProvider {...methods}>
			<Box
				component="form"
				{...formProps}
				onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
			/>
		</FormProvider>
	)
}
