// @ts-strict-ignore
import { useState, useRef, useEffect } from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import humanInterval from 'human-interval'

import { IconButton, Tooltip, Badge, Popover, Box, ForumIcon } from '@persuit/ui-components'

import { black, white } from '../../../theme/persuit-colors'
import injectSheet from '../../../injectSheet'

import requestsWithUnreadMessagesQuery from '../../../graphql/queries/messaging/requestsWithUnreadMessages-gql'
import requestsWithUnreadMessagesSubscription from '../../../graphql/subscriptions/messaging/requestsWithUnreadMessages-gql'
import rfpRemovedSubscription from '../../../graphql/subscriptions/rfpRemoved-gql'

import { ChannelsWithUnreads } from './channels-with-unreads'

type GlobalUnreadMessagesProps = {
	classes: any
	requestsWithUnreads: {
		refetch: () => Promise<void>
		loading: boolean
		requestsWithUnreadMessages: {
			totalUnreadCount: number
			requestsWithUnreads: Array<{
				requestId: string
				requestHeadline: string
				unreadCount: number
			}>
		}
	}
	subscribeToRequestRemovals: () => () => void
	subscribeToRequestsWithUnreadMessages: () => () => void
	t: any
}

const GlobalUnreadMessages = ({
	classes,
	requestsWithUnreads: { requestsWithUnreadMessages, refetch },
	t,
	subscribeToRequestRemovals,
	subscribeToRequestsWithUnreadMessages,
}: GlobalUnreadMessagesProps) => {
	const [showUnreadMessagesNotification, setShowMessagesNotifications] = useState(false)
	const unreadMessagesNotificationAnchorRef = useRef<any | null>(null)

	useEffect(() => {
		const unsubscribeToRequestsWithUnreadMessages = subscribeToRequestsWithUnreadMessages()
		const unsubscribeToRequestRemovals = subscribeToRequestRemovals()

		return () => {
			unsubscribeToRequestRemovals()
			unsubscribeToRequestsWithUnreadMessages()
		}
	}, [subscribeToRequestRemovals, subscribeToRequestsWithUnreadMessages])

	const closeUnreadMessagesNotification = () => setShowMessagesNotifications(false)

	const totalUnreadCount = requestsWithUnreadMessages
		? requestsWithUnreadMessages.totalUnreadCount
		: 0

	return (
		<>
			<div ref={unreadMessagesNotificationAnchorRef}>
				<Tooltip title="Messages" disabled={showUnreadMessagesNotification}>
					<IconButton
						aria-label={`${totalUnreadCount} unread messages`}
						className={classes.iconButton}
						onClick={() => setShowMessagesNotifications(true)}
						data-testid="main-nav-unread-messages"
					>
						<Badge badgeContent={totalUnreadCount} color="error">
							<ForumIcon />
						</Badge>
					</IconButton>
				</Tooltip>
			</div>

			<Popover
				open={showUnreadMessagesNotification}
				anchorEl={unreadMessagesNotificationAnchorRef.current}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				onClose={closeUnreadMessagesNotification}
			>
				{requestsWithUnreadMessages && (
					<ChannelsWithUnreads
						requestsWithUnreads={requestsWithUnreadMessages.requestsWithUnreads || []}
						closeUnreadMessagesNotification={closeUnreadMessagesNotification}
						refetchRequestsWithUnreads={refetch}
					/>
				)}
				{!requestsWithUnreadMessages && (
					<Box
						display="block"
						color="text.secondary"
						sx={{
							padding: '4rem 1rem',
							textAlign: 'center',
							maxWidth: '300px',
						}}
					>
						<span>{t('messaging.errors.messagingUnavailable')}</span>
						<br />
						<br />
						<span>{t('messaging.errors.contactSupport')}</span>
					</Box>
				)}
			</Popover>
		</>
	)
}

const styles = {
	iconButton: {
		color: 'inherit',
	},

	icon: {
		color: 'inherit !important',
		fill: 'currentColor !important',
	},

	inverseMenu: {
		background: `${white}`,
		color: `${black} !important`,
		padding: '8px 0px 0px !important',
	},
	messagingUnavailableMessage: {
		padding: '4rem 1rem',
		textAlign: 'center',
		// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
		color: '#999',
		maxWidth: '300px',
	},
}

export default compose(
	withTranslation(),
	injectSheet(styles),
	graphql(requestsWithUnreadMessagesQuery, {
		name: 'requestsWithUnreads',
		options: {
			pollInterval: humanInterval('10 minutes'), // Rerun this query every 10 mins
		},
		props: (props: any) => {
			const { requestsWithUnreads } = props

			return {
				...props,
				subscribeToRequestsWithUnreadMessages: () => {
					return requestsWithUnreads.subscribeToMore({
						document: requestsWithUnreadMessagesSubscription,
						updateQuery: (prev, { subscriptionData: { data: updatedUnreads } }) => {
							if (!updatedUnreads) {
								return prev
							}

							const { requestsWithUnreadMessages } = updatedUnreads

							return {
								requestsWithUnreadMessages,
							}
						},
					})
				},
				subscribeToRequestRemovals: () => {
					return requestsWithUnreads.subscribeToMore({
						document: rfpRemovedSubscription,
						updateQuery: () => {
							// arbitrary wait until channels are deleted before refetch otherwise count is wrong
							setTimeout(() => {
								requestsWithUnreads.refetch()
							}, 3000)
						},
					})
				},
			}
		},
	}),
)(GlobalUnreadMessages)
