import { useStore } from '../../store'

import { copyToClipboard } from '../../utils'
import { PlaceholderLoading } from '../../intelligence/placeholder-loading'
import {
	shortenArrForSummary,
	formatExtendedSummaryForClipboard,
} from '../../intelligence/intelligence-column-utils'
import { useQuestionIntelligence } from '../../intelligence/intelligence-hook'

import { ExpandableIntelligenceCell, ExpandableIntelligenceCellProps } from '../components'

import { Error, formatExtendedSummary, getMaxSummaryLength } from '../../intelligence/components'

type QuestionIntelligenceCellProps = Pick<ExpandableIntelligenceCellProps, 'headers'> & {
	question: any
}

export const QuestionIntelligenceCell = ({ question, headers }: QuestionIntelligenceCellProps) => {
	const { isWide, request } = useStore((state) => ({
		isWide: state.isWideIntelligence,
		request: state.request,
	}))

	const intelligence = useQuestionIntelligence({
		rfpId: request._id,
		questionId: question._id,
	})

	const data: any = intelligence?.intelligence ?? []
	const loading = intelligence?.loading ?? true
	const error = intelligence?.error ?? false

	const maxSummaryLength = getMaxSummaryLength(isWide)

	return (
		<ExpandableIntelligenceCell
			headers={headers}
			expandedContent={
				<>
					{loading && <PlaceholderLoading />}
					{error && <Error code={error} />}
					{!error && !loading && formatExtendedSummary(data)}
				</>
			}
			expandedTitle={question?.title ?? ''}
			copyToClipboard={async () => {
				return await copyToClipboard(formatExtendedSummaryForClipboard(question?.title ?? '', data))
			}}
			copyToClipboardDisabled={!!error || loading}
			data-trackid={question?.title}
		>
			{loading && <PlaceholderLoading />}
			{error && <Error code={error} />}
			{!error && !loading && formatExtendedSummary(shortenArrForSummary(data, maxSummaryLength))}
		</ExpandableIntelligenceCell>
	)
}
