// @ts-strict-ignore
import { Box } from '@persuit/ui-components'
import { PricingCardTemplate } from '../../pricing'
import { PricingSubItemListHeader } from './pricing-sub-item-list-header'
import { PricingSubItemListBody } from './pricing-sub-item-list-body'
import { PricingSubItemListFooter } from './pricing-sub-item-list-footer'
import { useWatch, PricingItemFieldPath } from '../pricing-form-utils'
import {
	usePricingSubItemsFieldArray,
	PricingSubItemListContext,
} from './pricing-sub-item-list-context'
import { filterSubItemPricingModels } from './pricing-sub-item-utils'
import { getOrGenerateUUID } from '@persuit/common-utils'

type PricingSubItemListProps = {
	pricingItemFieldPath: PricingItemFieldPath
	pricingItemIndex: number
}

const createNewSubItem = (
	itemPricingModel,
): { uuid: any; label: string; pricingPreferences?: any } => {
	switch (itemPricingModel) {
		case 'hourlyRates':
			return {
				uuid: getOrGenerateUUID(),
				label: '',
			}
		case 'discountPercentage':
			return {
				uuid: getOrGenerateUUID(),
				label: '',
				pricingPreferences: 'discountPercentage',
			}
		default:
			return {
				uuid: getOrGenerateUUID(),
				label: '',
				pricingPreferences:
					filterSubItemPricingModels({ pricingModel: itemPricingModel }) ?? 'fixedFee',
			}
	}
}

export const PricingSubItemList = ({
	pricingItemFieldPath,
	pricingItemIndex,
}: PricingSubItemListProps) => {
	const pricingSubItemsFieldArray = usePricingSubItemsFieldArray(pricingItemFieldPath)
	const { fields: pricingItemRates, append } = pricingSubItemsFieldArray
	const itemPricingModel = useWatch({
		name: `${pricingItemFieldPath}.pricingPreferences`,
	})

	const createPricingSubItem = () => {
		const newSubItem = createNewSubItem(itemPricingModel)
		append(newSubItem)
	}

	const isRateCard = itemPricingModel === 'hourlyRates'

	if (itemPricingModel === 'hourlyRate' || itemPricingModel === 'contingencyPercentage') {
		return null
	} else {
		return (
			<PricingSubItemListContext.Provider
				value={{
					pricingSubItemsFieldArray: {
						...pricingSubItemsFieldArray,
						createPricingSubItem,
					},
					pricingItemIndex,
					pricingItemFieldPath,
				}}
			>
				<Box margin="0 40px" data-testid="pricing-sub-item-list">
					<PricingCardTemplate
						header={
							<PricingSubItemListHeader
								itemPricingModel={itemPricingModel}
								numOfSubItems={pricingItemRates.length}
							/>
						}
						body={
							<PricingSubItemListBody pricingItemRates={pricingItemRates} isRateCard={isRateCard} />
						}
						footer={<PricingSubItemListFooter isRateCard={isRateCard} />}
					/>
				</Box>
			</PricingSubItemListContext.Provider>
		)
	}
}
