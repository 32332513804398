import { createStore } from '@persuit/ui-state'
// eslint-disable-next-line no-restricted-imports
import { TypedGridColDef } from './named-timekeepers-columns'

export type SortDirection = 'asc' | 'desc'
export type SortType = 'number' | 'percentage' | null
export type Field = TypedGridColDef['field']
type SortState = {
	field: Field
	direction: SortDirection
	type?: SortType
}

export type State = {
	sortState: null | SortState
}

type InitState = {
	field: Field
	direction: SortDirection
}

const store = createStore(
	(input: InitState): State => ({
		sortState: { field: input.field, direction: input.direction, type: null },
	}),
	(set) => ({
		toggleSortState: (sortState: SortState) =>
			set((state) => {
				if (
					state.sortState?.field === sortState.field &&
					state.sortState?.direction === sortState.direction &&
					state.sortState?.type === sortState.type
				) {
					return { sortState: null }
				}

				return { sortState }
			}),

		setSortState: (sortState: { field: Field; direction: SortDirection } | null) =>
			set({ sortState }),
	}),
)

export const { useStore: useSortStore, Provider: SortStoreProvider, useActions } = store
