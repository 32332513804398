import { Box, Typography } from '@persuit/ui-components'

import proposalStates from '../../../../common/data/rfp-proposal-states'
import useTranslation from '../../../custom-hooks/translation-hook'
import formatCurrency from '../../../utils/format-currency'
import NamwolfLogo from '../../namwolf/namwolf-logo'
import { ProposalStateComponent } from '../../status'

function ManageProposalWizardHeader({
	currency,
	firmName,
	isNamwolfMember,
	proposalStatus,
	totalPriceValue,
	totalPriceModel,
	isPendingPrice,
}) {
	const {
		translations: [tTotalPricingModel],
	} = useTranslation([
		{
			path: `feeBreakdown.pricingModels.firmCard.${
				totalPriceModel ? totalPriceModel : 'notApplicable'
			}`,
		},
	])
	// If totalPriceModel is not selected - show empty values for total price and pricing model
	const totalPriceValueFormatted = totalPriceModel ? formatCurrency(totalPriceValue, currency) : ''
	const totalPricingModelFormatted = totalPriceModel ? tTotalPricingModel : ''

	return (
		<Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography>{firmName}</Typography>
				{isNamwolfMember && <NamwolfLogo style={{ marginLeft: '4px' }} />}
			</Box>
			{!isPendingPrice && totalPriceValueFormatted && (
				<Typography variant="body2" color="text.secondary">
					{totalPriceValueFormatted}, {totalPricingModelFormatted}
				</Typography>
			)}
			{proposalStatus === proposalStates.UNDER_REVISION && (
				<Box display="flex">
					<ProposalStateComponent status={proposalStatus} />
				</Box>
			)}
			{isPendingPrice && (
				<Typography color="text.secondary" fontStyle="italic">
					Pending total price
				</Typography>
			)}
		</Box>
	)
}

export default ManageProposalWizardHeader
