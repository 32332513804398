import { ComponentType } from 'react'

import useMUIMediaQuery, { UseMediaQueryOptions } from '@mui/material/useMediaQuery'
import { PersuitTheme } from '../theme'

export function withMediaQuery<T extends string>(propName: T, mediaQueryString: string) {
	return function withMediaQueryHOC<P extends { [K in T]: boolean }>(
		WrappedComponent: ComponentType<P>,
	) {
		const Component = (props: Omit<P, T>) => {
			const mediaQuery = useMediaQuery(mediaQueryString)
			return <WrappedComponent {...{ [propName]: mediaQuery }} {...(props as P)} />
		}
		Component.displayName = `withMediaQueryHOC(${WrappedComponent.displayName})`
		return Component
	}
}
export function useMediaQuery<T = PersuitTheme>(
	queryInput: string | ((theme: T) => string),
	options?: UseMediaQueryOptions,
): boolean {
	return useMUIMediaQuery<T>(queryInput, options)
}
