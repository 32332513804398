import { Box } from '@persuit/ui-components'
import { SavingsSummary } from './savings-summary'

type RfpAnalyticsProps = {
	request: any
	responses: any[]
}

export const RfpAnalytics = ({ request }: RfpAnalyticsProps) => {
	const { _id: requestId } = request

	return (
		<Box m={4}>
			<SavingsSummary requestId={requestId} />
		</Box>
	)
}
