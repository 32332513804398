import { TypographyOptions } from '@mui/material/styles/createTypography'
import { theme } from '../../theme'

const { h1XSmall, h2XSmall, h3XSmall, h4, body1 } = theme.typography as TypographyOptions

const style = `
	.rich-text {
		font-size: ${body1?.fontSize};
		font-weight: ${body1?.fontWeight};
		line-height: ${body1?.lineHeight};
		box-sizing: border-box;
		font-family: ${body1?.fontFamily};
	}

	.rich-text a {
		text-Decoration: none;
	}

	.rich-text p {
		font-size: ${body1?.fontSize};
		font-weight: ${body1?.fontWeight};
		line-height: ${body1?.lineHeight};
	}

	.rich-text ul {
		padding: 0px 0px 0px 24px;
		margin: 0.5em 0 0.5em 0;
	}

	.rich-text ol {
		padding: 0px 0px 0px 24px;
		margin: 0.5em 0 0.5em 0;
	}

	.rich-text h1 {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-size: ${h1XSmall?.fontSize};
		font-weight: ${h1XSmall?.fontWeight};
		line-height: ${h1XSmall?.lineHeight};
		letter-spacing: ${h1XSmall?.letterSpacing};
	}

	.rich-text h2 {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-size: ${h2XSmall?.fontSize};
		font-weight: ${h2XSmall?.fontWeight};
		line-height: ${h2XSmall?.lineHeight};
		letter-spacing: ${h2XSmall?.letterSpacing};
	}

	.rich-text h3 {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-size: ${h3XSmall?.fontSize};
		font-weight: ${h3XSmall?.fontWeight};
		line-height: ${h3XSmall?.lineHeight};
		letter-spacing: ${h3XSmall?.letterSpacing};
	}

	.rich-text h4 {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-size: ${h4?.fontSize};
		font-weight: ${h4?.fontWeight};
		line-height: ${h4?.lineHeight};
		letter-spacing: ${h4?.letterSpacing};
	}

	.mce-edit-focus {
		outline: 0px !important;
	}

	.rich-text table {
		max-width: 100% !important;
		float: none;
	}

	.rich-text table td {
		word-break: break-word;
	}
`

export default style
