import { useState } from 'react'
import { graphql, FragmentType, getFragment, useMutation } from '@persuit/ui-graphql'
import { Box, Typography, Button, CrownIcon } from '@persuit/ui-components'
import { Link, useHistory } from 'react-router-dom'
import Truncate from 'react-truncate'
import { isUnauthorised } from '@persuit/ui-utils'

const RfpFragment = graphql(`
	fragment TemplateDescriptionCard_RfpTemplate on RfpTemplatePublished {
		_id
		templateTitle
		templateDescription
		useCase
	}
`)

type DescriptionCardProps = {
	truncateWidth: number | null
	templateFragment: FragmentType<typeof RfpFragment>
	onPreviewOrUseTemplate: () => void
}

export const DescriptionCard = ({
	templateFragment,
	truncateWidth,
	onPreviewOrUseTemplate,
}: DescriptionCardProps) => {
	const [truncated, setTruncated] = useState(false)
	const [expand, setExpand] = useState(false)
	const { _id, templateTitle, templateDescription, useCase } = getFragment(
		RfpFragment,
		templateFragment,
	)

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" justifyContent="space-between" alignItems="flex-start" pb={1}>
				<Box maxWidth="60%" display="flex" gap={1}>
					<Typography
						variant="body1Semibold"
						style={{
							wordWrap: 'normal',
						}}
						id={`${_id}-title`}
					>
						{templateTitle}
					</Typography>
					{useCase === 'rateReview' && <CrownIcon sx={{ color: 'premium.main', width: '17px' }} />}
				</Box>
			</Box>

			<Typography variant="body2" id={`${_id}-description`}>
				{truncateWidth ? (
					<Truncate
						width={truncateWidth}
						lines={expand ? 500 : 1}
						onTruncate={(truncated) => setTruncated((x) => x || truncated)}
					>
						{templateDescription}
					</Truncate>
				) : (
					templateDescription
				)}

				{!truncated ? null : (
					<Button
						onClick={() => setExpand((expand) => !expand)}
						size="small"
						variant="text"
						color="primary"
						style={{ height: '14px', padding: 0, textTransform: 'none' }}
					>
						See {expand ? 'less' : 'more'}
					</Button>
				)}
			</Typography>
			<Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} mt="auto">
				<Button
					component={Link}
					variant="outlined"
					to={`/en/create-request-from-template/${_id}/view/`}
					color="primary"
					size="small"
					data-trackid="button-preview"
					onClick={onPreviewOrUseTemplate}
				>
					Preview
				</Button>
				<CreateRfpFromTemplateButton
					templateId={_id}
					onPreviewOrUseTemplate={onPreviewOrUseTemplate}
				/>
			</Box>
		</Box>
	)
}

const CREATE_RFP_FROM_TEMPLATE = graphql(`
	mutation TemplateCard_CreateRfpFromTemplate($templateId: ID!) {
		createRfpFromTemplate(templateId: $templateId) {
			_id
		}
	}
`)

type CreateRfpFromTemplateButtonProps = {
	templateId: string
	onPreviewOrUseTemplate: () => void
}

const CreateRfpFromTemplateButton = ({
	templateId,
	onPreviewOrUseTemplate,
}: CreateRfpFromTemplateButtonProps) => {
	const [createRfpFromTemplate, { loading, error }] = useMutation(CREATE_RFP_FROM_TEMPLATE)
	const history = useHistory()

	if (error && isUnauthorised(error)) {
		history.push(`/en/unauthorized/`)
	}

	return (
		<Button
			color="primary"
			variant="contained"
			size="small"
			disabled={loading}
			style={{
				whiteSpace: 'nowrap',
			}}
			onClick={async () => {
				const { data } = await createRfpFromTemplate({
					variables: { templateId },
				})

				const rfpId = data?.createRfpFromTemplate?._id
				if (rfpId) {
					history.push(`/en/request/${rfpId}/edit/`)
					onPreviewOrUseTemplate()
				}
			}}
			data-trackid="button-use-template"
		>
			Use this template
		</Button>
	)
}
