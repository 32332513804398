import { styled } from '@persuit/ui-components'
import { getOrEmptyArray } from '../../../../utils/lenses'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import { FileUploaderDetached } from '../../../file/file-handlers'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils'

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

function OptionUploadFinalLoeOptions({ onCancel, onPrev, onNext: onNextParent, onChange, value }) {
	const files = getOrEmptyArray('files', value)

	const isReady =
		files.length > 0 &&
		files.reduce((ready, file) => {
			if (!file.fileStatus || file.fileStatus !== 'clean') return false
			return ready
		}, true)

	const onNext = () => {
		onChange({ files })
		onNextParent()
	}

	return (
		<DialogWizardContent
			onCancel={onCancel}
			onPrev={onPrev}
			onNext={isReady ? onNext : undefined}
			steps={defaultSteps}
			activeStep={'ACTIONS'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Upload Final Engagement Letter',
			}}
		>
			<strong>Attach Files</strong>
			<StyledList>
				<li>
					<Text type="body1" black={true}>
						Attaching the final engagement letter to this Proposal will allow the firm to view and
						download the letter.
					</Text>
				</li>
			</StyledList>
			<Spacer />
			<FileUploaderDetached
				files={files}
				onChange={(files) => {
					onChange({ files })
				}}
			/>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionUploadFinalLoeOptions
