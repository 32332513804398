import { useId, useState } from 'react'
import { CheckboxHierarchyCheckboxes } from './checkboxes'
import { CheckboxHierarchySelectedValues } from './selected-values'
import { CustomTrackingFieldValues } from '../types'
import { isNotNil } from '@persuit/common-utils'
import { useController, useFormContext } from '../../form-utils'
import {
	FormControl,
	FormHelperText,
	FormLabel,
	Typography,
	Box,
	Dialog,
} from '@persuit/ui-components'

type CheckboxHierarchyProps = {
	id: string
	disableSort?: boolean
	label: string
	required: boolean
	values: CustomTrackingFieldValues[]
	name: `tracking.customFields.${number}.hierarchyValue`
	error?: string
}

export const CheckboxHierarchy = ({
	id,
	disableSort = false,
	label,
	error,
	values,
	required,
	name,
}: CheckboxHierarchyProps) => {
	const { control } = useFormContext()
	const {
		field: { value },
	} = useController({
		name,
		control,
	})
	const [open, setOpen] = useState(false)

	const toggleDialog = () => {
		setOpen(!open)
	}

	let processedValues = values

	// Sorting is enabled by default, need to opt out if sorting
	// is not required
	if (!disableSort) {
		const sortedParentValues = [...values]

		// Double check value is not empty when user adds a new cat then clicks outside the input field
		sortedParentValues.sort(
			(a, b) => (a?.value && b?.value && a?.value?.localeCompare(b?.value)) || 0,
		)

		processedValues = sortedParentValues.filter(isNotNil).map((item) => {
			// Sort parent values
			const sortedChildValues = [...(item?.values ?? [])]
			sortedChildValues.sort(
				(a, b) => (a?.value && b?.value && a?.value.localeCompare(b?.value)) || 0,
			)

			return {
				...item,
				values: sortedChildValues,
			}
		})
	}

	const listTitleId = useId()
	const helperTextId = useId()

	const hierarchy = (
		<FormControl
			data-testid="checkbox-hierarchy-field"
			error={!!error}
			required={required}
			fullWidth={true}
		>
			<FormLabel data-testid="field-label" htmlFor={id}>
				{label}
			</FormLabel>

			<Box mt={0.5}>
				<CheckboxHierarchySelectedValues
					id={id}
					label={label}
					values={processedValues}
					onClick={toggleDialog}
					selectedValuesForItem={value || []}
					error={!!error}
					helperTextId={error ? helperTextId : undefined}
					required={required}
				/>
			</Box>

			<Dialog
				data-testid="checkbox-hierarchy-dialog"
				open={open}
				onClose={toggleDialog}
				size="md"
				title={`${label} options`}
				actions={[
					{
						label: 'Ok',
						variant: 'primary',
						onClick: toggleDialog,
					},
				]}
				id={listTitleId}
			>
				<Typography variant="body1">Select all that apply</Typography>

				<CheckboxHierarchyCheckboxes
					values={processedValues}
					value={value || []}
					name={name}
					listTitleId={listTitleId}
				/>
			</Dialog>

			{error && <FormHelperText id={helperTextId}>{error}</FormHelperText>}
		</FormControl>
	)

	return <Box flexGrow={'1'}>{hierarchy}</Box>
}
