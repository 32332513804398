import { SvgIcon, SvgIconProps } from '../components/svg'

type AddProposalIconProps = SvgIconProps

export const ProposalEditIcon = ({ ...props }: AddProposalIconProps) => {
	return (
		<SvgIcon {...props}>
			<g>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.4301 0C17.3965 0 18.1801 0.783501 18.1801 1.75V8.59179L12.5706 14.2012H5.16443C4.62077 14.2012 4.18005 14.642 4.18005 15.1856C4.18005 15.7293 4.62077 16.17 5.16443 16.17H10.6019L5.77184 21H2.43005C1.46356 21 0.680054 20.2165 0.680054 19.25V1.75C0.680054 0.783502 1.46356 0 2.43005 0H16.4301ZM13.6957 4.83002C14.2393 4.83002 14.6801 5.27074 14.6801 5.81439C14.6801 6.35805 14.2393 6.79877 13.6957 6.79877H5.16443C4.62077 6.79877 4.18005 6.35805 4.18005 5.81439C4.18005 5.27074 4.62077 4.83002 5.16443 4.83002H13.6957ZM14.68 10.5C14.6777 9.95836 14.2379 9.52 13.6957 9.52H5.16443C4.62223 9.52 4.18242 9.95836 4.18006 10.5C4.18242 11.0416 4.62223 11.48 5.16443 11.48H13.6957C14.2379 11.48 14.6777 11.0416 14.68 10.5Z"
				/>
				<path d="M9.31635 23.558L9.3864 20.8013C9.38963 20.6744 9.43773 20.5644 9.53071 20.4715L17.0239 12.9783L20.3379 16.2923L12.8357 23.7857C12.7518 23.8785 12.6327 23.9268 12.5148 23.9298L9.75822 23.9998C9.50432 24.0063 9.3099 23.8119 9.31635 23.558Z" />
				<path d="M23.0669 12.3172C23.4116 12.6619 23.3971 13.2332 23.0344 13.5958L21.3328 15.2974L18.0188 11.9833L19.7204 10.2818C20.083 9.91913 20.6543 9.90462 20.999 10.2493L23.0669 12.3172Z" />
			</g>
		</SvgIcon>
	)
}
