// @ts-strict-ignore
import diversityContent from '@client/components/request/modules/diversity/diversity-content'
import { calculateTotalWeightedScore } from '@client/utils/scorecard'
import { last } from 'lodash'
import { getDiversityAnswer } from '../rows/diversity-section/diversity-utils'
import { ComparisonTableRequest, ComparisonTableResponse, SortObject } from '../store'

type SortedResponsesArgs = {
	request: ComparisonTableRequest
	responses: Array<ComparisonTableResponse>
	sort: SortObject
	isUAMToggledOn: boolean
}
export const getSortedResponses = ({
	request,
	responses,
	sort,
	isUAMToggledOn,
}: SortedResponsesArgs) => {
	if (sort) {
		const { type, key, order = 'asc' } = sort

		const getFirmName = (res: ComparisonTableResponse) => res?.org?.name ?? ''

		switch (type) {
			case 'firmName': {
				return responses.sort(
					(a, b) => getFirmName(a).localeCompare(getFirmName(b)) * (order === 'asc' ? 1 : -1),
				)
			}

			case 'totalPrice': {
				const getTotalPrice = (res: ComparisonTableResponse) => {
					const totalPrices = res?.totalPrices
					const totalPriceValue =
						!totalPrices || totalPrices.length === 0
							? Infinity
							: Number(
									!last(totalPrices)?.totalPriceValue
										? Infinity
										: last(totalPrices)?.totalPriceValue,
							  )
					return totalPriceValue
				}

				return responses.sort(
					(a, b) =>
						(getTotalPrice(b) - getTotalPrice(a) || getFirmName(b).localeCompare(getFirmName(a))) *
						(order === 'asc' ? -1 : 1),
				)
			}

			case 'comparisonValue': {
				const getComparisonValue = (res: ComparisonTableResponse) => res.comparisonValue ?? 0

				return responses.sort(
					(a, b) =>
						(getComparisonValue(b) - getComparisonValue(a) ||
							getFirmName(b).localeCompare(getFirmName(a))) * (order === 'asc' ? -1 : 1),
				)
			}

			case 'averageRate': {
				const getAverageRate = (res: ComparisonTableResponse) => res.averageRate?.rate ?? 0

				return responses.sort(
					(a, b) =>
						(getAverageRate(b) - getAverageRate(a) ||
							getFirmName(b).localeCompare(getFirmName(a))) * (order === 'asc' ? -1 : 1),
				)
			}

			case 'pricing': {
				const getPrice = (res: ComparisonTableResponse) => {
					const resToDeliverable = res.responseToDeliverables?.find((x) => x?.deliverableId === key)
					if (resToDeliverable?.pricingModel === 'hourlyRates') {
						const responseToRates = resToDeliverable.responseToRates ?? []
						const firmSuppliedRates = resToDeliverable.firmSuppliedRates ?? []
						const combinedRates = [...responseToRates, ...firmSuppliedRates]

						const blendedRate =
							combinedRates.reduce((total, next) => total + (next?.rate ?? 0), 0) /
							combinedRates.length

						return !blendedRate ? Infinity : Math.round(blendedRate)
					}
					if (resToDeliverable?.pricingModel === 'contingencyPercentage') {
						const response = res.responseToDeliverables?.find((x) => x?.deliverableId === key)
							?.percentage
						return response === null ? Infinity : Number(response)
					}
					const response = res.responseToDeliverables?.find((x) => x?.deliverableId === key)

					const isExcludedFromScope = response?.notIncluded
					const price = response?.price
					const pricingModel = response?.pricingModel

					return isExcludedFromScope ||
						pricingModel === 'included' ||
						price === null ||
						price === undefined
						? Infinity
						: Number(price)
				}

				return responses.sort(
					(a, b) =>
						(getPrice(b) - getPrice(a) || getFirmName(b).localeCompare(getFirmName(a))) *
						(order === 'asc' ? -1 : 1),
				)
			}

			case 'questions': {
				const getAnswer = (res) => {
					const answerObj = res.answersToQuestions?.find((x) => x?.questionId === key)
					const questionType = answerObj?.type
					if (questionType === 'choice') {
						return answerObj?.choiceAnswer === null ? Infinity : !answerObj?.choiceAnswer
					} else if (questionType === 'singleSelect' || questionType === 'scale') {
						const question = request?.detail?.questions?.find((question) => question?._id === key)
						const optionIndex = question?.options?.findIndex(
							(option) => option._id === answerObj?.singleSelectAnswer,
						)
						return optionIndex === -1 ? Infinity : optionIndex
					} else if (questionType === 'multipleSelect') {
						return !answerObj?.multipleSelectAnswer?.length
							? Infinity
							: answerObj?.multipleSelectAnswer?.length
					} else if (questionType === 'percentage') {
						return answerObj?.numericAnswer === null ? Infinity : answerObj?.numericAnswer
					}
					return !answerObj?.stringAnswer ? Infinity : answerObj?.stringAnswer
				}

				return responses.sort((a, b) => {
					return (
						(getAnswer(b) - getAnswer(a) || getFirmName(b).localeCompare(getFirmName(a))) *
						(order === 'asc' ? -1 : 1)
					)
				})
			}

			case 'diversity': {
				const getAnswer = (res) => {
					const diversityAnswers = res.diversityAnswers
					const answerNumber = key?.replace('diversity', '')
					const diversityAnswer = getDiversityAnswer(diversityAnswers, answerNumber).answer
					const certificationsLength = isUAMToggledOn
						? res.group?.certifications.length
						: res.org?.certifications.length

					const diversityQuestionType = diversityContent[key].type
					if (diversityQuestionType === 'choice') {
						return diversityAnswer === null ? Infinity : !diversityAnswer
					} else if (diversityQuestionType === 'certification') {
						return certificationsLength
					}
					return diversityAnswer === null ? 0 : diversityAnswer
				}

				return responses.sort(
					(a, b) =>
						(getAnswer(b) - getAnswer(a) || getFirmName(b).localeCompare(getFirmName(a))) *
						(order === 'asc' ? -1 : 1),
				)
			}

			case 'scorecard': {
				const getAggregateScore = (res: ComparisonTableResponse) => {
					const scorecard = res.scorecard
					const scoreCategories = request.scoreCategories
					const aggregateScore = calculateTotalWeightedScore(scorecard, scoreCategories)
					return Number(aggregateScore) || 0
				}

				return responses.sort(
					(a, b) =>
						(getAggregateScore(b) - getAggregateScore(a) ||
							getFirmName(b).localeCompare(getFirmName(a))) * (order === 'asc' ? -1 : 1),
				)
			}

			default:
				return responses
		}
	}

	return responses
}
