// eslint-disable-next-line no-restricted-imports -- Please upgrade
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'Stop the firm from revising their proposal or submitting new proposals',
	'Other firms will not be notified.',
]

export default ({ onNext, hide, disabled }) => {
	return hide ? null : (
		<DialogWizardListOption
			dataTestid=""
			icon={<LocalLibraryIcon />}
			primaryText="Close request for this firm"
			bullets={bullets}
			onClick={onNext}
			disabled={disabled}
		/>
	)
}
