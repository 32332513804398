import { useEffect } from 'react'
import { isAfter } from 'date-fns'
import useForceUpdate from '../../custom-hooks/force-update-hook'
import formatCountdownToDate from './format-countdown-to-date'

const DueDateTicker = ({ prefix, dueDate }) => {
	const forceUpdate = useForceUpdate()

	useEffect(() => {
		const updateComponentTimer = setTimeout(() => {
			forceUpdate()
		}, 1000)

		return function componentWillUnmount() {
			clearTimeout(updateComponentTimer)
		}
	}, [forceUpdate])

	if (!dueDate) {
		return null
	}

	const currentDateObj = new Date()
	const dueDateObj = new Date(dueDate)

	if (isAfter(currentDateObj, dueDateObj)) {
		return null
	}

	const durationToDate = formatCountdownToDate(dueDate)

	return (
		<span>
			{prefix} {durationToDate}
		</span>
	)
}

DueDateTicker.defaultProps = {
	prefix: 'Due',
}

export default DueDateTicker
