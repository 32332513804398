import { find, findLast, isEmpty } from 'lodash/fp'
import responseActions from '../../common/data/response-actions'

const getLatestProposalRevisionPublishedDate = findLast({
	action: responseActions.PUBLISH_REVISED_PROPOSAL,
})

const findPublishedDate = (activityLog) => {
	return find({ action: responseActions.PUBLISH }, activityLog) || null
}

// TODO: Write unit test for this
export const getProposalPublishedAtDateFromActivityLog = (activityLog = []) => {
	const latestProposalRevisionPublishedDate = getLatestProposalRevisionPublishedDate(activityLog)

	return isEmpty(latestProposalRevisionPublishedDate)
		? findPublishedDate(activityLog)
		: latestProposalRevisionPublishedDate
}
