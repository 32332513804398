import { getOrFalse } from '../../../utils/lenses'
import { buildExampleResponse } from '../utils'
import { filterWithdrawn, filterRevised } from './filters'
// Filter and sort the responses
export default function getResponses({
	request,
	responses,
	showExampleResponse,
	showWithdrawnProposals,
	showRevisedProposals,
}) {
	// Early return if there are no request details (They haven't cleared conflicts)
	if (!request.detail) {
		return []
	}

	const {
		detail,
		detail: { deliverables, questions, totalPricingPreference },
	} = request
	const diversityRequired = getOrFalse('diversity.diversityRequired', detail)
	const responsesSansWithdrawn = !showWithdrawnProposals ? filterWithdrawn(responses) : responses

	const responsesSansWithdrawnRevised = !showRevisedProposals
		? filterRevised(responsesSansWithdrawn)
		: responsesSansWithdrawn

	// If needed push a made up example response onto the end of the list
	if (showExampleResponse) {
		responsesSansWithdrawnRevised.push({
			...buildExampleResponse({
				clientTotalPricingPreference: totalPricingPreference,
				deliverables,
				questions,
				diversityRequired,
			}),
			rank: 2, // Kinda dodgy hack but the example response is always the 2nd one
		})
	}

	return responsesSansWithdrawnRevised
}
