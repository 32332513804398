import { gql } from '@apollo/client'

export const FILESCAN_SUBSCRIPTION = gql`
	subscription fileScan {
		fileScan {
			_id
			fileStatus
		}
	}
`
