import { graphql } from '@apollo/client/react/hoc'

import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FieldArray, reduxForm } from 'redux-form'

import stripTypename from '../../../../../../common/deep-strip-key'

import saveRfpProposalScorecardMutation from '../../../../../graphql/mutations/saveRfpProposalScorecard-gql'

import Button from '../../../../button.jsx'
import Spacer from '../../../../layout/spacer.jsx'
import proposalScoreActions from '../../../../../actions/proposal-scores'
import ScoreCategoryFields from '../components/scores/form/score-categories'
import { withSnackbar } from '@persuit/ui-components'

class ProposalScoresForm extends PureComponent {
	onSaveClicked = () => {
		const { closeProposalScoreForm, handleSubmit, openSnackbar } = this.props

		// eslint-disable-next-line promise/catch-or-return -- cannot use useHandleUnexpectedError()
		handleSubmit()
			.then(closeProposalScoreForm)
			.then(() => {
				openSnackbar('Score saved!')
			})
	}

	render() {
		const { handleSubmit, scoreCategories } = this.props

		return (
			<form onSubmit={handleSubmit}>
				<FieldArray
					name="scores"
					component={ScoreCategoryFields}
					scoreCategories={scoreCategories}
				/>

				<Spacer space={3} direction="column" />

				<Button type="primary" label="Save" fullWidth={true} onClick={this.onSaveClicked} />
			</form>
		)
	}
}

const mapDispatchToProps = {
	closeProposalScoreForm: proposalScoreActions.closeProposalScoreForm,
}

const reduxFormHoC = reduxForm({
	form: 'proposalScoresForm',
	onSubmit: (values, dispatch, props) => {
		const { proposalId, saveProposalScores } = props

		return saveProposalScores({
			variables: {
				_id: proposalId,
				scorecard: values.scores.map((sc) => stripTypename(sc, '__typename')),
			},
		})
	},
})

export default compose(
	connect(null, mapDispatchToProps),
	graphql(saveRfpProposalScorecardMutation, {
		name: 'saveProposalScores',
	}),
	reduxFormHoC,
	withSnackbar,
)(ProposalScoresForm)
