// @ts-strict-ignore
import { isEmpty, toString } from 'lodash/fp'

export const areValuesEqual = (currentValue, suggestedValue) => {
	const currentValueString = toString(currentValue),
		suggestedValueString = toString(suggestedValue)

	if (suggestedValueString === '') {
		return true
	}

	return currentValueString === suggestedValueString
}

export const arePricesEqual = (currentPrice, suggestedPrice) => {
	const currentPriceString = toString(currentPrice),
		suggestedPriceString = toString(suggestedPrice)

	if (suggestedPriceString === '') {
		return true
	}

	return currentPriceString === suggestedPriceString
}

export const arePriceModelEqual = (currentModel, suggestedModel) => {
	const currentPricingModelString = toString(currentModel)
	const suggestedPricingModelString = toString(suggestedModel)

	if (suggestedPricingModelString === '') {
		return true
	}

	return currentPricingModelString === suggestedPricingModelString
}

export const areHoursEqual = (currentHours, suggestedHours) => {
	const currentHoursString = toString(currentHours),
		suggestedHoursString = toString(suggestedHours)

	if (suggestedHoursString === '') {
		return true
	}

	return currentHoursString === suggestedHoursString
}

export const hasAnySuggestionAdded = ({
	price,
	percentage,
	pricingModel,
	hours,
}: {
	price?: number
	percentage?: number
	pricingModel?: string
	hours?: string
}) => {
	return [
		!isEmpty(toString(price)),
		!isEmpty(toString(percentage)),
		!isEmpty(toString(pricingModel)),
		!isEmpty(toString(hours)),
	].some((value) => value === true)
}
