// @ts-strict-ignore
import { KnownPerson, UnknownPerson } from './types'
import { isNotNilProp } from '@persuit/common-utils'
import { RfpType, MappedSharedItem, Person, FirmUser } from './types'
import { SearchResult } from './all-contacts-form/people-search-list-item'
import unregisteredUserListState from '../../../../common/data/unregistered-user-list-states'
import sharingErrors from '../../../../common/data/sharing-errors'
import rfpStates from '../../../../common/data/rfp-states'

// This maps the share items into the shape expected by the
// sharing resolver (note: that the shape is the same for
// unregistered users and registered users)
export const mapShareItems = (items: Array<string | SearchResult | Person>): MappedSharedItem[] => {
	return items.map((item) => ({
		_id: typeof item === 'string' ? undefined : item._id,
		email: typeof item === 'string' ? item : item.email,
		list: unregisteredUserListState.TO,
	}))
}

export const getSelectedUnknownUsers = (
	rfp: RfpType,
	unregisteredUserStates: any,
	unregisteredUserListState: any,
): UnknownPerson[] => {
	return (rfp.unregisteredUsers ?? [])
		.filter(({ status }) => status === unregisteredUserStates.OPEN)
		.filter(
			({ list, createdByOrg }) =>
				list === unregisteredUserListState.TO ||
				(list === unregisteredUserListState.SHARED && createdByOrg !== rfp.org._id),
		)
		.map((item) => ({
			...item,
			__type__: 'unknown',
		}))
}

const isFavourite = (userId: string, userFavorites: string[]) => userFavorites.includes(userId)

export const getSelectedKnownUsers = (
	rfp: RfpType,
	userOrgId: string | null,
	allowTypes: any,
	userFavorites: string[],
	isFirmView?: boolean,
): KnownPerson[] => {
	return (
		rfp.allow
			// Filter out users colleagues
			.filter(({ org }) => {
				return org !== userOrgId
			})
			// Filter out revoked invites
			.filter(({ type }) => type !== allowTypes.REVOKED)
			// Map invited people into the shape the type expects
			.map(({ addedBySystem, item }) => {
				const fName = item?.name.first ?? ''
				const lName = item?.name.last ?? ''
				const name = `${fName} ${lName}`

				return {
					__type__: 'known',
					_id: item._id,
					name,
					email: item.email,
					title: item.title ?? '',
					org: item.org,
					location: item?.location?.name ?? '',
					isFavourite: isFavourite(item._id, userFavorites),
					addedBySystem: isFirmView ? addedBySystem : false,
					professionalProfile: item.professionalProfile,
				}
			})
	)
}

export const getInvitablePeople = (
	people: any,
	user: any,
	userOrgId: string | null,
	userFavourites: string[],
): Person[] => {
	return (
		people
			// workaround for bad data in db - users without local accounts
			.filter(isNotNilProp('email'))
			// filter out current user
			.filter(({ _id }) => _id !== user._id)
			.filter(
				({ org }) =>
					// only invite firm people
					org?._id !== userOrgId,
			)
			.filter(isNotNilProp('_id'))
			.filter(isNotNilProp('org'))
			.filter(isNotNilProp('name'))
			// just to type errors
			.map((user) => {
				return {
					...parsePerson(user, userFavourites),
				}
			})
	)
}

export const getShouldShowConfirmation = (rfpStatus) => {
	return [rfpStates.FINALIZED, rfpStates.CLOSED, rfpStates.COMPLETED].includes(rfpStatus)
}

export const extractAmmendableError = (error) => {
	// The custom error data sent back from the sharing resolver is contained here
	// if there any errors occurred
	const errorData = error?.graphQLErrors?.[0]?.extensions

	if (!errorData) {
		return null
	}

	// An error can only be amended if a user has attempted to share with a single invalid
	// existing user (amending multiple errors is not considered)
	if (
		Object.keys(errorData?.erroneousExistingUsers).length === 1 &&
		Object.keys(errorData?.erroneousUnregisteredUsers).length === 0
	) {
		const errorObj =
			errorData.erroneousExistingUsers[Object.keys(errorData?.erroneousExistingUsers)[0]]
		const error = errorObj.error
		const email = errorObj.user.email

		// If an error was received from the server that a client user is attempting
		// to INVITE a colleague then this will amend that to a SHARE.
		if (error === sharingErrors.CLIENT_INVITING_OWN_ORG) {
			return {
				error,
				users: [{ email, list: unregisteredUserListState.SHARED }],
			}
		}
	}
}

export const parsePerson = (contactUser: FirmUser, favourites: string[]): Person => {
	const id = contactUser?._id ?? ''
	return {
		_id: id,
		title: contactUser?.title ?? '',
		name: `${contactUser?.name?.first ?? ''} ${contactUser?.name?.last ?? ''}`,
		org: {
			_id: contactUser?.org?._id ?? '',
			name: contactUser?.org?.name ?? '',
			isNamwolfMember: contactUser?.org?.isNamwolfMember ?? false,
		},
		isFavourite: favourites.includes(id),
		email: contactUser?.email ?? '',
		location: contactUser?.location?.name ?? '',
		professionalProfile:
			contactUser?.professionalProfile && contactUser?.professionalProfile.length > 0
				? contactUser?.professionalProfile.map((profile) => {
						return {
							_id: profile._id,
							profile: profile.profile,
							url: profile.url,
						}
				  })
				: [],
		__type__: 'known' as const,
	}
}

export const getCleanUserFavorites = (favourites) =>
	(favourites ?? []).filter((item): item is string => item !== null)

export function hasId(obj: any): obj is Person | SearchResult {
	return typeof obj === 'object' && '_id' in obj
}
