// @ts-strict-ignore
import { useEffect } from 'react'
import { flatten } from 'lodash/fp'
import { isNotNil } from '@persuit/common-utils'

import { ClientNavbar } from './client-navbar'
import { FirmNavbar } from './firm-navbar'
import { useSelector } from '@client/store'

import { graphql, useQuery } from '@persuit/ui-graphql'
import { useUser } from '@persuit/ui-auth'

import { Unauthorized } from '@persuit/ui-shared-components'
import { logger } from '@persuit/ui-logger'
import { GlobalStyles, LoadingSpinner } from '@persuit/ui-components'

type NavbarProps = {
	notificationCount: number
}

export const NavbarContent = ({ notificationCount }: NavbarProps) => {
	const { loading: loadingSession, user, isImpersonated, can } = useUser()

	const org = user?.org ?? null

	const userOrgRoles = user?.orgRoles ?? []
	const isClientUser = org?.orgType === 'CLIENT'
	const orgName = org?.name ?? ''
	const userEmail = user?.email ?? ''
	const firstName = user?.name?.first ?? ''
	const lastName = user?.name?.last ?? ''

	if (loadingSession) {
		return <LoadingSpinner />
	}

	if (!org || !user) {
		return null
	}
	// navbar
	const initials = [firstName, lastName]
		.filter(isNotNil)
		.map((x) => x?.trim()[0])
		.join('')

	const canCreateTemplate = can('CREATE_TEMPLATE')
	const showAnalytics = can('VIEW_INSIGHT')
	const canCreateRfp = can('CREATE_RFP')
	const showPerfPageForRealFirmUser = can('VIEW_GROUP_ANALYTICS')

	const allOrgRoles = userOrgRoles.map((or) => or.roles)
	const allGroupRoles = user.groupRoles.map((gr) => gr.roles)
	const orgRoles = allOrgRoles ? flatten(allOrgRoles) : []
	const groupRoles = allGroupRoles ? flatten(allGroupRoles) : []
	const orgCertifications = user?.org?.certifications ?? []
	const isNamwolfMember = orgCertifications.includes('NAMWOLF')

	const userGroupName = user?.group?.name ?? ''
	const userTeamName = user?.team?.name ?? ''

	const navProps = {
		canCreateTemplate,
		showAnalytics,
		canCreateRfp,
	}
	const profileProps = {
		firstName,
		groupRoles,
		initials,
		isImpersonated,
		isNamwolfMember,
		lastName,
		orgName,
		orgRoles,
		userEmail,
		userGroupName,
		userTeamName,
	}

	return (
		<>
			<GlobalStyles
				styles={{
					body: {
						[`@media screen`]: {
							paddingTop: '64px !important',
						},
					},
				}}
			/>
			{isClientUser ? (
				<ClientNavbar
					notificationCount={notificationCount}
					navProps={navProps}
					profileProps={profileProps}
				/>
			) : (
				<FirmNavbar
					notificationCount={notificationCount}
					showPerfPageForRealFirmUser={showPerfPageForRealFirmUser}
					profileProps={profileProps}
				/>
			)}
		</>
	)
}

const NOTIFICATIONS_QUERY = graphql(`
	query NotificationsForNavbar {
		getNotifications {
			_id
			createdAt
		}
	}
`)

const NOTIFICATIONS_UPDATED = graphql(`
	subscription NotificationUpdated {
		notificationUpdated {
			_id
			createdAt
		}
	}
`)

export const Navbar = () => {
	const { notificationsLastViewed } = useSelector((state) => ({
		notificationsLastViewed: new Date(state.userActivity.notificationsLastViewed),
	}))
	const { data, loading, error, subscribeToMore } = useQuery(NOTIFICATIONS_QUERY)

	useEffect(() => {
		if (!error) {
			return subscribeToMore({
				document: NOTIFICATIONS_UPDATED,
				updateQuery: (prev, { subscriptionData }) => {
					if (!subscriptionData.data) {
						return prev
					}

					const incomingNotification = subscriptionData.data.notificationUpdated || {}
					const prevNotifications = prev.getNotifications || []

					// Check if it exists - if so, update
					// otherwise add to top of list

					const alreadyCached = prevNotifications?.find((notification) => {
						return notification?._id?.toString() === incomingNotification?._id?.toString()
					})

					const filteredPrevNotifications = prevNotifications.filter((notification) => {
						return notification?._id?.toString() !== incomingNotification?._id?.toString()
					})

					if (alreadyCached) {
						return {
							...prev,
							getNotifications: [incomingNotification, ...filteredPrevNotifications],
						}
					}

					return {
						...prev,
						getNotifications: [incomingNotification, ...prevNotifications],
					}
				},
			})
		}
	}, [error, subscribeToMore])

	if (error) {
		logger.error(error, {
			extra: {
				location: 'Navigation Bar',
			},
		})
		return <Unauthorized />
	}

	return (
		<NavbarContent
			notificationCount={
				loading || !data
					? 0
					: data.getNotifications?.filter(
							(notification) =>
								notification?.createdAt &&
								new Date(notification?.createdAt) > notificationsLastViewed,
					  ).length ?? 0
			}
		/>
	)
}
