export function omitDeep<T extends Record<string, unknown>, K extends KeyofDeep<T>>(
	obj: T,
	omitKey: K,
): OmitDeep<T, K> {
	return omitDeepHelper(obj, omitKey)
}

function omitDeepHelper(obj: any, omitKey: string): any {
	if (Array.isArray(obj)) {
		return obj.map((val) => omitDeepHelper(val, omitKey))
	}

	if (typeof obj === 'object' && obj !== null) {
		return Object.fromEntries(
			Object.entries(obj)
				.filter(([key]) => key !== omitKey)
				.map(([key, value]) => [key, omitDeepHelper(value, omitKey)]),
		)
	}

	return obj
}

export type OmitDeep<T, K> = T extends null
	? null
	: T extends Record<string, unknown>
	? {
			[Key in Exclude<keyof T, K>]: OmitDeep<T[Key], K>
	  }
	: T extends (infer R)[]
	? OmitDeep<R, K>[]
	: T

type Values<T> = T[keyof T]

export type KeyofDeep<T> = T extends null
	? never
	: T extends Record<string, unknown>
	?
			| keyof T
			| Values<{
					[K in keyof T]: KeyofDeep<T[K]>
			  }>
	: T extends (infer R)[]
	? KeyofDeep<R>
	: never
