import { Typography, EditIcon } from '@persuit/ui-components'
import classnames from 'classnames'

import { PROPOSAL_STATES } from '../../../common/data/rfp-proposal-states'
import injectSheet from '../../injectSheet'
import { ProposalStateComponent } from '../status'
import { BaseStatus } from 'src/client/components/base-status/base-status'

const ProposalStatus = ({
	classes,
	className,
	status,
	hasParent,
	isEliminated,
	EliminatedStatusComponent,
	expanded = true,
}) => {
	const classNames = classnames(classes.base, {
		[className]: !!className,
	})

	// Firms invite was eliminated - overrides other status
	if (status !== PROPOSAL_STATES.REVISED && status !== PROPOSAL_STATES.WITHDRAWN && isEliminated) {
		return <EliminatedStatusComponent expanded={expanded} />
	}

	switch (status) {
		case PROPOSAL_STATES.FINALIZED:
		case PROPOSAL_STATES.EXAMPLE:
		case PROPOSAL_STATES.COMPETITOR:
			return null
		case PROPOSAL_STATES.ACCEPTED:
		case PROPOSAL_STATES.DRAFT_REVISION:
		case PROPOSAL_STATES.REVISED:
		case PROPOSAL_STATES.WITHDRAWN:
		case PROPOSAL_STATES.UNDER_REVISION:
			return <ProposalStateComponent status={status} expanded={expanded} />
		case PROPOSAL_STATES.DRAFT:
			return hasParent ? (
				<BaseStatus
					expanded={expanded}
					iconNode={<EditIcon color="primary.main" fontSize={expanded ? undefined : 'small'} />}
					primaryText="DRAFT REVISION"
				/>
			) : (
				<ProposalStateComponent status={status} expanded={expanded} />
			)
		default:
			return (
				<div className={classNames} style={{ fontStyle: 'italic' }}>
					<Typography>New Draft</Typography>
				</div>
			)
	}
}

ProposalStatus.defaultProps = {
	white: false,
}

const styles = {
	label: {
		color: 'inherit !important',
		margin: 0,
	},
	icon: {
		color: 'inherit !important',
		height: '1.2em !important',
		paddingRight: '0.4em',
	},
	publishedIcon: {
		transform: 'scaleX(-1)',
	},
	base: {
		display: 'inline-flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
	},
}

export default injectSheet(styles)(ProposalStatus)
