// @ts-strict-ignore
import { useEffect, useState, useRef } from 'react'
import {
	DndContext,
	Box,
	Button,
	AddIcon,
	arrayMove,
	IconButton,
	UnfoldLessIcon,
	UnfoldMoreIcon,
	SortableContext,
	SortableList,
	DragOverlay,
} from '@persuit/ui-components'
import { getOrGenerateUUID } from '@persuit/common-utils'
import { usePricingListContext } from '../pricing-list-context'
import { isDeliverable, isGroup, useFormContext } from '../pricing-form-utils'
import { PricingItemOverlay, PricingItem } from './pricing-item'
import { PricingGroupOverlay, PricingGroup } from './pricing-group'
import {
	rectIntersection,
	pointerWithin,
	DragEndEvent,
	DragMoveEvent,
	DragOverEvent,
	defaultDropAnimationSideEffects,
	Active,
} from '@dnd-kit/core'
import { PricingGroup as PricingGroupType, Deliverable } from '../types'
import { createPortal } from 'react-dom'
import { getPricingPreference } from './utils'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import type { Modifier } from '@dnd-kit/core'

type ID = string | number

const DRAGGING_GROUPED_ITEM_OUT_TOP_THRESHOLD = 30
const DRAGGING_GROUPED_ITEM_OUT_BOTTOM_THRESHOLD = 40
const DRAGGING_GROUPED_ITEM_OUT_LEFT_THRESHOLD = 0
const DRAGGING_ITEM_INTO_GROUP_LEFT_THRESHOLD = 15

export const PricingList = () => {
	const {
		expandedState,
		expandAllItems,
		collapseAllItems,
		registerExpandedState,
		totalPricingPreference,
		pricingItemsFieldArray: { append, move, fields: pricingListItems, replace, update, remove },
		getPricingGroupIndex,
		getPricingItemIndex,
		getItemExpanded,
		errors,
	} = usePricingListContext()

	const { getValues } = useFormContext()

	const [isDragging, setIsDragging] = useState(false)
	const [activeDragTarget, setActiveDragTarget] = useState<Active | null>(null)
	const listItems = pricingListItems.map(
		(item) =>
			({
				id: getOrGenerateUUID(item),
				type: isGroup(item) ? 'group' : 'item',
				title: isGroup(item) ? item.title : isDeliverable(item) ? item.deliverableTitle : '',
				deliverables: isGroup(item) ? item.deliverables : undefined,
			}) as const,
	)

	const hasGroups = listItems.some((listItem) => listItem?.type === 'group')

	const states = Object.values(expandedState)
	const allExpanded = states.every((v) => v === true)
	const shouldScrollCollapseExpandBox = useRef(false)
	const allCollapsed = states.every((v) => v === false)

	const shouldRenderPricingItems = pricingListItems && pricingListItems.length > 0

	useEffect(() => {
		if (shouldScrollCollapseExpandBox.current && allExpanded) {
			document.getElementById('expand-collapse-box')?.scrollIntoView()
			shouldScrollCollapseExpandBox.current = false
		}
	}, [allExpanded])

	const itemsMap = new Map(listItems.map((item) => [getOrGenerateUUID(item), item]))
	const getPosition = (id) => {
		const parentGroup = getParentGroup(id)
		if (parentGroup && parentGroup.deliverables) {
			return parentGroup.deliverables.findIndex((item) => getOrGenerateUUID(item) === id) + 1
		} else {
			return listItems.findIndex((item) => getOrGenerateUUID(item) === id) + 1
		}
	}
	const getDraggableItemTitle = (id: string) => {
		const item = itemsMap.get(`${id}`)
		const indexPosition =
			(item?.type === 'group' ? getPricingGroupIndex(id) : getPricingItemIndex(id)) + 1

		return `${item?.type === 'group' ? 'group' : 'pricing item'} ${indexPosition} ${
			item?.title ?? ''
		}`
	}
	const getItemCount = (id) => {
		const parentGroup = getParentGroup(id)
		if (parentGroup && parentGroup.deliverables) {
			return `${parentGroup.deliverables.length} of group ${
				getPricingGroupIndex(parentGroup.id) + 1
			}`
		} else {
			return listItems.length
		}
	}

	return (
		<DndContext
			modifiers={[restrictToParentElementOnKeyboard]}
			collisionDetection={(args) => {
				const pointerCollisions = pointerWithin(args)
				const rectIntersectionCollisions = rectIntersection(args)

				// First, let's see if there are any collisions with the rect intersections
				if (rectIntersectionCollisions.length > 0) {
					return rectIntersection(args)
				} else {
					// If there are no collisions with the rect, return pointer collisions
					return pointerCollisions
				}
			}}
			onDragCancel={() => {
				setActiveDragTarget(null)
				setIsDragging(false)
			}}
			onDragStart={(event) => {
				setActiveDragTarget(event.active)
				setIsDragging(true)
			}}
			onDragEnd={handleDragEnd}
			onDragMove={handleDragMove}
			onDragOver={handleDragOver}
			accessibility={{
				announcements: {
					onDragStart({ active }) {
						return `Picked up draggable ${getDraggableItemTitle(
							`${active.id}`,
						)}. It is in position ${getPosition(active.id)} of ${getItemCount(active.id)}`
					},
					onDragOver({ active, over }) {
						if (over) {
							return `draggable ${getDraggableItemTitle(
								`${active.id}`,
							)} was moved into position ${getPosition(over.id)} of ${getItemCount(active.id)}`
						}
					},
					onDragEnd({ active, over }) {
						if (over) {
							return `draggable ${getDraggableItemTitle(
								`${active.id}`,
							)} was dropped at position ${getPosition(over.id)} of ${getItemCount(active.id)}`
						}
					},
					onDragCancel({ active }) {
						return `Dragging was cancelled. draggable ${getDraggableItemTitle(
							`${active.id}`,
						)} was dropped.`
					},
				},
				screenReaderInstructions: {
					draggable: `To pick up a draggable item, press space or enter.
							While dragging, use the arrow keys to move the item in up/down direction.
							Press space or enter again to drop the item in its new position, or press escape to cancel.`,
				},
			}}
		>
			<Box maxWidth="1000px">
				{shouldRenderPricingItems && (
					<>
						<Box m="20px 0 0 10px" display="flex" justifyContent="right" id="expand-collapse-box">
							<Box>
								<IconButton
									disabled={allCollapsed}
									onClick={collapseAllItems}
									title="collapse all"
									size="large"
								>
									<UnfoldLessIcon />
								</IconButton>
								<IconButton
									disabled={allExpanded}
									onClick={() => {
										expandAllItems()
										shouldScrollCollapseExpandBox.current = true
									}}
									title="expand all"
									size="large"
								>
									<UnfoldMoreIcon />
								</IconButton>
							</Box>
						</Box>

						<SortableContext items={listItems} id="root">
							<SortableList>
								{listItems.map(({ id, type }, index) => (
									<>
										{type === 'group' ? (
											<PricingGroup
												key={id}
												id={id}
												index={index}
												activeDragId={activeDragTarget?.id}
											/>
										) : (
											<PricingItem
												key={id}
												id={id}
												index={index}
												activeDragId={activeDragTarget?.id}
												removeItem={() => remove(index)}
												movePricingItemIntoGroup={movePricingItemIntoGroup(id)}
												hasGroups={hasGroups}
											/>
										)}
									</>
								))}
							</SortableList>
						</SortableContext>

						{createPortal(
							<DragOverlay
								dropAnimation={{
									sideEffects: defaultDropAnimationSideEffects({
										styles: {
											active: {
												opacity: '1',
											},
											dragOverlay: {
												opacity: '0.5',
											},
										},
									}),
								}}
							>
								{isDragging && activeDragTarget ? renderListItemOverlay(activeDragTarget) : null}
							</DragOverlay>,
							document.body,
						)}
					</>
				)}

				<Box marginTop={!shouldRenderPricingItems ? '16px' : undefined} display="flex">
					<Box>
						<Button
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							aria-label="Add Group"
							onClick={addNewPricingGroup}
							// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
							sx={{ background: '#fff' }}
						>
							Group
						</Button>
					</Box>
					<Box ml="1rem">
						<Button
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							onClick={addNewPricingItem}
							aria-label="Add Item"
							data-testid="add-pricing-item"
							// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
							sx={{ background: '#fff' }}
						>
							Item
						</Button>
					</Box>
				</Box>
			</Box>
		</DndContext>
	)

	function movePricingItemIntoGroup(itemId: string) {
		return (groupId: string) => {
			const floatingItemIndex = findContainerIndex(itemId)
			const groupIndex = findContainerIndex(groupId)

			const clonedContainers = [...getValues().pricingItems]
			const group = clonedContainers[groupIndex]
			const floatingItem = clonedContainers[floatingItemIndex]

			if (isGroup(group) && isDeliverable(floatingItem)) {
				;(clonedContainers[groupIndex] as PricingGroupType).deliverables = [
					...group.deliverables,
					floatingItem,
				]
				clonedContainers.splice(floatingItemIndex, 1)
				replace(clonedContainers)
			}
		}
	}

	/**
	 * List manipulation utils
	 */
	function addNewPricingItem() {
		const uuid = getOrGenerateUUID()
		registerExpandedState(uuid)

		const pricingPreferences = getPricingPreference(totalPricingPreference)

		append({
			allowFirmAddRate: false,
			firmRateCardItemPlaceholder: '',
			deliverable: '',
			deliverableTitle: '',
			rates: [],
			uuid,
			pricingPreferences,
		})
	}

	function addNewPricingGroup() {
		append({
			title: '',
			deliverables: [],
			uuid: getOrGenerateUUID(),
		})
	}

	function getParentGroup(id: string) {
		if (itemsMap.get(id)) {
			return undefined
		} else {
			return listItems.find((item) => {
				if (item.deliverables) {
					return !!item.deliverables.find((groupItem) => getOrGenerateUUID(groupItem) === id)
				} else {
					return false
				}
			})
		}
	}

	/**
	 * Return index of either a container or a container that pricing item belong to
	 */
	function findContainerIndex(id: ID) {
		const overContainerIdx = pricingListItems.findIndex(
			(container) => getOrGenerateUUID(container) === id,
		)

		if (overContainerIdx !== -1) {
			return overContainerIdx
		}

		return pricingListItems.findIndex((container) => {
			if (isGroup(container)) {
				return container.deliverables.findIndex((item) => getOrGenerateUUID(item) === id) !== -1
			} else {
				// Floating item container, return false since it's not a droppable target
				return false
			}
		})
	}

	/**
	 * Return container through provided ID. A container can be a pricing group or floating pricing item
	 */
	function findContainerById(id: ID) {
		return getValues().pricingItems.find((container) => getOrGenerateUUID(container) === id)
	}

	/**
	 * Drag and drop handlers
	 */
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event

		setActiveDragTarget(null)
		setIsDragging(false)

		if (!over) {
			return
		}

		// Check if dragging groups or floating item
		if (findContainerById(active.id)) {
			if (active.id !== over.id) {
				const findIndex = (id: ID): number | null => {
					const index = listItems.findIndex(
						(item) => (typeof item === 'object' ? item.id : item) === id,
					)
					return index >= 0 ? index : null
				}

				const from = findIndex(active.id)
				const to = findIndex(over.id)

				if (from !== null && to !== null) {
					return move(from, to)
				}
			}
		} else {
			// Check if is dragging group items
			// Find the containers
			const activeContainerIdx = findContainerIndex(active.id)
			const overContainerIdx = findContainerIndex(over.id)

			// No container found or different container, do nothing
			if (
				activeContainerIdx === -1 ||
				overContainerIdx === -1 ||
				activeContainerIdx !== overContainerIdx
			) {
				return
			}

			const clonedContainers = [...getValues().pricingItems]
			const activeContainer = clonedContainers[activeContainerIdx]

			if (!isGroup(activeContainer)) {
				return
			}

			// Same container, swap item
			const activeIndex = activeContainer.deliverables.findIndex(
				(item) => getOrGenerateUUID(item) === active.id,
			)
			const overIndex = activeContainer.deliverables.findIndex(
				(item) => getOrGenerateUUID(item) === over.id,
			)

			if (activeIndex !== overIndex) {
				update(activeContainerIdx, {
					...activeContainer,
					deliverables: arrayMove(activeContainer.deliverables, activeIndex, overIndex),
				})
			}
		}
	}

	function handleDragMove(event: DragMoveEvent) {
		const { active, over } = event

		if (!over) {
			return
		}

		const activeContainerIdx = findContainerIndex(active.id)
		const overContainerIdx = findContainerIndex(over.id)
		const isGroupOrFloatingItem = findContainerById(active.id)
		const isOverContainerGroup = findContainerById(over.id)

		if (activeContainerIdx !== overContainerIdx || isGroupOrFloatingItem || !isOverContainerGroup) {
			return
		}

		// Move item out of the group
		const isAbove =
			over.rect.top - (active.rect.current?.translated?.top ?? 0) >=
			DRAGGING_GROUPED_ITEM_OUT_TOP_THRESHOLD
		const isBelow =
			Math.abs((active.rect.current?.translated?.top ?? 0) - over.rect.bottom) <=
			DRAGGING_GROUPED_ITEM_OUT_BOTTOM_THRESHOLD
		const isOutOfGroup =
			(active.rect.current?.translated?.left ?? 0) - over.rect.left <
			DRAGGING_GROUPED_ITEM_OUT_LEFT_THRESHOLD

		if ((isAbove || isBelow) && isOutOfGroup) {
			const overContainerIdx = findContainerIndex(over.id)
			const overContainer = pricingListItems[overContainerIdx]
			const clonedContainers = [...getValues().pricingItems]

			if (!isGroup(overContainer)) {
				return
			}

			const newFloatingItem = overContainer.deliverables.find(
				(item) => getOrGenerateUUID(item) === active.id,
			)

			const newOverContainerDeliverables = overContainer.deliverables.filter(
				(item) => getOrGenerateUUID(item) !== active.id,
			)

			if (newFloatingItem) {
				;(clonedContainers[overContainerIdx] as PricingGroupType).deliverables =
					newOverContainerDeliverables

				if (isAbove && overContainerIdx === 0) {
					clonedContainers.unshift(newFloatingItem)
				} else if (isBelow && overContainerIdx === clonedContainers.length - 1) {
					clonedContainers.push(newFloatingItem)
				} else if (isAbove) {
					clonedContainers.splice(overContainerIdx, 0, newFloatingItem)
				} else if (isBelow) {
					clonedContainers.splice(overContainerIdx + 1, 0, newFloatingItem)
				}
			}

			replace(clonedContainers)
		}
	}

	function handleDragOver(event: DragOverEvent) {
		const { active, over } = event

		// Check if dragging groups or floating item
		const activeContainer = findContainerById(active.id)
		if (activeContainer && isGroup(activeContainer)) {
			return
		}

		if (over) {
			// Find the containers
			const activeContainerIdx = findContainerIndex(active.id)
			const overContainerIdx = findContainerIndex(over.id)

			if (
				activeContainerIdx === -1 ||
				overContainerIdx === -1 ||
				activeContainerIdx === overContainerIdx
			) {
				return
			}

			if (!findContainerById(over.id)) {
				return
			}

			const activeContainer = pricingListItems[activeContainerIdx]
			const overContainer = pricingListItems[overContainerIdx]

			// Dragging group items
			if (isGroup(overContainer)) {
				const isBelowOverItem =
					active.rect.current.translated &&
					active.rect.current.translated.top > over.rect.top + over.rect.height

				const modifier = isBelowOverItem ? 1 : 0
				const overIndex = overContainer.deliverables.findIndex(
					(item) => getOrGenerateUUID(item) === over.id,
				)

				const isInGroup =
					(active.rect.current?.translated?.left ?? 0) - over.rect.left >
					DRAGGING_ITEM_INTO_GROUP_LEFT_THRESHOLD

				const newIndex =
					overIndex >= 0 ? overIndex + modifier : overContainer.deliverables.length + 1

				const clonedContainers = [...getValues().pricingItems]

				// Dragging item from group to another group
				if (isGroup(activeContainer) && isInGroup) {
					// Find the indexes of pricing items
					const activeIndex = activeContainer.deliverables.findIndex(
						(item) => getOrGenerateUUID(item) === active.id,
					)

					const newActiveContainer = [
						...activeContainer.deliverables.filter((item) => getOrGenerateUUID(item) !== active.id),
					]
					const newOverContainer = [
						...overContainer.deliverables.slice(0, newIndex),
						activeContainer.deliverables[activeIndex],
						...overContainer.deliverables.slice(newIndex, overContainer.deliverables.length),
					]

					;(clonedContainers[activeContainerIdx] as PricingGroupType).deliverables =
						newActiveContainer
					;(clonedContainers[overContainerIdx] as PricingGroupType).deliverables = newOverContainer

					replace(clonedContainers)
				}
				// Draging floating item into a group
				else if (isDeliverable(activeContainer) && isInGroup) {
					const activeIndex = pricingListItems.findIndex(
						(item) => getOrGenerateUUID(item) === active.id,
					)

					const newOverContainerDeliverables = [
						...overContainer.deliverables.slice(0, newIndex),
						clonedContainers[activeIndex] as Deliverable,
						...overContainer.deliverables.slice(newIndex, overContainer.deliverables.length),
					]

					;(clonedContainers[overContainerIdx] as PricingGroupType).deliverables =
						newOverContainerDeliverables
					clonedContainers.splice(activeIndex, 1)

					replace(clonedContainers)
				}
			}
		}
	}

	function renderListItemOverlay(active: Active) {
		const { id } = active
		const activeContainerIdx = findContainerIndex(id)
		const activeContainer = getValues().pricingItems[activeContainerIdx]
		const activeTarget = findContainerById(id)

		function renderPricingItemOverlay(item: Deliverable) {
			const { deliverableTitle, pricingPreferences, rates } = item

			return (
				<PricingItemOverlay
					id={`${id}`}
					pricingItemHeaderProps={{
						title: deliverableTitle ?? '',
						pricingModel: pricingPreferences ?? '',
						subItemsCount: rates.length,
						pricingItemIndex: getPricingItemIndex(`${id}`),
					}}
					errors={errors}
					expanded={getItemExpanded(item)}
				/>
			)
		}

		// A pricing group or floating item
		if (activeTarget) {
			if (isGroup(activeTarget)) {
				return (
					<PricingGroupOverlay
						id={`${id}`}
						errors={errors}
						groupIndex={getPricingGroupIndex(`${id}`)}
						title={activeTarget?.title ?? ''}
					/>
				)
			} else if (isDeliverable(activeTarget)) {
				return renderPricingItemOverlay(activeTarget)
			}
		}
		// Pricing items belongs to a group
		else if (activeContainer && isGroup(activeContainer)) {
			const pricingItem = activeContainer.deliverables.find(
				(item) => getOrGenerateUUID(item) === id,
			)
			if (pricingItem && isDeliverable(pricingItem)) {
				return renderPricingItemOverlay(pricingItem)
			}
		}

		return null
	}
}

/** When we are using keyboard, restrict the movement within parent only to avoid confusing screen reader users  */
const restrictToParentElementOnKeyboard: Modifier = (props) => {
	const event = props.activatorEvent as KeyboardEvent
	if (event?.code === 'Enter' || event?.code === 'Space') {
		return restrictToParentElement(props)
	}

	return props.transform
}
