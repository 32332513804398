import { ChannelWithLastMessage } from './types'

export const sortChannels = (channels: Array<ChannelWithLastMessage>, sortBy: string) => {
	if (sortBy === 'CREATED_DATE') {
		const sortedChannels = channels.sort((a, b) => {
			const aDate = new Date(a.lastMessage ? parseInt(a.lastMessage.createdAt, 10) : 0)
			const bDate = new Date(b.lastMessage ? parseInt(b.lastMessage.createdAt, 10) : 0)

			if (aDate > bDate) {
				return -1
			} else if (bDate > aDate) {
				return 1
			}

			//If last message created at is the same, sort by firm or client name
			const aOrgName = a.orgs.firm ? a.orgs.firm.name : a.orgs.client ? a.orgs.client.name : ''
			const bOrgName = b.orgs.firm ? b.orgs.firm.name : b.orgs.client ? b.orgs.client.name : ''
			return aOrgName.localeCompare(bOrgName)
		})
		return sortedChannels
	} else if (sortBy === 'ORG_NAME') {
		const sortedChannels = channels.sort((a, b) => {
			const aOrgName = a.orgs.firm ? a.orgs.firm.name : a.orgs.client ? a.orgs.client.name : ''
			const bOrgName = b.orgs.firm ? b.orgs.firm.name : b.orgs.client ? b.orgs.client.name : ''
			return aOrgName.localeCompare(bOrgName)
		})
		return sortedChannels
	}
}
