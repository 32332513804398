import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	useTheme,
	useSnackbar,
	MenuItemProps,
	useConfirmDialog,
	DeleteIcon,
} from '@persuit/ui-components'
import { graphql, useMutation } from '@persuit/ui-graphql'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { RoutesContext } from '../../../routes-context'

const DELETE_RFP_TEMPLATE = graphql(`
	mutation RfpTemplateHeaderDeleteTemplate($templateId: ID!) {
		deleteRfpTemplate(_id: $templateId) {
			templateId
		}
	}
`)

export type DeleteMenuItemProps = {
	templateId: string
	isUploading: boolean
} & MenuItemProps

export const DeleteMenuItem = ({ templateId, isUploading, ...props }: DeleteMenuItemProps) => {
	const theme = useTheme()
	const history = useHistory()
	const routes = useContext(RoutesContext)
	const { openSnackbar } = useSnackbar()
	const { openConfirmDialog } = useConfirmDialog()
	const [deleteRfpDraftRevision] = useMutation(DELETE_RFP_TEMPLATE)

	const handleMutation = async () => {
		try {
			await deleteRfpDraftRevision({ variables: { templateId } })
			openSnackbar('Request Template discarded')
			history.push(routes.postDelete.uriFn())
		} catch (error) {
			openSnackbar('There was a network error. Please retry!', { variant: 'error' })
			console.error('saveProposalRevisionComment', error)
		}
	}

	return (
		<MenuItem
			disabled={isUploading}
			onClick={() => {
				openConfirmDialog({
					title: 'Are you sure you want to delete this template?',
					size: 'sm',
					actions: [
						{ label: 'Cancel', type: 'secondary', close: true },
						{
							label: 'Delete',
							type: 'primary',
							icon: <DeleteIcon />,
							action: async () => {
								await handleMutation()
							},
						},
					],
				})
			}}
			sx={{ color: theme.palette.primary.main, width: '100%' }}
			{...props}
		>
			<ListItemIcon>
				<DeleteIcon color="primary" fontSize="small" />
			</ListItemIcon>

			<ListItemText primary="DELETE" />
		</MenuItem>
	)
}
