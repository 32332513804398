import { merge } from 'lodash'
// eslint-disable-next-line no-restricted-imports
import { SxProps, Theme } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { SystemStyleObject } from '@mui/system'

export function applySx<T extends Theme = Theme>(theme: T, sx?: SxProps<T>): SystemStyleObject<T> {
	return !sx ? {} : typeof sx === 'function' ? (sx as any)(theme) : sx
}

export function composeSx<T extends Theme = Theme>(
	parent: SxProps<T> = {},
	overrides?: SxProps<T>,
): SxProps<Theme> {
	return ((theme: T): SystemStyleObject<T> =>
		merge(applySx(theme, parent), applySx(theme, overrides))) as SxProps<Theme>
}
