import { Box, Typography, Alert, AlertTitle } from '@persuit/ui-components'
import { averageProposalRates, clientPhaseModel } from '@persuit/common-logic'
import { ResponseToDeliverable } from '@persuit/ui-graphql/generated'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type AverageRateInfoProps = {
	currency: string
	responseToDeliverables: Pick<
		ResponseToDeliverable,
		'firmSuppliedRates' | 'responseToRates' | 'pricingModel'
	>[]
	isDetailView?: boolean
	averageRate?: number | null
	totalPriceRequired: boolean
}

export const AverageRateInfo = ({
	currency,
	responseToDeliverables,
	isDetailView,
	averageRate,
	totalPriceRequired,
}: AverageRateInfoProps) => {
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const averageOfAllRates = isDetailView
		? averageRate
		: averageProposalRates({
				responseToDeliverables,
		  })

	const isRatesOnlyRequest = responseToDeliverables.every((response) => {
		return (
			response.pricingModel === clientPhaseModel.HOURLYRATE ||
			response.pricingModel === clientPhaseModel.RATECARD
		)
	})

	const noRateRequest = responseToDeliverables.every(
		(response) =>
			response.pricingModel !== clientPhaseModel.HOURLYRATE &&
			response.pricingModel !== clientPhaseModel.RATECARD,
	)

	if (!isRateCardAuctionEnabled || noRateRequest) return null

	return (
		<>
			{isRatesOnlyRequest && !totalPriceRequired && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						mt: isRatesOnlyRequest ? 3 : undefined,
					}}
				>
					<Alert severity="info" role="status">
						<AlertTitle>This request is using average of all rates for price ranking.</AlertTitle>
						<Typography>
							When the total price is not requested and only rates are requested, the price ranking
							will be determined by the average of all provided rates.
						</Typography>
					</Alert>
				</Box>
			)}

			<Box display="flex" my={3}>
				<Box sx={{ width: '50%' }}>
					<Box display="flex" alignItems="center">
						<Typography variant="h3XSmall">Average of all rates</Typography>
					</Box>
					<Box>
						<Typography variant="body1">
							This is the average of all rate card items and hourly rate items.
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography variant="h3XSmall" component="p">
						{formatPriceWithCurrency(currency, averageOfAllRates, true, '0,0.[00]')}
					</Typography>
				</Box>
			</Box>
		</>
	)
}
