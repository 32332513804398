import { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'

import { generateInitialFormValues } from '../../../../../utils/scorecard'

import Scorecard from './scores'
import ScorecardForm from './form'
import { ScoreCardCellHeader } from './scorecard-cell-header'

class ScorecardScoresCell extends Component {
	render() {
		const {
			otherProposalsScoreBeingEdited,
			responseId,
			responseStatus,
			scorecard,
			editMode,
			scoreCategories,
			showSubcategories,
		} = this.props

		const hasScoreCategories = scoreCategories && scoreCategories.length > 0

		return (
			<div>
				<ScoreCardCellHeader scoreCategories={scoreCategories} responseId={responseId} />
				{/* Only show the scorecard if there are some categories */}
				{!editMode && hasScoreCategories && (
					<Fragment>
						<Scorecard
							scoreCategories={scoreCategories}
							scorecard={scorecard}
							showSubcategories={otherProposalsScoreBeingEdited || showSubcategories}
							proposalId={responseId}
							proposalStatus={responseStatus}
						/>
					</Fragment>
				)}
				{editMode && (
					<div>
						<ScorecardForm
							scoreCategories={scoreCategories}
							proposalId={responseId}
							initialValues={{
								scores: generateInitialFormValues(scorecard, scoreCategories),
							}}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (
	{
		proposalScores, // From redux state
	},
	{
		responseId, // From props
	},
) => ({
	// If our current proposals score is being edited
	editMode: proposalScores.idOfProposalBeingEdited === responseId,

	// If any other proposals score is currently being edited
	otherProposalsScoreBeingEdited: proposalScores.proposalScoreBeingEdited,

	showSubcategories: proposalScores.showSubcategories,
})

export default memo(connect(mapStateToProps, {})(ScorecardScoresCell))
