import axios from 'axios'

import { logout } from './utils/cookieRefresh'

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = `${window.location.origin}/en`

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.data && error.data.errorCode && error.data.errorCode === 'COOKIE_AUTH_FAILED') {
			console.error('Cookie auth failed.', error.data.errorMessage)

			logout()
		}

		return Promise.reject(error)
	},
)

export default axiosInstance
