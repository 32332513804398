import * as FS from '@fullstory/browser'

export const fullStoryInititalize = () => {
	if (
		window.__FULLSTORY_ORG_ID__ &&
		(window.__SENTRY_ENV__ === 'production' || window.__SENTRY_ENV__ === 'demo')
	) {
		FS.init({
			orgId: window.__FULLSTORY_ORG_ID__,
		})
	}
}

type FSIdentifyProps = {
	userId: string
	orgId: string
	email: string
	roles: string[]
	isImpersonated: boolean
}

export const fs = {
	...FS,
	identify: ({ userId, orgId, email, roles, isImpersonated }: FSIdentifyProps) => {
		if (fs.isInitialized()) {
			FS.identify(userId, {
				orgId,
				environment: window.__SENTRY_ENV__,
				email,
				roles,
				isImpersonated,
			})
		}
	},
}
