const states = {
	QUEUED: 'queued', // not yet scanned
	CLEAN: 'clean', // processed and clean
	VIRUS: 'virus', // found a virus
	NOTALLOWED: 'notallowed', // file type not in whitelist or filetype was changed or hidden from it's actual filename
	UNKNOWNTYPE: 'unknowntype', // filetype cannot be determined
	SCANERROR: 'scanerror', // virus error
	OVERSIZE: 'oversize', // file too big (50MB max)
	MALICIOUS: 'malicious', // malicious
	// possibly a legacy value - there are files with this value in prod, but
	// I cannot find any references to it in code
	EMBEDDED: 'embedded',
}

const values = Object.keys(states).map((key) => states[key])

export { values, states as default }
