import { Fragment } from 'react'

import { compose } from 'redux'
import injectSheet from 'react-jss'
import Spacer from '../../layout/spacer.jsx'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { grey } from '@mui/material/colors'

/*

Used on request for sub items (excluding rate card) when there are no resposes
When there are responses use SubItemsList.
In expanded view use SubItemsList with `showTotal = false`

Looks like this:

Item 1__________________Pricing Model

*/

const PricingListItem = ({ items, classes }) => {
	return (
		<div>
			{items.map((item, i) => (
				<Fragment key={`clientRate${i}`}>
					<div className={item.right ? classes.item : null}>
						{item.left}
						{item.right}
					</div>
					<Spacer space={1} />
				</Fragment>
			))}
		</div>
	)
}
PricingListItem.styles = {
	item: {
		borderBottom: `1px solid ${grey[500]}`,
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
	},
}
export default compose(injectSheet(PricingListItem.styles))(PricingListItem)
