import { useState, useCallback } from 'react'

/**
 * A wrapper around useState for common use cases where you need a boolean value that can be toggled
 */
export const useToggle = (initialState: boolean) => {
	const [state, setState] = useState(initialState)
	const toggle = useCallback(
		(value?: boolean) => setState(typeof value === 'boolean' ? value : (x) => !x),
		[setState],
	)
	return [state, toggle] as const
}
