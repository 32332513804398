import { useState } from 'react'
import { gql } from '@apollo/client'
import { useQuery } from '@persuit/ui-graphql'
import { Unauthorized } from '@persuit/ui-shared-components'
import responseActions from '../../common/data/response-actions'
import responseStates from '../../common/data/rfp-proposal-states'
import RfpInvitedFirmList from '../components/rfp/invites/rfp-invited-firm-list'
import { PeopleSearchDialog } from './sharing'
import { InviteTabs } from '../pages/new-rfp-form/invite-section/invite-tabs'

export const GET_SESSION_QUERY = gql`
	query SharingControl_getSessionInfo {
		getSessionInfo {
			user {
				_id
				favourites
				org {
					_id
					groups {
						_id
						name
					}
					panelFirmTags {
						firmId
						type
						groupId
					}
				}
				group {
					_id
					name
				}
			}
		}
	}
`

const RfpManageFirmsContainer = ({ params, rfp, rfp: { status, allow }, proposals }) => {
	const [showPeopleSearchDialog, setShowPeopleSearchDialog] = useState(false)
	const [showInviteDialog, setShowInviteDialog] = useState(false)

	const { data: getSession, loading, error } = useQuery(GET_SESSION_QUERY)

	if (loading) {
		return null
	}

	if (error) {
		return <Unauthorized redirectPath={`/en/`} buttonLabel="Go to dashboard" />
	}

	const user = getSession?.getSessionInfo?.user ?? {}
	const userGroup = user.group ?? undefined
	const userOrg = user.org ?? {}

	// Match up invited firm orgs with their corresponding proposals
	const enhancedInvites = rfp.invites.map((invite) => {
		// Add the proposals for this invite
		return {
			...invite,
			proposals: proposals
				.filter((proposal) => {
					return proposal.org._id.toString() === invite.item._id.toString()
				})
				.filter((proposal) => {
					return proposal.status !== responseStates.REVISED
				}),
		}
	})

	const invitedFirms = enhancedInvites.map((enhancedInvite) => {
		const firstProposal = enhancedInvite.proposals.length > 0 ? enhancedInvite.proposals[0] : null

		// The date shown on the card is either the date the firm updated their
		// invite state (i.e. cleared conflicts) or the date they submitted their
		// first propsoal
		let date = enhancedInvite.updatedAt

		if (firstProposal) {
			const publishedAction = firstProposal.activityLog.find(
				(item) => item.action === responseActions.PUBLISH,
			)

			// Need to make sure the published action exists to account
			// for any bad data in mongo
			if (publishedAction) {
				date = publishedAction.date
			}
		}

		return {
			...enhancedInvite,
			date,
		}
	})

	return (
		<div>
			{showPeopleSearchDialog && (
				<PeopleSearchDialog
					entity={rfp}
					isOpen={true}
					togglePeopleSearchDialog={() => setShowPeopleSearchDialog(false)}
					user={{ _id: user._id, org: userOrg, group: userGroup }}
				/>
			)}
			{showInviteDialog && (
				<InviteTabs
					toggleInviteDialog={() => setShowInviteDialog(false)}
					showInviteDialog={showInviteDialog}
					rfp={rfp}
				/>
			)}
			<RfpInvitedFirmList
				rfpStatus={status}
				invites={invitedFirms}
				handleInviteFirm={() => setShowPeopleSearchDialog(true)}
				params={params}
				sentTo={allow}
				rfp={{ ...rfp, proposals }}
				showInviteDialog={() => setShowInviteDialog(true)}
				user={{ _id: user._id, org: userOrg, group: userGroup, favorites: user.favorites ?? [] }}
			/>
		</div>
	)
}

export default RfpManageFirmsContainer
