const isFunction = (maybeAFn) => {
	if (maybeAFn instanceof Function) return true
	return false
}

const EnableStepIf = ({ condition, children }) => {
	if (isFunction(condition)) {
		return condition() ? children : null
	}
	return condition === true ? children : null
}

export default EnableStepIf
