import {
	Tooltip,
	Typography,
	styled,
	Box,
	ErrorOutlineIcon,
	useTheme,
	SROnly,
} from '@persuit/ui-components'
import { useId } from 'react'
import * as React from 'react'
import { NavLink as RRLink } from 'react-router-dom'
import { NavSubItems, NavSubItemsProps } from './nav-subitems'

const StyledLink = styled(RRLink)<{ selected: boolean }>(
	({ selected, theme: { palette } }) => `
	color: ${selected ? palette.primary.main : palette.common.black};
	font-weight: ${selected ? 'bold' : 'normal'};
	background-color: ${selected ? palette.primary.lighterHue : 'inherit'};

	border-radius: 4px;

	&:hover {
		background-color: ${palette.primary.lighterHue};
		cursor: ${selected ? 'unset' : 'pointer'};
	}
`,
)

export type NavItemProps = {
	/** Purely for meta data purposes, used for data-testid and data-trackid */
	sectionName?: string
	title: string
	icon: React.ReactElement
	adornment?: React.ReactElement
	selected?: boolean
	to: string
	error?: boolean
	subItems?: NavSubItemsProps['subItems']
	lockInfo: {
		letter: string
		tooltip: string
	} | null
	disabled?: boolean
	disabledReason?: string
}

export const NavItem = ({
	sectionName,
	title,
	subItems = [],
	icon,
	adornment,
	error,
	to: uri,
	selected = false,
	lockInfo,
	disabled = false,
	disabledReason,
}: NavItemProps) => {
	const showSubItems = selected && subItems && subItems.length > 0
	const theme = useTheme()
	const descriptionId = useId()

	const content = (
		<Box
			borderLeft={`4px solid ${selected ? theme.palette.primary.main : 'transparent'}`}
			padding="8px 8px 8px 4px"
			display="flex"
			flexDirection="column"
			borderRadius="4px 0px 0px 4px"
			gap="8px"
			sx={{ position: 'relative' }}
		>
			<Box
				width="100%"
				height="auto"
				display="grid"
				gridTemplateColumns="24px minmax(0, 1fr) auto 24px"
				gap="8px"
				gridTemplateRows="auto"
			>
				<Box
					gridRow={1}
					gridColumn={1}
					width="24px"
					height="24px"
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					color={selected ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.54)'}
				>
					{error ? <ErrorOutlineIcon color="error" aria-hidden={true} /> : icon}
				</Box>

				<Box gridRow={1} gridColumn={2} alignSelf="center" lineHeight="unset">
					{adornment ? (
						<Box sx={{ display: 'flex', gap: 1 }}>
							<Typography>{selected ? <b>{title}</b> : title}</Typography>
							{adornment}
						</Box>
					) : (
						<Typography>{selected ? <b>{title}</b> : title}</Typography>
					)}
				</Box>

				{subItems.length === 0 ? null : (
					<Box
						gridRow={1}
						gridColumn={3}
						height="24px"
						textAlign="center"
						lineHeight="24px"
						marginRight="8px"
						sx={{
							visibility: {
								xs: 'hidden',
								lg: 'initial',
							},
						}}
					>
						{subItems.length}
					</Box>
				)}

				{!!lockInfo && (
					<Tooltip title={lockInfo.tooltip} placement="top" arrow={true}>
						<Box
							gridRow={1}
							gridColumn={4}
							width="24px"
							height="24px"
							borderRadius="12px"
							color={theme.palette.white}
							bgcolor={theme.palette.primary.main}
							textAlign="center"
							lineHeight="24px"
							fontWeight="normal"
						>
							{lockInfo.letter}
						</Box>
					</Tooltip>
				)}
			</Box>

			{showSubItems && <NavSubItems subItems={subItems} aria-label={`${title}'s sub-items`} />}

			<SROnly id={descriptionId}>
				{`${error ? 'Errors present. ' : ''}${
					subItems.length > 0 ? `${subItems.length} sub items. ` : ''
				}${lockInfo ? `${lockInfo.tooltip}.` : ''}`}
			</SROnly>
		</Box>
	)

	if (disabled) {
		return (
			<Tooltip disabled={!disabled} arrow={true} title={`${title} - ${disabledReason}`}>
				<Box position="relative">
					<Box sx={disabled ? { opacity: 0.6, pointerEvents: 'none' } : undefined}>
						{content}
						<SROnly>{`Disabled, ${disabledReason}`}</SROnly>
					</Box>
				</Box>
			</Tooltip>
		)
	}

	return (
		<StyledLink
			selected={selected}
			data-trackid={`navigation-link-${sectionName}`}
			to={uri}
			aria-label={title}
			aria-describedby={descriptionId}
			// Don't show aria-current when it's expanded as group
			aria-current={showSubItems ? 'false' : selected ? 'page' : undefined}
			// Error in type defs of react-router-dom, passing string is valid to render native HTML element
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			component={showSubItems ? 'div' : undefined}
			role={showSubItems ? 'group' : 'link'}
		>
			{content}
		</StyledLink>
	)
}
