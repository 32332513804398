import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardStartProps = ProgressCardProps

export const CARD_TITLE = 'Start a draft'

export const ProgressCardStart = ({ index, step }: ProgressCardStartProps) => {
	return <BaseCard title={CARD_TITLE} index={index} status={step.status} />
}
