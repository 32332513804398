import { gql } from '@persuit/ui-graphql'
import userActivityGql from '../fragments/user-activity-gql'

const getUserActivity = gql`
	${userActivityGql}

	query {
		getUserActivity {
			...UserActivity
		}
	}
`

export default getUserActivity
