/**
 * Returns a function that opens the browser's print dialog when run
 */
export function useCreatePdf(): () => void {
	return () => {
		// the browser print dialog appears before the menu finishes it's closing animation
		// a delay to allow the menu to close before requesting the browser to print
		// alternativly we could disable animation for the menu
		setTimeout(window.print, 500)
	}
}
