// @ts-strict-ignore
import { useSelector } from '@client/store'
import sharingErrors from '../../../../common/data/sharing-errors'
import useTranslation from '../../../custom-hooks/translation-hook'

type InviteErrorProps = {
	error: any
}
// Convenience function to format and translate any errors for displaying
// them in a dialog
export const InviteErrors = ({ error }: InviteErrorProps) => {
	const { t } = useTranslation()
	const allowableEmailSuffixes = useSelector(
		(state) => state.auth.user?.org?.allowableEmailSuffix ?? [],
	)

	if (!error) {
		return null
	}

	// The custom error data sent back from the sharing resolver is contained here
	// if there any errors occurred
	const errorData: any = error?.graphQLErrors?.[0]?.extensions || null

	if (!errorData) {
		return null
	}

	// Append errors for existing user shares (group invalid user shares by error)
	const existingUserErrors = errorData?.erroneousExistingUsers
		? Object.keys(errorData.erroneousExistingUsers).reduce((acc, errorKey) => {
				const errorForUser = errorData.erroneousExistingUsers[errorKey]
				const errorMessage = t(`sharing.${errorForUser.error}`)

				acc[errorMessage] = {
					...(acc[errorMessage] || {}),
					[errorForUser.user.email]: true,
				}

				return acc
		  }, {})
		: []

	// Append errors for unregistered user shares (group invalid user shares by error)
	const unregisteredUserErrors = errorData?.erroneousUnregisteredUsers
		? Object.keys(errorData?.erroneousUnregisteredUsers).reduce((acc, errorKey) => {
				const errorForUser = errorData.erroneousUnregisteredUsers[errorKey]
				const errorMessage = t(`sharing.${errorForUser.error}`)

				acc[errorMessage] = {
					...(acc[errorMessage] || {}),
					[errorForUser.user.email]: true,
				}

				return acc
		  }, {})
		: []

	// Construct allowable email suffixes for displaying in the error dialog
	const allowableEmailMessage =
		allowableEmailSuffixes.length > 0
			? t('sharing.trusted-domains', { domains: allowableEmailSuffixes.join(', ') })
			: ''

	return (
		<div>
			{/*
				Show all existing user errors (single error message followed by each
				invalid share
			*/}
			{Object.keys(existingUserErrors).map((existingUserError) => {
				const userEmails = Object.keys(existingUserErrors[existingUserError])

				const showAllowableEmailMessage =
					existingUserError === t(`sharing.${sharingErrors.ANY_SHARING_OUTSIDE_TRUSTED_DOMAIN}`) ||
					existingUserError === t(`sharing.${sharingErrors.CLIENT_SHARING_WITH_ANOTHER_ORG}`)

				return (
					<div key={existingUserError}>
						{existingUserError}&nbsp;{showAllowableEmailMessage && allowableEmailMessage}
						<ul>
							{userEmails.map((email) => (
								<li key={email}>{email}</li>
							))}
						</ul>
					</div>
				)
			})}
			{/*
				Show all unregistered user errors (single error message followed by each
				invalid share
			*/}
			{Object.keys(unregisteredUserErrors).map((unregisteredUserError) => {
				const userEmails = Object.keys(unregisteredUserErrors[unregisteredUserError])

				const showAllowableEmailMessage =
					unregisteredUserError ===
						t(`sharing.${sharingErrors.ANY_SHARING_OUTSIDE_TRUSTED_DOMAIN}`) ||
					unregisteredUserError === t(`sharing.${sharingErrors.CLIENT_SHARING_WITH_ANOTHER_ORG}`)

				return (
					<div key={unregisteredUserError}>
						{unregisteredUserError}&nbsp;{showAllowableEmailMessage && allowableEmailMessage}
						<ul>
							{userEmails.map((email) => (
								<li key={email}>{email}</li>
							))}
						</ul>
					</div>
				)
			})}
		</div>
	)
}
