import * as React from 'react'
import { Box, BoxProps } from '@persuit/ui-components'

type PricingCardTemplateProps = {
	body?: React.ReactNode
	footer?: React.ReactNode
	header?: React.ReactNode
	containerProps?: BoxProps
}

export const PricingCardTemplate = ({
	header,
	body,
	footer,
	containerProps,
}: PricingCardTemplateProps) => {
	return (
		<Box width="100%" data-testid="pricing-card-template" {...containerProps}>
			{header && <div>{header}</div>}
			{body && <div>{body}</div>}
			{footer && <div>{footer}</div>}
		</Box>
	)
}

PricingCardTemplate.displayName = 'PricingCardTemplate'
