import * as React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, AccordionProps } from '../accordion'
import { Typography, TypographyProps } from '../typography'
import { Box } from '../box'
import { Paper } from '../paper'

export type TextAccordionProps = {
	title: string
	titleProps?: TypographyProps
	/** Text content */
	children: React.ReactNode
} & Pick<AccordionProps, 'initialExpanded'>

export const TextAccordion = ({ title, children, initialExpanded }: TextAccordionProps) => {
	return (
		<Accordion
			ContainerComponent={Paper}
			elevation={0}
			square={true}
			sx={{
				bgcolor: 'transparent',
				borderTop: '2px solid',
				borderBottom: '2px solid',
				borderColor: 'form.borderActive',
				'&:hover': {
					borderColor: 'primary.main',
				},
			}}
			initialExpanded={initialExpanded}
		>
			<AccordionSummary iconButtonLabel={title} sx={{ px: 3 }}>
				<Box display="flex">
					<Typography color="primary.main" variant="body1Semibold" fontWeight={600}>
						{title}
					</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3, pb: 1.5, pt: 0.5 }}>{children}</AccordionDetails>
		</Accordion>
	)
}
