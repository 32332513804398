import { Fragment } from 'react'
import { DeepPick } from '@persuit/common-utils'
import { Rfp } from '../../../../../types'
import { HeadlineSeparator } from '../../../headline-separator'
import { TotalPrice } from '../total-price'
import { RichTextView, Spacer } from '@persuit/ui-components'
import { Deliverables } from '../deliverables'
import { ComparisonValue } from '../comparison-value'

export type RfpDetailPricingSectionDocType = DeepPick<
	Rfp,
	| 'detail.deliverablesV2'
	| 'detail.totalPriceRequired'
	| 'detail.priceDescription'
	| 'detail.totalPricingPreference'
	| 'useCase'
>

type RfpDetailPricingSectionProps = {
	document: RfpDetailPricingSectionDocType
	isFirmView: boolean
}

export const RfpDetailPricingSection = ({ document, isFirmView }: RfpDetailPricingSectionProps) => {
	const deliverables = document?.detail?.deliverablesV2 ?? []
	const totalPriceRequired = document?.detail?.totalPriceRequired ?? false
	const priceDescription = document?.detail?.priceDescription ?? ''
	const totalPricingPreference = document?.detail?.totalPricingPreference ?? 'none'
	const useCase = document?.useCase

	if (deliverables.length === 0 && !priceDescription && !totalPriceRequired) return null

	if (useCase === 'rateReview') return null

	return (
		<Fragment>
			<HeadlineSeparator headline="Pricing" />
			<TotalPrice
				totalPriceRequired={totalPriceRequired}
				totalPricingPreference={totalPricingPreference}
			/>
			<ComparisonValue
				totalPriceRequired={totalPriceRequired}
				deliverables={deliverables}
				isFirmView={isFirmView}
			/>
			<Spacer space={1} />

			{!!priceDescription && (
				<RichTextView
					sx={{ mb: 1 }}
					data-testid="total-price-instructions"
					content={priceDescription}
				/>
			)}

			<Deliverables deliverables={deliverables} />
		</Fragment>
	)
}
