import isEmpty from 'lodash/fp/isEmpty'
import { compose, reverse, sortBy } from 'lodash/fp'
import getOr from 'lodash/fp/getOr'

import { participateEvents } from '../../../../../common/data/rfp-invite-events'
import proposalStates from '../../../../../common/data/rfp-proposal-states'
import { getOrFalse, getOrNull, getOrZero } from '../../../../utils/lenses'
import { ThumbUpIcon, RemoveCircleIcon } from '@persuit/ui-components'

export const getInvitedStep = (rfp, invite) => {
	const inviteCreatedAt = getOrZero('createdAt', invite)
	const rfpPublishedAt = getOrZero('publishedAt', rfp)
	const date = Math.max(inviteCreatedAt, rfpPublishedAt)
	return {
		label: 'Invited',
		date,
	}
}

export const getViewedStep = (invite) => {
	const hasViewedAt = getOrFalse('hasViewedAt', invite)
	if (!hasViewedAt) return null
	const viewedAt = getOrZero('viewedAt', invite)
	return {
		label: 'Viewed Request',
		date: viewedAt,
	}
}

export const getConflictStep = (rfp, invite) => {
	const ccRequired = getOrFalse('ccRequired', rfp)
	if (!ccRequired) return null
	const conflictResponse = getOrNull('state.conflict.response', invite)
	const date = getOrZero('state.conflict.createdAt', invite)
	if (conflictResponse === true) {
		return {
			label: 'Cleared Conflicts',
			date,
		}
	}
	if (conflictResponse === false) {
		return {
			label: 'Unable to clear conflicts',
			date,
			labelProps: {
				StepIconComponent: RemoveCircleIcon,
				StepIconProps: { sx: { color: 'black' } },
			},
		}
	}

	return {
		label: 'Cleared Conflicts',
		date: 0,
	}
}

export const getParticipateStep = (invite) => {
	const participateResponse = getOr(
		participateEvents.UNANSWERED,
		'state.participate.response',
		invite,
	)
	const date = getOrZero('state.participate.createdAt', invite)

	if (participateResponse === participateEvents.PARTICIPATING) {
		return {
			label: 'Agreed to Participate',
			date,
		}
	}

	if (participateResponse === participateEvents.NOT_PARTICIPATING) {
		return {
			label: 'Unable to Participate',
			date,
			labelProps: {
				StepIconComponent: RemoveCircleIcon,
				StepIconProps: { sx: { color: 'black' } },
			},
		}
	}

	return {
		label: 'Agreed to Participate',
		date: 0,
	}
}

export const getFinalStep = (proposals, invite) => {
	const isEliminated = getOrFalse('state.eliminate.eliminated', invite) === true
	const eliminatedDate = getOrZero('state.eliminate.createdAt', invite)
	if (isEliminated) {
		return {
			label: 'Unsuccessful',
			labelProps: {
				error: true,
				StepIconComponent: RemoveCircleIcon,
				// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
				StepIconProps: { sx: { color: '#bb3355' } },
			},
			date: eliminatedDate,
		}
	}

	const acceptedProposal = proposals.find((proposal) => proposal.status === proposalStates.ACCEPTED)
	if (acceptedProposal) {
		return {
			label: 'Selected',
			labelProps: {
				StepIconComponent: ThumbUpIcon,
				// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
				StepIconProps: { sx: { color: '#33bb77' } },
			},
			date: getProposalAcceptedDate(acceptedProposal.activityLog),
		}
	}

	const submittedProposals = proposals.filter(
		(proposal) => proposal.status !== proposalStates.DRAFT,
	)
	if (!isEmpty(submittedProposals)) {
		return {
			label: 'Submitted proposal',
			date: getOldestProposalDate(submittedProposals),
		}
	}

	// inactive state
	return {
		label: 'Submitted proposal',
		date: 0,
	}
}

export const getProposalAcceptedDate = (logs) => {
	// sort the activityLog in reverse chronological order
	const sortedLogs = compose(reverse, sortBy('date'))(logs)
	const log = sortedLogs.find((log) => log.action === 'accept')

	return log ? log.date : ''
}

export const getOldestProposalDate = (proposals) => {
	const oldestProposalDate = proposals.reduce((acc, proposal) => {
		// any submitted or previously submitted proposal. ie. not a draft
		if ((proposal.status !== proposalStates.DRAFT && !acc) || acc > proposal.createdAt) {
			return proposal.createdAt
		}

		return acc
	}, null)

	return oldestProposalDate
}
