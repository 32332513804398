// @ts-strict-ignore
import { useState } from 'react'
import numeral from 'numeral'
import moment, { Moment } from 'moment'
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ReferenceLine,
} from 'recharts'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
	Switch,
	FormControlLabel,
} from '@persuit/ui-components'
import { generateRatesChartData } from '../calculations'
import currencies from '../../../../../../common/data/currencies'

import ChartTooltip from './chart-tooltip'
import { format } from 'date-fns'
import { formatPriceWithCurrency } from '@persuit/common-utils'

const formatDate = (date: number) => format(new Date(date), 'd MMMM yyyy, h:mm a')

const formatPriceForYAxis = ({ value, currency }) => {
	const currencySymbol = currencies[currency].symbol
	const formattedVal = numeral(value).format('0.0a', Math.ceil)
	return `${currencySymbol}${formattedVal}`.toUpperCase()
}

type ChartProps = {
	/** Aspect ratio of Rechart ResponsiveContainer */
	aspect?: number
	currency: string
	end: Moment | null
	/** Height of Rechart ResponsiveContainer */
	height?: number
	orgIdToColourMapping: Record<string, string>
	orgsToChart: { orgName: string; orgId: string }[]
	start: Moment
	zoomToAuction: boolean
	responses: any[]
	proposalsDueBy: number
	auctionEnd: number
}

type ChartDataPoint = {
	time: number
	orgId: string
	[key: string]: number | string
}

type StartEndDataPoint = {
	time: number
	[key: string]: number
}

/*
 * WARNING: This doesn't behave sensibly for multiple proposals from the same firm.
 *
 * The data points are keys by firm, not by proposal
 * This can lead to strange chart behaviour
 * Not worth fixing at this point.
 * It's unlikely a firm would have two active proposals during an auction
 *
 */
export const RatesChart = ({
	aspect = 2,
	height,
	zoomToAuction,
	start,
	end,
	orgsToChart,
	currency,
	orgIdToColourMapping,
	responses,
	proposalsDueBy,
	auctionEnd,
}: ChartProps) => {
	const [showTable, setShowTable] = useState(false)

	const chartData: (ChartDataPoint | StartEndDataPoint)[] = generateRatesChartData({
		responses,
		auctionStart: proposalsDueBy,
		auctionEnd,
	})

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<ResponsiveContainer aria-hidden={true} aspect={height ? undefined : aspect} height={height}>
				<LineChart
					data={chartData}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 15,
					}}
				>
					<XAxis
						dataKey="time"
						height={60}
						tickMargin={18}
						type="number"
						tickFormatter={(value) => moment(value).format('h:mmA (DD MMM)')}
						domain={[
							zoomToAuction ? () => moment(start).valueOf() : 'auto',
							zoomToAuction ? () => moment(end).valueOf() : 'auto',
						]}
						allowDataOverflow={true}
					/>
					<YAxis
						tickFormatter={(value) => formatPriceForYAxis({ value, currency })}
						width={90}
						tickMargin={18}
						domain={[
							// Add some buffer so that there is space between the lowest
							// trendline and the x-axis
							(dataMin) => dataMin - 0.1 * dataMin,
							// Leave the max as default
							'auto',
						]}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip
						isAnimationActive={false}
						content={<ChartTooltip currency={currency} />}
						wrapperStyle={{ maxWidth: '80%' }}
						allowEscapeViewBox={{ x: false, y: true }}
					/>
					<Legend />

					{/*
						Iterate through each org and output a line for each
						The actual data is provided to the <LineChart> component above
					*/}
					{orgsToChart.map(({ orgName, orgId }) => {
						return (
							<Line
								type="stepAfter"
								key={orgName}
								dataKey={orgName}
								stroke={orgIdToColourMapping[orgId]}
								strokeWidth={2}
								activeDot={{ r: 8 }}
								connectNulls={true}
							/>
						)
					})}
					<ReferenceLine
						alwaysShow={true}
						x={start.valueOf()}
						stroke="green"
						strokeWidth={2}
						label={
							{
								position: 'top',
								value: 'Start',
								fill: 'green',
								fontSize: 14,
							} as any
						}
						strokeDasharray="3 3"
					/>
					{end && (
						<ReferenceLine
							alwaysShow={true}
							x={end.valueOf()}
							stroke="red"
							strokeWidth={2}
							label={
								{
									position: 'top',
									value: 'End',
									fill: 'red',
									fontSize: 14,
								} as any
							}
							strokeDasharray="3 3"
						/>
					)}
				</LineChart>
			</ResponsiveContainer>

			<FormControlLabel
				control={<Switch checked={showTable} onChange={(e) => setShowTable(e.target.checked)} />}
				label={'Show sequential bid table'}
			/>

			{showTable && (
				<Table aria-label="Sequential bid table">
					<caption>
						This table shows the bid history for the request, sorted by the time the bid occurred.
					</caption>
					<TableHead>
						<TableRow>
							<TableCell>Time</TableCell>
							<TableCell>Firm</TableCell>
							<TableCell>Average of all rates</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{chartData
							// Filter out the start and end data points
							.filter((dataPoint): dataPoint is ChartDataPoint => !!dataPoint.orgId)
							.map((bid, index) => {
								const [, firm] = Object.keys(bid)
								return (
									<TableRow key={index}>
										<TableCell>{formatDate(bid.time)}</TableCell>
										<TableCell>{firm}</TableCell>
										<TableCell>{formatPriceWithCurrency(currency, bid[firm])}</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			)}
		</Box>
	)
}
