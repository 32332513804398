import { createRHFUtils } from '@persuit/ui-components'
import { RfpQuestion } from '../../../types'

type QuestionFormValues = {
	questions: RfpQuestion[] & {
		type?: string
	}
	questionGroups: Array<any>
}

const { useFieldArray, useWatch } = createRHFUtils<QuestionFormValues>()

export const useQuestionFieldArray = () =>
	useFieldArray({
		name: 'questions',
	})

export const useQuestionGroupsFieldArray = () =>
	useFieldArray({
		name: 'questionGroups',
	})

export { useWatch }
