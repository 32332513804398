const states = {
	CREATED: 'created',
	CC_SENT: 'cc_sent',
	CLEARED: 'cleared',
	DECLINED: 'declined',
	ELIMINATED: 'eliminated',
}

const values = Object.keys(states).map((key) => states[key])

export { values, states as default }
