import * as React from 'react'
import { Box, Button, Typography, useTheme, InfoIcon, PopoverHelp } from '@persuit/ui-components'
import { CardIcon } from './card-icon'
import { RfpProgressStatusEnum } from '@persuit/ui-graphql/generated'

export type BaseCardProps = {
	index: number
	status: RfpProgressStatusEnum
	title: string
	subtitle?: React.ReactNode
	guidanceText?: string
	warningText?: string
	helpText?: string
	action?: {
		title: string
		onAction: () => void
		dataTrackId: string
	}
}

export const BaseCard = ({
	index,
	status,
	title,
	helpText,
	subtitle,
	guidanceText,
	warningText,
	action,
}: BaseCardProps) => {
	const theme = useTheme()
	return (
		<Box
			role="listitem"
			width="100%"
			height="auto"
			display="grid"
			gridTemplateRows="auto auto"
			gridTemplateColumns="auto 1fr"
			gap={theme.spacing(1)}
			alignItems="center"
			paddingTop={1}
			paddingBottom={1}
			bgcolor="white"
			aria-label={`${title} ${status}`}
		>
			<Box gridRow={1} gridColumn={1}>
				<CardIcon aria-hidden="true" index={index} status={status} />
			</Box>
			<Box
				gridRow={1}
				gridColumn={2}
				width="100%"
				height="auto"
				display="grid"
				gridTemplateRows="auto"
				gridTemplateColumns="1fr auto"
				gap={theme.spacing(1)}
				alignItems="center"
			>
				<Typography>
					<b>{title}</b>
				</Typography>
				{helpText && (
					<PopoverHelp
						content={<Box>{helpText}</Box>}
						triggerButtonSize="small"
						triggerButtonProps={{
							'aria-label': `${title} help`,
							size: 'small',
						}}
					/>
				)}
			</Box>

			<Box
				gridRow={2}
				gridColumn={2}
				width="100%"
				height="auto"
				display="flex"
				flexDirection="column"
				gap={theme.spacing(1)}
			>
				{subtitle}
				{guidanceText && (
					<Box
						display="grid"
						height="auto"
						gridTemplateColumns="auto minmax(0, 1fr)"
						gap={theme.spacing(1)}
						border={`1px solid ${theme.palette.info.main}`}
						borderRadius="4px"
						color={theme.palette.info.main}
						bgcolor={theme.palette.background.info}
						padding={1}
					>
						<InfoIcon color="inherit" />
						<Typography variant="body2" color="inherit">
							{guidanceText}
						</Typography>
					</Box>
				)}
				{warningText && (
					<Box
						display="grid"
						height="auto"
						gridTemplateColumns="auto minmax(0, 1fr)"
						gap={theme.spacing(1)}
						border={`1px solid ${theme.palette.warning.lightestHue}`}
						borderRadius="4px"
						color={theme.palette.warning.lightHue}
						bgcolor={theme.palette.background.warning}
						padding={1}
					>
						<InfoIcon color="inherit" />
						<Typography variant="body2" color="inherit">
							{guidanceText}
						</Typography>
					</Box>
				)}
				{action && (
					<Box>
						<Button
							variant="outlined"
							color="primary"
							onClick={action.onAction}
							data-trackid={action.dataTrackId}
						>
							{action.title}
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	)
}
