import { useState } from 'react'
import { Button } from '@persuit/ui-components'
import { ViewAssumptionDialog } from '../../rfp-request-proposal-revision/assumptions-edit'

type ViewAssumptionDialogProps = {
	suggestedAssumptions: string
}

const ViewAssumptionsDialog = ({ suggestedAssumptions }: ViewAssumptionDialogProps) => {
	const [open, setOpen] = useState(false)

	const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setOpen(true)
	}
	const handleClose = () => setOpen(false)

	return (
		<>
			<Button variant="text" size="small" onClick={handleOpen}>
				Updated
			</Button>
			<ViewAssumptionDialog
				handleViewClose={() => {
					handleClose()
				}}
				isOpen={open}
				currentAssumptions={suggestedAssumptions}
				isClientOrg={true}
			/>
		</>
	)
}

export default ViewAssumptionsDialog
