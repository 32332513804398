import ManageProposalWizard from './wizard'
import { getWizardProps } from './wizard-props'

/**
 * Assign the types to props
 * @param {Object} props
 * @param {Record<string, unknown>} props.proposal
 * @param {Record<unknown, unknown>} props.proposals
 * @param {Record<string, unknown>} props.rfp
 * @param {() => void} props.openProposalFeedbackFormAll
 * @param {() => void} props.openProposalFeedbackFormSingleFirm
 * @param {string} props.startingStep
 * @param {string} props.wizardName
 * @param {boolean} props.isFirmEliminated
 */

export default function ManageProposalWizardWrapper({
	rfp,
	proposal,
	proposals,
	fetchResponses,
	handleClose,
	isFirmEliminated,
	openProposalFeedbackFormAll,
	openProposalFeedbackFormSingleFirm,
	startingStep,
	wizardName,
}) {
	const wizardProps = getWizardProps({ proposal, rfp, proposals })

	return (
		<ManageProposalWizard
			{...wizardProps}
			fetchResponses={fetchResponses}
			handleClose={handleClose}
			isFirmEliminated={isFirmEliminated}
			openProposalFeedbackFormAll={openProposalFeedbackFormAll}
			openProposalFeedbackFormSingleFirm={openProposalFeedbackFormSingleFirm}
			startingStep={startingStep}
			wizardName={wizardName}
		/>
	)
}

ManageProposalWizardWrapper.defaultProps = {
	handleClose: () => {},
	fetchResponses: () => {},
}
