/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Link as RRLink } from 'react-router-dom'
import stringTrucate from 'lodash/truncate'

import {
	Badge,
	Divider,
	List,
	ListItem,
	MessagesIcon,
	Box,
	Typography,
	ListItemText,
	ListItemIcon,
	Link,
	Button,
} from '@persuit/ui-components'
import { graphql, useMutation } from '@persuit/ui-graphql'

export type ChannelsWithUnreadsProps = {
	closeUnreadMessagesNotification: () => void
	refetchRequestsWithUnreads: () => Promise<void>
	requestsWithUnreads: {
		requestId: string
		requestHeadline?: string
		unreadCount: number
	}[]
}

const MARK_ALL_AS_READ = graphql(`
	mutation channelsWithUnreadsMarkAllAsReadGlobal {
		markAllChannelsAsReadGlobal {
			id
			myUnreads {
				unreadCount
			}
		}
	}
`)

export const ChannelsWithUnreads = ({
	closeUnreadMessagesNotification,
	refetchRequestsWithUnreads,
	requestsWithUnreads,
}: ChannelsWithUnreadsProps) => {
	const [markAllAsReadGlobal] = useMutation(MARK_ALL_AS_READ)
	return (
		<Box
			sx={{
				padding: '1em',
				maxWidth: '26em',
			}}
		>
			{requestsWithUnreads.length <= 0 && (
				<Typography variant="body1">You have no unread messages</Typography>
			)}

			{requestsWithUnreads.length > 0 && (
				<Box display="flex" flexDirection="column" gap={1}>
					<Typography variant="body1">
						You have unread messages on the following requests:
					</Typography>
					<Box maxHeight="24em" overflow="auto">
						<List>
							{requestsWithUnreads.map(({ requestId, requestHeadline, unreadCount }, index) => {
								// Headline is either a trucated version of the real headline or "Untited draft"
								const headline = requestHeadline
									? stringTrucate(requestHeadline, {
											length: 80,
									  })
									: 'Untitled draft'

								return (
									<>
										<ListItem
											onClick={closeUnreadMessagesNotification}
											style={{
												marginBottom: '0.5em',
												fontStyle: requestHeadline ? 'regular' : 'italic',
											}}
										>
											<Link
												to={`/en/request/${requestId}/`}
												sx={{ display: 'flex', alignItems: 'center' }}
												component={RRLink}
											>
												<ListItemIcon>
													<Badge badgeContent={unreadCount} color="error" key={requestId}>
														<MessagesIcon />
													</Badge>
												</ListItemIcon>
												<ListItemText> {headline}</ListItemText>
											</Link>
										</ListItem>

										{/* Add a divider between each list item. Don't output after last item */}
										{index !== requestsWithUnreads.length - 1 && <Divider />}
									</>
								)
							})}
						</List>
					</Box>
					<Box display="flex" flexDirection="row-reverse">
						<Button
							data-trackid="button-mark-all-as-read-global"
							variant="text"
							onClick={async () => {
								await markAllAsReadGlobal()
								await refetchRequestsWithUnreads()
							}}
						>
							Mark all as read
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}
