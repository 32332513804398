import { useEffect, useState } from 'react'
import * as React from 'react'
import {
	Alert,
	Button,
	Chip,
	CircularProgress,
	KeyboardArrowDownIcon as CloseIcon,
	CopyIcon,
	AIIcon,
	DialogTitle as MuiDialogTitle,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
	Fab,
	Fade,
	IconButton,
	styled,
	Typography,
	Paper,
	Popper,
	useTheme,
	Box,
	ScreenOnly,
	RefreshIcon,
} from '@persuit/ui-components'
import ReactMarkdown from 'react-markdown'
import removeMarkdown from 'remove-markdown'

import { useStore } from '../store'
import {
	copyToClipboard,
	hasActiveProposals,
	hasQuestionsAndTotalPrice,
	allFirmsEliminated,
} from '../utils'

import { Feedback } from './feedback'

const OPEN_AI_URI = window.__OPEN_AI_URI__

const StyledFab = styled(Fab)(({ theme, loading }) => ({
	inset: 'auto 10px 10px auto',
	position: 'fixed',
	zIndex: '1010',
	...(loading && {
		animation: `color 2s infinite`,
	}),
	'@keyframes color': {
		'0%': {
			backgroundColor: theme.palette.secondary.main,
		},
		'50%': {
			backgroundColor: theme.palette.secondary.main,
		},
		'100%': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
}))

const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	margin: 0,
	padding: theme.spacing(2),
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	background: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: theme.spacing(1),
	top: theme.spacing(1.5),
	color: theme.palette.secondary.contrastText,
}))

const DialogTitle = (props) => {
	const { children, classes, onClose, ...other } = props
	return (
		<>
			{onClose ? (
				<StyledCloseButton aria-label="close dialog " onClick={onClose}>
					<CloseIcon data-trackid="button-collapse-ai-summary" />
				</StyledCloseButton>
			) : null}
			<StyledDialogTitle variant="h2XSmall" {...other}>
				{children}
			</StyledDialogTitle>
		</>
	)
}

const StyledPopper = styled(Popper)(({ theme }) => ({
	inset: 'auto 10px 75px auto !important',
	zIndex: '1064',
	borderRadus: 3,
	margin: theme.spacing(1),
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
	overflowY: 'auto',
	maxHeight: 'calc(90vh - 200px)',
	border: 'none',
	root: {
		padding: theme.spacing(2),
	},
}))

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
	margin: 0,
	padding: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
}))

const StyledPaper = styled(Paper)({
	width: '600px',
})

const StyledChip = styled(Chip)(({ theme }) => ({
	justifyContent: 'center',
	background: theme.palette.secondary.contrastText,
	color: theme.palette.secondary.main,
	position: 'relative',
	top: '-1px',
	marginLeft: theme.spacing(0.5),
	'& > *': {
		margin: theme.spacing(0.5),
	},
}))

const components = {
	h1: ({ children }) => (
		<Typography variant="h4" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	h2: ({ children }) => (
		<Typography variant="h5" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	h3: ({ children }) => (
		<Typography variant="h6" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	h4: ({ children }) => (
		<Typography variant="subtitle1" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	h5: ({ children }) => (
		<Typography variant="subtitle2" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	h6: ({ children }) => (
		<Typography variant="body1" gutterBottom={true} style={{ fontWeight: 500 }}>
			{children}
		</Typography>
	),
	paragraph: ({ children }) => (
		<Typography variant="body1" gutterBottom={true} component="p">
			{children}
		</Typography>
	),
	li: ({ children }) => (
		<Typography variant="body1" component="li">
			{children}
		</Typography>
	),
	strong: ({ node, ...props }) => <span style={{ fontWeight: 500 }} {...props} />,
}

function MarkdownRenderer({ markdown, isCached }) {
	const AlwaysScrollToBottom = () => {
		const elementRef = React.useRef()
		React.useEffect(() => {
			if (elementRef.current) {
				elementRef.current.scrollIntoView()
			}
		})
		return <div ref={elementRef} />
	}
	return (
		<div>
			<ReactMarkdown components={components}>{markdown}</ReactMarkdown>
			{!isCached && <AlwaysScrollToBottom />}
		</div>
	)
}

const FloatingWidgetContent = () => {
	const { proposalIds, rfpId, hasQuestionsAndTotalPriceForAiWidget } = useStore((state) => ({
		hasQuestionsAndTotalPriceForAiWidget: hasQuestionsAndTotalPrice(state.request, state.responses),
		rfpId: state.request._id,
		proposalIds: state.responses.map((res) => res._id),
	}))

	const [anchorEl, setAnchorEl] = useState(null)
	const [loading, setLoading] = useState(false)
	const [comparisonText, setComparisonText] = useState('')
	const [isCached, setIsCached] = useState(false)
	const [eventSourceContainer, setEventSource] = useState(null)
	const [error, setError] = useState(false)
	const [cacheError, setCacheError] = useState(false)

	const theme = useTheme()

	useEffect(() => {
		const fetchCachedData = async () => {
			try {
				const fetched = await fetch(
					`${OPEN_AI_URI}/openai/proposal-analysis-cache/?rfpId=${rfpId}`,
					{
						credentials: 'include',
					},
				)

				if (!fetched.ok) {
					// Handle the case where the server responded with an error status
					throw new Error(`Request failed with status ${fetched.status}`)
				} else {
					const response = await fetched.json()
					return response
				}
			} catch (err) {
				console.error(err)
				throw err
			}
		}

		async function fetchData() {
			try {
				const response = await fetchCachedData()
				if (!response) {
					setIsCached(false)
				} else {
					setComparisonText(response)
					setIsCached(true)
				}
			} catch (err) {
				console.error(err)
				setCacheError(true)
				setIsCached(false)
			}
		}
		fetchData()
	}, [setComparisonText, rfpId, cacheError])

	const rerunProposalAnalysis = async () => {
		await fetch(`${OPEN_AI_URI}/openai/proposal-analysis-clear/?rfpId=${rfpId}`, {
			credentials: 'include',
		})

		setIsCached(false)

		await fetchProposalAnalysis()
	}

	const fetchProposalAnalysis = async () => {
		setComparisonText('')
		setLoading(true)

		const eventSource = new EventSource(
			`${OPEN_AI_URI}/openai/proposal-analysis-multi/?rfpId=${rfpId}`,
			{ withCredentials: true },
		)
		setEventSource(eventSource)
		eventSource.onopen = () => {
			console.info('Reading response from OpenAI')
		}
		eventSource.onmessage = (event) => {
			// check for the "done" message
			if (event.data === '[DONE]') {
				eventSource.close() // close the event source
				setLoading(false)
				setIsCached(true)
			} else {
				// parse and process the JSON data
				const parsedData = JSON.parse(event.data)
				const content = parsedData?.content // Get the content property from the delta object if it exists

				if (content) {
					setComparisonText((prev) => prev + content)
				}
			}
		}
		eventSource.onerror = () => {
			eventSource.close()
			setLoading(false)
			setError(true)
			setIsCached(false)
		}
	}

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
		setError(false)
	}

	const handleButtonClick = () => {
		if (!comparisonText) {
			fetchProposalAnalysis()
		} else {
			setComparisonText('')
			handleClick()
		}
	}

	const handleCancel = () => {
		eventSourceContainer.close()
		setLoading(false)
		setComparisonText('')
		setEventSource(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'widget-popper' : undefined

	if (!hasQuestionsAndTotalPriceForAiWidget) {
		return (
			<div>
				<div>
					<StyledPopper open={open} transition={true}>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={350}>
								<StyledPaper>
									<DialogTitle id="customized-dialog-title" onClose={handleClick}>
										Proposal Analyzer with AI{' '}
										<StyledChip color="primary" label="BETA" size="small" />
									</DialogTitle>
									<DialogContent dividers={true}>
										<Alert severity="info" sx={{ mb: 1 }}>
											The quick summary can only be generated when proposals contain both a total
											price and responses to questions.
										</Alert>
									</DialogContent>
									<DialogActions>
										<div style={{ display: 'flex', width: '100%' }}>
											<Button color="secondary" onClick={handleClick}>
												Cancel
											</Button>
										</div>
									</DialogActions>
								</StyledPaper>
							</Fade>
						)}
					</StyledPopper>
				</div>
				<ScreenOnly>
					<StyledFab
						color="secondary"
						variant="extended"
						aria-label="Summarize proposals with AI"
						aria-describedby={id}
						onClick={handleClick}
					>
						<AIIcon />
						<Box sx={{ mr: '0.5rem' }} />
						Summarize with AI
					</StyledFab>
				</ScreenOnly>
			</div>
		)
	}

	return (
		<div>
			<div>
				<StyledPopper open={open} transition={true}>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350}>
							<StyledPaper>
								<DialogTitle id="customized-dialog-title" onClose={handleClick}>
									Proposal Analyzer with AI <StyledChip color="primary" label="BETA" size="small" />
								</DialogTitle>
								<DialogContent dividers={true}>
									{error && (
										<Alert
											severity="error"
											onClose={() => {
												setError(false)
											}}
											sx={{ mb: 1 }}
										>
											Oops! Error generating proposal comparison. Please try again.
										</Alert>
									)}
									{cacheError && (
										<Alert
											severity="error"
											onClose={() => {
												setCacheError(false)
											}}
											sx={{ mb: 1 }}
										>
											Failed to access proposal comparison cache. Please try again later.
										</Alert>
									)}
									{!loading && !comparisonText && (
										<>
											<Typography variant="body1" gutterBottom={true}>
												Summarize proposals, highlighting strengths, weaknesses, and key
												differences, to provide a ranking, by leveraging Open AI's cutting-edge
												language model, ChatGPT.
											</Typography>
										</>
									)}
									{comparisonText && (
										<MarkdownRenderer markdown={comparisonText} isCached={isCached} />
									)}
								</DialogContent>
								<DialogActions>
									{isCached && (
										<div style={{ display: 'flex', width: '100%' }}>
											<Feedback rfpId={rfpId} proposalIds={proposalIds} />
											<div style={{ flex: 1 }} />

											<Button
												color="secondary"
												onClick={async () => {
													await rerunProposalAnalysis()
												}}
												disabled={loading}
												aria-label="Re-run anlaysis"
												startIcon={<RefreshIcon />}
												data-trackid="button-re-run-ai-summary"
											>
												Re-run analysis
											</Button>

											<Button
												color="secondary"
												onClick={async () => {
													await copyToClipboard(
														removeMarkdown(comparisonText, { stripListLeaders: false }),
													)
												}}
												disabled={loading}
												aria-label="Copy summary"
												startIcon={<CopyIcon />}
												data-trackid="button-copy-ai-summary"
											>
												Copy
											</Button>
										</div>
									)}
									{!isCached && (
										<div style={{ display: 'flex', width: '100%' }}>
											<Button
												color="secondary"
												variant="outlined"
												onClick={handleButtonClick}
												disabled={loading || cacheError}
												data-trackid="button-generate-ai-summary"
											>
												Get a quick summary
											</Button>
											{loading && (
												<Button
													color="secondary"
													onClick={handleCancel}
													sx={{ marginLeft: '0.5rem' }}
													data-trackid="button-cancel-generate-ai-summary"
												>
													Cancel
												</Button>
											)}
										</div>
									)}
								</DialogActions>
							</StyledPaper>
						</Fade>
					)}
				</StyledPopper>
			</div>
			<ScreenOnly>
				<StyledFab
					color="secondary"
					variant="extended"
					aria-label={!loading ? 'Summarize proposals with AI' : 'Analyzing'}
					aria-describedby={id}
					onClick={handleClick}
					loading={loading}
					data-trackid="button-summarize-ai"
				>
					{!loading && <AIIcon />}
					{loading && (
						<CircularProgress size="1.2rem" sx={{ color: theme.palette.secondary.contrastText }} />
					)}
					<div style={{ marginRight: '0.5rem' }} />
					{!loading && !comparisonText && 'Summarize with AI'}
					{loading && 'Analyzing...'}
					{!loading && comparisonText && 'Summarize with AI'}
				</StyledFab>
			</ScreenOnly>
		</div>
	)
}

export const FloatingWidget = () => {
	const showWidget = useStore(
		(state) => hasActiveProposals(state.responses) && !allFirmsEliminated(state.request),
	)
	return showWidget ? <FloatingWidgetContent /> : null
}
