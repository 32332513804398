import { List, ListProps } from '../../list'
import { FileDownload, FileDownloadProps } from './file-download'

type FileRepresentation = FileDownloadProps['file']

export type FileDownloadListProps = ListProps & {
	files: FileRepresentation[]
	label?: string
}

export const FileDownloadList = ({
	files,
	label = 'list of downloadable files',
	...rest
}: FileDownloadListProps) => {
	return (
		<List dense={true} aria-label={label} {...rest}>
			{files.map((file) => (
				<FileDownload key={file._id} file={file} />
			))}
		</List>
	)
}
