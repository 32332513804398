import { Box, RichTextView, Typography, useTheme } from '@persuit/ui-components'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'
import { MessagesChannelPaneHumanMessageFragment } from '@persuit/ui-graphql/generated'
import { makeFormatMessageAtSafe } from '../../util'

const formatMessageAtSafe = makeFormatMessageAtSafe('h:mm a')

const MESSAGE_FRAGMENT = graphql(`
	fragment MessagesChannelPaneHumanMessage on Message {
		createdBy {
			name {
				first
				last
			}
		}
		messageAt
		message
	}
`)

export type HumanMessageProps = {
	message: FragmentType<typeof MESSAGE_FRAGMENT>
	currentUser: boolean
	currentOrg: boolean
	side: 'left' | 'right'
}

export const useMessageBubbleColor = (
	currentUser: boolean,
	currentOrg: boolean,
): { bg: string; fg: string; time: string } => {
	const { palette } = useTheme()
	if (currentUser)
		return { bg: palette.primary.lightHue, fg: palette.common.white, time: palette.common.white }
	if (currentOrg)
		return {
			bg: palette.primary.lighterHue,
			fg: palette.common.black,
			time: palette.neutral.darkerHue,
		}
	return {
		bg: palette.neutral.lighterHue,
		fg: palette.common.black,
		time: palette.neutral.darkerHue,
	}
}

export const getMessageBubbleName = (
	currentUser: boolean,
	createdBy: MessagesChannelPaneHumanMessageFragment['createdBy'],
) => {
	if (currentUser) return 'You'
	const firstName = createdBy?.name?.first ?? ''
	const lastname = createdBy?.name?.last ?? ''
	if (firstName || lastname) return [firstName, lastname].join(' ')
	return 'User'
}

export const HumanMessage = ({ message, currentOrg, currentUser, side }: HumanMessageProps) => {
	const { createdBy, messageAt, message: messageContent } = getFragment(MESSAGE_FRAGMENT, message)

	const colors = useMessageBubbleColor(currentUser, currentOrg)
	const name = getMessageBubbleName(currentUser, createdBy)
	const date = formatMessageAtSafe(messageAt)

	return (
		<Box
			width="100%"
			display="flex"
			flexDirection={side === 'left' ? 'row' : 'row-reverse'}
			role="listitem"
			mt={2}
		>
			<Box
				width="90%"
				maxWidth="640px"
				bgcolor={colors.bg}
				color={colors.fg}
				p={2}
				display="flex"
				flexDirection="column"
				gap={2}
				borderRadius={1}
			>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Typography color="inherit" variant="body1Semibold">
						{name}
					</Typography>
					<Typography color={colors.time} variant="caption">
						{date}
					</Typography>
				</Box>
				<RichTextView content={messageContent ?? 'Empty Message'} />
			</Box>
		</Box>
	)
}
