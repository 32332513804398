import { graphql } from '@persuit/ui-graphql'

const revoke = graphql(`
	mutation RevokeOld($rfpId: ID!, $userIds: [ID!]!) {
		revokeUsersFromRfp(rfpId: $rfpId, userIds: $userIds) {
			... on Rfp {
				...RfpOld
			}
		}
	}
`)

export default revoke
