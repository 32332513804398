import useTranslation from '../../../../custom-hooks/translation-hook'
import { Deliverable } from '@persuit/ui-graphql/generated'
import { Box, RichTextView, Typography } from '@persuit/ui-components'

import { ExpandableRowHeaderCell, ExpandableRowHeaderCellProps } from '../components'
import { useActions, useStore } from '../../store'
import { SortButton, SortOrder } from '../components/sort-button'

type PricingCellHeaderProps = Pick<ExpandableRowHeaderCellProps, 'sx' | 'cellProps'> & {
	deliverable: Deliverable
	groupTitle?: string
	index: number
}

export const PricingHeaderCell = ({
	deliverable: { deliverableTitle, deliverable, pricingPreferences, _id },
	groupTitle,
	index,
	...rest
}: PricingCellHeaderProps) => {
	const { t } = useTranslation()
	const { truncateCellContents, currentSort, isClient } = useStore((state) => ({
		truncateCellContents: state.truncateCellContents,
		currentSort: state.sort,
		isClient: !state.isFirm,
	}))

	const truncationLength = truncateCellContents ? 100 : undefined
	const preferredPricingModel =
		pricingPreferences === 'NONE' ? null : (
			<Typography display="block" variant="body2" sx={{ mb: 2 }} color="text.secondary">
				{t(`pricingModels.client.phase.${pricingPreferences}`)}
			</Typography>
		)

	const { setSort } = useActions()

	const sortOrder = currentSort?.key === _id ? currentSort.order : null

	const sortPrices = (order: SortOrder) => {
		setSort({
			type: 'pricing',
			key: _id ?? '',
			order,
		})
	}

	return (
		<ExpandableRowHeaderCell
			expandedContent={
				<>
					{preferredPricingModel}
					<RichTextView content={deliverable ?? ''} />
				</>
			}
			expandedTitle={`${index + 1}. ${deliverableTitle}`}
			expandedSubTitle={groupTitle}
			{...rest}
		>
			<Box>
				{isClient ? (
					<SortButton sortOrder={sortOrder} onClick={sortPrices} sx={{ float: 'right' }} />
				) : null}
				<Typography gutterBottom={true}>
					{`${index + 1}. `}
					{deliverableTitle}
				</Typography>
			</Box>

			{preferredPricingModel}

			<Typography display="block" variant="body2">
				<RichTextView content={deliverable ?? ''} truncationLength={truncationLength} />
			</Typography>
		</ExpandableRowHeaderCell>
	)
}
