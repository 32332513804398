import { TypeGuard } from './type-guard'

type BooleanFunction = (val: unknown) => boolean

export function not<A, B extends A>(predicate: TypeGuard<A, B>): TypeGuard<A, Exclude<A, B>>
export function not(predicate: BooleanFunction): BooleanFunction
export function not<A, B extends A>(
	predicate: TypeGuard<A, B> | BooleanFunction,
): TypeGuard<A, Exclude<A, B>> | BooleanFunction {
	return (a: A): a is Exclude<A, B> => !predicate(a)
}
