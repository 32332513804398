import { useStore } from '../../store'

import { copyToClipboard } from '../../utils'
import { PlaceholderLoading } from '../../intelligence/placeholder-loading'
import { useTotalPriceIntelligence } from '../../intelligence/intelligence-hook'
import {
	shortenArrForSummary,
	formatExtendedSummaryForClipboard,
} from '../../intelligence/intelligence-column-utils'

import { ExpandableIntelligenceCell, ExpandableIntelligenceCellProps } from '../components'

import { Error, formatExtendedSummary, getMaxSummaryLength } from '../../intelligence/components'

type TotalPriceIntelligenceCellProps = Pick<ExpandableIntelligenceCellProps, 'headers'>

export const ComparisonValueIntelligenceCell = ({ ...rest }: TotalPriceIntelligenceCellProps) => {
	const { isWide, request } = useStore((state) => ({
		isWide: state.isWideIntelligence,
		request: state.request,
	}))

	const totalPriceIntelligence = useTotalPriceIntelligence(request._id)

	const maxSummaryLength = getMaxSummaryLength(isWide)

	return (
		<ExpandableIntelligenceCell
			{...rest}
			expandedContent={
				<>
					{totalPriceIntelligence?.loading && <PlaceholderLoading />}
					{totalPriceIntelligence?.error && <Error code={totalPriceIntelligence?.error} />}
					{!totalPriceIntelligence?.error &&
						!totalPriceIntelligence?.loading &&
						formatExtendedSummary(totalPriceIntelligence?.intelligence ?? {})}
				</>
			}
			expandedTitle="Total price"
			copyToClipboard={async () => {
				return await copyToClipboard(
					formatExtendedSummaryForClipboard(
						'Total price',
						totalPriceIntelligence?.intelligence ?? [],
					),
				)
			}}
			copyToClipboardDisabled={Boolean(
				totalPriceIntelligence?.error || totalPriceIntelligence?.loading,
			)}
			data-trackid="total-price"
		>
			{totalPriceIntelligence?.loading && <PlaceholderLoading />}
			{totalPriceIntelligence?.error && <Error code={totalPriceIntelligence?.error} />}
			{!totalPriceIntelligence?.error &&
				!totalPriceIntelligence?.loading &&
				formatExtendedSummary(
					shortenArrForSummary(totalPriceIntelligence?.intelligence ?? [], maxSummaryLength),
				)}
		</ExpandableIntelligenceCell>
	)
}
