import { MutableRefObject, Ref, RefCallback } from 'react'
import { isNotNil } from '@persuit/common-utils'

type OptionalRef<T> = Ref<T> | undefined | null

export function composeRefs<T>(...refs: Array<OptionalRef<T>>): RefCallback<T> {
	return (instance: null | T) =>
		refs.filter(isNotNil).forEach((ref) => {
			if (typeof ref === 'function') {
				ref(instance)
			} else {
				;(ref as MutableRefObject<T | null>).current = instance
			}
		})
}
