import { useHistory, useParams } from 'react-router-dom'
import { isNotDraftStatus } from '../../../common/proposals/isProposalADraft'
import { PROPOSAL_PAGES, PagesType } from '../../../common/proposals/pages-viewmode'
import { RfpProposalState } from '../../../common/data/rfp-proposal-states'
import { noop } from '@persuit/common-utils'

type UseFirmNavigationToPageProps = {
	requestObjectRefetch?: () => void
	proposalStatus: RfpProposalState | null
	viewMode?: PagesType | undefined
}

export function useFirmNavigationToPage({
	requestObjectRefetch = noop,
	proposalStatus,
	viewMode,
}: UseFirmNavigationToPageProps) {
	const history = useHistory()
	const { lng, requestId, proposalId } = useParams<{
		lng: string
		requestId: string
		proposalId: string
	}>()

	function navigateToPage(pageTo: PagesType, options: { proposalId?: string }): void {
		if (viewMode && viewMode === pageTo) {
			switch (viewMode) {
				// If drafts are already present, a dialog is shown alerting about existing drafts.
				// If a user clicks "VIEW DRAFTS", page gets reloaded unnecessarily. The following case handles it
				case PROPOSAL_PAGES.VIEW_REQUEST:
					return
				/**
				 * Exception EDIT PROPOSAL page, in order to reset requestObject which resets dirty and pristine variables
				 * Can be reproduced as, Change a value in form on edit proposal page, save it and click back button, dirty field is not reset yet
				 * */
				case PROPOSAL_PAGES.EDIT_PROPOSAL:
					requestObjectRefetch()
					return
				// Exception: When VIEW AUCTION button is clicked on DRAFT preview page the page should redirect
				case PROPOSAL_PAGES.PROPOSAL_PREVIEW_COMPARE:
					if (proposalStatus && isNotDraftStatus(proposalStatus)) {
						return
					}
			}
		}

		if (pageTo === PROPOSAL_PAGES.BACK) {
			history.goBack()
			requestObjectRefetch()
			return
		}

		// If there is a new proposal ID, use that, otherwise use the existing ID
		const urlProposalId = options?.proposalId ?? proposalId

		const urlMap: Record<string, string> = {
			[PROPOSAL_PAGES.HOME]: `/${lng}`,
			[PROPOSAL_PAGES.VIEW_REQUEST]: `/${lng}/request/${requestId}`,
			[PROPOSAL_PAGES.EDIT_PROPOSAL]: `/${lng}/request/${requestId}/proposals/edit/${urlProposalId}`,
			[PROPOSAL_PAGES.PROPOSAL_PREVIEW_COMPARE]: `/${lng}/request/${requestId}/proposals/preview-compare/${urlProposalId}`,
			[PROPOSAL_PAGES.PROPOSAL_PREVIEW_DETAIL]: `/${lng}/request/${requestId}/proposals/preview-detail/${urlProposalId}`,
		}

		const url: string | undefined = urlMap[pageTo]

		// Fetch updated details from the request object
		requestObjectRefetch()
		if (url) return history.push(url)
	}

	return { navigateToPage }
}
