// eslint-disable-next-line no-restricted-imports
import { RfpDetailResponsesQuery, RfpDetailRfpQuery } from '@persuit/ui-graphql/hooks'
import { isNotNil, RequiredNonNullableKeys } from '@persuit/common-utils'
import { createStore } from '@persuit/ui-state'

import { ResponseForNegotiateDialog } from '../negotiate-dialog'
import { PROPOSAL_STATES } from '../../../../common/data/rfp-proposal-states'

import { createActions } from './actions'
import { selectors } from './selectors'

type ComparisonTableResponseUnion = NonNullable<RfpDetailResponsesQuery['getResponses'][number]>

export type ComparisonTableResponse = Extract<
	ComparisonTableResponseUnion,
	{ __typename: 'Response' }
>

export type ComparisonTableRequest = RequiredNonNullableKeys<
	NonNullable<RfpDetailRfpQuery['getRfp']>,
	'detail'
>

export type SortType =
	| 'firmName'
	| 'totalPrice'
	| 'comparisonValue'
	| 'pricing'
	| 'questions'
	| 'diversity'
	| 'scorecard'
	| 'averageRate'

export type SortObject = {
	type: SortType
	key?: string //id of entity to be sorted by
	order?: 'asc' | 'desc' | null
}

export type State = {
	// feature toggles
	proposalAnalyzerEnabled: boolean
	// Constants
	showExampleResponse: boolean
	isFirm: boolean
	disabled: boolean

	fullscreen: boolean
	showUnpinned: boolean
	showWithdrawn: boolean
	showRevised: boolean
	showBusinessIntel: boolean
	showEliminated: boolean

	negotiatingResponse: ResponseForNegotiateDialog | null
	truncateCellContents: boolean

	wideProposals: string[]

	/** All pinned proposals including hidden proposals */
	pinnedProposals: string[]

	/** Responses that are displayed in the compare table */
	responses: ComparisonTableResponse[]

	request: ComparisonTableRequest

	isSmallRequest: boolean

	// Intelligence
	isWideIntelligence: boolean

	// Which sections should be shown (expanded/collapsed)
	showScorecardSection: boolean
	showPricingSection: boolean
	showQuestionsSection: boolean
	showDiversitySection: boolean
	sort: SortObject

	// UAM
	isUAMToggledOn: boolean
}

type SetupInput = {
	request: ComparisonTableRequest
	responses: ComparisonTableResponse[]
	disabled?: boolean
	isFirm?: boolean
	showExampleResponse?: boolean
	proposalAnalyzerEnabled?: boolean
	sort: SortObject
	isUAMToggledOn: boolean
}

const Store = createStore(
	(initialState: SetupInput): State => {
		const isSmallRequest =
			(initialState.request.detail?.deliverablesV2 ?? []).length < 10 &&
			(initialState.request.detail?.questions ?? []).length < 10

		const responses = initialState.responses

		const showScorecardSection =
			isSmallRequest &&
			(responses ?? []).length > 0 &&
			responses
				.map((r) => (r.__typename === 'Response' ? r : null))
				?.filter(isNotNil)
				.some(
					({ scorecard, status }) =>
						status !== PROPOSAL_STATES.WITHDRAWN &&
						status !== PROPOSAL_STATES.REVISED &&
						(scorecard ?? []).length > 0,
				)

		return {
			proposalAnalyzerEnabled: !!initialState.proposalAnalyzerEnabled,
			isFirm: !!initialState.isFirm,
			showExampleResponse: !!initialState.showExampleResponse,

			disabled: !!initialState.disabled,
			responses,
			request: initialState.request,

			negotiatingResponse: null,
			fullscreen: false,
			showUnpinned: true,
			showEliminated: true,
			showRevised: false,
			truncateCellContents: true,
			showBusinessIntel: false,
			showWithdrawn: false,

			wideProposals: [],
			pinnedProposals: [],

			isSmallRequest,

			isWideIntelligence: false,

			showScorecardSection,
			showDiversitySection: false,

			// For UI perf reasons we only expand these by default on small requests
			showPricingSection: isSmallRequest,
			showQuestionsSection: isSmallRequest,
			sort: initialState.sort,
			isUAMToggledOn: initialState.isUAMToggledOn,
		}
	},
	createActions,
	selectors,
)

export const { Provider: StoreProvider, useActions, useStore, useStoreApi } = Store
