import { gql } from '@persuit/ui-graphql'

const getOwnOrg = gql`
	query OldGQLGetOwnOrg {
		getOwnOrg {
			_id
			name
			servicesProvided
			orgType
			externalApiAccess
			externalApiMatterSync {
				trackingFieldSyncEnabled
				externalSystemName
				searchUsingMatterType
				updateMatterReference
			}
			settings {
				contacts {
					_id
					name {
						first
						last
					}
				}
			}
			allowableEmailSuffix
			isConfirmed
			isNamwolfMember
			panelFirmTags {
				firmId
				type
				groupId
			}
			certifications
		}
	}
`

export default getOwnOrg
