import { useId, useState } from 'react'

import RevealLabel from './reveal/label'
import RevealedContent from './reveal/content'
import { Box, BoxProps } from '@persuit/ui-components'

type RevealProps = {
	label: string
	defaultRevealed?: boolean
} & BoxProps

export const Reveal = ({ children, label, defaultRevealed = false, ...props }: RevealProps) => {
	const [revealed, setRevealed] = useState(defaultRevealed)

	const toggleRevealed = () => {
		setRevealed((revealed) => !revealed)
	}

	const id = useId()

	return (
		<Box {...props}>
			<RevealLabel
				revealed={revealed}
				onClick={toggleRevealed}
				id={`reveal-button-${id}`}
				aria-controls={id}
				aria-expanded={revealed ? 'true' : 'false'}
			>
				{label}
			</RevealLabel>

			<RevealedContent revealed={revealed} id={id} aria-labelledby={`reveal-button-${id}`}>
				{children}
			</RevealedContent>
		</Box>
	)
}

export default Reveal
