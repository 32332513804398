import { Box, SxProps, useTheme } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import { GridRenderCellParams } from '@mui/x-data-grid-pro'

export const getKebabCase = (value: string): string => {
	if (!value || typeof value !== 'string') {
		return ''
	}
	const trimmedValue = value.trim()
	const valueToLower = trimmedValue.toLowerCase()
	const withoutSpecialChars = valueToLower.replace(/[^\w\s]|_/g, '')
	const hyphenatedLower = withoutSpecialChars.replace(/\s+/g, '-')

	return hyphenatedLower
}

export const DesignatedContactChip = ({ sx }: { sx?: SxProps }) => {
	const theme = useTheme()
	return (
		<Box
			sx={{
				...sx,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 4,
				px: 1.5,
				py: 0.5,
				width: '114px',
				height: '22px',
				background: theme.palette.action.disabled,
				color: theme.palette.text.primary,
				fontSize: '10px',
				lineHeight: '114px',
				fontWeight: 600,
			}}
		>
			Designated contact
		</Box>
	)
}

export const GridCustomCellWithAnchor = ({
	value,
	isAnchor = false,
	isDesignatedContact = false,
	sx,
}: GridRenderCellParams & { isAnchor?: boolean; isDesignatedContact?: boolean; sx?: SxProps }) => {
	const cellId = isAnchor ? getKebabCase(value) : undefined
	return (
		<Box
			id={cellId}
			sx={{
				...sx,
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 1,
				width: '100%',
			}}
		>
			{value}
			{isDesignatedContact ? <DesignatedContactChip /> : null}
		</Box>
	)
}
