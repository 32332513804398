import { State, Value } from './store'
import { createSelector } from 'reselect'

export function flattenOptions(options: Value[]): Value[] {
	return options.flatMap((field) => [
		field,
		...field.values.flatMap((value) => [value, ...value.values]),
	])
}

const filteredOptions = createSelector(
	(state: State) => state.searchTerm,
	(state: State) => state.options,
	(searchTerm, options) => options.search(searchTerm),
)

const isCollapsed = (state: Pick<State, 'searchTerm' | 'collapsedState'>, id: string) =>
	!state.searchTerm && state.collapsedState[id]

const visibleIds = createSelector(
	filteredOptions,
	(state: State) => state.headersSelectable,
	(state: State) => state.collapsedState,
	(state: State) => state.searchTerm,
	(filteredOptions, headersSelectable, collapsedState, searchTerm) => {
		const collapsedOptions = filteredOptions.map((value) =>
			isCollapsed({ collapsedState, searchTerm }, value._id) ? { ...value, values: [] } : value,
		)

		const ids = collapsedOptions.flatten().map((option) => option._id)

		// Filter out non selectable items which are not highlightable.
		return headersSelectable ? ids : ids.filter((id) => collapsedOptions.levelById(id) !== 1)
	},
)

const isSelected = (state: State, id: string) => state.options.isSelected(id, state.selections)
const isField = (state: State, id: string) => state.options.levelById(id) === 1
const isValue = (state: State, id: string) => state.options.levelById(id) === 2
const isSubValue = (state: State, id: string) => state.options.levelById(id) === 3

type CheckStatus = 'checked' | 'unchecked' | 'indeterminate'

const checkStatus = (state: State, id: string): CheckStatus => {
	const value = selectors.optionById(state, id)

	if (isSelected(state, value._id)) {
		return 'checked'
	}

	const checkedChildValues = value.values.filter((subValue) => isSelected(state, subValue._id))
	return checkedChildValues.length === 0
		? 'unchecked'
		: value.values.length === checkedChildValues.length
		? 'checked'
		: 'indeterminate'
}

export const selectors = {
	visibleIds,
	filteredOptions,
	allCollapsed: (state: State) =>
		state.options.every((option) =>
			option.values.length > 0 ? state.collapsedState[option._id] : true,
		),
	isCollapsed,
	optionById: (state: State, id: string) => state.options.valueById(id),
	filteredOptionById: (state: State, id: string) => filteredOptions(state).valueById(id),
	checkStatus,
	isField,
	isValue,
	isSubValue,
	isValueWithSubValues: (state: State, id: string) =>
		isValue(state, id) && state.options.hasSubValues(id),
	isSelected,
	countNestedSelections: (state: State, id: string) => {
		const option = state.options.valueById(id)
		return flattenOptions(option.values)
			.map((option) => option._id)
			.filter((id) => state.selections.includes(id)).length
	},
}
