// @ts-strict-ignore
import { useStore, useActions } from '../store'
import { Button, AutocompleteMultiSelect } from '@persuit/ui-components'

export const ShowProposalsDropdown = ({
	hasWithdrawnProposals,
	hasRevisedProposals,
	hasEliminatedProposals,
}) => {
	const {
		displayedPinnedProposals,
		pinningDisabled,
		isFirm,
		showUnpinned,
		showWithdrawn,
		showEliminated,
		showRevised,
	} = useStore((state, s) => ({
		pinningDisabled: state.disabled,
		showUnpinned: state.showUnpinned,
		showWithdrawn: state.showWithdrawn,
		showEliminated: state.showEliminated,
		showRevised: state.showRevised,
		displayedPinnedProposals: s.displayedPinnedProposalIds(),
		isFirm: state.isFirm,
	}))

	const {
		toggleShowUnpinned,
		toggleShowEliminated,
		toggleShowRevised,
		toggleShowWithdrawn,
		setShowUnpinned,
		setShowEliminated,
		clearShowRevised,
		clearShowWithdrawn,
	} = useActions()

	const showProposalsDropdownOptions = [
		{
			id: 'unsuccessful',
			label: 'Show unsuccessful',
			isChecked: !isFirm && showEliminated,
			isDisabled: !(!isFirm && hasEliminatedProposals),
			isVisible: !isFirm,
		},
		{
			id: 'withdrawn',
			label: 'Show withdrawn',
			isChecked: showWithdrawn,
			isDisabled: !hasWithdrawnProposals,
			isVisible: true,
		},
		{
			id: 'revised',
			label: 'Show revised',
			isChecked: showRevised,
			isDisabled: !hasRevisedProposals,
			isVisible: true,
		},
		{
			id: 'unpinned',
			label: 'Show unpinned',
			isChecked: !isFirm && showUnpinned,
			isDisabled: !(!pinningDisabled && displayedPinnedProposals.length > 0),
			dataTrackIdOn: 'switch-on-unpinnedProposals',
			dataTrackIdOff: 'switch-off-unpinnedProposals',
			isVisible: !isFirm,
		},
	].filter((item) => item.isVisible)

	return (
		<AutocompleteMultiSelect
			onChange={(_, reason, details) => {
				const optionId = details?.option.id

				const onChangeShowProposals = (optionId: string) => {
					if (optionId === 'unpinned') {
						toggleShowUnpinned()
					} else if (optionId === 'revised') {
						toggleShowRevised()
					} else if (optionId === 'withdrawn') {
						toggleShowWithdrawn()
					} else if (optionId === 'unsuccessful') {
						toggleShowEliminated()
					}
				}

				if ((reason === 'selectOption' || reason === 'removeOption') && optionId) {
					onChangeShowProposals(optionId)
				}
			}}
			label="Show"
			options={showProposalsDropdownOptions}
			value={showProposalsDropdownOptions
				.filter((option) => option.isChecked)
				.map((option) => option.id)}
			getLabel={(option) => option.label}
			getValue={(option) => option.id}
			getDisabled={(option) => option.isDisabled}
			endIcon={
				<Button
					variant="text"
					onClick={() => {
						setShowUnpinned()
						setShowEliminated()
						clearShowWithdrawn()
						clearShowRevised()
					}}
					aria-label="Reset show proposals"
					data-trackid="iconbutton-reset-show-proposals"
					style={{ position: 'absolute', right: '30px' }}
				>
					RESET
				</Button>
			}
		/>
	)
}
