// @ts-strict-ignore
import CertificationsList from '../../certifications'
import DIVERSITY_QUESTIONS from '../../request/modules/diversity/diversity-content'
import { Typography, Box } from '@persuit/ui-components'

export const DiversityQuestion = ({ value }) => <Typography variant="h3XSmall">{value}</Typography>

export const DiversityAnswerPercentage = ({ value, isNa }) => {
	if (!value && value !== 0 && !isNa) {
		return <Typography variant="body2">No response</Typography>
	}
	if (isNa === true) {
		return <Typography variant="body2">N/A</Typography>
	}

	return (
		<Typography variant="body2" data-testid="diversity-answer-percentage">
			{value}%
		</Typography>
	)
}

export const DiversityAnswerYesNo = ({ value, isNa }) => {
	if (isNa === true) {
		return (
			<Typography variant="body2" data-testid="diversity-answer-yes-no">
				N/A
			</Typography>
		)
	}

	let yesOrNo: string
	switch (value) {
		case true:
			yesOrNo = 'Yes'
			break
		case false:
			yesOrNo = 'No'
			break
		default:
			yesOrNo = 'No response'
	}

	return (
		<Typography variant="body2" data-testid="diversity-answer-yes-no">
			{yesOrNo}
		</Typography>
	)
}

export const DiversityAnswersAndCertifications = ({ diversityAnswers, certifications }) => {
	const answer1 = diversityAnswers?.answer1?.answer ?? null
	const answer2 = diversityAnswers?.answer2?.answer ?? null
	const answer3 = diversityAnswers?.answer3?.answer ?? null
	const isNa1 = diversityAnswers?.answer1?.isNa ?? false
	const isNa2 = diversityAnswers?.answer2?.isNa ?? false
	const isNa3 = diversityAnswers?.answer3?.isNa ?? false
	const comment1 = diversityAnswers?.answer1?.comment ?? ''
	const comment2 = diversityAnswers?.answer2?.comment ?? ''
	const comment3 = diversityAnswers?.answer3?.comment ?? ''

	return (
		<Box data-testid="diversity-qna">
			<Box my={2}>
				<DiversityQuestion value={`D1. ${DIVERSITY_QUESTIONS.diversity1.title}`} />
			</Box>
			<Box my={2}>
				<DiversityAnswerYesNo value={answer1} isNa={isNa1} />
			</Box>
			<Typography variant="body2" data-testid="diversity-comment">
				{comment1}
			</Typography>

			<Box my={2}>
				<DiversityQuestion value={`D2. ${DIVERSITY_QUESTIONS.diversity2.title}`} />
			</Box>
			<Box my={2}>
				<DiversityAnswerPercentage value={answer2} isNa={isNa2} />
			</Box>
			<Typography variant="body2" data-testid="diversity-comment">
				{comment2}
			</Typography>

			<Box my={2}>
				<DiversityQuestion value={`D3. ${DIVERSITY_QUESTIONS.diversity3.title}`} />
			</Box>
			<Box my={2}>
				<DiversityAnswerPercentage value={answer3} isNa={isNa3} />
			</Box>
			<Typography variant="body2" data-testid="diversity-comment">
				{comment3}
			</Typography>

			<Box my={2}>
				<DiversityQuestion value={`D4. ${DIVERSITY_QUESTIONS.diversity4.title}`} />
			</Box>
			<Box my={2}>
				<CertificationsList certifications={certifications} dataTestid="diversity-certifications" />
			</Box>
		</Box>
	)
}
