// @ts-strict-ignore
import { memo } from 'react'
import { Box } from '@persuit/ui-components'
import { useHistory } from 'react-router-dom'
import { isSupplier } from '../../../../../../common/auth/policies/user'
import auctionFirmVisibilityValues from '../../../../../../common/data/auction-firm-visibility'
import {
	PROPOSAL_STATES,
	RfpProposalState,
} from '../../../../../../common/data/rfp-proposal-states'
import rfpStates from '../../../../../../common/data/rfp-states'
import { isDraft } from '../../../../../../common/proposals/isProposalADraft'
import {
	disableSelectProposalButton,
	disableNegotiateProposalButton,
	isShowSelectProposalButton,
} from '../../../../../rules'
import { getOrFalse } from '../../../../../utils/lenses'
import { RankHeader } from './rank-header'
import isRequestReopenedForInvite from '../../../../rfp/get-reopened-status-for-invite'
import { useSelector } from '@client/store'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Cell } from '../../components'
import { LastBidTime } from './last-bid-time'
import { CompactHeaderCell } from './compact-header-cell'
import { ExpandedHeaderCell } from './expanded-header-cell'
import { reviseProposal as canReviseProposal } from '../../../../../../common/auth/policies/rfp-proposal'

export const getResponseURL = ({
	lang,
	isFirmUser,
	requestId,
	responseId,
	isResponseDraft,
	canNotCreate,
}) => {
	if (!isResponseDraft) {
		if (isFirmUser) {
			return `/${lang}/request/${requestId}/proposals/preview-detail/${responseId}/`
		}
		return `/${lang}/request/${requestId}/proposals/view/${responseId}/`
	}

	if (canNotCreate) {
		return `/${lang}/request/${requestId}/proposals/preview-detail/${responseId}/`
	}

	return `/${lang}/request/${requestId}/proposals/edit/${responseId}/`
}

type ResponseHeaderCellProps = {
	auctionFirmVisibility: string | null | undefined
	auctionInProgress: boolean
	cellProps: object
	compactHeader: boolean
	currency: string | null | undefined
	handleNegotiateProposal: (arg0: any) => void
	handleSelectProposal: (arg0: any) => void
	hasProposalRevisionRequest: boolean
	isEliminated: boolean
	isNamwolfMember: boolean
	numResponses: number | null | undefined
	orgName: string | null | undefined
	orgId: string | null | undefined
	hasParent: boolean
	rank: number | null | undefined
	request: any
	requestId: string
	requestObject: any
	response: any
	responseId: string
	responseStatus: string
	showPriceType: 'totalPrice' | 'comparisonValue' | null
	showRank: boolean
	showRightBorder: boolean
	tiedForLowest: boolean
}

export const ResponseHeaderCell = memo(
	({
		// className, // Always undefined
		auctionFirmVisibility,
		auctionInProgress,
		cellProps,
		compactHeader = true,
		currency,
		handleNegotiateProposal,
		handleSelectProposal,
		hasProposalRevisionRequest,
		isEliminated,
		numResponses,
		orgName,
		orgId,
		isNamwolfMember,
		hasParent,
		rank,
		request,
		requestId,
		requestObject,
		response,
		responseId,
		responseStatus,
		showPriceType,
		showRank,
		showRightBorder,
		tiedForLowest,
	}: ResponseHeaderCellProps) => {
		const { push } = useHistory()
		const { loading } = useFeatureToggles()
		const { user, scope } = useSelector((state) => state.auth)

		if (loading) {
			return null
		}

		const handleViewProposal = (path: string) => () => {
			push(path)
		}

		const getPublishedAt = ({ updatedAt, activityLog }) => {
			if (!Array.isArray(activityLog)) return updatedAt
			const publishedActivityDate = activityLog.reduce((acc, e) => {
				if (e?.action === 'publish' || e?.action === 'publish-revised-proposal') {
					if (e?.date) {
						if (!acc) return e.date
						if (e.date > acc) return e.date
					}
				}
				return acc
			}, null)
			return publishedActivityDate ?? updatedAt
		}

		const isFirmUser = isSupplier(user)
		const isExample = responseStatus === PROPOSAL_STATES.EXAMPLE
		const isCompetitor = responseStatus === PROPOSAL_STATES.COMPETITOR
		const isWithdrawn =
			responseStatus === PROPOSAL_STATES.WITHDRAWN || responseStatus === PROPOSAL_STATES.REVISED
		const showSelectProposalButton = isShowSelectProposalButton(response, isEliminated, user)
		const showLowestBid =
			auctionFirmVisibility === auctionFirmVisibilityValues.RANK_AND_LOWEST_PRICE && rank === 1
		const disableSelect = disableSelectProposalButton(response, isEliminated)
		const disableNegotiate = disableNegotiateProposalButton({
			response,
			request,
			isFirmEliminated: isEliminated,
		})
		const showLastBidTime = !compactHeader && (response.lastBidTime ?? getPublishedAt(response))

		const isExampleOrCompetitorProposal = isExample || isCompetitor
		const hasRankHeader = showRank && !isWithdrawn && !isExample && !!rank
		const isResponseDraft = isDraft({ status: responseStatus as RfpProposalState })
		const isParticipating = request?.invites?.[0]?.state?.participate?.response === 'participating'
		const requestReopenedForInvite = !!isRequestReopenedForInvite(request, orgId ?? '')

		// Show revise proposal only if the user has access and the request is open
		const showReviseProposalButton: boolean =
			isFirmUser &&
			(request.status === rfpStates.FINALIZED || requestReopenedForInvite) &&
			canReviseProposal(user, scope, response)

		const canNotCreate =
			([rfpStates.CLOSED, rfpStates.COMPLETED].includes(request.status) ||
				isEliminated ||
				!isParticipating) &&
			!getOrFalse('invites[0].isReOpened', request)

		const responseURL = getResponseURL({
			lang: 'en',
			isFirmUser,
			requestId,
			responseId,
			isResponseDraft,
			canNotCreate,
		})

		const sharedHeaderCellProps = {
			auctionInProgress,
			canNotCreate,
			currency,
			disableNegotiate,
			disableSelect,
			handleViewProposal: handleViewProposal(responseURL),
			handleNegotiateProposal: () => handleNegotiateProposal(response),
			handleSelectProposal: () => handleSelectProposal(response),
			hasParent,
			hasProposalRevisionRequest,
			isCompetitor,
			isEliminated,
			isExample,
			isExampleOrCompetitorProposal,
			isNamwolfMember,
			isResponseDraft,
			orgId,
			orgName,
			requestId,
			requestReopenedForInvite,
			responseStatus,
			request,
			response,
			showReviseProposalButton,
			showSelectProposalButton,
		}

		return (
			<Cell
				showRightBorder={showRightBorder}
				cellProps={{ component: 'td', ...cellProps }}
				responseId={responseId}
				sx={{ boxSizing: 'border-box', paddingBottom: compactHeader ? 1 : undefined }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					height="100%"
					position="relative"
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						height="100%"
						marginBottom={compactHeader ? undefined : '50px'}
					>
						<RankHeader
							rank={rank}
							numResponses={numResponses}
							isFirmUser={isFirmUser}
							showLowestBid={showLowestBid}
							isCurrentUserBidLowest={!isCompetitor}
							tiedForLowest={tiedForLowest}
							proposalId={responseId}
							showRank={hasRankHeader}
						/>

						{compactHeader ? (
							<CompactHeaderCell
								{...sharedHeaderCellProps}
								requestObjectRefetch={requestObject?.refetch}
								showPriceType={showPriceType}
							/>
						) : (
							<ExpandedHeaderCell
								{...sharedHeaderCellProps}
								isFirmUser={isFirmUser}
								requestObject={requestObject}
							/>
						)}
					</Box>
				</Box>
				{showLastBidTime && (
					<LastBidTime
						lastBidTime={response.lastBidTime ?? getPublishedAt(response)}
						sx={{
							position: 'absolute',
							width: '100%',
							bottom: 0,
							left: 0,
						}}
					/>
				)}
			</Cell>
		)
	},
)
