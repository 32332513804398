import { Box } from '@persuit/ui-components'
import { DeliverableV2, ResponseToDeliverable } from '@persuit/ui-graphql/generated'
import { ComparisonValueInfo } from '../../comparison-value'
import { AverageRateInfo } from '../../average-rate-info'

type PricingSectionProps = {
	comparisonValue: number
	deliverables: Array<DeliverableV2>
	responseToDeliverables: Array<ResponseToDeliverable>
	currency: string
	averageRate?: number | null
	totalPriceRequired: boolean
}

export const PricingInfo = ({
	comparisonValue,
	deliverables,
	responseToDeliverables,
	currency,
	averageRate,
	totalPriceRequired,
}: PricingSectionProps) => (
	<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2, '&& > * ': { my: 0 } }}>
		<ComparisonValueInfo
			totalPriceRequired={totalPriceRequired}
			deliverables={deliverables}
			responseToDeliverables={responseToDeliverables}
			comparisonValue={comparisonValue}
			rfpCurrency={currency}
			isDetailView={true}
		/>

		<AverageRateInfo
			totalPriceRequired={totalPriceRequired}
			currency={currency}
			responseToDeliverables={responseToDeliverables}
			isDetailView={true}
			averageRate={averageRate}
		/>
	</Box>
)
