// @ts-strict-ignore
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { RfpSection } from './types'
import { useSnackbar } from '@persuit/ui-components'

export type UseDisabledSectionRedirectProps = {
	generateSectionPath: (section: RfpSection) => string
	defaultSection: RfpSection
	currentSection?: RfpSection
	isSectionEnabled?: (section: string) => boolean
}

/**
 * Redirects the user if they have navigated to a section that is disabled
 */
export function useDisabledSectionRedirect({
	generateSectionPath,
	isSectionEnabled,
	defaultSection,
	currentSection = defaultSection,
}) {
	const history = useHistory()
	const { openSnackbar } = useSnackbar()
	const currentSectionEnabled = isSectionEnabled(currentSection)
	const defaultSectionPath = generateSectionPath(defaultSection)
	const currentPath = history.location.pathname
	const push = history.push

	useEffect(() => {
		if (!currentSectionEnabled && currentPath !== defaultSectionPath) {
			push(defaultSectionPath)
			openSnackbar(
				`Switched to the ${defaultSection} section because this section is not a part of this use case`,
				{ variant: 'info' },
			)
		}
	}, [push, currentPath, defaultSection, defaultSectionPath, currentSectionEnabled, openSnackbar])

	return null
}
