import { PureComponent } from 'react'
import persuitTheme from '../theme/persuit-theme'

class Box extends PureComponent {
	static defaultProps = {
		component: 'div',
		top: 0,
		right: 1,
		bottom: 0,
		left: 1,
	}

	render() {
		const {
			// Extract sheet form ...props to avoid warnings
			sheet,

			children,
			component: Component,
			styles,
			top,
			right,
			left,
			bottom,
			all,
			classes,
			...props
		} = this.props

		const gutter = persuitTheme.grid.desktopGutter / 2

		const style = {
			paddingBottom: gutter * (all || bottom),
			paddingLeft: gutter * (all || left),
			paddingRight: gutter * (all || right),
			paddingTop: gutter * (all || top),
		}

		return (
			<Component style={style} {...props}>
				{children}
			</Component>
		)
	}
}

export default Box
