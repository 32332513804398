const states = {
	EDIT: 'edit',
	VIEW: 'view',
	PENDING: 'pending',
	REVOKED: 'revoked',
}

const values = Object.keys(states).map((key) => states[key])

export { values, states as default }
