// @ts-strict-ignore
import { useState, useMemo } from 'react'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import uniqBy from 'lodash/fp/uniqBy'
import { clientPhaseModel } from '@persuit/common-logic'
import moment from 'moment'
import {
	Box,
	Paper,
	Typography,
	Button,
	ZoomOutIcon,
	SettingsBackupRestoreIcon,
} from '@persuit/ui-components'
import DeliverableItemChart from './deliverable-item-chart'
import { TotalPriceChart } from './total-price-chart'
import SelectChartData from './select-chart-data'
import { RatesChart } from './rates-chart'

type PricingItemOption = {
	type: 'pricingItem'
	title: string
	group: string
	id: string
	pricingModel: string
}

type Option = {
	type: string
	title: string
	group: string
}

const totalPriceOption = {
	type: 'totalPrice',
	title: 'Total price',
	group: '',
}

const comparisonValueOption = {
	type: 'comparisonValue',
	title: 'Comparison value',
	group: '',
}

const averageRateOption = {
	type: 'averageRate',
	title: 'Average of all rates',
	group: '',
}

const isPricingItem = (option: PricingItemOption | Option): option is PricingItemOption => {
	return option.type === 'pricingItem'
}

const hasRateItems = (deliverable: { pricingPreferences: string }) =>
	deliverable.pricingPreferences === clientPhaseModel.HOURLYRATE ||
	deliverable.pricingPreferences === clientPhaseModel.RATECARD

export const generateChartSelectOptions = ({
	deliverables,
	totalPriceRequired,
}): (Option | PricingItemOption)[] => {
	const containsRates = deliverables.some((deliverable) =>
		deliverable.__typename === 'PricingGroup'
			? deliverable.deliverables.some(hasRateItems)
			: hasRateItems(deliverable),
	)

	const ratesOnlyRequest = deliverables.every((deliverable) =>
		deliverable.__typename === 'PricingGroup'
			? deliverable.deliverables.every(hasRateItems)
			: hasRateItems(deliverable),
	)

	const { flatDeliverables } = deliverables.reduce(
		(acc, deliverable) => {
			if (deliverable.__typename === 'PricingGroup') {
				deliverable.deliverables.forEach((groupedDeliverable) => {
					acc.flatDeliverables.push({
						id: groupedDeliverable._id,
						title: `${acc.index + 1}. ${groupedDeliverable.deliverableTitle}  (${
							deliverable.title
						})`,
						type: 'pricingItem',
						group: 'Pricing Items',
						pricingModel: groupedDeliverable.pricingPreferences,
					})
					acc.index += 1
				})
				return acc
			}
			acc.flatDeliverables.push({
				id: deliverable._id,
				title: `${acc.index + 1}. ${deliverable.deliverableTitle}`,
				type: 'pricingItem',
				group: 'Pricing Items',
				pricingModel: deliverable.pricingPreferences,
			})
			acc.index += 1
			return acc
		},
		{
			flatDeliverables: [],
			index: 0,
		},
	)

	return ratesOnlyRequest
		? [...(totalPriceRequired ? [totalPriceOption] : []), averageRateOption, ...flatDeliverables]
		: [
				totalPriceRequired ? totalPriceOption : comparisonValueOption,
				...(containsRates ? [averageRateOption] : []),
				...flatDeliverables,
		  ]
}

type AuctionActivityChartProps = {
	responses: any[]
	auctionEnd: number
	proposalsDueBy: number
	orgIdToColourMapping: Record<string, string>
	currency: string
	deliverables: any[]
	totalPriceRequired: boolean
}

export const AuctionActivityChart = ({
	responses,
	auctionEnd,
	proposalsDueBy,
	orgIdToColourMapping,
	currency,
	deliverables,
	totalPriceRequired,
}: AuctionActivityChartProps) => {
	const chartSelectOptions = useMemo(
		() => generateChartSelectOptions({ deliverables, totalPriceRequired }),
		[deliverables, totalPriceRequired],
	)

	const [selectedChart, setSelectedChart] = useState<Option | PricingItemOption>(
		chartSelectOptions[0],
	)

	const [zoomToAuction, setZoomToAuction] = useState(true)

	const toggleZoomToAuction = () => {
		setZoomToAuction((prevZoom) => !prevZoom)
	}

	const start = moment(proposalsDueBy)
	const end = auctionEnd ? moment(auctionEnd) : null

	const orgsToChart = compose(
		// Ensure this is a unique list. This accounts for when a firm submits multiple proposals
		uniqBy('orgId'),
		map((response: any) => {
			return {
				orgId: response.org._id,
				orgName: response.org.name,
			}
		}),
	)(responses)

	const showChartSelector = deliverables.length > 0

	return (
		<Paper elevation={1}>
			<Box m={2} pt={1}>
				<Box display="flex" mb={2} justifyContent="space-between" alignItems="center">
					<Typography gutterBottom={true} variant="h3XSmall">
						Auction activity
					</Typography>

					<Box display="flex" justifyContent="space-between">
						{showChartSelector && (
							<Box mr={4} style={{ width: '280px' }}>
								<SelectChartData
									value={selectedChart}
									onChange={setSelectedChart}
									options={chartSelectOptions}
								/>
							</Box>
						)}

						<Button
							startIcon={zoomToAuction ? <ZoomOutIcon /> : <SettingsBackupRestoreIcon />}
							onClick={toggleZoomToAuction}
							variant="outlined"
						>
							{zoomToAuction ? 'Zoom out' : 'Reset'}
						</Button>
					</Box>
				</Box>

				{(selectedChart.type === 'totalPrice' || selectedChart.type === 'comparisonValue') && (
					<>
						<Typography gutterBottom={true}>
							{totalPriceRequired ? 'Total price chart' : 'Comparison value chart'}
						</Typography>
						<TotalPriceChart
							zoomToAuction={zoomToAuction}
							start={start}
							end={end}
							orgsToChart={orgsToChart}
							currency={currency}
							orgIdToColourMapping={orgIdToColourMapping}
							responses={responses}
							proposalsDueBy={proposalsDueBy}
							auctionEnd={auctionEnd}
							totalPriceRequired={totalPriceRequired}
						/>
					</>
				)}

				{selectedChart.type === 'averageRate' && (
					<>
						<Typography gutterBottom={true}>Average of all rates chart</Typography>
						<RatesChart
							zoomToAuction={zoomToAuction}
							start={start}
							end={end}
							orgsToChart={orgsToChart}
							currency={currency}
							orgIdToColourMapping={orgIdToColourMapping}
							responses={responses}
							proposalsDueBy={proposalsDueBy}
							auctionEnd={auctionEnd}
						/>
					</>
				)}

				{isPricingItem(selectedChart) && (
					<>
						<Typography gutterBottom={true}>{selectedChart.title} chart</Typography>
						<DeliverableItemChart
							zoomToAuction={zoomToAuction}
							start={start}
							end={end}
							orgsToChart={orgsToChart}
							currency={currency}
							orgIdToColourMapping={orgIdToColourMapping}
							responses={responses}
							proposalsDueBy={proposalsDueBy}
							auctionEnd={auctionEnd}
							deliverableId={selectedChart.id}
							deliverablePricingModel={selectedChart.pricingModel}
						/>
					</>
				)}
			</Box>
		</Paper>
	)
}
