import { Deliverable } from '../../../../../types'
import { DeliverableDetail } from '../deliverable-detail'
import { Box, useTheme } from '@persuit/ui-components'

type DeliverableContainerProps = {
	floating?: boolean
	deliverable: Deliverable
	index: number
}

export const DeliverableContainer = ({
	floating = true,
	deliverable,
	index,
}: DeliverableContainerProps) => {
	const theme = useTheme()
	if (floating)
		return (
			<Box style={{ border: `1px solid ${theme.palette.grey500}` }} padding={1}>
				<DeliverableDetail deliverable={deliverable} index={index} />
			</Box>
		)

	return <DeliverableDetail deliverable={deliverable} index={index} />
}
