import { generateChartData } from '../calculations'

import { Chart, ChartProps } from './chart'

export type TotalPriceChartProps = Pick<
	ChartProps,
	'currency' | 'end' | 'orgIdToColourMapping' | 'orgsToChart' | 'start' | 'zoomToAuction'
> & {
	auctionEnd: number
	responses: Record<string, unknown>[]
	proposalsDueBy: number
	totalPriceRequired: boolean
}

export const TotalPriceChart = ({
	responses,
	auctionEnd,
	proposalsDueBy,
	orgsToChart,
	totalPriceRequired,
	...rest
}: TotalPriceChartProps) => {
	const chartData = generateChartData({
		responses,
		auctionStart: proposalsDueBy,
		auctionEnd,
		totalPriceRequired,
	})

	// The chart has a base height of 400.
	// Each line has ~2.5 legend items (under estimated).
	// There are items / 2.5 lines.
	// Each line has height 20.
	const chartHeight = 400 + (orgsToChart.length / 2.5) * 20

	return <Chart {...rest} orgsToChart={orgsToChart} height={chartHeight} chartData={chartData} />
}
