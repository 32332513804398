import { Typography, Box } from '@persuit/ui-components'

type HeadlineSepatatorProps = {
	headline: string
}

export const HeadlineSeparator = ({ headline }: HeadlineSepatatorProps) => {
	return (
		<Box
			marginTop="2em"
			paddingTop="2em"
			paddingBottom="1.5em"
			borderTop={`1px solid`}
			borderColor={'form.borderResting'}
		>
			<Typography variant="h2XSmall">{headline}</Typography>
		</Box>
	)
}
