import { filter, find, some } from 'lodash/fp'
import proposalStates from '../../../../common/data/rfp-proposal-states'
import rfpStates from '../../../../common/data/rfp-states'
import {
	getOrEmptyString,
	getOrFalse,
	getOrNull,
	getOrUndefined,
	getOrZero,
} from '../../../utils/lenses'

export function getSubmittedProposals(proposals) {
	return filter(
		({ status }) => [proposalStates.FINALIZED, proposalStates.UNDER_REVISION].includes(status),
		proposals,
	)
}

export function findInvite(invites, firmOrgId) {
	return find({ item: { _id: firmOrgId } }, invites)
}

export function findProposal(submittedProposals, firmOrgId) {
	return find({ org: { _id: firmOrgId } }, submittedProposals)
}

export function findFirmSubmittedAnyProposal(submittedProposals, firmOrgId) {
	return some({ org: { _id: firmOrgId } }, submittedProposals)
}

export function disableReopenRadio(requestStatus, inviteState) {
	const isFirmEliminated = getOrFalse('eliminate.eliminated', inviteState)
	if (isFirmEliminated) return true
	if (requestStatus !== rfpStates.CLOSED) return true

	const conflictResponse = getOrNull('conflict.response', inviteState)
	return conflictResponse === false
}

export function hasFirmAnyAcceptedProposals(firmOrg, proposals = []) {
	return some({ org: { _id: firmOrg._id }, status: proposalStates.ACCEPTED }, proposals)
}

export const getWizardProps = (rfp, firmOrg) => {
	const { _id: requestId, invites, status: requestStatus, proposals } = rfp
	const { _id: firmOrgId, name: firmName, isNamwolfMember } = firmOrg

	const submittedProposals = getSubmittedProposals(proposals)
	const firmInvite = findInvite(invites, firmOrgId)
	const isFirmEliminated = getOrFalse('state.eliminate.eliminated', firmInvite)
	const firmProposal = findProposal(submittedProposals, firmOrgId)
	const hasFirmSubmittedAnyProposal = findFirmSubmittedAnyProposal(submittedProposals, firmOrgId)
	const disableReopenRfpOption = disableReopenRadio(requestStatus, firmInvite.state)
	const disableMarkUnsuccessfulOption = hasFirmAnyAcceptedProposals(firmOrg, rfp.proposals)
	const currency = getOrUndefined('detail.currency', rfp)
	const totalPriceModel = getOrEmptyString('totalPriceModel', firmProposal)
	const totalPriceValue = getOrZero('totalPriceValue', firmProposal)
	const isRfpReopenedForFirm = getOrFalse('isReOpened', firmInvite)
	const isFirmProposalUnderRevision =
		getOrEmptyString('status', firmProposal) === proposalStates.UNDER_REVISION

	return {
		requestId,
		firmOrgId,
		isNamwolfMember,
		firmName,
		currency,
		totalPriceValue,
		totalPriceModel,
		hasFirmSubmittedAnyProposal,
		isFirmProposalUnderRevision,
		disableReopenRfpOption,
		disableMarkUnsuccessfulOption,
		isRfpReopenedForFirm,
		isFirmEliminated,
	}
}
