import * as React from 'react'
import {
	Box,
	useTheme,
	Typography,
	CheckIcon,
	ShortTextIcon,
	SubjectIcon,
	DialpadIcon,
	AttachMoneyIcon,
	VerifiedUserIcon,
	DragHandleIcon,
	ChecklistRtlIcon as MultiSelectIcon,
	LinearScaleIcon as ScaleIcon,
	PercentIcon,
	RadioButtonCheckedIcon,
} from '@persuit/ui-components'

const QUESTION_RESPONSE_DESCRIPTION_TEXT: Record<string, string> = {
	choice: 'Yes or No response type',
	short: 'Short answer response text',
	medium: 'Medium answer response text',
	long: 'Long answer response text',
	price: 'Money value response type',
	numeric: 'Number response type',
	percentage: 'Percentage response type',
	certification: "Firm's list of certifications / memberships will be shown",
	singleSelect: 'Single select response type',
	multipleSelect: 'Multiple choice response type',
	scale: 'Scale response type',
}

const QUESTION_RESPONSE_DESCRIPTION_ICON: Record<string, React.ReactNode> = {
	choice: <CheckIcon aria-hidden="true" />,
	long: <SubjectIcon aria-hidden="true" />,
	medium: <DragHandleIcon aria-hidden="true" />,
	short: <ShortTextIcon aria-hidden="true" />,
	numeric: <DialpadIcon aria-hidden="true" />,
	price: <AttachMoneyIcon aria-hidden="true" />,
	percentage: <PercentIcon aria-hidden="true" />,
	certification: <VerifiedUserIcon aria-hidden="true" />,
	singleSelect: <RadioButtonCheckedIcon aria-hidden="true" />,
	multipleSelect: <MultiSelectIcon aria-hidden="true" />,
	scale: <ScaleIcon aria-hidden="true" />,
}

type QuestionResponseDescriptionProps = {
	rfpQuestionType: string
}

export const QuestionResponseDescription = ({
	rfpQuestionType,
}: QuestionResponseDescriptionProps) => {
	const theme = useTheme()

	return (
		<Box
			display="flex"
			flexDirection="row"
			gap={theme.spacing(2)}
			alignItems="center"
			color="text.secondary"
		>
			{QUESTION_RESPONSE_DESCRIPTION_ICON[rfpQuestionType]}
			<Typography variant="body1" sx={{ fontStyle: 'italic' }} color="inherit">
				{QUESTION_RESPONSE_DESCRIPTION_TEXT[rfpQuestionType]}
				{/* Word export ony */}
				{rfpQuestionType === 'short'
					? ' (max of 800 characters)'
					: rfpQuestionType === 'medium'
					? ' (max of 2500 characters)'
					: rfpQuestionType === 'long'
					? ' (no character limit)'
					: null}
			</Typography>
		</Box>
	)
}
