import { memo } from 'react'
import { Typography, TypographyProps } from '@persuit/ui-components'

type PricingSubItemListHeaderProps = {
	itemPricingModel?: string | null
	numOfSubItems: number
} & TypographyProps

export const PricingSubItemListHeader = memo(
	({ itemPricingModel, numOfSubItems, ...props }: PricingSubItemListHeaderProps) => {
		if (numOfSubItems <= 0) {
			return null
		}

		let headerText = ''
		if (itemPricingModel !== 'hourlyRates') {
			headerText = 'Sub-items'
		} else {
			headerText = 'Rate card'
		}

		return (
			<Typography variant="body2" fontWeight={300} {...props}>
				{headerText}
			</Typography>
		)
	},
)

PricingSubItemListHeader.displayName = 'PricingSubItemListHeader'
