import { Dialog } from '@persuit/ui-components'
import { Rfp, Response } from '@persuit/ui-graphql/generated'
import RfpProposalDetail from '../../../rfp-proposals/rfp-proposal-detail/rfp-proposal-detail'

type ProposalDetailDialogProps = {
	open: boolean
	onClose: () => void
	rfp: Rfp
	proposal: Response
	orgName: string
}

export const ProposalDetailDialog = ({
	open,
	onClose,
	rfp,
	proposal,
	orgName,
}: ProposalDetailDialogProps) => {
	return (
		<Dialog
			open={open}
			size="lg"
			onClose={onClose}
			title={orgName}
			actions={[{ label: 'Close', onClick: onClose, variant: 'text' }]}
		>
			<RfpProposalDetail proposal={proposal} rfp={rfp} />
		</Dialog>
	)
}
