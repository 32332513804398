import { gql } from '@persuit/ui-graphql'

const analytics = gql`
	fragment Analytics on Analytics {
		listingId
		listingTitle
		humanFriendlyId
		listingCurrency
		listingStatus
		requestedBy
		acceptedPrice
		highestPrice
		averagePrice
		averagePriceOfOthers
		startingPriceOfAcceptedProposal
		lowestPrice
		lowestPricePricingModel
		lowestPriceOrgName
		lowestPriceOrgIsNamwolfMember
		acceptedFirm {
			_id
			name
			isNamwolfMember
		}
		date
		acceptedPricingModel
		hasAnAcceptedProposal
		numberOfProposals
		numberOfFirmsInvited
		invitedFirms {
			_id
			name
			isNamwolfMember
		}
		isAuction
		bids {
			orgId
			orgName
			isNamwolfMember
			bid
			pricingModel
			accepted
		}
		tracked {
			referenceNo
			responseTotalPrice
		}
		variance
		customTrackingData
		currency {
			exchangeRate
			exchangeDate
			exchangedFrom
			exchangedTo
		}
		grants
	}
`

export default analytics
