// @ts-strict-ignore
import {
	IconButton,
	DeleteIcon,
	Card,
	Box,
	FormTextField,
	SortableDragHandler,
	ListIcon,
	Button,
	SortableListItem,
	TopLeftErrorOutlineIcon,
	AddIcon,
	SortableContext,
	SortableList,
	useDroppable,
	useFormContext,
	useSnackbar,
	useConfirmDialog,
	useExpandCollapseActions,
} from '@persuit/ui-components'
import { isEmpty } from 'lodash/fp'

import { getOrGenerateUUID } from '@persuit/common-utils'
import { RfpQuestionError, RfpQuestionGroupError } from '../../../types'
import { useQuestionFieldArray, useQuestionGroupsFieldArray } from './question-list-form-utils'
import { Question } from '../question'
import { QuestionGroupMoreActionsMenu } from './question-group-more-actions-menu'
import { getQuestionsForGroup } from './question-group-utils'

type QuestionGroupProps = {
	index: number
	id: string
	item: any
	activeDragId?: string
	deleteGroup: (index: number) => void
	duplicateGroup: () => void
	questionErrors: RfpQuestionError[]
	questionGroupErrors: RfpQuestionGroupError[]
}

const getFloatingQuestionError = (
	questionErrors: RfpQuestionError[],
	index: number,
): RfpQuestionError | null => {
	return !isEmpty(questionErrors)
		? questionErrors.find((questionError) => index === questionError.groupIndex) ?? null
		: null
}

const getQuestionError = (
	questionErrors: RfpQuestionError[],
	index: number,
): RfpQuestionError | null => {
	return !isEmpty(questionErrors)
		? questionErrors.find((questionError) => index === questionError.index) ?? null
		: null
}

const getQuestionGroupError = (questionGroupErrors, index) => {
	return !isEmpty(questionGroupErrors)
		? questionGroupErrors.find((questionGroupError) => index === questionGroupError.index)
		: null
}

const deleteQuestion = (_id: string, questions, replaceQuestions) => {
	const questionsWithoutDeleted = questions.filter((question) => question._id !== _id)

	replaceQuestions(questionsWithoutDeleted)
	return
}

export const QuestionGroup = ({
	index,
	item,
	id,
	activeDragId,
	deleteGroup,
	duplicateGroup,
	questionErrors,
	questionGroupErrors,
}: QuestionGroupProps) => {
	const { getValues } = useFormContext()
	const { setNodeRef } = useDroppable({
		id,
	})

	const { openConfirmDialog } = useConfirmDialog()
	const { openSnackbar } = useSnackbar()
	// NB: for deleteQuestion() using replace instead of remove here because sometimes
	// remove would strip keys from the question instead of removing it
	// instead we are manually removing the question and using replace.
	const { append: appendQuestion, replace: replaceQuestions } = useQuestionFieldArray()
	const { replace: replaceGroups } = useQuestionGroupsFieldArray()
	const { expand } = useExpandCollapseActions()

	const { questions, questionGroups } = getValues()

	const currentGroup = item
	const groupIndex = index
	const isActiveDragging = activeDragId === id
	const questionsForGroup = getQuestionsForGroup({ questions, questionGroup: currentGroup })

	const createNewQuestion = () => {
		const uuid = getOrGenerateUUID()
		appendQuestion({
			_id: uuid,
			groupId: currentGroup?._id || currentGroup?.id,
			title: '',
			description: '',
			isNewQuestion: true,
			required: false,
			type: 'long',
		})
		expand(uuid)
	}

	const questionGroupError = getQuestionGroupError(questionGroupErrors, index)
	const questionOrGroupError =
		questionsForGroup.find((item) => !!getQuestionError(questionErrors, item.index)) ||
		!!questionGroupError

	if (item.groupId) {
		const groupForQuestion = questionGroups.find(({ _id }) => _id === item.groupId)
		const questionIndex = questions.findIndex(({ _id }) => _id === item._id)
		const questionItem = (
			<div ref={setNodeRef}>
				<Question
					key={item._id}
					activeDragId={activeDragId}
					displayIndex={index + 1}
					index={questionIndex}
					_id={item._id}
					originalQuestionId={item.originalQuestionId}
					questionError={questionErrors ? getFloatingQuestionError(questionErrors, index) : null}
					title={item.title}
					type={item.type}
					deleteQuestion={
						!item.originalQuestionId ? (
							<IconButton
								onClick={(e) => {
									e.stopPropagation()
									openConfirmDialog({
										title: 'Are you sure you want to delete this question?',
										content: null,
										actions: [
											{ label: 'Cancel', type: 'secondary', close: true },
											{
												label: 'Delete',
												type: 'primary',
												icon: (
													<DeleteIcon
														sx={{
															color: 'common.white',
														}}
													/>
												),
												close: true,
												action: () => {
													deleteQuestion(item._id, questions, replaceQuestions)
													// Remove hidden group
													replaceGroups(questionGroups.filter(({ id }) => id !== item.groupId))
													openSnackbar('Question deleted')
												},
											},
										],
									})
								}}
								color="primary"
								data-testid="delete-question-button"
								aria-label={`Delete question ${groupIndex + 1}.${index + 1}`}
							>
								<DeleteIcon />
							</IconButton>
						) : null
					}
				/>
			</div>
		)
		if (groupForQuestion?.hidden) {
			const questionInHiddenGroup = (
				<SortableListItem id={id}>{() => <>{questionItem}</>}</SortableListItem>
			)
			return questionInHiddenGroup
		}
		return questionItem
	}

	return (
		<SortableListItem id={id}>
			{() => (
				<div ref={setNodeRef}>
					<Box
						bgcolor={isActiveDragging ? 'primary.lighterHue' : undefined}
						mb={2}
						id={`question_group_${groupIndex + 1}`}
						tabIndex={-1}
						aria-label={`Question group ${groupIndex + 1}`}
						role="group"
					>
						{questionOrGroupError && <TopLeftErrorOutlineIcon data-testid="group-error-icon" />}
						<Card
							style={{
								opacity: isActiveDragging ? 0 : 1,
							}}
						>
							<Box p="1rem">
								<Box display="flex" alignItems={'center'}>
									<SortableDragHandler id={id} />
									<Box ml=".5rem" display="flex" alignItems={'center'}>
										<ListIcon />
									</Box>
									<Box ml="1rem" flexGrow={1}>
										<Box ml={1} display="flex" alignItems="baseline">
											{groupIndex + 1}.&nbsp;
											<FormTextField
												inputProps={{
													maxLength: 200,
												}}
												required={true}
												fullWidth={true}
												name={`questionGroups.${index}.name`}
												label="Group title"
												id={`questionGroup-${index}-title`}
												placeholder="Group title"
												rules={{
													required: true,
												}}
												helperText={questionGroupError ? questionGroupError.name : null}
												error={!!questionGroupError}
											/>
										</Box>
									</Box>
									<QuestionGroupMoreActionsMenu
										index={index}
										totalGroupItems={questionsForGroup.length}
										deleteGroup={deleteGroup}
										duplicateGroup={duplicateGroup}
									/>
								</Box>
								<Box style={{ display: isActiveDragging ? 'none' : undefined }} marginTop={2}>
									<SortableContext items={questionsForGroup} id="group-items">
										{isActiveDragging ? null : (
											<SortableList>
												{questionsForGroup.map((item, index) => (
													<Question
														key={item._id}
														activeDragId={activeDragId}
														displayIndex={index + 1}
														index={item.index}
														questionGroupNumber={groupIndex + 1}
														_id={item._id}
														originalQuestionId={item.originalQuestionId}
														questionError={
															questionErrors ? getQuestionError(questionErrors, item.index) : null
														}
														title={item.title}
														type={item.type}
														deleteQuestion={
															!item.originalQuestionId ? (
																<IconButton
																	onClick={(e) => {
																		e.stopPropagation()
																		openConfirmDialog({
																			title: 'Are you sure you want to delete this question?',
																			content: null,
																			actions: [
																				{ label: 'Cancel', type: 'secondary', close: true },
																				{
																					label: 'Delete',
																					type: 'primary',
																					icon: (
																						<DeleteIcon
																							sx={{
																								color: 'common.white',
																							}}
																						/>
																					),
																					close: true,
																					action: () => {
																						deleteQuestion(item._id, questions, replaceQuestions)
																						openSnackbar('Question deleted')
																					},
																				},
																			],
																		})
																	}}
																	color="primary"
																	data-testid="delete-question-button"
																	aria-label={`Delete question ${groupIndex + 1}.${index + 1}`}
																>
																	<DeleteIcon />
																</IconButton>
															) : null
														}
													/>
												))}
											</SortableList>
										)}
									</SortableContext>

									<Button
										startIcon={<AddIcon />}
										color="primary"
										variant="outlined"
										aria-label="Add question to group"
										onClick={createNewQuestion}
										data-testid="add-question-to-group-button"
									>
										Question To Group
									</Button>
								</Box>
							</Box>
						</Card>
					</Box>
				</div>
			)}
		</SortableListItem>
	)
}
