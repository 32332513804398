// @ts-strict-ignore
import { PricingListContextProviderProps } from '../../pricing-list-context'

export const getPricingError = (
	pricingErrors: PricingListContextProviderProps['errors'],
	index,
) => {
	if (!pricingErrors) {
		return ''
	}

	return pricingErrors?.pricingItemErrors?.find((error) => error.index === index) ?? ''
}
