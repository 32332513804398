import { TimelineStepper } from '@client/components/timeline-stepper'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box } from '@mui/material'
import { ScreenOnly } from '@persuit/ui-components'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'

const RfpTimelineFragment = graphql(`
	fragment RfpTimeline_Analytics on Analytics {
		isAuction
		initialProposalsDueBy
		status
		proposalsDueBy
		auctionEnd
		publishedAt
	}
`)

export type RfpTimelineProps = {
	rfpFragment: FragmentType<typeof RfpTimelineFragment>
}

export function RfpTimeline({ rfpFragment }: RfpTimelineProps) {
	const rfp = getFragment(RfpTimelineFragment, rfpFragment)
	const showTimeline = rfp.isAuction && rfp.initialProposalsDueBy

	if (!showTimeline) {
		return null
	} else {
		return (
			<ScreenOnly>
				<Box mt={4} />
				<TimelineStepper
					requestStatus={rfp.status}
					initialProposalsDueBy={rfp.initialProposalsDueBy}
					auctionStart={rfp.proposalsDueBy}
					auctionEnd={rfp.auctionEnd}
					requestPublishedAt={rfp.publishedAt}
				/>
			</ScreenOnly>
		)
	}
}
