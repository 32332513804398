import questionTypes from '../../../../../common/data/question-types'
import diversityTypes from '../../../../../common/data/diversity-types'

export default {
	diversity1: {
		type: questionTypes.CHOICE,
		title:
			'Is the lead lawyer (or in the context of litigation, the first or second chair) proposed for this matter Diverse?',
	},
	diversity2: {
		type: diversityTypes.PERCENTAGE,
		title: 'What percentage of the scope of services will be completed by Diverse lawyers?',
	},
	diversity3: {
		type: diversityTypes.PERCENTAGE,
		title:
			'What percentage of business generation credit (for example origination credit) will be awarded to Diverse lawyers?',
	},
	diversity4: {
		type: diversityTypes.CERTIFICATION,
		title: 'Diversity Certification / Membership',
	},
}
