import { compose, initial, isNil, sortBy } from 'lodash/fp'
import { styled, Typography } from '@persuit/ui-components'
import rfpProposalStates from '../../../../common/data/rfp-proposal-states'
import useTranslation from '../../../custom-hooks/translation-hook'
import { getOrNull } from '../../../utils/lenses'
import formatPriceCurrency from '../../../utils/price-and-currency'
import Spacer from '../../layout/spacer.jsx'
import RfprItem from '../../pricing-card-detail-view/components/rfpr-item'
import Reveal from '../../reveal'
import { arePricesEqual } from '../../rfp-request-proposal-revision/suggestion-change-detection'
import { TotalPriceHistoryTable } from './price-history-table'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 51.3%;
`

const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 48.7% !important;
	gap: 8px;
`

const StyledTotalItem = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-spacing: 10px;
`

// Dedicate space for aligning fee, pricing model
const StyledMiniCellFee = styled('div')`
	width: 194px;
	display: flex;
	align-items: flex-end;
`

const StyledMiniCellModel = styled('div')`
	width: 150px;
`

const StyledMiniCellInnerRight = styled('div')`
	width: 80px;
`

const isSame = (varA, varB) => {
	if (!varA || !varB) {
		return false
	}

	return varA.toString() === varB.toString()
}

const dropLastTotalPrice = compose(initial, sortBy(['createdAt']))

const checkIfSuggestionsPresent = (rfprTotalPrice, rfprTotalPricingModel) => {
	if (!isNil(rfprTotalPrice)) {
		return true
	}

	if (!isNil(rfprTotalPricingModel)) {
		return true
	}

	return false
}

const TotalPrice = ({
	currency,
	rfpr,
	rfpProposal: { totalPriceValue, totalPriceModel, totalPrices, status },
}) => {
	const { t } = useTranslation()
	const rfprTotalPrice = getOrNull('rfprTotalPrice', rfpr)
	const rfprTotalPricingModel = getOrNull('rfprTotalPricingModel', rfpr)
	const hasRfpr = checkIfSuggestionsPresent(rfprTotalPrice, rfprTotalPricingModel)

	// if the prices are the same dont show the strikethrough
	const samePrice = arePricesEqual(totalPriceValue, rfprTotalPrice)

	//  if the models are the same dont show the strikethrough
	const samePricingModel = rfprTotalPricingModel
		? isSame(rfprTotalPricingModel, totalPriceModel)
		: true

	const prettyTotal = formatPriceCurrency(currency, totalPriceValue)

	const pricingModel = totalPriceModel
		? t(`pricingModels.firm.total.${totalPriceModel}`)
		: t(`pricingModels.firm.total.none`)

	// Ditch the last element as it is the current price
	const priceChanges = dropLastTotalPrice(totalPrices)

	// Only show price history if there is more than 1 price revision
	const showPriceRevisionHistory = priceChanges.length > 0 && status !== rfpProposalStates.DRAFT

	return (
		<div data-testid="proposal-detail-price" id="total-price-table" tabIndex={-1}>
			<StyledTotalItem>
				<StyledItemLeft>
					<Typography variant="h3XSmall">{t('rfpProposalDetail.totalPrice')}</Typography>
				</StyledItemLeft>
				<StyledItemRight>
					<StyledMiniCellFee>
						<Typography strikeThrough={!samePrice} variant="body1" data-testid="total-price-value">
							{prettyTotal ? prettyTotal : ''}
						</Typography>
					</StyledMiniCellFee>
					<StyledMiniCellModel>
						<Typography
							strikeThrough={!samePricingModel}
							variant="body1"
							data-testid="total-pricing-model"
						>
							{pricingModel ? pricingModel : ''}
						</Typography>
					</StyledMiniCellModel>
					<StyledMiniCellInnerRight />
				</StyledItemRight>
			</StyledTotalItem>
			{hasRfpr && (
				<RfprItem
					rfprPricingModel={rfprTotalPricingModel}
					originalPricingModel={totalPriceModel}
					rfprPrice={rfprTotalPrice}
					originalPrice={totalPriceValue}
					currency={currency}
				/>
			)}
			<Spacer size={2} />
			{showPriceRevisionHistory && (
				<Reveal label={t('rfpProposalDetail.showPriceRevisionHistory')}>
					<TotalPriceHistoryTable priceHistories={priceChanges} currency={currency} />
				</Reveal>
			)}
		</div>
	)
}

export default TotalPrice
