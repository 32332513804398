import * as React from 'react'
import { format } from 'date-fns'
import { formatDuration } from '../../../util'
import { Typography, styled } from '@persuit/ui-components'
import { RfpSystemClarification } from '../../../../types'

const StyledTable = styled('table')`
	width: 100%;
	border-collapse: collapse;
	text-align: center;
`

const StyledHeaderCell = styled('th')<{ isNew?: boolean }>`
	border: 1px solid ${({ theme }) => theme.palette.form.borderResting};
	padding: 0.5em;
	background: ${({ theme, isNew }) =>
		isNew ? theme.palette.yellow.bright : theme.palette.action.selected};
`

const StyledDataCell = styled('td')`
	border: 1px solid ${({ theme }) => theme.palette.form.borderResting};
	padding: 0.5em;
`

type ClarificationTableRowProps<T> = {
	oldValue?: T | null
	newValue?: T | null
	formatValue: (value: T) => React.ReactNode
	headerText: string
	dataTestId: string
}

const ClarificationTableRow = <T,>({
	oldValue,
	newValue,
	headerText,
	formatValue,
	dataTestId,
}: ClarificationTableRowProps<T>) => {
	return (
		<tr>
			<StyledHeaderCell role="rowheader" scope="row">
				<Typography>{headerText}</Typography>
			</StyledHeaderCell>
			<StyledDataCell data-testid={`original-${dataTestId}`}>
				<Typography>{oldValue ? formatValue(oldValue) : '-'}</Typography>
			</StyledDataCell>
			<StyledDataCell sx={{ bgcolor: 'yellow.lightestHue' }} data-testid={`new-${dataTestId}`}>
				<Typography>{newValue ? formatValue(newValue) : '-'}</Typography>
			</StyledDataCell>
		</tr>
	)
}

type AuctionDatesChangedClarificationProps = {
	systemClarification?: RfpSystemClarification
}

export const AuctionAddedToRfpClarification = ({
	systemClarification,
}: AuctionDatesChangedClarificationProps) => {
	type Data = {
		initialProposalsDueBy: number
		startDate: number
		auctionDuration: number
	}
	const { previousValues: prev, newValues: next } = (systemClarification ?? {}) as {
		previousValues: Data
		newValues: Data
	}

	const formatDate = (date: number) => format(new Date(date), 'd MMMM yyyy, h:mm a')

	return (
		<>
			<Typography variant="h4">
				<b>Request converted to auction</b>
			</Typography>

			<StyledTable>
				<Typography component="caption" variant="body2" align="left">
					Please see the updated information below
				</Typography>

				<tr>
					<td />
					<StyledHeaderCell>
						<Typography variant="body1Semibold">ORIGINAL</Typography>
					</StyledHeaderCell>
					<StyledHeaderCell isNew={true}>
						<Typography variant="body1Semibold">NEW</Typography>
					</StyledHeaderCell>
				</tr>

				{prev.initialProposalsDueBy !== next.initialProposalsDueBy && (
					<ClarificationTableRow
						dataTestId="initial-proposals-due"
						oldValue={prev.initialProposalsDueBy}
						newValue={next.initialProposalsDueBy}
						formatValue={formatDate}
						headerText="Proposals Due date changed"
					/>
				)}

				<ClarificationTableRow
					dataTestId="auction-start-date"
					oldValue={prev.startDate}
					newValue={next.startDate}
					formatValue={formatDate}
					headerText="Auction Start date"
				/>

				{prev.auctionDuration !== next.auctionDuration && (
					<ClarificationTableRow
						dataTestId="auction-duration"
						oldValue={prev.auctionDuration}
						newValue={next.auctionDuration}
						formatValue={formatDuration}
						headerText="Auction duration"
					/>
				)}
			</StyledTable>
		</>
	)
}
