export const conflictEvents = {
	CONFLICT_CLEARED: 'cleared',
	CONFLICT_DECLINED: 'declined',
	UNANSWERED: 'unanswered',
}

export const conflictEventsValues = Object.values(conflictEvents)

export const viewEvents = {
	VIEWED: 'viewed',
}

export const viewEventsValues = Object.values(viewEvents)

export const eliminateEvents = {
	ELIMINATED: 'eliminated',
	UNELIMINATED: 'uneliminated',
}

export const eliminateEventsValues = Object.values(eliminateEvents)

export const participateEvents = {
	UNANSWERED: 'unanswered',
	PARTICIPATING: 'participating',
	NOT_PARTICIPATING: 'not_participating',
}

export const participateEventsValues = Object.values(participateEvents)

export const participateEventReasons = {
	NOT_ENOUGH_TIME: 'not_enough_time',
	UNABLE_TO_CLEAR_CONFLICTS: 'unable_to_clear_conflicts',
	NOT_ENOUGH_INFO: 'not_enough_info',
	NOT_ENOUGH_EXPERTISE: 'not_enough_expertise',
	NOT_ENOUGH_CAPACITY: 'not_enough_capacity',
	WRONG_FEE_TYPE: 'wrong_fee_type',
	OUTSIDE_PRACTICE_AREA: 'outside_practice_area',
	NOT_VALUABLE_ENOUGH: 'not_valuable_enough',
}

export const participateEventReasonsValues = Object.values(participateEventReasons)

export const participateEventReasonsText = {
	[participateEventReasons.NOT_ENOUGH_TIME]: 'Not enough time to prepare proposal before deadline',
	[participateEventReasons.UNABLE_TO_CLEAR_CONFLICTS]:
		'Unable to clear conflicts and meet participation requirements',
	[participateEventReasons.NOT_ENOUGH_INFO]: 'Not enough information to prepare proposal',
	[participateEventReasons.NOT_ENOUGH_EXPERTISE]: 'Not enough expertise to provide services',
	[participateEventReasons.NOT_ENOUGH_CAPACITY]: 'Not enough capacity to provide services',
	[participateEventReasons.WRONG_FEE_TYPE]: 'Unable to provide requested fee type',
	[participateEventReasons.OUTSIDE_PRACTICE_AREA]: 'Outside of practice area',
	[participateEventReasons.NOT_VALUABLE_ENOUGH]: 'Request is not valuable enough',
}
