import { Typography } from '@persuit/ui-components'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardSelectWinnerProps = ProgressCardProps

export const CARD_TITLE = 'Select proposal(s)'

export const ProgressCardSelectWinner = ({
	index,
	step,
	extraData,
}: ProgressCardSelectWinnerProps) => {
	const isRateReview = extraData.useCase === 'rateReview'
	const HELP_TEXT = isRateReview
		? "To agree to a firm's proposed rates, click the select button for that proposal on the proposals tab. You will have the opportunity to choose which firm(s) will receive information about your decision. Note: marking a proposal as unsuccessful will eliminate all proposals submitted by that firm."
		: `Click select on the proposal of the firm you'd like to award the business to. You will be given options so you can control which firm(s) are notified of your decision. Note that marking a proposal as unsuccessful will eliminate all proposals submitted by that firm.`

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				guidanceText={
					isRateReview
						? 'By selecting proposals, your company will have a clear point of reference and your firms will be happy to know the outcome of the process.'
						: `By indicating which firm won the work, your company will have complete and clean data and your firms will be happy to know the outcome of the process.`
				}
				subtitle={<Typography>Select at least one winning proposal</Typography>}
			/>
		)

	if (step.status === 'completed' || step.status === 'passed')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				subtitle={<Typography>Selected {extraData.totalSelectedProposals} proposal(s)</Typography>}
			/>
		)

	return <BaseCard title={CARD_TITLE} helpText={HELP_TEXT} index={index} status={step.status} />
}
