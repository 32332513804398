// @ts-strict-ignore
import { useMemo } from 'react'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplatePricingSaveMutation } from '@persuit/ui-graphql/hooks'
import { useSectionalContainer } from '../../sectional-form'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { PricingForm, PricingFormProps } from '../../pricing-form'
import { DeepPick, isNotNil } from '@persuit/common-utils'
import { RfpTemplate } from '../types'

graphql(`
	mutation RfpTemplatePricingSave($payload: RfpTemplateDraftPricingInput!, $validate: Boolean!) {
		saveRfpTemplateDraftPricing(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

export type PricingSectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<
		RfpTemplate,
		| '_id'
		| 'detail.deliverablesV2'
		| 'detail.priceDescription'
		| 'detail.currency'
		| 'detail.totalPriceRequired'
		| 'detail.totalPricingPreference'
		| 'detail.allowFirmPriceModel'
		| 'auction'
	>
	validationErrors: {
		pricing?: PricingFormProps['pricingErrors']
	} | null
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const PricingSection = ({
	instanceId,
	rfpTemplate,
	validationErrors,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
}: PricingSectionProps) => {
	const [saveSectionMutation] = useRfpTemplatePricingSaveMutation()
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)
	const sectionErrors = validationErrors?.pricing ?? null

	const sectionData = useMemo(
		() => ({
			rfpTemplateId: rfpTemplate?._id ?? '',
			deliverablesV2: (rfpTemplate?.detail?.deliverablesV2 ?? []).map((item) => {
				if (item.__typename === 'Deliverable') {
					return {
						...item,
						rates: item.rates?.filter(isNotNil) ?? [],
					}
				} else {
					return {
						...item,
						deliverables: (item?.deliverables ?? []).map((deliverable) => ({
							...deliverable,
							rates: deliverable.rates?.filter(isNotNil) ?? [],
						})),
					}
				}
			}),
			priceDescription: rfpTemplate?.detail?.priceDescription ?? null,
			currency: rfpTemplate?.detail?.currency ?? null,
			totalPriceModel: rfpTemplate?.detail?.totalPricingPreference
				? rfpTemplate?.detail?.totalPricingPreference
				: 'fixedFee',
			totalPriceRequired: rfpTemplate?.detail?.totalPriceRequired ?? false,
			isFirmAllowedToChangeTotalPriceModel: rfpTemplate?.detail?.allowFirmPriceModel ?? false,
			auction: rfpTemplate?.auction,
		}),
		[rfpTemplate],
	)

	const saveSection = async (formVariables) => {
		await saveSectionMutation({
			variables: {
				payload: {
					...formVariables,
					deliverables: formVariables.deliverables.map((deliverable) => ({
						group: deliverable.deliverables ? deliverable : null,
						deliverable: !deliverable.deliverables ? deliverable : null,
					})),
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	if (loading) return <LoadingSpinner />

	return (
		<>
			<PageTitle>Edit pricing of template</PageTitle>
			<PricingForm onSave={saveSection} pricingData={sectionData} pricingErrors={sectionErrors} />
		</>
	)
}
