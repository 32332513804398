import { gql } from '@persuit/ui-graphql'

const getSessionInfo = gql`
	query getSessionInfo {
		getSessionInfo {
			isImpersonated
			languageCode
			featureMap
			user {
				_id
				email
				roles
				localAccountExists
				emailAddresses {
					email
					receiveNotifications
				}
				name {
					first
					last
				}
				isOnboarded
				favourites
				org {
					_id
					certifications
					name
					isNamwolfMember
					orgType
					allowableEmailSuffix
					settings {
						autoShares {
							_id
						}
						firmDesignatedRFPContacts {
							_id
						}
						firmDesignatedRFPContactsByOrg {
							firmDesignatedRFPContacts {
								_id
							}
							orgId
						}
					}
					contract {
						rateReview
					}
					hidePersuitTemplates
				}
				group {
					_id
					name
				}
				scope
				professionalProfile {
					_id
					profile
					url
				}
				phone
				title
			}
		}
	}
`

export default getSessionInfo
