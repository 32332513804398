import { useCallback } from 'react'
import { graphql, useQuery, useSuspenseQuery } from '@persuit/ui-graphql'
import {
	OrgGrant,
	GroupGrant,
	TeamGrant,
	UseUser_GetSessionInfoQuery,
} from '@persuit/ui-graphql/generated'

type User = UseUser_GetSessionInfoQuery['getSessionInfo']['user']

const GET_SESSION_QUERY = graphql(`
	query UseUser_GetSessionInfo {
		getSessionInfo {
			isImpersonated
			user {
				_id
				authMethod
				name {
					first
					last
				}
				email
				title
				org {
					_id
					name
					orgType
					isNamwolfMember
					grants
					certifications
					settings {
						isProposalAnalyserEnabled
						firmDesignatedRFPContacts {
							_id
						}
						firmDesignatedRFPContactsByOrg {
							orgId
							firmDesignatedRFPContacts {
								_id
							}
						}
					}
					groups {
						_id
						name
					}
					teams {
						_id
						name
					}
				}
				group {
					_id
					name
					grants
					certifications
					settings {
						businessType
						customEmails {
							proposalUnsuccessful
						}
						customTrackingFields {
							__typename
							_id
							name
							description
							apiMatchKey
							fieldType
							isArchived
							mandatory
							... on CustomTrackingFieldHierarchy {
								_id
								fieldType
								name
								description
								isArchived
								mandatory
								apiMatchKey
								values {
									_id
									value
									apiMatchKey
									isArchived
									values {
										_id
										value
										apiMatchKey
										isArchived
									}
								}
							}
						}
						isDiversityQuestionnaireFeatureEnabled
						currency
					}
				}
				team {
					_id
					name
					grants
					settings {
						autoShares {
							userId
						}
					}
				}
				orgRoles {
					org {
						_id
						name
					}
					roles
				}
				groupRoles {
					group {
						_id
					}
					roles
				}
				teamRoles {
					team {
						_id
					}
					roles
				}
				siteRoles
				favourites
			}
		}
	}
`)

export const useUAMEnabled = () => {
	return {
		enabled: true,
		loading: false,
	}
}

export const useUserSuspense = () => {
	const { data, refetch } = useSuspenseQuery(GET_SESSION_QUERY)

	const user = data?.getSessionInfo.user ?? null
	const isImpersonated = !!data?.getSessionInfo.isImpersonated

	const canHandler = useCallback((grant: Grant) => (user ? can(user, grant) : false), [user])

	return {
		can: canHandler,
		isImpersonated,
		user,
		refetch,
	}
}

export const useUser = () => {
	const { data, refetch, loading } = useQuery(GET_SESSION_QUERY)

	const user = data?.getSessionInfo.user ?? null
	const isImpersonated = !!data?.getSessionInfo.isImpersonated

	const canHandler = useCallback((grant: Grant) => (user ? can(user, grant) : false), [user])

	return {
		can: canHandler,
		isImpersonated,
		user,
		loading,
		refetch,
	}
}

export type Grant = OrgGrant | GroupGrant | TeamGrant

export const can = (user: User, grant: Grant) => {
	const grants = [
		...(user.org?.grants ?? []),
		...(user.group?.grants ?? []),
		...(user.team?.grants ?? []),
	]
	return grants.includes(grant)
}
