export const getPricingPreference = (totalPricingModel: string | null | undefined) => {
	const isInEligiblePricingModel = [null, undefined, '', 'contingencyFee'].includes(
		totalPricingModel,
	)
	if (isInEligiblePricingModel) {
		return 'fixedFee'
	}

	return totalPricingModel
}
