import { Button, DialogActions, CircularProgress, GetAppIcon } from '@persuit/ui-components'
import { FnHandleWizardNavigation } from './sync-tracking-fields-wizard.types'

type SyncTrackingFieldsWizardActionsProps = {
	isWorking: boolean
	onPrev?: FnHandleWizardNavigation
	onNext?: FnHandleWizardNavigation
	onSearch?: FnHandleWizardNavigation
	onConfirm?: FnHandleWizardNavigation
	onCancel: FnHandleWizardNavigation
}

export const SyncTrackingFieldsWizardActions = ({
	isWorking,
	onPrev,
	onNext,
	onSearch,
	onConfirm,
	onCancel,
}: SyncTrackingFieldsWizardActionsProps) => {
	return (
		<DialogActions>
			{onPrev && <Button onClick={onPrev}>Back</Button>}
			<Button onClick={onCancel}>Cancel</Button>
			{onNext && (
				<Button
					disabled={isWorking}
					color="primary"
					onClick={onNext}
					data-trackid="button-next-select-external-matter"
				>
					Next
				</Button>
			)}
			{onSearch && (
				<Button
					disabled={isWorking}
					color="primary"
					onClick={onSearch}
					data-trackid="button-search-external-matter"
				>
					Search
				</Button>
			)}
			{onConfirm && (
				<Button
					disabled={isWorking}
					color="primary"
					variant="contained"
					onClick={onConfirm}
					startIcon={isWorking ? <CircularProgress size={14} /> : <GetAppIcon />}
					data-trackid="button-confirm-sync-tracking-fields"
				>
					SYNC
				</Button>
			)}
		</DialogActions>
	)
}
