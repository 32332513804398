// @ts-strict-ignore
import { useState } from 'react'

import { gql, useMutation } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import { IconButton } from '@persuit/ui-components'

const FEEDBACK_MUTATION = gql`
	mutation proposalAnalysisFeedback(
		$rfpId: ID!
		$proposalIds: [ID!]!
		$cacheType: String!
		$thumbsUp: Boolean!
	) {
		proposalAnalysisFeedback(
			rfpId: $rfpId
			proposalIds: $proposalIds
			cacheType: $cacheType
			thumbsUp: $thumbsUp
		)
	}
`

const thumbpsUpFn =
	({ thumbsUpState, setThumbsUp, feedbackMutation, rfpId, proposalIds, thumbsUp }) =>
	async () => {
		if (thumbsUpState === thumbsUp) {
			return
		}

		await feedbackMutation({
			variables: { rfpId, proposalIds, cacheType: 'proposalCompare', thumbsUp },
		})
		setThumbsUp(thumbsUp)
		return
	}

export const Feedback = ({ rfpId, proposalIds }) => {
	const [thumbsUp, setThumbsUp] = useState(null)

	const [proposalAnalysisFeedback] = useMutation(FEEDBACK_MUTATION)

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ marginRight: '0.5rem' }}>Was this helpful?</div>
			<IconButton
				aria-label="Thumbs up"
				color="secondary"
				onClick={thumbpsUpFn({
					thumbsUpState: thumbsUp,
					setThumbsUp,
					feedbackMutation: proposalAnalysisFeedback,
					rfpId,
					proposalIds,
					thumbsUp: true,
				})}
			>
				{thumbsUp === true ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
			</IconButton>
			<IconButton
				color="secondary"
				aria-label="Thumbs down"
				onClick={thumbpsUpFn({
					thumbsUpState: thumbsUp,
					setThumbsUp,
					feedbackMutation: proposalAnalysisFeedback,
					rfpId,
					proposalIds,
					thumbsUp: false,
				})}
			>
				{thumbsUp === false ? (
					<ThumbDownAltIcon data-trackid="button-thumb-up-ai-summary" />
				) : (
					<ThumbDownOffAltIcon data-trackid="button-thumb-down-ai-summary" />
				)}
			</IconButton>
		</div>
	)
}
