import {
	Typography,
	useTheme,
	ArrowDropDownIcon,
	ArrowDropUpIcon,
	Box,
	SROnly,
} from '@persuit/ui-components'
import { formatPriceWithCurrency } from '@persuit/common-utils'

export type DeltaProps = {
	originalNumber: number
	newNumber: number
	currency: string
	showZero?: boolean
	typographyProps?: any
}

export const Delta = ({
	originalNumber,
	newNumber,
	currency,
	showZero = false,
	typographyProps,
}: DeltaProps) => {
	const theme = useTheme()
	const unChangedNode = <SROnly>Pricing delta unchanged</SROnly>
	if (originalNumber === newNumber) {
		if (!showZero) return unChangedNode
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography variant="body2" sx={{ ...typographyProps }}>
					{formatPriceWithCurrency(currency, 0, false)} (0%)
				</Typography>
			</Box>
		)
	}

	const difference = originalNumber - newNumber
	const Icon = difference > 0 ? ArrowDropDownIcon : ArrowDropUpIcon
	const color = difference > 0 ? theme.palette.success.main : theme.palette.error.main
	const absDifference = Math.abs(difference)
	const percentageChange = (absDifference * 100) / originalNumber
	const isPercentageValid = !isNaN(percentageChange) && isFinite(percentageChange)

	return isPercentageValid ? (
		<Box display="flex" flexDirection="row" alignItems="center" color={color}>
			<Icon color="inherit" />
			<Typography variant="body2" color="inherit" sx={{ ...typographyProps }}>
				<SROnly>{difference > 0 ? 'Reduced by: ' : 'Increased by: '}</SROnly>
				{formatPriceWithCurrency(currency, absDifference, false)} ({percentageChange.toFixed(0)}%)
			</Typography>
		</Box>
	) : (
		unChangedNode
	)
}
