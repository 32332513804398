import * as React from 'react'
import { Box, Typography } from '@persuit/ui-components'

type LabeledValueProps = {
	label: string
	value: string | React.ReactNode
	italicGrey?: boolean
	'aria-label'?: string
	title?: string
	role?: React.AriaRole
}

export const LabeledValue = ({
	label,
	value,
	italicGrey = false,
	...restProps
}: LabeledValueProps) => {
	const fontStyle = italicGrey ? 'italic' : 'normal'

	const valueNode =
		typeof value === 'string' ? (
			<Typography
				variant="body1Semibold"
				sx={{ color: italicGrey ? 'text.secondary' : 'common.black', fontStyle }}
				{...restProps}
			>
				{value}
			</Typography>
		) : (
			value
		)

	return (
		<Box display="flex" flexDirection="column">
			<Typography variant="caption">{label}</Typography>
			{valueNode}
		</Box>
	)
}
