import { graphql, useQuery } from '@persuit/ui-graphql'
import { Box, Typography } from '@persuit/ui-components'
import { RateCard, RateCardProps, NamedTimekeepers } from '@persuit/ui-shared-components'
import { Response } from '@persuit/ui-graphql/generated'

const GET_PRICE_HISTORY = graphql(`
	query RfpProposalRateReview_GetRateCardPriceHistory($proposalId: ID!) {
		getRfpProposal(_id: $proposalId) {
			_id
			rateCards {
				currency
				_id
				historicalPrices {
					currency
					previousCurrency
					previousProposedRate
					proposedRate
					timekeeperLabel
					updatedAt
				}
			}
		}
	}
`)

type RfpProposalRateReviewProps = {
	proposal: Response
	currency: RateCardProps['currency']
	timekeepers: RateCardProps['timekeepers']
}

export const RfpProposalRateReview = ({
	proposal,
	currency,
	timekeepers,
}: RfpProposalRateReviewProps) => {
	const { data: historyData, loading: historyLoading } = useQuery(GET_PRICE_HISTORY, {
		variables: { proposalId: proposal._id },
	})

	if (historyLoading) {
		return null
	}

	const rateCards = proposal?.rateCards
	const LatestRevisionCommentIndex = 0

	const getLatestRateCardRevisionCommentRateReview = (rateCardId: string) => {
		// revisionComments comes from a field resolver which retrieves RevisionComments from the DB in descending order.
		// This means the 0th is the latest revision comment
		const latestRevisionCommentOrgType =
			proposal?.revisionComments[LatestRevisionCommentIndex]?.org?.orgType ?? ''
		const latestClientRevisionCommentsRateReview =
			proposal?.revisionComments[LatestRevisionCommentIndex]?.rateReview ?? []

		if (latestRevisionCommentOrgType === 'CLIENT') {
			return latestClientRevisionCommentsRateReview.find(
				(revisionComment) => revisionComment.rateCardId === rateCardId,
			)
		}

		return undefined
	}
	const latestRevisionCommentNamedTimekeepers =
		proposal?.revisionComments[LatestRevisionCommentIndex]?.namedTimekeepers ?? []

	return (
		<Box sx={{ mb: 1 }}>
			<Typography variant="h2XSmall" gutterBottom={true}>
				Rates
			</Typography>

			{rateCards.map((rateCard, index) => (
				<RateCard
					sx={{ mb: 2 }}
					index={index}
					truncated={true}
					defaultOpen={true}
					historicalPrices={
						historyData?.getRfpProposal?.rateCards?.find((card) => card._id === rateCard._id)
							?.historicalPrices ?? []
					}
					key={rateCard._id}
					rateCard={{ ...rateCard, updatedAt: proposal.updatedAt }}
					currency={currency}
					timekeepers={timekeepers}
					rateCardRevisionComment={getLatestRateCardRevisionCommentRateReview(rateCard._id)}
				/>
			))}

			<NamedTimekeepers
				namedTimekeepers={proposal.namedTimekeepers}
				currency={currency}
				namedTimekeepersRevisionComment={latestRevisionCommentNamedTimekeepers}
				proposalId={proposal._id}
			/>
		</Box>
	)
}
