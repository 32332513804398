import { Fragment } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import DescriptionIcon from '@mui/icons-material/Description'
import { List, ListItemText, ListItemIcon, ListItem, Divider } from '@persuit/ui-components'
import FileSaver from 'file-saver'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardContent from '../../dialog-wizard-content'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import { defaultSteps } from '../../dialog-wizard-steps'
import { getOrEmptyString, getOrNull } from '../../../../utils/lenses'
import { stepIndex } from '../../utils.js'

const GET_LOE_TEMPLATE_QUERY = gql`
	query getLoeTemplate($templateId: ID!) {
		getLoeTemplate(templateId: $templateId) {
			_id
			loeTemplateTitle
			loeTemplateDescription
			createdBy {
				_id
				name {
					first
					last
				}
			}
		}
	}
`

const EXPORT_LOE_TEMPLATE_MUTATION = gql`
	mutation exportLoeTemplate($proposalId: ID!, $templateId: ID!) {
		exportLoeTemplate(proposalId: $proposalId, templateId: $templateId) {
			data
		}
	}
`

const saveFile = (result, fileName) => {
	const fileData = getOrEmptyString('data.exportLoeTemplate.data', result)
	if (!fileData) throw new Error('No data returned')

	const buffer = Buffer.from(fileData, 'base64')

	FileSaver.saveAs(new Blob([buffer], { type: '' }), fileName)
}

function OptionGenerateLoeConfirm({ onCancel, onPrev, onDone, value, proposalId }) {
	const { templateId } = value
	const loeTemplateQuery = useQuery(GET_LOE_TEMPLATE_QUERY, { variables: { templateId } })
	const [exportLoeTemplate, { loading, error }] = useMutation(EXPORT_LOE_TEMPLATE_MUTATION)

	const loeTemplate = getOrNull('data.getLoeTemplate', loeTemplateQuery)

	const onConfirm = async () => {
		const result = await exportLoeTemplate({
			variables: {
				proposalId,
				templateId,
			},
		})
		saveFile(result, `${loeTemplate.loeTemplateTitle}.docx`)
		onDone()
	}

	return (
		<DialogWizardContent
			onCancel={onCancel}
			onPrev={onPrev}
			onConfirm={onConfirm}
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			isWorking={loading}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Generate Engagement Letter',
				[stepIndex(defaultSteps, 'ACTIONS')]: 'Select Template',
			}}
		>
			<DialogWizardConfirmationError error={loeTemplateQuery.error || error} />
			<strong>Download Engagement Letter</strong>
			<Spacer />
			<Divider />
			{loeTemplate && (
				<List>
					<ListItem alignItems="center">
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText
							primary={
								// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
								<span style={{ fontWeight: 500, color: '#444' }}>
									{loeTemplate.loeTemplateTitle}
								</span>
							}
							secondary={
								<Fragment>
									<Text>{`Created by ${loeTemplate.createdBy.name.first} ${loeTemplate.createdBy.name.last}`}</Text>
									<Text black={true}>{loeTemplate.loeTemplateDescription}</Text>
								</Fragment>
							}
						/>
					</ListItem>
					<Divider />
				</List>
			)}
		</DialogWizardContent>
	)
}

export default OptionGenerateLoeConfirm
