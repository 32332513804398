import * as React from 'react'
import { Alert } from '@persuit/ui-components'
import { useUserDismissibles } from '@persuit/ui-hooks'
import { UserDismissible } from '@persuit/ui-graphql/generated'

type DismissibleAlertProps = {
	dismissible: UserDismissible
	content: string | React.ReactElement
	icon?: React.ReactElement
}

export const DismissibleAlert = ({ dismissible, content, icon }: DismissibleAlertProps) => {
	const { dismissibles, dismiss } = useUserDismissibles()

	if (dismissibles.has(dismissible)) return null

	return (
		<>
			<Alert
				severity="info"
				iconMapping={{
					info: icon,
				}}
				onClose={() => dismiss(dismissible)}
				sx={{ mt: 2 }}
			>
				{content}
			</Alert>
		</>
	)
}
