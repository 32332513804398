import { useState } from 'react'
import {
	DialogDeprecated,
	DialogTitle,
	Box,
	Button,
	Typography,
	IconButton,
	CloseIcon,
	FormControlLabel,
	Switch,
	PictureAsPdfIcon,
	LoadingSpinner,
	useTheme,
	LinkSupport,
} from '@persuit/ui-components'
import { graphql, useQuery } from '@persuit/ui-graphql'
import { Channel } from './channel'

export type ExportDialogProps = {
	requestId: string
	show: boolean
	onClose: () => void
}

const GET_CHANNELS_DATA = graphql(`
	query MessagingExportChannels($requestId: ID!) {
		channels(requestId: $requestId) {
			id
			...MessageExportChannelChannel
		}
	}
`)

const GET_SESSION_DATA = graphql(`
	query MessagingExportGetSessionInfo {
		getSessionInfo {
			isImpersonated
			user {
				_id
				org {
					_id
				}
			}
			...MessageExportChannelSession
		}
	}
`)

export const ExportDialog = ({ show, onClose, requestId }: ExportDialogProps) => {
	const theme = useTheme()
	const { data: sessionData, error: sessionError } = useQuery(GET_SESSION_DATA)
	const { data: channelsData, error: channelsError } = useQuery(GET_CHANNELS_DATA, {
		variables: { requestId },
	})
	const [showChannelMembers, setShowChannelMembers] = useState(true)
	const [showSystemMessages, setShowSystemMessages] = useState(false)

	if (!show) return null

	if (sessionError || channelsError)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				gap={2}
				p={4}
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
			>
				<Typography variant="body1Semibold">Messaging is temporarily unavailable.</Typography>
				<Typography variant="caption">
					If this problem persists, please contact our support team at&nbsp;
					<LinkSupport />.
				</Typography>
			</Box>
		)

	if (!sessionData || !channelsData)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
			>
				<LoadingSpinner />
			</Box>
		)

	return (
		<DialogDeprecated fullScreen={true} open={show} onClose={onClose}>
			<DialogTitle sx={{ display: 'none' }}>Export messages</DialogTitle>
			<Box display="block" displayPrint="none" position="sticky" top="0" zIndex={10000}>
				<Box
					bgcolor={theme.palette.deepPurple800}
					color="primary.contrastText"
					width="100%"
					display="flex"
					justifyContent="space-between"
					p={1}
					alignItems="center"
				>
					<Box display="flex" gap={2} alignItems="center">
						<IconButton edge="start" color="inherit" onClick={onClose} title="close" size="large">
							<CloseIcon />
						</IconButton>
						<Typography variant="body1Semibold">All messages</Typography>
					</Box>
					<Box>
						<FormControlLabel
							control={
								<Switch
									checked={showSystemMessages}
									onChange={() => {
										setShowSystemMessages(!showSystemMessages)
									}}
								/>
							}
							label="Show system messages?"
						/>
						<FormControlLabel
							control={
								<Switch
									checked={showChannelMembers}
									onChange={() => setShowChannelMembers(!showChannelMembers)}
								/>
							}
							label="Show channel members?"
						/>

						<Button variant="contained" onClick={window.print} startIcon={<PictureAsPdfIcon />}>
							PRINT/PDF
						</Button>
					</Box>
				</Box>
			</Box>

			<Box display="flex" flexDirection="column" gap={2} width="100%" p={2}>
				{channelsData?.channels?.map((channel) => {
					return (
						<Channel
							key={channel.id}
							channel={channel}
							session={sessionData.getSessionInfo}
							showSystemMessages={showSystemMessages}
							showChannelMembers={showChannelMembers}
						/>
					)
				})}
			</Box>
		</DialogDeprecated>
	)
}
