import { Box, Button, useTheme, ArrowBackIcon, ArrowForwardIcon } from '@persuit/ui-components'
import { Link } from 'react-router-dom'
import { SectionData } from '../../types'

export type SectionNavigationProps = {
	previousSection?: SectionData
	nextSection?: SectionData
	isFloating?: boolean
}

export const SectionNavigation = ({
	previousSection,
	nextSection,
	isFloating,
}: SectionNavigationProps) => {
	const theme = useTheme()
	return (
		<Box
			display="flex"
			borderTop={!isFloating ? `2px solid ${theme.palette.grey300}` : undefined}
			padding="8px"
			boxShadow={
				isFloating ? '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)' : undefined
			}
			bgcolor={isFloating ? theme.palette.white : undefined}
		>
			{previousSection && (
				<Button
					data-trackid="navigation-link-previous-section"
					variant="text"
					component={Link}
					color="primary"
					startIcon={<ArrowBackIcon />}
					to={previousSection.link}
					data-testid={'new-rfp-form-bottom-navigation-back'}
					aria-label={`Navigate to ${previousSection.label} section`}
				>
					{previousSection.label}
				</Button>
			)}
			<Box flexGrow={1} />
			{nextSection && (
				<Button
					data-trackid="navigation-link-next-section"
					component={Link}
					variant="contained"
					color="primary"
					endIcon={<ArrowForwardIcon />}
					to={nextSection.link}
					data-testid={'new-rfp-form-bottom-navigation-forward'}
					aria-label={`Navigate to ${nextSection.label} section`}
				>
					{nextSection.label}
				</Button>
			)}
		</Box>
	)
}
