// @ts-strict-ignore
import { authActions } from '../actions'

const {
	AUTH_ERROR,
	AUTH_RECEIVED,
	AUTH_REQUEST,
	PASSWORD_RESET_SENT,
	SET_AUTH_SCOPE,
	SET_USER,
	UPDATE_USER_ORG,
	UPDATE_USER_FAVOURITES,
} = authActions

type AuthState = {
	isLoggedIn: boolean
	error: unknown
	isFetching: boolean
	passwordReset: boolean
	scope: string[]
	user: null | {
		_id: string
		isOnboarded: boolean
		isImpersonated: boolean
		roles: string[]
		name: {
			first: string
			last: string
		}
		email: string
		title: string
		favourites?: string[]
		org: {
			_id: string
			name: string
			orgType: 'CLIENT' | 'FIRM'
			isNamwolfMember: boolean
			allowableEmailSuffix?: string[]
			contract: {
				rateReview: string
			}
			hidePersuitTemplates: boolean
		}
	}
}

const initialState: AuthState = {
	isLoggedIn: false,
	error: null,
	isFetching: false,
	passwordReset: false,
	scope: [],
	user: null,
}

function authReducer(state = initialState, action): AuthState {
	switch (action.type) {
		case AUTH_ERROR: {
			return Object.assign({}, state, {
				error: action.error,
				isFetching: false,
			})
		}

		case AUTH_RECEIVED: {
			return Object.assign({}, state, {
				isFetching: false,
				isLoggedIn: true,
			})
		}

		case AUTH_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}

		case PASSWORD_RESET_SENT: {
			return Object.assign({}, state, {
				passwordReset: true,
			})
		}

		case SET_AUTH_SCOPE: {
			return Object.assign({}, state, {
				scope: action.scope,
			})
		}

		case SET_USER: {
			//merge the old user with the new one to keep the localAccountExists property
			const user = Object.assign({}, state.user, action.user)

			return {
				...state,
				user,
				isLoggedIn: true,
			}
		}

		case UPDATE_USER_ORG: {
			return Object.assign({}, state, {
				user: {
					...state.user,
					org: action.org,
				},
			})
		}

		case UPDATE_USER_FAVOURITES: {
			return Object.assign({}, state, {
				user: {
					...state.user,
					favourites: action.favourites,
				},
			})
		}

		default: {
			return state
		}
	}
}

export default authReducer
