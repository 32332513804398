// @ts-strict-ignore
import { useState } from 'react'
import { PublishButton } from './publish-button'
import { CopyMenuItem } from './copy-menu-item'
import { DeleteMenuItem } from './delete-menu-item'
import { PreviewButton } from './preview-button'
import { PreviewMenuItem } from './preview-menu-item'
import { PublishMenuItem } from './publish-menu-item'
import { ButtonLayout } from './button-layout'
import { KebabButton } from '@persuit/ui-components'

type CabProps = {
	templateId: string
	handlePublish: () => void
	isUploading: boolean
	handlePreview: () => void
}

export const Cab = ({ templateId, handlePublish, isUploading, handlePreview }: CabProps) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<div data-testid="rfp-template-draft-cab">
			<ButtonLayout
				anchorEl={anchorEl}
				handleMenuClose={handleMenuClose}
				PublishButton={<PublishButton onClick={handlePublish} disabled={isUploading} />}
				PublishMenuItem={
					<PublishMenuItem handlePublish={handlePublish} handleMenuClose={handleMenuClose} />
				}
				PreviewButton={<PreviewButton openPreview={handlePreview} />}
				PreviewMenuItem={
					<PreviewMenuItem openPreview={handlePreview} handleMenuClose={handleMenuClose} />
				}
				CopyMenuItem={<CopyMenuItem templateId={templateId} isUploading={isUploading} />}
				DeleteMenuItem={<DeleteMenuItem templateId={templateId} isUploading={isUploading} />}
				MenuButton={
					<KebabButton
						onClick={handleMenuClick}
						id="kebab-button"
						aria-controls={anchorEl ? 'kebab-menu' : undefined}
					/>
				}
			/>
		</div>
	)
}
