// @ts-strict-ignore
import numeral from 'numeral'
import questionTypes from '../../../../common/data/question-types'
import {
	getAnswerOptionLabel,
	getAnswerOptionLabels,
} from '../../../../common/proposals/get-answer-option-label'
import formatPriceCurrency from '../../../utils/price-and-currency'
import { PrintOnly, RichTextView, Typography, useTheme, Box } from '@persuit/ui-components'

type RfpProposalDetailQuestionnaireAnswersProps = {
	answersToQuestions: {
		answerType: string
		questionId: string
		comments?: string
	}[]
	currency: string
	questions: { _id: string; title: string; description: string }[]
	questionGroups: [{ _id: string; name: string }]
}

const groupQuestions = (questions, questionGroups) => {
	const grouped = (questionGroups || []).reduce((acc, group) => {
		const foundForGroup = questions.filter((question) => question.groupId === group._id)
		acc.push({
			name: group.name,
			hidden: group.hidden,
			questions: foundForGroup,
		})

		return acc
	}, [])

	return grouped
}

export const RfpProposalDetailQuestionnaireAnswers = ({
	answersToQuestions,
	currency,
	questions,
	questionGroups,
}: RfpProposalDetailQuestionnaireAnswersProps) => {
	const theme = useTheme()
	if (!questions || questions.length === 0) {
		return null
	}

	const groupedQuestions = groupQuestions(questions, questionGroups)

	const NoResponseNode = (
		<Typography variant="body1" fontSize="italic">
			No response
		</Typography>
	)

	function renderAnswer(question, answer) {
		const {
			choiceAnswer,
			stringAnswer,
			numericAnswer,
			singleSelectAnswer,
			multipleSelectAnswer,
			type: answerType,
		} = answer

		const richTextAnswerNode = (
			<>{!stringAnswer ? NoResponseNode : <RichTextView content={stringAnswer} />}</>
		)

		const numericAnswerNode = (
			<>
				{!stringAnswer ? (
					NoResponseNode
				) : (
					<Typography variant="body1">
						{answerType === questionTypes.PRICE
							? formatPriceCurrency(currency, stringAnswer)
							: numeral(stringAnswer).format('0,0.[00000000]')}
					</Typography>
				)}
			</>
		)

		const percentageAnswerNode = (
			<>
				{typeof numericAnswer !== 'number' ? (
					NoResponseNode
				) : (
					<Typography variant="body1">
						{numeral(numericAnswer).format('0,0.[00000000]')}%
					</Typography>
				)}
			</>
		)

		switch (answerType) {
			case questionTypes.LONG: {
				return richTextAnswerNode
			}
			case questionTypes.MEDIUM: {
				return richTextAnswerNode
			}
			case questionTypes.SHORT: {
				return richTextAnswerNode
			}
			case questionTypes.NUMERIC: {
				return numericAnswerNode
			}
			case questionTypes.PRICE: {
				return numericAnswerNode
			}
			case questionTypes.PERCENTAGE: {
				return percentageAnswerNode
			}
			case questionTypes.CHOICE: {
				return (
					<>
						{choiceAnswer === null ? (
							NoResponseNode
						) : (
							<Typography fontWeight="bold">{choiceAnswer === true ? 'Yes' : 'No'}</Typography>
						)}
					</>
				)
			}
			case questionTypes.SCALE:
			case questionTypes.SINGLE_SELECT: {
				return (
					<>
						{!singleSelectAnswer ? (
							NoResponseNode
						) : (
							<Typography variant="body1">
								{getAnswerOptionLabel({ options: question.options, answer })}
							</Typography>
						)}
					</>
				)
			}
			case questionTypes.MULTIPLE_SELECT: {
				return (
					<>
						{multipleSelectAnswer?.length === 0
							? NoResponseNode
							: getAnswerOptionLabels({ options: question.options, answer }).map(
									(stringAnswer, index) => <RichTextView key={index} content={stringAnswer} />,
							  )}
					</>
				)
			}
			default: {
				return null
			}
		}
	}

	return (
		<Box my={2}>
			{groupedQuestions.map((group, index) => {
				if (group.hidden) {
					return (
						<>
							{group.questions.map((question) => {
								const { _id: questionId } = question

								const answer =
									answersToQuestions &&
									answersToQuestions.find((answer) => answer.questionId === questionId)

								return (
									<>
										<Typography key={index} variant="h3XSmall">
											{index + 1}. {question.title}
										</Typography>
										<Box data-testid="questionnaire-answers" key={questionId} pb={1} mb={2}>
											<PrintOnly>
												<Typography variant="body2" fontStyle="italic" mt={1}>
													{question.description}
												</Typography>
											</PrintOnly>
											{answer ? renderAnswer(question, answer) : NoResponseNode}
											{answer?.comments && <RichTextView content={answer?.comments} />}
										</Box>
									</>
								)
							})}
						</>
					)
				}
				return (
					<>
						<Typography key={index} variant="h3XSmall">
							{index + 1}. {group.name}
						</Typography>
						<Box
							style={{ border: `1px solid ${theme.palette.grey500}` }}
							mt={1}
							mb={2}
							px={1}
							pt={2}
							display="flex"
							flexDirection="column"
							gap={theme.spacing(2)}
						>
							{group.questions.map((question, i) => {
								const { _id: questionId } = question

								const answer =
									answersToQuestions &&
									answersToQuestions.find((answer) => answer.questionId === questionId)

								return (
									<Box
										data-testid="questionnaire-answers"
										key={questionId}
										pb={i + 1 !== questions.length ? 1 : 0}
										mb={i + 1 !== questions.length ? 1 : 0}
										sx={{
											borderBottom:
												i + 1 !== group.questions.length
													? `1px solid ${theme.palette.form.borderResting}`
													: '',
										}}
									>
										{/* Question title (with prefix) */}
										<Typography variant="body1Semibold">
											{index + 1}.{i + 1}. {question.title}
										</Typography>
										<PrintOnly>
											<Typography variant="body2" fontStyle="italic" mt={1}>
												{question.description}
											</Typography>
										</PrintOnly>
										{answer ? renderAnswer(question, answer) : NoResponseNode}
										{answer?.comments && <RichTextView content={answer?.comments} />}
									</Box>
								)
							})}
						</Box>
					</>
				)
			})}
		</Box>
	)
}
