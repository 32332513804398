import responseStates from '../../../../common/data/rfp-proposal-states'

export const withdrawnOrRevised = (status) =>
	status === responseStates.WITHDRAWN || status === responseStates.REVISED

// if Show Withdrawn unselected filter out withdrawn proposals
export const filterWithdrawn = (responses) => {
	return responses.filter(({ status }) => !(status === responseStates.WITHDRAWN))
}

// if Show Revised unselected filter out revised proposals
export const filterRevised = (responses) => {
	return responses.filter(({ status }) => !(status === responseStates.REVISED))
}

// if Show Eliminated unselected filter out eliminated and not withdrawn or revised
// Eg. Finalized and eliminated wont be shown. Withdrawn and eliminated will be shown.
export const filterEliminated = (responses) => {
	return responses.filter(({ isEliminated, status }) => {
		return !(isEliminated && !withdrawnOrRevised(status))
	})
}
