import { format } from 'date-fns'
import { Box, RichTextView, Typography, Divider, FileDownloadList } from '@persuit/ui-components'
import { RfpClarification } from '../../../../types'

type UserClarificationItemProps = {
	clarification: RfpClarification
	clarificationNumber: number | null
}

export const UserClarificationItem = ({
	clarification,
	clarificationNumber,
}: UserClarificationItemProps) => {
	const { content, createdAt, files } = clarification

	return (
		<Box
			display="flex"
			flexDirection="column"
			p={1}
			border={`1px solid`}
			borderColor="form.borderResting"
			gap={1}
		>
			<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
				<Typography variant="h3XSmall">CLARIFICATION #{clarificationNumber}</Typography>
				<Typography variant="body2">Added: {format(createdAt, 'd LLLL yyyy, hh:mma')}</Typography>
			</Box>

			<Divider />
			<RichTextView data-testid="user-clarification" content={content ?? ''} />
			{files && files.length !== 0 ? (
				<FileDownloadList
					files={files}
					label={`Downloadable files for CLARIFICATION #${clarificationNumber ?? ''}`}
				/>
			) : null}
		</Box>
	)
}
