// @ts-strict-ignore
import {
	RfpProposalDetailDeliverablePricing as DeliverablePricing,
	RfpProposalDetailDeliverablePricingProps,
} from './rfp-proposal-detail-deliverable-pricing'
import { Box, Typography, useTheme } from '@persuit/ui-components'
const getRfprDeliverable = (rfpr?: any, deliverableId?: string) => {
	if (!rfpr || !rfpr.deliverables || !deliverableId) {
		return null
	}

	const foundRfprDeliverable = rfpr.deliverables.find(
		(rfprDeliverable) => deliverableId.toString() === rfprDeliverable.deliverableId.toString(),
	)

	return foundRfprDeliverable || null
}

export type DeliverableProps = {
	deliverable: any
	index: number
	currency: string
	phaseDuration: string
	rfpr?: any
	isProposalRevised: boolean
	responseToDeliverables: Array<
		RfpProposalDetailDeliverablePricingProps['responseToDeliverable'] & { deliverableId: string }
	>
}

export const Deliverable = ({
	deliverable,
	responseToDeliverables,
	isProposalRevised,
	currency,
	phaseDuration,
	rfpr,
	index,
}: DeliverableProps) => {
	const theme = useTheme()

	const responseToDeliverable = responseToDeliverables.find(
		(r) => r.deliverableId === deliverable._id,
	)

	return (
		<Box
			display="grid"
			gridTemplateRows="1fr"
			gridTemplateColumns="auto minmax(0, 1fr)"
			width="100%"
			gap={theme.spacing(1)}
		>
			<Box gridRow="1" gridColumn="2">
				<Typography variant="h3XSmall" gutterBottom={true}>
					<b>
						{index + 1}. {deliverable.deliverableTitle}
					</b>
				</Typography>

				<Box>
					{responseToDeliverable && (
						<DeliverablePricing
							isProposalRevised={isProposalRevised}
							responseToDeliverable={responseToDeliverable}
							deliverable={deliverable}
							currency={currency}
							phaseDuration={phaseDuration}
							index={index}
							rfpr={getRfprDeliverable(rfpr, responseToDeliverable.deliverableId)}
						/>
					)}
				</Box>
			</Box>
		</Box>
	)
}
