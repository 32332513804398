import { useState, useEffect } from 'react'
import isEqual from 'lodash/fp/isEqual'

import { purifyFileList, addFiles, removeFile, updateFiles, noop } from './file-mediator-utils'

export const useFileMediator = ({
	files: filesFromParent,
	sync = noop,
	receive = updateFiles,
} = {}) => {
	const [files, setFiles] = useState(purifyFileList(filesFromParent))

	const updateFn = receive({ files, setFiles })

	useEffect(() => {
		if (!isEqual(files, filesFromParent)) {
			updateFn(filesFromParent)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filesFromParent])

	return {
		fileList: files,
		addFiles: addFiles({ files, setFiles: updateFn, sync }),
		removeFile: removeFile({ files, setFiles: updateFn, sync }),
	}
}
