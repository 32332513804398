// @ts-strict-ignore
import { getOr, sortBy, reverse, omit, set } from 'lodash/fp'
import { gql, useQuery, NetworkStatus } from '@apollo/client'

const GET_TAXONOMY = gql`
	query getTaxonomy {
		getTaxonomyForAreaOfLaw {
			code
			name
			description
			parent
			level
			parentChain
		}
		getTaxonomyForLocation {
			code
			name
			description
			parent
			level
			parentChain
		}
		getTaxonomyForType {
			code
			name
			description
			parent
			level
			parentChain
		}
		getTaxonomyForService {
			code
			name
			description
			parent
			level
			parentChain
		}
		getTaxonomyForSourcing {
			code
			name
			description
			parent
			level
			parentChain
		}
	}
`

// Unflattening the taxonomies is very expensive, we only want to do this once (these
// will not change after being fetched).
// Using an in-mem cache to avoid recomputation on re-renders
const inMemoryCache = new Map()

const getChildToUnflatten = (unflattenedTaxonomy, itemToAdd) => {
	const unflattenedChild = unflattenedTaxonomy[itemToAdd.code]
	// If child not yet flattened, then return
	if (!unflattenedChild) {
		return { ...itemToAdd, children: [] }
	}

	// Otherwise combine the item to add with the info of the already
	// flattened child
	return { children: [], ...unflattenedChild, ...itemToAdd }
}

const addChildToParent = (parentOfChildToFlatten, childToUnflatten) => {
	const existingChildren = getOr([], 'children', parentOfChildToFlatten)
	return {
		...parentOfChildToFlatten,
		children: [...existingChildren, childToUnflatten],
	}
}

const unflattenTaxonomy = (flatTaxonomy) => {
	const flatTaxonomyByDepth = reverse(sortBy(({ level }) => level, flatTaxonomy))

	const fullTaxonomy = flatTaxonomyByDepth.reduce((acc, item) => {
		// Get the child to add
		const childToUnflatten = getChildToUnflatten(acc, item)

		// Detach child so that it can be moved to its new parent
		const taxonomyWithChildDetached = omit([childToUnflatten.code], acc)

		// Re-attach the child to its new parent
		const parentOfChildToFlatten = getOr({}, childToUnflatten.parent, taxonomyWithChildDetached)
		const taxonomyWithChildReattached = set(
			// Path to the parent
			[`${childToUnflatten.parent}`],
			// Updated parent (with updated children)
			addChildToParent(parentOfChildToFlatten, childToUnflatten),
			taxonomyWithChildDetached,
		)
		return taxonomyWithChildReattached
	}, {})

	// Get children of root node
	const rootNode = fullTaxonomy[Object.keys(fullTaxonomy)[0]]
	const unflattenedTaxonomy = getOr([], 'children', rootNode)

	return unflattenedTaxonomy
}

export const useTaxonomy = () => {
	const { data, networkStatus } = useQuery(GET_TAXONOMY)

	if (inMemoryCache.get('taxonomies')) {
		const taxonomies = inMemoryCache.get('taxonomies')
		return {
			fetchingTaxonomy: false,
			taxonomies,
		}
	}

	const flatTaxonomyForAreaOfLaw = getOr([], 'getTaxonomyForAreaOfLaw', data)
	const flatTaxonomyForLocation = getOr([], 'getTaxonomyForLocation', data)
	const flatTaxonomyForType = getOr([], 'getTaxonomyForType', data)
	const flatTaxonomyForService = getOr([], 'getTaxonomyForService', data)
	const flatTaxonomyForSourcing = getOr([], 'getTaxonomyForSourcing', data)

	const taxonomyForAreaOfLaw = unflattenTaxonomy(flatTaxonomyForAreaOfLaw)
	const taxonomyForLocation = unflattenTaxonomy(flatTaxonomyForLocation)
	const taxonomyForType = unflattenTaxonomy(flatTaxonomyForType)
	const taxonomyForService = unflattenTaxonomy(flatTaxonomyForService)
	const taxonomyForSourcing = unflattenTaxonomy(flatTaxonomyForSourcing)

	const taxonomies = {
		taxonomyForAreaOfLaw,
		taxonomyForLocation,
		taxonomyForType,
		taxonomyForService,
		taxonomyForSourcing,
	}

	if (data) {
		inMemoryCache.set('taxonomies', taxonomies)
	}

	return {
		fetchingTaxonomy: networkStatus === NetworkStatus.loading,
		taxonomies,
	}
}
