// @ts-strict-ignore
import { useMemo } from 'react'
import { graphql, useMutation } from '@persuit/ui-graphql'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { useSectionalContainer } from '../../sectional-form/use-sectional-container'
import { ScorecardForm } from '../../rfp-forms'
import { RfpTemplate } from '../types'
import { DeepPick, isNotNil } from '@persuit/common-utils'

const SAVE_SCORECARD = graphql(`
	mutation RfpTemplateScorecardSectionSave(
		$payload: RfpTemplateDraftScorecardInput!
		$validate: Boolean!
	) {
		saveRfpTemplateDraftScorecard(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type ScorecardSectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<RfpTemplate, '_id' | 'scoreCategories'>
	validationErrors: {
		scorecard?: {
			label: string | null
			index: number
			subcategories: {
				label: string
				index: number
			}[]
		}[]
	} | null
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const ScorecardSection = ({
	instanceId,
	rfpTemplate,
	validationErrors,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
}: ScorecardSectionProps) => {
	const [saveSectionMutation] = useMutation(SAVE_SCORECARD)
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const sectionErrors = validationErrors?.scorecard ?? null

	const sectionData = useMemo(
		() => ({
			scoreCategories:
				rfpTemplate?.scoreCategories
					?.filter(isNotNil)
					.map((cat) => ({ ...cat, subcategories: cat.subcategories?.filter(isNotNil) })) ?? [],
		}),
		[rfpTemplate],
	)

	if (loading) return <LoadingSpinner />

	const saveSection = async (formVariables) => {
		await saveSectionMutation({
			variables: {
				payload: {
					...formVariables,
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	return (
		<>
			<PageTitle>Editing scorecard settings of a template</PageTitle>
			<ScorecardForm
				saveSection={saveSection}
				sectionData={sectionData}
				sectionErrors={sectionErrors}
			/>
		</>
	)
}
