import { useState, useEffect, useCallback, useRef } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { LDFlagSet, LDContextCommon } from 'launchdarkly-js-client-sdk'
import { useHandleUnexpectedError, useIsFirstRender, useLocalStorage } from '@persuit/ui-hooks'
import { ANONYMOUS_USER_SHARED_KEY } from '../hoc'

export const STOP_POLLING_THRESHOLD = 3000
const POLLING_INTERVAL = 10

let serverToggles: LDFlagSet = {}
const serverTogglesPromise = getServerFeatureToggles().then((toggles) => {
	serverToggles = toggles
})

type Mode = 'realtime' | 'on-refresh'

export type UseFeatureTogglesConfig = {
	/**
	 * Realtime: subscribe to live toggles updates, use this mode when you want users to see toggles change immediately.
	 *
	 * On-refresh: only update changed toggles after the component unmount/remount,
	 * use this mode when you want to delay toggles change to avoid disrupting current user action.
	 *
	 * Default to realtime */
	mode?: Mode
	/**
	 * Whether or not this feature toggle runs in an authenticated context.
	 * If so, the loading flag will remain `true` until the client user has been initialized.
	 * This prevents double renders of the ANONYMOUS user toggles and the authenticated user toggles.
	 */
	auth?: boolean
}

export type Toggles = {
	[key: string]: any
	'dev-10144.named-timekeepers': boolean
	'dev-10954.multi-currency': boolean
	'dev-5192.user-access-management': boolean
	'operational.reachable-urls-check-list': string
	'dev-5189.client-estimated-savings': boolean
	'dev-5754.company-rate-review': boolean
	'dev-5915-team-performance-benchmarking': boolean
	'dev-8628.rate-review-1.1': boolean
	'dev-7712-should-cost': boolean
	'operation.persuit-academy'?: {
		enabled: boolean
		triggerBtnText?: string
		triggerBtnUrl?: string
	}
	'dev-9900.rate-card-auction-improvements': boolean
}

export type UseFeatureTogglePayload = {
	loading: boolean
	toggles: Toggles
}

/**
 * This hook returns all feature toggles available.
 *
 * You will need to wrap your app using withFeatureToggleProvider HOC for this hook to work.
 */
export function useFeatureToggles({
	mode = 'realtime',
	auth = true,
}: UseFeatureTogglesConfig = {}): UseFeatureTogglePayload {
	const [loadingToggles, setLoadingToggles] = useState(true)
	const [toggles, setToggles] = useState<LDFlagSet>({})
	const [_, setServerToggles] = useState<LDFlagSet>(serverToggles)
	const client = useLDClient()
	const contextToggles = useFlags()
	const isFirstRender = useIsFirstRender()
	const [override] = useLocalStorage('override-toggles', {})
	const clientContext = client?.getContext()
	const userKey = useRef<string | LDContextCommon | undefined>()
	const handleUnexpectedError = useHandleUnexpectedError()

	const setFlagsCallback = useCallback(() => {
		setLoadingToggles(false)
		setToggles({
			// Given context toggles is a proxy, cloning to avoid proxy update
			...contextToggles,
		})
	}, [setToggles, contextToggles])

	useEffect(() => {
		if (mode === 'on-refresh') {
			client?.waitForInitialization().then(() => {
				if (isFirstRender === true) {
					setFlagsCallback()
				}
			})
		} else if (mode === 'realtime' && contextToggles && clientContext?.key) {
			setLoadingToggles(false)
		}
	}, [
		client,
		setFlagsCallback,
		contextToggles,
		setToggles,
		mode,
		isFirstRender,
		clientContext?.key,
	])

	useEffect(() => {
		serverTogglesPromise
			.then(() => {
				// This will trigger the component to rerender if the promise
				// happens to resolve after the component has rendered
				setServerToggles(serverToggles)
			})
			.catch(handleUnexpectedError)
	}, [handleUnexpectedError])

	// Set flags callback for on-refresh mode if user identity changed
	useEffect(() => {
		if (clientContext?.key !== userKey.current && mode === 'on-refresh') {
			userKey.current = clientContext?.key
			setFlagsCallback()
		}
	}, [userKey, clientContext, setFlagsCallback, mode])

	const upstreamToggles = mode === 'realtime' ? contextToggles : toggles

	const getUserLoaded = useCallback(() => {
		const clientContext = client?.getContext()
		return auth
			? clientContext && clientContext?.key !== ANONYMOUS_USER_SHARED_KEY
			: !!clientContext?.key
	}, [client, auth])
	const [userLoaded, setUserLoaded] = useState(getUserLoaded())
	const [userFailedToLoad, setUserFailedToLoad] = useState(false)

	useEffect(() => {
		if (userLoaded || !auth) return

		const intervalId = setInterval(() => checkUserState(), POLLING_INTERVAL)
		const cleanupInterval = () => clearInterval(intervalId)

		const start = Date.now()
		function checkUserState() {
			const now = Date.now()
			const pageLoaded = now - start > STOP_POLLING_THRESHOLD
			if (pageLoaded) {
				setUserFailedToLoad(true)
				cleanupInterval()
			}

			const userLoaded = getUserLoaded()

			if (userLoaded) {
				setUserLoaded(true)
			}
		}

		return () => cleanupInterval()
	}, [getUserLoaded, userLoaded, auth])

	if (
		!auth &&
		clientContext &&
		clientContext?.key !== ANONYMOUS_USER_SHARED_KEY &&
		process.env.NODE_ENV === 'development'
	) {
		console.warn(
			'useFeatureToggle(): auth option is currently set to false but a logged in user has been detected. You may want to remove auth=false or set auth=true to prevent double rendering.',
		)
	}

	return {
		/**
		 * Loading if the toggles haven't loaded or the user hasn't loaded
		 * If the user hasn't loaded after a reasonable amount of time we can ignore it
		 */
		loading: auth
			? loadingToggles || (userFailedToLoad ? false : !userLoaded) // consider toggles and user
			: loadingToggles, // only consider toggles being loaded
		toggles: {
			...upstreamToggles,
			...serverToggles,
			...override,
		} as Toggles,
	}
}

async function getServerFeatureToggles(): Promise<LDFlagSet> {
	if (process.env.NODE_ENV !== 'development') {
		return {}
	}

	// No need to put this in a try/catch because webpack blows up the
	// whole app if the import fails. The nom prepare script ensures that
	// the file exists, so we shouldn't get a failure here unless the file
	// isn't valid JSON
	const toggles = await import('../../../../feature-toggle.json')

	return Object.fromEntries((toggles.toggles ?? []).map(({ key, value }) => [key, value]))
}
