import { useEffect, useId } from 'react'
import moment, { MomentInput } from 'moment'
import {
	styled,
	LocalOfferIcon,
	GavelIcon,
	BlockIcon,
	CloseIcon,
	AssignmentIcon,
	Stepper,
	Step,
	StepLabel,
	StepConnector,
	useTheme,
} from '@persuit/ui-components'

import useForceUpdate from '@client/custom-hooks/force-update-hook'

import { getActiveTimelineDate } from './get-active-timeline-date'

const Root = styled('div')`
	width: 100%;
	text-align: center;
	display: table;
	margin: 0 auto;
`

const StyledStepConnector = styled(StepConnector)(
	({ theme }) => `
	&.MuiStepConnector-alternativeLabel {
		top: 10px;
		left: calc(-50% + 16px);
		right: calc(50% + 16px);
	}

	&.Mui-active .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
		border-color: ${theme.palette.primary.darkHue};
	}

	&.Mui-completed .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
		border-color: ${theme.palette.primary.darkHue};
	}

	&.MuiStepConnector-root > .MuiStepConnector-line {
		border-color: ${theme.palette.grey300};
		border-top-width: 3px;
		border-radius: 50px;
	}
`,
)

const StyledStep = styled(Step)(
	({ theme }) => `
	&.MuiStep-root .Mui-disabled .MuiSvgIcon-root {
		fill: ${theme.palette.grey400};
	}
`,
)

const CustomCloseIcon = () => <CloseIcon style={{ color: 'red' }} />

type HorizontalLinearStepperProps = {
	active: number
	disabledStep?: number
	steps: MomentInput[]
}

function HorizontalLinearStepper({ active, disabledStep, steps }: HorizontalLinearStepperProps) {
	const stepsUI = [
		{
			label: 'Request published',
			disabledIcon: CustomCloseIcon,
			icon: AssignmentIcon,
		},
		{
			label: 'Proposals due',
			icon: LocalOfferIcon,
		},
		{
			label: 'Auction start',
			icon: GavelIcon,
		},
		{
			label: 'Auction end',
			icon: BlockIcon,
		},
	]

	const dateFormat = 'LLL'

	const theme = useTheme()
	const id = useId()

	return (
		<Root>
			<Stepper
				role="list"
				aria-label="Auction Progress"
				activeStep={active}
				alternativeLabel={true}
				connector={<StyledStepConnector />}
				tabIndex={-1}
			>
				{stepsUI.map((stepUI, idx) => {
					const disabled = disabledStep === 0
					return (
						<StyledStep
							disabled={disabled ? true : undefined}
							completed={disabled ? false : undefined}
							role="listitem"
							key={`step-${idx}-${id}`}
							aria-labelledby={`step-label-${idx}-${id}`}
							aria-current={active === idx ? 'true' : 'false'}
						>
							<StepLabel
								StepIconComponent={disabled ? stepUI.disabledIcon : stepUI.icon}
								StepIconProps={{
									sx: !disabled
										? {
												fill: theme.palette.primary.darkHue,
										  }
										: undefined,
								}}
							>
								<div id={`step-label-${idx}-${id}`}>{stepUI.label}</div>
								{disabled ? (
									'NOT SET'
								) : (
									<span>{moment(steps[idx]).format(dateFormat).toString()}</span>
								)}
							</StepLabel>
						</StyledStep>
					)
				})}
			</Stepper>
		</Root>
	)
}

export type TimelineStepperProps = {
	requestStatus: string
	requestPublishedAt: MomentInput
	initialProposalsDueBy: MomentInput
	auctionStart: MomentInput
	auctionEnd: MomentInput
}

export const TimelineStepper = ({
	requestStatus,
	requestPublishedAt,
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
}: TimelineStepperProps) => {
	const forceUpdate = useForceUpdate()

	useEffect(() => {
		setTimeout(() => {
			forceUpdate()
		}, 1000)
	})

	return (
		<HorizontalLinearStepper
			active={getActiveTimelineDate(
				requestPublishedAt,
				initialProposalsDueBy,
				auctionStart,
				auctionEnd,
				requestStatus,
			)}
			steps={[requestPublishedAt, initialProposalsDueBy, auctionStart, auctionEnd]}
		/>
	)
}
