import { calculateTimekeeperCell, calculateFirmWeightedAverage, RateCard } from '../calculations'

import { Proposal } from '../../store'

import { RowData } from './generate-row-data'

type CalculateRowDataInput = {
	proposal: Proposal
	rateCards: RateCard[]
	// List of rate card lists from oldest to newest
	revisedRateCards?: RateCard[][]
	timekeepers: { _id?: string | null }[]
}

export function calculateRowData({
	proposal,
	rateCards,
	revisedRateCards = [],
	timekeepers: tks,
}: CalculateRowDataInput): RowData {
	const timekeepers = (tks ?? []).map((tk) => {
		const tkAvgs = calculateTimekeeperCell(tk._id ?? '', rateCards)

		const revisedAvgs = revisedRateCards
			.map((rateCards) => calculateTimekeeperCell(tk._id ?? '', rateCards))
			// find the first set of revisions that changes the proposed rate
			.find((calc) => calc.proposedRate && calc.proposedRate !== tkAvgs.proposedRate)

		return {
			timekeeper: tk._id ?? null,
			revisedRate: revisedAvgs?.proposedRate ?? null,
			revisedDelta: revisedAvgs?.delta ?? null,
			currentRate: tkAvgs.currentRate,
			proposedRate: tkAvgs.proposedRate,
			delta: tkAvgs.delta,
		}
	})

	return {
		id: proposal.org?._id ?? '',
		firmId: proposal.org?._id ?? '',
		isAverageRow: false,
		firmName: proposal.org?.name ?? '',
		weightedAverage: calculateFirmWeightedAverage(proposal.rateCards),
		timekeepers,
	}
}
