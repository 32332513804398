import { gql, useMutation } from '@apollo/client'
import { styled, useSnackbar } from '@persuit/ui-components'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils.js'

const UNELIMINATE_FIRM_MUTATION = gql`
	mutation uneliminateFirm($payload: UneliminateInput!) {
		uneliminateFirm(payload: $payload) {
			_id
		}
	}
`

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

const bullets = [
	'Firm status will no longer be unsuccessful.',
	'You will be able to request a revision or select this firm.',
	'The firm will be notified.',
	'Remaining firm(s) will not be notified.',
]

function OptionUneliminateFirmConfirm({
	onCancel,
	onDone,
	onPrev,
	firmId,
	firmName,
	requestId,
	fetchResponses,
}) {
	const [uneliminateFirm, { loading, error }] = useMutation(UNELIMINATE_FIRM_MUTATION)
	const { openSnackbar } = useSnackbar()
	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Undo "Unsuccessful" Status',
			}}
			isWorking={loading}
			onConfirm={async () => {
				await uneliminateFirm({
					variables: {
						payload: { requestId, orgId: firmId },
					},
				})
				openSnackbar(`Successfully changed status of ${firmName}`)
				onDone()
				fetchResponses() // Reload isEliminated value from resolver
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Are you sure you want to undo "unsuccessful" status?</strong>
			<StyledList>
				{bullets.map((bullet, i) => (
					<li key={i}>
						<Text component="p" type="body1" black={true}>
							{bullet}
						</Text>
					</li>
				))}
			</StyledList>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionUneliminateFirmConfirm
