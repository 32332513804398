import { memo } from 'react'
import { Box, Typography } from '@persuit/ui-components'
import { Cell, CellProps } from '../../components'
import { comparisonValuePresentaionData } from '../../../presentation-data'

type ComparisonValueCellProps = CellProps & {
	currency: string
	response: {
		status: string
		comparisonValue: string
		originalComparisonValue?: string | null
	}
}

const ComparisonValueCell = ({ currency, response, ...cellProps }: ComparisonValueCellProps) => {
	const { originalValue, currentValue } = comparisonValuePresentaionData(response, currency)

	return (
		<Cell
			{...cellProps}
			sx={{
				...cellProps.sx,
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				alignItems: 'center',
				textAlign: 'center',
				justifyContent: 'center',
			}}
		>
			<Box>
				<Box>
					{/* Comparison Value */}
					<Typography variant="h3Large" fontWeight={400} component="p" color="text.secondary">
						{currentValue}
					</Typography>

					{/* Original comparison value (if present) */}
					{originalValue && (
						<Typography
							variant="body1"
							fontWeight={400}
							strikeThrough={true}
							color="text.secondary"
						>
							{originalValue}
						</Typography>
					)}
				</Box>

				<Box sx={{ flex: '1 1 auto' }} />
			</Box>
		</Cell>
	)
}

export default memo(ComparisonValueCell) as any
