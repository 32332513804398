import { useRef, useEffect, Fragment } from 'react'
import {
	ChannelTypes,
	MessagesChannelPaneMessageListChannelFragment,
} from '@persuit/ui-graphql/generated'
import { HumanMessage } from './human-message'
import { Box, Divider, Typography } from '@persuit/ui-components'
import { SystemMessage } from './system-message'
import { getFragment, graphql, FragmentType, useMutation } from '@persuit/ui-graphql'
import { makeFormatMessageAtSafe } from '../../util'

const formatMessageAtSafe = makeFormatMessageAtSafe('MMM d, y')
const formatBotMessageAtSafe = makeFormatMessageAtSafe('h:mm a')

const ALLOWED_SYSTEM_MESSAGES = new Set([
	'comments.system.rfp.client.rfpColleagueRemoved',
	'comments.system.rfp.client.rfpColleagueAdded',
])

const MESSAGE_LIST_CHANNEL_FRAGMENT = graphql(`
	fragment MessagesChannelPaneMessageListChannel on Channel {
		id
		type
		members {
			userId
			orgId
		}
		messages {
			id
			isBot
			botMessage {
				translationKey
			}
			messageAt
			createdBy {
				userId
			}
			...MessagesChannelPaneHumanMessage
			...MessagesChannelPaneSystemMessage
		}
	}
`)

const MESSAGE_LIST_SESSION_FRAGMENT = graphql(`
	fragment MessagesChannelPaneMessageListSession on SessionInfo {
		user {
			_id
			org {
				_id
			}
		}
		isImpersonated
	}
`)

const UPDATE_CHANNEL_READ_DATA = graphql(`
	mutation MessagingChannelUpdateChannelReadData($channelId: ID!) {
		updateChannelReadData(channelId: $channelId) {
			success
		}
	}
`)

type Side = 'left' | 'right'

type UserLookup = Record<string, { side: Side; currentOrg: boolean; currentUser: boolean }>

export const getUserSide = (
	channelType: ChannelTypes,
	currentOrg: boolean,
	currentUser: boolean,
): Side => {
	if (currentUser) return 'right'
	if (channelType === 'CLIENT_TO_FIRM_CHANNEL' && currentOrg) return 'right'
	return 'left'
}

export const getUserLookup = (
	channelType: MessagesChannelPaneMessageListChannelFragment['type'],
	channelMembers: MessagesChannelPaneMessageListChannelFragment['members'],
	currentOrgId: string,
	currentUserId: string,
): UserLookup => {
	return channelMembers.reduce<UserLookup>((acc, channelMember) => {
		if (!channelMember) return acc
		const currentOrg = channelMember.orgId === currentOrgId
		const currentUser = channelMember.userId === currentUserId
		return {
			...acc,
			[channelMember.userId]: {
				currentOrg,
				currentUser,
				side: getUserSide(channelType, currentOrg, currentUser),
			},
		}
	}, {})
}

export const groupMessages = (
	messages: MessagesChannelPaneMessageListChannelFragment['messages'],
	showSystemMessages: boolean,
) => {
	return messages.reduce<Record<string, MessagesChannelPaneMessageListChannelFragment['messages']>>(
		(acc, message) => {
			if (!message) return acc

			// If it is a bot message and it is not on the allowed list and we have bot messages turned off, skip
			if (
				message.isBot &&
				!ALLOWED_SYSTEM_MESSAGES.has(message?.botMessage?.translationKey ?? '') &&
				!showSystemMessages
			)
				return acc

			const day = formatMessageAtSafe(message.messageAt)

			if (acc[day]) return { ...acc, [day]: [...acc[day], message] }
			return { ...acc, [day]: [message] }
		},
		{},
	)
}

export type MessageListProps = {
	channel: FragmentType<typeof MESSAGE_LIST_CHANNEL_FRAGMENT>
	session: FragmentType<typeof MESSAGE_LIST_SESSION_FRAGMENT>
	showSystemMessages: boolean
}

export const MessageList = ({ channel, session, showSystemMessages }: MessageListProps) => {
	const {
		messages,
		type,
		members,
		id: channelId,
	} = getFragment(MESSAGE_LIST_CHANNEL_FRAGMENT, channel)
	const { user, isImpersonated } = getFragment(MESSAGE_LIST_SESSION_FRAGMENT, session)
	const [updateChannelReadDataMutation] = useMutation(UPDATE_CHANNEL_READ_DATA, {
		variables: { channelId },
	})

	const currentUserId = user?._id ?? ''
	const currentOrgId = user?.org?._id ?? ''
	const userLookup = getUserLookup(type, members, currentOrgId, currentUserId)
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		ref.current?.scrollIntoView()
		if (!isImpersonated) updateChannelReadDataMutation()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages])

	const groupedMessages = groupMessages(messages, showSystemMessages)

	if (!Object.keys(groupedMessages)?.length)
		return (
			<Box p={2} width="100%" textAlign="center">
				<Typography>
					<i>No messages</i>
				</Typography>
			</Box>
		)

	return (
		<Box display="flex" flexDirection="column" p={2} role="list" aria-label="messages">
			{Object.keys(groupedMessages).map((day) => {
				return (
					<Fragment key={day}>
						<Box
							display="grid"
							gridTemplateRows="auto"
							gridTemplateColumns="1fr auto 1fr"
							alignItems="center"
							gap={2}
							role="listitem"
							mt={2}
						>
							<Divider sx={{ bgcolor: 'text.secondary' }} />
							<Typography variant="body2" color="text.secondary">
								{day}
							</Typography>
							<Divider sx={{ bgcolor: 'text.secondary' }} />
						</Box>
						{groupedMessages[day].map((message, index) => {
							if (!message) return null

							if (message.isBot) {
								const isPrevBot = index > 0 && groupedMessages?.[day]?.[index - 1]?.isBot
								const prevBotTime =
									isPrevBot &&
									formatBotMessageAtSafe(groupedMessages?.[day]?.[index - 1]?.messageAt)
								const botTime = formatBotMessageAtSafe(message?.messageAt)
								const showTime = !(prevBotTime === botTime)
								return <SystemMessage message={message} key={message.id} showTime={showTime} />
							}

							const { side, currentOrg, currentUser } = userLookup?.[
								message?.createdBy?.userId ?? ''
							] ?? {
								side: 'left',
								currentOrg: false,
								currentUser: false,
							}

							return (
								<HumanMessage
									key={message.id}
									message={message}
									side={side}
									currentOrg={currentOrg}
									currentUser={currentUser}
								/>
							)
						})}
					</Fragment>
				)
			})}
			<div ref={ref} />
		</Box>
	)
}
