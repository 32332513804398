import { createContext, useState, useContext, ReactNode } from 'react'
import { SearchResult } from './all-contacts-form/people-search-list-item'
import { useToggleFavorites } from '../../../containers/sharing/use-toggle-favourites'
import { Person, KnownPerson, UnknownPerson } from './types'

type InviteContextType = {
	onAdd: (users: (SearchResult | Person | string)[]) => Promise<void>
	onRemove: (userIdsToRemove: string[]) => Promise<void>
	usersToAdd: (Person | SearchResult | string)[]
	setUsersToAdd: (user: (Person | SearchResult)[]) => void
	isDraftRfp: boolean
	addToFavourites: (userId: any) => Promise<void>
	selectedUsers: (KnownPerson | UnknownPerson)[]
}

const InviteContext = createContext<InviteContextType | undefined>(undefined)

export const useInviteContext = () => {
	const context = useContext(InviteContext)
	if (context === undefined) {
		throw new Error('useInviteContext must be used within an InviteProvider')
	}
	return context
}

interface InvitesProviderProps {
	children: ReactNode
	onAdd: (people: (SearchResult | string | Person)[]) => Promise<void>
	onRemove: (userIdsToRemove: string[]) => Promise<void>
	isDraftRfp: boolean
	selectedUsers: (KnownPerson | UnknownPerson)[]
}

export const InviteProvider = ({
	children,
	onAdd,
	onRemove,
	isDraftRfp,
	selectedUsers,
}: InvitesProviderProps) => {
	const [usersToAdd, setUsersToAdd] = useState<Array<SearchResult | Person | string>>([])
	const { toggleFavourite: addToFavourites } = useToggleFavorites()

	const handleAddWrapper = async (users: (SearchResult | Person | string)[]) => {
		const getId = (user: SearchResult | Person | KnownPerson | UnknownPerson | string) => {
			if (typeof user === 'string') {
				return user
			} else {
				return user._id
			}
		}

		if (!isDraftRfp) {
			setUsersToAdd((prevUsers) => {
				const prevUserIds = new Set(prevUsers.map(getId))
				const selectedUserIds = new Set(selectedUsers.map(getId))

				const newUsers = users.filter((user) => {
					const userId = getId(user)
					return !prevUserIds.has(userId) && !selectedUserIds.has(userId)
				})
				return [...prevUsers, ...newUsers]
			})
		} else {
			await onAdd(users)
		}
	}

	const handleRemoveWrapper = async (userIdsToRemove: string[]) => {
		if (!isDraftRfp) {
			setUsersToAdd((prevUsers) =>
				prevUsers.filter((u) => {
					if (typeof u === 'string') {
						return !userIdsToRemove.includes(u)
					}
					return !userIdsToRemove.includes(u._id)
				}),
			)
		} else {
			await onRemove(userIdsToRemove)
		}
	}

	return (
		<InviteContext.Provider
			value={{
				onAdd: handleAddWrapper,
				onRemove: handleRemoveWrapper,
				usersToAdd,
				setUsersToAdd,
				isDraftRfp,
				addToFavourites,
				selectedUsers,
			}}
		>
			{children}
		</InviteContext.Provider>
	)
}
