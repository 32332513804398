import { Box, Typography, useTheme, WarningIcon } from '@persuit/ui-components'
import { Delta } from '../delta'
import { useStore } from '../../store'
import { isEmpty, omit } from 'lodash/fp'
import { getTotalCurrentPrice } from '../../utils'
import { useDebounce } from '@persuit/ui-hooks'

export const PhaseDeltaSection = () => {
	const theme = useTheme()

	const {
		pricingItemFormState,
		totalPriceValue,
		pricingItemLookup,
		originalTotalPrice,
		currency,
		totalPriceNotRequired,
	} = useStore(
		({
			pricingItemFormState,
			totalPriceValue,
			pricingItemLookup,
			proposal: { totalPriceValue: originalTotalPrice },
			currency,
			totalPriceNotRequired,
		}) => ({
			pricingItemFormState,
			totalPriceValue,
			pricingItemLookup,
			originalTotalPrice: originalTotalPrice ?? 0,
			currency,
			totalPriceNotRequired,
		}),
	)

	const originalItemsTotalPrice = getTotalCurrentPrice(pricingItemLookup)
	const newItemsTotalPrice = isEmpty(pricingItemFormState)
		? originalItemsTotalPrice
		: Object.values(omit('totalPriceValue', pricingItemFormState)).reduce(
				(acc, value) => acc + value,
				0,
		  )

	const totalDiff = originalTotalPrice - totalPriceValue
	const itemDiff = originalItemsTotalPrice - newItemsTotalPrice
	const showRecommendation = useDebounce(
		!totalPriceNotRequired && !isEmpty(pricingItemLookup) && totalDiff !== itemDiff,
		1000,
	)

	return (
		<Box
			display="flex"
			flexDirection="row"
			borderTop={`1px solid ${theme.palette.divider}`}
			paddingTop={2}
			paddingBottom={1}
			justifyContent="space-between"
			alignItems="center"
		>
			<Box
				display="grid"
				gridTemplateColumns="auto auto"
				gridTemplateRows="auto auto"
				alignItems="center"
				gap={theme.spacing(1)}
				flexShrink={0}
			>
				{!totalPriceNotRequired && Boolean(originalTotalPrice) && (
					<>
						<Box gridColumn={1} gridRow={1}>
							<Typography>Total price change:</Typography>
						</Box>
						<Box gridColumn={2} gridRow={1} data-testid="total-price-change">
							<Delta
								newNumber={totalPriceValue}
								originalNumber={originalTotalPrice}
								currency={currency}
								showZero={true}
							/>
						</Box>
					</>
				)}
				{!isEmpty(pricingItemLookup) && (
					<>
						<Box gridColumn={1} gridRow={2}>
							<Typography>Individual pricing items change:</Typography>
						</Box>
						<Box gridColumn={2} gridRow={2} data-testid="individual-pricing-item-change">
							<Delta
								newNumber={newItemsTotalPrice}
								originalNumber={originalItemsTotalPrice}
								currency={currency}
								showZero={true}
							/>
						</Box>
					</>
				)}
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				gap={theme.spacing(1)}
				paddingTop={0.5}
				paddingBottom={0.5}
				paddingLeft={1}
				paddingRight={1}
				border={`1px solid ${theme.palette.warning.light}`}
				borderRadius="5px"
				bgcolor={theme.palette.warning.lightestHue}
				color={theme.palette.warning.contrastText}
				alignItems="center"
				role="status"
				visibility={showRecommendation ? 'inherit' : 'hidden'}
			>
				{showRecommendation && (
					<>
						<WarningIcon
							color="inherit"
							sx={{
								color: 'warning.darkHue',
							}}
						/>
						<Typography variant="body2" color="warning.darkHue">
							We recommend keeping pricing item and total price changes synchronized.
						</Typography>
					</>
				)}
			</Box>
		</Box>
	)
}
