import { gql, useMutation } from '@apollo/client'
import addUsertoFavouritesMutation from '../../graphql/mutations/addUserToFavourites-gql'

/**
 * Toggles whether the given user is in our favourites list
 */
export function useToggleUserInFavourites() {
	// Although it says "add" user, it actually toggles that user
	const [addUserToFavourites] = useMutation(addUsertoFavouritesMutation)

	/**
	 * @param userId The ID of the user to toggle
	 */
	return async function ({ userId }) {
		return addUserToFavourites({
			variables: { userId },
			update: (proxy, { data: { addUserToFavourites } }) => {
				const isFavourite = addUserToFavourites.favourites.includes(userId)

				proxy.writeFragment({
					id: `User:${userId}`,
					fragment: gql`
						# eslint-disable @graphql-eslint/no-unused-fragments
						fragment UpdateUser on User {
							isFavourite
						}
					`,
					data: { isFavourite },
				})
			},
		})
	}
}
