import { useState, useEffect, Fragment, useId } from 'react'
import { isEmpty } from 'lodash/fp'

import { ViewAssumptionDialog } from './view-assumption-dialog'
import { RichTextEditor, Box, Button, Typography, Spacer } from '@persuit/ui-components'

type AssumptionsEditProps = {
	pricingTitle: string
	currentAssumptions: string
	suggestedAssumptions: string
	onSuggestedAssumptionUpdated: (content: string) => void
}

export const AssumptionsEdit = ({
	pricingTitle,
	currentAssumptions,
	suggestedAssumptions,
	onSuggestedAssumptionUpdated,
}: AssumptionsEditProps) => {
	const [viewDialogIsOpen, setViewOpen] = useState(false)
	// initialise suggestedAssumptions first on client suggestion if present otherwise use firms comment as default
	const [unSavedSuggestedAssumptions, setUnSavedSuggestedAssumptions] = useState(
		isEmpty(suggestedAssumptions) ? currentAssumptions : suggestedAssumptions,
	)

	// To update unSavedSuggestedAssumptions state value when a new value for suggestedAssumptions prop is passed
	useEffect(() => {
		setUnSavedSuggestedAssumptions(
			isEmpty(suggestedAssumptions) ? currentAssumptions : suggestedAssumptions,
		)
	}, [currentAssumptions, suggestedAssumptions])

	const handleViewOpen = () => setViewOpen(true)
	const handleViewClose = () => setViewOpen(false)

	// show clients suggestion if it differs from firms comment
	const showSuggested = unSavedSuggestedAssumptions !== currentAssumptions

	const handleEditSave = () => {
		onSuggestedAssumptionUpdated(unSavedSuggestedAssumptions)
	}

	const assumptionId = useId()
	const label = 'Assumptions & description'
	return (
		<Box>
			<Typography variant="h4">{label}</Typography>
			<Spacer space={1} />
			{showSuggested && (
				<Fragment>
					<Button onClick={handleViewOpen} variant="outlined">
						View firm's assumptions & description
					</Button>
					<Spacer space={1} />
				</Fragment>
			)}
			<Box
				sx={{
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					backgroundColor: showSuggested ? '#FCEAF5' : undefined,
					p: 1,
				}}
			>
				<RichTextEditor
					id={assumptionId}
					name={pricingTitle}
					hideLabel={true}
					label={`${label} of ${pricingTitle}`}
					height={300}
					value={unSavedSuggestedAssumptions}
					onChange={setUnSavedSuggestedAssumptions}
					onBlur={handleEditSave}
				/>
			</Box>
			{/* show original dialog */}
			<ViewAssumptionDialog
				currentAssumptions={currentAssumptions}
				isOpen={viewDialogIsOpen}
				handleViewClose={handleViewClose}
			/>
		</Box>
	)
}
