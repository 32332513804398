import ReactGA from 'react-ga4'

type GAInput = {
	/** page or feature that fires the event e.g CLIENT_DASHBOARD */
	page: string
	/** type of event that's fired e.g. SHARE_BUTTON_CLICKED */
	event: string
	/** category of the event fired e.g. FEATURE_USAGE */
	label: string
}

export const gaInititalize = () => {
	if (window.gATrackingId) {
		ReactGA.initialize(window.gATrackingId)
		ReactGA.isInitialized = true
	}
}

export const ga = ({ page, event, label }: GAInput) => {
	if (ReactGA.isInitialized) {
		ReactGA.event({ category: page, action: event, label })
	}
}
