import { StyledContainer } from '../styled-container'
import { Typography, Box, useTheme, FormNumberFieldCleave } from '@persuit/ui-components'
import { getPricingModelString } from 'packages/ui-shared-components/src/common-util'
import { formatPriceWithCurrency, getCurrencySymbol } from '@persuit/common-utils'
import { Delta } from '../delta'
import { useStore } from '../../store'

export type TotalPriceSectionProps = {
	editable?: boolean
}

export const TotalPriceSection = ({ editable = true }: TotalPriceSectionProps) => {
	const theme = useTheme()

	const {
		totalPriceModel,
		totalPriceValue,
		originalTotalPriceValue,
		currency,
		totalPriceNotRequired,
	} = useStore(
		(
			{ proposal: { totalPriceModel, totalPriceValue }, currency, totalPriceNotRequired },
			selectors,
		) => ({
			totalPriceModel: totalPriceModel || '',
			totalPriceValue: selectors.currentTotalPriceValue(),
			originalTotalPriceValue: totalPriceValue ?? 0,
			currency,
			totalPriceNotRequired,
		}),
	)

	if (totalPriceNotRequired || !originalTotalPriceValue) {
		return null
	}

	return (
		<StyledContainer presentationOnly={true}>
			<Typography variant="h3XSmall">Total price</Typography>
			<Box role="grid" aria-label="Total price">
				<Box
					display="grid"
					gridTemplateRows="auto auto"
					gridTemplateColumns="4fr 4fr 4fr 12fr"
					alignItems="center"
					role="row"
				>
					<Box gridRow={1} gridColumn={1} role="rowheader">
						<Typography variant="body2" color="text.secondary">
							Fee type
						</Typography>
					</Box>
					<Box gridRow={1} gridColumn={2} role="rowheader">
						<Typography variant="body2" color="text.secondary">
							Current Price
						</Typography>
					</Box>
					<Box gridRow={1} gridColumn={3} role="rowheader">
						<Typography variant="body2" color="text.secondary">
							Updated Price
						</Typography>
					</Box>
				</Box>
				<Box
					display="grid"
					gridTemplateRows="auto auto"
					gridTemplateColumns="4fr 4fr 4fr 12fr"
					alignItems="center"
					role="row"
				>
					<Box gridRow={2} gridColumn={1} role="cell" aria-label="Total price model">
						<Typography>{getPricingModelString(totalPriceModel)}</Typography>
					</Box>

					<Box gridRow={2} gridColumn={2} role="cell" data-testid="current-price">
						<Typography color="textSecondary">
							{formatPriceWithCurrency(currency, originalTotalPriceValue)}
						</Typography>
					</Box>
					<Box
						gridRow={2}
						gridColumn={3}
						display="flex"
						alignItems="center"
						gap={theme.spacing(0.5)}
						role="cell"
						data-testid="updated-price"
					>
						{editable ? (
							<>
								<Typography>{getCurrencySymbol(currency)}</Typography>
								<FormNumberFieldCleave
									name={'totalPriceValue'}
									id={'totalPriceValue'}
									label=""
									placeholder="Total price"
									decimalScale={4}
									inputProps={{
										'aria-label': 'Total price',
									}}
									emptyValue={totalPriceValue}
									replaceZero={true}
								/>
								<Typography>{currency}</Typography>
							</>
						) : (
							<Typography color="text.secondary">
								{formatPriceWithCurrency(currency, totalPriceValue)}
							</Typography>
						)}
					</Box>
					<Box gridRow={2} gridColumn={4} role="cell" data-testid="pricing-change-delta">
						<Delta
							newNumber={totalPriceValue}
							originalNumber={originalTotalPriceValue}
							currency={currency}
						/>
					</Box>
				</Box>
			</Box>
		</StyledContainer>
	)
}
