/* eslint-disable no-restricted-syntax */
export const white = '#FFFFFF'
export const black = '#000000'

export const deepPurple50 = '#DDD9E8'
export const deepPurple100 = '#B1AACB'
export const deepPurple300 = '#6B5D98'
export const deepPurple400 = '#5D5285'
export const deepPurple700 = '#352F4C'
export const deepPurple800 = '#282339'
export const deepPurple900 = '#1B1726'

export const teal50 = '#E0F2F1'
export const teal100 = '#B2DFDB'
export const teal200 = '#80CBC4'
export const teal300 = '#4DB6AC'
export const teal400 = '#26A69A'
export const teal500 = '#009688'
export const teal600 = '#00897B'
export const teal700 = '#00796B'
export const teal800 = '#00695C'
export const teal900 = '#004D40'

export const purple300 = '#B682EC'
export const purple500 = '#974DE4'
export const purple700 = '#7B1FA2'
export const purple800 = '#691CB9'
export const purple900 = '#4A148C'
export const purpleA700 = '#7820D4'

export const pink25 = '#FCEAF5'
export const pink50 = '#FCE4EC'
export const pink400 = '#EC407A'
export const pink500 = '#E91E63'
export const pink600 = '#D81B60'

export const grey50 = '#FAFAFA'
export const grey100 = '#F5F5F5'
export const grey150 = '#F3F3F3'
export const grey200 = '#EEEEEE'
export const grey300 = '#E0E0E0'
export const grey400 = '#BDBDBD'
export const grey500 = '#9E9E9E'
export const grey600 = '#757575'
export const grey700 = '#616161'
export const greyA700 = '#616161'

export const red500 = '#F44336'
export const lightGreen500 = '#8BC34A'
export const amber600 = '#FFB300'
export const dangerRed = '#D30000'
export const orange800 = '#ef6c00'
export const warningTertiaryTextDarkBrown = '#5f2b01'
