import { Box, useTheme } from '@persuit/ui-components'
import { DeepPick } from '@persuit/common-utils'
import { formatDuration } from '../../../util'
import { Rfp } from '../../../../types'
import { LabeledValue } from '../../labeled-value'
import { FormattedDate } from '../../../../common-components/formatted-date'

export type RfpDetailDatesSectionDocType = DeepPick<
	Rfp,
	| 'auction'
	| 'initialProposalsDueBy'
	| 'proposalsDueBy'
	| 'auctionEnd'
	| 'auctionDuration'
	| 'useCase'
>

type RfpDetailDatesSectionProps = {
	document: RfpDetailDatesSectionDocType
}

export const RfpDetailDatesSection = ({
	document: {
		auction,
		initialProposalsDueBy,
		proposalsDueBy,
		auctionEnd,
		auctionDuration,
		useCase,
	},
}: RfpDetailDatesSectionProps) => {
	const theme = useTheme()

	if (!initialProposalsDueBy && !proposalsDueBy && !auction && !auctionDuration && !auctionEnd) {
		return null
	}

	// When the use case is rateReview, we need to fake force the aution to be false
	// (Even though it might be true when pre-send)
	const overrideAuction = useCase === 'rateReview' ? false : auction

	return (
		<Box
			component="section"
			aria-label="Key dates and times"
			width="100%"
			display="flex"
			justifyContent="flex-start"
			gap={theme.spacing(2)}
		>
			{overrideAuction && initialProposalsDueBy ? (
				<Box width="30%">
					<LabeledValue
						label="Proposals due by:"
						value={<FormattedDate date={initialProposalsDueBy} />}
					/>
				</Box>
			) : null}

			{proposalsDueBy ? (
				<Box width="30%">
					<LabeledValue
						label={overrideAuction ? 'Auction start date:' : 'Proposals due by:'}
						value={<FormattedDate date={proposalsDueBy} />}
					/>
				</Box>
			) : null}

			{!overrideAuction ? null : auctionDuration ? (
				<Box width="30%">
					<LabeledValue label="Auction duration:" value={formatDuration(auctionDuration)} />
				</Box>
			) : auctionEnd ? (
				<Box width="30%">
					<LabeledValue label="Auction end date:" value={<FormattedDate date={auctionEnd} />} />
				</Box>
			) : null}
		</Box>
	)
}
