import { sub, differenceInSeconds, isAfter, formatDistanceStrict } from 'date-fns'

// Format the time to targetDate from startingDate. Note that startingDate can be null
// in which case, the function is relative to the current date
export default function formatCountdownToDate(targetDate, startingDate = null) {
	const startingDateObj = startingDate ? new Date(startingDate) : new Date()
	const targetDateObj = new Date(targetDate)

	// Show seconds countdown if within 1 minute of the due date
	// Otherwise format the duration using standard date-fns formats
	const oneMinuteBeforeTargetDate = sub(targetDateObj, { minutes: 1 })
	if (isAfter(startingDateObj, oneMinuteBeforeTargetDate)) {
		const diff = differenceInSeconds(targetDateObj, startingDateObj)
		return `in ${diff} seconds`
	}
	return formatDistanceStrict(targetDateObj, startingDateObj, {
		addSuffix: true,
		roundingMethod: 'ceil',
	})
}
