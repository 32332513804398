import { Fragment } from 'react'
import { Typography, Spacer } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { generatePricingItemChartData } from '../pricing-item-calculations'
import { clientPhaseModel } from '../../../../../../common/data/pricing-models'

import { Chart } from './chart'
import { PercentageChart } from './percentage-chart'

const DeliverableItemChart = ({
	zoomToAuction,
	start,
	end,
	orgsToChart,
	currency,
	orgIdToColourMapping,
	responses,
	proposalsDueBy,
	auctionEnd,
	deliverableId,
	deliverablePricingModel,
}) => {
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const chartData = generatePricingItemChartData({
		responses,
		auctionStart: proposalsDueBy,
		auctionEnd,
		deliverableId,
	})

	const isRateCardItem = deliverablePricingModel === clientPhaseModel.RATECARD

	const isPercentagePricingItem = [
		clientPhaseModel.CONTINGENCYPERCENTAGE,
		clientPhaseModel.DISCOUNTPERCENTAGE,
	].includes(deliverablePricingModel)

	if (isPercentagePricingItem) {
		return (
			<PercentageChart
				chartData={chartData}
				zoomToAuction={zoomToAuction}
				start={start}
				end={end}
				orgsToChart={orgsToChart}
				orgIdToColourMapping={orgIdToColourMapping}
			/>
		)
	}

	if (isRateCardItem && isRateCardAuctionEnabled) {
		return (
			<Chart
				chartData={chartData}
				zoomToAuction={zoomToAuction}
				start={start}
				end={end}
				orgsToChart={orgsToChart}
				currency={currency}
				orgIdToColourMapping={orgIdToColourMapping}
			/>
		)
	}

	return (
		<Fragment>
			{isRateCardItem && (
				<Fragment>
					<Typography variant="subtitle2">
						Rate card pricing does not display in this format
					</Typography>
					<Spacer />
				</Fragment>
			)}

			{!isRateCardItem && (
				<Chart
					chartData={chartData}
					zoomToAuction={zoomToAuction}
					start={start}
					end={end}
					orgsToChart={orgsToChart}
					currency={currency}
					orgIdToColourMapping={orgIdToColourMapping}
				/>
			)}
		</Fragment>
	)
}

export default DeliverableItemChart
