// @ts-strict-ignore
import { first, groupBy, isNil } from 'lodash/fp'
import {
	PricingGroup as PricingGroupType,
	PricingItem as PricingItemType,
	ResponseToDeliverable,
} from '../types'

export type PricingItemLookup = Record<string, { price: number; pricingModel: string }>

type ResponseToDeliverableLookup = Record<string, ResponseToDeliverable[]>

const preparePricingItemLookup = (
	responseToDeliverableLookup: ResponseToDeliverableLookup,
	deliverables: Array<PricingItemType | PricingGroupType>,
) => {
	const pricingItemLookup = deliverables.reduce((acc, deliverable) => {
		if (deliverable.__typename === 'PricingGroup') {
			const innerDeliverables = deliverable.deliverables
			const pricingItemLookupFromGroup = preparePricingItemLookup(
				responseToDeliverableLookup,
				innerDeliverables,
			)

			return {
				...acc,
				...pricingItemLookupFromGroup,
			}
		}

		// For __typename=Deliverable
		const isEligibleDeliverable = checkIfEligibleDeliverable(
			deliverable,
			responseToDeliverableLookup,
		)
		if (isEligibleDeliverable) {
			const responseToDeliverable = first(responseToDeliverableLookup[deliverable._id])
			const deliverbleLookupValue = {
				price: parseFloat(responseToDeliverable?.price ?? '0'),
				pricingModel: responseToDeliverable?.pricingModel ?? '',
			}

			return {
				...acc,
				[deliverable._id]: deliverbleLookupValue,
			}
		}

		return acc
	}, {})

	return pricingItemLookup
}

export const getPhaseValidDeliverables = ({
	deliverables,
	responseToDeliverables,
}: {
	deliverables: Array<PricingItemType | PricingGroupType>
	responseToDeliverables: Array<ResponseToDeliverable>
}): PricingItemLookup => {
	// Turn the ResponseToDeliverables into a lookup map from deliverableId -> responseToDeliverable
	const responseToDeliverableLookup: ResponseToDeliverableLookup = groupBy(
		(deliverable) => deliverable.deliverableId,
		responseToDeliverables,
	)

	// Iterate the deliverable tree and decide which to include
	const pricingItemLookup: Record<string, { price: number; pricingModel: string }> =
		preparePricingItemLookup(responseToDeliverableLookup, deliverables)

	return pricingItemLookup
}

export const getTotalCurrentPrice = (pricingItemLookup: PricingItemLookup) => {
	return Object.values(pricingItemLookup).reduce((acc, pricingItem) => acc + pricingItem.price, 0)
}

const checkIfEligibleDeliverable = (
	deliverable: PricingItemType,
	responseLookup: ResponseToDeliverableLookup,
): boolean => {
	const responseToDeliverable = first(responseLookup[deliverable._id])

	if (isNil(responseToDeliverable)) {
		return false
	}

	if (responseToDeliverable.notIncluded) {
		return false
	}

	const deliverablePricingModel = responseToDeliverable.pricingModel || ''
	const incorrectPricingModel = [
		'hourlyRates',
		'hourlyRate',
		'contingencyPercentage',
		'discountPercentage',
		'included',
		'rates',
		'notApplicable',
		'',
	].includes(deliverablePricingModel)
	if (incorrectPricingModel) {
		return false
	}

	const responsePrice = responseToDeliverable?.price
	const hasResponseFee = responsePrice

	if (!hasResponseFee) {
		return false
	}

	return true
}
