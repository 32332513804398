import { useId, useEffect } from 'react'

/** Adds a description element to the dom and returns the id that can be used for `aria-describedby` or `aria-labelledby` */
export const useDescription = (description: string) => {
	const id = useId()

	useEffect(() => {
		const node = document.createElement('span')
		node.innerHTML = description
		node.ariaHidden = 'true'
		node.id = id
		node.style.display = 'none'

		document.body.appendChild(node)
		return () => {
			try {
				document.body.removeChild(node)
			} catch (e) {
				// Ignore: During tests, the node may cleaned up before this runs
			}
		}
	}, [description, id])

	return id
}
