// @ts-strict-ignore
import { useDispatch } from '@client/store'
import { authActions } from '../../actions'
import { useToggleUserInFavourites } from '../../custom-hooks'

export const useToggleFavorites = () => {
	const toggleUserInFavourites = useToggleUserInFavourites()

	const dispatch = useDispatch()

	const updateUserFavourites = (favourites) => {
		dispatch(authActions.updateUserFavourites(favourites))
	}

	async function toggleFavourite(userId) {
		try {
			const user = await toggleUserInFavourites({ userId })

			updateUserFavourites(user.data.addUserToFavourites.favourites)
		} catch (error) {
			console.error('Error adding favourite', error)
		}
	}

	return { toggleFavourite }
}

export function countFavourites(users) {
	if (users && users.length) {
		return users.reduce((acc, user) => {
			if (user.isFavourite) {
				return ++acc
			}
			return acc
		}, 0)
	}
	return 0
}
