const channelTypes = {
	PRIVATE_FIRM_CHANNEL: 'PRIVATE_FIRM_CHANNEL',
	PRIVATE_CLIENT_CHANNEL: 'PRIVATE_CLIENT_CHANNEL',
	CLIENT_TO_FIRM_CHANNEL: 'CLIENT_TO_FIRM_CHANNEL',
}

// destinationChannelTypes can be any of the concrete channel types
// OR
// one of the two additional "virtual" types that actually send to multiple channels
const destinationChannelTypes = {
	...channelTypes,

	// "virtual" channel types
	ALL_PRIVATE_FIRM_CHANNELS: 'ALL_PRIVATE_FIRM_CHANNELS',
	ALL_CLIENT_TO_FIRM_CHANNELS: 'ALL_CLIENT_TO_FIRM_CHANNELS',
}

const values = Object.keys(channelTypes).map((key) => channelTypes[key])

export { destinationChannelTypes, values as channelTypes, channelTypes as default }
