import getClientStatesProps from './get-client-states-props'
import ClientStates from './client-states'
import { Typography, Tooltip, useTheme } from '@persuit/ui-components'
import { useShouldShowAuctionAlert } from '../hooks'
import ClientStatusTypes from './client-status-types'
import { colorCorePink90 } from '@persuit/ui-components/tokens'

const ClientStatus = ({ rfp, request, proposals, firms, showAlerts }) => {
	const theme = useTheme()
	const { status, statusLine2, statusLine3 } = getClientStatesProps(request, proposals, firms)
	const showAuctionAlert =
		useShouldShowAuctionAlert({ rfp, showAlerts }) &&
		status === ClientStatusTypes.AUCTION_IN_PROGRESS

	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						color: theme.palette.text.primary,
						bgcolor: colorCorePink90,
						'& .MuiTooltip-arrow': {
							color: colorCorePink90,
						},
					},
				},
				popper: {
					sx: {
						zIndex: 1000,
					},
				},
			}}
			title={<Typography style={{ whiteSpace: 'nowrap' }}>Auction extended by 5 mins</Typography>}
			arrow={true}
			open={showAuctionAlert}
		>
			<div>
				<ClientStates status={status} statusLine2={statusLine2} statusLine3={statusLine3} />
			</div>
		</Tooltip>
	)
}

export default ClientStatus
ClientStatus.defaultProps = {
	firms: {
		totalInviteCount: null,
		acceptedCount: null,
		declinedCount: null,
		reOpenInvitesCount: null,
	},
	proposals: { totalSubmittedProposalCount: null, totalAcceptedProposalCount: null },
	request: {
		status: '',
		lastBid: '',
		isAuction: false,
		isDraft: null,
		isRequestClosed: null,
		isRequestCompleted: null,
		auctionStart: null,
		auctionEnd: null,
		initialProposalsDueBy: null,
		proposalsDueBy: null,
		markedCompleteManually: null,
	},
}
