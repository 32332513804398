import { useHistory } from 'react-router-dom'
import {
	BoxProps,
	Typography,
	TypographyProps,
	ArrowBackIcon,
	IconButton,
	Divider,
	Box,
} from '@persuit/ui-components'

export type TitleNavigationBarProps = {
	title: string
	titleProps?: TypographyProps
	backButtonLabel: string
	backLink?: string
	asPageTitle?: boolean
	hideBackButton?: boolean
	subtitle?: string
	navbarProps?: BoxProps
} & BoxProps

export const TitleNavigationBar = ({
	backButtonLabel,
	title,
	titleProps,
	backLink,
	hideBackButton,
	subtitle,
	navbarProps,
}: TitleNavigationBarProps) => {
	const history = useHistory()

	return (
		<Box display="flex" py={2} alignItems="center" {...navbarProps}>
			{!hideBackButton && (
				<>
					<IconButton
						onClick={() => (backLink ? history.push(backLink) : history.goBack())}
						aria-label={backButtonLabel}
						color="primary"
					>
						<ArrowBackIcon />
					</IconButton>
					<Divider
						orientation="vertical"
						sx={(theme) => ({
							borderColor: theme.palette.black,
							mx: 3,
							height: 'inherit',
							alignSelf: 'stretch',
						})}
					/>
				</>
			)}
			<Box sx={{ display: 'flex', alignItems: 'baseline' }}>
				<Typography variant="h4" component="h1" {...titleProps}>
					{title}
				</Typography>
				<Typography variant="body1" sx={{ ml: 2 }}>
					{subtitle}
				</Typography>
			</Box>
		</Box>
	)
}
