import { AutocompleteMultiSelect } from '@persuit/ui-components'
import { isNotNil } from '@persuit/common-utils'

type Option = { name: string; id: string }

type FilterSelectProps<T extends Option> = {
	label: string
	options: Array<T>
	value: Array<T>
	onChange: (selectedIds: Array<T>) => void
	dataTrackName: string
}

export const FilterSelect = <T extends Option>({
	options,
	onChange,
	value,
	label,
	dataTrackName,
}: FilterSelectProps<T>) => {
	const getValue = (option: T) => option.id

	return (
		<AutocompleteMultiSelect
			enableSelectAll={true}
			variant="standard"
			label={label}
			slotProps={{ popper: { style: { width: '300px' } } }}
			style={{ width: '180px' }}
			data-trackid={`select-${dataTrackName}`}
			options={options}
			value={value.map(getValue)}
			onChange={(value) => {
				const selectedOptions: T[] = value
					.map((val) => options.find((option) => getValue(option) === val))
					.filter(isNotNil)
				onChange(selectedOptions)
			}}
			getLabel={(option) => option.name}
			getValue={getValue}
		/>
	)
}
