import isEmpty from 'lodash/fp/isEmpty'

// When an org's custom tracking fields are rendered as redux form fields, the array of
// custom field values on a request was previously matched implicitly based on array index.
// This caused problems when an org adds/re-orders their custom fields (the array indices
// of the custom field settings and those on a request no longer matched). Therefore, we
// need to explicitly match both arrays based on customTrackingField _id's instead of
// array indices.
export default function remapCustomFields(customTrackingFieldSettings, trackedFieldsOnRequest) {
	// Get only the custom fields that are tracked on the request
	const trackedFieldsOnRequestNotEmpty = trackedFieldsOnRequest.filter(
		(trackedFieldOnRequest) => !!trackedFieldOnRequest,
	)

	return customTrackingFieldSettings.reduce((acc, customTrackingField) => {
		// Find the matching field on the request

		const trackedRequestField = trackedFieldsOnRequestNotEmpty.find(
			(trackedFieldOnRequest) =>
				trackedFieldOnRequest.customTrackingFieldId === customTrackingField._id,
		)

		if (!isEmpty(trackedRequestField)) {
			const customField = { ...trackedRequestField }
			customField.mandatory = customTrackingField.mandatory
			customField.name = customTrackingField.name
			customField.isArchived = customTrackingField.isArchived
			acc.push(customField)
		} else {
			acc.push(customTrackingField)
		}

		return acc
	}, [])
}
