// @ts-strict-ignore
import {
	Box,
	PeopleIcon,
	Tooltip,
	PictureAsPdfIcon,
	ViewColumnIcon,
	LocalAtmIcon,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Dialog,
	Typography,
} from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import {
	GetRfpForRateCardExportQuery,
	useGetRfpForRateCardExportQuery,
} from '@persuit/ui-graphql/hooks'
import { useParams } from 'react-router-dom'
import { clientPhaseModel } from '../../../../common/data/pricing-models'
import { getOrFalse } from '../../../utils/lenses'
import ExportDownloadOptions from './export-download-options'

const formControlLabelStyles = {
	// eslint-disable-next-line no-restricted-syntax
	boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
	padding: 1.5,
	height: '120px',
	'&.MuiFormControlLabel-root': {
		margin: 0,
	},
}

graphql(`
	query GetRfpForRateCardExport($rfpId: ID!) {
		getRfp(_id: $rfpId) {
			_id
			useCase
			detail {
				deliverablesV2 {
					... on Deliverable {
						_id
						pricingPreferences
						__typename
					}
					... on PricingGroup {
						_id
						deliverables {
							_id
							pricingPreferences
						}
						__typename
					}
				}
			}
		}
	}
`)

const checkIfRateCardIsPresent = (getRfpResponse: GetRfpForRateCardExportQuery | undefined) => {
	const deliverables = getRfpResponse?.getRfp?.detail?.deliverablesV2 ?? []

	const isRateCardPresent = deliverables.some((deliverable) => {
		if (deliverable.__typename === 'Deliverable') {
			return deliverable.pricingPreferences === clientPhaseModel.RATECARD
		}

		if (deliverable.__typename === 'PricingGroup') {
			return deliverable.deliverables.some(
				(deliverableInGroup) => deliverableInGroup.pricingPreferences === clientPhaseModel.RATECARD,
			)
		}
		return false
	})

	return isRateCardPresent
}

const RateCardExportOption = () => {
	const { requestId } = useParams<{ requestId: string }>()
	const { data } = useGetRfpForRateCardExportQuery({
		variables: { rfpId: requestId },
		fetchPolicy: 'cache-only',
	})

	const { toggles } = useFeatureToggles()

	const ftRateCardExport = getOrFalse('dev-5190.rate-card-export', toggles)
	const isRateCardPresentInRfp = checkIfRateCardIsPresent(data)
	// Rate review requests should have the rate card export disabled
	const isNotRateReview = data?.getRfp?.useCase !== 'rateReview'

	return (
		ftRateCardExport &&
		isNotRateReview && (
			<>
				<Tooltip
					title={
						!isRateCardPresentInRfp
							? 'The rate card export is only available for requests that contain rate card items'
							: ''
					}
					placement="top-start"
					arrow={true}
				>
					<FormControlLabel
						className="StyledFormControlLabel"
						sx={formControlLabelStyles}
						value={ExportDownloadOptions.RATE_CARD_ONLY}
						control={<Radio color="primary" />}
						disabled={!isRateCardPresentInRfp}
						label={
							<Box pr="1rem" boxSizing="border-box" width="100%" ml="1em">
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<PeopleIcon
										sx={{ mr: 1, color: !isRateCardPresentInRfp ? 'currentcolor' : 'inherit' }}
									/>
									<Typography
										variant="body2Semibold"
										data-testid="rate-card-excel-export"
										textTransform="uppercase"
									>
										Excel - RATE CARDS
									</Typography>
								</Box>
								Rate card details for each proposal, including the starting and final rate.
								<br />
								Includes full rate card items only and not individual hourly rate items.
							</Box>
						}
					/>
				</Tooltip>
			</>
		)
	)
}

const ExportDialog = ({
	open,
	downloadOption,
	setDownloadOption,
	downloadInProgress,
	handleConfirm,
	handleCancel,
}) => {
	return (
		<Dialog
			onClose={handleCancel}
			open={open}
			title="Export proposals"
			size="md"
			actions={[
				{
					label: 'Cancel',
					variant: 'text',
					onClick: handleCancel,
					disabled: downloadInProgress,
				},
				{
					label: 'Export',
					variant: 'primary',
					onClick: handleConfirm,
					loading: downloadInProgress,
					'data-testid': 'dialog-submit',
				},
			]}
		>
			<FormControl
				sx={{
					'&.MuiFormControl-root': {
						width: '100%',
					},
				}}
			>
				<RadioGroup
					aria-label="excelExportDialog"
					name="excelExportDialog"
					value={downloadOption}
					onChange={({ target: { value } }) => {
						setDownloadOption(value)
					}}
					sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
				>
					<FormControlLabel
						className="StyledFormControlLabel"
						value={ExportDownloadOptions.PRICING_ONLY}
						control={<Radio color="primary" />}
						sx={formControlLabelStyles}
						label={
							<div
								style={{
									paddingRight: '1rem',
									boxSizing: 'border-box',
									width: '100%',
									marginLeft: '1em',
								}}
							>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<LocalAtmIcon sx={{ mr: 1 }} />
									<Typography
										variant="body2Semibold"
										textTransform="uppercase"
										data-testid="pricing-only-excel-export"
									>
										Excel - Simplified comparison and pricing
									</Typography>
								</Box>
								Simplified pricing for each proposal.
								<br />
								Useful for sending feedback to firms and integration with other workflows.
							</div>
						}
					/>
					<FormControlLabel
						className="StyledFormControlLabel"
						value={ExportDownloadOptions.DETAILED}
						control={<Radio color="primary" />}
						sx={formControlLabelStyles}
						label={
							<div
								style={{
									paddingRight: '1rem',
									boxSizing: 'border-box',
									width: '100%',
									marginLeft: '1em',
								}}
							>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<ViewColumnIcon sx={{ mr: 1 }} />
									<Typography
										variant="body2Semibold"
										textTransform="uppercase"
										data-testid="detailed-excel-export"
									>
										Excel - Detailed comparison and proposals
									</Typography>
								</Box>
								Comparison summary and detailed proposals that include the scorecard, questionnaire,
								and pricing.
							</div>
						}
					/>
					<RateCardExportOption />

					<FormControlLabel
						className="StyledFormControlLabel"
						value={ExportDownloadOptions.PRINT_PDF}
						control={<Radio color="primary" />}
						sx={formControlLabelStyles}
						label={
							<div
								style={{
									paddingRight: '1rem',
									boxSizing: 'border-box',
									width: '100%',
									marginLeft: '1em',
								}}
							>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<PictureAsPdfIcon sx={{ mr: 1 }} />
									<Typography
										variant="body2Semibold"
										textTransform="uppercase"
										data-testid="print-pdf"
									>
										PRINT / PDF - Side-by-side proposal view
									</Typography>
								</Box>
								A maximum of four proposals will fit on a landscape letter/A4 page.
							</div>
						}
					/>
				</RadioGroup>
			</FormControl>
		</Dialog>
	)
}

export default ExportDialog
