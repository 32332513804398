// @ts-strict-ignore
import { Deliverable } from '../../../../../types'
import { Box, RichTextView, Typography } from '@persuit/ui-components'
import { ClientItemPricingModelStrings } from '../../constants'
import { SubItems } from '../sub-items'
import { RateCard } from '../rate-card'

type DeliverableProps = {
	deliverable: Deliverable
	index: number
}

const getPricingModelString = (pricingPreferences: string) => {
	if (!(pricingPreferences in ClientItemPricingModelStrings)) return ''
	return ClientItemPricingModelStrings[pricingPreferences]
}

export const DeliverableDetail = ({ deliverable, index }: DeliverableProps) => {
	const deliverableTitle = deliverable?.deliverableTitle ?? ''
	const deliverableDescription = deliverable?.deliverable ?? ''
	const pricingPreferences = deliverable?.pricingPreferences ?? ''
	const pricingModelString = getPricingModelString(pricingPreferences)
	const rates = deliverable?.rates ?? []
	const allowFirmAddRate = deliverable?.allowFirmAddRate ?? false
	const orderNo = `${index + 1}.`
	const title = !deliverableTitle ? 'Untitled item' : deliverableTitle

	return (
		<>
			<Typography variant="h3XSmall">
				<Box
					display="grid"
					gridTemplateRows="1fr"
					gridTemplateColumns="auto 1fr"
					width="100%"
					gap={1}
					data-wordexport="hide"
				>
					<Box gridRow="1" gridColumn="1" role="presentation">
						<span role="presentation">{orderNo}</span>
					</Box>

					<Box gridRow="1" gridColumn="2" role="presentation">
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
							role="presentation"
						>
							<Box
								component="span"
								role="presentation"
								sx={{
									fontStyle: !deliverableTitle ? 'italic' : undefined,
								}}
							>
								{title}
							</Box>
							<span role="presentation">{pricingModelString}</span>
						</Box>
					</Box>
				</Box>
				{/* Word export only */}
				<Box
					component="span"
					display="none"
				>{`${orderNo} ${title.trim()} - ${pricingModelString}`}</Box>
			</Typography>

			<Box
				display="grid"
				gridTemplateRows="1fr"
				gridTemplateColumns="auto minmax(0, 1fr)"
				width="100%"
				gap={1}
			>
				<Box gridRow="1" gridColumn="2" width="100%">
					{pricingPreferences !== 'hourlyRates' ? (
						<SubItems mt={1} rates={rates} itemIndex={index} />
					) : (
						<RateCard mt={1} rates={rates} allowFirmAddRate={allowFirmAddRate} />
					)}

					{deliverableDescription && (
						<RichTextView
							mt={1}
							content={deliverableDescription}
							data-testid="pricing-item-instructions"
						/>
					)}
				</Box>
			</Box>
		</>
	)
}
