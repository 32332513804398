// @ts-strict-ignore
import {
	Box,
	PopoverHelp,
	Typography,
	Tooltip,
	Switch,
	FormControlLabel,
	FormControl,
	FormHelperText,
} from '@persuit/ui-components'

type TotalPriceRequiredCheckboxProps = {
	error?: string
	originalTotalPriceRequired: boolean | undefined
	onChange: (event) => void
	checked: boolean
}

export const TotalPriceRequiredToggle = ({
	error,
	originalTotalPriceRequired,
	onChange,
	checked,
}: TotalPriceRequiredCheckboxProps) => {
	return (
		<Box display="flex" paddingLeft="6px" paddingRight="6px" alignItems="center">
			<Tooltip
				title="Once set, the request total price cannot be enabled / disabled"
				arrow={true}
				disabled={!originalTotalPriceRequired}
			>
				<FormControl error={!!error} disabled={originalTotalPriceRequired}>
					<FormControlLabel
						label="Request total price"
						name={'totalPriceRequired'}
						control={
							<Switch
								color="primary"
								onChange={(e) => {
									onChange(e.currentTarget.checked)
								}}
								checked={checked}
								inputProps={{ 'data-testid': 'total-price-required' } as any}
							/>
						}
					/>
					<FormHelperText>{error}</FormHelperText>
				</FormControl>
			</Tooltip>

			{!originalTotalPriceRequired && (
				<PopoverHelp
					content={
						<div>
							<Typography variant="body2">
								Total price is a value that firms manually calculate based on your instruction. This
								will be used for data savings purposes and to provide a price ranking.
							</Typography>
						</div>
					}
					triggerButtonProps={{
						'aria-label': 'Total price required help',
					}}
				/>
			)}
		</Box>
	)
}
