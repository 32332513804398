import { useState } from 'react'
import { uploadFilesUsingAxios } from './file-upload-xhr-axios'
import { filterAllowableFiles, handleUploadResult } from './file-upload-xhr-utils'
import { useSnackbar } from '@persuit/ui-components'

export const useFileUploadXHR = ({ postXHR = uploadFilesUsingAxios, handleUploadedFiles }) => {
	const [isUploading, setIsUploading] = useState(false)
	const [uploadError, setError] = useState(null)
	const { openSnackbar } = useSnackbar()

	const uploadFiles = async (files) => {
		try {
			const filesToUpload = filterAllowableFiles(files)
			setError(false)
			setIsUploading(true)
			const uploadResult = await postXHR({ endpoint: '/file/', filesToUpload })
			handleUploadResult({ handleUploadedFiles, uploadResult })
			openSnackbar('File uploaded successfully')
		} catch (uploadError) {
			setError(uploadError)
		} finally {
			setIsUploading(false)
		}
	}

	return {
		uploadFiles,
		uploadError,
		isUploading,
	}
}
