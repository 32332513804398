import {
	Alert,
	Divider,
	Typography,
	Spacer,
	ReportProblemOutlinedIcon,
	Box,
	DataGrid,
} from '@persuit/ui-components'
import { ExternalApiMatter } from '../sync-tracking-fields-wizard.types'

type ConfirmMatterPanelProps = {
	selectedMatter: ExternalApiMatter
	syncErrorText?: string
	updateMatterReference?: boolean
}

export const ConfirmMatterPanel = ({
	selectedMatter,
	syncErrorText,
	updateMatterReference = false,
}: ConfirmMatterPanelProps) => {
	const generateTrackingFieldRows = () => {
		const matterReferenceRow = {
			id: `tracking-field-0`,
			trackingFieldName: 'Matter Reference',
			trackingFieldValue: selectedMatter.matterNumber,
		}
		const rows = updateMatterReference ? [matterReferenceRow] : []

		if (!selectedMatter.trackingFields) return rows

		const trackingFieldRows = selectedMatter.trackingFields.map((trackingField, index) => ({
			id: `tracking-field-${index + 1}`,
			trackingFieldName: trackingField.fieldName,
			trackingFieldValue: trackingField.values ? trackingField.values.join('\n') : '',
		}))
		return [...rows, ...trackingFieldRows]
	}

	return (
		<>
			<Divider />
			<Spacer space={2} />
			<div data-trackid="review-external-matter-tracking-fields">
				<Typography
					variant="button"
					component="span"
				>{`${selectedMatter.matterNumber} - ${selectedMatter.matterName}`}</Typography>
				<Spacer space={2} />
				<DataGrid
					rows={generateTrackingFieldRows()}
					columns={[
						{
							field: 'trackingFieldName',
							headerName: 'Tracking Field Name',
							width: 250,
							disableReorder: true,
						},
						{
							field: 'trackingFieldValue',
							headerName: 'Value Retrieved from External Matter',
							flex: 1,
							disableReorder: true,
						},
					]}
					pageSize={10}
					rowsPerPageOptions={[10]}
					density="compact"
					checkboxSelection={false}
					disableSelectionOnClick={true}
					disableColumnSelector={true}
					autoHeight={true}
				/>
			</div>
			<Spacer space={2} />
			<Alert severity="warning" icon={false}>
				<Box display="flex" alignItems="center">
					<ReportProblemOutlinedIcon />
					<Box marginLeft="1rem">
						This will override the relevant tracking fields based on the external matter
					</Box>
				</Box>
			</Alert>
			<Spacer />
			{syncErrorText && (
				<Alert severity="error" icon={false}>
					<Box display="flex" alignItems="center">
						<ReportProblemOutlinedIcon />
						<Box marginLeft="1rem">{syncErrorText}</Box>
					</Box>
				</Alert>
			)}
		</>
	)
}
