import { gql } from '@persuit/ui-graphql'
import analyticsGql from '../fragments/analytics-gql'

const getAnalytics = gql`
	${analyticsGql}

	query ($requestId: ID, $convertToBaseCurrency: Boolean, $reportType: String) {
		getAnalytics(
			requestId: $requestId
			convertToBaseCurrency: $convertToBaseCurrency
			reportType: $reportType
		) {
			...Analytics
		}
	}
`

export default getAnalytics
