import { IntegerNumberField, IntegerNumberFieldProps } from './integer-number-field'
import { useController, useFormContext } from 'react-hook-form'

export type FormIntegerNumberFieldProps = {
	name: string
	defaulValue?: number
	/** What to set the value to when the user clicks away from an empty input */
	emptyValue?: number
} & IntegerNumberFieldProps

export const FormIntegerNumberField = ({
	name,
	defaultValue,
	emptyValue,
	...props
}: FormIntegerNumberFieldProps) => {
	const formContext = useFormContext()

	if (!formContext?.control) {
		throw new Error('Missing FormProvider')
	}

	const { control } = formContext

	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue,
	})

	return (
		<IntegerNumberField
			onChange={onChange}
			onBlur={() => {
				onBlur()
				if (emptyValue !== undefined && value === null) {
					onChange(emptyValue)
				}
			}}
			value={value ?? null}
			name={name}
			inputRef={ref}
			error={!!error?.message}
			helperText={error?.message}
			{...props}
		/>
	)
}
