import classnames from 'classnames'
import injectSheet from '../injectSheet'
import { grey500, teal50, teal200, teal600, black, white, red500 } from '../theme/persuit-colors'
import Box from './box.jsx'

const Alert = ({ children, classes, className, type, ...props }) => {
	return (
		<Box
			top={1}
			bottom={1}
			left={1}
			right={1}
			{...props}
			className={classnames(classes.base, classes[type], className)}
		>
			{children}
		</Box>
	)
}

Alert.styles = {
	base: {
		borderRadius: '4px',
	},
	success: {
		backgroundColor: teal50,
		border: `1px solid ${teal200}`,
		color: teal600,
	},
	fail: {
		// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
		backgroundColor: 'rgba(244, 67, 54, 0.1)',
		border: `1px solid ${red500}`,
		color: red500,
	},
	black: {
		backgroundColor: white,
		border: `1px solid ${black}`,
		color: black,
	},
	info: {
		backgroundColor: white,
		color: black,
		border: `1px solid ${grey500}`,
		borderLeft: `4px solid ${grey500}`,
	},
}

export default injectSheet(Alert.styles)(Alert)
