// @ts-strict-ignore
import { useState } from 'react'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import {
	Divider,
	SxProps,
	RichTextView,
	Box,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	useTheme,
	allCurrencies,
} from '@persuit/ui-components'
import { useSaliLocationData, useSaliPracticeAreaData } from '@persuit/ui-hooks'
import { RateCardTable } from './rate-card-table'
import { formatPrice, timekeeperCalculations } from '../utils'
import { RateCardPriceHistory, RateCardPriceHistoryProps } from './rate-card-price-history'
import { OriginalAssumptionsDialog } from './original-assumptions-dialog'
import { SubAccordion } from '../sub-accordion'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type CurrentRateOrLyHoursNotApplicableMessageProps = {
	isCurrentRateApplicable: boolean
	isLastYearHoursApplicable: boolean
}

const getMessage = (
	isCurrentRateApplicable: boolean,
	isLastYearHoursApplicable: boolean,
): string => {
	if (!isCurrentRateApplicable && !isLastYearHoursApplicable) {
		return "Firm marked current rate and last year's hours as not applicable"
	}

	if (!isCurrentRateApplicable && isLastYearHoursApplicable) {
		return 'Firm marked current rate as not applicable'
	}

	if (isCurrentRateApplicable && !isLastYearHoursApplicable) {
		return "Firm marked last year's hours as not applicable"
	}

	return ''
}

export const CurrentRateOrLyHoursNotApplicableMessage = ({
	isCurrentRateApplicable,
	isLastYearHoursApplicable,
}: CurrentRateOrLyHoursNotApplicableMessageProps) => {
	if (isCurrentRateApplicable && isLastYearHoursApplicable) {
		return null
	}

	const message = getMessage(isCurrentRateApplicable, isLastYearHoursApplicable)

	return (
		<Box sx={{ mb: 1 }}>
			<Typography variant="body1">{message}</Typography>
		</Box>
	)
}

type Timekeeper = {
	timekeeper?: string | null
	currentRate?: number | null
	standardRate?: number | null
	proposedRate?: number | null
	lyHour?: number | null
	isEnabled: boolean
}
type RateCardData = {
	_id: string
	currency?: string | null
	originalRateCardId?: string | null
	description?: string | null | undefined
	timekeepers: Timekeeper[]
	location?: null | {
		country: string
		regions: string[]
	}
	practiceAreas: string[]
	isCurrentRateApplicable?: boolean | null
	isLastYearHoursApplicable?: boolean | null
}
type RateCardType = RateCardData & { updatedAt?: number | null }

export type RateCardProps = {
	open?: boolean
	onChange?: (open: boolean) => void
	defaultOpen?: boolean
	sx?: SxProps
	rateCard: RateCardType
	historicalPrices?: RateCardPriceHistoryProps['rowData']
	currency: string
	timekeepers: { _id?: string | null; timekeeperLabel?: string | null }[]
	truncated?: boolean
	index: number
	rateCardRevisionComment?: any
}

export const RateCard = ({
	index,
	open: openProp,
	onChange,
	truncated,
	rateCard,
	historicalPrices = [],
	currency: _currency,
	timekeepers,
	sx,
	defaultOpen = false,
	rateCardRevisionComment,
}: RateCardProps) => {
	const { toggles } = useFeatureToggles()
	const isMultiCurrencyEnabled = toggles['dev-10954.multi-currency']
	const currency = (isMultiCurrencyEnabled ? rateCard.currency : _currency) ?? null

	const [openState, setOpen] = useState(defaultOpen)
	const open = openProp ?? openState
	const { regionById } = useSaliLocationData()
	const { practiceAreaById } = useSaliPracticeAreaData()

	const timekepperSuggestedRate = (timekeeperId: string) => {
		return rateCardRevisionComment?.timekeepers.find(
			(timekeeper) => timekeeper.timekeeperId === timekeeperId,
		).suggestedRate
	}

	const assumptionsSuggested = rateCardRevisionComment?.assumptions

	const overallSpendImpact = rateCard.timekeepers
		.filter(isNotNilProp('standardRate'))
		.filter(isNotNilProp('proposedRate'))
		.map(timekeeperCalculations)
		.filter(isNotNilProp('spendImpact'))
		.reduce((total, { spendImpact }) => total + spendImpact, 0)

	const practiceAreasList = rateCard.practiceAreas
		.map(practiceAreaById)
		.map((p) => p?.name)
		.filter(isNotNil)

	const regionsList = (rateCard.location?.regions ?? [])
		.map(regionById)
		.map((region) => region?.name)
		.filter(isNotNil)

	const REGIONS_TO_SHOW = 3
	const PRACTICE_AREAS_TO_SHOW = 1

	const rowData = timekeepers
		.filter(isNotNilProp('_id'))
		.filter(isNotNilProp('timekeeperLabel'))
		.map((tk) => {
			const rtk = rateCard.timekeepers.find((rtk) => rtk.timekeeper === tk._id)

			if (!rtk) return null

			return {
				_id: tk._id,
				timekeeperLabel: tk.timekeeperLabel,
				isEnabled: rtk.isEnabled,
				lyHour: rtk.lyHour,
				currentRate: rtk.currentRate,
				proposedRate: rtk.proposedRate,
				standardRate: rtk.standardRate,
				suggestedRate: timekepperSuggestedRate(tk._id),
			}
		})
		.filter(isNotNil)

	const [assumption, setAssumption] = useState({
		isOpen: false,
		originalAssumption: '',
	})

	const handleAssumptionDialogOpen = (originalAssumption: string) => {
		setAssumption({
			isOpen: true,
			originalAssumption,
		})
	}
	const handleAssumptionDialogClose = () => {
		setAssumption({
			isOpen: false,
			originalAssumption: '',
		})
	}

	const { isOpen, originalAssumption } = assumption
	const theme = useTheme()

	const rateCardCurrencyData = allCurrencies.find((c) => c.value === rateCard.currency)

	return (
		<>
			<OriginalAssumptionsDialog
				isOpen={isOpen}
				originalAssumption={originalAssumption}
				handleClose={handleAssumptionDialogClose}
			/>
			<Accordion
				expanded={open}
				onChange={(open) => {
					setOpen(open)
					onChange?.(open)
				}}
				ContainerComponent={Box}
				sx={{ borderRadius: 1, border: 'solid 1px grey', p: 1, ...sx }}
			>
				<AccordionSummary iconButtonLabel={`expand rate card ${index + 1}`}>
					<Box
						sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}
						id={rateCard._id}
					>
						{index ? <span style={{ display: 'none' }}>Rate card {index + 1}</span> : null}
						<Typography variant="h3XSmall" flexBasis="100px" flexGrow={1}>
							{regionById(rateCard?.location?.country ?? '')?.name}
						</Typography>
						{!open && (
							<>
								<Typography flexGrow={1} flexBasis={0}>
									{regionsList.slice(0, REGIONS_TO_SHOW).join(', ')}
									{regionsList.length > REGIONS_TO_SHOW
										? `, +${regionsList?.length - REGIONS_TO_SHOW}`
										: null}
								</Typography>
								<Typography flexGrow={1} flexBasis={0}>
									{practiceAreasList.slice(0, PRACTICE_AREAS_TO_SHOW).join(', ')}
									{practiceAreasList.length > PRACTICE_AREAS_TO_SHOW
										? `, +${practiceAreasList.length - PRACTICE_AREAS_TO_SHOW}`
										: null}
								</Typography>
							</>
						)}
					</Box>
				</AccordionSummary>

				<AccordionDetails unmountOnExit={true}>
					<Box sx={{ mb: 1 }}>
						<Typography variant="body1Semibold" sx={{ display: 'inline' }}>
							Location:&nbsp;
						</Typography>
						<Typography sx={{ display: 'inline' }}>{regionsList.join(', ')}</Typography>
					</Box>

					<Box sx={{ mb: 1 }}>
						<Typography variant="body1Semibold" sx={{ display: 'inline' }}>
							Practice Area:&nbsp;
						</Typography>
						<Typography sx={{ display: 'inline' }}>{practiceAreasList.join(', ')}</Typography>
					</Box>

					{isMultiCurrencyEnabled && rateCardCurrencyData && (
						<Box sx={{ mb: 1 }}>
							<Typography variant="body1Semibold" sx={{ display: 'inline' }}>
								Currency:&nbsp;
							</Typography>
							<Typography
								sx={{ display: 'inline' }}
							>{`${rateCardCurrencyData.label} (${rateCardCurrencyData.value})`}</Typography>
						</Box>
					)}

					<CurrentRateOrLyHoursNotApplicableMessage
						isCurrentRateApplicable={Boolean(rateCard.isCurrentRateApplicable)}
						isLastYearHoursApplicable={Boolean(rateCard.isLastYearHoursApplicable)}
					/>

					<Divider sx={{ my: 2 }} />

					<RateCardTable
						currency={currency}
						rowData={rowData}
						truncated={truncated}
						ariaLabel={`timekeeper rates for rate card ${index + 1}`}
						rateCardId={rateCard._id}
					/>

					{!truncated ? (
						<Box mt={2} ml={2} data-testid="overall-spend-impact">
							<Typography variant="body2Semibold" sx={{ display: 'block' }}>
								Overall spend impact&nbsp;
							</Typography>
							{!rateCard.isCurrentRateApplicable || !rateCard.isLastYearHoursApplicable ? (
								<>
									<Typography variant="body2Semibold" sx={{ display: 'block' }}>
										Not available&nbsp;
									</Typography>
									<Typography variant="body2">
										requires last year's hours and current rate
									</Typography>
								</>
							) : (
								<Typography variant="body2Semibold">
									{formatPrice(currency, Math.round(overallSpendImpact), '0,0')}
								</Typography>
							)}
						</Box>
					) : null}

					{historicalPrices.length > 0 && (
						<SubAccordion
							title="Negotiation Price History"
							ariaLabel={`Negotiation Price history for rate card ${index + 1}`}
							sx={{ mt: 2 }}
						>
							<RateCardPriceHistory
								rowData={historicalPrices}
								ariaLabel={`historical prices for rate card ${index + 1}`}
							/>
						</SubAccordion>
					)}

					{(!!rateCard.description || !!assumptionsSuggested) && (
						<SubAccordion
							title="Assumptions & Description"
							ariaLabel={`Assumptions & Description for rate card ${index + 1}`}
							sx={{ mt: 2 }}
						>
							{assumptionsSuggested ? (
								<>
									<Button
										variant="outlined"
										onClick={() => handleAssumptionDialogOpen(rateCard.description ?? '')}
									>
										View Original Response
									</Button>
									<Box bgcolor={theme.palette.pink25} p={2} mt={2}>
										<RichTextView content={assumptionsSuggested} />
									</Box>
								</>
							) : (
								<RichTextView content={rateCard.description ?? ''} />
							)}
						</SubAccordion>
					)}
				</AccordionDetails>
			</Accordion>
		</>
	)
}
