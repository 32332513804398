// @ts-strict-ignore
import { useId } from 'react'
import {
	Button,
	Spacer,
	Box,
	IconButton,
	FormTextField,
	DeleteIcon,
	AddIcon,
	SortableListItem,
	SortableDragHandler,
	SortableVerticalList,
	Typography,
	useFormContext,
	useFieldArray,
} from '@persuit/ui-components'
import { ScorecardFormValues } from '../scorecard-form'

type SubcategoryProps = {
	index: number
	dragId?: string
	fieldName: `scoreCategories.${number}.subcategories.${number}`
	onRemove?: () => void
	error: string | null
}

export const Subcategory = ({ index, dragId, fieldName, onRemove, error }: SubcategoryProps) => {
	const subCategoryPosition = index + 1
	const { watch } = useFormContext<ScorecardFormValues>()
	const label = watch(`${fieldName}.label`)
	const mode = watch('mode')

	return (
		<Box display="flex" alignItems="center" mb={1}>
			{dragId && <SortableDragHandler id={dragId} />}

			<FormTextField
				id={`${fieldName}.label`}
				name={`${fieldName}.label`}
				label="Label"
				required={true}
				{...(mode !== 'MANUAL'
					? {
							error: !!error,
							helperText: error,
					  }
					: undefined)}
				fullWidth={true}
				rules={{ required: 'Subcategory name is required' }}
			/>

			{onRemove && (
				<IconButton
					style={{ padding: '0' }}
					aria-label={`Delete Sub Category ${label ?? subCategoryPosition}`}
					onClick={onRemove}
				>
					<DeleteIcon color="primary" />
				</IconButton>
			)}
		</Box>
	)
}

type SubcategoriesProps = {
	categoryIndex: number
	errors:
		| {
				index: number
				label: string
		  }[]
		| null
}

export const Subcategories = ({ categoryIndex, errors }: SubcategoriesProps) => {
	const fieldName = `scoreCategories.${categoryIndex}.subcategories` as const
	const { control } = useFormContext<ScorecardFormValues>()
	const {
		fields: subcategories,
		remove,
		move,
		append,
	} = useFieldArray<ScorecardFormValues, `scoreCategories.${number}.subcategories`>({
		control,
		name: fieldName,
	})
	const titleId = useId()

	const getSubCategoryErrors = (index) =>
		errors?.find((subcategoryErrors) => index === subcategoryErrors.index)?.label ?? null

	if (subcategories.length === 0) {
		return null
	}

	const addButton = (
		<Button
			variant="outlined"
			color="primary"
			onClick={() => append({ label: '' })}
			startIcon={<AddIcon />}
		>
			Add sub category
		</Button>
	)

	if (subcategories.length === 1) {
		return (
			<Box>
				<Subcategory index={0} fieldName={`${fieldName}.0`} error={getSubCategoryErrors(0)} />
				<Spacer />
				{addButton}
			</Box>
		)
	}

	const getPosition = (id) => subcategories.findIndex((cat) => cat.id === id) + 1
	const itemsCount = subcategories.length
	const subcategoriesMap = new Map(subcategories.map((cat) => [cat.id, cat]))
	const getSubcategoriesTitle = (id: string) =>
		`${getPosition(id)} ${subcategoriesMap.get(id)?.label ?? ''}`

	return (
		<Box>
			<Typography id={titleId} mb={1}>
				Subcategories
			</Typography>

			<SortableVerticalList
				containerProps={{
					'aria-labelledby': titleId,
				}}
				items={subcategories}
				onMove={move}
				accessibility={{
					announcements: {
						onDragStart({ active }) {
							return `Picked up draggable sub-category ${getSubcategoriesTitle(
								`${active.id}`,
							)}. It is in position ${getPosition(active.id)} of ${itemsCount}`
						},
						onDragOver({ active, over }) {
							if (over) {
								return `Draggable sub-category ${getSubcategoriesTitle(
									`${active.id}`,
								)} was moved into position ${getPosition(over.id)} of ${itemsCount}`
							}
						},
						onDragEnd({ active, over }) {
							if (over) {
								return `Draggable sub-category ${getSubcategoriesTitle(
									`${active.id}`,
								)} was dropped at position ${getPosition(over.id)} of ${itemsCount}`
							}
						},
						onDragCancel({ active }) {
							return `Dragging was cancelled. Draggable sub-category ${getSubcategoriesTitle(
								`${active.id}`,
							)} was dropped.`
						},
					},
					screenReaderInstructions: {
						draggable: `To pick up a draggable sub-category, press space or enter. 
							While dragging, use the arrow keys to move the sub-category in up/down direction. 
							Press space or enter again to drop the sub-category in its new position, or press escape to cancel.`,
					},
				}}
			>
				{subcategories.map((subcategory, subcategoryIndex) => (
					<SortableListItem
						id={subcategory.id}
						key={subcategory.id}
						style={{ margin: '4px 10px' }}
						aria-posinset={subcategoryIndex + 1}
						aria-setsize={subcategories.length}
					>
						{() => (
							<Subcategory
								index={subcategoryIndex}
								fieldName={`${fieldName}.${subcategoryIndex}`}
								dragId={subcategory.id}
								onRemove={() => remove(subcategoryIndex)}
								error={getSubCategoryErrors(subcategoryIndex)}
							/>
						)}
					</SortableListItem>
				))}
			</SortableVerticalList>
			<Spacer />
			{addButton}
		</Box>
	)
}
