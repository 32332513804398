import { map, reverse } from 'lodash/fp'
import { partition } from 'lodash'
import { currencies } from '@persuit/common-utils'

type RawCurrency = {
	code: string
	symbol: string
	fullName: string
}

export type Currency = {
	value: string
	label: string
	symbol: string
}

const mapToObj = (val: RawCurrency): Currency => ({
	value: val.code,
	symbol: val.symbol,
	label: val.fullName,
})

const mappedCurrencies = map(mapToObj, currencies)

const [mostUsedCurrenciesRev, otherCurrencies] = partition(mappedCurrencies, ({ value }) =>
	['USD', 'GBP', 'EUR'].includes(value),
)
const mostUsedCurrencies = reverse(mostUsedCurrenciesRev)
const allCurrencies = [...mostUsedCurrencies, ...otherCurrencies]

export { currencies, mostUsedCurrencies, otherCurrencies, allCurrencies }
