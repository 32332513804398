import { forwardRef } from 'react'
import * as React from 'react'
import { Checkbox, CheckboxProps } from './checkbox'
import { composeRefs } from '@persuit/ui-utils'
import {
	FormControl,
	FormControlProps,
	FormControlLabel,
	FormControlLabelProps,
	FormHelperText,
} from '../form'
import {
	OverrideNativeElementProps,
	DataAttributes,
	Override,
	useFormComponent,
	RHFFormComponentProps,
} from '../../utilities'

export type FormCheckboxProps = RHFFormComponentProps<
	boolean,
	Override<FormControlProps, { defaultValue?: boolean }> & {
		helperText?: string
		label: React.ReactNode
		/** Given this component is a FormControl > Label > Checkbox, the root props is of FormControl component,
		 * labelProps is for Label component specifically */
		labelProps?: Partial<Omit<FormControlLabelProps, 'defaultValue' | 'label'>>
		/** Given this component is a FormControl > Label > Checkbox, the root props is of FormControl component,
		 * checkBoxProps is for Checkbox component specifically */
		checkboxProps?: DataAttributes<OverrideNativeElementProps<CheckboxProps, 'input'>>
		/**
		 * Reverse the value checked, useful when you dealing with field name that needs the negation of checked value
		 */
		reverse?: boolean
	}
>

export const FormCheckbox = forwardRef(
	(
		{
			name,
			defaultValue,
			helperText,
			label,
			labelProps,
			checkboxProps,
			error: controlledError,
			rules,
			reverse = false,
			...props
		}: FormCheckboxProps,
		forwardedRef: React.Ref<HTMLInputElement>,
	) => {
		const {
			field,
			fieldState: { error },
		} = useFormComponent({
			rules,
			name,
			defaultValue,
		})

		return (
			<FormControl ref={forwardedRef as any} error={!!error?.message || controlledError} {...props}>
				<FormControlLabel
					{...labelProps}
					control={
						<Checkbox
							required={field.required}
							{...checkboxProps}
							onChange={(e) => {
								const value = reverse ? !e.currentTarget.checked : e.currentTarget.checked
								field.onChange(value)
								props.onChange?.(e)
							}}
							onBlur={(e) => {
								field.onBlur()
								props.onBlur?.(e as any)
							}}
							checked={reverse ? !field.value : field.value}
							name={field.name}
							inputRef={composeRefs(field.ref, forwardedRef)}
							inputProps={{
								...checkboxProps?.inputProps,
								'aria-invalid': error || controlledError ? 'true' : 'false',
							}}
						/>
					}
					label={label}
				/>
				<FormHelperText>{helperText || error?.message}</FormHelperText>
			</FormControl>
		)
	},
)

FormCheckbox.displayName = 'FormCheckbox'
