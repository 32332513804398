// @ts-strict-ignore
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateHeaderSaveHeadlineMutation } from '@persuit/ui-graphql/hooks'
import { EditableTitle } from '@persuit/ui-components'
import { useAutoSaveSnack } from '../../../../sectional-form/auto-save-snack'

graphql(`
	mutation RfpTemplateHeaderSaveHeadline(
		$payload: RfpTemplateDraftHeadlineInput!
		$validate: Boolean!
	) {
		saveRfpTemplateDraftHeadline(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

export type HeadlineProps = {
	templateId: string
	templateTitle: string
	error?: string | null
	sendMode: boolean
}

export const Headline = ({ templateId, templateTitle, error, sendMode }: HeadlineProps) => {
	const setSaveSnack = useAutoSaveSnack()
	const [saveRfpTemplateHeadline] = useRfpTemplateHeaderSaveHeadlineMutation()

	const onChange = async (headline) => {
		try {
			setSaveSnack('loading')
			await saveRfpTemplateHeadline({
				variables: {
					payload: {
						rfpTemplateId: templateId,
						headline,
					},
					validate: sendMode,
				},
			})
			setSaveSnack('success')
		} catch (e) {
			console.error(e)
			setSaveSnack('error')
		}
	}

	return (
		<EditableTitle
			placeholder="Template title"
			initialValue={templateTitle ?? ''}
			error={error ?? undefined}
			onChange={onChange}
			aria-label="Template Title"
			title={error ?? 'Edit template title'}
			required={true}
		/>
	)
}
