// @ts-strict-ignore
import { getOr } from 'lodash/fp'
import { isRequester } from '../../common/auth/policies/user'
import proposalStates from '../../common/data/rfp-proposal-states'
import rfpStates from '../../common/data/rfp-states'

/**
 * Returns false if the proposal is in a state where it can be selected by the client.
 * It does not check if the logged-in user is authorised to select this proposal.
 */
// TODO: Replace any with ProposalFragment type
export const disableSelectProposalButton = (proposal: any, isFirmEliminated: boolean): boolean => {
	const responseStatus = getOr('', 'status', proposal)
	const hasRevisionRequest = getOr(false, 'hasRevisionRequest', proposal)

	if (isFirmEliminated) {
		return true
	}

	if (hasRevisionRequest) {
		return true
	}

	if (responseStatus !== proposalStates.FINALIZED) {
		return true
	}
	// isPendingPrice is true when a request has changed from RFI to RFP
	if (proposal.isPendingPrice) {
		return true
	}
	return false
}

export const disableNegotiateProposalButton = ({
	response,
	request,
	isFirmEliminated,
}): boolean => {
	const responseStatus = getOr('', 'status', response)
	const requestStatus = getOr('', 'status', request)
	const hasRevisionRequest = getOr(false, 'hasRevisionRequest', response)
	const isPendingPrice = getOr(false, 'isPendingPrice', response)

	if (isFirmEliminated) {
		return true
	}

	if (hasRevisionRequest) {
		return true
	}

	if (isPendingPrice) {
		return true
	}

	if (requestStatus === rfpStates.COMPLETED) {
		return true
	}

	if (responseStatus === proposalStates.ACCEPTED) {
		return true
	}

	return false
}

/**
 * Returns true if the user should see the select button.
 * This is not responsible for the select button being enabled/disabled - only showing it
 * @param user
 */
// TODO: Replace any with UserFragment type
export function isShowSelectProposalButton(
	proposal: any,
	isFirmEliminated: boolean,
	user: any,
): boolean {
	const responseStatus = getOr('', 'status', proposal)

	if (!responseStatus) {
		return false
	}

	if (!isRequester(user)) {
		return false
	}

	if (isFirmEliminated) {
		return false
	}

	if (
		[
			proposalStates.DRAFT,
			proposalStates.REVISED,
			proposalStates.WITHDRAWN,
			proposalStates.DRAFT_REVISION,
			proposalStates.ACCEPTED,
		].includes(responseStatus)
	) {
		return false
	}

	return true
}
