// @ts-strict-ignore
import { memo, useMemo, useCallback } from 'react'
import {
	NestedAutocomplete,
	useFormComponent,
	SxProps,
	TextFieldProps,
} from '@persuit/ui-components'
import { useSaliPracticeAreaData, RateReviewServices } from '@persuit/ui-hooks'
import { logger } from '@persuit/ui-logger'
import { prepareFieldData } from './prepare-field-data'
import { flatten } from 'lodash'

export type PracticeAreaData = RateReviewServices[]

export type PracticeAreaFormData = {
	practiceAreas: PracticeAreaDropdownSelectedCodes
	practiceAreaNames: string[]
}

type PracticeAreaDropdownSelectedCodes = string[]

type PracticeAreaDropdownProps = {
	sx?: SxProps
	practiceAreaData: PracticeAreaData
	value: string[] // Array of practiceArea SALI codes
	onChange: (formData: PracticeAreaFormData) => unknown
	TextFieldProps?: Partial<TextFieldProps>
	placeholder?: string
}

export const PracticeAreaDropdown = ({
	sx,
	practiceAreaData,
	value,
	onChange,
	TextFieldProps,
	placeholder,
}: PracticeAreaDropdownProps) => {
	const fieldData = useMemo(() => prepareFieldData(practiceAreaData), [practiceAreaData])

	const practiceAreaDataMap = useMemo(
		() =>
			new Map(
				flatten(practiceAreaData.map((data) => data.practiceAreas)).map((practiceArea) => [
					practiceArea?.code,
					practiceArea?.name,
				]),
			),
		[practiceAreaData],
	)

	const getPracticeAreasName = useCallback(
		(codes: PracticeAreaDropdownSelectedCodes) => {
			return codes.map((code) => practiceAreaDataMap.get(code) ?? '')
		},
		[practiceAreaDataMap],
	)

	const handleChange = (selectedOptions: PracticeAreaDropdownSelectedCodes) => {
		onChange({
			practiceAreas: selectedOptions,
			practiceAreaNames: getPracticeAreasName(selectedOptions),
		})
	}

	return (
		<NestedAutocomplete
			sx={sx}
			placeholder={placeholder}
			label="Practice area"
			options={fieldData}
			selections={value}
			onChange={handleChange}
			getChipLabel={(option) => option.chipLabel ?? option.label}
			TextFieldProps={TextFieldProps}
		/>
	)
}

type ConnectedPracticeAreaDropdownProps = Omit<PracticeAreaDropdownProps, 'practiceAreaData'> & {
	/** Valid practice area ids to choose prom */
	options?: string[]
}

export const ConnectedPracticeAreaDropdown = ({
	options,
	...rest
}: ConnectedPracticeAreaDropdownProps) => {
	const { data, loading, error } = useSaliPracticeAreaData()

	const filteredPracticeAreaData = useMemo(() => {
		const practiceAreaData = data ?? []

		if (!options) return practiceAreaData
		const isValid = (id: string) => options.includes(id)

		return practiceAreaData
			.map((item) => ({
				...item,
				practiceAreas: item.practiceAreas.filter((area) => isValid(area.code)),
			}))
			.filter((item) => item.practiceAreas.length > 0)
	}, [data, options])

	if (loading) {
		return <div>Loading practice area data...</div>
	}

	if (error) {
		logger.error(error)
		return <div>Error loading practice area data</div>
	}

	return <PracticeAreaDropdown practiceAreaData={filteredPracticeAreaData} {...rest} />
}

type FormPracticeAreaDropdownProps = {
	name: string
	defaultValue?: PracticeAreaFormData
} & Pick<PracticeAreaDropdownProps, 'TextFieldProps'>

export const FormPracticeAreaDropdown = memo(
	({ name, defaultValue, ...props }: FormPracticeAreaDropdownProps) => {
		const {
			field: { onChange, value },
		} = useFormComponent({
			name: name ?? 'practiceAreas',
			defaultValue,
		})

		return (
			<ConnectedPracticeAreaDropdown
				value={value?.practiceAreas ?? []}
				onChange={onChange}
				{...props}
			/>
		)
	},
)
