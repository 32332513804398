// @ts-strict-ignore
import { Fragment, useId } from 'react'
import { isEmpty } from 'lodash/fp'
import {
	Alert,
	SortableListItem,
	Button,
	AddIcon,
	Spacer,
	Typography,
	Box,
	IconButton,
	FormTextField,
	ExpandableCard,
	SortableDragHandler,
	DeleteIcon,
	SortableVerticalList,
	FormIntegerNumberField,
	AlertTitle,
} from '@persuit/ui-components'
import { ScorecardWeight } from './scorecard-weight'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Subcategories } from './scorecard-subcategories'
import { ScorecardFormValues } from '../scorecard-form'
import { isNotNil } from '@persuit/common-utils'

const SCORECARD_MIN_WEIGHT = 0
const SCORECARD_MAX_WEIGHT = 999

type CategoryProps = {
	dragId: string
	categoryIndex: number
	onDelete: () => void
	error: {
		label: string | null
		subcategories: {
			index: number
			label: string
		}[]
	} | null
}

export const Category = ({ categoryIndex, onDelete, error, dragId }: CategoryProps) => {
	const { watch } = useFormContext<ScorecardFormValues>()
	const category = watch(`scoreCategories.${categoryIndex}`)
	const categoryPosition = categoryIndex + 1
	const descriptionId = useId()
	const mode = watch('mode')

	return (
		<Fragment>
			<ExpandableCard
				containerProps={{ mb: 2 }}
				hasError={!isEmpty(error)}
				summaryPrefix={<SortableDragHandler id={dragId} />}
				summarySuffix={
					<IconButton
						style={{ padding: '0' }}
						aria-label={
							category.label
								? `Delete category ${category.label}`
								: `Delete category ${categoryPosition}`
						}
						onClick={onDelete}
					>
						<DeleteIcon color="primary" />
					</IconButton>
				}
				summary={
					<Box display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" columnGap="10px">
						<Box>{category.label}</Box>
						<ScorecardWeight weight={category.weight} />
					</Box>
				}
				details={
					<Box id={`scorecard_item_${categoryIndex}`} width="100%" maxWidth="420px">
						<FormTextField
							id={`scoreCategories.${categoryIndex}.label`}
							name={`scoreCategories.${categoryIndex}.label`}
							label="Category name"
							required={true}
							{...(mode !== 'MANUAL'
								? {
										error: !!error?.label,
										helperText: error?.label,
								  }
								: undefined)}
							fullWidth={true}
							rules={{
								required: 'Category name is required',
							}}
						/>
						<Box display="flex" alignItems="baseline" mt={1}>
							<FormIntegerNumberField
								emptyValue={0}
								fullWidth={true}
								id={`scoreCategories.${categoryIndex}.weight`}
								label="Weight"
								name={`scoreCategories.${categoryIndex}.weight`}
								placeholder="Enter weight"
								min={SCORECARD_MIN_WEIGHT}
								max={SCORECARD_MAX_WEIGHT}
								inputProps={{
									'aria-describedby': descriptionId,
								}}
							/>
							<Spacer space={4} shape="row" />
							<ScorecardWeight id={descriptionId} weight={category.weight} />
						</Box>
						<Spacer space={4} />
						<Subcategories categoryIndex={categoryIndex} errors={error?.subcategories ?? null} />
					</Box>
				}
				defaultExpanded={true}
			/>
		</Fragment>
	)
}

export type CategoriesProps = {
	errors:
		| {
				label: string | null
				index: number
				subcategories: {
					label: string
					index: number
				}[]
		  }[]
		| null
}

export const Categories = ({ errors }: CategoriesProps) => {
	const {
		control,
		watch,
		formState: { errors: formStateErrors },
	} = useFormContext<ScorecardFormValues>()
	const {
		fields: categories,
		append: appendCategory,
		remove: removeCategory,
		move: moveCategory,
	} = useFieldArray({
		control,
		name: 'scoreCategories',
	})

	const addCategoryButton = (
		<Button
			variant="outlined"
			color="primary"
			onClick={() =>
				appendCategory({
					weight: 0,
					label: '',
					subcategories: [{ label: '' }],
				})
			}
			startIcon={<AddIcon />}
		>
			Add category
		</Button>
	)

	if (categories.length === 0) {
		return (
			<Box display="flex" flexDirection="column" width="100%">
				<Typography variant="body1Semibold">Add categories to create a scorecard</Typography>
				<Spacer space={2} />
				<Box>{addCategoryButton}</Box>
			</Box>
		)
	}

	const getPosition = (id) => categories.findIndex((cat) => cat.id === id) + 1
	const itemsCount = categories.length
	const categoriesMap = new Map(categories.map((cat) => [cat.id, cat]))
	const getCategoryTitle = (id: string) =>
		`${getPosition(id)} ${categoriesMap.get(id)?.label ?? ''}`

	const mode = watch('mode')
	const activeManualModeErrors = mode === 'MANUAL' ? formStateErrors.scoreCategories : undefined

	return (
		<Box>
			<>
				{activeManualModeErrors && Array.isArray(activeManualModeErrors) && (
					<Alert severity="error">
						<AlertTitle>Please fix the following errors</AlertTitle>
						<Box>
							<ul aria-label="Form errors">
								{activeManualModeErrors?.filter(isNotNil).map((error, idx) => {
									const categoryPosition = `Category ${idx + 1}`
									return (
										<li key={`scorecard-form-error-category-${idx}`}>
											{categoryPosition}
											<ul aria-label={`${categoryPosition} errors`}>
												{error?.label?.message && <li>{error?.label?.message}</li>}
												{(error?.subcategories ?? []).map((subCategory, idx) =>
													subCategory?.label?.message ? (
														<li key={`scorecard-form-error-sub-category-${idx}`}>
															{`Sub-category ${idx + 1}: ${subCategory?.label?.message}`}
														</li>
													) : null,
												)}
											</ul>
										</li>
									)
								})}
							</ul>
						</Box>
					</Alert>
				)}
			</>
			<SortableVerticalList
				items={categories}
				onMove={moveCategory}
				accessibility={{
					announcements: {
						onDragStart({ active }) {
							return `Picked up draggable category ${getCategoryTitle(
								`${active.id}`,
							)}. It is in position ${getPosition(active.id)} of ${itemsCount}`
						},
						onDragOver({ active, over }) {
							if (over) {
								return `Draggable category ${getCategoryTitle(
									`${active.id}`,
								)} was moved into position ${getPosition(over.id)} of ${itemsCount}`
							}
						},
						onDragEnd({ active, over }) {
							if (over) {
								return `Draggable category ${getCategoryTitle(
									`${active.id}`,
								)} was dropped at position ${getPosition(over.id)} of ${itemsCount}`
							}
						},
						onDragCancel({ active }) {
							return `Dragging was cancelled. Draggable category ${getCategoryTitle(
								`${active.id}`,
							)} was dropped.`
						},
					},
					screenReaderInstructions: {
						draggable: `To pick up a draggable category, press space or enter.
							While dragging, use the arrow keys to move the category in up/down direction.
							Press space or enter again to drop the category in its new position, or press escape to cancel.`,
					},
				}}
			>
				{categories.map((category, categoryIndex) => (
					<SortableListItem id={category.id} key={`${category.id}`} style={{ margin: '20px 10px' }}>
						{() => (
							<Category
								dragId={category.id}
								error={
									errors?.find((scorecardError) => categoryIndex === scorecardError.index) ?? null
								}
								categoryIndex={categoryIndex}
								onDelete={() => removeCategory(categoryIndex)}
							/>
						)}
					</SortableListItem>
				))}
			</SortableVerticalList>

			<Spacer />

			<Box>{addCategoryButton}</Box>
		</Box>
	)
}
