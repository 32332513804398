import moment from 'moment'

// returns true if auction start and end date is after now
export function beforeAuction(rfp) {
	return (
		!!rfp.auction &&
		moment(rfp.proposalsDueBy).isAfter(moment()) &&
		moment(rfp.auctionEnd).isAfter(moment())
	)
}

// returns true if auction start date is before now and end date is after now
export function duringAuction(rfp) {
	return (
		!!rfp.auction &&
		moment(rfp.proposalsDueBy).isBefore(moment()) &&
		moment(rfp.auctionEnd).isAfter(moment())
	)
}

// returns true if auction start and end date is before now
export function afterAuction(rfp) {
	return (
		!!rfp.auction &&
		moment(rfp.proposalsDueBy).isBefore(moment()) &&
		moment(rfp.auctionEnd).isBefore(moment())
	)
}
