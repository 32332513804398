import {
	Divider,
	Box,
	TextField,
	Spacer,
	CircularProgress,
	Select,
	MenuItem,
	InputLabel,
	FormHelperText,
} from '@persuit/ui-components'
import {
	ExternalApiMatterType,
	FnHandleOnChangeEvent,
	FnHandleOnSelectEvent,
} from '../sync-tracking-fields-wizard.types'

type SearchMatterPanelProps = {
	searchString: string
	matterTypes: ExternalApiMatterType[]
	selectedMatterType: string
	updateSelectedMatterType: FnHandleOnSelectEvent
	updateMatterRefNumber: FnHandleOnChangeEvent
	searchStringHelperText: string
	isLoadingExternalMatter: boolean
	searchUsingMatterType?: boolean
	matterTypeHelperText?: string
}

export const SearchMatterPanel = ({
	searchString,
	matterTypes,
	selectedMatterType,
	updateSelectedMatterType,
	updateMatterRefNumber,
	searchStringHelperText,
	isLoadingExternalMatter,
	searchUsingMatterType = false,
	matterTypeHelperText = '',
}: SearchMatterPanelProps) => {
	return (
		<>
			<Divider />
			<Spacer space={2} />
			<Box display="flex" alignItems="left">
				<Box alignItems="left" width="100%">
					{searchUsingMatterType && (
						<>
							<InputLabel htmlFor="select-matter-type" required={true}>
								Matter Management Site
							</InputLabel>
							<Select
								inputProps={{ 'aria-label': 'select-matter-type' }}
								labelId={'select-matter-type'}
								label="Matter Management Site"
								multiple={false}
								value={selectedMatterType}
								onChange={updateSelectedMatterType}
								data-trackid={'select-matter-type'}
								sx={{ width: '93%' }}
								required={true}
								error={!!matterTypeHelperText}
							>
								{matterTypes.map((matterType) => (
									<MenuItem key={matterType.id} value={matterType.id}>
										{matterType.name}
									</MenuItem>
								))}
							</Select>
							{!!matterTypeHelperText && (
								<FormHelperText error={true}>{matterTypeHelperText}</FormHelperText>
							)}
						</>
					)}
					<Spacer shape="row" space={4} />
					<TextField
						id="search-matter-text-input"
						data-testid="search-term-input"
						data-trackid="input-search-matter"
						label="Search Matter"
						onChange={updateMatterRefNumber}
						required={true}
						name="Search Matter"
						value={searchString}
						sx={{ width: '93%' }}
						helperText={searchStringHelperText}
						error={!!searchStringHelperText}
					/>
					{isLoadingExternalMatter && <CircularProgress size={30} />}
				</Box>
			</Box>
			<Spacer space={2} />
		</>
	)
}
