import { Box, CurrencySelectForHookForm, PopoverHelp, Typography } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { graphql, useQuery } from '@persuit/ui-graphql'
import { useParams } from 'react-router-dom'
import { FormMultiCurrencySelect } from '../../../common-components/multi-currency-select'

const GET_RFP_QUERY = graphql(`
	query GetRfp_MultiCurrencyDropdown($rfpId: ID!) {
		getRfp(_id: $rfpId) {
			_id
			rateReview {
				currencies
			}
		}
	}
`)

const MultiCurrencyDropdown = ({
	disableExistingCurrencySelections,
}: {
	disableExistingCurrencySelections: boolean
}) => {
	const { requestId } = useParams<{ requestId: string }>()
	const { data, loading } = useQuery(GET_RFP_QUERY, {
		variables: { rfpId: requestId },
		skip: !disableExistingCurrencySelections,
	})

	if (loading) {
		return null
	}

	const existingCurrencies = disableExistingCurrencySelections
		? data?.getRfp?.rateReview?.currencies ?? []
		: []

	return (
		<>
			<Box mt={2} display="flex" alignItems="center">
				<Typography variant="body1Semibold">Currency proposal settings</Typography>
				<PopoverHelp
					content={
						<Box>
							<Typography gutterBottom={true}>
								You can allow firms to submit proposals in multiple currencies. Select one or more
								currencies from the list. Firms can submit their proposals using any of the selected
								currencies. After publishing the request, you can add more currencies, but you
								cannot remove any previously selected currencies.
							</Typography>
						</Box>
					}
					triggerButtonProps={{
						'aria-label': 'information about proposal currency settings',
						size: 'small',
						sx: { p: 0, ml: 1 },
					}}
				/>
			</Box>
			<Box mt={2}>
				<FormMultiCurrencySelect
					label="Select currencies"
					name="currencies"
					getDisabled={(option) => existingCurrencies.includes(option.value)}
				/>
			</Box>
		</>
	)
}

type CurrencyFormProps = {
	useSingleCurrency: boolean
	disableExistingCurrencySelections: boolean
}

export const CurrencyForm = ({
	useSingleCurrency,
	disableExistingCurrencySelections,
}: CurrencyFormProps) => {
	const { toggles } = useFeatureToggles()

	const multiCurrenciesToggle = toggles['dev-10954.multi-currency']

	// it should appear for RFP draft form and update RFP form
	const showMulticurrenciesInput = multiCurrenciesToggle && !useSingleCurrency

	if (!showMulticurrenciesInput) {
		return (
			<CurrencySelectForHookForm
				id="rates-currency"
				label="Currency"
				name="currency"
				rules={{ required: true }}
				disableClearable={true}
			/>
		)
	}

	return (
		<MultiCurrencyDropdown disableExistingCurrencySelections={disableExistingCurrencySelections} />
	)
}
