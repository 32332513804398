// @ts-strict-ignore
import { FormAutocomplete } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { createFilterOptions } from '@mui/material/Autocomplete'

export type SelectableTimekeeper = {
	timekeeperId: string
	timekeeperLabel: string
}

export type TimekeeperAutocompleteProps = {
	options: SelectableTimekeeper[]
	timekeeperIndex: number
	nullValue: SelectableTimekeeper
}

const filter = createFilterOptions({
	matchFrom: 'start',
	stringify: (option: SelectableTimekeeper) => option.timekeeperLabel,
})

export const TimekeeperAutocomplete = ({
	options,
	timekeeperIndex,
	nullValue,
}: TimekeeperAutocompleteProps) => {
	return (
		<FormAutocomplete
			shrinkLabel={true}
			placeholder="Select Timekeeper"
			label="Timekeeper"
			name={`timekeepers.${timekeeperIndex}`}
			nullValue={nullValue}
			multiple={false}
			freeSolo={false}
			options={options}
			isOptionEqualToValue={(option, value) => {
				return option.timekeeperLabel === value.timekeeperLabel
			}}
			getOptionLabel={(option: SelectableTimekeeper): string => option?.timekeeperLabel ?? ''}
			renderOption={(props, option: SelectableTimekeeper) => {
				const foundOption = options.find((opt) => opt.timekeeperLabel === option.timekeeperLabel)
				const optionLabel = foundOption
					? option.timekeeperLabel
					: `${option.timekeeperLabel} (Add new timekeeper)`
				return (
					<li {...props} key={option.timekeeperLabel}>
						{optionLabel}
					</li>
				)
			}}
			filterOptions={(options, params): SelectableTimekeeper[] => {
				const filtered = filter(options, params)
				const { inputValue } = params
				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option.timekeeperLabel)
				if (inputValue !== '' && !isExisting) {
					filtered.unshift({
						timekeeperId: '',
						timekeeperLabel: inputValue,
					})
				}

				return filtered
			}}
			mapValue={(newValue, oldValue) => ({ ...oldValue, ...newValue })}
			style={{ width: 380 }}
			id={`rates-timekeeper-${timekeeperIndex}`}
			variant="outlined"
			aria-label={`Timekeeper ${timekeeperIndex + 1}`}
		/>
	)
}
