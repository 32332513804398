import { useState } from 'react'
import {
	Box,
	Button,
	Menu,
	MenuItem,
	KeyboardArrowDownIcon,
	Typography,
} from '@persuit/ui-components'

export type actionButtonDataType = {
	title: string
	tooltip?: string
	icon: JSX.Element
	disabled?: boolean
	onClick: () => void
}

type ActionsButtonGroupProps = {
	buttonsData: actionButtonDataType[]
}

export const ActionsButtonGroup = ({ buttonsData }: ActionsButtonGroupProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSelect = (callback: () => void) => {
		handleClose()
		callback()
	}

	if (buttonsData.length === 0) {
		return null
	}

	if (buttonsData.length === 1) {
		const buttonData = buttonsData[0]

		return (
			<Box marginLeft="4px">
				<Button
					variant="primary"
					onClick={() => handleSelect(buttonData.onClick)}
					disabled={buttonData.disabled}
					startIcon={buttonData.icon}
					title={buttonData.tooltip}
					size="small"
				>
					{buttonData.title}
				</Button>
			</Box>
		)
	}

	return (
		<Box marginLeft="4px">
			<Button
				variant="primary"
				size="small"
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				ACTIONS
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{buttonsData.map((buttonData, index) => (
					<MenuItem
						disabled={buttonData.disabled}
						key={index}
						onClick={() => handleSelect(buttonData.onClick)}
						title={buttonData.tooltip}
					>
						{buttonData.icon}
						<Typography component="span" marginLeft={1}>
							{buttonData.title}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</Box>
	)
}
