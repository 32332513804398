// @ts-strict-ignore
import messagingActions from '../actions/messaging'

const { SELECT_CHANNEL } = messagingActions

type MessagingState = {
	selectedChannelId: null | string
}

const initialState: MessagingState = {
	selectedChannelId: null,
}

export default function messagingReducer(state = initialState, action) {
	switch (action.type) {
		case SELECT_CHANNEL: {
			return Object.assign({}, state, {
				selectedChannelId: action.channelId,
			})
		}

		default: {
			return state
		}
	}
}
