import { PricingGroup, Deliverable } from '../types'

export function isGroup(item: Partial<Deliverable> | Partial<PricingGroup>): item is PricingGroup {
	return typeof (item as PricingGroup)?.deliverables !== 'undefined'
}

export function isDeliverable(
	item: Partial<Deliverable> | Partial<PricingGroup>,
): item is Deliverable {
	return typeof (item as PricingGroup)?.deliverables === 'undefined'
}
