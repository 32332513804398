import { useState, useMemo } from 'react'
import { isNotNil } from '@persuit/common-utils'
import { refocusBeforeRemoval } from '@persuit/ui-utils'
import {
	TextField,
	Box,
	Autocomplete,
	ListItem,
	ListItemText,
	Button,
} from '@persuit/ui-components'

import Fuse from 'fuse.js'
import emailValidator from 'email-validator'

import { NamwolfLabel } from '../../components/namwolf/namwolf-label'
import NamwolfLogo from '../../components/namwolf/namwolf-logo'
import Mailto from '../../components/mailto/mailto'
import { PanelPreferredFirmLogo } from '../../components/panel-preferred-firm/panel-preferred-firm-logo'
import { ApprovedFirmLogo } from '../../components/approved-firm/approved-firm-logo'
import { ApprovedFirmLabel } from '../../components/approved-firm/approved-firm-label'
import { PanelPreferredFirmLabel } from '../../components/panel-preferred-firm/panel-preferred-firm-label'

import { Chip } from './chip'

export type KnownPerson = {
	__type__: 'known'
	_id: string
	email: string
	name: {
		first: string
		last: string
	}
	org: {
		_id: string
		isNamwolfMember: boolean
		name: string
	}
	isFavourite: boolean
}

export type UnknownPerson = {
	__type__: 'unknown'
	_id: string
	email: string
	label?: string
}

export type Person = KnownPerson | UnknownPerson

export type SharingAutoCompleteProps = {
	people: Person[]
	selectedPeople: Person[]

	onSelect: (people: (Person | string)[]) => void
	onDelete: (person: Person) => void

	panelFirmTags: Array<{ firmId: string; type: string }>
}

export const SharingAutoComplete = ({
	selectedPeople,
	onSelect,
	onDelete,
	panelFirmTags = [],
	people,
}: SharingAutoCompleteProps) => {
	const [highlightedOption, setHighlightedOption] = useState<Person | null>(null)
	const notInvitedPeople = useMemo(
		() =>
			people
				.filter(({ _id }) => !selectedPeople.find((p) => p._id === _id))
				.filter((p): p is KnownPerson => p.__type__ === 'known'),
		[people, selectedPeople],
	)

	const searchPeople = useMemo(() => initialiseSearchEngine(notInvitedPeople), [notInvitedPeople])

	const [open, setOpen] = useState(false)
	const [showMoreTags, setShowMoreTags] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [error, setError] = useState(false)

	//Generate sets of panel/preferred and approved firms
	const panelOrPreferredFirms = new Set()
	const approvedFirms = new Set()

	panelFirmTags.forEach((panelFirm) => {
		const panelFirmId = panelFirm.firmId
		const panelFirmType = panelFirm.type

		if (panelFirmId && panelFirmType === 'approved') {
			approvedFirms.add(panelFirmId)
		} else if (panelFirmId && (panelFirmType === 'panel' || panelFirmType === 'preferred')) {
			panelOrPreferredFirms.add(panelFirmId)
		}
	})

	const validateEmail = (email: string) =>
		!selectedPeople.find((p) => p.email === email) && emailValidator.validate(email)

	// filter the autocomplete list when the input changes (also by default, have
	// the first item in the autocomplete list selected by simulating focus. This
	// ensures that if the user presses enter with a partially matching query, the
	// item from the the autocomplete is selected instead of adding an unknown
	// item)
	const onInputChange = (value: string, { enter = false }: { enter?: boolean } = {}) => {
		if (value === '') {
			setInputValue('')
			return
		}

		const oldValue = inputValue
		const cleanValue = value.replace(/\n\r/g, '')
		const splitValues = cleanValue.split(/[,;]/)

		function submitValues(splitValues: string[], suffix?: string) {
			const invalidValues: string[] = []
			const validValues: (string | Person)[] = []

			splitValues.forEach((value) => {
				const trimmedValue = value.trim()
				// accept a pasted email match to an existing user only if the email is
				// an exact match
				const suggestion = people.find((person) => person.email === trimmedValue)

				if (suggestion) {
					validValues.push(suggestion)
				} else if (validateEmail(trimmedValue)) {
					validValues.push(trimmedValue)
				} else {
					// Push the original value, not the trimmed one to avoid changing the
					// input value. We want the new input value to be the same as before
					// minus the valid values.
					invalidValues.push(value)
				}
			})

			if (validValues.length > 0) {
				onSelect(validValues)
			}
			setInputValue([...invalidValues, suffix].filter(isNotNil).join(','))
			setError(invalidValues.length > 0)
		}

		// Handle copy paste and Enter. In this case we assume that the user wants all inputs to be added. We detemine that the user copy pasted if the number of characters changes by more than one.
		if (value.length - oldValue.length > 1 || enter) {
			submitValues(splitValues)
		}
		// No separators, user is still typing.
		else if (splitValues.length === 1) {
			setInputValue(value)
			setError(false)
		}
		// Multiple emails manually typed. If the user is typing multiple emails it
		// is possible that one of the previous emails is invalid and they are still
		// typing the last email so attempt to add all the emails but the last one
		else if (splitValues.length > 1) {
			const lastValue = splitValues[splitValues.length - 1]
			submitValues(splitValues.slice(0, splitValues.length - 1), lastValue)
		}
	}

	return (
		<Autocomplete
			sx={{ flexBasis: 0, flexGrow: 1 }}
			inputValue={inputValue}
			onHighlightChange={(e, option) => setHighlightedOption(option)}
			value={selectedPeople}
			onChange={(e, value, reason, details) => {
				const option = details?.option
				if (reason === 'selectOption' && option) {
					onSelect([option])
				} else if (reason === 'removeOption' && option) {
					onDelete(option)
				}
			}}
			multiple={true}
			disableClearable={true}
			onOpen={() => setOpen(true)}
			onClose={() => {
				setHighlightedOption(null)
				setOpen(false)
			}}
			open={open && !error}
			noOptionsText={
				<span role="alert" aria-live="polite">
					No matching firm contacts
				</span>
			}
			options={notInvitedPeople}
			getOptionLabel={(person) => person.email}
			filterOptions={(options, state) => searchPeople(state.inputValue)}
			limitTags={4}
			renderTags={(options, getTagProps, { limitTags }) => {
				const showAllTags = showMoreTags

				return (
					<Box
						sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
						role="list"
						id="firm-chip-list"
						aria-label="Invited firm contacts"
						// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
						tabIndex={0}
					>
						{options.slice(0, showAllTags ? undefined : limitTags).map((item, index) => {
							if (item.__type__ === 'unknown') {
								return (
									<Box key={item.email} role="listitem">
										<Chip
											chipProps={{
												id: `firm-chip-${index}`,
												...getTagProps({ index }),
												tabIndex: 0,
												'aria-label': `unregistered contact, ${[item.email, item.label]
													.filter(Boolean)
													.join(', ')}`,
												sx: (theme) => ({ background: theme.palette.teal50 }),
											}}
											onDelete={() => {
												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.getElementById('firm-chip-list'),
													getItemByIndex: (index) => document.getElementById(`firm-chip-${index}`),
												})
												onDelete(item)
											}}
											primaryText={item.label ?? item.email}
											secondaryText={item.label}
											hideStar={!item.label}
										/>
									</Box>
								)
							} else {
								const isApprovedFirm = approvedFirms.has(item.org._id)
								const isPanelOrPreferredFirm = panelOrPreferredFirms.has(item.org._id)
								return (
									<Box key={item.email} role="listitem">
										<Chip
											key={item.email}
											chipProps={{
												id: `firm-chip-${index}`,
												...getTagProps({ index }),
												tabIndex: 0,
											}}
											onDelete={() => {
												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.getElementById('firm-chip-list'),
													getItemByIndex: (index) => document.getElementById(`firm-chip-${index}`),
												})
												onDelete(item)
											}}
											userId={item._id}
											primaryText={`${item.name.first} ${item.name.last}`}
											secondaryText={item.org.name}
											isApprovedFirm={isApprovedFirm}
											isPanelOrPreferredFirm={isPanelOrPreferredFirm}
											icon={
												<>
													{isApprovedFirm && <ApprovedFirmLogo />}
													{isPanelOrPreferredFirm && <PanelPreferredFirmLogo />}
													{item.org.isNamwolfMember && <NamwolfLogo />}
												</>
											}
											isFavourite={item.isFavourite}
											hideStar={false}
										/>
									</Box>
								)
							}
						})}
						{limitTags && options.length - limitTags > 0 ? (
							<Box role="listitem">
								<Button
									variant="text"
									color="primary"
									onClick={(e) => {
										e.stopPropagation()
										setShowMoreTags((show) => !show)
									}}
								>
									{showAllTags ? 'SHOW LESS' : `+ ${Math.max(0, options.length - limitTags)} MORE`}
								</Button>
							</Box>
						) : null}
					</Box>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					id="email-to-add"
					label="Firm Contacts *"
					InputLabelProps={{ shrink: true }}
					variant="standard"
					error={error}
					helperText={error ? 'Invalid email' : ''}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							// If there is a highlighted option, assume the user wants to use
							// the default behavior of selecting that item instead of trying
							// to parse the input into contacts
							if (highlightedOption) {
								setInputValue('')
							} else {
								onInputChange(inputValue, { enter: true })
							}
						}
					}}
					onChange={(e) => {
						onInputChange(e.currentTarget.value)
					}}
					onBlur={() => {
						if (!error) {
							onInputChange('')
						}
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Mailto
								bcc={selectedPeople.map((invitedUser) => invitedUser.email)}
								tooltip="Email firms"
							/>
						),
						style: { paddingRight: 0, paddingTop: 0 },
					}}
					inputProps={{
						...params.inputProps,
						style: { ...params.inputProps.style, minWidth: '60%' },
						'aria-label': 'emails to add',
						placeholder:
							'Type here to add email addresses to this request (all invites are BCC’ed)',
					}}
					data-testid="bcc-input-field"
				/>
			)}
			renderOption={(props, item) => {
				if (item.__type__ === 'unknown') return null

				const { _id, name, org, email } = item

				return (
					<ListItem key={_id} {...props} data-testid={`${email}`}>
						<ListItemText
							sx={{ m: 0 }}
							primary={`${name.first} ${name.last}`}
							secondary={
								<span>
									{email}
									<br />
									<em>{org.name}</em>
									<Box display="flex" gap={1} pt={1}>
										{approvedFirms.has(org._id) && <ApprovedFirmLabel />}
										{panelOrPreferredFirms.has(org._id) && <PanelPreferredFirmLabel />}
										{org.isNamwolfMember && <NamwolfLabel />}
									</Box>
								</span>
							}
						/>
					</ListItem>
				)
			}}
		/>
	)
}

const initialiseSearchEngine = (people: Person[]) => {
	const fuse = new Fuse(
		people.filter((p): p is KnownPerson => p.__type__ === 'known'),
		{
			shouldSort: true,
			threshold: 0,
			ignoreLocation: true,
			keys: ['name.first', 'name.last', 'org.name', 'email'],
		},
	)

	return (query: string): Person[] => {
		const limit = 5

		if (!query) {
			return people.slice(0, limit)
		}

		return fuse
			.search(query, { limit })
			.sort((a, b) =>
				a.item.name.first > b.item.name.first
					? 1
					: a.item.name.first < b.item.name.first
					? -1
					: a.item.name.last > b.item.name.last
					? 1
					: a.item.name.last < b.item.name.last
					? -1
					: 0,
			)
			.map((result) => result.item)
	}
}
