import { useState } from 'react'
import { Button, Dialog, InfoIcon, Link, Typography } from '@persuit/ui-components'

import NamwolfLogo from '../../../components/namwolf/namwolf-logo'

export const NamwolfDialog = () => {
	const [namwolfDialogIsOpen, setNamwolfDialogIsOpen] = useState<boolean>(false)

	return (
		<>
			<Button
				size="small"
				startIcon={<InfoIcon />}
				onClick={() => {
					setNamwolfDialogIsOpen(true)
				}}
				data-trackid="button-invites-namwolf-info"
			>
				NAMWOLF
			</Button>

			<Dialog
				open={namwolfDialogIsOpen}
				onClose={() => {
					setNamwolfDialogIsOpen(false)
				}}
				size="sm"
				title={
					<>
						NAMWOLF <NamwolfLogo style={{ marginLeft: '8px' }} />
					</>
				}
				actions={[
					{
						label: 'Close',
						variant: 'primary',
						onClick: () => setNamwolfDialogIsOpen(false),
					},
				]}
			>
				<Typography variant="body1Semibold">Looking to find a diverse-owned law firm?</Typography>
				<Typography variant="body1" sx={{ pt: 1, pb: 3 }}>
					National Association of Minority & Women Owned Law Firms is a non-profit trade association
					whose mission is to promote diversity in the legal profession by fostering successful
					relationships among pre-eminent minority and women owned law firms and private/public
					entities.
				</Typography>
				<Link
					variant="body1Link"
					href="https://namwolf.org/law-firm-members/"
					target="_blank"
					sx={{ mt: 2 }}
					data-trackid="link-invites-namwolf-directory"
				>
					Open NAMWOLF Directory
				</Link>
			</Dialog>
		</>
	)
}
