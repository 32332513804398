import { PricingModels } from '@persuit/ui-graphql/generated'
import constants from '@persuit/common/data/pricing-models'

const {
	NOTAPPLICABLE,
	FIXEDFEE,
	CAPPEDFEE,
	COLLAREDFEE,
	SUCCESSFEE,
	CONTINGENCYFEE,
	DISCOUNTPERCENTAGE,
	INCLUDEDINTOTAL,
	INCLUDEDINITEMTOTAL,
	BUDGETINGESTIMATE,
	HOURLYRATE,
	OTHER,
	AFA,
} = constants

const FIRM_OPTIONS: PricingItemModelOption = {
	firmCard: [
		{
			value: AFA,
			type: 'OPTION_HEADER',
			children: [
				{
					value: CAPPEDFEE,
					type: 'OPTION',
				},
				{
					value: CONTINGENCYFEE,
					type: 'OPTION',
				},
				{
					value: FIXEDFEE,
					type: 'OPTION',
				},
				{
					value: SUCCESSFEE,
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: BUDGETINGESTIMATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: HOURLYRATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: OTHER,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: NOTAPPLICABLE,
			type: 'OPTION',
		},
	],
	firmItem: [
		{
			value: AFA,
			type: 'OPTION_HEADER',
			children: [
				{
					value: CAPPEDFEE,
					type: 'OPTION',
				},
				{
					value: COLLAREDFEE,
					type: 'OPTION',
				},
				{
					value: CONTINGENCYFEE,
					type: 'OPTION',
				},
				{
					value: FIXEDFEE,
					type: 'OPTION',
				},
				{
					value: SUCCESSFEE,
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: BUDGETINGESTIMATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: OTHER,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: INCLUDEDINTOTAL,
			type: 'OPTION',
		},
	],
	// when removiung ft dev-3327.discount-percentage
	// rename this as firmItem
	firmItemWithDiscount: [
		{
			value: AFA,
			type: 'OPTION_HEADER',
			children: [
				{
					value: CAPPEDFEE,
					type: 'OPTION',
				},
				{
					value: COLLAREDFEE,
					type: 'OPTION',
				},
				{
					value: CONTINGENCYFEE,
					type: 'OPTION',
				},
				{
					value: FIXEDFEE,
					type: 'OPTION',
				},
				{
					value: SUCCESSFEE,
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: BUDGETINGESTIMATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: OTHER,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: INCLUDEDINTOTAL,
			type: 'OPTION',
		},
	],
	firmSubItem: [
		{
			value: AFA,
			type: 'OPTION_HEADER',
			children: [
				{
					value: CAPPEDFEE,
					type: 'OPTION',
				},
				{
					value: FIXEDFEE,
					type: 'OPTION',
				},
				{
					value: SUCCESSFEE,
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: BUDGETINGESTIMATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: OTHER,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: INCLUDEDINITEMTOTAL,
			type: 'OPTION',
		},
	],
	// when removiung ft dev-3327.discount-percentage
	// rename this as firmSubItem
	firmSubItemWithDiscount: [
		{
			value: AFA,
			type: 'OPTION_HEADER',
			children: [
				{
					value: CAPPEDFEE,
					type: 'OPTION',
				},
				{
					value: FIXEDFEE,
					type: 'OPTION',
				},
				{
					value: SUCCESSFEE,
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: BUDGETINGESTIMATE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: DISCOUNTPERCENTAGE,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: OTHER,
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: INCLUDEDINITEMTOTAL,
			type: 'OPTION',
		},
	],
}

export type PricingModelOption = {
	value: PricingModels | 'afa' | 'rates' | 'notApplicable'
	type: 'OPTION'
}

// Can use these types with typescript `satisfies` for intellisense later when typescript is upgraded
type PricingItemModelOption = Record<
	string,
	(
		| PricingModelOption
		| {
				type: 'OPTION_HEADER'
				children: PricingModelOption[]
				value: PricingModels | 'afa' | 'rates' | 'notApplicable'
		  }
		| {
				type: 'DIVIDER'
		  }
	)[]
>

export const DEFAULT_PRICING_MODELS_OPTIONS: PricingItemModelOption = {
	...FIRM_OPTIONS,
	form: [
		{
			value: 'none',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'hourlyRate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
	],
	item: [
		{
			value: 'none',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'collaredFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'rates',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'hourlyRate',
					type: 'OPTION',
				},
				{
					value: 'hourlyRates',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'included',
			type: 'OPTION',
		},
	],
	'item-dev-3327': [
		{
			value: 'none',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'collaredFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyPercentage',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'discountPercentage',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'rates',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'hourlyRate',
					type: 'OPTION',
				},
				{
					value: 'hourlyRates',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'included',
			type: 'OPTION',
		},
	],
	subItem: [
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'includedInItemTotal',
			type: 'OPTION',
		},
	],
	'subItem-dev-3327': [
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'discountPercentage',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'includedInItemTotal',
			type: 'OPTION',
		},
	],
	rfprSubItem: [
		{
			value: 'none',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'collaredFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'included',
			type: 'OPTION',
		},
	],
	card: [
		{
			value: 'none',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'afa',
			type: 'OPTION_HEADER',
			children: [
				{
					value: 'cappedFee',
					type: 'OPTION',
				},
				{
					value: 'contingencyFee',
					type: 'OPTION',
				},
				{
					value: 'fixedFee',
					type: 'OPTION',
				},
				{
					value: 'successFee',
					type: 'OPTION',
				},
			],
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'estimate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'hourlyRate',
			type: 'OPTION',
		},
		{
			type: 'DIVIDER',
		},
		{
			value: 'other',
			type: 'OPTION',
		},
	],
}
