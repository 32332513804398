// @ts-strict-ignore
import moment from 'moment'
import partition from 'lodash/partition'

import typeSafeFloat from '../type-safe-float.js'
// This function calculates the starting and ending price of a proposal during an
// auction
export default function calcaulateAuctionPriceDrop(rfp, proposal) {
	const { proposalsDueBy: auctionStart, auction } = rfp
	const { totalPrices } = proposal

	// Bail out early if the rfp is not an auction
	if (!auction) {
		return {}
	}

	// Partition the total prices into 2 sets: prices on or before the auction starts and
	// prices after the auction starts
	const [pricesBeforeAuctionStart, pricesAfterAuctionStart] = partition(
		totalPrices,
		// Predicate to split the totalPrices array
		({ createdAt }) => {
			return moment(createdAt).isSameOrBefore(moment(auctionStart))
		},
	)

	// Get the price just before the auction starts, since that would be considered the initial
	// price during the auction. Note even though it is the initial price, it is not considered
	// as the starting price! The starting price is calculated as the highest price during the
	// auction.
	// The prices need to be sorted chronologically to make sure we grab the correct price
	pricesBeforeAuctionStart.sort(sortAuctionPricesByDate)
	const priceAtAuctionStart = pricesBeforeAuctionStart[pricesBeforeAuctionStart.length - 1]

	// Check if there was a price before the auction started (i.e. the proposal was submitted
	// after the auction started)
	if (priceAtAuctionStart !== undefined) {
		pricesAfterAuctionStart.unshift(priceAtAuctionStart)
	}

	const startingPrice = getStartingPrice(pricesAfterAuctionStart)
	const endingPrice = getEndingPrice(pricesAfterAuctionStart)
	const priceDrop = getPriceDrop(startingPrice, endingPrice)

	return {
		startingPrice,
		endingPrice,
		priceDrop,
	}
}

// The ending price is the final price of a proposal. This does not consider the auction end
// date. Any price revisions that occur after the end date are still considered to be
// "during" the auction.
function getEndingPrice(prices) {
	const sortedPrices = [...prices].sort(sortAuctionPricesByDate)
	const endingPrice = sortedPrices[sortedPrices.length - 1]
	if (!endingPrice) {
		return null
	}
	return typeSafeFloat(endingPrice.totalPriceValue)
}

// The "starting" price is simply the highest price in the array of total prices :(
function getStartingPrice(prices) {
	const priceValues = prices.map(({ totalPriceValue }) => totalPriceValue)
	const highestPrice = Math.max(...priceValues)
	return typeSafeFloat(highestPrice)
}

// The price drop is zero if either starting price or ending price is null
// Otherwise it is the difference between the starting price and the ending price
function getPriceDrop(startingPrice, endingPrice) {
	if (startingPrice === null || endingPrice === null) {
		return 0
	}
	return startingPrice - endingPrice
}

// Sort prices by date in chronological order
function sortAuctionPricesByDate(a, b) {
	const mA = moment(a.createdAt)
	const mB = moment(b.createdAt)

	if (mA.isAfter(mB)) {
		return 1
	}
	if (mA.isBefore(mB)) {
		return -1
	}
	return 0
}
