// @ts-strict-ignore
import { Fragment, ReactElement } from 'react'
import { getOr, size } from 'lodash/fp'
import striptags from 'striptags'
import {
	CancelIcon as ExcludeIcon,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
} from '@persuit/ui-components'

const renderSummary = (summary = '') => striptags(summary).substr(0, 200)
const isTagged = (rfp) =>
	size(rfp.areaOfLaw) ||
	size(rfp.location) ||
	size(rfp.type) ||
	size(rfp.service) ||
	size(rfp.sourcing)

const getTagNames = (rfp) => (category) =>
	getOr([], `${category}`, rfp)
		.filter(Boolean)
		.map(({ name }) => name)
		.join(', ')

const getTagList = (tagNames) => {
	if (!tagNames) {
		return null
	}

	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	return <span style={{ color: 'rgb(220, 0, 78)' }}>{tagNames}</span>
}

const tags = (rfp) => {
	if (!isTagged(rfp)) {
		return null
	}

	const tagNames = getTagNames(rfp)
	const typeTags = getTagList(tagNames('type'))
	const areaOfLawTags = getTagList(tagNames('areaOfLaw'))
	const locationTags = getTagList(tagNames('location'))
	const serviceTags = getTagList(tagNames('service'))
	const sourcingTags = getTagList(tagNames('sourcing'))

	return (
		<Fragment>
			{typeTags && (
				<>
					<strong>Type:</strong> {typeTags}
					<span style={{ display: 'inline-block', marginRight: '0.75rem' }} />
				</>
			)}
			{areaOfLawTags && (
				<>
					<strong>Area of law:</strong> {areaOfLawTags}
					<span style={{ display: 'inline-block', marginRight: '0.75rem' }} />
				</>
			)}
			{locationTags && (
				<>
					<strong>Location:</strong> {locationTags}
					<span style={{ display: 'inline-block', marginRight: '0.75rem' }} />
				</>
			)}
			{serviceTags && (
				<>
					<strong>Service:</strong> {serviceTags}
					<span style={{ display: 'inline-block', marginRight: '0.75rem' }} />
				</>
			)}
			{sourcingTags && (
				<>
					<strong>Sourcing:</strong> {sourcingTags}
				</>
			)}
		</Fragment>
	)
}

type TaggerListProps = {
	processing?: boolean
	rfps: Array<any>
	emptyMessage: string
	pagination: ReactElement
	onItemClick: (id: string) => void
	onItemExclude?: (id: string) => (args?: any) => void
}

export const TaggerList = ({
	processing,
	rfps,
	emptyMessage,
	pagination,
	onItemClick,
	onItemExclude,
}: TaggerListProps) => {
	if (!rfps || rfps.length === 0) {
		return (
			<>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						height: '8rem',
						padding: '1.5rem',
						fontWeight: 600,
						// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
						color: '#777',
					}}
				>
					{emptyMessage}
				</div>
				{pagination}
			</>
		)
	}
	return (
		<>
			<List>
				{rfps.map((rfp) => (
					<ListItemButton key={rfp._id} onClick={() => onItemClick(rfp._id)} disabled={processing}>
						{onItemExclude && (
							<IconButton
								disabled={processing}
								size="small"
								onClick={(e) => {
									e.stopPropagation()
									onItemExclude(rfp._id)()
								}}
							>
								<ExcludeIcon />
							</IconButton>
						)}
						<ListItemText
							primary={
								<span style={{ display: 'block', maxWidth: '600px' }}>
									<span
										style={{
											// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
											color: '#666',
											fontSize: '0.8rem',
										}}
									>
										{rfp.orgName}
									</span>
									<br />
									<strong
										style={{
											// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
											color: '#444',
											fontWeight: 500,
										}}
									>
										{rfp.headline}
									</strong>
								</span>
							}
							secondary={
								<span style={{ display: 'block', maxWidth: '600px' }}>
									<span style={{ display: 'block', marginBottom: '0.5rem' }}>
										{renderSummary(rfp.summary)}
									</span>
									{tags(rfp)}
								</span>
							}
						/>
					</ListItemButton>
				))}
			</List>
			{pagination}
		</>
	)
}
