// @ts-strict-ignore
import { useEffect } from 'react'

import { ClientNavbar } from './client-navbar'
import { FirmNavbar } from './firm-navbar'
import { useSelector } from '@client/store'

import { graphql, useQuery } from '@persuit/ui-graphql'

import { Unauthorized } from '@persuit/ui-shared-components'
import { logger } from '@persuit/ui-logger'
import { GlobalStyles } from '@persuit/ui-components'

type NavbarProps = {
	notificationCount: number
}

export const NavbarContent = ({ notificationCount }: NavbarProps) => {
	const { isClientUser } = useSelector((state) => {
		return {
			isClientUser: state.auth.user?.org.orgType === 'CLIENT',
			userEmail: state.auth.user?.email ?? '',
			orgName: state.auth.user?.org.name ?? '',
			orgType: state.auth.user?.org.orgType ?? '',
			title: state.auth.user?.title ?? '',
			orgId: state.auth.user?.org._id ?? '',
		}
	})

	return (
		<>
			<GlobalStyles
				styles={{
					body: {
						[`@media screen`]: {
							paddingTop: '64px !important',
						},
					},
				}}
			/>
			{isClientUser ? (
				<ClientNavbar notificationCount={notificationCount} />
			) : (
				<FirmNavbar notificationCount={notificationCount} />
			)}
		</>
	)
}

const NOTIFICATIONS_QUERY = graphql(`
	query NotificationsForNavbar {
		getNotifications {
			_id
			createdAt
		}
	}
`)

const NOTIFICATIONS_UPDATED = graphql(`
	subscription NotificationUpdated {
		notificationUpdated {
			_id
			createdAt
		}
	}
`)

export const Navbar = () => {
	const { notificationsLastViewed } = useSelector((state) => ({
		notificationsLastViewed: new Date(state.userActivity.notificationsLastViewed),
	}))
	const { data, loading, error, subscribeToMore } = useQuery(NOTIFICATIONS_QUERY)

	useEffect(() => {
		if (!error) {
			return subscribeToMore({
				document: NOTIFICATIONS_UPDATED,
				updateQuery: (prev, { subscriptionData }) => {
					if (!subscriptionData.data) {
						return prev
					}

					const incomingNotification = subscriptionData.data.notificationUpdated || {}
					const prevNotifications = prev.getNotifications || []

					// Check if it exists - if so, update
					// otherwise add to top of list

					const alreadyCached = prevNotifications?.find((notification) => {
						return notification?._id?.toString() === incomingNotification?._id?.toString()
					})

					const filteredPrevNotifications = prevNotifications.filter((notification) => {
						return notification?._id?.toString() !== incomingNotification?._id?.toString()
					})

					if (alreadyCached) {
						return {
							...prev,
							getNotifications: [incomingNotification, ...filteredPrevNotifications],
						}
					}

					return {
						...prev,
						getNotifications: [incomingNotification, ...prevNotifications],
					}
				},
			})
		}
	}, [error, subscribeToMore])

	if (error) {
		logger.error(error, {
			extra: {
				location: 'Navigation Bar',
			},
		})
		return <Unauthorized />
	}

	return (
		<NavbarContent
			notificationCount={
				loading || !data
					? 0
					: data.getNotifications?.filter(
							(notification) =>
								notification?.createdAt &&
								new Date(notification?.createdAt) > notificationsLastViewed,
					  ).length ?? 0
			}
		/>
	)
}
