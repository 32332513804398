// eslint-disable-next-line no-restricted-imports
import { RfpDetailRfpQuery } from '@persuit/ui-graphql/hooks'
import { RequiredNonNullableKeys } from '@persuit/common-utils'

type Request = RequiredNonNullableKeys<NonNullable<RfpDetailRfpQuery['getRfp']>, 'detail'>

const isQuestionWithOptions = (questionType: string): boolean =>
	questionType === 'singleSelect' || questionType === 'multipleSelect' || questionType === 'scale'

type Answer = {
	type: string
	singleSelectAnswer: string
	multipleSelectAnswer: Array<string>
}

export const getAnswerOptionLabel = ({
	options,
	answer,
}: {
	options: NonNullable<NonNullable<Request['detail']['questions']>[number]>['options']
	answer: Answer
}): string | null => {
	if (!isQuestionWithOptions(answer.type)) {
		return null
	}

	if (!options || options.length === 0 || !answer?.singleSelectAnswer) {
		return null
	}

	const option = options.find(
		(option) => answer.singleSelectAnswer.toString() === option?._id?.toString(),
	)

	return option?.value ?? null
}

export const getAnswerOptionLabels = ({
	options,
	answer,
}: {
	options: NonNullable<NonNullable<Request['detail']['questions']>[number]>['options']
	answer: Answer
}): Array<string> => {
	if (!isQuestionWithOptions(answer.type)) {
		return []
	}

	if (!options || options.length === 0 || !answer?.multipleSelectAnswer) {
		return []
	}

	if (answer.type !== 'multipleSelect') {
		return []
	}

	const answerValues = answer.multipleSelectAnswer.map((answerId) => {
		const option = options.find((option) => answerId.toString() === option?._id?.toString())?.value
		return option ?? ''
	})

	return answerValues.filter((item) => !!item)
}
