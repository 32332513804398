import { useEffect, MutableRefObject } from 'react'
import { GridApi, GridRowModelUpdate } from '@persuit/ui-components'

type UseUndoRedoInput = {
	canUndo: boolean
	canRedo: boolean
	onUndo: () => void
	onRedo: () => void
	element: HTMLElement | null
}

/**
 * Listens to the keyboard for undo and redo events
 */
export const useUndoRedoKeyboardListener = ({
	onRedo,
	onUndo,
	canRedo,
	canUndo,
	element = document.body,
}: UseUndoRedoInput) => {
	useEffect(() => {
		if (!element) return

		function handler(e: KeyboardEvent) {
			if (canUndo && (e.metaKey || e.ctrlKey) && !e.shiftKey && e.key === 'z') {
				onUndo()
			}

			if (canRedo && (e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'z') {
				onRedo()
			} else if (canRedo && (e.metaKey || e.ctrlKey) && e.key === 'y') {
				onRedo()
			}
		}

		element.addEventListener('keydown', handler)
		return () => {
			element.removeEventListener('keydown', handler)
		}
	}, [onRedo, onUndo, canUndo, canRedo, element])
}

type UseClearSelectionListenerInput = {
	gridApiRef: MutableRefObject<GridApi>
	element?: HTMLElement | null
	callback?: () => void
	isCellEditable?: (input: { field: string; row: any }) => boolean
}

/**
 * Listens to the keyboard and clears the currently selected cells when the user presses backspace or delete
 */
export const useClearSelectionListener = ({
	gridApiRef,
	element,
	callback,
	isCellEditable: _isCellEditable,
}: UseClearSelectionListenerInput) => {
	useEffect(() => {
		if (!element) return

		const isCellEditable = _isCellEditable ?? (() => true)

		function handler(e: KeyboardEvent) {
			if (e.key === 'Backspace' || e.key === 'Delete') {
				const updates: GridRowModelUpdate[] = []
				const cellSelectionModel = gridApiRef.current.getCellSelectionModel()

				Object.entries(cellSelectionModel).forEach(([id, fields]) => {
					const row = gridApiRef.current.getRow(id)
					const updatedRow = { ...row }

					Object.entries(fields).forEach(([field, isSelected]) => {
						if (isSelected && isCellEditable({ field, row })) {
							updatedRow[field] = null
						}
					})

					updates.push(updatedRow)
				})

				gridApiRef.current.updateRows(updates)
				callback?.()
			}
		}

		element.addEventListener('keydown', handler)
		return () => {
			element.removeEventListener('keydown', handler)
		}
	}, [gridApiRef, element, callback, _isCellEditable])
}

type TargetDimensions = {
	rows: number
	cols: number
}

export function expandClipboard<T>(
	clipboard: T[][],
	{ rows: targetRows, cols: targetCols }: TargetDimensions,
): T[][] {
	const clipboardRows = clipboard.length
	const clipboardCols = clipboard[0].length

	const rowRepititions = Math.max(1, Math.floor(targetRows / clipboardRows))
	const colRepititions = Math.max(1, Math.floor(targetCols / clipboardCols))

	const result: T[][] = []

	for (let rowIndex = 0; rowIndex < rowRepititions * clipboardRows; rowIndex++) {
		const row: T[] = []
		for (let columnIndex = 0; columnIndex < colRepititions * clipboardCols; columnIndex++) {
			row.push(clipboard[rowIndex % clipboardRows][columnIndex % clipboardCols])
		}
		result.push(row)
	}

	return result
}
