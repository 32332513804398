import { useId } from 'react'
import { Divider, Typography, Box } from '@persuit/ui-components'

/**
 * Created for the confirm step in the wizard.
 * Identical to the OptionList component without interactivity.
 * Standardises the list and removes illegal HTML formating we once had.
 */
type SummaryListProps = {
	primaryText: string
	bullets: string[]
	dataTestid?: string
}

export default function SummaryList({ primaryText, bullets, dataTestid }: SummaryListProps) {
	const titleId = useId()

	return (
		<>
			<Box px={2} data-testid={dataTestid}>
				<Typography variant="body1Semibold" id={titleId}>
					{primaryText}
				</Typography>

				<ul
					aria-labelledby={titleId}
					style={{
						listStyleType: 'disc',
						paddingLeft: 0,
						listStylePosition: 'inside',
					}}
				>
					{bullets.map((bullet) => (
						<li key={bullet}>
							<Typography variant="body2" color="text.secondary">
								{bullet}
							</Typography>
						</li>
					))}
				</ul>
			</Box>

			<Divider />
		</>
	)
}
