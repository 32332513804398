import { HappyId } from '../../../happy-id'
import { RfpDetail, RfpDetailDocType } from '../../../rfp-detail'
import { Rfp } from '../../../types'
import {
	Box,
	VisibilityIcon,
	Typography,
	Paper,
	PersuitLogo,
	ScreenOnly,
	BoxProps,
	InfoIcon,
} from '@persuit/ui-components'
import { ConflictReveal } from '../conflict-reveal'
import { ConflictRevealContent } from '../conflict-reveal-content'
import { RfpTemplate } from '@persuit/ui-graphql/generated'
import { ExportToWordButton } from '../../../common-components'

const RFP_PREVIEW_ID = 'rfp-preview'

export type RfpPreviewDocType = RfpDetailDocType &
	Pick<Rfp, 'headline' | 'humanFriendlyId' | 'ccRequired' | 'useCase'> &
	Pick<RfpTemplate, 'templateVendor' | 'templateType' | 'templateTitle'>

export type RfpPreviewProps = {
	document: RfpPreviewDocType
	previewMode?: boolean
	containerProps?: BoxProps
	isTemplate?: boolean
}

export const RfpPreview = ({
	document,
	previewMode,
	containerProps,
	isTemplate = false,
}: RfpPreviewProps) => {
	const headline = document?.headline ?? ''
	const ccRequired = document?.ccRequired ?? false
	const isRateReview = document?.useCase === 'rateReview'

	const exportFileName = () => {
		if (document.humanFriendlyId) {
			return `${document.humanFriendlyId} - ${headline || 'DRAFT'}`
		} else if (document.templateType) {
			return `REQ TEMPLATE - ${document.templateTitle || 'DRAFT'}`
		}
	}

	return (
		<Box {...containerProps} id={RFP_PREVIEW_ID}>
			<Box display="none" displayPrint="block">
				<PersuitLogo />
			</Box>
			<Paper>
				<Box paddingX={2} paddingY={1}>
					{previewMode && (
						<Box
							display="flex"
							alignItems="center"
							color="text.secondary"
							gap={1}
							data-testid="rfp-template-author"
						>
							{document.templateType === 'system' ? (
								<Typography color="text.secondary">
									By&nbsp;<b>{document.templateVendor === 'CLOC' ? 'CLOC' : 'PERSUIT'}</b>
								</Typography>
							) : (
								<>
									<VisibilityIcon color="inherit" />
									<Typography variant="body1" fontStyle="italic">
										Preview
									</Typography>
								</>
							)}
						</Box>
					)}

					<Box display="flex">
						<Typography variant="h1XSmall" sx={{ my: 1, width: '75%' }}>
							{headline || <i>Untitled draft</i>}
						</Typography>
						<Box sx={{ width: '25%', alignSelf: 'center', textAlign: 'right' }} displayPrint="none">
							<ExportToWordButton fileName={exportFileName()} containerId={RFP_PREVIEW_ID} />
						</Box>
					</Box>

					{!previewMode && document.humanFriendlyId ? (
						<ScreenOnly>
							<HappyId
								humanFriendlyId={document.humanFriendlyId}
								googleAnalyticsAction={'COPY_HAPPYID_FROM_CLIENT_RFP_DETAIL_VIEW'}
							/>
						</ScreenOnly>
					) : null}

					{ccRequired && !isRateReview && (
						<Box data-wordexport="hide">
							<Box displayPrint="none" sx={{ mt: 2 }}>
								<ConflictReveal document={document} />
							</Box>
							<Box display="none" displayPrint="block">
								<ConflictRevealContent document={document} />
							</Box>

							<Box mb={1} display="flex" alignItems="center">
								<InfoIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
								<Typography variant="body1" color="primary" sx={{ fontStyle: 'italic', my: 3 }}>
									This is what the invited firms will see once they've cleared conflicts and/or
									accepted terms and/or signed an NDA
								</Typography>
							</Box>
						</Box>
					)}

					<RfpDetail document={document} isTemplate={isTemplate} />
				</Box>
			</Paper>
		</Box>
	)
}
