import { Fragment } from 'react'

import round from 'lodash/fp/round'
import {
	Box,
	Paper,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
} from '@persuit/ui-components'

import formatCurrency from './auction-price-format'

import { calculateKPIs, getComparisonValueRevisions, getTotalPriceRevisions } from './calculations'

export const roundOrNil = (amount, symbol = '%') => {
	if (isNaN(amount)) {
		return '-'
	}

	const rounded = round(amount) || 0
	const strValue = `${rounded}${symbol}`

	if (strValue === 'Infinity%') {
		return '-'
	}
	return strValue
}

const KeyPerformanceIndicators = ({ request, responses, currency }) => {
	const {
		startingSpreadPerc,
		startingSpread,
		endingSpreadPerc,
		priceReductionPerc,
		priceReduction,
		endingSpread,
	} = calculateKPIs({
		request,
		responses,
	})
	const totalPriceRequired = request?.detail?.totalPriceRequired
	const priceRevisions = totalPriceRequired
		? getTotalPriceRevisions({ request, responses })
		: getComparisonValueRevisions({ request, responses })

	return (
		<Fragment>
			<Paper elevation={1}>
				<Box pb={1}>
					<Box m={2} pt={1}>
						<Typography gutterBottom={true} variant="h3XSmall" id="kpi-table-label">
							Key performance indicators
						</Typography>
					</Box>
					<TableContainer>
						<Table aria-labelledby="kpi-table-label">
							<caption>This table has the key performance indicators for the request.</caption>
							<TableHead>
								<TableRow>
									<TableCell>Key performance indicator</TableCell>
									<TableCell align="right">Percentage</TableCell>
									<TableCell align="right">Amount</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{/* Price variance at auction start */}
								<TableRow>
									<TableCell component="th" scope="row">
										Price variance at auction start
									</TableCell>
									<TableCell align="right">{roundOrNil(startingSpreadPerc)}</TableCell>
									<TableCell align="right">{formatCurrency(startingSpread, currency)}</TableCell>
								</TableRow>

								{/* Price variance at auction end */}
								<TableRow>
									<TableCell component="th" scope="row">
										Price variation
									</TableCell>
									<TableCell align="right">{roundOrNil(endingSpreadPerc)}</TableCell>
									<TableCell align="right">{formatCurrency(endingSpread, currency)}</TableCell>
								</TableRow>

								{/* Average price reduction during auction */}
								<TableRow>
									<TableCell component="th" scope="row">
										Average price reduction from auction start
									</TableCell>
									<TableCell align="right">{roundOrNil(priceReductionPerc)}</TableCell>
									<TableCell align="right">{formatCurrency(priceReduction, currency)}</TableCell>
								</TableRow>

								{/* Price revisions */}
								<TableRow>
									<TableCell component="th" scope="row">
										Price revisions during auction
									</TableCell>
									<TableCell align="right">{/* Intentionally empty */}</TableCell>
									<TableCell align="right">{priceRevisions}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Paper>
		</Fragment>
	)
}

export default KeyPerformanceIndicators
