import { SettingsNavItem } from '@client/components/uam-nav/settings-nav-item'
import { useSelector } from '@client/store'

import {
	AppBar,
	Box,
	Chip,
	CloseIcon,
	Drawer,
	HelpOutlineOutlinedIcon,
	HomeIcon,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	MenuIcon,
	SecurityIcon,
	styled,
	Toolbar,
	useMediaQuery,
	useTheme,
	PersuitLogo,
	PersuitLogoSmall,
} from '@persuit/ui-components'
import { useId, useState } from 'react'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

import common from '../../../common/data/support'

import { RightNavActions } from './right-nav-actions'
import { ProfileProps } from './my-profile-menu'

type ChildrenProps = {
	closeDrawer: () => void
	isImpersonated: boolean
}

type NavBarProps = {
	notificationCount: number
	children?: (props: ChildrenProps) => React.ReactNode
	profileProps: ProfileProps
}

export const NavBar = ({ notificationCount, children = () => null, profileProps }: NavBarProps) => {
	const theme = useTheme()
	const smallScreen = useMediaQuery('@media (max-width: 650px)')

	const [openDrawer, setOpenDrawer] = useState(false)
	const toggleMenuDrawer = () => setOpenDrawer((s) => !s)
	const closeDrawer = () => setOpenDrawer(false)

	const isImpersonated = useSelector((state) => !!state.auth.user?.isImpersonated)

	return (
		<NoPrint>
			<StyledAppBar impersonated={isImpersonated}>
				<Toolbar
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
						paddingLeft: 8,
						paddingRight: 8,
					}}
				>
					<Box display="flex" alignItems="center">
						<IconButton
							style={{ color: 'white' }}
							onClick={toggleMenuDrawer}
							disableTouchRipple={true}
							size="large"
							aria-label={openDrawer ? 'close navigation drawer' : 'open navigation drawer'}
						>
							{openDrawer ? <CloseIcon /> : <MenuIcon />}
						</IconButton>

						<LogoLink
							to={`/en/`}
							aria-label="Homepage"
							sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
						>
							{smallScreen ? <PersuitLogoSmall /> : <PersuitLogo />}
						</LogoLink>
					</Box>

					<RightNavActions notificationCount={notificationCount} profileProps={profileProps} />
				</Toolbar>
			</StyledAppBar>

			<Drawer
				PaperProps={{
					style: { backgroundColor: theme.palette.deepPurple900, minWidth: '360px' },
				}}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
				<Box display="flex" alignItems="center" pl={2.5} p={1}>
					<IconButton
						sx={{ mr: '20px' }}
						style={{ color: 'white' }}
						onClick={toggleMenuDrawer}
						disableTouchRipple={true}
						size="large"
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={true}
						aria-label="Close Navigation drawer button. To activate press enter. Use Tab to navigate through items in the navigation panel"
					>
						{openDrawer ? <CloseIcon /> : <MenuIcon />}
					</IconButton>

					<PersuitLogo style={{ color: 'white' }} />
				</Box>

				<Box flexGrow={1} aria-label="Main" component="nav">
					<div role="list">
						<NavItem exact={true} to="/en/" onClick={closeDrawer} icon={<HomeIcon />}>
							Home
						</NavItem>

						{children({ closeDrawer, isImpersonated })}
						<SettingsNavItem onClick={closeDrawer} />

						<NavItem
							externalLink={true}
							to="https://support.persuit.com"
							onClick={closeDrawer}
							icon={<HelpOutlineOutlinedIcon />}
						>
							Help and FAQ
						</NavItem>

						<NavItem
							externalLink={true}
							to={common.privacyPolicyLink}
							onClick={closeDrawer}
							icon={<SecurityIcon />}
						>
							Privacy Policy
						</NavItem>
					</div>
				</Box>
			</Drawer>
		</NoPrint>
	)
}

export const NewChip = () => {
	return <Chip size="small" label="NEW" color="primary" style={{ marginLeft: '0.5rem' }} />
}

type NavItemProps = {
	children: React.ReactNode
	onClick: () => void
	icon: React.ReactNode
	to?: string
	externalLink?: boolean
	exact?: boolean
}

export const NavItem = ({ children, icon, onClick, to, externalLink, exact }: NavItemProps) => {
	const theme = useTheme()
	const menuId = useId()

	const content = (
		<>
			{' '}
			{icon ? <ListItemIcon style={{ color: 'white' }}>{icon}</ListItemIcon> : null}
			{children}
		</>
	)

	const styles = {
		height: '48px',
		color: `${theme.palette.grey50} !important`,
		borderLeft: 'transparent 4px solid !important',
		'a.active > &': {
			backgroundColor: `${theme.palette.deepPurple800} !important`,
			borderLeftColor: `${theme.palette.primary.main} !important`,
		},
		'&:focus, &:focus-visible': {
			backgroundColor: `${theme.palette.deepPurple800} !important`,
			borderLeftColor: `${theme.palette.primary.main} !important`,
		},
		'&:hover': {
			backgroundColor: `${theme.palette.deepPurple800} !important`,
			borderLeftColor: `${theme.palette.primary.main} !important`,
		},
	}

	return (
		<ListItem id={menuId} disablePadding={true} aria-labelledby={menuId}>
			{to ? (
				externalLink ? (
					<ListItemButton
						onClick={onClick}
						sx={styles}
						component="a"
						href={to}
						target="_blank"
						rel="noopener noreferrer"
					>
						{content}
					</ListItemButton>
				) : (
					<ListItemButton onClick={onClick} sx={styles} component={NavLink} to={to} exact={exact}>
						{content}
					</ListItemButton>
				)
			) : (
				<ListItemButton onClick={onClick} sx={styles}>
					{content}
				</ListItemButton>
			)}
		</ListItem>
	)
}

const LogoLink = styled(NavLink)({
	color: 'white',
	transition: '0.2s',
	padding: '0 0.3em',

	position: 'relative',

	'&:after': {
		transition: '0.2s',
		content: '""',
		display: 'inline-block',
		background: 'white',
		height: '1px',
		width: '100%',
		position: 'absolute',
		bottom: '-5px',
		left: 0,
		transform: 'scaleX(0)',
	},

	'&:hover:after': {
		transform: 'scaleX(1)',
	},
})

const NoPrint = styled(Box)({
	'@media print': {
		display: 'none',
	},
})

const StyledAppBar = styled(AppBar)<{ impersonated: boolean }>(({ impersonated, theme }) => ({
	position: 'fixed !important' as 'fixed',
	top: 0,
	flexFlow: 'row nowrap',
	backgroundColor: `${
		impersonated ? theme.palette.amber600 : theme.palette.deepPurple800
	} !important`,
	color: `${theme.palette.grey50} !important`,
}))
