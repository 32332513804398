import classnames from 'classnames'

import injectSheet from '../../injectSheet'
import persuitTheme from '../../theme/persuit-theme'

const Spacer = ({
	// Extract sheet form ...props to avoid warnings
	sheet,

	className,
	display,
	direction,
	space,
	classes,
	...props
}) => {
	const classNames = classnames(classes.spacer, className)
	const dimension = direction === 'column' ? 'height' : 'width'
	return (
		<i
			className={classNames}
			style={{
				[dimension]: `${(parseInt(persuitTheme.grid.desktopGutterLess, 10) / 2) * space}px`,
				display: display || direction === 'column' ? 'block' : 'inline-block',
			}}
			aria-hidden="true"
			{...props}
		/>
	)
}
Spacer.defaultProps = {
	space: 1,
	direction: 'column',
}

Spacer.styles = {
	spacer: {
		margin: 0,
		padding: 0,
		width: 0,
		height: 0,
		border: 'none',
		visibility: 'hidden',
		maxWidth: '100%',
	},
}

/**
 * @deprecated Use SX props with spacing values for consistent and responsive spacing.
 * https://mui.com/system/spacing/
 */
export default injectSheet(Spacer.styles)(Spacer)
