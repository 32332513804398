import { Box, BoxProps, Typography } from '@persuit/ui-components'

type NamwolfLabelProps = BoxProps

export const NamwolfLabel = (props: NamwolfLabelProps) => {
	return (
		<Box {...props}>
			<Typography
				variant="body2"
				style={{
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					background: '#F93800',
					color: 'black',
					padding: '0.25rem 0.5rem',
					fontSize: '0.8rem',
					fontWeight: 'bold',
					borderRadius: '0.25rem',
				}}
			>
				NAMWOLF
			</Typography>
		</Box>
	)
}
