import { gql, useMutation } from '@apollo/client'
import { Button } from '@persuit/ui-components'
import { isEmpty } from 'lodash/fp'

import { Fragment } from 'react'
import Text from '../../text.jsx'
import GET_NOTES_QUERY from './get-notes-query'

export const SAVE_NOTE_MUTATION = gql`
	mutation saveNote($saveNoteInput: SaveNoteInput!) {
		saveNote(saveNoteInput: $saveNoteInput) {
			id
			createdByName {
				first
				last
			}
			updatedAt
			noteContents
			isOwnNote
		}
	}
`

const SaveNotesButtonMutation = ({ preMutationCall, saveNoteInput, postMutationCall }) => {
	const { rfpId, firmId } = saveNoteInput
	const [saveNote, { loading, error }] = useMutation(SAVE_NOTE_MUTATION, {
		refetchQueries: [{ query: GET_NOTES_QUERY, variables: { rfpId, firmId } }],
		awaitRefetchQueries: true,
	})

	const handleSave = async () => {
		try {
			preMutationCall()
			await saveNote({ variables: { saveNoteInput } })
			postMutationCall()
		} catch (error) {
			console.error('SaveNotesButtonMutation error', error)
		}
	}

	const isMutationError = !isEmpty(error)
	const label = isMutationError ? 'Retry' : 'Save note'

	return (
		<Fragment>
			<Button
				data-testid="save-note-button"
				variant="contained"
				onClick={handleSave}
				loading={loading}
				sx={isMutationError && { marginBottom: '20px' }}
			>
				{label}
			</Button>
			{isMutationError && (
				<Text type="body2" red={true} style={{ position: 'absolute', bottom: '0' }}>
					There was some network error. Please retry!
				</Text>
			)}
		</Fragment>
	)
}

export default SaveNotesButtonMutation
