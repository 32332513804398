import proposalScoreActions from '../actions/proposal-scores'

const {
	OPEN_PROPOSAL_SCORE_FORM,
	CLOSE_PROPOSAL_SCORE_FORM,
	TOGGLE_SHOW_SUBCATEGORIES,
	TOGGLE_SHOW_CATEGORIES_FORM,
	OPEN_PROPOSAL_SCORE_SUBCATEGORIES,
	RESET_PROPOSAL_SCORES_STATE,
} = proposalScoreActions

const initialState = {
	// This will store the ID of the proposal currently having it's score edited
	idOfProposalBeingEdited: null,

	// Just stores if any proposal is currently being edited
	proposalScoreBeingEdited: false,

	showSubcategories: false,

	showEditCategoriesForm: false,
}

export default function proposalScoresReducer(state = initialState, action) {
	switch (action.type) {
		case OPEN_PROPOSAL_SCORE_FORM: {
			return Object.assign({}, state, {
				idOfProposalBeingEdited: action.proposalId,
				proposalScoreBeingEdited: true,
			})
		}

		case CLOSE_PROPOSAL_SCORE_FORM: {
			return Object.assign({}, state, {
				idOfProposalBeingEdited: null,
				proposalScoreBeingEdited: false,
			})
		}

		case OPEN_PROPOSAL_SCORE_SUBCATEGORIES: {
			return Object.assign({}, state, {
				showSubcategories: true,
			})
		}

		case TOGGLE_SHOW_SUBCATEGORIES: {
			return Object.assign({}, state, {
				showSubcategories: !state.showSubcategories,
			})
		}

		case TOGGLE_SHOW_CATEGORIES_FORM: {
			return Object.assign({}, state, {
				showEditCategoriesForm: !state.showEditCategoriesForm,
			})
		}

		case RESET_PROPOSAL_SCORES_STATE: {
			return Object.assign({}, initialState)
		}

		default: {
			return state
		}
	}
}
