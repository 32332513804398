import DialogWizardContent from '../../dialog-wizard-content'

import { stepIndex } from '../../utils'
import {
	ProposalQuickRevisionPricingForm,
	useProposalQuickRevisionStore,
} from '@persuit/ui-shared-components'

export const OptionQuickPriceRevisionUpdatePrices = ({ onNext, onCancel, onPrev }) => {
	const wizardSteps = useProposalQuickRevisionStore((_, selectors) =>
		selectors.getWizardStepDetails(),
	)

	return (
		<DialogWizardContent
			steps={wizardSteps}
			activeStep={'UPDATE_PRICES'}
			secondaryTextForSteps={{
				[stepIndex(wizardSteps, 'START')]: 'Quick price revision',
			}}
			onNext={() => {
				onNext()
			}}
			onPrev={onPrev}
			onCancel={onCancel}
		>
			<ProposalQuickRevisionPricingForm />
		</DialogWizardContent>
	)
}
