// @ts-strict-ignore
import i18next from 'i18next'

/**
 *
 * @param {string} translationPath path of the translation
 * @param {object} translationProps any extra properties needed by the translation like more then one entities, plural etc,
 * @description
 * The function returns the exact translation as per the path provided using i18next package
 *
 */
const translate = (translationPath, translationProps?: any) =>
	i18next.t(translationPath, translationProps)

/**
 *
 * @param {string} translationConfigList list of objects of shape { path: {string}, options: {object} }
 * @return {object} { t, translations }
 *
 * @description
 * This custom hook allows you to use translation in a file / component.
 * The return is an object with two properties
 *    -> t - to keep legacy translation call
 *    -> translations - array of object which takes path of all the translation with respect to a particular component
 *
 *
 * Then use the translation the same way it is used in other files,
 * e.g.
 *
 * 		**** Legacy support
 * 		t('sample.target.key'),
 * 		t('Number of items is', { count: numberOfItems })
 *
 * 		**** New
 * 		const { translations } = useTranslation([
 *   												{
 * 														path: 'path.to.translation',
 * 														options: {
 * 												                  	count: 2
 * 																 }
 * 													}
 * 												])
 *
 */

export type UseTranslationProps = {
	path: string
	options?: Record<string, unknown>
}[]

const useTranslation = (translationConfigList?: UseTranslationProps) => {
	const translations = (translationConfigList ?? []).map(({ path, options }) =>
		translate(path, options),
	)

	return {
		t: translate,
		translations,
	}
}

export { translate, useTranslation }

export default useTranslation
