import { useId } from 'react'
import { Box, Typography } from '@persuit/ui-components'

import { useActions, useStore } from '../../store'

import { RowHeaderCell, BaseIntelligenceCell, Row, SortButton, SortOrder } from '../components'
import { averageRateRowId, averageRateCellHeaders } from '../accessibilty'

import { AverageRateCell } from './average-rate-cell'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

export const AverageRateRow = () => {
	const {
		responses,
		showIntelligenceColumn,
		currentSort,
		isFirm,
		shouldShowAverageRate,
		isRatesOnlyRequest,
	} = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
		currentSort: state.sort,
		isFirm: state.isFirm,
		shouldShowAverageRate: s.shouldShowAverageRate(),
		isRatesOnlyRequest: s.isRatesOnlyRequest(),
	}))

	const { toggles } = useFeatureToggles()
	const isFeatureEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const titleId = useId()
	const { setSort } = useActions()

	const sortOrder = currentSort?.type === 'averageRate' ? currentSort.order : null
	const sortByAverageRate = (order: SortOrder) => {
		setSort({
			type: 'averageRate',
			order,
		})
	}

	const shouldHide = !isFeatureEnabled || !shouldShowAverageRate

	if (shouldHide) return null

	return (
		<Row>
			<RowHeaderCell cellProps={{ id: averageRateRowId, 'aria-labelledby': titleId }}>
				<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
					<Typography id={titleId}>Average of all rates</Typography>
					{!isFirm && <SortButton sortOrder={sortOrder} onClick={sortByAverageRate} />}
				</Box>
				<Typography variant="caption">
					{`This is the average of all rates provided in the pricing section.${
						isRatesOnlyRequest
							? ` This will be used to
					provide a price ranking.`
							: ''
					}`}
				</Typography>
			</RowHeaderCell>

			{showIntelligenceColumn && <BaseIntelligenceCell cellProps={{ 'aria-hidden': true }} />}

			{responses.map((response, index, array) => {
				return (
					<AverageRateCell
						showRightBorder={index === array.length - 1}
						headers={averageRateCellHeaders(response._id)}
						key={response._id}
						responseId={response._id}
						rate={response.averageRate?.rate ?? 0}
						originalRate={response.averageRate?.originalRate ?? undefined}
					/>
				)
			})}
		</Row>
	)
}
