// @ts-strict-ignore
import { Fragment } from 'react'
import { isBefore } from 'date-fns'
import { styled, Box, Typography } from '@persuit/ui-components'
import SavingsSummaryOld from '../../analytics/savings-summary.jsx'
import Spacer from '../../layout/spacer.jsx'
import { AuctionAnalytics } from './auction-analytics'
import formatCurrency from './auction-analytics/auction-price-format'
import { requestValidForComparisonValue } from '@persuit/ui-shared-components/src/common-util'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
const AnalyticsUnavailableMessage = styled('div')`
	min-height: 15rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;
	color: #999999;
`

export const AuctionAnalyticsOld = ({ request, responses, isCESEnabled, auctionInProgress }) => {
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']
	const { auction: isAuction, _id: requestId, proposalsDueBy } = request

	// If no total price values are available, don't show analytics
	// This can happen when an RFI (no total prices) is updated to an RFP with total prices
	// Proposals received during the RFI will have no total price
	const totalPriceRequired = request.detail.totalPriceRequired

	if (totalPriceRequired) {
		const someResponsesHaveTotalPrice = responses.some(
			(response) => response.totalPrices?.length >= 1,
		)
		if (isAuction && !someResponsesHaveTotalPrice) {
			return (
				<Box m={4}>
					<Typography variant="h2XSmall">Auction analytics</Typography>
					<AnalyticsUnavailableMessage>
						{'Analytics is unavailable when no total price is requested'}
					</AnalyticsUnavailableMessage>
				</Box>
			)
		}
	}

	// TODO: DEV-9900: Remove this check once rate card auction improvements are enabled
	// Since we can now show analytics for all types of auctions
	if (!totalPriceRequired && !isRateCardAuctionEnabled) {
		const rfpInvalidForComparisonValue = !requestValidForComparisonValue(
			request.detail.deliverablesV2,
		)
		const someResponsesHaveComparisonValue = responses.some(
			(response) => response.comparisonValues?.length >= 1,
		)
		if (isAuction && (!someResponsesHaveComparisonValue || rfpInvalidForComparisonValue)) {
			return (
				<Box m={4}>
					<Typography variant="h2XSmall">Auction analytics</Typography>
					<AnalyticsUnavailableMessage>
						{'Analytics is unavailable when no comparison value is available.'}
					</AnalyticsUnavailableMessage>
				</Box>
			)
		}
	}

	const noProposalsRecieved = responses.length === 0
	const isBeforeAuctionStart = isBefore(new Date(), proposalsDueBy)

	// If no responses are received, don't show analytics
	if (isAuction && noProposalsRecieved) {
		return (
			<Box m={4}>
				<Typography variant="h2XSmall">Auction analytics</Typography>
				<AnalyticsUnavailableMessage>
					Analytics is only available once proposals are received.
				</AnalyticsUnavailableMessage>
			</Box>
		)
	}

	// If the auction hasn't started yet, don't show analytics
	if (isAuction && isBeforeAuctionStart) {
		return (
			<Box m={4}>
				<Typography variant="h2XSmall">Auction analytics</Typography>
				<AnalyticsUnavailableMessage>
					Analytics is only available after the auction starts.
				</AnalyticsUnavailableMessage>
			</Box>
		)
	}

	return (
		<Box m={4}>
			{!isCESEnabled && (
				<>
					<Typography variant="h2XSmall">Savings summary</Typography>
					<SavingsSummaryOld
						requestId={requestId}
						condensed={true}
						currencyFormatFunction={formatCurrency}
					/>
				</>
			)}

			{isAuction && (
				<Fragment>
					<Spacer space={6} />
					<AuctionAnalytics
						request={request}
						responses={responses}
						auctionInProgress={auctionInProgress}
					/>
				</Fragment>
			)}
		</Box>
	)
}
