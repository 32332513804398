import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { graphql, useMutation } from '@persuit/ui-graphql'
import { SiteReachabilityReportInput } from '@persuit/ui-graphql/generated'
import { useHandleUnexpectedError } from '@persuit/ui-hooks'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const REPORT_SITE_REACHABILTY_MUTATION = graphql(`
	mutation ReportSiteReachability($reports: [SiteReachabilityReportInput!]!) {
		reportSiteReachability(reports: $reports) {
			success
		}
	}
`)

// This is used if the feature toggle values aren't available from LD
export const LAUNCH_DARKLY_FALLBACK =
	'https://app.launchdarkly.com/sdk/goals/63057c125606e112e462cac5'

export function ReachableSitesReporter() {
	const { toggles } = useFeatureToggles()
	const isLoggedIn = useSelector(getIsLoggedIn)
	const reachableUrlsCheckList = toggles['operational.reachable-urls-check-list']
	const [reportSiteReachability] = useMutation(REPORT_SITE_REACHABILTY_MUTATION)
	const handleUnexpectedError = useHandleUnexpectedError()

	useEffect(() => {
		const siteUrls = splitSiteUrls(reachableUrlsCheckList)
		if (!isLoggedIn || isEmpty(siteUrls)) {
			return
		}

		const siteRequests = siteUrls.map((url) => fetch(url, { method: 'HEAD' }))

		Promise.allSettled(siteRequests)
			.then((settledResults) => buildSiteReachabilityReports(siteUrls, settledResults))
			.then((reports) => reportSiteReachability({ variables: { reports } }))
			.catch(handleUnexpectedError)
	}, [handleUnexpectedError, isLoggedIn, reachableUrlsCheckList, reportSiteReachability])

	return null
}

/**
 * A selector that returns whether the user is logged in or not
 */
function getIsLoggedIn(state: { auth: { isLoggedIn: boolean } }): boolean {
	return state.auth.isLoggedIn
}

function buildSiteReachabilityReports(
	siteUrls: readonly string[],
	settledResults: readonly PromiseSettledResult<Response>[],
): SiteReachabilityReportInput[] {
	return settledResults.map((settledResult, idx) => {
		return getSiteStatus(siteUrls[idx], settledResult)
	})
}

function getSiteStatus(
	url: string,
	settledResult: PromiseSettledResult<Response>,
): SiteReachabilityReportInput {
	if (settledResult.status === 'fulfilled') {
		return { url, reachable: true, statusCode: settledResult.value.status }
	} else {
		return { url, reachable: false, statusCode: null }
	}
}

function splitSiteUrls(toggleValue = LAUNCH_DARKLY_FALLBACK): string[] {
	return toggleValue
		.split(',')
		.map((url) => url.trim())
		.filter((url) => !isEmpty(url))
}
