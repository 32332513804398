import { SROnly, styled, Typography } from '@persuit/ui-components'
import pricingModels from '../../../../common/data/pricing-models'

const StyledRedText = styled(Typography)(({ theme }) => ({
	display: 'block',
	color: theme.palette.error.main,
}))

const StyledGreenText = styled(Typography)(({ theme }) => ({
	display: 'block',
	color: theme.palette.success.main,
}))

const arrowUp = '▲'
const arrowDown = '▼'

type PriceDeltaProps = {
	formattedDifferenceAmount: string
	differencePercentage: number
	direction: string
	pricingModel?: string
}

export const PriceDelta = ({
	formattedDifferenceAmount,
	differencePercentage,
	direction,
	pricingModel,
}: PriceDeltaProps) => {
	const upNode = (
		<>
			<SROnly>Increased by: </SROnly>
			<span aria-hidden="true">{arrowUp}&nbsp;</span>
		</>
	)
	const downNode = (
		<>
			<SROnly>Reduced by: </SROnly>
			<span aria-hidden="true">{arrowDown}&nbsp;</span>
		</>
	)

	switch (direction) {
		case 'up':
			if (pricingModel === pricingModels.DISCOUNTPERCENTAGE) {
				return (
					<StyledGreenText variant="body2" data-testid="up-delta">
						{upNode}
						{`${differencePercentage}%`}
					</StyledGreenText>
				)
			}

			return (
				<StyledRedText variant="body2" data-testid="up-delta">
					{upNode}
					{formattedDifferenceAmount
						? `${formattedDifferenceAmount} (${differencePercentage}%)`
						: `${differencePercentage}%`}
				</StyledRedText>
			)
		case 'down':
			if (pricingModel === pricingModels.DISCOUNTPERCENTAGE) {
				return (
					<StyledRedText variant="body2" data-testid="up-delta">
						{downNode}
						{`${differencePercentage}%`}
					</StyledRedText>
				)
			}

			return (
				<StyledGreenText variant="body2" data-testid="down-delta">
					{downNode}
					{formattedDifferenceAmount
						? `${formattedDifferenceAmount} (${differencePercentage}%)`
						: `${differencePercentage}%`}
				</StyledGreenText>
			)
		default:
			return null
	}
}
