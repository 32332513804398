import { not } from '../type-guard'
import { RequiredNonNullableKeys } from '../type-utils'

export function isNil<T>(value: T | undefined | null): value is undefined | null {
	return value === null || value === undefined
}

export const isNotNil = not(isNil)

export const isNotNilProp = <
	T extends Record<string | number | symbol, unknown>,
	K extends keyof T,
>(
	key: K,
): ((value: T) => value is RequiredNonNullableKeys<T, K>) => {
	return (value: T): value is RequiredNonNullableKeys<T, K> => isNotNil(value[key])
}

export const defaultNilProp = <T extends Record<string, unknown>, K extends keyof T>(
	key: K,
	defaultValue: Exclude<T[K], null | undefined>,
): ((value: T) => RequiredNonNullableKeys<T, K>) => {
	return (value: T): RequiredNonNullableKeys<T, K> => {
		return (
			isNotNilProp(key)(value)
				? value
				: {
						...value,
						[key]: defaultValue,
				  }
		) as RequiredNonNullableKeys<T, K>
	}
}
