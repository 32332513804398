// @ts-strict-ignore
import { Fragment } from 'react'
import { sortBy, size } from 'lodash/fp'

import {
	CancelIcon as ExcludeIcon,
	Divider,
	Button,
	List,
	ListItem,
	ListItemText,
	Chip,
} from '@persuit/ui-components'

import { Search } from './tagger-search'
import { TaxonomyItem, constructChildren } from './tagger-taxonomy-item'

export const Taxonomy = ({
	searchTerm,
	taxonomy,
	toggleSelected,
	toggleExpand,
	selected,
	expanded,
	handleConfirm,
	handleCancel,
	handleSearch,
	handleExclude,
	disableSort = false,
	allowParentSelection = false,
}) => {
	const processing = false
	const sortedTaxonomy = disableSort ? taxonomy : sortBy(['name'], taxonomy)

	return (
		<div style={{ height: 'calc(100vh - 184px)', display: 'flex', flexDirection: 'column' }}>
			<Search searchTerm={searchTerm} onChange={handleSearch} />
			<div style={{ flex: 1, overflowY: 'auto' }}>
				<List>
					{size(taxonomy) === 0 && (
						<ListItem disabled={true}>
							<ListItemText primary="No tags found" />
						</ListItem>
					)}
					{sortedTaxonomy.map((item) => (
						<Fragment key={item.code}>
							<TaxonomyItem
								item={item}
								expanded={expanded}
								selected={selected}
								toggleExpand={toggleExpand}
								toggleSelected={toggleSelected}
								allowParentSelection={allowParentSelection}
							/>
							{constructChildren(
								toggleExpand,
								expanded,
								toggleSelected,
								selected,
								item,
								allowParentSelection,
							)}
						</Fragment>
					))}
				</List>
			</div>
			<Divider />
			<div
				style={{
					background: 'white',
					padding: '1rem',
					overflowY: 'auto',
				}}
			>
				{selected.map((item) => (
					<Chip
						key={item.code}
						style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
						label={item.name}
						onDelete={() => toggleSelected(item)}
					/>
				))}
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
				{handleExclude && (
					<Button
						style={{ marginRight: 'auto' }}
						onClick={handleExclude}
						color="primary"
						disabled={processing}
					>
						<ExcludeIcon style={{ width: '1rem', height: '1rem', marginRight: '0.25rem' }} />
						Exclude
					</Button>
				)}
				<Button style={{ marginRight: '1rem' }} onClick={handleCancel} disabled={processing}>
					Close
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={selected.length === 0 || processing}
					onClick={handleConfirm}
				>
					Update tags
				</Button>
			</div>
		</div>
	)
}
