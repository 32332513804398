import { Button, VisibilityIcon } from '@persuit/ui-components'

type PreviewButtonProps = {
	openPreview: () => void
}

export const PreviewButton = ({ openPreview }: PreviewButtonProps) => {
	return (
		<Button
			data-trackid="button-preview"
			startIcon={<VisibilityIcon />}
			onClick={openPreview}
			variant="text"
			color="primary"
		>
			Preview
		</Button>
	)
}
