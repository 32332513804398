import { LockIcon, Avatar, Typography } from '@persuit/ui-components'
import { ChannelTypes } from '@persuit/ui-graphql/generated'

export type ChannelAvatarProps = {
	channelName: string
	channelType: ChannelTypes
}

export const ChannelAvatar = ({ channelName, channelType }: ChannelAvatarProps) => {
	if (channelType === 'CLIENT_TO_FIRM_CHANNEL') {
		const channelNameAbreviation = channelName
			.split(' ')
			.map((word) => word[0])
			.join('')
			.slice(0, 3)
			.toUpperCase()

		return (
			<Avatar sx={{ bgcolor: 'background.greyLabel' }}>
				<Typography variant="body1Semibold">{channelNameAbreviation}</Typography>
			</Avatar>
		)
	}

	return (
		<Avatar sx={{ bgcolor: 'primary.lightHue' }}>
			<LockIcon fontSize="small" sx={{ color: 'primary.contrastText' }} />
		</Avatar>
	)
}
