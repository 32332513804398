import { Tab as MuiTab, TabProps as MuiTabProps, TabTypeMap } from '@mui/material'
import { Box } from '../box'
import { ReportProblemIcon } from '../../icons'
import { composeSx } from '@persuit/ui-utils'
import { useDescription } from '@persuit/ui-hooks'

export type TabProps<
	D extends React.ElementType = TabTypeMap['defaultComponent'],
	P = object,
> = MuiTabProps<D, P> & {
	error?: boolean
}

export const Tab = <D extends React.ElementType = TabTypeMap['defaultComponent'], P = object>({
	error,
	label,
	sx,
	...rest
}: TabProps<D, P>) => {
	const errorDescription = useDescription(`Errors in ${label} tab`)

	return (
		<MuiTab
			{...rest}
			aria-describedby={error ? errorDescription : undefined}
			label={
				!error ? (
					label
				) : (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<ReportProblemIcon color="error" fontSize="small" sx={{ mr: 1 }} />
						{label}
					</Box>
				)
			}
			sx={composeSx(
				sx,
				!error
					? {}
					: (theme) => ({
							color: `${theme.palette.error.main}`,
							fontWeight: 400,
							'&.Mui-selected': {
								color: `${theme.palette.error.main}`,
								fontWeight: 600,
							},
					  }),
			)}
		/>
	)
}

export { Tabs } from '@mui/material'
export type { TabsProps } from '@mui/material'
export { TabContext, TabList, TabPanel } from '@mui/lab'
