import { useState } from 'react'
import * as React from 'react'
import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from './text-field'
import { Override } from '../../utilities'
import { useDebouncedCallback } from 'use-debounce'

export type TextFieldDebouncedProps = Override<
	MUITextFieldProps,
	{
		id: MUITextFieldProps['id']
		label: MUITextFieldProps['label']
	}
>
/**
 * This component is created to patch performance issue in existing Pricing & Questions form and shall only be used in exceptional case.
 * Always default to normal TextField
 */
export const TextFieldDebounced = React.forwardRef(
	({ variant = 'standard', ...props }: TextFieldDebouncedProps, ref: React.Ref<HTMLDivElement>) => {
		const { value, onChange } = props
		const [state, setState] = useState(value)
		const debouncedCallback = useDebouncedCallback((value) => onChange?.(value), 500)

		return (
			<MUITextField
				variant={variant}
				{...props}
				ref={ref}
				value={state}
				onChange={(e) => {
					setState(e.target.value)

					debouncedCallback(e)
				}}
			/>
		)
	},
)

TextFieldDebounced.displayName = 'TextFieldDebounced'
