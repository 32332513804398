import { PricingItemFieldPath, useWatch } from '../../../pricing-form-utils'
import { usePricingListContext } from '../../../pricing-list-context'
import { PricingItemHeader } from './pricing-item-header'

type PricingItemHeaderContainerProps = {
	id: string
	fieldPath: PricingItemFieldPath
}

export const PricingItemHeaderContainer = ({ fieldPath, id }: PricingItemHeaderContainerProps) => {
	const { getPricingItemIndex } = usePricingListContext()
	const title = useWatch({
		name: `${fieldPath}.deliverableTitle`,
	})

	const pricingModel = useWatch({
		name: `${fieldPath}.pricingPreferences`,
	})

	const subItemsCount =
		useWatch({
			name: `${fieldPath}.rates`,
		})?.length ?? 0

	return (
		<PricingItemHeader
			title={title ?? ''}
			pricingModel={pricingModel ?? ''}
			subItemsCount={subItemsCount}
			pricingItemIndex={getPricingItemIndex(id)}
		/>
	)
}
