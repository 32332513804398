import { Box, Table, TableHead, TableRow, TableBody } from '@persuit/ui-components'
import { isNotNil } from '@persuit/common-utils'
import { ResponseToDeliverableHistory } from '@persuit/ui-graphql/generated'
import { RateCardTableRow } from './rate-card-table-row'

type RateCardHistoryTableProps = {
	priceHistories: ResponseToDeliverableHistory[]
	currency: string
	deliverableNumber: number
}

export const RateCardHistoryTable = ({
	priceHistories,
	currency,
	deliverableNumber,
}: RateCardHistoryTableProps) => {
	if (priceHistories.length === 0) {
		return null
	}

	return (
		<Table
			size="small"
			sx={{
				'& caption': {
					pt: 1.5,
					pb: 0,
					px: 0,
				},
			}}
		>
			<caption>Rate card price history</caption>
			<TableHead sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.border.main}` })}>
				<TableRow
					component={'tr'}
					sx={{
						'& th': {
							py: 1.5,
						},
					}}
				>
					<Box component={'th'} sx={{ typography: 'body1Semibold', textAlign: 'start' }}>
						Last updated
					</Box>
					<Box component={'th'} sx={{ typography: 'body1Semibold', textAlign: 'start' }}>
						Rate card title
					</Box>
					<Box component={'th'} sx={{ typography: 'body1Semibold', textAlign: 'end' }}>
						Price
					</Box>
				</TableRow>
			</TableHead>
			<TableBody>
				{priceHistories.map(({ _id, rates, notIncluded, updatedAt }) => (
					<RateCardTableRow
						key={_id}
						rates={rates.filter(isNotNil)}
						currency={currency}
						notIncluded={notIncluded}
						updatedAt={updatedAt}
						deliverableNumber={deliverableNumber}
					/>
				))}
			</TableBody>
		</Table>
	)
}
