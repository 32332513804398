import numeral from 'numeral'

type PercentageTextProps = {
	value: string
}

export const PercentageText = ({ value }: PercentageTextProps) => {
	// when value is 0 or null show "—" Em dash
	if ([null, undefined, ''].includes(value)) {
		return <span>-</span>
	}

	const formattedDisplayValue = Number.isInteger(parseInt(value, 10))
		? numeral(value).format('0.[00]')
		: value

	// example: 12 %
	// The space below is a narrow no-break space
	return <span aria-label={`${formattedDisplayValue}%`}>{formattedDisplayValue}&#8239;%</span>
}
