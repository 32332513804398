import { useId } from 'react'
import filesize from 'filesize'

import { SROnly } from '../../sr-only'
import { FileDownloadIcon, AttachmentIcon } from '../../../icons'
import { Typography } from '../../typography'
import { ListItem, ListItemIcon, ListItemText, ListItemButton, ListItemProps } from '../../list'
import { Link } from '../../link'
import { checkFileStatusClean } from '../utils'
import { Box } from '../../box'

export type FileDownloadProps = ListItemProps & {
	file: {
		_id: string
		filename?: string | null
		length?: number | null
		fileStatus?: string | null
	}
}

export const FileDownload = ({
	file: { _id, fileStatus, filename, length },
	...rest
}: FileDownloadProps) => {
	const labelId = useId()

	const clean = !fileStatus || checkFileStatusClean(fileStatus)

	if (!clean) return null

	return (
		<>
			<ListItem
				disablePadding={true}
				aria-labelledby={`${labelId}_label ${labelId}_file`}
				data-wordexport="hide"
				{...rest}
			>
				<SROnly id={`${labelId}_file`}>File</SROnly>
				<ListItemButton
					LinkComponent={Link}
					href={`/en/file/${_id}`}
					sx={{
						display: 'flex',
						width: '100%',
						p: 1,
						alignItems: 'center',
						bgcolor: 'action.selected',
					}}
				>
					<ListItemIcon>
						<AttachmentIcon />
					</ListItemIcon>
					<ListItemText>
						<SROnly>Download</SROnly>
						<Typography
							variant="body1"
							color="primary.main"
							sx={{
								wordBreak: 'break-word',
							}}
							data-testid="download-file-details"
							id={`${labelId}_label`}
						>
							{filename}
							<Typography variant="caption" color="primary.main">
								&nbsp;&nbsp;&nbsp;{filesize(length ?? 0)}
							</Typography>
						</Typography>
					</ListItemText>

					<ListItemIcon
						sx={{
							p: 0,
							m: 0,
							minWidth: 0,
						}}
					>
						<FileDownloadIcon />
					</ListItemIcon>
				</ListItemButton>
			</ListItem>
			{/* Word export only */}
			<Box component="li" display="none">
				{filename}
			</Box>
		</>
	)
}
