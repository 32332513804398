import { useMutation } from '@apollo/client'
import { graphql } from '@persuit/ui-graphql'
import { styled, useSnackbar } from '@persuit/ui-components'
import { trackWithPendo } from '../../../../track-event'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils'

export const RECLOSE_RFP_FOR_FIRM = graphql(`
	mutation ManageProposal_CloseRfpForFirm($_id: ID!, $firmOrgId: ID!) {
		closeRfpForFirm(_id: $_id, firmOrgId: $firmOrgId) {
			_id
			invites {
				isReOpened
			}
		}
	}
`)

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

function OptionCloseRequestConfirm({
	onCancel,
	onDone,
	onPrev,
	firmId,
	firmName,
	requestId,
	fetchResponses,
}) {
	const [closeRfpForFirm, { loading, error }] = useMutation(RECLOSE_RFP_FOR_FIRM)
	const { openSnackbar } = useSnackbar()
	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Close Request for this Firm',
			}}
			isWorking={loading}
			onConfirm={async () => {
				await closeRfpForFirm({ variables: { _id: requestId, firmOrgId: firmId } })
				trackWithPendo('manage_proposal::close_request_for_firm', { requestId, firmId })
				openSnackbar(`Request closed for ${firmName}`)
				onDone()
				fetchResponses() // Reload values from resolver
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Are you sure you want to close request for this firm?</strong>
			<StyledList>
				<li>
					<Text component="p" type="body1" black={true}>
						Stop the firm from revising their proposal or submitting new proposals.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						Other firms will not be notified.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						An email will be sent to the firm.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						You can reopen the Request for revisions at any time.
					</Text>
				</li>
			</StyledList>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionCloseRequestConfirm
