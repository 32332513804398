export {
	/** @deprecated Use Accordion Instead*/
	Accordion as MuiAccordion,
	/** @deprecated Use AccordionDetails Instead*/
	AccordionDetails as MuiAccordionDetails,
	/** @deprecated Use AccordionSummary Instead*/
	AccordionSummary as MuiAccordionSummary,
	AccordionActions,
} from '@mui/material'
export type {
	AccordionActionsProps,
	AccordionProps as MUIAccordionProps,
	AccordionDetailsProps as MUIAccordionDetailsProps,
	AccordionSummaryProps as MUIAccordionSummaryProps,
} from '@mui/material'

import { createContext, useContext, useState } from 'react'
import * as React from 'react'

import { useDescription } from '@persuit/ui-hooks'

import { Paper } from '../paper'
import { Box, BoxProps } from '../box'
import { IconButton, IconButtonProps } from '../icon-button'
import { ExpandMoreIcon, ExpandLessIcon, ErrorOutlineIcon } from '../../icons'
import { Collapse, CollapseProps } from '../collapse'
import { Badge, BadgeProps } from '../badge'
import { SxProps } from '../../'
import { SROnly } from '../sr-only'

import { noop, getOrGenerateUUID } from '@persuit/common-utils'

type SetState<T> = React.Dispatch<React.SetStateAction<T>>

function useControlledState<T>(
	defaultValue: T,
	value?: T,
	onChange: (value: T) => void = noop,
): [T, SetState<T>] {
	const [state, setState] = useState(defaultValue)

	return typeof value === 'undefined'
		? [state, setState]
		: [
				value,
				(action) => {
					onChange(typeof action === 'function' ? (action as any)(value) : action)
				},
		  ]
}

type AccordionContext = {
	expanded: boolean
	setExpanded: SetState<boolean>
	toggleExpanded: () => void
	id: string
	contentId: string
	controlId: string
	headerId: string
}

const AccordionContext = createContext(null as unknown as AccordionContext)

const useAccordionContext = () => useContext(AccordionContext)

export type AccordionProps<T = unknown> = T & {
	style?: React.CSSProperties
	sx?: SxProps
	ContainerComponent?: React.ComponentType<T>
	initialExpanded?: boolean
	expanded?: boolean
	onChange?: (expanded: boolean) => void
	id?: string
	children?: React.ReactNode
}

export const Accordion = <T,>({
	ContainerComponent,
	initialExpanded = false,
	children,
	expanded: expandedProp,
	onChange,
	id: idProp,
	...rest
}: AccordionProps<T>) => {
	const Wrapper: any = ContainerComponent ?? Paper
	const [expanded, setExpanded] = useControlledState(initialExpanded, expandedProp, onChange)

	const id = idProp ?? getOrGenerateUUID()

	return (
		<AccordionContext.Provider
			value={{
				expanded,
				setExpanded,
				toggleExpanded: () => setExpanded((expanded) => !expanded),
				id,
				contentId: `${id}-content`,
				controlId: `${id}-control`,
				headerId: `${id}-header`,
			}}
		>
			<Wrapper {...rest} id={id}>
				{children}
			</Wrapper>
		</AccordionContext.Provider>
	)
}

export type AccordionSummaryProps = Omit<BoxProps, 'children'> & {
	IconButtonProps?: IconButtonProps & { 'data-testid'?: string }
	disableContainerClick?: boolean
	/**
	 * Override the label of the expand toggle button. By default it will be "[Expand|Collapse] [summary]".
	 *
	 * Consider using a more descriptive summary or using `<AccordionSummary aria-label="...">` before using `iconButtonLabel`.
	 */
	iconButtonLabel?: string | ((expanded: boolean) => string)
	showIconButtonBadge?: boolean
	iconButtonBadgeProps?: BadgeProps
	children?: React.ReactNode | ((props: AccordionSummaryChildrenProps) => React.ReactNode)
}

type AccordionSummaryChildrenProps = {
	headerId: string
	expanded: boolean
}

export const AccordionSummary = ({
	children,
	sx,
	disableContainerClick,
	IconButtonProps,
	iconButtonLabel,
	iconButtonBadgeProps,
	showIconButtonBadge,
	...rest
}: AccordionSummaryProps) => {
	const { expanded, toggleExpanded, headerId, controlId, contentId } = useAccordionContext()

	const descriptionId = useDescription(expanded ? 'Collapse' : 'Expand')

	const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
	const isUsingRenderProps = typeof children === 'function'

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<Box
			{...rest}
			sx={{
				position: 'relative',
				minHeight: '48px',
				px: 2,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				cursor: disableContainerClick ? 'unset' : 'pointer',
				...sx,
			}}
			onClick={disableContainerClick ? undefined : toggleExpanded}
		>
			<Box flexGrow={1} minWidth={0} id={isUsingRenderProps ? undefined : headerId}>
				{isUsingRenderProps ? children({ headerId, expanded }) : children}
			</Box>

			<IconButton
				color="primary"
				tabIndex={0}
				aria-expanded={expanded}
				aria-controls={contentId}
				id={controlId}
				onClick={(e) => {
					if (!disableContainerClick) {
						e.stopPropagation()
					}
					toggleExpanded()
				}}
				aria-label={
					typeof iconButtonLabel === 'string' ? iconButtonLabel : iconButtonLabel?.(expanded)
				}
				aria-labelledby={
					iconButtonLabel || IconButtonProps?.['aria-label']
						? undefined
						: `${descriptionId} ${headerId}`
				}
				{...IconButtonProps}
			>
				{showIconButtonBadge ? <Badge {...iconButtonBadgeProps}>{icon}</Badge> : icon}
			</IconButton>
		</Box>
	)
}

export type AccordionDetailsProps = BoxProps & Pick<CollapseProps, 'unmountOnExit'>

export const AccordionDetails = ({
	children,
	sx,
	unmountOnExit = true,
	...rest
}: AccordionDetailsProps) => {
	const { expanded, headerId, contentId } = useAccordionContext()

	return (
		<Collapse in={expanded} unmountOnExit={unmountOnExit}>
			<Box
				sx={{ px: 2, pb: 2, pt: 1, ...sx }}
				role="region"
				id={contentId}
				{...rest}
				aria-labelledby={`${headerId}${
					rest['aria-labelledby'] ? ` ${rest['aria-labelledby']}` : ''
				}`}
			>
				{children}
			</Box>
		</Collapse>
	)
}

type AccordionErrorProps = BoxProps & {
	message?: string
	error?: boolean
}

export const AccordionError = ({
	message = 'Error presents - ',
	error,
	...rest
}: AccordionErrorProps) => {
	if (!error) return null

	return (
		<Box position="relative" {...rest}>
			<ErrorOutlineIcon
				color="error"
				aria-hidden={true}
				sx={{
					position: 'absolute',
					transform: `translate(-50%, -50%)`,
					top: 0,
					left: 0,
				}}
			/>
			<SROnly>{message}</SROnly>
		</Box>
	)
}
