import { Box, BoxProps, Spacer, useTheme } from '@persuit/ui-components'

const BORDER_SIZE = 4

type RevealContentProps = BoxProps & {
	revealed: boolean
}

export const RevealContent = ({ revealed, children, ...props }: RevealContentProps) => {
	const theme = useTheme()

	if (!revealed) {
		return null
	}

	return (
		<Box
			{...props}
			sx={{
				...props.sx,
				marginBottom: '1em',
				paddingBottom: '0.5em',
				borderRadius: '2px',

				borderLeft: `${BORDER_SIZE}px solid ${theme.palette.primary.main}`,
				marginLeft: `-${BORDER_SIZE * 3}px`, // Offset border
				paddingLeft: `${BORDER_SIZE * 2}px`,
			}}
		>
			<Spacer shape="column" space={2} />
			{children}
		</Box>
	)
}

export default RevealContent
