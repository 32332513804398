// @ts-strict-ignore
import { AnyAction, applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import {
	useSelector as useSelectorOrig,
	useDispatch as useDispatchOrig,
	TypedUseSelectorHook,
} from 'react-redux'

import reducers from './reducers'

const devToolsEnhancer = window.devToolsExtension ? window.devToolsExtension() : (f) => f

export const store = createStore(
	reducers,
	compose(applyMiddleware(thunkMiddleware), devToolsEnhancer),
)

if (module.hot) {
	module.hot.accept('./reducers', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const nextRootReducer = require('./reducers/index').default
		store.replaceReducer(nextRootReducer)
	})
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>

export const useDispatch: () => AppDispatch = useDispatchOrig
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorOrig

export default store
