import { useTheme, Box } from '@persuit/ui-components'
import { useStore, useActions } from '../../store'

import { Cell, RowHeaderCell, BaseIntelligenceCell, SectionHeaderRow, Row } from '../components'
import { scorecardCellHeaders, scorecardRowId, scorecardSectionId } from '../accessibilty'

import { ScorecardHeaderCell } from './scorecard-header-cell'
import { ScorecardCell } from './scorecard-cell'

export const ScorecardSection = () => {
	const {
		isClient,
		request,
		responses,
		showIntelligenceColumn,
		showScorecardSection: expanded,
	} = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
		isClient: !state.isFirm,
		request: state.request,
		showScorecardSection: state.showScorecardSection,
	}))

	const { toggleShowScorecardSection } = useActions()

	const theme = useTheme()

	if (!isClient) {
		return null
	}

	return (
		<Box component="tbody" aria-label="Scorecard section">
			<SectionHeaderRow
				id={scorecardSectionId}
				title="Scorecard"
				expanded={expanded}
				onToggle={toggleShowScorecardSection}
			/>

			{expanded && (
				<Row>
					<RowHeaderCell
						cellProps={{
							id: scorecardRowId,
							'aria-label': 'Scorecard',
						}}
						p="15px 1em 1em"
						minWidth="170px"
						sx={{
							background: theme.palette.white,
							border: `1px solid  ${theme.palette.grey300}`,
						}}
					>
						<ScorecardHeaderCell rfpId={request._id} scoreCategories={request.scoreCategories} />
					</RowHeaderCell>

					{showIntelligenceColumn && <BaseIntelligenceCell cellProps={{ 'aria-hidden': true }} />}

					{responses.map((response, index, array) => {
						const { _id: responseId, status, scorecard } = response

						return (
							<Cell
								showRightBorder={index === array.length - 1}
								key={responseId}
								responseId={responseId}
								sx={{ pt: 0 }}
								headers={scorecardCellHeaders(responseId)}
							>
								<ScorecardCell
									responseId={responseId}
									scorecard={scorecard as any}
									responseStatus={status}
									scoreCategories={request.scoreCategories as any}
								/>
							</Cell>
						)
					})}
				</Row>
			)}
		</Box>
	)
}
