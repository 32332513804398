import { NestedAutocomplete, SxProps } from '@persuit/ui-components'
import { SaliCountry } from '@persuit/ui-graphql/generated'
import { isNotNil } from '@persuit/common-utils'

type RegionFilterProps = {
	sx?: SxProps
	validRegions: string[]
	countries: SaliCountry[]
	selections: string[]
	onChange?: (selections: string[]) => void
	disabled?: boolean
}

export const RegionFilter = ({
	disabled,
	countries,
	selections,
	sx,
	onChange,
	validRegions,
}: RegionFilterProps) => {
	const options = prepareFieldData(countries, validRegions)

	return (
		<NestedAutocomplete
			disableOptionValidation={true}
			disabled={disabled}
			sx={sx}
			placeholder={selections.length === 0 ? 'Filter' : undefined}
			TextFieldProps={{
				InputLabelProps: { shrink: true },
				InputProps: {
					startAdornment: selections.length > 0 && `${selections.length} selected`,
				},
				inputProps: {
					autocomplete: 'do-not-autofill',
				},
			}}
			label="State or City"
			options={options}
			selections={selections}
			onChange={onChange}
		/>
	)
}

function prepareFieldData(countries: SaliCountry[], validRegions: string[]) {
	type Item = { _id: string; values: Item[] }
	const isValidItem = (item: Item) => isValid(item._id) || item.values.some(isValidItem)
	const isValid = (id: string) => validRegions.includes(id)

	return countries.flatMap((countryDetails) => {
		const { code: countryCode, name: countryName } = countryDetails

		const stateData = countryDetails.states.map(({ code: stateCode, name: stateName, cities }) => ({
			_id: `entire-state-group-${stateCode}`,
			label: stateName,
			values: [
				isValid(stateCode)
					? {
							_id: stateCode,
							label: `All of ${stateName}`,
							values: [],
					  }
					: null,

				...cities
					.map(({ name: cityName, code: cityCode }) => ({
						_id: cityCode,
						label: cityName,
						values: [],
					}))
					.filter(isValidItem),
			].filter(isNotNil),

			defaultExpanded: false,
		}))

		return [
			{
				_id: `${countryName}:${countryCode}`,
				label: countryName,
				values: [
					{ _id: countryCode, label: `All of ${countryName}`, values: [] },
					...stateData,
				].filter(isValidItem),
			},
		]
	})
}
