import { memo, forwardRef } from 'react'
import * as React from 'react'
import { ListChildComponentProps } from 'react-window'

import { useTheme } from '../../../theme'
import { Collapse } from '../../collapse'
import { IconButton } from '../../icon-button'
import { Typography } from '../../typography'
import { Box } from '../../box'
import { List, ListItem, ListItemIcon, ListItemText } from '../../list'

import {
	ArrowDropDownIcon,
	ArrowDropUpIcon,
	CheckBoxOutlineBlankIcon,
	IndeterminateCheckBoxIcon,
	SubdirectoryArrowRightIcon,
	CheckBoxIcon,
} from '../../../icons'

import { useStore, useActions } from './store'
import { Value } from './nested-options'

type OptionProps = {
	style?: React.CSSProperties
	option: Value
	headersSelectable?: boolean
}

export const Option = memo(
	forwardRef(({ option, style }: OptionProps, ref: React.ForwardedRef<HTMLUListElement>) => {
		const { collapsed, selectionCount, searchTerm, highlighted, headersSelectable, checkStatus } =
			useStore((state, s) => ({
				checkStatus: s.checkStatus(option._id),
				headersSelectable: state.headersSelectable,
				selectionCount: s.countNestedSelections(option._id),
				collapsed: !state.searchTerm && s.isCollapsed(option._id),
				highlighted: state.highlightedId === option._id,
				searchTerm: state.searchTerm,
			}))

		const { select, setHighlighted, toggleCollapse } = useActions()

		const theme = useTheme()
		const highlightColor = theme.palette.primary.lighterHue

		return (
			<List
				id={`field-list:${option._id}`}
				ref={ref}
				style={style}
				role="group"
				key={option._id}
				dense={true}
				disablePadding={true}
				aria-labelledby={`${option._id}-title`}
				subheader={
					<Box
						display="flex"
						style={{
							backgroundColor: highlighted ? highlightColor : undefined,
						}}
						sx={{ cursor: 'pointer' }}
					>
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
						<ListItem
							id={option._id}
							disablePadding={true}
							onMouseEnter={() => setHighlighted(option._id)}
							onClick={() => {
								if (headersSelectable) {
									select(option._id)
								} else if (!searchTerm) {
									toggleCollapse(option._id)
								}
							}}
							sx={{
								position: 'sticky',
								top: 32,
								zIndex: 10,
								pl: 2,
								py: 1,
							}}
							aria-selected={headersSelectable ? checkStatus === 'checked' : undefined}
							role={headersSelectable ? 'option' : 'presentation'}
						>
							{headersSelectable && (
								<ListItemIcon
									sx={{ minWidth: theme.spacing(4) }}
									onClick={() => select(option._id)}
								>
									<CheckStatusIcon status={checkStatus} />
								</ListItemIcon>
							)}

							<Typography variant="body1Semibold" fontWeight="bold" id={`${option._id}-title`}>
								{option.label}
								{selectionCount > 0 ? ` (${selectionCount})` : null}
							</Typography>
						</ListItem>

						{searchTerm ? null : (
							<IconButton
								aria-label={collapsed ? 'expand' : 'collapse'}
								size="small"
								sx={{ mr: 2, p: 0 }}
								onClick={() => {
									if (!searchTerm) {
										toggleCollapse(option._id)
									}
								}}
								role={headersSelectable ? 'button' : 'presentation'}
								aria-hidden={'true'}
								tabIndex={-1}
								data-testid={`filter-option-expand-button:${option._id}`}
							>
								{collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
							</IconButton>
						)}
					</Box>
				}
			>
				<Collapse in={!collapsed}>
					{option.values.map((value) => (
						<CustomListItem key={value._id} id={value._id} nested={headersSelectable} />
					))}
				</Collapse>
			</List>
		)
	}),
)

export const ListOption = memo(
	forwardRef(
		({ index, data }: ListChildComponentProps, ref: React.ForwardedRef<HTMLUListElement>) => {
			const option = data[index]
			return <Option key={option._id} ref={ref} option={option} />
		},
	),
)

type CustomListItemProps = {
	id: string
	subItemIndex?: number
	nested?: boolean
	parent?: {
		name: string
		totalSubItems: number
	}
	isSubItem?: boolean
}

const CustomListItem = memo(
	({ id, subItemIndex, nested, parent, isSubItem = false }: CustomListItemProps) => {
		const theme = useTheme()
		const highlightColor = theme.palette.primary.lighterHue

		const { highlighted, checkStatus, option, searchTerm, collapsed, headersSelectable } = useStore(
			(state, s) => ({
				headersSelectable: state.headersSelectable,
				collapsed: s.isCollapsed(id),
				searchTerm: state.searchTerm,
				highlighted: state.highlightedId === id,
				checkStatus: s.checkStatus(id),
				option: s.filteredOptionById(id),
			}),
		)

		const { toggleCollapse, setHighlighted, select } = useActions()

		const label = option.label

		return (
			<>
				<Box
					display="flex"
					style={{ backgroundColor: highlighted ? highlightColor : undefined }}
					sx={{ cursor: 'pointer' }}
				>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
					<ListItem
						id={id}
						role="option"
						aria-selected={checkStatus === 'checked' ? 'true' : 'false'}
						onClick={() => select(id)}
						onMouseEnter={() => setHighlighted(id)}
						disablePadding={true}
						sx={{ px: 2, py: 1 / 2 }}
						aria-label={`${label}${checkStatus === 'checked' ? ' selected' : ''}${
							isSubItem
								? `, sub item of ${parent?.name ?? ''}, ${subItemIndex} of ${
										parent?.totalSubItems ?? ''
								  }`
								: ''
						}`}
					>
						{nested ? <SubdirectoryArrowRightIcon color="disabled" /> : null}
						<ListItemIcon sx={{ minWidth: theme.spacing(4) }}>
							<CheckStatusIcon status={checkStatus} />
						</ListItemIcon>
						<ListItemText primary={label} />
					</ListItem>
					{option.values.length === 0 || searchTerm ? null : (
						<IconButton
							aria-label={collapsed ? 'expand' : 'collapse'}
							size="small"
							sx={{ mr: 2, p: 0 }}
							onClick={() => {
								if (!searchTerm) {
									toggleCollapse(option._id)
								}
							}}
							aria-hidden={'true'}
							tabIndex={-1}
							data-testid={`filter-option-expand-button:${option._id}`}
						>
							{collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
						</IconButton>
					)}
				</Box>

				<Collapse in={!collapsed} sx={{ pl: headersSelectable ? 3 : undefined }}>
					{option.values.map((subValue, idx) => (
						<CustomListItem
							key={subValue._id}
							id={subValue._id}
							parent={{ name: option.label, totalSubItems: option.values.length }}
							subItemIndex={idx + 1}
							isSubItem={true}
							nested={true}
						/>
					))}
				</Collapse>
			</>
		)
	},
)

type CheckStatus = 'checked' | 'unchecked' | 'indeterminate'

type CheckStatusProps = {
	status: CheckStatus
}

const CheckStatusIcon = ({ status }: CheckStatusProps) => {
	return status === 'unchecked' ? (
		<CheckBoxOutlineBlankIcon color="primary" />
	) : status === 'checked' ? (
		<CheckBoxIcon color="primary" />
	) : status === 'indeterminate' ? (
		<IndeterminateCheckBoxIcon color="primary" />
	) : null
}
