export function getPreviousSection(sectionOrder: readonly string[] = [], currentSection = '') {
	const currentSectionIndex = sectionOrder.findIndex((section) => section === currentSection)
	// Not found
	if (currentSectionIndex === -1) return ''
	// First element doesn't have a previous
	if (currentSectionIndex === 0) return ''
	// Return previous
	return sectionOrder[currentSectionIndex - 1]
}

export function getNextSection(sectionOrder: readonly string[] = [], currentSection = '') {
	const currentSectionIndex = sectionOrder.findIndex((section) => section === currentSection)
	const maxIndex = sectionOrder.length - 1
	// Not found
	if (currentSectionIndex === -1) return ''
	// Least element doesn't have a next
	if (currentSectionIndex === maxIndex) return ''
	// Return next
	return sectionOrder[currentSectionIndex + 1]
}
