import { RfpClarification } from '../../../../types'
import { SystemClarificationItem } from '../system-clarification-item'
import { UserClarificationItem } from '../user-clarification-item'

type ClarificationItemProps = {
	clarification: RfpClarification
	clarificationNumber?: number | null
}

export const ClarificationItem = ({
	clarification,
	clarificationNumber = null,
}: ClarificationItemProps) => {
	const { type } = clarification
	return type === 'USER' ? (
		<UserClarificationItem
			clarification={clarification}
			clarificationNumber={clarificationNumber}
		/>
	) : type === 'SYSTEM' ? (
		<SystemClarificationItem
			clarification={clarification}
			clarificationNumber={clarificationNumber}
		/>
	) : null
}
