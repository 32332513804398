import { styled } from '../theme'

import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { SvgIconProps } from '../components/svg'

const errorIconLeftOffset = 11
const errorIconTopOffset = 10

const StyledErrorIcon = styled('div')`
	position: absolute;
	top: -${errorIconTopOffset}px;
	left: -${errorIconLeftOffset}px;
	background-color: white;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	z-index: 10;
`

export const TopLeftErrorOutlineIcon = (props: SvgIconProps) => {
	return (
		<StyledErrorIcon>
			<ErrorIcon color="error" {...props} />
		</StyledErrorIcon>
	)
}

TopLeftErrorOutlineIcon.displayName = 'TopLeftErrorOutlineIcon'
