// @ts-strict-ignore
import moment from 'moment'
import { Box, BoxProps, Typography } from '@persuit/ui-components'

type LastBidTimeProps = BoxProps & {
	lastBidTime: number
}

export const LastBidTime = ({ lastBidTime, ...rest }: LastBidTimeProps) => {
	const { formattedLastBidTime } = getLastBidTimeDisplayText(lastBidTime)

	return (
		<Box mt={2} textAlign="center" borderTop="1px solid lightgrey" padding={1} {...rest}>
			<Typography display="block" variant="caption">
				{formattedLastBidTime}
			</Typography>
		</Box>
	)
}

export function getLastBidTimeDisplayText(lastBidTime) {
	const lastBidTimeMoment = moment(lastBidTime)
	const currentDateTime = moment()

	const lastBidTimeDisplayText = { formattedLastBidTime: '' }

	// Workaround to handle spooky cases where bids seem to occur in the future!
	if (lastBidTimeMoment.isSameOrAfter(currentDateTime)) {
		// Don't bother displaying the bid time, just the date. If we show the current
		// time it will appear to change after each reload until the "future" date/time
		// is reached.
		lastBidTimeDisplayText.formattedLastBidTime = currentDateTime.format('D MMMM YYYY')
	} else {
		lastBidTimeDisplayText.formattedLastBidTime = lastBidTimeMoment.format('D MMMM YYYY, h:mmA')
	}

	return lastBidTimeDisplayText
}
