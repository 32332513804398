const FirmStatusTypes = {
	DRAFT: 'DRAFT',
	OPEN_TO_PROPOSALS: 'OPEN_TO_PROPOSALS',
	EVALUATING: 'EVALUATING',
	AWAITING_AUCTION: 'AWAITING_AUCTION',
	AUCTION_IN_PROGRESS: 'AUCTION_IN_PROGRESS',
	COMPLETED: 'COMPLETED',
	DECLINED_TO_PARTICIPATE: 'DECLINED_TO_PARTICIPATE',
	ELIMINATED: 'ELIMINATED',
	WITHDRAWN: 'WITHDRAWN',
}

export const values = Object.keys(FirmStatusTypes)

export default FirmStatusTypes
