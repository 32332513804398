import { useState } from 'react'
import { NoteAddIcon, GroupWorkIcon } from '@persuit/ui-components'
import {
	AutoSaveSnackContextProvider,
	SectionalFormLayout,
	useDisabledSectionRedirect,
	useSectionalLocks,
} from '../../sectional-form'
import { TemplateLockableSection } from '../template-lockable-section'
import { PublishDialog } from '../publish-dialog'
import { Errors } from '../errors'
import { PreviewDrawer } from '../preview-drawer'
import { Header } from '../header'
import { DetailSection } from '../detail-section'
import { DiversitySection } from '../diversity-section'
import { AuctionSection } from '../auction-section'
import { ConflictCheckSection } from '../conflict-check-section'
import { QuestionSection } from '../questions-section'
import { ScorecardSection } from '../scorecard-section'
import { SummarySection } from '../summary-section'
import { PricingSection } from '../pricing-section'
import { RatesSection } from '../rates-section'
import { UseCaseSection } from '../use-case-section'
import { RfpTemplate, RfpTemplateDraftSectionErrors } from '../types'
import { isNotNil, isNotNilProp, defaultNilProp } from '@persuit/common-utils'
import { generatePath, useRouteMatch, useParams } from 'react-router-dom'
import {
	NavItem,
	SummaryNavItem,
	PricingNavItem,
	ConflictNavItem,
	DiversityNavItem,
	QuestionsNavItem,
	ScorecardNavItem,
	RatesNavItem,
	NavGroup,
	NavDivider,
	Navigation,
} from '../../sectional-form/navigation'
import { getIsSectionEnabled } from '../is-section-enabled'

type LayoutProps = {
	rfpTemplate: RfpTemplate
	validateRfpTemplate: () => void | Promise<void>
	validationErrors: RfpTemplateDraftSectionErrors | null
	refetchErrors?: () => Promise<void>
	userId: string
	refetchRfpTemplate: () => Promise<void>
	sendMode: boolean
	isDiversitySectionEnabled: boolean
}

export const Layout = ({
	rfpTemplate,
	validateRfpTemplate,
	validationErrors,
	refetchErrors,
	userId,
	refetchRfpTemplate,
	sendMode,
	isDiversitySectionEnabled,
}: LayoutProps) => {
	const [publishDialogOpen, setPublishDialogOpen] = useState(false)
	const [previewOpen, setPreviewOpen] = useState(false)

	const openPublishDialog = () => setPublishDialogOpen(true)
	const closePublishDialog = () => setPublishDialogOpen(false)

	const openPreview = () => setPreviewOpen(true)
	const closePreview = () => setPreviewOpen(false)

	const isSectionEnabled = getIsSectionEnabled({
		isDiversitySectionEnabled,
		useCase: rfpTemplate?.useCase ?? undefined,
	})

	const templateId = rfpTemplate?._id ?? ''
	const { sectionalLocks, instanceId, tryLock, refetchLocks } = useSectionalLocks(
		'RFP_TEMPLATE_DRAFT',
		templateId,
	)
	const pricingItems = rfpTemplate?.detail?.deliverablesV2 ?? []

	const questions = (rfpTemplate?.detail?.questions ?? [])
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))
		.map(defaultNilProp('title', ''))
		.map(defaultNilProp('required', false))

	const questionGroups = rfpTemplate?.detail?.questionGroups ?? []

	const sectionProps = {
		instanceId,
		rfpTemplate,
		refetchRfpTemplate,
		validationErrors,
		refetchErrors,
		sendMode,
		useSingleCurrency: true,
	}

	const lockableSectionProps = {
		sectionalLocks,
		instanceId,
		tryLock,
		rfpTemplate,
		userId,
		isDiversitySectionEnabled,
	}

	const { section: currentSection } = useParams<{ section: string }>()
	const { path } = useRouteMatch()
	const generateSectionPath = (sectionName: string) =>
		generatePath(path, {
			lng: 'en',
			templateId,
			section: sectionName,
		})

	useDisabledSectionRedirect({
		generateSectionPath,
		isSectionEnabled,
		defaultSection: 'detail',
		currentSection,
	})

	return (
		<AutoSaveSnackContextProvider>
			<SectionalFormLayout
				currentSection={currentSection}
				global={
					<>
						<PreviewDrawer
							refetchRfpTemplate={refetchRfpTemplate}
							previewOpen={previewOpen}
							closePreview={closePreview}
							rfpTemplate={rfpTemplate}
						/>

						<PublishDialog
							open={publishDialogOpen}
							onClose={closePublishDialog}
							templateId={templateId}
							userId={userId}
							sectionalLocks={sectionalLocks}
							refetchSectionalLocks={refetchLocks}
							validationErrors={validationErrors}
							refetchValidationErrors={validateRfpTemplate}
						/>
					</>
				}
				header={
					<Header
						templateId={rfpTemplate?._id ?? ''}
						templateTitle={rfpTemplate?.templateTitle ?? ''}
						templateUpdatedAt={
							rfpTemplate?.updatedAt ? new Date(rfpTemplate.updatedAt) : new Date()
						}
						handlePublish={openPublishDialog}
						handlePreview={openPreview}
						headlineError={validationErrors?.headline?.headline ?? ''}
						sendMode={sendMode}
					/>
				}
				navigation={
					<Navigation
						currentSection={currentSection}
						generatePath={generateSectionPath}
						errors={validationErrors}
						sectionalLocks={sectionalLocks}
						instanceId={instanceId}
						userId={userId}
						containerProps={{
							gridRow: 2,
							gridColumn: 1,
						}}
					>
						{({ itemProps }) => (
							<>
								<NavGroup title="Use Case" />
								<UseCaseSection {...sectionProps} />
								<NavDivider />

								<NavGroup title="Template Details" />
								{isSectionEnabled('detail') && (
									<NavItem
										title="Template Details"
										icon={<NoteAddIcon />}
										{...itemProps('detail')}
									/>
								)}
								<NavDivider />
								<NavGroup title="Content" subtitle="Change content on your template" />
								{isSectionEnabled('summary') && <SummaryNavItem {...itemProps('summary')} />}
								{isSectionEnabled('rates') && <RatesNavItem {...itemProps('rates')} />}
								{isSectionEnabled('pricing') && (
									<PricingNavItem
										items={pricingItems.map((item) =>
											item.__typename === 'Deliverable'
												? { type: 'item', ...item }
												: { type: 'group', ...item },
										)}
									/>
								)}
								{isSectionEnabled('questions') && (
									<QuestionsNavItem questions={questions} questionGroups={questionGroups} />
								)}
								{isSectionEnabled('diversity') && <DiversityNavItem {...itemProps('diversity')} />}
								<NavDivider />
								<NavGroup title="Settings" subtitle="Configure your template" />
								{isSectionEnabled('auction') && (
									<NavItem
										title="Reverse Auction"
										icon={<GroupWorkIcon />}
										{...itemProps('auction')}
									/>
								)}
								{isSectionEnabled('conflict') && <ConflictNavItem {...itemProps('conflict')} />}
								{isSectionEnabled('scorecard') && <ScorecardNavItem {...itemProps('scorecard')} />}
							</>
						)}
					</Navigation>
				}
				main={
					<>
						<TemplateLockableSection sectionName="detail" {...lockableSectionProps}>
							<DetailSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="summary" {...lockableSectionProps}>
							<SummarySection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="pricing" {...lockableSectionProps}>
							<PricingSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="rates" {...lockableSectionProps}>
							<RatesSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="questions" {...lockableSectionProps}>
							<QuestionSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="diversity" {...lockableSectionProps}>
							<DiversitySection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="auction" {...lockableSectionProps}>
							<AuctionSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="conflict" {...lockableSectionProps}>
							<ConflictCheckSection {...sectionProps} />
						</TemplateLockableSection>
						<TemplateLockableSection sectionName="scorecard" {...lockableSectionProps}>
							<ScorecardSection {...sectionProps} />
						</TemplateLockableSection>
					</>
				}
				sidebar={<Errors errors={validationErrors} />}
			/>
		</AutoSaveSnackContextProvider>
	)
}
