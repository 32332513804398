import { useMemo, createContext, useContext, useEffect, useRef } from 'react'
import * as React from 'react'
import shallowEqual from 'shallowequal'
import { createStore as createStoreOrig, useStore as useStoreOrig, StoreApi } from 'zustand'
import { mapRecord } from '@persuit/common-utils'

type SelectorMap<T> = Record<string, (state: T, ...args: any[]) => unknown>

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R ? (...args: P) => R : never

type SimplifiedSelector<T extends SelectorMap<any>> = {
	[K in keyof T]: OmitFirstArg<T[K]>
}

/**
 * Creates and returns a new store `Provider`, `useStore` and `useActions` hook.
 *
 * By using context test isolation works without any extra work
 */
export function createStore<
	State extends Record<string, unknown>,
	Actions,
	Selectors extends SelectorMap<State>,
	SetupInput extends Record<string, unknown> = Record<string, never>,
>(
	/** A function that takes props and sets the initial state */
	setupState: SetupInput extends Record<string, never> ? State : (input: SetupInput) => State,
	/** A set of actions that can be consumed via `useActions` */
	createActions: CreateActions<State, Actions> = (() => ({})) as CreateActions<State, any>,
	selectors: Selectors = {} as Selectors,
) {
	const StoreContext = createContext(null as unknown as ReturnType<typeof createStore>)
	const useStoreApi = () => useContext(StoreContext)

	type MergeStateInput = {
		initialState: SetupInput
		currentState: State
	}

	type ProviderProps = {
		children: React.ReactNode
		initialState: SetupInput
		mergeState?: ({ initialState, currentState }: MergeStateInput) => Partial<State>
	}
	type PrunedProviderProps = SetupInput extends Record<string, never>
		? Omit<ProviderProps, 'initialState'>
		: ProviderProps
	const Provider = ({ children, initialState, mergeState }: PrunedProviderProps) => {
		const store = useMemo(() => createStore(initialState as any), [])
		const previousInitialState = useRef(initialState)
		const memoizedMergeState = useMemo(() => mergeState, [])

		useEffect(() => {
			if (memoizedMergeState && !shallowEqual(initialState, previousInitialState.current)) {
				store.setState((currentState) => ({
					...currentState,
					...memoizedMergeState({ initialState, currentState }),
				}))
			}
			previousInitialState.current = initialState
		}, [initialState, memoizedMergeState, store])

		return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
	}

	const createStore = (setupInput: SetupInput) => {
		return createStoreOrig<State>((set, get, store) => ({
			...createActions(set, get, store),
			...(typeof setupState === 'function' ? setupState(setupInput) : setupState),
		}))
	}

	// Local variable to be updated and consumed synchronously within the useStore selector
	let currentState: State = null as unknown as State
	const wrappedSelectors = mapRecord(
		selectors,
		(value) =>
			(...args) =>
				value(currentState, ...args),
	) as SimplifiedSelector<Selectors>

	function useStore<T>(
		selector: (
			state: State,
			selectors: Record<string, never> extends Selectors ? never : SimplifiedSelector<Selectors>,
		) => T,
		equalityFn: (left: T, right: T) => boolean = shallowEqual,
	): T {
		const store = useStoreApi()
		if (!store) throw new Error('Missing Store Provider in the tree')
		// TODO Support shallow merge here, maybe make it the default, conditional even, by detecting objects
		return useStoreOrig(
			store,
			(state) => {
				// Synchronously save the state to a local variable and call the selector function removing the need to recreate selectors each time
				currentState = state
				return selector(state, wrappedSelectors as any)
			},
			equalityFn,
		)
	}

	function useActions(): Actions {
		const store = useStoreApi()
		if (!store) throw new Error('Missing Store Provider in the tree')
		return useMemo(() => createActions(store.setState, store.getState, store), [store])
	}

	return { useStore, useActions, Provider, useStoreApi }
}

type CreateActions<State, Actions> = (
	set: StoreApi<State>['setState'],
	get: StoreApi<State>['getState'],
	store: StoreApi<State>,
) => Actions

export type { StoreApi } from 'zustand'
