import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
} from '@persuit/ui-components'
import { Response, RfprNamedTimekeeper } from '@persuit/ui-graphql/generated'
import { size } from 'lodash/fp'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { NamedTimekeeperRow } from './named-timekeeper-row'
import { SubAccordion } from '../sub-accordion'
import { NamedTimekeepersPriceHistory } from './named-timkeepers-price-history'

const StyledTable = styled(Table)(({ theme }) => ({
	borderCollapse: 'collapse',
	'& td, & th': {
		border: `1px solid ${theme.palette.grey300}`,
	},
	'& thead th': {
		textAlign: 'center',
	},
	// CSS for all cells of every row
	'& tbody td': {
		textAlign: 'center',
	},
	// CSS for first cell of every row
	'& tbody td:first-child': {
		textAlign: 'left',
	},
	// CSS for last 4 cells of every row
	'& tbody td:nth-last-child(-n+4)': {
		textAlign: 'right',
	},
	// CSS for odd numbered rows
	'& tbody > tr:nth-child(2n + 1)': {
		background: theme.palette.grey100,
	},
	'& td *, & th *, & td, & th': {
		// Reduce table font size and padding whilst printing
		'@media print': {
			fontSize: '10px',
			padding: '2px',
			textAlign: 'center',
		},
	},
	'@media print': {
		tableLayout: 'fixed',
	},
}))

const getClientSuggestedProposedRate = (
	proposalNamedTimeKeeperId: string,
	namedTimekeepersRevisionComment: RfprNamedTimekeeper[],
) => {
	const foundSuggestedNameTimekeeper = namedTimekeepersRevisionComment.find(
		(nameTimekeeperRevisionComment: RfprNamedTimekeeper) =>
			nameTimekeeperRevisionComment.namedTimekeeperId === proposalNamedTimeKeeperId,
	)
	return foundSuggestedNameTimekeeper?.suggestedProposedRate ?? null
}

export const NamedTimekeepers = ({
	namedTimekeepers,
	currency,
	namedTimekeepersRevisionComment,
	proposalId,
}: {
	namedTimekeepers: Response['namedTimekeepers']
	currency: string
	namedTimekeepersRevisionComment: RfprNamedTimekeeper[]
	proposalId: string
}) => {
	const { toggles } = useFeatureToggles()

	if (!toggles['dev-10144.named-timekeepers']) {
		return null
	}

	if (size(namedTimekeepers) === 0) {
		return null
	}

	return (
		<>
			<Typography variant="h2XSmall" gutterBottom={true}>
				Individual timekeepers
			</Typography>
			<TableContainer tabIndex={0}>
				<StyledTable aria-label={'Rates for individual timekeepers'}>
					<TableHead>
						<TableRow>
							<TableCell>Timekeeper level</TableCell>
							<TableCell>First name</TableCell>
							<TableCell>Last name</TableCell>
							<TableCell>Location</TableCell>
							<TableCell>Graduation year</TableCell>
							<TableCell>Timekeeper ID</TableCell>
							<TableCell>Existing timekeeper</TableCell>
							<TableCell>Last year's hours</TableCell>
							{toggles['dev-10954.multi-currency'] && <TableCell>Currency</TableCell>}
							<TableCell>Current rate</TableCell>
							<TableCell>New standard rate</TableCell>
							<TableCell>Proposed rate</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{namedTimekeepers.map((namedTimekeeper) => (
							<NamedTimekeeperRow
								key={namedTimekeeper._id}
								namedTimekeeper={namedTimekeeper}
								currency={currency}
								clientSuggestedProposedRate={getClientSuggestedProposedRate(
									namedTimekeeper._id,
									namedTimekeepersRevisionComment ?? [],
								)}
							/>
						))}
					</TableBody>
				</StyledTable>
			</TableContainer>
			<SubAccordion
				title="Negotiation Price History"
				ariaLabel="Negotiation Price history for individual timekeepers"
				sx={{ mt: 2 }}
			>
				<NamedTimekeepersPriceHistory
					currency={currency}
					ariaLabel="historical prices for individual timekeepers"
					proposalId={proposalId}
				/>
			</SubAccordion>
		</>
	)
}
