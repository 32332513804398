import { Box, List, Divider, Typography } from '@persuit/ui-components'
import { UserCard } from './user-card'

export type UserListProps = {
	users: Array<{ name: { first: string; last: string }; email: string; currentUser: boolean }>
	currentOrg: boolean
	orgName: string
}

export const UserList = ({ users, currentOrg, orgName }: UserListProps) => {
	return (
		<Box mt={4}>
			<Box pl={3} pr={3} pt={1} pb={1}>
				<Typography variant="body2Semibold" color="text.secondary">
					{orgName.toUpperCase()}
				</Typography>
			</Box>
			<Divider />
			<Box pl={3} pr={3}>
				<List sx={{ padding: 0 }} aria-label={`${orgName} participant list`}>
					{users.map((user, index) => (
						<>
							<UserCard {...user} currentOrg={currentOrg} key={index} />
							{index + 1 !== users.length && <Divider />}
						</>
					))}
				</List>
			</Box>
			<Divider />
		</Box>
	)
}
