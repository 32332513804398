import { useState, Fragment } from 'react'

import { isEmpty } from 'lodash/fp'

import DisplayBox from './display-box'
import ViewAssumptionDialog from './view-assumption-dialog'
import { RichTextView, Typography, Button, Box, SROnly } from '@persuit/ui-components'

const AssumptionsView = ({ currentAssumptions, suggestedAssumptions }) => {
	const [viewDialogIsOpen, setViewOpen] = useState(false)

	const handleViewOpen = () => setViewOpen(true)
	const handleViewClose = () => setViewOpen(false)

	// show clients suggestion if it differs from firms comment
	const showSuggested =
		!isEmpty(suggestedAssumptions) && suggestedAssumptions !== currentAssumptions

	return (
		<Fragment>
			{!showSuggested && (
				<Box mb={2}>
					<RichTextView content={currentAssumptions} />
				</Box>
			)}

			{showSuggested && (
				<>
					<Typography variant="h4" sx={{ mb: 1 }}>
						Assumptions & description
					</Typography>

					<Button
						data-testid="view-original-response-button"
						onClick={handleViewOpen}
						variant="outlined"
						sx={{ mb: 2 }}
					>
						View original response
					</Button>

					<SROnly>Updated to:</SROnly>
					<DisplayBox suggestedAssumptions={suggestedAssumptions} />
					{/* show original dialog */}
					<ViewAssumptionDialog
						currentAssumptions={currentAssumptions}
						isOpen={viewDialogIsOpen}
						handleViewClose={handleViewClose}
					/>
				</>
			)}
		</Fragment>
	)
}

export default AssumptionsView
