import { useMutation } from '@apollo/client'

import reviseProposalMutation from '../../../../graphql/mutations/reviseProposal-gql'

import ProposalPages from '../../../../../common/proposals/pages-viewmode'

import Text from '../../../text.jsx'
import Spacer from '../../../layout/spacer.jsx'

import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import { stepIndex } from '../../utils'
import { useSnackbar } from '@persuit/ui-components'

function OptionReviseTotalPriceConfirm({ navigateToPage, onCancel, onDone, onPrev, proposalId }) {
	const [reviseProposal, { loading, error }] = useMutation(reviseProposalMutation)
	const { openSnackbar } = useSnackbar()

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Revise proposal',
			}}
			isWorking={loading}
			onConfirm={async () => {
				const newDraftProposal = await reviseProposal({
					variables: {
						_id: proposalId,
					},
				})

				const {
					data: {
						underRevisionProposal: { _id: newProposalId },
					},
				} = newDraftProposal

				openSnackbar('Draft proposal revision created')
				onDone()
				navigateToPage(ProposalPages.EDIT_PROPOSAL, { proposalId: newProposalId })
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />

			<Text type="body1">
				Your proposal will be placed under revision and you can revise your proposal for any changes
				required.
				<br />
				<br />
				The client will be unable to select your firm until you've re-submitted your proposal.
			</Text>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionReviseTotalPriceConfirm
