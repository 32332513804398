import formatCurrency from '@client/utils/format-currency'
import {
	Box,
	Button,
	TableRow as MUITableRow,
	SROnly,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	Typography,
	styled,
	useTheme,
} from '@persuit/ui-components'
import { useSaliLocationData, useSaliPracticeAreaData } from '@persuit/ui-hooks'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import { scrollToElementWithId } from '@persuit/ui-utils'
import { isEmpty } from 'lodash/fp'
import { useState } from 'react'
import {
	NamedTimekeeperSuggestion,
	RateReviewValue,
} from '../rate-review/get-default-rate-review-values'
import { TableRow } from '../rate-review/rates-section/timekeeper-table'
import { StyledTableRow } from '../suggested-changes-table/row-contents'
import { AssumptionDialog } from './assumption-dialog'
import { RateReviewFormValues } from './get-suggested-changes'

const StyledTableContainer = styled(TableContainer)`
	&.MuiTableContainer-root {
		width: auto;
		border: 1px solid ${({ theme }) => theme.palette.background.appMain};
		padding: 1em;
	}

	& td,
	th {
		border: 1px solid ${({ theme }) => theme.palette.divider};
	}
`

type SuggestedChangesBodyProps = {
	suggestedChanges: Array<RateReviewValue>
	namedTimekeeperSuggestions: NamedTimekeeperSuggestion[]
	handleAssumptionDialogOpen: (originalAssumptions: string, updatedAssumptions: string) => void
}

const SuggestedChangesBody = ({
	suggestedChanges,
	namedTimekeeperSuggestions,
	handleAssumptionDialogOpen,
}: SuggestedChangesBodyProps) => {
	const { regionById } = useSaliLocationData()
	const { practiceAreaById } = useSaliPracticeAreaData()
	const theme = useTheme()

	return (
		<>
			{suggestedChanges.map((rateCard) => {
				const country = regionById(rateCard?.location?.country ?? '')?.name ?? ''
				const regionList = (rateCard?.location?.regions ?? [])
					.map(regionById)
					.map((r) => r?.name)
					.filter(isNotNil)
				const practiceAreasList = (rateCard?.practiceAreas ?? [])
					.map(practiceAreaById)
					.map((p) => p?.name)
					.filter(isNotNil)
				const timekeepersWithSuggestedRates = rateCard.timekeepers.filter(
					isNotNilProp('suggestedRate'),
				)

				return (
					<>
						<StyledTableRow
							sx={{ backgroundColor: theme.palette.action.hover }}
							onClick={() => scrollToElementWithId(rateCard.rateCardId)}
						>
							<TableCell colSpan={2}>
								<Box display="flex">
									<Typography variant="body2Semibold" sx={{ width: '30%' }}>
										<SROnly>Rate card country</SROnly>
										{country}
									</Typography>
									<Typography variant="body2">
										<SROnly>Rate card regions</SROnly>
										{regionList.join(', ')}
									</Typography>
								</Box>
							</TableCell>
						</StyledTableRow>
						<StyledTableRow onClick={() => scrollToElementWithId(rateCard.rateCardId)}>
							<TableCell colSpan={2}>
								<Box display="flex" alignItems="baseline">
									<Box>
										<SROnly>Rate card practice areas</SROnly>
										<Typography variant="body2">Practice area:&nbsp;</Typography>
										<Typography variant="body2">{practiceAreasList.join(', ')}</Typography>
									</Box>
									{!isEmpty(rateCard.assumptions) && (
										<Typography variant="body2" sx={{ ml: 'auto', width: '26%', textAlign: 'end' }}>
											Assumptions:{' '}
											<Button
												onClick={(e) => {
													e.stopPropagation()
													handleAssumptionDialogOpen(
														rateCard.originalDescription ?? '',
														rateCard.assumptions ?? '',
													)
												}}
												color="error"
												size="small"
												data-testid="view-original-assumption"
												aria-label="Click to view original & updated assumption"
											>
												Updated
											</Button>
										</Typography>
									)}
								</Box>
							</TableCell>
						</StyledTableRow>
						{timekeepersWithSuggestedRates.map((tk) => {
							const scrollId = `${rateCard.rateCardId}_${tk.timekeeperId}`
							return (
								<StyledTableRow
									key={tk.timekeeperId}
									onClick={() => scrollToElementWithId(scrollId)}
								>
									<TableCell sx={{ pl: 5 }}>
										<Typography variant="body2" aria-label="Timekeeper">
											{tk.timekeeperLabel}
										</Typography>
									</TableCell>
									<TableCell sx={{ textAlign: 'end', color: theme.palette.error.main }}>
										<Typography variant="body2Semibold">
											{formatCurrency(tk.suggestedRate, rateCard?.currency ?? '')}
										</Typography>
									</TableCell>
								</StyledTableRow>
							)
						})}
					</>
				)
			})}
			{namedTimekeeperSuggestions.length > 0 && (
				<StyledTableRow sx={{ backgroundColor: theme.palette.action.hover }}>
					<TableCell colSpan={2}>
						<Typography variant="body2Semibold" sx={{ width: '30%' }}>
							Individual Timekeeper
						</Typography>
					</TableCell>
				</StyledTableRow>
			)}
			{namedTimekeeperSuggestions.map((suggestedRate) => (
				<StyledTableRow
					key={suggestedRate.namedTimekeeperId}
					onClick={() => scrollToElementWithId(suggestedRate.namedTimekeeperId)}
				>
					<TableCell sx={{ pl: 5 }}>
						<Typography variant="body2" aria-label="Timekeeper">
							{`${suggestedRate.firstName} ${suggestedRate.lastName}`}
						</Typography>
					</TableCell>
					<TableCell sx={{ textAlign: 'end', color: theme.palette.error.main }}>
						<Typography variant="body2Semibold">
							{formatCurrency(suggestedRate.suggestedProposedRate, suggestedRate.currency ?? '')}
						</Typography>
					</TableCell>
				</StyledTableRow>
			))}
		</>
	)
}

const SuggestedChangesHead = () => (
	<MUITableRow>
		<TableCell width="80%"></TableCell>
		<TableCell align="right">Your suggested rate</TableCell>
	</MUITableRow>
)

export type RateReviewSuggestedChangesTableProps = {
	suggestedChanges: RateReviewFormValues[]
	namedTimekeeperSuggestions: NamedTimekeeperSuggestion[]
}

export const RateReviewSuggestedChangesTable = ({
	suggestedChanges,
	namedTimekeeperSuggestions,
}: RateReviewSuggestedChangesTableProps) => {
	const [assumption, setAssumption] = useState({
		isOpen: false,
		originalAssumption: '',
		updatedAssumption: '',
	})

	const handleAssumptionDialogOpen = (originalAssumption: string, updatedAssumption: string) => {
		setAssumption({
			isOpen: true,
			originalAssumption,
			updatedAssumption,
		})
	}
	const handleAssumptionDialogClose = () => {
		setAssumption({
			isOpen: false,
			originalAssumption: '',
			updatedAssumption: '',
		})
	}

	const { isOpen, originalAssumption, updatedAssumption } = assumption

	const hasSuggestedChanges = suggestedChanges.length > 0 || namedTimekeeperSuggestions.length > 0

	return (
		<>
			<AssumptionDialog
				isOpen={isOpen}
				originalAssumption={originalAssumption}
				updatedAssumption={updatedAssumption}
				handleClose={handleAssumptionDialogClose}
			/>
			<StyledTableContainer>
				<Typography variant="h3XSmall" mt={1}>
					Suggested changes
				</Typography>
				<Table size="small" aria-label="Suggested changes">
					<caption style={{ captionSide: 'top' }}>
						List of suggested changes to a proposal will appear here
					</caption>
					<TableHead>
						<SuggestedChangesHead />
					</TableHead>
					{!hasSuggestedChanges ? (
						<TableBody>
							<TableRow>
								<TableCell colSpan={2}>
									<Typography variant="caption">No suggested changes</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<SuggestedChangesBody
							suggestedChanges={suggestedChanges}
							namedTimekeeperSuggestions={namedTimekeeperSuggestions}
							handleAssumptionDialogOpen={handleAssumptionDialogOpen}
						/>
					)}
				</Table>
			</StyledTableContainer>
		</>
	)
}
