import { graphql, useQuery } from '@persuit/ui-graphql'
import { MessagingInner } from './messaging-inner'
import { Box, LoadingSpinner, Typography, PageTitle, LinkSupport } from '@persuit/ui-components'

export type MessagingProps = {
	requestId: string
	/** Used when the component is the only component in the page */
	pageMode?: boolean
	expanded?: boolean
	toggleExpanded?: () => void
	popOut?: () => void
	closeMessages?: () => void
}

const GET_RFP = graphql(`
	query MessagingGetRfp($_id: ID!) {
		getRfp(_id: $_id) {
			_id
			headline
		}
	}
`)

export const Messaging = ({
	requestId,
	expanded,
	toggleExpanded,
	popOut,
	closeMessages,
	pageMode,
}: MessagingProps) => {
	const { data, loading, error } = useQuery(GET_RFP, { variables: { _id: requestId } })
	const dataTestId = `${requestId}-messages`

	if (loading)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
				data-testid={dataTestId}
			>
				<LoadingSpinner />
			</Box>
		)

	if (error || !data?.getRfp)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				gap={2}
				p={4}
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
				data-testid={dataTestId}
			>
				<Typography variant="body1Semibold">Messaging is temporarily unavailable.</Typography>
				<Typography variant="caption">
					If this problem persists, please contact our support team at&nbsp;
					<LinkSupport />.
				</Typography>
			</Box>
		)

	const headline = data?.getRfp?.headline ?? 'Untitled request'

	return (
		<>
			{pageMode && <PageTitle>{`Messages for ${data.getRfp.headline ?? ''}`}</PageTitle>}
			<MessagingInner
				requestId={requestId}
				headline={headline}
				expanded={expanded}
				toggleExpanded={toggleExpanded}
				popOut={popOut}
				closeMessages={closeMessages}
				data-testid={dataTestId}
			/>
		</>
	)
}
