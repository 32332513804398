import { Chip, useTheme } from '@persuit/ui-components'
import { MATTER_TYPE_LABEL_MAP } from '../../../../../src/common/data/template-tags'
import { TemplateMatterType } from '@persuit/ui-graphql/generated'

export type MatterTypeLabelProps = {
	matterType?: TemplateMatterType | null
}

const getLabelText = (matterType?: TemplateMatterType | null): string | undefined => {
	if (!matterType) return undefined
	return MATTER_TYPE_LABEL_MAP[matterType]
}

export const MatterTypeLabel = ({ matterType }: MatterTypeLabelProps) => {
	const theme = useTheme()
	const label = getLabelText(matterType)
	if (!label) return null

	return (
		<Chip
			label={label}
			color="secondary"
			sx={{
				color: theme.palette.teal800,
				background: theme.palette.teal100,
			}}
		/>
	)
}
