const SET_LANGUAGE = 'SET_LANGUAGE'
function setLanguage(language) {
	return {
		type: SET_LANGUAGE,
		language,
	}
}

export default {
	SET_LANGUAGE,
	setLanguage,
}
