import { gql } from '@apollo/client'

export const getTaggedSourcingCountQuery = gql`
	query getTaggedSourcingCount($orgs: [ID], $year: Int) {
		getTaggedSourcingCount(orgs: $orgs, year: $year) {
			taggedCount
			untaggedCount
			excludedCount
		}
	}
`

export const getUntaggedSourcingQuery = gql`
	query getUntaggedSourcing($orgs: [ID], $page: Int, $filter: String, $year: Int) {
		getUntaggedSourcing(orgs: $orgs, page: $page, filter: $filter, year: $year) {
			_id
			headline
			orgName
			summary
			sourcing {
				code
				name
				description
			}
		}
	}
`

export const getTaggedSourcingQuery = gql`
	query getTaggedSourcing($orgs: [ID], $page: Int, $year: Int) {
		getTaggedSourcing(orgs: $orgs, page: $page, year: $year) {
			_id
			headline
			orgName
			summary
			sourcing {
				code
				name
				description
			}
		}
	}
`

export const getExcludedSourcingQuery = gql`
	query getExcludedSourcing($orgs: [ID], $page: Int, $year: Int) {
		getExcludedSourcing(orgs: $orgs, page: $page, year: $year) {
			_id
			headline
			orgName
			summary
		}
	}
`

export const excludeSourcingMutation = gql`
	mutation excludeSourcingFromTagging($rfpId: ID!) {
		excludeSourcingFromTagging(rfpId: $rfpId)
	}
`

export const getTaggableSourcingQuery = gql`
	query getTaggableSourcing($rfpId: ID!) {
		getTaggableSourcing(rfpId: $rfpId) {
			_id
			headline
			orgName
			summary
			currency
			requestedBy
			sourcing {
				code
				name
				description
				parentChain
			}
			excludedFromTagging
			deliverablesV2 {
				__typename
				... on RfpPricingItem {
					_id
					deliverableTitle
					deliverable
				}
				... on RfpTaggerPricingGroup {
					_id
					title
					deliverables {
						_id
						deliverableTitle
						deliverable
					}
				}
			}
			pricingDescription
			totalPriceRequested
			markedAsCompleted
			taggedBy {
				_id
				name {
					first
					last
				}
			}
		}
	}
`

export const getTaggableRfpAndSourcingQuery = gql`
	query getTaggableRfpInline($rfpId: ID!) {
		getTaggableRfp(rfpId: $rfpId) {
			_id
			areaOfLaw {
				code
				name
				description
			}
			location {
				code
				name
				description
			}
			type {
				code
				name
				description
			}
			service {
				code
				name
				description
			}
			excludedFromTagging
			markedAsCompleted
			taggedByLlm
		}
		getTaggableSourcing(rfpId: $rfpId) {
			_id
			sourcing {
				code
				name
				description
				parentChain
			}
			excludedFromTagging
			markedAsCompleted
			taggedBy {
				_id
				name {
					first
					last
				}
			}
		}
	}
`

export const tagRfpMutation = gql`
	mutation tagRfp($rfpId: ID!, $tags: Tags!, $markAsComplete: Boolean) {
		tagRfp(rfpId: $rfpId, tags: $tags, markAsComplete: $markAsComplete)
	}
`

export const tagSourcingMutation = gql`
	mutation tagSourcing($rfpId: ID!, $tags: SourcingTags!, $markAsComplete: Boolean) {
		tagSourcing(rfpId: $rfpId, tags: $tags, markAsComplete: $markAsComplete)
	}
`
