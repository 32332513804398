import { languageActions } from '../actions'

const initialState = {
	code: 'en',
}

export default function languageReducer(state = initialState, action) {
	switch (action.type) {
		case languageActions.SET_LANGUAGE: {
			return Object.assign({}, state, {
				code: action.language,
			})
		}
		default: {
			return state
		}
	}
}
