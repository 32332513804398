import priceCurrency from './price-and-currency'
/**
 * Formats a number as a currency. Supports both Variance and Financial
 * @param {Number} - value
 * @param {String} - currency USD, PLN, AUD
 * @param {Bool} - variance - True: shows +/- or False: financial ($100)
 */
export default function formatCurrency(value, currency = '', variance = false) {
	// when value is 0 or null show "—" Em dash
	if (!value) {
		return '—'
	}

	// remove negative
	const displayValue = Math.abs(value)
	const formattedDisplayValue = priceCurrency(currency, displayValue)
	// check is negative
	const negative = value < 0 ? true : false

	// example: + 3 or - 15
	if (variance) {
		return negative ? `- ${formattedDisplayValue}` : `+ ${formattedDisplayValue}`
	}

	// example: $50 or $(100)
	return negative ? `(${formattedDisplayValue})` : `${formattedDisplayValue}`
}
