// @ts-strict-ignore
import { toString } from 'lodash/fp'
import { TemplateMatterType } from '@persuit/ui-graphql/generated'

export const MATTER_TYPES: Array<{ name: string; id: TemplateMatterType }> = [
	{ name: 'Litigation and Investigations', id: 'litigationsAndInvestigations' },
	{ name: 'Transactions and M&A', id: 'transactionsAndMandA' },
	{ name: 'Legal Advice Projects', id: 'legalAdviceProjects' },
	{ name: 'Intellectual Property (Non Lit)', id: 'intellectualProperty' },
	{ name: 'Regulatory Services (Non Dispute)', id: 'regulatoryServices' },
	{ name: 'Finance and Bankruptcy Practice', id: 'financialAndBankruptcyPractice' },
	{ name: 'Consulting, Tax and Accounting', id: 'consultingTaxAccounting' },
	{ name: 'IT Services and SaaS', id: 'itServicesSaas' },
	{ name: 'Marketing and Advertising Services', id: 'marketingAdvertisingServices' },
	{ name: 'Generic Category', id: 'anyMatter' },
]

export const MATTER_TYPE_LABEL_MAP = MATTER_TYPES.reduce(
	(map, item) => ({ ...map, [item.id]: item.name }),
	{} as Record<TemplateMatterType, string>,
)

export const templateMatterTypeTypes = MATTER_TYPES.map((matterType) => toString(matterType.id))

export function isTemplateMatterType(value: string): value is TemplateMatterType {
	return !!MATTER_TYPE_LABEL_MAP[value]
}

export type TemplateCountryInfo = { name: string; id: string }

export const COUNTRIES: TemplateCountryInfo[] = [
	{ name: 'Global', id: 'global' },
	{ name: 'USA', id: 'us' },
	{ name: 'UK', id: 'uk' },
	{ name: 'EU', id: 'eu' },
	{ name: 'Brazil', id: 'brazil' },
	{ name: 'Australia', id: 'australia' },
	{ name: 'Germany', id: 'germany' },
	{ name: 'France', id: 'france' },
	{ name: 'Mexico', id: 'mx' },
	{ name: 'Argentina', id: 'ar' },
	{ name: 'China', id: 'cn' },
	{ name: 'Norway', id: 'no' },
	{ name: 'Hong Kong', id: 'hk' },
	{ name: 'Canada', id: 'canada' },
	{ name: 'Switzerland', id: 'switzerland' },
	{ name: 'India', id: 'in' },
	{ name: 'Israel', id: 'il' },
	{ name: 'Japan', id: 'jp' },
	{ name: 'Netherlands', id: 'nl' },
]
