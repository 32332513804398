// @ts-strict-ignore
import numeral from 'numeral'
import currencies, { codes as currencyCodes } from '../../common/data/currencies'

/**
 * Formats a number as a currency. Supports both Variance and Financial
 * @param currencyCode AUD, USD, PLN
 * @param price
 * @param showCurrencyCode default to true. Used in the Comparator cell to provide more room
 */

export default (currencyCode: string, price: string | number, showCurrencyCode = true): string => {
	if (!currencyCodes.includes(currencyCode)) {
		throw new Error(`${currencyCode} not a valid currency`)
	}
	const currencySymbol = currencies[currencyCode].symbol
	// If no price set, make price 0 for display purposes
	// commonly seen on firm draft proposal preview/comparator
	const formattedPrice = !price
		? 0
		: Number.isInteger(parseInt(`${price}`, 10))
		? numeral(price).format('0,0.[0000]')
		: price

	// Add currency code (AUD,USD) when currencySymnol is "$"
	// Also option to not show currencyCode at all
	return currencySymbol === '$' && showCurrencyCode
		? `${currencySymbol}${formattedPrice} ${currencyCode}`
		: `${currencySymbol}${formattedPrice}`
}

export function currencySymbol(currencyCode: string): string {
	if (!currencyCodes.includes(currencyCode)) {
		throw new Error(`${currencyCode} not a valid currency`)
	}
	return currencies[currencyCode].symbol
}
