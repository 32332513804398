import getFirmStatesProps from './get-firm-states-props'
import FirmStates, { Eliminated } from './firm-states'
import RevisionRequired from './revision-required-status'
import { Typography, Tooltip, useTheme } from '@persuit/ui-components'
import { useShouldShowAuctionAlert } from '../hooks'
import FirmStatusTypes from './firm-status-types'
import { colorCorePink90 } from '@persuit/ui-components/tokens'

import { mapServerDataToProposalProps } from '../transforms'

const FirmStatus = ({ rfp, request, firm, showAlerts, expanded = true }) => {
	const theme = useTheme()
	const proposalStatus = mapServerDataToProposalProps(rfp)
	const { status, statusLine2, statusLine3 } = getFirmStatesProps(request, firm, proposalStatus)
	const showAuctionAlert =
		useShouldShowAuctionAlert({ rfp, showAlerts }) && status === FirmStatusTypes.AUCTION_IN_PROGRESS

	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						color: theme.palette.text.primary,
						bgcolor: colorCorePink90,
						'& .MuiTooltip-arrow': {
							color: colorCorePink90,
						},
					},
				},
				popper: {
					sx: {
						zIndex: 1000,
					},
				},
			}}
			title={<Typography style={{ whiteSpace: 'nowrap' }}>Auction extended by 5 mins</Typography>}
			arrow={true}
			open={showAuctionAlert}
		>
			<div>
				<FirmStates
					status={status}
					statusLine2={statusLine2}
					statusLine3={statusLine3}
					expanded={expanded}
				/>
			</div>
		</Tooltip>
	)
}

FirmStatus.defaultProps = {
	request: {
		status: null,
		isAuction: false,
		auctionStart: null,
		auctionEnd: null,
		initialProposalsDueBy: null,
		proposalsDueBy: null,
		markedCompleteManually: null,
		lastBid: null,
		isRequestClosed: null,
	},
	firm: {
		canParticipate: true,
		isEliminated: false,
		isRequestReopened: false,
		isRequestReopenedForFirm: false,
	},
}

export { Eliminated, RevisionRequired }

export default FirmStatus
