import {
	ResponseToRate as ResponseToRateType,
	FirmSuppliedRate as FirmSuppliedRateType,
	Rate as RateType,
	CombinedRates,
} from '../types'

export const getCombinedRates = (
	responseToRates: ResponseToRateType[],
	firmSuppliedRates: FirmSuppliedRateType[],
	rates: RateType[],
): CombinedRates[] => {
	return [
		...responseToRates.map(({ _id, rate, rateId }) => ({
			_id,
			rate,
			title: rates.find((rate) => rate._id === rateId)?.label ?? '',
		})),
		...firmSuppliedRates.map(({ _id, rate, label }) => ({ _id, rate, title: label })),
	]
}
