import * as React from 'react'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import DialogContent from '@mui/material/DialogContent'

import { DialogWizardStepper, DialogWizardStepperProps } from './dialog-wizard-stepper'
import { DialogWizardActions, DialogWizardActionsProps } from './dialog-wizard-actions'

type DialogWizardProps = {
	children?: React.ReactNode
} & DialogWizardStepperProps &
	DialogWizardActionsProps

export default function DialogWizard({
	isWorking,
	children,
	secondaryTextForSteps,
	activeStep,
	onPrev,
	onNext,
	onConfirm,
	onCancel,
	steps,
	disableConfirmButton,
	confirmDataTrackId,
}: DialogWizardProps) {
	return (
		<React.Fragment>
			<DialogContent>
				<DialogWizardStepper
					steps={steps}
					activeStep={activeStep}
					secondaryTextForSteps={secondaryTextForSteps}
				/>
				{children}
			</DialogContent>
			<DialogWizardActions
				onNext={onNext}
				onPrev={onPrev}
				onConfirm={onConfirm}
				confirmDataTrackId={confirmDataTrackId}
				onCancel={onCancel}
				isWorking={isWorking}
				disableConfirmButton={disableConfirmButton}
			/>
		</React.Fragment>
	)
}
