import { withFeatureToggleProvider } from '@persuit/ui-feature-toggle'

type AppPublicProps = {
	children: React.ReactNode
}

/**
 * Container for non protected route
 */
const AppPublic = ({ children }: AppPublicProps) => {
	return <>{children}</>
}

export default withFeatureToggleProvider()(AppPublic)
