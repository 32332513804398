import { useMutation, useQuery } from '@apollo/client'
import { first, keys, size } from 'lodash/fp'
import { useEffect, useRef } from 'react'
import { checkFilesQueued } from '../../../../common/file-status'

const POLLING_INTERVAL = 500

const mapFilesForSync = (files) => files.map(({ _id }) => _id)

export const getFileList = (data = {}) => {
	const dataKeys = keys(data)
	if (size(dataKeys) === 0) {
		return []
	}

	const fileListKey = first(dataKeys)
	return data[fileListKey] || []
}

export const useFileGQLAdapter = ({
	documentId,
	getFilesQuery: GET_FILES_FOR_DOCUMENT_QUERY,
	updateFilesMutation: UPDATE_FILES_FOR_DOCUMENT_MUTATION,
	refetchQueries = [],
	fetchPolicy = 'network-only',
}) => {
	// setup a reference to track if polling is enabled
	const isPolling = useRef(false)

	const { data, loading, error, startPolling, stopPolling } = useQuery(
		GET_FILES_FOR_DOCUMENT_QUERY,
		{
			variables: {
				documentId,
			},
			fetchPolicy,
		},
	)

	const [mutate, { loading: updatingFilesForDocument, error: errorUpdatingFilesForDocument }] =
		useMutation(UPDATE_FILES_FOR_DOCUMENT_MUTATION, {
			refetchQueries: [
				{
					query: GET_FILES_FOR_DOCUMENT_QUERY,
					variables: {
						documentId,
					},
				},
				...refetchQueries,
			],
		})

	const files = getFileList(data)

	// Stop polling once all files have scanned
	useEffect(() => {
		if (isPolling.current === true) {
			if (!checkFilesQueued(files)) {
				isPolling.current = false
				stopPolling()
			}
		}
	}, [files, stopPolling])

	// Start polling when files are in queued state
	useEffect(() => {
		if (isPolling.current === false) {
			if (checkFilesQueued(files)) {
				isPolling.current = true
				startPolling(POLLING_INTERVAL)
			}
		}
	}, [files, startPolling])

	return {
		filesForDocument: files,
		fetchingFilesForDocument: loading,
		errorFetchingFilesForDocument: error,
		updateFilesForDocument: (files) => {
			const fileIds = mapFilesForSync(files)
			return mutate({
				variables: { documentId, fileIds },
			})
		},
		updatingFilesForDocument,
		errorUpdatingFilesForDocument,
	}
}
