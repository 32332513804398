import { Fragment } from 'react'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

import { Typography } from '@persuit/ui-components'
import { DueDateTicker } from '../due-date-ticker'

export type ProgressCardWaitForProposalsProps = ProgressCardProps

export const CARD_TITLE = 'Await proposals'

export const ProgressCardWaitForProposals = ({
	index,
	step,
	extraData,
}: ProgressCardWaitForProposalsProps) => {
	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				subtitle={
					<Fragment>
						{extraData.dueDate && (
							<DueDateTicker prefix="Proposals due" dueDate={extraData.dueDate} />
						)}
						<Typography>{extraData.totalProposals} proposals received</Typography>
						<Typography>
							{extraData.totalFirmsParticipating}/{extraData.totalFirmsInvited} firms have agreed to
							participate
						</Typography>
						<Typography>
							{extraData.totalFirmsNotParticipating}/{extraData.totalFirmsInvited} firms unable to
							participate
						</Typography>
					</Fragment>
				}
			/>
		)

	if (step.status === 'completed') {
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				subtitle={<Typography>{extraData.totalProposals} proposals received</Typography>}
			/>
		)
	}

	return <BaseCard title={CARD_TITLE} index={index} status={step.status} />
}
