import { getManagerTabs } from '@client/components/rfp/rfp-edit-tabs'
import Tabs from '@client/components/tabs'
import { FragmentType, getFragment, graphql } from '@persuit/ui-graphql'
import { useTranslation } from 'react-i18next'
import tabNames from '../../../common/data/rfp-tabs'

const RfpTabsFragment = graphql(`
	fragment RfpTabs_Analytics on Analytics {
		# Make an Analytics object look sufficiently like an RFP
		_id: listingId
		headline: listingTitle
		tracking: tracked {
			referenceNo
			customFields {
				# Matches fields requested by src/client/graphql/fragments/rfp-gql
				fieldType
				customTrackingFieldId
				hierarchyValue
				plainTextValue
				richTextValue
				numericValue
				dateValue
			}
		}
	}
`)

export type RfpTabsProps = {
	rfpFragment: FragmentType<typeof RfpTabsFragment>
}

export function RfpTabs({ rfpFragment }: RfpTabsProps) {
	const { t } = useTranslation()
	const rfp = getFragment(RfpTabsFragment, rfpFragment)

	return (
		<Tabs
			tabs={getManagerTabs({
				rfp,
				currentTab: tabNames.TRACKING,
				handleRequestTabChange: () => {
					// There is no tab to change to, so this function is a noop
				},
				t,
			})}
			currentTab={tabNames.TRACKING}
		/>
	)
}
