import { graphql, unmaskNode } from '@persuit/ui-graphql'

export const getReponsesQueryDEPRECATED = unmaskNode(
	graphql(`
		query RfpDetailResponses($requestId: ID!) {
			getResponses(requestId: $requestId) {
				__typename
				... on Response {
					...Response_GQL_DEPRECATED
					rank
					numResponses
					responseToDeliverableRanks {
						responseToDeliverableId
						rank
						tied
					}
				}

				... on CompetitorResponse {
					...CompetitorResponse_GQL_DEPRECATED
				}
			}
		}
	`),
)
