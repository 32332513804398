import axiosInstance from '../axios'
import { getOrFalse } from '../utils/lenses'

const setSessionInterval = (handler, timeout) => {
	let baseTime = Date.now()
	const checkTimeout = async () => {
		const checkTime = Date.now() - baseTime
		if (checkTime >= timeout) {
			await handler()
			baseTime = Date.now()
		}
	}

	// check the timeout each 10 seconds
	return window.setInterval(checkTimeout, 10000)
}

// If the request is user initiated then reset the 6 hour limit
async function setMaxCookieTimeout() {
	try {
		await axiosInstance.get('/user/refresh-session-timeout/')
	} catch (e) {
		console.error('Failed to extend session timeout')
	}
}

// This function polls the server to refresh the session.
// It also logs out if the max session timeout is exceeded.
const refreshCookieRequest = async () => {
	try {
		const result = await axiosInstance.get('/user/refresh-cookie/')
		const isSessionTimedOut = getOrFalse('data.maxSessionTimeout', result)
		// If the session max has been reached call logout.
		if (isSessionTimedOut) {
			logout()
		}
	} catch (e) {
		// this isnt really an error. if our endpoint returns a 401 then the session has expired
		logout()
	}
}

// Setup a timeout to refresh the session cookie every so often
// The '/refresh-cookie/' endpoint will bump the cookie expiry everytime it is requested
// The '/refresh-cookie/' endpoint shouldnt reset the 6 hour logout timer
// using let here seems to prevent instantiating multiple timers
let refreshCookieTimeout
async function setRefreshCookieTimeout() {
	clearInterval(refreshCookieTimeout)

	const refreshTimeout = window.__SESSION_COOKIE_REFRESH_INTERVAL__
	refreshCookieTimeout = setSessionInterval(refreshCookieRequest, refreshTimeout)
}

// Redirect to the login page and show an alert
export async function logout() {
	try {
		await axiosInstance.post('/user/logout/')
		// Dont redirect on the "/en/user/*" routes
		if (!/\/en\/user/.test(window.location.pathname)) {
			window.location.replace('/en/user/login/?sessionexpired=1')
		}
	} catch (e) {
		console.error(e)
	}
}

export const refreshUserInitiatedRequest = () => {
	setRefreshCookieTimeout()
	setMaxCookieTimeout()
}
