import { filterWithdrawn, filterRevised, filterEliminated } from './filters'
import { partition } from 'lodash'
import { getSortedResponses } from './get-sorted-responses'

// Filter the responses
export default function getClientResponses({
	sort,
	responses,
	showWithdrawnProposals,
	showRevisedProposals,
	showEliminatedProposals,
	pinnedProposals,
	showUnpinned = true,
	request,
	isUAMToggledOn,
}) {
	const responsesSansWithdrawn = !showWithdrawnProposals ? filterWithdrawn(responses) : responses

	const responsesSansWithdrawnRevised = !showRevisedProposals
		? filterRevised(responsesSansWithdrawn)
		: responsesSansWithdrawn

	const responsesSansWithdrawnRevisedEliminated = !showEliminatedProposals
		? filterEliminated(responsesSansWithdrawnRevised)
		: responsesSansWithdrawnRevised

	const [pinned, notPinned] = partition(responsesSansWithdrawnRevisedEliminated, (r) =>
		pinnedProposals.includes(r._id),
	)

	const sortedPinnedResponses = getSortedResponses({
		request,
		responses: pinned,
		sort,
		isUAMToggledOn,
	})

	const sortedUnpinnedResponses = getSortedResponses({
		request,
		responses: notPinned,
		sort,
		isUAMToggledOn,
	})

	const clientResponses = showUnpinned
		? [...sortedPinnedResponses, ...sortedUnpinnedResponses]
		: sortedPinnedResponses

	return clientResponses
}
