import { useState, useId } from 'react'
import {
	Box,
	Button,
	ArrowRightIcon,
	ArrowDropDownIcon,
	useTheme,
	Paper,
} from '@persuit/ui-components'
import { ConflictRevealContent, ConflictRevealContentDocType } from '../conflict-reveal-content'

export type ConflictRevealDocType = ConflictRevealContentDocType

type ConflictRevealProps = {
	document: ConflictRevealDocType
}

export const ConflictReveal = ({ document }: ConflictRevealProps) => {
	const theme = useTheme()
	const conflictCheckDescriptionId = useId()
	const [revealed, setRevealed] = useState(false)
	const toggleRevealed = () => setRevealed(!revealed)

	return (
		<Box>
			<Button
				startIcon={revealed ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
				onClick={toggleRevealed}
				color="primary"
				aria-expanded={revealed ? 'true' : 'false'}
				aria-controls={conflictCheckDescriptionId}
			>
				Conflict Check / T&Cs / NDA preview
			</Button>
			{revealed && (
				<Box
					borderRadius="2px"
					paddingLeft={1}
					paddingY={1}
					borderLeft={`4px solid ${theme.palette.primary.main}`}
					padding={1}
				>
					<Paper id={conflictCheckDescriptionId}>
						<Box padding={1}>
							<ConflictRevealContent document={document} />
						</Box>
					</Paper>
				</Box>
			)}
		</Box>
	)
}
