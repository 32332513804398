import { Box } from '../../box'
import { IconButton } from '../../icon-button'
import { ListItem, ListItemText, ListItemSecondaryAction, ListItemButton } from '../../list'
import { styled } from '../../../theme'
import { ErrorOutlineIcon, DeleteIcon } from '../../../icons'
import filesize from 'filesize'
import {
	checkFileStatusClean,
	checkFileStatusOversize,
	checkFileStatusThreat,
	checkFileStatusQueued,
} from '../utils'
import { FileScanState } from '../types'

export type FileListItemProps = {
	file: {
		_id: string
		filename?: string | null
		fileStatus?: FileScanState | null
		length?: number | null
	}
	onRemoveFile?: () => void
	disabled?: boolean
}

export const FileListItem = ({
	file: { _id, filename = '', fileStatus, length },
	onRemoveFile,
	disabled,
}: FileListItemProps) => {
	const FileItemComponent: typeof StyledListItem =
		fileStatus === 'clean' ? StyledListItem : DisabledListItem

	const getFileSize = (fileLength: number | null | undefined) =>
		fileLength === undefined || fileLength === null ? null : filesize(fileLength)

	const clean = checkFileStatusClean(fileStatus)

	return (
		<FileItemComponent sx={{ pl: clean ? 0 : undefined, py: clean ? 0 : undefined }}>
			{clean ? (
				<ListItemButton
					aria-label={`Download ${filename}`}
					onClick={clean ? () => (window.location.href = `/en/file/${_id}`) : undefined}
				>
					<ListItemText
						primary={filename}
						secondary={getFileSize(length)}
						secondaryTypographyProps={{
							color: 'text.primary',
						}}
					/>
				</ListItemButton>
			) : (
				<ListItemText primary={filename} secondary={getFileSize(length)} />
			)}

			{checkFileStatusThreat(fileStatus) ? <ErrorOutlineIcon color="error" /> : null}

			{checkFileStatusQueued(fileStatus) ? (
				<Box
					fontSize="0.9rem"
					fontWeight="500"
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					color="#4552c4"
					margin="0 1rem 0 0.5rem"
				>
					Scanning for viruses...
				</Box>
			) : checkFileStatusOversize(fileStatus) ? (
				<Box fontSize="0.9rem" fontWeight="500" color="error.main" margin="0 1rem 0 0.5rem">
					Exceeds 50MB size limit!
				</Box>
			) : checkFileStatusThreat(fileStatus) ? (
				<Box fontSize="0.9rem" fontWeight="500" color="error.main" margin="0 1rem 0 0.5rem">
					Malicious file detected!
				</Box>
			) : null}

			{!onRemoveFile ? null : (
				<ListItemSecondaryAction>
					<IconButton
						onClick={onRemoveFile}
						disabled={disabled}
						size="large"
						aria-label={`Remove ${filename}`}
					>
						<DeleteIcon />
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</FileItemComponent>
	)
}

const StyledListItem = styled(ListItem)`
	&.MuiListItem-root {
		background-color: ${({ theme }) => theme.palette.background.appMain};
		margin-bottom: 0.5rem;
		cursor: pointer;
	}
	&.MuiListItem-root:hover {
		background-color: ${({ theme }) => theme.palette.grey300};
	}
`

const DisabledListItem = styled(ListItem)`
	&.MuiListItem-root {
		color: ${({ theme }) => theme.palette.text.secondary};
		background-color: ${({ theme }) => theme.palette.grey100};
		margin-bottom: 0.5rem;
		cursor: not-allowed;
	}
`
