import { useHasChangedRecently } from '@persuit/ui-hooks'
import { useRef } from 'react'

const ALERT_DISPLAY_PERIOD = 4000
const ALERT_LAST_DISPLAYED_KEY_PREFIX = 'auction_alert.hide_until'

type UseShouldShowAuctionAlertRfp = {
	_id: string
	auctionEnd: number
	auctionExtendedByFirm: boolean
}

export type UseShouldShowAuctionAlertProps = {
	rfp: UseShouldShowAuctionAlertRfp
	showAlerts: boolean
}

export function useShouldShowAuctionAlert({
	rfp,
	showAlerts,
}: UseShouldShowAuctionAlertProps): boolean {
	// This will be true for ALERT_DISPLAY_PERIOD seconds each time rfp.auctionEnd
	// changes
	const auctionAlertChangedRecently = useHasChangedRecently({
		value: rfp.auctionEnd,
		period: ALERT_DISPLAY_PERIOD,
	})
	const isFirstComponentToShowAlert = useIsFirstComponentToShowCurrentAuctionExtensionAlert(rfp)

	return Boolean(
		showAlerts &&
			rfp.auctionExtendedByFirm &&
			auctionAlertChangedRecently &&
			isFirstComponentToShowAlert,
	)
}

/**
 * Returns true if this is the first component to display the auction-extended
 * alert for the most recent auction extension of this RFP
 */
function useIsFirstComponentToShowCurrentAuctionExtensionAlert(
	rfp: UseShouldShowAuctionAlertRfp,
): boolean {
	const componentId = useComponentId()

	return componentId === getIdOfFirstComponentToShowCurrentAuctionExtensionAlert(rfp, componentId)
}

/**
 * Returns an ID that's unique to the current mounting of this component
 */
function useComponentId(): string {
	return useRef(String(Math.random())).current
}

/**
 * Returns the ID of the component to first display the alert for the most recent
 * extension to this RFP's auction
 */
function getIdOfFirstComponentToShowCurrentAuctionExtensionAlert(
	rfp: UseShouldShowAuctionAlertRfp,
	componentId: string,
) {
	const key = `${ALERT_LAST_DISPLAYED_KEY_PREFIX}:${rfp._id}:${rfp.auctionEnd}`

	return readSessionStorage(key, componentId)
}

/**
 * Reads the current value for the given key from sessionStorage, setting
 * it to initialValue if it's not already set
 */
function readSessionStorage(key: string, initialValue: string): string {
	// We cannot use useSessionStorage() from react-use here because it assumes that key
	// never changes.
	if (!(key in sessionStorage)) {
		sessionStorage[key] = initialValue
	}

	return sessionStorage[key]
}
