// @ts-strict-ignore
import { useId } from 'react'
import { Box, Typography } from '@persuit/ui-components'
import diversityContent from '../../../request/modules/diversity/diversity-content'
import { useStore, useActions } from '../../store'
import { Row, RowHeaderCell, BaseIntelligenceCell, SectionHeaderRow } from '../components'
import { diversityCellHeaders, diversityRowId, diversitySectionId } from '../accessibilty'
import { DiversityCell } from './diversity-cell'
import { SortButton, SortOrder } from '../components/sort-button'
import { useUAMEnabled } from '@persuit/ui-auth'

export const DiversitySection = () => {
	const { request, showDiversitySection } = useStore((state) => ({
		request: state.request,
		showDiversitySection: state.showDiversitySection,
	}))

	const { toggleShowDiversitySection } = useActions()

	const diversityRequired = request?.detail?.diversity?.diversityRequired || false

	if (!diversityRequired) {
		return null
	}

	return (
		<Box component="tbody" aria-label="Diversity section">
			<SectionHeaderRow
				id={diversitySectionId}
				title="Diversity"
				expanded={showDiversitySection}
				onToggle={toggleShowDiversitySection}
			/>

			{showDiversitySection &&
				Object.keys(diversityContent).map((key, rowIndex) => {
					return <DiversityRow key={rowIndex} diversityKey={key} rowIndex={rowIndex} />
				})}
		</Box>
	)
}

type DiversityRowProps = {
	rowIndex: number
	diversityKey: string
}

const DiversityRow = ({ rowIndex, diversityKey }: DiversityRowProps) => {
	const { enabled: isUAMToggledOn } = useUAMEnabled()
	const { responses, showIntelligenceColumn, currentSort, isClient } = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
		currentSort: state.sort,
		isClient: !state.isFirm,
	}))

	const { setSort } = useActions()

	const sortOrder = currentSort?.key === diversityKey ? currentSort.order : null

	const sortDiversityAnswers = (order: SortOrder) => {
		setSort({
			type: 'diversity',
			key: diversityKey ?? '',
			order,
		})
	}

	const titleId = useId()

	return (
		<Row>
			<RowHeaderCell cellProps={{ id: diversityRowId(diversityKey), 'aria-labelledby': titleId }}>
				<Box>
					{isClient && (
						<SortButton
							sortOrder={sortOrder}
							onClick={sortDiversityAnswers}
							sx={{ float: 'right' }}
						/>
					)}
					<Typography id={titleId}>{`D${rowIndex + 1}: ${
						diversityContent[diversityKey].title
					}`}</Typography>
				</Box>
			</RowHeaderCell>

			{showIntelligenceColumn && <BaseIntelligenceCell cellProps={{ 'aria-hidden': true }} />}

			{responses.map((response, index, array) => {
				const { org, status, group } = response

				const certifications = isUAMToggledOn
					? group?.certifications ?? []
					: org?.certifications ?? []

				return (
					<DiversityCell
						showRightBorder={index === array.length - 1}
						headers={diversityCellHeaders({ responseId: response._id, diversityId: diversityKey })}
						key={response._id}
						responseId={response._id}
						index={rowIndex}
						type={diversityContent[diversityKey].type}
						diversityTitle={diversityContent[diversityKey].title}
						diversityAnswers={response.diversityAnswers as any}
						certifications={certifications}
						responseStatus={status}
					/>
				)
			})}
		</Row>
	)
}
