import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@persuit/ui-graphql'

import { useSelector, useDispatch } from '@client/store'

import { authActions } from '../actions'
import getSessionInfo from '../graphql/queries/getSessionInfo-gql'
import LoadingSpinner from '../components/loading-spinner'
import axios from '../axios'

export const LoadSession = () => {
	const dispatch = useDispatch()

	const isLoggedInStatus = useIsLoggedIn()
	const { data, loading, error } = useQuery(getSessionInfo, { skip: !isLoggedInStatus })

	const isLoggedInState = useSelector((state) => state.auth.isLoggedIn)

	useEffect(() => {
		const setBootstrapData = (...data) => dispatch(authActions.setBootstrapData(...data))

		if (error || loading || !data || isLoggedInState) return

		const { user, isImpersonated, languageCode, featureMap } = data.getSessionInfo

		const userRoles = user?.roles ?? []
		if (Array.isArray(userRoles) && userRoles.includes('admin')) {
			window.location = '/admin/'
		}

		setBootstrapData(user, isImpersonated, languageCode, featureMap)
	}, [data, error, isLoggedInState, loading, dispatch])

	if (error) return null
	if (loading) return <LoadingSpinner spinnerStatic={true} />
	return null
}

const useIsLoggedIn = () => {
	const history = useHistory()
	const [isLoggedInStatus, setIsLoggedInStatus] = useState(false)

	useEffect(() => {
		async function getLoggedInStatus() {
			try {
				const isLoggedInData = await getIsLoggedIn()
				if (isLoggedInData) {
					setIsLoggedInStatus(isLoggedInData.isLoggedIn)
				}
			} catch (e) {
				history.push(`/en/user/login/`)
			}
		}
		getLoggedInStatus()
	}, [history])

	return isLoggedInStatus
}

async function getIsLoggedIn() {
	return axios
		.post(`user/login/isLoggedIn/`)
		.then(({ data }) => data)
		.catch((err) => {
			const error = err.request
			throw error
		})
}
