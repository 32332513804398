export function filterRecord<T extends Record<string, unknown>, U extends T[keyof T]>(
	record: T,
	filterFn: (input: T[keyof T], key: string) => input is U,
): PickType<T, U>
export function filterRecord<T, U extends T>(
	record: Record<string, T>,
	filterFn: (input: T, key: string) => input is U,
): Record<string, U>
export function filterRecord<T>(
	record: Record<string, T>,
	filterFn: (input: T, key: string) => boolean,
): Record<string, T>
export function filterRecord<T>(
	record: Record<string, T>,
	filterFn: (input: T, key: string) => boolean,
): Record<string, T> {
	return Object.fromEntries(Object.entries(record).filter(([k, v]) => filterFn(v, k)))
}

type PickType<T, U> = {
	[K in keyof T as T[K] extends U ? K : never]: T[K]
}
