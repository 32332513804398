import { useEffect } from 'react'
import {
	Box,
	Typography,
	FormCheckbox,
	FormTextField,
	FormRadio,
	PopoverHelp,
	FormLabel,
	Tooltip,
} from '@persuit/ui-components'
import { useDescription } from '@persuit/ui-hooks'
import { FieldRequirementValue } from '@persuit/ui-graphql/generated'
import { fieldName, useWatch, useFormContext } from '../rates-form-utils'

const radioOptions: { label: string; value: FieldRequirementValue }[] = [
	{ label: 'Mandatory', value: 'required' },
	{ label: 'Optional', value: 'optional' },
	{ label: 'Do not request', value: 'hidden' },
]

export type IndividualTimekeepersProps = {
	disableNamedTimekeepersCheckbox?: boolean
}

export const IndividualTimekeepers = ({
	disableNamedTimekeepersCheckbox,
}: IndividualTimekeepersProps) => {
	const methods = useFormContext()
	const tooltip = `Once set "Individual Timekeepers" cannot be enabled / disabled`
	const tooltipDescriptionId = useDescription(tooltip)
	const requestNamedTimekeepers = useWatch({ name: 'requestNamedTimekeepers' })
	const requestBillingId = useWatch({ name: 'namedTimekeepersSettings.requestBillingId' })
	const requestGraduationYear = useWatch({ name: 'namedTimekeepersSettings.requestGraduationYear' })

	useEffect(() => {
		if (requestBillingId === 'hidden') {
			methods.setValue('namedTimekeepersSettings.billingIdInstructions', '')
		}
		if (requestGraduationYear === 'hidden') {
			methods.setValue('namedTimekeepersSettings.graduationYearInstructions', '')
		}
		if (!requestNamedTimekeepers) {
			methods.setValue('namedTimekeepersSettings.requestBillingId', 'required')
			methods.setValue('namedTimekeepersSettings.requestGraduationYear', 'required')
			methods.setValue('namedTimekeepersSettings.billingIdInstructions', '')
			methods.setValue('namedTimekeepersSettings.graduationYearInstructions', '')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBillingId, requestGraduationYear, requestNamedTimekeepers])

	return (
		<Box
			sx={{
				pl: 4,
				mt: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				maxWidth: '600px',
			}}
		>
			<Typography variant="body1Semibold">
				Do you want to receive individual timekeepers?
			</Typography>

			<Tooltip title={tooltip} arrow={true} disabled={!disableNamedTimekeepersCheckbox}>
				<Box sx={{ mt: 1, ml: 1.5 }}>
					<FormCheckbox
						checkboxProps={{
							inputProps: {
								'aria-describedby': disableNamedTimekeepersCheckbox
									? tooltipDescriptionId
									: undefined,
							},
						}}
						disabled={disableNamedTimekeepersCheckbox}
						label="Allow firms to submit individual timekeepers"
						name={fieldName('requestNamedTimekeepers')}
					/>
				</Box>
			</Tooltip>

			<FormRadio
				sx={{ mt: 2 }}
				id="timekeeperid"
				disabled={!requestNamedTimekeepers}
				required={requestNamedTimekeepers}
				name={fieldName('namedTimekeepersSettings.requestBillingId')}
				radioGroupProps={{ sx: { ml: 1.5 } }}
				label={({ id }) => (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<FormLabel component="legend" id={id}>
							Timekeeper ID
						</FormLabel>
						<PopoverHelp
							content={
								<Box>
									<Typography gutterBottom={true}>
										Use the timekeeper ID field to configure the individual timekeepers. You may
										also provide additional instructions i.e outlining that the e-billing ID is
										needed.
									</Typography>
									<Typography>
										<strong>Mandatory</strong> - A response is compulsory.
									</Typography>
									<Typography>
										<strong>Optional</strong> - A response is not compulsory.
									</Typography>
									<Typography>
										<strong>Do not request</strong> - This will be hidden on the request.
									</Typography>
								</Box>
							}
							triggerButtonProps={{
								'aria-label': 'information about timekeeper id',
								size: 'small',
								sx: { p: 0, ml: 1 },
							}}
						/>
					</Box>
				)}
				values={radioOptions}
			/>

			<FormTextField
				sx={{ mt: 2 }}
				fullWidth={true}
				disabled={!requestNamedTimekeepers || requestBillingId === 'hidden'}
				variant="outlined"
				name={fieldName('namedTimekeepersSettings.billingIdInstructions')}
				label="Timekeeper ID instructions (optional)"
				InputLabelProps={{ shrink: true }}
			/>

			<FormRadio
				sx={{ mt: 4 }}
				id="graduationyear"
				disabled={!requestNamedTimekeepers}
				required={requestNamedTimekeepers}
				name={fieldName('namedTimekeepersSettings.requestGraduationYear')}
				radioGroupProps={{ sx: { ml: 1.5 } }}
				label={({ id }) => (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<FormLabel component="legend" id={id}>
							Graduation year
						</FormLabel>
						<PopoverHelp
							content={
								<Box>
									<Typography gutterBottom={true}>
										Use the graduation year field to configure the individual timekeepers. You may
										also provide additional instructions e.g the year they graduated from the bar.
									</Typography>
									<Typography>
										<strong>Mandatory</strong> - A response is compulsory.
									</Typography>
									<Typography>
										<strong>Optional</strong> - A response is not compulsory.
									</Typography>
									<Typography>
										<strong>Do not request</strong> - This will be hidden on the request.
									</Typography>
								</Box>
							}
							triggerButtonProps={{
								'aria-label': 'information about graduation year',
								size: 'small',
								sx: { p: 0, ml: 1 },
							}}
						/>
					</Box>
				)}
				values={radioOptions}
			/>

			<FormTextField
				sx={{ mt: 2 }}
				fullWidth={true}
				variant="outlined"
				defaultValue=""
				disabled={!requestNamedTimekeepers || requestGraduationYear === 'hidden'}
				name={fieldName('namedTimekeepersSettings.graduationYearInstructions')}
				label="Graduation year instructions (optional)"
				InputLabelProps={{ shrink: true }}
			/>
		</Box>
	)
}
