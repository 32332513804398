import { createStore } from '@persuit/ui-state'
import { useSnackbar } from '@persuit/ui-components'
import { useFirmNavigationToPage } from './use-firm-navigation-to-page'
import { useMutation, graphql, getFragment } from '@persuit/ui-graphql'
import ReviseProposalWizard from '../../components/dialog-wizard/revise-proposal/index'
import PROPOSAL_PAGES, { PagesType } from '../../../common/proposals/pages-viewmode'
import proposalGql from '../../graphql/fragments/proposal-gql'

const REVISE_PROPOSAL_MUTATION = graphql(`
	mutation ReviseProposalHookReviseProposal($_id: ID!) {
		underRevisionProposal(_id: $_id) {
			...RfpProposal
		}
	}
`)

type State = {
	shouldOpenDialog: boolean
	navigateToPage: (pageTo: PagesType, options: { proposalId?: string }) => void
} & Partial<UseReviseProposalProps>
const { Provider, useStore, useActions } = createStore(
	{ shouldOpenDialog: false } as State,
	(set) => ({
		openDialog: () =>
			set({
				shouldOpenDialog: true,
			}),
		closeDialog: () =>
			set({
				shouldOpenDialog: false,
			}),
		set,
	}),
)

type UseReviseProposalProps = {
	proposal: any
	request: any
	requestObjectRefetch: () => void
}

const useReviseProposal = ({ proposal, request, requestObjectRefetch }: UseReviseProposalProps) => {
	const { openSnackbar } = useSnackbar()
	const { openDialog, set } = useActions()
	const { navigateToPage } = useFirmNavigationToPage({
		requestObjectRefetch,
		proposalStatus: proposal.status,
	})

	const [reviseProposalMut] = useMutation(REVISE_PROPOSAL_MUTATION)
	const reviseProposal = async (id: string) => reviseProposalMut({ variables: { _id: id } })

	const createProposalRevision = async () => {
		const reviseProposalResponse = await reviseProposal(proposal._id)
		const newProposal = getFragment(proposalGql, reviseProposalResponse.data?.underRevisionProposal)

		openSnackbar('Draft proposal revision created')
		navigateToPage(PROPOSAL_PAGES.EDIT_PROPOSAL, { proposalId: newProposal?._id })
	}

	const handleReviseProposal = () => {
		set({ navigateToPage, proposal, request, requestObjectRefetch })

		if (request.useCase === 'rateReview') {
			createProposalRevision()
		} else {
			openDialog()
		}
	}

	return { handleReviseProposal }
}

const ReviseProposalDialog = () => {
	const { proposal, request, requestObjectRefetch, shouldOpenDialog } = useStore((state) => state)
	const { closeDialog } = useActions()
	const { navigateToPage } = useFirmNavigationToPage({
		requestObjectRefetch,
		proposalStatus: proposal?.status,
	})

	const currency = request?.detail?.currency
	const totalPriceRequired = request?.detail?.totalPriceRequired

	return shouldOpenDialog ? (
		<ReviseProposalWizard
			proposal={proposal}
			handleClose={closeDialog}
			currency={currency}
			navigateToPage={navigateToPage}
			isRequestTotalPriceRequired={totalPriceRequired}
			request={request}
		/>
	) : null
}

type ReviseProposalProviderProps = {
	children: React.ReactNode
}

const ReviseProposalProvider = ({ children }: ReviseProposalProviderProps) => {
	return (
		<Provider>
			<ReviseProposalDialog />
			{children}
		</Provider>
	)
}

export { useReviseProposal, ReviseProposalProvider }
