import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'
import { Box, Form, FormRichTextEditor, Spacer, Typography } from '@persuit/ui-components'

export type ClarificationFormValues = { clarification: string }

export type ClarificationFormProps = {
	saveSection: (input: ClarificationFormValues) => Promise<void>
	sectionData: ClarificationFormValues
	sectionErrors: null | {
		clarification?: string
	}
}

export const ClarificationForm = ({
	saveSection,
	sectionData,
	sectionErrors,
}: ClarificationFormProps) => {
	const methods = useSectionalForm(
		sectionData,
		(values) =>
			saveSection({
				clarification: values.clarification,
			}),
		sectionErrors,
	)

	return (
		<SectionalFormContainer label="Clarification section" focusOnMount={true}>
			<Form methods={methods} aria-label="Clarification form">
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Clarification</Typography>

					<Spacer space={1} />
					<FormRichTextEditor
						id="clarification-input"
						label="Clarification"
						required={true}
						name="clarification"
						height={380}
					/>
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
