import { ReactNode } from 'react'
import * as React from 'react'
import {
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Divider,
	NextIcon,
} from '@persuit/ui-components'

type OptionListProps = {
	primaryText: string
	bullets: string[]
	icon: ReactNode
	disabled?: boolean
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	dataTestid?: string
	dataTrackid?: string
}

export default function OptionList({
	primaryText,
	bullets,
	icon,
	disabled = false,
	onClick,
	dataTestid,
	dataTrackid,
}: OptionListProps) {
	return (
		<React.Fragment key={primaryText}>
			<ListItemButton
				data-testid={dataTestid}
				alignItems="flex-start"
				onClick={(e) => {
					if (!disabled) {
						onClick(e)
					}
				}}
				disabled={disabled}
				data-trackid={dataTrackid}
			>
				{icon && <ListItemIcon>{icon}</ListItemIcon>}
				<ListItemText
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					primary={<span style={{ fontWeight: 500, color: '#444' }}>{primaryText}</span>}
					secondary={
						<React.Fragment>
							<ul
								style={{
									listStyleType: 'disc',
									paddingLeft: 0,
									listStylePosition: 'inside',
								}}
							>
								{bullets.map((bullet) => {
									return <li key={bullet}>{bullet}</li>
								})}
							</ul>
						</React.Fragment>
					}
				/>
				<NextIcon fontSize="small" style={{ alignSelf: 'center' }} />
			</ListItemButton>
			<Divider />
		</React.Fragment>
	)
}
