// @ts-strict-ignore
import { generatePath, useRouteMatch } from 'react-router-dom'
import {
	SectionNavigation,
	getPreviousSection,
	getNextSection,
	SectionData,
} from '../../sectional-form'
import { allSections, getIsSectionEnabled } from '../is-section-enabled'
import { RfpTemplate } from '../types'

const labelMap = {
	detail: 'Template Details',
	summary: 'Summary',
	pricing: 'Pricing',
	rates: 'Rates',
	questions: 'Questions',
	diversity: 'Diversity',
	conflict: 'Conflict Check',
	auction: 'Reverse Auction',
	scorecard: 'Scorecard',
}

function convertSectionToProps(
	section: string,
	path: string,
	templateId: string,
): SectionData | undefined {
	if (!section) return undefined
	const label = labelMap[section]
	const link = generatePath(path, {
		lng: 'en',
		templateId,
		section,
	})

	return { label, link }
}

type RfpTemplateDraftFormSectionNavigationProps = {
	currentSection: string
	rfpTemplate: Pick<RfpTemplate, '_id' | 'useCase'>
	isDiversitySectionEnabled: boolean
}

export const TemplateSectionNavigation = ({
	currentSection,
	rfpTemplate,
	isDiversitySectionEnabled,
}: RfpTemplateDraftFormSectionNavigationProps) => {
	const { path } = useRouteMatch()
	const isSectionEnabled = getIsSectionEnabled({
		isDiversitySectionEnabled,
		useCase: rfpTemplate.useCase ?? undefined,
	})
	const sectionOrder = allSections.filter(isSectionEnabled)
	const previousSection = getPreviousSection(sectionOrder, currentSection)
	const nextSection = getNextSection(sectionOrder, currentSection)
	const previousSectionProps = convertSectionToProps(previousSection, path, rfpTemplate._id)
	const nextSectionProps = convertSectionToProps(nextSection, path, rfpTemplate._id)

	return (
		<SectionNavigation
			previousSection={previousSectionProps}
			nextSection={nextSectionProps}
			isFloating={currentSection === 'pricing' || currentSection === 'questions'}
		/>
	)
}
