import { Slider as MUISlider, SliderProps as MUISliderProps } from '@mui/material'
import { FormHelperText, FormControl, FormLabel } from '../form'

export type Mark = {
	label: string
	value: number
}

export type SliderProps = MUISliderProps & {
	helperText?: string
	error?: string
	label: string
	marks: Array<Mark>
	onChange: (...args: any) => any
	required?: boolean
	ariaValueText?: string
	getAriaLabel?: (arg: number) => string
	valueLabelFormat?: (arg: number) => string
}

export const Slider = ({
	id,
	value,
	helperText,
	error,
	name,
	label = 'scale input',
	onChange,
	required,
	marks = [],
	ariaValueText,
	getAriaLabel,
	valueLabelFormat,
}: SliderProps) => {
	return (
		<FormControl
			error={!!error}
			required={required}
			sx={{ width: '100%', px: 4, mt: 2, mb: 1, paddingLeft: 5, paddingRight: 5 }}
		>
			<FormLabel id={id}>{label}</FormLabel>
			<MUISlider
				aria-labelledby={id}
				aria-valuetext={ariaValueText}
				getAriaLabel={getAriaLabel}
				name={name}
				value={value ?? 0}
				onChange={onChange}
				min={0}
				max={marks.length - 1}
				valueLabelDisplay="auto"
				valueLabelFormat={valueLabelFormat}
				marks={marks}
				track={false}
				sx={{
					'& .MuiSlider-markLabel': {
						width: '100px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						textAlign: 'center',
					},
				}}
			/>
			{(helperText || error) && <FormHelperText>{helperText || error}</FormHelperText>}
		</FormControl>
	)
}
