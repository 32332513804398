import * as React from 'react'

import {
	Typography as MUITypography,
	TypographyProps as MUITypographyProps,
	TypographyTypeMap,
} from '@mui/material'
import { styled } from '../../theme'

type TypographyCustomProps = {
	/** Whether to enable strikethrough style for text. By default it will result in Typography rendering del HTML element. */
	strikeThrough?: boolean
	strikeThroughColor?: string
}

export type TypographyProps<D extends React.ElementType = TypographyTypeMap['defaultComponent']> =
	Omit<MUITypographyProps<D>, 'variantMapping'> & {
		component?: React.ElementType
	} & TypographyCustomProps

const StyledTypography = styled(MUITypography)<TypographyCustomProps>(
	({ strikeThrough, strikeThroughColor, theme }) => `
		${
			strikeThrough
				? `
					text-decoration-line: line-through;
					text-decoration-color: ${strikeThroughColor ?? theme.palette.error.main};
					text-decoration-thickness: 0.75px;
				`
				: ``
		}
	`,
)

const Typography = ({ children, ...props }: TypographyProps) => {
	return (
		<StyledTypography component={props.strikeThrough ? 'del' : undefined} {...props}>
			{children}
		</StyledTypography>
	)
}

Typography.displayName = 'Typography'

export { Typography }
