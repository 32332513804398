// @ts-strict-ignore
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Dialog, Typography, Box } from '@persuit/ui-components'

import formatPriceCurrency from '../../utils/price-and-currency'

export const SAVE_REVISION_COMMENT = gql`
	mutation SaveRevisionComment($revisionCommentInput: RevisionCommentInput!) {
		saveRevisionComment(revisionCommentInput: $revisionCommentInput) {
			_id
		}
	}
`

export type ResponseForNegotiateDialog = {
	_id: string
	org?: null | {
		name?: string | null
	}
	totalPriceModel?: string | null
	totalPriceValue?: number | null
	isPendingPrice?: boolean | null
	hasRevisionRequest?: boolean | null
	hasRfprExists: boolean
}
export type RequestForNegotiateDialog = {
	_id: string
	status?: string | null | undefined
}

type NegotiateDialogProps = {
	handleClose: () => void
	currency: string
	response: ResponseForNegotiateDialog
	request: RequestForNegotiateDialog
}

const pricingModelCopy = {
	fixedFee: 'Fixed Fee',
	cappedFee: 'Capped Fee',
	contingencyFee: 'Contingency Fee',
	successFee: 'Success Fee',
	hourlyRates: 'Hourly Rates',
	hourlyRate: 'Hourly Rate',
	other: 'Detailed below',
	estimate: 'Estimate Only',
	notApplicable: 'Not Applicable',
	none: 'No Preference',
}

export const NegotiateDialog = ({
	handleClose,
	request,
	response,
	currency,
}: NegotiateDialogProps) => {
	const { _id: responseId, org, totalPriceModel, totalPriceValue, hasRfprExists } = response

	const firmOrgName = org?.name
	const requestId = request._id

	const history = useHistory()
	const [saveRevisionComment] = useMutation(SAVE_REVISION_COMMENT)

	const handleConfirm = async (responseId: string, hasRfprExists: boolean) => {
		// Create a new RFPR if none already exist
		if (!hasRfprExists) {
			await saveRevisionComment({
				variables: {
					revisionCommentInput: { proposalId: responseId, rateReview: [], namedTimekeepers: [] },
				},
			})
		}
		history.push(`/en/request/${requestId}/proposals/revise/${responseId}/`)
	}

	const prettyPrice = formatPriceCurrency(currency, totalPriceValue ?? 0)

	if (!requestId) {
		return null
	}

	return (
		<Dialog
			open={true}
			title="Negotiate"
			onClose={handleClose}
			size="sm"
			actions={[
				{ label: 'Cancel', variant: 'text', onClick: handleClose },
				{
					label: 'Negotiate',
					variant: 'primary',
					onClick: () => handleConfirm(responseId, hasRfprExists),
				},
			]}
		>
			<Box mb={0.7}>
				<Typography>{firmOrgName}</Typography>
				{totalPriceModel && (
					<Typography>{`${prettyPrice}, ${pricingModelCopy[totalPriceModel]}`} </Typography>
				)}
			</Box>
			<Typography>
				Negotiate with your firm by red-lining suggested changes to the proposal in the next step.
			</Typography>
			<ul>
				<li>This is private and confidential.</li>
				<li>You can negotiate as many times as needed.</li>
			</ul>
		</Dialog>
	)
}
