import { PureComponent } from 'react'
import { Box, Typography, Divider, LinkButton } from '@persuit/ui-components'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {
	calculateTotalCategoryWeight,
	calculateCategoryWeightAsPercentage,
} from '../../../../../utils/scorecard'

import { proposalScoreActions } from '../../../../../actions'
import { SortButton } from '../../components/sort-button'

class ScoreCardCategories extends PureComponent {
	static defaultProps = {
		showSubcategories: false,
		scoreCategories: [],
		title: '',
	}

	toggleShowCategoriesForm = () => {
		this.props.toggleShowCategoriesForm()
	}

	render() {
		const {
			actionButtons,
			proposalsScoreBeingEdited,
			scoreCategories,
			showSubcategories,
			isClientUser,
			onSort,
			sortOrder,
			isClient,
			categoriesId,
		} = this.props

		const totalWeight = calculateTotalCategoryWeight(scoreCategories)

		const noCategories = (!scoreCategories || scoreCategories.length === 0) && isClientUser

		return (
			<Box id={categoriesId}>
				<Box sx={{ height: `${72 - 16}px` }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography variant="body1Semibold">SCORE</Typography>
						<Box display="flex">
							{isClient && !noCategories && <SortButton sortOrder={sortOrder} onClick={onSort} />}

							{!!actionButtons && <Box>{actionButtons}</Box>}
						</Box>
					</Box>
				</Box>

				{noCategories && (
					<LinkButton
						sx={{ textWrap: 'nowrap', cursor: 'pointer' }}
						variant="body2Link"
						color="primary"
						onClick={this.toggleShowCategoriesForm}
						data-testid="open-scorecard-category-dialog"
					>
						Add categories to create a scorecard
					</LinkButton>
				)}

				<Box role="list" aria-label="Categories">
					{!!scoreCategories &&
						scoreCategories.map((category) => {
							const {
								subcategories,
								_id: categoryId,
								label: categoryLabel,
								weight: categoryWeight,
							} = category

							const weightPercentage = calculateCategoryWeightAsPercentage(
								categoryWeight,
								totalWeight,
							)

							return (
								<div key={categoryId} role="listitem">
									<Box
										sx={{
											mt: 1,
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<Typography
											variant="body1Semibold"
											fontSize="20px"
											sx={{
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											}}
										>
											{categoryLabel}
										</Typography>

										<Typography variant="body1Semibold" fontSize="20px" sx={{ ml: 2 }}>
											{weightPercentage}%
										</Typography>
									</Box>

									<Divider />

									{(showSubcategories || proposalsScoreBeingEdited) && (
										<Box sx={{ mt: 1 }} role="list" aria-label="Subcategories">
											{Object.keys(subcategories).map((subcategoryId) => {
												const { label } = subcategories[subcategoryId]

												return (
													<Box key={subcategoryId} role="listitem">
														<Typography
															color="text.secondary"
															sx={{
																pb: 0.5,
																ml: 2,
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
															}}
														>
															{label}
														</Typography>
													</Box>
												)
											})}
										</Box>
									)}
								</div>
							)
						})}
				</Box>
			</Box>
		)
	}
}

const mapStateToProps = (state) => ({
	// If any other proposals score is currently being edited
	proposalsScoreBeingEdited: state.proposalScores.proposalScoreBeingEdited,

	showSubcategories: state.proposalScores.showSubcategories,
	isClientUser: state.auth.user.roles.includes('requester'),
})

const mapDispatchToProps = {
	toggleShowCategoriesForm: proposalScoreActions.toggleShowCategoriesForm,
}

/** @type {any} */
export default compose(connect(mapStateToProps, mapDispatchToProps))(ScoreCardCategories)
