import { BoxProps as MUIBoxProps, Box as MUIBox } from '@mui/material'
export { Box } from '@mui/material'
import { DataAttributes } from '../../utilities'
import { styled } from '../../theme'

export type BoxProps<D extends React.ElementType = 'div', P = unknown> = DataAttributes<
	MUIBoxProps<D, P>
>

export type BorderBoxProps = BoxProps

export const BorderBox = styled(MUIBox)`
	& * {
		box-sizing: border-box;
	}
`

export const ContentBox = styled(MUIBox)`
	& * {
		box-sizing: content-box;
	}
`
