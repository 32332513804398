import { useState } from 'react'
import {
	ScrollShadows,
	DialogDeprecated,
	DialogDeprecatedProps,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
	Box,
	FormRadio,
	Form,
	useTheme,
	CrownIcon,
	DialogActions,
	DialogClose,
} from '@persuit/ui-components'
import { USE_CASES } from 'src/common/data/use-cases'
import { UseCase } from '@persuit/ui-graphql/generated'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type ChangeUseCaseDialogProps = {
	onClose: () => void
	forceClose: () => void
	useCase: UseCase | null
	saveUseCase: (useCaseId: UseCase) => void
	goBackToUseCaseSelection?: () => void
	useSingleCurrency?: boolean
} & DialogDeprecatedProps

export const ChangeUseCaseDialog = ({
	saveUseCase,
	useCase,
	onClose,
	forceClose,
	goBackToUseCaseSelection,
	useSingleCurrency,
	...props
}: ChangeUseCaseDialogProps) => {
	const { toggles } = useFeatureToggles()
	const theme = useTheme()
	const [selectedUseCase, setSelectedUseCase] = useState<UseCase | null>(useCase ?? null)
	const onCloseInternal = () => {
		onClose()
	}

	//do not show rate review use case if its feature toggle is off
	//sort use cases to show rate review as first item in the list
	const useCases = [
		...USE_CASES.filter((useCase) => {
			if (toggles['dev-5754.company-rate-review'] && useCase.id === 'rateReview') return true
			return false
		}),
		...USE_CASES.filter((useCase) => useCase.id !== 'rateReview'),
	]

	return (
		<DialogDeprecated onClose={onCloseInternal} size="md" {...props}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<DialogTitle>
					<Typography variant="h2XSmall">
						{useSingleCurrency ? 'What is this template for?' : 'Select use case'}
					</Typography>
				</DialogTitle>
				{useCase && <DialogClose onClick={onClose} />}
			</Box>
			<DialogContent>
				<Form>
					<ScrollShadows
						textAlign={'left'}
						mt="1rem"
						minHeight={'40vh'}
						maxHeight={'40vh'}
						overflow="hidden auto"
					>
						<FormRadio
							sx={{
								'& .MuiFormGroup-root .MuiFormControlLabel-root': {
									mt: 1.5,
									mb: 1.5,
									ml: 0,
									alignItems: 'flex-start',
									'& .MuiRadio-root': {
										pt: 0.25,
									},
								},
							}}
							values={useCases.map((useCase) => ({
								label: (
									<Box display={'flex'}>
										<Box maxWidth="700px">
											<Box display="flex" alignItems="center" gap={1}>
												<Typography variant="body1Semibold" noWrap={true} title={useCase.name}>
													{useCase.name}
												</Typography>
												{useCase.id === 'rateReview' && (
													<CrownIcon sx={{ color: theme.palette.premium.main, width: '17px' }} />
												)}
											</Box>
											<Typography
												variant="body2"
												noWrap={true}
												title={useCase.description}
												whiteSpace={'break-spaces'}
												color="text.secondary"
											>
												{useCase.description}
											</Typography>
										</Box>
									</Box>
								),
								value: useCase.id,
							}))}
							id={'useCase-radio'}
							label={null}
							name="useCase"
							radioGroupProps={{
								onChange: (event) => setSelectedUseCase(event.currentTarget.value as UseCase),
								value: selectedUseCase,
								'aria-label': 'use-case-radio',
							}}
						/>
					</ScrollShadows>
				</Form>
			</DialogContent>
			<DialogActions>
				{useCase && (
					<Button variant="text" color="primary" onClick={onCloseInternal}>
						Cancel
					</Button>
				)}
				<Button
					variant="primary"
					disabled={!selectedUseCase}
					onClick={() => {
						if (selectedUseCase) saveUseCase(selectedUseCase)
					}}
				>
					Apply
				</Button>
			</DialogActions>
		</DialogDeprecated>
	)
}
