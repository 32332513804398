// @ts-strict-ignore
import { Fragment } from 'react'
import { Box, List, ListItem, Spacer, Typography, TypographyProps } from '@persuit/ui-components'
import { QuestionResponseDescription } from '../question-response-description'
import { QuestionOption as GQuestionOption } from '@persuit/ui-graphql/generated'

type QuestionOption = Pick<GQuestionOption, '_id' | 'value'>

type RfpDetailQuestionProps = {
	index: number
	prefix?: string
	questionTitle?: string
	questionTitleProps?: TypographyProps
	questionDescription?: string
	rfpQuestionType: string
	questionOptions?: QuestionOption[] | null
}

const isMultiSelect = (rfpQuestionType) => {
	return (
		rfpQuestionType === 'singleSelect' ||
		rfpQuestionType === 'multipleSelect' ||
		rfpQuestionType === 'scale'
	)
}

export const RfpDetailQuestion = ({
	index,
	questionTitle,
	questionTitleProps,
	questionDescription,
	questionOptions,
	rfpQuestionType,
	prefix,
}: RfpDetailQuestionProps) => {
	return (
		<Box>
			<Spacer space={1} shape="row" />

			<Typography variant={prefix ? 'body1Semibold' : 'h3XSmall'} {...questionTitleProps}>
				{prefix}
				{index + 1}. {questionTitle}
			</Typography>

			<Spacer space={2} shape="row" />

			{questionDescription && (
				<Fragment>
					<Typography data-testid="rfp-question-description" variant="body1">
						{questionDescription}
					</Typography>
					<Spacer space={2} shape="row" />
				</Fragment>
			)}

			<Box
				style={{ display: 'flex', alignItems: 'flex-end' }}
				data-testid="rfp-question-type"
				pl={1}
				pr={1}
			>
				<QuestionResponseDescription rfpQuestionType={rfpQuestionType} />
			</Box>

			{isMultiSelect(rfpQuestionType) && questionOptions && questionOptions?.length > 0 && (
				<Box data-testid="rfp-question-option" pl={1} pr={1}>
					<List>
						{questionOptions?.map((option, index) => (
							<ListItem key={index} alignItems="flex-start">
								<Typography
									fontWeight={500}
									variant="body2Semibold"
									sx={{ whiteSpace: 'nowrap' }}
								>{`Option ${index + 1}: `}</Typography>
								&nbsp;
								<Typography variant="body2">{option.value}</Typography>
							</ListItem>
						))}
					</List>
				</Box>
			)}
			<Spacer space={2} shape="row" />
		</Box>
	)
}
