import { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Box, Typography, Divider } from '@persuit/ui-components'

import {
	calculateCategoryScore,
	calculateCategoryWeightAsPercentage,
} from '../../../../../../../utils/scorecard'

import { ScoreInput } from '../../../../../../form-inputs/score'

class ScorecardCategoryField extends PureComponent {
	render() {
		const { fields, scores, scoreCategory, totalWeight } = this.props

		const categoryTotal = calculateCategoryScore(scores) || 'N/A'

		const { subcategories, label: categoryLabel, weight: categoryWeight } = scoreCategory

		const weightPercentage = calculateCategoryWeightAsPercentage(categoryWeight, totalWeight)

		return (
			<Box sx={{ mt: 1, width: '100%' }} role="listitem">
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box
						sx={{
							overflow: 'hidden',
						}}
					>
						<Typography
							variant="body1Semibold"
							fontSize="20px"
							style={{
								paddingRight: '1em',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{categoryLabel}
						</Typography>
					</Box>

					<Box sx={{ display: 'flex' }}>
						<Typography variant="body1Semibold" fontSize="20px" component="span">
							{weightPercentage}%
						</Typography>
						<Typography
							variant="body1Semibold"
							fontSize="20px"
							sx={{ width: '48px' }}
							textAlign="right"
						>
							{categoryTotal}
						</Typography>
					</Box>
				</Box>

				<Divider />

				<Box sx={{ mt: 1 }} role="list" aria-label="Subcategories">
					{fields.map((member, index) => {
						const { label } = subcategories[Object.keys(subcategories)[index]]
						return (
							<Box
								key={index}
								sx={{ display: 'flex', alignItems: 'center', pb: 0.5 }}
								role="listitem"
							>
								<Box
									sx={{
										flex: 1,
										overflow: 'hidden',
										ml: 2,
									}}
								>
									<Typography
										color="text.secondary"
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											pr: 1,
										}}
									>
										{label}
									</Typography>
								</Box>
								<Box sx={{ flex: 1 }}>
									<Field id={`${member}.score`} name={`${member}.score`} component={ScoreInput} />
								</Box>
							</Box>
						)
					})}
				</Box>
			</Box>
		)
	}
}

export { ScorecardCategoryField }
