import { Fragment } from 'react'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'
import { format } from 'date-fns'
import { Typography } from '@persuit/ui-components'
import { publishRfpProgress } from '../../singleton'

export type ProgressCardSendRequestProps = ProgressCardProps

export const CARD_TITLE = 'Send request'

export const ProgressCardSendRequest = ({
	index,
	step,
	extraData,
}: ProgressCardSendRequestProps) => {
	const onActionSend = () => publishRfpProgress('DRAFT:LAUNCH_SEND_DIALOG')

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				action={{
					title: 'SEND',
					onAction: onActionSend,
					dataTrackId: 'rfp-progress-send',
				}}
			/>
		)

	if (step.status === 'completed') {
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				subtitle={
					<Fragment>
						{extraData.publishedAt && (
							<Typography>
								{`Invitation to participate sent to firms on ${format(
									extraData.publishedAt,
									'd LLLL yyyy',
								)}`}
							</Typography>
						)}
					</Fragment>
				}
			/>
		)
	}

	return <BaseCard title={CARD_TITLE} index={index} status={step.status} />
}
