import { Fragment, useId } from 'react'
import * as React from 'react'
import { Box, useTheme, styled, Tooltip, Typography, BoxProps, List } from '@persuit/ui-components'
import { NavLink as Link } from 'react-router-dom'

type SubItemBase = {
	title: string
	error: boolean
	to: string
	prefix?: React.ReactNode
	prefixTitle?: string
	placeholder?: string
}

type NavSubItem = SubItemBase & {
	subItems?: NestedNavSubItem[]
}

type NestedNavSubItem = SubItemBase & {
	title: string
	error: boolean
	to: string
}

export type NavSubItemsProps = {
	subItems: Array<NavSubItem>
	'aria-label': string
}

const INDENT_SPACING = '24px'

export const NavSubItems = ({ subItems, ...rest }: NavSubItemsProps) => {
	const theme = useTheme()
	return (
		<Box
			boxSizing="border-box"
			width="100%"
			bgcolor={theme.palette.grey100}
			padding="4px"
			borderRadius="4px 0px 0px 4px"
			height="auto"
			maxHeight="200px"
			overflow="auto"
		>
			<Box display="flex" flexDirection="column" gap="4px">
				<List aria-label={rest['aria-label']} disablePadding={true} component="ol">
					{subItems.map((item) => {
						return (
							<Fragment key={item.to}>
								<NavSubItem
									{...item}
									subItems={
										item.subItems && item.subItems.length > 0 ? (
											<List
												component="ol"
												aria-label={`${item.title || item.placeholder}'s sub-items`}
												disablePadding={true}
											>
												{item.subItems.map((item) => (
													<NavSubItem
														key={item.to}
														{...item}
														containerProps={{ ml: INDENT_SPACING }}
													/>
												))}
											</List>
										) : null
									}
								/>
							</Fragment>
						)
					})}
				</List>
			</Box>
		</Box>
	)
}

type NavSubItemProps = SubItemBase & {
	containerProps?: BoxProps
	subItems?: React.ReactNode
}

const NavSubItem = ({
	error,
	title,
	to,
	containerProps,
	placeholder = 'Untitled item',
	prefix = null,
	prefixTitle,
	subItems,
}: NavSubItemProps) => {
	const theme = useTheme()
	const lineTitle = title ? title : placeholder
	const descriptionId = useId()

	return (
		<Box {...containerProps} component="li">
			<Tooltip title={lineTitle} key={to}>
				<StyledLink
					to={to}
					aria-label={`${prefixTitle ? `${prefixTitle}: ` : ' '}${lineTitle}`}
					aria-describedby={descriptionId}
				>
					<Typography
						style={{
							display: 'flex',
							fontStyle: title ? undefined : 'italic',
							color: error
								? theme.palette.error.main
								: title
								? theme.palette.text.primary
								: theme.palette.grey700,
						}}
						noWrap={true}
					>
						<Box
							component="span"
							display="flex"
							alignItems="center"
							fontStyle="normal"
							color={error ? theme.palette.error.main : theme.palette.text.primary}
						>
							{prefix}&nbsp;
						</Box>
						{lineTitle}
						<Typography display="none" id={descriptionId}>
							{error ? 'Error presents' : ''}
						</Typography>
					</Typography>
				</StyledLink>
			</Tooltip>
			{subItems}
		</Box>
	)
}

const StyledLink = styled(Link)`
	border-radius: 4px;
	padding: 0px 4px;
	display: flex;
	flex-direction: row;
	gap: 4px;
	&:hover {
		background-color: ${(props) => props.theme.palette.primary.lighterHue};
	}
`
