import {
	Box,
	Button,
	ScreenOnly,
	Tooltip,
	Typography,
	VisibilityIcon,
	NegotiateIcon,
	ThumbUpIcon,
} from '@persuit/ui-components'

import { pink600 } from '../../../../../theme/persuit-colors'
import Spacer from '../../../../layout/spacer.jsx'
import { OnlineStatusIndicator } from '../../../../online-status-indicator/online-status-indicator'
import { RequestReOpenStatus, FirmStatus } from '../../../../status'
import NamwolfLogo from '../../../../namwolf/namwolf-logo'
import RevisionRequiredLabel from '../../../../status/firm/revision-required-status'
import ResponseStatus from '../../../../rfp-proposals/rfp-proposal-status'
import { ReviseProposalButton } from './revise-proposal-button'

type ExpandedHeaderCellProps = {
	auctionInProgress: boolean
	canNotCreate: boolean
	currency: string | null | undefined
	disableNegotiate: boolean
	disableSelect: boolean
	handleViewProposal: () => void
	handleNegotiateProposal: () => void
	handleSelectProposal: () => void
	hasProposalRevisionRequest: boolean
	isCompetitor: boolean
	isEliminated: boolean
	isExample: boolean
	isExampleOrCompetitorProposal: boolean
	isFirmUser: boolean
	isNamwolfMember: boolean
	isResponseDraft: boolean
	orgId: string | null | undefined
	hasParent: boolean
	orgName: string | null | undefined
	request: any
	requestId: string
	requestObject: any
	requestReopenedForInvite: boolean
	response: any
	responseStatus: string
	showSelectProposalButton: boolean
}

export const ExpandedHeaderCell = ({
	auctionInProgress,
	canNotCreate,
	disableNegotiate,
	disableSelect,
	handleViewProposal,
	handleNegotiateProposal,
	handleSelectProposal,
	hasProposalRevisionRequest,
	isCompetitor,
	isEliminated,
	isExample,
	isExampleOrCompetitorProposal,
	isFirmUser,
	isNamwolfMember,
	isResponseDraft,
	orgId,
	hasParent,
	orgName,
	request,
	requestId,
	requestObject,
	requestReopenedForInvite,
	response,
	responseStatus,
	showSelectProposalButton,
}: ExpandedHeaderCellProps) => {
	return (
		<>
			<Box marginTop={2} paddingTop={2} minHeight="50px" textAlign="center">
				{orgName && (
					<Typography variant="body1" strikeThrough={!!isEliminated} component="span">
						{request.auction && auctionInProgress && orgId && (
							<Box display="inline-block" mr={1} sx={{ transform: 'translateY(2px)' }}>
								<OnlineStatusIndicator rfpId={requestId} orgId={orgId} />
							</Box>
						)}
						{orgName}
						<Box display="inline-block" ml={0.5}>
							{isNamwolfMember && <NamwolfLogo />}
						</Box>
					</Typography>
				)}
				{isExample && <Typography fontStyle="italic">Example response</Typography>}
			</Box>

			{requestReopenedForInvite && <RequestReOpenStatus />}
			<Spacer />
			{hasProposalRevisionRequest && !isEliminated && <RevisionRequiredLabel />}

			<Spacer direction="column" space={2} />

			<ResponseStatus
				status={responseStatus}
				hasParent={hasParent}
				isEliminated={isEliminated}
				EliminatedStatusComponent={() => <FirmStatus rfp={request} rfpProposal={response} />}
			/>

			<Spacer direction="column" space={2} />

			{isCompetitor && (
				<>
					<Typography color={pink600} textAlign="center" marginTop={2}>
						Proposal from competitor firm
					</Typography>
					<Spacer direction="column" space={1} />
				</>
			)}
			<Box flexGrow={{ flex: '1 1 auto' }} />
			<ScreenOnly>
				<Box display="flex" alignItems="center" flexDirection="column" width="100%">
					{/* Do not show VIEW PROPOSAL button if proposal is an example or from a competitor */}
					{!isExampleOrCompetitorProposal && (
						<Button
							startIcon={<VisibilityIcon />}
							variant="contained"
							color="primary"
							onClick={handleViewProposal}
							data-testid="view-proposal-button"
							fullWidth={true}
							aria-label="View Proposal"
						>
							{isResponseDraft && !canNotCreate ? 'Continue editing' : 'View'}
						</Button>
					)}
					{showSelectProposalButton && (
						<Box display="flex" alignItems="center" width="100%" marginTop={1}>
							<Tooltip
								describeChild={true}
								title="Suggest changes on this proposal"
								style={{ width: '100%' }}
							>
								<Button
									startIcon={<NegotiateIcon />}
									variant="outlined"
									color="primary"
									disabled={disableNegotiate}
									onClick={handleNegotiateProposal}
									aria-label="negotiate"
									fullWidth={true}
									data-trackid="button-negotiate-comparator"
								>
									Negotiate
								</Button>
							</Tooltip>
							<Spacer direction="row" space={2} />
							<Tooltip
								describeChild={true}
								title="Select this firm’s proposal"
								style={{ width: '100%' }}
							>
								<Button
									startIcon={<ThumbUpIcon />}
									variant="outlined"
									color="primary"
									disabled={disableSelect}
									onClick={handleSelectProposal}
									aria-label="select"
									fullWidth={true}
								>
									Select
								</Button>
							</Tooltip>
						</Box>
					)}
				</Box>
			</ScreenOnly>

			{isFirmUser && (
				<>
					<Spacer space={1} />
					<ReviseProposalButton
						request={request}
						proposal={response}
						requestObject={requestObject}
					/>
				</>
			)}
		</>
	)
}
