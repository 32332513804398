// @ts-strict-ignore
import { Fragment } from 'react'
import { useTheme, Typography, Box } from '@persuit/ui-components'
import { isNotNil } from '@persuit/common-utils'
import { useStore, useActions } from '../../store'

import { QuestionIntelligenceCell } from './question-intelligence-cell'

import { Response, Request } from '../types'
import { SectionHeaderRow, Row } from '../components'
import {
	questionCellHeaders,
	questionRowId,
	questionGroupRowId,
	questionsSectionId,
	questionIntelligenceCellHeaders,
} from '../accessibilty'

import { QuestionHeaderCell } from './question-header-cell'
import QuestionAnswerCell from './question-cell'
import { SortOrder } from '../components/sort-button'

function groupQuestions(questions, questionGroups) {
	if (!questionGroups || !questionGroups.length || questionGroups.length === 0) {
		return []
	}

	const grouped = questionGroups.reduce((acc, group) => {
		const foundForGroup = questions.filter(
			(question) => question.groupId.toString() === group._id.toString(),
		)
		acc.push({
			name: group.name,
			hidden: group.hidden,
			questions: foundForGroup,
		})

		return acc
	}, [])

	return grouped
}

export const QuestionsSection = () => {
	const {
		request,
		responses,
		showIntelligenceColumn,
		showQuestionsSection: expanded,
	} = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
		request: state.request,
		showQuestionsSection: state.showQuestionsSection,
	}))

	const { toggleShowQuestionsSection } = useActions()
	const theme = useTheme()

	const questions = request.detail.questions
	const questionGroups = request.detail.questionGroups

	if (!questions || questions.length === 0) {
		return null
	}

	const groupedQuestions = groupQuestions(questions, questionGroups)

	return (
		<Box component="tbody" aria-label="Questions section">
			<SectionHeaderRow
				id={questionsSectionId}
				title="Questions"
				expanded={expanded}
				onToggle={toggleShowQuestionsSection}
				collapsedText={`${request.detail?.questions?.length} questions hidden`}
			/>

			{expanded &&
				groupedQuestions.map((group, groupIndex) => {
					if (group.hidden) {
						return group.questions.map((question) => (
							<QuestionRow
								showIntelligenceColumn={showIntelligenceColumn}
								key={question?._id}
								question={question as any}
								responses={responses}
								groupIndex={groupIndex}
							/>
						))
					}
					return (
						<Fragment key={groupIndex}>
							<Row sx={{ boxSizing: 'border-box' }}>
								<Box
									id={questionGroupRowId(group._id) ?? ''}
									aria-label={group.name}
									component="th"
									colSpan={999}
									pt={2}
								>
									<Box
										sx={{
											display: 'flex',
											border: `1px solid ${theme.palette.form.borderActive}`,
											borderBottom: '0px',
											borderLeft: '0px',
											background: theme.palette.grey300,
										}}
									>
										<Box
											sx={{
												position: 'sticky',
												left: '0px',
												padding: '4px 12px',
												fontWeight: 'bolder',
												borderLeft: `1px solid ${theme.palette.form.borderActive}`,
												borderBottom: '0px',
												background: theme.palette.grey300,
											}}
										>
											<Typography variant="h3" fontSize="18px">
												{groupIndex + 1}.&nbsp;{group.name}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Row>

							{group.questions.map((question, index) => (
								<QuestionRow
									showIntelligenceColumn={showIntelligenceColumn}
									key={question?._id}
									question={question as any}
									responses={responses}
									index={index}
									groupIndex={groupIndex}
									groupName={group.name}
									lastInGroup={index === group.questions.length - 1}
								/>
							))}
						</Fragment>
					)
				})}
		</Box>
	)
}

export type QuestionRowProps = {
	showIntelligenceColumn: boolean
	responses: Array<Response>
	question: NonNullable<NonNullable<Request['detail']['questions']>[number]>
	index?: number
	groupIndex: number
	groupName?: string
	lastInGroup?: boolean
}

export const QuestionRow = ({
	showIntelligenceColumn,
	responses,
	question,
	index,
	groupIndex,
	groupName,
	lastInGroup,
}: QuestionRowProps) => {
	const theme = useTheme()
	const inGroup = !!groupName

	const { request, currentSort } = useStore((state) => ({
		request: state.request,
		currentSort: state.sort,
	}))

	const { setSort } = useActions()

	const borderStyle = `1px solid ${theme.palette.form.borderActive}`
	const hasMarginTop = !inGroup
	const commonStyles = {
		borderTop: borderStyle,
		borderBottom: (inGroup && lastInGroup) || !inGroup ? borderStyle : 'none',
	}

	const sortPrices = (order: SortOrder) => {
		setSort({
			type: 'questions',
			key: question._id ?? '',
			order,
		})
	}

	return (
		<>
			{hasMarginTop ? (
				<Box component="tr" aria-hidden={true} sx={{ visibility: 'hidden' }}>
					<Box component="td" sx={{ pt: 2 }} />
				</Box>
			) : null}

			<Row>
				<QuestionHeaderCell
					cellProps={{
						id: questionRowId(question._id ?? ''),
						'aria-label': question.title ?? '',
					}}
					title={question.title ?? ''}
					type={question.type ?? ''}
					description={question.description ?? ''}
					index={index}
					groupIndex={groupIndex}
					sx={commonStyles}
					sortOrder={currentSort?.key === question._id ? currentSort?.order : null}
					onSort={sortPrices}
				/>

				{showIntelligenceColumn && (
					<QuestionIntelligenceCell
						question={question}
						headers={questionIntelligenceCellHeaders(question._id ?? '')}
					/>
				)}

				{responses.map((response, responseIndex, array) => {
					const { _id: responseId, status, answersToQuestions } = response

					const questionAnswer =
						answersToQuestions
							?.filter(isNotNil)
							.find(({ questionId }) => questionId === question?._id) ?? null

					const rfpCurrency =
						request.useCase === 'rateReview'
							? request?.rateReview?.currency ?? ''
							: request.detail.currency ?? ''

					return (
						<QuestionAnswerCell
							showRightBorder={responseIndex === array.length - 1}
							headers={questionCellHeaders({ responseId, questionId: question._id ?? '' })}
							key={responseId}
							responseId={responseId}
							currency={rfpCurrency}
							questionAnswer={questionAnswer!}
							questionTitle={question?.title ?? ''}
							questionOptions={question?.options ?? []}
							responseStatus={status}
							index={index}
							groupIndex={groupIndex}
							groupName={groupName}
							sx={commonStyles}
						/>
					)
				})}
			</Row>
		</>
	)
}
