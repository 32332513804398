import { PricingListContextProviderProps } from '../../pricing-list-context'

export const getPricingItemError = (
	pricingErrors: PricingListContextProviderProps['errors'],
	pricingItemIndex: number,
) => {
	if (!pricingErrors) {
		return false
	}

	return pricingErrors?.pricingItemErrors?.find((error) => error.index === pricingItemIndex)
}
