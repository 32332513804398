import { Fragment } from 'react'
import {
	AddIcon,
	Button,
	Tooltip,
	PopoverHelp,
	Box,
	Typography,
	Spacer,
} from '@persuit/ui-components'
import { useWatch } from '../../pricing-form-utils'
import { PricingItemListFooterOptions } from './pricing-sub-item-list-footer-options'
import { usePricingSubItemListContext } from '../pricing-sub-item-list-context'

type PricingSubItemListFooterProps = {
	isRateCard?: boolean
}

export const PricingSubItemListFooter = ({ isRateCard }: PricingSubItemListFooterProps) => {
	const {
		pricingItemFieldPath,
		pricingSubItemsFieldArray: { createPricingSubItem },
	} = usePricingSubItemListContext()

	const isOriginalPricingItem = !!useWatch({
		name: `${pricingItemFieldPath}.originalDeliverableId`,
	})

	return (
		<>
			<Box display="flex" alignItems="center">
				<Tooltip
					title={
						isRateCard
							? 'Once set, rates cannot be added or deleted'
							: 'Once set sub-items cannot be added or deleted'
					}
					arrow={true}
					disabled={!isOriginalPricingItem}
				>
					<Box>
						<Button
							variant="outlined"
							color="primary"
							data-testid={isRateCard ? 'add-entry-button' : 'add-sub-item-button'}
							onClick={() => createPricingSubItem()}
							startIcon={<AddIcon />}
							disabled={isOriginalPricingItem}
						>
							{isRateCard ? 'Add Rate Card Entry' : 'Add sub-item'}
						</Button>
					</Box>
				</Tooltip>

				{!isRateCard ? (
					<PopoverHelp
						triggerButtonProps={{
							'aria-label': 'Add sub item help',
						}}
						content={<AddSubItemHelp isOriginalPricingItem={isOriginalPricingItem} />}
					/>
				) : null}
			</Box>
			{isRateCard && <PricingItemListFooterOptions />}
		</>
	)
}

const AddSubItemHelp = ({ isOriginalPricingItem = false }: { isOriginalPricingItem: boolean }) => {
	const text =
		'Set guidelines in the form of sub-items so firms can respond accordingly. Your firms can choose to respond to these sub-items and / or add their own.'

	return (
		<Fragment>
			{isOriginalPricingItem && (
				<Fragment>
					<Typography variant="body2">Once set, the pricing structure cannot be altered</Typography>
					<Spacer />
					<hr />
					<Spacer />
				</Fragment>
			)}

			<Typography variant="body2">{text}</Typography>

			<Spacer space={2} />
		</Fragment>
	)
}
