import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardNegotiateProps = ProgressCardProps

export const CARD_TITLE = 'Consider negotiating'

export const ProgressCardNegotiate = ({ index, step, extraData }: ProgressCardNegotiateProps) => {
	const isRateReview = extraData.useCase === 'rateReview'

	const HELP_TEXT = isRateReview
		? 'If you wish to negotiate with a firm, navigate to their proposal and click “Negotiate”. This will start a counter-proposal'
		: `Navigate to the firm's proposal you'd like to negotiate with and click “Negotiate” to start a draft of your counter proposal.`

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				guidanceText={
					isRateReview
						? "Don't leave money on the table! PERSUIT clients that use the negotiation workflow see greater savings."
						: `Don't leave money on the table! PERSUIT clients that use the negotiation workflow typically see greater savings than running an RFP alone.`
				}
			/>
		)

	return <BaseCard title={CARD_TITLE} helpText={HELP_TEXT} index={index} status={step.status} />
}
