// @ts-strict-ignore
import { getOr, size } from 'lodash/fp'

export const taxonomyCategories = {
	TYPE: 0,
	AREA_OF_LAW: 1,
	LOCATION: 2,
	SERVICE: 3,
	SOURCING: 4,
}

export const taxonomyCategoryMapping = {
	TYPE: 'type',
	AREA_OF_LAW: 'areaOfLaw',
	LOCATION: 'location',
	SERVICE: 'service',
	SOURCING: 'sourcing',
}

const getTaxonomyCategoryByIdx = (taxonomyIdx) => {
	const mappedKey = Object.keys(taxonomyCategories).find(
		(key) => taxonomyCategories[key] === taxonomyIdx,
	)
	if (!mappedKey) {
		return null
	}
	return taxonomyCategoryMapping[mappedKey]
}

export const getTaxonomy = ({ taxonomies, taxonomyCategory }) => {
	if (taxonomyCategory === taxonomyCategories.AREA_OF_LAW) {
		return taxonomies.taxonomyForAreaOfLaw
	}
	if (taxonomyCategory === taxonomyCategories.LOCATION) {
		return taxonomies.taxonomyForLocation
	}
	if (taxonomyCategory === taxonomyCategories.TYPE) {
		return taxonomies.taxonomyForType
	}
	if (taxonomyCategory === taxonomyCategories.SERVICE) {
		return taxonomies.taxonomyForService
	}
	if (taxonomyCategory === taxonomyCategories.SOURCING) {
		return taxonomies.taxonomyForSourcing
	}
}

export const getSelectionForCategory = ({ selectedTags, taxonomyCategory }) => {
	return getOr([], getTaxonomyCategoryByIdx(taxonomyCategory), selectedTags)
}

export const setSelectionForCategory =
	({ selectedTags, setSelectedTags, taxonomyCategory }) =>
	(selected) => {
		return setSelectedTags({
			...selectedTags,
			[getTaxonomyCategoryByIdx(taxonomyCategory)]: selected,
		})
	}

export const createToggleForSelected =
	({ selectedTags, setSelectedTags, taxonomyCategory, allowMulitSelect = false }) =>
	(item) => {
		const setSelected = setSelectionForCategory({ selectedTags, setSelectedTags, taxonomyCategory })
		const selected = getSelectionForCategory({ selectedTags, taxonomyCategory })

		if (selected.map(({ code }) => code).includes(item.code)) {
			const updatedSelected = selected.filter((selected) => selected.code !== item.code)
			return setSelected(updatedSelected)
		}

		const updatedSelected = allowMulitSelect ? [...selected, item] : [item]
		setSelected(updatedSelected)
	}

export const createToggleForExpanded =
	({ setExpanded, expanded }) =>
	(item) => {
		if (expanded.map(({ code }) => code).includes(item.code)) {
			const updatedExpanded = expanded.filter((expanded) => expanded.code !== item.code)
			return setExpanded(updatedExpanded)
		}

		const updatedExpanded = [...expanded, item]
		setExpanded(updatedExpanded)
	}

const clenseSearchTerm = (searchTerm) => searchTerm.replace(/[^&\-\w\s]/gi, '')

// Recursively find subtrees that contain a match
export const filterItem = (searchTerm, item) => {
	const regex = new RegExp(searchTerm, 'gi')

	// If the item matches the search criteria, return the entire element and its children
	if (regex.test(item.name)) {
		return item
	}

	// Otherwise, do a depth-first search on the element's children for any matching items
	const filteredChildren = (item.children || []).map((child) => filterItem(searchTerm, child))

	// The above traversal returns nulls for subtrees that don't contain the search term. Those
	// can be filtered out
	const processed = filteredChildren.filter(Boolean)

	// If there are some matching children, then return the item and only the matching children
	if (size(processed) > 0) {
		return { ...item, children: processed }
	}

	// Otherwise return null (this represents subtrees that can be pruned)
	return null
}

// Think of the taxonomy as an array of trees. We need to search each tree for any matching element/subtree. The search
// is done depth-first to pick up any children that match the search term.
export const filterTaxonomy = (searchTerm, taxonomy) => {
	const clensedSearchTerm = clenseSearchTerm(searchTerm)
	const filteredItems = taxonomy
		// Process each tree of the taxonomy
		.map((item) => filterItem(clensedSearchTerm, item))
		// If a match is not found in a tree, then it will be returned by filterItem as a null. These can be filtered out
		// before returning the final result
		.filter(Boolean)

	return filteredItems
}

// Expand to see children if they match, otherwise if the parent matches, keep it collapsed
export const getCodes = (searchTerm, taxonomy) => {
	if (taxonomy.length === 0) {
		return []
	}

	return taxonomy.reduce((acc, item) => {
		// Don't expand top-level parents if they match the search term
		// Makes it easier to navigate the hierarchy

		const pattern = new RegExp(clenseSearchTerm(searchTerm), 'gi')
		if (pattern.test(item.name) && size(item.children) > 0) {
			return [...acc]
		}
		return [...acc, item, ...getCodes(clenseSearchTerm(searchTerm), item.children)]
	}, [])
}
