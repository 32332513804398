import { memo } from 'react'
import { Box, IconButton, MoreVertIcon, ExpandableCard, DragHandler } from '@persuit/ui-components'
import { QuestionHeader, QuestionHeaderProps } from '../question/question'

type QuestionOverlayProps = {
	id: string
	questionHeaderProps: QuestionHeaderProps
	expanded?: boolean
}

export const QuestionOverlay = memo(
	({ questionHeaderProps, expanded }: QuestionOverlayProps) => {
		return (
			<ExpandableCard
				summaryPrefix={
					<Box display="flex" alignItems={'center'}>
						<DragHandler />
					</Box>
				}
				summary={<QuestionHeader {...questionHeaderProps} />}
				summarySuffix={
					<>
						<IconButton
							aria-label="Pricing item actions"
							aria-controls="long-menu"
							aria-haspopup="true"
							color="primary"
							size="large"
						>
							<MoreVertIcon />
						</IconButton>
					</>
				}
				expanded={expanded}
				details={null}
			/>
		)
	},
	(prevProps, nextProps) => prevProps.id !== nextProps.id,
)

QuestionOverlay.displayName = 'QuestionOverlay'
