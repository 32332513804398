import { useMemo } from 'react'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateAuctionSaveMutation } from '@persuit/ui-graphql/hooks'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { AuctionForm } from '../../rfp-forms'
import { requestValidForComparisonValue } from '../../common-util'
import { DeepPick } from '@persuit/common-utils'

graphql(`
	mutation RfpTemplateAuctionSave($payload: RfpTemplateDraftAuctionInput!, $validate: Boolean!) {
		saveRfpTemplateDraftAuction(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type AuctionSectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<
		RfpTemplate,
		| '_id'
		| 'auction'
		| 'auctionFirmVisibility'
		| 'detail.totalPriceRequired'
		| 'detail.deliverablesV2'
	>
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const AuctionSection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
}: AuctionSectionProps) => {
	const [saveSectionMutation] = useRfpTemplateAuctionSaveMutation()
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const sectionData = useMemo(
		() => ({
			auction: rfpTemplate?.auction ?? false,
			auctionFirmVisibility: rfpTemplate?.auctionFirmVisibility ?? null,
		}),
		[rfpTemplate],
	)

	const possibleBadAuction =
		!rfpTemplate?.detail?.totalPriceRequired &&
		!requestValidForComparisonValue(rfpTemplate?.detail?.deliverablesV2 ?? [])

	if (loading) return <LoadingSpinner />

	return (
		<>
			<PageTitle>Editing timeline & auction settings of a template</PageTitle>
			<AuctionForm
				title="Reverse Auction"
				saveSection={async (formVariables) => {
					await saveSectionMutation({
						variables: {
							payload: {
								...formVariables,
								formInstanceId: instanceId,
								rfpTemplateId: rfpTemplate._id,
							},
							validate: sendMode,
						},
					})
				}}
				sectionData={sectionData}
				possibleBadAuction={possibleBadAuction}
			/>
		</>
	)
}
