// @ts-strict-ignore
import { Box, RichTextView, Typography } from '@persuit/ui-components'

import { ExpandableRowHeaderCell, RowHeaderCell, RowHeaderCellProps } from '../components'
import { useStore } from '../../store'
import { SortButton, SortOrder } from '../components/sort-button'

type QuestionHeaderCellProps = RowHeaderCellProps & {
	description: string
	index?: number
	groupIndex: number
	title: string
	type: string
	sortOrder: SortOrder
	onSort: (e) => void
}

export const QuestionHeaderCell = ({
	description = '',
	index,
	title,
	type,
	groupIndex,
	sortOrder,
	onSort,
	...rest
}: QuestionHeaderCellProps) => {
	const { truncateCellContents: shouldTruncateWhilstPrinting, isClient } = useStore((state) => ({
		truncateCellContents: state.truncateCellContents,
		isClient: !state.isFirm,
	}))

	const titlePrefix =
		index !== null && index !== undefined ? `${groupIndex + 1}.${index + 1}.` : `${groupIndex + 1}.`

	const titleWithPrefix = `${titlePrefix} ${title}`
	if (!shouldTruncateWhilstPrinting) {
		return (
			<RowHeaderCell {...rest}>
				<QuestionTitleAndSortContainer
					title={titleWithPrefix}
					type={type}
					sortOrder={sortOrder}
					onSort={onSort}
					isClient={isClient}
				></QuestionTitleAndSortContainer>

				<RichTextView content={description ?? ''} />
			</RowHeaderCell>
		)
	}

	const truncationLength = 100
	const shouldTruncateDescription = description && description.length > truncationLength

	if (!shouldTruncateDescription) {
		return (
			<RowHeaderCell {...rest}>
				<QuestionTitleAndSortContainer
					title={titleWithPrefix}
					type={type}
					sortOrder={sortOrder}
					onSort={onSort}
					isClient={isClient}
				></QuestionTitleAndSortContainer>
				<Typography display="block" variant="body2">
					<RichTextView content={description ?? ''} />
				</Typography>
			</RowHeaderCell>
		)
	}

	return (
		<ExpandableRowHeaderCell
			{...rest}
			expandedContent={<Typography>{description}</Typography>}
			expandedTitle={titleWithPrefix}
		>
			<QuestionTitleAndSortContainer
				title={titleWithPrefix}
				type={type}
				sortOrder={sortOrder}
				onSort={onSort}
				isClient={isClient}
			></QuestionTitleAndSortContainer>

			<Typography display="block" variant="body2">
				<RichTextView content={description ?? ''} truncationLength={100} />
			</Typography>
		</ExpandableRowHeaderCell>
	)
}

const QuestionTitleAndSortContainer = ({ title, type, sortOrder, onSort, isClient }) => {
	return (
		<Box>
			{isClient && type !== 'short' && type !== 'medium' && type !== 'long' ? (
				<SortButton sortOrder={sortOrder} onClick={onSort} sx={{ float: 'right' }} />
			) : null}
			<Typography gutterBottom={true}>{title}</Typography>
		</Box>
	)
}
