// @ts-strict-ignore
import { useMemo } from 'react'
import { useSectionalContainer } from '../../sectional-form'
import { RatesForm, RatesFormProps } from '../../rfp-forms'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { graphql, useQuery } from '@persuit/ui-graphql'
import { DeepPick } from '@persuit/common-utils'
import { RfpTemplate } from '../types'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateRatesSectionSaveMutation } from '@persuit/ui-graphql/hooks'

graphql(`
	mutation RfpTemplateRatesSectionSave($payload: RfpTemplateDraftRatesInput!, $validate: Boolean!) {
		saveRfpTemplateDraftRates(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

const GET_TIMEKEEPERS = graphql(`
	query RateSectionTemplateForm_GetTimekeepers {
		getRateReviewTimeKeepers {
			_id
			label
		}
	}
`)

export type RatesSectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<RfpTemplate, '_id' | 'rateReview'>
	validationErrors: {
		rates?: RatesFormProps['sectionErrors']
	} | null
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
	useSingleCurrency: boolean
}

export const RatesSection = ({
	instanceId,
	rfpTemplate,
	validationErrors,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
	useSingleCurrency,
}: RatesSectionProps) => {
	const [saveSectionMutation] = useRfpTemplateRatesSectionSaveMutation()
	const { data: rateReviewTimeKeepersData, loading: loadingSelectableTimekeepers } = useQuery(
		GET_TIMEKEEPERS,
		{ fetchPolicy: 'network-only' },
	)
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const ratesErrors = validationErrors?.rates ?? null
	const ratesData = useMemo(
		() => ({
			requestNamedTimekeepers: rfpTemplate.rateReview?.requestNamedTimekeepers ?? false,
			namedTimekeepersSettings: rfpTemplate.rateReview?.namedTimekeepersSettings ?? null,
			details: rfpTemplate?.rateReview?.details ?? '',
			currency: rfpTemplate?.rateReview?.currency ?? '',
			currencies: [],
			timekeepers: rfpTemplate.rateReview?.timekeepers
				? rfpTemplate.rateReview.timekeepers.map(({ timekeeper, timekeeperLabel }) => ({
						id: timekeeper,
						timekeeperLabel,
				  }))
				: [],
		}),
		[rfpTemplate],
	)

	const selectableRateReviewTimeKeepers = rateReviewTimeKeepersData?.getRateReviewTimeKeepers ?? []

	const saveSection = async (formVariables) => {
		const { currencies, ...restformVariables } = formVariables
		await saveSectionMutation({
			variables: {
				payload: {
					...restformVariables,
					details: formVariables.details,
					currency: formVariables.currency,
					timekeepers: formVariables.timekeepers.map((tk) => {
						/**
						 * Look for an existing tk in the options to get the tk id
						 * This is required because we have allowed freesolo
						 * When allowing the user to choose an existing option then
						 * change the text it needs to drop the tk id to treat it like a new one
						 *
						 */
						const { timekeeperLabel } = tk

						const foundOption = selectableRateReviewTimeKeepers.find(
							(opt) => opt.label === timekeeperLabel,
						)
						if (foundOption && foundOption._id) {
							return {
								timekeeper: foundOption._id,
								timekeeperLabel: foundOption.label,
							}
						}

						return {
							timekeeperLabel,
						}
					}),
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	if (loading && loadingSelectableTimekeepers) {
		return <LoadingSpinner />
	}

	return (
		<>
			<PageTitle>Edit rates of a template</PageTitle>
			<RatesForm
				saveSection={saveSection}
				sectionData={ratesData}
				sectionErrors={ratesErrors}
				useSingleCurrency={useSingleCurrency}
			/>
		</>
	)
}
