import { Box, BoxProps } from '@persuit/ui-components'
import { useEffect, useRef } from 'react'

type SectionalFormContainerProps = {
	focusOnMount?: boolean
	label?: string
} & BoxProps

export const SectionalFormContainer = ({
	focusOnMount,
	children,
	label,
	...props
}: SectionalFormContainerProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (focusOnMount) {
			containerRef.current?.focus()
		}
	}, [focusOnMount])

	return (
		<Box
			id={'rfp-form-container'}
			tabIndex={-1}
			ref={containerRef}
			aria-label={label}
			padding={2}
			role="group"
			{...props}
		>
			{children}
		</Box>
	)
}
