// @ts-strict-ignore
import { Fragment } from 'react'
import { Typography, SortableVerticalList, SortableListItem } from '@persuit/ui-components'
import { getOrGenerateUUID } from '@persuit/common-utils'
import { usePricingListContext } from '../../pricing-list-context'
import { PricingSubItem } from './item'
import { Rate } from '../../types'
import { getPricingItemError } from '../pricing-sub-item-utils/get-pricing-errors'
import { usePricingSubItemListContext } from '../pricing-sub-item-list-context'

type PricingSubItemListBodyProps = {
	pricingItemRates: Rate[]
	isRateCard: boolean
}

export const PricingSubItemListBody = ({
	pricingItemRates,
	isRateCard,
}: PricingSubItemListBodyProps) => {
	const { errors } = usePricingListContext()
	const {
		pricingItemIndex,
		pricingSubItemsFieldArray: { move },
	} = usePricingSubItemListContext()

	const items = pricingItemRates.map((subItem) => ({
		id: `pricing_subitem-${getOrGenerateUUID(subItem)}`,
		label: subItem.label,
	}))

	const sortableSubItems = items.map(({ id }, idx) => {
		return (
			<SortableListItem id={id} key={id}>
				{() => <PricingSubItem sortableId={id} pricingSubItemIndex={idx} isRateCard={isRateCard} />}
			</SortableListItem>
		)
	})

	const priceItemError = getPricingItemError(errors, pricingItemIndex)
	const rateCardEmptyError = priceItemError && (priceItemError?.rateCardEmptyError ?? '')
	const subItemsCount = items.length
	const itemsMap = new Map(items.map((item) => [getOrGenerateUUID(item), item]))
	const getPosition = (id) => items.findIndex((item) => getOrGenerateUUID(item) === id) + 1
	const getSubitemTitle = (id: string) => `${getPosition(id)} ${itemsMap.get(`${id}`)?.label ?? ''}`

	return (
		<Fragment>
			{isRateCard && (
				<Typography variant="body2" color="error">
					{rateCardEmptyError}
				</Typography>
			)}
			<SortableVerticalList
				onMove={move}
				items={items}
				accessibility={{
					announcements: {
						onDragStart({ active }) {
							return `Picked up draggable sub-item ${getSubitemTitle(
								`${active.id}`,
							)}. It is in position ${getPosition(active.id)} of ${subItemsCount}`
						},
						onDragOver({ active, over }) {
							if (over) {
								return `draggable sub-item ${getSubitemTitle(
									`${active.id}`,
								)} was moved into position ${getPosition(over.id)} of ${subItemsCount}`
							}
						},
						onDragEnd({ active, over }) {
							if (over) {
								return `draggable sub-item ${getSubitemTitle(
									`${active.id}`,
								)} was dropped at position ${getPosition(over.id)} of ${subItemsCount}`
							}
						},
						onDragCancel({ active }) {
							return `Dragging was cancelled. draggable sub-item ${getSubitemTitle(
								`${active.id}`,
							)} was dropped.`
						},
					},
					screenReaderInstructions: {
						draggable: `To pick up a draggable sub-item, press space or enter.
							While dragging, use the arrow keys to move the sub-item in up/down direction.
							Press space or enter again to drop the sub-item in its new position, or press escape to cancel.`,
					},
				}}
			>
				{sortableSubItems}
			</SortableVerticalList>
		</Fragment>
	)
}
