// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Alert from '@mui/material/Alert'

export const FileListError = ({ errorFetchingFilesForDocument }) => {
	if (!errorFetchingFilesForDocument) {
		return null
	}

	return (
		<Alert severity="error" style={{ margin: '1rem 0' }}>
			Uploaded files could not be displayed due to an error.
		</Alert>
	)
}
