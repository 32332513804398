import { isEmpty } from 'lodash/fp'
import { Fragment } from 'react'
import constants from '../../../../common/data/pricing-models'
import useTranslation from '../../../custom-hooks/translation-hook'
import { getOrEmptyArray } from '../../../utils/lenses'
import Spacer from '../../layout/spacer.jsx'
import Reveal from '../../reveal'
import { PriceItemHistoryTable } from '../../rfp-proposals/rfp-proposal-detail/price-history-table'
import { RateCardHistoryTable } from '../../rfp-proposals/rfp-proposal-detail/price-history/rate-card-history-table'
import RateCardList from './rate-card-list'
import SubItemsList from './sub-items-list'
import SubItemsPoints from './sub-items-points'
import { Box, Typography, useTheme } from '@persuit/ui-components'
import { PricingItemRanking } from './pricing-item-ranking'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

// Hourly Rate only shows header
const PricingCardItemExpanded = ({
	showHeader,
	allowFirmAddRate,
	isProposalRevised,
	responseToDeliverable,
	pricingPreferences,
	showTotal,
	requestedRates,
	phaseIndex,
	phaseDuration,
	currency,
	rateCardData,
	rfpr,
	Header,
	rank,
}) => {
	const theme = useTheme()
	const { t } = useTranslation()
	const { toggles } = useFeatureToggles()

	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const priceHistories = getOrEmptyArray('history', responseToDeliverable)
	const showPriceHistory = !isEmpty(priceHistories) && !isProposalRevised
	const hasResponseToDeliverable = !isEmpty(responseToDeliverable)
	const showHeaderHourlyRateOrRatecard =
		showHeader &&
		(pricingPreferences === constants.RATECARD || pricingPreferences === constants.HOURLYRATE)

	const responseToRates = getOrEmptyArray('responseToRates', responseToDeliverable)
	const firmSuppliedRates = getOrEmptyArray('firmSuppliedRates', responseToDeliverable)
	const willHaveSubItems = !isEmpty(responseToRates) || !isEmpty(firmSuppliedRates)

	const showHeaderWhenNotHourlyRateOrRatecard =
		showHeader &&
		pricingPreferences !== constants.RATECARD &&
		pricingPreferences !== constants.HOURLYRATE &&
		(!showTotal || !willHaveSubItems)

	const showNormalSubItems =
		pricingPreferences !== constants.RATECARD &&
		pricingPreferences !== constants.HOURLYRATE &&
		hasResponseToDeliverable

	const showRateCardWithFirmResponse =
		pricingPreferences === constants.RATECARD && hasResponseToDeliverable

	const showRateCardWithoutFirmResponse =
		pricingPreferences === constants.RATECARD && !hasResponseToDeliverable && requestedRates

	const shouldHideRateCardRanking =
		!isRateCardAuctionEnabled && pricingPreferences === constants.RATECARD

	return (
		<Fragment>
			<Box position="absolute" top={theme.spacing(2)} right={theme.spacing(3)}>
				{shouldHideRateCardRanking ? null : <PricingItemRanking rank={rank} />}
			</Box>
			<div>
				{/* Always show total price header for hourlyrate or ratecard */}
				{showHeaderHourlyRateOrRatecard && Header}
				{/* When not hourlyrate or ratecard provide option to show header - because the total is usually included as the last row for subitems */}
				{showHeaderWhenNotHourlyRateOrRatecard && Header}
				{/* normal sub items (not rate card or hourly rate) with firm response */}
				{showNormalSubItems && willHaveSubItems && (
					<SubItemsList
						requestedRates={requestedRates}
						phaseIndex={phaseIndex}
						t={t}
						responseToDeliverable={responseToDeliverable}
						currency={currency}
						showTotal={showTotal}
						phaseDuration={phaseDuration}
						rfpr={rfpr}
					/>
				)}
				{/* Rate card with firm response */}
				{showRateCardWithFirmResponse && (
					<RateCardList items={rateCardData} phaseIndex={phaseIndex} />
				)}
				{/* Rate card without firm response */}
				{showRateCardWithoutFirmResponse && (
					<div data-testid="rate-card-without-response">
						{!!requestedRates.length && (
							<Fragment>
								<Typography variant="caption" color="text.secondary">
									Rates requested for:
								</Typography>
								<Spacer space={1} />
								<SubItemsPoints requestedRates={requestedRates} />
							</Fragment>
						)}
						{allowFirmAddRate && (
							<Typography variant="caption" color="text.secondary">
								Additional rates such as Timekeeper name, Category can be added
							</Typography>
						)}
					</div>
				)}
			</div>
			<Spacer space={2} />
			{showPriceHistory && (
				<Reveal label="Price history">
					{pricingPreferences === constants.RATECARD ? (
						<RateCardHistoryTable
							key={responseToDeliverable.deliverableId}
							priceHistories={priceHistories}
							currency={currency}
							deliverableNumber={phaseIndex + 1}
						/>
					) : (
						<PriceItemHistoryTable
							key={responseToDeliverable.deliverableId}
							priceHistories={priceHistories}
							currency={currency}
							phaseDuration={phaseDuration}
						/>
					)}
				</Reveal>
			)}
		</Fragment>
	)
}

export default PricingCardItemExpanded
