import {
	Box,
	ScreenOnly,
	Typography,
	VisibilityIcon,
	NegotiateIcon,
	ThumbUpIcon,
	ProposalEditIcon,
} from '@persuit/ui-components'
import { red500 } from '../../../../../theme/persuit-colors'

import { TotalPriceOrComparisonValue } from './total-price-or-comparison-value'
import { OnlineStatusIndicator } from '../../../../online-status-indicator/online-status-indicator'
import { RequestReOpenStatus, FirmStatus } from '../../../../status'
import NamwolfLogo from '../../../../namwolf/namwolf-logo'
import RevisionRequiredLabel from '../../../../status/firm/revision-required-status'
import ResponseStatus from '../../../../rfp-proposals/rfp-proposal-status'
import { useReviseProposal } from '../../../../../custom-hooks/firm/use-revise-proposal'
import { ActionsButtonGroup, actionButtonDataType } from './actions-button-group'
import { totalPricePresentaionData } from '../../../presentation-data/total-price-presentation-data'
import { comparisonValuePresentaionData } from '../../../presentation-data'

type CompactHeaderCellProps = {
	auctionInProgress: boolean
	canNotCreate: boolean
	currency: string | null | undefined
	disableNegotiate: boolean
	disableSelect: boolean
	handleNegotiateProposal: () => void
	handleSelectProposal: () => void
	handleViewProposal: () => void
	hasProposalRevisionRequest: boolean
	isCompetitor: boolean
	isEliminated: boolean
	isExample: boolean
	isExampleOrCompetitorProposal: boolean
	isNamwolfMember: boolean
	isResponseDraft: boolean
	orgId: string | null | undefined
	hasParent: boolean
	orgName: string | null | undefined
	request: any
	requestId: string
	requestObjectRefetch: () => void
	requestReopenedForInvite: boolean
	response: any
	responseStatus: string
	showPriceType: 'totalPrice' | 'comparisonValue' | null
	showReviseProposalButton: boolean
	showSelectProposalButton: boolean
}

export const CompactHeaderCell = ({
	auctionInProgress,
	canNotCreate,
	currency,
	disableNegotiate,
	disableSelect,
	handleNegotiateProposal,
	handleSelectProposal,
	handleViewProposal,
	hasProposalRevisionRequest,
	isCompetitor,
	isEliminated,
	isExample,
	isExampleOrCompetitorProposal,
	isNamwolfMember,
	isResponseDraft,
	orgId,
	hasParent,
	orgName,
	request,
	requestId,
	requestObjectRefetch,
	requestReopenedForInvite,
	response,
	responseStatus,
	showPriceType,
	showReviseProposalButton,
	showSelectProposalButton,
}: CompactHeaderCellProps) => {
	const { handleReviseProposal } = useReviseProposal({
		proposal: response,
		request,
		requestObjectRefetch,
	})

	const viewButton: actionButtonDataType = {
		title: isResponseDraft && !canNotCreate ? 'Continue editing' : 'View',
		tooltip: 'VIEW PROPOSAL',
		icon: <VisibilityIcon />,
		onClick: handleViewProposal,
	}

	const negotiateButton: actionButtonDataType = {
		title: 'Negotiate',
		tooltip: 'SUGGEST CHANGES ON THIS PROPOSAL',
		icon: <NegotiateIcon />,
		disabled: disableNegotiate,
		onClick: handleNegotiateProposal,
	}

	const selectButton: actionButtonDataType = {
		title: 'Select',
		tooltip: 'SELECT THIS FIRM’S PROPOSAL',
		icon: <ThumbUpIcon />,
		disabled: disableSelect,
		onClick: handleSelectProposal,
	}

	const reviseButton: actionButtonDataType = {
		title: 'Revise',
		icon: <ProposalEditIcon />,
		onClick: handleReviseProposal,
	}

	const actionButtonsData = () => {
		const buttons: actionButtonDataType[] = []

		if (!isExampleOrCompetitorProposal) {
			buttons.push(viewButton)
		}
		if (showSelectProposalButton) {
			buttons.push(negotiateButton, selectButton)
		}
		if (showReviseProposalButton) {
			buttons.push(reviseButton)
		}

		return buttons
	}

	const showOnlineStatus = request.auction && auctionInProgress && orgId

	const orgNameMaxWdith = () => {
		const containerMinWidth = 300
		const containerMarginWidth = 16 * 2
		const namwolfWidth = isNamwolfMember ? 4 + 16 : 0
		const onlineStatusWidth = showOnlineStatus ? 8 + 16 : 0

		return `${containerMinWidth - containerMarginWidth - namwolfWidth - onlineStatusWidth}px`
	}

	const valueSummaryData = () => {
		if (showPriceType === 'comparisonValue') {
			return comparisonValuePresentaionData(response, currency ?? 'AUD')
		} else if (showPriceType === 'totalPrice') {
			return totalPricePresentaionData(response, currency ?? 'AUD')
		} else {
			return null
		}
	}

	const valueSummaryDataConst = valueSummaryData()

	return (
		<>
			<Box
				marginTop={2.5} // 32 rank header - 16 padding + 4 gap = 20 px
				textAlign="center"
			>
				{orgName && (
					<Box display="flex">
						<Typography
							variant="body1Semibold"
							strikeThrough={!!isEliminated}
							maxWidth={orgNameMaxWdith()}
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{orgName}
						</Typography>
						{isNamwolfMember && (
							<Box display="flex" alignItems="center" justifyContent="center" marginLeft={0.5}>
								<NamwolfLogo />
							</Box>
						)}
						{showOnlineStatus && <OnlineStatusIndicator rfpId={requestId} orgId={orgId ?? ''} />}
					</Box>
				)}
				{isExample && <Typography fontStyle="italic">Example response</Typography>}
			</Box>

			<Box display="flex" marginTop="5px">
				{requestReopenedForInvite && <RequestReOpenStatus expanded={false} />}
				{hasProposalRevisionRequest && !isEliminated && <RevisionRequiredLabel expanded={false} />}

				<ResponseStatus
					status={responseStatus}
					hasParent={hasParent}
					isEliminated={isEliminated}
					EliminatedStatusComponent={() => (
						<FirmStatus rfp={request} rfpProposal={response} expanded={false} />
					)}
					expanded={false}
				/>

				<ScreenOnly>
					<ActionsButtonGroup buttonsData={actionButtonsData()} />
				</ScreenOnly>
			</Box>

			{isCompetitor && <Typography color={red500}>Proposal from competitor firm</Typography>}

			{valueSummaryDataConst && (
				<>
					<Box flexGrow={1} />
					<TotalPriceOrComparisonValue {...valueSummaryDataConst} />
				</>
			)}
		</>
	)
}
