import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { graphql, useMutation, useQuery } from '@persuit/ui-graphql'

import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { ConflictCheckForm } from '../../rfp-forms/conflict-check-form'

const GET_FILES = graphql(`
	query RfpTemplateConflictFiles($documentId: ID!) {
		getConflictCheckFilesForRfpTemplate(documentId: $documentId) {
			_id
			filename
			length
			fileStatus
		}
	}
`)

const UPDATE_FILES = graphql(`
	mutation RfpTemplateConflictUpdateFiles($documentId: ID!, $fileIds: [ID!]!) {
		updateConflictCheckFilesForRfpTemplate(documentId: $documentId, fileIds: $fileIds)
	}
`)

const SAVE_SECTION = graphql(`
	mutation RfpTemplateConfictSaveDraft(
		$payload: RfpTemplateDraftConflictInput!
		$validate: Boolean!
	) {
		saveRfpTemplateDraftConflict(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

export type ConflictCheckSectionProps = {
	instanceId: string
	rfpTemplate: Pick<
		RfpTemplate,
		| '_id'
		| 'ccRequired'
		| 'legalEntity'
		| 'counterParties'
		| 'ccInformation'
		| 'detail'
		| 'files'
		| 'templateType'
	>
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	validationErrors: {
		conflict?: {
			files?: string
		}
	} | null
	sendMode: boolean
}

export const ConflictCheckSection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	refetchErrors,
	validationErrors,
	sendMode,
}: ConflictCheckSectionProps) => {
	const { templateId } = useParams<{ templateId: string }>()
	const [saveSectionMutation] = useMutation(SAVE_SECTION)
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const [updateFiles] = useMutation(UPDATE_FILES)

	const { refetch: refetchFiles } = useQuery(GET_FILES, {
		variables: { documentId: templateId },
	})

	const sectionErrors = validationErrors?.conflict ?? null

	const sectionData = useMemo(
		() => ({
			ccRequired: rfpTemplate?.ccRequired ?? false,
			legalEntity: rfpTemplate?.legalEntity ?? null,
			counterParties: rfpTemplate?.counterParties?.filter(isNotNil) ?? [],
			ccInformation: rfpTemplate?.ccInformation ?? null,
			files: rfpTemplate?.files?.filter(isNotNil).filter(isNotNilProp('_id')) ?? [],
		}),
		[rfpTemplate],
	)

	const isSystemTemplate = rfpTemplate?.templateType === 'system'

	if (loading) return <LoadingSpinner />

	return (
		<>
			<PageTitle>Manage conflict check of a template</PageTitle>
			<ConflictCheckForm
				refetchFiles={async () =>
					(await refetchFiles()).data.getConflictCheckFilesForRfpTemplate
						?.filter(isNotNil)
						.filter(isNotNilProp('_id'))
				}
				saveSection={async ({ ccRequired, legalEntity, counterParties, ccInformation, files }) => {
					await Promise.all([
						isSystemTemplate
							? null
							: updateFiles({
									variables: { documentId: templateId, fileIds: files.map((file) => file._id) },
							  }),
						saveSectionMutation({
							variables: {
								payload: {
									ccRequired,
									legalEntity,
									counterParties,
									ccInformation,
									formInstanceId: instanceId,
									rfpTemplateId: rfpTemplate._id,
								},
								validate: sendMode,
							},
						}),
					])
				}}
				sectionData={sectionData}
				sectionErrors={sectionErrors}
				isSystemTemplate={isSystemTemplate}
			/>
		</>
	)
}
