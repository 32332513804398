// @ts-strict-ignore
import { Box, FormTextField, Spacer, Typography, FormAutocomplete } from '@persuit/ui-components'
import { FormProvider } from 'react-hook-form'
import { SectionalFormContainer, useSectionalForm } from '../../sectional-form'
import { MATTER_TYPES } from '../../../../../src/common/data/template-tags'
import { TemplateMatterType } from '@persuit/ui-graphql/generated'
import useTemplateCountries, { NewTemplateCountryInfo } from './use-template-countries'

export type DetailFormData = {
	templateDescription: string
	templateMatterType?: {
		name: string
		id: TemplateMatterType
	}
	templateCountry?: {
		name: string
		id: string
	}
}

export type DetailFormProps = {
	saveSection: (formVariables: DetailFormData) => Promise<void>
	sectionData: DetailFormData
	sectionErrors?: {
		templateMatterType?: string
		templateCountry?: string
	}
	useCase?: string
}

const TemplateCountryDropdown = () => {
	const { templateCountries, loading, error } = useTemplateCountries()

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		return null
	}

	return (
		<FormAutocomplete
			name="templateCountry"
			id="template-country"
			label="Template Country"
			fullWidth={true}
			required={true}
			options={templateCountries}
			getOptionLabel={({ name }: NewTemplateCountryInfo) => name}
		/>
	)
}

export const DetailForm = ({
	saveSection,
	sectionData,
	sectionErrors,
	useCase,
}: DetailFormProps) => {
	const isRateReview = useCase === 'rateReview'

	const methods = useSectionalForm(sectionData, saveSection, sectionErrors)
	return (
		<SectionalFormContainer label="Template Details section" focusOnMount={true}>
			<FormProvider {...methods}>
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Template Details</Typography>
					<Spacer shape="row" space={3} />
					<Box alignItems="baseline">
						<FormTextField
							name="templateDescription"
							id="template-description"
							label="Template Description"
							fullWidth={true}
							defaultValue=""
						/>
						<Spacer space={2} />

						{!isRateReview && (
							<FormAutocomplete
								name="templateMatterType"
								id="template-matter-type"
								label="Template Category"
								fullWidth={true}
								required={true}
								options={MATTER_TYPES}
								getOptionLabel={({ name }: { name: string; id: TemplateMatterType }) => name}
							/>
						)}

						<Spacer space={2} />
						<TemplateCountryDropdown />
					</Box>
					<Spacer shape="row" space={2} />
				</Box>
			</FormProvider>
		</SectionalFormContainer>
	)
}
