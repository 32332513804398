import { graphql } from '@persuit/ui-graphql'

const proposal = graphql(`
	fragment RfpProposal on RfpProposal {
		_id
		listing
		subject
		diversityAnswers {
			answer1 {
				answer
				comment
				isNa
			}
			answer2 {
				answer
				comment
				isNa
			}
			answer3 {
				answer
				comment
				isNa
			}
		}
		responseToDeliverables {
			_id
			deliverableId
			notIncluded
			price
			percentage
			pricingModel
			comment
			hours
			firmSuppliedRates {
				_id
				label
				rate
				percentage
				pricingModel
				hours
				firmSuppliedRateId
			}
			responseToRates {
				_id
				rateId
				rate
			}
		}
		answersToQuestions {
			_id
			questionId
			type
			stringAnswer
			choiceAnswer
			numericAnswer
			multipleSelectAnswer
			singleSelectAnswer
			comments
		}
		summaryOfProposal
		scorecard {
			categoryId
			subcategories {
				subcategoryId
				score
			}
		}
		status
		lastBidTime
		totalPriceValue
		originalPrice
		originalComparisonValue
		originalId
		totalPriceModel
		totalPrices {
			totalPriceValue
			createdAt
			draftValue
		}
		files {
			_id
			filename
			contentType
			length
			aliases
			chunkSize
			createdAt
			createdBy
			md5
			metadata
			org {
				_id
				name
			}
			updatedAt
			updatedBy
			uploadDate
			fileStatus
		}
		engagementLetters {
			files {
				_id
				filename
				contentType
				length
				aliases
				chunkSize
				createdAt
				createdBy
				md5
				metadata
				org {
					_id
					name
					isNamwolfMember
				}
				updatedAt
				updatedBy
				uploadDate
				fileStatus
			}
		}
		allow {
			kind
			item {
				_id
				name {
					first
					last
				}
				org {
					_id
					name
				}
			}
			type
			org
			updatedBy {
				_id
				org {
					_id
					name
				}
			}
		}
		org {
			_id
			name
			isNamwolfMember
		}
		createdAt
		createdBy
		updatedAt
		updatedBy
		isPendingPrice
		rateCards {
			_id
			currency
			isCurrentRateApplicable
			description
			timekeepers {
				timekeeper
				isEnabled
				lyHour
				currentRate
				standardRate
				proposedRate
			}
			location {
				country
				countryName
				regions
				regionNames
			}
			practiceAreas
			practiceAreaNames
		}
		namedTimekeepers {
			_id
			currency
			originalNamedTimekeeperId
			billingId
			graduationYear
			firstName
			lastName
			location
			jobTitle
			standardRate
			currentRate
			proposedRate
			lastYearHours
			isExistingTimekeeper
			comment
		}
		averageRate {
			rate
			originalRate
		}
	}
`)

export default proposal
