import { useState } from 'react'
import {
	SxProps,
	InfoIcon,
	Box,
	Autocomplete,
	TextField,
	Switch,
	Button,
	FormControlLabel,
	Checkbox,
	FileDownloadIcon,
	CurrencySelect,
} from '@persuit/ui-components'
import { PracticeAreaDropdown } from '@persuit/ui-shared-components'
import { useSaliLocationData } from '@persuit/ui-hooks'
import { useStore, useActions } from '../store'
import { TableInformationDialog } from './table-information-dialog'
import { RegionFilter } from './region-filter'
import { useParams } from 'react-router-dom'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { ExcelExportDialog } from '../excel-export-dialog'

export const FilterControls = () => {
	const [showExportDialog, setShowExportDialog] = useState(false)
	const { requestId: rfpId } = useParams<{ requestId: string }>()
	const { toggles } = useFeatureToggles()
	const [showInfoDialog, setShowInfoDialog] = useState(false)

	const showProposalRevisions = useStore((state) => state.showProposalRevisions)

	const currencyFilter = useStore((state) => state.currencyFilter)
	const rateCardCurrencies = useStore((state, s) => s.rateCardCurrencies())

	const firms = useStore((state, s) => s.firms())
	const selectedFirmIds = useStore((state) => state.firmFilters)

	const countries = useStore((state, s) => s.countries())
	const selectedCountryIds = useStore((state) => state.countryFilters)

	const regions = useStore((state, s) => s.regions())
	const selectedRegionIds = useStore((state) => state.regionFilters)

	const practiceAreas = useStore((state, s) => s.practiceAreas())
	const selectedPracticeAreaIds = useStore((state) => state.practiceAreaFilters)

	const {
		setCurrencyFilter,
		toggleShowProposalRevisions,
		setFirmFilters,
		resetFilters,
		setPracticeAreaFilters,
		setRegionFilters,
		setCountryFilters,
	} = useActions()

	const { data: locationData, loading: loadingLocationData } = useSaliLocationData()

	const countryOptions = locationData.filter((c) => countries.includes(c.code))
	const selectedCountries = countryOptions.filter((country) =>
		selectedCountryIds.includes(country.code),
	)

	return (
		<Box mt={2}>
			<Box sx={{ display: 'flex', gap: 1 }}>
				{toggles['dev-10954.multi-currency'] && (
					<CurrencySelect
						sx={{ flexGrow: 1, flexBasis: 0 }}
						disableClearable={true}
						label="Currency"
						variant="outlined"
						value={currencyFilter}
						onChange={(currency) => setCurrencyFilter(currency)}
						allowedCurrencies={rateCardCurrencies}
					/>
				)}

				<MultiAutocomplete
					sx={{ flexGrow: 1, flexBasis: 0 }}
					aria-label="filter by firms"
					label="Firms"
					placeholder="Filter"
					getOptionLabel={(org) => org.name}
					options={firms}
					value={firms.filter((firm) => selectedFirmIds.includes(firm._id))}
					onChange={(options) => setFirmFilters(options.map((f) => f._id))}
				/>

				<PracticeAreaDropdown
					sx={{ flexGrow: 1, flexBasis: 0 }}
					options={practiceAreas}
					value={selectedPracticeAreaIds}
					onChange={({ practiceAreas }) => setPracticeAreaFilters(practiceAreas)}
					placeholder={selectedPracticeAreaIds.length === 0 ? 'Filter' : undefined}
					TextFieldProps={{
						InputLabelProps: { shrink: true },
						InputProps: {
							startAdornment:
								selectedPracticeAreaIds.length > 0 && `${selectedPracticeAreaIds.length} selected`,
						},
					}}
				/>

				{!loadingLocationData && (
					<MultiAutocomplete
						sx={{ flexGrow: 1, flexBasis: 0 }}
						aria-label="filter by countries"
						label="Country"
						placeholder="Filter"
						getOptionLabel={(country) => country.name}
						options={countryOptions}
						value={selectedCountries}
						onChange={(options) => setCountryFilters(options.map((f) => f.code))}
					/>
				)}

				{!loadingLocationData && (
					<RegionFilter
						disabled={selectedCountries.length === 0}
						validRegions={regions}
						sx={{ flexGrow: 1, flexBasis: 0 }}
						countries={selectedCountries}
						selections={selectedRegionIds}
						onChange={(selections) => setRegionFilters(selections)}
					/>
				)}

				<Button onClick={resetFilters}>Reset Filters</Button>
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
				<Box sx={{ flexGrow: 2 }}>
					<FormControlLabel
						control={
							<Switch
								checked={showProposalRevisions}
								onClick={toggleShowProposalRevisions}
								data-trackid="rates-table-show-proposal-revisions"
							/>
						}
						label="Show proposal revisions"
					/>
				</Box>
				{toggles['dev-8628.rate-review-1.1'] && firms.length > 0 ? (
					<Button
						sx={{ mr: 2 }}
						aria-label="Download timekeeper rates in excel file"
						startIcon={<FileDownloadIcon />}
						onClick={() => setShowExportDialog(true)}
					>
						Export
					</Button>
				) : null}

				<Button
					variant="outlined"
					color="primary"
					startIcon={<InfoIcon />}
					onClick={() => setShowInfoDialog(true)}
					data-trackid="rates-tab-table-info"
				>
					Table Information
				</Button>

				<ExcelExportDialog
					open={showExportDialog}
					onClose={() => setShowExportDialog(false)}
					firms={firms}
					rfpId={rfpId}
				/>
				<TableInformationDialog open={showInfoDialog} onClose={() => setShowInfoDialog(false)} />
			</Box>
		</Box>
	)
}

type MultiAutocompleteProps<T> = {
	sx?: SxProps
	label: string
	'aria-label': string
	options: T[]
	value: T[]
	getOptionLabel: (option: T) => string
	placeholder?: string
	onChange: (selections: T[]) => void
}

const MultiAutocomplete = <T,>({
	onChange,
	placeholder,
	options,
	value,
	getOptionLabel,
	sx,
	'aria-label': ariaLabel,
	label,
}: MultiAutocompleteProps<T>) => {
	return (
		<Autocomplete
			renderTags={(options) => `${options.length} selected`}
			sx={sx}
			options={options}
			value={value}
			getOptionLabel={getOptionLabel}
			renderOption={(params, option, state) => (
				<Box component="li" {...params}>
					<Checkbox checked={state.selected} /> {getOptionLabel(option)}
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					variant="outlined"
					aria-label={ariaLabel}
					label={label}
					placeholder={value.length === 0 ? placeholder : undefined}
					{...params}
					InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
					inputProps={{ ...params.inputProps }}
				/>
			)}
			disableCloseOnSelect={true}
			onChange={(e, options) => onChange(options)}
			multiple={true}
		/>
	)
}
