import {
	DataGridPremium,
	GridColumnMenuColumnsItem,
	GridColumnMenuContainer,
	GridColumnMenuProps,
	MenuList,
	Typography,
	styled,
} from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import {
	NamedTimekeeperRowData,
	SubAccordion,
	getNamedTimekeeperRowData,
	NamedTimekeepersPriceHistory,
} from '@persuit/ui-shared-components'
import { useEffect, useId } from 'react'
import { Field, useActions, useSortStore, SortStoreProvider } from './sortStore'
import { useStore } from '../../store'
import { CustomColumnMenu } from './custom-column-menu'
import { TypedGridColDef, getInitialColumns } from './named-timekeepers-columns'

export type TypedGridColumnMenuProps = GridColumnMenuProps & { colDef: TypedGridColDef }

// Filter columns based on certain boolean values so that they do not show on the column menu options
const getFilteredColumns = ({
	columns,
	isGraduationYearRequested,
	isTimekeeperIdRequested,
}: {
	columns: TypedGridColDef[]
	isGraduationYearRequested: boolean
	isTimekeeperIdRequested: boolean
}) => {
	const graduationYearColumn = isGraduationYearRequested ? '' : 'graduationYear'
	const timekeeperIdColumn = isTimekeeperIdRequested ? '' : 'timekeeperId'
	const hiddenColumns = [graduationYearColumn, timekeeperIdColumn]

	return columns.filter((col) => !hiddenColumns.includes(col.field))
}

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
	marginTop: 20,
	flexGrow: 1,
	'& .MuiDataGrid-row:nth-child(odd)': {
		background: theme.palette.grey100,
	},
	'& .MuiDataGrid-row:nth-child(even)': {
		background: 'white',
	},
}))

type NamedTimekeepersDataGridProps = {
	rows: NamedTimekeeperRowData[]
}

const NamedTimekeepersDataGrid = ({ rows }: NamedTimekeepersDataGridProps) => {
	const { toggles } = useFeatureToggles()
	const containerId = useId()
	const { sortState } = useSortStore((state) => ({
		sortState: state.sortState,
	}))
	const { isGraduationYearRequested, isTimekeeperIdRequested } = useStore((state) => ({
		currency: state.rfp.rateReview?.currency,
		isGraduationYearRequested:
			state.rfp.rateReview?.namedTimekeepersSettings?.requestGraduationYear !== 'hidden',
		isTimekeeperIdRequested:
			state.rfp.rateReview?.namedTimekeepersSettings?.requestBillingId !== 'hidden',
	}))

	const { setSortState } = useActions()

	useEffect(() => {
		setSortState({ field: 'timekeeperLevel', direction: 'asc' })
	}, [])

	const columns = getFilteredColumns({
		columns: getInitialColumns({
			isMultiCurrencyToggleEnabled: toggles['dev-10954.multi-currency'],
		}),
		isGraduationYearRequested,
		isTimekeeperIdRequested,
	})

	return (
		<StyledDataGrid
			columnHeaderHeight={40}
			rowHeight={40}
			aria-label="Individual timekeepers"
			forwardedProps={{ id: containerId }}
			autosizeOnMount={true}
			disableRowSelectionOnClick={true}
			hideFooter={true}
			autoHeight={false}
			getRowId={(row) => row.id}
			columns={columns}
			rows={rows}
			slots={{
				columnMenu: (params) => {
					const { colDef, hideMenu } = params as TypedGridColumnMenuProps
					const isColumnWithNoSorting =
						colDef.field === 'timekeeperId' || colDef.field === 'comment'

					return (
						<GridColumnMenuContainer
							{...params}
							aria-labelledby={undefined}
							aria-label={`${colDef.headerName} column sort options`}
						>
							<MenuList>
								{!isColumnWithNoSorting && <CustomColumnMenu field={colDef.field} />}
								<GridColumnMenuColumnsItem colDef={colDef} onClick={hideMenu} />
							</MenuList>
						</GridColumnMenuContainer>
					)
				},
			}}
			onSortModelChange={([sort]) => {
				setSortState(
					!sort || !sort.sort
						? null
						: {
								field: sort.field as Field,
								direction: sort.sort,
						  },
				)
			}}
			sortModel={sortState ? [{ field: sortState.field, sort: sortState.direction }] : []}
			checkboxSelectionVisibleOnly={true}
			initialState={{
				sorting: {
					sortModel: [{ field: 'timekeeperLevel', sort: 'asc' }],
				},
			}}
			disableColumnFilter={true}
			disableColumnPinning={true}
		/>
	)
}

export const NamedTimekeepersContent = () => {
	const { data, hasClientRequestedNamedTimekeeepers, currency } = useStore((state, select) => ({
		data: select.proposalDetailData(),
		hasClientRequestedNamedTimekeeepers: state.rfp.rateReview?.requestNamedTimekeepers ?? false,
		currency: state.rfp.rateReview?.currency,
	}))

	if (!hasClientRequestedNamedTimekeeepers) {
		return (
			<Typography variant="body1">
				You've selected not to receive individual timekeepers.
			</Typography>
		)
	}

	const proposal = data?.proposal

	if (!proposal) {
		return null
	}

	if (proposal.namedTimekeepers.length === 0) {
		return (
			<Typography variant="body1">
				No individual timekeepers have been provided by this firm. If required, please message them.
			</Typography>
		)
	}

	const namedTimekeeperRows = getNamedTimekeeperRowData(proposal.namedTimekeepers)

	return (
		<SortStoreProvider initialState={{ field: 'timekeeperLevel', direction: 'asc' }}>
			<NamedTimekeepersDataGrid rows={namedTimekeeperRows} />
			<SubAccordion
				title="Negotiation Price History"
				ariaLabel="Negotiation Price history for individual timekeepers"
				sx={{ mt: 2 }}
			>
				<NamedTimekeepersPriceHistory
					currency={currency ?? ''}
					ariaLabel="historical prices for individual timekeepers"
					proposalId={proposal._id}
				/>
			</SubAccordion>
		</SortStoreProvider>
	)
}
