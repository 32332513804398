import { Dialog, RichTextView, Typography } from '@persuit/ui-components'

type AssumptionDialogProps = {
	handleClose: () => void
	isOpen: boolean
	originalAssumption: string
	updatedAssumption: string
}

export const AssumptionDialog = ({
	handleClose,
	isOpen,
	originalAssumption,
	updatedAssumption,
}: AssumptionDialogProps) => (
	<Dialog
		onClick={(e) => {
			e.stopPropagation()
		}}
		size="md"
		open={isOpen}
		onClose={handleClose}
		title="Original & updated assumptions & description"
		actions={[
			{
				label: 'Ok',
				variant: 'primary',
				onClick: handleClose,
			},
		]}
	>
		<Typography variant="h3XSmall">Original assumption</Typography>
		<RichTextView content={originalAssumption} data-testid="rfpr-view-original-assumptions" />

		<Typography variant="h3XSmall">Updated assumption</Typography>
		<RichTextView content={updatedAssumption} data-testid="rfpr-view-updated-assumptions" />
	</Dialog>
)
