import { Typography } from '@persuit/ui-components'
import { publishRfpProgress } from '../../singleton'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardFeedbackProps = ProgressCardProps

export const CARD_TITLE = 'Provide feedback'

const HELP_TEXT = `You are able to provide feedback to any firm that you have selected as a winner or marked as unsuccessful. Providing feedback helps firms improve for your next request.`

export const ProgressCardFeedback = ({ index, step, extraData }: ProgressCardFeedbackProps) => {
	const onActionProvideFeedback = () => publishRfpProgress('DETAIL:LAUNCH_PROVIDE_FEEDBACK')
	const needFeedback = extraData.totalProposals - extraData.totalProposalsWithFeedback

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				guidanceText={`Everyone loves feedback, including your firms! Consider giving your firms a steer on how you came to your decision`}
				subtitle={<Typography>{needFeedback} proposals need feedback</Typography>}
				action={{
					title: 'PROVIDE FEEDBACK',
					onAction: onActionProvideFeedback,
					dataTrackId: 'rfp-progress-provide-feedback',
				}}
			/>
		)

	if (step.status === 'completed' || step.status === 'passed')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				subtitle={
					<Typography>
						Provided feedback on {extraData.totalProposalsWithFeedback} proposal(s)
					</Typography>
				}
			/>
		)

	return <BaseCard title={CARD_TITLE} helpText={HELP_TEXT} index={index} status={step.status} />
}
