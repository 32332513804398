import { size } from 'lodash/fp'
import MailtoIcon from './mailto-icon.jsx'
import { useTheme } from '@persuit/ui-components'

const Mailto = ({ bcc, tooltip }) => {
	const theme = useTheme()
	if (!bcc || size(bcc) === 0) {
		return null
	}
	const bccString = bcc.join('%3B%20') // HTML encoded semi-colon(%3B) and space(%20) === %3B%20
	return (
		<a href={`mailto:?bcc=${bccString}`} target="_blank" rel="noopener noreferrer" title={tooltip}>
			<MailtoIcon style={{ fill: theme.palette.primary.main }} />
		</a>
	)
}

Mailto.defaultProps = {
	bcc: [],
	tooltip: null,
}

export default Mailto
