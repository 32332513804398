export const allRfpSections = [
	'clarification',
	'summary',
	'rates',
	'pricing',
	'questions',
	'diversity',
	'timeline',
	'conflict',
	'tracking',
	'scorecard',
	'invite',
] as const
