import * as React from 'react'
import { Card } from '../card'
import { Box, BoxProps } from '../box'
import { Typography } from '../typography'
import { Button } from '../button'
import { SignalWifiOffIcon, CachedIcon } from '../../icons'

export type ErrorBoxProps = {
	/** Title text */
	title?: string
	/** Title icon */
	icon?: React.ReactNode
	/** Message text */
	message?: string
	containerProps?: BoxProps
	onTryAgain?: () => void
}

export const ErrorBox = ({
	title = 'Something went wrong',
	icon = <SignalWifiOffIcon fontSize="large" />,
	message = 'A network error occurred. Please try again',
	containerProps,
	onTryAgain = () => window.location.reload(),
}: ErrorBoxProps) => {
	return (
		<Card>
			<Box p="2rem" {...containerProps}>
				<Box display="flex" alignItems="flex-start">
					{icon}
					<Box ml="0.5rem">
						<Box>
							<Typography variant="h2" component="h1">
								{title}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box pl="3rem" mt="0.5rem">
					<Typography variant="h6" component="p" color="text.secondary">
						{message}
					</Typography>
				</Box>
				{onTryAgain && (
					<Box textAlign="right" mt="1.5rem">
						<Button
							startIcon={<CachedIcon />}
							variant="contained"
							color="primary"
							onClick={onTryAgain}
						>
							Try Again
						</Button>
					</Box>
				)}
			</Box>
		</Card>
	)
}

ErrorBox.displayName = 'ErrorBox'
