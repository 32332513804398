import { useRef, useState, useEffect } from 'react'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Skeleton from '@mui/material/Skeleton'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
	root: {
		width: '100%',
		minHeight: '50px',
		height: '100%',
	},
})

export const PlaceholderLoading = () => {
	const classes = useStyles()
	const containerRef = useRef<HTMLDivElement>(null)
	const [skeletonCount, setSkeletonCount] = useState(0)

	useEffect(() => {
		const containerHeight = containerRef?.current?.offsetHeight
		const skeletonHeight = 22.4 // The height of the Skeleton component
		const newSkeletonCount = Math.floor((containerHeight ? containerHeight : 0) / skeletonHeight)
		setSkeletonCount(newSkeletonCount)
	}, [])

	return (
		<div className={classes.root} ref={containerRef}>
			{Array.from({ length: skeletonCount }).map((_, index) => (
				<Skeleton key={index} style={{ width: index === skeletonCount - 1 ? '60%' : '100%' }} />
			))}
		</div>
	)
}
