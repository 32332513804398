import { Button, ButtonProps, ArrowDropDownIcon, ArrowDropUpIcon } from '@persuit/ui-components'

type RevealLabelProps = ButtonProps & {
	revealed: boolean
}
export const RevealLabel = ({ children, revealed, ...props }: RevealLabelProps) => {
	return (
		<Button
			data-testid="reveal-button"
			startIcon={revealed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			{...props}
		>
			{children}
		</Button>
	)
}

export default RevealLabel
