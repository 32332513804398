import { useId } from 'react'
import * as React from 'react'
import { Button, Box } from '@persuit/ui-components'
import { refocusBeforeRemoval } from '@persuit/ui-utils'
import { Chip, ChipProps } from './chip'

type ChipListProps = {
	children: React.ReactNode
	chipProps?: Partial<ChipProps>
	data: Array<{
		onDelete: () => void
		isApprovedFirm: boolean
		isPanelOrPreferredFirm: boolean
		primaryText: string
		secondaryText: string
		icon: React.ReactNode
	}>
	maximumToShow?: number
	truncated?: boolean
	onMoreClick: (e: React.MouseEvent) => void
	listAriaLabel?: string
}

export const ChipList = ({
	children,
	chipProps = {},
	data,
	maximumToShow = 0,
	truncated,
	onMoreClick,
	listAriaLabel,
}: ChipListProps) => {
	const chipData = truncated ? data.slice(0, maximumToShow) : data
	const more = data.length - maximumToShow
	const chipIdPrefix = useId()
	const chipId = (index: number) => `${chipIdPrefix}:${index}`
	const chipListId = `${chipIdPrefix}:chip-list`

	return (
		<Box
			sx={{
				width: '100%',
			}}
		>
			{chipData.length > 0 && (
				<Box
					display="flex"
					flexWrap="wrap"
					alignItems="center"
					role="list"
					id={chipListId}
					tabIndex={-1}
					aria-label={listAriaLabel}
				>
					{chipData.map((d, index) => {
						const { className, ...restOfChipProps } = chipProps

						const onDelete = d.onDelete || restOfChipProps.onDelete
						const id = chipId(index)

						return (
							<Box role="listitem" key={id}>
								<Chip
									chipProps={{ id }}
									{...d}
									{...restOfChipProps}
									onDelete={() => {
										refocusBeforeRemoval({
											currentIndex: index,
											fallback: document.getElementById(chipListId),
											getItemByIndex: (index) => document.getElementById(chipId(index)),
										})
										onDelete()
									}}
									className={className}
									expandable={true}
									isApprovedFirm={d.isApprovedFirm}
									isPanelOrPreferredFirm={d.isPanelOrPreferredFirm}
								/>
							</Box>
						)
					})}
					{maximumToShow && more > 0 ? (
						<Box display="flex" alignItems="center" role="listitem">
							<Button variant="text" color="primary" onClick={onMoreClick}>
								{truncated ? `+ ${more} More` : 'Show Less'}
							</Button>
						</Box>
					) : null}
				</Box>
			)}
			{children}
		</Box>
	)
}
