import { gql } from '@apollo/client'

import { FileWrapper } from '../file-wrapper'
import { FileList } from '../file-list'

const GET_FILES_QUERY = gql`
	query getFilesForProposal($documentId: ID!) {
		getFilesForProposal(documentId: $documentId) {
			_id
			filename
			length
			fileStatus
		}
	}
`

const UPDATE_FILES_MUTATION = gql`
	mutation updateFilesForProposal($documentId: ID!, $fileIds: [ID!]!) {
		updateFilesForProposal(documentId: $documentId, fileIds: $fileIds)
	}
`

const GET_UPDATED_PROPOSAL = gql`
	query getRfp($_id: ID!) {
		getRfp(_id: $_id) {
			_id
			proposals {
				... on Response {
					_id
					files {
						_id
						filename
						length
						fileStatus
					}
				}
			}
		}
	}
`
export const FileListForProposal = ({ documentId, requestId }) => {
	const refetchQueries = [
		{
			query: GET_UPDATED_PROPOSAL,
			variables: {
				_id: requestId,
			},
		},
	]
	return (
		<FileWrapper
			documentId={documentId}
			getFilesQuery={GET_FILES_QUERY}
			updateFilesMutation={UPDATE_FILES_MUTATION}
			refetchQueries={refetchQueries}
		>
			{({ fileList, updatingFilesForDocument, errorFetchingFilesForDocument, removeFile }) => (
				<FileList
					files={fileList}
					removeFile={removeFile}
					errorFetchingFilesForDocument={errorFetchingFilesForDocument}
					updatingFilesForDocument={updatingFilesForDocument}
				/>
			)}
		</FileWrapper>
	)
}
