// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { isNotNil } from '@persuit/common-utils'
import { ErrorCardData, ErrorCardTransformSectionHandler } from '../../../../sectional-form'

import { RfpTemplateDraftRatesErrors } from '@persuit/ui-graphql/generated'

export function getCurrencyError(
	sectionErrors: RfpTemplateDraftRatesErrors,
	link: string,
): Array<ErrorCardData> {
	const currencyError = sectionErrors?.currency ?? ''
	if (isEmpty(currencyError)) return []
	return [
		{
			title: 'Rates - Currency',
			link,
			errorMessages: [currencyError],
			subSections: [],
		},
	]
}

export function getTimekeeperErrors(
	sectionErrors: RfpTemplateDraftRatesErrors,
	link: string,
): Array<ErrorCardData> {
	const timekeepersErrors = sectionErrors?.timekeepers ?? []
	if (isEmpty(timekeepersErrors)) return []

	return timekeepersErrors.reduce((acc, timekeeperError, index) => {
		if (timekeeperError?.timekeeperLabel)
			return [
				...acc,
				{
					title: `Rates - Timekeeper ${index + 1}`,
					link,
					errorMessages: [timekeeperError.timekeeperLabel].filter(isNotNil),
					subSections: [],
				},
			]
		return acc
	}, [])
}

export function getDetailsError(
	sectionErrors: RfpTemplateDraftRatesErrors,
	link: string,
): Array<ErrorCardData> {
	const detailsError = sectionErrors?.details ?? ''
	if (isEmpty(detailsError)) return []
	return [
		{
			title: 'Rates - Details',
			link,
			errorMessages: [detailsError],
			subSections: [],
		},
	]
}

export function getNamedTimekeepersSettingsErrors(
	sectionErrors: RfpTemplateDraftRatesErrors,
	link: string,
) {
	const namedTimekeepersSettingsErrors = sectionErrors.namedTimekeepersSettings
	if (isEmpty(namedTimekeepersSettingsErrors)) return []
	const billingError = namedTimekeepersSettingsErrors?.billingIdInstructions
		? {
				title: 'Rates - Timekeeper ID instructions',
				errorMessages: [namedTimekeepersSettingsErrors.billingIdInstructions],
				link,
				subSections: [],
		  }
		: null

	const graduationYearError = namedTimekeepersSettingsErrors?.graduationYearInstructions
		? {
				title: 'Rates - Graduation year instructions',
				errorMessages: [namedTimekeepersSettingsErrors.graduationYearInstructions],
				link,
				subSections: [],
		  }
		: null

	return [billingError, graduationYearError].filter(isNotNil)
}

type makeRatesHandlerData = {
	sectionName: string
	path?: string
	templateId?: string
	titleOverride?: string
}

export const makeRatesHandlerMaker =
	({ getCardLink }) =>
	({
		sectionName,
		path = '',
		templateId = '',
	}: makeRatesHandlerData): ErrorCardTransformSectionHandler =>
	(sectionErrors: RfpTemplateDraftRatesErrors): Array<ErrorCardData> => {
		if (isEmpty(sectionErrors)) return []
		const link = getCardLink({ path, templateId, sectionName })

		return [
			...getDetailsError(sectionErrors, link),
			...getCurrencyError(sectionErrors, link),
			...getTimekeeperErrors(sectionErrors, link),
			...getNamedTimekeepersSettingsErrors(sectionErrors, link),
		].filter(isNotNil)
	}
