import * as React from 'react'
import { IconButton, IconButtonProps } from '../icon-button'
import { MoreVertIcon } from '../../icons'

type KebabButtonProps = {
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	'aria-controls': string | undefined
	id: string
} & IconButtonProps

export const KebabButton = ({ onClick, ...props }: KebabButtonProps) => {
	return (
		<IconButton
			data-trackid="button-kebab"
			aria-label="more actions"
			aria-haspopup="true"
			onClick={onClick}
			data-testid="more-vertical-button"
			color="primary"
			size="large"
			{...props}
		>
			<MoreVertIcon color="primary" />
		</IconButton>
	)
}
