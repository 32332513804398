import { Box } from '../'
import { useTheme } from '../../'

/**
 * @deprecated Use SX props with spacing values for consistent and responsive spacing.
 * https://mui.com/system/spacing/
 */
export type SpacerProps = {
	/**
	 * Shape can either be a 'row' or 'column'
	 * A row will produce a "space" row
	 * A column will produce a "space" column
	 */
	shape?: 'row' | 'column'
	/**
	 * Space is a number that will be fed into
	 * theme.spacing to generate a space based on the
	 * theme's scaling factor
	 *
	 * Default: 1
	 */
	space?: number
}

/**
 * @deprecated Use SX props with spacing values for consistent and responsive spacing.
 * https://mui.com/system/spacing/
 */
export const Spacer = ({ shape = 'row', space = 1 }: SpacerProps) => {
	const theme = useTheme()

	if (shape === 'row')
		return (
			<Box
				display="block"
				component={'span'}
				width={0}
				height={theme.spacing(space)}
				aria-hidden="true"
				role="presentation"
			/>
		)
	if (shape === 'column')
		return (
			<Box
				display="inline-block"
				component={'span'}
				height={0}
				width={theme.spacing(space)}
				aria-hidden="true"
				role="presentation"
			/>
		)
	return null
}

Spacer.displayName = 'Spacer'
