// @ts-strict-ignore
import { Typography } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import { GridColDef, gridNumberComparator } from '@mui/x-data-grid-pro'

import { RateCell, HeaderCell, FirmCell } from './cells'

import { RowData } from './generate-row-data'

import { StoreApi, Field, Rfp } from '../store'

type ColDef = GridColDef<RowData>

export type GenerateColumnDefinitionsInput = {
	rfp: Rfp
	currency: string
	/**
	 * Allow column definitions to access the latest store state, creating new
	 * column definitions based on state does not always work as data seems to be
	 * cached, or the times at which col def functions are called is delayed and
	 * out of sync with the data.
	 */
	storeApi: StoreApi
}

export function generateColumnDefinitions({
	currency,
	rfp,
	storeApi,
}: GenerateColumnDefinitionsInput) {
	const rfpTimekeepers = rfp.rateReview?.timekeepers ?? []

	/**
	 * A sorting comparator for numbers with nulls pushed to the bottom of the
	 * table
	 */
	function gridNumberComparatorNullsAtEnd(field: Field) {
		return function sortComparator(v1, v2, p1, p2) {
			const sortState = storeApi.getState().sortState

			if (sortState?.field !== field || !sortState?.direction) return 0

			const sortDirection = sortState.direction
			const flip = sortDirection === 'desc' ? -1 : 1
			const nullValue = Infinity * flip
			return gridNumberComparator(v1 ?? nullValue, v2 ?? nullValue, p1, p2)
		}
	}

	const columnDefinitions: ColDef[] = [
		{
			field: 'firmName',
			headerName: 'Firm Name',
			headerAlign: 'left',
			align: 'left',
			width: 200,
			minWidth: 200,
			maxWidth: 200,
			headerClassName: 'even',
			cellClassName: 'even',
			type: 'string',
			resizable: false,
			renderHeader: (props) => <HeaderCell {...props} />,
			renderCell: ({ row }) => <FirmCell firmName={row.firmName} firmId={row.firmId} />,
		},
		{
			width: 130,
			field: 'weightedAverage',
			headerName: 'Weighted Average',
			headerAlign: 'center',
			align: 'center',
			headerClassName: 'odd',
			cellClassName: 'odd',
			type: 'number',
			sortComparator: gridNumberComparatorNullsAtEnd('weightedAverage'),
			renderHeader: (props) => <HeaderCell {...props} />,
			renderCell: ({ row }) => {
				const weightedAverage = row.weightedAverage
				if (weightedAverage === null) return '-'
				return (
					<Typography
						variant="body2"
						sx={(theme) => ({
							color: weightedAverage > 0 ? theme.palette.error.main : theme.palette.success.main,
							whiteSpace: 'nowrap',
						})}
					>
						{weightedAverage > 0 ? '+' : ''}
						{(weightedAverage * 100).toFixed(2)}%
					</Typography>
				)
			},
		},
		...rfpTimekeepers.map((timekeeper, index): ColDef => {
			const field = `timekeepers[${index}]` as const

			return {
				field,
				headerAlign: 'left',
				align: 'left',
				width: 130,
				headerClassName: index % 2 === 0 ? 'even' : 'odd',
				cellClassName: index % 2 === 0 ? 'even' : 'odd',
				type: 'number',
				sortComparator: gridNumberComparatorNullsAtEnd(field),
				// This value is only used for sorting purposes
				valueGetter: ({ row }) => {
					const sortState = storeApi.getState().sortState
					if (!sortState) return 'rate'

					const sortField = sortState.field
					const sortType = sortState.type

					const tk = row.timekeepers.find((t) => t.timekeeper === timekeeper._id)
					if (sortField === field && sortType === 'percentage') {
						return tk?.delta ?? null
					}
					return tk?.proposedRate ?? null
				},
				renderHeader: (props) => <HeaderCell {...props} />,
				renderCell: ({ row }) => {
					const data = row.timekeepers[index]
					return <RateCell {...data} currency={currency} sx={{ flexGrow: 1 }} />
				},
				headerName: timekeeper.timekeeperLabel ?? '',
			}
		}),
	]

	return columnDefinitions
}
