import {
	Card,
	Box,
	FormTextField,
	SortableDragHandler,
	ListIcon,
	Button,
	SortableListItem,
	TopLeftErrorOutlineIcon,
	AddIcon,
	SortableContext,
	SortableList,
	useDroppable,
} from '@persuit/ui-components'

import { usePricingListContext } from '../../pricing-list-context'
import { isGroup, useFormContext } from '../../pricing-form-utils'
import { getOrGenerateUUID } from '@persuit/common-utils'
import { PricingItem } from '../pricing-item'
import { PricingGroup as PricingGroupType } from '../../types'
import { PricingGroupMoreActionsMenu } from './pricing-group-more-actions-menu'
import { getPricingPreference } from '../utils'

type PricingGroupProps = {
	id: string
	index: number
	activeDragId?: string | number | null
}

export const PricingGroup = ({ index, id, activeDragId }: PricingGroupProps) => {
	const {
		errors,
		getPricingGroupIndex,
		registerExpandedState,
		totalPricingPreference,
		pricingItemsFieldArray: {
			fields: pricingListItems,
			insert: insertPricingListItems,
			update: updatePricingListItems,
		},
	} = usePricingListContext()
	const { getValues } = useFormContext()
	const { setNodeRef } = useDroppable({
		id,
	})
	const isActiveDragging = activeDragId === id

	const group = pricingListItems[index]
	if (!isGroup(group)) {
		return null
	}
	const pricingGroupItems = group.deliverables ?? []

	const getCurrentGroup = () => getValues().pricingItems[index] as PricingGroupType

	const createNewPricingItem = () => {
		const uuid = getOrGenerateUUID()
		const currentGroup = getValues().pricingItems[index] as PricingGroupType
		registerExpandedState(uuid, true)
		const pricingPreferences = getPricingPreference(totalPricingPreference)
		updatePricingListItems(index, {
			...currentGroup,
			deliverables: [
				...currentGroup.deliverables,
				{
					allowFirmAddRate: false,
					firmRateCardItemPlaceholder: '',
					deliverable: '',
					deliverableTitle: '',
					rates: [],
					uuid,
					pricingPreferences,
				},
			],
		})
		setTimeout(() => {
			document.getElementById(`item-pricing-title-${uuid}`)?.focus()
		}, 100)
	}

	const removeGroupItem = (groupIndex: number) => {
		const currentGroup = getCurrentGroup()
		const deliverables = [...currentGroup.deliverables]
		deliverables.splice(groupIndex, 1)

		updatePricingListItems(index, {
			...currentGroup,
			deliverables,
		})
	}

	const ungroupItem = (groupIndex: number) => {
		const currentGroup = getCurrentGroup()
		const currentGroupItems = [...currentGroup.deliverables]
		const item = currentGroupItems.splice(groupIndex, 1)

		updatePricingListItems(index, {
			...currentGroup,
			deliverables: currentGroupItems,
		})
		insertPricingListItems(index, item)
	}

	const groupIndex = getPricingGroupIndex(id)
	const pricingGroupError = errors?.pricingGroupErrors?.find((error) => error.index === groupIndex)

	const items = pricingGroupItems.map((item) => ({
		id: getOrGenerateUUID(item),
		originalDeliverableId: item.originalDeliverableId,
	}))

	return (
		<SortableListItem id={id}>
			{() => (
				<div ref={setNodeRef}>
					<Box
						bgcolor={isActiveDragging ? 'primary.lighterHue' : undefined}
						mb={2}
						id={`pricing_group_${groupIndex}`}
						tabIndex={-1}
						aria-label={`Pricing item ${groupIndex + 1}`}
						data-testid={`pricing-group-${index}`}
						role="group"
					>
						{pricingGroupError && <TopLeftErrorOutlineIcon data-testid="group-error-icon" />}
						<Card
							style={{
								opacity: isActiveDragging ? 0 : 1,
							}}
						>
							<Box p="1rem">
								<Box display="flex" alignItems={'center'}>
									<SortableDragHandler id={id} />
									<Box ml=".5rem" display="flex" alignItems={'center'}>
										<ListIcon />
									</Box>
									<Box ml="1rem" flexGrow={1}>
										<FormTextField
											inputProps={{
												maxLength: 200,
											}}
											required={true}
											fullWidth={true}
											name={`pricingItems.${index}.title`}
											label="Group title"
											error={!!pricingGroupError?.title}
											helperText={pricingGroupError?.title}
											id={`pricingItems-${index}-title`}
											placeholder="Group title"
										/>
									</Box>
									<Box display="flex" alignItems={'center'}>
										<PricingGroupMoreActionsMenu
											index={index}
											totalGroupItems={pricingGroupItems.length}
										/>
									</Box>
								</Box>
								<Box style={{ display: isActiveDragging ? 'none' : undefined }} marginTop={2}>
									<SortableContext items={items} id="group-items">
										{isActiveDragging ? null : (
											<SortableList>
												{items.map(({ id }, groupIndex) => (
													<PricingItem
														key={id}
														activeDragId={activeDragId}
														index={index}
														groupIndex={groupIndex}
														id={id}
														removeItem={() => removeGroupItem(groupIndex)}
														ungroupItem={() => ungroupItem(groupIndex)}
													/>
												))}
											</SortableList>
										)}
									</SortableContext>

									<Button
										startIcon={<AddIcon />}
										color="primary"
										variant="outlined"
										aria-label="Add item to group"
										onClick={createNewPricingItem}
									>
										Item To Group
									</Button>
								</Box>
							</Box>
						</Card>
					</Box>
				</div>
			)}
		</SortableListItem>
	)
}
