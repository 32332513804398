import { Dialog, RichTextView, Typography } from '@persuit/ui-components'

type AssumptionDialogProps = {
	handleClose: () => void
	isOpen: boolean
	originalAssumption: string
	updatedAssumption: string
}

export const AssumptionDialog = ({
	handleClose,
	isOpen,
	originalAssumption,
	updatedAssumption,
}: AssumptionDialogProps) => {
	return (
		<Dialog
			onClick={(e) => {
				e.stopPropagation()
			}}
			open={isOpen}
			onClose={handleClose}
			size="md"
			title="Original & updated assumptions & description"
			actions={[
				{
					label: 'Ok',
					variant: 'primary',
					onClick: handleClose,
					'data-testid': 'close-assumptions-button',
				},
			]}
		>
			<Typography variant="h3XSmall">Original assumption</Typography>
			<RichTextView content={originalAssumption} data-testid="rfpr-view-original-assumptions" />

			<Typography variant="h3XSmall">Updated assumption</Typography>
			<RichTextView content={updatedAssumption} data-testid="rfpr-view-updated-assumptions" />
		</Dialog>
	)
}
