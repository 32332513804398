import { Component } from 'react'
import classnames from 'classnames'
import injectSheet from '../../injectSheet'
import baseTheme from '../../theme/persuit-theme'
import clearfix from '../../utils/clearfix'

const { desktopGutter, desktopMq, mobileGutter, breakpoints } = baseTheme.grid

const jssStyles = {
	col: {
		flexFlow: 'column nowrap',
		boxSizing: 'border-box',
		maxWidth: '100%',
		paddingLeft: `${mobileGutter / 2}px`,
		paddingRight: `${mobileGutter / 2}px`,
		marginBottom: `${mobileGutter}px`,
		[desktopMq]: {
			paddingLeft: `${desktopGutter / 2}px`,
			paddingRight: `${desktopGutter / 2}px`,
		},
		'&:before': clearfix,
		'&:after': clearfix,
	},

	noGutterBottom: {
		marginBottom: '0 !important',
		[desktopMq]: {
			marginBottom: '0 !important',
		},
	},
}

Object.keys(breakpoints).forEach((breakpoint) => {
	const { minWidth, columns } = breakpoints[breakpoint]

	for (let i = 0; i <= columns; i++) {
		if (i === 0) {
			// If columns is set to 0 (eg xs={0} then hide the element
			jssStyles[`col-${breakpoint}-${i}`] = {
				[`@media (min-width: ${minWidth})`]: {
					display: 'none',
				},
			}
		} else {
			const percent = (i / columns) * 100
			const percentage = percent.toFixed(4)
			jssStyles[`col-${breakpoint}-${i}`] = {
				[`@media (min-width: ${minWidth})`]: {
					// When a column is is 100%, use display: block (Safari fix).
					display: percent === 100 ? 'block' : 'flex',
					flex: `0 0 ${percentage}%`,
					// maxWidth for ie - flex basis + box-sizing: border-box = fail
					maxWidth: `${percentage}%`,
				},
			}

			jssStyles[`${breakpoint}-order-${i}`] = {
				[`@media (min-width: ${minWidth})`]: {
					order: i,
				},
			}
		}
	}

	jssStyles[`${breakpoint}-end`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
	}

	jssStyles[`${breakpoint}-expand`] = {
		[`@media (min-width: ${minWidth})`]: {
			flex: '0 1 100%',
		},
	}

	jssStyles[`${breakpoint}-auto`] = {
		[`@media (min-width: ${minWidth})`]: {
			flex: '0 1 auto',
			width: 'auto',
		},
	}

	jssStyles[`${breakpoint}-direction-row`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexDirection: 'row',
		},
	}

	jssStyles[`${breakpoint}-direction-column`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexDirection: 'column',
		},
	}
})

class Column extends Component {
	static defaultProps = {
		component: 'div',
		gutterBottom: false,
		xs: 4,
	}

	render() {
		const {
			// Remove shitload of props from ...other to prevent warnings
			sheet,
			i18nLoadedAt,
			xs,
			sm,
			md,
			lg,
			xl,
			xsAuto,
			xsDirection,
			xsEnd,
			xsExpand,
			xsOrder,
			smAuto,
			smDirection,
			smEnd,
			smExpand,
			smOrder,
			mdAuto,
			mdDirection,
			mdEnd,
			mdExpand,
			mdOrder,
			lgAuto,
			lgDirection,
			lgEnd,
			lgExpand,
			lgOrder,
			xlAuto,
			xlDirection,
			xlEnd,
			xlExpand,
			xlOrder,

			// Real props we actually care about
			children,
			className,
			component,
			gutterBottom,
			classes,
			style,
			...other
		} = this.props

		const breakpointClassNames = Object.keys(breakpoints).reduce((memo, key) => {
			const size = this.props[key]
			const auto = this.props[`${key}Auto`]
			const direction = this.props[`${key}Direction`]
			const end = this.props[`${key}End`]
			const expand = this.props[`${key}Expand`]
			const order = this.props[`${key}Order`]
			memo[classes[`col-${key}-${size}`]] = size >= 0
			memo[classes[`${key}-auto`]] = !!auto
			memo[classes[`${key}-direction-${direction}`]] = !!direction
			memo[classes[`${key}-end`]] = !!end
			memo[classes[`${key}-expand`]] = !!expand
			memo[classes[`${key}-order-${order}`]] = !!order
			return memo
		}, {})

		const Component = component

		const classNames = classnames(classes.col, className, breakpointClassNames, {
			[classes.noGutterBottom]: !gutterBottom,
		})

		return (
			<Component className={classNames} style={style || {}} {...other}>
				{children}
			</Component>
		)
	}
}

export default injectSheet(jssStyles)(Column)
