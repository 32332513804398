import cond from 'lodash/fp/cond'
import constant from 'lodash/fp/constant'
import conforms from 'lodash/fp/conforms'
import matches from 'lodash/fp/matches'
import stubTrue from 'lodash/fp/stubTrue'
import getOr from 'lodash/fp/getOr'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import isDate from 'date-fns/isDate'
import toDate from 'date-fns/toDate'
import i18next from 'i18next'
import DueDateTicker from '../../due-date-ticker'
import FirmStatusTypes from '../firm-status-types'

const isDateInFuture = (lens) => (timestamp) => {
	const date = lens(timestamp)
	if (!isDate(toDate(date))) {
		return ''
	}

	return isFuture(date)
}

const isAuctionInProgress = conforms({
	auctionStart: (date) => isPast(date),
	auctionEnd: (date) => isFuture(date),
})

const getTertiaryText = ({ auctionStart, auctionEnd, lastBid, isRequestClosed }) => {
	if (isRequestClosed) {
		return null
	}

	return cond([
		[isAuctionInProgress, constant(lastBid)],
		[stubTrue, constant('')],
	])({
		auctionStart,
		auctionEnd,
		lastBid,
	})
}

const getReopenedStatusForFirm = ({ isRequestReopenedForFirm }) =>
	isRequestReopenedForFirm ? i18next.t('reopenIndividually.firm.statusText') : null

const getSecondaryText = ({
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	isRequestClosed,
	isRequestReopenedForFirm,
}) => {
	if (isRequestClosed) {
		return getReopenedStatusForFirm({ isRequestReopenedForFirm })
	}

	const dueDateTickerProps = cond([
		[
			isDateInFuture(getOr(undefined, 'initialProposalsDueBy')),
			constant({ dueDate: initialProposalsDueBy }),
		],
		[
			isDateInFuture(getOr(undefined, 'auctionStart')),
			constant({ prefix: 'Auction starts', dueDate: auctionStart }),
		],
		[
			isDateInFuture(getOr(undefined, 'auctionEnd')),
			constant({ prefix: 'Ends', dueDate: auctionEnd }),
		],
	])({ initialProposalsDueBy, auctionStart, auctionEnd })

	return <DueDateTicker {...dueDateTickerProps} />
}

const getSecondaryWithdrawnText = ({ canFirmParticipate, proposalStatus }) => {
	if (!canFirmParticipate) {
		return 'Unable to participate'
	}

	if (proposalStatus === 'sent') {
		return 'Proposal sent'
	}

	return 'Proposal not sent'
}

const getAuctionProps = ({
	requestStatus,
	proposalStatus,
	initialProposalsDueBy,
	auctionStart,
	auctionEnd,
	lastBid,
	isRequestClosed,
	isRequestReopenedForFirm,
	isFirmEliminated,
	canFirmParticipate,
}) =>
	cond([
		[matches({ isFirmEliminated: true }), constant({ status: FirmStatusTypes.ELIMINATED })],
		[
			matches({ canFirmParticipate: false }),
			constant({ status: FirmStatusTypes.DECLINED_TO_PARTICIPATE }),
		],
		[
			matches({ requestStatus: FirmStatusTypes.DECLINED_TO_PARTICIPATE }),
			constant({ status: requestStatus }),
		],
		[
			matches({ requestStatus: FirmStatusTypes.DRAFT }),
			constant({
				status: requestStatus,
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.OPEN_TO_PROPOSALS }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
				}),
				statusLine3: getTertiaryText({
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
				}),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.EVALUATING }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
					isRequestReopenedForFirm,
				}),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.AWAITING_AUCTION }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
					isRequestReopenedForFirm,
				}),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.AUCTION_IN_PROGRESS }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryText({
					initialProposalsDueBy,
					auctionStart,
					auctionEnd,
					isRequestClosed,
				}),
				statusLine3: getTertiaryText({
					auctionStart,
					auctionEnd,
					lastBid,
					isRequestClosed,
				}),
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.COMPLETED }),
			constant({
				status: requestStatus,
			}),
		],
		[
			matches({ requestStatus: FirmStatusTypes.WITHDRAWN }),
			constant({
				status: requestStatus,
				statusLine2: getSecondaryWithdrawnText({ canFirmParticipate, proposalStatus }),
			}),
		],
		[stubTrue, constant({ status: '' })],
	])({
		requestStatus,
		isFirmEliminated,
		canFirmParticipate,
	})

export default getAuctionProps
