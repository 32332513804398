// eslint-disable-next-line no-restricted-imports -- Please upgrade
import HistoryIcon from '@mui/icons-material/History'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'Request will be reopened only for this firm. Other firms will not be notified.',
	'Firm will be allowed to revise their proposals.',
]

export default ({ onNext, disabled, hide }) => {
	return hide ? null : (
		<DialogWizardListOption
			dataTestid=""
			icon={<HistoryIcon />}
			primaryText="Reopen request for proposal revision"
			disabled={disabled}
			bullets={bullets}
			onClick={onNext}
		/>
	)
}
