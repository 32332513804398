import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Table from '@mui/material/Table'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableBody from '@mui/material/TableBody'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableCell from '@mui/material/TableCell'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableHead from '@mui/material/TableHead'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableRow from '@mui/material/TableRow'
import { filter, isEmpty, size } from 'lodash/fp'

import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import reportTypes from '../../../common/data/report-types'
import savingsCalculationMethods from '../../../common/data/savings-calculation-methods'
import { Left } from '../../../common/either'
import Transducer from '../../../common/transducer'
import useTranslation from '../../custom-hooks/translation-hook'
import savingsSummaryToExcel from '../../graphql/mutations/savingsSummaryToExcel-gql'
import getAnalytics from '../../graphql/queries/getAnalytics-gql'
import injectSheet from '../../injectSheet'
import { grey100 } from '../../theme/persuit-colors'
import clampSavings from '../../utils/analytics/clampSavings'
import getSavingsReference from '../../utils/analytics/getSavingsReference'
import showEstimatedSavings from '../../utils/analytics/showEstimatedSavings'
import formatCurrency from '../../utils/format-currency'
import { getOrEmptyArray, getOrEmptyObject, getOrEmptyString } from '../../utils/lenses'
import Alert from '../alert.jsx'
import ExportToExcel from '../export-to-excel.jsx'
import Spacer from '../layout/spacer.jsx'
import LoadingSpinner from '../loading-spinner.jsx'
import Text from '../text.jsx'
import ReportRefreshButton from './report-refresh-button'
import RfpsExcludedFromAnalyticsText from './rfps-excluded-from-analytics-text'
import { useUser, useUAMEnabled } from '@persuit/ui-auth'
//TODO - DEV-6860: Refactor this component to a functional componet and import useTheme
import { theme } from '@persuit/ui-components'

const getDataFromPayload = () => (data) => {
	if (data.loading) {
		return new Left('loading')
	}

	if (data.error) {
		return new Left('gql')
	}

	return data.getAnalytics
}

const TableContentWhenNoAnalyticsRow = ({
	isRfpExcludedFromAnalytics,
	savingsSummaryTableRowSize,
}) => {
	if (isRfpExcludedFromAnalytics) {
		return (
			<TableRow>
				<TableCell colSpan={6} style={{ backgroundColor: grey100 }}>
					<Text type="body1">
						Your organization has chosen to not track savings from this Request in company-wide
						analytics. This Request may contain test, duplicate, cancelled or distorted data.
					</Text>
				</TableCell>
			</TableRow>
		)
	}

	if (savingsSummaryTableRowSize === 0) {
		return (
			<TableRow>
				<TableCell colSpan={6} style={{ backgroundColor: grey100 }}>
					<Text style={{ textAlign: 'center' }} grey={true} type="subheading1">
						No savings data available
					</Text>
				</TableCell>
			</TableRow>
		)
	}

	return null
}

const renderTable =
	({
		condensed,
		exportToExcel,
		t,
		gqlData,
		orgOrGroup,
		userRefetch,
		numberOfRfpExcludedInAnalytics,
		requestId,
	}) =>
	(data) => {
		const headings = []

		if (!condensed) {
			headings.push('Matter ref', 'Title', 'PERSUIT ID')
		}

		headings.push(
			'Comparison Type',
			'Comparison Value',
			'Selected Bid or Lowest Bid*',
			'Comparison - (Selected or Lowest*)',
			'Savings Achieved^',
			'Savings %',
		)

		const subTitle = `${'Current savings calculation method:'} ${t(
			`analytics.savings.${getSavingsCalculation({ orgOrGroup })}`,
		)}`

		const analyticsDataLength = size(getOrEmptyObject('getAnalytics', gqlData))
		const isRfpExcludedFromAnalytics = !isEmpty(requestId) && analyticsDataLength === 0

		return (
			<Fragment>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{!condensed && (
						<Fragment>
							<div style={{ marginRight: 'auto' }}>
								<Text type="title">Savings Summary</Text>
								<Text type="caption">{subTitle}</Text>
							</div>
							<ExportToExcel name="Savings Summary" exportToExcel={exportToExcel} />
						</Fragment>
					)}
					<ReportRefreshButton onRefresh={refresh(gqlData, userRefetch)} />
				</div>
				<Spacer direction="column" space={2} />
				<Table stickyHeader={true}>
					<TableHead>
						<TableRow>
							{headings.map((heading) => (
								<TableCell
									key={heading}
									sx={{
										backgroundColor: theme.palette.primary.main,
										color: theme.palette.primary.contrastText,
									}}
								>
									{heading}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data}
						<TableContentWhenNoAnalyticsRow
							isRfpExcludedFromAnalytics={isRfpExcludedFromAnalytics}
							savingsSummaryTableRowSize={size(data)}
						/>
					</TableBody>
				</Table>
				<Spacer />
				{!isRfpExcludedFromAnalytics && (
					<Text
						type="body1"
						style={{
							fontStyle: 'italic',
						}}
					>
						* Before a firm is selected, the default Lowest Bid will be used to estimate savings.
						<br />^ Savings is calculated only on those firms where the selected bid is lower than
						the comparison bid.
					</Text>
				)}
				<br />
				{!condensed && (
					<RfpsExcludedFromAnalyticsText
						numberOfRfpExcludedInAnalytics={numberOfRfpExcludedInAnalytics}
					/>
				)}
			</Fragment>
		)
	}

const renderError = () => (error) => {
	if (error === 'loading') {
		return <LoadingSpinner />
	} else if (error === 'gql') {
		return <Alert type="fail">A server error occurred while generating this report</Alert>
	} else {
		return <Alert type="fail">An unexpected error occurred while generating this report</Alert>
	}
}

const getRequestData = () => (item) => {
	return {
		...item,
		ref: item.tracked.referenceNo,
		matterTitle: item.listingTitle,
		persuitId: item.humanFriendlyId,
	}
}

const getSavingsCalculation = ({ orgOrGroup }) => {
	if (!orgOrGroup) {
		return new Left('loading')
	}

	const settings = orgOrGroup.settings

	return settings.savingsCalculationMethod || savingsCalculationMethods.RELATIVE_TO_AVERAGE_PRICE
}

const getComparisonType =
	({ orgOrGroup, t }) =>
	(item) => {
		// Default comparison
		let comparisonType = getSavingsCalculation({ orgOrGroup })

		return {
			...item,
			comparisonType: t(`analytics.savings.${comparisonType}`),
			comparisonValue: getSavingsReference(item, comparisonType),
		}
	}

const calculatePercentage = (numerator, denominator) => {
	if (denominator !== 0) {
		return (100 * numerator) / denominator
	}
	return 0
}

const calculateSavings = () => (item) => {
	let savingsValue = 0
	let savingsPercentage = 0
	const isEstimated = showEstimatedSavings(
		item.hasAnAcceptedProposal,
		item.lowestPrice,
		item.bids.length,
	)
	const skip = !item.lowestPrice && !item.acceptedPrice

	const hasComparisonValue = item.comparisonValue !== null && item.comparisonValue !== undefined
	const hasAcceptedPrice = item.acceptedPrice !== null && item.acceptedPrice !== undefined

	if (hasComparisonValue) {
		const comparisonValue = item.comparisonValue
		if (isEstimated && !skip) {
			savingsValue = comparisonValue - item.lowestPrice
			savingsPercentage = calculatePercentage(savingsValue, comparisonValue)
			item.acceptedPrice = item.lowestPrice
		} else if (hasAcceptedPrice) {
			savingsValue = comparisonValue - item.acceptedPrice
			savingsPercentage = calculatePercentage(savingsValue, comparisonValue)
		}
	}

	savingsPercentage = clampSavings(savingsPercentage)

	return {
		...item,
		calculatedSavings: savingsValue,
		savingsValue: clampSavings(savingsValue),
		savingsPercentage: isNaN(savingsPercentage) ? 0 : savingsPercentage,
		isEstimated,
		skip,
	}
}

const formatData = (currencyFormatFunction) => (item) => {
	const IsNonZeroEstimatedSavings =
		item.isEstimated && item.savingsValue && parseFloat(item.savingsValue, 10) > 0

	return {
		...item,
		comparisonValue: currencyFormatFunction(
			parseFloat(item.comparisonValue, 10),
			item.listingCurrency,
		),

		acceptedPrice: item.skip
			? '—'
			: `${currencyFormatFunction(parseFloat(item.acceptedPrice, 10), item.listingCurrency)}${
					IsNonZeroEstimatedSavings ? '*' : ''
			  }`,
		savingsValue: item.skip
			? '—'
			: `${currencyFormatFunction(parseFloat(item.savingsValue, 10), item.listingCurrency)}${
					IsNonZeroEstimatedSavings ? '*' : ''
			  }`,
		calculatedSavings: item.skip
			? '—'
			: `${currencyFormatFunction(parseFloat(item.calculatedSavings, 10), item.listingCurrency)}${
					IsNonZeroEstimatedSavings ? '*' : ''
			  }`,
		savingsPercentage: item.skip
			? '—'
			: `${item.savingsPercentage.toFixed(0)}%${IsNonZeroEstimatedSavings ? '*' : ''}`,
	}
}

const constructTableRow =
	({ classes, condensed, navigateTo, t }) =>
	(item) => {
		// - Ref No/Accepted
		// - Matter Title
		// - Comparison Type
		// - Comparison Value
		// - Accepted Value
		// - Savings Value
		// - Savings %
		return (
			<TableRow
				hover={true}
				className={classes.row}
				onClick={handleRowClick({ requestId: item.listingId, grants: item.grants, navigateTo, t })}
			>
				{!condensed && (
					<Fragment>
						<TableCell className={classes.cell}>{item.ref}</TableCell>
						<TableCell className={classes.cell}>{item.matterTitle}</TableCell>
						<TableCell className={classes.cell}>{item.persuitId}</TableCell>
					</Fragment>
				)}
				<TableCell className={classes.cell}>{item.comparisonType}</TableCell>
				<TableCell className={classes.cell}>{item.comparisonValue}</TableCell>
				<TableCell className={classes.cell}>{item.acceptedPrice}</TableCell>
				<TableCell className={classes.cell}>{item.calculatedSavings}</TableCell>
				<TableCell
					className={classes.cell}
					style={{
						fontWeight: 'bold',
					}}
				>
					{item.savingsValue}
				</TableCell>
				<TableCell
					className={classes.cell}
					style={{
						fontWeight: 'bold',
					}}
				>
					{item.savingsPercentage}
				</TableCell>
			</TableRow>
		)
	}

// Basic refresh control that calls the apollo refetch method
// to perform another fetch (this is useful because the
// analytics data does not get updated over sockets)
const refresh = (data, userRefetch) => async () => {
	// Refetch the org in case settings have changed (i.e. if savings
	// calculation method has changed)
	await userRefetch()

	data.refetch()
}

// Each row in the table links back to the proposals tab of the
// corresponding request
const handleRowClick =
	({ requestId, grants, navigateTo }) =>
	() => {
		if (grants.includes('VIEW')) {
			navigateTo(`/${'en'}/request/${requestId}/proposals/edit/`)
		} else {
			navigateTo(`/${'en'}/tracking/${requestId}/`)
		}
	}

const SavingsSummary = ({
	data,
	classes,
	condensed,
	exportToExcel,
	navigateTo,
	rfpAnalyticsSettings,
	requestId,
	currencyFormatFunction,
}) => {
	const { t } = useTranslation()
	const { user, refetch: userRefetch, loading } = useUser()
	const { enabled: isUAMToggledOn } = useUAMEnabled()

	if (loading) return null

	const numberOfRfpExcludedInAnalytics = size(
		filter(
			{ includedInAnalytics: false },
			getOrEmptyArray('getRfpAnalyticsSettings', rfpAnalyticsSettings),
		),
	)

	const orgOrGroup = isUAMToggledOn ? user.group : user.org

	return (
		new Transducer(data)
			.pre(getDataFromPayload())
			// 1. Get required request-related data
			.map(getRequestData())
			// 2. Get the savings comparison type
			.map(getComparisonType({ orgOrGroup, t }))
			// 3. Calculate the savings for each item
			.map(calculateSavings())
			// 4. Format the data for display
			.map(formatData(currencyFormatFunction))
			// Contruct the table row
			.map(constructTableRow({ classes, condensed, navigateTo, t }))
			// Apply the transforms - kind of forces handling errors explicitly
			.either(
				// Render the output if the transforms above went well
				renderTable({
					condensed,
					exportToExcel,
					t,
					gqlData: data,
					userRefetch,
					orgOrGroup,
					numberOfRfpExcludedInAnalytics,
					requestId,
				}),
				// Render an error otherwise
				renderError({ t }),
			)
	)
}

const styles = {
	cell: {
		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
		verticalAlign: 'top',
	},
	row: {
		'&:hover': {
			cursor: 'pointer',
			// Arbitrary background colour on hover
			// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
			background: '#E2E2E2',
		},
		// This sets the background colour of each odd row
		// (i.e. gives it a striped effect)
		'&:nth-of-type(odd)': {
			// Arbitrary background color for the striped row
			// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
			backgroundColor: '#F6F6F6',
			'&:hover': {
				// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
				background: '#E2E2E2',
			},
		},
	},
}

SavingsSummary.defaultProps = {
	currencyFormatFunction: formatCurrency,
}
const SavingsSummaryWithBrowserHistory = ({ history, ...props }) => (
	<SavingsSummary {...props} navigateTo={(url) => history.push(url)} />
)

const GET_RFP_ANALYTICS_SETTINGS = gql`
	query getRfpAnalyticsSettings($orgId: ID!) {
		getRfpAnalyticsSettings(orgId: $orgId) {
			_id
			includedInAnalytics
		}
	}
`

/** @type {any} */
export default compose(
	withRouter,
	injectSheet(styles),
	graphql(getAnalytics, {
		options: ({ requestId }) => ({
			variables: {
				requestId,
				reportType: reportTypes.OVERVIEW_SAVINGS_SUMMARY,
			},
		}),
	}),
	graphql(savingsSummaryToExcel, {
		name: 'exportToExcel',
	}),
	graphql(GET_RFP_ANALYTICS_SETTINGS, {
		name: 'rfpAnalyticsSettings',
		options: (props) => {
			const orgId = getOrEmptyString('org.getOwnOrg._id', props)
			return {
				variables: { orgId },
			}
		},
	}),
)(SavingsSummaryWithBrowserHistory)
