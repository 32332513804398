import { getOrUndefined, getOrEmptyString, getOrZero, getOrFalse } from '../../../../utils/lenses'
import getAuctionProps from './get-auction-props'
import getNonAuctionProps from './get-non-auction-props'

export default (request, proposals, firms) => {
	return getOrFalse('isAuction', request)
		? getAuctionProps({
				requestStatus: getOrEmptyString('status', request),
				updatedAt: getOrUndefined('updatedAt', request),
				initialProposalsDueBy: getOrUndefined('initialProposalsDueBy', request),
				auctionStart: getOrUndefined('auctionStart', request),
				auctionEnd: getOrUndefined('auctionEnd', request),
				lastBid: getOrUndefined('lastBid', request),
				totalAcceptedProposalCount: getOrZero('totalAcceptedProposalCount', proposals),
				totalSubmittedProposalCount: getOrZero('totalSubmittedProposalCount', proposals),
				totalInviteCount: getOrZero('totalInviteCount', firms),
				declinedCount: getOrZero('declinedCount', firms),
				isRequestClosed: getOrFalse('isRequestClosed', request),
				isRequestCompleted: getOrFalse('isRequestCompleted', request),
				reOpenInvitesCount: getOrZero('reOpenInvitesCount', firms),
		  })
		: getNonAuctionProps({
				requestStatus: getOrEmptyString('status', request),
				updatedAt: getOrUndefined('updatedAt', request),
				proposalsDueBy: getOrUndefined('proposalsDueBy', request),
				totalAcceptedProposalCount: getOrZero('totalAcceptedProposalCount', proposals),
				totalSubmittedProposalCount: getOrZero('totalSubmittedProposalCount', proposals),
				totalInviteCount: getOrZero('totalInviteCount', firms),
				declinedCount: getOrZero('declinedCount', firms),
				reOpenInvitesCount: getOrZero('reOpenInvitesCount', firms),
		  })
}
