// POLICY FILE
import getOr from 'lodash/fp/getOr'
import allowTypes from '../../data/allow-types'
import { firmTotalModel } from '../../data/pricing-models'
import rfpProposalStates from '../../data/rfp-proposal-states'
import getAllowType from './helpers/get-allow-type'
import isInAllow from './helpers/is-in-allow'
import { isRequester } from './user'

const viewAllowTypes = [allowTypes.VIEW, allowTypes.EDIT]
// This should almost certainly just be allowTypes.EDIT, but keeping
// it with VIEW as well to maintain the current (likely buggy)
// behaviour
const editAllowTypes = [allowTypes.VIEW, allowTypes.EDIT]

export function create(user, scope, data) {
	if (!scope.includes('create_rfpproposal')) {
		return false
	}

	if (isInAllow(user, data)) {
		return true
	}

	return false
}

export function edit(user, scope, resource) {
	if (resource.status === rfpProposalStates.DRAFT_REVISION_DISCARDED) {
		return false
	}

	if (!scope.includes('edit_rfpproposal')) {
		return false
	}

	return editAllowTypes.includes(getAllowType(user, resource))
}

export function accept(user, scope, resource) {
	if (resource.status !== rfpProposalStates.FINALIZED) {
		return false
	}

	if (!isRequester(user) || !scope.includes('accept_rfpproposal')) {
		return false
	}

	if (isInAllow(user, resource)) {
		return true
	}

	return false
}
export function unaccept(user, scope, resource) {
	if (resource.status !== rfpProposalStates.ACCEPTED) {
		return false
	}

	if (!isRequester(user) || !scope.includes('accept_rfpproposal')) {
		return false
	}

	if (isInAllow(user, resource)) {
		return true
	}

	return false
}

export function canRequestProposalRevision(user, scope, resource) {
	if (!scope.includes('accept_rfpproposal')) {
		return false
	}

	if (isInAllow(user, resource)) {
		return true
	}

	return false
}

export function view(user, scope, resource) {
	if (!scope.includes('view_rfpproposal')) {
		return false
	}

	return viewAllowTypes.includes(getAllowType(user, resource))
}

export function destroy(user, scope, resource) {
	if (!scope.includes('destroy_rfpproposal')) {
		return false
	}

	// Check if the user can edit the RFP proposal
	if (!edit(user, scope, resource)) {
		return false
	}

	return false
}

// Verify if proposal price can be revised
export function revisePrice(user, scope, resource) {
	if (!resource) {
		return false
	}

	// Check if the user can edit the RFP proposal
	if (!edit(user, scope, resource)) {
		return false
	}

	const hasRevisionRequest = getOr(false, 'hasRevisionRequest', resource)
	const totalPriceModel = getOr('', 'totalPriceModel', resource)
	const hasTotalPriceModel = !!totalPriceModel

	// Can't revise price on proposal that has a
	// revision request or does not have pricing model
	if (hasRevisionRequest || !hasTotalPriceModel) {
		return false
	}

	// Can't revise price if the proposal pricing model is NOTAPPLICABLE
	if (totalPriceModel === firmTotalModel.NOTAPPLICABLE) {
		return false
	}

	// Can't revise price if the firm has been eliminated
	if (resource.isEliminated) {
		return false
	}

	// Allow revise price only on a proposal that is finalized aka submitted
	return resource.status === rfpProposalStates.FINALIZED
}

export function reviseProposal(user, scope, resource) {
	if (!resource) {
		return false
	}

	// Check if the user can edit the proposal
	if (!edit(user, scope, resource)) {
		return false
	}

	// Can't revise proposal if the firm has been eliminated
	if (resource.isEliminated) {
		return false
	}

	// Allow revise proposal only on finalized aka submitted proposals
	return resource.status === rfpProposalStates.FINALIZED
}

export function viewScorecard(user, scope, proposal) {
	if (!isRequester(user) || !scope.includes('accept_rfpproposal')) {
		return false
	}

	if (isInAllow(user, proposal)) {
		return true
	}

	return false
}

export function editScorecard(user, scope, proposal) {
	if (!isRequester(user) || !scope.includes('accept_rfpproposal')) {
		return false
	}

	if (isInAllow(user, proposal)) {
		return true
	}

	return false
}

export const canDiscardProposalDraftRevision = (user, scope, resource) => {
	if (resource.status !== rfpProposalStates.DRAFT_REVISION) {
		return false
	}

	if (!scope.includes('edit_rfpproposal')) {
		return false
	}

	return editAllowTypes.includes(getAllowType(user, resource))
}
