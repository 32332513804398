import { ReviseProposalIcon } from '@persuit/ui-components'
import DialogWizardListOption from '../../dialog-wizard-list-option'

export default function OptionReviseProposalStart({ onNext }) {
	return (
		<DialogWizardListOption
			dataTestid="revise-proposal-option"
			icon={
				<ReviseProposalIcon
					sx={{
						color: 'text.secondary',
					}}
				/>
			}
			primaryText="Revise proposal"
			bullets={['Full proposal revision including summary, pricing, and questions sections.']}
			onClick={onNext}
		/>
	)
}
