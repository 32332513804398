import { formatPriceWithCurrency } from '@persuit/common-utils'
import { Cell, CellProps } from '../components'
import { Typography } from '@persuit/ui-components'
import { useStore } from '../../store'

type AverageRateCellProps = Pick<CellProps, 'headers' | 'showRightBorder'> & {
	responseId: string
	rate: number
	originalRate?: number
} & CellProps

export const AverageRateCell = ({
	responseId,
	rate,
	originalRate,
	...rest
}: AverageRateCellProps) => {
	const { currency } = useStore((state) => ({
		currency: state.request.detail.currency ?? '',
	}))
	const formattedRate = formatPriceWithCurrency(currency, rate, true, '0,0.[00]')
	const formattedOriginalRate = originalRate
		? formatPriceWithCurrency(currency, originalRate, true, '0,0.[00]')
		: null

	const shouldShowOriginalValue = originalRate && rate !== originalRate

	return (
		<Cell
			responseId={responseId}
			{...rest}
			sx={{
				...rest.sx,
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				alignItems: 'center',
				textAlign: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography variant="h3Large" fontWeight={400} component="p" color="text.secondary">
				{formattedRate}
			</Typography>

			{shouldShowOriginalValue && (
				<Typography variant="body1" strikeThrough={true} color="text.secondary">
					{formattedOriginalRate}
				</Typography>
			)}
		</Cell>
	)
}
