import { createContext, useEffect, useState, useRef, useContext } from 'react'
import * as React from 'react'
import { getOrGenerateUUID, mapRecord, arrayToRecord } from '@persuit/common-utils'
import { logger } from '@persuit/ui-logger'
import { useParams } from 'react-router-dom'
import { Deliverable, DeliverableErrors, PricingFormErrors } from '../types'
import {
	isGroup,
	useFormContext,
	usePricingItemsFieldArray,
	isDeliverable,
	cleanupDuplicatedDeliverable,
} from '../pricing-form-utils'

export type PricingListContextValue = {
	expandedState: Record<string, boolean>
	/** Register an item into expandedState with expansion status */
	registerExpandedState: (id: string, expanded?: boolean) => void
	collapseAllItems: () => void
	expandAllItems: () => void
	getItemExpanded: (item: Pick<Deliverable, '_id'>) => boolean
	toggleItemExpanded: (item: Pick<Deliverable, '_id'>) => void
	duplicatePricingItem: (itemIndex: number, groupIndex?: number) => void
	duplicateGroup: (groupIndex: number) => void
	totalPricingPreference?: string | null
	errors?: {
		pricingItemErrors?: Partial<DeliverableErrors>[]
		pricingGroupErrors?: PricingFormErrors['groups']
	}
	pricingItemsFieldArray: ReturnType<typeof usePricingItemsFieldArray>
	/** Get numbering index of pricing item */
	getPricingItemIndex: (id: string) => number
	/** Get numbering index of pricing group */
	getPricingGroupIndex: (id: string) => number
}

export const PricingListContext = createContext<PricingListContextValue | undefined>(undefined)

export const usePricingListContext = () => {
	const pricingListContext = useContext(PricingListContext)
	if (!pricingListContext)
		throw new Error('usePricingListContext must be within a PricingListContext')
	return pricingListContext
}

export type PricingListContextProviderProps = {
	totalPricingPreference?: string | null
	children: React.ReactNode
	onChange?: (items: Deliverable[]) => void
	errors?: {
		pricingItemErrors?: Partial<DeliverableErrors>[]
		pricingGroupErrors?: PricingFormErrors['groups']
	}
}

const useOnPricingItemNavigate = (handler: (itemIndex: number) => void) => {
	const { anchor } = useParams<{ anchor?: string }>()
	const anchorRef = useRef(anchor)
	const handlerRef = useRef(handler)
	handlerRef.current = handler

	useEffect(() => {
		if (anchor) {
			try {
				const index = parseInt(anchor.replace('pricing_item_', ''), 10)
				handlerRef.current(index)
				setTimeout(() => {
					document.getElementById(anchor)?.focus()
				}, 300)
			} catch (e) {
				logger.error(e)
			}
		}
		anchorRef.current = anchor
	}, [anchor])
}

export const PricingListContextProvider = ({
	totalPricingPreference,
	children,
	errors,
}: PricingListContextProviderProps) => {
	const pricingItemsFieldArray = usePricingItemsFieldArray()
	const { getValues } = useFormContext()

	const { fields: pricingItems, insert, update } = pricingItemsFieldArray

	/** This ensure group's pricing items are considered  */
	const formStatePricingItems = [
		...pricingItems,
		...(getValues().pricingItems ?? []).filter(isGroup).flatMap((group) => group.deliverables),
	]

	const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
		arrayToRecord(formStatePricingItems, (item) => [getOrGenerateUUID(item), false]),
	)

	const registerExpandedState = (id: string, expanded = true) => {
		setExpandedState((state) => ({
			...state,
			[id]: expanded,
		}))
	}

	useOnPricingItemNavigate((itemIndex) =>
		setExpandedState((state) => {
			const flatPricingItems = getValues().pricingItems.flatMap((item) =>
				isGroup(item) ? item.deliverables : item,
			)
			return {
				...state,
				[getOrGenerateUUID(flatPricingItems[itemIndex])]: true,
			}
		}),
	)

	const getPricingItemIndex = (id: string): number => {
		const { pricingItems } = getValues()

		let currentIndex = 0

		for (const item of pricingItems) {
			if (!isGroup(item) && getOrGenerateUUID(item) === id) {
				return currentIndex
			} else if (isGroup(item)) {
				for (const groupItem of item.deliverables) {
					if (getOrGenerateUUID(groupItem) === id) {
						return currentIndex
					} else {
						currentIndex++
					}
				}
			} else {
				currentIndex++
			}
		}

		return -1
	}

	const duplicatePricingItem = (pricingItemIndex: number, pricingGroupIndex?: number) => {
		const { pricingItems } = getValues()
		const uuid = getOrGenerateUUID()

		if (typeof pricingGroupIndex !== 'undefined') {
			const currentGroup = pricingItems[pricingGroupIndex]
			if (isGroup(currentGroup)) {
				const { deliverables } = currentGroup

				const copiedPricingItem = cleanupDuplicatedDeliverable(deliverables[pricingItemIndex])

				update(pricingGroupIndex, {
					...currentGroup,
					deliverables: [
						...deliverables.slice(0, pricingItemIndex + 1),
						{
							...copiedPricingItem,
							uuid,
						},
						...deliverables.slice(pricingItemIndex + 1),
					],
				})

				registerExpandedState(uuid, false)
			}
		} else {
			const pricingItem = pricingItems[pricingItemIndex]
			if (isDeliverable(pricingItem)) {
				const copiedPricingItem = cleanupDuplicatedDeliverable(pricingItem)
				insert(pricingItemIndex + 1, {
					...copiedPricingItem,
					uuid,
				})

				registerExpandedState(uuid, false)
			}
		}
	}

	const duplicateGroup = (pricingGroupIndex: number) => {
		const { pricingItems } = getValues()

		const pricingGroup = pricingItems[pricingGroupIndex]

		if (isGroup(pricingGroup)) {
			const uuid = getOrGenerateUUID()
			const { deliverables } = pricingGroup
			const copiedDeliverables = deliverables
				.map(cleanupDuplicatedDeliverable)
				.map((deliverable) => ({
					...deliverable,
					uuid: getOrGenerateUUID(),
				}))

			insert(pricingGroupIndex + 1, {
				...pricingGroup,
				_id: undefined,
				uuid,
				deliverables: copiedDeliverables,
			})

			setExpandedState({
				...expandedState,
				uuid: false,
				...copiedDeliverables.reduce(
					(acc, { uuid }) => ({
						...acc,
						[uuid]: false,
					}),
					{},
				),
			})
		}
	}

	const getPricingGroupIndex = (id: string): number => {
		const { pricingItems } = getValues()

		let currentIndex = 0

		for (const item of pricingItems.filter(isGroup)) {
			if (getOrGenerateUUID(item) === id) {
				return currentIndex
			} else {
				currentIndex++
			}
		}

		return -1
	}

	return (
		<PricingListContext.Provider
			value={{
				expandedState,
				registerExpandedState,
				collapseAllItems: () => setExpandedState(mapRecord(expandedState, () => false)),
				expandAllItems: () => setExpandedState(mapRecord(expandedState, () => true)),
				getItemExpanded: (pricingItem) => expandedState[getOrGenerateUUID(pricingItem)],
				toggleItemExpanded: (pricingItem) => {
					setExpandedState(
						mapRecord(expandedState, (expanded, id) =>
							id === getOrGenerateUUID(pricingItem) ? !expanded : expanded,
						),
					)
				},
				duplicatePricingItem,
				totalPricingPreference,
				errors,
				pricingItemsFieldArray,
				getPricingGroupIndex,
				getPricingItemIndex,
				duplicateGroup,
			}}
		>
			{children}
		</PricingListContext.Provider>
	)
}

PricingListContextProvider.displayName = 'PricingItemListContextProvider'
