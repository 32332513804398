import { useEffect } from 'react'
import { Box, FormCheckbox, Tooltip } from '@persuit/ui-components'
import { useIsFirstRender } from '@persuit/ui-hooks'
import { useFormContext } from '../../pricing-form-utils'
import { ClientTotalPriceModel } from '../../types'

type AllowOtherPricingModelCheckboxProps = {
	originalAllowFirmPriceModel: boolean | undefined
	originalTotalPriceRequired: boolean | undefined
}

export const AllowOtherPricingModelCheckbox = ({
	originalAllowFirmPriceModel,
	originalTotalPriceRequired,
}: AllowOtherPricingModelCheckboxProps) => {
	const { watch, setValue } = useFormContext()

	const isFirstRender = useIsFirstRender()
	const totalPriceRequired = watch(`totalPriceRequired`)
	const totalPriceModel = watch(`totalPriceModel`)

	useEffect(() => {
		if (!isFirstRender && totalPriceModel === 'none') {
			setValue('isFirmAllowedToChangeTotalPriceModel', true)
		}
	}, [isFirstRender, setValue, totalPriceModel])

	const isInRfpRevisionMode = originalAllowFirmPriceModel && originalTotalPriceRequired
	const isDisabled =
		isInRfpRevisionMode ||
		shouldDisableAllowOtherPricingModelCheckbox(totalPriceModel, totalPriceRequired)

	const allowOtherPricingModelCheckbox = (
		<FormCheckbox
			label="Allow other pricing models"
			name={'isFirmAllowedToChangeTotalPriceModel'}
			color="primary"
			checkboxProps={{
				color: 'primary',
				inputProps: {
					'data-testid': 'allow-other-price-models',
				},
			}}
			disabled={isDisabled}
		/>
	)

	return (
		<Box width="40%">
			{isInRfpRevisionMode ? (
				<Tooltip
					title={
						'Allow other pricing models cannot be disabled as it was already enabled for the request.'
					}
				>
					<Box>{allowOtherPricingModelCheckbox}</Box>
				</Tooltip>
			) : (
				allowOtherPricingModelCheckbox
			)}
		</Box>
	)
}

const shouldDisableAllowOtherPricingModelCheckbox = (
	totalPricingPreference?: ClientTotalPriceModel | null,
	totalPriceRequired?: boolean | null,
) => {
	if (!totalPriceRequired) {
		return true
	}

	if (totalPricingPreference === 'none') {
		return true
	}

	return false
}
