import { Box, Typography, useTheme } from '@persuit/ui-components'
import { afterAuction, duringAuction } from '../../../../../common/auction-in-progress'
import { useStore, useActions } from '../../store'

import { RowHeaderCell, Row } from '../components'

import { ResponseHeaderCell } from './response-header-cell/response-header-cell'
import { IntelligenceHeaderCell } from './intelligence-header-cell'

import { Response } from '../types'
import { SortButton, SortOrder } from '../components/sort-button'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { requestValidForComparisonValue } from '@persuit/ui-shared-components'

export type ColumnHeaderRowProps = {
	requestObject: any
	handleSelectProposal: (proposal: Response | null) => void
	auctionInProgress: boolean
	showPriceType: 'totalPrice' | 'comparisonValue' | null
	compactHeader: boolean
}

type SortAttributes = 'firmName' | 'comparisonValue' | 'totalPrice'

export const ColumnHeaderRow = ({
	requestObject,
	handleSelectProposal,
	auctionInProgress,
	showPriceType,
	compactHeader,
}: ColumnHeaderRowProps) => {
	const theme = useTheme()
	const {
		isClient,
		request,
		proposalsForIntelligence,
		responses,
		showIntelligenceColumn,
		showExampleResponse,
		currentSort,
	} = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: s.displayedResponses(),
		isClient: !state.isFirm,
		request: state.request,
		proposalsForIntelligence: state.responses.filter(({ status }) => status !== 'revised'),
		showExampleResponse: state.showExampleResponse,
		currentSort: state.sort,
	}))
	const { toggles } = useFeatureToggles()
	const rateCardAuctionImprovementsToggle = toggles['dev-9900.rate-card-auction-improvements']

	// Returns boolean
	// Returns true if total price is required
	// AND
	// There is more than one response
	// AND (one of the following is true)
	// 1. An example response is being shown
	// 2. User is a firm person and an auction is in progress or is now over
	// 3. User is a client
	const shouldShowRank = () => {
		const { totalPriceRequired, deliverablesV2 } = request.detail
		// Responses can not be ranked if no deliverables are present
		const noDeliverables = !deliverablesV2 || deliverablesV2.length === 0

		if (!totalPriceRequired) {
			if (noDeliverables) return false
			if (!rateCardAuctionImprovementsToggle && !requestValidForComparisonValue(deliverablesV2))
				return false
		}

		if (showExampleResponse) return true

		//Check if responses have a rank property (added by backend)
		if (responses && responses.length > 0 && !!responses[0].rank) return true
		// Don't bother showing rank if there is only one response
		if (!(responses && responses.length > 1)) return false

		// During or after an auction
		const auctionTime = afterAuction(request) || duringAuction(request)

		return auctionTime || isClient
	}

	const showRank = shouldShowRank()

	const { toggleIsWideIntelligence, negotiateResponse } = useActions()
	const { isWideIntelligence } = useStore((state) => ({
		isWideIntelligence: state.isWideIntelligence,
	}))

	const {
		detail: { currency },
		auctionFirmVisibility,
	} = request

	const { setSort } = useActions()

	const sortOrderForAttribute = (attribute: SortAttributes) => {
		return currentSort?.type === attribute ? currentSort.order : null
	}

	const sortByAttribute = (order: SortOrder, attribute: SortAttributes) => {
		setSort({
			type: attribute,
			order,
		})
	}

	const responsiveStyles = {
		position: 'sticky',
		zIndex: 300,
		top: 0,
		'@media(max-height: 800px)': {
			position: 'relative',
			top: 0,
		},
	}

	return (
		<Row sx={{ zIndex: 500 }}>
			<RowHeaderCell
				cellProps={{
					'data-testid': 'firm-name-detail',
					component: 'td',
					sx: {
						left: 0,
						zIndex: 500,
						position: 'sticky',
						top: 0,
						'@media(max-height: 800px)': {
							top: 'unset',
						},
					},
					scope: undefined,
				}}
				sx={{
					border: `1px solid ${theme.palette.form.borderResting}`,
					backgroundColor: 'white',
				}}
			>
				{isClient && (
					<Box display="flex" flexDirection="column" justifyContent="space-around" height="100%">
						<Box display="flex" justifyContent="space-between" alignItems="center" height="100%">
							<Typography variant="body1Semibold">Firm name</Typography>
							<SortButton
								sortOrder={sortOrderForAttribute('firmName')}
								onClick={(order) => sortByAttribute(order, 'firmName')}
								isFirmNameSort={true}
							/>
						</Box>
						{compactHeader && showPriceType && (
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body1Semibold">
									{showPriceType === 'comparisonValue' ? 'Comp. value ' : 'Total price '}
									(default)
								</Typography>
								<SortButton
									sortOrder={sortOrderForAttribute(showPriceType)}
									onClick={(order) => sortByAttribute(order, showPriceType)}
								/>
							</Box>
						)}
					</Box>
				)}
			</RowHeaderCell>

			{showIntelligenceColumn && (
				<IntelligenceHeaderCell
					cellProps={{
						component: 'td',
						sx: { ...responsiveStyles },

						'aria-label': 'Proposal Analyzer with AI',
					}}
					sx={{
						...(isWideIntelligence ? { minWidth: '600px' } : {}),
					}}
					isWide={isWideIntelligence}
					toggleIsWide={toggleIsWideIntelligence}
					rfpId={request._id}
					proposals={proposalsForIntelligence}
				/>
			)}

			{responses.map((response, index, array) => {
				response.org
				const {
					_id: responseId,
					hasRevisionRequest,
					isEliminated,
					listing: requestId,
					org,
					rank,
					status,
					originalId,
					tiedForLowest,
					numResponses,
				} = response

				return (
					<ResponseHeaderCell
						key={responseId}
						auctionFirmVisibility={auctionFirmVisibility}
						auctionInProgress={auctionInProgress}
						cellProps={{
							'aria-label': org?.name,
							sx: {
								minHeight: '50px',
								...responsiveStyles,
							},
						}}
						currency={currency}
						handleNegotiateProposal={negotiateResponse}
						handleSelectProposal={handleSelectProposal}
						hasParent={!!originalId}
						hasProposalRevisionRequest={!!hasRevisionRequest}
						isEliminated={!!isEliminated}
						numResponses={numResponses}
						orgName={org?.name}
						orgId={org?._id}
						isNamwolfMember={org?.isNamwolfMember ?? false}
						rank={rank}
						request={request}
						requestId={requestId}
						requestObject={requestObject}
						response={response}
						responseId={responseId}
						responseStatus={status}
						showRank={showRank}
						showRightBorder={index === array.length - 1}
						tiedForLowest={tiedForLowest}
						showPriceType={showPriceType}
						compactHeader={compactHeader}
					/>
				)
			})}
		</Row>
	)
}
