import { Autocomplete, Divider, TextField, TextFieldProps, SxProps } from '@persuit/ui-components'
import { getOrGenerateUUID } from '@persuit/common-utils'

export type CountryOption = { code: string; name: string }

export type CountrySelectProps = {
	sx?: SxProps
	value: string | null
	options: CountryOption[]
	onChange: (country: CountryOption | null) => void
	TextFieldProps?: Partial<TextFieldProps>
}

export const CountrySelect = ({
	value,
	options,
	onChange,
	TextFieldProps,
	sx,
}: CountrySelectProps) => {
	const autocompleteId = `autocomplete-id-${getOrGenerateUUID()}`

	return (
		<Autocomplete
			sx={sx}
			data-testid="country-select"
			fullWidth={true}
			value={options.find(({ code }) => code === value)}
			id={autocompleteId}
			options={options}
			onChange={(_, newValue) => onChange(newValue || null)}
			renderInput={(params) => {
				const foundCountry = options.find(({ code }) => code === value)
				const selectedCountryNameText = foundCountry ? `${foundCountry.name} selected` : ''

				return (
					<TextField
						{...params}
						label="Select country"
						variant="outlined"
						required={true}
						inputProps={{
							...params.inputProps,
							'aria-owns': `${autocompleteId}-listbox`,
							'aria-label': `Select country. ${selectedCountryNameText}`,
						}}
						InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
						{...TextFieldProps}
					/>
				)
			}}
			getOptionLabel={({ name }) => name}
			renderOption={(props, option) => {
				if (option.name === 'divider') {
					return <Divider />
				}

				return <li {...props}>{option.name}</li>
			}}
		/>
	)
}
