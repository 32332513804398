import { PROPOSAL_STATES } from '../../../../common/data/rfp-proposal-states'
import { firmPhaseModel, firmTotalModel } from '../../../../common/data/pricing-models'
import { formatPriceWithCurrency, isNotNil } from '@persuit/common-utils'
import { TotalPriceOrComparisonValueDataType } from './types'

type Response = {
	status: string
	totalPriceModel?: string | null
	originalPricingModel?: string | null
	isPendingPrice?: boolean | null
	originalPrice?: string | null
	totalPriceValue?: string | null
}

export const totalPricePresentaionData = (
	response: Response,
	currency: string,
): TotalPriceOrComparisonValueDataType => {
	const {
		totalPriceModel,
		originalPricingModel,
		isPendingPrice,
		originalPrice,
		totalPriceValue,
		status,
	} = response

	const isExample = status === PROPOSAL_STATES.EXAMPLE
	const isDraft = status === PROPOSAL_STATES.DRAFT

	const shouldShowCurrentValue =
		isNotNil(totalPriceValue) &&
		totalPriceModel &&
		totalPriceModel !== firmTotalModel.NOTAPPLICABLE &&
		totalPriceModel !== firmPhaseModel.INCLUDED

	const shouldShowOriginalValue =
		shouldShowCurrentValue &&
		isNotNil(originalPrice) &&
		parseFloat(originalPrice) !== parseFloat(totalPriceValue) &&
		!isDraft

	const totalValue = () => {
		if (!shouldShowCurrentValue) return ''

		return formatPriceWithCurrency(currency, isExample ? 'XX,000' : totalPriceValue)
	}

	const originalValue = () => {
		if (!shouldShowOriginalValue) return ''

		if (originalPricingModel === firmTotalModel.NOTAPPLICABLE && parseFloat(originalPrice) === 0) {
			return 'Not Applicable'
		}

		return formatPriceWithCurrency(currency, originalPrice)
	}

	return {
		title: 'Total Price:',
		originalValue: originalValue(),
		currentValue: totalValue(),
		isPending: !!isPendingPrice,
	}
}
