// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { GridColumnMenuProps, GridColumnMenuContainer } from '@mui/x-data-grid-pro'

import {
	MenuItem,
	MenuItemProps,
	Typography,
	CheckIcon,
	ListItemText,
} from '@persuit/ui-components'

import { useActions, useStore, SortDirection, SortType, Field } from '../store'

import { SORT_LABELS } from './constants'

export function CustomColumnMenu(props: GridColumnMenuProps) {
	const { colDef } = props
	const field = colDef.field

	return (
		<GridColumnMenuContainer
			{...props}
			aria-labelledby={undefined}
			aria-label={`${colDef.headerName} column sort options`}
		>
			{field === 'firmName' ? (
				<FirmNameMenu {...props} />
			) : field === 'weightedAverage' ? (
				<WeightedAverageMenu {...props} />
			) : field.startsWith('timekeepers') ? (
				<TimekeeperMenu {...props} />
			) : null}
		</GridColumnMenuContainer>
	)
}

export function FirmNameMenu({ colDef }: GridColumnMenuProps) {
	const field = colDef.field as Field

	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<CustomMenuItem field={field} direction="asc" aria-label={SORT_LABELS.firmName.asc}>
				A - Z
			</CustomMenuItem>
			<CustomMenuItem field={field} direction="desc" aria-label={SORT_LABELS.firmName.desc}>
				Z - A
			</CustomMenuItem>
		</>
	)
}

export function WeightedAverageMenu({ colDef }: GridColumnMenuProps) {
	const field = colDef.field as Field

	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<CustomMenuItem field={field} direction="asc" aria-label={SORT_LABELS.weightedAverage.asc}>
				Lowest to Highest
			</CustomMenuItem>
			<CustomMenuItem field={field} direction="desc" aria-label={SORT_LABELS.weightedAverage.desc}>
				Highest to Lowest
			</CustomMenuItem>
		</>
	)
}

export function TimekeeperMenu({ colDef }: GridColumnMenuProps) {
	const field = colDef.field as Field

	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by Proposed Rate
			</Typography>

			<CustomMenuItem
				field={field}
				direction="asc"
				type="rate"
				aria-label={SORT_LABELS.timekeepers.rate.asc}
			>
				Lowest to Highest
			</CustomMenuItem>
			<CustomMenuItem
				field={field}
				direction="desc"
				type="rate"
				aria-label={SORT_LABELS.timekeepers.rate.desc}
			>
				Highest to Lowest
			</CustomMenuItem>

			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by Percentage Change
			</Typography>

			<CustomMenuItem
				field={field}
				direction="asc"
				type="percentage"
				aria-label={SORT_LABELS.timekeepers.percentage.asc}
			>
				Lowest to Highest
			</CustomMenuItem>
			<CustomMenuItem
				field={field}
				direction="desc"
				type="percentage"
				aria-label={SORT_LABELS.timekeepers.percentage.desc}
			>
				Highest to Lowest
			</CustomMenuItem>
		</>
	)
}

type CustomMenuItemProps = MenuItemProps & {
	field: Field
	direction: SortDirection
	type?: SortType
}

const CustomMenuItem = ({
	'aria-label': ariaLabel = '',
	direction,
	type = null,
	field,
	children,
	...rest
}: CustomMenuItemProps) => {
	const { toggleSortState } = useActions()

	const selected = useStore(
		({ sortState }) =>
			field === sortState?.field &&
			direction === sortState?.direction &&
			(!type || type === sortState?.type),
	)

	return (
		<MenuItem
			{...rest}
			selected={selected}
			aria-label={`${ariaLabel}, ${selected ? 'selected' : 'unselected'}`}
			onClick={() => toggleSortState({ field, direction, type })}
		>
			<ListItemText>{children}</ListItemText>
			{selected ? <CheckIcon /> : null}
		</MenuItem>
	)
}
