import { useFeatureToggles } from '@persuit/ui-feature-toggle'

import { AuctionAnalyticsOld } from './rfp-analytics-old'
import { RfpAnalytics as RfpAnalyticsNew } from './rfp-analytics'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box } from '@mui/material'
import { Typography } from '@persuit/ui-components'

type RfpAnalyticsProps = {
	request: any
	responses: any[]
	auctionInProgress: boolean
}

export const RfpAnalytics = ({ request, responses, auctionInProgress }: RfpAnalyticsProps) => {
	const { toggles, loading: loadingToggles } = useFeatureToggles()

	if (loadingToggles) {
		return null
	}

	const isCESEnabled = toggles['dev-5189.client-estimated-savings']

	return (
		<Box p={4}>
			<Typography variant="h1XSmall">Manage matter's analytics and savings</Typography>

			{isCESEnabled && <RfpAnalyticsNew request={request} responses={responses} />}

			<AuctionAnalyticsOld
				request={request}
				responses={responses}
				isCESEnabled={isCESEnabled}
				auctionInProgress={auctionInProgress}
			/>
		</Box>
	)
}
