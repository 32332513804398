import { Link } from 'react-router-dom'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Box, Typography } from '@persuit/ui-components'

// eslint-disable-next-line no-restricted-imports
import {
	GridFooterContainer,
	GridFooterContainerProps,
	selectedGridRowsCountSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'

export const FirmRecommenderLink = ({
	message,
	firmRecommenderIsEnabled = false,
}: {
	message: string
	firmRecommenderIsEnabled: boolean
}) => {
	return (
		<>
			{firmRecommenderIsEnabled ? (
				<Box sx={{ display: 'flex', alignItems: 'baseline' }}>
					<Typography variant="body2Semibold" sx={{ mr: 1 }}>
						{message}
					</Typography>
					<Link
						to="/en/firm-recommender"
						target="_blank"
						rel="noopener noreferrer"
						data-trackid="link-try-firm-recommender"
					>
						<Typography variant="body2SemiboldLink" color="primary.main">
							Try Firm Recommender
						</Typography>
					</Link>
				</Box>
			) : null}
		</>
	)
}

export const GridCustomFooter = (props: GridFooterContainerProps) => {
	const apiRef = useGridApiContext()
	const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector)
	const { toggles } = useFeatureToggles()
	const ftEnableFirmRecommender = toggles['dev-4604.firm-recommender-nav']
	return (
		<GridFooterContainer
			sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}
			{...props}
		>
			<Box>{`${selectedRowCount} ${selectedRowCount !== 1 ? 'firms' : 'firm'} selected`}</Box>
			<FirmRecommenderLink
				firmRecommenderIsEnabled={ftEnableFirmRecommender}
				message="Not sure which firms to invite?"
			/>
		</GridFooterContainer>
	)
}
