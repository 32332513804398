// @ts-strict-ignore
import getOr from 'lodash/fp/getOr'
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Button,
	Divider,
	SROnly,
	styled,
	Typography,
} from '@persuit/ui-components'
import { participateEvents } from '../../../../common/data/rfp-invite-events'
import { getOrEmptyObject, getOrEmptyString, getOrFalse } from '../../../utils/lenses'
import ManageFirm from '../../dialog-wizard/manage-firm'
import { hasFirmAnyAcceptedProposals } from '../../dialog-wizard/manage-firm/utils'
import isRequestReopenedForInvite from '../get-reopened-status-for-invite'
import { RequestReOpenStatus } from '../../status'
import RevisionRequired from '../../status/firm/revision-required-status'
import ThinSeparator from '../../thin-separator.jsx'
import NotesDialog from '../notes-dialog'
import NotesIcon from '../notes-dialog/notes-icon'
import RfpInvitedFirmProgressStepper from './invited-firm-progress-stepper'
import RfpInvitedFirmStatus from './rfp-invited-firm-status'
import RfpInvitedFirmNotParticipating from './rfp-invited-firm-not-participating'
import { ga } from '@persuit/ui-analytics'
import { ContactsAddedSectionPublishedFirmCard } from '@client/pages/new-rfp-form/invite-section/contacts-added-section'
import persuitTheme from '../../../theme/persuit-theme'
import { ListTypeLabel } from '@client/pages/panel-management/edit-list/edit-list-review/list-details'
import NamwolfLogo from '@client/components/namwolf/namwolf-logo'

const StyledTagsAndButtons = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 5px;
	width: 100%;
`

const StyledButtons = styled('div')`
	display: flex;
	align-items: center;
	gap: 8px;
`

export const RfpInvitedFirmCard = ({
	expanded,
	handleExpand,
	state: inviteState,
	item,
	proposals,
	rfp,
	invite,
	isPanelOrPreferredFirm = false,
	isApprovedFirm = false,
	userOrgId,
	userFavorites,
}) => {
	const hasClientAskedForProposalRevision = proposals.some(
		(proposal) => proposal.hasRevisionRequest === true,
	)

	const firmOrgId = getOrEmptyString('_id', item)

	const isEliminated = getOrFalse('eliminate.eliminated', inviteState) === true

	const participateState = getOrEmptyObject('participate', inviteState)
	const notParticipating =
		getOr(participateEvents.UNANSWERED, 'response', participateState) ===
		participateEvents.NOT_PARTICIPATING

	const firmHasAcceptedProposals = hasFirmAnyAcceptedProposals(item, rfp.proposals)

	return (
		<Accordion
			expanded={expanded}
			onChange={() => handleExpand(`${item._id}`)}
			sx={{ p: 2, width: '100%', boxShadow: persuitTheme.shadow }}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 2, width: '100%' }}>
				<Box>
					<Typography variant="h3XSmall" component="label" id={`accordion-label-${item._id}`}>
						{item.hasOwnProperty('name') ? item.name : ''}
						{item.isNamwolfMember && <NamwolfLogo style={{ marginLeft: '10px' }} />}
					</Typography>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<StyledTagsAndButtons>
							<Box sx={{ display: 'flex', gap: 1 }}>
								<ListTypeLabel label="Panel" showCondition={isPanelOrPreferredFirm} />
								<ListTypeLabel label="Approved" showCondition={isApprovedFirm} />
							</Box>
							<StyledButtons>
								<NotesDialog rfpId={rfp._id} firmId={item._id} firmName={item.name}>
									{({ handleOpenDialog }) => (
										<Button
											size="small"
											data-testid="notes-button"
											startIcon={<NotesIcon />}
											onClick={() => {
												ga({
													label: 'FEATURE_USAGE',
													page: 'CLIENT_RFP_FIRM_TAB',
													event: 'CLICK_NOTES_BUTTON',
												})
												handleOpenDialog()
											}}
										>
											Notes
										</Button>
									)}
								</NotesDialog>
								{/* Manage firm button */}
								{!firmHasAcceptedProposals && <ManageFirm rfp={rfp} firmOrg={item} />}
							</StyledButtons>
						</StyledTagsAndButtons>
						<AccordionSummary
							iconButtonLabel={(expanded) =>
								`${expanded ? `Collapse` : `Expand`} ${
									item.hasOwnProperty('name') ? item.name : ''
								}`
							}
							disableContainerClick={true}
							sx={{ '& .MuiIconButton-root': { p: 0 } }}
						>
							<SROnly>{item.hasOwnProperty('name') ? item.name : ''}</SROnly>
						</AccordionSummary>
					</Box>
				</Box>

				<ThinSeparator />

				<Box sx={{ display: 'flex', gap: 1 }}>
					{isRequestReopenedForInvite(rfp, firmOrgId) && (
						// Wrap the status with an inline-block div
						// This is a fix for IE11
						// Without this the status is full width and looks bad
						<div style={{ display: 'inline-block' }}>
							<RequestReOpenStatus />
						</div>
					)}
					{hasClientAskedForProposalRevision && !isEliminated && <RevisionRequired />}
				</Box>

				<Box sx={{ pb: 2 }}>
					<RfpInvitedFirmProgressStepper rfp={rfp} proposals={proposals} invite={invite} />
				</Box>

				{notParticipating && <RfpInvitedFirmNotParticipating participateState={participateState} />}
			</Box>
			<AccordionDetails>
				<Box sx={{ display: 'flex', flexDirection: 'column', px: 1, gap: 2 }}>
					<Divider />
					<ContactsAddedSectionPublishedFirmCard
						rfp={rfp}
						firmOrgId={firmOrgId}
						userOrgId={userOrgId}
						userFavorites={userFavorites}
					/>
					<RfpInvitedFirmStatus proposals={proposals || []} />
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}
