import { graphql, FragmentType, getFragment } from '@persuit/ui-graphql'
import { Box, CrownIcon, Typography } from '@persuit/ui-components'
import { CountryLabel, MatterTypeLabel, UseCaseLabel } from '@persuit/ui-shared-components'

const RfpFragment = graphql(`
	fragment TemplateTitleCard_RfpTemplate on RfpTemplatePublished {
		_id
		templateTitle
		templateType
		org {
			name
		}
		templateVendor
		useCase
		templateTags {
			matterType
			country
		}
	}
`)

type TitleCardProps = {
	templateFragment: FragmentType<typeof RfpFragment>
}

export const TitleCard = ({ templateFragment }: TitleCardProps) => {
	const { templateTitle, org, templateType, templateTags, useCase, templateVendor, _id } =
		getFragment(RfpFragment, templateFragment)
	const systemTemplate = templateType === 'system'

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between">
			<Box maxWidth="80%" display="flex" gap={1}>
				<Typography variant="body1Semibold" gutterBottom={true}>
					{templateTitle}
				</Typography>
				{useCase === 'rateReview' && <CrownIcon sx={{ color: 'premium.main', width: '17px' }} />}
			</Box>

			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box display="flex" gap={1}>
					<UseCaseLabel useCase={useCase} />
					<MatterTypeLabel matterType={templateTags?.matterType}></MatterTypeLabel>
					<CountryLabel country={templateTags?.country}></CountryLabel>
				</Box>
				<Typography id={`${_id}-vendor`}>
					Template by: <strong>{systemTemplate ? templateVendor ?? 'PERSUIT' : org?.name}</strong>
				</Typography>
			</Box>
		</Box>
	)
}
