import { createStore } from '@persuit/ui-state'
import { PricingItemLookup, RateItemLookup, Deliverable } from '../types'
import { RfpProposal } from '@persuit/ui-graphql/generated'
import { isEmpty } from 'lodash'
import { averageProposalRates, averageRate, clientPhaseModel } from '@persuit/common-logic'

import { isNotNil, isNotNilProp } from '@persuit/common-utils'

export type ProposalQuickRevisionStoreState = {
	deliverables: Deliverable[]
	proposal: Partial<
		Pick<RfpProposal, 'totalPriceModel' | 'totalPriceValue' | '_id' | 'comparisonValue'>
	>
	currency: string
	pricingItemLookup: PricingItemLookup
	rateItemLookup: RateItemLookup
	pricingItemFormState: Record<string, number>
	rateItemFormState: Record<string, number>
	totalPriceValue: number
	totalPriceNotRequired: boolean
}

type InitialStateInput = {
	deliverables?: Deliverable[]
	proposal?: Partial<
		Pick<RfpProposal, 'totalPriceModel' | 'totalPriceValue' | '_id' | 'comparisonValue'>
	>
	currency?: string
	pricingItemLookup?: PricingItemLookup
	rateItemLookup?: RateItemLookup
	pricingItemFormState?: Record<string, number>
	rateItemFormState?: Record<string, number>
	totalPriceValue?: number
	totalPriceNotRequired?: boolean
}

const isRatesItem = <T extends { pricingModel?: string }>({ pricingModel }: T) =>
	pricingModel === clientPhaseModel.RATECARD || pricingModel === clientPhaseModel.HOURLYRATE

const isQualifiedForAverageRateCalculation = <T extends { type?: string; pricingModel?: string }>({
	type,
	pricingModel,
}: T) => !type && isRatesItem({ pricingModel })

const store = createStore(
	({
		deliverables = [],
		proposal = {},
		currency = 'USD',
		pricingItemLookup = {},
		rateItemLookup = {},
		pricingItemFormState,
		rateItemFormState,
		totalPriceValue = 0,
		totalPriceNotRequired = false,
	}: InitialStateInput): ProposalQuickRevisionStoreState => ({
		deliverables,
		proposal,
		currency,
		pricingItemLookup,
		rateItemLookup,
		pricingItemFormState: pricingItemFormState ?? {},
		rateItemFormState: rateItemFormState ?? {},
		totalPriceValue,
		totalPriceNotRequired,
	}),
	(set) => ({
		setPricingItemFormState: (
			pricingItemFormState: ProposalQuickRevisionStoreState['pricingItemFormState'],
		) =>
			set({
				pricingItemFormState,
				totalPriceValue: pricingItemFormState.totalPriceValue,
			}),
		setRateItemFormState: (
			rateItemFormState: ProposalQuickRevisionStoreState['rateItemFormState'],
		) =>
			set({
				rateItemFormState,
				totalPriceValue: rateItemFormState.totalPriceValue,
			}),
		setTotalPrice: (totalPriceValue: number) =>
			set({
				totalPriceValue,
			}),
	}),
	{
		currentTotalPriceValue: ({
			totalPriceValue,
			proposal: { totalPriceValue: originalTotalPriceValue },
		}) => totalPriceValue || originalTotalPriceValue || 0,

		currentComparisonValue: ({
			pricingItemFormState,
			proposal: { comparisonValue: originalComparisonValue },
		}) =>
			!isEmpty(pricingItemFormState)
				? Object.entries(pricingItemFormState).reduce((sum, [key, value]) => {
						return key !== 'totalPriceValue' && typeof value === 'number' ? sum + value : sum
				  }, 0)
				: originalComparisonValue,

		currentAverageRate: ({ rateItemLookup, rateItemFormState }) => {
			const liveRates = Object.entries(rateItemLookup)
				.filter(([_, response]) => isQualifiedForAverageRateCalculation(response))
				// Mapping lookup rate to live rate
				.map(([key, { price, pricingModel, responseToRates, firmSuppliedRates }]) => {
					if (pricingModel === clientPhaseModel.RATECARD) {
						const combinedRates = [...(responseToRates || []), ...(firmSuppliedRates || [])]
							.map(({ _id, rate }) => ({
								rate: rateItemFormState[_id] || rate,
							}))
							.filter(isNotNil)
							.filter(isNotNilProp('rate'))

						return {
							rate: averageRate(combinedRates),
						}
					} else if (pricingModel === clientPhaseModel.HOURLYRATE) {
						return {
							rate: rateItemFormState[key] ?? price,
						}
					} else {
						return null
					}
				})
				.filter(isNotNil)

			return averageRate(liveRates)
		},

		originalAverageRate: ({ rateItemLookup }) => {
			const responses = Object.entries(rateItemLookup)
				.filter(([_, response]) => isQualifiedForAverageRateCalculation(response))
				.map(([_, response]) => response)

			return averageProposalRates({ responseToDeliverables: responses })
		},

		shouldDisplayAverageRateBanner: ({ rateItemLookup }) => {
			const responses = Object.entries(rateItemLookup).filter(([_, response]) =>
				isQualifiedForAverageRateCalculation(response),
			)

			return responses.length > 0
		},

		hasRatesItem: ({ rateItemLookup }) => {
			return Object.entries(rateItemLookup).some(([_, response]) => isRatesItem(response))
		},

		getWizardStepDetails: ({ rateItemLookup, pricingItemLookup, totalPriceNotRequired }) => {
			const steps = [
				{
					name: 'START',
					displayName: 'Select an action',
				},
			]

			if (
				(!totalPriceNotRequired && isEmpty(pricingItemLookup) && isEmpty(rateItemLookup)) ||
				!isEmpty(pricingItemLookup)
			) {
				steps.push({
					name: 'UPDATE_PRICES',
					displayName: 'Update total and items',
				})
			}

			if (!isEmpty(rateItemLookup)) {
				steps.push({
					name: 'UPDATE_RATES',
					displayName: 'Update rates & percentages',
				})
			}

			steps.push({
				name: 'CONFIRM',
				displayName: 'Review and confirm',
			})

			return steps.map((step, index) => ({
				index,
				...step,
			}))
		},
		changesMade: ({
			pricingItemFormState,
			pricingItemLookup,
			rateItemFormState,
			rateItemLookup,
			totalPriceValue,
			proposal: { totalPriceValue: originalTotalPriceValue },
		}) => {
			if (totalPriceValue !== (originalTotalPriceValue ?? 0)) {
				return true
			}

			if (
				Object.entries(pricingItemFormState).some(
					([key, value]) => pricingItemLookup[key] && pricingItemLookup[key].price !== value,
				)
			) {
				return true
			}

			if (
				Object.entries(rateItemFormState).some(
					([key, value]) => rateItemLookup[key] && rateItemLookup[key].price !== value,
				)
			) {
				return true
			}

			return false
		},
		noValidItems: ({ pricingItemLookup, rateItemLookup }) => {
			return isEmpty(pricingItemLookup) && isEmpty(rateItemLookup)
		},
	},
)

export const ProposalQuickRevisionStoreProvider = store.Provider
export const useStore = store.useStore
export const useProposalQuickRevisionStore = useStore
export const useActions = store.useActions
