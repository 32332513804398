import { gql, useMutation } from '@apollo/client'
import { styled, useSnackbar } from '@persuit/ui-components'
import { getOrEmptyArray } from '../../../../utils/lenses'
import FileList from '../../../form-inputs/file-list.jsx'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardContent from '../../dialog-wizard-content'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils'
import { ga } from '@persuit/ui-analytics'

const ADD_LOE_MUTATION = gql`
	mutation addLoeToProposal($proposalId: ID!, $fileIds: [ID]!) {
		addLoeToProposal(proposalId: $proposalId, fileIds: $fileIds)
	}
`

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

function OptionUploadFinalLoeConfirm({
	onCancel,
	onPrev,
	onDone,
	value,
	proposalId,
	fetchResponses,
}) {
	const [addLoeToProposal, { loading, error }] = useMutation(ADD_LOE_MUTATION)

	const files = getOrEmptyArray('files', value)

	const { openSnackbar } = useSnackbar()

	const onConfirm = async () => {
		await addLoeToProposal({
			variables: {
				proposalId,
				fileIds: files.map((file) => file._id),
			},
		})
		ga({
			label: 'FEATURE_USAGE',
			page: 'LOE',
			event: 'PROPOSAL_LOE_ATTACHED',
		})
		openSnackbar(`Attached ${files.length} file/s to the proposal`)
		onDone()
		fetchResponses()
	}

	return (
		<DialogWizardContent
			onCancel={onCancel}
			onPrev={onPrev}
			onConfirm={onConfirm}
			isWorking={loading}
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Upload Final Engagement Letter',
				[stepIndex(defaultSteps, 'ACTIONS')]: 'Attach Files',
			}}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Upload Final Engagement Letter</strong>
			<StyledList>
				<li>
					<Text type="body1" black={true}>
						Attaching the final engagement letter to this Proposal will allow the firm to view and
						download the letter.
					</Text>
				</li>
			</StyledList>
			<Spacer />
			<FileList files={files} />
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionUploadFinalLoeConfirm
