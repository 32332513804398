// @ts-strict-ignore
import {
	Box,
	Typography,
	PopoverHelp,
	Accordion,
	AccordionSummary,
	TrendingDownIcon,
} from '@persuit/ui-components'

import { AccordionContents } from './accordion-contents'

export const FirmAccordion = ({
	id,
	sx,
	handleChange,
	handleNext,
	orgName,
	proposals,
	isExpanded,
	handleValueChange,
	formState,
	accordionStatus,
	rfp,
}) => {
	return (
		<Accordion
			sx={sx}
			id={id}
			expanded={isExpanded}
			onChange={(expanded) => handleChange(orgName)(undefined, expanded)}
		>
			<AccordionSummary
				iconButtonLabel={(expanded) => `${expanded ? 'Collapse' : 'Expand'} details for ${orgName}`}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="body1Semibold">{orgName}</Typography>
					{!isExpanded && (
						<Typography sx={{ marginLeft: 'auto', pr: 1 }} color="text.secondary">
							{accordionStatus}
						</Typography>
					)}

					<PopoverHelp
						triggerIcon={TrendingDownIcon}
						triggerButtonProps={{
							'aria-label': 'More information on performance analytics coming soon',
						}}
						content={
							<>
								<Typography variant="body1Semibold" gutterBottom={true}>
									Performance analytics coming soon!
								</Typography>
								<Typography variant="body2">
									Continue to provide feedback on proposals. Overtime you will be able to see
									detailed analytics of your firm’s performance.
								</Typography>
							</>
						}
					/>
				</Box>
			</AccordionSummary>

			{proposals.map((proposal, index) => (
				<AccordionContents
					key={proposal._id}
					handleNext={index === proposals.length - 1 ? handleNext : null}
					proposal={proposal}
					handleValueChange={handleValueChange}
					formState={formState}
					orgName={orgName}
					rfp={rfp}
				/>
			))}
		</Accordion>
	)
}
