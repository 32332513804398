import { NestedAutocomplete, NestedAutocompleteProps } from '@persuit/ui-components'
import { LocationData } from './location-dropdown'

type Country = LocationData[number]

export type RegionDropdownProps = Omit<NestedAutocompleteProps, 'options' | 'label'> & {
	label?: string
	countryData: Country | null | undefined
}

export const RegionDropdown = ({ countryData, ...props }: RegionDropdownProps) => {
	return (
		<NestedAutocomplete
			label="Select state or city"
			{...props}
			options={countryData ? prepareFieldData(countryData) : []}
		/>
	)
}

function prepareFieldData(countryData: Country): NestedAutocompleteProps['options'] {
	const { code: countryCode, name: countryName, states } = countryData

	const stateData = states.map(({ code: stateCode, name: stateName, cities }) => ({
		_id: `entire-state-group-${stateCode}`,
		label: stateName,
		values: [
			{
				_id: stateCode,
				label: `All of ${stateName}`,
				values: [],
			},
			...cities.map(({ name: cityName, code: cityCode }) => ({
				_id: cityCode,
				label: cityName,
				values: [],
			})),
		],
		defaultExpanded: false,
	}))

	return [
		{
			_id: 'entire-country-group',
			label: 'Select entire country',
			values: [{ _id: countryCode, label: `All of ${countryName}`, values: [] }],
		},
		...stateData,
	]
}
