import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateUseCaseSaveMutation } from '@persuit/ui-graphql/hooks'
import { LoadingSpinner, useSnackbar } from '@persuit/ui-components'
import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { UseCaseForm } from '../../rfp-forms/use-case-form'
import { UseCase } from '@persuit/ui-graphql/generated'

graphql(`
	mutation RfpTemplateUseCaseSave($payload: RfpTemplateDraftUseCaseInput!, $validate: Boolean!) {
		saveRfpTemplateDraftUseCase(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type UseCaseSectionProps = {
	instanceId: string
	rfpTemplate: Pick<RfpTemplate, '_id' | 'useCase'>
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const UseCaseSection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
}: UseCaseSectionProps) => {
	const { openSnackbar } = useSnackbar()
	const [saveSectionMutation] = useRfpTemplateUseCaseSaveMutation()
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const changeUseCase = async (useCase: UseCase) => {
		await saveSectionMutation({
			variables: {
				payload: {
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
					useCase,
				},
				validate: sendMode,
			},
		})
		openSnackbar('Use case has been updated.')
	}

	if (loading) return <LoadingSpinner />

	return (
		<>
			<UseCaseForm
				changeUseCase={changeUseCase}
				useCase={rfpTemplate?.useCase ?? null}
				useSingleCurrency={true}
			></UseCaseForm>
		</>
	)
}
