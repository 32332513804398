import { ComponentType } from 'react'
import * as React from 'react'
import {
	SnackbarProvider as NotiSnackbarProvider,
	useSnackbar as useNotiSnackbar,
	OptionsObject,
} from 'notistack'

import { Grow } from '@mui/material'
import { GlobalStyles } from '../../theme'

type SnackbarProviderProps = {
	children: React.ReactNode
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
	return (
		<>
			<GlobalStyles
				styles={(theme) => ({
					'.snack-error': {
						backgroundColor: `${theme.palette.error.main} !important`,
					},
					'.snack-success': {
						backgroundColor: `${theme.palette.success.main} !important`,
					},
					'.snack-info': {
						backgroundColor: `${theme.palette.info.main} !important`,
					},
					'.snack-warning': {
						backgroundColor: `${theme.palette.warning.main} !important`,
					},
				})}
			/>
			<NotiSnackbarProvider
				classes={{
					variantError: 'snack-error',
					variantSuccess: 'snack-success',
					variantInfo: 'snack-info',
					variantWarning: 'snack-warning',
				}}
				TransitionComponent={Grow as any}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				role={undefined}
				domRoot={document.getElementById('global-alert') ?? undefined}
			>
				{children}
			</NotiSnackbarProvider>
		</>
	)
}

export const useSnackbar = () => {
	const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar()

	function openSnackbar(
		message: string,
		options: OptionsObject = {
			variant: 'success',
		},
	) {
		return enqueueSnackbar(message, options)
	}

	return { openSnackbar, closeSnackbar }
}

export function withSnackbar<T>(Component: ComponentType<T>) {
	const SnackbarComponent = (props: T) => {
		const { openSnackbar } = useSnackbar()
		return <Component openSnackbar={openSnackbar} {...props} />
	}
	SnackbarComponent.displayName = `withSnackBar(${Component.displayName})`
	return SnackbarComponent
}
