import { useTheme, Typography, Box, SROnly } from '@persuit/ui-components'
import { Deliverable } from '@persuit/ui-graphql/generated'
import { isNotNil } from '@persuit/common-utils'
import rfpProposalStates from '../../../../../common/data/rfp-proposal-states'

import { useStore, useActions } from '../../store'
import { PricingIntelligenceCell } from './pricing-intelligence-cell'

import { Response } from '../types'
import { Row, SectionHeaderRow } from '../components'
import {
	pricingSectionId,
	pricingGroupRowId,
	pricingRowId,
	pricingCellHeaders,
	pricingIntelligenceCellHeaders,
} from '../accessibilty'

import { PricingHeaderCell } from './pricing-header-cell'
import { PricingCell } from './pricing-cell'

export const PricingSection = () => {
	const theme = useTheme()
	const {
		request,
		responses,
		showPricingSection: expanded,
	} = useStore((state, s) => ({
		responses: s.displayedResponses(),
		request: state.request,
		showPricingSection: state.showPricingSection,
	}))

	const { toggleshowPricingSection } = useActions()

	const {
		detail: { deliverablesV2 },
	} = request

	let allDeliverablesCount = 0

	deliverablesV2.map((deliverable) =>
		deliverable.__typename === 'Deliverable'
			? allDeliverablesCount++
			: deliverable?.deliverables?.filter(isNotNil).map(() => allDeliverablesCount++),
	)

	const deliverablesAndGroups = deliverablesV2.filter(isNotNil)

	if (!deliverablesV2 || deliverablesV2.length === 0) {
		return null
	}

	let deliverableIndex = 0

	return (
		<Box component="tbody" aria-label="Pricing section">
			<SectionHeaderRow
				id={pricingSectionId}
				title="Pricing"
				expanded={expanded}
				onToggle={toggleshowPricingSection}
				collapsedText={`${allDeliverablesCount} items hidden`}
			/>

			{expanded &&
				deliverablesAndGroups.map((item) => {
					if (item.__typename === 'PricingGroup') {
						return (
							<>
								<Row sx={{ boxSizing: 'border-box' }}>
									<Box
										id={pricingGroupRowId(item._id) ?? ''}
										aria-label={item.title}
										component="th"
										colSpan={999}
										pt={2}
									>
										<Box
											sx={{
												display: 'flex',
												border: `1px solid ${theme.palette.form.borderActive}`,
												borderBottom: '0px',
												borderLeft: '0px',
												background: theme.palette.grey300,
											}}
										>
											<Box
												sx={{
													position: 'sticky',
													left: '0px',
													padding: '4px 12px',
													fontWeight: 'bolder',
													borderLeft: `1px solid ${theme.palette.form.borderActive}`,
													borderBottom: '0px',
													background: theme.palette.grey300,
												}}
											>
												<Typography variant="h3" fontSize="18px">
													<SROnly>Group: </SROnly>
													{item.title}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Row>

								{item.deliverables.map((deliverable, index, array) => (
									<PricingRow
										key={deliverable?._id}
										responses={responses}
										deliverable={deliverable}
										index={deliverableIndex++}
										groupTitle={item.title}
										groupId={item._id}
										lastInGroup={index === array.length - 1}
									/>
								))}
							</>
						)
					}

					return (
						<PricingRow
							key={item?._id}
							responses={responses}
							deliverable={item}
							index={deliverableIndex++}
						/>
					)
				})}
		</Box>
	)
}

export type PricingRowProps = {
	groupTitle?: string
	groupId?: string
	lastInGroup?: boolean
	responses: Array<Response>
	// deliverable: NonNullable<NonNullable<Request['detail']['deliverablesV2']>[number]>
	deliverable: Deliverable
	index: number
}

export const PricingRow = ({
	responses,
	deliverable,
	index,
	groupTitle,
	groupId,
	lastInGroup,
}: PricingRowProps) => {
	const theme = useTheme()
	const inGroup = !!groupTitle

	const { request, showIntelligenceColumn } = useStore((state, s) => ({
		showIntelligenceColumn: s.showIntelligenceColumn(),
		request: state.request,
	}))

	const borderStyle = `1px solid ${theme.palette.form.borderActive}`
	const hasMarginTop = !inGroup && index !== 0
	const commonStyles = {
		borderTop: borderStyle,
		borderBottom: (inGroup && lastInGroup) || !inGroup ? borderStyle : 'none',
	}

	return (
		<>
			{hasMarginTop ? (
				<Box component="tr" aria-hidden={true} sx={{ visibility: 'hidden' }}>
					<Box component="td" sx={{ pt: 2 }} />
				</Box>
			) : null}

			<Row>
				<PricingHeaderCell
					index={index}
					deliverable={deliverable}
					groupTitle={groupTitle}
					cellProps={{
						id: pricingRowId(deliverable._id),
						'aria-label': deliverable.deliverableTitle ?? undefined,
						scope: 'row',
						headers: pricingGroupRowId(groupId) ?? undefined,
					}}
					sx={{
						...commonStyles,
						borderLeft: borderStyle,
					}}
				/>

				{showIntelligenceColumn && (
					<PricingIntelligenceCell
						deliverable={deliverable}
						sx={commonStyles}
						headers={pricingIntelligenceCellHeaders({ deliverableId: deliverable._id, groupId })}
					/>
				)}

				{responses.map((response, responseIndex, array) => {
					const lastProposal = responseIndex + 1 === array.length

					const { _id: responseId, status, responseToDeliverables } = response

					const responseToDeliverableRankMap =
						response.__typename === 'Response'
							? (response.responseToDeliverableRanks ?? []).reduce<
									Record<string, { rank: number; tied: boolean }>
							  >((acc, { responseToDeliverableId, rank, tied }) => {
									acc[responseToDeliverableId] = { rank, tied }
									return acc
							  }, {})
							: {}

					const { _id: deliverableId, deliverableTitle, rates } = deliverable

					const responseToDeliverable =
						responseToDeliverables?.find((r) => r?.deliverableId === deliverableId) ?? null

					return (
						<PricingCell
							headers={pricingCellHeaders({ responseId, deliverableId, groupId })}
							key={responseId}
							responseId={responseId}
							sx={{
								...commonStyles,
								borderRight: lastProposal ? borderStyle : 'none',
							}}
							groupTitle={groupTitle}
							responseToDeliverable={responseToDeliverable}
							currency={request.detail.currency!}
							responseStatus={status}
							rates={rates?.filter(isNotNil) ?? []}
							index={index}
							deliverableTitle={deliverableTitle ?? ''}
							phaseDuration={request.phaseDuration}
							isProposalRevised={status === rfpProposalStates.REVISED}
							rank={responseToDeliverableRankMap[responseToDeliverable?._id ?? ''] ?? null}
						/>
					)
				})}
			</Row>
		</>
	)
}
