import { NavItem } from '@client/components/uam-nav/nav-components'
import { SettingsIcon } from '@persuit/ui-components'
import { useUser, useUAMEnabled } from '@persuit/ui-auth'

export type SettingsNavItemProps = {
	onClick: () => void
}

export function SettingsNavItem({ onClick }: SettingsNavItemProps) {
	const { can } = useUser()
	const { enabled, loading } = useUAMEnabled()
	const showSettings = !enabled || can('UPDATE_SETTINGS')

	if (loading) return null

	if (showSettings) {
		return (
			<NavItem to="/en/settings/" onClick={onClick} icon={<SettingsIcon />}>
				Settings
			</NavItem>
		)
	} else {
		return null
	}
}
