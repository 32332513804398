import { useStore } from '../../store'
import { StyledContainer } from '../styled-container'
import { PricingItem } from '../pricing-item'
import { HourlyRateItem } from '../hourly-rate-item'
import { RateCardList } from '../rate-card-list'
import { PricingItem as PricingItemType, Deliverable, CombinedRates } from '../../types'
import { isNotNil } from '@persuit/common-utils'
import { Box, SROnly, Typography } from '@persuit/ui-components'
import { GRID_TEMPLATE_COLUMNS } from '../constants'
import { getCombinedRates } from '../../utils'
import { PercentPricingItems } from '../percent-pricing-items'

type PricingListProps = {
	showPricingItem?: boolean
	showRateItem?: boolean
	editable?: boolean
	onlyShowChangedItems?: boolean
}

export const PricingList = ({
	showPricingItem,
	showRateItem,
	editable = true,
	onlyShowChangedItems = false,
}: PricingListProps) => {
	const {
		deliverables,
		currency,
		pricingItemLookup,
		rateItemLookup,
		pricingItemFormState,
		rateItemFormState,
	} = useStore(
		({
			deliverables,
			currency,
			pricingItemLookup,
			rateItemLookup,
			pricingItemFormState,
			rateItemFormState,
		}) => ({
			deliverables,
			currency,
			pricingItemLookup,
			rateItemLookup,
			pricingItemFormState,
			rateItemFormState,
		}),
	)

	const deliverableFragments = deliverables
		.filter(onlyShowChangedDeliverablePredicate)
		.reduce<Array<JSX.Element>>((acc, deliverable) => {
			if (deliverable.__typename === 'Deliverable') {
				const floatingItem = renderFloatingItem(deliverable)
				if (floatingItem) {
					acc.push(<StyledContainer>{floatingItem}</StyledContainer>)
				}
			} else if (deliverable.__typename === 'PricingGroup') {
				const groupItems = deliverable.deliverables
					.filter(onlyShowChangedDeliverablePredicate)
					.map(renderFloatingItem)
					.filter(isNotNil)

				if (groupItems.length > 0) {
					acc.push(<StyledContainer header={deliverable.title}>{groupItems}</StyledContainer>)
				}
			}
			return acc
		}, [])

	if (deliverableFragments.length === 0) {
		return null
	}

	return (
		<Box
			role="grid"
			aria-label={`${showPricingItem ? 'Individual pricing items' : ''}${
				showPricingItem && showRateItem ? ' and ' : ''
			}${showRateItem ? 'Rates' : ''}`}
		>
			<Box
				paddingLeft={1}
				paddingRight={1}
				display="grid"
				gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
				gridTemplateRows="auto"
				role="row"
			>
				<Box role="rowheader">
					<SROnly>Number</SROnly>
				</Box>
				<Box role="rowheader">
					<SROnly>Title</SROnly>
				</Box>
				<Box gridColumn={'3 / 5'} role="rowheader">
					<Typography variant="body2" color="text.secondary">
						{showPricingItem && showRateItem
							? 'Fee type / Rate name'
							: showPricingItem
							? 'Fee type'
							: 'Rate name'}
					</Typography>
				</Box>
				<Box gridColumn={5} role="rowheader">
					<Typography variant="body2" color="text.secondary">
						Current rate
					</Typography>
				</Box>
				<Box gridColumn={6} role="rowheader">
					<Typography variant="body2" color="text.secondary">
						Updated rate
					</Typography>
				</Box>
				<Box role="rowheader">
					<SROnly>Price change delta</SROnly>
				</Box>
			</Box>

			{deliverableFragments}
		</Box>
	)

	function renderFloatingItem(deliverable: PricingItemType) {
		if (showPricingItem && deliverable._id in pricingItemLookup) {
			return (
				<PricingItem
					key={deliverable._id}
					index={deliverable.index}
					pricingModel={pricingItemLookup[deliverable._id].pricingModel}
					originalPrice={pricingItemLookup[deliverable._id].price}
					title={deliverable?.deliverableTitle ?? ''}
					currency={currency}
					id={deliverable._id}
					editable={editable}
				/>
			)
		} else if (showRateItem && deliverable._id in rateItemLookup) {
			return renderRateOrPercentageItem(deliverable)
		}

		return null
	}

	function onlyShowChangedDeliverablePredicate(deliverable: Deliverable) {
		if (!onlyShowChangedItems) {
			return true
		}

		if (deliverable.__typename === 'PricingGroup') {
			return true
		}

		if (
			typeof pricingItemFormState[deliverable._id] !== 'undefined' &&
			pricingItemFormState[deliverable._id] !== pricingItemLookup[deliverable._id]?.price
		) {
			return true
		}

		const pricingItem = pricingItemLookup[deliverable._id] || rateItemLookup[deliverable._id]

		if (!pricingItem) {
			return false
		}

		// __typename === 'Deliverable' for below conditions
		const pricingModel = pricingItem.pricingModel
		if (pricingModel === 'hourlyRates') {
			// Rate card
			const hasAnyUpdatedRateItem = getCombinedRates(
				rateItemLookup[deliverable._id]?.responseToRates ?? [],
				rateItemLookup[deliverable._id]?.firmSuppliedRates ?? [],
				deliverable?.rates ?? [],
			).some(onlyShowChangedRatePredicate)

			if (hasAnyUpdatedRateItem) {
				return true
			}
		}

		if (['contingencyPercentage', 'discountPercentage'].includes(pricingModel)) {
			if (
				typeof rateItemFormState[deliverable._id] !== 'undefined' &&
				rateItemFormState[deliverable._id] !== rateItemLookup[deliverable._id].percentage
			) {
				return true
			}
		}

		if (pricingModel === 'hourlyRate') {
			if (
				typeof rateItemFormState[deliverable._id] !== 'undefined' &&
				rateItemFormState[deliverable._id] !== rateItemLookup[deliverable._id].price
			) {
				return true
			}
		}

		return false
	}

	function onlyShowChangedRatePredicate(rateItem: CombinedRates) {
		if (!onlyShowChangedItems) {
			return true
		}

		return (
			rateItem._id in rateItemFormState &&
			rateItem.rate !== null &&
			rateItemFormState[rateItem._id] !== rateItem.rate
		)
	}

	function renderRateOrPercentageItem(deliverable: PricingItemType) {
		const pricingItem = rateItemLookup[deliverable._id]
		const pricingModel = pricingItem.pricingModel

		// RATE CARD
		if (pricingModel === 'hourlyRates') {
			const combinedRates = getCombinedRates(
				pricingItem.responseToRates || [],
				pricingItem.firmSuppliedRates || [],
				deliverable?.rates ?? [],
			).filter(onlyShowChangedRatePredicate)

			return (
				<RateCardList
					index={deliverable.index}
					pricingModel={pricingModel}
					title={deliverable?.deliverableTitle ?? ''}
					combinedRates={combinedRates}
					currency={currency}
					id={deliverable._id}
					key={deliverable._id}
					editable={editable}
					showAverageRate={!onlyShowChangedItems}
				/>
			)
		}

		if (['contingencyPercentage', 'discountPercentage'].includes(pricingModel)) {
			return (
				<PercentPricingItems
					key={deliverable._id}
					index={deliverable.index}
					pricingModel={pricingModel}
					originalPercentage={pricingItem.percentage || 0}
					title={deliverable?.deliverableTitle ?? ''}
					id={deliverable._id}
					editable={editable}
				/>
			)
		}

		return (
			<HourlyRateItem
				key={deliverable._id}
				index={deliverable.index}
				pricingModel={pricingModel}
				originalPrice={pricingItem.price ?? 0}
				title={deliverable?.deliverableTitle ?? ''}
				currency={currency}
				id={deliverable._id}
				editable={editable}
			/>
		)
	}
}
