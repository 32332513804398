import { useEffect, useState } from 'react'
import { CustomTrackingFieldList } from './custom-tracking-fields'
import {
	FormTextField,
	Box,
	PopoverHelp,
	Form,
	Typography,
	Button,
	SyncIcon,
	Spacer,
	LoadingSpinner,
	Chip,
	Alert,
} from '@persuit/ui-components'
import { FragmentType, getFragment, graphql, useQuery } from '@persuit/ui-graphql'
import { NewRfpForm_RfpFragment } from '@persuit/ui-graphql/generated'
import {
	SyncTrackingFieldsWizard,
	SyncTrackingFieldsWizardProps,
} from './sync-tracking-fields-wizard'
import { isNotNil, omitDeepArray } from '@persuit/common-utils'
import { TrackingFormValues } from './form-utils'
import { isValid } from 'date-fns'
import { useSectionalForm } from '../../sectional-form'

const CustomTrackingFieldFragment = graphql(`
	fragment TrackingFormCustomTrackingFields on ICustomTrackingField {
		_id
		name
		description
		apiMatchKey
		fieldType
		isArchived
		mandatory
		__typename
		... on CustomTrackingFieldHierarchy {
			_id
			fieldType
			name
			description
			isArchived
			mandatory
			apiMatchKey
			values {
				_id
				value
				apiMatchKey
				isArchived
				values {
					_id
					value
					apiMatchKey
					isArchived
				}
			}
			__typename
		}
	}
`)

const GET_OWN_ORG_DATA = graphql(`
	query TrackingFormGetOrgData {
		getOwnOrg {
			_id
			externalApiMatterSync {
				trackingFieldSyncEnabled
				externalSystemName
				searchUsingMatterType
				updateMatterReference
			}
		}
	}
`)

type TrackingFormProps = {
	showTitle?: boolean
	showMatterReference?: boolean
	autoSave?: boolean
	saveSection: (input: { tracking: TrackingFormValues }) => Promise<void>
	trackingData: NonNullable<NewRfpForm_RfpFragment['tracking']>
	trackingErrors: {
		message: string
		index: number
	}[]
	customTrackingFields: FragmentType<typeof CustomTrackingFieldFragment>[]
	rfpId?: string
	handleSync?: SyncTrackingFieldsWizardProps['handleSync']
	preview?: boolean
}

export const TrackingForm = ({
	showTitle = true,
	showMatterReference = true,
	autoSave = true,
	saveSection,
	trackingData,
	trackingErrors,
	handleSync,
	rfpId,
	preview = false,
	...props
}: TrackingFormProps) => {
	const { data: ownOrgData, loading: ownOrgLoading } = useQuery(GET_OWN_ORG_DATA)
	const [showSyncMatterPanel, setShowSyncMatterPanel] = useState(false)

	const customTrackingFields = getFragment(
		CustomTrackingFieldFragment,
		props.customTrackingFields,
	).filter(isNotNil)

	const methods = useSectionalForm<TrackingFormValues>(
		{
			referenceNo: trackingData?.referenceNo || '',
			customFields: trackingData.customFields,
			tracking: {
				customFields: trackingData?.customFields?.filter(isNotNil) || [],
			},
		},
		async (values) => {
			const customFields = (customTrackingFields ?? []).map((field, index) => {
				const dataIndex = values.customFields?.findIndex(
					(fieldData) => fieldData?.customTrackingFieldId === field._id,
				)

				const fieldValue =
					typeof dataIndex !== 'undefined' && dataIndex !== -1
						? values?.tracking?.customFields?.[dataIndex]
						: values?.tracking?.customFields?.[index]

				// Convert date to ISO prior to submitting to server
				if (field.fieldType === 'DATE' && fieldValue?.dateValue && isValid(fieldValue.dateValue)) {
					fieldValue.dateValue = new Date(fieldValue.dateValue).valueOf()
				}

				return {
					...fieldValue,
					customTrackingFieldId: field._id,
					fieldType: field.fieldType,
				}
			})

			const fieldsWithoutTypename = omitDeepArray(customFields, '__typename')
			const customFieldsCleaned = omitDeepArray(
				fieldsWithoutTypename,
				'_id' as any,
			) as typeof fieldsWithoutTypename

			await saveSection({
				tracking: {
					customFields: customFieldsCleaned,
					referenceNo: values.referenceNo ?? '',
				},
			})
		},
		undefined,
		{
			autoSave,
		},
	)

	useEffect(() => {
		methods.reset({
			referenceNo: trackingData?.referenceNo || '',
			customFields: trackingData.customFields,
			tracking: {
				customFields: trackingData?.customFields?.filter(isNotNil) || [],
			},
		})
	}, [showSyncMatterPanel, methods])

	const toggleSyncWizardVisibility = (isVisible: boolean) => {
		setShowSyncMatterPanel(isVisible)
	}

	if (ownOrgLoading) return <LoadingSpinner />

	const externalApiMatterSyncData = ownOrgData?.getOwnOrg?.externalApiMatterSync
	const trackingFieldSyncEnabled = externalApiMatterSyncData?.trackingFieldSyncEnabled ?? false
	const externalSystemName = externalApiMatterSyncData?.externalSystemName ?? 'External Matter'
	const searchUsingMatterType = externalApiMatterSyncData?.searchUsingMatterType ?? false
	const updateMatterReference = externalApiMatterSyncData?.updateMatterReference ?? false

	return (
		<Form methods={methods}>
			<Box display="flex" flexDirection="column" gap={2}>
				{showTitle && (
					<Box display="flex" gap={3} alignItems="center">
						<Typography variant="h1XSmall">Tracking</Typography>
						<Chip label="INTERNAL USE ONLY" color="primary" />
					</Box>
				)}

				<Alert severity="info">
					<Typography>
						Help your team organize and find requests, as well as improving reporting and analytics.
					</Typography>
					<Typography>You'll still be able to modify the tracking fields after sending.</Typography>
				</Alert>

				<Spacer />

				{trackingFieldSyncEnabled && !preview && (
					<>
						<Typography variant="body1Semibold">Sync from {externalSystemName}</Typography>
						<Box display="flex">
							<Box>
								<Typography variant="body2" color="text.secondary">
									Quickly sync matter details from your organisation's chosen external matter
									management application.
								</Typography>
							</Box>
							<Spacer space={6} shape="column" />
							<Button
								data-testid="sync-from-external-matter-button"
								data-trackid="button-sync-from-external-matter"
								startIcon={<SyncIcon />}
								onClick={() => toggleSyncWizardVisibility(true)}
								color="primary"
								variant="outlined"
								sx={{ minWidth: 300, maxHeight: 38 }}
							>
								Sync from {externalSystemName}
							</Button>
						</Box>
					</>
				)}
				{showSyncMatterPanel && handleSync && rfpId && (
					<SyncTrackingFieldsWizard
						rfpId={rfpId}
						handleClose={() => toggleSyncWizardVisibility(false)}
						handleSync={handleSync}
						externalSystemName={externalSystemName}
						searchUsingMatterType={searchUsingMatterType}
						updateMatterReference={updateMatterReference}
					/>
				)}

				{!showSyncMatterPanel && (
					<Box display="flex" flexDirection="column" justifyContent="center">
						<Typography variant="body1Semibold">Custom tracking fields</Typography>
						<Typography variant="body2" color="text.secondary">
							These fields have been created on PERSUIT.
						</Typography>
						<Spacer space={4} />
						{showMatterReference && (
							<Box display="flex" alignItems="baseline">
								<FormTextField
									id="referenceNo"
									name="referenceNo"
									label="Matter Reference"
									fullWidth={true}
								/>
								<PopoverHelp
									content={
										<Typography variant="body1">
											Enter a matter reference number (if applicable). This could be an internal
											reference that helps track this request in PERSUIT.
										</Typography>
									}
									triggerButtonProps={{
										'aria-label': 'Matter reference number help',
									}}
								/>
							</Box>
						)}

						{customTrackingFields && (
							<CustomTrackingFieldList
								customTrackingFields={customTrackingFields}
								fieldNamePrefix="tracking.customFields"
								errors={trackingErrors ?? []}
							/>
						)}
					</Box>
				)}
			</Box>
		</Form>
	)
}
