import { useContext, createContext, useRef, useEffect, useState, useId, ReactNode } from 'react'
import {
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps,
	DialogTitle as MuiDialogTitle,
	DialogTitleProps as MuiDialogTitleProps,
	DialogContent as MuiDialogContent,
	DialogContentProps as MuiDialogContentProps,
	DialogActions,
} from '@mui/material'
import { CloseIcon } from '../../icons'
import { Button, ButtonProps } from '../button'
import { IconButton, IconButtonProps } from '../icon-button'

type ContextValue = {
	titleId?: string
	scroll: DialogProps['scroll']
}

const DialogContext = createContext<ContextValue>({ titleId: undefined, scroll: undefined })

const useDialogContext = () => useContext(DialogContext)
const Provider = DialogContext.Provider

export type DialogSize = 'xs' | 'sm' | 'md' | 'lg'

/**
 * Please use an array of DialogActionButtons to define the actions for consistency and better type safety.
 * Example:
 * actions={[
 *   { variant: 'text', label: 'Cancel', onClick: handleCancel }
 *   { variant: 'primary, label: 'Save', onClick: handleSave },
 * ]}
 */

export type DialogProps = {
	size: DialogSize
	title: ReactNode
	children: ReactNode
	actions?: DialogActionButtons[] | ReactNode
	onClose: () => void
} & Omit<MuiDialogProps, 'onClose' | 'title'>

export type DialogActionButtons = {
	variant: ButtonProps['variant']
	label: string
	onClick?: () => void
} & Partial<ButtonProps>

export const Dialog = ({ open, onClose, title, children, actions, ...props }: DialogProps) => {
	const titleId = useId()
	const contentRef = useRef<ReactNode | null>(null)

	const [shouldDisableEnforceFocus, setShouldDisableEnforceFocus] = useState(false)

	/** In order for MUI Dialog to play well with TinyMCE, we need to disable focus trap */
	useEffect(() => {
		const intervalId = setInterval(() => {
			if (!shouldDisableEnforceFocus) {
				const dialogNode = document.querySelector('.tox-dialog-wrap')

				if (dialogNode) {
					setShouldDisableEnforceFocus(true)
				}
			}
		}, 100)

		return () => {
			clearInterval(intervalId)
		}
	}, [shouldDisableEnforceFocus])

	/** In order for MUI Dialog to not hide global alert */
	useEffect(() => {
		if (open) {
			const globalAlertId = document.getElementById('global-alert')

			if (globalAlertId) globalAlertId.setAttribute('aria-hidden', 'false')
		}
	}, [open])

	/** In order for MUI Dialog to show closing transition with dynamic content we cache the children */
	if (open) {
		contentRef.current = children
	}

	return (
		<Provider value={{ scroll: props.scroll ?? 'paper', titleId }}>
			<MuiDialog
				open={open}
				onClose={onClose}
				aria-labelledby={titleId}
				maxWidth={'xxl'}
				PaperProps={{
					sx: {
						containerType: 'inline-size',
					},
				}}
				{...props}
				disableEnforceFocus={shouldDisableEnforceFocus || props.disableAutoFocus}
			>
				<DialogTitle sx={{ maxWidth: '90%' }}>{title}</DialogTitle>
				<DialogClose onClick={onClose} />
				{children && (
					<DialogContent sx={{ paddingBottom: actions ? 2 : 4 }}>
						{contentRef.current}
					</DialogContent>
				)}
				{actions && (
					<DialogActions disableSpacing={true}>
						{Array.isArray(actions)
							? actions.map(({ variant, label, onClick, ...buttonProps }) => (
									<Button key={label} onClick={onClick} variant={variant} {...buttonProps}>
										{label}
									</Button>
							  ))
							: actions}
					</DialogActions>
				)}
			</MuiDialog>
		</Provider>
	)
}

type DialogTitleProps = MuiDialogTitleProps

const DialogTitle = (props: DialogTitleProps) => {
	const { titleId } = useDialogContext()
	return <MuiDialogTitle id={titleId} variant="h2XSmall" {...props} />
}

type DialogContentProps = MuiDialogContentProps

const DialogContent = (props: DialogContentProps) => {
	const { scroll, titleId } = useDialogContext()
	const containerRef = useRef<HTMLElement>()
	const [scrollable, setScrollable] = useState(false)
	const labelId = useId()
	const shouldHandleScroll = scroll === 'paper'

	const addScrollContainer = scrollable && shouldHandleScroll

	useEffect(() => {
		const container = containerRef.current
		if (!shouldHandleScroll || !container) return

		const resizeCallback = () => {
			if (!containerRef.current) return
			const element = containerRef.current

			const { scrollHeight, clientHeight } = element
			const scrollable = scrollHeight > clientHeight
			setScrollable(scrollable)
		}

		const observer = new ResizeObserver(resizeCallback)

		for (const child of container.children) {
			observer.observe(child)
		}

		const element = containerRef.current!
		observer.observe(element)

		return () => observer.disconnect()
	}, [shouldHandleScroll])

	return (
		<>
			<span style={{ display: 'none' }} id={labelId}>
				Scrollable content area for
			</span>
			<MuiDialogContent
				ref={containerRef}
				aria-labelledby={`${scrollable ? `${labelId} ` : ''}${titleId}`}
				tabIndex={addScrollContainer ? 0 : undefined}
				{...props}
			/>
		</>
	)
}

type DialogCloseProps = IconButtonProps

const DialogClose = (props: DialogCloseProps) => {
	return (
		<IconButton
			aria-label="close dialog"
			{...props}
			size="small"
			sx={{
				position: 'absolute',
				top: '16px',
				right: '16px',
				mt: '-5px',
				mr: '-5px',
				...props?.sx,
			}}
		>
			<CloseIcon />
		</IconButton>
	)
}
