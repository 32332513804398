export default function typeSafeFloat(val) {
	if (val !== undefined && val !== null) {
		const valAsInt = parseFloat(val, 10)
		// Ensure the result of parseInt is not NaN
		if (!isNaN(valAsInt)) {
			return valAsInt
		}
	}

	return null
}
