import cond from 'lodash/fp/cond'
import constant from 'lodash/fp/constant'
import matches from 'lodash/fp/matches'
import stubTrue from 'lodash/fp/stubTrue'
import isBefore from 'date-fns/isBefore'
import i18next from 'i18next'
import DueDateTicker from '../../due-date-ticker'
import ClientStatusTypes from '../client-status-types'

const getFinalizedStatusTertiaryText = ({
	declinedCount,
	totalInviteCount,
	totalAcceptedProposalCount,
}) => {
	if (declinedCount === totalInviteCount) {
		return 'All firms unable to participate'
	}

	if (totalAcceptedProposalCount >= 1) {
		return 'Firm selected'
	}

	return null
}

const getEvaluatingStatusTertiaryText = ({
	declinedCount,
	totalInviteCount,
	totalAcceptedProposalCount,
	totalSubmittedProposalCount,
}) => {
	if (declinedCount === totalInviteCount) {
		return 'All firms unable to participate'
	}

	if (totalAcceptedProposalCount >= 1) {
		return 'Firm selected'
	}

	if (totalSubmittedProposalCount === 0) {
		return 'No proposals received'
	}

	return null
}

const getEvaluatingStatusSecondaryText = ({ reOpenInvitesCount }) =>
	reOpenInvitesCount > 0
		? i18next.t('reopenIndividually.client.statusText', { count: reOpenInvitesCount })
		: null

const getCompletedStatusTertiaryText = ({
	declinedCount,
	totalInviteCount,
	totalAcceptedProposalCount,
	totalSubmittedProposalCount,
}) => {
	if (declinedCount === totalInviteCount) {
		return 'All firms unable to participate'
	}

	if (totalAcceptedProposalCount >= 1) {
		return 'Firm selected'
	}

	if (totalSubmittedProposalCount === 0) {
		return 'No proposals received'
	}

	return null
}

const getWithdrawnSecondaryText = ({ totalSubmittedProposalCount }) => {
	if (totalSubmittedProposalCount >= 1) {
		return 'Proposals recieved'
	}

	if (totalSubmittedProposalCount === 0) {
		return 'No proposals recieved'
	}

	return null
}

const getNonAuctionProps = ({
	requestStatus,
	updatedAt,
	proposalsDueBy,
	totalAcceptedProposalCount,
	totalSubmittedProposalCount,
	totalInviteCount,
	declinedCount,
	reOpenInvitesCount,
}) =>
	cond([
		[
			matches({ requestStatus: ClientStatusTypes.DRAFT }),
			constant({ status: requestStatus, statusLine2: updatedAt }),
		],
		[
			matches({ requestStatus: ClientStatusTypes.OPEN_TO_PROPOSALS }),
			constant({
				status: requestStatus,
				statusLine2: isBefore(new Date(), new Date(proposalsDueBy)) ? (
					<DueDateTicker dueDate={proposalsDueBy} />
				) : null,
				statusLine3: getFinalizedStatusTertiaryText({
					declinedCount,
					totalInviteCount,
					totalAcceptedProposalCount,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.EVALUATING }),
			constant({
				status: requestStatus,
				statusLine2: getEvaluatingStatusSecondaryText({
					reOpenInvitesCount,
				}),
				statusLine3: getEvaluatingStatusTertiaryText({
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					totalInviteCount,
					declinedCount,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.COMPLETED }),
			constant({
				status: requestStatus,
				statusLine3: getCompletedStatusTertiaryText({
					totalAcceptedProposalCount,
					totalSubmittedProposalCount,
					totalInviteCount,
					declinedCount,
				}),
			}),
		],
		[
			matches({ requestStatus: ClientStatusTypes.WITHDRAWN }),
			constant({
				status: requestStatus,
				statusLine2: getWithdrawnSecondaryText({
					totalSubmittedProposalCount,
				}),
			}),
		],
		[
			stubTrue,
			constant({
				status: '',
			}),
		],
	])({
		requestStatus,
	})

export default getNonAuctionProps
