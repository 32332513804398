import { ReactNode } from 'react'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { useLocation, Redirect } from 'react-router-dom'
import { useUser } from '@persuit/ui-auth'
import preferredAuthMethods from '../../../common/auth/auth-methods'

export const ONBOARDING_AUTH_METHODS = [
	preferredAuthMethods.SAML,
	preferredAuthMethods.GOOGLE,
	preferredAuthMethods.MICROSOFT,
]

function isOnboardingMethod(value: unknown): boolean {
	return ONBOARDING_AUTH_METHODS.includes(value as string)
}

type OnBoardingRedirectFn = {
	authMethod: string | null
	isClientInfoSliderEnabled: boolean
	isSkipped: boolean
	isImpersonated: boolean
	isOnboarded: boolean
	orgType: 'CLIENT' | 'FIRM'
	pathname: string
	hasTeam: boolean
}

export const onBoardingRedirect = ({
	isClientInfoSliderEnabled,
	isSkipped,
	isImpersonated,
	isOnboarded,
	orgType,
	pathname,
	authMethod,
	hasTeam,
}: OnBoardingRedirectFn) => {
	function redirect(newPath: string) {
		return newPath !== pathname ? newPath : null
	}

	const isSSOPage = pathname.startsWith('/en/onboarding')
	const noSlider = !isClientInfoSliderEnabled || isSkipped
	const isClient = orgType === 'CLIENT'

	if (!noSlider && isClient && !isOnboarded) return redirect('/en/getting-started/')

	// Impersonated users dont need to go through any onboarding
	if (isImpersonated) return null

	// SSO page redirects
	if (orgType === 'CLIENT' && isOnboardingMethod(authMethod)) {
		// Onboarding complete
		if (hasTeam && isOnboarded && isSSOPage) return redirect('/en/')
		// Finish onboarding at profile page
		if (hasTeam && !isOnboarded) return redirect('/en/myprofile/')
		// User doesn't have a team need to wait for onboarding
		if (!hasTeam && isSSOPage) return null
		if (!hasTeam && isOnboarded) return redirect('/en/onboarding/confirmation/')
		if (!hasTeam && !isOnboarded) return redirect('/en/onboarding/')
	}

	// For autoregistered users force them to set their name and company name
	// force user to the myprofile page
	if (!isOnboarded) return redirect('/en/myprofile/')

	// Should not be on SSO onboarding page at this point
	if (isSSOPage) return redirect('/en/')

	return null
}

export type OnboardingRedirectProps = {
	children: ReactNode
	user: {
		isOnboarded: boolean
		isImpersonated: boolean
		org: {
			orgType: 'CLIENT' | 'FIRM'
		}
	}
}

export const OnboardingRedirect = ({ children, user }: OnboardingRedirectProps) => {
	const { pathname } = useLocation()
	const { toggles, loading: loadingToggles } = useFeatureToggles()
	const {
		isOnboarded,
		isImpersonated,
		org: { orgType },
	} = user

	const { user: authUser, loading: loadingUser } = useUser()

	const loading = loadingToggles || loadingUser
	const isClientInfoSliderEnabled = toggles['dev-2838.info-slider'] ?? false

	if (loading) {
		return null
	}

	const redirectPath = onBoardingRedirect({
		hasTeam: !!authUser?.team,
		authMethod: authUser?.authMethod ?? preferredAuthMethods.BASIC,
		isClientInfoSliderEnabled,
		isSkipped: sessionStorage.getItem('skipSlider') === 'yes',
		isImpersonated: !!isImpersonated,
		isOnboarded,
		orgType,
		pathname,
	})

	if (redirectPath) {
		return <Redirect to={redirectPath} />
	}

	return <>{children}</>
}
