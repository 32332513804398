import { useEffect } from 'react'
import { Box, Tooltip, Typography } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import {
	GridColType,
	GridColumnHeaderParams,
	GridSortDirection,
	gridSortModelSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import { useDescription } from '@persuit/ui-hooks'
import { getSortForField } from '../utils'

const SORT_LABELS: Record<
	| Exclude<GridColType, 'boolean' | 'date' | 'dateTime' | 'singleSelect' | 'actions'>
	| 'rank'
	| 'seniority',
	Record<Exclude<GridSortDirection, null | undefined>, string>
> = {
	string: {
		asc: 'Sorted in ascending alphabetical order (A to Z)',
		desc: 'Sorted in descending alphabetical order (Z to A)',
	},
	number: {
		asc: 'Sorted from lowest to highest',
		desc: 'Sorted from highest to lowest',
	},
	rank: {
		asc: 'Sorted from lowest to highest',
		desc: 'Sorted from highest to lowest',
	},
	seniority: {
		asc: 'Sorted from lowest to highest',
		desc: 'Sorted from highest to lowest',
	},
}

const getSortDescription = (
	columnType:
		| Exclude<GridColType, 'boolean' | 'date' | 'dateTime' | 'singleSelect' | 'actions'>
		| 'rank'
		| 'seniority' = 'string',
	columnSortState: GridSortDirection,
): string => {
	if (!columnSortState) return 'unsorted'
	else return SORT_LABELS[columnType][columnSortState]
}

export const GridCustomHeaderCell = ({ field, colDef }: GridColumnHeaderParams) => {
	const apiRef = useGridApiContext()
	const sortModel = useGridSelector(apiRef, gridSortModelSelector)
	const sortState = getSortForField(sortModel, field)

	const menuInstructionsId = useDescription('Press CTRL + Enter to open column menu')
	const label = `${colDef.headerName}, ${getSortDescription(colDef.type, sortState)}`

	useEffect(() => {
		const columnHeader = document.querySelector(`[role="columnheader"][data-field="${field}"]`)
		columnHeader?.setAttribute('aria-describedby', menuInstructionsId)

		const sortIconButton = columnHeader?.querySelector(`[aria-label="Sort"]`)
		sortIconButton?.setAttribute('aria-hidden', 'true')

		const menuIconButton = columnHeader?.querySelector(`[aria-label="Menu"]`)
		menuIconButton?.setAttribute('aria-label', 'filter menu')
	}, [menuInstructionsId, field])

	return (
		<>
			<Tooltip title={colDef.headerName} aria-label={label}>
				<Box sx={{ maxWidth: '100%' }}>
					<Typography
						variant="body2Semibold"
						sx={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							display: 'block',
						}}
					>
						{colDef.headerName}
					</Typography>
				</Box>
			</Tooltip>
		</>
	)
}
