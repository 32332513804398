import { gql } from '@persuit/ui-graphql'

const savingsSummaryToExcel = gql`
	mutation {
		savingsSummaryToExcel {
			data
		}
	}
`

export default savingsSummaryToExcel
