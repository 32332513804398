import { styled } from '@persuit/ui-components'
import useTranslation from '../custom-hooks/translation-hook'
import {
	// black,
	grey300,
	greyA700,
} from '../theme/persuit-colors'

const BaseContainer = styled('div')`
	background-color: ${(props) => props.backgroundColor};
	color: ${(props) => props.color};
	width: fit-content;
	border-radius: 5px;
	padding: 0.25rem 0.5rem;
	font-weight: 500 !important;
	font-size: 0.75rem !important;
	text-transform: uppercase;
`

/**
 * @text {string} text to display
 * @color {string} text font colour
 * @backgroundColor {string} label background colour
 *
 * Usage:
 * supported use case as named exports:
	<NAMWOLFLabel />
	<PublishedLabel />
	<UnpublishedLabel />
	<SystemLabel />
	<CreatorLabel />
 * Generic use case as default export <Label text="Hello" color="yellow" backgroundColor="green" />
 */
const Label = ({ text, color, backgroundColor }) => {
	return (
		<BaseContainer color={color} backgroundColor={backgroundColor}>
			{text}
		</BaseContainer>
	)
}

export const NAMWOLFLabel = () => {
	const { t } = useTranslation()
	// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
	return <Label color={'black'} backgroundColor="#E14924" text={t('labels.namwolf')} />
}
export const CreatorLabel = () => {
	const { t } = useTranslation()
	return <Label color={greyA700} backgroundColor={grey300} text={t('labels.creator')} />
}
export const DisabledLabel = () => {
	return <Label color={greyA700} backgroundColor={grey300} text="Deactivated user" />
}
export const DesignatedContactLabel = () => {
	return <Label color={greyA700} backgroundColor={grey300} text="DESIGNATED CONTACT" />
}
export const AutoShareLabel = () => {
	return <Label color={greyA700} backgroundColor={grey300} text="AUTO-SHARE" />
}

export default Label
