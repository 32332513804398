// @ts-strict-ignore
import { useState } from 'react'
import unique from 'lodash/uniq'
import { CustomTrackingFieldValues } from '../types'
import { useFormContext } from '../../form-utils'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CheckBoxIcon,
	CheckBoxOutlineBlankIcon,
	IndeterminateCheckBoxIcon,
} from '@persuit/ui-components'

type CheckboxHierarchyCheckboxesProps = {
	values: CustomTrackingFieldValues[]
	value: (string | null)[]
	name: `tracking.customFields.${number}.hierarchyValue`
	listTitleId: string
}

export const CheckboxHierarchyCheckboxes = ({
	values,
	value,
	name,
	listTitleId,
}: CheckboxHierarchyCheckboxesProps) => {
	const { setValue } = useFormContext()
	const [activeId, setActiveId] = useState<string | undefined>(undefined)

	const isValueSelected = (_id) => {
		return value.includes(_id)
	}

	const valuesMap = new Map(
		values.flatMap((field) =>
			(field?.values ?? []).filter(isNotNil).map((value) => [value._id, value]),
		),
	)
	const subValuesMap = new Map(
		Array.from(valuesMap).flatMap(([, value]) =>
			(value.values ?? []).filter(isNotNil).map((subValue) => [subValue._id, subValue]),
		),
	)

	const getParentCheckboxIcon = (_id) => {
		if (isValueSelected(_id)) {
			return <CheckBoxIcon color="primary" />
		}

		const childValues = values?.find((val) => val?._id === _id)?.values || []
		const checkedChildValues = childValues.filter((val2) => val2?._id && value.includes(val2._id))

		if (checkedChildValues.length === 0) {
			return <CheckBoxOutlineBlankIcon color="primary" />
		}

		if (childValues.length === checkedChildValues.length) {
			return <CheckBoxIcon color="primary" />
		}

		return <IndeterminateCheckBoxIcon color="primary" />
	}

	const getChildCheckboxIcon = (_id) => {
		const valueSelected = isValueSelected(_id)

		return valueSelected ? (
			<CheckBoxIcon color="primary" />
		) : (
			<CheckBoxOutlineBlankIcon color="primary" />
		)
	}

	const onChildCheck = (_id) => () => {
		const isAlreadyChecked = value.includes(_id)

		if (isAlreadyChecked) {
			setValue(
				name,
				value.filter((val) => val !== _id),
			)
		} else {
			setValue(name, [...value, _id])
		}
	}

	const onParentCheck = (_id) => () => {
		const childValues = values?.find((val) => val?._id === _id)?.values || []
		const checkedChildValues = childValues.filter((val2) => val2?._id && value.includes(val2._id))

		// Scenario 1. If the parent has no children then we check and uncheck it as normal
		if (childValues.length === 0) {
			onChildCheck(_id)()
			return
		}
		// Scenario 2. If the parent *does* has children then
		// the children and checked and unchecked as a group
		// If all of the children are checked then they will all be unchecked
		// If one of the children are checked then they will all be checked
		else {
			let updatedValues
			if (checkedChildValues.length >= 0 && checkedChildValues.length < childValues.length) {
				updatedValues = [...value, ...childValues.map((childVal) => childVal?._id)]
			} else {
				updatedValues = value.filter(
					(val) => !childValues.some((childVal) => childVal?._id === val),
				)
			}

			setValue(name, unique(updatedValues))
		}
	}

	const check = (_id) => () => {
		if (subValuesMap.has(_id)) {
			onChildCheck(_id)()
		} else {
			onParentCheck(_id)()
		}
	}

	const filteredIds = values
		// Only display items that have an _id otherwise the
		// checkbox checks don't work properly
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))

	const flatIds = filteredIds.flatMap((field) => [
		field._id,
		...(field.values ?? []).flatMap((value) => [
			value?._id,
			...(value?.values ?? []).map((subValue) => subValue?._id),
		]),
	])

	return (
		<List
			disablePadding={true}
			sx={{ p: 0 }}
			role="listbox"
			aria-labelledby={listTitleId}
			aria-activedescendant={activeId ?? ''}
			tabIndex={0}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					if (activeId) check(activeId)()
				} else if (e.key === 'ArrowDown') {
					e.preventDefault()
					return setActiveId((activeId) =>
						typeof activeId === 'undefined'
							? flatIds[0]
							: flatIds[(flatIds.indexOf(activeId) + 1) % flatIds.length],
					)
				} else if (e.key === 'ArrowUp') {
					e.preventDefault()
					return setActiveId((activeId) =>
						typeof activeId === 'undefined'
							? flatIds[flatIds.length - 1]
							: flatIds[(flatIds.indexOf(activeId) - 1 + flatIds.length) % flatIds.length],
					)
				}
			}}
		>
			{filteredIds.map(({ _id, value, values: subItems = [] }, index) => {
				const valueId = _id ? _id : index

				const nestedItems = subItems?.filter(isNotNil).map(({ _id, value: subValue }, index2) => {
					const subItemId = _id ? _id : index2

					return (
						<ListItem
							id={`${subItemId}`}
							role="option"
							data-testid="checkbox-nested-list-item"
							key={subItemId}
							aria-selected={isValueSelected(subItemId) ? 'true' : 'false'}
							aria-label={`${subValue}, sub item of ${value ?? ''}, ${index2 + 1} of ${
								subItems.length ?? ''
							}`}
							onClick={check(subItemId)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									check(subItemId)()
								}
							}}
							onMouseEnter={() => setActiveId(`${subItemId}`)}
							sx={{
								bgcolor: subItemId === activeId ? 'primary.lighterHue' : undefined,
								pl: 2,
							}}
						>
							<ListItemIcon>{getChildCheckboxIcon(subItemId)}</ListItemIcon>
							<ListItemText>{subValue}</ListItemText>
						</ListItem>
					)
				})

				return (
					<>
						<ListItem
							id={_id}
							data-testid="checkbox-list-item"
							key={_id}
							role="option"
							aria-selected={isValueSelected(_id) ? 'true' : 'false'}
							onClick={check(_id)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									check(_id)()
								}
							}}
							onMouseEnter={() => setActiveId(`${_id}`)}
							sx={{
								bgcolor: _id === activeId ? 'primary.lighterHue' : undefined,
								px: 0,
							}}
						>
							<ListItemIcon>{getParentCheckboxIcon(valueId)}</ListItemIcon>
							<ListItemText>{value}</ListItemText>
						</ListItem>

						{nestedItems}
					</>
				)
			})}
		</List>
	)
}
