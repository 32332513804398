// @ts-strict-ignore
import { Box, alpha, DataGridPro, RemoveIcon, SROnly, styled } from '@persuit/ui-components'
// eslint-disable-next-line no-restricted-imports
import { gridClasses, GridColDef } from '@mui/x-data-grid-pro'
import {
	GridCustomCellWithLogo,
	GridCustomHeaderCell,
} from '@client/pages/panel-management/edit-list/edit-list-add-firms/add-firms-data-grid/custom-cells'
import { GridCustomColumnMenu } from '@client/pages/panel-management/edit-list/edit-list-add-firms/add-firms-data-grid/custom-subcomponents'
import { GridCustomCellWithButton, GridCustomCellWithAnchor } from './custom-cells'
import { KnownPerson, UnknownPerson } from '../../types'
import { ViewInfoButton } from '../../view-info-button'

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
	border: `0.5px solid rgb(224, 224, 224)`,
	borderRadius: 0,
	'& .MuiDataGrid-columnHeaders, .MuiDataGrid-withBorderColor': {
		borderColor: theme.palette.neutral.main,
	},
	'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
		borderRight: `0.5px solid rgb(224, 224, 224)`,
		'& .MuiDataGrid-menuIcon': {
			visibility: 'visible',
			width: 'auto',
		},
		padding: '0 16px',
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `0.5px solid rgb(224, 224, 224)`,
	},
	'& .MuiDataGrid-iconSeparator': {
		color: `rgb(224, 224, 224)`,
	},
	[`& .${gridClasses.row}.even`]: {
		// zebra stripes
		backgroundColor: theme.palette.grey150,
		// shared selected and hover styles
		...hoverAndSelectedStyles({ theme }),
	},
	[`& .${gridClasses.row}.odd`]: {
		// white stripes
		backgroundColor: 'white',
		// shared selected and hover styles
		...hoverAndSelectedStyles({ theme }),
	},
}))

type Person = KnownPerson | UnknownPerson

type GridRow = {
	contactName: string
	contactTitle: string
	firmName: string
	contactLocation: string
	pendingRemoval: boolean
	addedBySystem: boolean
	selectedUser: Person
}

const ODD_OPACITY = 0.2

const getColumns = (
	onRemove: (person: AddedContact) => void,
	onCancelRemoval: (person: AddedContact) => void,
	isFirmView: boolean,
	isDraftRfp: boolean,
): GridColDef<GridRow[][number]>[] => {
	return [
		{
			field: 'contactName',
			headerName: 'Contact name',
			type: 'string',
			minWidth: isFirmView ? 300 : 200,
			flex: 1.5,
			editable: false,
			renderHeader: (params) => <GridCustomHeaderCell {...params} />,
			renderCell: (params) => (
				<GridCustomCellWithAnchor
					{...params}
					isAnchor={true}
					isDesignatedContact={params.row.addedBySystem}
					sx={{ opacity: params.row.pendingRemoval ? 0.5 : 1 }}
				/>
			),
		},
		{
			field: 'firmName',
			headerName: 'Firm name',
			type: 'string',
			minWidth: 300,
			flex: 2,
			editable: false,
			renderHeader: (params) => <GridCustomHeaderCell {...params} />,
			renderCell: (params) => (
				<GridCustomCellWithLogo {...params} sx={{ opacity: params.row.pendingRemoval ? 0.5 : 1 }} />
			),
		},
		{
			field: 'contactTitle',
			headerName: 'Job title',
			type: 'string',
			width: 200,
			flex: 1,
			editable: false,
			renderHeader: (params) => <GridCustomHeaderCell {...params} />,
			renderCell: (params) => (
				<GridCustomCellWithAnchor
					{...params}
					sx={{ opacity: params.row.pendingRemoval ? 0.5 : 1 }}
				/>
			),
		},
		{
			field: 'contactLocation',
			headerName: 'Location',
			type: 'string',
			width: 200,
			flex: 1,
			editable: false,
			renderHeader: (params) => <GridCustomHeaderCell {...params} />,
			renderCell: (params) => (
				<GridCustomCellWithAnchor
					{...params}
					sx={{ opacity: params.row.pendingRemoval ? 0.5 : 1 }}
				/>
			),
		},
		{
			field: 'viewInfo',
			headerName: 'View info column',
			type: 'custom',
			width: 120,
			align: 'center',
			sortable: false,
			filterable: false,
			hideSortIcons: true,
			disableColumnMenu: true,
			renderHeader: (params) => <SROnly>{params.colDef.headerName}</SROnly>,
			renderCell: (params) =>
				params.row.selectedUser && (
					<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
						<ViewInfoButton
							person={params.row.selectedUser}
							tabIndex={params.tabIndex}
							isInvitationListView={true}
							isDraftRfp={isDraftRfp}
						/>
					</Box>
				),
		},
		{
			field: 'remove',
			headerName: 'Action column',
			type: 'custom',
			width: 120,
			align: 'center',
			sortable: false,
			filterable: false,
			hideSortIcons: true,
			disableColumnMenu: true,
			renderHeader: (params) => <SROnly>{params.colDef.headerName}</SROnly>,
			renderCell: (params) => (
				<GridCustomCellWithButton
					{...params}
					buttonProps={{
						variant: 'outlined',
						startIcon: params.row.pendingRemoval ? null : <RemoveIcon />,
					}}
					buttonLabel={params.row.pendingRemoval ? 'Add Back' : 'Remove'}
					handleButtonClick={params.row.pendingRemoval ? onCancelRemoval : onRemove}
				/>
			),
		},
	]
}

const hoverAndSelectedStyles = ({ theme }) => ({
	'&:hover, &.Mui-hovered': {
		backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
		'@media (hover: none)': {
			backgroundColor: 'transparent',
		},
	},
})

export type AddedContact = {
	_id: string
	contactName: string
	contactTitle: string | null
	firmName: string
	contactLocation: string | null
	isNamwolfMember: boolean
	type: string
	pendingRemoval: boolean
	addedBySystem: boolean
	selectedUser?: Person
}

type ContactsAddedGridProps = {
	rows: AddedContact[]
	loading?: boolean
	pendingRemoval?: Record<string, boolean>
	setPendingRemoval?: React.Dispatch<Record<string, boolean>>
	isFirmView?: boolean
	isShoppingCartView?: boolean
	isDraftRfp: boolean
}

export const ContactsAddedGrid = ({
	rows,
	pendingRemoval,
	setPendingRemoval,
	loading = false,
	isFirmView = false,
	isShoppingCartView = false,
	isDraftRfp,
}: ContactsAddedGridProps) => {
	const onRemove = (person: AddedContact) => {
		if (pendingRemoval && setPendingRemoval)
			setPendingRemoval({ ...pendingRemoval, [person._id]: true })
	}
	const onCancelRemoval = (person: AddedContact) => {
		if (pendingRemoval && setPendingRemoval) {
			const { [person._id]: _, ...rest } = pendingRemoval
			setPendingRemoval(rest)
		}
	}
	return (
		<StripedDataGrid
			aria-label="List of contacts added"
			rows={rows}
			columns={getColumns(onRemove, onCancelRemoval, isFirmView, isDraftRfp)}
			disableRowSelectionOnClick={true}
			disableColumnSelector={true}
			disableColumnReorder={true}
			disableColumnPinning={true}
			getRowId={(row) => row._id}
			slots={{ columnMenu: GridCustomColumnMenu }}
			autoHeight={true}
			getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
			experimentalFeatures={{ ariaV7: true }}
			rowHeight={46}
			hideFooter={true}
			loading={loading}
			initialState={{
				columns: {
					columnVisibilityModel: {
						firmName: !isFirmView,
						viewInfo: !isShoppingCartView,
						remove: !isShoppingCartView,
					},
				},
			}}
			autosizeOptions={{
				columns: ['contactName', 'firmName'],
				includeOutliers: true,
				includeHeaders: false,
			}}
		/>
	)
}
