import { Button } from '../../button'
import { Box } from '../../box'
import { Divider } from '../../divider'

import { ListboxComponent } from './variable-list'
import { ListOption, Option } from './option'

import { useActions, useStore } from './store'

export type ListBoxProps = {
	id?: string
}

export const ListBox = ({ ...rest }: ListBoxProps) => {
	const { options, allCollapsed, hideActions, virtualize } = useStore((state, s) => ({
		virtualize: state.virtualize,
		options: s.filteredOptions().array(),
		hideActions: !!state.searchTerm,
		allCollapsed: s.allCollapsed(),
	}))

	const { expandCollapseAll } = useActions()

	const containerProps = {
		...rest,
		'aria-multiselectable': true,
	}

	return (
		// Need to attach onMouseDown handler to prevent popup from closing when popover is clicked
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<Box position="relative" onMouseDown={(rest as any).onMouseDown}>
			<Box position="sticky" top={0} zIndex={900} bgcolor="white" height={32} aria-hidden={'true'}>
				<Box display="flex" justifyContent="flex-end" alignItems="center">
					<Button
						disabled={hideActions}
						size="small"
						color="primary"
						onClick={expandCollapseAll}
						aria-hidden={'true'}
						tabIndex={-1}
					>
						{allCollapsed ? 'Expand' : 'Collapse'} All
					</Button>
				</Box>
				<Divider />
			</Box>

			{virtualize ? (
				<ListboxComponent {...containerProps}>{ListOption as any}</ListboxComponent>
			) : (
				<Box {...containerProps}>
					{options.map((data) => (
						<Option key={data._id} option={data} />
					))}
				</Box>
			)}
		</Box>
	)
}
