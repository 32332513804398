export const white = '#FFFFFF'
export const black = '#000000'

export const deepPurple50 = '#DDD9E8'
export const deepPurple100 = '#B1AACB'
export const deepPurple200 = '#9287B5'
export const deepPurple300 = '#6B5D98'
export const deepPurple400 = '#5D5285'
export const deepPurple500 = '#504672'
export const deepPurple600 = '#433A5F'
export const deepPurple700 = '#352F4C'
export const deepPurple800 = '#282339'
export const deepPurple900 = '#1B1726'
export const deepPurpleA100 = '#DDD9E8'
export const deepPurpleA200 = '#B1AACB'
export const deepPurpleA400 = '#5D5285'
export const deepPurpleA700 = '#352F4C'

export const teal50 = '#E0F2F1'
export const teal100 = '#B2DFDB'
export const teal200 = '#80CBC4'
export const teal300 = '#4DB6AC'
export const teal400 = '#26A69A'
export const teal500 = '#009688'
export const teal600 = '#00897B'
export const teal700 = '#00796B'
export const teal800 = '#00695C'
export const teal900 = '#004D40'
export const tealA100 = '#A7FFEB'
export const tealA200 = '#64FFDA'
export const tealA400 = '#1DE9B6'
export const tealA700 = '#00BFA5'

export const purple50 = '#FFFFFF'
export const purple100 = '#F7F1FD'
export const purple200 = '#DAC0F5'
export const purple300 = '#B682EC'
export const purple400 = '#A768E8'
export const purple500 = '#974DE4'
export const purple600 = '#8732E0'
export const purple700 = '#7B1FA2'
export const purple800 = '#691CB9'
export const purple900 = '#4A148C'
export const purpleA100 = '#FFFFFF'
export const purpleA200 = '#F7F1FD'
export const purpleA400 = '#A768E8'
export const purpleA700 = '#7820D4'

export const pink25 = '#FCEAF5'
export const pink50 = '#FCE4EC'
export const pink100 = '#F8BBD0'
export const pink200 = '#F48FB1'
export const pink300 = '#F06292'
export const pink400 = '#EC407A'
export const pink500 = '#E91E63'
export const pink600 = '#D81B60'
export const pink700 = '#C2185B'
export const pink800 = '#AD1457'
export const pink900 = '#880E4F'
export const pinkA100 = '#FF80AB'
export const pinkA200 = '#FF4081'
export const pinkA400 = '#F50057'
export const pinkA700 = '#C51162'

export const grey50 = '#FAFAFA'
export const grey100 = '#F5F5F5'
export const grey150 = '#F3F3F3'
export const grey200 = '#EEEEEE'
export const grey300 = '#E0E0E0'
export const grey400 = '#BDBDBD'
export const grey500 = '#9E9E9E'
export const grey600 = '#757575'
export const grey700 = '#616161'
export const grey800 = '#424242'
export const grey900 = '#212121'
export const greyA100 = '#FFFFFF'
export const greyA200 = '#EEEEEE'
export const greyA400 = '#BDBDBD'
export const greyA700 = '#616161'

export const red50 = '#ffebee'
export const red500 = '#F44336'
export const lightGreen500 = '#8BC34A'
export const amber600 = '#FFB300'
export const dangerRed = '#D30000'
export const persuitBlue = '#17182B'
export const PriceDeltaUp = '#ff5252'
export const PriceDeltaDown = '#00c853'
export const orange800 = '#ef6c00'
export const warningTertiaryTextDarkBrown = '#5f2b01'
