import { RichTextEditor } from '@persuit/ui-components'

const NoteEditor = ({ noteContents, setNoteContents }) => {
	const handleEditorChange = (content) => {
		setNoteContents(content)
	}

	return (
		<span data-testid="notes-tinymce-editor">
			<RichTextEditor
				label="Notes"
				required={true}
				height={200}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={true}
				onChange={handleEditorChange}
				onBlur={handleEditorChange}
				value={noteContents}
			/>
		</span>
	)
}

export default NoteEditor
