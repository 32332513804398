import { PureComponent } from 'react'
import { Box, Divider } from '@persuit/ui-components'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { calculateCategoryScore, calculateTotalWeightedScore } from '../../../../../utils/scorecard'
import proposalScoreActions from '../../../../../actions/proposal-scores'
import proposalStates from '../../../../../../common/data/rfp-proposal-states'

import { Stars } from './score/stars'

class ProposalScores extends PureComponent {
	static defaultProps = {
		showSubcategories: false,
	}

	onEditClicked = () => {
		const { proposalId, openProposalScoreForm, openProposalScoreSubcategories } = this.props

		openProposalScoreForm(proposalId)
		openProposalScoreSubcategories()
	}

	render() {
		const { proposalId, proposalStatus, scorecard, scoreCategories, showSubcategories } = this.props

		const proposalWithdrawn = proposalStatus === proposalStates.WITHDRAWN
		const totalWeightedScore = calculateTotalWeightedScore(scorecard, scoreCategories)
		const scoreCategoriesId = `${proposalId}-categories`

		return (
			<Box>
				<Box>
					<Stars
						sx={{ mt: 1, mb: 1.2 }}
						variant="body1Semibold"
						fontSize="24px"
						value={proposalWithdrawn ? null : totalWeightedScore}
						scale={1.2}
						label="Overall score"
					/>
				</Box>

				<Box id={scoreCategoriesId} role="list" aria-label="Categories score">
					{scoreCategories &&
						scoreCategories.map((category, index) => {
							let categoryScores = null
							let categoryTotal = null

							if (scorecard) {
								categoryScores = scorecard.find(({ categoryId }) => categoryId === category._id)
								categoryTotal = calculateCategoryScore(categoryScores)
							}

							return (
								<Box key={index} role="listitem">
									<Stars
										sx={{ mt: 1 }}
										variant="body1Semibold"
										fontSize="20px"
										value={proposalWithdrawn ? null : categoryTotal}
										scale={1}
										label={category.label}
									/>

									<Divider />

									{showSubcategories && (
										<Box role="list" aria-label="Subcategories score" sx={{ mt: 1 }}>
											{/* empty stars as placeholder */}
											{!categoryScores &&
												category.subcategories.map((subcategory) => (
													<Box key={subcategory._id} role="listitem">
														<Stars
															sx={{ pb: 0.5 }}
															color="text.secondary"
															value={proposalWithdrawn ? null : 0}
															scale={0.8}
															label={subcategory.label}
														/>
													</Box>
												))}

											{!!categoryScores &&
												category.subcategories.map((subcategory) => {
													const scorecardSubcategory = categoryScores.subcategories.find(
														({ subcategoryId }) => subcategoryId === subcategory._id,
													)

													let scoreValue = null

													if (
														!proposalWithdrawn &&
														scorecardSubcategory &&
														scorecardSubcategory.score
													) {
														scoreValue = scorecardSubcategory.score
													}

													return (
														<Box key={subcategory._id} role="listitem">
															<Stars
																key={subcategory._id}
																sx={{ pb: 0.5 }}
																value={scoreValue}
																scale={0.8}
																color="text.secondary"
																label={subcategory.label}
															/>
														</Box>
													)
												})}
										</Box>
									)}
								</Box>
							)
						})}
				</Box>
			</Box>
		)
	}
}

const mapDispatchToProps = {
	openProposalScoreForm: proposalScoreActions.openProposalScoreForm,
	openProposalScoreSubcategories: proposalScoreActions.openProposalScoreSubcategories,
}

const mapStateToProps = (state) => ({
	showSubcategories: state.proposalScores.showSubcategories,
})

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps),
)(ProposalScores)
