import { useHandleUnexpectedError } from '@persuit/ui-hooks'
import { useEffect, useState } from 'react'
import { NetworkStatus as apolloNetworkStatuses } from '@apollo/client'
import {
	Box,
	ClearIcon,
	IconButton,
	SearchIcon,
	TextField,
	Typography,
	LinkSupport,
} from '@persuit/ui-components'
import { useQuery, graphql, useMutation, getFragment } from '@persuit/ui-graphql'
import { ChannelList } from './channel-list'
import { sortChannels } from './sort-channels'
import { Channel, ChannelWithLastMessage, Message } from './types'
import { getOrgNameByChannel } from './utils'
import { ChannelList_ChannelsQuery } from '@persuit/ui-graphql/generated'

type ChannelListProps = {
	requestId: string
	selectChannel: (channelId: string) => void
}

const MESSAGES_CHANNEL_LIST_FRAGMENT = graphql(`
	fragment MessagingChannelList_Channels on Channel {
		id
		type
		myUnreads {
			readCount
			unreadCount
			lastViewed
		}
		orgs {
			firm {
				id
				name
				isNamwolfMember
			}
			client {
				id
				name
			}
		}
		members {
			userId
			orgId
			email
			name {
				first
				last
			}
			removed
		}
		messages {
			message
			id
			isBot
			messageAt
			createdAt
			createdBy {
				userId
				name {
					first
					last
				}
			}
			botMessage {
				translationKey
				translationReplacements
			}
		}
		draftMessage {
			message
			id
			createdAt
			createdBy {
				userId
				name {
					first
					last
				}
			}
		}
	}
`)

const GET_CHANNELS_AND_MESSAGES = graphql(`
	query ChannelList_Channels($requestId: ID!) {
		channels(requestId: $requestId) {
			...MessagingChannelList_Channels
		}
	}
`)

const GET_SESSION_INFO = graphql(`
	query ChannelList_SessionInfo {
		getSessionInfo {
			user {
				_id
				org {
					_id
					orgType
				}
			}
		}
	}
`)

const REQUEST_CHANNELS_SUBSCRIPTION = graphql(`
	subscription RequestChannels($requestId: ID!) {
		requestChannels(requestId: $requestId) {
			...MessagingChannelList_Channels
		}
	}
`)

const MARK_ALL_AS_READ = graphql(`
	mutation messagingMarkAllChannelsAsRead($requestId: ID!) {
		markAllChannelsAsRead(requestId: $requestId) {
			...MessagingChannelList_Channels
		}
	}
`)

export const ChannelsPane = ({ requestId, selectChannel }: ChannelListProps) => {
	const { data, error, loading, subscribeToMore, refetch, networkStatus } = useQuery(
		GET_CHANNELS_AND_MESSAGES,
		{
			variables: { requestId },
		},
	)
	const [markAllAsRead] = useMutation(MARK_ALL_AS_READ, { variables: { requestId } })

	const channels = getFragment(MESSAGES_CHANNEL_LIST_FRAGMENT, data?.channels)

	const { data: sessionData } = useQuery(GET_SESSION_INFO)

	const currentUser = sessionData?.getSessionInfo?.user

	const subscribeToRequestChannelUpdates = () => {
		return subscribeToMore({
			document: REQUEST_CHANNELS_SUBSCRIPTION,
			variables: {
				requestId,
			},
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) {
					return prev
				}

				return {
					...prev,
					channels: subscriptionData.data.requestChannels,
				} as ChannelList_ChannelsQuery
			},
		})
	}
	const [refetchingInProgress, setRefetchingInProgress] = useState(false)
	const [firstRender, setFirstRender] = useState(true)
	const [searchTerm, setSearchTerm] = useState('')
	const handleUnexpectedError = useHandleUnexpectedError()

	useEffect(() => {
		const unsubscribe = subscribeToRequestChannelUpdates()

		const timeoutId = setTimeout(() => {
			setFirstRender(false)
		}, 5)

		return () => {
			unsubscribe()
			clearTimeout(timeoutId)
		}
	}, [])

	useEffect(() => {
		if (!error && !refetchingInProgress && !loading && channels && channels.length === 0) {
			setRefetchingInProgress(true)
			refetch()
				.then(({ data: { channels: refetchedChannels } }) => {
					if (refetchedChannels && refetchedChannels.length === 0) {
						setTimeout(() => {
							setRefetchingInProgress(false)
						}, 15000)
					}
				})
				.catch(handleUnexpectedError)
		}

		const refetchTimer = setTimeout(refetch, error ? 240000 : 0)

		return () => {
			clearTimeout(refetchTimer)
		}
	}, [handleUnexpectedError, channels, refetchingInProgress])

	const channelList = (channels || []).concat([])

	const getLastMessage = (channel: Channel): Message | null => {
		const nonBotMessages = channel.messages.filter((message) => !message?.isBot)
		const lastMessage = nonBotMessages.length
			? nonBotMessages.reduce((prev, current) => {
					return parseInt(prev?.createdAt ?? '', 10) > parseInt(current?.createdAt ?? '', 10)
						? prev
						: current
			  })
			: null
		return lastMessage
	}

	const channelListWithLastMessage: Array<ChannelWithLastMessage> = channelList.map((channel) => ({
		...channel,
		lastMessage: getLastMessage(channel),
	}))

	const filteredChannels = searchTerm
		? channelListWithLastMessage.filter((channel) => {
				const orgName = getOrgNameByChannel(channel, currentUser?.org ?? null)
				return orgName ? orgName.toLowerCase().includes(searchTerm.toLowerCase()) : false
		  })
		: channelListWithLastMessage

	const filteredRecentChannels = filteredChannels.filter(
		(channel: Channel) =>
			channel.messages.filter((message) => !message?.isBot).length !== 0 || channel.draftMessage,
	)

	const filteredNoActivityChannels = filteredChannels.filter(
		(channel: Channel) =>
			channel.messages.filter((message) => !message?.isBot).length === 0 && !channel.draftMessage,
	)

	const sortedRecentChannels = sortChannels(filteredRecentChannels, 'CREATED_DATE')
	const sortedNoActivityChannels = sortChannels(filteredNoActivityChannels, 'ORG_NAME')

	if (networkStatus === apolloNetworkStatuses.loading && firstRender === true) {
		return (
			<Box
				padding={2}
				color="text.secondary"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				Loading...
			</Box>
		)
	}

	if (error)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				gap={2}
				p={4}
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
			>
				<Typography variant="body1Semibold">Messaging is temporarily unavailable.</Typography>
				<Typography variant="caption">
					If this problem persists, please contact our support team at&nbsp;
					<LinkSupport />.
				</Typography>
			</Box>
		)

	return (
		<div>
			{!error && channelList && channelList.length === 0 && (
				<Box
					padding={2}
					color="text.secondary"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					Loading...
				</Box>
			)}

			<Box my={5}>
				<Typography hidden={true} variant="h2"></Typography>
				<Typography hidden={true} variant="h3"></Typography>
				{channelListWithLastMessage.length > 2 ? (
					<Box mx={3}>
						<TextField
							size="small"
							InputProps={{
								startAdornment: <SearchIcon style={{ paddingRight: 8 }} />,
								endAdornment: searchTerm ? (
									<IconButton
										onClick={() => setSearchTerm('')}
										size="small"
										aria-label="Clear search"
									>
										<ClearIcon />
									</IconButton>
								) : null,
							}}
							variant="outlined"
							style={{ width: '100%' }}
							id="messages-search-input"
							label="Search conversation by organization name"
							inputProps={{
								type: 'search',
								'aria-label': 'Search conversation by organization name',
								style: {
									height: '56px',
									paddingTop: 0,
									paddingBottom: 0,
								},
							}}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</Box>
				) : null}

				<Box>
					{filteredChannels.length ? (
						<>
							{sortedRecentChannels && sortedRecentChannels.length > 0 ? (
								<ChannelList
									channels={sortedRecentChannels}
									isRecent={true}
									currentUser={currentUser ?? {}}
									selectChannel={selectChannel}
									markAllAsRead={markAllAsRead}
								/>
							) : null}
							{sortedNoActivityChannels && sortedNoActivityChannels.length > 0 ? (
								<ChannelList
									channels={sortedNoActivityChannels}
									isRecent={false}
									currentUser={currentUser ?? {}}
									selectChannel={selectChannel}
								/>
							) : null}
						</>
					) : (
						<Box
							padding={2}
							color="text.secondary"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
						>
							<Typography>No matching organizations found.</Typography>
							<Typography>Please check spelling and try again.</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</div>
	)
}
