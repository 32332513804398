import proposalScoreActions from '../../../../../actions/proposal-scores'
import { IconButton, ExpandMoreIcon, ExpandLessIcon } from '@persuit/ui-components'
import { useSelector, useDispatch } from '@client/store'

type ToggleShowSubcategoriesProps = {
	controlId: string
}

export const ToggleShowSubcategories = ({ controlId }: ToggleShowSubcategoriesProps) => {
	const dispatch = useDispatch()
	const { showSubcategories, proposalsScoreBeingEdited } = useSelector((state) => ({
		showSubcategories: state.proposalScores.showSubcategories,
		proposalsScoreBeingEdited: state.proposalScores.proposalScoreBeingEdited,
	}))

	return (
		<IconButton
			disabled={proposalsScoreBeingEdited}
			onClick={() => dispatch(proposalScoreActions.toggleShowSubcategories())}
			aria-label={`${showSubcategories ? 'Hide' : 'Show'} subcategories`}
			aria-controls={controlId}
			aria-expanded={showSubcategories ? 'true' : 'false'}
			size="small"
		>
			{showSubcategories ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
		</IconButton>
	)
}
