// @ts-strict-ignore
import { useState, useRef, useEffect, Fragment, MutableRefObject } from 'react'
import { ScreenOnly, styled, Box as MuiBox } from '@persuit/ui-components'
import RfpProposal from '../../containers/rfp-proposal'
import { getOrEmptyString, getOrFalse } from '../../utils/lenses.js'
import Box from '../box.jsx'
import Spacer from '../layout/spacer.jsx'
import Offset from '../offset.jsx'
import RfpProposalHeader from '../rfp-proposals/rfp-proposal-header.jsx'
import { RequestReOpenStatus } from '../status'
import RevisionRequiredLabel from '../status/firm/revision-required-status'
import isRequestReopenedForInvite from './get-reopened-status-for-invite'
import ProposalCAB from './proposal-cab'

// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
const StyledFixedProposalCAB = styled('div')`
	width: 100%;
	height: auto;
	position: fixed;
	top: 142px;
	z-index: 500;
	left: 0;
	background-color: #eeeeee;
	padding: 15px;
	border-bottom: 2px solid #e0e0e0;
`

const StyledFixedProposalContainer = styled('div')`
	@media screen and (max-width: 800px) {
		position: relative;
		right: 10px;
	}

	@media screen and (min-width: 801px) {
		width: 932px;
	}
`

export type ProposalViewContainerProps = {
	fetchResponses: () => unknown
	isFirmEliminated: boolean
	proposalId: string
	// Temporary until we have types from GraphQL
	proposals: any[]
	rfp: any
	user: any
}

const handleScroll =
	(
		proposalCABRef?: MutableRefObject<HTMLDivElement | null>,
		handleCabVisibility?: (value: boolean) => void,
	) =>
	() => {
		if (!proposalCABRef?.current) return
		const top = proposalCABRef.current.getBoundingClientRect().top
		if (top < 150) {
			handleCabVisibility?.(true)
		} else {
			handleCabVisibility?.(false)
		}
	}

const ProposalViewContainer = ({
	rfp,
	proposalId,
	proposals,
	isFirmEliminated,
	fetchResponses,
}) => {
	const [isCabVisible, setCabVisible] = useState(false)
	const proposalCABRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const handleCabVisibility = (value: boolean) => setCabVisible(value)
		window.addEventListener('scroll', handleScroll(proposalCABRef, handleCabVisibility))
		return () => window.removeEventListener('scroll', handleScroll())
	}, [])
	const proposal = proposals.find(({ _id }) => _id === proposalId)
	const hasProposalRevisionRequest = getOrFalse('hasRevisionRequest', proposal)
	const firmOrgId = getOrEmptyString('org._id', proposal)
	return (
		<Fragment>
			<Box top={2} bottom={1}>
				<ScreenOnly>
					{isCabVisible && (
						<StyledFixedProposalCAB id="fixed-proposal-cab">
							<Offset>
								<StyledFixedProposalContainer>
									<ProposalCAB
										rfp={rfp}
										proposalId={proposalId}
										proposals={proposals}
										isFirmEliminated={isFirmEliminated}
										fetchResponses={fetchResponses}
									/>
								</StyledFixedProposalContainer>
							</Offset>
						</StyledFixedProposalCAB>
					)}
					<div ref={proposalCABRef}>
						{!isCabVisible && (
							<ProposalCAB
								rfp={rfp}
								proposalId={proposalId}
								proposals={proposals}
								isFirmEliminated={isFirmEliminated}
								fetchResponses={fetchResponses}
							/>
						)}
					</div>
					<Spacer direction="column" space={2} />
					<MuiBox display="flex" alignItems="flex-start">
						{isRequestReopenedForInvite(rfp, firmOrgId) && (
							<Fragment>
								<Spacer direction="column" space={2} />
								<RequestReOpenStatus />
							</Fragment>
						)}
						<Spacer direction="row" space={2} />
						{hasProposalRevisionRequest && !isFirmEliminated && <RevisionRequiredLabel />}
					</MuiBox>
				</ScreenOnly>
				<RfpProposalHeader rfp={rfp} proposal={proposal} isFirmEliminated={isFirmEliminated} />
			</Box>

			<RfpProposal rfp={rfp} proposal={proposal} fetchResponses={fetchResponses} />
		</Fragment>
	)
}

export default ProposalViewContainer
