// @ts-strict-ignore
import { useState } from 'react'
import {
	Typography,
	Button,
	AccordionDetails,
	Box,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	LocalOfferIcon,
	AccordionActions,
	TextField,
} from '@persuit/ui-components'
import { ProposalDetailDialog } from './proposal-detail'
import { RatingsRadioGroup } from './ratings-radio-group'

export const AccordionContents = ({
	proposal,
	handleValueChange,
	formState,
	orgName,
	rfp,
	handleNext,
}) => {
	const [showProposalDetail, setShowProposalDetail] = useState(false)
	const openProposalDetail = () => setShowProposalDetail(true)
	const closeProposalDetail = () => setShowProposalDetail(false)

	const { _id: proposalId, subject, proposalStatus } = proposal
	const proposalState = formState[proposalId]

	const handleCommentChange = (value: string) => {
		const trimmedValue = value.trimStart()
		handleValueChange({ proposalId, field: 'comment', value: trimmedValue })
	}

	const handleRadioChange = (field) => (value) => handleValueChange({ proposalId, field, value })

	return (
		<>
			<AccordionDetails>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
					<Typography>{subject}</Typography>

					<Button
						color="secondary"
						variant="outlined"
						sx={{ marginLeft: 'auto' }}
						onClick={openProposalDetail}
						startIcon={<LocalOfferIcon />}
					>
						View Proposal
					</Button>
					<Typography color="text.secondary" sx={{ ml: 2 }}>
						{proposalStatus}
					</Typography>
				</Box>

				<Table
					role="grid"
					size="small"
					sx={{
						'& th': {
							width: '15%',
							textAlign: 'center',
						},
						'& th:first-child': {
							width: '10%',
							textAlign: 'left',
						},
						'& td': {
							textAlign: 'center',
						},
					}}
				>
					<caption>Feedback responses table</caption>
					<TableHead>
						<TableRow>
							<TableCell component="td" />
							<TableCell>
								<Typography variant="body2Semibold">Excellent</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body2Semibold">Good</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body2Semibold">Satisfactory</Typography>
							</TableCell>
							<TableCell>
								<div>
									<Typography variant="body2Semibold">Needs</Typography>
								</div>
								<div>
									<Typography variant="body2Semibold">Improvement</Typography>
								</div>
							</TableCell>
							<TableCell>
								<div>
									<Typography variant="body2Semibold">Not</Typography>
								</div>
								<div>
									<Typography variant="body2Semibold">Applicable</Typography>
								</div>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow>
							<TableCell component="th">Strategy</TableCell>

							<RatingsRadioGroup
								name="strategy"
								label="Strategy"
								value={proposalState.strategy}
								onChange={handleRadioChange('strategy')}
							/>
						</TableRow>

						<TableRow>
							<TableCell component="th">Pricing</TableCell>

							<RatingsRadioGroup
								name="pricing"
								label="Pricing"
								value={proposalState.pricing}
								onChange={handleRadioChange('pricing')}
							/>
						</TableRow>

						<TableRow>
							<TableCell component="th">Expertise</TableCell>

							<RatingsRadioGroup
								name="expertise"
								label="Expertise"
								value={proposalState.expertise}
								onChange={handleRadioChange('expertise')}
							/>
						</TableRow>

						<TableRow>
							<TableCell component="th">Diversity</TableCell>

							<RatingsRadioGroup
								name="diversity"
								label="Diversity"
								value={proposalState.diversity}
								onChange={handleRadioChange('diversity')}
							/>
						</TableRow>
					</TableBody>
				</Table>

				<TextField
					label="Additional comments"
					name="comment"
					value={proposalState.comment}
					onChange={(e) => handleCommentChange(e.currentTarget.value)}
					multiline={true}
					fullWidth={true}
					inputProps={{ maxLength: 800 }}
				/>

				{handleNext && (
					<AccordionActions>
						<Button size="small" color="primary" onClick={handleNext}>
							Next Firm
						</Button>
					</AccordionActions>
				)}
			</AccordionDetails>

			<ProposalDetailDialog
				open={showProposalDetail}
				onClose={closeProposalDetail}
				proposal={proposal}
				rfp={rfp}
				orgName={orgName}
			/>
		</>
	)
}

export default AccordionContents
