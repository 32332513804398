import { Box, Divider, List, Typography, Button } from '@persuit/ui-components'
import { ChannelCard } from './channel-card'
import { ChannelWithLastMessage, CurrentUser } from './types'

type ChannelListProps = {
	channels: Array<ChannelWithLastMessage>
	isRecent: boolean
	currentUser: CurrentUser
	selectChannel: (channelId: string) => void
	markAllAsRead?: () => void
}

export const ChannelList = ({
	channels,
	isRecent,
	currentUser,
	selectChannel,
	markAllAsRead,
}: ChannelListProps) => {
	const listHeading = isRecent ? 'Recent' : 'No activity'
	const listLabel = `${isRecent ? 'Recent' : 'No activity'} messages list with ${
		channels.length
	} items`

	const hasUnreadMessages = channels.some((channel) => channel.myUnreads.unreadCount)

	return (
		<Box>
			<Box mt={5} mb={2} mx={3} display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body1Semibold" color="text.secondary" aria-label={listLabel}>
					{listHeading}
				</Typography>
				{markAllAsRead && (
					<Button
						data-trackid="button-mark-all-as-read-request"
						onClick={markAllAsRead}
						variant="outlined"
						disabled={!hasUnreadMessages}
					>
						Mark all as read
					</Button>
				)}
			</Box>
			<Divider />
			<List sx={{ px: 3, py: 0 }}>
				{channels.map((channel: ChannelWithLastMessage, index: number) => (
					<ChannelCard
						key={channel?.id}
						isLastIndex={index === channels.length - 1}
						channel={channel}
						currentUser={currentUser}
						selectChannel={selectChannel}
					/>
				))}
			</List>
			<Divider />
		</Box>
	)
}
