import * as React from 'react'
import {
	useTheme,
	styled,
	LoadingSpinner,
	Typography,
	CircularProgress,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogDeprecated,
	DialogDeprecatedProps,
} from '@persuit/ui-components'

import useTranslation from '../custom-hooks/translation-hook'

const StyledContentText = styled(DialogContentText)<{ black?: boolean }>`
	color: ${(props) => (props.black ? 'black !important' : undefined)};
`

type DialogProps = DialogDeprecatedProps & {
	black?: boolean
	cancelLabel?: string
	children?: React.ReactNode
	confirmIcon?: React.ComponentType<any>
	confirmLabel?: string
	danger?: boolean
	disabledConfirm?: boolean
	loading?: boolean
	onCancel?: () => void
	onConfirm?: () => void
	open: boolean
	secondaryAction?: () => void
	secondaryActionIcon?: React.ComponentType<any>
	secondaryActionLabel?: string
	subTitle?: string
	title?: string
	working?: boolean
}

const Dialog = ({
	cancelLabel,
	children,
	confirmIcon: ConfirmIcon,
	confirmLabel,
	disabledConfirm,
	onCancel,
	onConfirm,
	open,
	title,
	subTitle,
	working,
	loading,
	danger,
	secondaryAction,
	secondaryActionIcon: SecondaryActionIcon,
	secondaryActionLabel,
	black,
	...props
}: DialogProps) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const icon = ConfirmIcon ? (
		<ConfirmIcon style={{ color: 'white', marginRight: theme.spacing(1) }} />
	) : null

	const secondaryIcon = SecondaryActionIcon ? (
		<SecondaryActionIcon style={{ color: 'white', marginRight: theme.spacing(1) }} />
	) : null

	const actions = [
		secondaryAction ? (
			<Button
				key="secondaryAction"
				variant="text"
				disabled={loading || working}
				onClick={secondaryAction}
				style={{
					position: 'absolute',
					left: '1rem',
				}}
			>
				{secondaryIcon} {secondaryActionLabel}
			</Button>
		) : null,
		<Button
			variant="text"
			data-testid="dialog-close"
			disabled={loading || working}
			key="cancel"
			onClick={onCancel}
		>
			{cancelLabel || t('dialog.cancel')}
		</Button>,
		onConfirm ? (
			<Button
				key="submit"
				disabled={loading || working || disabledConfirm}
				variant={danger ? 'text' : 'contained'}
				color={danger ? 'error' : 'primary'}
				onClick={onConfirm}
				data-testid="dialog-submit"
			>
				{working ? (
					<CircularProgress size={16} style={{ top: '-2px', marginRight: theme.spacing(1) }} />
				) : (
					icon
				)}
				{confirmLabel || t('dialog.confirm')}
			</Button>
		) : null,
	]

	return (
		<DialogDeprecated
			PaperProps={{ 'data-testid': 'mui-dialog' } as any}
			onClose={onCancel}
			open={open}
			fullWidth={true}
			maxWidth="md"
			{...props}
		>
			{title && (
				<React.Fragment>
					<DialogTitle>
						{subTitle && <Typography variant="caption">{subTitle}</Typography>}
						{title}
					</DialogTitle>
				</React.Fragment>
			)}

			{children && (
				<DialogContent>
					{loading && <LoadingSpinner />}

					{!loading && (
						<StyledContentText id="alert-dialog-description" black={black}>
							{children}
						</StyledContentText>
					)}
				</DialogContent>
			)}

			<DialogActions>{actions}</DialogActions>
		</DialogDeprecated>
	)
}

export default Dialog
