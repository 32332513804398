/**
 * This hook can be used by PricingItem or its children
 * to provide meta data to calculate field name location etc.
 */
export function usePricingItemFieldPath(
	itemIndex: number,
	groupItemIndex?: number,
): {
	path: PricingItemFieldPath
} {
	if (typeof groupItemIndex === 'number') {
		return {
			path: `pricingItems.${itemIndex}.deliverables.${groupItemIndex}` as const,
		}
	} else {
		return {
			path: `pricingItems.${itemIndex}` as const,
		}
	}
}

export type PricingItemFieldPath =
	| `pricingItems.${number}`
	| `pricingItems.${number}.deliverables.${number}`
