import {
	SxProps,
	useTheme,
	Accordion,
	Box,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@persuit/ui-components'

export type SubAccordionProps = {
	sx?: SxProps
	title: string
	children: React.ReactNode
	ariaLabel?: string
}

export const SubAccordion = ({ title, children, sx, ariaLabel }: SubAccordionProps) => {
	const theme = useTheme()

	return (
		<Accordion
			ContainerComponent={Box}
			sx={{
				borderTop: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
				...sx,
			}}
		>
			<AccordionSummary iconButtonLabel={title}>
				<Typography
					aria-label={ariaLabel}
					variant="body2Semibold"
					sx={(theme) => ({ p: 0, color: theme.palette.primary.main, textTransform: 'uppercase' })}
				>
					{title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}
