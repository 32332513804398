import { SvgIcon, SvgIconProps } from '@persuit/ui-components'

export const MicrosoftIcon = (props: SvgIconProps) => (
	<SvgIcon
		version="1.1"
		id="Calque_1"
		x="0px"
		y="0px"
		width="22px"
		height="22px"
		viewBox="0 0 439 439"
		{...props}
		sx={{
			backgroundColor: '#FFF',
			...props.sx,
		}}
	>
		<rect width="439" height="439" style={{ fill: '#FFF' }} />
		<rect x="17" y="17" width="194" height="194" style={{ fill: '#F35325' }} />
		<rect x="228" y="17" width="194" height="194" style={{ fill: '#81BC06' }} />
		<rect x="17" y="228" width="194" height="194" style={{ fill: '#05A6F0' }} />
		<rect x="228" y="228" width="194" height="194" style={{ fill: '#FFBA08' }} />
	</SvgIcon>
)
