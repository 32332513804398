import { Fragment } from 'react'
import { Box, Table, TableBody, Spacer, Typography } from '@persuit/ui-components'
import { find, size } from 'lodash/fp'
import { getOrEmptyArray } from '../../../utils/lenses'
import formatPriceCurrency from '../../../utils/price-and-currency'

import { PricingCardItemsView } from '../../pricing-card-detail-view/client'

const SuggestedRateForExcludedRateCard = ({ rfprRate, rate, currency }) => {
	const hasRfprSuggestion = Boolean(rfprRate)
	const formattedRate = formatPriceCurrency(currency, rate)
	const formattedRfprRate = formatPriceCurrency(currency, rfprRate)

	if (hasRfprSuggestion === true) {
		return (
			<Box display="flex">
				<Typography
					variant="body1"
					strikeThrough={true}
					style={{ textDecorationColor: 'red', textDecorationThickness: '3px' }}
				>
					{formattedRate}
				</Typography>
				<Spacer />
				<Typography variant="body1">{formattedRfprRate}</Typography>
			</Box>
		)
	}

	return <Typography variant="body1">{formattedRate}</Typography>
}

const ExcludedRateItemRow = ({ label, rfprRate, rate, rateItemNumber, currency }) => {
	return (
		<Fragment>
			<Box display="flex" justifyContent="space-between">
				<Box display="flex">
					<Typography variant="body2">{rateItemNumber}</Typography>
					<Spacer />
					<Typography varaint="body2">{label}</Typography>
				</Box>
				<SuggestedRateForExcludedRateCard rate={rate} rfprRate={rfprRate} currency={currency} />
			</Box>
			<Spacer />
		</Fragment>
	)
}

const getAllRates = (requestedRates = [], rfprRates = []) => {
	if (size(rfprRates) === 0) {
		return []
	}
	const newRates = requestedRates.map((rate, rateIndex) => {
		const foundRate = find(({ rateId }) => rateId === rate._id, rfprRates) || {}

		const rateItemNumber = foundRate.rateItemNumber || rateIndex + 1
		const rateValue = foundRate.rate || 0

		return {
			_id: rate._id,
			label: rate.label,
			rateItemNumber,
			rate: rateValue,
			rfprRate: foundRate.rfprRate,
		}
	})

	return newRates
}

export const ExcludedRateCardItemsView = ({ rfpr, currency, deliverableIndex, requestedRates }) => {
	const rfprRates = getOrEmptyArray('rfprRates', rfpr)
	const allRates = getAllRates(requestedRates, rfprRates)

	return (
		<Table>
			<TableBody>
				<Typography variant="body1Semibold" align="center">
					Excluded from scope
				</Typography>
				{allRates.map((rate) => (
					<ExcludedRateItemRow
						key={rate._id}
						label={rate.label}
						rate={rate.rate}
						rfprRate={rate.rfprRate}
						rateItemNumber={`${deliverableIndex + 1}.${rate.rateItemNumber}.`}
						currency={currency}
					/>
				))}
			</TableBody>
		</Table>
	)
}

const RateCardView = ({
	isProposalRevised,
	currency,
	responseToDeliverable,
	requestedRates,
	phaseIndex,
	showTotal,
	expandedView,
	detailedView,
	showHeader,
	cellView,
	phaseDuration,
	pricingModel,
	isCompetitor,
	rfpr,
	rank,
}) => {
	return (
		<PricingCardItemsView
			isProposalRevised={isProposalRevised}
			currency={currency}
			responseToDeliverable={responseToDeliverable}
			requestedRates={requestedRates}
			phaseIndex={phaseIndex}
			showTotal={showTotal}
			expandedView={expandedView}
			detailedView={detailedView}
			showHeader={showHeader}
			cellView={cellView}
			phaseDuration={phaseDuration}
			pricingPreferences={pricingModel}
			isCompetitor={isCompetitor}
			rfpr={rfpr}
			rank={rank}
		/>
	)
}

RateCardView.defaultProps = {
	expandedView: false,
	isProposalRevised: false,
}

/** @type {any} */
export default RateCardView
