const mimes = ['image/*']

const extensions = [
	'.pdf',

	// Word
	'.doc',
	'.docx',

	// Powerpoint
	'.ppt',
	'.pptx',
	'.pps',
	'.ppsx',

	// Excel
	'.xls',
	'.xlt',
	'.xlsx',

	// Outlook email export
	'.eml',
]

const accept = [...mimes, ...extensions]

const string = accept.join(',')

// return the extensions without leading .
const acceptExt = extensions.map((e) => {
	return e[0] === '.' ? e.substring(1) : e
})

// return the mime without trailing *
const acceptMime = mimes.map((m) => {
	return m[m.length - 1] === '*' ? m.substring(0, m.length - 1) : m
})

export { accept as values, acceptExt as extensions, acceptMime as mimes, string as default }
