import { gql, useMutation } from '@apollo/client'

import { getOrEmptyArray } from '../../../utils/lenses'
import { FileList, useSnackbar } from '@persuit/ui-components'
import { useUser } from '@persuit/ui-auth'

const DELETE_LOE_MUTATION = gql`
	mutation deleteLoeFromProposal($proposalId: ID!, $fileId: ID!) {
		deleteLoeFromProposal(proposalId: $proposalId, fileId: $fileId)
	}
`

const EngagementLetters = ({ engagementLetters, proposalId, fetchResponses }: any) => {
	const { openSnackbar } = useSnackbar()
	const [deleteLoeFromProposal] = useMutation(DELETE_LOE_MUTATION)
	const { user } = useUser()

	const files = getOrEmptyArray('files', engagementLetters)
	const orgType = user?.org?.orgType

	const onRemoveConfirm = async (id: string) => {
		try {
			await deleteLoeFromProposal({
				variables: {
					proposalId,
					fileId: id,
				},
			})
			openSnackbar('Successfully deleted Letter of Engagement')

			fetchResponses()
		} catch (e: any) {
			openSnackbar(`Failed to delete Letter of Engagement with error ${e.message}`, {
				variant: 'error',
			})
		}
	}

	// If the user is not a client, then return the basic file list
	if (orgType === 'FIRM') return <FileList files={files} />

	return (
		<>
			<FileList files={files} enableConfirmDialog={true} onRemoveFile={onRemoveConfirm} />
		</>
	)
}

export default EngagementLetters
