import axios from '../axios'

const actions = {}

const orgFields = `
	fragment fields on Org {
		_id
		name
		servicesProvided
		orgType
		settings {
			isDiversityQuestionnaireFeatureEnabled
			currency
			categories {
				_id
				name
				isArchived
			},
			contacts {
				_id
				name {
					first
					last
				}
			}
			savingsCalculationMethod
		},
		allowableEmailSuffix
		isConfirmed
	}
`
// This is still used indirectly by `authActions.updatedUserOrg` to refetch
// orgs and update the user's org in the redux store
// Need to modify how the user object gets hydrated in redux before this
// can be deleted
actions.getOrgs = function getOrgs() {
	return () => {
		return axios
			.post('/graphql/', {
				query: `
				query {
					getOrgs {
						...fields
					}
				}
				${orgFields}
			`,
			})
			.then((res) => {
				return res.data.data.getOrgs
			})
	}
}

export default actions
