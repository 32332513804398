import { useContext } from 'react'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplatePublishDialogPublishTemplateMutation } from '@persuit/ui-graphql/hooks'
import { useHistory } from 'react-router-dom'
import { PublishIcon, useSnackbar } from '@persuit/ui-components'
import { SendDialog, SendDialogProps } from '../../sectional-form/send-dialog'
import { RoutesContext } from '../routes-context'

graphql(`
	mutation RfpTemplatePublishDialogPublishTemplate($templateId: ID!) {
		publishTemplate(templateId: $templateId) {
			_id
		}
	}
`)

export type PublishDialogProps = Pick<
	SendDialogProps,
	| 'open'
	| 'onClose'
	| 'userId'
	| 'sectionalLocks'
	| 'refetchSectionalLocks'
	| 'validationErrors'
	| 'refetchValidationErrors'
> & {
	templateId: string
}

export const PublishDialog = ({
	open,
	onClose,
	templateId,
	refetchValidationErrors,
	...rest
}: PublishDialogProps) => {
	const routes = useContext(RoutesContext)
	const history = useHistory()
	const { openSnackbar } = useSnackbar()
	const [publishTemplate, { loading: publishLoading }] =
		useRfpTemplatePublishDialogPublishTemplateMutation({
			onError: () => {
				refetchValidationErrors()
				onClose()
			},
		})

	const publish = async () => {
		const { errors } = await publishTemplate({ variables: { templateId } })
		openSnackbar('Template published!')
		if (!errors) {
			history.push(routes.postPublish.uriFn(templateId))
		}
	}

	return (
		<SendDialog
			{...rest}
			open={open}
			onClose={onClose}
			onPrimary={publish}
			primaryLoading={publishLoading}
			primaryIcon={<PublishIcon />}
			primaryLabel="Publish"
			refetchValidationErrors={refetchValidationErrors}
			title="Are you sure you want to publish this template?"
			infoText="Publishing this template will make it available to all users in your organisation."
		/>
	)
}
