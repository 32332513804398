import { ChannelOrgs, ChannelTypes } from '@persuit/ui-graphql/generated'

export const getOrgNames = (
	channelOrgs: ChannelOrgs,
	currentOrgId: string,
	fallbackName: string,
): { currentOrgName: string; otherOrgName: string } => {
	if (channelOrgs?.client?.id === currentOrgId)
		return {
			currentOrgName: channelOrgs?.client?.name ?? fallbackName,
			otherOrgName: channelOrgs?.firm?.name ?? fallbackName,
		}
	if (channelOrgs?.firm?.id === currentOrgId)
		return {
			currentOrgName: channelOrgs?.firm?.name ?? fallbackName,
			otherOrgName: channelOrgs?.client?.name ?? fallbackName,
		}
	return {
		currentOrgName: fallbackName,
		otherOrgName: fallbackName,
	}
}

export const getChannelName = (
	type: ChannelTypes,
	channelOrgs: ChannelOrgs,
	currentOrgId: string,
	fallbackName: string,
): string => {
	const orgNames = getOrgNames(channelOrgs, currentOrgId, fallbackName)

	if (type === 'CLIENT_TO_FIRM_CHANNEL') return orgNames.otherOrgName
	return orgNames.currentOrgName
}
