// @ts-strict-ignore
import { useState, useEffect } from 'react'
import {
	Divider,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ChevronRightIcon,
	ArrowBackIcon,
	Typography,
	Box,
	ClearIcon,
	Chip,
	LinearProgress,
} from '@persuit/ui-components'

import {
	getTaxonomy,
	getSelectionForCategory,
	taxonomyCategories,
	createToggleForSelected,
	createToggleForExpanded,
	filterTaxonomy,
	getCodes,
} from './tagger-taxonomy-selector-utils'
import { useTaxonomy } from './tagger-use-taxonomy'
import { Taxonomy } from './tagger-taxonomy'

type Tag = {
	name?: string
	code: string
}

const formatTags = ({ tags, taggedByName }: { tags: Array<Tag>; taggedByName?: string }) => {
	if (tags.length === 0) {
		return <em>Not tagged</em>
	}

	return (
		<Box mt={1}>
			{taggedByName && (
				<Chip color="secondary" size="small" label={taggedByName} sx={{ mr: 1, mb: 1 }} />
			)}
			{tags.map((item) => (
				<Chip
					color="primary"
					key={item?.name}
					label={item?.name}
					size="small"
					sx={{ mr: 1, mb: 1 }}
				/>
			))}
		</Box>
	)
}
const TaxonomySelectorHeader = ({ handleCancel }) => {
	return (
		<Box p={2} display="flex">
			<Box flex={1}>
				<Typography variant="h2XSmall">Request Tagger</Typography>
			</Box>
			{handleCancel && (
				<Button startIcon={<ClearIcon />} onClick={() => handleCancel()}>
					Close
				</Button>
			)}
		</Box>
	)
}

type TaxonomySelectorProps = {
	initialSelection: any
	handleExclude?: (() => Promise<void>) | null
	handleConfirm: (...args: any) => void
	handleCancel: (...args: any) => void
	taggedByName?: string
	taggedByLlm?: boolean
	taxonomySlots: Array<string>
}

export const TaxonomySelector = ({
	initialSelection = {},
	handleExclude,
	handleConfirm,
	handleCancel,
	taggedByName,
	taggedByLlm,
	taxonomySlots = [],
}: TaxonomySelectorProps) => {
	const { taxonomies, fetchingTaxonomy } = useTaxonomy()

	const [expanded, setExpanded] = useState<Array<Tag>>([])
	const [selectedTags, setSelectedTags] = useState(initialSelection)
	const [taxonomyCategory, setTaxonomyCategory] = useState<null | number>(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])

	const taxonomy = getTaxonomy({ taxonomies, taxonomyCategory })
	const selected = getSelectionForCategory({ selectedTags, taxonomyCategory })
	const toggleSelected = createToggleForSelected({
		selectedTags,
		setSelectedTags,
		taxonomyCategory,
		allowMulitSelect:
			taxonomyCategory === taxonomyCategories.LOCATION ||
			taxonomyCategory === taxonomyCategories.AREA_OF_LAW ||
			taxonomyCategory === taxonomyCategories.SOURCING,
	})
	const toggleExpand = createToggleForExpanded({ expanded, setExpanded })

	useEffect(() => {
		if (!searchTerm) {
			setSearchResults([])
			setExpanded([])
		} else {
			const filteredTaxonomy = filterTaxonomy(searchTerm, taxonomy)
			setSearchResults(filteredTaxonomy)

			const filteredCodes = getCodes(searchTerm, filteredTaxonomy)
			setExpanded(filteredCodes)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	// Want to have the type taxonomy expanded by default
	useEffect(() => {
		if (taxonomyCategory === taxonomyCategories.TYPE) {
			setExpanded([{ code: 'rfp' }])
		}
	}, [taxonomyCategory])

	const wrappedHandleConfirm = async () => {
		await handleConfirm(selectedTags)
		setTaxonomyCategory(null)
	}

	const filteredTaxonomyData = searchTerm ? searchResults : taxonomy
	const allowParentSelection =
		taxonomyCategory === taxonomyCategories.LOCATION ||
		taxonomyCategory === taxonomyCategories.SERVICE ||
		taxonomyCategory === taxonomyCategories.AREA_OF_LAW

	if (fetchingTaxonomy) {
		return (
			<>
				<TaxonomySelectorHeader handleCancel={handleCancel} />
				<Box p={2}>
					<Typography variant="body1" mb={2}>
						<em>Loading taxonomy, please wait...</em>
					</Typography>
					<LinearProgress />
				</Box>
			</>
		)
	}

	return (
		<>
			<TaxonomySelectorHeader handleCancel={handleCancel} />
			{!taxonomyCategory && (
				<List>
					{taxonomySlots.includes('service') && (
						<>
							<Divider />
							<ListItem disablePadding={true}>
								<ListItemButton onClick={() => setTaxonomyCategory(taxonomyCategories.SERVICE)}>
									<ListItemText
										primary="Service"
										secondary={formatTags({
											tags: initialSelection?.service ?? [],
											taggedByName: taggedByLlm ? 'Tagged by LLM' : undefined,
										})}
									/>
									<ChevronRightIcon />
								</ListItemButton>
							</ListItem>
						</>
					)}
					{taxonomySlots.includes('areaOfLaw') && (
						<>
							<Divider />
							<ListItem disablePadding={true}>
								<ListItemButton onClick={() => setTaxonomyCategory(taxonomyCategories.AREA_OF_LAW)}>
									<ListItemText
										primary="Area of law"
										secondary={formatTags({
											tags: initialSelection?.areaOfLaw ?? [],
											taggedByName: taggedByLlm ? 'Tagged by LLM' : undefined,
										})}
									/>
									<ChevronRightIcon />
								</ListItemButton>
							</ListItem>
						</>
					)}
					{taxonomySlots.includes('location') && (
						<>
							<Divider />
							<ListItem disablePadding={true}>
								<ListItemButton onClick={() => setTaxonomyCategory(taxonomyCategories.LOCATION)}>
									<ListItemText
										primary="Location"
										secondary={formatTags({
											tags: initialSelection?.location ?? [],
											taggedByName: taggedByLlm ? 'Tagged by LLM' : undefined,
										})}
									/>
									<ChevronRightIcon />
								</ListItemButton>
							</ListItem>
						</>
					)}
					{taxonomySlots.includes('sourcing') && (
						<>
							<Divider />
							<ListItem disablePadding={true}>
								<ListItemButton onClick={() => setTaxonomyCategory(taxonomyCategories.SOURCING)}>
									<ListItemText
										primary="Sourcing"
										secondary={formatTags({ tags: initialSelection?.sourcing ?? [], taggedByName })}
									/>
									<ChevronRightIcon />
								</ListItemButton>
							</ListItem>
						</>
					)}
					<Divider />
				</List>
			)}
			{taxonomyCategory && (
				<>
					<Box px={2} pb={1}>
						<Button
							startIcon={<ArrowBackIcon />}
							onClick={() => {
								setSelectedTags(initialSelection)
								setTaxonomyCategory(null)
							}}
						>
							Back
						</Button>
					</Box>
					<Divider />
					<Taxonomy
						searchTerm={searchTerm}
						taxonomy={filteredTaxonomyData}
						toggleSelected={toggleSelected}
						toggleExpand={toggleExpand}
						selected={selected}
						expanded={expanded}
						handleSearch={setSearchTerm}
						handleConfirm={wrappedHandleConfirm}
						handleCancel={handleCancel}
						handleExclude={handleExclude}
						disableSort={taxonomyCategory === taxonomyCategories.TYPE}
						allowParentSelection={allowParentSelection}
					/>
				</>
			)}
		</>
	)
}

TaxonomySelector.defaultProps = {
	handleConfirm: () => {},
}
