import { useStore, useActions } from '../store'
import { PushPinIcon, Button, AutocompleteMultiSelect } from '@persuit/ui-components'
import responseStates from '../../../../common/data/rfp-proposal-states'
import { groupBy } from 'lodash/fp'
import formatCurrency from '../../../utils/format-currency.js'
import { isNotNil } from '@persuit/common-utils'

export const PinProposalsDropdown = () => {
	const { clearPinnedProposals, togglePinProposal } = useActions()
	const { displayedPinnedProposals, pinningDisabled, displayedResponses, responses, request } =
		useStore((state, s) => ({
			pinningDisabled: state.disabled,
			showUnpinned: state.showUnpinned,
			displayedPinnedProposals: s.displayedPinnedProposalIds(),
			displayedResponses: s.displayedResponses(),
			responses: state.responses,
			request: state.request,
		}))

	const currency = request.detail.currency

	const groupedResponses = groupBy(
		(p) =>
			p.status === responseStates.WITHDRAWN
				? 'withdrawn'
				: p.status === responseStates.REVISED
				? 'revised'
				: p.isEliminated
				? 'eliminated'
				: 'rest',
		// allResponses has a stable order so use it to prevent items swapping around in the auto complete
		responses.filter((r1) => displayedResponses.map((r2) => r2._id).includes(r1._id)),
	)

	const flatGroupedResponses = [
		{ group: null, responses: groupedResponses.rest },
		{ group: 'Withdrawn', responses: groupedResponses.withdrawn },
		{ group: 'Revised', responses: groupedResponses.revised },
		{ group: 'Unsuccessful', responses: groupedResponses.eliminated },
	].flatMap(({ group, responses = [] }) => responses.map((v) => ({ ...v, optionGroupName: group })))

	const proposalAutoCompleteOptions = flatGroupedResponses.map((p) => ({
		group: p.optionGroupName,
		orgName: p?.org?.name,
		title: [
			p.org?.name,
			p.totalPriceValue ? `(${formatCurrency(p.totalPriceValue, currency ?? '')})` : null,
		]
			.filter(isNotNil)
			.join(' '),
		proposalId: p._id,
		checked: displayedPinnedProposals.includes(p._id),
	}))

	if (pinningDisabled) return null

	return (
		<AutocompleteMultiSelect
			placeholder="Search..."
			label="Pinned proposals"
			data-trackid="autocomplete-searchToPin-proposals"
			getValue={(option) => option.proposalId}
			getLabel={(option) => option.title}
			onChange={(_, reason, details) => {
				const option = details?.option
				if ((reason === 'selectOption' || reason === 'removeOption') && option) {
					togglePinProposal(option.proposalId)
				}
			}}
			value={proposalAutoCompleteOptions
				.filter((option) => option.checked)
				.map((option) => option.proposalId)}
			options={proposalAutoCompleteOptions}
			selectedLabel="pinned"
			startIcon={<PushPinIcon color="primary" />}
			endIcon={
				<Button
					variant="text"
					onClick={clearPinnedProposals}
					aria-label="Clear all pinned proposals"
					data-trackid="iconbutton-clearpin-proposals"
				>
					Reset
				</Button>
			}
		/>
	)
}
