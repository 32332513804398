import moment from 'moment'
import { getOrEmptyArray, getOrFalse } from '../../utils/lenses'
import responseStates from '../../../common/data/rfp-proposal-states'
import {
	clientPhaseModel as clientDeliverablePricingModels,
	firmPhaseModel as firmDeliverablePricingModels,
	clientTotalModel as clientTotalPricingModels,
	firmTotalModel as firmTotalPricingModels,
} from '../../../common/data/pricing-models'
import questionTypes from '../../../common/data/question-types'

// Returns a matching pricingModel from the proposal
const getMatchingPricingModel = (deliverables, deliverableIndex, rateIndex) => {
	if (deliverables && deliverables[deliverableIndex]) {
		const deliverable = deliverables[deliverableIndex]
		if (deliverable && deliverable.rates) {
			const rate = deliverable.rates[rateIndex]
			return rate.pricingPreferences ? rate.pricingPreferences : undefined
		}
	}

	return undefined
}

// Create an example response to match the clients request
export const buildExampleResponse = ({
	clientTotalPricingPreference,
	deliverables = [],
	questions = [],
	diversityRequired,
}) => {
	const subject = 'Example response subject'

	const summaryOfProposal = '<p>Example response summary</p>'

	const textQuestionAnswer = 'Example answer'

	const richQuestionAnswer = '<p>Example answer</p>'

	// Pricing model should match the clients request
	// Special case is if the client has NONE for the preference
	// In this case set the response pricing model to FIXEDFEE
	let totalPriceModel = clientTotalPricingPreference
	if (clientTotalPricingPreference === clientTotalPricingModels.NONE) {
		totalPriceModel = firmTotalPricingModels.FIXEDFEE
	}

	return {
		_id: 999, // Arbitrary ID
		status: responseStates.EXAMPLE,
		subject,
		summaryOfProposal,
		totalPriceModel,
		lastBidTime: moment().subtract(3, 'hours'),
		answersToQuestions: questions.map(({ _id, type }) => ({
			type,
			stringAnswer: type === questionTypes.LONG ? richQuestionAnswer : textQuestionAnswer,
			choiceAnswer: true,
			numericAnswer: 99,
			questionId: _id,
		})),
		totalPriceValue: 999999999,
		responseToDeliverables: deliverables.map(
			({ _id: deliverableId, pricingPreferences, rates }, deliverableIndex) => {
				// Pricing model should match the clients request
				// Special case is if the client has NONE for the preference
				// In this case set the response pricing model to FIXEDFEE
				let pricingModel = pricingPreferences
				if (pricingPreferences === clientDeliverablePricingModels.NONE) {
					pricingModel = firmDeliverablePricingModels.FIXEDFEE
				}

				const newResponseToRates = rates
					? rates.map((requestedRate, rateIndex) => {
							const pricingModel = getMatchingPricingModel(
								deliverables,
								deliverableIndex,
								rateIndex,
							)
							const isItemPercentage = [
								firmDeliverablePricingModels.DISCOUNTPERCENTAGE,
								firmDeliverablePricingModels.CONTINGENCYPERCENTAGE,
							].includes(pricingModel)

							if (isItemPercentage) {
								return {
									rateId: requestedRate._id,
									percentage: 'XX',
									label: 'XXXX',
									pricingModel,
								}
							}

							return {
								rateId: requestedRate._id,
								rate: 'XX',
								hours: 'XX',
								label: 'XXXX',
								pricingModel,
							}
					  })
					: []

				const isTotalPercentage = [
					firmDeliverablePricingModels.DISCOUNTPERCENTAGE,
					firmDeliverablePricingModels.CONTINGENCYPERCENTAGE,
				].includes(pricingModel)

				if (isTotalPercentage) {
					return {
						deliverableId,
						pricingModel,
						responseToRates: newResponseToRates,
						percentage: 'XX',
					}
				}
				return {
					deliverableId,
					pricingModel,
					responseToRates: newResponseToRates,
					hours: 'XX',
					price: pricingModel === 'hourlyRates' ? 'XX - XX' : 'XXX',
				}
			},
		),
		diversityAnswers: diversityRequired
			? {
					answer1: { answer: true, isNa: false, comment: '' },
					answer2: { answer: 'XX', isNa: false, comment: '' },
					answer3: { answer: 'XX', isNa: false, comment: '' },
			  }
			: null,
		org: {
			certifications: ['NAMWOLF', 'MANSFIELD', 'MWBE'],
		},
	}
}

export const copyToClipboard = async (textToCopy) => {
	try {
		if ('clipboard' in navigator) {
			await navigator.clipboard.writeText(textToCopy)
		} else {
			// for older browsers
			document.execCommand('copy', true, textToCopy)
		}
	} catch (error) {
		console.error(error)
	}
}

export const hasActiveProposals = (proposals) => {
	return proposals.some(
		({ status }) =>
			status === responseStates.FINALIZED ||
			status === responseStates.UNDER_REVISION ||
			status === responseStates.ACCEPTED,
	)
}

export const hasQuestionsAndTotalPrice = (rfp, proposals) => {
	return (
		getOrEmptyArray('detail.questions', rfp).length > 0 &&
		(getOrFalse('detail.totalPriceRequired', rfp) === true ||
			proposals.some((proposal) => !!proposal.comparisonValue))
	)
}

export const allFirmsEliminated = (rfp) => {
	if (!rfp.invites) {
		return false
	}
	return rfp.invites.every((invite) => invite.status === 'eliminated')
}
