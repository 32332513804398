import { Fragment, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import DescriptionIcon from '@mui/icons-material/Description'
import { List, ListItemText, ListItemIcon, ListItem, Divider } from '@persuit/ui-components'
import FileSaver from 'file-saver'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardContent from '../../dialog-wizard-content'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import { defaultSteps } from '../../dialog-wizard-steps'
import { getOrEmptyString } from '../../../../utils/lenses'
import { stepIndex } from '../../utils.js'

const EXPORT_LOE_TEMPLATE_MUTATION = gql`
	mutation exportLoeTemplate($proposalId: ID!, $templateId: ID = "DEFAULT") {
		exportLoeTemplate(proposalId: $proposalId, templateId: $templateId) {
			data
		}
	}
`

const saveFile = (result, fileName) => {
	const fileData = getOrEmptyString('data.exportLoeTemplate.data', result)
	if (!fileData) throw new Error('No data in template')

	const buffer = Buffer.from(fileData, 'base64')

	FileSaver.saveAs(new Blob([buffer], { type: '' }), fileName)
}

function OptionGenerateLoeConfirmDefault({ onCancel, onPrev, onDone, proposalId }) {
	const [exportLoeTemplate, { loading, error }] = useMutation(EXPORT_LOE_TEMPLATE_MUTATION)
	const [saveError, setErrorState] = useState(null)

	const onConfirm = async () => {
		const result = await exportLoeTemplate({
			variables: {
				proposalId,
			},
		})

		try {
			saveFile(result, 'persuitStandardEngagementLetter.docx')
		} catch (e) {
			setErrorState(e)
			throw e
		}
		onDone()
	}

	return (
		<DialogWizardContent
			onCancel={onCancel}
			onPrev={onPrev}
			onConfirm={onConfirm}
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			isWorking={loading}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Generate Engagement Letter',
				[stepIndex(defaultSteps, 'ACTIONS')]: 'Select Template',
			}}
		>
			<DialogWizardConfirmationError error={error || saveError} />
			<strong>Download Engagement Letter</strong>
			<Spacer />
			<Divider />
			<List>
				<ListItem alignItems="center">
					<ListItemIcon>
						<DescriptionIcon />
					</ListItemIcon>
					<ListItemText
						primary={
							// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
							<span style={{ fontWeight: 500, color: '#444' }}>
								Standard Engagement Letter Template by PERSUIT
							</span>
						}
						secondary={
							<Fragment>
								<Text black={true}>
									This standard template includes an initial draft with details from the matter and
									proposal.
								</Text>
							</Fragment>
						}
					/>
				</ListItem>
				<Divider />
			</List>
		</DialogWizardContent>
	)
}

export default OptionGenerateLoeConfirmDefault
