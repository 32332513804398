import { useState } from 'react'
import { Box, NumberField, NumberFieldProps } from '@persuit/ui-components'
import { useDebouncedCallback } from 'use-debounce'

type PercentageTextFieldProps = {
	value?: number
	onChange: (value: number) => void
	dataTestid?: string
	errorText?: string
} & Partial<NumberFieldProps>

export const PercentageTextField = ({
	value,
	onChange,
	dataTestid,
	errorText,
	...rest
}: PercentageTextFieldProps) => {
	const [state, setState] = useState(value || null)
	const debouncedCallback = useDebouncedCallback((rawValue) => onChange(rawValue), 500)

	const handleChange = (updatedValue: number | null) => {
		setState(updatedValue)
		debouncedCallback(updatedValue)
	}

	return (
		<Box display="flex" alignItems="baseline" width="50%">
			<NumberField
				label=""
				id=""
				value={state as unknown as number}
				onChange={handleChange}
				decimalPlaces={2}
				integerPlaces={3}
				positiveOnly={true}
				min={0}
				max={100}
				data-testid={dataTestid}
				error={!!errorText}
				{...rest}
			/>
			<div>%</div>
		</Box>
	)
}
