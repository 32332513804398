import { SvgIcon, SvgIconProps } from '../components/svg'

type AddProposalIconProps = SvgIconProps & {
	isPrimary?: boolean
}

export const AddProposalIcon = ({ isPrimary, ...props }: AddProposalIconProps) => {
	return (
		<SvgIcon color={isPrimary ? 'primary' : 'inherit'} {...props}>
			<g>
				<path d="M7,12c-2.761,0-5,2.238-5,5s2.239,5,5,5s5-2.239,5-5S9.762,12,7,12z M10,18H8v2H6v-2H4v-2h2v-2h2v2h2V18z" />
			</g>
			<g>
				<path
					d="M21.393,12.555L10.892,2H2l0.021,8.848l1.366,1.37C4.392,11.457,5.641,11,7,11c3.314,0,6,2.686,6,6&#xA;&#x9;&#x9;c0,1.366-0.462,2.622-1.231,3.63l0.754,0.756c0.813,0.816,2.134,0.818,2.95,0.006l5.913-5.887&#xA;&#x9;&#x9;C22.202,14.692,22.205,13.371,21.393,12.555z M5.499,7C4.671,7,4,6.328,4,5.499S4.671,4,5.499,4C6.328,4,7,4.67,7,5.499&#xA;&#x9;&#x9;S6.328,7,5.499,7z"
				/>
			</g>
		</SvgIcon>
	)
}
