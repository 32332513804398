import { ChangeEvent } from 'react'
import * as React from 'react'
import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '../text-field'
import { Override } from '../../utilities'

const checkMin = (value: number, min?: number) => {
	if (typeof min === 'undefined' || isNaN(min)) return true
	return value >= min
}

const checkMax = (value: number, max?: number) => {
	if (typeof max === 'undefined' || isNaN(max)) return true
	return value <= max
}

export type IntegerNumberFieldProps = Override<
	Omit<MUITextFieldProps, 'multiline'>,
	{
		value?: number | null
		label: MUITextFieldProps['label']
		id: MUITextFieldProps['id']
		min?: number
		max?: number
		required?: boolean
		/**
		 * Will be called whenever the value changes.
		 * The value will be a number unless the input is empty in which case it will be null.
		 */
		onChange?: (value: number | null, event: ChangeEvent<HTMLInputElement>) => void
	}
>

export const IntegerNumberField = React.forwardRef(
	(
		{
			variant = 'standard',
			value,
			min,
			max,
			required,
			onChange,
			...props
		}: IntegerNumberFieldProps,
		ref: React.Ref<HTMLDivElement>,
	) => {
		return (
			<MUITextField
				{...props}
				value={value === undefined ? undefined : value === null ? '' : `${value}`}
				variant={variant}
				onChange={(e) => {
					const value = parseInt(e.target.value, 10)
					if (isNaN(value)) onChange?.(null, e as ChangeEvent<HTMLInputElement>)
					else if (checkMin(value, min) && checkMax(value, max))
						onChange?.(value, e as ChangeEvent<HTMLInputElement>)
				}}
				ref={ref}
				inputProps={{
					required,
					...props.inputProps,
				}}
			/>
		)
	},
)

IntegerNumberField.displayName = 'NumberField'
