import { useCallback } from 'react'

/**
 * This hook is one way (although not the preferred way) to handle the uncaught promise
 * eslint error. The preferred way is to inform the user in a way that's specific to your
 * component. This hook mostly exists to grandfather code that was not handling promises
 * correctly
 *
 * Use it as follows:
 *
 * const handleUnexpectedError = useHandleUnexpectedError()
 *
 * useEffect(() => {
 *     fetch('http://blah.com')
 *        .then((response) => {
 *           setResponse(response)
 *        })
 *        .catch(handleUnexpectedError)
 * }, [handleUnexpectedError])
 */
export function useHandleUnexpectedError(): (e: unknown) => void {
	return useCallback((e) => {
		// This is a placeholder. It is the equivalent of not catching the rejection
		// (ie display a message in the console and inform Sentry). In future we may
		// add a generic user notification, eg a toast message telling them that something
		// went wrong
		throw e
	}, [])
}
