import { createStore } from '@persuit/ui-state'
import { NestedOptions } from './nested-options'
import { selectors, flattenOptions } from './selectors'
import { difference, union } from 'lodash'

export type RawValue = {
	_id: string
	label: string
	values?: Array<RawValue>
}

export type Value = {
	_id: string
	label: string
	values: Array<Value>
	/** Default to true */
	defaultExpanded?: boolean
}

export type State = {
	virtualize?: boolean
	headersSelectable: boolean
	autocompleteId: string
	selections: string[]

	options: NestedOptions

	highlightedId: string
	searchTerm: string
	collapsedState: Record<string, boolean>

	onChange: (selections: string[]) => void
}

type SetupInput = {
	virtualize?: boolean
	headersSelectable: boolean
	autocompleteId: string
	selections: string[]
	options: RawValue[]
	onChange: (selections: string[]) => void
}

const store = createStore(
	({
		options: rawOptions,
		selections,
		onChange,
		autocompleteId,
		headersSelectable,
		virtualize,
	}: SetupInput): State => {
		const options = new NestedOptions(rawOptions)

		const collapsedState = options
			.filter((value) => value.defaultExpanded === false)
			.array()
			.reduce(
				(acc, curr) => ({
					...acc,
					[curr._id]: true,
				}),
				{},
			)

		return {
			virtualize,
			headersSelectable,
			autocompleteId,
			onChange,

			selections: selections.filter((selectedId) => options.hasOption(selectedId)),

			searchTerm: '',
			highlightedId: '',

			collapsedState,

			options,
		}
	},
	(set, get) => {
		const setHighlighted = (id: string) => {
			const autocompleteId = get().autocompleteId
			const element = document.getElementById(id)
			// this is the list id given to the listbox component
			const container = document.getElementById(`${autocompleteId}-listbox`)
			if (element && container) {
				const eleRect = element.getBoundingClientRect()
				const containerRect = container.getBoundingClientRect()

				const offsetTop = eleRect.top - containerRect.top
				const above = offsetTop < 0
				const offsetBottom = eleRect.bottom - containerRect.bottom
				const below = offsetBottom > 0
				const shouldScroll = above || below
				if (shouldScroll) {
					element.scrollIntoView({ block: 'nearest' })
				}
			}

			set({ highlightedId: id })
		}

		return {
			isField: (id: string) => selectors.isField(get(), id),
			isValue: (id: string) => selectors.isValue(get(), id),
			isSubValue: (id: string) => selectors.isSubValue(get(), id),
			isValueWithSubValues: (id: string) => selectors.isValueWithSubValues(get(), id),

			setHighlighted,
			highlightNext: () => {
				const { highlightedId, virtualize } = get()
				const visibleIds = selectors.visibleIds(get())
				const visibleIdCount = visibleIds.length
				const rawIndex = visibleIds.indexOf(highlightedId ?? '') + 1
				const index = virtualize
					? Math.min(visibleIdCount - 1, rawIndex)
					: (rawIndex + visibleIdCount) % visibleIds.length
				setHighlighted(visibleIds[index])
			},
			highlightPrevious: () => {
				const { highlightedId, virtualize } = get()
				const visibleIds = selectors.visibleIds(get())
				const rawIndex = visibleIds.indexOf(highlightedId ?? '') - 1
				const index = virtualize
					? Math.max(0, rawIndex)
					: (rawIndex + visibleIds.length) % visibleIds.length
				setHighlighted(visibleIds[index])
			},
			setSearchTerm: (searchTerm: string) => set({ searchTerm }),
			toggleCollapse: (id: string) => {
				const { searchTerm } = get()
				if (!searchTerm) {
					set(({ collapsedState }) => ({
						collapsedState: { ...collapsedState, [id]: !collapsedState[id] },
					}))
				}
			},
			expandCollapseAll: () => {
				const { searchTerm } = get()

				if (!searchTerm) {
					set((state) => ({
						collapsedState: Object.fromEntries(
							state.options
								.array()
								.flatMap((field) => [field, ...field.values])
								.map((field) => [field._id, !selectors.allCollapsed(state)]),
						),
					}))
				}
			},

			select: (id: string) => {
				const state = get()
				const { options, selections, onChange } = state
				const filteredOptions = selectors.filteredOptions(state)

				const option = options.valueById(id)
				const filteredOption = filteredOptions.valueById(id)

				function toggle(id: string) {
					return options.isSelected(id, selections)
						? selections.filter((selectedId) => selectedId !== id)
						: [...selections, id]
				}

				if (option.values.length === 0) {
					return onChange(toggle(option._id))
				} else {
					const idsToSelect = flattenOptions(filteredOption.values).map((o) => o._id)
					const selectAll = idsToSelect.every((id) => !filteredOptions.isSelected(id, selections))
					return onChange(
						selectAll ? union(selections, idsToSelect) : difference(selections, idsToSelect),
					)
				}
			},
		}
	},
	selectors,
)

export const { Provider, useActions, useStore, useStoreApi } = store

export { selectors } from './selectors'
