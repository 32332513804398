import { Fragment } from 'react'
import { Typography, Spacer, Box, InfoIcon } from '@persuit/ui-components'
import {
	RfpDetailConflictSection,
	RfpDetailConflictSectionDocType,
} from 'packages/ui-shared-components/src/rfp-detail/components/conflict'
import { getRequestedBy } from '../../../rfp-detail/util'
import { LabeledValue } from '../../../rfp-detail/components/labeled-value'
import { DeepPick } from '@persuit/common-utils'
import { Rfp } from '../../../types'
import { FormattedDate } from '../../../common-components/formatted-date'

export type ConflictRevealContentDocType = RfpDetailConflictSectionDocType & {
	org?: {
		name?: string | null
	} | null
	requestedBy?: string | null
} & DeepPick<Rfp, 'auction' | 'initialProposalsDueBy' | 'proposalsDueBy'>

type ConflictRevealProps = {
	document: ConflictRevealContentDocType
}

export const ConflictRevealContent = ({ document }: ConflictRevealProps) => {
	const orgName = document?.org?.name ?? ''
	const requestedBy = document?.requestedBy ?? ''

	const { auction, initialProposalsDueBy, proposalsDueBy } = document

	return (
		<Fragment>
			<Box mb={1} display="flex" alignItems="center">
				<InfoIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
				<Typography variant="body1" color="primary">
					This is what the invited firms will see before they've cleared conflicts and/or accepted
					terms and/or signed an NDA
				</Typography>
			</Box>

			<Box display="flex">
				<Box width="50%">
					<LabeledValue label="Request from:" value={getRequestedBy(orgName, requestedBy)} />
				</Box>

				{auction ? (
					initialProposalsDueBy ? (
						<Box width="50%">
							<LabeledValue
								label="Proposals due by:"
								value={<FormattedDate date={initialProposalsDueBy} />}
							/>
						</Box>
					) : null
				) : proposalsDueBy ? (
					<Box width="50%">
						<LabeledValue
							label={'Proposals due by:'}
							value={<FormattedDate date={proposalsDueBy} />}
						/>
					</Box>
				) : null}
			</Box>

			<Spacer />
			<RfpDetailConflictSection document={document} />
		</Fragment>
	)
}
