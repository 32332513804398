import { format } from 'date-fns'
import { Box, Divider } from '@persuit/ui-components'
import { formatPriceWithCurrency, isNotNilProp } from '@persuit/common-utils'
import { RateItem } from '@persuit/ui-graphql/generated'
import { averageRate } from '@persuit/common-logic'

const getFormattedPrice = (price: string | number | undefined | null, currency: string) => {
	if (!price) {
		return '-'
	}

	return formatPriceWithCurrency(currency, price, true, '0,0.[00]')
}

type RateCardTableRowProps = {
	currency: string
	notIncluded: boolean | null | undefined
	deliverableNumber: number
	updatedAt: number | null | undefined
	rates: RateItem[]
}

export const RateCardTableRow = ({
	rates,
	currency,
	notIncluded,
	updatedAt,
	deliverableNumber,
}: RateCardTableRowProps) => {
	const lastUpdatedDate = updatedAt ? format(updatedAt, 'dd MMM yyyy, hh:mma') : null
	const averageOfRates = averageRate(rates.filter(isNotNilProp('rate')))
	// const averageOfAllRates = formatPriceWithCurrency(currency, averageRate, true, '0,0.[00]')

	// TODO: not needed, since excluded from scope text at top of table/ not included always false ?
	if (notIncluded) {
		return (
			<Box
				component={'tr'}
				sx={(theme) => ({
					borderBottom: `0.5px solid ${theme.palette.border.divider}`,
				})}
			>
				<Box component={'td'} sx={{ py: 2 }}>
					{lastUpdatedDate}
				</Box>
				<Box component={'td'} colSpan={3}>
					Excluded from scope
				</Box>
			</Box>
		)
	}

	return (
		<Box
			component={'tr'}
			sx={(theme) => ({
				borderBottom: `1px solid ${theme.palette.border.main}`,
			})}
		>
			<Box component={'td'} sx={{ py: 2, verticalAlign: 'top' }}>
				{lastUpdatedDate}
			</Box>
			<Box component={'td'} sx={{ py: 2, px: 0 }}>
				<Box display="flex" flexDirection="column" justifyContent="flex-start">
					{rates.map(({ _id, label }, rateIndex: number) => (
						<Box component="span" key={_id}>{`${deliverableNumber}.${rateIndex + 1} ${label}`}</Box>
					))}
					<Divider
						flexItem={true}
						sx={(theme) => ({
							my: 1.5,
							borderBottom: `0.5px solid ${theme.palette.border.divider}`,
						})}
					/>
					<Box component={'span'} sx={{ typography: 'body1Semibold' }}>
						Average Rate
					</Box>
				</Box>
			</Box>
			<Box component={'td'} sx={{ py: 2, px: 0 }}>
				<Box display="flex" flexDirection="column" alignItems={'flex-end'}>
					{rates.map(({ _id, rate }) => (
						<Box key={_id} component="span">
							{getFormattedPrice(rate, currency)}
						</Box>
					))}
					<Divider
						flexItem={true}
						sx={(theme) => ({
							my: 1.5,
							borderBottom: `0.5px solid ${theme.palette.border.divider}`,
						})}
					/>
					<Box component="span" sx={{ typography: 'body1Semibold' }}>
						{formatPriceWithCurrency(currency, averageOfRates, true, '0,0.[00]')}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
