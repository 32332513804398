import {
	GavelIcon,
	CancelIcon,
	CheckCircleIcon,
	LocalLibraryIcon,
	ScheduleIcon,
} from '@persuit/ui-components'
import { BaseStatus } from '@client/components/base-status'

export const AuctionInProgress = ({ statusLine2, statusLine3, expanded = true }) => (
	<BaseStatus
		iconNode={<GavelIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'AUCTION IN PROGRESS'}
		secondaryText={statusLine2}
		tertiaryText={statusLine3}
		variant={'PINK'}
		expanded={expanded}
	/>
)

export const Completed = ({ statusLine3, expanded = true }) => (
	<BaseStatus
		iconNode={<CheckCircleIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'COMPLETED'}
		tertiaryText={statusLine3}
		variant={'GREY'}
		expanded={expanded}
	/>
)

export const Evaluating = ({ statusLine2, statusLine3, expanded = true }) => (
	<BaseStatus
		iconNode={<LocalLibraryIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'EVALUATING'}
		secondaryText={statusLine2}
		tertiaryText={statusLine3}
		variant={'YELLOW'}
		expanded={expanded}
	/>
)

export const AwaitingAuction = ({ statusLine2, statusLine3, expanded = true }) => (
	<BaseStatus
		iconNode={<ScheduleIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'AWAITING AUCTION'}
		secondaryText={statusLine2}
		tertiaryText={statusLine3}
		variant={'YELLOW'}
		expanded={expanded}
	/>
)

export const OpenToProposals = ({ statusLine2, statusLine3, expanded = true }) => (
	<BaseStatus
		iconNode={<ScheduleIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'OPEN TO PROPOSALS'}
		secondaryText={statusLine2}
		tertiaryText={statusLine3}
		variant={'GREEN'}
		expanded={expanded}
	/>
)

export const Withdrawn = ({ statusLine2, expanded = true }) => (
	<BaseStatus
		iconNode={<CancelIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'WITHDRAWN'}
		secondaryText={statusLine2}
		variant={'GREY'}
		expanded={expanded}
	/>
)
