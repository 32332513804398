import { AutocompleteMultiSelect } from '@persuit/ui-components'
import { mostUsedCurrencies, otherCurrencies, allCurrencyCodes, Currency } from './config'

export type MultiCurrencySelectProps = {
	error?: boolean
	helperText?: string
	label: string
	onChange: (values: string[]) => void
	value: string[]
	getDisabled?: (option: Currency) => boolean
}

export const selectedLabel = (selectedCurrencyCodes: string[]): string => {
	// nothing selected
	if (!selectedCurrencyCodes || selectedCurrencyCodes.length === 0) return 'Select currency'

	// 1 selected
	if (selectedCurrencyCodes.length === 1) {
		// find the currency label by code
		const foundCurrency = mostUsedCurrencies
			.concat(otherCurrencies)
			.find(({ value }) => value === selectedCurrencyCodes[0])

		return foundCurrency
			? `${foundCurrency.label} (${foundCurrency.value})`
			: selectedCurrencyCodes[0]
	}

	// all selected
	if (selectedCurrencyCodes.length === allCurrencyCodes.length) return `All currencies selected`

	// some selected (more than 1 and less than all)
	return `${selectedCurrencyCodes.length} selected`
}

export const MultiCurrencySelect = ({
	error = false,
	helperText,
	label,
	onChange,
	value,
	getDisabled,
}: MultiCurrencySelectProps) => {
	return (
		<AutocompleteMultiSelect
			getDisabled={getDisabled}
			enableSelectAll={true}
			error={error}
			getLabel={(option: Currency) => `${option.label} (${option.value})`}
			getSelectedLabel={selectedLabel}
			getValue={(option) => option.value}
			helperText={helperText}
			label={label}
			onChange={onChange}
			options={[...mostUsedCurrencies, 'divider', ...otherCurrencies]}
			value={value}
			variant="outlined"
		/>
	)
}
