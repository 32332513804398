import { memo } from 'react'
import numeral from 'numeral'

import { RichTextView, Typography, TypographyProps, Box } from '@persuit/ui-components'

import rfpProposalStates from '../../../../../common/data/rfp-proposal-states'
import questionTypeValues from '../../../../../common/data/question-types'
import {
	getAnswerOptionLabel,
	getAnswerOptionLabels,
} from '../../../../../common/proposals/get-answer-option-label'
import formatPriceCurrency from '../../../../utils/price-and-currency'
import { Cell, ExpandableCell, CellProps } from '../components'

import { useStore } from '../../store'
import { Request } from '../types'

const Text = (props: TypographyProps) => (
	<Typography variant="body1Semibold" fontSize="20px" {...props} />
)

type QuestionAnswerCellProps = CellProps & {
	currency: string
	index?: number
	groupIndex: number
	questionAnswer: any
	questionTitle: string
	questionOptions: NonNullable<NonNullable<Request['detail']['questions']>[number]>['options']
	responseId: string
	responseStatus: string
	groupName: string | undefined
}

const QuestionAnswerCell = ({
	currency,
	index,
	groupIndex,
	groupName,
	questionAnswer,
	questionTitle,
	questionOptions,
	responseStatus,
	...rest
}: QuestionAnswerCellProps) => {
	const { isWide, truncateCellContents } = useStore((state, s) => ({
		isWide: s.isWide(rest.responseId),
		truncateCellContents: state.truncateCellContents,
	}))

	const truncationLength = isWide ? 500 : 250
	const truncationValue = truncateCellContents ? truncationLength : undefined

	const isCompetitor = responseStatus === rfpProposalStates.COMPETITOR

	// Special case for competitor responses
	if (isCompetitor) {
		return (
			<Cell {...rest}>
				<Text>Not displayed</Text>
			</Cell>
		)
	}

	// Special case when there is no answer
	if (!questionAnswer) {
		return (
			<Cell {...rest}>
				<Text>No answer</Text>
			</Cell>
		)
	}

	const {
		choiceAnswer,
		stringAnswer,
		numericAnswer,
		singleSelectAnswer,
		multipleSelectAnswer,
		comments,
		type,
	} = questionAnswer

	const getContent = (truncate: boolean) => (
		<>
			{type === questionTypeValues.CHOICE ? (
				<Text>{choiceAnswer === null ? 'No answer' : choiceAnswer ? 'Yes' : 'No'}</Text>
			) : type === questionTypeValues.PRICE ? (
				<Text>{stringAnswer ? formatPriceCurrency(currency, stringAnswer) : ''}</Text>
			) : type === questionTypeValues.PERCENTAGE ? (
				<Text>
					{typeof numericAnswer === 'number'
						? `${numeral(numericAnswer).format('0,0.[00000000]')}%`
						: ''}
				</Text>
			) : type === questionTypeValues.NUMERIC ? (
				<Text>{stringAnswer ? numeral(stringAnswer).format('0,0.[00000000]') : ''}</Text>
			) : type === questionTypeValues.SINGLE_SELECT || type === questionTypeValues.SCALE ? (
				singleSelectAnswer && (
					<Text>{getAnswerOptionLabel({ options: questionOptions, answer: questionAnswer })}</Text>
				)
			) : type === questionTypeValues.MULTIPLE_SELECT ? (
				multipleSelectAnswer && (
					<>
						{getAnswerOptionLabels({ options: questionOptions, answer: questionAnswer }).map(
							(text, index) => (
								<Text key={index}>{text}</Text>
							),
						)}
					</>
				)
			) : type === questionTypeValues.LONG ||
			  type === questionTypeValues.MEDIUM ||
			  type === questionTypeValues.SHORT ? (
				<RichTextView
					content={questionAnswer.stringAnswer}
					truncationLength={truncate ? truncationValue : undefined}
				/>
			) : null}
			{comments && (
				<Box mt={2}>
					<RichTextView
						content={comments}
						truncationLength={truncate ? truncationValue : undefined}
					/>
				</Box>
			)}
		</>
	)

	if (index === undefined) {
		return (
			<ExpandableCell
				expandedTitle={`${groupIndex + 1}. ${questionTitle}`}
				expandedSubTitle={''}
				expandedContent={getContent(false)}
				{...rest}
			>
				{getContent(true)}
			</ExpandableCell>
		)
	}

	return (
		<ExpandableCell
			expandedTitle={`${groupIndex + 1}.${index + 1}. ${questionTitle}`}
			expandedSubTitle={`${groupIndex + 1}. ${groupName}`}
			expandedContent={getContent(false)}
			{...rest}
		>
			{getContent(true)}
		</ExpandableCell>
	)
}

export default memo(QuestionAnswerCell)
