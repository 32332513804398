import { useEffect } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { usePricingListContext, PricingListContextValue } from '../../../pricing-list-context'
import { useWatch, useFormContext } from '../../../pricing-form-utils'
import { PricingModelSelect } from '../../../../common-components'
import { PricingSubItemContent } from './pricing-sub-item-content'
import { PricingSubItemContentPopover } from './pricing-sub-item-content-popover'
import { PricingCardItemNumbering } from '../../../../pricing'
import {
	FormTextField,
	SortableDragHandler,
	Tooltip,
	Box,
	PopoverHelp,
} from '@persuit/ui-components'
import { usePricingSubItemListContext } from '../../pricing-sub-item-list-context'
import { filterSubItemPricingModels } from '../../pricing-sub-item-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

type PricingSubItemProps = {
	sortableId: string
	pricingSubItemIndex: number
	isRateCard?: boolean
}

export const PricingSubItem = ({
	pricingSubItemIndex,
	isRateCard,
	sortableId,
}: PricingSubItemProps) => {
	const { errors } = usePricingListContext()
	const { setValue } = useFormContext()
	const {
		pricingItemIndex,
		pricingItemFieldPath,
		pricingSubItemsFieldArray: { remove },
	} = usePricingSubItemListContext()

	const pricingItemModel = useWatch({
		name: `${pricingItemFieldPath}.pricingPreferences`,
	})
	const pricingSubItem = useWatch({
		name: `${pricingItemFieldPath}.rates.${pricingSubItemIndex}`,
	})
	const { toggles } = useFeatureToggles()

	const isOriginalPricingSubItem = !!pricingSubItem.originalRateId

	/** When switch from rate card to other pricing model.
	 * If sub-item doesn't have existing pricing model, try to default to pricing item model or fixedFee */
	useEffect(() => {
		if (pricingItemModel !== 'hourlyRates' && !pricingSubItem.pricingPreferences) {
			const newSubItemPricingPreferences =
				filterSubItemPricingModels({
					pricingModel: pricingItemModel,
				}) ?? 'fixedFee'
			setValue(
				`${pricingItemFieldPath}.rates.${pricingSubItemIndex}.pricingPreferences`,
				newSubItemPricingPreferences,
			)
		}
	}, [pricingItemFieldPath, pricingItemModel, pricingSubItem, pricingSubItemIndex, setValue])

	const pricingSubItemError = getPricingSubItemError(errors, pricingItemIndex, pricingSubItemIndex)

	const removeCurrentPricingSubItem = () => remove(pricingSubItemIndex)
	const onRemove = isOriginalPricingSubItem ? undefined : removeCurrentPricingSubItem

	const dragHandler = !isOriginalPricingSubItem ? <SortableDragHandler id={sortableId} /> : null

	const hasSubItemFieldValidationErrors = pricingSubItemError && (pricingSubItemError?.label ?? '')
	const pricingSubItemTextField = (
		<FormTextField
			required={true}
			error={!!hasSubItemFieldValidationErrors}
			disabled={isOriginalPricingSubItem}
			helperText={hasSubItemFieldValidationErrors}
			label="Title"
			name={`${pricingItemFieldPath}.rates.${pricingSubItemIndex}.label`}
			fullWidth={true}
			inputProps={{ maxLength: '50', 'data-testid': 'sub-item-title-input' }} // Maximum length of item title is 200 characters
		/>
	)
	const subItemTitle = isOriginalPricingSubItem ? (
		<Tooltip
			title={
				isRateCard
					? 'Once set, rate card title cannot be edited'
					: 'Once set, sub-item title cannot be edited'
			}
			arrow={true}
		>
			<Box p="0 10px" flexGrow="1" data-testid="sub-item-title">
				{pricingSubItemTextField}
			</Box>
		</Tooltip>
	) : (
		<Box p="0 10px" flexGrow="1" data-testid="sub-item-title">
			{pricingSubItemTextField}
		</Box>
	)

	const pricingModelSelect = (
		<PricingModelSelect
			id={`${pricingItemIndex}-sub-item-pricing-select-${pricingSubItemIndex}`}
			name={`${pricingItemFieldPath}.rates.${pricingSubItemIndex}.pricingPreferences`}
			hasValidationErrors={pricingSubItemError ? pricingSubItemError?.pricingPreferences ?? '' : ''}
			type={toggles['dev-3327.contingency-percentage'] ? 'subItem-dev-3327' : 'subItem'}
			disabled={isOriginalPricingSubItem}
		/>
	)

	const subItemPricingModel = (
		<>
			{isOriginalPricingSubItem ? (
				<Tooltip title="Once set, the pricing structure cannot be altered" arrow={true}>
					<Box width="100%">{pricingModelSelect}</Box>
				</Tooltip>
			) : (
				pricingModelSelect
			)}
			<PopoverHelp
				triggerButtonProps={{
					'aria-label': `Sub-item ${pricingSubItemIndex + 1} pricing model help`,
				}}
				content={
					<PricingSubItemContentPopover isOriginalPricingSubItem={isOriginalPricingSubItem} />
				}
			/>
		</>
	)

	return (
		<Box
			aria-label={pricingSubItem.label ?? 'Untitled sub-item'}
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
			role="group"
			id={`pricing_sub_item_${pricingItemIndex}_${pricingSubItemIndex}`}
		>
			<PricingSubItemContent
				dragHandler={dragHandler}
				subItemNumber={
					<PricingCardItemNumbering
						parentIndex={pricingItemIndex}
						childIndex={pricingSubItemIndex}
					/>
				}
				subItemIndex={pricingSubItemIndex}
				subItemTitle={subItemTitle}
				subItemPricingModel={isRateCard ? null : subItemPricingModel}
				onRemove={onRemove}
				isRateCard={isRateCard}
			/>
		</Box>
	)
}

const getPricingSubItemError = (
	pricingErrors: PricingListContextValue['errors'],
	pricingItemIndex: number,
	pricingSubItemIndex: number,
) => {
	if (isEmpty(pricingErrors)) {
		return false
	}

	const priceItemError = (pricingErrors?.pricingItemErrors ?? []).find(
		(error) => error.index === pricingItemIndex,
	)

	if (!priceItemError || isEmpty(priceItemError.rateErrors)) {
		return false
	}

	return priceItemError?.rateErrors?.find((rateError) => rateError.index === pricingSubItemIndex)
}
