import { Component } from 'react'
import classnames from 'classnames'

import injectSheet from '../../injectSheet'

import clearfix from '../../utils/clearfix'
import baseTheme from '../../theme/persuit-theme'

const { desktopGutter, desktopMq, mobileGutter } = baseTheme.grid

const styles = {
	container: {
		display: 'block',
		// For ie
		width: '100%',
		// For everything
		maxWidth: '100%',
		flexBasis: '100%',
		boxSizing: 'border-box',
		paddingLeft: `${mobileGutter / 2}px`,
		paddingRight: `${mobileGutter / 2}px`,
		[desktopMq]: {
			paddingLeft: `${desktopGutter / 2}px`,
			paddingRight: `${desktopGutter / 2}px`,
		},
		'&:before': clearfix,
		'&:after': clearfix,
	},
}

const fixedWidthStyles = {}
Object.keys(baseTheme.grid.breakpoints).forEach((breakpoint) => {
	const { minWidth, containerWidth } = baseTheme.grid.breakpoints[breakpoint]
	fixedWidthStyles[`@media (min-width: ${minWidth})`] = {
		width: containerWidth,
	}
})

styles['container-fixed'] = {
	...fixedWidthStyles,
}

class Container extends Component {
	static defaultProps = {
		component: 'div',
		fixed: false,
	}

	render() {
		const {
			// Extract stuff form ...props to avoid warnings
			sheet,
			i18nLoadedAt,

			children,
			className,
			component,
			fixed,
			classes,
			...props
		} = this.props

		const Component = component
		const classNames = classnames(classes.container, {
			[className]: !!className,
			[classes['container-fixed']]: !!fixed,
		})

		return (
			<Component className={classNames} {...props}>
				{children}
			</Component>
		)
	}
}

export default injectSheet(styles)(Container)
