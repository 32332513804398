import { styled, Alert } from '@persuit/ui-components'

const StyledFirmName = styled('div')`
	font-weight: 500;
	margin-bottom: 0.35em;
`

const MAX_SUMMARY_LENGTH = 300
const MAX_SUMMARY_LENGTH_WIDE = 600

export const getMaxSummaryLength = (isWide: boolean) =>
	isWide ? MAX_SUMMARY_LENGTH_WIDE : MAX_SUMMARY_LENGTH

type CompletionData = {
	summary?: string
	firms?: Array<{
		firmName: string
		points: Array<string>
	}>
}

export const formatExtendedSummary = (data: CompletionData) => {
	return (
		<>
			{data.summary && <div>{data.summary}</div>}

			{data.firms &&
				data.firms.map((firm, i: number) => {
					return (
						<>
							{firm.firmName && (
								<div>
									<StyledFirmName key={i}>{firm.firmName}</StyledFirmName>
								</div>
							)}
							{firm.points && (
								<ul>
									{firm.points.map((point: string, j: number) => {
										return <li key={j}>{point}</li>
									})}
								</ul>
							)}
						</>
					)
				})}
		</>
	)
}

export const Error = ({ code }: { code: string | null | undefined }) => {
	switch (code) {
		case 'context_length_exceeded':
			return (
				<Alert severity="info" sx={{ mb: 1, border: 'none' }}>
					Unfortunately the provided responses exceeds the maximum limit for processing.
				</Alert>
			)

		default:
			return (
				<Alert severity="info" sx={{ mb: 1, border: 'none' }}>
					There was an issue generating an analysis. Please try again later.
				</Alert>
			)
	}
}
