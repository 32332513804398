import { useState, useEffect } from 'react'
import domPurify from 'dompurify'
import { Box, BoxProps } from '../../box'
import style from '../style'
import { styled } from '../../../theme'

const StyledRichTextContainer = styled(Box)`
	${style}

	@media print {
		font-weight: normal;
	}
`

export type RichTextViewProps = BoxProps & {
	/** HTML content (string) to be rendered */
	content: string | null
	truncationLength?: number
	truncatedContentSuffix?: string
}

const getTruncatedContent = (
	content: string,
	truncatedContentSuffix: string,
	truncationLength?: number,
	whilstPrinting = false,
) => {
	const getStrippedContent = () => content.replace(/<(?:.|\n)*?>/g, '')

	// Strip off HTML content whilst printing
	if (whilstPrinting) {
		return getStrippedContent()
	}

	if (!truncationLength) return content

	const strippedContent = getStrippedContent()
	if (strippedContent.length + 1 > truncationLength)
		return `${strippedContent.slice(0, truncationLength)}${truncatedContentSuffix}`

	return content
}

export const RichTextView = ({
	content,
	truncationLength,
	truncatedContentSuffix = '…',
	...props
}: RichTextViewProps) => {
	const [whilstPrinting, setWhilstPrinting] = useState(false)
	const truncatedContent = getTruncatedContent(
		content ?? '',
		truncatedContentSuffix,
		truncationLength,
		whilstPrinting,
	)

	useEffect(() => {
		const beforePrintListener = () => {
			setWhilstPrinting(true)
		}
		const afterPrintListener = () => {
			setWhilstPrinting(false)
		}
		window.addEventListener('beforeprint', beforePrintListener)
		window.addEventListener('afterprint', afterPrintListener)

		return () => {
			window.removeEventListener('beforeprint', beforePrintListener)
			window.removeEventListener('afterprint', afterPrintListener)
		}
	}, [])

	return (
		<Box sx={{ width: '100%', overflowX: 'auto' }}>
			<StyledRichTextContainer
				className="rich-text"
				dangerouslySetInnerHTML={{ __html: domPurify.sanitize(truncatedContent) }}
				{...props}
			/>
		</Box>
	)
}

RichTextView.displayName = 'RichTextView'
