import { useContext, useEffect, useId, useState } from 'react'

import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from '../../icons'
import {
	Slider,
	SliderProps,
	Slide,
	SlideProps,
	ButtonBack,
	ButtonNext,
	ButtonBackProps,
	ButtonNextProps,
	CarouselContext,
} from 'pure-react-carousel'
import { styled, css } from '../../theme'
import { DeprecatedCarouselStyles } from './deprecated-carousel-styles'
import { PartialKeys } from '@persuit/common-utils'

export {
	ButtonBack as CarouselButtonBack,
	ButtonNext as CarouselButtonNext,
	DotGroup as CarouselDotGroup,
	CarouselProvider,
} from 'pure-react-carousel'

type StyledSlideProps = { disableFocusStyle?: boolean }

const StyledSlide = styled(Slide)<StyledSlideProps>(({ disableFocusStyle, theme }) =>
	disableFocusStyle
		? css`
				& .focusRing___1airF {
					outline-style: none;
					outline-color: -webkit-focus-ring-color;
				}
		  `
		: css`
				& .focusRing___1airF {
					outline-color: ${theme.palette.primary.main};
				}
		  `,
)

type DeprecatedCarouselSliderProps = SliderProps & {
	disableFocus?: boolean
}

/** @deprecated Use Carousel instead */
export const DeprecatedCarouselSlider = ({
	disableFocus,
	...props
}: DeprecatedCarouselSliderProps) => {
	const id = useId()

	// When carousel is only showing 1 slide at a time, remove ability to focus on the whole list for it to work with JAWS
	useEffect(() => {
		const sliderContainer = document.getElementById(id)
		if (disableFocus && sliderContainer) {
			sliderContainer.removeAttribute('tabindex')
		}
	}, [disableFocus, id])

	return (
		<>
			<DeprecatedCarouselStyles />
			<Slider
				id={id}
				role={undefined}
				{...props}
				trayProps={{ role: 'list', ...props.trayProps }}
			/>
		</>
	)
}

export type DeprecatedCarouselSlideProps = SlideProps &
	StyledSlideProps &
	(
		| {
				/** Required for accessibility purpose */
				'aria-label': string
		  }
		| { 'aria-labelledby': string }
	)

/** @deprecated Use Carousel instead */
export const DeprecatedCarouselSlide = ({
	index,
	onFocus,
	...rest
}: DeprecatedCarouselSlideProps) => {
	const {
		setStoreState,
		state: { visibleSlides },
	} = useCarouselContext()
	const currentSlide = useCarouselCurrentSlide()

	const id = useId()

	// Auto self focus if current side is this slide, only when carousel is showing 1 slide at a time
	useEffect(() => {
		const slider = document.getElementById(id)
		if (currentSlide === index && slider && visibleSlides === 1) {
			slider.focus()
		}
	}, [currentSlide, id, index, visibleSlides])

	return (
		<StyledSlide
			index={index}
			role="listitem"
			id={id}
			aria-selected={undefined}
			tabIndex={-1}
			onFocus={() => {
				onFocus?.()
				setStoreState({ currentSlide: index })
			}}
			{...rest}
		/>
	)
}

const useCarouselContext = () => useContext(CarouselContext)

const useCarouselCurrentSlide = (): number => {
	const carouselContext = useContext(CarouselContext)
	const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)

	useEffect(() => {
		const subscriber = () => setCurrentSlide(carouselContext.state.currentSlide)
		carouselContext.subscribe(subscriber)
		return () => carouselContext.unsubscribe(subscriber)
	}, [carouselContext, setCurrentSlide])

	return currentSlide
}

const StyledButtonBack = styled(ButtonBack)`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	background: transparent;
	box-shadow: none;
	border: none;
`

export type CarouselFloatingButtonBackProps = PartialKeys<ButtonBackProps, 'children'>

/** @deprecated Use Carousel instead */
export const DeprecatedCarouselFloatingButtonBack = ({
	children,
	...rest
}: CarouselFloatingButtonBackProps) => {
	const currentSlide = useCarouselCurrentSlide()

	const disabled = currentSlide === 0

	return (
		<StyledButtonBack
			data-testid="back-button"
			disabled={disabled}
			tabIndex={-1}
			aria-label="previous slide"
			aria-hidden="true"
			{...rest}
		>
			{children ?? (
				<KeyboardArrowLeftIcon fontSize="large" color={disabled ? 'disabled' : 'primary'} />
			)}
		</StyledButtonBack>
	)
}

const StyledButtonNext = styled(ButtonNext)`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	background: transparent;
	box-shadow: none;
	border: none;
`

export type CarouselFloatingButtonNextProps = PartialKeys<ButtonNextProps, 'children'>

/** @deprecated Use Carousel instead */
export const DeprecatedCarouselFloatingButtonNext = ({
	children,
	...rest
}: CarouselFloatingButtonNextProps) => {
	const currentSlide = useCarouselCurrentSlide()
	const { totalSlides, visibleSlides } = useCarouselContext().state

	const disabled = currentSlide + visibleSlides === totalSlides

	return (
		<StyledButtonNext
			data-testid="next-button"
			disabled={disabled}
			tabIndex={-1}
			aria-label="next slide"
			aria-hidden="true"
			{...rest}
		>
			{children ?? (
				<KeyboardArrowRightIcon fontSize="large" color={disabled ? 'disabled' : 'primary'} />
			)}
		</StyledButtonNext>
	)
}
