import { gql } from '@persuit/ui-graphql'

const responsePricingExportToExcel = gql`
	mutation (
		$rfpId: ID!
		$includeWithdrawnProposals: Boolean
		$includeEliminatedProposals: Boolean
		$includeRevisedProposals: Boolean
	) {
		reponsePricingExcelExport(
			rfpId: $rfpId
			includeWithdrawnProposals: $includeWithdrawnProposals
			includeEliminatedProposals: $includeEliminatedProposals
			includeRevisedProposals: $includeRevisedProposals
		) {
			data
		}
	}
`

export default responsePricingExportToExcel
