import { firmTotalModel } from '../../../../common/data/pricing-models'
import { getOrEmptyString, getOrFalse, getOrNull } from '../../../utils/lenses'
import isEmpty from 'lodash/isEmpty'

export const getWizardProps = (proposal, isRequestTotalPriceRequired, request) => {
	const proposalId = getOrEmptyString('_id', proposal)
	const proposalTotalPrice = getOrNull('totalPriceValue', proposal)
	const hasRevisionRequest = getOrFalse('hasRevisionRequest', proposal)
	const totalPriceModel = getOrEmptyString('totalPriceModel', proposal)
	const pricingModelNotSelected = !totalPriceModel
	const pricingModelNotApplicable = totalPriceModel === firmTotalModel.NOTAPPLICABLE
	const totalPriceNotRequired = !isRequestTotalPriceRequired
	const noResponseToDeliverables = isEmpty(proposal?.responseToDeliverables)

	return {
		proposal,
		request,
		proposalId,
		proposalTotalPrice,
		hasRevisionRequest,
		pricingModelNotSelected,
		pricingModelNotApplicable,
		totalPriceNotRequired,
		noResponseToDeliverables,
	}
}
