// @ts-strict-ignore
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports
import { useRfpTemplateQuestionsSectionSaveMutation } from '@persuit/ui-graphql/hooks'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { QuestionForm } from '../../rfp-forms'
import { RfpQuestionError, RfpQuestionGroupError } from '../../types'
import { DeepPick, isNotNilProp, defaultNilProp, isNotNil } from '@persuit/common-utils'

graphql(`
	mutation RfpTemplateQuestionsSectionSave(
		$payload: RfpTemplateDraftQuestionsInput!
		$validate: Boolean!
	) {
		saveRfpTemplateDraftQuestions(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type QuestionSectionProps = {
	instanceId: string
	rfpTemplate: DeepPick<RfpTemplate, '_id' | 'detail.questions' | 'detail.questionGroups'>
	validationErrors: {
		questions?: RfpQuestionError[]
		questionGroups?: RfpQuestionGroupError[]
	} | null
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const QuestionSection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	validationErrors,
	refetchErrors,
	sendMode,
}: QuestionSectionProps) => {
	const [saveSectionMutation] = useRfpTemplateQuestionsSectionSaveMutation()
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const questionData = (rfpTemplate?.detail?.questions ?? [])
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))
		.filter(isNotNilProp('type'))
		.map(defaultNilProp('title', ''))
		.map(defaultNilProp('required', false))

	const questionGroupData = (rfpTemplate?.detail?.questionGroups ?? [])
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))
		.filter(isNotNilProp('name'))

	const questionErrors = validationErrors?.questions ?? null
	const questionGroupErrors = validationErrors?.questionGroups ?? null

	if (loading) return <LoadingSpinner />

	const saveSection = async (formVariables) => {
		await saveSectionMutation({
			variables: {
				payload: {
					...formVariables,
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	return (
		<>
			<PageTitle>Edit questions of template</PageTitle>
			<QuestionForm
				saveQuestions={saveSection}
				questionData={questionData}
				questionGroupData={questionGroupData}
				questionErrors={questionErrors}
				questionGroupErrors={questionGroupErrors}
			/>
		</>
	)
}
