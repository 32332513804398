import { useFormContext } from 'react-hook-form'
import { Spacer, Typography, Box } from '@persuit/ui-components'
import { isNil } from '@persuit/common-utils'
import { ScorecardFormValues } from '../scorecard-form'

type ScorecardWeightProps = {
	id?: string
	weight?: number | null
}

export const ScorecardWeight = ({ weight, id }: ScorecardWeightProps) => {
	const { watch } = useFormContext<ScorecardFormValues>()
	const scoreCategories = watch('scoreCategories')

	const totalWeight = scoreCategories.reduce((total, category) => total + (category.weight ?? 0), 0)

	if (isNil(weight) || totalWeight === 0) {
		return null
	}

	const percentage = (100 * weight) / totalWeight

	return (
		<Box display="flex" id={id}>
			<Typography variant="body2">{weight}</Typography>
			<Spacer shape="column" />
			<Typography variant="body2">/</Typography>
			<Spacer shape="column" />
			<Typography variant="body2">{totalWeight}</Typography>
			<Spacer space={2} shape="column" />
			<Typography variant="body2">|</Typography>
			<Spacer space={2} shape="column" />
			<Typography variant="body2">{percentage.toFixed(0)}%</Typography>
		</Box>
	)
}
