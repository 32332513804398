import { RfpProgressStatusEnum, RfpProgressTypeEnum } from '@persuit/ui-graphql/generated'
import { CARD_TITLE as startTitle } from '../progress-cards/progress-card-start'
import { CARD_TITLE as draftRequestTitle } from '../progress-cards/progress-card-draft-request'
import { CARD_TITLE as sendRequestTitle } from '../progress-cards/progress-card-send-request'
import { CARD_TITLE as waitForProposalsTitle } from '../progress-cards/progress-card-wait-for-proposals'
import { CARD_TITLE as sendClarificationTitle } from '../progress-cards/progress-card-send-clarification'
import { CARD_TITLE as watchAuctionTitle } from '../progress-cards/progress-card-watch-auction'
import { CARD_TITLE as negotiateTitle } from '../progress-cards/progress-card-negotiate'
import { CARD_TITLE as selectWinnerTitle } from '../progress-cards/progress-card-select-winner'
import { CARD_TITLE as feedbackTitle } from '../progress-cards/progress-card-feedback'
import { CARD_TITLE as completeRequestTitle } from '../progress-cards/progress-card-complete-request'

const PROGRESS_TITLE_MAP: Record<RfpProgressTypeEnum, string> = {
	start: startTitle,
	draftRequest: draftRequestTitle,
	sendRequest: sendRequestTitle,
	waitForProposals: waitForProposalsTitle,
	sendClarification: sendClarificationTitle,
	watchAuction: watchAuctionTitle,
	negotiate: negotiateTitle,
	selectWinner: selectWinnerTitle,
	feedback: feedbackTitle,
	completeRequest: completeRequestTitle,
}

export const getRfpProgressStepTitle = (stepType: RfpProgressTypeEnum): string => {
	return PROGRESS_TITLE_MAP[stepType] ?? 'Unknown step'
}

export const getRfpProgressMiniViewContent = (
	steps: Array<{
		type: RfpProgressTypeEnum
		status: RfpProgressStatusEnum
	}>,
): {
	progress: number
	text: string
	stepNumber: number
	totalSteps: number
} => {
	const totalSteps = steps.length

	if (totalSteps === 0)
		return {
			progress: 0,
			text: 'No steps found',
			stepNumber: totalSteps,
			totalSteps,
		}

	const activeStepIndex = steps.findIndex((step) => step.status === 'active')
	const futureStepIndex = steps.findIndex((step) => step.status === 'future')

	const currentStepIndex = activeStepIndex === -1 ? futureStepIndex : activeStepIndex

	// If active not found in the array, assume they are all completed
	if (currentStepIndex === -1)
		return {
			progress: 100,
			text: 'Request complete',
			stepNumber: totalSteps,
			totalSteps,
		}

	return {
		progress: (100 * currentStepIndex) / totalSteps,
		text: getRfpProgressStepTitle(steps[currentStepIndex].type),
		stepNumber: currentStepIndex + 1,
		totalSteps,
	}
}
