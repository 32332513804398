import { gql } from '@persuit/ui-graphql'

const requestsWithUnreads = gql`
	fragment RequestsWithUnreadMessages on RequestsWithUnreadMessages {
		totalUnreadCount
		requestsWithUnreads {
			requestId
			requestHeadline
			unreadCount
		}
	}
`

export default requestsWithUnreads
