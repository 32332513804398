import { createRHFUtils } from '@persuit/ui-components'
import { NewRfpForm_RfpFragment } from '@persuit/ui-graphql/generated'

export type TrackingFormValues = {
	referenceNo: string
	customFields?: NonNullable<NewRfpForm_RfpFragment['tracking']>['customFields']
	tracking?: {
		customFields: {
			hierarchyValue?: (string | null)[] | null
			numericValue?: number | null
			plainTextValue?: string | null
			richTextValue?: string | null
			dateValue?: number | null
			__typename?: string
		}[]
	}
}

const { useController, useFormContext } = createRHFUtils<TrackingFormValues>()

export { useController, useFormContext }
