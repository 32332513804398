import { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams, withRouter } from 'react-router-dom'
import { isNotNil } from '@persuit/common-utils'
import { compose } from 'redux'
import { duringAuction } from '../../../common/auction-in-progress'
import countProposals from '../../../common/count-proposals'
import tabNames from '../../../common/data/rfp-tabs'
import { ResponseCompare } from '../../components/response-compare/response-compare'
import { ProposalFeedbackDialog } from '../../components/proposal-feedback'
import { RequestTracking } from '../../containers/request-tracking'
import RfpManageFirms from '../../containers/rfp-manage-firms.jsx'
import useTranslation from '../../custom-hooks/translation-hook'
import { getOrEmptyString, getOrFalse } from '../../utils/lenses'
import ProposalTabLabel from '../proposal-tab-label'
import Tabs from '../tabs'
import ProposalViewContainer from './proposal-view-container'
import { RfpAnalytics } from './rfp-analytics'
import { useRfpProgressSubscribe, RfpPreview } from '@persuit/ui-shared-components'
import {
	PageTitle,
	PlaylistAddCheckIcon,
	LocalOfferIcon,
	TrendingDownIcon,
	AssignmentIcon,
	AccountBalanceIcon,
	RatesSectionIcon,
} from '@persuit/ui-components'

import { RfpRatesTab } from './rfp-rates-tab'

const getActiveProposal = ({ proposalId, proposals }) => {
	if (!proposals) {
		return null
	}

	return proposals.find((proposal) => proposal._id.toString() === proposalId)
}

export const getManagerTabs = ({ rfp, currentTab, handleRequestTabChange, t }) => [
	{
		id: tabNames.TRACKING,
		icon: PlaylistAddCheckIcon,
		label: t('rfpEdit.tab.tracking.label'),
		dataTestid: 'rfp-tracking-tab',
		dataTrackId: 'tab-rfp-tracking',
		content: (() => {
			return (
				<>
					<PageTitle>Manage custom tracking fields for matter</PageTitle>
					<div>
						<RequestTracking request={rfp} />
					</div>
				</>
			)
		})(),
		handleChange: handleRequestTabChange(tabNames.TRACKING, currentTab),
		fullWidth: false,
	},
]

const getClientTabs = ({
	t,
	action,
	proposalId,
	requestId,
	rfp,
	currentTab,
	auctionInProgress,
	proposals,
	firmOrgId,
	isFirmEliminated,
	fetchResponses,
	proposalCount,
	managerTabs,
	handleRequestTabChange,
	user,
	progressViewExpanded,
	collapseRfpProgressView,
	toggleRfpProgressView,
	setShowProposalFeedbackFormAll,
	setShowProposalFeedbackFormSingleFirm,
	selectingProposal,
	setSelectingProposal,
}) => {
	return [
		{
			id: tabNames.MATTER,
			icon: AssignmentIcon,
			label: t('rfpEdit.tab.detail.label'),
			content: (() => {
				return (
					<>
						<PageTitle>Matter Summary</PageTitle>
						<div>{action === 'view' && <RfpPreview document={rfp} />}</div>
					</>
				)
			})(),
			handleChange: handleRequestTabChange(tabNames.MATTER, currentTab),
			fullWidth: false,
		},
		rfp.useCase === 'rateReview'
			? {
					id: tabNames.RATES,
					icon: RatesSectionIcon,
					label: 'Rates',
					content: (() => (
						<>
							<PageTitle>Rates comparison</PageTitle>
							<RfpRatesTab rfp={rfp} proposals={proposals} />
						</>
					))(),
					disabled: !proposals || !proposals.length,
					badgeCount: proposalCount,
					handleChange: handleRequestTabChange(tabNames.RATES, currentTab),
					fullWidth: !proposalId, // i.e. full-width when viewing proposal comparator
			  }
			: null,
		{
			id: tabNames.PROPOSAL,
			icon: LocalOfferIcon,
			label: (
				<ProposalTabLabel
					isAuction={!!rfp && duringAuction(rfp)}
					auctionInProgress={auctionInProgress}
				/>
			),
			content: (() => {
				if (!proposalId) {
					return (
						<>
							<PageTitle>Proposal comparison</PageTitle>
							<div
								style={{
									padding: '1em',
								}}
							>
								<ResponseCompare
									gridMaxHeight="calc(100vh - 200px)"
									enablePinning={true}
									isFirm={false}
									responses={proposals}
									request={rfp}
									fetchResponses={fetchResponses}
									progressViewExpanded={progressViewExpanded}
									collapseRfpProgressView={collapseRfpProgressView}
									toggleRfpProgressView={toggleRfpProgressView}
									setShowProposalFeedbackFormAll={setShowProposalFeedbackFormAll}
									setShowProposalFeedbackFormSingleFirm={setShowProposalFeedbackFormSingleFirm}
									selectingProposal={selectingProposal}
									setSelectingProposal={setSelectingProposal}
									auctionInProgress={duringAuction(rfp)}
								/>
							</div>
						</>
					)
				} else {
					return (
						<>
							<PageTitle>View firm’s proposal</PageTitle>
							<ProposalViewContainer
								rfp={rfp}
								firmOrgId={firmOrgId}
								proposalId={proposalId}
								proposals={proposals}
								isFirmEliminated={isFirmEliminated}
								fetchResponses={fetchResponses}
								user={user}
							/>
						</>
					)
				}
			})(),
			disabled: !proposals || !proposals.length,
			badgeCount: proposalCount,
			handleChange: handleRequestTabChange(tabNames.PROPOSAL, currentTab),
			fullWidth: !proposalId, // i.e. full-width when viewing proposal comparator
		},
		{
			id: tabNames.FIRM,
			icon: AccountBalanceIcon,
			label: t('rfpEdit.tab.firm.label'),
			content: (
				<>
					<PageTitle>Manage firms and view firm activity</PageTitle>
					<RfpManageFirms rfp={rfp} proposals={proposals} />
				</>
			),
			disabled: !requestId,
			handleChange: handleRequestTabChange(tabNames.FIRM, currentTab),
			fullWidth: false,
		},
		...managerTabs,
		{
			id: tabNames.ANALYTICS,
			icon: TrendingDownIcon,
			label: t('rfpEdit.tab.analytics.label'),
			content: (
				<>
					<PageTitle>Manage matter’s analytics and savings</PageTitle>
					<RfpAnalytics request={rfp} responses={proposals} auctionInProgress={auctionInProgress} />
				</>
			),
			handleChange: handleRequestTabChange(tabNames.ANALYTICS, currentTab),
			fullWidth: false,
			disabled: !proposals || !proposals.length,
		},
	].filter(isNotNil)
}

const RfpEditTabs = ({
	currentTab,
	proposals,
	rfp,
	auctionInProgress,
	isFirmEliminated,
	trackingOnlyAccess,
	fetchResponses,
	handleRfpTabChange,
	user,
	isNewSelectFirmFlowEnabled,
	progressViewExpanded,
	collapseRfpProgressView,
	toggleRfpProgressView,
}) => {
	const { lng, requestId, proposalId, action } = useParams()
	let history = useHistory()
	const { t } = useTranslation()
	useEffect(() => {
		if (trackingOnlyAccess) {
			// Replace with only tracking tab
			const newPath = `/${lng}/request/${requestId}/tracking/edit/`
			history.push(newPath)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const proposal = getActiveProposal({ proposalId, proposals })
	const firmOrgId = getOrEmptyString('org._id', proposal)

	const [showProposalFeedbackFormSingleFirm, setShowProposalFeedbackFormSingleFirm] =
		useState(false)
	const [showProposalFeedbackFormAll, setShowProposalFeedbackFormAll] = useState(false)
	useRfpProgressSubscribe('DETAIL:LAUNCH_PROVIDE_FEEDBACK', () => {
		setShowProposalFeedbackFormAll(true)
	})

	const [selectingProposal, setSelectingProposal] = useState(null)

	// Count the number of active proposals, excluding
	// withdrawn proposals
	const proposalCount = countProposals(proposals)

	const handleRequestTabChange = (tab, currentTab) => () => handleRfpTabChange(tab, currentTab)

	return (
		<Fragment>
			{showProposalFeedbackFormSingleFirm && (
				<ProposalFeedbackDialog
					rfp={rfp}
					proposals={proposals}
					handleClose={() => setShowProposalFeedbackFormSingleFirm(false)}
					fetchResponses={fetchResponses}
					firmId={selectingProposal?.org?._id ?? undefined}
				/>
			)}
			{showProposalFeedbackFormAll && (
				<ProposalFeedbackDialog
					rfp={rfp}
					proposals={proposals}
					handleClose={() => setShowProposalFeedbackFormAll(false)}
					fetchResponses={fetchResponses}
				/>
			)}
			<Tabs
				tabs={
					trackingOnlyAccess
						? getManagerTabs({ rfp, proposals, currentTab, handleRequestTabChange, t })
						: getClientTabs({
								isNewSelectFirmFlowEnabled,
								t,
								action,
								proposalId,
								requestId,
								rfp,
								currentTab,
								auctionInProgress,
								proposals,
								firmOrgId,
								proposal,
								isFirmEliminated,
								fetchResponses,
								proposalCount,
								managerTabs: getManagerTabs({
									rfp,
									proposals,
									currentTab,
									handleRequestTabChange,
									t,
								}),
								handleRequestTabChange,
								user,
								progressViewExpanded,
								collapseRfpProgressView,
								toggleRfpProgressView,
								setShowProposalFeedbackFormAll,
								setShowProposalFeedbackFormSingleFirm,
								selectingProposal,
								setSelectingProposal,
						  })
				}
				currentTab={
					trackingOnlyAccess
						? getManagerTabs({ rfp, proposals, currentTab, handleRequestTabChange, t })[0].id
						: currentTab
				}
			/>
		</Fragment>
	)
}

const getCurrentProposalId = ({ proposalId, proposals }) => {
	if (!proposals) {
		return null
	}

	const currentProposal = proposals.find((proposal) => proposal._id.toString() === proposalId)

	if (currentProposal) {
		return currentProposal.org._id
	}

	return ''
}

const mapStateToProps = (
	state,
	{
		rfp,
		proposals,
		match: {
			params: { proposalId },
		},
	},
) => {
	const isNewSelectFirmFlowEnabled = getOrFalse(
		'auth.user.featureMap.ftEnableNewSelectFirmFlow',
		state,
	)
	const isManager = state.auth.user.roles.some((role) => role === 'manager')
	const { user } = state.auth
	const invite = rfp.invites.find(
		(invite) => invite.item._id === getCurrentProposalId({ proposals, proposalId }),
	)

	const isFirmEliminated = getOrFalse('state.eliminate.eliminated', invite) === true

	return {
		trackingOnlyAccess:
			isManager &&
			!rfp.allow.find(
				(allow) =>
					allow.item._id.toString() === state.auth.user._id.toString() && allow.type !== 'revoked',
			) &&
			// Is not the owner of the request
			rfp.createdBy._id !== state.auth.user._id,
		isFirmEliminated,
		user,
		isNewSelectFirmFlowEnabled,
	}
}

RfpEditTabs.displayName = 'RfpEditTabs'
RfpEditTabs.defaultProps = {
	currentTab: 'matter',
	proposals: [],
}

export default compose(withRouter, connect(mapStateToProps))(RfpEditTabs)
