import numeral from 'numeral'
import { renderPrice } from '../utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { ProposalNamedTimekeeper } from '@persuit/ui-graphql/generated'
import { TableCell, TableRow, Typography, useTheme } from '@persuit/ui-components'

type NamedTimekeeperRowProps = {
	namedTimekeeper: Omit<ProposalNamedTimekeeper, 'createdAt' | 'updatedAt'>
	currency: string
	clientSuggestedProposedRate: number | null
}

export const NamedTimekeeperRow = ({
	namedTimekeeper,
	currency: oldCurrency,
	clientSuggestedProposedRate,
}: NamedTimekeeperRowProps) => {
	const { toggles } = useFeatureToggles()
	const isMultiCurrencyEnabled = toggles['dev-10954.multi-currency']
	const theme = useTheme()
	const {
		_id,
		billingId,
		firstName,
		lastName,
		locationLabel,
		jobTitle,
		standardRate,
		currentRate,
		proposedRate,
		lastYearHours,
		graduationYear,
		isExistingTimekeeper,
	} = namedTimekeeper

	const currency = isMultiCurrencyEnabled ? namedTimekeeper.currency : oldCurrency

	const formattedCurrentRate = isExistingTimekeeper ? renderPrice(currentRate, currency) : '-'
	const formattedLastYearsHours =
		isExistingTimekeeper && typeof lastYearHours === 'number'
			? numeral(lastYearHours).format('0,0')
			: '-'
	const formattedExistingTimekeeper = isExistingTimekeeper ? 'Yes' : 'No'
	const hasClientSuggestedProposedRate = Boolean(clientSuggestedProposedRate)

	return (
		<TableRow
			sx={{
				'& > td': {
					minWidth: '120px',
				},
			}}
		>
			<TableCell>{jobTitle}</TableCell>
			<TableCell>{firstName}</TableCell>
			<TableCell>{lastName}</TableCell>
			<TableCell>{locationLabel ?? '-'}</TableCell>
			<TableCell>{graduationYear ?? '-'}</TableCell>
			<TableCell>{billingId ?? '-'}</TableCell>
			<TableCell>{formattedExistingTimekeeper}</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedLastYearsHours}</TableCell>
			{isMultiCurrencyEnabled && <TableCell sx={{ whiteSpace: 'nowrap' }}>{currency}</TableCell>}
			<TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedCurrentRate}</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap' }}>{renderPrice(standardRate, currency)}</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap' }} id={_id}>
				{hasClientSuggestedProposedRate && (
					<>
						<Typography variant="body2" color={theme.palette.text.primary}>
							{renderPrice(clientSuggestedProposedRate, currency)}
						</Typography>
						<br />
					</>
				)}
				<Typography variant="body2" strikeThrough={hasClientSuggestedProposedRate}>
					{renderPrice(proposedRate, currency)}
				</Typography>
			</TableCell>
		</TableRow>
	)
}
