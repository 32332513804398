// @ts-strict-ignore
/* eslint-disable react/no-multi-comp */
import { ApolloProvider } from '@apollo/client'
import { Component } from 'react'
import * as React from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider as JSSThemeProvider } from 'react-jss'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import StylesProvider from '@mui/styles/StylesProvider'

import { logger, routerHistory } from '@persuit/ui-logger'
import {
	ThemeProvider,
	theme,
	SnackbarProvider,
	ConfirmDialogProvider,
	ErrorPage,
	Box,
} from '@persuit/ui-components'
import store from '../store'
import Routes from '../routes/index'
import apolloClient from '../apollo-client'
// "Legacy" MUI, however 95% of components use old MUI
import { LegacyMUIThemeProvider } from '../old-mui-provider'

// Makes i18next magically bind to react
// no need to use a <I18nextProvider>
import '../i18next'

import MonitorRouteChanges from './monitor-route-changes'

class ErrorBoundary extends React.Component<
	{ children: React.ReactNode },
	{
		error: boolean
	}
> {
	constructor(props) {
		super(props)

		this.state = {
			error: false,
		}
	}

	static getDerivedStateFromError(error) {
		return { error }
	}

	componentDidCatch(error, errorInfo) {
		logger.error(error, {
			extra: errorInfo,
		})
		this.setState({
			error: true,
		})
	}

	render() {
		const { error } = this.state

		return error ? (
			<ThemeProvider>
				<Box
					sx={{
						mt: 5,
						px: {
							xs: 2,
							md: 0,
						},
					}}
				>
					<ErrorPage />
				</Box>
			</ThemeProvider>
		) : (
			this.props.children
		)
	}
}

class Root extends Component {
	render() {
		return (
			<ErrorBoundary>
				<Provider store={store}>
					<ApolloProvider client={apolloClient}>
						<StylesProvider injectFirst={true}>
							<JSSThemeProvider theme={theme}>
								<LegacyMUIThemeProvider>
									<ThemeProvider>
										<SnackbarProvider>
											<ConfirmDialogProvider>
												<Router history={routerHistory}>
													<MonitorRouteChanges />
													<Routes />
												</Router>
											</ConfirmDialogProvider>
										</SnackbarProvider>
									</ThemeProvider>
								</LegacyMUIThemeProvider>
							</JSSThemeProvider>
						</StylesProvider>
					</ApolloProvider>
				</Provider>
			</ErrorBoundary>
		)
	}
}

export default Root
