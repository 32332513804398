import { format } from 'date-fns'
import { map, size } from 'lodash/fp'
import {
	styled,
	Box,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	Typography,
} from '@persuit/ui-components'
import formatPriceCurrency from '../../../../utils/price-and-currency'

const getFormattedPrice = (price, currency) => {
	if (!price) {
		return '-'
	}

	return formatPriceCurrency(currency, price)
}

const StyledTableCell = styled(TableCell)`
	&.MuiTableCell-root {
		border: none;
	}
`

const RateCardTableRow = ({ rates, currency, notIncluded, updatedAt, deliverableNumber }) => {
	const formattedUpdateAt = format(updatedAt, 'dd MMM yyyy, hh:mma')
	if (notIncluded) {
		return (
			<TableRow>
				<StyledTableCell>{formattedUpdateAt}</StyledTableCell>
				<StyledTableCell colSpan={3}>
					<Typography variant="body1">Excluded from scope</Typography>
				</StyledTableCell>
			</TableRow>
		)
	}

	return (
		<TableRow style={{ borderBottom: '1px solid #e0e0e0' }}>
			<StyledTableCell style={{ verticalAlign: 'baseline' }}>{formattedUpdateAt}</StyledTableCell>
			{rates.map(({ _id, label, rate }, rateIndex) => {
				const formattedRate = getFormattedPrice(rate, currency)
				const rateItemNumber = `${deliverableNumber}.${rateIndex + 1}`

				return (
					<Box key={_id} display="flex" justifyContent="space-between">
						<StyledTableCell>{`${rateItemNumber} ${label}`}</StyledTableCell>
						<StyledTableCell>{formattedRate}</StyledTableCell>
					</Box>
				)
			})}
		</TableRow>
	)
}

/**
 * @deprecated
 * Use src/client/components/rfp-proposals/rfp-proposal-detail/price-history/rate-card-history-table/rate-card-history-table.tsx instead
 */
const RateCardHistoryTable = ({ priceHistories, currency, deliverableNumber }) => {
	if (size(priceHistories) === 0) {
		return null
	}

	return (
		<Table size="small" aria-label="Rate card price history">
			<caption>Rate card price history</caption>
			<TableHead>
				<TableRow>
					<TableCell>
						<Typography variant="body2">Last updated</Typography>
					</TableCell>
					<TableCell style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="body2">Rate card title</Typography>
						<Typography variant="body2">Price</Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(
					(priceHistory) => (
						<RateCardTableRow
							key={priceHistory._id}
							rates={priceHistory.rates}
							currency={currency}
							notIncluded={priceHistory.notIncluded}
							updatedAt={priceHistory.updatedAt}
							deliverableNumber={deliverableNumber}
						/>
					),
					priceHistories,
				)}
			</TableBody>
		</Table>
	)
}

export default RateCardHistoryTable
