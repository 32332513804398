import isEmpty from 'lodash/fp/isEmpty'
import { useMemo, memo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import {
	FormFloatingErrorsContainer,
	ErrorCard,
	makeErrorCardDataBuilder,
} from '../../../../sectional-form'
import { makeHandlers } from '../../utils'
import { RfpTemplateDraftSectionErrors } from '../../../types'

type ErrorsProps = {
	errors: RfpTemplateDraftSectionErrors | null
}

export const Errors = memo(({ errors }: ErrorsProps) => {
	const { path } = useRouteMatch()
	const { templateId } = useParams<{ templateId: string }>()

	const errorCardDatasBuilder = useMemo(
		() => makeErrorCardDataBuilder(makeHandlers(path, templateId)),
		[path, templateId],
	)

	return (
		<FormFloatingErrorsContainer>
			{!isEmpty(errors)
				? errorCardDatasBuilder(errors).map((errorCardData, i) => (
						<ErrorCard key={i} loading={false} errorCardData={errorCardData} />
				  ))
				: null}
		</FormFloatingErrorsContainer>
	)
})
