import { Dialog, RichTextView } from '@persuit/ui-components'

type OriginalAssumptionsDialogProps = {
	handleClose: () => void
	isOpen: boolean
	originalAssumption: string
}

export const OriginalAssumptionsDialog = ({
	handleClose,
	isOpen,
	originalAssumption,
}: OriginalAssumptionsDialogProps) => (
	<Dialog
		onClick={(e) => {
			e.stopPropagation()
		}}
		open={isOpen}
		onClose={handleClose}
		size="md"
		title="Original assumptions & description"
		actions={[
			{
				label: 'Ok',
				variant: 'primary',
				onClick: handleClose,
			},
		]}
	>
		<RichTextView content={originalAssumption} />
	</Dialog>
)
