import { createContext, useState, useRef, useContext, useEffect } from 'react'
import { Splide, SplideProps, SplideSlide } from '@splidejs/react-splide'
import { CarouselStyles } from './carousel-styles'
import { Spacer } from '../spacer'
import { getComponentName } from '@persuit/ui-utils'

type CarouselProps = SplideProps

type CarouselContextProps = {
	ref?: Splide
}
const CarouselContext = createContext<CarouselContextProps>({})

export const Carousel = ({ children, ...props }: CarouselProps) => {
	const splideRef = useRef<Splide | undefined>()
	const [refValue, setRefValue] = useState<Splide | undefined>()

	useEffect(() => {
		if (splideRef.current && !refValue) {
			setRefValue(splideRef.current)
		}
	}, [refValue])

	if (!children) {
		return null
	}

	const splideChilren = Array.isArray(children)
		? children.filter((child) => getComponentName(child) === 'CarouselSlide')
		: typeof children === 'object' && getComponentName(children) === 'CarouselSlide'
		? children
		: null

	const additionalChildren = Array.isArray(children)
		? children.filter((child) => getComponentName(child) !== 'CarouselSlide')
		: typeof children === 'object' && getComponentName(children) !== 'CarouselSlide'
		? children
		: null

	return (
		<>
			<CarouselStyles />
			<CarouselContext.Provider
				value={{
					ref: refValue,
				}}
			>
				<Splide {...props} ref={splideRef as unknown as any}>
					{splideChilren}
				</Splide>
				{props.hasTrack !== false && <Spacer space={4} />}
				{additionalChildren}
			</CarouselContext.Provider>
		</>
	)
}

Carousel.displayName = 'Carousel'

export const useCarouselContext = () => {
	return useContext(CarouselContext)
}

export const useCarouselCurrentSlide = () => {
	const [index, setIndex] = useState(0)
	const { ref } = useCarouselContext()

	useEffect(() => {
		if (ref && ref.splide) {
			ref.splide.on('pagination:mounted', () => {
				setIndex(ref?.splide?.index ?? 0)
			})

			ref.splide.on('pagination:updated', () => {
				setIndex(ref?.splide?.index ?? 0)
			})
		}
	}, [ref])

	return index
}

export const useCarouselSlideVisible = (index: number) => {
	const [isVisible, setIsVisible] = useState(false)
	const { ref } = useCarouselContext()

	useEffect(() => {
		if (ref && ref.splide) {
			ref.splide.on('visible', (data) => {
				setIsVisible(data.index === index)
			})
		}
	}, [index, ref])

	return isVisible
}

type CarouselSlideProps = JSX.IntrinsicElements['li']

export const CarouselSlide = (props: CarouselSlideProps) => <SplideSlide {...props} />

CarouselSlide.displayName = 'CarouselSlide'
