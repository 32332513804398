export const formatDuration = (duration: number | null | undefined) => {
	switch (duration) {
		case 5:
			return '5 MIN'
		case 30:
			return '30 MIN'
		case 60:
			return '1 HR'
		case 120:
			return '2 HRS'
		case 1440:
			return '24 HRS'
		case 2880:
			return '48 HRS'
		default:
			return '-'
	}
}
