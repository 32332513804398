import { styled } from '@persuit/ui-components'

const StyledHorizontalDivider = styled('div')(({ theme }) => ({
	marginTop: '1em',
	paddingTop: '1.5em',
	paddingBottom: '1.5em',
	borderTop: `3px solid ${theme.palette.grey500}`,
}))

export const HorizontalDivider = () => {
	return <StyledHorizontalDivider />
}
