import { useContext, createContext, useRef, useEffect, useState, useId } from 'react'
import { IconButton, IconButtonProps } from '../icon-button'
import { CloseIcon } from '../../icons'

import {
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps,
	DialogTitle as MuiDialogTitle,
	DialogTitleProps as MuiDialogTitleProps,
	DialogContent as MuiDialogContent,
	DialogContentProps as MuiDialogContentProps,
	DialogActions as MuiDialogActions,
	DialogActionsProps as MuiDialogActionsProps,
	DialogContentText as MuiDialogContentText,
	DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material'

type ContextValue = {
	titleId?: string
	scroll: DialogDeprecatedProps['scroll']
}

const DialogContext = createContext<ContextValue>({ titleId: undefined, scroll: undefined })

const useDialogContext = () => useContext(DialogContext)
const Provider = DialogContext.Provider

type DialogSize = 'xs' | 'sm' | 'md' | 'lg'

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogDeprecatedProps = {
	size?: DialogSize
} & MuiDialogProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogDeprecated = (props: DialogDeprecatedProps) => {
	const titleId = useId()

	const [shouldDisableEnforceFocus, setShouldDisableEnforceFocus] = useState(false)

	/** In order for MUI Dialog to play well with TinyMCE, we need to disable focus trap */
	useEffect(() => {
		const intervalId = setInterval(() => {
			if (!shouldDisableEnforceFocus) {
				const dialogNode = document.querySelector('.tox-dialog-wrap')

				if (dialogNode) {
					setShouldDisableEnforceFocus(true)
				}
			}
		}, 100)

		return () => {
			clearInterval(intervalId)
		}
	}, [shouldDisableEnforceFocus])

	/** In order for MUI Dialog to not hide global alert */
	useEffect(() => {
		if (props.open) {
			const globalAlertId = document.getElementById('global-alert')

			if (globalAlertId) globalAlertId.setAttribute('aria-hidden', 'false')
		}
	}, [props.open])

	return (
		<Provider value={{ scroll: props.scroll ?? 'paper', titleId }}>
			<MuiDialog
				aria-labelledby={titleId}
				{...props}
				disableEnforceFocus={shouldDisableEnforceFocus || props.disableAutoFocus}
			/>
		</Provider>
	)
}

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogTitleProps = MuiDialogTitleProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogTitle = (props: DialogTitleProps) => {
	const { titleId } = useDialogContext()
	return <MuiDialogTitle id={titleId} variant="h2XSmall" {...props} />
}

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogContentProps = MuiDialogContentProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogContent = (props: MuiDialogContentProps) => {
	const { scroll, titleId } = useDialogContext()
	const containerRef = useRef<HTMLElement>()
	const [scrollable, setScrollable] = useState(false)
	const labelId = useId()
	const shouldHandleScroll = scroll === 'paper'

	const addScrollContainer = scrollable && shouldHandleScroll

	useEffect(() => {
		const container = containerRef.current
		if (!shouldHandleScroll || !container) return

		const resizeCallback = () => {
			if (!containerRef.current) return
			const element = containerRef.current

			const { scrollHeight, clientHeight } = element
			const scrollable = scrollHeight > clientHeight
			setScrollable(scrollable)
		}

		const observer = new ResizeObserver(resizeCallback)

		for (const child of container.children) {
			observer.observe(child)
		}

		const element = containerRef.current!
		observer.observe(element)

		return () => observer.disconnect()
	}, [shouldHandleScroll])

	return (
		<>
			<span style={{ display: 'none' }} id={labelId}>
				Scrollable content area for
			</span>
			<MuiDialogContent
				ref={containerRef}
				aria-labelledby={`${scrollable ? `${labelId} ` : ''}${titleId}`}
				tabIndex={addScrollContainer ? 0 : undefined}
				{...props}
			/>
		</>
	)
}

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogContentTextProps = MuiDialogContentTextProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogContentText = MuiDialogContentText

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogActionsProps = MuiDialogActionsProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogActions = MuiDialogActions

/**
 * @deprecated Use Design System Dialog Component
 */
export type DialogCloseProps = IconButtonProps

/**
 * @deprecated Use Design System Dialog Component
 */
export const DialogClose = (props: DialogCloseProps) => {
	return (
		<IconButton
			aria-label="close dialog"
			{...props}
			size="small"
			sx={{
				position: 'absolute',
				top: '16px',
				right: '16px',
				mt: '-5px',
				mr: '-5px',
				...props?.sx,
			}}
		>
			<CloseIcon />
		</IconButton>
	)
}
