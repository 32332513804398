import { useEffect } from 'react'
import { useAutoSaveSnack } from '../auto-save-snack'
import { DefaultValues, useForm, UseFormReturn, FieldPath, FieldValues } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import isEmpty from 'lodash/fp/isEmpty'

const SAVE_DEBOUNCE_WAIT_MS = 1500

type UseSectionalFormOptions = {
	autoSave?: boolean
	enableDebounce?: boolean
}

export const useSectionalForm = <T extends FieldValues>(
	sectionData: T,
	saveSection: (values: T) => Promise<void>,
	sectionErrors?: Partial<MapObjectLeafFields<T, string>> | null,
	options: UseSectionalFormOptions = {},
): UseFormReturn<T, object> => {
	const setSaveSnack = useAutoSaveSnack()
	const methods = useForm<T, object>({
		mode: 'onChange',
		defaultValues: sectionData as DefaultValues<T>,
		shouldUnregister: false,
	})
	// Set default options
	const { autoSave = true, enableDebounce = true } = options

	const save = async () => {
		if (autoSave !== false) {
			try {
				setSaveSnack('loading')
				const values = methods.getValues() as T
				await saveSection(values)
				setSaveSnack('success')
			} catch (e) {
				console.error(e)
				setSaveSnack('error')
			}
		}
	}
	const debouncedSave = useDebouncedCallback(save, SAVE_DEBOUNCE_WAIT_MS)

	useEffect(() => {
		const subscriptionSave = methods.watch(enableDebounce ? debouncedSave : save)
		return () => {
			if (enableDebounce) {
				debouncedSave.flush()
			}
			subscriptionSave.unsubscribe()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDebounce])

	// handle setting errors
	useEffect(() => {
		methods.clearErrors()
		if (!isEmpty(sectionErrors)) {
			const errors = sectionErrors ? getErrors(sectionErrors) : []
			errors.forEach(({ message, path }) => methods.setError(path, { type: 'manual', message }))
		}
	}, [sectionErrors, methods])

	return methods
}

/**
 * Converts the leaf nodes of an object to a constant type
 */
export type MapObjectLeafFields<T extends Record<string, unknown>, U> = {
	[K in keyof T]: T[K] extends Record<string, unknown> ? MapObjectLeafFields<T[K], U> : U
}

/**
 * Converts an object of errors into a list of paths and messages
 * that can be used to set form errors in react-hook-form
 */
export function getErrors<T extends Record<string, unknown>>(
	errors: Partial<MapObjectLeafFields<T, string | null | undefined>>,
	path?: string,
): Array<{ path: FieldPath<T>; message: string }> {
	return Object.entries(errors)
		.map(([key, value]) => {
			const currentPath = [path, key].filter((x) => typeof x === 'string').join('.')
			if (typeof value === 'object' && value !== null) {
				return getErrors(value, currentPath)
			}

			return { path: currentPath as FieldPath<T>, message: value }
		})
		.flat()
		.filter((error) => typeof error.message === 'string') as Array<{
		path: FieldPath<T>
		message: string
	}>
}
