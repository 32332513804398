import { Box, Tooltip, Typography, useTheme } from '@persuit/ui-components'
import numeral from 'numeral'

export type PricingItemRankingProps = {
	rank?: {
		rank: number
		tied: boolean
	}
}

export const PricingItemRanking = ({ rank }: PricingItemRankingProps) => {
	const theme = useTheme()
	if (!rank) return null

	return (
		<Box>
			<Tooltip
				describeChild={true}
				title="This item's price ranking among the competition"
				arrow={true}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					height="auto"
					padding={1}
					borderRadius={2}
					bgcolor={theme.palette.primary.lighterHue}
				>
					<Typography color="primary">
						<b>
							{numeral(rank.rank).format('Oo')} {rank.tied && '(tied)'}
						</b>
					</Typography>
					<Typography color="primary">Item ranking</Typography>
				</Box>
			</Tooltip>
		</Box>
	)
}
