import { gql, useMutation } from '@apollo/client'
import { styled, useSnackbar } from '@persuit/ui-components'
import Text from '../../../text.jsx'
import DialogWizardContent from '../../dialog-wizard-content'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import { defaultSteps } from '../../dialog-wizard-steps'
import { trackWithPendo } from '../../../../track-event'
import { stepIndex } from '../../utils.js'

const UNSELECT_FIRM_MUTATION = gql`
	mutation unacceptResponse($responseId: ID!) {
		unacceptResponse(responseId: $responseId) {
			_id
		}
	}
`

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

function OptionUnselectFirmConfirm({
	onCancel,
	onPrev,
	onDone,
	proposalId,
	firmName,
	fetchResponses,
}) {
	const [unselectFirm, { loading, error }] = useMutation(UNSELECT_FIRM_MUTATION)
	const { openSnackbar } = useSnackbar()
	const onConfirm = async () => {
		await unselectFirm({ variables: { responseId: proposalId } })
		trackWithPendo('manage_proposal::unselect_proposal', { proposalId, firmName })
		openSnackbar(`Unselected ${firmName}'s proposal`)
		onDone()
		fetchResponses()
	}

	return (
		<DialogWizardContent
			onCancel={onCancel}
			onPrev={onPrev}
			onConfirm={onConfirm}
			isWorking={loading}
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: `Unselect firm's proposal`,
				[stepIndex(defaultSteps, 'ACTIONS')]: `Unselect firm's proposal`,
			}}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Unselect firm's proposal</strong>
			<StyledList>
				<li>
					<Text type="body1" black={true}>
						Unselected firm will not be notified.
					</Text>
					<Text type="body1" black={true}>
						Remaining firm(s) will not be notified.
					</Text>
				</li>
			</StyledList>
		</DialogWizardContent>
	)
}

export default OptionUnselectFirmConfirm
