import { gql } from '@persuit/ui-graphql'
import userActivityGql from '../fragments/user-activity-gql'

const saveUserActivity = gql`
	${userActivityGql}

	mutation ($_id: ID) {
		saveUserActivity(_id: $_id) {
			...UserActivity
		}
	}
`

export default saveUserActivity
