import { useEffect } from 'react'
import { getOr, isEqual } from 'lodash/fp'
import { useSubscription } from '@apollo/client'

import { FILESCAN_SUBSCRIPTION } from './file-gql-subscription'

const updateFilesWithScanResult = ({ scanResult, filesToUpdate }) => {
	const scannedFile = getOr(null, 'fileScan', scanResult)
	if (!scannedFile) {
		return filesToUpdate
	}

	if (!Array.isArray(filesToUpdate)) {
		return []
	}

	const updatedFileList = filesToUpdate.map((file) => {
		if (file._id === scannedFile._id) {
			return {
				...file,
				fileStatus: scannedFile.fileStatus,
			}
		}

		return file
	})

	return updatedFileList
}

export const useFileScan = ({ files, onScanResult }) => {
	const { data: scanResult } = useSubscription(FILESCAN_SUBSCRIPTION)

	const scannedFiles = updateFilesWithScanResult({ scanResult, filesToUpdate: files })

	useEffect(() => {
		if (!isEqual(files, scannedFiles)) {
			onScanResult(scannedFiles)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scannedFiles])

	return {
		scannedFiles,
	}
}
