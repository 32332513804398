import { Fragment } from 'react'
import { Spacer, Typography } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const helpTextParagraphs = [
	{
		title: '',
		text: 'Choose a pricing structure for this item. Setting a preference will make it easier to compare proposals.',
	},
	{
		title: 'No preference: ',
		text: 'Let firms choose their own pricing structure.',
	},
	{
		title: 'Alternative Fee Arrangements (AFA) ',
		text: '(Recommended) : Capped Fee, Collared Fee, Contingency Fee, Fixed Fee, Success Fee.',
	},
	{
		title: 'Budgeting Estimate:',
		text: "If you're only looking for an estimate which is subject to change, select this option.",
	},
	{
		title: 'Hourly Rate:',
		text: "Firms will respond with a single hourly rate for this item. If you'd like to receive multiple rates, select Rate Card.",
	},
	{
		title: 'Rate Card:',
		text: 'Ask for multiple rates with a Rate Card and / or let firms add their own. For each entry you add the firm will respond with a corresponding rate. For specific auction settings, firms will be able to see their competitor’s rates but not their corresponding titles.',
	},
	{
		title: 'Other (see instructions):',
		text: "If you'd like the firms to follow another type of pricing stucture, list it out in the Instructions and Assumptions box. ",
	},
	{
		title: 'No charge:',
		text: 'If the fee is not applicable for this Item or if you’d like to not get charged, select this option.',
	},
]

const helpTextParagraphs1 = [
	{
		title: '',
		text: 'Choose a pricing structure for this item. Setting a preference will make it easier to compare proposals.',
	},
	{
		title: 'No preference: ',
		text: 'Let firms choose their own pricing structure.',
	},
	{
		title: 'Fee Arrangements ',
		text: '(Recommended) : Capped Fee, Collared Fee, Contingency Fee (%), Fixed Fee, Success Fee.',
	},
	{
		title: 'Budgeting Estimate:',
		text: "If you're only looking for an estimate which is subject to change, select this option.",
	},
	{
		title: 'Hourly Rate:',
		text: "Firms will respond with a single hourly rate for this item. If you'd like to receive multiple rates, select Rate Card.",
	},
	{
		title: 'Rate Card:',
		text: 'Ask for multiple rates with a Rate Card and / or let firms add their own. For each entry you add the firm will respond with a corresponding rate. For specific auction settings, firms will be able to see their competitor’s rates but not their corresponding titles.',
	},
	{
		title: 'Other (see instructions):',
		text: "If you'd like the firms to follow another type of pricing stucture, list it out in the Instructions and Assumptions box. ",
	},
	{
		title: 'Discount:',
		text: 'Request or allow the firm to offer a discount in the form of a percentage.',
	},
	{
		title: 'No charge:',
		text: 'If the fee is not applicable for this Item or if you’d like to not get charged, select this option.',
	},
]

export const PricingItemPricingPopoverContent = ({
	isOriginalPricingItem = false,
}: {
	isOriginalPricingItem: boolean
}) => {
	const { toggles } = useFeatureToggles()

	const isToggleOn = toggles['dev-3327.contingency-percentage']

	const helpText = isToggleOn ? helpTextParagraphs1 : helpTextParagraphs

	return (
		<Fragment>
			{isOriginalPricingItem && (
				<Fragment>
					<Typography variant="body2" style={{ fontWeight: 'bold' }}>
						Once set, the pricing structure cannot be altered
					</Typography>
					<Spacer />
					<hr style={{ width: '100%' }} />
					<Spacer />
				</Fragment>
			)}

			{/*
			Iterate over each help text paragraph.
			Emit the title in bold (if there is one)

			Emit the help text always
			*/}
			{helpText.map(({ title, text }, index) => (
				<Fragment key={index}>
					<Typography variant="body2" component="p">
						<Typography
							variant="body2"
							component="span"
							style={{
								fontWeight: 'bold',
							}}
						>
							{title}{' '}
						</Typography>
						{text}
					</Typography>

					<Spacer space={2} />
				</Fragment>
			))}
		</Fragment>
	)
}
