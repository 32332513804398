import partition from 'lodash/fp/partition'
import getOr from 'lodash/fp/getOr'
import axios from '../../../axios'

export const uploadFilesUsingAxios = async ({ endpoint, filesToUpload }) => {
	const fileUploadPromises = filesToUpload.map((fileToUpload) => {
		const formData = new FormData()
		formData.append('file', fileToUpload)
		return axios.post(endpoint, formData)
	})

	const uploadResults = await Promise.allSettled(fileUploadPromises)
	const [fullfilledPromises, rejectedPromises] = partition({ status: 'fulfilled' }, uploadResults)
	const uploadedFiles = fullfilledPromises.map((fullfilledPromise) =>
		getOr({}, 'value.data', fullfilledPromise),
	)
	const filesWithError = rejectedPromises.map((rejectedPromise) =>
		getOr('', 'reason', rejectedPromise),
	)

	return { uploadedFiles, filesWithError }
}
