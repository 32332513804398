import { styled } from '../../theme'
import { Box, BoxProps } from '../box'

export type LoadingSpinnerProps = {
	/** Use spinnerStatic = true to display without animation.
	 *  Use case is on heavy rendering where the DOM is frozen and the animation halts.
	 *  Default to false */
	spinnerStatic?: boolean
} & BoxProps

const LOADING_SPINNER_LOGO_PATH_LENGTH = '182' // The SVG loading image has a path length of 182

const SpinnerPath = styled('path')<LoadingSpinnerProps>(
	({ theme, spinnerStatic }) => `
	stroke: ${theme.palette.deepPurple800};
	stroke-dashoffset: 0;

	${
		!spinnerStatic &&
		`
			stroke-dasharray: ${LOADING_SPINNER_LOGO_PATH_LENGTH};
			animation-name: animate-loading-spinner;
			animation-duration: 3.5s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		`
	}
`,
)

const LoadingText = styled('span')`
	text-transform: uppercase;
	margin-top: 0.5em;
	font-size: 1.3em;
	color: ${(props) => props.theme.palette.deepPurple800};
`

const Container = styled(Box)`
	@keyframes animate-loading-spinner {
		0% {
			stroke-dashoffset: ${LOADING_SPINNER_LOGO_PATH_LENGTH};
		}

		50% {
			stroke-dashoffset: 0;
		}

		100% {
			stroke-dashoffset: -${LOADING_SPINNER_LOGO_PATH_LENGTH};
		}
	}
`

export const LoadingSpinner = ({ spinnerStatic = false, ...boxProps }: LoadingSpinnerProps) => {
	return (
		<Container
			display={'flex'}
			justifyContent="center"
			width={'100%'}
			marginTop="1em"
			textAlign={'center'}
			{...boxProps}
		>
			<Box top={1} bottom={1} left={4} right={4}>
				<Box mb="1rem">
					<svg width="62.6px" height="84.6px" viewBox="0 0 31.3 42.3">
						<SpinnerPath
							fill="none"
							d="M7.8,16.9v-10h8.6c4.7,0,7.2,2.8,7.2,6.8
					c0,3.9-2.4,6.7-7.2,6.7L1,20.4V1h15.6c8.3,0,13.8,4.3,13.8,12.7c0,8.2-5.4,12.6-13.8,12.6H7.8v15H1V24.6"
							spinnerStatic={spinnerStatic}
						/>
					</svg>
				</Box>
				<LoadingText>Loading</LoadingText>
			</Box>
		</Container>
	)
}
