import { useEffect, useRef, useState } from 'react'
import { useWhenChanged } from './use-when-changed'

export type UseHasChangedRecentlyProps = {
	/** The value to monitor */
	value: unknown

	/**
	 * The period of time (in milliseconds) that the hook should
	 * return true for after a change
	 */
	period: number
}

/**
 * Returns true if the value has changed with the given period
 */
export function useHasChangedRecently({ value, period }: UseHasChangedRecentlyProps) {
	const [result, setResult] = useState(true)
	const resetTimeout = useRef(0)

	useWhenChanged({
		value,
		notifyOnFirstRender: true,
		callback: () => {
			clearTimeout(resetTimeout.current)

			setResult(true)
			resetTimeout.current = window.setTimeout(() => {
				setResult(false)
			}, period)
		},
	})

	// Clear the timeout when the component is unmounted
	useEffect(() => {
		return () => clearTimeout(resetTimeout.current)
	}, [])

	return result
}
