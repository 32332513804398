import { ReactNode } from 'react'
import { DialogDeprecated, DialogTitle, theme } from '@persuit/ui-components'

type Breakpoints = keyof typeof theme.breakpoints.values

type DialogWizardProps = {
	wizardName: string
	children: ReactNode
	width?: false | Breakpoints
}

export const DialogWizard = ({ wizardName, children, width }: DialogWizardProps) => {
	return (
		<DialogDeprecated
			open={true}
			fullWidth={true}
			maxWidth={width} // default value is 'md' breakpoint as set in theme config
			sx={{
				'& .MuiDialog-container': { alignItems: 'flex-start' },
				'& .MuiPaper-root': { overflow: 'hidden' },
			}}
		>
			<DialogTitle>{wizardName}</DialogTitle>
			{children}
		</DialogDeprecated>
	)
}
