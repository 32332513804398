import { Tooltip, useTheme } from '@persuit/ui-components'

type ApprovedFirmLogoProps = {
	disabled?: boolean
}

export const ApprovedFirmLogo = ({ disabled = false }: ApprovedFirmLogoProps) => {
	const theme = useTheme()

	return (
		<Tooltip title="Approved">
			<svg
				width={16}
				height={16}
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					width: 16,
					height: 16,
					borderRadius: theme.spacing(0.25),
					opacity: disabled ? 0.5 : 1,
				}}
			>
				<rect
					x={0}
					width={16}
					height={16}
					rx={4}
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					fill="rgb(83, 83, 89)"
				/>
				<text
					x={8.25}
					y={8.8}
					aria-hidden={true}
					fill="white"
					textAnchor="middle"
					dominantBaseline="middle"
					style={{
						fontSize: 12,
						fontWeight: 500,
					}}
				>
					A
				</text>
			</svg>
		</Tooltip>
	)
}
