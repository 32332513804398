import { lazyComponent, PageTitle, Spacer } from '@persuit/ui-components'
import { Unauthorized } from '@persuit/ui-shared-components'
import { Fragment, Suspense } from 'react'
import { connect } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import { SentryRoute as Route } from '@persuit/ui-logger'
import Offset from '../components/offset'

// These two imports get tree-shaken out of the prod builds. See Below.
import ClientDemo from '../components/status/client/demo'
import FirmDemo from '../components/status/firm/demo'
import { App, AppPublic } from '../containers/app'

// These are always rendered don't code split
import { LoadSession } from '../containers/load-session'

// These are almost always rendered
import { RfpTracking } from '../containers/rfp-tracking/rfp-tracking'
import Settings from '../containers/settings'

import { getOrFalse, getOrNull } from '../utils/lenses'

const MessagesPage = lazyComponent(() =>
	import('@client/pages/messages/index').then((m) => m.MessagesPage),
)

const InvalidToken = lazyComponent(() =>
	import('../components/auth/invalid-token').then((m) => m.InvalidToken),
)
const LoginForm = lazyComponent(() =>
	import('../components/auth/login-form').then((m) => m.LoginForm),
)
const RecoverPassword = lazyComponent(() =>
	import('../components/auth/recover-password').then((m) => m.RecoverPassword),
)
const ResendActivation = lazyComponent(() =>
	import('../components/auth/resend-activation').then((m) => m.ResendActivation),
)
const SAMLFailed = lazyComponent(() => import('../components/auth/saml-failed'))
const SetPassword = lazyComponent(() =>
	import('../components/auth/set-password').then((m) => m.SetPassword),
)

const ShareNotActive = lazyComponent(() =>
	import('../components/auth/share-notactive').then((m) => m.ShareNotActive),
)
const ShareUnregistered = lazyComponent(() =>
	import('../components/auth/share-unregistered').then((m) => m.ShareUnregistered),
)
const SSOFailed = lazyComponent(() => import('../components/auth/sso-failed'))
const SAMLLogout = lazyComponent(() => import('../components/auth/saml-logout'))
const Dashboard = lazyComponent(() => import('../containers/dashboard'))
const InfoSlider = lazyComponent(() =>
	import('../containers/info-slider').then((m) => m.InfoSlider),
)
const Notifications = lazyComponent(() =>
	import('../containers/notifications').then((m) => m.NotificationsPage),
)
const RequestRedirector = lazyComponent(() => import('../containers/request/request-redirector'))
const RfpRenderer = lazyComponent(() => import('../containers/rfp-renderer'))
const ClientRequestContainer = lazyComponent(async () => await import('../containers/rfp'))
const SettingsCompanyProfile = lazyComponent(() =>
	import('../containers/settings/settings-company-profile'),
)
const SettingsTrackingFields = lazyComponent(() =>
	import('../containers/settings/settings-tracking-fields').then((m) => m.SettingsTrackingFields),
)
const SettingsContacts = lazyComponent(() =>
	import('../containers/settings/settings-contacts').then((m) => m.SettingsContacts),
)
const SettingsCustomEmails = lazyComponent(() =>
	import('../containers/settings/settings-custom-emails').then((m) => m.SettingsCustomEmails),
)
const SettingsTeams = lazyComponent(() =>
	import('../containers/settings/settings-teams').then((m) => m.SettingsTeams),
)
const SettingsHome = lazyComponent(() => import('../containers/settings/settings-home'))

const UserProfile = lazyComponent(() => import('../containers/user-profile/index'))
const Onboarding = lazyComponent(() =>
	import('../containers/user-profile/onboarding').then((m) => m.Onboarding),
)
const OnboardingConfirmation = lazyComponent(() =>
	import('../containers/user-profile/onboarding-confirmation').then(
		(m) => m.OnboardingConfirmation,
	),
)
const AnalyticsSettingsPage = lazyComponent(() =>
	import('../pages/analytics-settings').then((m) => m.AnalyticsSettingsPage),
)
const FirmFinder = lazyComponent(() => import('../pages/firm-finder').then((m) => m.FirmFinder))
const FirmPerformancePage = lazyComponent(() => import('../pages/firm-performance-page'))
const FirmRequestView = lazyComponent(() => import('../pages/firm-request-view-page'))
const HumanFriendlyIdRedirectorPage = lazyComponent(() =>
	import('../pages/human-friendly-id-redirector-page'),
)
const LoeTemplateEdit = lazyComponent(() => import('../pages/loe-template/loe-template-edit'))
const LoeTemplateList = lazyComponent(() =>
	import('../pages/loe-template/loe-template-list').then((m) => m.LoeTemplateList),
)
const NewRfpForm = lazyComponent(() => import('../pages/new-rfp-form'))
const ProposalPreviewDraftAutosave = lazyComponent(() =>
	import('../pages/proposal-preview-autosave'),
)
const CreateRequestFromTemplatePage = lazyComponent(() =>
	import('../pages/create-request-from-template').then((m) => m.CreateRequestFromTemplatePage),
)
const RequestFromTemplateView = lazyComponent(() =>
	import('../pages/request-from-template').then((m) => m.RequestFromTemplateView),
)
const RequestProposalRevisionPage = lazyComponent(() =>
	import('../pages/request-proposal-revision'),
)

const RfpUpdateForm = lazyComponent(() => import('../pages/rfp-update-form/index'))
const ManageTemplatesPage = lazyComponent(() =>
	import('../pages/templates').then((m) => m.ManageTemplatesPage),
)
const ManageTemplateView = lazyComponent(() =>
	import('../pages/templates').then((m) => m.ManageTemplateView),
)
const RfpTemplateForm = lazyComponent(() => import('../pages/rfp-template-form-page'))
const FirmDashboardLayoutPage = lazyComponent(() =>
	import('../pages/firm-dashboard-page').then((m) => m.FirmDashboardLayoutPage),
)
const Proposals = lazyComponent(() =>
	import('../pages/firm-dashboard-page').then((m) => m.Proposals),
)
const Requests = lazyComponent(() => import('../pages/firm-dashboard-page').then((m) => m.Requests))

const Insights = lazyComponent(() => import('../pages/insights').then((m) => m.Insights))
const RealTimeBusinessReview = lazyComponent(() =>
	import('../pages/real-time-business-review').then((m) => m.RealTimeBusinessReview),
)
const ProgramBenchmarking = lazyComponent(() =>
	import('../pages/client-benchmarking').then((m) => m.ProgramBenchmarking),
)
const TeamPerformance = lazyComponent(() =>
	import('../pages/team-performance').then((m) => m.TeamPerformance),
)

const ShouldCostClientView = lazyComponent(() =>
	import('../pages/should-cost').then((m) => m.ShouldCostClientView),
)
const ShouldCostFirmView = lazyComponent(() =>
	import('../pages/should-cost').then((m) => m.ShouldCostFirmView),
)

const PanelManagementContainer = lazyComponent(() => import('../containers/panel-management'))

const PanelManagement = lazyComponent(() =>
	import('../pages/panel-management').then((m) => m.PanelManagement),
)
const EditFirmList = lazyComponent(() =>
	import('../pages/panel-management/edit-list').then((m) => m.EditList),
)

const Routes = ({ isLoggedIn, isClientUser, isFirmUser, isImpersonated }) => {
	return (
		<Suspense>
			<PageTitle>PERSUIT</PageTitle>
			{/* TODO: [DS-1458] This LoadSession is called everytime a page is navigated to.
		This needs to be there always or else a white screen appears when a page is reloaded
		Another point to note is it sets the login status in redux*/}
			<LoadSession />

			<Route path="/:lng/">
				{!isLoggedIn && (
					<AppPublic>
						<Route path="/:lng/user/login/">
							<LoginForm captchaMode="recaptcha" />
						</Route>

						<Route path="/:lng/user/login2/">
							<LoginForm captchaMode="hcaptcha" />
						</Route>

						<Route path="/:lng/user/set-password">
							<SetPassword />
						</Route>

						<Route path="/:lng/user/invalid-token">
							<InvalidToken />
						</Route>

						<Route path="/:lng/user/recover-password">
							<RecoverPassword captchaMode="recaptcha" />
						</Route>

						<Route path="/:lng/user/resend-activation">
							<ResendActivation captchaMode="recaptcha" />
						</Route>

						<Route path="/:lng/user/recover-password-force">
							<RecoverPassword disableEmailField={true} forcePasswordReset={true} />
						</Route>

						<Route path="/:lng/user/share-unregistered">
							<ShareUnregistered />
						</Route>

						<Route path="/:lng/user/share-notonboard">
							<ShareNotActive />
						</Route>

						<Route path="/:lng/user/saml-failed">
							<SAMLFailed />
						</Route>

						<Route path="/:lng/user/sso-failed">
							<SSOFailed />
						</Route>

						<Route path="/:lng/user/saml-logout">
							<SAMLLogout />
						</Route>
					</AppPublic>
				)}

				{isLoggedIn && (
					<Route path="/:lng/messages/:requestId" exact={true}>
						<MessagesPage />
					</Route>
				)}

				{isLoggedIn && (
					<App>
						{/* TODO: move this route to a better location? */}
						<Route path="/:lng/getting-started" exact={true}>
							<InfoSlider />
						</Route>
						<Route path="/:lng/onboarding" exact={true}>
							<Onboarding />
						</Route>
						<Route path="/:lng/onboarding/confirmation" exact={true}>
							<OnboardingConfirmation />
						</Route>

						<Route path="/:lng/" exact={true}>
							<WithSpacer>
								<Offset>
									<Dashboard />
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/user/invalid-token">
							<WithSpacer>
								<Offset>
									<InvalidToken />
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/user/set-password">
							<WithSpacer>
								<Offset>
									<SetPassword />
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/request/:requestId" exact={true}>
							<WithSpacer>
								<Offset>
									<RequestRedirector />
								</Offset>
							</WithSpacer>
						</Route>
						{isClientUser && (
							<Fragment>
								{/*******************************
										Update, edit, view RFP
							 *********************************/}
								<Switch>
									<Route path="/:lng/id/:humanFriendlyId" exact={true}>
										<WithSpacer>
											<Offset>
												{isLoggedIn && isClientUser && (
													<HumanFriendlyIdRedirectorPage isClient={true} />
												)}
											</Offset>
										</WithSpacer>
									</Route>
									<Route
										path="/:lng/request/:requestId/update/edit/:section?/:anchor?"
										exact={true}
									>
										<RfpUpdateForm />
									</Route>

									<Route path="/:lng/request/:requestId/proposals/revise/:proposalId/" exact={true}>
										<WithSpacer>
											<Offset>
												<RequestProposalRevisionPage />
											</Offset>
										</WithSpacer>
									</Route>
									<Route path="/:lng/request/:requestId/edit/:section?/:anchor?" exact={true}>
										<NewRfpForm />
									</Route>
									{/* Redirect the old rfp routes to the new request routes */}
									{/* 1. RFP + proposal redirect */}
									<Redirect
										from="/:lng/rfp/:requestId/proposals/view/:proposalId/*"
										to="/:lng/request/:requestId/proposals/view/:proposalId"
									/>
									{/* 2. RFP only redirect */}
									<Redirect
										from="/:lng/rfp/:requestId/*"
										to="/:lng/request/:requestId/matter/view"
									/>
									{/* {isNewFirmSelectFlow && (
										<Route path="/:lng/request/:requestId/proposals/view/:proposalId" exact={true}>
											<Offset>
												<ClientRequestContainer>
													<RfpProposalRenderer />
												</ClientRequestContainer>
											</Offset>
										</Route>
									)} */}
									<Route path="/:lng/request/:requestId/:tab/:action/:proposalId?" exact={true}>
										<WithSpacer>
											<Offset>
												<ClientRequestContainer>
													<RfpRenderer />
												</ClientRequestContainer>
											</Offset>
										</WithSpacer>
									</Route>
									{/* Used for users who can only see the tracking details of an RFP */}
									<Route path="/:lng/tracking/:requestId" exact={true}>
										<WithSpacer>
											<Offset>
												<RfpTracking />
											</Offset>
										</WithSpacer>
									</Route>
								</Switch>

								{/*******************************
									Create an RFP from template
							 *********************************/}
								{/*
									Redirect
									From:	/en/create-request-from-template/
									To:		/en/create-request-from-template/custom/
								*/}
								<Route path="/en/create-request-from-template/" exact={true}>
									<WithSpacer>
										<CreateRequestFromTemplatePage />
									</WithSpacer>
								</Route>

								<Route path="/:lng/create-request-from-template/:templateId/view/">
									<WithSpacer>
										<Offset>
											<RequestFromTemplateView />
										</Offset>
									</WithSpacer>
								</Route>

								{/*
									<Route path="request">
										<Route path=":requestId/update/edit" component={RfpDraftRevisionPage} />
										<Route path=":requestId/update/preview" component={RfpDraftRevisionPreview} />
										<Route path="create" component={RequestCreate} />
									</Route>
								*/}
								{/*******************************
										Manage templates
							 *********************************/}
								{/* Redirect /templates/ to /templates/custom/ */}
								{/*
									Redirect
									From:	/en/templates/
									To:		/en/templates/custom/
								*/}
								<Route path="/en/templates" exact={true}>
									<WithSpacer>
										<Redirect to="/en/templates/custom/" />
									</WithSpacer>
								</Route>
								<Route path="/:lng/templates/:templateView" exact={true}>
									<WithSpacer>
										<Offset>
											<ManageTemplatesPage />
										</Offset>
									</WithSpacer>
								</Route>
								<Route path="/:lng/templates/:templateId/view" exact={true}>
									<WithSpacer>
										<Offset>
											<ManageTemplateView />
										</Offset>
									</WithSpacer>
								</Route>
								<Route path="/:lng/templates/:templateId/edit/:section?/:anchor?" exact={true}>
									<RfpTemplateForm />
								</Route>

								{isImpersonated && (
									<Fragment>
										<Route path="/en/loe-templates" exact={true}>
											<WithSpacer>
												<Offset>
													<LoeTemplateList />
												</Offset>
											</WithSpacer>
										</Route>
										<Route path="/en/loe-templates/:loeTemplateId/edit">
											<WithSpacer>
												<Offset>
													<LoeTemplateEdit />
												</Offset>
											</WithSpacer>
										</Route>
									</Fragment>
								)}
								{/*******************************
										Reports and analytics
							 *********************************/}

								<Route path="/:lng/firm-recommender" exact={true}>
									<WithSpacer>
										<Offset>
											<FirmFinder />
										</Offset>
									</WithSpacer>
								</Route>

								<Route path="/:lng/analytics-dashboards/:tab?" exact={true}>
									<WithSpacer>
										<Insights />
									</WithSpacer>
								</Route>
								<Switch>
									<Redirect
										from="/:lng/real-time-business-review"
										to="/:lng/real-time-business-review/opportunity-loss/"
										exact={true}
									/>

									<Route path="/:lng/real-time-business-review/opportunity-loss" exact={true}>
										<WithSpacer>
											<RealTimeBusinessReview />
										</WithSpacer>
									</Route>
									<Route
										path="/:lng/real-time-business-review/competitive-intelligence"
										exact={true}
									>
										<WithSpacer>
											<RealTimeBusinessReview />
										</WithSpacer>
									</Route>
									<Route path="/:lng/real-time-business-review/proposal-feedback" exact={true}>
										<WithSpacer>
											<RealTimeBusinessReview />
										</WithSpacer>
									</Route>
								</Switch>

								<Switch>
									<Redirect
										from="/:lng/program-benchmarking"
										to="/:lng/program-benchmarking/program-overview/"
										exact={true}
									/>

									<Route path="/:lng/program-benchmarking/program-overview" exact={true}>
										<WithSpacer>
											<ProgramBenchmarking />
										</WithSpacer>
									</Route>
									<Route path="/:lng/program-benchmarking/coverage" exact={true}>
										<WithSpacer>
											<ProgramBenchmarking />
										</WithSpacer>
									</Route>
									<Route path="/:lng/program-benchmarking/diversity" exact={true}>
										<WithSpacer>
											<ProgramBenchmarking />
										</WithSpacer>
									</Route>
									<Route path="/:lng/program-benchmarking/efficiency" exact={true}>
										<WithSpacer>
											<ProgramBenchmarking />
										</WithSpacer>
									</Route>
									<Route path="/:lng/program-benchmarking/workflow-completion" exact={true}>
										<WithSpacer>
											<ProgramBenchmarking />
										</WithSpacer>
									</Route>
								</Switch>
								<Switch>
									<Redirect
										from="/:lng/team-performance"
										to="/:lng/team-performance/team-overview/"
										exact={true}
									/>

									<Route path="/:lng/team-performance/team-overview" exact={true}>
										<WithSpacer>
											<TeamPerformance />
										</WithSpacer>
									</Route>
									<Route path="/:lng/team-performance/coverage" exact={true}>
										<WithSpacer>
											<TeamPerformance />
										</WithSpacer>
									</Route>
									<Route path="/:lng/team-performance/diversity" exact={true}>
										<WithSpacer>
											<TeamPerformance />
										</WithSpacer>
									</Route>
									<Route path="/:lng/team-performance/efficiency" exact={true}>
										<WithSpacer>
											<TeamPerformance />
										</WithSpacer>
									</Route>
									<Route path="/:lng/team-performance/workflow-completion" exact={true}>
										<WithSpacer>
											<TeamPerformance />
										</WithSpacer>
									</Route>
								</Switch>
								<Switch>
									<Redirect
										from="/:lng/price-benchmarking"
										to="/:lng/price-benchmarking/fees"
										exact={true}
									/>
									<Route path="/:lng/price-benchmarking/:tab" exact={true}>
										<WithSpacer>
											<Offset>
												<ShouldCostClientView />
											</Offset>
										</WithSpacer>
									</Route>
								</Switch>

								{/*******************************
										Panel Management
							 *********************************/}
								<Route path="/:lng/panel-management" exact={true}>
									<WithSpacer>
										<PanelManagementContainer>
											<PanelManagement />
										</PanelManagementContainer>
									</WithSpacer>
								</Route>
								<Route path="/:lng/panel-management/edit/:listId?" exact={true}>
									<WithSpacer>
										<PanelManagementContainer>
											<EditFirmList />
										</PanelManagementContainer>
									</WithSpacer>
								</Route>
							</Fragment>
						)}

						{/*******************************
									Settings
					 *********************************/}
						<Route path="/:lng/settings" exact={true}>
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsHome />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>

						<Route path="/:lng/settings/teams/">
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsTeams />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>

						<Route path="/:lng/settings/company/">
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsCompanyProfile />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/settings/group/">
							<WithSpacer>
								<Offset>
									<SettingsCompanyProfile />
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/settings/contacts">
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsContacts />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/settings/tracking-fields">
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsTrackingFields />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/settings/custom-emails">
							<WithSpacer>
								<Offset>
									<Settings>
										<SettingsCustomEmails />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>

						<Route path="/:lng/settings/analytics-settings" exact={true}>
							<WithSpacer>
								<Offset>
									<Settings>
										<AnalyticsSettingsPage />
									</Settings>
								</Offset>
							</WithSpacer>
						</Route>
						{isFirmUser && (
							<Switch>
								<Route path="/:lng/id/:humanFriendlyId" exact={true}>
									<WithSpacer>
										<Offset>
											{isLoggedIn && isFirmUser && (
												<HumanFriendlyIdRedirectorPage isClient={false} />
											)}
										</Offset>
									</WithSpacer>
								</Route>
								<Route path="/:lng/request/:requestId/:tab/:action" exact={true}>
									<WithSpacer>
										<Offset>
											<FirmRequestView />
										</Offset>
									</WithSpacer>
								</Route>
								<Route path="/:lng/request/:requestId/:tab/:action/:proposalId" exact={true}>
									<WithSpacer>
										<Offset>
											<FirmRequestView />
										</Offset>
									</WithSpacer>
								</Route>
								<Route
									path="/:lng/request/:requestId/proposals/preview-detail/:proposalId/:locked?"
									exact={true}
								>
									<WithSpacer>
										<Offset>
											<ProposalPreviewDraftAutosave />
										</Offset>
									</WithSpacer>
								</Route>

								<Route path={`/:lng/requests/`} exact={true}>
									<WithSpacer>
										<Offset>
											<FirmDashboardLayoutPage />
											<Requests />
										</Offset>
									</WithSpacer>
								</Route>
								<Route path={`/:lng/proposals/`} exact={true}>
									<WithSpacer>
										<Offset>
											<FirmDashboardLayoutPage />
											<Proposals />
										</Offset>
									</WithSpacer>
								</Route>

								<Route path="/:lng/performance" exact={true}>
									<WithSpacer>
										<Offset>
											<FirmPerformancePage />
										</Offset>
									</WithSpacer>
								</Route>
								<Switch>
									<Redirect
										from="/:lng/price-benchmarking"
										to="/:lng/price-benchmarking/fees"
										exact={true}
									/>
									<Route path="/:lng/price-benchmarking/:tab" exact={true}>
										<WithSpacer>
											<Offset>
												<ShouldCostFirmView />
											</Offset>
										</WithSpacer>
									</Route>
								</Switch>
							</Switch>
						)}
						<Route path="/:lng/notifications">
							<WithSpacer>
								<Offset>
									<Notifications />
								</Offset>
							</WithSpacer>
						</Route>
						<Route path="/:lng/myprofile">
							<WithSpacer>
								<Offset>
									<UserProfile />
								</Offset>
							</WithSpacer>
						</Route>

						{/*
							Unauthorized page
							Not sure this route is used anymore
						*/}
						<Route path="/:lng/unauthorized">
							<WithSpacer>
								<Offset>
									<Unauthorized />
								</Offset>
							</WithSpacer>
						</Route>
						{/*******************************
								Dev only routes
					 *********************************/}
						{/* This NODE_ENV check prevents this route from working in prod builds */}
						{/* This means prod, qa, demo etc get a 404 */}
						{/* The two imports also get tree-shaken out in a prod build */}
						{process.env.NODE_ENV !== 'production' && (
							<Fragment>
								<Route path="/:lng/playground/client">
									<WithSpacer>
										<Offset>
											<ClientDemo />
										</Offset>
									</WithSpacer>
								</Route>
								<Route path="/:lng/playground/firm">
									<WithSpacer>
										<Offset>
											<FirmDemo />
										</Offset>
									</WithSpacer>
								</Route>
							</Fragment>
						)}
					</App>
				)}
			</Route>
		</Suspense>
	)
}

const mapStateToProps = (state) => {
	const orgType = getOrNull('auth.user.org.orgType', state)

	const isClientUser = orgType === 'CLIENT'
	const isFirmUser = orgType === 'FIRM'

	const isImpersonated = getOrFalse('auth.user.isImpersonated', state)

	// Only consider the user logged in if there is a user object loaded
	// Needed because the auth actions/reducers first set isLoggedIn=true and
	// then set the auth.user state
	// Between those two activies isLoggedIn=true and user=null
	// and when user=null various components are very unhappy
	const isLoggedIn = state.auth.isLoggedIn && state.auth.user

	return {
		isLoggedIn,
		isClientUser,
		isFirmUser,
		isImpersonated,
	}
}

/**
 * This component just adds space to the bottom of the page. This was originally in the app container which wraps everthing which causes problems on pages that do not need a spacer at the bottom of the page.
 *
 * In the future, pages should be responsible for their own styles, including bottom spacing and this component should be removed.
 *
 * If your page does not need a global bottom spacer, move it out of this container.
 */
const WithSpacer = ({ children }) => {
	return (
		<>
			{children}
			<Spacer shape="column" space={2} />
		</>
	)
}

export default connect(mapStateToProps)(Routes)
