// @ts-strict-ignore
import { Typography, Box } from '@persuit/ui-components'
import { pricingModels } from '../../../../common-components'
import { PricingCardItemNumbering } from '../../../../pricing'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

export type PricingItemHeaderProps = {
	title: string
	pricingModel: string
	subItemsCount: number
	pricingItemIndex: number
}

export const PricingItemHeader = ({
	title,
	pricingModel,
	subItemsCount,
	pricingItemIndex,
}: PricingItemHeaderProps) => {
	const { toggles } = useFeatureToggles()
	const isPercetageToggleOn = toggles['dev-3327.contingency-percentage']
	const [rateCardEntryText, subItemCountText] = [
		getSubItemText('rateCardEntry', subItemsCount),
		getSubItemText('subItemCount', subItemsCount),
	]

	const pricingItemModel = isPercetageToggleOn
		? pricingModels['item-dev-3327'][pricingModel]
		: pricingModels.item[pricingModel]

	return (
		<Box display="flex" alignItems="center" data-testid="pricing-item-header">
			<Box sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
				<Typography variant="body2" component="p" data-testid="numbering" sx={{ width: 'auto' }}>
					<PricingCardItemNumbering
						parentIndex={pricingItemIndex}
						containerProps={{
							p: '0',
							px: 1,
							component: 'span',
						}}
					/>
				</Typography>

				<Typography variant="body2" component="p" data-testid="itemTitle" title={title ?? ''}>
					{title}
				</Typography>
			</Box>

			<Typography
				variant="body2"
				component="p"
				data-testid="pricingModel"
				sx={{ width: '25%', pl: 1 }}
			>
				{pricingItemModel || ''}
			</Typography>

			{subItemsCount > 0 && (
				<Typography variant="body2" data-testid="numOfSubItems" sx={{ margin: 'auto !important' }}>
					{pricingModel === 'hourlyRates' ? rateCardEntryText : subItemCountText}
				</Typography>
			)}
		</Box>
	)
}

const getSubItemText = (type, count) => {
	const strings = {
		rateCardEntry: count > 1 ? `${count} Entries` : `${count} Entry`,
		subItemCount: count > 1 ? `${count} Sub-items` : `${count} Sub-item`,
	}

	return strings[type]
}
