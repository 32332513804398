import { useEffect } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { Box, Form, useTheme } from '@persuit/ui-components'
import { RatePricingSection } from '../rate-pricing-section'
import { TotalPriceSection } from '../total-price-section'
import { useForm } from 'react-hook-form'
import { useStore, useActions } from '../../store'
import { AverageRateBanner } from '../average-rate-banner'

export const ProposalQuickRevisionConfirmFormRateForm = () => {
	const theme = useTheme()
	const { deliverables, rateItemLookup, totalPriceValue, rateItemFormState } = useStore(
		({ deliverables, rateItemLookup, rateItemFormState }, selectors) => ({
			deliverables,
			rateItemLookup,
			totalPriceValue: selectors.currentTotalPriceValue(),
			rateItemFormState,
		}),
	)
	const { setRateItemFormState } = useActions()

	const defaultValues = Object.keys(rateItemLookup).reduce((acc, deliverableId) => {
		const pricingItem = rateItemLookup[deliverableId]

		if (['contingencyPercentage', 'discountPercentage'].includes(pricingItem.pricingModel)) {
			return {
				...acc,
				[deliverableId]: rateItemLookup[deliverableId].percentage,
			}
		}
		return {
			...acc,
			[deliverableId]: rateItemLookup[deliverableId].price,
		}
	}, {})

	const methods = useForm({
		defaultValues: {
			...defaultValues,
			...rateItemFormState,
			totalPriceValue,
		},
	})

	useEffect(() => {
		const { unsubscribe } = methods.watch((value) => {
			setRateItemFormState(value)
		})
		return () => unsubscribe()
	}, [methods, setRateItemFormState])

	if (isEmpty(deliverables)) return null

	return (
		<Form methods={methods}>
			<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
				<AverageRateBanner />
				<TotalPriceSection />
				{!isEmpty(rateItemLookup) && <RatePricingSection />}
			</Box>
		</Form>
	)
}
