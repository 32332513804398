import { isNil } from 'lodash/fp'
import * as React from 'react'
import { SROnly, Typography, TypographyProps } from '@persuit/ui-components'

export type OriginalOrSuggestedTextProps = {
	currentText?: React.ReactNode
	suggestedText?: React.ReactNode
	isTextSame?: boolean
	dataTestid?: string
	typographyProps?: TypographyProps
}

export const OriginalOrSuggestedText = ({
	currentText = null,
	suggestedText = null,
	isTextSame = false,
	dataTestid,
	typographyProps,
}: OriginalOrSuggestedTextProps) => {
	if (isTextSame === true) {
		return (
			<Typography data-testid={dataTestid} variant="body2" {...typographyProps}>
				{currentText}
			</Typography>
		)
	}

	if (!isNil(suggestedText)) {
		return (
			<Typography data-testid={dataTestid} variant="body2" color="error.main" {...typographyProps}>
				{typeof suggestedText !== 'object' ? (
					<>
						<SROnly>Updated to </SROnly>
						{suggestedText}
					</>
				) : (
					suggestedText
				)}
			</Typography>
		)
	}

	if (!isNil(currentText)) {
		return (
			<Typography data-testid={dataTestid} variant="body2" {...typographyProps}>
				{currentText}
			</Typography>
		)
	}

	return null
}
