import * as React from 'react'
import {
	useForm,
	UseFormProps,
	useWatch,
	useFieldArray as useFieldArrayRHF,
	UseFieldArrayReturn,
	Control,
	FieldPath,
	FieldPathValue,
	FieldArrayPath,
	UseFieldArrayProps,
	useFormContext,
	FieldValues,
	useController,
	UseControllerProps,
} from 'react-hook-form'

/**
 * Returns a set of RHF utilities with the TFieldValues preset
 * @example
 * const { useWatch } = createRHFUtils<FormValues>()
 */
export function createRHFUtils<TFieldValues extends FieldValues>() {
	const useFieldArray = <
		TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
		TKeyName extends string = 'id',
	>(
		props: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>,
	) => useFieldArrayRHF<TFieldValues, TFieldArrayName, TKeyName>(props)

	type FieldArrayProps<
		TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
		TKeyName extends string = 'id',
	> = UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> & {
		children: (
			props: UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>,
		) => React.ReactNode
	}

	const FieldArray = <
		TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
		TKeyName extends string = 'id',
	>({
		children,
		...rest
	}: FieldArrayProps<TFieldArrayName, TKeyName>) => <>{children(useFieldArray(rest))}</>

	return {
		useForm: (props: UseFormProps<TFieldValues>) => useForm(props),
		useWatch: <TFieldName extends FieldPath<TFieldValues>>(props?: {
			name: TFieldName
			defaultValue?: FieldPathValue<TFieldValues, TFieldName>
			control?: Control<TFieldValues>
			disabled?: boolean
			exact?: boolean
		}) => useWatch<TFieldValues, TFieldName>(props as any),
		useFieldArray,
		FieldArray,
		useFormContext: () => useFormContext<TFieldValues>(),
		/** Validates field name and type */
		fieldName: <U extends FieldPath<TFieldValues>>(fieldName: U) =>
			fieldName as unknown as OpaqueFieldName<NonNullable<FieldPathValue<TFieldValues, U>>>,
		/** Validates field name only */
		fieldNameOnly: <Field extends FieldPath<TFieldValues>>(fieldName: Field) => fieldName,
		useController: <
			TFieldValues extends FieldValues = FieldValues,
			TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
		>(
			props: UseControllerProps<TFieldValues, TName>,
		) => useController<TFieldValues, TName>(props),
	}
}

// A named "Box" that contains a value type this is just an arbitrary opaque
// type that we can reference by name
export type OpaqueFieldName<T> = {
	__fieldType: T
}
