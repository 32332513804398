import { isNotNil } from '@persuit/common-utils'
import { TemplateMatterType, UseCase } from '@persuit/ui-graphql/generated'
import { isTemplateMatterType } from '../../../common/data/template-tags'
import { isUseCase } from '../../../common/data/use-cases'

const BASE_ROUTE = '/en/create-request-from-template/'

type PageUrlInput = {
	filters?: SerializeFilterInput
}

export const pageUrl = ({ filters }: PageUrlInput = {}) => {
	return filters ? `${BASE_ROUTE}?${serializeFilters(filters)}` : BASE_ROUTE
}

type SerializeFilterInput = {
	useCaseIds: UseCase[]
	matterTypeIds: TemplateMatterType[]
	countryIds: string[]
}

export function serializeFilters({ useCaseIds, countryIds, matterTypeIds }: SerializeFilterInput) {
	return [
		useCaseIds.length > 0 ? `useCases=${useCaseIds.join(',')}` : null,
		matterTypeIds.length > 0 ? `matterTypes=${matterTypeIds.join(',')}` : null,
		countryIds.length > 0 ? `countries=${countryIds.join(',')}` : null,
	]
		.filter(isNotNil)
		.join('&')
}

export function parseFilters(
	query: string,
	isTemplateCountry: (countryId: string) => boolean = () => false,
) {
	const params = new URLSearchParams(query)

	const countryIds = params.get('countries')?.split(',').filter(isTemplateCountry) ?? []

	return {
		useCaseIds: params.get('useCases')?.split(',').filter(isUseCase) ?? [],
		matterTypeIds: params.get('matterTypes')?.split(',').filter(isTemplateMatterType) ?? [],
		countryIds,
	}
}
