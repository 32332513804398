import { groupBy } from 'lodash'
import { mapRecord, isNotNilProp, isNotNil } from '@persuit/common-utils'

import { Proposal } from '../types'

export function average(vals: number[]): number | null {
	if (vals.length === 0) return null
	return vals.reduce((acc, val) => acc + val, 0) / vals.length
}

function isRevisedProposal<T extends Proposal>(p: T): boolean {
	return p.status === 'revised'
}

export function isCurrentProposal<T extends Proposal>(p: T): boolean {
	return !p.isEliminated && !['revised', 'withdrawn'].includes(p.status)
}

export function findLatestProposal<T extends Proposal>(proposals: T[]): T | null {
	return proposals.reduce(
		(latest: null | T, proposal) =>
			!latest ? proposal : (latest.createdAt ?? 0) > (proposal.createdAt ?? 0) ? latest : proposal,
		null,
	)
}

export type LatestFirmProposals = {
	proposal: Proposal
	revisedProposal: Proposal | null
	revisedProposals: Proposal[]
}

/**
 * Gets the latest current proposal and last revision which will be shown in the
 * rates table
 */
export function getRowProposals(proposals: Proposal[]): LatestFirmProposals[] {
	const proposalsByFirm = groupBy(proposals, (p) => p.org?._id)

	const latestProposalsByFirm = mapRecord(proposalsByFirm, (_proposals) => {
		const proposals = _proposals.filter(isNotNilProp('createdAt')).filter(isNotNilProp('org'))
		const currentProposals = proposals.filter(isCurrentProposal)
		const latestProposal = findLatestProposal(currentProposals)

		const revisedProposals = getProposalRevisions(latestProposal?.proposalFamilyId ?? '', proposals)
		const latestRevisedProposal = findLatestProposal(revisedProposals)

		if (!latestProposal) {
			return null
		}

		return {
			proposal: latestProposal,
			revisedProposal: latestRevisedProposal,
			revisedProposals,
		}
	})

	return Object.values(latestProposalsByFirm).filter(isNotNil)
}

/**
 * Get all proposal revisions for a proposal family id
 */
export function getProposalRevisions(proposalFamilyId: string, _proposals: Proposal[]): Proposal[] {
	const proposals = _proposals.filter(isNotNilProp('createdAt'))

	const revisedProposals = proposals
		.filter((p) => p.proposalFamilyId === proposalFamilyId)
		.filter(isRevisedProposal)
		.filter(isNotNilProp('updatedAt'))
		.sort((a, b) => b.updatedAt - a.updatedAt)

	return revisedProposals
}
