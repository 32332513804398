import { currencies } from '@persuit/common-utils'
import { map } from 'lodash/fp'
import { partition } from 'lodash'

type RawCurrency = {
	code: string
	symbol: string
	fullName: string
}

export type Currency = {
	value: string
	label: string
	symbol: string
}

const mapToObj = (val: RawCurrency): Currency => ({
	value: val.code,
	symbol: val.symbol,
	label: val.fullName,
})

const mappedCurrencies = map(mapToObj, currencies)
const allCurrencyCodes = mappedCurrencies.map((c) => c.value)

const [mostUsedCurrencies, otherCurrencies] = partition(mappedCurrencies, ({ value }) =>
	['AUD', 'USD', 'GBP', 'EUR'].includes(value),
)
export { allCurrencyCodes, mostUsedCurrencies, otherCurrencies }
