import * as React from 'react'
import { Box } from '@persuit/ui-components'

type FormFloatingErrorsContainerProps = {
	children: React.ReactNode
}

export const FormFloatingErrorsContainer = ({ children }: FormFloatingErrorsContainerProps) => (
	<Box id={'rfp-form-error-container'} tabIndex={-1} aria-label="Form errors" role="group">
		<Box>{children}</Box>
	</Box>
)
