import { useState, useEffect } from 'react'
import { SyncTrackingFieldsWizardContent } from './sync-tracking-fields-wizard-content'
import { SyncTrackingFieldsWizardSteps } from './sync-tracking-fields-wizard-steps'
import {
	Spacer,
	DialogDeprecated,
	DialogTitle,
	LoadingSpinner,
	DialogClose,
} from '@persuit/ui-components'
import { SearchMatterPanel, SelectMatterPanel, ConfirmMatterPanel } from './wizard-panels'
import { toString, trim } from 'lodash/fp'
import {
	ExternalApiMatter,
	FnHandleOnChangeEvent,
	FnHandleOnSelectEvent,
} from './sync-tracking-fields-wizard.types'
import { graphql, useQuery, useLazyQuery } from '@persuit/ui-graphql'
import { isNotNil } from '@persuit/common-utils'

export const GET_EXTERNAL_MATTER_TYPES = graphql(`
	query SyncTrackingWizardGetExternalApiMatterTypes($rfpId: ID!) {
		getExternalApiMatterTypes(rfpId: $rfpId) {
			id
			name
		}
	}
`)

export const SEARCH_EXTERNAL_MATTERS = graphql(`
	query SyncTrackingWizardSearchExternalApiMatters(
		$rfpId: ID!
		$searchTerm: String!
		$matterType: String
	) {
		searchExternalApiMatters(rfpId: $rfpId, searchTerm: $searchTerm, matterType: $matterType) {
			externalId
			matterNumber
			matterName
			trackingFields {
				fieldName
				values
			}
		}
	}
`)

export type SyncTrackingFieldsWizardProps = {
	rfpId: string
	handleClose: () => void
	handleSync: (props: { externalId: string; matterType: string }) => Promise<void>
	externalSystemName?: string
	searchUsingMatterType?: boolean
	updateMatterReference?: boolean
}

export const SyncTrackingFieldsWizard = ({
	rfpId,
	handleClose,
	handleSync,
	externalSystemName = 'External Matter',
	searchUsingMatterType = false,
	updateMatterReference = false,
}: SyncTrackingFieldsWizardProps) => {
	const [wizardStep, setWizardStep] = useState<SyncTrackingFieldsWizardSteps>(
		SyncTrackingFieldsWizardSteps.SEARCH_EXTERNAL_MATTER,
	)
	const [syncTrackingFieldsError, setSyncTrackingFieldsError] = useState('')
	const [searchString, setSearchString] = useState('')
	const [selectedMatterType, setSelectedMatterType] = useState('')
	const [isLoadingExternalMatter, setIsLoadingExternalMatter] = useState(false)
	const [matterTypeHelperText, setMatterTypeHelperText] = useState('')
	const [searchStringHelperText, setSearchStringHelperText] = useState('')
	const [searchedMatters, setSearchedMatters] = useState<ExternalApiMatter[] | null>(null)
	const [selectedMatter, setSelectedMatter] = useState<ExternalApiMatter | null>(null)

	const {
		data: matterTypesData,
		loading: matterTypesLoading,
		error: matterTypesError,
	} = useQuery(GET_EXTERNAL_MATTER_TYPES, {
		variables: { rfpId },
	})

	const [
		searchExternalMatters,
		{ loading: searchMatterLoading, data: searchMatterData, error: searchMatterErrors },
	] = useLazyQuery(SEARCH_EXTERNAL_MATTERS, {
		fetchPolicy: 'no-cache',
	})

	useEffect(() => {
		setIsLoadingExternalMatter(searchMatterLoading)
		setSearchedMatters(null)
		setSearchStringHelperText('')
	}, [searchMatterLoading])

	useEffect(() => {
		if (searchMatterErrors) {
			setSearchStringHelperText(toString(searchMatterErrors))
			setIsLoadingExternalMatter(false)
			setSearchedMatters(null)
		}
	}, [searchMatterErrors])

	useEffect(() => {
		if (searchMatterData && searchMatterData.searchExternalApiMatters) {
			setIsLoadingExternalMatter(false)
			setSearchedMatters((searchMatterData.searchExternalApiMatters ?? []).filter(isNotNil))
			setWizardStep(SyncTrackingFieldsWizardSteps.SELECT_EXTERNAL_MATTER)
		} else {
			setSearchedMatters(null)
		}
	}, [searchMatterData])

	const syncWizardTitle = `Sync from ${externalSystemName}`

	if (matterTypesLoading === true) {
		return (
			<DialogDeprecated open={true} fullWidth={true} maxWidth="md">
				<DialogTitle>{syncWizardTitle}</DialogTitle>
				<LoadingSpinner />
				<Spacer shape="row" space={8} />
			</DialogDeprecated>
		)
	}

	if (matterTypesError) {
		console.error('Error when fetching external matter types:', matterTypesError)
	}

	const matterTypes = matterTypesData?.getExternalApiMatterTypes ?? []

	if (
		!!searchUsingMatterType &&
		!matterTypeHelperText &&
		(!matterTypes || !matterTypes.length || !!matterTypesError)
	) {
		setMatterTypeHelperText('No accessible Matter Management Site found')
	}

	const moveForwardToSelectMatter = () => {
		if (searchUsingMatterType && !selectedMatterType) {
			setMatterTypeHelperText('Please select a Matter Management Site')
			return
		}
		const searchTerm = trim(searchString)
		if (searchTerm.length < 4) {
			setSearchStringHelperText('Please enter more than 3 characters to search')
			return
		}

		searchExternalMatters({
			variables: {
				rfpId,
				searchTerm,
				matterType: selectedMatterType,
			},
		})
	}

	const moveBackToSearchMatter = () => {
		setSelectedMatter(null)
		setIsLoadingExternalMatter(false)
		setWizardStep(SyncTrackingFieldsWizardSteps.SEARCH_EXTERNAL_MATTER)
	}

	const moveForwardToConfirmMatter = () => {
		setSyncTrackingFieldsError('')
		if (selectedMatter) {
			setWizardStep(SyncTrackingFieldsWizardSteps.CONFIRM_EXTERNAL_MATTER)
		}
	}

	const moveBackToSelectMatter = () => {
		setSyncTrackingFieldsError('')
		setWizardStep(SyncTrackingFieldsWizardSteps.SELECT_EXTERNAL_MATTER)
	}

	const updateSelectedMatterType: FnHandleOnSelectEvent = (event) => {
		setSelectedMatterType(event.target.value)
		setIsLoadingExternalMatter(false)
		setMatterTypeHelperText('')
		setSearchStringHelperText('')
	}

	const updateSearchString: FnHandleOnChangeEvent = (event) => {
		setSearchString(event.target.value)
		setIsLoadingExternalMatter(false)
		setSearchStringHelperText('')
	}

	return (
		<DialogDeprecated open={true} size="md">
			<DialogTitle>{syncWizardTitle}</DialogTitle>
			<DialogClose onClick={handleClose} />
			{wizardStep === SyncTrackingFieldsWizardSteps.SEARCH_EXTERNAL_MATTER && (
				<SyncTrackingFieldsWizardContent
					activeStep={wizardStep}
					onCancel={handleClose}
					onSearch={moveForwardToSelectMatter}
					isWorking={isLoadingExternalMatter}
				>
					<SearchMatterPanel
						searchString={searchString}
						selectedMatterType={selectedMatterType}
						matterTypes={matterTypes.filter(isNotNil)}
						updateSelectedMatterType={updateSelectedMatterType}
						updateMatterRefNumber={updateSearchString}
						searchStringHelperText={searchStringHelperText}
						isLoadingExternalMatter={isLoadingExternalMatter}
						matterTypeHelperText={matterTypeHelperText}
						searchUsingMatterType={searchUsingMatterType}
					/>
				</SyncTrackingFieldsWizardContent>
			)}

			{!!searchedMatters && wizardStep === SyncTrackingFieldsWizardSteps.SELECT_EXTERNAL_MATTER && (
				<SyncTrackingFieldsWizardContent
					activeStep={wizardStep}
					onCancel={handleClose}
					onPrev={moveBackToSearchMatter}
					onNext={moveForwardToConfirmMatter}
					isWorking={!selectedMatter}
				>
					<SelectMatterPanel
						searchedMatters={searchedMatters}
						selectedMatter={selectedMatter}
						setSelectedMatter={setSelectedMatter}
					/>
					<Spacer space={2} />
				</SyncTrackingFieldsWizardContent>
			)}

			{!!selectedMatter && wizardStep === SyncTrackingFieldsWizardSteps.CONFIRM_EXTERNAL_MATTER && (
				<SyncTrackingFieldsWizardContent
					activeStep={wizardStep}
					onCancel={handleClose}
					onPrev={moveBackToSelectMatter}
					onConfirm={async () => {
						try {
							await handleSync({
								externalId: selectedMatter.externalId,
								matterType: selectedMatterType,
							})
							handleClose()
						} catch (e) {
							setSyncTrackingFieldsError(toString(e))
						}
					}}
				>
					<ConfirmMatterPanel
						selectedMatter={selectedMatter}
						syncErrorText={syncTrackingFieldsError ?? ''}
						updateMatterReference={updateMatterReference}
					/>
				</SyncTrackingFieldsWizardContent>
			)}
		</DialogDeprecated>
	)
}
