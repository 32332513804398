// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { Box, useTheme } from '@persuit/ui-components'
import { PricingGroup, Deliverable } from '../../../../../types'
import { DeliverableContainer } from '../deliverable-container'
import { GroupContainer } from '../group-container'

type DeliverablesProps = {
	deliverables: (Deliverable | PricingGroup)[]
}

export const Deliverables = ({ deliverables }: DeliverablesProps) => {
	const theme = useTheme()

	if (isEmpty(deliverables)) return null

	const { deliverableFragments } = deliverables.reduce<{
		deliverableFragments: Array<JSX.Element>
		deliverableIndex: number
	}>(
		(acc, deliverable) => {
			if (isDeliverable(deliverable)) {
				acc.deliverableFragments.push(
					<DeliverableContainer
						key={`D${acc.deliverableIndex}`}
						floating={true}
						deliverable={deliverable}
						index={acc.deliverableIndex}
					/>,
				)
				acc.deliverableIndex += 1
			} else if (isPricingGroup(deliverable)) {
				acc.deliverableFragments.push(
					<GroupContainer
						key={`G${acc.deliverableIndex}`}
						group={deliverable}
						index={acc.deliverableIndex}
					/>,
				)
				acc.deliverableIndex += deliverable.deliverables.length
			}
			return acc
		},
		{
			deliverableFragments: [],
			deliverableIndex: 0,
		},
	)

	return (
		<Box display="flex" flexDirection="column" gap={theme.spacing(2)} paddingTop={2}>
			{deliverableFragments}
		</Box>
	)
}

function isPricingGroup(item): item is PricingGroup {
	return typeof (item as PricingGroup)?.deliverables !== 'undefined'
}

function isDeliverable(item): item is Deliverable {
	return typeof (item as PricingGroup)?.deliverables === 'undefined'
}
