import { TableCell, FormControlLabel, Radio } from '@persuit/ui-components'

type Rating = 'excellent' | 'good' | 'satisfactory' | 'needsImprovement' | 'notApplicable'

type RatingsRadioGroupProps = {
	name: string
	label: string
	value: Rating
	onChange: (value: Rating) => void
}

export const RatingsRadioGroup = ({ onChange, value, name, label }: RatingsRadioGroupProps) => {
	const ratingsRadio = (rating: Rating, label: string) => (
		<TableCell>
			<FormControlLabel
				label=""
				aria-label={label}
				control={<Radio name={name} checked={value === rating} onClick={() => onChange(rating)} />}
			/>
		</TableCell>
	)

	return (
		<>
			{ratingsRadio('excellent', `${label} Rating - Excellent`)}
			{ratingsRadio('good', `${label} Rating - Good`)}
			{ratingsRadio('satisfactory', `${label} Rating - Satisfactory`)}
			{ratingsRadio('needsImprovement', `${label} Rating - Needs Improvement`)}
			{ratingsRadio('notApplicable', `${label} Rating - Not Applicable`)}
		</>
	)
}
