import { isNotNilProp } from '@persuit/common-utils'
import { RateCard } from './types'

/**
 * Calculate the weighted average column for a firm
 */
export function calculateFirmWeightedAverage(rateCards: RateCard[]): number | null {
	const timekeepers = rateCards
		.filter((card) => card.isCurrentRateApplicable && card.isLastYearHoursApplicable)
		.flatMap((card) => card.timekeepers)
		.filter((timekeeper) => timekeeper.isEnabled)
		.filter(isNotNilProp('currentRate'))
		// cannot calculate % changes when currentRate = 0
		.filter((tk) => tk.currentRate > 0)
		.filter(isNotNilProp('proposedRate'))
		.filter(isNotNilProp('lyHour'))

	const { totalContributions, totalHours } = timekeepers.reduce(
		({ totalHours, totalContributions }, tk) => {
			const change = tk.proposedRate - tk.currentRate
			const delta = change / tk.currentRate
			const contribution = delta * tk.lyHour
			return {
				totalHours: totalHours + tk.lyHour,
				totalContributions: totalContributions + contribution,
			}
		},
		{
			totalHours: 0,
			totalContributions: 0,
		},
	)

	if (totalHours === 0) return null

	return totalContributions / totalHours
}
