import { RichTextEditor, Box, Button, Alert, styled } from '@persuit/ui-components'
import { useState, useEffect } from 'react'
import { graphql, useMutation, useQuery } from '@persuit/ui-graphql'
import { GET_CHANNEL_DATA } from './channel-pane'

const StyledAlert = styled(Alert)({
	'& .MuiAlert-icon': {
		fontSize: 16,
	},
})

const GET_DRAFT_MESSAGE = graphql(`
	query MessagingChannelGetDraftMessage($channelId: ID!) {
		draftMessage(channelId: $channelId) {
			message
			id
			createdAt
			createdBy {
				userId
				name {
					first
					last
				}
			}
		}
	}
`)

const ADD_MESSAGE = graphql(`
	mutation MessagingChannelSendMessage($payload: MessageInput) {
		addMessage(input: $payload) {
			message
			id
			isBot
			messageAt
			createdAt
			botMessage {
				translationKey
				translationReplacements
			}
			createdBy {
				userId
			}
		}
	}
`)

const SAVE_DRAFT_MESSAGE = graphql(`
	mutation MessagingChannelSaveDraftMessage($payload: DraftMessageInput) {
		addDraftMessage(input: $payload) {
			success
		}
	}
`)

const DELETE_DRAFT_MESSAGE = graphql(`
	mutation MessagingChannelDeleteDraftMessage($payload: DeleteDraftMessageInput) {
		deleteDraftMessage(input: $payload) {
			success
		}
	}
`)

export type MessageFormProps = { channelId: string; isImpersonated: boolean; userId: string }

export const MessageForm = ({ channelId, isImpersonated, userId }: MessageFormProps) => {
	const [sendMessageMutation] = useMutation(ADD_MESSAGE, {
		update: (cache, { data: mutationData }) => {
			const data = cache.readQuery({ query: GET_CHANNEL_DATA, variables: { channelId } })
			if (data)
				cache.writeQuery({
					query: GET_CHANNEL_DATA,
					data: {
						...data,
						channel: {
							...data?.channel,
							messages: [...(data?.channel.messages ?? []), mutationData?.addMessage],
						},
					},
				})
		},
	})
	const [message, setMessage] = useState<string>('')

	const { data: draftMessageData } = useQuery(GET_DRAFT_MESSAGE, {
		variables: { channelId },
		fetchPolicy: 'no-cache',
	})

	const [draftSavedAlert, setDraftSavedAlert] = useState(false)

	useEffect(() => {
		if (draftMessageData?.draftMessage && draftMessageData.draftMessage.message) {
			setMessage(draftMessageData.draftMessage.message)
		}
	}, [draftMessageData])

	const [saveDraftMessageMutation] = useMutation(SAVE_DRAFT_MESSAGE)
	const [deleteDraftMessageMutation] = useMutation(DELETE_DRAFT_MESSAGE)

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (draftMessageData?.draftMessage?.message && !message) {
				deleteDraftMessageMutation({ variables: { payload: { channelId, userId } } })
			}
			if (message && !isImpersonated && message !== draftMessageData?.draftMessage?.message) {
				saveDraftMessageMutation({ variables: { payload: { channelId, message } } })
				setDraftSavedAlert(true)
			}
		}, 1000)

		return () => clearTimeout(delayDebounceFn)
	}, [message])

	useEffect(() => {
		if (draftSavedAlert) {
			const timeout = setTimeout(() => setDraftSavedAlert(false), 3000)
			return () => clearTimeout(timeout)
		}
	}, [draftSavedAlert])

	const sendMessage = () => {
		if (message && !isImpersonated)
			sendMessageMutation({
				variables: {
					payload: { channelId, message },
				},
				optimisticResponse: {
					addMessage: {
						id: '123',
						isBot: false,
						message,
						botMessage: {
							translationKey: '',
							translationReplacements: null,
						},
						messageAt: new Date().getTime().toString(),
						createdAt: new Date().getTime().toString(),
						createdBy: {
							userId,
						},
					},
				},
			})
		setMessage('')
	}

	return (
		<Box display="flex" flexDirection="column" gap={1} p={1} bgcolor="background.appMain">
			{isImpersonated ? (
				<Box p="2">
					<Alert severity="warning">Messages cannot be sent when impersonating</Alert>
				</Box>
			) : (
				<>
					<Box minHeight="136px" data-trackid="rich-text-message">
						<RichTextEditor
							id="messageBox"
							label="Message input"
							hideLabel={true}
							height={0}
							name="Message input"
							simplified={true}
							value={message}
							onChange={setMessage}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus={true}
						/>
					</Box>
					<Box display="flex" flexDirection="row-reverse" gap={1}>
						<Button
							variant="contained"
							onClick={sendMessage}
							disabled={!message}
							sx={{ flexShrink: 0 }}
						>
							Send
						</Button>
						{draftSavedAlert && (
							<StyledAlert
								severity="info"
								sx={{
									width: '100%',
									padding: 0,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								Draft saved
							</StyledAlert>
						)}
					</Box>
				</>
			)}
		</Box>
	)
}
