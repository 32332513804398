import { SvgIcon, SvgIconProps } from '../components/svg'

export const CrownIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 17 12" {...props}>
			<path
				fillRule="evenodd"
				d="M15.3621,12l1.038,-10.011l-4.231,2.988l-3.769,-4.977l-3.77,4.977l-4.23,-2.988l1.038,10.011h6.962h6.962z"
			></path>
		</SvgIcon>
	)
}
