import { useHandleUnexpectedError } from '@persuit/ui-hooks'
import { useEffect, useMemo, useState } from 'react'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'
import { graphql, useQuery } from '@persuit/ui-graphql'
import {
	InvitablePeopleHook_GetInvitablePeopleQuery,
	InvitablePeopleHook_GetOrgsQuery,
	InvitablePeopleSortOrder,
} from '@persuit/ui-graphql/generated'

type Person = NonNullable<
	NonNullable<InvitablePeopleHook_GetInvitablePeopleQuery['getInvitablePeople']['users']>[number]
>
type Org = NonNullable<NonNullable<InvitablePeopleHook_GetOrgsQuery['getOrgs']>[number]>

const GET_ORGS = graphql(`
	query InvitablePeopleHook_GetOrgs {
		getOrgs {
			_id
			name
			isNamwolfMember
		}
	}
`)

const GET_INVITABLE_PEOPLE = graphql(`
	query InvitablePeopleHook_GetInvitablePeople(
		$includeColleagues: Boolean
		$page: Int
		$pageSize: Int
		$search: String
		$sortOrder: InvitablePeopleSortOrder
	) {
		getInvitablePeople(
			includeColleagues: $includeColleagues
			page: $page
			pageSize: $pageSize
			search: $search
			sortOrder: $sortOrder
		) {
			users {
				_id
				name {
					first
					last
				}
				email
				isDisabled
				isFavourite
				orgId
				location {
					name
				}
				title
				professionalProfile {
					_id
					profile
					url
				}
			}
			totalCount
			totalPages
		}
	}
`)

export type UseInvitablePeopleInput = {
	includeColleagues?: boolean
	page?: number | null
	pageSize?: number | null
	search?: string
	sortOrder?: InvitablePeopleSortOrder
}

export const useInvitablePeople = ({
	includeColleagues = false,
	page = null,
	pageSize = null,
	search = '',
	sortOrder = 'ASC',
}: UseInvitablePeopleInput = {}) => {
	const handleUnexpectedError = useHandleUnexpectedError()
	const {
		data: peopleData,
		loading: isFetchingPeople,
		refetch: refetchPeople,
		error: peopleError,
	} = useQuery(GET_INVITABLE_PEOPLE, {
		variables: { includeColleagues, page, pageSize, search, sortOrder },
	})
	const {
		data: orgsData,
		loading: isFetchingOrgs,
		refetch: refetchOrgs,
		error: orgsError,
	} = useQuery(GET_ORGS)

	const invitablePeopleRaw = useMemo(
		() =>
			getEnrichedResponse(
				peopleData?.getInvitablePeople?.users?.filter(isNotNil) ?? [],
				orgsData?.getOrgs?.filter(isNotNil) ?? [],
			),
		[peopleData, orgsData],
	)

	const invitablePeople = useMemo(() => filterTypes(invitablePeopleRaw), [invitablePeopleRaw])

	const missingOrgIds = useMemo(
		() => (invitablePeopleRaw ?? []).filter((p) => !p.org).map(({ orgId }) => orgId),
		[invitablePeopleRaw],
	)

	const needOrgRefetch = missingOrgIds.length > 0
	const [refetchingCompleteFor, setRefetchingCompleteFor] =
		useState<InvitablePeopleHook_GetInvitablePeopleQuery>()
	const refetchingOrgsComplete = refetchingCompleteFor === peopleData

	// This is a temporary fix. All users should have an org. The enrichment logic
	// should also be moved to the backend eventually.
	// Check that we have successfully enriched all the user records. If not, we
	// need to refetch the getOrgs query to fetch the missing ones
	useEffect(() => {
		if (needOrgRefetch && !isFetchingOrgs && !refetchingOrgsComplete) {
			refetchOrgs().then(refetchComplete, refetchComplete).catch(handleUnexpectedError)
		}

		function refetchComplete() {
			setRefetchingCompleteFor(peopleData)
		}
	}, [
		handleUnexpectedError,
		peopleData,
		refetchingOrgsComplete,
		isFetchingOrgs,
		refetchOrgs,
		needOrgRefetch,
	])

	return {
		data: {
			getInvitablePeople: {
				users: invitablePeople,
				totalPages: peopleData?.getInvitablePeople?.totalPages,
				totalCount: peopleData?.getInvitablePeople?.totalCount,
			},
		},
		loading: (needOrgRefetch && !refetchingOrgsComplete) || isFetchingPeople || isFetchingOrgs,
		error: peopleError ?? orgsError,
		refetch: (variables?: Parameters<typeof refetchPeople>[0]) =>
			Promise.all([refetchOrgs(), refetchPeople(variables)]),
	}
}

/** Attaches orgs to users */
function getEnrichedResponse(people: Person[], orgs: Org[]) {
	return people.map((person) => ({
		...person,
		org: orgs.find((org) => org?._id && person?.orgId && org._id === person.orgId),
	}))
}

type EnrichedPersonRaw = ReturnType<typeof getEnrichedResponse>[number]

/** Filters to modify the typescript types since the backend schema contains a
 * lot of nullables still */
function filterTypes(people: EnrichedPersonRaw[]) {
	return people
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))
		.filter(isNotNilProp('org'))
		.map((p) => {
			const orgId = p.org._id
			const orgName = p.org.name
			if (!orgId || !orgName) return null
			return {
				...p,
				name: p.name ?? { first: '', last: '' },
				org: { ...p.org, _id: orgId, name: orgName, isNamwolfMember: !!p.org.isNamwolfMember },
			}
		})
		.filter(isNotNil)
}

export type InvitablePerson = ReturnType<typeof filterTypes>[number]
