import numeral from 'numeral'
import currencies, { codes as currencyCodes } from '../../../../../common/data/currencies'
/**
 * Formats a number as a currency. Supports both Variance and Financial
 * @param {String} - currencyCode AUD, USD, PLN
 * @param {String} - price
 * @param {Bool} - showCurrencyCode default to true. Used in the Comparator cell to provide more room
 */

export default (rawPrice, currencyCode, showCurrencyCode = true) => {
	// when value is 0 or null show "—" Em dash
	if (!rawPrice) {
		return '—'
	}

	// remove negative
	const price = Math.abs(rawPrice)

	if (!currencyCodes.includes(currencyCode)) {
		throw new Error(`${currencyCode} not a valid currency`)
	}
	const currencySymbol = currencies[currencyCode].symbol
	// If no price set, make price 0 for display purposes
	// commonly seen on firm draft proposal preview/comparator
	let formattedPrice
	if (price === null || price === undefined || price === '') {
		formattedPrice = 0
	} else {
		formattedPrice = Number.isInteger(parseInt(price, 10))
			? numeral(price).format('0,0.[00]')
			: price
	}

	// Add currency code (AUD,USD) when currencySymnol is "$"
	// Also option to not show currencyCode at all
	if (currencySymbol === '$' && showCurrencyCode) {
		return `${currencySymbol}${formattedPrice} ${currencyCode}`
	}
	return `${currencySymbol}${formattedPrice}`
}
