import { Fragment } from 'react'

import persuitTheme from '../theme/persuit-theme'
import injectSheet from '../injectSheet'

const ThinSeparator = ({ classes }) => (
	<Fragment>
		<div className={classes.container} aria-hidden="true"></div>
	</Fragment>
)
ThinSeparator.styles = {
	container: {
		borderBottom: `1px solid ${persuitTheme.borderColor.subtle}`,
	},
}

export default injectSheet(ThinSeparator.styles)(ThinSeparator)
