import moment from 'moment'

import { Fragment, useId } from 'react'
import {
	getConflictStep,
	getFinalStep,
	getInvitedStep,
	getParticipateStep,
	getViewedStep,
} from './helper'
import { Typography, Step, StepLabel, Stepper } from '@persuit/ui-components'

const InvitedFirmProgressStepper = ({
	rfp,
	proposals,
	invite,
	getInvitedStep,
	getViewedStep,
	getConflictStep,
	getParticipateStep,
	getFinalStep,
}) => {
	const invitedStep = getInvitedStep(rfp, invite)
	const viewedStep = getViewedStep(invite)
	const conflictStep = getConflictStep(rfp, invite)
	const participateStep = getParticipateStep(invite)
	const finalStep = getFinalStep(proposals, invite)

	const steps = [invitedStep, viewedStep, conflictStep, participateStep, finalStep].filter(Boolean)
	const id = useId()

	return (
		<Fragment>
			<Stepper
				alternativeLabel={true}
				nonLinear={true}
				tabIndex={-1}
				role="list"
				aria-label="Firm Progress"
			>
				{steps.map(({ label, labelProps, date }, idx) => (
					<Step
						key={label}
						completed={date || false}
						aria-labelledby={`step-label-${idx}-${id}`}
						role="listitem"
					>
						<StepLabel {...labelProps} id={`step-label-${idx}-${id}`}>
							{label}

							<Typography color="text.secondary" variant="body2" display="block">
								{date && typeof date === 'number'
									? moment(date).format('D MMMM YYYY')
									: 'Not completed'}
							</Typography>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Fragment>
	)
}

InvitedFirmProgressStepper.defaultProps = {
	getInvitedStep,
	getViewedStep,
	getConflictStep,
	getParticipateStep,
	getFinalStep,
}

export default InvitedFirmProgressStepper
