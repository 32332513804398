import {
	CheckIcon,
	ListItemText,
	MenuItem,
	MenuItemProps,
	Typography,
} from '@persuit/ui-components'
import { SortDirection, SortType, useActions, useSortStore } from './sortStore'
import { SORT_LABELS } from './constants'
import { ColumnField, TypedGridColDef } from './named-timekeepers-columns'

const AToZColumnMenu = ({ field }: { field: ColumnField }) => {
	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<CustomMenuItem field={field} direction="asc" aria-label={SORT_LABELS.aToZSortColumnMenu.asc}>
				A - Z
			</CustomMenuItem>
			<CustomMenuItem
				field={field}
				direction="desc"
				aria-label={SORT_LABELS.aToZSortColumnMenu.desc}
			>
				Z - A
			</CustomMenuItem>
		</>
	)
}

const LowestToHighestColumnMenu = ({ field }: { field: ColumnField }) => {
	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<CustomMenuItem
				field={field}
				direction="asc"
				aria-label={SORT_LABELS.lowestToHighestColumnMenu.asc}
			>
				Lowest to highest
			</CustomMenuItem>
			<CustomMenuItem
				field={field}
				direction="desc"
				aria-label={SORT_LABELS.lowestToHighestColumnMenu.desc}
			>
				Highest to lowest
			</CustomMenuItem>
		</>
	)
}

const EarliestToLatestColumnMenu = ({ field }: { field: ColumnField }) => {
	return (
		<>
			<Typography variant="body1Semibold" sx={{ py: 0.75, px: 2 }} aria-hidden={true}>
				Sort by
			</Typography>

			<CustomMenuItem
				field={field}
				direction="asc"
				aria-label={SORT_LABELS.earliestToLatestColumnMenu.asc}
			>
				Earliest to latest
			</CustomMenuItem>
			<CustomMenuItem
				field={field}
				direction="desc"
				aria-label={SORT_LABELS.earliestToLatestColumnMenu.desc}
			>
				Latest to earliest
			</CustomMenuItem>
		</>
	)
}

type CustomMenuItemProps = MenuItemProps & {
	field: TypedGridColDef['field']
	direction: SortDirection
	type?: SortType
}

const CustomMenuItem = ({
	'aria-label': ariaLabel = '',
	direction,
	type = null,
	field,
	children,
	...rest
}: CustomMenuItemProps) => {
	const { toggleSortState } = useActions()

	const selected = useSortStore(
		({ sortState }) =>
			field === sortState?.field &&
			direction === sortState?.direction &&
			(!type || type === sortState?.type),
	)

	return (
		<MenuItem
			{...rest}
			selected={selected}
			aria-label={`${ariaLabel}, ${selected ? 'selected' : 'unselected'}`}
			onClick={() => toggleSortState({ field, direction, type })}
		>
			<ListItemText>{children}</ListItemText>
			{selected ? <CheckIcon /> : null}
		</MenuItem>
	)
}

export const CustomColumnMenu = ({ field }: { field: ColumnField }) => {
	if (['timekeeperLevel', 'firstName', 'lastName', 'location'].includes(field)) {
		return <AToZColumnMenu field={field} />
	}

	if (
		[
			'lastYearsHours',
			'currentRate',
			'proposedRate',
			'newStandardRate',
			'discountFromStandard',
			'changeFromLastYear',
			'spendImpact',
		].includes(field)
	) {
		return <LowestToHighestColumnMenu field={field} />
	}

	if (['graduationYear'].includes(field)) {
		return <EarliestToLatestColumnMenu field={field} />
	}

	return null
}
