// @ts-strict-ignore
import { useId } from 'react'
import { CustomTrackingFieldValues } from '../types.js'
import { Box, styled, Typography } from '@persuit/ui-components'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'

type CheckboxHierarchySelectedValuesProps = {
	id: string
	label: string
	onClick: () => void
	values: CustomTrackingFieldValues[]
	selectedValuesForItem: (string | null)[]
	error?: boolean
	helperTextId?: string
	required?: boolean
}

const StyledContainer = styled(Box)<{ error?: string }>`
	border: 1px solid
		${({ error, theme }) => (error ? theme.palette.error.main : theme.palette.form.borderResting)};
	cursor: pointer;
	padding: 0.5rem;
	&:hover {
		border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
	}
`

export const CheckboxHierarchySelectedValues = ({
	id,
	label,
	onClick,
	values,
	selectedValuesForItem,
	error,
	helperTextId,
	required,
}: CheckboxHierarchySelectedValuesProps) => {
	const isValueSelected = (_id) => {
		return selectedValuesForItem.includes(_id)
	}

	const selectedValues = values
		// Only display items that have an _id otherwise the
		// checkbox checks don't work properly
		.filter(isNotNil)
		.filter(isNotNilProp('_id'))
		.reduce<{ value: React.ReactNode }[]>((acc, { _id, value, values: subItems = [] }) => {
			if (isValueSelected(_id)) {
				acc.push({
					value,
				})

				return acc
			}

			const selectedChildren = subItems?.filter((subItem) => isValueSelected(subItem?._id))

			// Check child values
			if (selectedChildren?.length) {
				acc.push({
					value: (
						<div>
							{value}
							{': '}
							{selectedChildren.map((selectedChild) => selectedChild?.value).join(', ')}.
						</div>
					),
				})
			}

			return acc
		}, [])

	const selectedValuesId = useId()

	return (
		<StyledContainer
			tabIndex={0}
			role="button"
			onClick={onClick}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					onClick()
				}
			}}
			data-testid="checkbox-hierarchy-values"
			aria-label={`Edit ${label} tracking options${required ? ', required' : ''}${
				error ? ', invalid data' : ''
			}`}
			error={error ? 'true' : undefined}
			aria-describedby={`${selectedValuesId}${helperTextId ? ` ${helperTextId}` : ''}`}
		>
			{selectedValues.length === 0 && (
				<Typography variant="subtitle1" style={{ marginTop: '0rem' }} component="span">
					Click here to edit
				</Typography>
			)}

			{selectedValues.length > 0 && (
				<>
					<ul
						style={{
							margin: 0,
						}}
					>
						{selectedValues.map((selectedValue, index) => {
							return <li key={`${id}_${index}`}>{selectedValue.value}</li>
						})}
					</ul>
					<Typography id={selectedValuesId} display="none">
						Selected: {selectedValues.map((selectedValue) => selectedValue.value)}
					</Typography>
				</>
			)}
			<textarea hidden={true} id={id} />
		</StyledContainer>
	)
}
