import * as React from 'react'
import { graphql } from '@persuit/ui-graphql'
import { useQuery } from '@apollo/client'

export const GET_SESSION_QUERY = graphql(`
	query FeatureToggleGetSessionInfo {
		getSessionInfo {
			featureMap
		}
	}
`)

type FeatureToggleProps = {
	name: string
	whenOn: React.ReactNode | null
	whenOff: React.ReactNode | null
}

const FeatureToggle = ({ name, whenOn, whenOff }: FeatureToggleProps) => {
	const { data, loading, error } = useQuery(GET_SESSION_QUERY, { fetchPolicy: 'cache-first' })

	if (loading) {
		return null
	}

	if (error) {
		console.error('FeatureToggle error = ', error)
		return null
	}

	const isFeatureToggleOn = data?.getSessionInfo?.featureMap?.[name]

	const Component = isFeatureToggleOn ? whenOn : whenOff

	return <>{Component}</>
}

FeatureToggle.defaultProps = {
	name: '',
	whenOff: null,
	whenOn: null,
}

export { FeatureToggle }
