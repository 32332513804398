import moment from 'moment'

// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Box from '@mui/material/Box'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Paper from '@mui/material/Paper'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Typography from '@mui/material/Typography'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Table from '@mui/material/Table'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableBody from '@mui/material/TableBody'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import MuiTableCell from '@mui/material/TableCell'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableContainer from '@mui/material/TableContainer'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import TableRow from '@mui/material/TableRow'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import withStyles from '@mui/styles/withStyles'
import { PercentageText } from '@persuit/ui-components'

// Remove bottom border from table
const TableCell = withStyles({
	root: {
		borderBottom: 'none',
	},
})(MuiTableCell)

export const PercentageChartTooltip = ({ payload = [], label = '' }) => {
	if (!payload || payload.length === 0) {
		return null
	}

	return (
		<Paper elevation={3}>
			<Box p="1em" pb="0">
				<Typography gutterBottom={true} variant="subtitle1">
					{moment(label).format('h:mmA (DD MMM)')}
				</Typography>
			</Box>

			<Box p="1em" pt="0" pl="0">
				<TableContainer>
					<Table size="small">
						<TableBody>
							{payload.map((item, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											<Typography
												style={{
													color: item.stroke,
												}}
												variant="subtitle1"
											>
												{item.dataKey}
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												style={{
													color: item.stroke,
												}}
												variant="subtitle1"
											>
												<PercentageText value={item.value} />
											</Typography>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	)
}
