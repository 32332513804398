import * as React from 'react'
import { DialogContent } from '@persuit/ui-components'

import { SyncTrackingFieldsWizardStepper } from './sync-tracking-fields-wizard-stepper'
import { SyncTrackingFieldsWizardActions } from './sync-tracking-fields-wizard-actions'
import { SyncTrackingFieldsWizardSteps } from './sync-tracking-fields-wizard-steps'
import { FnHandleWizardNavigation } from './sync-tracking-fields-wizard.types'

type SyncTrackingFieldsWizardContentProps = {
	activeStep: SyncTrackingFieldsWizardSteps
	children: React.ReactNode
	isWorking?: boolean
	onPrev?: FnHandleWizardNavigation
	onNext?: FnHandleWizardNavigation
	onSearch?: FnHandleWizardNavigation
	onConfirm?: FnHandleWizardNavigation
	onCancel: FnHandleWizardNavigation
}
export const SyncTrackingFieldsWizardContent = ({
	activeStep,
	children,
	isWorking = false,
	onPrev,
	onNext,
	onSearch,
	onConfirm,
	onCancel,
}: SyncTrackingFieldsWizardContentProps) => {
	return (
		<React.Fragment>
			<DialogContent>
				<SyncTrackingFieldsWizardStepper activeStep={activeStep} />
				{children}
			</DialogContent>
			<SyncTrackingFieldsWizardActions
				onNext={onNext}
				onPrev={onPrev}
				onSearch={onSearch}
				onConfirm={onConfirm}
				onCancel={onCancel}
				isWorking={isWorking}
			/>
		</React.Fragment>
	)
}
