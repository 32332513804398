export const isPriceModelAllowed = (pricingPreference: string) => {
	return (
		pricingPreference !== 'hourlyRates' &&
		pricingPreference !== 'hourlyRate' &&
		pricingPreference !== 'none' &&
		pricingPreference !== 'included' &&
		pricingPreference !== 'notApplicable' &&
		pricingPreference !== 'other'
	)
}

export const isAuctionable = (rfpStatus: string, rfpUseCase: string) => {
	if (rfpUseCase === 'rateReview') {
		return false
	}

	return rfpStatus === 'finalized' || rfpStatus === 'closed'
}
