import {
	Paper,
	Box,
	Typography,
	Button,
	ArrowBackIcon,
	LockIcon,
	PageTitle,
} from '@persuit/ui-components'
import { useHistory } from 'react-router-dom'

type UnauthorizedProps = {
	redirectPath?: string
	buttonLabel?: string
}

export const Unauthorized = ({ redirectPath, buttonLabel }: UnauthorizedProps) => {
	const history = useHistory()

	const handleRedirect = () => {
		history.push(redirectPath ? redirectPath : '/en/')
	}

	return (
		<Box padding={3} maxWidth="500px" margin="0px auto">
			<Paper>
				<PageTitle>Unauthorized</PageTitle>
				<Box padding={3}>
					<Box data-testid="unauthorized" display="flex" flexDirection="column" gap={2}>
						<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
							<LockIcon />
							<Typography variant="h1XSmall">Unauthorized</Typography>
						</Box>

						<Typography variant="body1" color="text.secondary">
							You do not have access to view this page.
						</Typography>
						<Box>
							<Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleRedirect}>
								{buttonLabel ? buttonLabel : 'Go to dashboard'}
							</Button>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Box>
	)
}
