import { Fragment } from 'react'
import { gql, useQuery } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import DescriptionIcon from '@mui/icons-material/Description'
import isEmpty from 'lodash/isEmpty'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Divider from '@mui/material/Divider'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardListOption from '../../dialog-wizard-list-option'
import { stepIndex } from '../../utils'

const GET_LOE_TEMPLATES_QUERY = gql`
	query getLoeTemplates {
		getLoeTemplates {
			_id
			loeTemplateTitle
			loeTemplateDescription
			isPublished
		}
	}
`

export function OptionGenerateLoeOptionsContent({
	error,
	loading,
	data,
	onSelectOption,
	onSelectDefault,
}) {
	if (error) return <DialogWizardConfirmationError error={error} />

	const titleLine = (
		<Fragment>
			<strong>Select Template</strong>
			<Divider />
		</Fragment>
	)

	if (loading) return titleLine

	const loeTemplates = data.getLoeTemplates

	const defaultListItem = (
		<DialogWizardListOption
			dataTestid="default-template-option"
			key={'DEFAULT'}
			primaryText="Standard Engagement Letter Template by PERSUIT"
			bullets={[
				'This standard template includes an initial draft with details from the matter and proposal.',
			]}
			icon={<DescriptionIcon />}
			onClick={onSelectDefault()}
		/>
	)

	if (isEmpty(loeTemplates))
		return (
			<Fragment>
				{titleLine}
				{defaultListItem}
			</Fragment>
		)

	return (
		<Fragment>
			{titleLine}
			{defaultListItem}
			{loeTemplates.map((template) => {
				if (!template.isPublished) return null
				return (
					<DialogWizardListOption
						dataTestid="custom-template-option"
						key={template._id}
						primaryText={template.loeTemplateTitle}
						bullets={[template.loeTemplateDescription]}
						icon={<DescriptionIcon />}
						onClick={onSelectOption(template._id)}
					/>
				)
			})}
		</Fragment>
	)
}

function OptionGenerateLoeOptions({ onChange, onPrev, onNext, onNextDefault, onCancel }) {
	const { data, loading, error } = useQuery(GET_LOE_TEMPLATES_QUERY, {
		fetchPolicy: 'network-only',
	})

	const onSelectOption = (templateId) => () => {
		onChange({ templateId })
		onNext()
	}

	const onSelectDefault = () => () => {
		onNextDefault()
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'ACTIONS'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Generate Engagement Letter',
			}}
			onPrev={onPrev}
			onCancel={onCancel}
		>
			<OptionGenerateLoeOptionsContent
				error={error}
				loading={loading}
				data={data}
				onSelectOption={onSelectOption}
				onSelectDefault={onSelectDefault}
			/>
		</DialogWizardContent>
	)
}

export default OptionGenerateLoeOptions
