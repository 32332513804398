import isEmpty from 'lodash/fp/isEmpty'
import { DIVERSITY_DEFINITION_MAP } from '../../constants'
import { DiversityDefinitionType } from '../../../../../types'

const DEFINITIONS_EMPTY_PLACEHOLDER = 'Definition of Diverse is not set in organisation settings'

export const constructDiversityDefinition = (
	definitions: Array<DiversityDefinitionType>,
	hidePlaceholder = false,
): string => {
	const definitionStrings = definitions.map((definition) => DIVERSITY_DEFINITION_MAP[definition])
	const definitionString = definitionStrings.join(', ')

	if (isEmpty(definitionString)) {
		if (hidePlaceholder) return ''
		return DEFINITIONS_EMPTY_PLACEHOLDER
	}

	return `Diversity criteria: ${definitionString}.`
}
