import { useMutation } from '@apollo/client'
import { styled, useSnackbar } from '@persuit/ui-components'
import Spacer from '../../../layout/spacer.jsx'
import Text from '../../../text.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'
import { stepIndex } from '../../utils.js'
import { CLOSE_RFP_FOR_FIRM } from '../gql-mutations'
import { ga } from '@persuit/ui-analytics'

const StyledList = styled('ul')`
	list-style-type: disc;
	padding-left: 2em;
	& > li {
		padding-left: 0.5em;
	}
`

export default ({ onCancel, onDone, onPrev, firmOrgId, firmName, requestId }) => {
	const [closeRfpForFirm, { loading, error }] = useMutation(CLOSE_RFP_FOR_FIRM)
	const { openSnackbar } = useSnackbar()
	const handleConfirm = async () => {
		// Fire GA event
		ga({
			label: 'FEATURE_USAGE',
			page: 'MANAGE_FIRM',
			event: 'CLOSE_REQUEST_INDIVIDUALLY',
		})
		// Fire mutation
		await closeRfpForFirm({ variables: { _id: requestId, firmOrgId } })
		openSnackbar(`Request closed for ${firmName}`)
		onDone()
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Close Request for this Firm',
			}}
			isWorking={loading}
			onConfirm={handleConfirm}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={error} />
			<strong>Are you sure you want to close request for this firm?</strong>
			<StyledList>
				<li>
					<Text component="p" type="body1" black={true}>
						Stop the firm from revising their proposal or submitting new proposals.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						Other firms will not be notified.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						An email will be sent to the firm.
					</Text>
				</li>
				<li>
					<Text component="p" type="body1" black={true}>
						You can reopen the Request for revisions at any time.
					</Text>
				</li>
			</StyledList>
			<Spacer />
		</DialogWizardContent>
	)
}
