import { Autocomplete, TextField } from '@persuit/ui-components'

const SelectChartData = ({ options, value, onChange }) => {
	return (
		<Autocomplete
			onChange={(e, newValue) => {
				onChange(newValue)
			}}
			options={options}
			value={value}
			renderInput={(params) => <TextField {...params} label="Chart selection" variant="outlined" />}
			getOptionLabel={(option) => option.title}
			groupBy={(option) => option.group}
			disableClearable={true}
			blurOnSelect={true}
		/>
	)
}

export default SelectChartData
