import { ArrowBackIcon, IconButton } from '@persuit/ui-components'
import { useContext } from 'react'
import { RoutesContext } from '../../../routes-context'

type BackButtonProps = {
	templateId: string
}

export const BackButton = ({ templateId }: BackButtonProps) => {
	const routes = useContext(RoutesContext)
	const href = routes.back.uriFn(templateId)
	const title = routes.back.label
	return (
		<IconButton
			href={href}
			color="primary"
			title={title}
			id="back"
			disabled={!templateId}
			size="large"
		>
			<ArrowBackIcon />
		</IconButton>
	)
}
