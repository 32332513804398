import { Fragment, memo } from 'react'
import { useStore } from '../../store'
import { grey700 } from '../../../../theme/persuit-colors'
import Text from '../../../text.jsx'
import Spacer from '../../../layout/spacer.jsx'
import { ExpandableCell, ExpandableCellProps } from '../components'
import { RichTextView, AttachmentIcon } from '@persuit/ui-components'

type SummaryCell = Omit<ContentProps, 'summaryView'> &
	Pick<ExpandableCellProps, 'headers' | 'showRightBorder'> & {
		responseId: string
	}

export const SummaryCell = ({ responseId, ...rest }: SummaryCell) => {
	return (
		<ExpandableCell
			responseId={responseId}
			expandedTitle="Proposal Summary"
			expandedContent={<ContentMemoized summaryView={false} responseId={responseId} {...rest} />}
			{...rest}
		>
			<ContentMemoized summaryView={true} responseId={responseId} {...rest} />
		</ExpandableCell>
	)
}

type ContentProps = {
	responseId: string
	summaryView: boolean
	summaryOfResponse?: string | null
	filesCount: number | null
}

const Content = ({ summaryView, summaryOfResponse, filesCount, responseId }: ContentProps) => {
	const { isWide, truncateCellContents } = useStore((state, s) => ({
		isWide: s.isWide(responseId),
		truncateCellContents: state.truncateCellContents,
	}))

	const truncationLength = isWide ? 200 : 100
	const truncationValue = summaryView && truncateCellContents ? truncationLength : undefined

	return (
		<div>
			{summaryOfResponse ? (
				<RichTextView content={summaryOfResponse} truncationLength={truncationValue} />
			) : null}
			{!!filesCount && filesCount > 0 && (
				<Fragment>
					<Spacer space={1} />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<AttachmentIcon
							sx={{
								fill: `${grey700} !important`,
								transform: 'rotate(-90deg)',
							}}
						/>
						<Text type="body1">{`${filesCount} ${
							filesCount === 1 ? 'Attachment' : 'Attachments'
						}`}</Text>
					</div>
				</Fragment>
			)}
		</div>
	)
}

const ContentMemoized = memo(Content)

export default SummaryCell
