// eslint-disable-next-line no-restricted-imports -- Please upgrade
import EvaluatingIcon from '@mui/icons-material/LocalLibrary'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'Stop the firm from revising proposals or submitting new proposals.',
	'Other firms will not be notified.',
]

function OptionCloseRequestStart({ onNext, isRfpReopenedForFirm }) {
	return (
		<DialogWizardListOption
			dataTestid="close-request-for-firm-option"
			icon={<EvaluatingIcon />}
			primaryText="Close request for this firm."
			bullets={bullets}
			onClick={onNext}
			disabled={!isRfpReopenedForFirm}
		/>
	)
}

export default OptionCloseRequestStart
