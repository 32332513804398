import * as React from 'react'

import {
	Checkbox,
	useTheme,
	Box,
	Spacer,
	ListItem,
	ListItemIcon,
	StarIcon,
	StarBorderIcon,
	styled,
	Typography,
	IconButton,
	useSnackbar,
	CheckIcon,
} from '@persuit/ui-components'
import { useInviteContext } from '../invite-context'
import NamwolfLogo from '../../../../components/namwolf/namwolf-logo'
import { Person } from '../types'
import { ViewInfoButton } from '../view-info-button'
import { hasId } from '../utils'

const ListItemWithWiderSecondaryAction = styled(ListItem)(() => ({
	display: 'grid',
	gridTemplateColumns: '96px 1fr 120px',
	alignItems: 'flex-start',
	minHeight: '48px',
	'& .PrivateSwitchBase-root': { padding: '0px' },
	'& .MuiListItemSecondaryAction-root': {
		top: '8px',
		transform: 'none',
	},
}))

export type SearchResult = Person & {
	nameHighlight: React.ReactNode
	orgNameHighlight: React.ReactNode
	titleHighlight: React.ReactNode
	locationHighlight: React.ReactNode
}

type PeopleSearchListItemProps = {
	person: SearchResult
}

export const PeopleSearchListItem = ({ person }: PeopleSearchListItemProps) => {
	const theme = useTheme()
	const { usersToAdd, onAdd, onRemove, isDraftRfp, addToFavourites, selectedUsers } =
		useInviteContext()
	const { openSnackbar } = useSnackbar()

	const previouslySaved = !!selectedUsers.find((added) => added._id === person._id)

	const userToAdd = usersToAdd.some((userObj) => hasId(userObj) && userObj._id === person._id)

	const addUser = async () => {
		await onAdd([person])
		openSnackbar('A contact has been added to the Invitation List')
	}

	const removeUser = async () => {
		await onRemove([person._id])
		openSnackbar('A contact has been removed from the Invitation List')
	}

	const text = (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: '2fr 2fr 1fr',
				gridColumnGap: 1,
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant="body2Semibold" fontWeight="medium">
					{person.nameHighlight}
				</Typography>
				<Typography variant="body2">{person.titleHighlight}</Typography>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<Typography variant="body2">
					{person.orgNameHighlight}
					<Spacer shape="column" space={0.5} />
					<Box display="inline-block">
						<Box display="flex" gap="4px">
							{person?.org?.isNamwolfMember && <NamwolfLogo />}
						</Box>
					</Box>
				</Typography>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<Typography variant="body2">{person.locationHighlight}</Typography>
			</Box>
		</Box>
	)

	const personName = `${person.name} ${person?.org?.name}`

	return (
		<ListItemWithWiderSecondaryAction
			sx={{ px: 2, py: 1 }}
			secondaryAction={
				<Box display="flex" alignItems="center">
					{!isDraftRfp && previouslySaved && (
						<>
							<CheckIcon sx={{ color: theme.palette.success.main }} />
							<Typography pr={2} color={theme.palette.success.main} variant="body2">
								Invited
							</Typography>
						</>
					)}
					<ViewInfoButton
						person={person}
						addUser={addUser}
						removeUser={removeUser}
						isAdded={previouslySaved}
						isDraftRfp={isDraftRfp}
					/>
				</Box>
			}
		>
			<ListItemIcon sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Checkbox
					checked={previouslySaved || userToAdd}
					disabled={!isDraftRfp && previouslySaved}
					inputProps={{
						'aria-label': `${personName}, ${previouslySaved ? 'selected' : 'not selected'}`,
					}}
					onChange={async (e) => {
						if (e.target.checked) {
							addUser()
						} else {
							removeUser()
						}
					}}
					sx={{ py: 0 }}
					data-trackid={
						previouslySaved
							? 'button-invites-all-contacts-remove-contact'
							: 'button-invites-all-contacts-add-contact'
					}
				/>
				<IconButton
					size="large"
					onClick={(e) => {
						e.stopPropagation()
						addToFavourites?.(person._id)
					}}
					aria-label={`${
						person.isFavourite ? 'remove from favorites' : 'add to favorites'
					}, ${personName}`}
					sx={{ p: 0 }}
				>
					{person.isFavourite ? (
						<StarIcon sx={{ color: theme.palette.primary.main }} />
					) : (
						<StarBorderIcon sx={{ color: theme.palette.primary.main }} />
					)}
				</IconButton>
			</ListItemIcon>
			{text}
		</ListItemWithWiderSecondaryAction>
	)
}
