import { Alert as MuiAlert, AlertTitle, AlertProps as MuiAlertProps } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'

type AlertProps = Omit<
	MuiAlertProps,
	| 'color'
	| 'variant'
	| 'square'
	| 'elevation'
	| 'slots'
	| 'slotProps'
	| 'components'
	| 'componentsProps'
>

const alertIconMapping = {
	success: <CheckCircleIcon />,
	info: <InfoIcon />,
	error: <ErrorIcon />,
	warning: <WarningIcon />,
}

const Alert = ({ iconMapping = alertIconMapping, ...props }: AlertProps) => {
	return (
		<MuiAlert iconMapping={iconMapping} {...props}>
			{props.children}
		</MuiAlert>
	)
}

export { Alert, AlertTitle }
export type { AlertProps }
