import { Box, BoxProps } from '../box'
import { Alert } from '../alert'
import { Typography } from '../typography'

export type FormErrorsProps = {
	errors?: string[]
	/** Default to: Please correct the following errors */
	title?: string
} & BoxProps

export const FormErrors = ({
	errors = [],
	title = 'Please correct the following errors',
	...props
}: FormErrorsProps) => {
	const hasError = errors.length > 0

	return (
		<Box role="alert" sx={{ visibility: hasError ? 'inherit' : 'hidden' }} {...props}>
			{hasError && (
				<Alert severity="error" role={'presentation'}>
					<Typography>{title}</Typography>
					<ul aria-label="Errors">
						{errors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</Alert>
			)}
		</Box>
	)
}

FormErrors.displayName = 'FormErrors'
