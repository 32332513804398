import { Box, BoxProps, Typography, useTheme } from '@persuit/ui-components'

type ApprovedFirmLabelProps = BoxProps

export const ApprovedFirmLabel = (props: ApprovedFirmLabelProps) => {
	const { palette } = useTheme()

	return (
		<Box {...props}>
			<Typography
				variant="body2"
				style={{
					background: palette.background.greyLabel,
					color: palette.white,
					padding: '0.25rem 0.5rem',
					fontSize: '0.8rem',
					fontWeight: 'bold',
					borderRadius: '0.25rem',
				}}
			>
				APPROVED
			</Typography>
		</Box>
	)
}
