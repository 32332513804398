export type StepType = {
	index: number
	name: string
	displayName: string
}
export const defaultSteps: StepType[] = [
	{
		index: 0,
		name: 'START',
		displayName: 'Select an action',
	},
	{
		index: 1,
		name: 'ACTIONS',
		displayName: 'Action options',
	},
	{
		index: 2,
		name: 'CONFIRM',
		displayName: 'Review and confirm',
	},
]

export const withdrawSteps: StepType[] = [
	{
		index: 0,
		name: 'START',
		displayName: 'Select an action',
	},
	{
		index: 1,
		name: 'ACTIONS',
		displayName: 'Provide reason',
	},
	{
		index: 2,
		name: 'CONFIRM',
		displayName: 'Review and confirm',
	},
]
