/* eslint-disable no-restricted-syntax */
import { GlobalStyles, css } from '@persuit/ui-components'

export const DeprecatedCarouselStyles = () => {
	return <GlobalStyles styles={styles} />
}

const styles = css`
	.buttonBack___1mlaL,
	.buttonFirst___2rhFr,
	.buttonLast___2yuh0,
	.buttonNext___2mOCa,
	.buttonNext___3Lm3s,
	.dot___3c3SI {
		cursor: pointer;
	}
	.prc-dotGroup {
		text-align: center;
		padding-top: 5px;
		padding-bottom: 10px;
	}
	.carousel__dot {
		transform: scale(0.5);
		height: 16px;
		width: 16px;

		border-radius: 50%;
		border: 1px solid #dcdcdf;
		background-color: #dcdcdf;
	}
	.carousel__dot--selected {
		background-color: #6f6f77;
		border: 1px solid #6f6f77;
	}
	.image___xtQGH {
		display: block;
		width: 100%;
		height: 100%;
	}
	.spinner___27VUp {
		position: absolute;
		top: calc(50% - 15px);
		left: calc(50% - 15px);
		width: 30px;
		height: 30px;
		animation-name: spin___S3UuE;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		border: 4px solid #a9a9a9;
		border-top-color: #000;
		border-radius: 30px;
	}
	@keyframes spin___S3UuE {
		0% {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(1turn);
		}
	}
	.container___2O72F {
		position: relative;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	.overlay___IV4qY {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		cursor: zoom-in;
		transition:
			opacity 0.3s,
			transform 0.3s;
	}
	.hover___MYy31,
	.loading___1pvNI,
	.zoom___3kqYk {
		opacity: 1;
	}
	.imageLoadingSpinnerContainer___3UIPD {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #f4f4f4;
	}
	.slide___3-Nqo {
		position: relative;
		display: block;
		box-sizing: border-box;
		height: 0;
		margin: 0;
		list-style-type: none;
	}
	.slide___3-Nqo:focus {
		outline: none !important;
	}
	.slideHorizontal___1NzNV {
		float: left;
	}
	[dir='rtl'] .slideHorizontal___1NzNV {
		direction: rtl;
		transform: scaleX(-1);
	}
	.slideInner___2mfX9 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.focusRing___1airF {
		position: absolute;
		top: 5px;
		right: 5px;
		bottom: 5px;
		left: 5px;
		pointer-events: none;
		outline-width: 5px;
		outline-style: solid;
		outline-color: Highlight;
	}
	@media (-webkit-min-device-pixel-ratio: 0) {
		.focusRing___1airF {
			outline-style: auto;
			outline-color: -webkit-focus-ring-color;
		}
	}
	.horizontalSlider___281Ls {
		position: relative;
		overflow: hidden;
		touch-action: pan-y pinch-zoom;
	}
	[dir='rtl'] .horizontalSlider___281Ls {
		direction: ltr;
		transform: scaleX(-1);
	}
	.horizontalSliderTray___1L-0W {
		overflow: hidden;
		width: 100%;
	}
	.verticalSlider___34ZFD {
		position: relative;
		overflow: hidden;
	}
	.verticalSliderTray___267D8 {
		overflow: hidden;
	}
	.verticalTray___12Key {
		float: left;
	}
	.verticalSlideTrayWrap___2nO7o {
		overflow: hidden;
	}
	.sliderTray___-vHFQ {
		display: block;
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.sliderAnimation___300FY {
		transition: transform 0.5s;
		transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
		will-change: transform;
	}
	.masterSpinnerContainer___1Z6hB {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #f4f4f4;
	}
`
