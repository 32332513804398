import assign from 'lodash/fp/assign'
import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import groupBy from 'lodash/fp/groupBy'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import omit from 'lodash/fp/omit'
import reduce from 'lodash/fp/reduce'
import sortBy from 'lodash/fp/sortBy'
import getOr from 'lodash/fp/getOr'
import concat from 'lodash/fp/concat'
import pricingModels, { firmPhaseModel } from '../../../../../common/data/pricing-models'
import { findBidAtAuctionStart, findBidsAtAuctionEnd } from './calculations'
import { averageRate } from '@persuit/common-logic'

// Produces an array of data ready for the chart
const generatePricingItemChartData = ({ auctionEnd, auctionStart, responses, deliverableId }) => {
	const chartData = compose(
		sortBy('time'),
		reduce(reducePricingItemResponsesToChartData(deliverableId), []),
	)(responses)

	// Special data point for the start of the auction
	const auctionStartDataPoint = generatePricingItemAuctionStartDataPoint({
		responses,
		auctionStart,
		deliverableId,
	})

	// Special data point for the end of the auction
	const auctionEndDataPoint = generatePricingItemAuctionEndDataPoint({
		responses,
		auctionEnd,
		deliverableId,
	})

	return sortBy('time')([...chartData, auctionStartDataPoint, auctionEndDataPoint])
}

const calculatePricingValue = ({ averageRate, price, percentage, pricingModel, notIncluded }) => {
	// If item is excluded from scope (notIncluded) or no charge (Included) then we conside it to be price of 0
	if (notIncluded || pricingModel === firmPhaseModel.INCLUDED) {
		return 0
	}

	switch (pricingModel) {
		case firmPhaseModel.RATECARD:
			return averageRate
		case pricingModels.CONTINGENCYPERCENTAGE:
		case pricingModels.DISCOUNTPERCENTAGE:
			return percentage
		default:
			return price
	}
}

// Append all the bids from the proposal to the accumulator
const reducePricingItemResponsesToChartData = (deliverableId) => (acc, response) => {
	const orgName = response.org.name
	const orgId = response.org._id

	const formatForHistoryDataPoint = (history) => {
		const pricingValue = calculatePricingValue({
			averageRate: averageRate(history?.rates ?? []),
			price: history.price,
			percentage: history.percentage,
			pricingModel: history.pricingModel,
			notIncluded: history.notIncluded,
		})

		return {
			time: history.updatedAt,
			[orgName]: pricingValue,
			orgId,
		}
	}

	// Special data point for the current price
	const responseToDeliverable = find({ deliverableId }, response.responseToDeliverables)

	const pricingValue = calculatePricingValue({
		averageRate: responseToDeliverable.averageRate?.rate,
		price: responseToDeliverable.price,
		percentage: responseToDeliverable.percentage,
		pricingModel: responseToDeliverable.pricingModel,
		notIncluded: responseToDeliverable.notIncluded,
	})

	const currentPrice = [
		{
			time: response.updatedAt,
			[orgName]: pricingValue,
			orgId,
		},
	]

	return compose(
		concat(acc),
		concat(currentPrice),
		map(formatForHistoryDataPoint),
		getOr([], 'history'),
		find({ deliverableId }),
	)(response.responseToDeliverables)
}

// Generate a data point for the auction start time
// Gives a slice of data for that specific point in time
// Even though no bids have occured at that exact time
// Finds the price of each bid at the instant the auction started
//
// The start data is defined to be the bid when the auction started (from before the auction start)
// OR
// In the less likely case when there is no bid before the auction start, it's
// the bid nearest to the auction start
const generatePricingItemAuctionStartDataPoint = ({ deliverableId, responses, auctionStart }) => {
	const auctionStartDataPoint = compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidAtAuctionStart(auctionStart), []),
		groupBy('orgId'),
		sortBy('time'),
		reduce(reducePricingItemResponsesToChartData(deliverableId), []),
	)(responses)

	return auctionStartDataPoint
}

// Generate a data point for the auction end time
// Gives a slice of data for that specific point in time
// Even though no bids have occured at that exact time
// Finds the price of each bid at the instant the auction ended
const generatePricingItemAuctionEndDataPoint = ({ deliverableId, responses, auctionEnd }) => {
	const isBeforeAuctionEnd = ({ time }) => time <= auctionEnd

	return compose(
		reduce(assign, {}),
		map(omit('orgId')),
		reduce(findBidsAtAuctionEnd(auctionEnd), []),
		groupBy('orgId'),
		sortBy('time'),
		filter(isBeforeAuctionEnd),
		reduce(reducePricingItemResponsesToChartData(deliverableId), []),
	)(responses)
}

export { generatePricingItemChartData }
