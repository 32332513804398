// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import { Fragment } from 'react'
import Spacer from '../../layout/spacer.jsx'
import { RevisionCommentsListItem } from '../revision-comments/list-item'

export type RevisionCommentsListProps = {
	rfp: any
	proposal: any
	currency: any
	revisionComments: any
	phaseDuration: any
}

export const RevisionCommentsList = ({
	rfp,
	proposal,
	currency,
	revisionComments,
	phaseDuration,
}: RevisionCommentsListProps) => {
	if (isEmpty(revisionComments)) {
		return null
	}

	return (
		<Fragment>
			{revisionComments.map((revisionComment) => (
				<Fragment key={revisionComment._id}>
					<RevisionCommentsListItem
						rfp={rfp}
						proposal={proposal}
						currency={currency}
						revisionComment={revisionComment}
						phaseDuration={phaseDuration}
					/>
					<Spacer space={2} />
				</Fragment>
			))}
			<Spacer space={3} />
		</Fragment>
	)
}
