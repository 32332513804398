import {
	calculateTotalWeightedAverage,
	calculateTimekeeperAverage,
	RateCard,
	LatestFirmProposals,
} from '../calculations'

import { Rfp } from '../../store'

import { calculateRowData } from './calculate-row-data'

type GenerateRowDataResult = {
	rowData: RowData[]
	averageRow: RowData
}

export type RowData = {
	id: string
	firmId?: string | null
	isAverageRow: boolean
	firmName: string
	weightedAverage: number | null
	timekeepers: TimekeeperData[]
}

type TimekeeperData = {
	timekeeper: string | null
	revisedRate: number | null
	revisedDelta: number | null
	proposedRate: number | null
	delta: number | null
}

export type GenerateRowDataInput = {
	rfp: Rfp
	proposals: LatestFirmProposals[]
}

export function generateRowData({ rfp, proposals }: GenerateRowDataInput): GenerateRowDataResult {
	const firmData = proposals

	const rowData: RowData[] = firmData.map((b) =>
		calculateRowData({
			proposal: b.proposal,
			rateCards: b.proposal.rateCards,
			revisedRateCards: b.revisedProposals
				// sort from oldest to newest
				.sort((a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0))
				.map((p) => p.rateCards),
			timekeepers: rfp.rateReview?.timekeepers ?? [],
		}),
	)

	return {
		rowData,
		averageRow: calculateAverageRow({
			timekeepers: rfp.rateReview?.timekeepers ?? [],
			rateCards: firmData.map((data) => data?.proposal.rateCards),
		}),
	}
}

type CalculateAverageRowInputs = {
	timekeepers: { _id?: string | null }[]
	rateCards: RateCard[][]
}

export function calculateAverageRow({
	timekeepers: tks,
	rateCards,
}: CalculateAverageRowInputs): RowData {
	const timekeepers = tks.map((tk) => {
		const { currentRate, delta, proposedRate } = calculateTimekeeperAverage(tk._id ?? '', rateCards)

		return {
			timekeeper: tk._id ?? null,
			revisedRate: null,
			revisedDelta: null,
			proposedRate,
			currentRate,
			delta,
		}
	})

	return {
		id: 'averageRow',
		isAverageRow: true,
		firmName: 'Average Proposed Rate',
		weightedAverage: calculateTotalWeightedAverage(rateCards),
		timekeepers,
	}
}
