import rfpStates from '../../../../../common/data/rfp-states'
import DialogWizardListOption from '../../dialog-wizard-list-option'
import { NegotiateIcon } from '@persuit/ui-components'

const standardBullets = [
	'Draft suggested changes for the firm.',
	'This is private and confidential.',
]
const revisionInProgressBullet = [
	'A request for revision is currently in progress. Please allow the firm to respond before requesting another revision.',
]
const requestCompletedBullet = [
	'Proposals cannot be revised when your request is marked "completed". To request a revision you must first update the RFP to "mark as evaluating"',
]

function getDisplayText(requestStatus, isDisabled) {
	const isRequestCompleted = requestStatus === rfpStates.COMPLETED

	if (isDisabled && isRequestCompleted) {
		return requestCompletedBullet
	}

	if (isDisabled) {
		return revisionInProgressBullet
	}

	return standardBullets
}

function OptionRequestProposalRevisionStart({
	onNext,
	requestStatus,
	disableRequestProposalRevsionRadio,
}) {
	const displayText = getDisplayText(requestStatus, disableRequestProposalRevsionRadio)

	return (
		<DialogWizardListOption
			dataTestid="request-for-proposal-revision-option"
			icon={<NegotiateIcon />}
			primaryText={'Negotiate'}
			bullets={displayText}
			onClick={onNext}
			disabled={disableRequestProposalRevsionRadio}
			dataTrackid="menuitem-negotiate-manageproposal"
		/>
	)
}

export default OptionRequestProposalRevisionStart
