import { useRef } from 'react'

import { CircularProgress, Button, AttachFileIcon } from '@persuit/ui-components'

import allowableFileTypes from '../../../../common/data/accepted-file-types'
import { FileUploadError } from './file-upload-error'

const resetFileInputRefValue = (event) => (event.target.value = '')

export const FileUpload = ({
	handleFileUploadInputClick,
	disabled,
	onChange,
	isUploading,
	uploadError,
	resetFileInputRefValue,
}) => {
	const fileInputRef = useRef(null)

	const handleFileUploadButtonClick = () => {
		fileInputRef.current.click()
	}

	const handleFileChange = (event) => {
		event.preventDefault()
		onChange(Array.from(event.target.files))

		// ***************** DO NOT REMOVE the `resetFileInputRefValue(event)` code ************
		// input html tag with type file needs value to be reset when trying to upload the same file again.
		// If we dont do this, the change event does not fire and as a result no React stuff works
		resetFileInputRefValue(event)
		// *********************** DO NOT REMOVE the `resetFileInputRefValue(event)` code ************
	}

	return (
		<>
			<div style={{ display: 'flex' }}>
				<Button
					data-testid="file-upload-button"
					startIcon={isUploading ? <CircularProgress size={16} /> : <AttachFileIcon />}
					disabled={disabled || isUploading}
					onClick={handleFileUploadButtonClick}
					color="primary"
				>
					Attach files
				</Button>
			</div>
			<FileUploadError uploadError={uploadError} />
			<input
				data-testid="file-upload-input"
				ref={fileInputRef}
				accept={allowableFileTypes}
				type="file"
				hidden={true}
				multiple={true}
				onClick={handleFileUploadInputClick}
				onChange={handleFileChange}
			/>
		</>
	)
}

FileUpload.defaultProps = {
	// This props is used for testing only - this should not be overriden
	// for normal use of this component
	handleFileUploadInputClick: () => {},
	onChange: () => {},
	resetFileInputRefValue,
	isUploading: false,
}
