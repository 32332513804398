// eslint-disable-next-line no-restricted-imports -- Please upgrade
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'Generate initial draft with details from matter and proposal.',
	'Select and download from a list of templates.',
]

function OptionGenerateLoeStart({ onNext, disabled }) {
	return (
		<DialogWizardListOption
			dataTestid="generate-loe-option"
			icon={<AssignmentTurnedInIcon />}
			primaryText="Generate Engagement Letter"
			bullets={bullets}
			onClick={onNext}
			disabled={disabled}
		/>
	)
}

export default OptionGenerateLoeStart
