import { List, ListProps } from '../../list'
import { FileListItem, FileListItemProps } from './file-list-item'
import { useSnackbar } from '../../snackbar'
import { useConfirmDialog } from '../../dialog'

type FileRepresentation = FileListItemProps['file']

export type FileListProps = Omit<ListProps, 'onChange'> & {
	files: FileRepresentation[]
	onRemoveFile?: (id: string) => void
	/** Will notify when a file is removed giving back the remaining files */
	onChange?: (files: FileRepresentation[]) => void
	disabled?: boolean
	label?: string
	/** Whether to enable remove confirm dialog */
	enableConfirmDialog?: boolean
}

export function FileList({
	files,
	onRemoveFile,
	onChange,
	disabled,
	label = 'list of file attachments',
	enableConfirmDialog,
	...rest
}: FileListProps) {
	const showRemoveButton = onRemoveFile || onChange
	const { openSnackbar } = useSnackbar()
	const { openConfirmDialog } = useConfirmDialog()

	return (
		<List dense={true} aria-label={label} {...rest}>
			{files.map((file) => (
				<FileListItem
					key={file._id}
					file={file}
					disabled={disabled}
					onRemoveFile={
						showRemoveButton
							? () => {
									if (enableConfirmDialog) {
										openConfirmDialog({
											title: 'Delete File',
											content: `Are you sure you want do delete the following file: ${file.filename}`,
											actions: [
												{ label: 'Cancel', type: 'secondary', close: true },
												{
													label: 'Delete',
													type: 'primary',
													close: true,
													action: () => {
														onRemoveFile?.(file._id)
													},
												},
											],
										})
									} else {
										onRemoveFile?.(file._id)
										onChange?.(files.filter((f) => f._id !== file._id))
										openSnackbar('File deleted successfully')
									}
							  }
							: undefined
					}
				/>
			))}
		</List>
	)
}
