export type RawValue = {
	_id: string
	label: string
	values?: RawValue[]
	/** Default to true */
	defaultExpanded?: boolean
}

export type Value = {
	_id: string
	label: string
	values: Value[]
	/** Default to true */
	defaultExpanded?: boolean
}

type Metadata = {
	value: Value
	level: number
	hasSubValues: boolean
}

export class NestedOptions {
	private options: Value[] = []
	private optionsMap: Map<string, Metadata> = new Map()

	constructor(options: RawValue[]) {
		function normalizeOptions(options: RawValue[]): Value[] {
			return options.map((option) => ({
				...option,
				values: normalizeOptions(option.values ?? []),
			}))
		}

		this.options = normalizeOptions(options)
		this.traverse((value, level) => {
			this.optionsMap.set(value._id, { value, level, hasSubValues: value.values.length > 0 })
		})
	}

	array() {
		return this.options
	}

	traverse(callback: (value: Value, level: number) => void | { stop: boolean }): void {
		function traverseHelper(values: Value[], level: number): void {
			for (const value of values) {
				const res = callback(value, level)
				if (res?.stop) {
					return
				}
				traverseHelper(value.values, level + 1)
			}
		}

		traverseHelper(this.options, 1)
	}

	map(fn: (value: Value) => Value): NestedOptions {
		function applyMapping(values: Value[]): Value[] {
			return values.map((value) => {
				const transformedValue = fn(value)
				return {
					...transformedValue,
					values: applyMapping(transformedValue.values),
				}
			})
		}

		return new NestedOptions(applyMapping(this.options))
	}

	flatten() {
		return Array.from(this.optionsMap.values()).map((v) => v.value)
	}

	filter(predicate: (value: Value) => boolean): NestedOptions {
		function applyFiltering(values: Value[]): Value[] {
			return values.filter(predicate).map((val) => ({
				...val,
				values: applyFiltering(val.values),
			}))
		}

		return new NestedOptions(applyFiltering(this.options))
	}

	every(predicate: (value: Value) => boolean) {
		let result = true

		this.traverse((value) => {
			if (!predicate(value)) {
				result = false
				return { stop: true }
			}
		})

		return result
	}

	search(searchTerm: string): NestedOptions {
		const lowerSearchTerm = searchTerm.toLowerCase()
		return new NestedOptions(
			this.options
				.filter((field) =>
					[
						field.label,
						...field.values.flatMap((value) => [
							value.label,
							...(value.values ?? []).flatMap((subValue) => subValue.label),
						]),
					]
						.join(' ')
						.toLowerCase()
						.includes(lowerSearchTerm),
				)
				.map((field) => ({
					...field,
					values: field.values
						.filter(
							(value) =>
								field.label.toLowerCase().includes(lowerSearchTerm) ||
								value.label.toLowerCase().includes(lowerSearchTerm) ||
								value.values.some((subValue) =>
									subValue.label.toLowerCase().includes(lowerSearchTerm),
								),
						)
						.map((value) => ({
							...value,
							values: value.values.filter(
								(subValue) =>
									field.label.toLowerCase().includes(lowerSearchTerm) ||
									value.label.toLowerCase().includes(lowerSearchTerm) ||
									subValue.label.toLowerCase().includes(lowerSearchTerm),
							),
						})),
				})),
		)
	}

	isSelected(id: string, selectedIds: string[]): boolean {
		const option = this.valueById(id)
		return option.values.length > 0
			? option.values.every((v) => this.isSelected(v._id, selectedIds))
			: selectedIds.includes(option._id)
	}

	hasOption(id: string): boolean {
		return !!this.optionsMap.get(id)
	}

	getMetaData(id: string): Metadata {
		const result = this.optionsMap.get(id)
		if (!result) {
			throw new Error(`No option exists with id ${id}`)
		}
		return result
	}

	hasSubValues(id: string): boolean {
		return this.valueById(id).values.length > 0
	}

	levelById(id: string): number {
		return this.getMetaData(id).level
	}

	valueById(id: string): Value {
		return this.getMetaData(id).value
	}
}
