import { forwardRef, useRef, useState } from 'react'
import {
	Box,
	Button,
	FormHelperText,
	Link as TextLink,
	Slide,
	Typography,
	useConfirmDialog,
	useSnackbar,
} from '@persuit/ui-components'
import { SharingControl_RevokeUsersFromRfpMutation } from '@persuit/ui-graphql/generated'
import { FetchResult, useMutation } from '@persuit/ui-graphql'
import allowTypes from '../../../../../common/data/allow-types'
import rfpStates from '../../../../../common/data/rfp-states'
import unregisteredUserListState from '../../../../../common/data/unregistered-user-list-states'
import unregisteredUserStates from '../../../../../common/data/unregistered-user-states'
import { getSelectedUnknownUsers, getSelectedKnownUsers, getCleanUserFavorites } from '../utils'
import { REVOKE_MUTATION } from '../../../../containers/sharing/sharing-control.graphql'
import { RfpType, KnownPerson, UnknownPerson, TabIndexes } from '../types'
import { ContactsAddedGrid, AddedContact } from './contacts-added-grid'
import { useInviteContext } from '../invite-context'

const getDomainFromEmail = (email: string): string => {
	const atIndex = email.indexOf('@')
	if (atIndex !== -1) {
		return email.substring(atIndex + 1)
	}
	return ''
}

export const parseRows = (
	selectedUsers: (KnownPerson | UnknownPerson | string)[],
	pendingRemoval: Record<string, boolean>,
	firmOrgId?: string,
): AddedContact[] => {
	return selectedUsers
		.map((selectedUser): AddedContact | undefined => {
			if (typeof selectedUser === 'string') {
				return {
					_id: selectedUser,
					contactName: selectedUser,
					contactTitle: null,
					firmName: getDomainFromEmail(selectedUser),
					contactLocation: null,
					isNamwolfMember: false,
					type: 'unknown',
					pendingRemoval: pendingRemoval[selectedUser] || false,
					addedBySystem: false,
				}
			}
			if (selectedUser.__type__ === 'unknown') {
				return {
					_id: selectedUser._id,
					contactName: selectedUser.email,
					contactTitle: null,
					firmName: getDomainFromEmail(selectedUser.email),
					contactLocation: null,
					isNamwolfMember: false,
					type: selectedUser.__type__,
					pendingRemoval: pendingRemoval[selectedUser._id] || false,
					addedBySystem: false,
					selectedUser,
				}
			}
			const knownPerson = selectedUser as KnownPerson
			// this handles the single firm list in firm tab
			if (!firmOrgId || knownPerson.org._id === firmOrgId) {
				return {
					_id: knownPerson._id,
					contactName: `${knownPerson.name}`.trim(),
					contactTitle: knownPerson.title,
					firmName: knownPerson.org.name,
					contactLocation: knownPerson.location ?? '',
					isNamwolfMember: knownPerson.org.isNamwolfMember,
					type: knownPerson.__type__,
					pendingRemoval: pendingRemoval[knownPerson._id] || false,
					addedBySystem: knownPerson.addedBySystem,
					selectedUser,
				}
			}
		})
		.filter((contact): contact is AddedContact => contact !== undefined)
}

type ContactsAddedSectionPublishedFirmCardProps = {
	rfp: RfpType
	firmOrgId: string
	userOrgId: string
	userFavorites: string[]
}

export const ContactsAddedSectionPublishedFirmCard = ({
	rfp,
	firmOrgId,
	userOrgId,
	userFavorites,
}: ContactsAddedSectionPublishedFirmCardProps) => {
	const [pendingRemoval, setPendingRemoval] = useState<Record<string, boolean>>({})
	const [revoke] = useMutation(REVOKE_MUTATION)

	const revokeFn = async (userIdsToRemove: string[]) => {
		return await revoke({
			variables: {
				rfpId: rfp._id,
				userIds: userIdsToRemove,
			},
		})
	}
	const userFavoritesClean = getCleanUserFavorites(userFavorites)

	const selectedUnknownUsers = getSelectedUnknownUsers(
		rfp,
		unregisteredUserStates,
		unregisteredUserListState,
	)

	const selectedKnownUsers = getSelectedKnownUsers(rfp, userOrgId, allowTypes, userFavoritesClean)

	const selectedUsers = [...selectedKnownUsers, ...selectedUnknownUsers]

	const rows = parseRows(selectedUsers, pendingRemoval, firmOrgId)

	return (
		<ContactsAddedForm
			isDraftRfp={rfp.status === rfpStates.DRAFT}
			firmOrgId={firmOrgId}
			pendingRemoval={pendingRemoval}
			setPendingRemoval={setPendingRemoval}
			rows={rows}
			isFirmView={true}
			revoke={revokeFn}
		/>
	)
}

type ContactsAddedSectionTabProps = {
	inviteError: string | undefined
	setTabIndex: (tab: number) => void
	tabs: TabIndexes
}

export const ContactsAddedSectionTab = ({
	setTabIndex,
	inviteError,
	tabs,
}: ContactsAddedSectionTabProps) => {
	const [pendingRemoval, setPendingRemoval] = useState<Record<string, boolean>>({})
	const { onRemove, usersToAdd, selectedUsers, isDraftRfp } = useInviteContext()

	const rows = isDraftRfp
		? parseRows(selectedUsers, pendingRemoval)
		: parseRows(usersToAdd, pendingRemoval)

	return (
		<ContactsAddedForm
			isDraftRfp={isDraftRfp}
			setTabIndex={setTabIndex}
			pendingRemoval={pendingRemoval}
			setPendingRemoval={setPendingRemoval}
			rows={rows}
			revoke={onRemove}
			inviteError={inviteError}
			tabs={tabs}
		/>
	)
}

type ContactsAddedFormProps = {
	isDraftRfp: boolean
	firmOrgId?: string
	setTabIndex?: (tab: number) => void
	pendingRemoval: Record<string, boolean>
	setPendingRemoval: (isPending: Record<string, boolean>) => void
	rows: AddedContact[]
	isFirmView?: boolean
	revoke: (
		userIdsToRemove: string[],
	) => Promise<void> | Promise<FetchResult<SharingControl_RevokeUsersFromRfpMutation>>
	inviteError?: string | undefined
	tabs?: TabIndexes
}

const ContactsAddedForm = ({
	isDraftRfp,
	setTabIndex,
	pendingRemoval,
	setPendingRemoval,
	rows,
	isFirmView = false,
	revoke,
	inviteError,
	tabs,
}: ContactsAddedFormProps) => {
	const containerRef = useRef<HTMLElement>(null)

	const { openConfirmDialog } = useConfirmDialog()
	const { openSnackbar } = useSnackbar()

	const handleCancel = () => {
		setPendingRemoval({})
	}

	const contactsPendingRemoval: AddedContact[] = rows.filter((row) => row.pendingRemoval)

	const handleRemove = async () => {
		await onRemoveUsers(contactsPendingRemoval)
		setPendingRemoval({})
	}

	const onRemoveUsers = async (contactsToRemove: AddedContact[]) => {
		const unknownUserIds = contactsToRemove
			.filter((person) => person.type === 'unknown')
			.map((person) => person._id)

		const knownUsers = contactsToRemove.filter((person) => person.type === 'known')

		const knownUserIds = contactsToRemove
			.filter((person) => person.type === 'known')
			.map((person) => person._id)

		const revokeShare = async (userIdsToRemove: string[]) => {
			await revoke(userIdsToRemove)

			openSnackbar(
				`${
					userIdsToRemove.length === 1 ? 'A contact has' : 'Multiple contacts have'
				} been removed from the Invitation List`,
			)
		}

		if (unknownUserIds.length > 0) {
			await revokeShare(unknownUserIds)
		}

		if (knownUserIds.length > 0) {
			if (isDraftRfp) {
				await revokeShare(knownUserIds)
			} else {
				const confirmDialogContent =
					knownUserIds.length === 1 && knownUsers[0]?.contactName
						? `${knownUsers[0].contactName} will be removed from the invitation list.`
						: `${knownUserIds.length} people will be removed from the invitation list.`

				const confirmDialogTitle =
					knownUserIds.length === 1
						? `Are you sure you want to remove this person?`
						: `Are you sure you want to remove these people?`

				openConfirmDialog({
					title: confirmDialogTitle,
					content: confirmDialogContent,
					actions: [
						{ type: 'secondary', label: 'Cancel', close: true },
						{ label: 'Confirm', close: true, action: () => revokeShare(knownUserIds) },
					],
				})
			}
		}
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
			{rows.length === 0 && setTabIndex && tabs ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
					<Box>
						<Typography variant="body1" gutterBottom={true}>
							Your Invitation List is currently empty. To add contacts, you can:
						</Typography>
						<Typography component="ul">
							<Typography component="li" variant="body1" gutterBottom={true}>
								Search and select from the{' '}
								{getTabLink('All Contacts', setTabIndex, tabs.ALL_CONTACTS)}
								{tabs.FIRM_LISTS && (
									<> and {getTabLink('Firm Lists', setTabIndex, tabs.FIRM_LISTS)} tabs</>
								)}
							</Typography>
							<Typography component="li" variant="body1" gutterBottom={true}>
								Or, you can {getTabLink('Add contacts by Email', setTabIndex, tabs.EMAIL)} as well
							</Typography>
						</Typography>
					</Box>
					{Boolean(inviteError) && <FormHelperText error={true}>{inviteError}</FormHelperText>}
				</Box>
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} ref={containerRef}>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
						{isFirmView && (
							<Typography variant="h4" component="span" color="secondary">
								Invited contacts ({rows.length})
							</Typography>
						)}
						<ContactsAddedGrid
							rows={rows}
							pendingRemoval={pendingRemoval}
							setPendingRemoval={setPendingRemoval}
							isFirmView={isFirmView}
							isDraftRfp={isDraftRfp}
						/>
					</Box>
					{Object.keys(pendingRemoval).length > 0 && (
						<Slide
							in={Object.keys(pendingRemoval).length > 0}
							container={containerRef.current}
							direction="up"
						>
							<SaveChangesPanel
								numberOfContactsPendingRemoval={Object.keys(pendingRemoval).length}
								onCancel={handleCancel}
								onRemove={handleRemove}
							/>
						</Slide>
					)}
				</Box>
			)}
		</Box>
	)
}

const SaveChangesPanel = forwardRef(
	(
		{
			numberOfContactsPendingRemoval,
			onCancel,
			onRemove,
		}: {
			numberOfContactsPendingRemoval: number
			onCancel: () => void
			onRemove: () => Promise<void>
		},
		ref,
	) => {
		return (
			<Box
				ref={ref}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					p: 2,
					boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
				}}
			>
				<Typography variant="body1">{`${numberOfContactsPendingRemoval} ${
					numberOfContactsPendingRemoval === 1 ? 'contact' : 'contacts'
				} to be removed from the Invitation List`}</Typography>
				<Box sx={{ display: 'flex', gap: 1.5 }}>
					<Button size="large" onClick={onCancel}>
						Cancel
					</Button>
					<Button size="large" variant="contained" onClick={onRemove}>
						Save Changes
					</Button>
				</Box>
			</Box>
		)
	},
)

const getTabLink = (text: string, setTabIndex: React.Dispatch<number>, tabIndex: number) => {
	return (
		<Typography component="span" variant="body1SemiboldLink" gutterBottom={true}>
			<TextLink href="#" underline="always" onClick={() => setTabIndex(tabIndex)}>
				{text}
			</TextLink>
		</Typography>
	)
}
