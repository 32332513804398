export default {
	fallbackLng: 'en',

	interpolation: {
		escapeValue: false, // not needed for react!!
	},

	whitelist: ['en', 'de'],

	returnObjects: true,
}
