import { RichTextView, Dialog } from '@persuit/ui-components'

type EditAssumptionDialog = {
	handleViewClose: () => void
	isOpen: boolean
	currentAssumptions: string | null
}

const EditAssumptionDialog = ({
	handleViewClose,
	isOpen,
	currentAssumptions,
}: EditAssumptionDialog) => {
	return (
		<Dialog
			open={isOpen}
			onClose={handleViewClose}
			size="sm"
			title="Assumptions & description"
			actions={[
				{
					label: 'Ok',
					variant: 'primary',
					onClick: handleViewClose,
				},
			]}
			aria-describedby="alert-dialog-description"
		>
			<RichTextView id="alert-dialog-description" content={currentAssumptions} />
		</Dialog>
	)
}

export default EditAssumptionDialog
