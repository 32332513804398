// @ts-strict-ignore
import * as React from 'react'
import {
	AttachMoneyIcon,
	Box,
	CheckIcon,
	DialpadIcon,
	FormCheckbox,
	FormSelect,
	FormTextField,
	MenuItem,
	ShortTextIcon,
	SortableExpandableCard,
	Spacer,
	SubjectIcon,
	Typography,
	Tooltip,
	DragHandleIcon,
	ChecklistRtlIcon as MultiSelectIcon,
	LinearScaleIcon as ScaleIcon,
	PercentIcon,
	RadioButtonCheckedIcon,
	useExpandCollapseStore,
	useExpandCollapseActions,
} from '@persuit/ui-components'

import { useWatch } from '../question-list/question-list-form-utils'
import { RfpQuestion, RfpQuestionError } from '../../../types'

import { ValueSelectForm } from './value-select-form'

type QuestionProps = Pick<RfpQuestion, '_id' | 'originalQuestionId' | 'title' | 'type'> & {
	index: number
	questionError: RfpQuestionError | null
	displayIndex: number
	activeDragId?: string
	deleteQuestion: React.ReactNode
	questionGroupNumber?: number
}

export const Question = React.memo(
	({
		index,
		displayIndex,
		activeDragId,
		_id,
		questionError,
		deleteQuestion,
		originalQuestionId,
		questionGroupNumber,
		title,
		type: typeFromProps,
	}: QuestionProps) => {
		const expanded = useExpandCollapseStore(({ expandedState }) => expandedState[_id])
		const { expand, collapse } = useExpandCollapseActions()

		const onToggleExpanded = () => {
			return !expanded ? expand(_id) : collapse(_id)
		}

		const typeChanged = useWatch({
			name: `questions.${index}.type`,
		})

		const type = typeChanged || typeFromProps

		const isActiveDragging = activeDragId === _id

		const questionTypeSelect = (
			<FormSelect
				fullWidth={true}
				id={`question-type-select-${index}`}
				name={`questions.${index}.type`}
				label="Question type"
				aria-label="Question type"
				required={true}
				selectProps={{
					'data-testid': 'question-type',
				}}
				disabled={!!originalQuestionId}
			>
				{Object.keys(questionType).map((type: string) => {
					const TypeIcon = primaryTypeIcon(type)

					return (
						<MenuItem
							key={type}
							value={type}
							aria-label={questionType[type]}
							data-testid="question-type-menu-item"
						>
							<Box display="flex" alignItems="center">
								<TypeIcon />
								<Spacer shape="column" />
								<Typography>{questionType[type]}</Typography>
								<Spacer shape="column" />
								<Typography color={originalQuestionId ? undefined : 'text.secondary'}>
									{questionTypeSubtitle[type]}
								</Typography>
							</Box>
						</MenuItem>
					)
				})}
			</FormSelect>
		)

		const details = (
			<Box width="100%">
				<FormTextField
					name={`questions.${index}.title`}
					inputProps={{ 'data-testid': 'question-title', maxLength: 200 }}
					required={true}
					error={!!questionError?.title ?? false}
					label="Title / Question (max 200 chars)"
					id={`question-title-${index}`}
					fullWidth={true}
					helperText={questionError?.title ? questionError.title : null}
				/>

				<Spacer space={2} />

				{originalQuestionId ? (
					<Tooltip title="Once set, the Question type cannot be changed" arrow={true}>
						<Box>{questionTypeSelect}</Box>
					</Tooltip>
				) : (
					questionTypeSelect
				)}

				<Box mt={3} mb={1}>
					<FormTextField
						name={`questions.${index}.description`}
						inputProps={{ 'data-testid': 'question-description' }}
						label="Description"
						id={`question-description-${index}`}
						fullWidth={true}
						multiline={true}
						maxRows={4}
					/>
				</Box>

				{(type === 'singleSelect' || type === 'multipleSelect' || type === 'scale') && (
					<Box mt={3} mb={1}>
						<ValueSelectForm
							parentName={`questions.${index}`}
							optionErrors={questionError?.options}
							isOriginalQuestion={!!originalQuestionId}
							type={type}
						/>
					</Box>
				)}

				<Box mt={3} mb={1}>
					<FormCheckbox
						name={`questions.${index}.required`}
						id={`question-required-checkbox-${index}`}
						checkboxProps={{
							inputProps: { 'data-testid': 'question-required-checkbox' },
							color: 'primary',
						}}
						label="Required"
					/>
				</Box>
			</Box>
		)

		return (
			<SortableExpandableCard
				id={_id}
				key={_id}
				hasError={!!questionError}
				summary={
					<QuestionHeader
						questionGroupNumber={questionGroupNumber}
						questionNumber={displayIndex}
						title={title}
						type={type}
					/>
				}
				containerProps={{
					tabIndex: -1,
					role: 'group',
					bgcolor: isActiveDragging ? 'primary.lighterHue' : undefined,
				}}
				style={{
					opacity: isActiveDragging ? 0 : 1,
				}}
				details={details}
				expanded={expanded}
				onToggle={onToggleExpanded}
				summarySuffix={deleteQuestion}
				sortableListItemProps={{
					containerProps: {
						mb: 2,
					},
				}}
				shouldRenderDetailsOnCollapse={false}
				sortableDragHandlerProps={{
					'aria-label': `Drag handler for question ${questionGroupNumber}.${displayIndex}`,
				}}
			/>
		)
	},
)

Question.displayName = 'Question'

const questionType = {
	choice: 'Yes / No',
	short: 'Short text',
	medium: 'Medium text',
	long: 'Long text',
	numeric: 'Numeric',
	percentage: 'Percentage',
	price: 'Money',
	singleSelect: 'Single select',
	multipleSelect: 'Multiple select',
	scale: 'Scale input',
}

const questionTypeSubtitle = {
	choice: '',
	short: '(~125 word response)',
	medium: '(~400 word response)',
	long: '(Unlimited word response)',
	numeric: '',
	percentage: '',
	price: '',
	singleSelect: '(Firms will pick a single option from a pre-defined list)',
	multipleSelect: '(Firms will pick multiple options from a pre-defined list)',
	scale: '(Firms will pick a single option on a pre-defined scale)',
}

const primaryTypeIcon = (type: string) => {
	switch (type) {
		case 'choice': {
			return CheckIcon
		}
		case 'short': {
			return ShortTextIcon
		}
		case 'medium': {
			return DragHandleIcon
		}
		case 'long': {
			return SubjectIcon
		}
		case 'numeric': {
			return DialpadIcon
		}
		case 'percentage': {
			return PercentIcon
		}
		case 'price': {
			return AttachMoneyIcon
		}
		case 'singleSelect': {
			return RadioButtonCheckedIcon
		}
		case 'multipleSelect': {
			return MultiSelectIcon
		}
		case 'scale': {
			return ScaleIcon
		}
		default: {
			// eslint-disable-next-line react/display-name
			return () => null
		}
	}
}

export type QuestionHeaderProps = {
	questionNumber: number
	title?: string | null
	type: string
	questionGroupNumber?: number
}

export const QuestionHeader = ({
	questionNumber,
	title,
	type,
	questionGroupNumber,
}: QuestionHeaderProps) => {
	const TypeIcon = primaryTypeIcon(type)

	const linkNumber =
		questionGroupNumber !== undefined ? `${questionGroupNumber}_${questionNumber}` : questionNumber
	const displayNumber =
		questionGroupNumber !== undefined ? `${questionGroupNumber}.${questionNumber}` : questionNumber

	return (
		<Box
			id={`question_item_${linkNumber}`}
			display="flex"
			alignItems="center"
			tabIndex={-1}
			aria-label={`Question ${displayNumber}. ${title ?? ''}`}
		>
			<TypeIcon />
			<Box ml={4} display="flex" alignItems="baseline">
				{displayNumber}.{' '}
				{title ? (
					title
				) : (
					<>
						<Spacer shape="column" space={1} />
						<Typography
							variant="body2"
							color="text.secondary"
							style={{
								fontStyle: 'italic',
							}}
						>
							{' '}
							Add a question
						</Typography>
					</>
				)}
			</Box>
		</Box>
	)
}
