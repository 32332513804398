import { useId, useRef, useState, useEffect } from 'react'
import { Box, BoxProps, DataGridPro, ScrollShadows } from '@persuit/ui-components'
import { useUser } from '@persuit/ui-auth'
import { FilterControls } from './filter-controls'

import { StoreProvider, Proposal, Rfp, useStore, useActions, Field, useStoreApi } from './store'

import { CustomColumnMenu } from './column-definitions/column-menu'

import { ProposalDetailDialog } from './proposal-detail-dialog'

const ROW_HEIGHT = 90

export type CompareTableProps = {
	containerProps?: BoxProps
	proposals: Proposal[]
	rfp: Rfp
}

export const CompareTable = ({ proposals, rfp, containerProps }: CompareTableProps) => {
	const { user, loading } = useUser()

	if (loading) return null

	return (
		<StoreProvider
			initialState={{ rfp, proposals, groupCurrency: user?.group?.settings.currency }}
			mergeState={({ initialState, currentState }) => ({
				...currentState,
				...initialState,
			})}
		>
			<RateDataGrid {...containerProps} />
		</StoreProvider>
	)
}

type RateDataGridProps = {
	containerProps?: BoxProps
}

export const RateDataGrid = ({ containerProps }: RateDataGridProps) => {
	const { rowData, averageRow } = useStore((state, s) => s.rowData())
	const storeApi = useStoreApi()
	const columnDefinitions = useStore((state, s) => s.columnDefinitions(storeApi))

	const { setSortState } = useActions()

	const sortState = useStore((state) => state.sortState)

	const containerId = useId()

	// To provide consistent height when filtering
	const heightRef = useRef(0)
	heightRef.current = Math.max((rowData.length + 1) * ROW_HEIGHT + 60 + 100, heightRef.current)

	const [virtualScrollerEl, setVirtualScrollerEl] = useState<Element | null>(null)

	useEffect(() => {
		const getEl = () => document.querySelector('.MuiDataGrid-virtualScroller')

		function getScroller() {
			const el = getEl()
			if (el) {
				setVirtualScrollerEl(el)
			} else {
				requestAnimationFrame(getScroller)
			}
		}

		getScroller()
	}, [])

	return (
		<Box {...containerProps}>
			<FilterControls />

			<ScrollShadows
				disableLeftShadow={true}
				disableTopShadow={true}
				shadowSize={48}
				tolerance={2}
				scrollingElement={virtualScrollerEl}
				sx={(theme) => ({
					mt: 2,
					display: 'flex',
					maxHeight: '60vh',
					// firm rows + average row + header row + extra space
					height: `${heightRef.current}px`,
					'& .odd': {
						background: theme.palette.grey100,
					},
					'& .even': {
						background: 'white',
					},
				})}
			>
				<DataGridPro
					stickyColumnWidths={[0, 200]}
					aria-label="Rates comparison table"
					experimentalFeatures={{ ariaV7: true }}
					forwardedProps={{ id: containerId }}
					slots={{ columnMenu: CustomColumnMenu }}
					sx={{
						flexGrow: 1,
						width: 0,
						'& .MuiDataGrid-columnHeader:focus-visible': {
							'.MuiDataGrid-menuIcon': {
								visibility: 'visible',
								width: 'auto',
							},
						},
						'& .MuiDataGrid-row.Mui-hovered': {
							backgroundColor: 'transparent',
						},
						'& .MuiDataGrid-row:hover': {
							backgroundColor: 'transparent',
						},
					}}
					autosizeOnMount={true}
					disableRowSelectionOnClick={true}
					getRowHeight={() => ROW_HEIGHT}
					hideFooter={true}
					autoHeight={false}
					getRowId={(row) => row.id}
					columns={columnDefinitions}
					rows={rowData}
					pinnedRows={{ top: [averageRow] }}
					onSortModelChange={([sort]) => {
						setSortState(
							!sort || !sort.sort
								? null
								: {
										field: sort.field as Field,
										direction: sort.sort,
								  },
						)
					}}
					sortModel={sortState ? [{ field: sortState.field, sort: sortState.direction }] : []}
				/>
			</ScrollShadows>

			<ProposalDetailDialog />
		</Box>
	)
}
