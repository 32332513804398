type Currency = {
	code: string
	symbol: string
	name: string
	fullName: string
}

export const CURRENCIES: Record<string, Currency> = {
	AED: {
		code: 'AED',
		name: 'currency.currencies.AED',
		symbol: 'Dhs.',
		fullName: 'United Arab Emirates Dirham',
	},
	AFN: {
		code: 'AFN',
		name: 'currency.currencies.AFN',
		symbol: '؋',
		fullName: 'Afghan Afghani',
	},
	ALL: {
		code: 'ALL',
		name: 'currency.currencies.ALL',
		symbol: 'L',
		fullName: 'Albanian Lek',
	},
	AMD: {
		code: 'AMD',
		name: 'currency.currencies.AMD',
		symbol: '֏',
		fullName: 'Armenian Dram',
	},
	ANG: {
		code: 'ANG',
		name: 'currency.currencies.ANG',
		symbol: 'ƒ',
		fullName: 'Netherlands Antillean Guilder',
	},
	AOA: {
		code: 'AOA',
		name: 'currency.currencies.AOA',
		symbol: 'Kw',
		fullName: 'Angolan Kwanza',
	},
	ARS: {
		code: 'ARS',
		name: 'currency.currencies.ARS',
		symbol: '$',
		fullName: 'Argentine Peso',
	},
	AUD: {
		code: 'AUD',
		name: 'currency.currencies.AUD',
		symbol: '$',
		fullName: 'Australian Dollar',
	},
	AWG: {
		code: 'AWG',
		name: 'currency.currencies.AWG',
		symbol: 'ƒ',
		fullName: 'Aruban Florin',
	},
	AZN: {
		code: 'AZN',
		name: 'currency.currencies.AZN',
		symbol: '₼',
		fullName: 'Azerbaijani Manat',
	},
	BAM: {
		code: 'BAM',
		name: 'currency.currencies.BAM',
		symbol: 'KM',
		fullName: 'Bosnia-Herzegovina Convertible Mark',
	},
	BBD: {
		code: 'BBD',
		name: 'currency.currencies.BBD',
		symbol: '$',
		fullName: 'Barbadian Dollar',
	},
	BDT: {
		code: 'BDT',
		name: 'currency.currencies.BDT',
		symbol: '৳',
		fullName: 'Bangladeshi Taka',
	},
	BGN: {
		code: 'BGN',
		name: 'currency.currencies.BGN',
		symbol: 'лв',
		fullName: 'Bulgarian Lev',
	},
	BHD: {
		code: 'BHD',
		name: 'currency.currencies.BHD',
		symbol: 'د.ب',
		fullName: 'Bahraini Dinar',
	},
	BIF: {
		code: 'BIF',
		name: 'currency.currencies.BIF',
		symbol: 'FBu',
		fullName: 'Burundian Franc',
	},
	BMD: {
		code: 'BMD',
		name: 'currency.currencies.BMD',
		symbol: '$',
		fullName: 'Bermudan Dollar',
	},
	BND: {
		code: 'BND',
		name: 'currency.currencies.BND',
		symbol: '$',
		fullName: 'Brunei Dollar',
	},
	BOB: {
		code: 'BOB',
		name: 'currency.currencies.BOB',
		symbol: 'Bs',
		fullName: 'Bolivian Boliviano',
	},
	BRL: {
		code: 'BRL',
		name: 'currency.currencies.BRL',
		symbol: 'R$',
		fullName: 'Brazilian Real',
	},
	BSD: {
		code: 'BSD',
		name: 'currency.currencies.BSD',
		symbol: '$',
		fullName: 'Bahamian Dollar',
	},
	BTN: {
		code: 'BTN',
		name: 'currency.currencies.BTN',
		symbol: 'Nu',
		fullName: 'Bhutanese Ngultrum',
	},
	BWP: {
		code: 'BWP',
		name: 'currency.currencies.BWP',
		symbol: 'P',
		fullName: 'Botswanan Pula',
	},
	BYN: {
		code: 'BYN',
		name: 'currency.currencies.BYN',
		symbol: 'Br',
		fullName: 'New Belarusian Ruble',
	},
	BYR: {
		code: 'BYR',
		name: 'currency.currencies.BYR',
		symbol: 'Br',
		fullName: 'Belarusian Ruble',
	},
	BZD: {
		code: 'BZD',
		name: 'currency.currencies.BZD',
		symbol: '$',
		fullName: 'Belize Dollar',
	},
	CAD: {
		code: 'CAD',
		name: 'currency.currencies.CAD',
		symbol: '$',
		fullName: 'Canadian Dollar',
	},
	CDF: {
		code: 'CDF',
		name: 'currency.currencies.CDF',
		symbol: '$',
		fullName: 'Congolese Franc',
	},
	CHF: {
		code: 'CHF',
		name: 'currency.currencies.CHF',
		symbol: 'fr',
		fullName: 'Swiss Franc',
	},
	CLF: {
		code: 'CLF',
		name: 'currency.currencies.CLF',
		symbol: 'UF',
		fullName: 'Chilean Unit of Account (UF)',
	},
	CLP: {
		code: 'CLP',
		name: 'currency.currencies.CLP',
		symbol: '$',
		fullName: 'Chilean Peso',
	},
	CNY: {
		code: 'CNY',
		name: 'currency.currencies.CNY',
		symbol: 'CN¥',
		fullName: 'Chinese Yuan',
	},
	COP: {
		code: 'COP',
		name: 'currency.currencies.COP',
		symbol: '$',
		fullName: 'Colombian Peso',
	},
	CRC: {
		code: 'CRC',
		name: 'currency.currencies.CRC',
		symbol: '₡',
		fullName: 'Costa Rican Col\u00f3n',
	},
	CUC: {
		code: 'CUC',
		name: 'currency.currencies.CUC',
		symbol: 'CUC',
		fullName: 'Cuban Convertible Peso',
	},
	CUP: {
		code: 'CUP',
		name: 'currency.currencies.CUP',
		symbol: 'CUP',
		fullName: 'Cuban Peso',
	},
	CVE: {
		code: 'CVE',
		name: 'currency.currencies.CVE',
		symbol: '$',
		fullName: 'Cape Verdean Escudo',
	},
	CZK: {
		code: 'CZK',
		name: 'currency.currencies.CZK',
		symbol: 'Kč',
		fullName: 'Czech Republic Koruna',
	},
	DJF: {
		code: 'DJF',
		name: 'currency.currencies.DJF',
		symbol: 'Fdj',
		fullName: 'Djiboutian Franc',
	},
	DKK: {
		code: 'DKK',
		name: 'currency.currencies.DKK',
		symbol: 'kr',
		fullName: 'Danish Krone',
	},
	DOP: {
		code: 'DOP',
		name: 'currency.currencies.DOP',
		symbol: 'RD$',
		fullName: 'Dominican Peso',
	},
	DZD: {
		code: 'DZD',
		name: 'currency.currencies.DZD',
		symbol: 'د.ج',
		fullName: 'Algerian Dinar',
	},
	EGP: {
		code: 'EGP',
		name: 'currency.currencies.EGP',
		symbol: '$',
		fullName: 'Egyptian Pound',
	},
	ERN: {
		code: 'ERN',
		name: 'currency.currencies.ERN',
		symbol: '$',
		fullName: 'Eritrean Nakfa',
	},
	ETB: {
		code: 'ETB',
		name: 'currency.currencies.ETB',
		symbol: '$',
		fullName: 'Ethiopian Birr',
	},
	EUR: {
		code: 'EUR',
		name: 'currency.currencies.EUR',
		symbol: '€',
		fullName: 'Euro',
	},
	FJD: {
		code: 'FJD',
		name: 'currency.currencies.FJD',
		symbol: '$',
		fullName: 'Fijian Dollar',
	},
	FKP: {
		code: 'FKP',
		name: 'currency.currencies.FKP',
		symbol: '$',
		fullName: 'Falkland Islands Pound',
	},
	GBP: {
		code: 'GBP',
		name: 'currency.currencies.GBP',
		symbol: '£',
		fullName: 'British Pound',
	},
	GEL: {
		code: 'GEL',
		name: 'currency.currencies.GEL',
		symbol: '$',
		fullName: 'Georgian Lari',
	},
	GGP: {
		code: 'GGP',
		name: 'currency.currencies.GGP',
		symbol: '$',
		fullName: 'Guernsey Pound',
	},
	GHS: {
		code: 'GHS',
		name: 'currency.currencies.GHS',
		symbol: '$',
		fullName: 'Ghanaian Cedi',
	},
	GIP: {
		code: 'GIP',
		name: 'currency.currencies.GIP',
		symbol: '£',
		fullName: 'Gibraltar Pound',
	},
	GMD: {
		code: 'GMD',
		name: 'currency.currencies.GMD',
		symbol: '$',
		fullName: 'Gambian Dalasi',
	},
	GNF: {
		code: 'GNF',
		name: 'currency.currencies.GNF',
		symbol: 'FG',
		fullName: 'Guinean Franc',
	},
	GTQ: {
		code: 'GTQ',
		name: 'currency.currencies.GTQ',
		symbol: 'Q',
		fullName: 'Guatemalan Quetzal',
	},
	GYD: {
		code: 'GYD',
		name: 'currency.currencies.GYD',
		symbol: '$',
		fullName: 'Guyanaese Dollar',
	},
	HKD: {
		code: 'HKD',
		name: 'currency.currencies.HKD',
		symbol: 'HK$',
		fullName: 'Hong Kong Dollar',
	},
	HNL: {
		code: 'HNL',
		name: 'currency.currencies.HNL',
		symbol: 'L',
		fullName: 'Honduran Lempira',
	},
	HRK: {
		code: 'HRK',
		name: 'currency.currencies.HRK',
		symbol: 'kn',
		fullName: 'Croatian Kuna',
	},
	HTG: {
		code: 'HTG',
		name: 'currency.currencies.HTG',
		symbol: 'G',
		fullName: 'Haitian Gourde',
	},
	HUF: {
		code: 'HUF',
		name: 'currency.currencies.HUF',
		symbol: 'Ft',
		fullName: 'Hungarian Forint',
	},
	IDR: {
		code: 'IDR',
		name: 'currency.currencies.IDR',
		symbol: 'Rp',
		fullName: 'Indonesian Rupiah',
	},
	ILS: {
		code: 'ILS',
		name: 'currency.currencies.ILS',
		symbol: '₪',
		fullName: 'Israeli New Shekel',
	},
	IMP: {
		code: 'IMP',
		name: 'currency.currencies.IMP',
		symbol: '$',
		fullName: 'Manx pound',
	},
	INR: {
		code: 'INR',
		name: 'currency.currencies.INR',
		symbol: '₹',
		fullName: 'Indian Rupee',
	},
	IQD: {
		code: 'IQD',
		name: 'currency.currencies.IQD',
		symbol: '$',
		fullName: 'Iraqi Dinar',
	},
	IRR: {
		code: 'IRR',
		name: 'currency.currencies.IRR',
		symbol: '$',
		fullName: 'Iranian Rial',
	},
	ISK: {
		code: 'ISK',
		name: 'currency.currencies.ISK',
		symbol: '$',
		fullName: 'Icelandic Kr\u00f3na',
	},
	JEP: {
		code: 'JEP',
		name: 'currency.currencies.JEP',
		symbol: '$',
		fullName: 'Jersey Pound',
	},
	JMD: {
		code: 'JMD',
		name: 'currency.currencies.JMD',
		symbol: '$',
		fullName: 'Jamaican Dollar',
	},
	JOD: {
		code: 'JOD',
		name: 'currency.currencies.JOD',
		symbol: '$',
		fullName: 'Jordanian Dinar',
	},
	JPY: {
		code: 'JPY',
		name: 'currency.currencies.JPY',
		symbol: 'JP¥',
		fullName: 'Japanese Yen',
	},
	KES: {
		code: 'KES',
		name: 'currency.currencies.KES',
		symbol: '$',
		fullName: 'Kenyan Shilling',
	},
	KGS: {
		code: 'KGS',
		name: 'currency.currencies.KGS',
		symbol: '$',
		fullName: 'Kyrgystani Som',
	},
	KHR: {
		code: 'KHR',
		name: 'currency.currencies.KHR',
		symbol: '៛',
		fullName: 'Cambodian Riel',
	},
	KMF: {
		code: 'KMF',
		name: 'currency.currencies.KMF',
		symbol: '$',
		fullName: 'Comorian Franc',
	},
	KPW: {
		code: 'KPW',
		name: 'currency.currencies.KPW',
		symbol: '₩',
		fullName: 'North Korean Won',
	},
	KRW: {
		code: 'KRW',
		name: 'currency.currencies.KRW',
		symbol: '₩',
		fullName: 'South Korean Won',
	},
	KWD: {
		code: 'KWD',
		name: 'currency.currencies.KWD',
		symbol: 'د.ك',
		fullName: 'Kuwaiti Dinar',
	},
	KYD: {
		code: 'KYD',
		name: 'currency.currencies.KYD',
		symbol: '$',
		fullName: 'Cayman Islands Dollar',
	},
	KZT: {
		code: 'KZT',
		name: 'currency.currencies.KZT',
		symbol: '$',
		fullName: 'Kazakhstani Tenge',
	},
	LAK: {
		code: 'LAK',
		name: 'currency.currencies.LAK',
		symbol: '₭',
		fullName: 'Laotian Kip',
	},
	LBP: {
		code: 'LBP',
		name: 'currency.currencies.LBP',
		symbol: '$',
		fullName: 'Lebanese Pound',
	},
	LKR: {
		code: 'LKR',
		name: 'currency.currencies.LKR',
		symbol: 'Rs',
		fullName: 'Sri Lankan Rupee',
	},
	LRD: {
		code: 'LRD',
		name: 'currency.currencies.LRD',
		symbol: '$',
		fullName: 'Liberian Dollar',
	},
	LSL: {
		code: 'LSL',
		name: 'currency.currencies.LSL',
		symbol: 'L',
		fullName: 'Lesotho Loti',
	},
	LTL: {
		code: 'LTL',
		name: 'currency.currencies.LTL',
		symbol: 'Lt',
		fullName: 'Lithuanian Litas',
	},
	LVL: {
		code: 'LVL',
		name: 'currency.currencies.LVL',
		symbol: 'Ls',
		fullName: 'Latvian Lats',
	},
	LYD: {
		code: 'LYD',
		name: 'currency.currencies.LYD',
		symbol: 'ل.د',
		fullName: 'Libyan Dinar',
	},
	MAD: {
		code: 'MAD',
		name: 'currency.currencies.MAD',
		symbol: 'د.م',
		fullName: 'Moroccan Dirham',
	},
	MDL: {
		code: 'MDL',
		name: 'currency.currencies.MDL',
		symbol: 'L',
		fullName: 'Moldovan Leu',
	},
	MGA: {
		code: 'MGA',
		name: 'currency.currencies.MGA',
		symbol: 'Ar',
		fullName: 'Malagasy Ariary',
	},
	MKD: {
		code: 'MKD',
		name: 'currency.currencies.MKD',
		symbol: 'ден',
		fullName: 'Macedonian Denar',
	},
	MMK: {
		code: 'MMK',
		name: 'currency.currencies.MMK',
		symbol: 'K',
		fullName: 'Myanma Kyat',
	},
	MNT: {
		code: 'MNT',
		name: 'currency.currencies.MNT',
		symbol: '₮',
		fullName: 'Mongolian Tugrik',
	},
	MOP: {
		code: 'MOP',
		name: 'currency.currencies.MOP',
		symbol: 'MOP$',
		fullName: 'Macanese Pataca',
	},
	MRO: {
		code: 'MRO',
		name: 'currency.currencies.MRO',
		symbol: 'UM',
		fullName: 'Mauritanian Ouguiya',
	},
	MUR: {
		code: 'MUR',
		name: 'currency.currencies.MUR',
		symbol: 'Rs',
		fullName: 'Mauritian Rupee',
	},
	MVR: {
		code: 'MVR',
		name: 'currency.currencies.MVR',
		symbol: 'Rf',
		fullName: 'Maldivian Rufiyaa',
	},
	MWK: {
		code: 'MWK',
		name: 'currency.currencies.MWK',
		symbol: 'MK',
		fullName: 'Malawian Kwacha',
	},
	MXN: {
		code: 'MXN',
		name: 'currency.currencies.MXN',
		symbol: 'MX$',
		fullName: 'Mexican Peso',
	},
	MYR: {
		code: 'MYR',
		name: 'currency.currencies.MYR',
		symbol: 'RM',
		fullName: 'Malaysian Ringgit',
	},
	MZN: {
		code: 'MZN',
		name: 'currency.currencies.MZN',
		symbol: 'MT',
		fullName: 'Mozambican Metical',
	},
	NAD: {
		code: 'NAD',
		name: 'currency.currencies.NAD',
		symbol: '$',
		fullName: 'Namibian Dollar',
	},
	NGN: {
		code: 'NGN',
		name: 'currency.currencies.NGN',
		symbol: '₦',
		fullName: 'Nigerian Naira',
	},
	NIO: {
		code: 'NIO',
		name: 'currency.currencies.NIO',
		symbol: 'C$',
		fullName: 'Nicaraguan C\u00f3rdoba',
	},
	NOK: {
		code: 'NOK',
		name: 'currency.currencies.NOK',
		symbol: 'kr',
		fullName: 'Norwegian Krone',
	},
	NPR: {
		code: 'NPR',
		name: 'currency.currencies.NPR',
		symbol: 'Rs',
		fullName: 'Nepalese Rupee',
	},
	NZD: {
		code: 'NZD',
		name: 'currency.currencies.NZD',
		symbol: '$',
		fullName: 'New Zealand Dollar',
	},
	OMR: {
		code: 'OMR',
		name: 'currency.currencies.OMR',
		symbol: '$',
		fullName: 'Omani Rial',
	},
	PAB: {
		code: 'PAB',
		name: 'currency.currencies.PAB',
		symbol: 'B/.',
		fullName: 'Panamanian Balboa',
	},
	PEN: {
		code: 'PEN',
		name: 'currency.currencies.PEN',
		symbol: 'S/.',
		fullName: 'Peruvian Nuevo Sol',
	},
	PGK: {
		code: 'PGK',
		name: 'currency.currencies.PGK',
		symbol: 'K',
		fullName: 'Papua New Guinean Kina',
	},
	PHP: {
		code: 'PHP',
		name: 'currency.currencies.PHP',
		symbol: '₱',
		fullName: 'Philippine Peso',
	},
	PKR: {
		code: 'PKR',
		name: 'currency.currencies.PKR',
		symbol: 'Rs',
		fullName: 'Pakistani Rupee',
	},
	PLN: {
		code: 'PLN',
		name: 'currency.currencies.PLN',
		symbol: 'zł',
		fullName: 'Polish Zloty',
	},
	PYG: {
		code: 'PYG',
		name: 'currency.currencies.PYG',
		symbol: '₲',
		fullName: 'Paraguayan Guarani',
	},
	QAR: {
		code: 'QAR',
		name: 'currency.currencies.QAR',
		symbol: 'ر.ق',
		fullName: 'Qatari Rial',
	},
	RON: {
		code: 'RON',
		name: 'currency.currencies.RON',
		symbol: 'lei',
		fullName: 'Romanian Leu',
	},
	RSD: {
		code: 'RSD',
		name: 'currency.currencies.RSD',
		symbol: 'дин',
		fullName: 'Serbian Dinar',
	},
	RUB: {
		code: 'RUB',
		name: 'currency.currencies.RUB',
		symbol: '$',
		fullName: 'Russian Ruble',
	},
	RWF: {
		code: 'RWF',
		name: 'currency.currencies.RWF',
		symbol: 'FRw',
		fullName: 'Rwandan Franc',
	},
	SAR: {
		code: 'SAR',
		name: 'currency.currencies.SAR',
		symbol: 'ر.س',
		fullName: 'Saudi Riyal',
	},
	SBD: {
		code: 'SBD',
		name: 'currency.currencies.SBD',
		symbol: '$',
		fullName: 'Solomon Islands Dollar',
	},
	SCR: {
		code: 'SCR',
		name: 'currency.currencies.SCR',
		symbol: 'Rs',
		fullName: 'Seychellois Rupee',
	},
	SDG: {
		code: 'SDG',
		name: 'currency.currencies.SDG',
		symbol: '£',
		fullName: 'Sudanese Pound',
	},
	SEK: {
		code: 'SEK',
		name: 'currency.currencies.SEK',
		symbol: 'kr',
		fullName: 'Swedish Krona',
	},
	SGD: {
		code: 'SGD',
		name: 'currency.currencies.SGD',
		symbol: '$',
		fullName: 'Singapore Dollar',
	},
	SHP: {
		code: 'SHP',
		name: 'currency.currencies.SHP',
		symbol: '£',
		fullName: 'Saint Helena Pound',
	},
	SLE: {
		code: 'SLE',
		name: 'currency.currencies.SLE',
		symbol: 'Le',
		fullName: 'Sierra Leonean Leone',
	},
	SLL: {
		code: 'SLL',
		name: 'currency.currencies.SLL',
		symbol: '$',
		fullName: 'Sierra Leonean Leone',
	},
	SOS: {
		code: 'SOS',
		name: 'currency.currencies.SOS',
		symbol: 'S',
		fullName: 'Somali Shilling',
	},
	SRD: {
		code: 'SRD',
		name: 'currency.currencies.SRD',
		symbol: '$',
		fullName: 'Surinamese Dollar',
	},
	STD: {
		code: 'STD',
		name: 'currency.currencies.STD',
		symbol: '$',
		fullName: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra',
	},
	SVC: {
		code: 'SVC',
		name: 'currency.currencies.SVC',
		symbol: '$',
		fullName: 'Salvadoran Col\u00f3n',
	},
	SYP: {
		code: 'SYP',
		name: 'currency.currencies.SYP',
		symbol: '£',
		fullName: 'Syrian Pound',
	},
	SZL: {
		code: 'SZL',
		name: 'currency.currencies.SZL',
		symbol: 'L',
		fullName: 'Swazi Lilangeni',
	},
	THB: {
		code: 'THB',
		name: 'currency.currencies.THB',
		symbol: '฿',
		fullName: 'Thai Baht',
	},
	TJS: {
		code: 'TJS',
		name: 'currency.currencies.TJS',
		symbol: 'SM',
		fullName: 'Tajikistani Somoni',
	},
	TMT: {
		code: 'TMT',
		name: 'currency.currencies.TMT',
		symbol: 'T',
		fullName: 'Turkmenistani Manat',
	},
	TND: {
		code: 'TND',
		name: 'currency.currencies.TND',
		symbol: 'د.ت',
		fullName: 'Tunisian Dinar',
	},
	TOP: {
		code: 'TOP',
		name: 'currency.currencies.TOP',
		symbol: 'T$',
		fullName: 'Tongan Pa\u02bbanga',
	},
	TRY: {
		code: 'TRY',
		name: 'currency.currencies.TRY',
		symbol: '$',
		fullName: 'Turkish Lira',
	},
	TTD: {
		code: 'TTD',
		name: 'currency.currencies.TTD',
		symbol: '$',
		fullName: 'Trinidad and Tobago Dollar',
	},
	TWD: {
		code: 'TWD',
		name: 'currency.currencies.TWD',
		symbol: 'NT$',
		fullName: 'New Taiwan Dollar',
	},
	TZS: {
		code: 'TZS',
		name: 'currency.currencies.TZS',
		symbol: '$',
		fullName: 'Tanzanian Shilling',
	},
	UAH: {
		code: 'UAH',
		name: 'currency.currencies.UAH',
		symbol: '₴',
		fullName: 'Ukrainian Hryvnia',
	},
	UGX: {
		code: 'UGX',
		name: 'currency.currencies.UGX',
		symbol: 'Ush',
		fullName: 'Ugandan Shilling',
	},
	USD: {
		code: 'USD',
		name: 'currency.currencies.USD',
		symbol: '$',
		fullName: 'United States Dollar',
	},
	UYU: {
		code: 'UYU',
		name: 'currency.currencies.UYU',
		symbol: '$U',
		fullName: 'Uruguayan Peso',
	},
	UZS: {
		code: 'UZS',
		name: 'currency.currencies.UZS',
		symbol: 'лв',
		fullName: 'Uzbekistan Som',
	},
	VEF: {
		code: 'VEF',
		name: 'currency.currencies.VEF',
		symbol: 'Bs.S.',
		fullName: 'Venezuelan Bol\u00edvar Fuerte',
	},
	VES: {
		code: 'VES',
		name: 'currency.currencies.VES',
		symbol: '$',
		fullName: 'Sovereign Bolivar',
	},
	VND: {
		code: 'VND',
		name: 'currency.currencies.VND',
		symbol: '₫',
		fullName: 'Vietnamese Dong',
	},
	VUV: {
		code: 'VUV',
		name: 'currency.currencies.VUV',
		symbol: 'VT',
		fullName: 'Vanuatu Vatu',
	},
	WST: {
		code: 'WST',
		name: 'currency.currencies.WST',
		symbol: 'T',
		fullName: 'Samoan Tala',
	},
	XAF: {
		code: 'XAF',
		name: 'currency.currencies.XAF',
		symbol: 'FCFA',
		fullName: 'CFA Franc BEAC',
	},
	XCD: {
		code: 'XCD',
		name: 'currency.currencies.XCD',
		symbol: '$',
		fullName: 'East Caribbean Dollar',
	},
	XOF: {
		code: 'XOF',
		name: 'currency.currencies.XOF',
		symbol: 'CFA',
		fullName: 'CFA Franc BCEAO',
	},
	XPF: {
		code: 'XPF',
		name: 'currency.currencies.XPF',
		symbol: '$',
		fullName: 'CFP Franc',
	},
	YER: {
		code: 'YER',
		name: 'currency.currencies.YER',
		symbol: '﷼',
		fullName: 'Yemeni Rial',
	},
	ZAR: {
		code: 'ZAR',
		name: 'currency.currencies.ZAR',
		symbol: 'R',
		fullName: 'South African Rand',
	},
	ZMW: {
		code: 'ZMW',
		name: 'currency.currencies.ZMW',
		symbol: 'ZK',
		fullName: 'Zambian Kwacha',
	},
	ZWL: {
		code: 'ZWL',
		name: 'currency.currencies.ZWL',
		symbol: 'Z$',
		fullName: 'Zimbabwean Dollar',
	},
}

export type CurrencyCodes = keyof typeof CURRENCIES

export const CURRENCY_CODES = Object.keys(CURRENCIES)
