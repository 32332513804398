import { useState, ReactNode, isValidElement, forwardRef } from 'react'
import { isNotNil } from '@persuit/common-utils'
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material'

import { InputAdornment } from '../input-adornment'
import { Box } from '../box'
import { Button } from '../button'
import { Divider } from '../divider'
import { TextField, TextFieldProps } from '../text-field'
import { List, ListItemButton } from '../list'
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from '../../icons'

import { Autocomplete, AutocompleteProps } from './autocomplete'
import { useDescription } from '@persuit/ui-hooks'

export type AutocompleteMultiSelectProps<T> = Pick<
	AutocompleteProps<T, true, undefined, undefined>,
	'sx' | 'style' | 'slotProps'
> & {
	variant?: TextFieldProps['variant']
	getDisabled?: (option: T) => boolean
	label: string
	options: (T | 'divider')[]
	value: string[]
	onChange: (
		selectedIds: string[],
		reason: AutocompleteChangeReason | 'selectAll' | 'unselectAll',
		details?: AutocompleteChangeDetails<T>,
	) => void
	startAdornment?: ReactNode
	startIcon?: ReactNode
	selectedLabel?: string
	getSelectedLabel?: (value: string[]) => string
	endAdornment?: ReactNode
	endIcon?: ReactNode
	getLabel: (option: T) => string
	getValue: (option: T) => string
	placeholder?: string
	enableSelectAll?: boolean
	error?: boolean
	helperText?: string
}

const defaultSelectedLabel = (value: unknown[], label: string = 'selected'): string => {
	return `${value.length} ${label}`
}

export const AutocompleteMultiSelect = <T,>({
	enableSelectAll,
	placeholder,
	endIcon,
	startIcon,
	selectedLabel = 'selected',
	getSelectedLabel,
	variant = 'outlined',
	getDisabled = () => false,
	getLabel,
	getValue,
	options,
	onChange,
	value,
	label,
	startAdornment,
	endAdornment,
	error,
	helperText,
	...rest
}: AutocompleteMultiSelectProps<T>) => {
	const [open, setOpen] = useState(false)

	const selectableOptions = options.filter((x): x is T => x !== 'divider')

	const selectedOptions: T[] = value
		.map((val) => selectableOptions.find((option) => getValue(option) === val))
		.filter(isNotNil)

	const selectedText = getSelectedLabel
		? getSelectedLabel(value)
		: defaultSelectedLabel(value, selectedLabel)

	const id = useDescription(selectedText)

	const noneSelected = !selectableOptions.every((opt) =>
		selectedOptions.find((selected) => getValue(selected) === getValue(opt)),
	)
	const selectAll = () => onChange(selectableOptions.map(getValue), 'selectAll', undefined)
	const unselectAll = () => onChange([], 'unselectAll', undefined)

	return (
		<Autocomplete
			disableCloseOnSelect={true}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			multiple={true}
			options={options as T[]}
			value={selectedOptions}
			disabledItemsFocusable={true}
			onChange={(_, value, reason, details) => onChange(value.map(getValue), reason, details)}
			renderTags={() => (open ? null : <>{selectedText}</>)}
			getOptionDisabled={getDisabled}
			renderOption={(props, option: T | 'divider', state) => {
				if (option === 'divider') {
					return <Divider />
				}

				const isDisabled = getDisabled(option)
				return (
					<ListItemButton component="li" {...props}>
						<Box mr={1} sx={{ display: 'flex', alignItems: 'center' }}>
							{state.selected ? (
								<CheckBoxIcon color={isDisabled ? 'disabled' : 'primary'} />
							) : (
								<CheckBoxOutlineBlankIcon color={isDisabled ? 'disabled' : 'primary'} />
							)}
						</Box>

						{getLabel(option)}
					</ListItemButton>
				)
			}}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder={placeholder}
						variant={variant}
						label={label}
						autoComplete="off"
						error={error}
						helperText={helperText}
						InputProps={{
							'aria-describedby': id,
							...params.InputProps,
							startAdornment:
								startAdornment ?? (startIcon || params.InputProps.startAdornment) ? (
									<>
										{startIcon}
										{params.InputProps.startAdornment}
									</>
								) : null,
							endAdornment:
								endAdornment ?? (endIcon || params.InputProps.endAdornment) ? (
									<InputAdornment position="end" style={{ position: 'absolute', right: '9px' }}>
										{value.length > 0 ? endIcon : null}
										{(isValidElement(params.InputProps.endAdornment) &&
											params.InputProps.endAdornment?.props?.children?.[1]) ??
											null}
									</InputAdornment>
								) : null,
						}}
					/>
				)
			}}
			getOptionLabel={getLabel}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			ListboxProps={{ noneSelected, selectAll, unselectAll } as any}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			ListboxComponent={enableSelectAll ? (ListBox as any) : undefined}
			{...rest}
		/>
	)
}

type ListBoxProps = {
	children: ReactNode
	noneSelected: boolean
	selectAll: () => void
	unselectAll: () => void
}

const ListBox = forwardRef<HTMLUListElement, ListBoxProps>(
	({ children, noneSelected, selectAll, unselectAll, ...rest }, ref) => {
		const containerProps = {
			...rest,
			'aria-multiselectable': true,
		}

		return (
			// Need to attach onMouseDown handler to prevent popup from closing when popover is clicked
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<Box position="relative" onMouseDown={(rest as any).onMouseDown}>
				<Box
					position="sticky"
					top={0}
					zIndex={900}
					bgcolor="white"
					height={32}
					aria-hidden={'true'}
				>
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<Button
							size="small"
							color="primary"
							onClick={noneSelected ? selectAll : unselectAll}
							aria-hidden={'true'}
							tabIndex={-1}
						>
							{noneSelected ? 'Select' : 'Unselect'} All
						</Button>
					</Box>
					<Divider />
				</Box>

				<List {...containerProps} ref={ref}>
					{children}
				</List>
			</Box>
		)
	},
)
