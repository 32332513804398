import { Typography, Box, useTheme, Divider, PopoverHelp } from '@persuit/ui-components'
import { Delta } from '../delta'
import { useStore } from '../../store'
import { formatPriceWithCurrency } from '@persuit/common-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

export const AverageRateBanner = () => {
	const theme = useTheme()
	const { toggles } = useFeatureToggles()

	const isFeatureEnabled = toggles['dev-9900.rate-card-auction-improvements']

	const {
		currency,
		currentAverageRate,
		originalAverageRate,
		hasRatesItem,
		comparisonValue,
		totalPriceNotRequired,
	} = useStore(({ currency, totalPriceNotRequired, proposal: { comparisonValue } }, selectors) => ({
		currency,
		currentAverageRate: selectors.currentAverageRate(),
		originalAverageRate: selectors.originalAverageRate(),
		hasRatesItem: selectors.hasRatesItem(),
		totalPriceNotRequired,
		comparisonValue,
	}))

	if (!isFeatureEnabled) {
		return null
	}

	const shouldUseAverageRateForRanking = totalPriceNotRequired && !comparisonValue && hasRatesItem

	return (
		<Box
			display="flex"
			border={`1px solid ${theme.palette.primary.main}`}
			bgcolor={theme.palette.primary.lightestHue}
			padding={3}
			justifyContent="center"
		>
			<Box display="flex" width={'800px'}>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems={'flex-end'}
					marginRight={3}
					flex={1}
				>
					<Box display="flex" alignItems="center">
						<Typography
							variant="h4"
							component="p"
							my={shouldUseAverageRateForRanking ? undefined : 0.5}
						>
							Average of all rates
						</Typography>
						{shouldUseAverageRateForRanking && (
							<PopoverHelp
								role="tooltip"
								content={
									<div>
										<Typography variant="caption" sx={{ fontWeight: 'bold' }}>
											This request uses the average of all rates for price ranking.
										</Typography>
										<br />
										<Typography variant="caption">
											When the total price is not requested and only rates are requested, the price
											ranking will be determined by the average of all provided rates.
										</Typography>
									</div>
								}
								triggerButtonProps={{
									'aria-label': 'Average of all rates help',
								}}
								triggerButtonSize="small"
							/>
						)}
					</Box>
					<Typography variant="h1XSmall" component="p">
						{formatPriceWithCurrency(currency, currentAverageRate)}
					</Typography>
					<Typography variant="body2">This is the average of all rates provided below</Typography>
				</Box>
				<Divider orientation="vertical" flexItem={true} sx={{ borderColor: theme.palette.black }} />
				<Box
					marginLeft={3}
					flex={1}
					alignItems={'flex-start'}
					flexDirection="column"
					justifyContent="center"
				>
					<Box display="flex" alignItems="center" height="34px">
						<Typography variant="h4" component="p">
							Value change
						</Typography>
					</Box>
					<Delta
						newNumber={currentAverageRate ?? 0}
						originalNumber={originalAverageRate ?? 0}
						currency={'AUD'}
						showZero={true}
						typographyProps={{
							fontSize: theme.typography.h1XSmall.fontSize,
							lineHeight: theme.typography.h1XSmall.lineHeight,
							fontWeight: theme.typography.h1XSmall.fontWeight,
							letterSpacing: theme.typography.h1XSmall.letterSpacing,
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}
