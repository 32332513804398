import { useState, ReactNode } from 'react'
import * as React from 'react'
import { Menu, MenuProps } from '../menu'

type ControlRenderProps = {
	setAnchorEl: (el: HTMLElement | React.MouseEvent<HTMLElement>) => void
}

export type MenuControlProps = {
	control: (props: ControlRenderProps) => ReactNode
	children: ReactNode
	open?: boolean
	closeOnClick?: boolean
} & Omit<MenuProps, 'open'>

export const MenuControl = ({
	children,
	control,
	onClose,
	open,
	onClick,
	closeOnClick,
	...rest
}: MenuControlProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

	return (
		<>
			{control({
				setAnchorEl: (e) => setAnchorEl((e as React.MouseEvent<HTMLElement>)?.currentTarget ?? e),
			})}

			<Menu
				anchorEl={anchorEl}
				{...rest}
				open={open ?? !!anchorEl}
				onKeyDown={(e) => {
					if (e.key === 'Enter' && closeOnClick) {
						setAnchorEl(null)
					}
				}}
				onClick={(e) => {
					onClick?.(e)
					if (closeOnClick) {
						setAnchorEl(null)
					}
				}}
				onClose={(...args) => {
					onClose?.(...args)
					setAnchorEl(null)
				}}
			>
				{children}
			</Menu>
		</>
	)
}
