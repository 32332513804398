import { useState, useEffect, useRef } from 'react'
import { useStore, useActions } from './store'

/* Make the compare table look better for printing
 * Expand out the sections and cells
 * Use the browser before and after print events
 */

/** Attaches effects that listen to print events, rendered as component to prevent rerenders of the parent component */
export const PrintEffects = () => {
	const { displayedResponses } = useStore((state, s) => ({
		displayedResponses: s.displayedResponses(),
	}))

	const currentState = useStore((state) => ({
		showScorecardSection: state.showScorecardSection,
		showPricingSection: state.showPricingSection,
		showQuestionsSection: state.showQuestionsSection,
		showDiversitySection: state.showDiversitySection,
	}))

	const currentStateRef = useRef(currentState)
	currentStateRef.current = currentState

	const beforeStateRef = useRef(currentState)

	const someProposalsHaveScores = displayedResponses.some(({ scorecard }) =>
		scorecard ? scorecard.length > 0 : false,
	)

	const printModeActive = useRef(false)

	const {
		setTruncateCellContents,
		toggleShowScorecardSection,
		toggleShowDiversitySection,
		toggleShowQuestionsSection,
		toggleshowPricingSection,
	} = useActions()

	// Before and after print event listeners
	useEffect(() => {
		function beforePrintHandler() {
			// Sometimes the print handler can be executed multiple times.
			// This check prevents the actual handler code from running twice
			if (printModeActive.current) {
				return
			}

			// Store the pre-print expand/collapse states
			beforeStateRef.current = currentStateRef.current

			// Show the scorecard section
			// Only show this row if there are scores on 1 or more proposals
			if (someProposalsHaveScores) {
				toggleShowScorecardSection(true)
			}

			// Expand all the other sections
			toggleShowDiversitySection(true)
			toggleShowQuestionsSection(true)
			toggleshowPricingSection(true)

			// Set all cells to show full contents without truncation
			setTruncateCellContents(false)

			printModeActive.current = true
		}

		window.addEventListener('beforeprint', beforePrintHandler)
		return () => window.removeEventListener('beforeprint', beforePrintHandler)
	}, [someProposalsHaveScores])

	useEffect(() => {
		function afterPrintHandler() {
			const before = beforeStateRef.current
			// Revert the section expand/collapse state back to how it was before the print
			toggleShowScorecardSection(before.showScorecardSection)
			toggleShowDiversitySection(before.showDiversitySection)
			toggleShowQuestionsSection(before.showQuestionsSection)
			toggleshowPricingSection(before.showPricingSection)

			// Set all cells to back to truncated state
			setTruncateCellContents(true)

			printModeActive.current = false
		}

		window.addEventListener('afterprint', afterPrintHandler)
		return () => window.removeEventListener('afterprint', afterPrintHandler)
	}, [])

	return null
}

export const usePrint = () => {
	const [printScheduled, setPrintScheduled] = useState(false)

	useEffect(() => {
		if (printScheduled) {
			setPrintScheduled(false)
			window.print()
		}
	}, [printScheduled])

	const print = () => {
		// Dirty hack due to Chrome bug
		// Chrome triggers the `beforeprint` event too late when using `window.print()`
		// So we're explicitly running our before print handler here
		// This is safe to run in other browsers too. beforePrintHandler is idempotent
		window.dispatchEvent(new Event('beforeprint'))
		// schedule print to ensure state is updated before running window.print()
		setPrintScheduled(true)
	}

	return { print }
}
