import { cond, constant } from 'lodash/fp'

import constants from '../../../../common/data/pricing-models'

const shouldShowClientLabel = ({ rate }) => Boolean(rate.rateId)
const shouldRedactFirmLabel = ({ isCompetitor, pricingModel }) =>
	isCompetitor && pricingModel === constants.RATECARD
const otherwise = constant(true)

const showClientLabel = ({ requestedRate }) => requestedRate.label
const showFirmLabel = ({ rate }) => rate.label
const showRedactedLabel = ({ redactedRateLabel }) => redactedRateLabel

const getRateCardLabel = cond([
	[shouldShowClientLabel, showClientLabel],
	[shouldRedactFirmLabel, showRedactedLabel],
	[otherwise, showFirmLabel],
])

export default getRateCardLabel
