import { useRef, useEffect } from 'react'
import { BoxProps, Box } from '../box'
import { debounce } from 'lodash'

export type ScrollShadowProps = BoxProps & {
	shadowSize?: number
	// The container that is scrolling to be used for calculations
	scrollingElement?: Element | null
	disableTopShadow?: boolean
	disableBottomShadow?: boolean
	disableLeftShadow?: boolean
	disableRightShadow?: boolean
	// Allow some margin of error when calculating whether the "end" of scrolling has been reached
	tolerance?: number
}

export const ScrollShadows = ({
	tolerance = 1,
	disableBottomShadow,
	disableLeftShadow,
	disableRightShadow,
	disableTopShadow,
	scrollingElement,
	shadowSize = 16,
	children,
	...rest
}: ScrollShadowProps) => {
	const containerRef = useRef<HTMLElement>(null)
	const topRef = useRef<HTMLElement>(null)
	const bottomRef = useRef<HTMLElement>(null)
	const leftRef = useRef<HTMLElement>(null)
	const rightRef = useRef<HTMLElement>(null)

	useEffect(() => {
		const container = containerRef.current
		const calculationContainer = scrollingElement ?? container

		const topShadow = topRef.current
		const bottomShadow = bottomRef.current
		const leftShadow = leftRef.current
		const rightShadow = rightRef.current

		function onScrollListener() {
			if (
				!calculationContainer ||
				!container ||
				!topShadow ||
				!leftShadow ||
				!rightShadow ||
				!bottomShadow
			)
				return

			const { bottom, left, right, top } = getSidesWithShadow(calculationContainer, tolerance)

			const { scrollLeft, scrollTop } = container

			function translate(el: HTMLElement) {
				el.style.transform = `translate(${scrollLeft}px, ${scrollTop}px)`
			}

			translate(topShadow)
			translate(bottomShadow)
			translate(leftShadow)
			translate(rightShadow)

			topShadow.style.opacity = top ? '1' : '0'
			bottomShadow.style.opacity = bottom ? '1' : '0'
			rightShadow.style.opacity = right ? '1' : '0'
			leftShadow.style.opacity = left ? '1' : '0'
		}

		onScrollListener()

		calculationContainer?.addEventListener('scroll', onScrollListener)
		calculationContainer?.addEventListener('resize', onScrollListener)

		const observer = new ResizeObserver(debounce(onScrollListener, 100))

		if (calculationContainer) {
			observer.observe(calculationContainer)
		}

		return () => {
			calculationContainer?.removeEventListener('scroll', onScrollListener)
			observer.disconnect()
		}
	}, [scrollingElement, tolerance])

	function shadow(angle: number) {
		return `linear-gradient(${angle}deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)`
	}

	const commonStyles = {
		transition: 'opacity 100ms',
		position: 'absolute',
		zIndex: 100,
		opacity: 0,
		pointerEvents: 'none',
	}
	const size = `${shadowSize}px`

	return (
		<Box {...rest} ref={containerRef} position="relative">
			<Box
				ref={topRef}
				aria-hidden={true}
				sx={{
					...commonStyles,
					display: disableTopShadow ? 'none' : 'block',
					top: 0,
					width: '100%',
					height: size,
					background: shadow(180),
				}}
			/>

			<Box
				ref={bottomRef}
				aria-hidden={true}
				sx={{
					...commonStyles,
					display: disableBottomShadow ? 'none' : 'block',
					bottom: 0,
					width: '100%',
					height: size,
					background: shadow(0),
				}}
			/>

			<Box
				ref={leftRef}
				aria-hidden={true}
				sx={{
					...commonStyles,
					display: disableLeftShadow ? 'none' : 'block',
					left: 0,
					height: '100%',
					width: size,
					background: shadow(90),
				}}
			/>

			<Box
				ref={rightRef}
				aria-hidden={true}
				sx={{
					...commonStyles,
					display: disableRightShadow ? 'none' : 'block',
					right: 0,
					height: '100%',
					width: size,
					background: shadow(270),
				}}
			/>

			{children}
		</Box>
	)
}

type GetSidesWithShadowProps = {
	scrollTop: number
	scrollLeft: number
	clientHeight: number
	scrollHeight: number
	clientWidth: number
	scrollWidth: number
}

export function getSidesWithShadow(
	{
		scrollTop,
		scrollLeft,
		clientHeight,
		scrollHeight,
		clientWidth,
		scrollWidth,
	}: GetSidesWithShadowProps,
	tolerance: number = 0,
) {
	return {
		bottom: Math.ceil(clientHeight) + tolerance < scrollHeight - scrollTop,
		top: scrollTop > 0,

		right: Math.ceil(clientWidth) + tolerance < scrollWidth - scrollLeft,
		left: scrollLeft > 0,
	}
}
