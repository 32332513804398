import { Component } from 'react'
import classnames from 'classnames'

import baseTheme from '../../theme/persuit-theme'
import injectSheet from '../../injectSheet'

const { desktopGutter, desktopMq, mobileGutter } = baseTheme.grid

const styles = {
	row: {
		display: 'flex',
		flexFlow: 'row wrap',
		marginLeft: `-${mobileGutter / 2}px`,
		marginRight: `-${mobileGutter / 2}px`,
		alignItems: 'stretch',
		justifyContent: 'flex-start',
		[`${desktopMq}`]: {
			marginLeft: `-${desktopGutter / 2}px`,
			marginRight: `-${desktopGutter / 2}px`,
		},
	},
}

Object.keys(baseTheme.grid.breakpoints).forEach((breakpoint) => {
	const { minWidth } = baseTheme.grid.breakpoints[breakpoint]

	styles[`${breakpoint}-row`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexDirection: 'row',
		},
	}

	styles[`${breakpoint}-column`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexDirection: 'column',
		},
	}

	styles[`${breakpoint}-wrap`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexWrap: 'wrap',
		},
	}

	styles[`${breakpoint}-nowrap`] = {
		[`@media (min-width: ${minWidth})`]: {
			flexWrap: 'nowrap',
		},
	}
})

class Row extends Component {
	static defaultProps = {
		component: 'div',
	}

	render() {
		const { children, className, component, classes, style } = this.props

		const Component = component

		const breakpointStyles = Object.keys(baseTheme.grid.breakpoints).reduce((memo, breakpoint) => {
			memo[classes[`${breakpoint}-column`]] = this.props[`${breakpoint}Direction`] === 'column'
			memo[classes[`${breakpoint}-row`]] = this.props[`${breakpoint}Direction`] === 'row'
			memo[classes[`${breakpoint}-wrap`]] = !!this.props[`${breakpoint}Wrap`]
			memo[classes[`${breakpoint}-nowrap`]] =
				this.props.hasOwnProperty(`${breakpoint}Wrap`) && !this.props[`${breakpoint}Wrap`]
			return memo
		}, {})

		const classNames = classnames(classes.row, className, breakpointStyles)

		return (
			<Component className={classNames} style={style || {}}>
				{children}
			</Component>
		)
	}
}

export default injectSheet(styles)(Row)
