const states = {
	DRAFT: 'draft',
	FINALIZED: 'finalized',
	CLOSED: 'closed',
	PENDING: 'pending',
	COMPLETED: 'completed',
	UNDER_REVISION: 'underRevision',
	DRAFT_REVISION: 'draftRevision',
	WITHDRAWN: 'withdrawn',
} as const

export type RfpStates = (typeof states)[keyof typeof states]

export const allowedActions = {
	save: [states.DRAFT],
	clarification: [states.FINALIZED, states.CLOSED, states.COMPLETED],
	publish: [states.DRAFT],
	close: [states.FINALIZED, states.COMPLETED],
	changeEndDate: [states.FINALIZED],
	reOpenRfpChangeEndDate: [states.CLOSED],
	completed: [states.CLOSED, states.FINALIZED],
	reOpenFirm: [states.CLOSED],
	reCloseFirm: [states.CLOSED],
	revise: [states.FINALIZED],
	withdraw: [states.FINALIZED, states.CLOSED, states.UNDER_REVISION, states.DRAFT_REVISION],
} as const

export const values = Object.values(states)

export default states
