// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	"Select this firm's proposal (you can select more than one).",
	'Choose who receives notifications in the next step.',
]

const priceNotUpdatedText = [
	'This firm cannot be selected until a Total Price has been supplied by the firm.',
]

function OptionSelectFirmStart({ onNext, disableSelectFirmRadio, isPendingPrice }) {
	const displayBullets = isPendingPrice ? priceNotUpdatedText : bullets

	return (
		<DialogWizardListOption
			dataTestid="accept-proposal-option"
			icon={<ThumbUpAltIcon />}
			primaryText="Select firm"
			bullets={displayBullets}
			onClick={onNext}
			disabled={disableSelectFirmRadio}
		/>
	)
}

export default OptionSelectFirmStart
