import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardDraftRequestProps = ProgressCardProps

export const CARD_TITLE = 'Draft request and invite firm contacts'

export const ProgressCardDraftRequest = ({
	index,
	step,
	extraData,
}: ProgressCardDraftRequestProps) => {
	const isRateReview = extraData.useCase === 'rateReview'
	const HELP_TEXT = isRateReview
		? 'Add a summary, timekeeper levels, questions and firm contacts to your request and then send. PERSUIT allows you to invite as many firms as required.'
		: `Draft your summary, pricing items, questions, add firm contacts and then send! PERSUIT can support as many firm invitations as you'd like.`

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				helpText={HELP_TEXT}
				guidanceText={
					isRateReview
						? 'For the best outcomes with a rate review request, consider inviting all firms you work with. A single request can support as many as 500 firms invited.'
						: 'For RFPs, we recommend inviting 4-6 firms to get the best outcome.'
				}
			/>
		)

	if (step.status === 'completed') {
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				helpText={HELP_TEXT}
				subtitle={`Invited ${extraData.totalFirmsInvited} firms`}
			/>
		)
	}

	return <BaseCard title={CARD_TITLE} index={index} status={step.status} helpText={HELP_TEXT} />
}
