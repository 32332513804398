const eventLabels = [
	'manage_proposal::close_request_for_firm',
	'manage_proposal::mark_firm_successful',
	'manage_proposal::ask_firm_revise_proposal',
	'manage_proposal::mark_firm_successful_eliminate_others',
	'manage_proposal::undo_successful',
	'manage_proposal::unselect_proposal',
] as const
type EventLabel = (typeof eventLabels)[number]

export const trackWithPendo = (label: EventLabel, trackingData: Record<string, unknown>) => {
	if (typeof window?.pendo?.track === 'function') {
		window.pendo.track(label, trackingData)
	} else {
		console.info('Pendo not available to track events')
	}
}
