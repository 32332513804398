import { GridColDef, GridRenderCellParams } from '@persuit/ui-components'
import { isNotNil } from '@persuit/common-utils'
import { useStore } from '../../store'
import { renderPercentage, renderPrice } from '@persuit/ui-shared-components'
import { CommentText } from './comment-text'
import numeral from 'numeral'

const RenderPriceCell = (
	params: GridRenderCellParams & { isMultiCurrencyToggleEnabled: boolean },
) => {
	const { value, row, isMultiCurrencyToggleEnabled } = params
	const { currency: storeCurrency } = useStore((state) => ({
		currency: state.rfp.rateReview?.currency,
	}))
	const currency = isMultiCurrencyToggleEnabled ? row.currency : storeCurrency
	const formattedValue = renderPrice(value, currency ?? '')
	return <div>{formattedValue}</div>
}

const RenderDiscountFromStandardCell = (params: GridRenderCellParams) => {
	const { value } = params
	const formattedValue = renderPercentage(value)
	return <div>{formattedValue}</div>
}

const RenderChangeFromLastYearCell = (params: GridRenderCellParams) => {
	const { value } = params
	const formattedValue = renderPercentage(value, true)
	return <div>{formattedValue}</div>
}

const RenderLastYearHoursCell = (params: GridRenderCellParams) => {
	const formattedValue = params.value ? numeral(params.value).format('0,0') : '-'
	return <div>{formattedValue}</div>
}

const RenderValueOrNil = (params: GridRenderCellParams) => (!params.value ? '-' : params.value)

export type ColumnField =
	| 'timekeeperLevel'
	| 'firstName'
	| 'lastName'
	| 'location'
	| 'graduationYear'
	| 'lastYearsHours'
	| 'currency'
	| 'currentRate'
	| 'newStandardRate'
	| 'proposedRate'
	| 'discountFromStandard'
	| 'changeFromLastYear'
	| 'spendImpact'
	| 'timekeeperId'
	| 'comment'
export type TypedGridColDef = GridColDef & { field: ColumnField }

type Options = {
	isMultiCurrencyToggleEnabled: boolean
}

export const getInitialColumns = ({ isMultiCurrencyToggleEnabled }: Options): TypedGridColDef[] => {
	return (
		[
			{
				field: 'timekeeperLevel',
				headerName: 'Timekeeper level',
				width: 120,
				renderCell: RenderValueOrNil,
				hideable: false,
			},
			{ field: 'firstName', headerName: 'First name', width: 130, renderCell: RenderValueOrNil },
			{ field: 'lastName', headerName: 'Last name', width: 130, renderCell: RenderValueOrNil },
			{ field: 'location', headerName: 'Location', width: 130, renderCell: RenderValueOrNil },
			{
				field: 'graduationYear',
				headerName: 'Graduation year',
				width: 80,
				type: 'string',
				align: 'center',
				renderCell: RenderValueOrNil,
			},
			{
				field: 'lastYearsHours',
				headerName: "Last year's hours",
				width: 160,
				type: 'number',
				align: 'center',
				renderCell: RenderLastYearHoursCell,
			},
			isMultiCurrencyToggleEnabled
				? {
						field: 'currency',
						headerName: 'Currency',
						width: 160,
						type: 'string',
						renderCell: (params) => params.row.currency,
				  }
				: null,
			{
				field: 'currentRate',
				headerName: 'Current rate',
				width: 150,
				type: 'number',
				renderCell: (params) => (
					<RenderPriceCell
						{...params}
						isMultiCurrencyToggleEnabled={isMultiCurrencyToggleEnabled}
					/>
				),
			},
			{
				field: 'newStandardRate',
				headerName: 'New standard rate',
				width: 180,
				type: 'number',
				renderCell: (params) => (
					<RenderPriceCell
						{...params}
						isMultiCurrencyToggleEnabled={isMultiCurrencyToggleEnabled}
					/>
				),
			},
			{
				field: 'proposedRate',
				headerName: 'Proposed rate',
				width: 180,
				type: 'number',
				renderCell: (params) => (
					<RenderPriceCell
						{...params}
						isMultiCurrencyToggleEnabled={isMultiCurrencyToggleEnabled}
					/>
				),
			},
			{
				field: 'discountFromStandard',
				headerName: 'Discount from standard',
				width: 180,
				type: 'number',
				align: 'center',
				renderCell: RenderDiscountFromStandardCell,
			},
			{
				field: 'changeFromLastYear',
				headerName: 'Change from last year',
				width: 180,
				type: 'number',
				align: 'center',
				renderCell: RenderChangeFromLastYearCell,
			},
			{
				field: 'spendImpact',
				headerName: 'Spend impact',
				width: 180,
				type: 'number',
				renderCell: (params) => (
					<RenderPriceCell
						{...params}
						isMultiCurrencyToggleEnabled={isMultiCurrencyToggleEnabled}
					/>
				),
			},
			{
				field: 'timekeeperId',
				headerName: 'Timekeeper ID',
				width: 100,
				type: 'string',
				align: 'center',
				sortable: false,
				renderCell: RenderValueOrNil,
			},
			{
				field: 'comment',
				headerName: 'Comments',
				width: 200,
				renderCell: CommentText,
				sortable: false,
			},
		] as TypedGridColDef[]
	).filter(isNotNil)
}
