import { format } from 'date-fns'
import { map, size } from 'lodash/fp'

import { styled, Table, TableCell, TableHead, TableRow, TableBody } from '@persuit/ui-components'
import formatPriceCurrency from '../../../../utils/price-and-currency'

const getFormattedPrice = (price, currency) => {
	if (!price) {
		return '-'
	}

	return formatPriceCurrency(currency, price)
}

const StyledTableCell = styled(TableCell)`
	&.MuiTableCell-root {
		border: none;
	}
`

const PriceHistoryTableRow = ({
	priceHistory: { totalPriceValue, createdAt, draftValue },
	currency,
}) => {
	if (draftValue) {
		return null
	}

	const formattedTotalPrice = getFormattedPrice(totalPriceValue, currency)
	const formattedUpdateAt = format(createdAt, 'dd MMM yyyy, hh:mma')

	return (
		<TableRow>
			<StyledTableCell data-testid="proposal-price-history">{formattedTotalPrice}</StyledTableCell>
			<StyledTableCell>{formattedUpdateAt}</StyledTableCell>
		</TableRow>
	)
}

const TotalPriceHistoryTable = ({ priceHistories, currency }) => {
	if (size(priceHistories) === 0) {
		return null
	}

	return (
		<Table size="small">
			<caption>Total price history</caption>
			<TableHead>
				<TableRow>
					<TableCell>Price</TableCell>
					<TableCell>Last updated</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(
					(priceHistory) => (
						<PriceHistoryTableRow
							key={priceHistory._id}
							priceHistory={priceHistory}
							currency={currency}
						/>
					),
					priceHistories,
				)}
			</TableBody>
		</Table>
	)
}

export default TotalPriceHistoryTable
