import { Component } from 'react'
import { styled, IconButton, CancelIcon, HelpOutlineIcon } from '@persuit/ui-components'
import injectSheet from '../../injectSheet'
import Text from '../text.jsx'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import withTheme from '@mui/styles/withTheme'

const StyledInfoHelpContainer = styled('div')`
	background: ${(props) => props.theme.palette.primary.lighterHue};
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
`

class InlineHelp extends Component {
	static defaultProps = {
		disable: false,
	}

	static styles = (theme) => ({
		component: {
			width: '100%',
		},

		row: {
			display: 'flex',
			flexFlow: 'row nowrap',
			justifyContent: 'flex-start',
			width: '100%',
			flex: '0 0 100%',
		},

		toggle: {
			flex: '0 0 auto',
		},

		messageContainer: {
			display: 'flex',
			flexFlow: 'row nowrap',
			alignItems: 'flex-start',
			borderStyle: 'solid',
			borderColor: theme.palette.primary.main,
			borderWidth: '1px',
			color: theme.palette.primary.main,
			borderRadius: '3px',
		},

		icon: {
			color: `${theme.palette.primary.main} !important`,
		},

		messageIcon: {
			color: `${theme.palette.primary.main} !important`,
		},
	})

	constructor(props) {
		super(props)
		this.state = { isOpen: false }
	}

	handleToggleHelp = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	handleCloseHelp = (evt) => {
		evt.preventDefault()
		this.setState({ isOpen: false })
	}

	render() {
		const {
			alignVerticalCenter,
			children,
			classes,
			inline,
			message,
			messageWrapperClass,
			disable,
			theme,
			size,
			'aria-label': ariaLabel,
		} = this.props

		const { isOpen } = this.state

		const label = typeof ariaLabel === 'function' ? ariaLabel(isOpen) : ariaLabel

		// used for mobile to not showing help
		if (disable) {
			return <div>{children}</div>
		}

		return (
			<div className={classes.component}>
				{!inline && (
					<div
						className={classes.row}
						style={{ alignItems: alignVerticalCenter ? 'center' : 'flex-end' }}
					>
						<div style={{ flex: '1 1 100%', paddingRight: !message ? '48px' : 0 }}>{children}</div>
						{!!message && (
							<div className={classes.toggle}>
								<IconButton
									onClick={this.handleToggleHelp}
									aria-label={label}
									dataTestid="inline-help-icon"
									size={size ?? 'large'}
								>
									{isOpen ? <CancelIcon color="primary" /> : <HelpOutlineIcon color="primary" />}
								</IconButton>
							</div>
						)}
					</div>
				)}
				{inline && (
					<div
						style={{
							display: 'flex',
						}}
					>
						{children}
						<IconButton
							onClick={this.handleToggleHelp}
							aria-label={label}
							dataTestid="inline-help-icon"
							size={size ?? 'large'}
						>
							{isOpen ? <CancelIcon color="primary" /> : <HelpOutlineIcon color="primary" />}
						</IconButton>
					</div>
				)}
				{isOpen && (
					<div
						className={messageWrapperClass}
						style={{
							display: 'flex',
							border: `1px solid ${theme.palette.primary.main}`,
							borderRadius: '3px',
							marginTop: '0.3rem',
							marginBottom: '0.5rem',
							background: theme.palette.primary.lighterHue,
						}}
					>
						<StyledInfoHelpContainer>
							<HelpOutlineIcon className={classes.messageIcon} />
						</StyledInfoHelpContainer>
						<div
							style={{
								padding: '0.5rem',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Text
								type="body1"
								style={{ marginBottom: 0, marginTop: 0 }}
								data-testid="inline-help-text"
							>
								{message}
							</Text>
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default injectSheet(InlineHelp.styles, withTheme)(InlineHelp)
