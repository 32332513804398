// eslint-disable-next-line no-restricted-imports -- Please upgrade
import Alert from '@mui/material/Alert'

export const FileUploadError = ({ uploadError }) => {
	if (!uploadError) {
		return null
	}

	return (
		<Alert severity="error" style={{ margin: '1rem 0' }}>
			An error occurred during upload. One or more files failed to upload.
		</Alert>
	)
}
