import { useState } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from '@persuit/ui-auth'
import { gql, useQuery } from '@apollo/client'
import { ErrorPage } from '@persuit/ui-shared-components'
import {
	LoadingSpinner,
	List,
	ListItem,
	Box,
	Button,
	TextField,
	InputAdornment,
	SearchIcon,
	IconButton,
	ClearIcon,
	Typography,
	Select,
	SROnly,
	MenuItem,
	InputLabel,
	FormControl,
} from '@persuit/ui-components'
import { FirmListCard } from './firm-list-card'
import { FirmList } from '@persuit/ui-graphql/generated'

export const GET_SESSION_QUERY = gql`
	query RfpInviteForm_getSessionInfo {
		getSessionInfo {
			groups {
				_id
				settings {
					firmLists {
						_id
						name
						isPanel
						approved
						description
						instructions
						firms {
							org {
								_id
								name
								isNamwolfMember
							}
						}
						updatedBy {
							_id
							name {
								first
								last
							}
						}
						updatedAt
						refreshDate
					}
				}
			}
			user {
				_id
				favourites
				org {
					_id
					settings {
						contacts {
							_id
							org {
								_id
								name
							}
							name {
								first
								last
							}
							email
							title
							location {
								name
							}
							professionalProfile {
								_id
								url
								profile
							}
						}
					}
				}
			}
		}
	}
`

function filterBySearchTerm(firmList: FirmList, searchTerm: string) {
	if (searchTerm.length < 3) return true

	const lowerCaseSearchTerm = searchTerm.toLowerCase()

	const firmListNameMatches = firmList.name.toLowerCase().includes(lowerCaseSearchTerm)

	const firmsNameMatches = firmList.firms.some((firm) => {
		if (!firm.org.name) return

		return firm.org.name.toLowerCase().includes(lowerCaseSearchTerm)
	})

	return firmListNameMatches || firmsNameMatches
}

function filterFirmLists(firmLists: FirmList[], searchTerm: string, sortBy: string) {
	// Filter firmLists based on searchTerm
	const filteredFirmLists = firmLists.filter((firmList) => filterBySearchTerm(firmList, searchTerm))

	// Sort the filtered firmLists based on sortBy
	if (sortBy === 'asc') {
		filteredFirmLists.sort((a, b) => a.name.localeCompare(b.name))
	} else if (sortBy === 'desc') {
		filteredFirmLists.sort((a, b) => b.name.localeCompare(a.name))
	}

	return filteredFirmLists
}

export const FirmListsFormContainer = ({ inviteError }: { inviteError: string | undefined }) => {
	const [expanded, setExpanded] = React.useState<string>('')
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [sortBy, setSortBy] = useState<string>('asc')

	const history = useHistory()

	const { loading: loadingSession, can } = useUser()
	const canEditFirmLists = can('ACCESS_FIRM_LISTS')

	const {
		data: groupFirmLists,
		loading,
		error,
	} = useQuery(GET_SESSION_QUERY, { fetchPolicy: 'cache-first' })

	if (loadingSession || loading) {
		return <LoadingSpinner />
	}

	if (error) {
		return <ErrorPage />
	}

	const firmLists = (groupFirmLists.getSessionInfo.groups[0].settings?.firmLists ??
		[]) as FirmList[]
	const favourites = groupFirmLists.getSessionInfo.user.favourites ?? []
	const contacts = groupFirmLists.getSessionInfo.user.org.settings.contacts ?? []
	const filteredFirmLists = filterFirmLists(firmLists, searchTerm, sortBy)
	const hasFirmLists = filteredFirmLists.length > 0

	return (
		<Box mt={4}>
			<Box display="grid" gridTemplateColumns="1fr auto" gap={1} width="100%">
				<TextField
					id="search-firm-lists"
					data-testid="search-firm-lists"
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
					label="Search"
					fullWidth={true}
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: searchTerm ? (
							<IconButton onClick={() => setSearchTerm('')} size="small" aria-label="clear search">
								<ClearIcon color="primary" />
							</IconButton>
						) : null,
					}}
					error={Boolean(inviteError)}
					helperText={inviteError ? inviteError : null}
					disabled={!hasFirmLists}
				/>

				<FormControl variant="outlined" size="medium">
					<InputLabel id="sort-firm-lists">Sort by</InputLabel>
					<Select
						id="sort-firm-lists"
						labelId={`sort-firm-lists`}
						data-testid="sort-firm-lists"
						value={sortBy}
						fullWidth={true}
						variant="outlined"
						size="medium"
						autoWidth={true}
						label="Sort by"
						onChange={(e) => setSortBy(e.target.value)}
						disabled={!hasFirmLists}
					>
						<MenuItem value="asc">Firm list name A-Z</MenuItem>
						<MenuItem value="desc">Firm list name Z-A</MenuItem>
					</Select>
				</FormControl>
			</Box>
			{!hasFirmLists ? (
				<Box
					role="alert"
					aria-live="polite"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 3,
						mt: 4,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: 2,
						}}
					>
						<Typography variant="body1Semibold">
							It appears there isn’t a list created yet.
						</Typography>
						<Typography variant="body2">Lists can be created by your manager.</Typography>
					</Box>

					{canEditFirmLists && (
						<Button
							aria-label="Go to Panel Management"
							variant="outlined"
							onClick={() => {
								history.push('/en/panel-management/')
							}}
						>
							Go to Panel Management
						</Button>
					)}
				</Box>
			) : (
				<Box mt={2}>
					<Box aria-live="polite">
						<SROnly>{`${filteredFirmLists.length} firm ${
							filteredFirmLists.length === 1 ? 'list' : 'lists'
						} found`}</SROnly>
					</Box>
					<Typography variant="h4" component="h2">
						{`${filteredFirmLists.length} firm lists`}
					</Typography>
					<List aria-label="Firm lists" sx={{ p: 0, m: 0 }}>
						{filteredFirmLists.map((firmList, index) => (
							<ListItem key={index} sx={{ p: 0, m: 0 }}>
								<FirmListCard
									name={firmList.name}
									isPanel={firmList.isPanel}
									approved={firmList.approved}
									description={firmList.description}
									instructions={firmList.instructions}
									firms={firmList.firms}
									expanded={expanded === firmList._id}
									handleAccordionChange={(isExpanded: boolean) =>
										isExpanded ? setExpanded(firmList._id) : setExpanded('')
									}
									favourites={favourites}
									contacts={contacts}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			)}
		</Box>
	)
}
