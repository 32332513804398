import { memo } from 'react'
import {
	Typography,
	ListIcon,
	Box,
	IconButton,
	MoreVertIcon,
	ExpandableCard,
	DragHandler,
} from '@persuit/ui-components'

type GroupHeaderProps = {
	name: string
}

type QuestionGroupOverlayProps = {
	groupHeaderProps: GroupHeaderProps
	expanded?: boolean
	id: string
}

const GroupHeader = ({ name }: GroupHeaderProps) => {
	return (
		<Box display="flex" alignItems={'center'}>
			<Box px={1} display="flex" alignItems={'center'}>
				<ListIcon />
			</Box>
			<Box px={1} flexGrow={1}>
				<Typography>{name}</Typography>
			</Box>
		</Box>
	)
}

export const GroupOverlay = memo(
	({ groupHeaderProps, expanded }: QuestionGroupOverlayProps) => {
		return (
			<ExpandableCard
				summaryPrefix={
					<Box display="flex" alignItems={'center'}>
						<DragHandler />
					</Box>
				}
				summary={<GroupHeader {...groupHeaderProps} />}
				summarySuffix={
					<>
						<IconButton
							aria-label="Pricing item actions"
							aria-controls="long-menu"
							aria-haspopup="true"
							color="primary"
							size="large"
						>
							<MoreVertIcon />
						</IconButton>
					</>
				}
				expanded={expanded}
				details={null}
			/>
		)
	},
	(prevProps, nextProps) => prevProps.id !== nextProps.id,
)

GroupOverlay.displayName = 'GroupOverlay'
