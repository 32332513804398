// Using a function to clamp savings to make it easier
// to modify this if we ever decide to change the rules
// Current behaviour: if savings is less than zero, then
// show savings as zero (don't show negative savings)
export default function clampSavings(value) {
	if (isNaN(value) || value < 0) {
		return 0
	}

	return value
}
