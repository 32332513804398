import { RfpProgressStep, RfpProgressTypeEnum } from '@persuit/ui-graphql/generated'
import { ProgressCardStart, ProgressCardProps } from '../progress-cards'
import { ProgressCardDraftRequest } from '../progress-cards/progress-card-draft-request'
import { ProgressCardSendRequest } from '../progress-cards/progress-card-send-request'
import { ProgressCardWaitForProposals } from '../progress-cards/progress-card-wait-for-proposals'
import { ProgressCardSendClarification } from '../progress-cards/progress-card-send-clarification'
import { ProgressCardWatchAuction } from '../progress-cards/progress-card-watch-auction'
import { ProgressCardNegotiate } from '../progress-cards/progress-card-negotiate'
import { ProgressCardSelectWinner } from '../progress-cards/progress-card-select-winner'
import { ProgressCardFeedback } from '../progress-cards/progress-card-feedback'
import { ProgressCardCompleteRequest } from '../progress-cards/progress-card-complete-request'

const PROGRESS_CARDS_MAP: Record<RfpProgressTypeEnum, (props: ProgressCardProps) => JSX.Element> = {
	start: ProgressCardStart,
	draftRequest: ProgressCardDraftRequest,
	sendRequest: ProgressCardSendRequest,
	waitForProposals: ProgressCardWaitForProposals,
	sendClarification: ProgressCardSendClarification,
	watchAuction: ProgressCardWatchAuction,
	negotiate: ProgressCardNegotiate,
	selectWinner: ProgressCardSelectWinner,
	feedback: ProgressCardFeedback,
	completeRequest: ProgressCardCompleteRequest,
}

export type RfpProgressStepCardProps = ProgressCardProps & {
	step: RfpProgressStep
}

export const RfpProgressStepCard = ({ step, ...rest }: RfpProgressStepCardProps) => {
	const Card = PROGRESS_CARDS_MAP[step.type]

	if (!Card) return null

	return <Card step={step} {...rest} />
}
