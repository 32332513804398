import filesize from 'filesize'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import IconButton from 'material-ui/IconButton'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import LinearProgress from 'material-ui/LinearProgress'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { ListItem } from 'material-ui/List'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import IconCancel from 'material-ui/svg-icons/navigation/cancel'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import IconError from 'material-ui/svg-icons/alert/error'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import IconUpload from 'material-ui/svg-icons/file/cloud-upload'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file'
import injectSheet from '../../injectSheet'
import { grey200, grey300, teal400, grey500, deepPurple800 } from '../../theme/persuit-colors'
import { DeleteIcon, Typography } from '@persuit/ui-components'

import {
	checkFileStatusThreat,
	checkFileStatusOversize,
	checkFileStatusQueued,
} from '../../../common/file-status'

const FileItem = ({
	_id,
	classes,
	error,
	filename,
	fileStatus,
	length,
	onCancel,
	onRemove,
	status,
}) => {
	let secondaryText
	if (status === 'uploading' || status === 'error') {
		secondaryText = (
			<div>
				{status === 'uploading' && <LinearProgress mode="indeterminate" />}
				{status === 'error' && <div>{error}</div>}
			</div>
		)
	}

	const extraProps = {}

	if (status === 'uploaded') {
		extraProps.leftIcon = <FileIcon color={grey500} />
	}
	if (status === 'uploading') {
		extraProps.leftIcon = <IconUpload />
	}
	if (status === 'error') {
		extraProps.leftIcon = <IconError />
	}

	if (onCancel && status === 'uploading') {
		extraProps.rightIconButton = (
			<IconButton onClick={onCancel} tooltip="Cancel upload" disableTouchRipple={true} size="large">
				<IconCancel />
			</IconButton>
		)
	}

	if (onRemove && (status === 'uploaded' || status === 'error')) {
		extraProps.rightIconButton = (
			<IconButton data-testid="remove-file-button" onClick={onRemove} size="large">
				<DeleteIcon color="primary" />
			</IconButton>
		)
	}

	const text = (
		<Typography>
			{filename}
			&nbsp;&nbsp;&nbsp;({filesize(length)})
		</Typography>
	)

	if (checkFileStatusQueued(fileStatus)) {
		const tertiaryText = (
			<div>
				<Typography variant="caption" color="text.primary">
					Please wait for virus scanning to complete
				</Typography>
			</div>
		)
		return (
			<ListItem
				className={classes.listItemQueued}
				primaryText={text}
				secondaryText={secondaryText ? secondaryText : tertiaryText}
				{...extraProps}
			/>
		)
	}

	if (checkFileStatusOversize(fileStatus)) {
		const tertiaryText = (
			<div>
				<Typography variant="caption" color="text.primary">
					File larger than 50MB limit - please remove
				</Typography>
			</div>
		)
		return (
			<ListItem
				className={classes.listItemError}
				primaryText={text}
				secondaryText={secondaryText ? secondaryText : tertiaryText}
				{...extraProps}
			/>
		)
	}

	if (checkFileStatusThreat(fileStatus)) {
		const tertiaryText = (
			<div data-testid="maliciousText">
				<Typography variant="caption" color="text.primary">
					Malicious file detected - please remove
				</Typography>
			</div>
		)
		return (
			<div data-testid="maliciousMessageContainer">
				<ListItem
					className={classes.listItemError}
					primaryText={text}
					secondaryText={secondaryText ? secondaryText : tertiaryText}
					{...extraProps}
				/>
			</div>
		)
	}

	return (
		<a href={`/en/file/${_id}`}>
			<div data-testid="validFileUploadContainer">
				<ListItem
					className={classes.listItem}
					primaryText={text}
					secondaryText={secondaryText}
					{...extraProps}
				/>
			</div>
		</a>
	)
}

FileItem.styles = {
	listItemError: {
		border: '2px solid red !important',
		backgroundColor: `${grey200} !important`,
		wordBreak: 'break-all',
	},
	listItemQueued: {
		border: '2px solid blue !important',
		backgroundColor: `${grey200} !important`,
		wordBreak: 'break-all',
	},
	listItem: {
		backgroundColor: `${grey200} !important`,
		'&:hover': {
			backgroundColor: `${grey300} !important`,
		},
		wordBreak: 'break-all',
	},
	fileName: {
		color: `${deepPurple800} !important`,
		wordBreak: 'break-word',
	},
	fileDetails: {
		lineHeight: '1.75rem !important',
	},
	icon: {
		fill: `${teal400} !important`,
	},
	button: {
		padding: '0px !important',
	},
}
FileItem.defaultProps = {
	status: 'uploaded',
}

const StyledItem = injectSheet(FileItem.styles)(FileItem)
export default StyledItem
