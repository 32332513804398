import { FC, useState } from 'react'
import { getOr, has } from 'lodash/fp'
import { Button, NegotiateIcon, ButtonProps } from '@persuit/ui-components'
import {
	NegotiateDialog,
	ResponseForNegotiateDialog,
	RequestForNegotiateDialog,
} from '../../response-compare/negotiate-dialog'
import { disableNegotiateProposalButton } from '../../../rules'

export type NegotiateButtonProps = {
	ButtonProps?: ButtonProps
	requestId: string
	request: RequestForNegotiateDialog
	response: Omit<ResponseForNegotiateDialog, 'hasRfprExists'>
	currency: string
	isFirmEliminated?: boolean | null
}

export const NegotiateButton: FC<NegotiateButtonProps> = ({
	ButtonProps,
	request,
	response,
	currency,
	isFirmEliminated,
}) => {
	const [showNegotiatingProposalDialog, setShowNegotiatingProposalDialog] = useState(false)
	const isDisabled = disableNegotiateProposalButton({
		response,
		request,
		isFirmEliminated,
	})

	if (!request) {
		return null
	}

	const { _id, org, totalPriceModel, totalPriceValue, isPendingPrice, hasRevisionRequest } =
		response
	const clientOrgId = getOr('', 'org._id', request)
	const revisionCommentOrgId = getOr('', 'draftRevisionComment.org._id', response)
	const isRevisionCommentFromCurrentOrg =
		has('draftRevisionComment._id', response) && clientOrgId === revisionCommentOrgId

	const hasRfprExists = hasRevisionRequest || isRevisionCommentFromCurrentOrg

	const negotiatingProposal = {
		_id,
		org: { name: org?.name },
		totalPriceModel,
		totalPriceValue,
		isPendingPrice,
		hasRevisionRequest,
		// hasRfprExists will resume the existing RFPR draft if true
		hasRfprExists,
	}

	return (
		<>
			<Button
				startIcon={<NegotiateIcon />}
				variant="outlined"
				color="primary"
				disabled={isDisabled}
				onClick={() => setShowNegotiatingProposalDialog(true)}
				aria-label="negotiate"
				data-trackid="button-negotiate-proposalview"
				{...ButtonProps}
			>
				Negotiate
			</Button>

			{request && showNegotiatingProposalDialog && (
				<NegotiateDialog
					request={request}
					response={negotiatingProposal}
					currency={currency}
					handleClose={() => setShowNegotiatingProposalDialog(false)}
				/>
			)}
		</>
	)
}
