import { PricingModels } from '@persuit/ui-graphql/generated'
import { Box, Typography } from '@persuit/ui-components'
import { ClientSubItemPricingModelStrings } from '../../constants'

type SubItemProps = {
	itemIndex: number
	subIndex: number
	ratePricingPreference?: PricingModels | null
	label?: string
}

const getSubItemPricingModel = (ratePricingPreference?: PricingModels | null) => {
	if (!ratePricingPreference || !(ratePricingPreference in ClientSubItemPricingModelStrings))
		return ''
	return ClientSubItemPricingModelStrings[ratePricingPreference]
}

export const SubItem = ({ itemIndex, subIndex, ratePricingPreference, label }: SubItemProps) => {
	const indexString = `${itemIndex + 1}.${subIndex + 1}`
	const title = `${indexString} ${!label ? '-' : label}`
	const pricingModel = getSubItemPricingModel(ratePricingPreference)

	return (
		<>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				data-wordexport="hide"
			>
				<Typography variant="body2" data-testid="pricing-sub-item-title">
					{title}
				</Typography>
				<Typography variant="body1" data-testid="pricing-sub-item-model">
					{pricingModel}
				</Typography>
			</Box>
			{/* Word export only */}
			<Typography variant="body2" display="none">{`${title.trim()} - ${pricingModel}`}</Typography>
		</>
	)
}
