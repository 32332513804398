import {
	useTheme,
	ListItem,
	ListItemIcon,
	Typography,
	Badge,
	Box,
	ListItemButton,
	LockIcon,
	RichTextView,
	EditIcon,
} from '@persuit/ui-components'
import channelTypes from 'src/common/data/channel-types'
import moment from 'moment'
import { ChannelWithLastMessage, CurrentUser } from './types'
import { getOrgNameByChannel } from './utils'
import { removeHtmlTags } from '@persuit/ui-utils'

type ChannelCardProps = {
	channel: ChannelWithLastMessage
	isLastIndex: boolean
	currentUser: CurrentUser
	selectChannel: (channelId: string) => void
}

export const ChannelCard = ({
	channel,
	channel: {
		orgs: channelOrg,
		type: channelType,
		myUnreads: { unreadCount },
		lastMessage,
		draftMessage,
	},
	isLastIndex,
	currentUser,
	selectChannel,
}: ChannelCardProps) => {
	const currentUserOrgId = currentUser?.org?._id
	const currentUserId = currentUser?._id
	const theme = useTheme()

	const {
		message: lastMessageText,
		createdAt: lastMessageCreatedAt,
		isBot,
		createdBy: lastMessageSender,
	} = lastMessage || {}

	const isPrivateFirmChannel = channelType === channelTypes.PRIVATE_FIRM_CHANNEL
	const isPrivateClientChannel = channelType === channelTypes.PRIVATE_CLIENT_CHANNEL
	const isClientToFirmChannel = channelType === channelTypes.CLIENT_TO_FIRM_CHANNEL

	const isInternalOrg =
		(isPrivateFirmChannel && channelOrg.firm?.id === currentUserOrgId) ||
		(isPrivateClientChannel && channelOrg.client?.id === currentUserOrgId)
			? true
			: false

	const orgName = getOrgNameByChannel(channel, currentUser?.org ?? null)

	const formatCreatedDate = (date: string) => {
		return date
			? moment().diff(parseInt(date, 10), 'hours') > 24
				? moment(parseInt(date, 10)).format('D MMM YY')
				: moment(parseInt(date, 10)).format('hh:mm A')
			: null
	}

	const lastMessageAdded = lastMessageCreatedAt ? formatCreatedDate(lastMessageCreatedAt) : ''

	const lastMessageSenderName =
		lastMessageSender?.userId === currentUserId ? 'You' : lastMessageSender?.name?.first

	const truncatedMessageStyles = {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': '2',
		'-webkit-box-orient': 'vertical',
	}

	return (
		<ListItem
			sx={{ borderBottom: isLastIndex ? 0 : 1, borderColor: theme.palette.divider, py: 3 }}
			aria-label={`${orgName} Channel`}
			disablePadding={true}
		>
			<ListItemButton
				onClick={() => selectChannel(channel.id)}
				aria-describedby=""
				sx={{ alignItems: 'flex-start', p: 0 }}
			>
				<ListItemIcon>
					<Badge
						color="error"
						overlap="circular"
						badgeContent={unreadCount}
						aria-label={`${unreadCount} Unread Messages`}
					>
						<Box
							component="span"
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{
								bgcolor: isClientToFirmChannel ? 'background.greyLabel' : 'primary.main',
								width: 40,
								height: 40,
								borderRadius: '50%',
							}}
						>
							{isClientToFirmChannel && orgName ? (
								<Typography color={'primary.contrastText'}>
									{orgName
										.split(' ')
										.map((word) => word[0])
										.join('')
										.slice(0, 3)
										.toUpperCase()}
								</Typography>
							) : (
								<LockIcon fontSize="small" sx={{ color: 'primary.contrastText' }} />
							)}
						</Box>
					</Badge>
				</ListItemIcon>

				<Box display="flex" flexDirection="column" width="100%">
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h4">
							{orgName}
							{isInternalOrg && <span> (Internal)</span>}
						</Typography>

						{draftMessage && draftMessage?.message ? (
							<Box display="flex" gap={1}>
								<Typography variant="body1" color="text.secondary" textAlign="right" width={120}>
									{formatCreatedDate(draftMessage.createdAt)}
								</Typography>
								<EditIcon sx={{ color: theme.palette.neutral.main }} />
							</Box>
						) : lastMessage && !isBot ? (
							<Typography variant="body1" color="text.secondary" textAlign="right" width={120}>
								{lastMessageAdded}
							</Typography>
						) : null}
					</Box>

					{draftMessage && draftMessage?.message ? (
						<Box display="flex" gap={1}>
							<Typography variant="body1Semibold" color="primary.lightHue">
								Draft:{' '}
							</Typography>
							<RichTextView content={removeHtmlTags(draftMessage?.message)} truncationLength={80} />
						</Box>
					) : lastMessageText && !isBot ? (
						<RichTextView
							content={`${lastMessageSenderName}: ${lastMessageText?.replace(/<[^>]*>/g, '')}`}
							sx={truncatedMessageStyles}
						/>
					) : (
						<Typography color="text.secondary" sx={truncatedMessageStyles}>
							{`No recent conversation. Message ${orgName} to start a conversation.`}
						</Typography>
					)}
				</Box>
			</ListItemButton>
		</ListItem>
	)
}
