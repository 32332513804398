const SELECT_CHANNEL = 'SELECT_CHANNEL'
const SCROLL_TO_CHANNEL_END = 'SCROLL_TO_CHANNEL_END'
const FOCUS_MESSAGE_TEXT_FIELD = 'FOCUS_MESSAGE_TEXT_FIELD'

function selectChannel(channelId) {
	return {
		type: SELECT_CHANNEL,
		channelId,
	}
}

function scrollToChannelEnd() {
	const messagesListElement = document.querySelector('#messages-list')
	messagesListElement.scrollIntoView(false)

	return {
		// This isn't actually used by the reducers but a type needs to be returned by every redux action
		type: SCROLL_TO_CHANNEL_END,
	}
}

function focusMessageTextField() {
	const messageTextField = document.querySelector('#message')
	if (messageTextField) {
		messageTextField.focus()
	}

	return {
		// As above, this isn't actually used by the reducers
		// but a type needs to be returned by every redux action
		type: FOCUS_MESSAGE_TEXT_FIELD,
	}
}

export default {
	SELECT_CHANNEL,

	selectChannel,
	scrollToChannelEnd,
	focusMessageTextField,
}
