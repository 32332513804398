/**
 *	We decided to use `id` field because there is a possibility that the product will add made up labels
 * 	that map to the same timezone `key`. This maybe done to make the UX simpler. Eg:
 * 	--> America/New_York will be the same key for New York and Miami since they follow the same TZ.
 */

export const TIMEZONES = [
	{ id: 292, key: 'Europe/Zurich', label: 'Central European Time' },
	{ id: 293, key: 'Europe/London', label: 'Greenwich Mean Time' },
	{ id: 294, key: 'America/New_York', label: 'USA Eastern Time' },
	{ id: 295, key: 'America/Chicago', label: 'USA Central Time' },
	{ id: 296, key: 'America/Los_Angeles', label: 'USA Pacific Time' },
	{ id: 297, key: 'Australia/Sydney', label: 'Australian Eastern Time' },
	{ id: 1, key: 'Asia/Kabul', label: 'Afghanistan - Kabul Time' },
	{ id: 2, key: 'Europe/Tirane', label: 'Albania - Tirane Time' },
	{ id: 3, key: 'Africa/Algiers', label: 'Algeria - Algiers Time' },
	{ id: 4, key: 'America/Argentina/Buenos_Aires', label: 'Argentina - Buenos Aires Time' },
	{ id: 5, key: 'America/Argentina/Cordoba', label: 'Argentina - Cordoba Time' },
	{ id: 6, key: 'America/Argentina/Salta', label: 'Argentina - Salta Time' },
	{ id: 7, key: 'America/Argentina/Jujuy', label: 'Argentina - Jujuy Time' },
	{ id: 8, key: 'America/Argentina/Tucuman', label: 'Argentina - Tucuman Time' },
	{ id: 9, key: 'America/Argentina/Catamarca', label: 'Argentina - Catamarca Time' },
	{ id: 10, key: 'America/Argentina/La_Rioja', label: 'Argentina - La Rioja Time' },
	{ id: 11, key: 'America/Argentina/San_Juan', label: 'Argentina - San Juan Time' },
	{ id: 12, key: 'America/Argentina/Mendoza', label: 'Argentina - Mendoza Time' },
	{ id: 13, key: 'America/Argentina/San_Luis', label: 'Argentina - San Luis Time' },
	{ id: 14, key: 'America/Argentina/Rio_Gallegos', label: 'Argentina - Rio Gallegos Time' },
	{ id: 15, key: 'America/Argentina/Ushuaia', label: 'Argentina - Ushuaia Time' },
	{ id: 16, key: 'Asia/Yerevan', label: 'Armenia - Yerevan Time' },
	{ id: 17, key: 'Australia/Lord_Howe', label: 'Australia - Lord Howe Time' },
	{ id: 18, key: 'Antarctica/Macquarie', label: 'Antarctica - Macquarie Time' },
	{ id: 19, key: 'Australia/Hobart', label: 'Australia - Hobart Time' },
	{ id: 20, key: 'Australia/Currie', label: 'Australia - Currie Time' },
	{ id: 21, key: 'Australia/Melbourne', label: 'Australia - Melbourne Time' },
	{ id: 22, key: 'Australia/Sydney', label: 'Australia - Sydney Time' },
	{ id: 23, key: 'Australia/Broken_Hill', label: 'Australia - Broken Hill Time' },
	{ id: 24, key: 'Australia/Brisbane', label: 'Australia - Brisbane Time' },
	{ id: 25, key: 'Australia/Lindeman', label: 'Australia - Lindeman Time' },
	{ id: 26, key: 'Australia/Adelaide', label: 'Australia - Adelaide Time' },
	{ id: 27, key: 'Australia/Darwin', label: 'Australia - Darwin Time' },
	{ id: 28, key: 'Australia/Perth', label: 'Australia - Perth Time' },
	{ id: 29, key: 'Australia/Eucla', label: 'Australia - Eucla Time' },
	{ id: 30, key: 'Europe/Vienna', label: 'Austria - Vienna Time' },
	{ id: 31, key: 'Asia/Baku', label: 'Azerbaijan - Baku Time' },
	{ id: 32, key: 'Asia/Bahrain', label: 'Bahrain - Bahrain Time' },
	{ id: 33, key: 'Asia/Dhaka', label: 'Bangladesh - Dhaka Time' },
	{ id: 34, key: 'Europe/Minsk', label: 'Belarus - Minsk Time' },
	{ id: 35, key: 'Europe/Brussels', label: 'Belgium - Brussels Time' },
	{ id: 36, key: 'America/Belize', label: 'Belize - Belize Time' },
	{ id: 37, key: 'Asia/Thimphu', label: 'Bhutan - Thimphu Time' },
	{ id: 38, key: 'America/La_Paz', label: 'Bolivia - La Paz Time' },
	{ id: 39, key: 'Europe/Sarajevo', label: 'Bosnia and Herzegovina - Sarajevo Time' },
	{ id: 40, key: 'Africa/Gaborone', label: 'Botswana - Gaborone Time' },
	{ id: 41, key: 'America/Noronha', label: 'Brazil - Noronha Time' },
	{ id: 42, key: 'America/Belem', label: 'Brazil - Belem Time' },
	{ id: 43, key: 'America/Fortaleza', label: 'Brazil - Fortaleza Time' },
	{ id: 44, key: 'America/Recife', label: 'Brazil - Recife Time' },
	{ id: 45, key: 'America/Araguaina', label: 'Brazil - Araguaina Time' },
	{ id: 46, key: 'America/Maceio', label: 'Brazil - Maceio Time' },
	{ id: 47, key: 'America/Bahia', label: 'Brazil - Bahia Time' },
	{ id: 48, key: 'America/Sao_Paulo', label: 'Brazil - Sao Paulo Time' },
	{ id: 49, key: 'America/Campo_Grande', label: 'Brazil - Campo Grande Time' },
	{ id: 50, key: 'America/Cuiaba', label: 'Brazil - Cuiaba Time' },
	{ id: 51, key: 'America/Santarem', label: 'Brazil - Santarem Time' },
	{ id: 52, key: 'America/Porto_Velho', label: 'Brazil - Porto Velho Time' },
	{ id: 53, key: 'America/Boa_Vista', label: 'Brazil - Boa Vista Time' },
	{ id: 54, key: 'America/Manaus', label: 'Brazil - Manaus Time' },
	{ id: 55, key: 'America/Eirunepe', label: 'Brazil - Eirunepe Time' },
	{ id: 56, key: 'America/Rio_Branco', label: 'Brazil - Rio Branco Time' },
	{ id: 57, key: 'Asia/Brunei', label: 'Brunei - Brunei Time' },
	{ id: 58, key: 'Europe/Sofia', label: 'Bulgaria - Sofia Time' },
	{ id: 59, key: 'Asia/Phnom_Penh', label: 'Cambodia - Phnom Penh Time' },
	{ id: 60, key: 'Africa/Douala', label: 'Cameroon - Douala Time' },
	{ id: 61, key: 'America/St_Johns', label: 'Canada - St Johns Time' },
	{ id: 62, key: 'America/Halifax', label: 'Canada - Halifax Time' },
	{ id: 63, key: 'America/Glace_Bay', label: 'Canada - Glace Bay Time' },
	{ id: 64, key: 'America/Moncton', label: 'Canada - Moncton Time' },
	{ id: 65, key: 'America/Goose_Bay', label: 'Canada - Goose Bay Time' },
	{ id: 66, key: 'America/Blanc-Sablon', label: 'Canada - Blanc-Sablon Time' },
	{ id: 67, key: 'America/Toronto', label: 'Canada - Toronto Time' },
	{ id: 68, key: 'America/Nipigon', label: 'Canada - Nipigon Time' },
	{ id: 69, key: 'America/Thunder_Bay', label: 'Canada - Thunder Bay Time' },
	{ id: 70, key: 'America/Iqaluit', label: 'Canada - Iqaluit Time' },
	{ id: 71, key: 'America/Pangnirtung', label: 'Canada - Pangnirtung Time' },
	{ id: 72, key: 'America/Atikokan', label: 'Canada - Atikokan Time' },
	{ id: 73, key: 'America/Winnipeg', label: 'Canada - Winnipeg Time' },
	{ id: 74, key: 'America/Rainy_River', label: 'Canada - Rainy River Time' },
	{ id: 75, key: 'America/Resolute', label: 'Canada - Resolute Time' },
	{ id: 76, key: 'America/Rankin_Inlet', label: 'Canada - Rankin Inlet Time' },
	{ id: 77, key: 'America/Regina', label: 'Canada - Regina Time' },
	{ id: 78, key: 'America/Swift_Current', label: 'Canada - Swift Current Time' },
	{ id: 79, key: 'America/Edmonton', label: 'Canada - Edmonton Time' },
	{ id: 80, key: 'America/Cambridge_Bay', label: 'Canada - Cambridge Bay Time' },
	{ id: 81, key: 'America/Yellowknife', label: 'Canada - Yellowknife Time' },
	{ id: 82, key: 'America/Inuvik', label: 'Canada - Inuvik Time' },
	{ id: 83, key: 'America/Creston', label: 'Canada - Creston Time' },
	{ id: 84, key: 'America/Dawson_Creek', label: 'Canada - Dawson Creek Time' },
	{ id: 85, key: 'America/Fort_Nelson', label: 'Canada - Fort Nelson Time' },
	{ id: 86, key: 'America/Vancouver', label: 'Canada - Vancouver Time' },
	{ id: 87, key: 'America/Whitehorse', label: 'Canada - Whitehorse Time' },
	{ id: 88, key: 'America/Dawson', label: 'Canada - Dawson Time' },
	{ id: 89, key: 'America/Santiago', label: 'Chile - Santiago Time' },
	{ id: 90, key: 'Pacific/Easter', label: 'Chile - Easter Time' },
	{ id: 91, key: 'Asia/Shanghai', label: 'China - Shanghai Time' },
	{ id: 92, key: 'Asia/Urumqi', label: 'China - Urumqi Time' },
	{ id: 93, key: 'America/Bogota', label: 'Colombia - Bogota Time' },
	{ id: 94, key: 'Africa/Kinshasa', label: 'Congo (DRC) - Kinshasa Time' },
	{ id: 95, key: 'Africa/Lubumbashi', label: 'Congo (DRC) - Lubumbashi Time' },
	{ id: 96, key: 'America/Costa_Rica', label: 'Costa Rica - Costa Rica Time' },
	{ id: 97, key: 'Africa/Abidjan', label: 'Côte d’Ivoire - Abidjan Time' },
	{ id: 98, key: 'Europe/Zagreb', label: 'Croatia - Zagreb Time' },
	{ id: 99, key: 'America/Havana', label: 'Cuba - Havana Time' },
	{ id: 100, key: 'Europe/Prague', label: 'Czech Republic - Prague Time' },
	{ id: 101, key: 'Europe/Copenhagen', label: 'Denmark - Copenhagen Time' },
	{ id: 102, key: 'Africa/Djibouti', label: 'Djibouti - Djibouti Time' },
	{ id: 103, key: 'America/Santo_Domingo', label: 'Dominican Republic - Santo Domingo Time' },
	{ id: 104, key: 'America/Guayaquil', label: 'Ecuador - Guayaquil Time' },
	{ id: 105, key: 'Pacific/Galapagos', label: 'Ecuador - Galapagos Time' },
	{ id: 106, key: 'Africa/Cairo', label: 'Egypt - Cairo Time' },
	{ id: 107, key: 'America/El_Salvador', label: 'El Salvador - El Salvador Time' },
	{ id: 108, key: 'Africa/Asmara', label: 'Eritrea - Asmara Time' },
	{ id: 109, key: 'Europe/Tallinn', label: 'Estonia - Tallinn Time' },
	{ id: 110, key: 'Africa/Addis_Ababa', label: 'Ethiopia - Addis Ababa Time' },
	{ id: 111, key: 'Atlantic/Faroe', label: 'Faroe Islands - Faroe Time' },
	{ id: 112, key: 'Europe/Helsinki', label: 'Finland - Helsinki Time' },
	{ id: 113, key: 'Europe/Paris', label: 'France - Paris Time' },
	{ id: 114, key: 'Asia/Tbilisi', label: 'Georgia - Tbilisi Time' },
	{ id: 115, key: 'Europe/Berlin', label: 'Germany - Berlin Time' },
	{ id: 116, key: 'Europe/Busingen', label: 'Germany - Busingen Time' },
	{ id: 117, key: 'Europe/Athens', label: 'Greece - Athens Time' },
	{ id: 118, key: 'America/Godthab', label: 'Greenland - Godthab Time' },
	{ id: 119, key: 'America/Danmarkshavn', label: 'Greenland - Danmarkshavn Time' },
	{ id: 120, key: 'America/Scoresbysund', label: 'Greenland - Scoresbysund Time' },
	{ id: 121, key: 'America/Thule', label: 'Greenland - Thule Time' },
	{ id: 122, key: 'America/Guatemala', label: 'Guatemala - Guatemala Time' },
	{ id: 123, key: 'America/Port-au-Prince', label: 'Haiti - Port-au-Prince Time' },
	{ id: 124, key: 'America/Tegucigalpa', label: 'Honduras - Tegucigalpa Time' },
	{ id: 125, key: 'Asia/Hong_Kong', label: 'Hong Kong SAR - Hong Kong Time' },
	{ id: 126, key: 'Europe/Budapest', label: 'Hungary - Budapest Time' },
	{ id: 127, key: 'Atlantic/Reykjavik', label: 'Iceland - Reykjavik Time' },
	{ id: 128, key: 'Asia/Kolkata', label: 'India - Kolkata Time' },
	{ id: 129, key: 'Asia/Jakarta', label: 'Indonesia - Jakarta Time' },
	{ id: 130, key: 'Asia/Pontianak', label: 'Indonesia - Pontianak Time' },
	{ id: 131, key: 'Asia/Makassar', label: 'Indonesia - Makassar Time' },
	{ id: 132, key: 'Asia/Jayapura', label: 'Indonesia - Jayapura Time' },
	{ id: 133, key: 'Asia/Tehran', label: 'Iran - Tehran Time' },
	{ id: 134, key: 'Asia/Baghdad', label: 'Iraq - Baghdad Time' },
	{ id: 135, key: 'Europe/Dublin', label: 'Ireland - Dublin Time' },
	{ id: 136, key: 'Asia/Jerusalem', label: 'Israel - Jerusalem Time' },
	{ id: 137, key: 'Europe/Rome', label: 'Italy - Rome Time' },
	{ id: 138, key: 'America/Jamaica', label: 'Jamaica - Jamaica Time' },
	{ id: 139, key: 'Asia/Tokyo', label: 'Japan - Tokyo Time' },
	{ id: 140, key: 'Asia/Amman', label: 'Jordan - Amman Time' },
	{ id: 141, key: 'Asia/Almaty', label: 'Kazakhstan - Almaty Time' },
	{ id: 142, key: 'Asia/Qyzylorda', label: 'Kazakhstan - Qyzylorda Time' },
	{ id: 143, key: 'Asia/Aqtobe', label: 'Kazakhstan - Aqtobe Time' },
	{ id: 144, key: 'Asia/Aqtau', label: 'Kazakhstan - Aqtau Time' },
	{ id: 145, key: 'Asia/Oral', label: 'Kazakhstan - Oral Time' },
	{ id: 146, key: 'Africa/Nairobi', label: 'Kenya - Nairobi Time' },
	{ id: 147, key: 'Asia/Seoul', label: 'Korea - Seoul Time' },
	{ id: 148, key: 'Asia/Kuwait', label: 'Kuwait - Kuwait Time' },
	{ id: 149, key: 'Asia/Bishkek', label: 'Kyrgyzstan - Bishkek Time' },
	{ id: 150, key: 'Asia/Vientiane', label: 'Laos - Vientiane Time' },
	{ id: 151, key: 'Europe/Riga', label: 'Latvia - Riga Time' },
	{ id: 152, key: 'Asia/Beirut', label: 'Lebanon - Beirut Time' },
	{ id: 153, key: 'Africa/Tripoli', label: 'Libya - Tripoli Time' },
	{ id: 154, key: 'Europe/Vaduz', label: 'Liechtenstein - Vaduz Time' },
	{ id: 155, key: 'Europe/Vilnius', label: 'Lithuania - Vilnius Time' },
	{ id: 156, key: 'Europe/Luxembourg', label: 'Luxembourg - Luxembourg Time' },
	{ id: 157, key: 'Asia/Macau', label: 'Macao SAR - Macau Time' },
	{ id: 158, key: 'Europe/Skopje', label: 'Macedonia, FYRO - Skopje Time' },
	{ id: 159, key: 'Asia/Kuala_Lumpur', label: 'Malaysia - Kuala Lumpur Time' },
	{ id: 160, key: 'Asia/Kuching', label: 'Malaysia - Kuching Time' },
	{ id: 161, key: 'Indian/Maldives', label: 'Maldives - Maldives Time' },
	{ id: 162, key: 'Africa/Bamako', label: 'Mali - Bamako Time' },
	{ id: 163, key: 'Europe/Malta', label: 'Malta - Malta Time' },
	{ id: 164, key: 'America/Mexico_City', label: 'Mexico - Mexico City Time' },
	{ id: 165, key: 'America/Cancun', label: 'Mexico - Cancun Time' },
	{ id: 166, key: 'America/Merida', label: 'Mexico - Merida Time' },
	{ id: 167, key: 'America/Monterrey', label: 'Mexico - Monterrey Time' },
	{ id: 168, key: 'America/Matamoros', label: 'Mexico - Matamoros Time' },
	{ id: 169, key: 'America/Mazatlan', label: 'Mexico - Mazatlan Time' },
	{ id: 170, key: 'America/Chihuahua', label: 'Mexico - Chihuahua Time' },
	{ id: 171, key: 'America/Ojinaga', label: 'Mexico - Ojinaga Time' },
	{ id: 172, key: 'America/Hermosillo', label: 'Mexico - Hermosillo Time' },
	{ id: 173, key: 'America/Tijuana', label: 'Mexico - Tijuana Time' },
	{ id: 174, key: 'America/Bahia_Banderas', label: 'Mexico - Bahia Banderas Time' },
	{ id: 175, key: 'Europe/Chisinau', label: 'Moldova - Chisinau Time' },
	{ id: 176, key: 'Europe/Monaco', label: 'Monaco - Monaco Time' },
	{ id: 177, key: 'Asia/Ulaanbaatar', label: 'Mongolia - Ulaanbaatar Time' },
	{ id: 178, key: 'Asia/Hovd', label: 'Mongolia - Hovd Time' },
	{ id: 179, key: 'Asia/Choibalsan', label: 'Mongolia - Choibalsan Time' },
	{ id: 180, key: 'Europe/Podgorica', label: 'Montenegro - Podgorica Time' },
	{ id: 181, key: 'Africa/Casablanca', label: 'Morocco - Casablanca Time' },
	{ id: 182, key: 'Asia/Rangoon', label: 'Myanmar - Rangoon Time' },
	{ id: 183, key: 'Asia/Kathmandu', label: 'Nepal - Kathmandu Time' },
	{ id: 184, key: 'Europe/Amsterdam', label: 'Netherlands - Amsterdam Time' },
	{ id: 185, key: 'Pacific/Auckland', label: 'New Zealand - Auckland Time' },
	{ id: 186, key: 'Pacific/Chatham', label: 'New Zealand - Chatham Time' },
	{ id: 187, key: 'America/Managua', label: 'Nicaragua - Managua Time' },
	{ id: 188, key: 'Africa/Lagos', label: 'Nigeria - Lagos Time' },
	{ id: 189, key: 'Europe/Oslo', label: 'Norway - Oslo Time' },
	{ id: 190, key: 'Asia/Muscat', label: 'Oman - Muscat Time' },
	{ id: 191, key: 'Asia/Karachi', label: 'Pakistan - Karachi Time' },
	{ id: 192, key: 'America/Panama', label: 'Panama - Panama Time' },
	{ id: 193, key: 'America/Asuncion', label: 'Paraguay - Asuncion Time' },
	{ id: 194, key: 'America/Lima', label: 'Peru - Lima Time' },
	{ id: 195, key: 'Asia/Manila', label: 'Philippines - Manila Time' },
	{ id: 196, key: 'Europe/Warsaw', label: 'Poland - Warsaw Time' },
	{ id: 197, key: 'Europe/Lisbon', label: 'Portugal - Lisbon Time' },
	{ id: 198, key: 'Atlantic/Madeira', label: 'Portugal - Madeira Time' },
	{ id: 199, key: 'Atlantic/Azores', label: 'Portugal - Azores Time' },
	{ id: 200, key: 'America/Puerto_Rico', label: 'Puerto Rico - Puerto Rico Time' },
	{ id: 201, key: 'Asia/Qatar', label: 'Qatar - Qatar Time' },
	{ id: 202, key: 'Indian/Reunion', label: 'Réunion - Reunion Time' },
	{ id: 203, key: 'Europe/Bucharest', label: 'Romania - Bucharest Time' },
	{ id: 204, key: 'Europe/Kaliningrad', label: 'Russia - Kaliningrad Time' },
	{ id: 205, key: 'Europe/Moscow', label: 'Russia - Moscow Time' },
	{ id: 206, key: 'Europe/Simferopol', label: 'Russia - Simferopol Time' },
	{ id: 207, key: 'Europe/Volgograd', label: 'Russia - Volgograd Time' },
	{ id: 208, key: 'Europe/Astrakhan', label: 'Russia - Astrakhan Time' },
	{ id: 209, key: 'Europe/Samara', label: 'Russia - Samara Time' },
	{ id: 210, key: 'Europe/Ulyanovsk', label: 'Russia - Ulyanovsk Time' },
	{ id: 211, key: 'Asia/Yekaterinburg', label: 'Russia - Yekaterinburg Time' },
	{ id: 212, key: 'Asia/Omsk', label: 'Russia - Omsk Time' },
	{ id: 213, key: 'Asia/Novosibirsk', label: 'Russia - Novosibirsk Time' },
	{ id: 214, key: 'Asia/Barnaul', label: 'Russia - Barnaul Time' },
	{ id: 215, key: 'Asia/Novokuznetsk', label: 'Russia - Novokuznetsk Time' },
	{ id: 216, key: 'Asia/Krasnoyarsk', label: 'Russia - Krasnoyarsk Time' },
	{ id: 217, key: 'Asia/Irkutsk', label: 'Russia - Irkutsk Time' },
	{ id: 218, key: 'Asia/Chita', label: 'Russia - Chita Time' },
	{ id: 219, key: 'Asia/Yakutsk', label: 'Russia - Yakutsk Time' },
	{ id: 220, key: 'Asia/Khandyga', label: 'Russia - Khandyga Time' },
	{ id: 221, key: 'Asia/Vladivostok', label: 'Russia - Vladivostok Time' },
	{ id: 222, key: 'Asia/Ust-Nera', label: 'Russia - Ust-Nera Time' },
	{ id: 223, key: 'Asia/Magadan', label: 'Russia - Magadan Time' },
	{ id: 224, key: 'Asia/Sakhalin', label: 'Russia - Sakhalin Time' },
	{ id: 225, key: 'Asia/Srednekolymsk', label: 'Russia - Srednekolymsk Time' },
	{ id: 226, key: 'Asia/Kamchatka', label: 'Russia - Kamchatka Time' },
	{ id: 227, key: 'Asia/Anadyr', label: 'Russia - Anadyr Time' },
	{ id: 228, key: 'Africa/Kigali', label: 'Rwanda - Kigali Time' },
	{ id: 229, key: 'Asia/Riyadh', label: 'Saudi Arabia - Riyadh Time' },
	{ id: 230, key: 'Africa/Dakar', label: 'Senegal - Dakar Time' },
	{ id: 231, key: 'Europe/Belgrade', label: 'Serbia - Belgrade Time' },
	{ id: 232, key: 'Asia/Singapore', label: 'Singapore - Singapore Time' },
	{ id: 233, key: 'Europe/Bratislava', label: 'Slovakia - Bratislava Time' },
	{ id: 234, key: 'Europe/Ljubljana', label: 'Slovenia - Ljubljana Time' },
	{ id: 235, key: 'Africa/Mogadishu', label: 'Somalia - Mogadishu Time' },
	{ id: 236, key: 'Africa/Johannesburg', label: 'South Africa - Johannesburg Time' },
	{ id: 237, key: 'Europe/Madrid', label: 'Spain - Madrid Time' },
	{ id: 238, key: 'Africa/Ceuta', label: 'Spain - Ceuta Time' },
	{ id: 239, key: 'Atlantic/Canary', label: 'Spain - Canary Time' },
	{ id: 240, key: 'Asia/Colombo', label: 'Sri Lanka - Colombo Time' },
	{ id: 241, key: 'Europe/Stockholm', label: 'Sweden - Stockholm Time' },
	{ id: 242, key: 'Europe/Zurich', label: 'Switzerland - Zurich Time' },
	{ id: 243, key: 'Asia/Damascus', label: 'Syria - Damascus Time' },
	{ id: 244, key: 'Asia/Taipei', label: 'Taiwan - Taipei Time' },
	{ id: 245, key: 'Asia/Dushanbe', label: 'Tajikistan - Dushanbe Time' },
	{ id: 246, key: 'Asia/Bangkok', label: 'Thailand - Bangkok Time' },
	{ id: 247, key: 'America/Port_of_Spain', label: 'Trinidad and Tobago - Port of Spain Time' },
	{ id: 248, key: 'Africa/Tunis', label: 'Tunisia - Tunis Time' },
	{ id: 249, key: 'Europe/Istanbul', label: 'Turkey - Istanbul Time' },
	{ id: 250, key: 'Asia/Ashgabat', label: 'Turkmenistan - Ashgabat Time' },
	{ id: 251, key: 'Europe/Kiev', label: 'Ukraine - Kiev Time' },
	{ id: 252, key: 'Europe/Uzhgorod', label: 'Ukraine - Uzhgorod Time' },
	{ id: 253, key: 'Europe/Zaporozhye', label: 'Ukraine - Zaporozhye Time' },
	{ id: 254, key: 'Asia/Dubai', label: 'United Arab Emirates - Dubai Time' },
	{ id: 255, key: 'Europe/London', label: 'United Kingdom - London Time' },
	{ id: 256, key: 'America/New_York', label: 'USA - New York Time' },
	{ id: 257, key: 'America/Detroit', label: 'USA - Detroit Time' },
	{ id: 258, key: 'America/Kentucky/Louisville', label: 'USA - Louisville Time' },
	{ id: 259, key: 'America/Kentucky/Monticello', label: 'USA - Monticello Time' },
	{ id: 260, key: 'America/Indiana/Indianapolis', label: 'USA - Indianapolis Time' },
	{ id: 261, key: 'America/Indiana/Vincennes', label: 'USA - Vincennes Time' },
	{ id: 262, key: 'America/Indiana/Winamac', label: 'USA - Winamac Time' },
	{ id: 263, key: 'America/Indiana/Marengo', label: 'USA - Marengo Time' },
	{ id: 264, key: 'America/Indiana/Petersburg', label: 'USA - Petersburg Time' },
	{ id: 265, key: 'America/Indiana/Vevay', label: 'USA - Vevay Time' },
	{ id: 266, key: 'America/Chicago', label: 'USA - Chicago Time' },
	{ id: 267, key: 'America/Indiana/Tell_City', label: 'USA - Tell City Time' },
	{ id: 268, key: 'America/Indiana/Knox', label: 'USA - Knox Time' },
	{ id: 269, key: 'America/Menominee', label: 'USA - Menominee Time' },
	{ id: 270, key: 'America/North_Dakota/Center', label: 'USA - Center Time' },
	{ id: 271, key: 'America/North_Dakota/New_Salem', label: 'USA - New Salem Time' },
	{ id: 272, key: 'America/North_Dakota/Beulah', label: 'USA - Beulah Time' },
	{ id: 273, key: 'America/Denver', label: 'USA - Denver Time' },
	{ id: 274, key: 'America/Boise', label: 'USA - Boise Time' },
	{ id: 275, key: 'America/Phoenix', label: 'USA - Phoenix Time' },
	{ id: 276, key: 'America/Los_Angeles', label: 'USA - Los Angeles Time' },
	{ id: 277, key: 'America/Anchorage', label: 'USA - Anchorage Time' },
	{ id: 278, key: 'America/Juneau', label: 'USA - Juneau Time' },
	{ id: 279, key: 'America/Sitka', label: 'USA - Sitka Time' },
	{ id: 280, key: 'America/Metlakatla', label: 'USA - Metlakatla Time' },
	{ id: 281, key: 'America/Yakutat', label: 'USA - Yakutat Time' },
	{ id: 282, key: 'America/Nome', label: 'USA - Nome Time' },
	{ id: 283, key: 'America/Adak', label: 'USA - Adak Time' },
	{ id: 284, key: 'Pacific/Honolulu', label: 'USA - Honolulu Time' },
	{ id: 285, key: 'America/Montevideo', label: 'Uruguay - Montevideo Time' },
	{ id: 286, key: 'Asia/Samarkand', label: 'Uzbekistan - Samarkand Time' },
	{ id: 287, key: 'Asia/Tashkent', label: 'Uzbekistan - Tashkent Time' },
	{ id: 288, key: 'America/Caracas', label: 'Venezuela - Caracas Time' },
	{ id: 289, key: 'Asia/Ho_Chi_Minh', label: 'Vietnam - Ho Chi Minh Time' },
	{ id: 290, key: 'Asia/Aden', label: 'Yemen - Aden Time' },
	{ id: 291, key: 'Africa/Harare', label: 'Zimbabwe - Harare Time' },
] as const

/**
 *  Returns a list of timezone object record
 *  Each record has the following shape
 * 	type TzRecord = { id: Number, key: String, label: String }
 */

/**
 *  Gets an entire timezone object record for a given timezone id
 *  @param {Number} timezoneId One of the valid id values from timezone
 */
export const getTimezoneById = (timezoneId = 0) => {
	const found = TIMEZONES.find(({ id }) => id === timezoneId)
	return found || NO_TIMEZONE
}

const NO_TIMEZONE = { id: 0, key: '', label: '' }

type Timezones = typeof TIMEZONES

export type Timezone = Timezones[number]
