import { alpha, createTheme, ThemeOptions } from '@mui/material/styles'
import {
	colorComponentBorderDivider,
	colorComponentBorderFocus,
	colorComponentBorderMain,
	colorComponentSurfaceBackdrop,
	colorComponentSurfacePrimary,
	colorComponentSurfaceSecondary,
	colorComponentTextDisabled,
	colorComponentTextError,
	colorComponentTextHeading,
	colorComponentTextInfo,
	colorComponentTextPrimary,
	colorComponentTextSecondary,
	colorComponentTextSuccess,
	colorComponentTextWarning,
	colorComponentTextWhite,
	colorSemanticActionActive,
	colorSemanticActionDisabled,
	colorSemanticActionDisabledBg,
	colorSemanticActionFocus,
	colorSemanticActionHover,
	colorSemanticActionSelected,
	colorSemanticBackgroundAppMain,
	colorSemanticBackgroundBackdropOverlay,
	colorSemanticBackgroundContentMain,
	colorSemanticBackgroundGreyLabel,
	colorSemanticBackgroundInfo,
	colorSemanticBackgroundNavigationMain,
	colorSemanticBackgroundWarning,
	colorSemanticCommonBlack,
	colorSemanticCommonBlackTransparentDark,
	colorSemanticCommonBlackTransparentLight,
	colorSemanticCommonWhite,
	colorSemanticErrorContrastText,
	colorSemanticErrorDarkerHue,
	colorSemanticErrorDarkHue,
	colorSemanticErrorLighterHue,
	colorSemanticErrorLightestHue,
	colorSemanticErrorLightHue,
	colorSemanticErrorMain,
	colorSemanticFormBorderActive,
	colorSemanticFormBorderResting,
	colorSemanticInfoContrastText,
	colorSemanticInfoDarkerHue,
	colorSemanticInfoDarkHue,
	colorSemanticInfoLighterHue,
	colorSemanticInfoLightestHue,
	colorSemanticInfoLightHue,
	colorSemanticInfoMain,
	colorSemanticNeutralDarkerHue,
	colorSemanticNeutralDarkestHue,
	colorSemanticNeutralDarkHue,
	colorSemanticNeutralFaint,
	colorSemanticNeutralLighterHue,
	colorSemanticNeutralLightestHue,
	colorSemanticNeutralLightHue,
	colorSemanticNeutralMain,
	colorSemanticPremiumDarkHue,
	colorSemanticPremiumLightestHue,
	colorSemanticPremiumLightHue,
	colorSemanticPremiumMain,
	colorSemanticPrimaryContrastText,
	colorSemanticPrimaryDarkerHue,
	colorSemanticPrimaryDarkHue,
	colorSemanticPrimaryLighterHue,
	colorSemanticPrimaryLightestHue,
	colorSemanticPrimaryLightHue,
	colorSemanticPrimaryMain,
	colorSemanticSecondaryContrastText,
	colorSemanticSecondaryDarkerHue,
	colorSemanticSecondaryDarkHue,
	colorSemanticSecondaryLighterHue,
	colorSemanticSecondaryLightestHue,
	colorSemanticSecondaryLightHue,
	colorSemanticSecondaryMain,
	colorSemanticSuccessContrastText,
	colorSemanticSuccessDarkerHue,
	colorSemanticSuccessDarkHue,
	colorSemanticSuccessLighterHue,
	colorSemanticSuccessLightestHue,
	colorSemanticSuccessLightHue,
	colorSemanticSuccessMain,
	colorSemanticWarningContrastText,
	colorSemanticWarningDarkHue,
	colorSemanticWarningLightestHue,
	colorSemanticWarningLightHue,
	colorSemanticWarningMain,
	colorSemanticYellowBright,
	colorSemanticYellowLightestHue,
} from '../tokens'
import * as colors from './colors'
import './types'

export const themeOptions: ThemeOptions = {
	palette: {
		primary: {
			main: colorSemanticPrimaryMain,
			lightHue: colorSemanticPrimaryLightHue,
			lighterHue: colorSemanticPrimaryLighterHue,
			lightestHue: colorSemanticPrimaryLightestHue,
			darkHue: colorSemanticPrimaryDarkHue,
			darkerHue: colorSemanticPrimaryDarkerHue,
			contrastText: colorSemanticPrimaryContrastText,
		},
		secondary: {
			main: colorSemanticSecondaryMain,
			lightHue: colorSemanticSecondaryLightHue,
			lighterHue: colorSemanticSecondaryLighterHue,
			lightestHue: colorSemanticSecondaryLightestHue,
			darkHue: colorSemanticSecondaryDarkHue,
			darkerHue: colorSemanticSecondaryDarkerHue,
			contrastText: colorSemanticSecondaryContrastText,
		},
		error: {
			main: colorSemanticErrorMain,
			light: colorSemanticErrorLightHue,
			lightHue: colorSemanticErrorLightHue,
			lighterHue: colorSemanticErrorLighterHue,
			lightestHue: colorSemanticErrorLightestHue,
			dark: colorSemanticErrorDarkHue,
			darkHue: colorSemanticErrorDarkHue,
			darkerHue: colorSemanticErrorDarkerHue,
			contrastText: colorSemanticErrorContrastText,
		},
		warning: {
			main: colorSemanticWarningMain,
			light: colorSemanticWarningLightHue,
			lightHue: colorSemanticWarningLightHue,
			lightestHue: colorSemanticWarningLightestHue,
			dark: colorSemanticWarningDarkHue,
			darkHue: colorSemanticWarningDarkHue,
			contrastText: colorSemanticWarningContrastText,
		},
		info: {
			main: colorSemanticInfoMain,
			light: colorSemanticInfoLightHue,
			lightHue: colorSemanticInfoLightHue,
			lighterHue: colorSemanticInfoLighterHue,
			lightestHue: colorSemanticInfoLightestHue,
			dark: colorSemanticInfoDarkHue,
			darkHue: colorSemanticInfoDarkHue,
			darkerHue: colorSemanticInfoDarkerHue,
			contrastText: colorSemanticInfoContrastText,
		},
		success: {
			main: colorSemanticSuccessMain,
			lightHue: colorSemanticSuccessLightHue,
			lighterHue: colorSemanticSuccessLighterHue,
			lightestHue: colorSemanticSuccessLightestHue,
			darkHue: colorSemanticSuccessDarkHue,
			darkerHue: colorSemanticSuccessDarkerHue,
			contrastText: colorSemanticSuccessContrastText,
		},
		text: {
			primary: colorComponentTextPrimary,
			secondary: colorComponentTextSecondary,
			disabled: colorComponentTextDisabled,
			white: colorComponentTextWhite,
			info: colorComponentTextInfo,
			warning: colorComponentTextWarning,
			error: colorComponentTextError,
			success: colorComponentTextSuccess,
			heading: colorComponentTextHeading,
		},
		background: {
			appMain: colorSemanticBackgroundAppMain,
			contentMain: colorSemanticBackgroundContentMain,
			backdropOverlay: colorSemanticBackgroundBackdropOverlay,
			navigationMain: colorSemanticBackgroundNavigationMain,
			greyLabel: colorSemanticBackgroundGreyLabel,
			info: colorSemanticBackgroundInfo,
			warning: colorSemanticBackgroundWarning,
		},
		divider: colorComponentBorderDivider,
		action: {
			active: colorSemanticActionActive,
			hover: colorSemanticActionHover,
			selected: colorSemanticActionSelected,
			disabled: colorSemanticActionDisabled,
			disabledBackground: colorSemanticActionDisabledBg,
			focus: colorSemanticActionFocus,
		},
		form: {
			borderResting: colorSemanticFormBorderResting,
			borderActive: colorSemanticFormBorderActive,
		},
		premium: {
			main: colorSemanticPremiumMain,
			lightHue: colorSemanticPremiumLightHue,
			lightestHue: colorSemanticPremiumLightestHue,
			darkHue: colorSemanticPremiumDarkHue,
		},
		common: {
			black: colorSemanticCommonBlack,
			white: colorSemanticCommonWhite,
			blackTransparentDark: colorSemanticCommonBlackTransparentDark,
			blackTransparentLight: colorSemanticCommonBlackTransparentLight,
		},
		neutral: {
			main: colorSemanticNeutralMain,
			lightHue: colorSemanticNeutralLightHue,
			lighterHue: colorSemanticNeutralLighterHue,
			lightestHue: colorSemanticNeutralLightestHue,
			darkHue: colorSemanticNeutralDarkHue,
			darkerHue: colorSemanticNeutralDarkerHue,
			darkestHue: colorSemanticNeutralDarkestHue,
			faint: colorSemanticNeutralFaint,
		},
		surface: {
			primary: colorComponentSurfacePrimary,
			secondary: colorComponentSurfaceSecondary,
			backdrop: colorComponentSurfaceBackdrop,
		},
		border: {
			main: colorComponentBorderMain,
			divider: colorComponentBorderDivider,
			focus: colorComponentBorderFocus,
		},
		yellow: {
			bright: colorSemanticYellowBright,
			lightestHue: colorSemanticYellowLightestHue,
		},
		...colors,
	},

	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
		values: {
			xs: 0,
			sm: 480,
			md: 768,
			lg: 1024,
			xl: 1536,
			xxl: 1920,
		},
	},

	typography: {
		h1Large: {
			fontSize: '3.125rem',
			fontWeight: 400,
			letterSpacing: '-0.25px',
			lineHeight: '3.625rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h1Medium: {
			fontSize: '2.625rem',
			fontWeight: 400,
			letterSpacing: '0.25px',
			lineHeight: '3.125rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h1Small: {
			fontSize: '2.125rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2.625rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h1XSmall: {
			fontSize: '1.75rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h2Large: {
			fontSize: '2.5rem',
			fontWeight: 400,
			letterSpacing: '0.25px',
			lineHeight: '3rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h2Medium: {
			fontSize: '2.25rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2.75rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h2Small: {
			fontSize: '1.875rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2.25rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h2XSmall: {
			fontSize: '1.5rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h3Large: {
			fontSize: '2rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2.5rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h3Medium: {
			fontSize: '1.75rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2.125rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h3Small: {
			fontSize: '1.5rem',
			fontWeight: 500,
			letterSpacing: '0.25px',
			lineHeight: '2rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h3XSmall: {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '0.25px',
			lineHeight: '1.75rem',
			fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
		},
		h4: {
			fontWeight: 700,
			fontSize: '1.125rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
		},
		body1: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
		},
		body1Link: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
		},
		body1Strikethrough: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
			textDecoration: 'line-through',
		},
		body1Semibold: {
			fontWeight: 600,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
		},
		body1SemiboldLink: {
			fontWeight: 600,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
			textDecoration: 'underline',
		},
		body1SemiboldStrikethrough: {
			fontWeight: 600,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.25px',
			textDecoration: 'line-through',
		},
		body2: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.15px',
		},
		body2Semibold: {
			fontWeight: 600,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.15px',
		},
		body2Link: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.15px',
			textDecoration: 'underline',
		},
		body2SemiboldLink: {
			fontWeight: 600,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.15px',
			textDecoration: 'underline',
		},
		caption: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: '1rem',
			letterSpacing: '0rem',
		},
		captionLink: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: '1rem',
			letterSpacing: '0rem',
			textDecoration: 'underline',
		},
		h1: {
			fontWeight: 400,
			fontSize: '3rem',
			lineHeight: 1.167,
			letterSpacing: '0em',
		},
		h2: {
			fontWeight: 400,
			fontSize: '2.125rem',
			lineHeight: 1.235,
			letterSpacing: '0.00735em',
		},
		h3: {
			fontWeight: 400,
			fontSize: '1.75rem',
			lineHeight: 1.334,
			letterSpacing: '0em',
		},
		h5: {
			fontWeight: 500,
			fontSize: '1.25rem',
			lineHeight: 1.6,
			letterSpacing: '0.0075em',
		},
		h6: {
			fontWeight: 400,
			fontSize: '1.125rem',
			lineHeight: 1.6,
			letterSpacing: '0.0075em',
		},
		subtitle1: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 1.75,
			letterSpacing: '0.00938em',
		},
		subtitle2: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: 1.57,
			letterSpacing: '0.00714em',
		},
	},

	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					// Legacy mapping, keeping for backward compatibility
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
					h6: 'h6',
					subtitle1: 'h6',
					subtitle2: 'h6',

					// New mapping
					h1Large: 'h1',
					h1Medium: 'h1',
					h1Small: 'h1',
					h1XSmall: 'h1',
					h2Large: 'h2',
					h2Medium: 'h2',
					h2Small: 'h2',
					h2XSmall: 'h2',
					h3Large: 'h3',
					h3Medium: 'h3',
					h3Small: 'h3',
					h3XSmall: 'h3',
					body1: 'p',
					body1Link: 'p',
					body1Strikethrough: 'p',
					body1Semibold: 'p',
					body1SemiboldLink: 'p',
					body1SemiboldStrikethrough: 'p',
					body2: 'span',
					body2Link: 'span',
					body2Semibold: 'span',
					body2SemiboldLink: 'span',
					caption: 'span',
					captionLink: 'span',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '0.875rem',
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: colorSemanticBackgroundGreyLabel,
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiCircularProgress: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'hover',
				sx: {
					textDecorationColor: 'initial',
				},
			},
		},
		MuiDivider: {
			defaultProps: {
				'aria-hidden': 'true',
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: ({ theme }) => ({
					'& .MuiBackdrop-root': {
						backgroundColor: alpha(theme.palette.background.backdropOverlay, 0.5),
					},
				}),
				container: ({ ownerState, theme }) => ({
					alignItems: ownerState.size === 'lg' ? 'center' : 'flex-start',
					padding: theme.spacing(2),
					paddingTop: ownerState.size === 'lg' ? theme.spacing(2) : theme.spacing(7),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
						paddingTop: ownerState.size === 'lg' ? theme.spacing(3) : theme.spacing(7),
					},
					// Fullscreen dialog specific styles
					...(ownerState.fullScreen && {
						[theme.breakpoints.up('xs')]: {
							width: '100%',
							height: '100%',
							padding: 0,
							paddingTop: 0,
							margin: 0,
							marginTop: 0,
						},
					}),
				}),
				paper: ({ ownerState, theme }) => ({
					margin: 0,
					flex: '1 1 0',
					...(ownerState.size === 'xs'
						? {
								// Extra-small Dialog
								[theme.breakpoints.up('xs')]: {
									maxWidth: '48%',
									maxHeight: '56%',
								},

								[theme.breakpoints.up('lg')]: {
									maxWidth: '40%',
								},
								[theme.breakpoints.up('xl')]: {
									maxWidth: '30%',
								},
								[theme.breakpoints.up('xxl')]: {
									maxWidth: '24%',
								},
						  }
						: ownerState.size === 'sm'
						? {
								// Small Dialog
								[theme.breakpoints.up('xs')]: {
									maxWidth: '61%',
									maxHeight: '82%',
								},

								[theme.breakpoints.up('lg')]: {
									maxWidth: '48%',
								},
								[theme.breakpoints.up('xl')]: {
									maxWidth: '37%',
								},
						  }
						: ownerState.size === 'md'
						? {
								// Medium Dialog
								[theme.breakpoints.up('xs')]: {
									maxWidth: '74%',
									maxHeight: '86%',
								},

								[theme.breakpoints.up('lg')]: {
									maxWidth: '66%',
								},
								[theme.breakpoints.up('xl')]: {
									maxWidth: '49%',
								},
						  }
						: ownerState.size === 'lg'
						? {
								// Large Dialog
								[theme.breakpoints.up('xs')]: {
									width: '87%',
									maxHeight: '96%',
								},

								[theme.breakpoints.up('lg')]: {
									width: '83%',
								},
								[theme.breakpoints.up('xl')]: {
									width: '75%',
								},
						  }
						: {}),
				}),
			},
			defaultProps: {
				maxWidth: 'md',
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: ({ theme }) => ({
					'@container (max-width: 368px)': {
						padding: theme.spacing(2),
						paddingTop: theme.spacing(3),
					},
					padding: theme.spacing(3),
					paddingTop: theme.spacing(4),
					paddingBottom: theme.spacing(2),
				}),
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: ({ theme }) => ({
					'@container (max-width: 368px)': {
						paddingTop: theme.spacing(1),
						paddingRight: theme.spacing(2),
						paddingBottom: theme.spacing(2),
						paddingLeft: theme.spacing(2),
					},
					padding: theme.spacing(3),
					paddingTop: theme.spacing(1),
					paddingBottom: theme.spacing(2),
				}),
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: ({ theme }) => ({
					/* Add in Container queries in MUI v6 */
					//https://next.mui.com/material-ui/customization/container-queries/
					// [theme.containerQueries.down(369)]: {
					// 	flexDirection: 'column',
					// 	alignItems: 'stretch',
					//  ... },
					'@container (max-width: 368px)': {
						flexDirection: 'column-reverse',
						alignItems: 'stretch',
						padding: theme.spacing(2),
						gap: theme.spacing(1),
					},
					padding: theme.spacing(3),
					paddingTop: theme.spacing(2),
					gap: theme.spacing(3),
					'& > :not(:first-of-type)': {
						marginLeft: 0,
					},
				}),
			},
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					fontWeight: 400,
					fontSize: '1rem',
					lineHeight: '1.5rem',
					letterSpacing: '0.25px',
					...(ownerState.severity === 'info'
						? {
								backgroundColor: theme.palette.info.lightestHue,
								color: theme.palette.info.main,
								border: `1px solid ${theme.palette.info.main}`,
						  }
						: ownerState.severity === 'warning'
						? {
								backgroundColor: theme.palette.warning.lightestHue,
								color: theme.palette.warning.darkHue,
								border: `1px solid ${theme.palette.warning.darkHue}`,
						  }
						: ownerState.severity === 'error'
						? {
								backgroundColor: theme.palette.error.lightestHue,
								color: theme.palette.error.darkHue,
								border: `1px solid ${theme.palette.error.darkHue}`,
						  }
						: ownerState.severity === 'success'
						? {
								backgroundColor: theme.palette.success.lightestHue,
								color: theme.palette.success.main,
								border: `1px solid ${theme.palette.success.main}`,
						  }
						: {}),
					'& .MuiAlert-icon': {
						...(ownerState.severity === 'info'
							? {
									color: theme.palette.info.main,
							  }
							: ownerState.severity === 'warning'
							? {
									color: theme.palette.warning.darkHue,
							  }
							: ownerState.severity === 'error'
							? {
									color: theme.palette.error.darkHue,
							  }
							: ownerState.severity === 'success'
							? {
									color: theme.palette.success.main,
							  }
							: {}),
					},
				}),
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: {
					fontWeight: 600,
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&.Mui-selected': {
						backgroundColor: `${theme.palette.neutral.lightHue}`,
						':hover': {
							backgroundColor: `${theme.palette.primary.main}`,
							color: theme.palette.common.white,
						},
					},
					':hover': {
						backgroundColor: `${theme.palette.primary.main}`,
						color: theme.palette.common.white,
					},
				}),
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					'@media only print': {
						display: 'none',
					},
				},
			},
		},
	},
}

export const theme = createTheme(themeOptions)

export type PersuitTheme = typeof theme
