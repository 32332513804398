import * as React from 'react'

import {
	DoneAllIcon,
	DoneIcon,
	useTheme,
	Box,
	Spacer,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	StarIcon,
	StarBorderIcon,
	IconButton,
} from '@persuit/ui-components'

import NamwolfLogo from '../../../components/namwolf/namwolf-logo'
import { PanelPreferredFirmLogo } from '../../../components/panel-preferred-firm/panel-preferred-firm-logo'
import { ApprovedFirmLogo } from '../../../components/approved-firm/approved-firm-logo'

type PeopleSearchListItemProps = {
	item: {
		_id: string
		org: {
			name: string
			isNamwolfMember: boolean
		}
		name: string
		isFavourite?: boolean | null

		nameHighlight: React.ReactNode
		orgNameHighlight: React.ReactNode
	}
	previouslySelected: boolean
	selected: boolean

	isPanelFirm?: boolean
	isApprovedFirm?: boolean
	addToFavourites?: (id: string) => void

	onSelect: (itemId: string) => void
	onRemove: (itemId: string) => void
}

export const PeopleSearchListItem = ({
	item,
	previouslySelected,
	selected,
	addToFavourites,
	isPanelFirm = false,
	isApprovedFirm = false,
	onSelect,
	onRemove,
}: PeopleSearchListItemProps) => {
	const theme = useTheme()

	const text = (
		<ListItemText
			sx={{ opacity: previouslySelected ? 0.5 : undefined }}
			primaryTypographyProps={{
				color: previouslySelected ? theme.palette.text.secondary : undefined,
			}}
			secondaryTypographyProps={{
				color: previouslySelected ? theme.palette.text.secondary : undefined,
			}}
			primary={<span data-testid="search-list-item">{item.nameHighlight}</span>}
			secondary={
				<span>
					{item.orgNameHighlight}
					<Spacer shape="column" space={0.5} />
					<Box display="inline-block">
						<Box display="flex" gap="4px">
							{isApprovedFirm && <ApprovedFirmLogo />}
							{isPanelFirm && <PanelPreferredFirmLogo />}
							{item.org.isNamwolfMember && <NamwolfLogo />}
						</Box>
					</Box>
				</span>
			}
		/>
	)

	const itemName = `${item.name} ${item.org.name}`

	return (
		<ListItem
			sx={{ py: 0 }}
			aria-label={`${item.name}, ${previouslySelected || selected ? 'selected' : 'not selected'}`}
			secondaryAction={
				previouslySelected ? (
					<DoneAllIcon sx={{ color: theme.palette.teal400 }} />
				) : selected ? (
					<DoneIcon sx={{ color: theme.palette.teal400 }} />
				) : null
			}
		>
			<ListItemIcon>
				<IconButton
					size="large"
					onClick={(e) => {
						e.stopPropagation()
						addToFavourites?.(item._id)
					}}
					aria-label={`${
						item.isFavourite ? 'remove from favorites' : 'add to favorites'
					}, ${itemName}`}
				>
					{item.isFavourite ? (
						<StarIcon sx={{ color: theme.palette.amber600 }} />
					) : (
						<StarBorderIcon />
					)}
				</IconButton>
			</ListItemIcon>
			{previouslySelected ? (
				text
			) : (
				<ListItemButton
					sx={{ p: 0 }}
					aria-label={`${selected ? 'unselect' : 'select'} ${itemName}`}
					onClick={
						previouslySelected
							? undefined
							: selected
							? () => onRemove(item._id)
							: () => onSelect(item._id)
					}
				>
					{text}
				</ListItemButton>
			)}
		</ListItem>
	)
}
