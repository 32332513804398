// @ts-strict-ignore
import { generatePath } from 'react-router-dom'
import { getCardTitle } from '../get-card-title'
import { makeGetCardLink } from '../get-card-link'
import { makeGenericHandlerMaker } from '../generic-handler'
import { makePricingHandlerMaker } from '../pricing-handler'
import { makeQuestionsHandlerMaker } from '../questions-handler'
import { makeQuestionGroupsHandlerMaker } from '../question-groups-handler'
import { makeScorecardHandlerMaker } from '../scorecard-handler'
import { makeRatesHandlerMaker } from '../rates-handler'
import { ErrorCardTransformer } from '../../../../sectional-form'

const getCardLink = makeGetCardLink({ generatePath })
const makeGenericHandler = makeGenericHandlerMaker({ getCardTitle, getCardLink })
const makePricingHandler = makePricingHandlerMaker({ getCardTitle, getCardLink })
const makeRatesHandler = makeRatesHandlerMaker({ getCardLink })

const makeScorecardHandler = makeScorecardHandlerMaker({ getCardLink })
const makeQuestionsHandler = makeQuestionsHandlerMaker({ getCardLink })
const makeQuestionGroupsHandler = makeQuestionGroupsHandlerMaker({ getCardLink })

export const makeHandlers = (path: string, templateId: string): Array<ErrorCardTransformer> => [
	{
		sectionName: 'headline',
		sectionHandler: makeGenericHandler({
			sectionName: 'headline',
			titleOverride: 'Title',
		}),
	},
	{
		sectionName: 'detail',
		sectionHandler: makeGenericHandler({ sectionName: 'detail', path, templateId }),
	},
	{
		sectionName: 'summary',
		sectionHandler: makeGenericHandler({ sectionName: 'summary', path, templateId }),
	},
	{
		sectionName: 'rates',
		sectionHandler: makeRatesHandler({ sectionName: 'rates', path, templateId }),
	},
	{
		sectionName: 'pricing',
		sectionHandler: makePricingHandler({ sectionName: 'pricing', path, templateId }),
	},
	{
		sectionName: 'questionGroups',
		sectionHandler: makeQuestionGroupsHandler({ sectionName: 'questions', path, templateId }),
	},
	{
		sectionName: 'questions',
		sectionHandler: makeQuestionsHandler({ sectionName: 'questions', path, templateId }),
	},
	{
		sectionName: 'conflict',
		sectionHandler: makeGenericHandler({
			sectionName: 'conflict',
			path,
			templateId,
			titleOverride: 'Conflict Check',
		}),
	},
	{
		sectionName: 'scorecard',
		sectionHandler: makeScorecardHandler({ sectionName: 'scorecard', path, templateId }),
	},
]
