import { isNotNil, isNotNilProp, RequiredNonNullableKeys } from '@persuit/common-utils'
import { clientPhaseModel } from '../pricing-models'

type Proposal = {
	responseToDeliverables?: (ResponseToDeliverable | null)[] | null
}

type ResponseToDeliverable = {
	responseToRates?: (ResponseToRate | null)[] | null
	firmSuppliedRates?: (ResponseToRate | null)[] | null
	pricingModel?: string | null
	price?: number | string | null
	delta?: {
		price?: number | null
		direction?: 'up' | 'down' | string | null
		percentage?: string | number | null
	} | null
	notIncluded?: boolean | null
}

type ResponseToRate = {
	rate?: number | string | null
}

type RequiredResponseToRate = RequiredNonNullableKeys<ResponseToRate, 'rate'>

export function averageRate(
	rates: RequiredResponseToRate[],
	{ round = true }: { round?: boolean } = {},
) {
	if (rates.length === 0) return 0
	const total = rates.reduce((sum, rateObj) => sum + Number(rateObj.rate), 0)

	if (round) {
		return Math.round(total / rates.length)
	} else {
		return total / rates.length
	}
}

/**
 * Pure function to calculate average rate for an RfpProposal
 * Sharable between backend and frontend
 */
export function averageProposalRates({ responseToDeliverables = [] }: Proposal) {
	const rates = (responseToDeliverables?.filter(isNotNil) ?? [])
		.reduce<RequiredResponseToRate[]>((acc, curr) => {
			if (curr.notIncluded) {
				return acc
			} else if (curr.pricingModel === clientPhaseModel.HOURLYRATE) {
				return [
					...acc,
					{
						rate: Number(curr.price) ?? 0,
					},
				]
			} else if (
				curr.pricingModel === clientPhaseModel.RATECARD &&
				(curr?.responseToRates?.length || curr?.firmSuppliedRates?.length)
			) {
				const { responseToRates, firmSuppliedRates } = curr ?? {}
				const combinedRates = [...(responseToRates ?? []), ...(firmSuppliedRates ?? [])]
					.filter(isNotNil)
					.filter(isNotNilProp('rate'))
					.filter(({ rate }) => rate)

				return [
					...acc,
					{
						rate: averageRate(combinedRates),
					},
				]
			} else {
				return acc
			}
		}, [])
		/** Only consider non 0 rate because of a bad assumption on frontend that null rate is 0 */
		.filter(({ rate }) => rate)

	if (rates.length === 0) {
		return null
	}

	return averageRate(rates)
}

/**
 * To return originalAverageRate, this function assumes that the proposal history has been enriched
 * and therefore optional delta has been set.
 */
export function averageDeliverableRate(responseToDeliverable: ResponseToDeliverable) {
	const averageRate = averageProposalRates({ responseToDeliverables: [responseToDeliverable] })
	const averageRateDelta = responseToDeliverable.delta?.price
	const percentage = responseToDeliverable.delta?.percentage

	const deltaPercentage = percentage
		? responseToDeliverable.delta?.direction === 'up'
			? Number(percentage) * -1
			: Number(percentage)
		: null

	const originalAverageRate =
		averageRateDelta && averageRate
			? responseToDeliverable.delta?.direction === 'up'
				? averageRate - averageRateDelta
				: averageRate + averageRateDelta
			: null

	return { averageRate, originalAverageRate, deltaPercentage }
}
