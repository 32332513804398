// @ts-strict-ignore
import { isEmpty } from 'lodash/fp'
import { ErrorCardData } from 'packages/ui-shared-components/src/sectional-form'
import { RfpQuestionGroupError } from '../../../../types'

export const getQuestionGroupsErrors = (sectionErrors, link) => {
	if (isEmpty(sectionErrors)) return []

	return sectionErrors.map((sectionError) => {
		return {
			title: `Question Group ${sectionError.index + 1}`,
			errorMessages: [sectionError.name, sectionError.length],
			link: `${link}/question_group_${sectionError.index + 1}`,
			subSections: [],
		}
	})
}

export const makeQuestionGroupsHandlerMaker =
	({ getCardLink }) =>
	({ sectionName, path = '', templateId = '' }) =>
	(sectionErrors: Array<RfpQuestionGroupError> | null): Array<ErrorCardData> => {
		if (sectionErrors === null || isEmpty(sectionErrors)) return []
		const link = getCardLink({ path, templateId, sectionName })

		const questionGroupsErrors = getQuestionGroupsErrors(sectionErrors, link)

		return [...questionGroupsErrors]
	}
