import { Button, ButtonProps, styled } from '@persuit/ui-components'

const StyledButton = styled(Button)`
	position: fixed;
	left: -9999px;
	z-index: 9999;
	margin: 1em;
	opacity: 0;

	&:focus {
		left: 50%;
		transform: translateX(-50%);
		opacity: 1;
	}
`

export type SkipToContentButtonProps = { href: string } & ButtonProps

export const SkipToContentButton = ({ href, ...props }: SkipToContentButtonProps) => {
	return (
		<StyledButton href={href} variant="contained" color="primary" {...props}>
			Skip To Main Content
		</StyledButton>
	)
}
