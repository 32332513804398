import { format } from 'date-fns'
import { Box, TableCell, TableRow, Typography } from '@persuit/ui-components'
import { formatPrice } from '../utils'

export type Row = {
	jobTitle: string
	firstName: string
	lastName: string
	proposedRate: number
	previousProposedRate: number
}

type NamedTimekeepersPriceHistoryRowProps = {
	updatedAt: number | null
	proposedRate: number
	timekeeperLabel: string
	firstname: string
	lastname: string
	previousProposedRate: number
	currency: string
	previousCurrency: string
}

export const NamedTimekeepersPriceHistoryRow = ({
	updatedAt,
	proposedRate,
	timekeeperLabel,
	firstname,
	lastname,
	previousProposedRate,
	currency,
	previousCurrency,
}: NamedTimekeepersPriceHistoryRowProps) => {
	const prevPrice = formatPrice(previousCurrency, previousProposedRate)
	const currentPrice = formatPrice(currency, proposedRate)
	const lastUpdatedDate = updatedAt ? format(updatedAt, 'dd MMM y, hh:mma') : null

	return (
		<TableRow key={`${timekeeperLabel}:${updatedAt}`}>
			<TableCell align="center" sx={{ width: '8em', whiteSpace: 'nowrap' }}>
				{lastUpdatedDate}
			</TableCell>
			<TableCell align="left">{timekeeperLabel}</TableCell>
			<TableCell align="left">{firstname}</TableCell>
			<TableCell align="left">{lastname}</TableCell>
			<TableCell align="right">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
					gap={2}
					sx={{ whiteSpace: 'nowrap' }}
				>
					<span aria-label={`Price updated from ${prevPrice} to ${currentPrice}`}>
						<Typography role="deletion" component="del" strikeThrough={true} fontSize="inherit">
							{prevPrice}
						</Typography>
						&nbsp;
						<ins role="insertion" style={{ textDecoration: 'none' }}>
							{currentPrice}
						</ins>
					</span>
				</Box>
			</TableCell>
		</TableRow>
	)
}
