// @ts-strict-ignore
const states = {
	PUBLISH: 'publish',
	WITHDRAW: 'withdraw',
	REVISE: 'revise',
	PUBLISH_REVISED_PROPOSAL: 'publish-revised-proposal',
	REVISE_PRICE: 'revise-price',
	ACCEPT: 'accept',
	UNACCEPT: 'unaccept',
	ADD_FEEDBACK: 'add_feedback',
} as const

export type ActivityLogActions = (typeof states)[keyof typeof states]

const values = Object.keys(states).map((key) => states[key])

export { values, states as default }
