import getOr from 'lodash/fp/getOr'

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {undefined | path} The value of the property in question or if not present, return undefined
 * @description This function returns the value of property path in object
 *   or if it is not present returns undefined.
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrUndefined('a.b', obj) => 5
 *
 *   getOrUndefined('b', obj) => undefined
 */
export const getOrUndefined = getOr(undefined)

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {null | path} The value of the property in question or if not present, return null
 * @description This function returns the value of property path in object
 *   or if it is not present returns null.
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrNull('a.b', obj) => 5
 *
 *   getOrNull('b', obj) => null
 */
export const getOrNull = getOr(null)

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {int | path} The value of the property in question or if not present, return 0
 * @description This function returns the value of property path in object
 *   or if it is not present returns 0.
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrZero('a.b', obj) => 5
 *
 *   getOrZero('b', obj) => 0
 */
export const getOrZero = getOr(0)

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {string | path} The value of the property in question or if not present, return empty string ('')
 * @description This function returns the value of property path in object
 *   or if it is not present returns empty string ('').
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrEmptyString('a.b', obj) => 5
 *
 *   getOrEmptyString('b', obj) => ''
 */
export const getOrEmptyString = getOr('')

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {boolean | path} The value of the property in question or if not present, return true
 * @description This function returns the value of property path in object
 *   or if it is not present returns true.
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrTrue('a.b', obj) => 5
 *
 *   getOrTrue('b', obj) => true
 */
export const getOrTrue = getOr(true)

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {boolean | path} The value of the property in question or if not present, return false
 * @description This function returns the value of property path in object
 *   or if it is not present returns false.
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrFalse('a.b', obj) => 5
 *
 *   getOrFalse('b', obj) => false
 */
export const getOrFalse = getOr(false)

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {array | path} The value of the property in question or if not present, return empty array
 * @description This function returns the value of property path in object
 *   or if it is not present returns empty array ([]).
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrEmptyArray('a.b', obj) => 5
 *
 *   getOrEmptyArray('b', obj) => []
 */
export const getOrEmptyArray = getOr([])

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {object | path} The value of the property in question or if not present, return empty object
 * @description This function returns the value of property path in object
 *   or if it is not present returns empty object ({}).
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrEmptyObject('a.b', obj) => 5
 *
 *   getOrEmptyObject('b', obj) => {}
 */
export const getOrEmptyObject = getOr({})

/**
 * @param {string} path - Property path
 * @param {object} object - Object / Array value
 * @return {function | path} The value of the property in question or if not present, return empty function call
 * @description This function returns the value of property path in object
 *   or if it is not present returns empty function call (() => {}).
 *
 *   It takes 2 arguments
 *
 *   e.g.
 *
 *   const obj = {
 *       a: {
 *           b: 5
 *       }
 *   }
 *
 *   getOrNoOp('a.b', obj) => 5
 *
 *   getOrNoOp('b', obj) => () => {}
 */
export const getOrNoOp = getOr(() => {})
