import { isEmpty } from 'lodash/fp'
import { useState } from 'react'
import * as React from 'react'
import remapCustomFields from '../../common/remap-custom-fields'
import validateCustomTrackingFields from '../../common/validators/validate-custom-tracking-fields'
import { Container } from '../components/grid'
import useTranslation from '../custom-hooks/translation-hook'
import SAVE_TRACKING_MUTATION from '../graphql/mutations/saveTracking-gql'
import cleanTrackingFields from './request/helpers/clean-tracking-fields'
import { Box, LoadingSpinner, useSnackbar, styled } from '@persuit/ui-components'
import { TrackingForm } from '@persuit/ui-shared-components'
import { isNotNil } from '@persuit/common-utils'
import { useUser, useUAMEnabled } from '@persuit/ui-auth'
import { useMutation, graphql } from '@persuit/ui-graphql'

const StyledContainer = styled(Container)`
	background-color: white;
	padding: 1em;
	margin-top: 0;
`

const SYNC_TRACKING_FIELDS_PUBLISHED_RFP = graphql(`
	mutation RequestTrackingTabSyncTrackingFieldsPublishedRfp(
		$rfpId: ID!
		$externalId: String!
		$matterType: String
	) {
		syncTrackingFieldsPublishedRfp(
			rfpId: $rfpId
			externalId: $externalId
			matterType: $matterType
		) {
			tracking {
				referenceNo
				customFields {
					fieldType
					customTrackingFieldId
					hierarchyValue
					plainTextValue
					richTextValue
					numericValue
					dateValue
				}
			}
		}
	}
`)

export const RequestTracking = ({ request }) => {
	const [trackingData, setTrackingData] = React.useState(request.tracking)
	const { user, loading } = useUser()
	const { enabled: isUAMToggledOn } = useUAMEnabled()

	const { t } = useTranslation()
	const { openSnackbar } = useSnackbar()
	const [saveTracking] = useMutation(SAVE_TRACKING_MUTATION)
	const [trackingErrors, setTrackingErrors] = useState([])
	const [syncTrackingFieldsMutation] = useMutation(SYNC_TRACKING_FIELDS_PUBLISHED_RFP, {
		onCompleted: (data) => {
			setTrackingData(data.syncTrackingFieldsPublishedRfp.tracking)
		},
	})

	if (loading) {
		// Wait for the getOwnOrg query to complete before attempting
		// to render this component (needed to generate initial values)
		return <LoadingSpinner />
	}
	const org = user.org

	const handleSave = async (values) => {
		const { tracking } = values

		const cleanTracking = cleanTrackingFields(tracking)

		// Validate rich text fields
		// This will return an array of errors or an array of nulls
		const errors = validateCustomTrackingFields(tracking.customFields, true)

		if (errors) {
			const translatedCustomFieldErrors = errors
				.map((customFieldError, index) => {
					if (!customFieldError || isEmpty(customFieldError)) return undefined
					const customFieldErrorKeys = Object.keys(customFieldError)
					if (isEmpty(customFieldErrorKeys)) return undefined
					const customFieldErrorKey = customFieldErrorKeys[0]
					return {
						index,
						message: t(customFieldError[customFieldErrorKey]),
					}
				})
				.filter(isNotNil)

			setTrackingErrors(translatedCustomFieldErrors)
		} else {
			setTrackingErrors([])
			try {
				await saveTracking({
					variables: {
						payload: {
							_id: request._id,
							tracking: cleanTracking,
						},
					},
				})

				openSnackbar(t('request.form.saved'))
			} catch (e) {
				openSnackbar(e?.message || 'Failed to save request. Please try again.', {
					variant: 'error',
				})
			}
		}
	}

	const initialValues = {
		tracking: {
			customFields: [],
		},
	}

	const customTrackingFieldSettings = isUAMToggledOn
		? request.group.settings.customTrackingFields
		: org.settings.customTrackingFields

	if (trackingData) {
		initialValues.tracking.referenceNo = trackingData.referenceNo

		const trackedFieldsOnRequest = trackingData.customFields

		initialValues.tracking.customFields = remapCustomFields(
			customTrackingFieldSettings,
			trackedFieldsOnRequest,
		)
	}

	const syncTrackingFields = async ({ externalId, matterType }) => {
		await syncTrackingFieldsMutation({
			variables: {
				rfpId: request._id,
				externalId,
				matterType,
			},
		})
	}

	return (
		<StyledContainer>
			<Box position="relative">
				<Box p={1.5}>
					<TrackingForm
						showTitle={false}
						customTrackingFields={customTrackingFieldSettings}
						trackingData={initialValues.tracking}
						saveSection={handleSave}
						trackingErrors={trackingErrors}
						rfpId={request._id}
						handleSync={syncTrackingFields}
					/>
				</Box>
			</Box>
		</StyledContainer>
	)
}
