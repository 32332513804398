import { Rfp } from '@persuit/ui-graphql/generated'
import { generatePath, useRouteMatch } from 'react-router-dom'
import {
	getNextSection,
	getPreviousSection,
	getSectionLabel,
	RfpSection,
	SectionData,
	SectionNavigation,
} from '../sectional-form'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

function convertSectionToProps(
	section: string,
	path: string,
	rfp: Pick<Rfp, '_id' | 'useCase'>,
	panelManagementIsEnabled: boolean,
): SectionData | undefined {
	if (!section) return undefined
	const label = getSectionLabel(
		rfp.useCase ?? undefined,
		section as RfpSection,
		panelManagementIsEnabled,
	)
	const link = generatePath(path, { lng: 'en', requestId: rfp._id, section })

	return { label, link }
}

type RfpDraftFormSectionNavigationProps = {
	currentSection: string
	rfp: Pick<Rfp, '_id' | 'useCase'>
	enabledSections: readonly RfpSection[]
}

export const RfpSectionNavigation = ({
	currentSection,
	rfp,
	enabledSections,
}: RfpDraftFormSectionNavigationProps) => {
	const { toggles } = useFeatureToggles()
	const { path } = useRouteMatch()

	const previousSection = getPreviousSection(enabledSections, currentSection)
	const nextSection = getNextSection(enabledSections, currentSection)
	const previousSectionProps = convertSectionToProps(
		previousSection,
		path,
		rfp,
		toggles['dev-8710.panel-management'],
	)
	const nextSectionProps = convertSectionToProps(
		nextSection,
		path,
		rfp,
		toggles['dev-8710.panel-management'],
	)

	return (
		<SectionNavigation
			previousSection={previousSectionProps}
			nextSection={nextSectionProps}
			isFloating={currentSection === 'pricing' || currentSection === 'questions'}
		/>
	)
}
