import { wait } from '../wait'

export type PollUntilInput<T> = {
	/** Function to keep polling */
	pollingFunction: () => Promise<T>
	/** When polling should stop */
	doneCondition: (data: T) => boolean
	onPolledResult?: (data: T) => void
	pollingInterval?: number
	maxTries?: number
}

export async function pollUntil<T>({
	maxTries = Infinity,
	onPolledResult,
	doneCondition,
	pollingFunction,
	pollingInterval = 500,
}: PollUntilInput<T>): Promise<T> {
	let tries = 1

	async function poll(): Promise<T> {
		if (tries++ > maxTries) {
			throw new Error(`pollUntil has passed max tries of ${maxTries}`)
		}

		const data = await pollingFunction()
		onPolledResult?.(data)
		if (doneCondition(data)) {
			return data
		}

		await wait(pollingInterval)
		return poll()
	}

	return poll()
}
