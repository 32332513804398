import { Box, Typography } from '@persuit/ui-components'
import { getCurrencyName } from '@persuit/common-utils'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { CompareTable, CompareTableProps } from './compare-table'

type Proposal = CompareTableProps['proposals'][number]
type Rfp = CompareTableProps['rfp']

type RfpRatesTabProps = {
	proposals: Proposal[]
	rfp: Rfp
}

export const RfpRatesTab = ({ rfp, proposals }: RfpRatesTabProps) => {
	const isMultiCurrencyEnabled = useFeatureToggles().toggles['dev-10954.multi-currency']
	const rateReview = rfp.rateReview

	if (!rateReview) {
		return null
	}

	const currency = rateReview.currency

	return (
		<Box p={2}>
			<Typography variant="h2XSmall" gutterBottom={true}>
				Compare Proposed Rates
			</Typography>
			{isMultiCurrencyEnabled ? (
				<Typography>
					This table displays a blended average of the firm's rate cards for your timekeeper levels
					in the selected currency. Use the filters to choose the desired currency, and you can also
					filter by individual or multiple firms, specific locations, or practice areas if provided
					by your firms.
				</Typography>
			) : (
				<Typography>
					This table displays a blended average of the firm's rate cards for your timekeeper levels.
					Filter by individual or multiple firms, specific locations, or practice areas if provided
					by your firms.
				</Typography>
			)}
			{!isMultiCurrencyEnabled && (
				<Typography mt={2}>
					Currency: {getCurrencyName(currency)} ({currency})
				</Typography>
			)}

			<CompareTable rfp={rfp} proposals={proposals} />
		</Box>
	)
}
