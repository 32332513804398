import { Divider } from '@persuit/ui-components'

import DialogWizardContent from '../../dialog-wizard-content'
import { defaultSteps } from '../../dialog-wizard-steps'

import DialogWizardListOption from '../../dialog-wizard-list-option'
import notificationOptions from './notification-option-enums'
import { stepIndex } from '../../utils'

const option1Bullets = [
	'The unsuccessful firm will be notified.',
	'All proposals from this firm will be marked as unsuccessful.',
	'The firm’s dashboard will indicate the unsuccessful status of the proposal.',
]

const option2Bullets = [
	'The unsuccessful firm will not be notified.',
	'All proposals from this firm will be marked as unsuccessful.',
	'The firm’s dashboard will indicate the unsuccessful status of the proposal.',
]

function OptionEliminateFirmOptions({ onChange, onPrev, onNext, onCancel }) {
	const onSelectOption = (value) => () => {
		onChange({
			sendFirmEmailsSelected: value,
		})
		onNext()
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'ACTIONS'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Mark firm as unsuccessful',
			}}
			onPrev={onPrev}
			onCancel={onCancel}
		>
			<Divider />
			<DialogWizardListOption
				dataTestid="notify-unsuccessful-firm-option"
				primaryText="Notify unsuccessful firm"
				bullets={option1Bullets}
				onClick={onSelectOption(notificationOptions.SEND_ELIMINATE_FIRM_NOTIFICATIONS)}
			/>
			<DialogWizardListOption
				dataTestid="dont-notify-unsuccessful-firm"
				primaryText="Do not send notifications"
				bullets={option2Bullets}
				onClick={onSelectOption(notificationOptions.DONT_SEND_ELIMINATE_FIRM_NOTIFICATIONS)}
			/>
		</DialogWizardContent>
	)
}

export default OptionEliminateFirmOptions
