import { NavItem } from '@client/components/nav/nav-components'
import { SettingsIcon } from '@persuit/ui-components'
import { useQuery, graphql } from '@persuit/ui-graphql'

const getGroupGrantsQuery = graphql(`
	query SettingsNavItem_GetGroups {
		getSessionInfo {
			groups {
				_id
				grants
			}
		}
	}
`)

export type SettingsNavItemProps = {
	onClick: () => void
}

export function SettingsNavItem({ onClick }: SettingsNavItemProps) {
	const isUamEnabled = true // dev-5192.user-access-management
	const { data } = useQuery(getGroupGrantsQuery, { skip: !isUamEnabled })

	const showSettings =
		!isUamEnabled || data?.getSessionInfo.groups[0]?.grants.includes('UPDATE_SETTINGS')

	if (showSettings) {
		return (
			<NavItem to="/en/settings/" onClick={onClick} icon={<SettingsIcon />}>
				Settings
			</NavItem>
		)
	} else {
		return null
	}
}
