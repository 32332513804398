import { memo } from 'react'
import { Box, IconButton, MoreVertIcon, ExpandableCard, DragHandler } from '@persuit/ui-components'
import { PricingItemHeader, PricingItemHeaderProps } from './pricing-item-header'
import { getPricingError } from './get-pricing-error'
import { PricingListContextProviderProps } from '../../pricing-list-context'

type PricingItemOverlayProps = {
	id: string
	pricingItemHeaderProps: PricingItemHeaderProps
	expanded: boolean
	errors: PricingListContextProviderProps['errors']
}

export const PricingItemOverlay = memo(
	({ pricingItemHeaderProps, errors, expanded }: PricingItemOverlayProps) => {
		return (
			<ExpandableCard
				summaryPrefix={
					<Box display="flex" alignItems={'center'}>
						<DragHandler />
					</Box>
				}
				summary={<PricingItemHeader {...pricingItemHeaderProps} />}
				summarySuffix={
					<>
						<IconButton
							aria-label="Pricing item actions"
							aria-controls="long-menu"
							aria-haspopup="true"
							color="primary"
							size="large"
						>
							<MoreVertIcon />
						</IconButton>
					</>
				}
				hasError={!!getPricingError(errors, pricingItemHeaderProps.pricingItemIndex)}
				expanded={expanded}
				details={null}
			/>
		)
	},
	(prevProps, nextProps) => prevProps.id !== nextProps.id,
)

PricingItemOverlay.displayName = 'PricingItemOverlay'
