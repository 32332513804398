import { createSelector } from 'reselect'
import { State, StoreApi, RateCard, Rfp, Proposal } from './store'
import {
	generateColumnDefinitions,
	generateRowData,
	getRowProposals,
	getProposalRevisions,
	LatestFirmProposals,
} from '../column-definitions'
import { uniqBy, uniq } from 'lodash'
import { isNotNil, isNotNilProp } from '@persuit/common-utils'

const rfp = (state: State) => state.rfp
const proposals = (state: State) => state.proposals

const rowProposals = createSelector(proposals, (proposals) => getRowProposals(proposals))

const proposalDetailData = createSelector(
	proposals,
	(state: State) => state.proposalDetailId,
	(proposals, firmId) => {
		const rowProposals = getRowProposals(proposals)
		const latestProposals = rowProposals.find((rp) => rp.proposal?.org?._id === firmId) ?? null

		if (!latestProposals) return null

		return {
			...latestProposals,
			proposalRevisions: getProposalRevisions(latestProposals.proposal.proposalFamilyId, proposals),
		}
	},
)

type RateCardFilters = {
	countryFilters: string[]
	regionFilters: string[]
	practiceAreaFilters: string[]
	currencyFilter: string | null
}

export function filterRateCards(
	rateCards: RateCard[],
	{ countryFilters, practiceAreaFilters, regionFilters, currencyFilter }: RateCardFilters,
): RateCard[] {
	return rateCards.filter((rateCard) => {
		const { currency, location, practiceAreas } = rateCard
		return (
			(!currencyFilter || currency === currencyFilter) &&
			(!countryFilters.length || countryFilters.includes(location?.country ?? '')) &&
			(!regionFilters.length ||
				location?.regions.some((region) => regionFilters.includes(region))) &&
			(!practiceAreaFilters.length ||
				practiceAreas.some((area) => practiceAreaFilters.includes(area)))
		)
	})
}

const filteredRowProposals = createSelector(
	rowProposals,
	(state: State) => state.firmFilters,
	(state: State) => state.countryFilters,
	(state: State) => state.regionFilters,
	(state: State) => state.practiceAreaFilters,
	(state: State) => state.currencyFilter,
	(
		rowProposals,
		firmFilters,
		countryFilters,
		regionFilters,
		practiceAreaFilters,
		currencyFilter,
	): LatestFirmProposals[] => {
		const rateCardFilters = { countryFilters, regionFilters, practiceAreaFilters, currencyFilter }

		return rowProposals
			.filter((p) =>
				firmFilters.length === 0 ? true : firmFilters.includes(p.proposal.org?._id ?? ''),
			)
			.map(({ proposal, revisedProposal, revisedProposals }) => ({
				revisedProposals: revisedProposals
					.map((p) => ({
						...p,
						rateCards: filterRateCards(p.rateCards, rateCardFilters),
					}))
					.filter((p) => p.rateCards.length > 0),
				proposal: {
					...proposal,
					rateCards: filterRateCards(proposal.rateCards, rateCardFilters),
				},
				revisedProposal: !revisedProposal
					? null
					: {
							...revisedProposal,
							rateCards: filterRateCards(revisedProposal.rateCards, rateCardFilters),
					  },
			}))
			.map(({ proposal, revisedProposal, revisedProposals }) => ({
				revisedProposals,
				proposal: proposal.rateCards.length === 0 ? null : proposal,
				revisedProposal:
					revisedProposal === null || revisedProposal.rateCards.length === 0
						? null
						: revisedProposal,
			}))
			.filter(isNotNilProp('proposal'))
	},
)

const columnDefinitions = createSelector(
	(state: State, storeApi: StoreApi) => storeApi,
	rfp,
	(storeApi, rfp) =>
		generateColumnDefinitions({
			rfp,
			currency: rfp.rateReview?.currency ?? '',
			storeApi,
		}),
)

const isProposalEliminated = (invites: Rfp['invites']) => (proposal: Proposal) => {
	const orgId = proposal.org?._id ?? ''

	const firmInvite = invites?.find((invite) => invite?.item?._id === orgId)

	const isFirmEliminated = firmInvite?.state?.eliminate?.eliminated
	return !isFirmEliminated
}

const firms = createSelector(rfp, proposals, ({ invites }, proposals) => {
	const filteredProposals = uniqBy(
		proposals
			.filter((p) => ['finalized', 'accepted', 'under-revision'].includes(p.status))
			.filter(isProposalEliminated(invites))
			.map((p) => p.org)
			.filter(isNotNil)
			.filter(isNotNilProp('_id'))
			.filter(isNotNilProp('name'))
			.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
		(org) => org?._id,
	)
	return filteredProposals
})

const relevantProposals = createSelector(rowProposals, (latestProposals) =>
	latestProposals.map(({ proposal }) => proposal).filter(isNotNil),
)

const countries = createSelector(relevantProposals, (proposals): string[] => {
	return uniq(
		proposals.flatMap((p) => p.rateCards.map((card) => card.location?.country)).filter(isNotNil),
	)
})

const regions = createSelector(relevantProposals, (proposals): string[] => {
	return uniq(
		proposals
			.flatMap((p) => p.rateCards.flatMap((card) => card.location?.regions))
			.filter(isNotNil),
	)
})

const practiceAreas = createSelector(relevantProposals, (proposals): string[] => {
	return uniq(proposals.flatMap((p) => p.rateCards.flatMap((card) => card.practiceAreas)))
})

const rateCardCurrencies = createSelector(relevantProposals, (proposals): string[] => {
	return proposals.flatMap((p) => p.rateCards.map((card) => card.currency)).filter(isNotNil)
})

const rowData = createSelector(
	rfp,
	filteredRowProposals,
	relevantProposals,
	(rfp, proposals, allProposals) => {
		const rfpTimekeepers = rfp.rateReview?.timekeepers ?? []
		const rowData = generateRowData({ rfp, proposals })

		const proposalsWithNoData = allProposals.filter(
			(p) => !rowData.rowData.find((row) => row.firmId === p.org?._id),
		)

		const emptyFirmRows = proposalsWithNoData.map((p) => {
			const firmId = p.org?._id
			return {
				id: firmId,
				firmId,
				isAverageRow: false,
				firmName: p.org?.name ?? '',
				weightedAverage: null,
				timekeepers: rfpTimekeepers.map((tk) => ({
					timekeeper: tk._id,
					revisedRate: null,
					revisedDelta: null,
					currentRate: null,
					proposedRate: null,
					delta: null,
				})),
			}
		})

		return {
			rowData: [...rowData.rowData, ...emptyFirmRows],
			averageRow: rowData.averageRow,
		}
	},
)

export const selectors = {
	rateCardCurrencies,
	filteredRowProposals,
	columnDefinitions,
	rowData,
	firms,
	countries,
	regions,
	practiceAreas,
	proposalDetailData,
}
