/**
 * A duplication of @persuit/common-logic/src/pricing-models. The reason is because this is used by non workspace code like src/notification.
 * Please use @persuit/common-logic instead of this unless you are working in notification app.
 */
const clientPricingModelEnum = {
	NONE: 'none',
	FIXEDFEE: 'fixedFee',
	CAPPEDFEE: 'cappedFee',
	COLLAREDFEE: 'collaredFee',
	CONTINGENCYFEE: 'contingencyFee',
	CONTINGENCYPERCENTAGE: 'contingencyPercentage',
	DISCOUNTPERCENTAGE: 'discountPercentage',
	SUCCESSFEE: 'successFee',
	HOURLYRATES: 'hourlyRates',
	OTHER: 'other',
	ESTIMATE: 'estimate',
	INCLUDED: 'included',
	NOTAPPLICABLE: 'notApplicable',
	////////////////////////
	AFA: 'afa',
	BUDGETINGESTIMATE: 'estimate', // BUDGETINGESTIMATE is called estimate to keep in sync with legacy and prevent migration
	HOURLYRATE: 'hourlyRate',
	INCLUDEDINITEMTOTAL: 'includedInItemTotal',
	INCLUDEDINTOTAL: 'included', // INCLUDEDINTOTAL is called included to keep in sync with legacy and prevent migration
	RATECARD: 'hourlyRates', // RATECARD is called hourlyRates to keep in sync with legacy and prevent migration
	RATES: 'rates',
	EMPTY: '',
} as const

const firmPricingModelEnum = {
	FIXEDFEE: 'fixedFee',
	CAPPEDFEE: 'cappedFee',
	COLLAREDFEE: 'collaredFee',
	CONTINGENCYFEE: 'contingencyFee',
	CONTINGENCYPERCENTAGE: 'contingencyPercentage',
	DISCOUNTPERCENTAGE: 'discountPercentage',
	SUCCESSFEE: 'successFee',
	HOURLYRATES: 'hourlyRates',
	OTHER: 'other',
	ESTIMATE: 'estimate',
	INCLUDED: 'included',
	NOTAPPLICABLE: 'notApplicable',
	AFA: 'afa',
	HOURLYRATE: 'hourlyRate',
	INCLUDEDINITEMTOTAL: 'includedInItemTotal',
	INCLUDEDINTOTAL: 'included',
	RATECARD: 'hourlyRates',
	RATES: 'rates',
} as const

const clientPricingModelKeys = Object.values(clientPricingModelEnum)
const firmPricingModelKeys = Object.values(firmPricingModelEnum)

export default {
	clientTotal: clientPricingModelKeys,
	firmTotal: firmPricingModelKeys,
	clientPhase: clientPricingModelKeys,
	firmPhase: firmPricingModelKeys,
	...clientPricingModelEnum,
}

export const clientTotalModel = clientPricingModelEnum
export const firmTotalModel = firmPricingModelEnum
export const clientPhaseModel = clientPricingModelEnum
export const firmPhaseModel = firmPricingModelEnum
