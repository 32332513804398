import { ErrorIcon } from '../error-icon'
import { ErrorMessages } from '../error-messages'

import { Button, Typography, Box, BoxProps, useTheme } from '@persuit/ui-components'
import { Fragment } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { ErrorCardData } from '../../types'

type ErrorCardProps = {
	loading: boolean
	errorCardData: ErrorCardData
} & BoxProps

export const ErrorCard = ({
	loading,
	errorCardData: { errorMessages, subSections, title, link },
	...props
}: ErrorCardProps) => {
	const theme = useTheme()

	return (
		<Box
			width="100%"
			bgcolor={theme.palette.red50}
			margin="0px 0px 8px auto"
			padding="10px 0px 10px 10px"
			borderRadius="5px"
			boxSizing="border-box"
			{...props}
		>
			<Box display="flex" flexDirection="row" alignItems="center" gap="8px">
				<ErrorIcon loading={loading} />
				<Typography color="error">
					<b>{title}</b>
				</Typography>
			</Box>
			<Box display="flex" flexDirection="column" paddingRight="8px" marginLeft="32px">
				<ErrorMessages errorMessages={errorMessages} />
				{subSections.map((subSection) => (
					<Fragment key={subSection.title}>
						<Typography color="error">
							<b>{subSection.title}</b>
						</Typography>
						<ErrorMessages errorMessages={subSection.errorMessages} />
					</Fragment>
				))}
			</Box>
			{link && (
				<Box display="flex" justifyContent="flex-end" paddingRight="10px">
					<Button
						component={Link}
						color="error"
						variant="contained"
						style={{ height: '40px', backgroundColor: theme.palette.error.main }}
						to={link}
						title={`Review ${title} error`}
						aria-label={`Review ${title} error`}
						onClick={() => {
							const item = link.match(/\w+_item_\d/)
							if (item && item[0]) {
								document.getElementById(item[0])?.focus()
							} else {
								setTimeout(() => {
									document.getElementById('rfp-form-container')?.focus()
								}, 200)
							}
						}}
					>
						REVIEW
					</Button>
				</Box>
			)}
		</Box>
	)
}
