import { useSectionalForm, SectionalFormContainer } from '../../sectional-form'
import { Box, Form, FormRichTextEditor, Typography } from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Timekeepers } from './components/'
import { RatesFormValues } from './rates-form-utils'
import { IndividualTimekeepers } from './individual-timekeepers'
import { CurrencyForm } from './components/currency'

const NamedTimekeepersForm = ({
	disableNamedTimekeepersCheckbox,
}: {
	disableNamedTimekeepersCheckbox?: boolean
}) => {
	const { toggles } = useFeatureToggles()
	const namedTimekeeperToggle = toggles['dev-10144.named-timekeepers']

	if (!namedTimekeeperToggle) {
		return null
	}

	return <IndividualTimekeepers disableNamedTimekeepersCheckbox={disableNamedTimekeepersCheckbox} />
}

export type RatesFormProps = {
	disableNamedTimekeepersCheckbox?: boolean
	disableExistingCurrencySelections?: boolean
	useSingleCurrency: boolean
	saveSection: (input: RatesFormValues) => Promise<void>
	sectionData: RatesFormValues
	sectionErrors: null | {
		details: string | null
		currency: string | null
		timekeepers: Array<{ timekeeper: string; timekeeperLabel: string }>
	}
}
export const RatesForm = ({
	saveSection,
	sectionData,
	sectionErrors,
	disableNamedTimekeepersCheckbox,
	disableExistingCurrencySelections = false, // for update rfp form
	useSingleCurrency = false,
}: RatesFormProps) => {
	const namedTimekeeperSettings = sectionData.namedTimekeepersSettings

	const methods = useSectionalForm(
		{
			details: sectionData.details,
			currency: sectionData.currency,
			currencies: sectionData.currencies,
			timekeepers: sectionData.timekeepers,
			requestNamedTimekeepers: sectionData.requestNamedTimekeepers,
			namedTimekeepersSettings: {
				requestBillingId: namedTimekeeperSettings?.requestBillingId ?? 'required',
				billingIdInstructions: namedTimekeeperSettings?.billingIdInstructions ?? '',
				requestGraduationYear: namedTimekeeperSettings?.requestGraduationYear ?? 'required',
				graduationYearInstructions: namedTimekeeperSettings?.graduationYearInstructions ?? '',
			},
		},
		(values) =>
			saveSection({
				details: values.details,
				currency: values.currency,
				currencies: values.currencies,
				timekeepers: values.timekeepers,
				requestNamedTimekeepers: values.requestNamedTimekeepers,
				namedTimekeepersSettings: values.namedTimekeepersSettings,
			}),
		// TODO: DEV-9022 Fix types

		{
			...sectionErrors,
			timekeepers: sectionErrors?.timekeepers?.map((tk) => tk.timekeeperLabel),
		} as any,
	)

	return (
		<SectionalFormContainer label="Rates section" focusOnMount={true}>
			<Form methods={methods} aria-label="Rates Section">
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Rates</Typography>
					<Typography variant="body1">
						Firms will provide proposals with rates for different timekeeper levels. This includes
						last year's hours, current rate, new standard rate and proposed rate. You will be able
						to compare and filter rate cards by practice area and location. For optimal results tell
						firms how detailed you want the rate card to be (for example, by country or
						city/cities).
					</Typography>
					<Box sx={{ mt: 1 }}></Box>
					<FormRichTextEditor
						id="rates-details"
						label="Rates details & assumptions"
						required={true}
						name="details"
						height={200}
					/>
					<Box sx={{ mt: 1 }}></Box>

					<CurrencyForm
						useSingleCurrency={useSingleCurrency}
						disableExistingCurrencySelections={disableExistingCurrencySelections}
					/>

					<Box sx={{ mt: 4 }}>
						<Typography variant="h3XSmall" component="h2">
							Rate cards
						</Typography>
						<Typography variant="body1">
							Define the timekeepers you want the firms to provide rates for.
						</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Timekeepers />
					</Box>
					<NamedTimekeepersForm disableNamedTimekeepersCheckbox={disableNamedTimekeepersCheckbox} />
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
