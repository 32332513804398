import { Box, PopoverHelp, Typography, useTheme } from '@persuit/ui-components'
import { PricingList } from '../pricing-list'

export const PhasePricingSection = () => {
	const theme = useTheme()

	return (
		<Box display="flex" flexDirection="column" gap={theme.spacing(1)} paddingTop={2}>
			<Box display="flex" alignItems="center" gap={theme.spacing(1)}>
				<Typography variant="h3XSmall">Individual pricing items</Typography>
				<PopoverHelp
					triggerButtonProps={{ 'aria-label': 'Individual pricing items help' }}
					content={
						'Note: Items that have been excluded from scope or listed as no charge are hidden in the quick editor. If you need to alter them, choose the full proposal revision option.'
					}
				/>
			</Box>

			<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
				<PricingList showPricingItem={true} />
			</Box>
		</Box>
	)
}
