import { Box, Portal, Typography } from '@persuit/ui-components'

// eslint-disable-next-line no-restricted-imports
import {
	gridRowCountSelector,
	gridExpandedSortedRowEntriesSelector,
	GridToolbarContainer,
	GridToolbarProps,
	GridToolbarQuickFilter,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'

export const GridCustomToolbar = (props: GridToolbarProps) => {
	const apiRef = useGridApiContext()
	const totalRowCount = useGridSelector(apiRef, gridRowCountSelector)
	const visibleRows = useGridSelector(apiRef, gridExpandedSortedRowEntriesSelector)
	const visibleRowCount = visibleRows.length

	const getGridToolbarText = (visibleRowCount: number, totalRowCount: number): string => {
		if (visibleRowCount === totalRowCount) return `Showing ${totalRowCount} Available Firms`
		return `Showing ${visibleRowCount} of ${totalRowCount} Available Firms`
	}

	return (
		<>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter {...props.quickFilterProps} />
			</Portal>
			<GridToolbarContainer {...props}>
				<Box sx={{ display: 'flex', alignItems: 'baseline', m: 3 }}>
					<Typography variant="h4">{getGridToolbarText(visibleRowCount, totalRowCount)}</Typography>
					<Typography variant="body2" sx={{ ml: 2 }}>
						Any firm invited to a previous request will appear below.
					</Typography>
				</Box>
			</GridToolbarContainer>
		</>
	)
}
