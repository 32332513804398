const HIDE_CHANNELS = 'HIDE_CHANNELS'
const HIDE_COPY_RFP_DIALOG = 'HIDE_COPY_RFP_DIALOG'
const HIDE_CREATE_TEMPLATE_DIALOG = 'HIDE_CREATE_TEMPLATE_DIALOG'
const HIDE_PASSWORD_CHANGE_DIALOG = 'HIDE_PASSWORD_CHANGE_DIALOG'
const HIDE_PROPOSAL_DELETE_DIALOG = 'HIDE_PROPOSAL_DELETE_DIALOG'
const HIDE_PROPOSAL_SUBMIT_DIALOG = 'HIDE_PROPOSAL_SUBMIT_DIALOG'
const HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG = 'HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG'
const HIDE_RFP_CLOSE_DIALOG = 'HIDE_RFP_CLOSE_DIALOG'
const HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG = 'HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG'
const HIDE_RFP_FINALIZE_DIALOG = 'HIDE_RFP_FINALIZE_DIALOG'
const HIDE_RFP_OPEN_DIALOG = 'HIDE_RFP_OPEN_DIALOG'
const SET_LISTING_FILTER = 'SET_LISTING_FILTER'
const SET_LISTING_SORT = 'SET_LISTING_SORT'
const SHOW_CHANNELS = 'SHOW_CHANNELS'
const SHOW_COPY_RFP_DIALOG = 'SHOW_COPY_RFP_DIALOG'
const SHOW_CREATE_TEMPLATE_DIALOG = 'SHOW_CREATE_TEMPLATE_DIALOG'
const SHOW_PASSWORD_CHANGE_DIALOG = 'SHOW_PASSWORD_CHANGE_DIALOG'
const SHOW_PROPOSAL_DELETE_DIALOG = 'SHOW_PROPOSAL_DELETE_DIALOG'
const SHOW_PROPOSAL_SUBMIT_DIALOG = 'SHOW_PROPOSAL_SUBMIT_DIALOG'
const SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG = 'SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG'
const SHOW_RFP_CLOSE_DIALOG = 'SHOW_RFP_CLOSE_DIALOG'
const SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG = 'SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG'
const SHOW_RFP_FINALIZE_DIALOG = 'SHOW_RFP_FINALIZE_DIALOG'
const SHOW_RFP_OPEN_DIALOG = 'SHOW_RFP_OPEN_DIALOG'
const TOGGLE_INVITE_LIST_DIALOG = 'TOGGLE_INVITE_LIST_DIALOG'
const TOGGLE_PROPOSAL_ACCEPT_DIALOG = 'TOGGLE_PROPOSAL_ACCEPT_DIALOG'
const TOGGLE_PROPOSAL_REVISE_DIALOG = 'TOGGLE_PROPOSAL_REVISE_DIALOG'
const TOGGLE_INVITE_SEARCH_DIALOG = 'TOGGLE_INVITE_SEARCH_DIALOG'
const SHOW_TOTAL_PRICE_DIALOG = 'SHOW_TOTAL_PRICE_DIALOG'
const HIDE_TOTAL_PRICE_DIALOG = 'HIDE_TOTAL_PRICE_DIALOG'
const SHOW_CHANGE_END_DATE_DIALOG = 'SHOW_CHANGE_END_DATE_DIALOG'
const HIDE_CHANGE_END_DATE_DIALOG = 'HIDE_CHANGE_END_DATE_DIALOG'
const TOGGLE_SHARING_DIALOG = 'TOGGLE_SHARING_DIALOG'

function toggleChannels() {
	return (dispatch, getState) => {
		const {
			ui: { showChannels: showChannelsBool },
		} = getState()

		if (showChannelsBool) {
			return dispatch(hideChannels())
		}

		return dispatch(showChannels())
	}
}

function hideChannels() {
	return {
		type: HIDE_CHANNELS,
		showChannels: false,
	}
}

function toggleInviteSearchDialog() {
	return {
		type: TOGGLE_INVITE_SEARCH_DIALOG,
	}
}

function toggleSharingDialog() {
	return {
		type: TOGGLE_SHARING_DIALOG,
	}
}

function hideInviteListDialog() {
	return {
		type: TOGGLE_INVITE_LIST_DIALOG,
		show: false,
	}
}

function hidePasswordChangeDialog() {
	return {
		type: HIDE_PASSWORD_CHANGE_DIALOG,
	}
}

function hideProposalAcceptDialog() {
	return {
		type: TOGGLE_PROPOSAL_ACCEPT_DIALOG,
		show: false,
	}
}

function hideProposalDeleteDialog() {
	return {
		type: HIDE_PROPOSAL_DELETE_DIALOG,
	}
}

function hideProposalReviseDialog() {
	return {
		type: TOGGLE_PROPOSAL_REVISE_DIALOG,
		show: false,
	}
}

function hideProposalSubmitDialog() {
	return {
		type: HIDE_PROPOSAL_SUBMIT_DIALOG,
	}
}

function hideRfpFinalizeDialog() {
	return {
		type: HIDE_RFP_FINALIZE_DIALOG,
	}
}

function showChannels() {
	return {
		type: SHOW_CHANNELS,
		showChannels: true,
	}
}

function showInviteListDialog() {
	return {
		type: TOGGLE_INVITE_LIST_DIALOG,
		show: true,
	}
}

function showPasswordChangeDialog() {
	return {
		type: SHOW_PASSWORD_CHANGE_DIALOG,
	}
}

function showProposalAcceptDialog() {
	return {
		type: TOGGLE_PROPOSAL_ACCEPT_DIALOG,
		show: true,
	}
}

function showProposalDeleteDialog() {
	return {
		type: SHOW_PROPOSAL_DELETE_DIALOG,
	}
}

function showProposalReviseDialog() {
	return {
		type: TOGGLE_PROPOSAL_REVISE_DIALOG,
		show: true,
	}
}

function showProposalSubmitDialog() {
	return {
		type: SHOW_PROPOSAL_SUBMIT_DIALOG,
	}
}

function showRfpFinalizeDialog() {
	return {
		type: SHOW_RFP_FINALIZE_DIALOG,
	}
}

function hideClearConflictCheckDialog() {
	return {
		type: HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
		show: false,
	}
}

function showClearConflictCheckDialog() {
	return {
		type: SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
		show: true,
	}
}

function hideCopyRfpDialog() {
	return {
		type: HIDE_COPY_RFP_DIALOG,
		show: false,
	}
}

function hideCreateTemplateDialog() {
	return {
		type: HIDE_CREATE_TEMPLATE_DIALOG,
		show: false,
	}
}

function showCopyRfpDialog() {
	return {
		type: SHOW_COPY_RFP_DIALOG,
		show: true,
	}
}

function showCreateTemplateDialog() {
	return {
		type: SHOW_CREATE_TEMPLATE_DIALOG,
		show: true,
	}
}

function hideDontClearConflictCheckDialog() {
	return {
		type: HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
		show: false,
	}
}

function showDontClearConflictCheckDialog() {
	return {
		type: SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
		show: true,
	}
}

function showRfpCloseDialog() {
	return {
		type: SHOW_RFP_CLOSE_DIALOG,
		show: true,
	}
}

function hideRfpCloseDialog() {
	return {
		type: HIDE_RFP_CLOSE_DIALOG,
		show: false,
	}
}

function showRfpOpenDialog() {
	return {
		type: SHOW_RFP_OPEN_DIALOG,
		show: true,
	}
}

function hideRfpOpenDialog() {
	return {
		type: HIDE_RFP_OPEN_DIALOG,
		show: false,
	}
}

function setListingFilter(filter) {
	return {
		type: SET_LISTING_FILTER,
		filter,
	}
}

function setListingSort(sort) {
	return {
		type: SET_LISTING_SORT,
		sort,
	}
}

function showTotalPriceDialog() {
	return {
		type: SHOW_TOTAL_PRICE_DIALOG,
		show: true,
	}
}

function hideTotalPriceDialog() {
	return {
		type: HIDE_TOTAL_PRICE_DIALOG,
		show: false,
	}
}

function showChangeEndDateDialog() {
	return {
		type: SHOW_CHANGE_END_DATE_DIALOG,
	}
}

function hidehangeEndDateDialog() {
	return {
		type: HIDE_CHANGE_END_DATE_DIALOG,
	}
}

export default {
	HIDE_COPY_RFP_DIALOG,
	HIDE_CREATE_TEMPLATE_DIALOG,
	HIDE_CHANNELS,
	HIDE_PASSWORD_CHANGE_DIALOG,
	HIDE_PROPOSAL_DELETE_DIALOG,
	HIDE_PROPOSAL_SUBMIT_DIALOG,
	HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
	HIDE_RFP_CLOSE_DIALOG,
	HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
	HIDE_RFP_FINALIZE_DIALOG,
	HIDE_RFP_OPEN_DIALOG,
	HIDE_TOTAL_PRICE_DIALOG,
	SET_LISTING_FILTER,
	SET_LISTING_SORT,
	SHOW_CHANNELS,
	SHOW_COPY_RFP_DIALOG,
	SHOW_CREATE_TEMPLATE_DIALOG,
	SHOW_PASSWORD_CHANGE_DIALOG,
	SHOW_PROPOSAL_DELETE_DIALOG,
	SHOW_PROPOSAL_SUBMIT_DIALOG,
	SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
	SHOW_RFP_CLOSE_DIALOG,
	SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
	SHOW_RFP_FINALIZE_DIALOG,
	SHOW_RFP_OPEN_DIALOG,
	TOGGLE_INVITE_LIST_DIALOG,
	TOGGLE_PROPOSAL_ACCEPT_DIALOG,
	TOGGLE_PROPOSAL_REVISE_DIALOG,
	TOGGLE_INVITE_SEARCH_DIALOG,
	SHOW_TOTAL_PRICE_DIALOG,
	SHOW_CHANGE_END_DATE_DIALOG,
	HIDE_CHANGE_END_DATE_DIALOG,
	TOGGLE_SHARING_DIALOG,

	hideCopyRfpDialog,
	hideCreateTemplateDialog,
	hideChannels,
	hideClearConflictCheckDialog,
	hideDontClearConflictCheckDialog,
	hideInviteListDialog,
	hidePasswordChangeDialog,
	hideProposalAcceptDialog,
	hideProposalDeleteDialog,
	hideProposalReviseDialog,
	hideProposalSubmitDialog,
	hideRfpCloseDialog,
	hideRfpFinalizeDialog,
	hideRfpOpenDialog,
	hideTotalPriceDialog,
	setListingFilter,
	setListingSort,
	showChannels,
	showClearConflictCheckDialog,
	showCopyRfpDialog,
	showCreateTemplateDialog,
	showDontClearConflictCheckDialog,
	showInviteListDialog,
	showPasswordChangeDialog,
	showProposalAcceptDialog,
	showProposalDeleteDialog,
	showProposalReviseDialog,
	showProposalSubmitDialog,
	showRfpCloseDialog,
	showRfpFinalizeDialog,
	showRfpOpenDialog,
	showTotalPriceDialog,
	toggleInviteSearchDialog,
	showChangeEndDateDialog,
	hidehangeEndDateDialog,
	toggleChannels,
	toggleSharingDialog,
}
