import { useRef } from 'react'
import * as React from 'react'
import { noop } from '@persuit/common-utils'
import { Button, ButtonProps } from '../../button'
import { AttachFileIcon } from '../../../icons'
import { CircularProgress } from '../../circular-progress'

export type FileSelectorProps = {
	onChange?: (files: File[]) => void
	multiple?: boolean
	disabled?: boolean
	loading?: boolean
	children?: (props: ControlProps) => React.ReactNode
	fileExtensions?: string[]
}

export const FileSelector = ({
	onChange = noop,
	children = defaultRenderControl,
	disabled = false,
	loading = false,
	multiple = false,
	fileExtensions = ALLOWED_FILE_EXTENSIONS,
}: FileSelectorProps) => {
	const inputRef = useRef<null | HTMLInputElement>(null)

	return (
		<>
			{children({
				disabled,
				loading,
				selectFile: () => {
					inputRef.current?.click()
				},
			})}

			<input
				aria-label="upload file"
				ref={inputRef}
				accept={fileExtensions.join(',')}
				type="file"
				hidden={true}
				multiple={multiple}
				onChange={(e) => {
					onChange(Array.from(e.target.files ?? []))
					// Resets the input value so that you may upload the same file again
					e.target.value = ''
				}}
			/>
		</>
	)
}

type ControlProps = {
	selectFile: () => void
	disabled: boolean
	loading: boolean
}

const defaultRenderControl = ({ selectFile, loading, disabled }: ControlProps) => (
	<DefaultFileSelectorControl onClick={() => selectFile()} loading={loading} disabled={disabled} />
)

export type DefaultFileSelectorControlProps = ButtonProps & { loading?: boolean }

export const DefaultFileSelectorControl = ({
	disabled,
	loading,
	...rest
}: DefaultFileSelectorControlProps) => {
	return (
		<Button
			color="primary"
			startIcon={loading ? <CircularProgress size={16} /> : <AttachFileIcon />}
			disabled={disabled || loading}
			{...rest}
		>
			{loading && !disabled ? 'Attaching files...' : 'Attach files'}
		</Button>
	)
}

const ALLOWED_FILE_EXTENSIONS = [
	'.pdf',

	// Word
	'.doc',
	'.docx',

	// Powerpoint
	'.ppt',
	'.pptx',
	'.pps',
	'.ppsx',

	// Excel
	'.xls',
	'.xlt',
	'.xlsx',

	// Outlook email export
	'.eml',
]
