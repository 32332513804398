import { memo, useState, useMemo } from 'react'
import {
	Box,
	useFormComponent,
	NestedAutocompleteProps,
	TextFieldProps,
	SxProps,
} from '@persuit/ui-components'
import { useSaliLocationData, SaliCountry } from '@persuit/ui-hooks'
import { logger } from '@persuit/ui-logger'
import { flatten } from 'lodash'

import { CountrySelect, CountrySelectProps } from './country-select'
import { RegionDropdown } from './region-dropdown'

export type FormLocationDropdownFormData = {
	country: string | null
	countryName?: string | null
	regions: string[]
	regionNames?: string[]
}

export type CityData = {
	city: string
	country: string
	state: string
	stateCode?: string
}

export type LocationData = SaliCountry[]

type LocationDropdownProps = {
	sx?: SxProps
	countrySelectProps?: Partial<CountrySelectProps>
	regionsSelectProps?: Partial<NestedAutocompleteProps>
	locationData: LocationData
	value: FormLocationDropdownFormData
	onChange: (updatedLocationData: FormLocationDropdownFormData) => unknown
	countryTextFieldProps?: Partial<TextFieldProps>
	regionsTextFieldProps?: Partial<TextFieldProps>
}

function prepareFieldData(
	locationData: LocationData,
	country: string,
): NestedAutocompleteProps['options'] {
	const countryDetails = locationData.find((c) => c.code === country)
	if (!countryDetails) {
		return []
	}

	const { code: countryCode, name: countryName } = countryDetails

	const stateData = countryDetails.states.map(({ code: stateCode, name: stateName, cities }) => ({
		_id: `entire-state-group-${stateCode}`,
		label: stateName,
		values: [
			{
				_id: stateCode,
				label: `All of ${stateName}`,
				values: [],
			},
			...cities.map(({ name: cityName, code: cityCode }) => ({
				_id: cityCode,
				label: cityName,
				values: [],
			})),
		],
		defaultExpanded: false,
	}))

	return [
		{
			_id: 'entire-country-group',
			label: 'Select entire country',
			values: [{ _id: countryCode, label: `All of ${countryName}`, values: [] }],
		},
		...stateData,
	]
}

type CountryObject = Pick<LocationData[number], 'code' | 'name'>
// This function adds a divider object to create a line between top
// countries and other countries
const getCountryList = (locationData: LocationData): CountryObject[] => {
	const TOP_COUNTRIES_COUNT = 7
	const mappedCountryList = locationData.map(({ code, name }) => ({
		code,
		name,
	}))

	const topCountries = mappedCountryList.slice(0, TOP_COUNTRIES_COUNT)
	const remainingCountries = mappedCountryList.slice(TOP_COUNTRIES_COUNT, -1)
	const dividerObject: CountryObject = {
		code: 'divider',
		name: 'divider',
	}

	const newMappedCountryList = [...topCountries, dividerObject, ...remainingCountries]
	return newMappedCountryList
}

export const LocationDropdown = ({
	sx,
	locationData,
	countrySelectProps,
	regionsSelectProps,
	value,
	onChange,
	countryTextFieldProps,
	regionsTextFieldProps,
}: LocationDropdownProps) => {
	const [country, setCountry] = useState(value.country ?? null)
	const [countryName, setCountryName] = useState<string | null>(null)
	const [stateSelections, setStateSelections] = useState(value.country ? value.regions : [])

	const countryList = useMemo(() => getCountryList(locationData), [locationData])

	const stateData = useMemo(
		() => prepareFieldData(locationData, country ?? ''),
		[country, locationData],
	)

	const stateDataMap = useMemo(
		() =>
			new Map(
				flatten(stateData.map((data) => data.values)).map((stateInfo) => [
					stateInfo?._id,
					stateInfo?.label,
				]),
			),
		[stateData],
	)

	function getStateName(states: string[] = []): string[] {
		return states.map((stateId) => stateDataMap.get(stateId) ?? '')
	}

	const countryData = locationData.find((c) => c.code === country)

	return (
		<Box sx={{ display: 'flex', gap: 1, ...sx }}>
			<CountrySelect
				sx={{ flexGrow: 1, flexBasis: 0 }}
				value={country ?? null}
				options={countryList}
				onChange={(newValue) => {
					const countryCode = newValue?.code || null
					const countryName = newValue?.name || null

					setCountry(countryCode)
					setCountryName(countryName)
					setStateSelections([])

					onChange({ country: countryCode, countryName, regions: [], regionNames: [] })
				}}
				TextFieldProps={countryTextFieldProps}
				{...countrySelectProps}
			/>

			<RegionDropdown
				disabled={!country}
				sx={{ flexGrow: 1, flexBasis: 0 }}
				virtualize={stateData.length > 20}
				key={country}
				countryData={countryData}
				selections={stateSelections}
				onChange={(regions) => {
					setStateSelections(regions)
					onChange({
						country,
						countryName,
						regions,
						regionNames: getStateName(regions),
					})
				}}
				TextFieldProps={{
					required: true,
					...regionsTextFieldProps,
				}}
				{...regionsSelectProps}
			/>
		</Box>
	)
}

type ConnectedLocationDropdownProps = Omit<LocationDropdownProps, 'locationData'>

export const ConnectedLocationDropdown = (props: ConnectedLocationDropdownProps) => {
	const { data, loading, error } = useSaliLocationData()

	if (loading) {
		return <div>Loading location data...</div>
	}

	if (error) {
		logger.error(error)
		return <div>Error loading location component</div>
	}

	return <LocationDropdown locationData={data} {...props} />
}

type FormLocationDropdown = {
	name: string
} & Pick<LocationDropdownProps, 'countryTextFieldProps' | 'regionsTextFieldProps'>

export const FormLocationDropdown = memo(({ name, ...props }: FormLocationDropdown) => {
	const {
		field: { onChange, value },
	} = useFormComponent<FormLocationDropdownFormData>({ name: name ?? 'location' })

	return (
		<ConnectedLocationDropdown
			value={value || { country: '', locations: [] }}
			onChange={onChange}
			{...props}
		/>
	)
})
