// @ts-strict-ignore
import {
	ArrowDownwardIcon,
	ArrowUpwardIcon,
	Button,
	ScreenOnly,
	SxProps,
} from '@persuit/ui-components'

export type SortOrder = 'asc' | 'desc' | null | undefined

export type SortButtonProps = {
	sortOrder: SortOrder
	onClick: (sortOrder: SortOrder) => void
	isFirmNameSort?: boolean
	sx?: SxProps | undefined
}

export const SortButton = ({ sortOrder, onClick, isFirmNameSort, sx }: SortButtonProps) => {
	const sortRow = (e) => {
		e.stopPropagation()
		onClick(sortOrder ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc')
	}
	return (
		<ScreenOnly>
			<Button
				variant={sortOrder ? 'contained' : 'outlined'}
				onClick={sortRow}
				title={
					!isFirmNameSort
						? sortOrder === 'asc'
							? 'Ascending - low to high'
							: sortOrder === 'desc'
							? 'Descending - high to low'
							: ''
						: ''
				}
				endIcon={
					isFirmNameSort ? null : sortOrder ? (
						sortOrder === 'asc' ? (
							<ArrowUpwardIcon style={{ width: '14px', height: '14px', marginLeft: '-4px' }} />
						) : (
							<ArrowDownwardIcon style={{ width: '14px', height: '14px', marginLeft: '-4px' }} />
						)
					) : null
				}
				sx={{
					fontSize: '13px',
					lineHeight: '22px',
					width: '80px',
					marginLeft: '8px',
					marginBottom: '4px',
					...sx,
				}}
			>
				{sortOrder
					? sortOrder === 'asc'
						? isFirmNameSort
							? 'A TO Z'
							: 'ASC.'
						: isFirmNameSort
						? 'Z TO A'
						: 'DESC.'
					: 'SORT'}
			</Button>
		</ScreenOnly>
	)
}
