import * as React from 'react'
import { noop } from '@persuit/common-utils'
import { UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext, DndContext, DndContextProps, SortableList, SortableListProps } from '../'

type Identifiable =
	| UniqueIdentifier
	| {
			id: UniqueIdentifier
	  }

export type SortableVerticalListProps<T extends Identifiable> = Pick<
	DndContextProps,
	| 'onDragCancel'
	| 'onDragEnd'
	| 'onDragMove'
	| 'onDragOver'
	| 'onDragStart'
	| 'collisionDetection'
	| 'accessibility'
> & {
	children: React.ReactNode
	items: T[]
	/**
	 * Called whenever the user intends to move an item
	 * @param fromIndex The position the moved item is currently in
	 * @param toIndex The position the moved item is beinged move to
	 */
	onMove?: (fromIndex: number, toIndex: number) => void
	containerProps?: SortableListProps
}

export const SortableVerticalList = <T extends Identifiable>({
	children,
	items,
	onMove = noop,
	onDragEnd,
	containerProps,
	...rest
}: SortableVerticalListProps<T>) => {
	return (
		<DndContext
			{...rest}
			onDragOver={noop}
			onDragEnd={(event) => {
				onDragEnd?.(event)
				const { active, over } = event
				if (over && active.id !== over.id) {
					const findIndex = (id: UniqueIdentifier): number | null => {
						const index = items.findIndex(
							(item) => (typeof item === 'object' ? item.id : item) === id,
						)
						return index >= 0 ? index : null
					}

					const from = findIndex(active.id)
					const to = findIndex(over.id)

					if (from !== null && to !== null) {
						return onMove(from, to)
					}
				}
			}}
		>
			<SortableContext items={items}>
				<SortableList {...containerProps}>{children}</SortableList>
			</SortableContext>
		</DndContext>
	)
}
