import { SvgIcon, SvgIconProps } from '../components/svg'

type InvitationListIconProps = SvgIconProps

export const InvitationListIcon = ({ ...props }: InvitationListIconProps) => {
	return (
		<SvgIcon {...props}>
			<path d="M9 5C9 6.66 7.66 8 6 8C4.34 8 3 6.66 3 5C3 3.34 4.34 2 6 2C7.66 2 9 3.34 9 5ZM12 16H0V14C0 11.79 2.69 10 6 10C9.31 10 12 11.79 12 14M5 5C5 5.55 5.45 6 6 6C6.55 6 7 5.55 7 5C7 4.45 6.55 4 6 4C5.45 4 5 4.45 5 5ZM2 14H10C10 12.9 8.21 12 6 12C3.79 12 2 12.9 2 14ZM20 8V10H11V8M20 4V6H11V4M20 0V2H11V0H20Z" />
		</SvgIcon>
	)
}
