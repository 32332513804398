import * as React from 'react'

import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material'
import { Override } from '../../utilities'

import { WarningIcon } from '../../icons'
import { Box } from '../box'

export type TextFieldProps = Override<
	MUITextFieldProps,
	{
		label: string | null | undefined
		hiddenLabel?: boolean
		optionalLabel?: boolean //Adds (optional) to the label text if true
	}
>

export const TextField = React.forwardRef(
	(
		{
			variant = 'standard',
			label,
			hiddenLabel,
			optionalLabel,
			error,
			helperText,
			...props
		}: TextFieldProps,
		ref: React.Ref<HTMLDivElement>,
	) => {
		return (
			<MUITextField
				variant={variant}
				ref={ref}
				label={hiddenLabel ? undefined : optionalLabel ? `${label} (optional)` : label}
				aria-label={hiddenLabel ? label ?? undefined : undefined}
				error={error}
				helperText={
					error && variant === 'outlined' ? (
						<Box display={'flex'} alignItems="flex-start" gap={0.5}>
							<WarningIcon sx={{ width: '16px', height: '16px' }} color="inherit" />
							{helperText}
						</Box>
					) : (
						helperText
					)
				}
				{...props}
			/>
		)
	},
)

TextField.displayName = 'TextField'
