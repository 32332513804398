import FirmStates, { Eliminated as EliminatedLabel, RevisionRequired } from './firm'
import ClientStates from './client'
import { ProposalStates } from './firm/proposal-states'
import { RequestReOpenStates } from './client/request-reopen-states'

import {
	mapServerDataToFirmStatusProps,
	mapServerDataToClientStatusProps,
	mapServerDataToProposalProps,
} from './transforms'

export const FirmStatus = ({ rfp, rfpProposal = null, showAlerts = null, expanded = true }) => {
	const { request, firm } = mapServerDataToFirmStatusProps({
		rfp,
		rfpProposal,
	})

	return (
		<FirmStates
			rfp={rfp}
			request={request}
			firm={firm}
			showAlerts={showAlerts}
			expanded={expanded}
		/>
	)
}

export const ClientStatus = ({ rfp, showAlerts = false }) => {
	const { request, firms, proposals } = mapServerDataToClientStatusProps({
		rfp,
	})

	return (
		<ClientStates
			rfp={rfp}
			request={request}
			proposals={proposals}
			firms={firms}
			showAlerts={showAlerts}
		/>
	)
}

// take all proposals in all states to figure out this status
export const ProposalStatus = ({ rfp }) => {
	const status = mapServerDataToProposalProps(rfp)

	if (status) return <ProposalStates status={status} />
	else return null
}

export const ProposalStateComponent = ProposalStates

export const RequestReOpenStatus = ({ expanded = true }) => {
	return <RequestReOpenStates expanded={expanded} />
}

export { EliminatedLabel, RevisionRequired }
