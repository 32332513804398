import { Typography, LinkSupport, Link, useConfirmDialog } from '../../'

/**
 * Convenient method to open popup in center and focus on popup
 * Credits: http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
 */
export const useOpenPopup = (params: {
	url: string
	name?: string
	width: number
	height: number
	windowFeatures?: string
}) => {
	const { openConfirmDialog } = useConfirmDialog()

	function openPopup() {
		const { url, name = '', width, height, windowFeatures } = params || {}
		if (!url || typeof width === 'undefined' || typeof height === 'undefined') {
			return
		}

		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

		const currentWidth = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width
		const currentHeight = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height

		const systemZoom = currentHeight / window.screen.availWidth
		const left = (currentWidth - width) / 2 / systemZoom + dualScreenLeft
		const top = (currentHeight - height) / 2 / systemZoom + dualScreenTop

		const newWindow = window.open(
			url,
			name,
			`width=${width / systemZoom}, height=${height / systemZoom}, top=${top}, left=${left}${
				windowFeatures ?? ''
			}`,
		)

		if (hasPopupBlocker(newWindow)) {
			openConfirmDialog({
				title: 'Pop-Up Blocker Detected',
				content: (
					<>
						<Typography>
							To enable the pop-out chat window, disable your pop-up blocker for our site.
						</Typography>
						<Typography mt={1}>Here's a quick guide</Typography>
						<Typography variant="body1Semibold" mt={2}>
							Chrome
						</Typography>
						<ol aria-label="Disable popup blocker for Chrome">
							<li>Click on the three horizontal dots in the top-right corner to open the menu.</li>
							<li>
								Select{' '}
								<Typography variant="body1Semibold" component="span">
									Settings &gt; Privacy and security.
								</Typography>
							</li>
							<li>
								Select{' '}
								<Typography variant="body1Semibold" component="span">
									Site settings.
								</Typography>
							</li>
							<li>
								Under content, click on{' '}
								<Typography variant="body1Semibold" component="span">
									Pop-ups and redirects.
								</Typography>
							</li>
							<li>
								On the{' '}
								<Typography variant="body1Semibold" component="span">
									allowed to send pop-ups and use redirects
								</Typography>
								, click{' '}
								<Typography variant="body1Semibold" component="span">
									Add.
								</Typography>
							</li>
							<li>
								Add{' '}
								<Typography variant="body1Semibold" component="span">
									app.persuit.com.
								</Typography>{' '}
								The URL should now appear in your Allow list.
							</li>
						</ol>
						<Typography variant="body2">
							For comprehensive Chrome instructions, refer to the{' '}
							<Link href="https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-pop-ups-and-redirects-from-a-site:~:text=Allow%20pop%2Dups%20and%20redirects%20from%20a%20site">
								Google Help page.
							</Link>
						</Typography>
						<Typography variant="body1Semibold" mt={2}>
							Microsoft Edge
						</Typography>
						<ol aria-label="Disable popup blocker for Chrome">
							<li>Click on the three horizontal dots in the top-right corner to open the menu.</li>
							<li>
								Select{' '}
								<Typography variant="body1Semibold" component="span">
									Settings &gt; Cookies and site permissions.
								</Typography>
							</li>
							<li>
								Under{' '}
								<Typography variant="body1Semibold" component="span">
									All permissions,
								</Typography>{' '}
								select{' '}
								<Typography variant="body1Semibold" component="span">
									Pop-ups and redirects.
								</Typography>
							</li>
							<li>
								Go to the{' '}
								<Typography variant="body1Semibold" component="span">
									Allow section,
								</Typography>{' '}
								and then select{' '}
								<Typography variant="body1Semibold" component="span">
									Add.
								</Typography>
							</li>
							<li>
								Add{' '}
								<Typography variant="body1Semibold" component="span">
									app.persuit.com.
								</Typography>{' '}
								The URL should now appear in your Allow list.
							</li>
						</ol>
						<Typography variant="body2">
							For comprehensive Microsoft Edge instructions, refer to the{' '}
							<Link href="https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5#:~:text=How%20to%20allow%20pop%2Dups%20for%20a%20specific%20URL%20in%20Microsoft%20Edge">
								Microsoft Help page.
							</Link>
						</Typography>
						<Typography variant="body1" mt={2}>
							If you still need assistance, feel free to contact&nbsp;
							<LinkSupport />.
						</Typography>
					</>
				),
				actions: [{ label: 'Ok', type: 'primary', close: true }],
			})
		} else {
			newWindow?.focus()
		}
	}

	return { openPopup }
}

function hasPopupBlocker(poppedWindow?: Window | null) {
	try {
		if (typeof poppedWindow === 'undefined' || !poppedWindow) {
			// Safari with popup blocker... leaves the popup window handle undefined
			return true
		} else if (poppedWindow && poppedWindow.closed) {
			// This happens if the user opens and closes the client window...
			// Confusing because the handle is still available, but it's in a "closed" state.
			// We're not saying that the window is not being blocked, we're just saying
			// that the window has been closed before the test could be run.
			return false
		} else {
			poppedWindow?.focus()
			// Else we'll assume the window is OK
			return false
		}
	} catch (err) {
		// Failed to open popup for whatever reason
		return true
	}
}
