import { createContext, useContext, useState, useCallback } from 'react'
import * as React from 'react'
import { Alert, Box, CircularProgress } from '@persuit/ui-components'
import { useDebouncedCallback } from 'use-debounce'

export type AutoSaveSnackState = 'none' | 'loading' | 'success' | 'error'

type AutoSaveSnackContextValue = (state: AutoSaveSnackState) => void

export const AutoSaveSnackContext = createContext<AutoSaveSnackContextValue>(() => undefined)

export const useAutoSaveSnack = () => useContext(AutoSaveSnackContext)

type AutoSaveSnackProps = {
	snackState: AutoSaveSnackState
}

export const AutoSaveSnack = ({ snackState }: AutoSaveSnackProps) => {
	if (snackState === 'none') return null

	return (
		<Box position="fixed" bottom="1rem" left="1rem" zIndex={1500}>
			{snackState === 'error' ? (
				<Alert severity="warning">Failed to auto-save.</Alert>
			) : snackState === 'success' ? (
				<Alert severity="success" role="status">
					Successfully auto-saved.
				</Alert>
			) : snackState === 'loading' ? (
				<Alert severity="info" role="status">
					<Box display="flex" alignItems="center">
						<CircularProgress size={18} title="saving..." />
						<Box marginLeft="1rem">saving...</Box>
					</Box>
				</Alert>
			) : null}
		</Box>
	)
}

type AutoSaveSnackContextProviderProps = {
	children: React.ReactNode
}

export const AutoSaveSnackContextProvider = ({ children }: AutoSaveSnackContextProviderProps) => {
	const [snackState, setSnackState] = useState<AutoSaveSnackState>('none')
	const setSnackStateNone = useDebouncedCallback(() => setSnackState('none'), 2000)

	const contextFunction = useCallback(
		(state: AutoSaveSnackState) => {
			setSnackState(state)
			if (state === 'loading') {
				setSnackStateNone.cancel()
			} else if (state === 'success' || state === 'error') {
				setSnackStateNone()
			}
		},
		[setSnackState, setSnackStateNone],
	)

	return (
		<AutoSaveSnackContext.Provider value={contextFunction}>
			<AutoSaveSnack snackState={snackState} />
			{children}
		</AutoSaveSnackContext.Provider>
	)
}
