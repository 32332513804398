import * as React from 'react'
import { Box, Typography, useTheme } from '@persuit/ui-components'

export type StyledContainerProps = {
	header?: string
	children: React.ReactNode
	presentationOnly?: boolean
}

export const StyledContainer = ({ header, children, presentationOnly }: StyledContainerProps) => {
	const theme = useTheme()

	return (
		<Box
			border={`1px solid ${theme.palette.form.borderActive}`}
			borderRadius="5px"
			mb={1}
			role="presentation"
		>
			{header && (
				<Box
					padding={[2, 1]}
					bgcolor={theme.palette.background.appMain}
					borderRadius="5px 5px 0px 0px"
					borderBottom={`1px solid ${theme.palette.form.borderActive}`}
					role="presentation"
				>
					<Typography variant="body2">{header}</Typography>
				</Box>
			)}
			<Box
				paddingX={2}
				paddingY={2}
				display="flex"
				flexDirection="column"
				gap={theme.spacing(1)}
				role={presentationOnly ? undefined : 'rowgroup'}
				aria-label={header}
			>
				{children}
			</Box>
		</Box>
	)
}
