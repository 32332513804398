import { RfpQuestion, RfpQuestionGroup } from '../../types'

export function groupQuestions(questions: RfpQuestion[], questionGroups: RfpQuestionGroup[]) {
	if (!questionGroups || !questionGroups.length) {
		return []
	}

	const grouped = questionGroups.reduce(
		(
			acc: {
				_id: string
				name: string
				hidden: boolean | null | undefined
				questions: RfpQuestion[]
			}[],
			group,
		) => {
			const foundForGroup = questions.filter(
				(question) => question.groupId.toString() === group._id.toString(),
			)
			acc.push({
				_id: group._id,
				name: group.name,
				hidden: group.hidden,
				questions: foundForGroup,
			})

			return acc
		},
		[],
	)

	return grouped
}
