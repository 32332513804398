// @ts-strict-ignore
import { Button, AddIcon, Box, useExpandCollapseActions } from '@persuit/ui-components'
import { getOrGenerateUUID } from '@persuit/common-utils'

export const QuestionListActionButtons = ({ appendQuestionGroup, appendQuestion }) => {
	const { expand } = useExpandCollapseActions()
	const createNewGroup = () => {
		appendQuestionGroup({
			_id: `tmp_${getOrGenerateUUID()}`,
			name: '',
			isNewGroup: true,
		})
	}

	const createNewGroupWithQuestion = () => {
		const groupId = `tmp_${getOrGenerateUUID()}`

		appendQuestionGroup({
			_id: groupId,
			name: '',
			hidden: true,
			isNewGroup: true,
		})

		const uuid = getOrGenerateUUID()

		appendQuestion({
			_id: uuid,
			groupId,
			title: '',
			description: '',
			isNewQuestion: true,
			required: false,
			type: 'long',
		})
		expand(uuid)
	}

	return (
		<Box display="flex">
			<Button
				startIcon={<AddIcon />}
				color="primary"
				variant="outlined"
				aria-label="Create question group"
				onClick={createNewGroup}
				// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
				sx={{ mr: 1, background: '#fff' }}
			>
				Group
			</Button>
			<Button
				startIcon={<AddIcon />}
				color="primary"
				variant="outlined"
				aria-label="Add question"
				// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
				sx={{ mr: 1, background: '#fff' }}
				onClick={createNewGroupWithQuestion}
			>
				Question
			</Button>
		</Box>
	)
}
