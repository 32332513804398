import isEmpty from 'lodash/fp/isEmpty'
import { ReactNode, useEffect, useState } from 'react'
import { Box, Dialog, Typography, PersuitTheme } from '@persuit/ui-components'

import { SectionalLocks } from '../types'
import { UserLine } from './user-line'
import { ValidationLine } from './validation-line'

export type SendDialogProps = {
	open: boolean
	onClose: () => void
	userId: string
	onPrimary: () => void
	primaryLoading: boolean
	primaryIcon: ReactNode
	primaryLabel: string
	sectionalLocks: SectionalLocks
	refetchSectionalLocks: () => void
	validationErrors: object | null
	refetchValidationErrors: () => void
	title: string
	infoText: string
}

const contentRowStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: 1,
	color: (theme: PersuitTheme) => theme.palette.text.secondary,
	fontStyle: 'italic',
}

export const SendDialog = ({
	open,
	onClose,
	userId,
	onPrimary,
	primaryLoading,
	primaryIcon,
	primaryLabel,
	sectionalLocks,
	refetchSectionalLocks,
	validationErrors,
	refetchValidationErrors,
	title,
	infoText,
}: SendDialogProps) => {
	const [loadingValidationErrors, setLoadingValidationErrors] = useState(true)
	const [loadingSectionalLocks, setLoadingSectionalLocks] = useState(true)

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		if (open) {
			;(async () => {
				setLoadingValidationErrors(true)
				setLoadingSectionalLocks(true)
				await Promise.all([refetchSectionalLocks(), refetchValidationErrors()])
				setLoadingSectionalLocks(false)
				setLoadingValidationErrors(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	const hasValidationErrors = !isEmpty(validationErrors)

	const sendDisabled =
		primaryLoading || loadingValidationErrors || loadingSectionalLocks || hasValidationErrors

	const isDialogOpen = typeof validationErrors !== 'undefined' && !hasValidationErrors && open

	useEffect(() => {
		if (hasValidationErrors) {
			onClose()

			document.getElementById('rfp-form-error-container')?.focus()
		}
	}, [hasValidationErrors, onClose])

	return (
		<Dialog
			open={!!isDialogOpen}
			onClose={onClose}
			size="md"
			title={title}
			actions={[
				{
					label: 'Cancel',
					variant: 'text',
					onClick: onClose,
					disabled: primaryLoading,
				},
				{
					label: primaryLabel,
					variant: 'primary',
					onClick: onPrimary,
					startIcon: primaryIcon,
					loading: primaryLoading,
					disabled: sendDisabled,
					'data-trackid': 'button-send-dialog',
				},
			]}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					height: '100%',
					width: '100%',
					overflow: 'hidden',
				}}
			>
				<Box sx={contentRowStyle}>
					<UserLine
						loading={loadingSectionalLocks}
						sectionalLocks={sectionalLocks}
						userId={userId}
					/>
				</Box>
				<Box sx={contentRowStyle}>
					<ValidationLine
						loading={loadingValidationErrors}
						hasValidationErrors={hasValidationErrors}
					/>
				</Box>
				{infoText && (
					<Typography sx={(theme) => ({ mt: 2.5, color: theme.palette.text.secondary })}>
						{infoText}
					</Typography>
				)}
			</Box>
		</Dialog>
	)
}
