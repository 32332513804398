// @ts-strict-ignore
import { isEmpty } from 'lodash/fp'
import { Typography } from '@persuit/ui-components'
import { Fragment } from 'react'
import { getOrNull } from '../../../../client/utils/lenses'
import {
	clientPhaseModel as clientPhasePricingModels,
	firmPhaseModel as firmPhasePricingModels,
} from '../../../../common/data/pricing-models'
import useTranslation from '../../../custom-hooks/translation-hook'
import Spacer from '../../layout/spacer.jsx'
import { combinedRates, priceRange } from '../../pricing-card-detail-view/common/utilities'
import Reveal from '../../reveal'
import AssumptionsView from './assumptions-view'
import ExcludedFromScope from './excluded-from-scope'
import HourlyRate from './hourly-rate'
import ResponsePercentage from './response-percentage'
import { PriceItemHistoryTable } from './price-history-table'
import RateCardHistory from './price-history-table/rate-card-history-table'
import RateCardView, { ExcludedRateCardItemsView } from './rate-card-view.jsx'
import { PriceHistory } from './price-history'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import { Deliverable, ResponseToDeliverable, RfprDeliverable } from '@persuit/ui-graphql/generated'

export const getPriceCopy = ({ prettyPhasePrice, responsePricingModel, t }) => {
	if (!responsePricingModel) {
		return `${prettyPhasePrice}`
	}

	if (responsePricingModel === clientPhasePricingModels.RATECARD) {
		return null
	}

	return responsePricingModel !== firmPhasePricingModels.INCLUDEDINTOTAL
		? `${prettyPhasePrice} | ${t(`feeBreakdown.pricingModels.firmCard.${responsePricingModel}`)}`
		: `${t(`feeBreakdown.pricingModels.firmCard.${responsePricingModel}`)}`
}

export type RfpProposalDetailDeliverablePricingProps = {
	isProposalRevised: boolean
	responseToDeliverable: ResponseToDeliverable
	rfpr: RfprDeliverable | null
	deliverable: Deliverable
	currency: string
	phaseDuration: string
	index: number
}

export const RfpProposalDetailDeliverablePricing = ({
	isProposalRevised,
	responseToDeliverable,
	rfpr,
	deliverable,
	currency,
	phaseDuration,
	index,
}: RfpProposalDetailDeliverablePricingProps) => {
	const { t } = useTranslation()
	const {
		pricingModel: responsePricingModel,
		comment: responseComment,
		price: responsePrice,
		percentage,
		notIncluded: responseNotIncluded,
		responseToRates,
		firmSuppliedRates,
		deliverableId,
		_id: responseToDeliverableId,
		notIncluded,
	} = responseToDeliverable

	const { firmRateCardItemPlaceholder, rates: requestedRates } = deliverable
	const { toggles } = useFeatureToggles()
	const isRateCardAuctionEnabled = toggles['dev-9900.rate-card-auction-improvements']

	let priceCopy: string | null = ''

	if (responsePricingModel === clientPhasePricingModels.HOURLYRATES) {
		priceCopy = getPriceCopy({
			prettyPhasePrice: priceRange(
				responsePricingModel,
				combinedRates(responseToDeliverable),
				responseToDeliverable,
				currency,
			),
			responsePricingModel,
			t,
		})
	}

	const priceHistories = responseToDeliverable.history ?? []
	const showPriceHistory = !isEmpty(priceHistories) && !isProposalRevised

	const isPricingItemWithSubItems = !(
		responsePricingModel === clientPhasePricingModels.CONTINGENCYPERCENTAGE ||
		responsePricingModel === clientPhasePricingModels.HOURLYRATE
	)

	return (
		<div id={deliverableId || undefined}>
			{/* price excluded */}
			{!!responseNotIncluded && (
				<ExcludedFromScope
					rfpr={rfpr}
					currency={currency}
					rateCardView={
						<Fragment>
							<Typography>{priceCopy}</Typography>
							<Spacer space={2} />
							<ExcludedRateCardItemsView
								currency={currency}
								rfpr={rfpr}
								deliverableIndex={index}
								requestedRates={requestedRates}
							/>
							<Spacer space={2} />
						</Fragment>
					}
				/>
			)}
			{/* price included and hourly rate gets a special component */}
			{!responseNotIncluded && responsePricingModel === clientPhasePricingModels.HOURLYRATE && (
				<HourlyRate responsePrice={responsePrice} currency={currency} rfpr={rfpr} />
			)}

			{!responseNotIncluded &&
				responsePricingModel === clientPhasePricingModels.CONTINGENCYPERCENTAGE && (
					// Contingency percentage renders percentage format but very similar to hourly rate
					<ResponsePercentage
						percentage={percentage}
						rfpr={rfpr}
						responsePricingModel={responsePricingModel}
					/>
				)}

			<Spacer space={2} />
			{/* price included show rate card / subitems */}
			{!responseNotIncluded && isPricingItemWithSubItems && (
				<Fragment>
					<Typography>{priceCopy}</Typography>
					<Spacer space={2} />
					<RateCardView
						isProposalRevised={isProposalRevised}
						currency={currency}
						firmSuppliedRates={firmSuppliedRates}
						responseToDeliverable={responseToDeliverable}
						requestedRates={requestedRates}
						responseToDeliverableId={responseToDeliverableId}
						responseToRates={responseToRates}
						// isExample={responseStatus === responseStates.EXAMPLE}
						price={responsePrice}
						firmRateCardItemPlaceholder={
							firmRateCardItemPlaceholder ? firmRateCardItemPlaceholder : ''
						}
						pricingModel={responsePricingModel}
						phaseIndex={index}
						notIncluded={notIncluded}
						showHeader={false}
						showTotal={true}
						expandedView={false}
						detailedView={true}
						phaseDuration={phaseDuration}
						rfpr={rfpr}
					/>
					<Spacer space={2} />
				</Fragment>
			)}

			{isRateCardAuctionEnabled ? (
				<PriceHistory
					key={deliverableId}
					priceHistories={priceHistories}
					currency={currency}
					deliverableNumber={index + 1}
					phaseDuration={phaseDuration}
					showPriceHistory={showPriceHistory}
					responsePricingModel={responsePricingModel}
				/>
			) : (
				showPriceHistory && (
					<Reveal label="Price history">
						{responsePricingModel === firmPhasePricingModels.RATECARD ? (
							<RateCardHistory
								key={deliverableId}
								priceHistories={priceHistories}
								currency={currency}
								deliverableNumber={index + 1}
							/>
						) : (
							<PriceItemHistoryTable
								key={deliverableId}
								priceHistories={priceHistories}
								currency={currency}
								phaseDuration={phaseDuration}
							/>
						)}
					</Reveal>
				)
			)}

			<Spacer space={2} />
			<AssumptionsView
				currentAssumptions={responseComment}
				suggestedAssumptions={getOrNull('rfprComment', rfpr)}
			/>
			<Spacer space={2} />
		</div>
	)
}
