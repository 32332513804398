import { ApolloError } from '@apollo/client'

/** Check if GraphQL error is unauthorised error */
export function isUnauthorised(errors: ApolloError | undefined) {
	if (!errors) {
		return false
	}

	return errors.graphQLErrors.some((error) => error.extensions?.code === 'FORBIDDEN')
}
