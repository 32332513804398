import { Box } from '../box'
import { Button } from '../button'
import { Card, CardProps } from '../card'
import { Typography } from '../typography'
import { WarningAmberRoundedIcon } from '../../icons'
import { LinkSupport } from '../link'

export type ErrorPageProps = CardProps

export const ErrorPage = ({ ...rest }: ErrorPageProps) => (
	<Card
		{...rest}
		sx={{
			textAlign: 'center',
			p: {
				xs: 2,
				md: 5,
			},
			maxWidth: '480px',
			margin: 'auto',
			...rest.sx,
		}}
	>
		<Box>
			<WarningAmberRoundedIcon color="primary" sx={{ fontSize: '6rem' }} />
		</Box>
		<Typography variant="h1XSmall" sx={{ mt: 1 }}>
			Sorry, something went wrong
		</Typography>
		<Typography variant="body1" sx={{ mt: 3 }}>
			Please refresh the page or try again later. If the problem persists, contact support at&nbsp;
			<LinkSupport />.
		</Typography>
		<Button
			variant="contained"
			color="primary"
			sx={{ mt: 3 }}
			onClick={() => {
				window.location.reload()
			}}
		>
			Refresh
		</Button>
	</Card>
)

ErrorPage.displayName = 'ErrorPage'
