import { getOr, isEmpty } from 'lodash/fp'

import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowBackIcon, SettingsIcon, Box, Spacer, Button, NotesIcon } from '@persuit/ui-components'
import proposalStates from '../../../common/data/rfp-proposal-states'
import ManageProposalWizard from '../dialog-wizard/manage-proposal'
import { ProposalFeedbackDialog } from '../../components/proposal-feedback'
import useTranslation from '../../custom-hooks/translation-hook'
import { getOrEmptyString } from '../../utils/lenses'
import NotesDialog from './notes-dialog'
import ProposalFeedbackButton from './proposal-feedback-button'
import { ga } from '@persuit/ui-analytics'

const getActiveProposal = (proposalId, proposals) => {
	if (isEmpty(proposals)) {
		return null
	}

	return proposals.find((proposal) => proposal._id.toString() === proposalId)
}

const ProposalCAB = ({ rfp, proposalId, isFirmEliminated, proposals, fetchResponses }) => {
	const { lng, requestId } = useParams()
	const history = useHistory()
	const { t } = useTranslation()
	const handleBackToProposalList = () =>
		history.push(`/${lng}/request/${requestId}/proposals/view/`)
	const proposal = getActiveProposal(proposalId, proposals)
	const showManageProposalButton = [
		proposalStates.FINALIZED,
		proposalStates.UNDER_REVISION,
		proposalStates.ACCEPTED,
	].includes(proposal.status)

	const [showManageProposalWizard, setShowManageProposalWizard] = useState(false)
	const [showProposalFeedbackFormAll, setShowProposalFeedbackFormAll] = useState(false)
	const [showProposalFeedbackFormSingleFirm, setShowProposalFeedbackFormSingleFirm] =
		useState(false)

	const firmId = getOrEmptyString('org._id', proposal)
	const firmName = getOr('No name', 'org.name', proposal)

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" justifyContent="space-between">
				<Button onClick={handleBackToProposalList} startIcon={<ArrowBackIcon color="primary" />}>
					{t('rfpProposalForm.buttons.back')}
				</Button>
				<Box display="flex">
					<NotesDialog rfpId={rfp._id} firmId={firmId} firmName={firmName}>
						{({ handleOpenDialog }) => (
							<Button
								data-testid="notes-button"
								startIcon={<NotesIcon color="primary" />}
								onClick={() => {
									ga({
										label: 'FEATURE_USAGE',
										page: 'PROPOSAL_DETAIL_VIEW',
										event: 'CLICK_NOTES_BUTTON',
									})
									handleOpenDialog()
								}}
							>
								Notes
							</Button>
						)}
					</NotesDialog>

					<ProposalFeedbackButton
						openProposalFeedbackForm={() => setShowProposalFeedbackFormSingleFirm(true)}
						rfp={rfp}
						proposal={proposal}
						orgId={firmId}
					/>
					{showProposalFeedbackFormAll && (
						<ProposalFeedbackDialog
							rfp={rfp}
							proposals={proposals}
							handleClose={() => setShowProposalFeedbackFormAll(false)}
							fetchResponses={fetchResponses}
						/>
					)}
					{showProposalFeedbackFormSingleFirm && (
						<ProposalFeedbackDialog
							rfp={rfp}
							proposals={proposals}
							handleClose={() => setShowProposalFeedbackFormSingleFirm(false)}
							fetchResponses={fetchResponses}
							firmId={firmId}
						/>
					)}

					<Spacer direction="row" space={2} />
					{showManageProposalButton && (
						<Button
							data-testid="manage-proposal-button"
							startIcon={<SettingsIcon color="primary" />}
							onClick={() => setShowManageProposalWizard(true)}
						>
							Manage Proposal
						</Button>
					)}
					{showManageProposalWizard && (
						<ManageProposalWizard
							proposal={proposal}
							proposals={proposals}
							rfp={rfp}
							isFirmEliminated={isFirmEliminated}
							fetchResponses={fetchResponses}
							handleClose={() => setShowManageProposalWizard(false)}
							openProposalFeedbackFormAll={() => setShowProposalFeedbackFormAll(true)}
							openProposalFeedbackFormSingleFirm={() => setShowProposalFeedbackFormSingleFirm(true)}
						/>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default ProposalCAB
