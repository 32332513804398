import { Box, BoxProps } from '../box'

export type ScreenOnlyProps = BoxProps

/**
 * Only show content for screen, hidden in print
 */
export const ScreenOnly = ({ children, ...props }: ScreenOnlyProps) => (
	<Box
		sx={{
			display: 'block',
			displayPrint: 'none',
		}}
		{...props}
	>
		{children}
	</Box>
)

ScreenOnly.displayName = 'ScreenOnly'
