// @ts-strict-ignore
import { first, groupBy, isNil } from 'lodash/fp'
import {
	FirmSuppliedRate,
	PricingGroup as PricingGroupType,
	PricingItem as PricingItemType,
	ResponseToDeliverable,
	ResponseToRate,
} from '../types'

export type RateItemLookup = Record<
	string,
	{
		price: number
		percentage: number | null
		pricingModel: string
		responseToRates?: Array<ResponseToRate>
		firmSuppliedRates?: Array<FirmSuppliedRate>
		type?: string
	}
>

type ResponseToDeliverableLookup = Record<string, ResponseToDeliverable[]>

const getRateData = (
	type: 'rate' | 'firmSuppliedRate',
	rates: ResponseToRate[] | FirmSuppliedRate[] | undefined,
	pricingModel: string,
) => {
	if (isNil(rates)) {
		return {}
	}

	if (rates.length === 0) {
		return {}
	}

	const rateLookupData = {}
	rates.forEach((rate) => {
		rateLookupData[rate._id] = {
			price: rate.rate || 0,
			percentage: rate.percentage || null,
			pricingModel: pricingModel || '',
			type,
		}
	})
	return rateLookupData
}

const preparePricingItemLookup = (
	responseToDeliverableLookup: ResponseToDeliverableLookup,
	deliverables: Array<PricingItemType | PricingGroupType>,
) => {
	const pricingItemLookup = deliverables.reduce((acc, deliverable) => {
		if (deliverable.__typename === 'PricingGroup') {
			const innerDeliverables = deliverable.deliverables
			const pricingItemLookupFromGroup = preparePricingItemLookup(
				responseToDeliverableLookup,
				innerDeliverables,
			)

			return {
				...acc,
				...pricingItemLookupFromGroup,
			}
		}

		if (checkIfEligibleDeliverable(deliverable, responseToDeliverableLookup)) {
			const responseToDeliverable = first(responseToDeliverableLookup[deliverable._id])
			if (isNil(responseToDeliverable)) {
				return acc
			}

			const deliverableLookupData = {
				price: parseFloat(responseToDeliverable.price || '0'),
				percentage: responseToDeliverable.percentage || null,
				pricingModel: responseToDeliverable.pricingModel || '',
				responseToRates: responseToDeliverable.responseToRates || [],
				firmSuppliedRates: responseToDeliverable.firmSuppliedRates || [],
			}

			const responseToRatesLookupData = getRateData(
				'rate',
				responseToDeliverable.responseToRates,
				responseToDeliverable.pricingModel,
			)
			const firmSuppliedRateLookupData = getRateData(
				'firmSuppliedRate',
				responseToDeliverable.firmSuppliedRates,
				responseToDeliverable.pricingModel,
			)

			const lookupData = {
				[deliverable._id]: deliverableLookupData,
				...responseToRatesLookupData,
				...firmSuppliedRateLookupData,
			}

			return {
				...acc,
				...lookupData,
			}
		}

		return acc
	}, {})

	return pricingItemLookup
}

export const getRateCardDeliverables = ({
	deliverables,
	responseToDeliverables,
}: {
	deliverables: Array<PricingItemType | PricingGroupType>
	responseToDeliverables: Array<ResponseToDeliverable>
}): RateItemLookup => {
	// Turn the ResponseToDeliverables into a lookup map from deliverableId | rateId -> responseToDeliverable | responseToRate
	const responseToDeliverableLookup: ResponseToDeliverableLookup = groupBy(
		(deliverable) => deliverable.deliverableId,
		responseToDeliverables,
	)

	// Iterate the deliverable tree and decide which to include
	const rateItemLookup: Record<string, { price: number; percentage: null; pricingModel: string }> =
		preparePricingItemLookup(responseToDeliverableLookup, deliverables)

	return rateItemLookup
}

const checkIfEligibleDeliverable = (
	deliverable: PricingItemType | PricingGroupType,
	responseToDeliverableLookup: ResponseToDeliverableLookup,
): boolean => {
	const responseToDeliverable = first(responseToDeliverableLookup[deliverable._id])

	if (isNil(responseToDeliverable)) {
		return false
	}

	if (responseToDeliverable.notIncluded) {
		return false
	}

	const deliverablePricingModel = responseToDeliverable.pricingModel || ''
	const isCorrectPricingModel = [
		'hourlyRate',
		'hourlyRates', // Rate card
		'contingencyPercentage',
		'discountPercentage',
	].includes(deliverablePricingModel)
	if (!isCorrectPricingModel) {
		return false
	}

	// Hourly Rate
	const price = parseFloat(responseToDeliverable.price || '0')
	const isHourlyRateZero = responseToDeliverable.pricingModel === 'hourlyRate' && price === 0
	if (isHourlyRateZero) {
		return false
	}

	// Rate card
	const responseToRates = responseToDeliverable.responseToRates ?? []
	const firmSuppliedRates = responseToDeliverable.firmSuppliedRates ?? []
	const combinedRates = [...responseToRates, ...firmSuppliedRates]

	const doesRateCardHaveAnyRates = combinedRates.some((cRate) => Boolean(cRate?.rate))
	if (responseToDeliverable.pricingModel === 'hourlyRates' && !doesRateCardHaveAnyRates) {
		return false
	}

	// Percetage pricing items
	const isPercentagePricingItem = ['contingencyPercentage', 'discountPercentage'].includes(
		deliverablePricingModel,
	)
	const percentage = responseToDeliverable.percentage
	if (isPercentagePricingItem && !percentage) {
		return false
	}

	return true
}
