import { forwardRef } from 'react'
import { IconButton, IconButtonProps } from '../icon-button'
import { DragIndicatorIcon } from '../../icons'

export type DragHandlerProps = IconButtonProps

export const DragHandler = forwardRef<HTMLButtonElement, DragHandlerProps>((props, ref) => {
	return (
		<IconButton
			aria-label="Drag handler"
			sx={{
				color: 'text.primary',
			}}
			size={'small'}
			ref={ref}
			color="primary"
			{...props}
		>
			<DragIndicatorIcon />
		</IconButton>
	)
})

DragHandler.displayName = 'DragHandler'
