import { isNil } from 'lodash/fp'
import { styled, Typography, PercentageText, SROnly } from '@persuit/ui-components'
import useTranslation from '../../../custom-hooks/translation-hook'
import { pink25 } from '../../../theme/persuit-colors'
import formatPriceCurrency from '../../../utils/price-and-currency'
import {
	arePricesEqual,
	areValuesEqual,
} from '../../rfp-request-proposal-revision/suggestion-change-detection'
import pricingModels from '../../../../common/data/pricing-models'

const StyledItemLeft = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 50%;
`
const StyledItemRight = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 50% !important;
	gap: 8px;
`

const StyledRfprItem = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-spacing: 10px;
	background-color: ${pink25};
	padding-top: 10px;
`

// Dedicate space for aligning fee, pricing model, and hours
const StyledMiniCellFee = styled('div')`
	width: 194px;
	white-space: nowrap;
`
const StyledMiniCellModel = styled('div')`
	width: 150px;
`
const StyledMiniCellHrs = styled('div')`
	width: 80px;
`

const isSame = (varA, varB) => {
	if (!varA || !varB) {
		return false
	}

	return varA.toString() === varB.toString()
}

const RfprItem = ({
	rfprPricingModel,
	originalPricingModel,
	rfprPrice,
	originalPrice,
	rfprPercentage,
	originalPercentage,
	rfprHours,
	originalHours,
	phaseDuration,
	currency,
}) => {
	const { t } = useTranslation()

	const isPercentagePricingItem = [
		pricingModels.CONTINGENCYPERCENTAGE,
		pricingModels.DISCOUNTPERCENTAGE,
	].includes(originalPricingModel)

	const samePrice = arePricesEqual(originalPrice, rfprPrice)
	const samePercentage = areValuesEqual(originalPercentage, rfprPercentage)
	const samePricingModel = isSame(rfprPricingModel, originalPricingModel)
	const sameHours = isSame(rfprHours, originalHours)
	const updatedToSROnlyText = <SROnly>Updated to </SROnly>

	return (
		<StyledRfprItem>
			<StyledItemLeft />
			<StyledItemRight>
				{isPercentagePricingItem ? (
					<StyledMiniCellFee>
						{!isNil(rfprPercentage) && (
							<Typography
								variant="body1"
								data-testid="rfpr-item-percentage"
								color={samePercentage ? undefined : 'error.main'}
							>
								{!samePercentage && updatedToSROnlyText}
								<PercentageText value={rfprPercentage} />
							</Typography>
						)}
					</StyledMiniCellFee>
				) : (
					<StyledMiniCellFee>
						{!isNil(rfprPrice) && (
							<Typography
								variant="body1"
								data-testid="rfpr-item-subtotal-rate"
								color={samePrice ? undefined : 'error.main'}
							>
								{!samePrice && updatedToSROnlyText}
								{formatPriceCurrency(currency, rfprPrice)}
							</Typography>
						)}
					</StyledMiniCellFee>
				)}
				<StyledMiniCellModel>
					{rfprPricingModel && (
						<Typography
							variant="body1"
							data-testid="rfpr-item-subtotal-model"
							color={samePricingModel ? undefined : 'error.main'}
						>
							{!samePricingModel && updatedToSROnlyText}
							{t(`feeBreakdown.pricingModels.firmCard.${rfprPricingModel}`)}
						</Typography>
					)}
				</StyledMiniCellModel>
				<StyledMiniCellHrs>
					{rfprHours && (
						<Typography
							data-testid="rfpr-item-subtotal-hours"
							color={sameHours ? undefined : 'error.main'}
						>
							{!sameHours && updatedToSROnlyText}
							{rfprHours} {phaseDuration === 'months' ? 'mo' : 'hrs'}
						</Typography>
					)}
				</StyledMiniCellHrs>
			</StyledItemRight>
		</StyledRfprItem>
	)
}

RfprItem.defaultProps = {
	originalHours: null,
	originalPrice: null,
	originalPricingModel: '',
	rfprHours: null,
	rfprPrice: null,
	rfprPricingModel: '',
}

export default RfprItem
