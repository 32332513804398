import { DiversityDefinitionType } from '../../../../types'

export const DIVERSITY_DEFINITION_MAP: Record<DiversityDefinitionType, string> = {
	women: 'Women',
	lgbtqia: 'Members of LGBTQIA+ Community',
	minorities: 'Racial and Ethnic Minorities',
	disabilities: 'Persons with Disabilities',
	veterans: 'Veterans',
	neurodivergent: 'Neurodiverse Individuals',
}
