import { Rate } from '../../../../../types'
import isEmpty from 'lodash/fp/isEmpty'
import { Box, BoxProps } from '@persuit/ui-components'
import { SubItem } from '../sub-item'

type SubItemsProps = BoxProps & {
	rates: Array<Rate>
	itemIndex: number
}

export const SubItems = ({ rates, itemIndex, ...rest }: SubItemsProps) => {
	if (isEmpty(rates)) return null

	return (
		<Box display="flex" flexDirection="column" {...rest}>
			{rates.map((rate, i) => (
				<SubItem
					key={i}
					itemIndex={itemIndex}
					subIndex={i}
					ratePricingPreference={rate.pricingPreferences}
					label={rate.label ?? ''}
				/>
			))}
		</Box>
	)
}
