export default function cleanTrackingFields(trackingData) {
	if (!trackingData) return {}
	const { referenceNo, customFields } = trackingData
	if (Array.isArray(customFields)) {
		const cleanCustomFields = trackingData.customFields.map((customField) => {
			const {
				fieldType,
				customTrackingFieldId,
				_id,
				hierarchyValue,
				plainTextValue,
				richTextValue,
				numericValue,
				dateValue,
			} = customField
			return {
				fieldType,
				customTrackingFieldId: customTrackingFieldId || _id,
				hierarchyValue,
				plainTextValue,
				richTextValue,
				numericValue,
				dateValue,
			}
		})
		return { referenceNo, customFields: cleanCustomFields }
	}
	return {
		referenceNo,
		customFields: [],
	}
}
