// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { RemoveCircle as EliminatedIcon } from '@mui/icons-material'

import { BaseStatus } from '@client/components/base-status'
import {
	OpenToProposals,
	Completed,
	Evaluating,
	AuctionInProgress,
	AwaitingAuction,
	Withdrawn,
} from '../shared-states'
import FirmStatusTypes from './firm-status-types'

export const Eliminated = ({ expanded = true }) => (
	<BaseStatus
		iconNode={<EliminatedIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'UNSUCCESSFUL'}
		variant={'GREY'}
		expanded={expanded}
	/>
)

export const DeclinedToParticipate = ({ expanded = true }) => (
	<BaseStatus
		iconNode={<EliminatedIcon fontSize={expanded ? undefined : 'small'} />}
		primaryText={'UNABLE TO PARTICIPATE'}
		variant={'GREY'}
		expanded={expanded}
	/>
)

const FirmStatesMap = {
	[FirmStatusTypes.COMPLETED]: ({ expanded }) => <Completed expanded={expanded} />,
	[FirmStatusTypes.ELIMINATED]: ({ expanded }) => <Eliminated expanded={expanded} />,
	[FirmStatusTypes.WITHDRAWN]: ({ statusLine2, expanded }) => (
		<Withdrawn statusLine2={statusLine2} expanded={expanded} />
	),
	[FirmStatusTypes.DECLINED_TO_PARTICIPATE]: ({ expanded }) => (
		<DeclinedToParticipate expanded={expanded} />
	),
	[FirmStatusTypes.OPEN_TO_PROPOSALS]: ({ statusLine2, statusLine3, expanded }) => (
		<OpenToProposals statusLine2={statusLine2} statusLine3={statusLine3} expanded={expanded} />
	),
	[FirmStatusTypes.EVALUATING]: ({ statusLine2, statusLine3, expanded }) => (
		<Evaluating statusLine2={statusLine2} statusLine3={statusLine3} expanded={expanded} />
	),
	[FirmStatusTypes.AWAITING_AUCTION]: ({ statusLine2, statusLine3, expanded }) => (
		<AwaitingAuction statusLine2={statusLine2} statusLine3={statusLine3} expanded={expanded} />
	),
	[FirmStatusTypes.AUCTION_IN_PROGRESS]: ({ statusLine2, statusLine3, expanded }) => (
		<AuctionInProgress statusLine2={statusLine2} statusLine3={statusLine3} expanded={expanded} />
	),
}

const FirmStates = ({ status, statusLine2, statusLine3, expanded = true }) =>
	FirmStatesMap[status] ? FirmStatesMap[status]({ statusLine2, statusLine3, expanded }) : null

export default FirmStates
