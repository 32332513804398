import { Step, Stepper, StepLabel } from '@persuit/ui-components'

import { SyncTrackingFieldsWizardSteps } from './sync-tracking-fields-wizard-steps'

type SyncTrackingFieldsWizardStepperProps = {
	activeStep: SyncTrackingFieldsWizardSteps
}

export const SyncTrackingFieldsWizardStepper = ({
	activeStep,
}: SyncTrackingFieldsWizardStepperProps) => {
	return (
		<Stepper activeStep={activeStep} alternativeLabel={true}>
			<Step completed={activeStep > SyncTrackingFieldsWizardSteps.SEARCH_EXTERNAL_MATTER}>
				<StepLabel>Search External Matter</StepLabel>
			</Step>
			<Step completed={activeStep > SyncTrackingFieldsWizardSteps.SELECT_EXTERNAL_MATTER}>
				<StepLabel>Select External Matter</StepLabel>
			</Step>
			<Step completed={activeStep > SyncTrackingFieldsWizardSteps.CONFIRM_EXTERNAL_MATTER}>
				<StepLabel>Review and Confirm</StepLabel>
			</Step>
		</Stepper>
	)
}
