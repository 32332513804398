import { ApolloError, useQuery } from '@apollo/client'
import { getOr } from 'lodash/fp'
import getSessionInfoQuery from '../../graphql/queries/getSessionInfo-gql'

export type UseIsFeatureEnabledProps = {
	/** The name of the feature toggle to check */
	name: string
}

/**
 * Returns true if the feature is enabled, false if not, and undefined if it's not
 * yet known (because we're still loading or there's an error)
 *
 * @param name The name of the feature toggle to check
 */
export function useIsFeatureEnabled({ name }: UseIsFeatureEnabledProps): {
	enabled: boolean | undefined
	loading: boolean
	error?: ApolloError
} {
	const { data, loading, error } = useQuery(getSessionInfoQuery, { fetchPolicy: 'cache-first' })
	const enabled =
		loading || error ? undefined : getOr(false, `getSessionInfo.featureMap.${name}`, data)

	return { loading, error, enabled }
}
