import { Box, GavelIcon, useTheme } from '@persuit/ui-components'

type ProposalTabLabelProps = {
	isAuction: boolean
	auctionInProgress: boolean
}

const ProposalTabLabel = ({ isAuction, auctionInProgress }: ProposalTabLabelProps) => {
	const {
		palette: { pink600 },
	} = useTheme()

	return (
		<Box position="relative" data-testid="rfp-proposals-tab">
			<Box>Proposals</Box>
			{isAuction || auctionInProgress ? (
				<Box
					sx={{
						fontWeight: 'bold',
						position: 'absolute',
						top: '-2rem',
						textTransform: 'uppercase',
						fontSize: '0.9rem',
						width: '100px',
						left: 'calc(50% - 50px - 0.5rem)',
						// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
						background: 'rgba(255,255,255,0.6)',
						color: pink600,
						padding: '0 0.5rem',
						borderRadius: '6px',
						border: `2px solid ${pink600}`,
						// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
						boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 4px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
						zIndex: 10,
					}}
				>
					<GavelIcon
						sx={{
							height: '1rem',
							width: '1rem',
							position: 'relative',
							top: '3px',
							left: '-5px',
							color: pink600,
						}}
					/>
					Auction in progress
				</Box>
			) : null}
		</Box>
	)
}

ProposalTabLabel.defaultProps = {
	isAuction: false,
}

export default ProposalTabLabel
