import { FileDownloadList } from '@persuit/ui-components'
import { Fragment } from 'react'
import { Rfp } from '../../../types'
import { DeepPick } from '@persuit/common-utils'
import { HeadlineSeparator } from '../headline-separator'
import isEmpty from 'lodash/fp/isEmpty'

export type RfpDetailFilesSectionDocType = DeepPick<Rfp, 'detail.files'>

type RfpDetailFilesSectionProps = {
	document: RfpDetailFilesSectionDocType
}

export const RfpDetailFilesSection = ({ document }: RfpDetailFilesSectionProps) => {
	const files = document?.detail?.files ?? []

	if (isEmpty(files)) return null

	return (
		<Fragment>
			<HeadlineSeparator headline="Supporting Documents" />
			<FileDownloadList files={files} label="Downloadable supporting documents" />
		</Fragment>
	)
}
