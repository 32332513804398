import { useState, useEffect } from 'react'
import { useQuery, graphql } from '@persuit/ui-graphql'
import {
	BorderBox,
	Box,
	Typography,
	IconButton,
	ArrowBackIcon,
	Button,
	LoadingSpinner,
	PageTitle,
} from '@persuit/ui-components'
import { isNotNil } from '@persuit/common-utils'

import { Link, useLocation, useHistory } from 'react-router-dom'

import { TemplateCard } from './template-card'
import { FilterSelect } from './filter-select'

import { MATTER_TYPES } from '../../../common/data/template-tags'
import { USE_CASES } from '../../../common/data/use-cases'
import { logger } from '@persuit/ui-logger'
import { Unauthorized } from '@persuit/ui-shared-components'
import { StartFromScratchButton } from './start-from-scratch-button'
import { parseFilters, serializeFilters } from './routing'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import useTemplateCountries, {
	NewTemplateCountryInfo,
} from 'packages/ui-shared-components/src/rfp-template-form/detail-section/use-template-countries'

const TEMPLATES_QUERY = graphql(`
	query Templates_GetPublishedRfpTemplates {
		getPublishedRfpTemplates {
			_id
			templateType
			templateTitle
			useCase
			templateTags {
				matterType
				country
			}
			... on RfpTemplate {
				...TemplateCard_RfpTemplate
			}
		}
	}
`)

type CreateRequestFromTemplateProps = {
	templateCountries: NewTemplateCountryInfo[]
	templateCountryLookupBySearchKey: Record<string, NewTemplateCountryInfo>
}

const CreateRequestFromTemplate = ({
	templateCountries,
	templateCountryLookupBySearchKey,
}: CreateRequestFromTemplateProps) => {
	const { toggles } = useFeatureToggles()
	const companyRateReviewToggle = toggles['dev-5754.company-rate-review']

	const { data, error } = useQuery(TEMPLATES_QUERY, { fetchPolicy: 'cache-and-network' })

	const location = useLocation()

	const history = useHistory()

	const initialFilters = parseFilters(location.search, (countryKey) => {
		return !!templateCountryLookupBySearchKey[countryKey]
	})

	const [useCases, setUseCases] = useState(
		initialFilters.useCaseIds
			.map((id) => USE_CASES.find((useCase) => useCase.id === id))
			.filter(isNotNil),
	)
	const [matterTypes, setMatterTypes] = useState(
		initialFilters.matterTypeIds
			.map((id) => MATTER_TYPES.find((useCase) => useCase.id === id))
			.filter(isNotNil),
	)
	const [countries, setCountries] = useState(
		initialFilters.countryIds
			.map((searchKey) => templateCountries.find((country) => country.searchKey === searchKey))
			.filter(isNotNil),
	)

	useEffect(() => {
		history.replace(
			`${location.pathname}?${serializeFilters({
				useCaseIds: useCases.map(({ id }) => id),
				matterTypeIds: matterTypes.map(({ id }) => id),
				countryIds: countries.map(({ searchKey }) => searchKey),
			})}`,
		)
	}, [useCases, matterTypes, countries, history, location.pathname])

	if (error) {
		logger.error(error)
		return <Unauthorized redirectPath="/en/" buttonLabel="Go to dashboard" />
	}

	if (!data) {
		return (
			<Box p={10}>
				<LoadingSpinner />
			</Box>
		)
	}

	const allTemplates = data.getPublishedRfpTemplates.filter(isNotNil)
	const displayedTemplates = allTemplates
		.filter(({ templateTags, useCase }) => {
			// If there are no filters return true
			if (countries.length === 0 && matterTypes.length === 0 && useCases.length === 0) return true
			// Cannot match filter if there are no tags
			// TODO: Remove this once templateTags is made required
			if (!templateTags) return false
			// Tags should match at least one of the filters
			// This is an OR filter on multiple options within same category and an AND filter between categories
			return (
				(countries.length === 0 ||
					(templateTags.country && countries.find(({ id }) => id === templateTags.country))) &&
				(matterTypes.length === 0 ||
					(templateTags.matterType &&
						matterTypes.find(({ id }) => id === templateTags.matterType))) &&
				(useCases.length === 0 || (useCase && useCases.find(({ id }) => id === useCase)))
			)
		})
		// Sort alphabetically
		.sort((a, b) => ((a.templateTitle ?? '') < (b.templateTitle ?? '') ? -1 : 1))

	const clientTemplates = displayedTemplates.filter((template) => template?.templateType === 'user')

	const persuitTemplates = displayedTemplates.filter(
		(template) => template?.templateType === 'system',
	)

	const templates = [...clientTemplates, ...persuitTemplates].filter((template) => {
		if (!companyRateReviewToggle && template.useCase === 'rateReview') return false
		return true
	})

	const useCaseOptions = USE_CASES.filter((useCase) => {
		if (!companyRateReviewToggle && useCase.id === 'rateReview') return false
		return true
	})

	return (
		<>
			<PageTitle>Request creation</PageTitle>
			<BorderBox maxWidth="1200px" margin="auto" pt={6}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box display="flex" alignItems="center">
						<IconButton
							style={{ marginRight: 24 }}
							component={Link}
							to="/en"
							aria-label="back to dashboard"
						>
							<ArrowBackIcon />
						</IconButton>

						<Typography component="h1" variant="h4">
							Select a template to get started
						</Typography>
					</Box>

					<StartFromScratchButton />
				</Box>

				<Box display="flex" justifyContent="flex-end" alignItems="flex-end" mb={3} mt={3}>
					<Button
						color="primary"
						style={{ textDecoration: 'underline', marginBottom: '-6px' }}
						size="large"
						onClick={() => {
							setUseCases([])
							setMatterTypes([])
							setCountries([])
						}}
						data-trackid="button-clear-filters"
					>
						Clear filters
					</Button>

					<Box ml={2}>
						<FilterSelect
							label="Use Case"
							onChange={setUseCases}
							value={useCases}
							options={useCaseOptions}
							dataTrackName="use-case"
						/>
					</Box>

					<Box ml={2}>
						<FilterSelect
							label="Category"
							onChange={setMatterTypes}
							value={matterTypes}
							options={MATTER_TYPES}
							dataTrackName="matter-type"
						/>
					</Box>

					<Box ml={2}>
						<FilterSelect
							label="Country"
							onChange={setCountries}
							value={countries}
							options={templateCountries}
							dataTrackName="country"
						/>
					</Box>
				</Box>

				{templates.length === 0 ? (
					<Box pt={4} maxWidth="550px" margin="auto">
						<Typography color="text.secondary" align="center">
							There aren't any templates matching the filters you've selected. Please try
							unselecting some filters or click "Clear filters" to start over.
						</Typography>
					</Box>
				) : (
					<Box role="list" aria-label="List of templates. Focus template to see more.">
						{templates.map((template) => (
							<TemplateCard
								key={template._id}
								templateFragment={template}
								role="listitem"
								aria-labelledby={`${template._id}-title ${template._id}-vendor`}
								aria-describedby={`${template._id}-description`}
								tabIndex={0}
							/>
						))}
					</Box>
				)}
			</BorderBox>
		</>
	)
}

export const CreateRequestFromTemplatePage = () => {
	const { templateCountries, templateCountryLookupBySearchKey, loading } = useTemplateCountries()

	if (loading) {
		return null
	}

	return (
		<CreateRequestFromTemplate
			templateCountries={templateCountries}
			templateCountryLookupBySearchKey={templateCountryLookupBySearchKey}
		/>
	)
}
