import * as React from 'react'
import { Box, BoxProps } from '../box'
import { visuallyHidden } from '@mui/utils'

export type SROnlyProps<D extends React.ElementType = 'span', P = unknown> = BoxProps<D, P>

export const SROnly = (props: SROnlyProps) => (
	<Box
		component="span"
		data-testid="sr-only"
		{...props}
		sx={{ ...(visuallyHidden as any), ...props.sx }}
	/>
)

SROnly.displayName = 'SROnly'
