import { Dialog, RichTextView } from '@persuit/ui-components'

type ViewAssumptionDialogProps = {
	handleViewClose: () => void
	isOpen: boolean
	currentAssumptions: string
	isClientOrg?: boolean
}

export const ViewAssumptionDialog = ({
	handleViewClose,
	isOpen,
	currentAssumptions,
	isClientOrg = false,
}: ViewAssumptionDialogProps) => {
	const dialogTitle = isClientOrg ? 'Assumptions & description' : "Firm's assumptions & description"

	return (
		<Dialog
			size="sm"
			onClick={(e) => {
				e.stopPropagation()
			}}
			open={isOpen}
			onClose={handleViewClose}
			title={dialogTitle}
			actions={[
				{
					label: 'Ok',
					variant: 'primary',
					onClick: handleViewClose,
					'data-testid': 'close-assumptions-button',
				},
			]}
			aria-describedby="alert-dialog-description"
		>
			{currentAssumptions && (
				<RichTextView
					data-testid="rfpr-view-assumptions"
					content={currentAssumptions}
					id="alert-dialog-description"
				/>
			)}
		</Dialog>
	)
}
