import { useState, ReactNode } from 'react'
import {
	Box,
	useTheme,
	Typography,
	Dialog,
	ZoomInIcon,
	CopyIcon,
	ScreenOnly,
} from '@persuit/ui-components'
import { useStore } from '../../store'

import { GridCell, GridCellProps } from './cells'

export type BaseIntelligenceCellProps = GridCellProps

export const BaseIntelligenceCell = ({ ...rest }: BaseIntelligenceCellProps) => {
	const theme = useTheme()
	const { isWide } = useStore((state) => ({
		isWide: state.isWideIntelligence,
	}))

	return (
		<GridCell
			{...rest}
			sx={{
				background: theme.palette.teal50,
				borderBottom: '1px solid lightgrey',
				maxWidth: '380px',
				minWidth: isWide ? '600px' : '300px',
				padding: '1em',
				transition: 'background 2s',
				borderLeft: `3px solid ${theme.palette.secondary.main}`,
				borderRight: `3px solid ${theme.palette.secondary.main}`,
				...rest.sx,
			}}
		/>
	)
}

export type ExpandableIntelligenceCellProps = BaseIntelligenceCellProps & {
	expandedContent: ReactNode
	expandedTitle: ReactNode
	expandedSubTitle?: ReactNode
	copyToClipboard?: () => void
	copyToClipboardDisabled?: boolean
}

export const ExpandableIntelligenceCell = ({
	children,
	expandedContent,
	expandedTitle,
	expandedSubTitle,
	copyToClipboard,
	copyToClipboardDisabled,
	...boxProps
}: ExpandableIntelligenceCellProps) => {
	const theme = useTheme()

	const [showDialog, setShowDialog] = useState(false)
	const closeDialog = () => setShowDialog(false)
	const openDialog = () => setShowDialog(true)

	const noCellContent = !children

	const dialogTitle = (
		<>
			{expandedSubTitle && (
				<Typography display="block" variant="caption">
					{expandedSubTitle}
				</Typography>
			)}
			{expandedTitle}
		</>
	)

	return (
		<>
			<BaseIntelligenceCell
				{...boxProps}
				onClick={noCellContent ? () => undefined : openDialog}
				sx={{
					position: 'relative',
					wordWrap: 'break-word',
					'&:hover': {
						zIndex: 900,
						transform: `scale(1.05, 1.05)`,
						boxShadow: `0 0 3px 1px ${theme.palette.primary.main}`,
						cursor: 'pointer',
					},
					...boxProps.sx,
				}}
			>
				<Box sx={{ mb: '1em', height: '100%' }}>
					{children}
					{noCellContent ? null : (
						<ScreenOnly>
							<ZoomInIcon
								sx={{
									color: theme.palette.primary.main,
									display: 'block',
									position: 'absolute',
									right: '-1px',
									bottom: '-1px',
								}}
							/>
						</ScreenOnly>
					)}
				</Box>
			</BaseIntelligenceCell>

			<Dialog
				open={showDialog}
				onClose={closeDialog}
				size="md"
				title={dialogTitle}
				actions={[
					{
						label: 'Copy',
						variant: 'text',
						onClick: copyToClipboard,
						disabled: copyToClipboardDisabled,
						startIcon: <CopyIcon />,
					},
					{
						label: 'Close',
						variant: 'primary',
						onClick: closeDialog,
					},
				]}
			>
				<Box sx={{ wordBreak: 'break-word' }}>{expandedContent}</Box>
			</Dialog>
		</>
	)
}
