import { Box, SortableExpandableCard } from '@persuit/ui-components'
import { PricingCardTemplate } from '../../../pricing'
import getOr from 'lodash/fp/getOr'
import { usePricingListContext } from '../../pricing-list-context'
import { refocusOnRemoval, usePricingItemFieldPath, useWatch } from '../../pricing-form-utils'
import { PricingSubItemList } from '../../pricing-sub-item-list'
import { PricingItemHeaderContainer } from './pricing-item-header'
import { PricingItemBody } from './pricing-item-body'
import { PricingItemFooter } from './pricing-item-footer'
import { getPricingError } from './get-pricing-error'
import { PricingItemMoreActionsMenu } from './pricing-item-more-actions-menu'

type PricingItemProps = {
	id: string
	/** index of pricing items within pricingItems array if it is a floating item
	 * or else this is the index of its group */
	index: number
	/** Only available if pricing item belongs to a group */
	groupIndex?: number
	/** Lets the menus know if there are groups in the document */
	hasGroups?: boolean
	removeItem: () => void
	ungroupItem?: () => void
	movePricingItemIntoGroup?: (groupId: string) => void
	activeDragId?: string | number | null
}

export const PricingItem = ({
	index,
	id,
	groupIndex,
	hasGroups,
	removeItem,
	ungroupItem,
	movePricingItemIntoGroup,
	activeDragId = null,
}: PricingItemProps) => {
	const { errors, getItemExpanded, toggleItemExpanded, getPricingItemIndex } =
		usePricingListContext()

	const pricingItemIndex = getPricingItemIndex(id)

	const pricingError = getPricingError(errors, pricingItemIndex)
	const hasPricingErrors = Boolean(pricingError)
	const hasValidationErrors = pricingError && getOr('', 'deliverableTitle', pricingError)

	const { path } = usePricingItemFieldPath(index, groupIndex)

	const isOriginalPricingItem = !!useWatch({
		name: `${path}.originalDeliverableId`,
	})

	const isActiveDragging = activeDragId === id

	return (
		<SortableExpandableCard
			id={id}
			shouldRenderDetailsOnCollapse={false}
			containerProps={{
				id: `pricing_item_${pricingItemIndex}`,
				tabIndex: -1,
				'aria-label': `Pricing item ${pricingItemIndex + 1}`,
				role: 'group',
				bgcolor: isActiveDragging ? 'primary.lighterHue' : undefined,
			}}
			style={{
				opacity: isActiveDragging ? 0 : 1,
			}}
			summary={<PricingItemHeaderContainer id={id} fieldPath={path} />}
			summaryProps={{
				'aria-label': `Pricing item ${pricingItemIndex + 1}`,
			}}
			summarySuffix={
				<PricingItemMoreActionsMenu
					hasGroups={hasGroups}
					pricingItemIndex={pricingItemIndex}
					index={index}
					groupIndex={groupIndex}
					removeItem={() => {
						refocusOnRemoval({
							currentIndex: pricingItemIndex,
						})
						removeItem()
					}}
					movePricingItemIntoGroup={movePricingItemIntoGroup}
					ungroupItem={ungroupItem}
				/>
			}
			details={
				<Box position="relative" width="100%">
					<PricingCardTemplate
						header={
							<PricingItemBody
								id={id}
								fieldPath={path}
								hasValidationErrors={hasValidationErrors}
								isOriginalPricingItem={isOriginalPricingItem}
							/>
						}
						body={
							<PricingSubItemList pricingItemFieldPath={path} pricingItemIndex={pricingItemIndex} />
						}
						footer={<PricingItemFooter id={id} fieldPath={path} />}
					/>
				</Box>
			}
			hasError={!!hasValidationErrors || hasPricingErrors}
			errorIconProps={{
				'data-testid': 'notify-error',
			}}
			expanded={!isActiveDragging && getItemExpanded({ _id: id })}
			onToggle={() => toggleItemExpanded({ _id: id })}
			sortableListItemProps={{
				containerProps: {
					mb: 2,
					'data-testid': 'item-card-container',
				},
			}}
		/>
	)
}
