import { omitDeep, OmitDeep, KeyofDeep } from './omitDeep'

export function omitDeepArray<T extends Record<string, unknown>[], K extends KeyofDeep<T>>(
	arr: T,
	key: K,
): OmitDeep<T, K> {
	return arr.map((val) => {
		if (Array.isArray(val)) {
			return omitDeepArray(val, key)
		} else if (typeof val === 'object') {
			return omitDeep(val, key)
		}

		return val
	}) as any
}
