import { Fragment } from 'react'
import { styled, Typography } from '@persuit/ui-components'
import { getOrEmptyString } from '../../../utils/lenses'
import Spacer from '../../layout/spacer.jsx'

const StyledContainer = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const ProposalDetailsContainer = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`

const StyledRatingContainer = styled('section')`
	display: flex;
	flex-direction: row;
`

const StyledRatingTitle = styled('span')`
	width: 7em;
	font-weight: bold;
`

const ratingMap = {
	unanswered: null,
	notApplicable: null,
	needsImprovement: 'Needs Improvement',
	satisfactory: 'Satisfactory',
	good: 'Good',
	excellent: 'Excellent',
}

const ratingToDisplay = (rating) => {
	if (!(rating in ratingMap)) return null
	return ratingMap[rating]
}

const ProposalFeedbackDisplayContent = ({ feedback, showProposalDetails = false }) => {
	const status = getOrEmptyString('status', feedback)
	const subject = getOrEmptyString('subject', feedback)
	const strategyRating = getOrEmptyString('ratings.strategy', feedback)
	const strategyRatingDisplay = ratingToDisplay(strategyRating)
	const pricingRating = getOrEmptyString('ratings.pricing', feedback)
	const pricingRatingDisplay = ratingToDisplay(pricingRating)
	const expertiseRating = getOrEmptyString('ratings.expertise', feedback)
	const expertiseRatingDisplay = ratingToDisplay(expertiseRating)
	const diversityRating = getOrEmptyString('ratings.diversity', feedback)
	const diversityRatingDisplay = ratingToDisplay(diversityRating)
	const comment = getOrEmptyString('comment', feedback)

	return (
		<StyledContainer>
			{showProposalDetails && (
				<Fragment>
					<ProposalDetailsContainer>
						<span>{subject}</span>
						<Typography color="text.secondary">{status}</Typography>
					</ProposalDetailsContainer>
					<Spacer space={1} />
				</Fragment>
			)}
			{strategyRatingDisplay && (
				<StyledRatingContainer data-testid="feedback-strategy">
					<StyledRatingTitle aria-label="Feedback - Strategy">Strategy</StyledRatingTitle>
					<span>{strategyRatingDisplay}</span>
				</StyledRatingContainer>
			)}
			{pricingRatingDisplay && (
				<StyledRatingContainer data-testid="feedback-pricing">
					<StyledRatingTitle aria-label="Feedback - Pricing">Pricing</StyledRatingTitle>
					<span>{pricingRatingDisplay}</span>
				</StyledRatingContainer>
			)}
			{expertiseRatingDisplay && (
				<StyledRatingContainer data-testid="feedback-expertise">
					<StyledRatingTitle aria-label="Feedback - Expertise">Expertise</StyledRatingTitle>
					<span>{expertiseRatingDisplay}</span>
				</StyledRatingContainer>
			)}
			{diversityRatingDisplay && (
				<StyledRatingContainer data-testid="feedback-diversity">
					<StyledRatingTitle aria-label="Feedback - Diversity">Diversity</StyledRatingTitle>
					<span>{diversityRatingDisplay}</span>
				</StyledRatingContainer>
			)}
			{comment && (
				<Fragment>
					<Spacer space={1} />
					<span aria-label="Feedback - Comment">{comment}</span>
				</Fragment>
			)}
		</StyledContainer>
	)
}

export default ProposalFeedbackDisplayContent
