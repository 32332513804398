import numeral from 'numeral'
import { CURRENCIES } from '../currencies'
/**
 * Formats a number as a currency. Supports both Variance and Financial
 * @param {String} - currencyCode AUD, USD, PLN
 * @param {String} - price
 * @param {Bool} - showCurrencyCode default to true. Used in the Comparator cell to provide more room
 */

export interface formatPriceWithCurrency {
	showCurrencyCode?: boolean | 'dollar' | 'all'
	format?: string
	/* Add space between currency symbol and value */
	spaceBetween?: boolean
}

export const formatPriceWithCurrency = (
	currencyCode: string,
	price: string | number | null | undefined,
	options?: formatPriceWithCurrency | boolean,
	/** @deprecated Use options argument instead */
	_format: string = '0,0.[0000]',
) => {
	const showCurrencyCode =
		(typeof options === 'boolean' ? options : options?.showCurrencyCode) ?? true
	const format =
		(options && typeof options !== 'boolean' ? options?.format : _format) || '0,0.[0000]'
	const spaceBetween = options && typeof options !== 'boolean' ? options?.spaceBetween : false

	if (!(currencyCode in CURRENCIES)) {
		throw new Error(`${currencyCode} not a valid currency`)
	}

	const currencySymbol = CURRENCIES[currencyCode].symbol
	// If no price set, make price 0 for display purposes
	// commonly seen on firm draft proposal preview/comparator
	let formattedPrice
	if (price === null || price === undefined || price === '') {
		formattedPrice = 0
	} else {
		formattedPrice = Number.isInteger(parseInt(`${price}`, 10))
			? numeral(price).format(format)
			: price
	}

	if (spaceBetween && currencySymbol) {
		formattedPrice = ` ${formattedPrice}`
	}

	// Add currency code (AUD,USD) when currencySymnol is "$"
	// Also option to not show currencyCode at all
	if (
		(currencySymbol === '$' && (showCurrencyCode === 'dollar' || showCurrencyCode === true)) ||
		showCurrencyCode === 'all'
	) {
		return `${currencySymbol}${formattedPrice} ${currencyCode}`
	}

	return `${currencySymbol}${formattedPrice}`
}
