export default {
	ANY_SHARING_OUTSIDE_TRUSTED_DOMAIN: 'any-sharing-outside-trusted-domain',
	ANY_SHARING_WITHIN_SSO_ONLY_ORG: 'any-sharing-within-sso-only-org',
	CLIENT_SHARING_WITH_ANOTHER_CLIENT: 'client-sharing-with-another-client',
	CLIENT_SHARING_WITH_ANOTHER_ORG: 'client-sharing-with-another-org',
	CLIENT_SHARING_WITH_FIRM: 'client-sharing-with-firm',
	CLIENT_SHARING_WITH_OWNER: 'client-sharing-with-owner',
	CLIENT_INVITING_OWN_ORG: 'client-inviting-own-org',
	FIRM_SHARING_WITH_ANOTHER_CLIENT: 'firm-sharing-with-another-client',
	FIRM_SHARING_WITH_ANOTHER_FIRM: 'firm-sharing-with-another-firm',
	FIRM_SHARING_WITH_ANOTHER_ORG: 'firm-sharing-with-another-org',
	FIRM_INVITING_ANY: 'firm-inviting-any',
	ANY_ADDING_SELF: 'any-adding-self',
	INVALID_EMAIL: 'invalid-email',
	ALREADY_SHARED_WITH: 'already-shared-with',
}
