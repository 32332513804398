import { useState } from 'react'
import { Box, Button, useSnackbar } from '@persuit/ui-components'
import { SharingAutoComplete } from './sharing-autocomplete'
import { useInvitablePeople } from '../../../../custom-hooks'
import { MappedSharedItem, User } from '../types'
import { getInvitablePeople } from '../utils'
import { useInviteContext } from '../invite-context'

export type InviteByEmailProps = {
	rfpId: string
	handleShare: (users: MappedSharedItem[]) => Promise<void>
	user: User
	userFavorites: string[]
	inviteError: string | undefined
	firmLists: Array<{
		_id: string
		name: string
		isPanel: boolean
		approved: boolean
		firms: { org: { _id?: string | null | undefined } }[]
	}>
}

export const InviteByEmailForm = ({
	firmLists,
	user,
	userFavorites,
	inviteError,
}: InviteByEmailProps) => {
	const [userIdsToBeAdded, setUserIdsToBeAdded] = useState<string[]>([])
	const [pending, _setPending] = useState<Record<string, boolean>>({})
	const { onAdd, selectedUsers } = useInviteContext()
	const { openSnackbar } = useSnackbar()
	const people = useInvitablePeople({
		includeColleagues: false,
	})

	const setPending = (update: Parameters<typeof _setPending>[0]) => {
		const values = typeof update === 'function' ? update(pending) : update

		setUserIdsToBeAdded(Object.keys(values))

		_setPending(update)
	}

	const invitablePeople = getInvitablePeople(
		people.data?.getInvitablePeople.users ?? [],
		user,
		user?.org?._id ?? null,
		userFavorites,
	)

	const confirm = async () => {
		const knownUserIds = userIdsToBeAdded.filter((userId) => !userId.includes('@'))
		const knownUsersToAdd = invitablePeople.filter((user) => knownUserIds.includes(user._id))

		const unknownUserIds = userIdsToBeAdded.filter((userId) => userId.includes('@'))

		await onAdd([...knownUsersToAdd, ...unknownUserIds])

		openSnackbar(
			`${userIdsToBeAdded.length} ${
				userIdsToBeAdded.length === 1 ? 'contact' : 'contacts'
			} added to Invitation List`,
		)

		setUserIdsToBeAdded([])
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 4 }}>
			<SharingAutoComplete
				people={invitablePeople}
				selectedPeople={selectedUsers}
				firmLists={firmLists}
				pending={pending}
				setPending={setPending}
				inviteError={inviteError}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					variant="contained"
					disabled={!Object.keys(pending).length}
					onClick={() => {
						confirm()
						setPending({})
					}}
					aria-label="Invite selected contacts"
					data-trackid="button-invites-invite-by-email-add-contacts"
				>
					Add to Invitation List
				</Button>
			</Box>
		</Box>
	)
}
