import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type EventType = keyof DocumentEventMap

type ActivityDetectorProps = {
	/** Callback function called when a user activity is detected */
	onActivityDetected: (event: Event) => void

	/** Specify the delay time (ms) for debouncing onActivityDetected callback. Default: 0 */
	delay?: number

	/** Maximum time to wait before triggering callback invocation (in case user keep making non stop activities). Default: delay * 5 */
	maxWait?: number
}

const USER_ACTIVITY_EVENTS: EventType[] = [
	'click',
	'dblclick',
	'keydown',
	'wheel',
	'mousemove',
	'mousedown',
	'touchstart',
	'touchmove',
	'focus',
]

export const ActivityDetector = ({
	onActivityDetected,
	delay = 0,
	maxWait,
}: ActivityDetectorProps) => {
	const debouncedActivityHandler = useDebouncedCallback(
		async (event: Event) => {
			await onActivityDetected(event)
		},
		delay,
		{
			leading: true,
			maxWait: maxWait ?? delay * 5,
		},
	)

	useEffect(() => {
		// Attach listeners
		USER_ACTIVITY_EVENTS.forEach((type) => {
			window.addEventListener(type, debouncedActivityHandler)
		})

		return () => {
			// Detach listeners
			USER_ACTIVITY_EVENTS.forEach((type) => {
				window.removeEventListener(type, debouncedActivityHandler)
			})
		}
	}, [debouncedActivityHandler])

	return null
}
