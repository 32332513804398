import injectSheet from '../injectSheet'
import persuitTheme from '../theme/persuit-theme'

const Offset = ({ classes, children }) => <div className={classes.offset}>{children}</div>

Offset.styles = {
	offset: {
		[`@media screen`]: {
			padding: `0px ${persuitTheme.grid.mobileGutter / 2}px`,
			height: '100%',
		},
		[`@media screen and (min-width: ${persuitTheme.grid.breakpoints.sm.minWidth})`]: {
			padding: `0px ${persuitTheme.grid.desktopGutter / 2}px`,
		},

		[`@media screen and (min-width: ${persuitTheme.grid.breakpoints.md.minWidth})`]: {
			padding: `0px ${persuitTheme.grid.breakpoints.md.offset}`,
		},

		[`@media screen and (min-width: ${persuitTheme.grid.breakpoints.lg.minWidth})`]: {
			padding: `0px ${persuitTheme.grid.breakpoints.lg.offset}`,
		},

		[`@media screen and (min-width: ${persuitTheme.grid.breakpoints.xl.minWidth})`]: {
			padding: `0px ${persuitTheme.grid.breakpoints.xl.offset}`,
		},
	},
}

export default injectSheet(Offset.styles)(Offset)
