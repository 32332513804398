import isEmpty from 'lodash/fp/isEmpty'
import {
	getCurrencyName,
	DeepPick,
	getMultiCurrencyNames,
	isAnyCurrencies,
} from '@persuit/common-utils'
import { Box, useTheme, Spacer } from '@persuit/ui-components'
import { AuctionFirmVisibility, Rfp } from '../../../types'
import { AuctionFirmVisibilityStrings } from '../../constants'
import { LabeledValue } from '../labeled-value'
import {
	RfpDetailClarificationSection,
	RfpDetailClarificationSectionDocType,
} from '../clarifications'
import { RfpDetailDiversitySection, RfpDetailDiversitySectionDocType } from '../diversity'
import { RfpDetailConflictSection, RfpDetailConflictSectionDocType } from '../conflict'
import { RfpDetailDatesSection, RfpDetailDatesSectionDocType } from '../dates'
import { RfpDetailFilesSection, RfpDetailFilesSectionDocType } from '../files'
import { RfpDetailSummarySection, RfpDetailSummarySectionDocType } from '../summary'
import { RfpDetailPricingSection, RfpDetailPricingSectionDocType } from '../pricing'
import { RfpDetailQuestionsSection, RfpDetailQuestionsSectionDocType } from '../question'

import { getRequestedBy } from '../../util'
import { RfpDetailRatesSection } from '../rates'
import { RateReview } from '@persuit/ui-graphql/generated'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'

const getAuctionFirmVisibilityString = (auctionFirmVisibility: AuctionFirmVisibility) => {
	if (!(auctionFirmVisibility in AuctionFirmVisibilityStrings)) return 'INVALID'
	return AuctionFirmVisibilityStrings[auctionFirmVisibility]
}

export type RfpDetailDocType = RfpDetailClarificationSectionDocType &
	RfpDetailDatesSectionDocType &
	RfpDetailSummarySectionDocType &
	RfpDetailFilesSectionDocType &
	RfpDetailPricingSectionDocType &
	RfpDetailQuestionsSectionDocType &
	RfpDetailDiversitySectionDocType &
	RfpDetailConflictSectionDocType &
	DeepPick<
		Rfp,
		| 'auction'
		| 'auctionFirmVisibility'
		| 'detail.currency'
		| 'detail.totalPriceRequired'
		| 'detail.deliverablesV2'
		| 'org.name'
		| 'requestedBy'
		| 'useCase'
		| 'rateReview'
	>

type CurrencyDetailViewProps = {
	rfpUseCase: Rfp['useCase']
	rateReviewCurrencies: RateReview['currencies']
	paymentCurrency: RateReview['currency']
}
const CurrencyDetailView = ({
	rfpUseCase,
	rateReviewCurrencies,
	paymentCurrency,
}: CurrencyDetailViewProps) => {
	const isRateReview = rfpUseCase === 'rateReview'

	if (isRateReview) {
		const currencies = rateReviewCurrencies ?? []
		const value = getMultiCurrencyNames(currencies)
		const isTextAnyCurrencies = isAnyCurrencies(value)

		return (
			<LabeledValue
				label="Available currency:"
				value={value}
				italicGrey={isEmpty(currencies)}
				aria-label={isTextAnyCurrencies ? value : currencies.join(',')}
				title={isTextAnyCurrencies ? value : currencies.join(',')}
				role="region"
			/>
		)
	}

	const currencyValue = paymentCurrency ?? ''

	return (
		<LabeledValue
			label="Payment currency"
			value={getCurrencyName(currencyValue)}
			italicGrey={isEmpty(currencyValue)}
		/>
	)
}

export type RfpDetailProps = {
	isFirmView?: boolean
	isTemplate?: boolean
	document: RfpDetailDocType
}

export const RfpDetail = ({ document, isFirmView = false, isTemplate = false }: RfpDetailProps) => {
	const theme = useTheme()
	const { toggles } = useFeatureToggles()
	const isMultiCurrencyEnabled = toggles['dev-10954.multi-currency']

	// When the use case is rateReview, we need to fake force the aution to be false
	// (Even though it might be true when pre-send)
	const auction = document?.useCase === 'rateReview' ? false : document?.auction ?? false
	const auctionFirmVisibility = document?.auctionFirmVisibility ?? ''
	const orgName = document?.org?.name ?? ''
	const requestedBy = document?.requestedBy ?? ''

	const rateReviewCurrencies =
		isMultiCurrencyEnabled && !isTemplate
			? document.rateReview?.currencies ?? []
			: [document.rateReview?.currency ?? '']

	return (
		<Box>
			<RfpDetailClarificationSection document={document} />

			<Box width="100%" display="flex" justifyContent="flex-start" gap={theme.spacing(2)}>
				<Box width="30%">
					<LabeledValue label="Request from:" value={getRequestedBy(orgName, requestedBy)} />
				</Box>

				<Box width="30%" data-testid="payment-currency">
					<CurrencyDetailView
						rfpUseCase={document.useCase}
						rateReviewCurrencies={rateReviewCurrencies ?? []}
						paymentCurrency={document.detail?.currency ?? ''}
					/>
				</Box>

				{auction && auctionFirmVisibility && (
					<Box width="30%" data-testid="auction-visibility">
						<LabeledValue
							label="What will firms see during the auction:"
							value={getAuctionFirmVisibilityString(auctionFirmVisibility)}
						/>
					</Box>
				)}
			</Box>

			<Spacer space={4} shape="row" />

			<RfpDetailDatesSection document={document} />

			<RfpDetailSummarySection document={document} />

			<RfpDetailFilesSection document={document} />

			<RfpDetailPricingSection document={document} isFirmView={isFirmView} />

			<RfpDetailRatesSection document={document} />

			<RfpDetailQuestionsSection document={document} />

			<RfpDetailDiversitySection document={document} isFirmView={isFirmView} />

			<RfpDetailConflictSection document={document} />
		</Box>
	)
}
