import { AriaAttributes } from 'react'
import { Box, visuallyHidden } from '@persuit/ui-components'
import { SortObject, useStore } from '../../store'
import { responseColumnId, intelligenceColumnId, firmNameRowId } from '../accessibilty'

/** This component is used as a 1-1 map to ColumnHeaderRow, for screen reader only
 *  to avoid excessive announcement of all other details like buttons, date etc.
 *  as users navigate through table cells */
export const SRColumnHeaderRow = () => {
	const { currentSort, showIntelligenceColumn, responses } = useStore((state, s) => ({
		currentSort: state.sort,
		showIntelligenceColumn: s.showIntelligenceColumn(),
		responses: state.responses,
	}))
	const sortOrder = currentSort?.type === 'firmName' ? currentSort.order : undefined

	function getAriaSortOrder(order: SortObject['order']): AriaAttributes['aria-sort'] {
		return order
			? order === 'asc'
				? 'ascending'
				: order === 'desc'
				? 'descending'
				: 'none'
			: undefined
	}

	return (
		<Box sx={{ ...visuallyHidden }} component="tr">
			<th scope="row" aria-sort={getAriaSortOrder(sortOrder)} id={firmNameRowId}>
				Firm name
			</th>
			{showIntelligenceColumn && (
				<th scope="col" id={intelligenceColumnId}>
					Proposal Analyzer with AI
				</th>
			)}
			{responses.map((response) => {
				return (
					<th key={response._id} scope="col" id={responseColumnId(response._id)}>
						{response.status === 'competitor'
							? 'Proposal from competitor firm'
							: response.org?.name}
					</th>
				)
			})}
		</Box>
	)
}
