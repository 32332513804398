// @ts-strict-ignore
import { Box, Button, LockIcon, EditIcon, useTheme } from '@persuit/ui-components'
import { useId } from 'react'
import * as React from 'react'
import { TemplateSectionNavigation } from './template-section-navigation'
import { SectionalLocks } from '../../sectional-form/types'
import { TemplateLockedText } from './template-locked-text'
import { ga } from '@persuit/ui-analytics'
import { SectionalFormContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'

function getSectionalLock(sectionalLocks, sectionName) {
	if (!(sectionName in sectionalLocks)) return null
	return sectionalLocks[sectionName]
}

const sendGaLockEvent = () => {
	ga({ page: 'LOCKED_SECTION', event: 'OPENED_LOCKED_SECTION', label: 'RFP_TEMPLATE_FORM' })
}

type TemplateLockableSectionInnerProps = {
	children: React.ReactNode
	sectionName: string
	sectionalLocks: SectionalLocks
	instanceId: string
	tryLock: (sectionName: string) => Promise<void>
	rfpTemplate: RfpTemplate
	userId: string
	isDiversitySectionEnabled: boolean
}

export const TemplateLockableSectionInner = ({
	children,
	sectionName,
	sectionalLocks,
	instanceId,
	tryLock,
	userId,
	rfpTemplate,
	isDiversitySectionEnabled,
}: TemplateLockableSectionInnerProps) => {
	const [shouldSendGaEvent, setShouldSendGaEvent] = React.useState(true)
	const theme = useTheme()

	React.useEffect(() => {
		tryLock(sectionName)
		// OnMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sectionalLock = getSectionalLock(sectionalLocks, sectionName)
	const lockDescriptionId = useId()

	if (!sectionalLock || sectionalLock.formInstanceId === instanceId)
		return (
			<React.Fragment>
				{children}
				{rfpTemplate._id && (
					<TemplateSectionNavigation
						currentSection={sectionName}
						rfpTemplate={rfpTemplate}
						isDiversitySectionEnabled={isDiversitySectionEnabled}
					/>
				)}
			</React.Fragment>
		)

	const lockedByYou = sectionalLock.userId === userId

	if (!lockedByYou && shouldSendGaEvent) {
		sendGaLockEvent()
		setShouldSendGaEvent(false)
	}

	return (
		<SectionalFormContainer focusOnMount={true} p={0}>
			<Box
				width="100%"
				padding={1}
				border-radius="5px 5px 0px 0px"
				bgcolor={theme.palette.primary.lighterHue}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				boxSizing="border-box"
				height="72px"
			>
				<Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(1)}>
					<LockIcon color="primary" aria-hidden="true" />
					<TemplateLockedText
						id={lockDescriptionId}
						sectionalLock={sectionalLock}
						userId={userId}
					/>
				</Box>
				<Box>
					{lockedByYou && (
						<Button
							color="primary"
							startIcon={<EditIcon />}
							onClick={() => tryLock(sectionName)}
							aria-describedby={lockDescriptionId}
						>
							Edit here
						</Button>
					)}
				</Box>
			</Box>
			{rfpTemplate._id && (
				<TemplateSectionNavigation
					currentSection={sectionName}
					rfpTemplate={rfpTemplate}
					isDiversitySectionEnabled={isDiversitySectionEnabled}
				/>
			)}
		</SectionalFormContainer>
	)
}
