const OPEN_PROPOSAL_SCORE_FORM = 'OPEN_PROPOSAL_SCORE_FORM'
const CLOSE_PROPOSAL_SCORE_FORM = 'CLOSE_PROPOSAL_SCORE_FORM'
const TOGGLE_SHOW_SUBCATEGORIES = 'TOGGLE_SHOW_SUBCATEGORIES'
const TOGGLE_SHOW_CATEGORIES_FORM = 'TOGGLE_SHOW_CATEGORIES_FORM'
const OPEN_PROPOSAL_SCORE_SUBCATEGORIES = 'OPEN_PROPOSAL_SCORE_SUBCATEGORIES'
const RESET_PROPOSAL_SCORES_STATE = 'RESET_PROPOSAL_SCORES_STATE'

function openProposalScoreForm(proposalId) {
	return {
		type: OPEN_PROPOSAL_SCORE_FORM,
		proposalId,
	}
}

function closeProposalScoreForm() {
	return {
		type: CLOSE_PROPOSAL_SCORE_FORM,
	}
}

function openProposalScoreSubcategories() {
	return {
		type: OPEN_PROPOSAL_SCORE_SUBCATEGORIES,
	}
}

function toggleShowSubcategories() {
	return {
		type: TOGGLE_SHOW_SUBCATEGORIES,
	}
}

function toggleShowCategoriesForm() {
	return {
		type: TOGGLE_SHOW_CATEGORIES_FORM,
	}
}

function resetState() {
	return {
		type: RESET_PROPOSAL_SCORES_STATE,
	}
}

export default {
	CLOSE_PROPOSAL_SCORE_FORM,
	OPEN_PROPOSAL_SCORE_FORM,
	OPEN_PROPOSAL_SCORE_SUBCATEGORIES,
	TOGGLE_SHOW_CATEGORIES_FORM,
	TOGGLE_SHOW_SUBCATEGORIES,
	RESET_PROPOSAL_SCORES_STATE,

	closeProposalScoreForm,
	openProposalScoreForm,
	openProposalScoreSubcategories,
	toggleShowCategoriesForm,
	toggleShowSubcategories,
	resetState,
}
