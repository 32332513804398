import { uiActions } from '../actions'

const {
	HIDE_CHANNELS,
	HIDE_COPY_RFP_DIALOG,
	HIDE_CREATE_TEMPLATE_DIALOG,
	HIDE_PASSWORD_CHANGE_DIALOG,
	HIDE_PROPOSAL_DELETE_DIALOG,
	HIDE_PROPOSAL_SUBMIT_DIALOG,
	HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
	HIDE_RFP_CLOSE_DIALOG,
	HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
	HIDE_RFP_FINALIZE_DIALOG,
	HIDE_RFP_OPEN_DIALOG,
	HIDE_TOTAL_PRICE_DIALOG,
	HIDE_CHANGE_END_DATE_DIALOG,
	SET_LISTING_FILTER,
	SET_LISTING_SORT,
	SHOW_CHANNELS,
	SHOW_COPY_RFP_DIALOG,
	SHOW_CREATE_TEMPLATE_DIALOG,
	SHOW_EDIT_INVITE_LIST_DIALOG,
	SHOW_PASSWORD_CHANGE_DIALOG,
	SHOW_PROPOSAL_DELETE_DIALOG,
	SHOW_PROPOSAL_SUBMIT_DIALOG,
	SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG,
	SHOW_RFP_CLOSE_DIALOG,
	SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG,
	SHOW_RFP_FINALIZE_DIALOG,
	SHOW_RFP_OPEN_DIALOG,
	SHOW_TOTAL_PRICE_DIALOG,
	SHOW_CHANGE_END_DATE_DIALOG,

	TOGGLE_INVITE_LIST_DIALOG,
	TOGGLE_PROPOSAL_ACCEPT_DIALOG,
	TOGGLE_PROPOSAL_REVISE_DIALOG,
	TOGGLE_INVITE_SEARCH_DIALOG,
	SHOW_DIALOG,
	HIDE_DIALOG,

	TOGGLE_SHARING_DIALOG,
} = uiActions

const initialState = {
	listingFilter: [],
	listingSort: null,
	showChannels: false,
	showClearConflictCheckDialog: false,
	showDeleteProposalDialog: false,
	showCopyRfpDialog: false,
	showCreateTemplateDialog: false,
	showDeleteRfpTemplateDialog: false,
	showDontClearConflictCheckDialog: false,
	showInviteListDialog: false,
	showPasswordChangeDialog: false,
	showProposalAcceptDialog: false,
	showProposalReviseDialog: false,
	showPublishProposalDialog: false,
	showRfpCloseDialog: false,
	showRfpDialog: false,
	showRfpOpenDialog: false,
	showInviteSearchDialog: false,
	showTotalPriceDialog: false,
	showChangeEndDateDialog: false,
	dialog: {
		title: null,
		content: null,
		open: false,
		cancelLabel: null,
		confirmLabel: null,
	},
	showSharingDialog: false,
}

export default function uiReducer(state = initialState, action) {
	switch (action.type) {
		case HIDE_CHANNELS: {
			return Object.assign({}, state, {
				showChannels: action.showChannels,
			})
		}

		case HIDE_PASSWORD_CHANGE_DIALOG: {
			return Object.assign({}, state, {
				showPasswordChangeDialog: false,
			})
		}

		case HIDE_PROPOSAL_DELETE_DIALOG: {
			return Object.assign({}, state, {
				showDeleteProposalDialog: false,
			})
		}

		case HIDE_PROPOSAL_SUBMIT_DIALOG: {
			return Object.assign({}, state, {
				showPublishProposalDialog: false,
			})
		}

		case HIDE_RFP_FINALIZE_DIALOG: {
			return Object.assign({}, state, {
				showRfpDialog: false,
			})
		}

		case HIDE_COPY_RFP_DIALOG: {
			return Object.assign({}, state, {
				showCopyRfpDialog: false,
			})
		}

		case HIDE_CREATE_TEMPLATE_DIALOG: {
			return Object.assign({}, state, {
				showCreateTemplateDialog: false,
			})
		}

		case SHOW_COPY_RFP_DIALOG: {
			return Object.assign({}, state, {
				showCopyRfpDialog: true,
			})
		}

		case SHOW_CREATE_TEMPLATE_DIALOG: {
			return Object.assign({}, state, {
				showCreateTemplateDialog: true,
			})
		}

		case SHOW_CHANNELS: {
			return Object.assign({}, state, {
				showChannels: action.showChannels,
			})
		}

		case SHOW_EDIT_INVITE_LIST_DIALOG: {
			return Object.assign({}, state, {
				showInviteListDialog: action.show,
			})
		}

		case SHOW_PASSWORD_CHANGE_DIALOG: {
			return Object.assign({}, state, {
				showPasswordChangeDialog: true,
			})
		}

		case SHOW_PROPOSAL_DELETE_DIALOG: {
			return Object.assign({}, state, {
				showDeleteProposalDialog: true,
			})
		}

		case SHOW_PROPOSAL_SUBMIT_DIALOG: {
			return Object.assign({}, state, {
				showPublishProposalDialog: true,
			})
		}

		case SHOW_RFP_FINALIZE_DIALOG: {
			return Object.assign({}, state, {
				showRfpDialog: true,
			})
		}

		case TOGGLE_INVITE_LIST_DIALOG: {
			return Object.assign({}, state, {
				showInviteListDialog: action.show,
			})
		}

		case TOGGLE_PROPOSAL_ACCEPT_DIALOG: {
			return Object.assign({}, state, {
				showProposalAcceptDialog: action.show,
			})
		}

		case TOGGLE_INVITE_SEARCH_DIALOG: {
			return Object.assign({}, state, {
				showInviteSearchDialog: !state.showInviteSearchDialog,
			})
		}

		case TOGGLE_PROPOSAL_REVISE_DIALOG: {
			return Object.assign({}, state, {
				showProposalReviseDialog: action.show,
			})
		}

		case HIDE_RFP_CLEAR_CONFLICT_CHECK_DIALOG: {
			return Object.assign({}, state, {
				showClearConflictCheckDialog: action.show,
			})
		}

		case SHOW_RFP_CLEAR_CONFLICT_CHECK_DIALOG: {
			return Object.assign({}, state, {
				showClearConflictCheckDialog: action.show,
			})
		}

		case HIDE_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG: {
			return Object.assign({}, state, {
				showDontClearConflictCheckDialog: action.show,
			})
		}

		case SHOW_RFP_DONT_CLEAR_CONFLICT_CHECK_DIALOG: {
			return Object.assign({}, state, {
				showDontClearConflictCheckDialog: action.show,
			})
		}

		case SHOW_RFP_CLOSE_DIALOG: {
			return Object.assign({}, state, {
				showRfpCloseDialog: action.show,
			})
		}

		case HIDE_RFP_CLOSE_DIALOG: {
			return Object.assign({}, state, {
				showRfpCloseDialog: action.show,
			})
		}

		case SHOW_RFP_OPEN_DIALOG: {
			return Object.assign({}, state, {
				showRfpOpenDialog: action.show,
			})
		}

		case HIDE_RFP_OPEN_DIALOG: {
			return Object.assign({}, state, {
				showRfpOpenDialog: action.show,
			})
		}

		case SET_LISTING_FILTER: {
			return Object.assign({}, state, {
				listingFilter: action.filter.concat(),
			})
		}

		case SET_LISTING_SORT: {
			return Object.assign({}, state, {
				listingSort: action.sort,
			})
		}

		case SHOW_DIALOG: {
			return Object.assign({}, state, {
				dialog: action.meta,
			})
		}

		case HIDE_DIALOG: {
			return Object.assign({}, state, {
				dialog: initialState.dialog,
			})
		}

		case HIDE_TOTAL_PRICE_DIALOG: {
			return Object.assign({}, state, {
				showTotalPriceDialog: action.show,
			})
		}

		case SHOW_TOTAL_PRICE_DIALOG: {
			return Object.assign({}, state, {
				showTotalPriceDialog: action.show,
			})
		}

		case SHOW_CHANGE_END_DATE_DIALOG: {
			return {
				...state,
				showChangeEndDateDialog: true,
			}
		}

		case HIDE_CHANGE_END_DATE_DIALOG: {
			return {
				...state,
				showChangeEndDateDialog: false,
			}
		}

		case TOGGLE_SHARING_DIALOG:
			return {
				...state,
				showSharingDialog: !state.showSharingDialog,
			}

		default: {
			return state
		}
	}
}
