import { useFormComponent, RHFFormComponentProps, Currency } from '@persuit/ui-components'
import { MultiCurrencySelect, MultiCurrencySelectProps } from './multi-currency-select'

type FormMultiCurrencySelectProps = RHFFormComponentProps<
	Currency,
	Omit<MultiCurrencySelectProps, 'value' | 'onChange'>
>

export const FormMultiCurrencySelect = ({
	label,
	name,
	rules,
	defaultValue,
	getDisabled,
}: FormMultiCurrencySelectProps) => {
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<MultiCurrencySelect
			error={!!error?.message}
			helperText={error?.message}
			label={label}
			onChange={onChange}
			value={value}
			getDisabled={getDisabled}
		/>
	)
}
