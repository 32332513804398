import { useState } from 'react'
import { Box, Button, Dialog, Link, SROnly, Typography, useTheme } from '@persuit/ui-components'
import NamwolfLogo from '../../../components/namwolf/namwolf-logo'
import { KnownPerson, UnknownPerson } from './types'

type Person = KnownPerson | UnknownPerson

const isKnownPerson = (person: Person): person is KnownPerson => person.__type__ === 'known'

type ViewInfoButtonProps = {
	person: Person
	addUser?: () => Promise<void>
	removeUser?: () => Promise<void>
	isAdded?: boolean
	tabIndex?: number
	isInvitationListView?: boolean
	isDraftRfp: boolean
	isNamwolfMember?: boolean
}

export const ViewInfoButton = ({
	person,
	addUser,
	removeUser,
	isAdded,
	tabIndex,
	isDraftRfp,
	isInvitationListView = false,
	isNamwolfMember = false,
}: ViewInfoButtonProps) => {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const theme = useTheme()

	if (!isKnownPerson(person)) {
		return <SROnly>No contact information available for {person.email}</SROnly>
	}

	const title = (
		<Box display={'flex'} flexDirection={'column'}>
			{person.name}
			<Typography variant="body2">{person.email}</Typography>
		</Box>
	)

	const actions = (
		<>
			<Button
				onClick={() => {
					setIsDialogOpen(false)
				}}
			>
				Back
			</Button>
			{isAdded && removeUser && isDraftRfp && (
				<Button
					onClick={async () => {
						await removeUser()
						setIsDialogOpen(false)
					}}
					variant="primary"
				>
					Remove from request
				</Button>
			)}
			{!isAdded && addUser && isDraftRfp && (
				<Button
					onClick={async () => {
						await addUser()
						setIsDialogOpen(false)
					}}
					variant="primary"
				>
					Add to request
				</Button>
			)}
		</>
	)

	return (
		<>
			<Button
				size="small"
				variant={isInvitationListView ? 'text' : 'outlined'}
				onClick={() => setIsDialogOpen(true)}
				aria-label={`view contact information ${person.name}`}
				data-trackid="button-invites-all-contacts-view-info"
				{...(tabIndex !== undefined && { tabIndex })}
			>
				View Info
			</Button>
			<Dialog
				open={isDialogOpen}
				onClose={() => {
					setIsDialogOpen(false)
				}}
				size="sm"
				title={title}
				actions={actions}
			>
				<Box borderBottom={`1px solid ${theme.palette.primary.main}`}>
					<Typography variant="body2" color="text.secondary">
						Firm name
					</Typography>
				</Box>
				<Box borderBottom={`1px solid ${theme.palette.divider}`}>
					<Box mr={2} display="flex" alignItems="center">
						<Typography mr={2}>{person?.org?.name}</Typography>
						{(person?.org?.isNamwolfMember || isNamwolfMember) && <NamwolfLogo />}
					</Box>
				</Box>
				{person.title && (
					<>
						<Box borderBottom={`1px solid ${theme.palette.primary.main}`} mt={2}>
							<Typography variant="body2" color="text.secondary">
								Job title
							</Typography>
						</Box>
						<Box borderBottom={`1px solid ${theme.palette.divider}`}>
							<Typography variant="body1">{person.title}</Typography>
						</Box>
					</>
				)}
				{person.location && (
					<>
						<Box borderBottom={`1px solid ${theme.palette.primary.main}`} mt={2}>
							<Typography variant="body2" color="text.secondary">
								Location
							</Typography>
						</Box>
						<Box borderBottom={`1px solid ${theme.palette.divider}`}>
							<Typography variant="body1">{person.location}</Typography>
						</Box>
					</>
				)}
				{person?.professionalProfile?.length > 0 && (
					<>
						<Box mt={3}>
							<Typography fontWeight="medium">Professional Profile</Typography>
						</Box>
						{person?.professionalProfile.map((profile) => {
							return (
								<Box key={profile._id}>
									<Box borderBottom={`1px solid ${theme.palette.primary.main}`} mt={2}>
										<Typography variant="body2" color="text.secondary">
											Profile type
										</Typography>
									</Box>
									<Box borderBottom={`1px solid ${theme.palette.divider}`}>
										<Typography variant="body1">{profile.profile}</Typography>
									</Box>
									{profile.url && (
										<>
											<Box borderBottom={`1px solid ${theme.palette.primary.main}`} mt={1}>
												<Typography variant="body2" color="text.secondary">
													Profile URL
												</Typography>
											</Box>
											<Box borderBottom={`1px solid ${theme.palette.divider}`}>
												<Link href={profile.url} target="_blank">
													{profile.url}
												</Link>
											</Box>
										</>
									)}
								</Box>
							)
						})}
					</>
				)}
			</Dialog>
		</>
	)
}
