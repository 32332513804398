// @ts-strict-ignore
import { Fragment, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
	CloseIcon,
	FilterListIcon as FilterIcon,
	CheckBoxIcon as CheckedIcon,
	CheckBoxOutlineBlankIcon as CheckIcon,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	DialogDeprecated,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogClose,
	Button,
	Select,
	MenuItem,
	InputLabel,
	Box,
} from '@persuit/ui-components'

const LOOKBACK_N_YEARS = 5
const getYears = () => {
	const currentYear = new Date().getFullYear()
	const years: Array<number> = []
	for (let i = 0; i < LOOKBACK_N_YEARS; i++) {
		years.push(currentYear - i)
	}
	return years
}

const GET_ORGS = gql`
	query getOrgs($orgType: OrgType) {
		getOrgs(orgType: $orgType) {
			_id
			name
		}
	}
`

export const OrgFilter = ({ yearFilter, setYearFilter, initialSelection = [], handleConfirm }) => {
	const [selected, setSelected] = useState<Array<string>>(initialSelection)
	const [open, setOpen] = useState(false)
	const { data, loading } = useQuery(GET_ORGS, {
		variables: {
			orgType: 'CLIENT',
		},
	})

	if (loading) {
		return <div style={{ padding: '1.5rem' }}>Loading...</div>
	}

	const orgs = [...data.getOrgs]
	orgs.sort((a, b) => a.name.localeCompare(b.name))

	const toggleSelected = (orgId: string) => {
		if (selected.includes(orgId)) {
			const updatedSelection = selected.filter((selectedItem) => selectedItem !== orgId)
			return setSelected(updatedSelection)
		}

		const updatedSelection = [...selected, orgId]
		setSelected(updatedSelection)
	}

	return (
		<Fragment>
			<div
				style={{
					fontWeight: 600,
					fontSize: '0.8rem',
					// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
					color: '#777',
				}}
			>
				Sourcing Tagger
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
				<div style={{ marginRight: '1rem', fontSize: '1.2rem', fontWeight: 600 }}>
					RFPs for {initialSelection.length === 0 && 'all clients'}
					{initialSelection
						.map((selection) => orgs.find(({ _id: orgId }) => orgId === selection).name)
						.join(', ')}{' '}
					in {yearFilter}
				</div>
				<div style={{ marginRight: 'auto' }}>
					{initialSelection.length > 0 && (
						<Button
							onClick={() => {
								setSelected([])
								handleConfirm([])
							}}
							variant="outlined"
							size="small"
						>
							<CloseIcon style={{ width: '1rem', height: '1rem', marginRight: '0.25rem' }} />
							Clear filter
						</Button>
					)}
				</div>
				<Box mr={3}>
					<InputLabel id="year-filter">Year</InputLabel>
					<Select
						labelId="year-filter"
						id="year-filter-select"
						value={yearFilter}
						label="Year"
						onChange={(event) => setYearFilter(event.target.value)}
					>
						{getYears().map((year) => (
							<MenuItem key={year} value={year}>
								{year}
							</MenuItem>
						))}
					</Select>
				</Box>
				<div>
					<Button
						onClick={() => setOpen(true)}
						variant="contained"
						color="primary"
						style={{ marginRight: '0.5rem' }}
					>
						<FilterIcon style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }} />
						Filter by client
						{initialSelection.length > 0 && <span>&nbsp;({initialSelection.length})</span>}
					</Button>
				</div>
			</div>
			<DialogDeprecated open={open} onClose={() => setOpen(false)} size="lg">
				<DialogTitle>Filter by client</DialogTitle>
				<DialogClose onClick={() => setOpen(false)} />
				<DialogContent>
					<List>
						{orgs.map((org) => (
							<ListItemButton key={org._id} onClick={() => toggleSelected(org._id)}>
								<ListItemIcon>
									{selected.includes(org._id) ? <CheckedIcon /> : <CheckIcon />}
								</ListItemIcon>
								<ListItemText primary={org.name} />
							</ListItemButton>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setSelected(initialSelection)
							setOpen(false)
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={async () => {
							await handleConfirm(selected)
							setOpen(false)
						}}
						variant="contained"
						color="primary"
					>
						Set Filter
					</Button>
				</DialogActions>
			</DialogDeprecated>
		</Fragment>
	)
}
