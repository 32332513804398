import GlobalStyles from '@mui/material/GlobalStyles'

export const GlobalCSS = (
	<GlobalStyles
		styles={(theme) => {
			const { h1XSmall, h2XSmall, h3XSmall, h4, body1 } = theme.typography

			return {
				'*, *:before, *:after': {
					boxSizing: 'inherit',
				},
				html: {
					fontSize: body1.fontSize,
					lineHeight: body1.lineHeight,
					fontFamily: body1.fontFamily,
					letterSpacing: body1.letterSpacing,
					boxSizing: 'border-box',
					printColorAdjust: 'exact',
					WebkitPrintColorAdjust: 'exact',
				},
				body: {
					padding: 0,
					margin: 0,
					display: 'flex',
					boxSizing: 'border-box',
					minHeight: '100vh',
					backgroundColor: theme.palette.grey100,
					fontFamily: 'Roboto, sans-serif',
				},
				a: {
					textDecoration: 'none',
				},
				p: {
					margin: '0px 0px 6px 0px',
				},
				ul: {
					padding: '0px 0px 0px 24px',
					margin: '0.5em 0 0.5em 0',
				},
				ol: {
					padding: '0px 0px 0px 24px',
					margin: '0.5em 0 0.5em 0',
				},
				'#root': {
					width: '100%',
				},

				h1: {
					'-moz-osx-font-smoothing': 'grayscale',
					'-webkit-font-smoothing': 'antialiased',
					fontSize: h1XSmall.fontSize,
					fontWeight: h1XSmall.fontWeight,
					lineHeight: h1XSmall.lineHeight,
					letterSpacing: h1XSmall.letterSpacing,
				},
				h2: {
					'-moz-osx-font-smoothing': 'grayscale',
					'-webkit-font-smoothing': 'antialiased',
					fontSize: h2XSmall.fontSize,
					fontWeight: h2XSmall.fontWeight,
					lineHeight: h2XSmall.lineHeight,
					letterSpacing: h2XSmall.letterSpacing,
				},
				h3: {
					'-moz-osx-font-smoothing': 'grayscale',
					'-webkit-font-smoothing': 'antialiased',
					fontSize: h3XSmall.fontSize,
					fontWeight: h3XSmall.fontWeight,
					lineHeight: h3XSmall.lineHeight,
					letterSpacing: h3XSmall.letterSpacing,
				},
				h4: {
					'-moz-osx-font-smoothing': 'grayscale',
					'-webkit-font-smoothing': 'antialiased',
					fontSize: h4.fontSize,
					fontWeight: h4.fontWeight,
					lineHeight: h4.lineHeight,
					letterSpacing: h4.letterSpacing,
				},

				// Accessibility styles
				'&:focus-visible': {
					outline: `2px solid ${theme.palette.primary.main}`,
				},
				'&.MuiLink-root.MuiLink-underlineHover:focus-visible': {
					textDecoration: `underline`,
				},
				'input::-webkit-search-cancel-button': {
					'-webkit-appearance': 'none',
					content: '"URL()"',
					cursor: 'pointer',
				},

				// Text Field
				'& .MuiOutlinedInput-root': {
					'&:hover': {
						backgroundColor: theme.palette.primary.lighterHue,
					},
					'&:hover fieldset': {
						borderColor: theme.palette.primary.main,
					},
					'& input::placeholder': {
						WebkitTextFillColor: theme.palette.neutral.main,
					},
					'&.Mui-disabled': {
						backgroundColor: theme.palette.action.disabledBackground,
						borderColor: theme.palette.action.focus,
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: theme.palette.text.primary,
						},
						'& > fieldset': {
							borderColor: 'inherit',
							opacity: 1,
						},
						'.MuiSvgIcon-root': {
							fill: theme.palette.background.greyLabel,
						},
						'& input::placeholder': {
							opacity: 1,
						},
					},
					'&.Mui-error:not(.Mui-disabled)': {
						'&:hover': {
							backgroundColor: theme.palette.error.lightestHue,
						},
						'&:hover fieldset': {
							borderColor: theme.palette.error.main,
						},
					},
				},
				'& .MuiInputLabel-root': {
					'&.Mui-disabled': {
						zIndex: 1,
					},
				},
				'& .MuiInput-root': {
					'&.Mui-disabled': {
						color: theme.palette.neutral.darkHue,
						backgroundColor: theme.palette.neutral.lightestHue,

						'& .MuiInput-input': {
							color: theme.palette.neutral.darkHue,
							WebkitTextFillColor: theme.palette.neutral.darkHue,
						},
					},
				},
				'& .MuiFormHelperText-root': {
					marginLeft: '0px',
					'&.Mui-disabled': {
						color: theme.palette.background.greyLabel,
					},
				},
				'& .MuiFormLabel-root': {
					marginLeft: '0px',
					'&.Mui-disabled': {
						color: theme.palette.background.greyLabel,
					},
				},

				//Button
				'&.MuiButton-root': {
					'&.MuiLoadingButton-root': {
						boxShadow: 'none',
						'&:hover': {
							boxShadow: 'none',
						},
						'&:focus': {
							outline: `4px solid ${theme.palette.primary.lightHue}`,
							outlineOffset: '2px',
						},
						'&.MuiButton-primary, &.MuiButton-contained': {
							'&:hover': {
								backgroundColor: theme.palette.primary.darkHue,
							},
							'&.MuiLoadingButton-loading': {
								backgroundColor: theme.palette.action.focus,
								'&.Button-icon': {
									color: theme.palette.background.greyLabel,
								},
								'& .MuiLoadingButton-loadingIndicator': {
									color: theme.palette.background.greyLabel,
								},
							},
							'&.Mui-disabled:not(.MuiLoadingButton-loading)': {
								color: theme.palette.background.greyLabel,
								backgroundColor: theme.palette.action.focus,
							},
							'&.Button-destructive:not(.Mui-disabled)': {
								backgroundColor: theme.palette.error.main,
								'&:hover': {
									backgroundColor: theme.palette.error.darkHue,
								},
							},
						},
						'&.MuiButton-secondary, &.MuiButton-outlined': {
							backgroundColor: theme.palette.primary.contrastText,
							'&:hover': {
								color: theme.palette.primary.darkHue,
								borderColor: theme.palette.primary.darkHue,
							},
							'&.MuiLoadingButton-loading': {
								borderColor: theme.palette.text.disabled,
								backgroundColor: theme.palette.action.focus,
								'&.Button-icon': {
									color: theme.palette.background.greyLabel,
								},
								'& .MuiLoadingButton-loadingIndicator': {
									color: theme.palette.background.greyLabel,
								},
							},
							'&.Mui-disabled:not(.MuiLoadingButton-loading)': {
								color: theme.palette.background.greyLabel,
								borderColor: theme.palette.text.disabled,
								backgroundColor: theme.palette.action.focus,
							},

							'&.Button-destructive:not(.Mui-disabled)': {
								borderColor: theme.palette.error.main,
								color: theme.palette.error.main,
								'&:hover': {
									borderColor: theme.palette.error.darkHue,
									color: theme.palette.error.darkHue,
									backgroundColor: theme.palette.background.contentMain,
								},
							},
						},
						'&.MuiButton-text': {
							'&:hover': {
								color: theme.palette.primary.darkHue,
								backgroundColor: 'transparent',
							},
							'&.MuiLoadingButton-loading': {
								'&.Button-icon': {
									color: theme.palette.text.disabled,
								},
								'& .MuiLoadingButton-loadingIndicator': {
									color: theme.palette.text.disabled,
								},
							},
							'&.Button-destructive:not(.Mui-disabled)': {
								color: theme.palette.error.main,
								'&:hover': {
									color: theme.palette.error.darkHue,
								},
							},
							'&.Mui-disabled:not(.MuiLoadingButton-loading)': {
								color: theme.palette.text.disabled,
							},
							'&.MuiLoadingButton-loadingIndicator': {
								color: theme.palette.text.disabled,
							},
						},
					},
					'&.MuiButton-sizeLarge': {
						lineHeight: '1.625rem',
						letterSpacing: '0.46px',
					},
					'&.MuiButton-sizeSmall': {
						lineHeight: '1.375rem',
						letterSpacing: '0.46px',
					},
				},
			}
		}}
	/>
)
