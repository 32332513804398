import { omit } from 'lodash/fp'
import { Deliverable } from '../types'

export function cleanupDuplicatedDeliverable(deliverable: Deliverable): Deliverable {
	const cleanedDeliverable = omit(
		['_id', 'uuid', 'originalAllowsFirmsToAddEntries', 'originalDeliverableId'],
		deliverable,
	)
	// remove original values from rates
	cleanedDeliverable.rates = cleanedDeliverable.rates.map((rate) =>
		omit(['_id', 'originalRateId'], rate),
	)

	return cleanedDeliverable
}
