import { useSectionalForm, SectionalFormContainer } from '../../sectional-form'
import {
	Box,
	Form,
	FormRichTextEditor,
	FormTextField,
	FormFileUploadList,
	FileScanState,
	Spacer,
	PopoverHelp,
	Typography,
} from '@persuit/ui-components'

export type File = {
	_id: string
	filename?: string | null
	fileStatus?: FileScanState | null
	length?: number | null
}

export type SummaryFormValues = { summary: string; requestedBy: string; files: File[] }

export type SummaryFormProps = {
	refetchFiles: () => Promise<File[]>
	saveSection: (input: SummaryFormValues) => Promise<void>
	sectionData: SummaryFormValues
	sectionErrors: null | {
		files?: string
	}
	isSystemTemplate?: boolean
	isTemplate?: boolean
}

export const SummaryForm = ({
	saveSection,
	sectionData,
	sectionErrors,
	refetchFiles,
	isSystemTemplate = false,
	isTemplate = false,
}: SummaryFormProps) => {
	const methods = useSectionalForm(
		sectionData,
		(values) =>
			saveSection({
				summary: values.summary,
				requestedBy: values.requestedBy,
				files: values.files,
			}),
		sectionErrors,
	)

	return (
		<SectionalFormContainer label="Summary section" focusOnMount={true}>
			<Form methods={methods} aria-label="Summary Section">
				<Box display="flex" flexDirection="column">
					<Typography variant="h1XSmall">Summary</Typography>

					<Spacer space={1} />
					<FormRichTextEditor
						id="summary-input"
						label="Summary"
						hideLabel={true}
						name="summary"
						height={380}
					/>
					<Spacer space={2} />
					{!isSystemTemplate && (
						<FormFileUploadList refetchFiles={refetchFiles} name="files" multiple={true} />
					)}
					<Spacer space={2} />
					<Box display="flex" alignItems="baseline">
						<FormTextField
							id="requested-by-input"
							label="Requested By"
							name="requestedBy"
							required={!isTemplate}
							fullWidth={true}
						/>
						<PopoverHelp
							triggerButtonProps={{ 'aria-label': 'Requested By help' }}
							content="Name and job title of the person within your company responsible for this Request."
						/>
					</Box>
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
