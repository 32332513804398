import isEmpty from 'lodash/fp/isEmpty'
import rfpProposalStates from '../../../../common/data/rfp-proposal-states'
import { useTheme, Box } from '@persuit/ui-components'
import { GroupContainer, GroupContainerProps } from './group-container'
import { DeliverableContainer, DeliverableContainerProps } from './deliverable-container'

export type ProposalDeliverablesProps = {
	status: string
	deliverables: Array<DeliverableContainerProps['deliverable'] | GroupContainerProps['group']>
} & Pick<
	DeliverableContainerProps,
	'responseToDeliverables' | 'currency' | 'phaseDuration' | 'rfpr'
> &
	Pick<GroupContainerProps, 'responseToDeliverables' | 'currency' | 'phaseDuration' | 'rfpr'>

export const ProposalDeliverables = ({
	deliverables,
	responseToDeliverables,
	currency,
	phaseDuration,
	rfpr,
	status,
}: ProposalDeliverablesProps) => {
	const theme = useTheme()
	if (isEmpty(deliverables)) return null

	const isProposalRevised = status === rfpProposalStates.REVISED

	const { deliverableFragments } = deliverables.reduce<{
		deliverableFragments: Array<JSX.Element>
		deliverableIndex: number
	}>(
		(acc, deliverable) => {
			if (deliverable.__typename === 'Deliverable') {
				acc.deliverableFragments.push(
					<DeliverableContainer
						key={`D${acc.deliverableIndex}`}
						deliverable={deliverable}
						responseToDeliverables={responseToDeliverables}
						currency={currency}
						phaseDuration={phaseDuration}
						rfpr={rfpr}
						isProposalRevised={isProposalRevised}
						floating={true}
						index={acc.deliverableIndex}
					/>,
				)
				acc.deliverableIndex += 1
			} else if (deliverable.__typename === 'PricingGroup') {
				acc.deliverableFragments.push(
					<GroupContainer
						key={`G${acc.deliverableIndex}`}
						group={deliverable}
						responseToDeliverables={responseToDeliverables}
						currency={currency}
						phaseDuration={phaseDuration}
						rfpr={rfpr}
						isProposalRevised={isProposalRevised}
						index={acc.deliverableIndex}
					/>,
				)
				acc.deliverableIndex += deliverable.deliverables.length
			}
			return acc
		},
		{
			deliverableFragments: [],
			deliverableIndex: 0,
		},
	)
	return (
		<Box
			aria-label="Deliverables"
			role="list"
			display="flex"
			flexDirection="column"
			gap={theme.spacing(2)}
			paddingTop={2}
		>
			{deliverableFragments}
		</Box>
	)
}
