import { graphql, useQuery, useSubscription } from '@persuit/ui-graphql'
import {
	Box,
	ChevronLeftIcon,
	Divider,
	IconButton,
	PeopleAltIcon,
	Typography,
	LinkSupport,
} from '@persuit/ui-components'
import { MessageForm } from './message-form'
import { MessageList } from './message-list'
import { getChannelName } from '../../util'
import { ChannelAvatar } from './channel-avatar'

export type ChannelPaneProps = {
	channelId: string
	openParticipantsPane: () => void
	backToChannelListPane: () => void
	showSystemMessages: boolean
}

const GET_SESSION_DATA = graphql(`
	query MessagingChannelGetSessionInfo {
		getSessionInfo {
			isImpersonated
			user {
				_id
				org {
					_id
				}
			}
			...MessagesChannelPaneMessageListSession
		}
	}
`)

export const GET_CHANNEL_DATA = graphql(`
	query MessagingChannelGetChannelData($channelId: ID!) {
		channel(channelId: $channelId) {
			id
			type
			orgs {
				firm {
					id
					name
				}
				client {
					id
					name
				}
			}
			# This is not *required* by the component, but is needed
			# For the optimistic cache updating of the query to work
			# Technically this is in the fragment, but the types get weird
			# It it is not in the actual query
			messages {
				id
				isBot
				messageAt
				createdBy {
					userId
				}
				createdAt
				message
			}
			...MessagesChannelPaneMessageListChannel
		}
	}
`)

const GET_CHANNEL_DATA_SUB = graphql(`
	subscription MessagingChannelGetChannelUpdates($channelId: ID!) {
		channel(channelId: $channelId) {
			id
			...MessagesChannelPaneMessageListChannel
		}
	}
`)

export const ChannelPane = ({
	channelId,
	openParticipantsPane,
	backToChannelListPane,
	showSystemMessages,
}: ChannelPaneProps) => {
	const { data: sessionData, error: sessionError } = useQuery(GET_SESSION_DATA)
	const { data: channelData, error: channelError } = useQuery(GET_CHANNEL_DATA, {
		variables: { channelId },
	})
	useSubscription(GET_CHANNEL_DATA_SUB, { variables: { channelId } })

	if (sessionError || channelError)
		return (
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				gap={2}
				p={4}
				justifyContent="center"
				alignItems="center"
				width="100%"
				textAlign="center"
			>
				<Typography variant="body1Semibold">Messaging is temporarily unavailable.</Typography>
				<Typography variant="caption">
					If this problem persists, please contact our support team at&nbsp;
					<LinkSupport />.
				</Typography>
			</Box>
		)

	if (!sessionData || !channelData) return null

	const {
		getSessionInfo: { isImpersonated, user },
	} = sessionData
	const currentOrgId = user?.org?._id ?? ''
	const currentUserId = user?._id ?? ''
	const {
		channel: { type: channelType, orgs: channelOrgs },
	} = channelData

	const channelName = getChannelName(channelType, channelOrgs, currentOrgId, 'Channel messages')
	const channelTypeName =
		channelData.channel.type === 'CLIENT_TO_FIRM_CHANNEL' ? 'EXTERNAL CHANNEL' : 'INTERNAL CHANNEL'

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box display="flex" justifyContent="space-between" p={2} alignItems="center" gap={2}>
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<Box>
						<IconButton onClick={backToChannelListPane} title="Back to channel list">
							<ChevronLeftIcon color="primary" />
						</IconButton>
					</Box>
					<ChannelAvatar channelName={channelName} channelType={channelType} />
					<Box display="flex" flexDirection="column">
						<Typography variant="caption">{channelTypeName}</Typography>
						<Typography variant="body1Semibold">{channelName}</Typography>
					</Box>
				</Box>
				<Box>
					<IconButton onClick={openParticipantsPane} title="Open participants list">
						<PeopleAltIcon color="primary" />
					</IconButton>
				</Box>
			</Box>
			<Divider />
			{/* It's okay because this box contain scrollable content */}
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
			<Box flexGrow={1} overflow="auto" tabIndex={0}>
				<MessageList
					channel={channelData.channel}
					session={sessionData.getSessionInfo}
					showSystemMessages={showSystemMessages}
				/>
			</Box>
			<Divider />
			<MessageForm channelId={channelId} isImpersonated={isImpersonated} userId={currentUserId} />
		</Box>
	)
}
