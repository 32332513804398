import { formatPriceWithCurrency } from '@persuit/common-utils'
import isNumber from 'lodash/fp/isNumber'

export const formatPrice = (
	currency: string | null | undefined,
	val: number,
	format = '0,0.00',
): string =>
	currency
		? formatPriceWithCurrency(currency, val, { format, showCurrencyCode: 'all' })
		: val.toLocaleString(undefined, { minimumFractionDigits: 2 })

const isNum = (num: unknown): num is number => typeof num === 'number'

type TimekeeperData = {
	lyHour?: number | null
	currentRate?: number | null
	standardRate?: number | null
	proposedRate?: number | null
}

export function timekeeperCalculations({
	proposedRate,
	standardRate,
	currentRate,
	lyHour,
}: TimekeeperData) {
	const discountFromStandard =
		isNum(proposedRate) && isNum(standardRate) && standardRate > 0
			? Math.max(0, (standardRate - proposedRate) / standardRate)
			: null
	const changeFromLastYear =
		isNum(proposedRate) && isNum(currentRate) && currentRate > 0
			? proposedRate / currentRate - 1
			: null
	const lastYearsSpend = isNum(lyHour) && isNum(currentRate) ? lyHour * currentRate : null
	const spendImpact =
		isNum(changeFromLastYear) && isNum(lastYearsSpend) ? changeFromLastYear * lastYearsSpend : null

	return {
		discountFromStandard,
		changeFromLastYear,
		lastYearsSpend,
		spendImpact,
	}
}

export const getPercentageDifference = (
	oldValue?: number | null,
	newValue?: number | null,
): number | null => {
	if (!isNumber(oldValue) || !isNumber(newValue)) return null
	if (oldValue === 0) return null

	return (newValue - oldValue) / oldValue
}

export const getPercentageDiscount = (
	oldValue?: number | null,
	newValue?: number | null,
): number | null => {
	const percentageDifference = getPercentageDifference(oldValue, newValue)

	return percentageDifference !== null ? Math.max(0, percentageDifference * -1) : null
}

export function renderPrice(
	val: number | null | undefined,
	currency?: string | null,
	noDecimals?: boolean,
) {
	if (typeof val !== 'number') return '-'
	return formatPrice(currency, noDecimals ? Math.round(val) : val, noDecimals ? '0,0' : undefined)
}

export function renderPercentage(val: number | null | undefined, alwaysShowSign = false): string {
	if (typeof val !== 'number') return '-'

	return `${alwaysShowSign && val > 0 ? '+' : ''}${(val * 100).toFixed(2)}%`
}
