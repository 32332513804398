// @ts-strict-ignore
const trackingFieldTypes = {
	HIERARCHY: 'HIERARCHY', // Multiselect hierarchy
	PLAIN_TEXT: 'PLAIN_TEXT',
	NUMERIC: 'NUMERIC',
	RICH_TEXT: 'RICH_TEXT',
	DATE: 'DATE',
} as const
const values = Object.keys(trackingFieldTypes).map((key) => trackingFieldTypes[key])

export { values, trackingFieldTypes as default }
