import { format } from 'date-fns'
import { Box, FileList, Typography, useTheme, Chip } from '@persuit/ui-components'
import { RfpClarification } from '../../../../types'
import { CancelAuctionClarification } from '../cancel-auction-clarification'
import { AuctionDatesChangedClarification } from '../auction-dates-changed-clarification'
import { DueDateChangedNonAuctionClarification } from '../due-date-changed-non-auction-clarification'
import { AuctionAddedToRfpClarification } from '../auction-added-to-rfp'

type SystemClarificationItemProps = {
	clarification: RfpClarification
	clarificationNumber: number | null
}

export const SystemClarificationItem = ({
	clarification,
	clarificationNumber,
}: SystemClarificationItemProps) => {
	const theme = useTheme()
	const { createdAt, files, systemClarification } = clarification
	const action = systemClarification?.action

	return (
		<Box
			display="flex"
			flexDirection="column"
			p={1}
			border={`1px solid`}
			borderColor="form.borderResting"
			gap={1}
		>
			<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
				<Box display="flex" alignItems="center" gap={1}>
					<Typography variant="h3XSmall">CLARIFICATION #{clarificationNumber}</Typography>
					<Chip label="System" size="small" />
				</Box>
				<Typography variant="body2">Added: {format(createdAt, 'd LLLL yyyy, hh:mma')}</Typography>
			</Box>

			<Box width="100%" borderBottom={`1px solid ${theme.palette.grey300}`} />

			{action === 'AUCTION_DATES_CHANGED' ? (
				<AuctionDatesChangedClarification systemClarification={systemClarification} />
			) : action === 'DUE_DATE_CHANGED_NON_AUCTION' ? (
				<DueDateChangedNonAuctionClarification systemClarification={systemClarification} />
			) : action === 'REOPEN_RFP_CHANGE_DUE_DATE' ? (
				<DueDateChangedNonAuctionClarification systemClarification={systemClarification} />
			) : action === 'RFP_CANCEL_AUCTION' ? (
				<CancelAuctionClarification systemClarification={systemClarification} />
			) : action === 'CONVERT_RFP_TO_AUCTION' ? (
				<AuctionAddedToRfpClarification systemClarification={systemClarification} />
			) : null}

			{files && files.length !== 0 ? (
				<FileList
					files={files}
					label={`Downloadable files for CLARIFICATION #${clarificationNumber}`}
				/>
			) : null}
		</Box>
	)
}
