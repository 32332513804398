import { Helmet } from 'react-helmet'

export const Head = Helmet

export type PageTitleProps = {
	children: string
}

export const PageTitle = ({ children }: PageTitleProps) => {
	return (
		<Head>
			<title>{children}</title>
		</Head>
	)
}
