// @ts-strict-ignore
export const generatePanelApprovedFirmSets = (
	panelFirmTags: Array<{ firmId: string; type: string }>,
) => {
	const panelOrPreferredFirms = new Set<string>()
	const approvedFirms = new Set<string>()

	panelFirmTags.forEach(({ firmId, type }) => {
		if (firmId && type === 'approved') {
			approvedFirms.add(firmId)
		} else if (firmId && (type === 'panel' || type === 'preferred')) {
			panelOrPreferredFirms.add(firmId)
		}
	})

	return { panelOrPreferredFirms, approvedFirms }
}

export const filterGroupPanels = (selectedGroup, panelFirmTags) => {
	if (!selectedGroup || selectedGroup === 'all') {
		return panelFirmTags
	}
	return panelFirmTags.filter((p) => p.groupId === selectedGroup)
}
