// @ts-strict-ignore
import { formatToTimeZone } from 'date-fns-timezone'
import { format } from 'date-fns'
import { Box, Typography } from '@persuit/ui-components'

type FormattedDateProps = {
	date: number
}

export const getLocalTimezoneAbbreviation = (date, fallbackText = '') => {
	const isIntlSupported = typeof Intl !== 'undefined'
	if (!isIntlSupported) {
		return fallbackText
	}

	return formatToTimeZone(date, '(z)', {
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	})
}

export default getLocalTimezoneAbbreviation

export const FormattedDate = ({ date }: FormattedDateProps) => {
	return (
		<Box>
			<Typography variant="body1Semibold">{format(date, 'd MMMM yyyy')}</Typography>
			<Typography variant="body1Semibold">
				{format(date, 'hh:mm a')} {getLocalTimezoneAbbreviation(date)}
			</Typography>
			<Typography></Typography>
		</Box>
	)
}
