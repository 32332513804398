import { useId } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar, Button } from '@persuit/ui-components'

const SUBMIT_PROPOSAL_FEEDBACK = gql`
	mutation submitProposalFeedback($clientProposalFeedbackInput: ClientProposalFeedbackInput!) {
		submitProposalFeedback(clientProposalFeedbackInput: $clientProposalFeedbackInput)
	}
`

const mutationSchemaFormat = ({ proposalId, feedback }) => {
	return {
		proposalId,
		comment: feedback.comment,
		ratings: {
			strategy: feedback.strategy,
			pricing: feedback.pricing,
			expertise: feedback.expertise,
			diversity: feedback.diversity,
		},
	}
}

const transformDataForMutation = ({ formState, rfpId }) => {
	//loop through feedback and put in mutation schema format
	const proposalFeedback = Object.keys(formState).map((proposalId) => {
		return mutationSchemaFormat({ rfpId, proposalId, feedback: formState[proposalId] })
	})

	const mutationData = {
		rfpId,
		proposalFeedback: [...proposalFeedback],
	}

	return mutationData
}

const ConfirmButton = ({ handleClose, formState, rfpId, isSubmittable = true, fetchResponses }) => {
	const [saveProposalFeedback, { loading }] = useMutation(SUBMIT_PROPOSAL_FEEDBACK)
	const { openSnackbar } = useSnackbar()

	const saveFeedback = async ({ rfpId, formState }) => {
		const clientProposalFeedbackInput = transformDataForMutation({ formState, rfpId })

		await saveProposalFeedback({ variables: { clientProposalFeedbackInput } })
		handleClose()
		openSnackbar('Feedback sent')
		fetchResponses()
	}

	const descriptionId = useId()

	return (
		<>
			<span style={{ display: 'none' }} id={descriptionId}>
				Add feedback before sending feedback
			</span>
			<Button
				aria-describedby={!isSubmittable ? descriptionId : undefined}
				loading={loading}
				disabled={!isSubmittable}
				color="primary"
				variant="contained"
				onClick={(e) => {
					e.stopPropagation()
					saveFeedback({ rfpId, formState })
				}}
			>
				Send Feedback
			</Button>
		</>
	)
}

export default ConfirmButton
