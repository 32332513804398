export const SORT_LABELS = {
	firmName: {
		asc: 'Sort in ascending alphabetical order (A to Z)',
		desc: 'Sort in descending alphabetical order (Z to A)',
	},
	weightedAverage: {
		asc: 'Sort from lowest to highest',
		desc: 'Sort from highest to lowest',
	},
	timekeepers: {
		rate: {
			asc: 'Sort by proposed rate from lowest to highest',
			desc: 'Sort by proposed rate from highest to lowest',
		},
		percentage: {
			asc: 'Sort by percentage change from lowest to highest',
			desc: 'Sort by percentage change from highest to lowest',
		},
	},
}
