import { publishRfpProgress } from '../../singleton'
import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'

export type ProgressCardCompleteRequestProps = ProgressCardProps

export const CARD_TITLE = 'Complete request'

const HELP_TEXT = `Requests can be marked as completed any time. Just click the "Complete request" button. Requests can be completed even if you decide not to select a winner.`

export const ProgressCardCompleteRequest = ({ index, step }: ProgressCardCompleteRequestProps) => {
	const onActionCompleteRequest = () => publishRfpProgress('DETAIL:LAUNCH_COMPLETE_REQUEST')

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				helpText={HELP_TEXT}
				index={index}
				status={step.status}
				guidanceText={`Are you done? Mark this request as completed to give closure to all participating firms.`}
				action={{
					title: 'COMPLETE REQUEST',
					onAction: onActionCompleteRequest,
					dataTrackId: 'rfp-progress-complete-request',
				}}
			/>
		)

	return <BaseCard title={CARD_TITLE} helpText={HELP_TEXT} index={index} status={step.status} />
}
