// @ts-strict-ignore
import { Fragment } from 'react'
import { size, sortBy, reverse } from 'lodash/fp'

import {
	SubdirectoryArrowRightIcon as ChildIcon,
	ExpandMoreIcon as CollapseIcon,
	Collapse,
	ChevronRightIcon as ExpandIcon,
	CheckBoxIcon as CheckedIcon,
	CheckBoxOutlineBlankIcon as CheckIcon,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@persuit/ui-components'

const isExpanded = (expanded, item) => Boolean(expanded.find(({ code }) => code === item.code))
const isSelected = (selected, item) => Boolean(selected.find(({ code }) => code === item.code))
const hasChildren = (item) => size(item.children) > 0
const getWidthForIndent = (item, indent) => (hasChildren(item) ? indent * 9 : indent * 9 + 40)

export const TaxonomyItem = ({
	indent = 1,
	item,
	expanded,
	selected,
	toggleExpand,
	toggleSelected,
	allowParentSelection = false,
}) => {
	const isSelectable = allowParentSelection || !hasChildren(item)

	return (
		<ListItem
			key={item.code}
			secondaryAction={
				isSelectable ? (
					<IconButton
						edge="end"
						aria-label="delete"
						onClick={() => toggleSelected(item)}
						size="large"
					>
						{isSelected(selected, item) ? <CheckedIcon /> : <CheckIcon />}
					</IconButton>
				) : null
			}
		>
			<ListItemButton
				sx={{ pl: `${getWidthForIndent(item, indent)}px` }}
				onClick={() => (hasChildren(item) ? toggleExpand(item) : toggleSelected(item))}
			>
				{hasChildren(item) && (
					<ListItemIcon sx={{ minWidth: '40px' }}>
						{isExpanded(expanded, item) ? <CollapseIcon /> : <ExpandIcon />}
					</ListItemIcon>
				)}
				<ListItemText
					primary={
						<Fragment>
							{size(item.parentChain) > 0 && (
								<div
									style={{
										fontSize: '0.875rem',
										// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
										color: 'rgba(0, 0, 0, 0.35)',
										fontWeight: 500,
									}}
								>
									<ChildIcon
										style={{
											height: '0.875rem',
											width: '0.875rem',
										}}
									/>{' '}
									{reverse(item.parentChain).join(' ➜ ')}
								</div>
							)}
							<div>{item.name}</div>
						</Fragment>
					}
					secondary={item.description}
				/>
				{isSelectable && <ListItemSecondaryAction></ListItemSecondaryAction>}
			</ListItemButton>
		</ListItem>
	)
}

export const constructChildren = (
	toggleExpand,
	expanded,
	toggleSelected,
	selected,
	item,
	allowParentSelection,
	indent = 2,
) => {
	if (size(item.children) === 0) {
		return <Fragment />
	}

	const sortedChildren = sortBy(['name'], item.children)

	return (
		<Collapse in={isExpanded(expanded, item)}>
			{isExpanded(expanded, item) && (
				<List disablePadding={true}>
					{sortedChildren.map((child) => (
						<Fragment key={child.code}>
							<TaxonomyItem
								indent={indent}
								item={child}
								selected={selected}
								expanded={expanded}
								toggleSelected={toggleSelected}
								toggleExpand={toggleExpand}
								allowParentSelection={allowParentSelection}
							/>
							{isExpanded(expanded, child) &&
								constructChildren(
									toggleExpand,
									expanded,
									toggleSelected,
									selected,
									child,
									allowParentSelection,
									indent + 1,
								)}
						</Fragment>
					))}
				</List>
			)}
		</Collapse>
	)
}
