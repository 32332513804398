import classnames from 'classnames'
import injectSheet from '../injectSheet'
import persuitTheme from '../theme/persuit-theme'
import { grey400 } from '../theme/persuit-colors'
import Box from './box.jsx'
import Spacer from './layout/spacer.jsx'
import { Row } from './grid'

const Card = ({
	// Extract sheet form ...props to avoid warnings
	sheet,
	children,
	className,
	component: Component,
	showHoverStyle,
	classes,
	titleBar,
	footerBar,
	content: contentProp,
	leftBorder,
	leftBorderColor,
	...props
}) => {
	// Main content is either children or a passed in content prop
	const content = children || contentProp

	return (
		<Component
			className={classnames(
				classes.component,
				{
					[classes.hover]: showHoverStyle,
					[classes.leftBorder]: leftBorder && leftBorderColor,
				},
				classes.card,
				className,
			)}
			style={{
				borderLeftColor: leftBorderColor,
			}}
			{...props}
		>
			<Box className={classes.box}>
				<Spacer direction="column" space={2} />
				<Box className={classes.content}>
					{titleBar}

					<Spacer direction="column" />
					{content}
					<Spacer direction="column" />
				</Box>
				<Spacer key="spacer" direction="column" />
				{footerBar && [
					<Row key="row" className={classes.footer}>
						<Box className={classes.footerBox}>{footerBar}</Box>
					</Row>,
				]}
			</Box>
		</Component>
	)
}

const styles = {
	card: {
		display: 'block',
		boxSizing: 'border-box',
	},
	leftBorder: {
		borderLeftWidth: '4px',
		borderLeftStyle: 'solid',
		borderTopLeftRadius: '4px',
		borderBottomLeftRadius: '4px',
	},
	component: {
		backgroundColor: 'white',
		color: 'black',
		boxShadow: persuitTheme.shadow,
		width: '100%',
		transform: 'translateY(0)',
		transition: 'all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95)',
		// Required for equal heights in safari
		flexDirection: 'column',
		flexBasis: '100%',
		flexGrow: 1,
		flexShrink: 0,
	},

	hover: {
		'&:hover': {
			transform: 'translateY(-3px)',
			boxShadow: persuitTheme.shadowHover,
			transition: 'all 0.2s cubic-bezier(0.23, 1, 0.32, 1)',
		},
		'&:active': {
			transform: 'translateY(-3px)',
			boxShadow: persuitTheme.shadowHover,
			transition: 'all 0.2s cubic-bezier(0.23, 1, 0.32, 1)',
		},
	},

	box: {
		// Required for equal heights in safari.
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		flexShrink: 0,
		height: '100%',
	},
	content: {
		maxWidth: '1000px',
	},
	footer: {
		marginTop: 'auto',
		alignItems: 'center',
		borderTop: `1px solid ${grey400}`,
	},
	footerBox: {
		width: '100%',
	},
}

Card.defaultProps = {
	component: 'div',
	leftBorder: false,
	showHoverStyle: true,
}

export default injectSheet(styles)(Card)
