import { createRHFUtils } from '@persuit/ui-components'
import {
	Timekeeper as GeneratedTimeKeeper,
	FieldRequirementValue,
} from '@persuit/ui-graphql/generated'

export type Timekeeper = Pick<GeneratedTimeKeeper, 'timekeeper' | 'timekeeperLabel'> & {
	originalTimekeeperId?: string | null
}

export type RatesFormValues = {
	details: string
	currency: string
	currencies: string[]
	timekeepers: Timekeeper[]
	requestNamedTimekeepers: boolean
	namedTimekeepersSettings: null | {
		requestBillingId: FieldRequirementValue
		billingIdInstructions: string
		requestGraduationYear: FieldRequirementValue
		graduationYearInstructions: string
	}
}

export const { useFieldArray, useFormContext, useWatch, fieldName, useForm } =
	createRHFUtils<RatesFormValues>()

export const useTimekeeperFieldArray = () =>
	useFieldArray({
		name: 'timekeepers',
	})
