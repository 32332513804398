import LoadingSpinner from '../components/loading-spinner.jsx'
import RfpProposalDetail from '../components/rfp-proposals/rfp-proposal-detail/rfp-proposal-detail'

const RfpProposalContainer = ({ rfp, proposal, fetchResponses }) => {
	if (!proposal) return <LoadingSpinner />

	return <RfpProposalDetail proposal={proposal} rfp={rfp} fetchResponses={fetchResponses} />
}

export default RfpProposalContainer
