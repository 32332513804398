// @ts-strict-ignore
import { Typography, Box, IconButton, useSnackbar, LinkIcon } from '@persuit/ui-components'
import { isEmpty } from 'lodash/fp'
import { ga } from '@persuit/ui-analytics'

const copyHumanFriendlyUrl = (humanFriendlyId = '', googleAnalyticsAction = '') => {
	try {
		if (!isEmpty(googleAnalyticsAction)) {
			ga({ page: 'HAPPYID', event: googleAnalyticsAction, label: 'FEATURE_USAGE' })
		}
		const url = new URL(window.location.href)
		const urlToCopy = `${url.origin}/en/id/${humanFriendlyId}`
		navigator.clipboard.writeText(urlToCopy)
	} catch (error) {
		console.error('Could not copy PERSUIT ID')
	}
}

export type HappyIdProps = {
	humanFriendlyId: string
	hideLink?: boolean
	googleAnalyticsAction?: string
}

export const HappyId = ({
	humanFriendlyId,
	hideLink = false,
	googleAnalyticsAction = '',
}: HappyIdProps) => {
	const { openSnackbar } = useSnackbar()

	const textId = (
		<Typography variant="body1Semibold" data-testid="happy-id">
			{humanFriendlyId}
		</Typography>
	)

	if (hideLink === true) {
		return textId
	}

	const handleClick = (e) => {
		e.stopPropagation()
		e.preventDefault()
		copyHumanFriendlyUrl(humanFriendlyId, googleAnalyticsAction)
		openSnackbar('PERSUIT ID link copied to clipboard')
	}

	return (
		<Box display="flex" alignItems="center">
			{textId}
			<IconButton
				size="small"
				onClick={handleClick}
				aria-label="Copy PERSUIT ID link"
				title="Copy PERSUIT ID link"
				data-testid="happy-id-link"
			>
				<LinkIcon fontSize="small" />
			</IconButton>
		</Box>
	)
}
