import { ResponseToDeliverable } from '@persuit/ui-graphql/generated'

/**
 * Calculates the comparison value of a proposal
 * @param responseToDeliverables
 * @returns comparison value (sum of all pricing items)
 */
export function calculateComparisonValue(
	responseToDeliverables: Array<ResponseToDeliverable>,
): number {
	return responseToDeliverables.reduce((acc, responseToDeliverable) => {
		const price = Number(responseToDeliverable?.price ?? 0)
		const pricingModel = responseToDeliverable?.pricingModel ?? ''
		const notIncluded = responseToDeliverable?.notIncluded ?? false
		if (isValidForComparisonValue({ pricingModel, price, notIncluded })) {
			return acc + price
		}
		return acc
	}, 0)
}

const isValidForComparisonValue = ({
	pricingModel,
	price,
	notIncluded,
}: {
	pricingModel: string
	price: number
	notIncluded: boolean
}): boolean => {
	return (
		[
			'cappedFee',
			'collaredFee',
			'contingencyFee',
			'fixedFee',
			'successFee',
			'estimate',
			'other',
		].includes(pricingModel) &&
		price !== null &&
		price > 0 &&
		!notIncluded
	)
}
