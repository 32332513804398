import * as React from 'react'
import { Menu, Box, useMediaQuery } from '@persuit/ui-components'

type ButtonLayoutProps = {
	PublishButton: React.ReactNode
	PublishMenuItem: React.ReactNode
	PreviewButton: React.ReactNode
	PreviewMenuItem: React.ReactNode
	CopyMenuItem: React.ReactNode
	DeleteMenuItem: React.ReactNode
	MenuButton: React.ReactNode
	handleMenuClose: () => void
	anchorEl: HTMLButtonElement | null
}

export const ButtonLayout = ({
	PublishButton,
	PublishMenuItem,
	PreviewButton,
	PreviewMenuItem,
	CopyMenuItem,
	DeleteMenuItem,
	MenuButton,
	handleMenuClose,
	anchorEl,
}: ButtonLayoutProps) => {
	// The action buttons are rendered 3 times
	// * For big screens as simple row of buttons
	// * For smallish screens as single button followed by a kebab containing any extra buttons
	// * For really small screens with all buttons in a kebab menu
	const isBigScreen = useMediaQuery('(min-width: 991px)')
	const isSmallScreen = useMediaQuery('(min-width: 661px) and (max-width: 990px)')
	const isExtraSmallScreen = useMediaQuery('(max-width: 660px)')

	return (
		<Box flexDirection="row" alignItems="center" gap="8px" display="flex">
			{/* Desktop */}
			{isBigScreen && (
				<React.Fragment>
					{PreviewButton}
					{MenuButton}
					{PublishButton}
					<Menu
						anchorEl={anchorEl}
						keepMounted={true}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
						transformOrigin={{ horizontal: 'center', vertical: 'top' }}
					>
						{CopyMenuItem}
						{DeleteMenuItem}
					</Menu>
				</React.Fragment>
			)}

			{/* Small screen */}
			{isSmallScreen && (
				<React.Fragment>
					{PublishButton}
					{MenuButton}
					<Menu
						id="kebab-menu"
						aria-labelledby="kebab-button"
						anchorEl={anchorEl}
						keepMounted={true}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
						transformOrigin={{ horizontal: 'center', vertical: 'top' }}
					>
						{PreviewMenuItem}
						{CopyMenuItem}
						{DeleteMenuItem}
					</Menu>
				</React.Fragment>
			)}

			{/* On very small views we put all the actions in a popover menu */}
			{isExtraSmallScreen && (
				<React.Fragment>
					{MenuButton}
					<Menu
						anchorEl={anchorEl}
						keepMounted={true}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
						transformOrigin={{ horizontal: 'center', vertical: 'top' }}
					>
						{PublishMenuItem}
						{PreviewMenuItem}
						{CopyMenuItem}
						{DeleteMenuItem}
					</Menu>
				</React.Fragment>
			)}
		</Box>
	)
}
