// @ts-strict-ignore
import { PracticeAreaData } from './practice-area-dropdown'

export const prepareFieldData = (practiceAreaData: PracticeAreaData) => {
	const fieldData = practiceAreaData.reduce((acc, service) => {
		const areasOfLaw = service.practiceAreas

		const mappedAreasOfLaw = areasOfLaw.map((aol, index) => {
			if (index === 0 && aol.name.toLowerCase() !== 'other') {
				return {
					_id: aol.code,
					label: aol.name,
					chipLabel: aol.name,
					values: [],
				}
			}

			// Concatenate serviceName - areaOfLawName for all services but Other
			const chipLabel =
				service.name.toLowerCase() === 'other' ? aol.name : `${service.name} - ${aol.name}`
			return {
				_id: aol.code,
				label: aol.name,
				chipLabel,
				values: [],
			}
		})

		const entry = {
			_id: `service_${service.code}`,
			label: service.name,
			chipLabel: '',
			values: mappedAreasOfLaw,
		}

		return [...acc, entry]
	}, [])

	return fieldData
}
