import { Box, Typography, useTheme, CircularProgress, CheckIcon } from '@persuit/ui-components'
import { getRfpProgressMiniViewContent } from './get-rfp-progress-mini-view-content'
import { RfpProgressStatusEnum, RfpProgressTypeEnum } from '@persuit/ui-graphql/generated'

export type RfpProgressMiniViewProps = {
	steps: Array<{
		type: RfpProgressTypeEnum
		status: RfpProgressStatusEnum
	}>
}

export const RfpProgressMiniView = ({ steps }: RfpProgressMiniViewProps) => {
	const theme = useTheme()
	const { progress, text, stepNumber, totalSteps } = getRfpProgressMiniViewContent(steps)

	const complete = progress === 100

	return (
		<Box
			display="flex"
			flexDirection="row"
			gap={theme.spacing(1)}
			alignItems="center"
			position="relative"
		>
			{complete ? (
				<Box
					minWidth="32px"
					height="32px"
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
					borderRadius="32px"
					bgcolor={theme.palette.success.main}
					color={theme.palette.primary.contrastText}
					m={0.5}
				>
					<CheckIcon />
				</Box>
			) : (
				<div
					style={{
						minWidth: '40px',
					}}
				>
					<CircularProgress
						variant="determinate"
						value={progress}
						aria-hidden="true"
						trackColor={theme.palette.background.appMain}
					/>
				</div>
			)}

			<Box display="flex" flexDirection="column">
				{!complete && (
					<Typography variant="caption">{`Step ${stepNumber} of ${totalSteps}`}</Typography>
				)}
				<Typography>
					<b>{text}</b>
				</Typography>
			</Box>
		</Box>
	)
}
