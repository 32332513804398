import { useEffect } from 'react'
import * as React from 'react'
import {
	ScreenOnly,
	Tabs as MUITabs,
	Tab as MUITab,
	Box,
	Typography,
	Badge,
	SvgIcon,
} from '@persuit/ui-components'
import { useIsFirstRender } from '@persuit/ui-hooks'

type TabProps = {
	currentTab: string
	tabs: {
		id: string
		icon?: typeof SvgIcon
		handleChange?: () => void
		disabled?: boolean
		dataTestid?: string
		dataTrackId?: string
		badgeCount?: number
		label: React.ReactNode
		content: React.ReactNode
	}[]
	fullWidthTabs: boolean
	isAnalyticsPage: boolean
}

const Tabs = ({ currentTab, tabs, fullWidthTabs, isAnalyticsPage }: TabProps) => {
	const tabValue = tabs.findIndex((tab) => tab.id === currentTab)

	const firstRender = useIsFirstRender()

	// Ensure that if the tabs are changed via any other method, that focus goes to the tab button
	useEffect(() => {
		const tabId = `tab-${currentTab}`
		const tab = document.getElementById(tabId)
		if (tab && !firstRender) {
			tab.focus()
		}
	}, [currentTab, firstRender])

	if (!tabs) {
		return null
	}

	return (
		<Box
			sx={{
				width: 'auto',
				minWidth: 'fit-content',
				'@supports (-ms-ime-align: auto)': {
					display: 'inline-block',
					right: 'inherit',
					minWidth: '1000px',
				},
				...(isAnalyticsPage
					? {
							'@supports (-ms-ime-align: auto)': {
								minWidth: '1200px !important',
							},
							width: '1000px',
					  }
					: {}),
			}}
		>
			<ScreenOnly>
				<Box
					sx={{
						display: 'flex',
						backgroundColor: 'common.white',
						borderBottom: `1px solid`,
						borderBottomColor: 'form.borderResting',
						// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
						boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
					}}
				>
					<MUITabs
						onChange={(_, value) => {
							const selectedTab = tabs[value]
							if (selectedTab && selectedTab.handleChange) {
								selectedTab.handleChange()
							}
						}}
						value={tabValue}
					>
						{tabs.map((tab) => {
							const Icon = tab.icon
							const StyledMobileIcon = Icon ? (
								<Icon color={tab.disabled ? 'disabled' : 'primary'} />
							) : undefined

							return [
								<MUITab
									sx={{
										display: 'flex',
										flexDirection: 'column',
										borderBottom: `3px solid`,
										borderBottomColor: 'common.white',
										padding: '18px 32px 8px 32px',
										textAlign: 'center',
										alignItems: 'center',
										justifyContent: 'center',
										position: 'relative',
										minWidth: '116px',
										'&:hover': {
											backgroundColor: 'action.hover',
											borderBottom: `2px solid`,
											borderBottomColor: 'primary.main',
											cursor: 'pointer',
										},
										transition: 'all 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
										boxSizing: 'content-box',
										maxWidth: fullWidthTabs ? 'none' : undefined,
									}}
									disabled={tab.disabled}
									data-testid={tab.dataTestid}
									data-trackid={tab.dataTrackId}
									id={`tab-${tab.id}`}
									key={`tab-${tab.id}`}
									aria-controls={`tab-panel-${tab.id}`}
									label={
										<>
											{!!tab.badgeCount && (
												<Badge badgeContent={tab.badgeCount} color="primary">
													{StyledMobileIcon}
												</Badge>
											)}
											{!tab.badgeCount && StyledMobileIcon}
											<Typography
												variant="body2Semibold"
												sx={{
													color: tab.disabled ? 'text.disabled' : 'primary.main',
												}}
											>
												{tab.label}
											</Typography>
										</>
									}
								/>,
							]
						})}
					</MUITabs>
				</Box>
			</ScreenOnly>
			{tabs.map((tab) => (
				<Box
					role="tabpanel"
					aria-labelledby={`tab-${tab.id}`}
					key={`tab-panel-${tab.id}`}
					id={`tab-panel-${tab.id}`}
					position="relative"
					hidden={currentTab !== tab.id}
				>
					<Box
						sx={{
							width: '100%',
							backgroundColor: 'common.white',
							position: 'relative',
							// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
							boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
						}}
					>
						{currentTab === tab.id && tab.content}
					</Box>
				</Box>
			))}
		</Box>
	)
}

Tabs.defaultProps = {
	fullWidthTabs: false,
	isAnalyticsPage: false,
}

export default Tabs
