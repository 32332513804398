import { graphql } from '@apollo/client/react/hoc'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box as MuiBox } from '@mui/material'
import getOr from 'lodash/fp/getOr'

import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Typography, styled, Divider, Button } from '@persuit/ui-components'
import allowTypes from '../../../../common/data/allow-types'
import { participateEvents } from '../../../../common/data/rfp-invite-events'
import { useToggleFavorites } from '../../../containers/sharing'
import revokeMutation from '../../../graphql/mutations/revoke-gql'
import { getOrEmptyObject, getOrEmptyString, getOrFalse } from '../../../utils/lenses'
import Box from '../../box.jsx'
import Card from '../../card.jsx'
import { ChipList } from '../../chip-list'
import ManageFirm from '../../dialog-wizard/manage-firm'
import { hasFirmAnyAcceptedProposals } from '../../dialog-wizard/manage-firm/utils'
import { NAMWOLFLabel } from '../../label'
import Spacer from '../../layout/spacer.jsx'
import NamwolfLogo from '../../namwolf/namwolf-logo'
import isRequestReopenedForInvite from '../../rfp/get-reopened-status-for-invite'
import { RequestReOpenStatus } from '../../status'
import RevisionRequired from '../../status/firm/revision-required-status'
import Text from '../../text.jsx'
import ThinSeparator from '../../thin-separator.jsx'
import NotesDialog from '../notes-dialog'
import NotesIcon from '../notes-dialog/notes-icon'
import RfpInvitedFirmProgressStepper from './invited-firm-progress-stepper'
import RfpInvitedFirmStatus from './rfp-invited-firm-status'
import RfpInvitedFirmNotParticipating from './rfp-invited-firm-not-participating'
import { PanelPreferredFirmLabel } from '../../panel-preferred-firm/panel-preferred-firm-label'
import { ApprovedFirmLabel } from '../../approved-firm/approved-firm-label'
import { ApprovedFirmLogo } from '../../approved-firm/approved-firm-logo'
import { PanelPreferredFirmLogo } from '../../panel-preferred-firm/panel-preferred-firm-logo'
import { ga } from '@persuit/ui-analytics'

const StyledToList = styled('div')`
	display: flex;
`

const StyledEmptyToListMessage = styled('div')`
	font-style: italic;
`

const StyledText = styled(Text)`
	text-transform: uppercase;
`

const StyledTagsAndButtons = styled('div')`
	display: flex;
	justify-content: space-between;
	padding-bottom: 5px;
`

const StyledTags = styled('div')`
	display: flex;
	align-items: center;
`

const StyledButtons = styled('div')`
	display: flex;
	align-items: center;
	gap: 8px;
`

const InvitedFirmCard = ({
	state: inviteState,
	item,
	sentTo,
	proposals,
	t,
	currentUserOrgId,
	rfp,
	invite,
	userFavourites,
	revokeMutation,
	isApprovedFirm = false,
	isPanelOrPreferredFirm = false,
}) => {
	const { toggleFavourite } = useToggleFavorites()
	const handleToggleFavourite = (userId) => () => toggleFavourite(userId)

	const hasClientAskedForProposalRevision = proposals.some(
		(proposal) => proposal.hasRevisionRequest === true,
	)

	const revoke = (userToDelete) => {
		revokeMutation({
			variables: {
				rfpId: rfp._id,
				userIds: [userToDelete._id],
			},
		})
	}

	const chipMap = (allow) => {
		const { item } = allow

		const {
			_id,
			name: { first, last },
			org: { name: orgName, isNamwolfMember },
		} = item

		const itemLogos = (
			<>
				{isApprovedFirm && <ApprovedFirmLogo />}
				{isPanelOrPreferredFirm && <PanelPreferredFirmLogo />}
				{isNamwolfMember && <NamwolfLogo />}
			</>
		)

		return {
			userId: _id,
			primaryText: `${first} ${last}`,
			secondaryText: orgName,
			org: { name: orgName, isNamwolfMember },
			icon: itemLogos,
			orgName,
			onDelete: () => {
				revoke(item)
			},
			isFavourite: userFavourites.includes(_id.toString()),
			isPanelOrPreferredFirm,
			isApprovedFirm,
		}
	}

	const firmOrgId = getOrEmptyString('_id', item)

	// shaping the data as required by the ChipList component for client invites
	const chipsTo = sentTo
		.filter(({ type, updatedBy }) => {
			return (
				(type === allowTypes.VIEW || type === allowTypes.EDIT || type === allowTypes.PENDING) &&
				updatedBy.org._id === currentUserOrgId
			)
		})
		.map(chipMap)

	// shaping the data as required by the ChipList component for firm shares
	const chipsSharedWith = sentTo
		.filter(({ type, updatedBy }) => {
			return (
				(type === allowTypes.VIEW || type === allowTypes.EDIT || type === allowTypes.PENDING) &&
				updatedBy.org._id !== currentUserOrgId
			)
		})
		.map(chipMap)

	const isEliminated = getOrFalse('eliminate.eliminated', inviteState) === true

	const participateState = getOrEmptyObject('participate', inviteState)
	const notParticipating =
		getOr(participateEvents.UNANSWERED, 'response', participateState) ===
		participateEvents.NOT_PARTICIPATING

	const firmHasAcceptedProposals = hasFirmAnyAcceptedProposals(item, rfp.proposals)
	const content = (
		<Box left={1} right={1}>
			<StyledText type="title" component="span">
				{item.hasOwnProperty('name') ? item.name : ''}
			</StyledText>

			<StyledTagsAndButtons>
				<StyledTags>
					{isApprovedFirm && (
						<Fragment>
							<ApprovedFirmLabel mr={1} />
						</Fragment>
					)}
					{isPanelOrPreferredFirm && (
						<Fragment>
							<PanelPreferredFirmLabel mr={1} />
						</Fragment>
					)}
					{item.isNamwolfMember && (
						<Fragment>
							<NAMWOLFLabel />
							<Spacer direction="row" />
						</Fragment>
					)}
				</StyledTags>
				<StyledButtons>
					<NotesDialog rfpId={rfp._id} firmId={item._id} firmName={item.name}>
						{({ handleOpenDialog }) => (
							<Button
								data-testid="notes-button"
								startIcon={<NotesIcon />}
								onClick={() => {
									ga({
										label: 'FEATURE_USAGE',
										page: 'CLIENT_RFP_FIRM_TAB',
										event: 'CLICK_NOTES_BUTTON',
									})
									handleOpenDialog()
								}}
							>
								Notes
							</Button>
						)}
					</NotesDialog>
					{/* Manage firm button */}
					{!firmHasAcceptedProposals && <ManageFirm rfp={rfp} firmOrg={item} />}
				</StyledButtons>
			</StyledTagsAndButtons>

			<ThinSeparator />
			<Spacer direction="column" space={1} />

			<MuiBox display="flex">
				{isRequestReopenedForInvite(rfp, firmOrgId) && (
					// Wrap the status with an inline-block div
					// This is a fix for IE11
					// Without this the status is full width and looks bad
					<div style={{ display: 'inline-block' }}>
						<RequestReOpenStatus />
					</div>
				)}
				<Spacer direction="row" space={1} />
				{hasClientAskedForProposalRevision && !isEliminated && <RevisionRequired />}
			</MuiBox>

			<MuiBox mt={4} mb={2}>
				<RfpInvitedFirmProgressStepper rfp={rfp} proposals={proposals} invite={invite} />
			</MuiBox>

			{notParticipating && (
				<Fragment>
					<Spacer />
					<RfpInvitedFirmNotParticipating participateState={participateState} />
					<Spacer />
				</Fragment>
			)}

			<Spacer direction="column" space={1} />
			<Divider />

			<Spacer direction="column" space={2} />
			<StyledToList>
				<Typography color="text.secondary">
					<Spacer direction="column" space={1} />
					To:
					<Spacer direction="row" space={1} />
				</Typography>
				{!chipsTo.length && (
					<StyledEmptyToListMessage>
						<Spacer direction="column" space={1} />
						None selected
					</StyledEmptyToListMessage>
				)}
				{chipsTo.length > 0 && (
					<ChipList
						data={chipsTo}
						chipProps={{
							hideStar: false,
							addToFavourites: handleToggleFavourite,
						}}
						listAriaLabel="Invited users: Press backspace or delete on a user to remove"
					/>
				)}
			</StyledToList>
			<Spacer direction="column" space={1} />
			{chipsSharedWith.length > 0 && (
				<StyledToList>
					<Typography color="text.secondary">
						<Spacer direction="column" space={1} />
						{t('rfp.invites.sharedWith')}:
						<Spacer direction="row" space={1} />
					</Typography>
					<ChipList
						data={chipsSharedWith}
						chipProps={{
							hideStar: true,
						}}
						listAriaLabel="Invited users: Press backspace or delete on a user to remove"
					/>
				</StyledToList>
			)}
			<Spacer direction="column" space={1} />
			<RfpInvitedFirmStatus proposals={proposals || []} />
		</Box>
	)

	return (
		<Fragment>
			<Card content={content} showHoverStyle={false} />
			<Spacer direction="column" space={1} />
		</Fragment>
	)
}

export default compose(
	withTranslation(),
	connect((state) => ({
		currentUserOrgId: state.auth.user.org._id,
		userFavourites: state.auth.user.favourites,
	})),
	graphql(revokeMutation, {
		name: 'revokeMutation',
	}),
)(InvitedFirmCard)
