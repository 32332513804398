import { TextField, TextFieldProps } from './text-field'
import { useFormComponent, RHFFormComponentProps } from '../../utilities'

export type FormTextFieldProps = RHFFormComponentProps<string, TextFieldProps>

export const FormTextField = ({ name, defaultValue, rules, ...props }: FormTextFieldProps) => {
	const {
		field,
		fieldState: { error },
	} = useFormComponent({
		rules,
		name,
		defaultValue,
	})

	const errorMessage = error?.message
	const hasError = !!errorMessage

	return (
		<TextField
			value={field.value}
			name={field.name}
			inputRef={field.ref}
			required={field.required}
			error={hasError}
			helperText={error?.message}
			{...props}
			onChange={(e) => {
				field.onChange(e)
				props.onChange?.(e)
			}}
			onBlur={(e) => {
				field.onBlur()
				props.onBlur?.(e)
			}}
		/>
	)
}
