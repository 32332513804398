import { useState, useEffect, ReactNode, ElementType } from 'react'
import {
	Box,
	BoxProps,
	useTheme,
	Typography,
	Dialog,
	ZoomInIcon,
	ScreenOnly,
	IconButton,
} from '@persuit/ui-components'
import { filterRecord } from '@persuit/common-utils'
import { useStore } from '../../store'

type UsePinHighlightInput = {
	responseId: string
	background?: string
	transition?: string
}

const usePinHighlight = ({ responseId, background, transition }: UsePinHighlightInput) => {
	const theme = useTheme()
	const [showPinHighlight, setShowPinHighlight] = useState(false)

	const { isPinned } = useStore((state, s) => ({
		isPinned: s.isPinned(responseId),
	}))

	useEffect(() => {
		if (isPinned) {
			setShowPinHighlight(() => {
				requestAnimationFrame(() => setShowPinHighlight(false))
				return true
			})
		}
	}, [isPinned])

	return {
		background: showPinHighlight ? theme.palette.primary.lighterHue : background,
		transition: [transition, showPinHighlight ? transition : 'background 2s']
			.filter(Boolean)
			.join(', '),
	}
}

export type GridCellProps<T extends ElementType = 'td'> = BoxProps<T> & {
	headers?: string
	cellProps?: BoxProps<T>
}

/** Cell used for CSS grid positioning */
export const GridCell = ({ headers, sx, cellProps, ...rest }: GridCellProps) => {
	const theme = useTheme()

	return (
		<Box component="td" headers={headers} {...cellProps} sx={{ height: '100%', ...cellProps?.sx }}>
			<Box
				sx={{
					height: '100%',
					verticalAlign: 'top',
					textAlign: 'left',
					borderBottom: `1px solid ${theme.palette.form.borderResting}`,
					padding: '1em',
					boxSizing: 'border-box',
					...filterRecord((sx as any) ?? {}, (v) => v !== undefined),
				}}
				{...rest}
			/>
		</Box>
	)
}

export type DataCellProps = GridCellProps & {
	responseId: string
	showRightBorder?: boolean
}

export const DataCell = ({ showRightBorder, sx, responseId, ...rest }: DataCellProps) => {
	const theme = useTheme()
	const { isWide } = useStore((state, s) => ({
		isWide: s.isWide(responseId),
	}))

	return (
		<GridCell
			sx={{
				borderBottom: `1px solid ${theme.palette.form.borderResting}`,
				borderRight: showRightBorder ? `1px solid ${theme.palette.form.borderResting}` : undefined,
				maxWidth: '380px',
				minWidth: isWide ? '600px' : '300px',
				padding: '1em',
				boxSizing: 'border-box',
				...filterRecord(sx as any, (v) => v !== undefined),
			}}
			{...rest}
		/>
	)
}

export type CellProps = DataCellProps & {
	responseId: string
}

export const Cell = ({ sx, responseId, ...rest }: CellProps) => {
	const pinStyles = usePinHighlight({ responseId })

	return <DataCell responseId={responseId} sx={{ ...pinStyles, ...sx }} {...rest} />
}

export type ExpandableCellProps = DataCellProps & {
	expandedContent: ReactNode
	expandedTitle: ReactNode
	expandedSubTitle?: ReactNode
}

export const ExpandableCell = ({
	responseId,
	children,
	expandedContent,
	expandedTitle,
	expandedSubTitle,
	...boxProps
}: ExpandableCellProps) => {
	const theme = useTheme()

	const [showDialog, setShowDialog] = useState(false)
	const closeDialog = () => setShowDialog(false)
	const openDialog = () => setShowDialog(true)

	const noCellContent = !children
	const pinStyles = usePinHighlight({ responseId })

	const dialogTitle = (
		<>
			{expandedSubTitle && (
				<Typography display="block" variant="caption">
					{expandedSubTitle}
				</Typography>
			)}
			{expandedTitle}
		</>
	)

	return (
		<>
			<DataCell
				{...boxProps}
				responseId={responseId}
				onClick={noCellContent ? () => undefined : openDialog}
				cellProps={{
					...boxProps.cellProps,
					sx: {
						position: 'relative',
						'&:hover': {
							transition: 'transform 0.2s ease-in-out',
							zIndex: 900,
							transform: `scale(1.05, 1.05)`,
							boxShadow: `0 0 3px 1px ${theme.palette.primary.main}`,
							cursor: 'pointer',
						},
						...boxProps.cellProps?.sx,
					},
				}}
				sx={{
					...pinStyles,
					wordWrap: 'break-word',
					...boxProps.sx,
				}}
			>
				<Box sx={{ mb: '1em', height: '100%' }}>
					{children}
					{noCellContent ? null : (
						<ScreenOnly>
							<IconButton
								aria-label="View full content"
								sx={{
									color: 'primary.main',
									display: 'block',
									position: 'absolute',
									right: '-1px',
									bottom: '-1px',
								}}
							>
								<ZoomInIcon />
							</IconButton>
						</ScreenOnly>
					)}
				</Box>
			</DataCell>

			<Dialog
				open={showDialog}
				onClose={closeDialog}
				size="md"
				title={dialogTitle}
				actions={[
					{
						label: 'Close',
						variant: 'text',
						onClick: closeDialog,
					},
				]}
			>
				<Box sx={{ wordBreak: 'break-word' }}>{expandedContent}</Box>
			</Dialog>
		</>
	)
}
