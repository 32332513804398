import { useMemo } from 'react'
import { LoadingSpinner, PageTitle } from '@persuit/ui-components'
import { graphql, useMutation } from '@persuit/ui-graphql'
import { useSectionalContainer } from '../../sectional-form'
import { RfpTemplate } from '../types'
import { DiversityForm, DiversityFormSectionData } from '../../rfp-forms'
import { isNotNil } from '@persuit/common-utils'

const SAVE_DIVERSITY = graphql(`
	mutation RfpTemplateDiversitySave(
		$payload: RfpTemplateDraftDiversityInput!
		$validate: Boolean!
	) {
		saveRfpTemplateDraftDiversity(payload: $payload, validate: $validate) {
			rfpTemplate {
				...TemplateFormRfpDraft
			}
			rfpTemplateDraftErrors @include(if: $validate) {
				...TemplateFormRfpDraftErrors
			}
		}
	}
`)

type DiversitySectionProps = {
	instanceId: string
	rfpTemplate: RfpTemplate
	refetchRfpTemplate: () => Promise<void>
	refetchErrors?: () => Promise<void>
	sendMode: boolean
}

export const DiversitySection = ({
	instanceId,
	rfpTemplate,
	refetchRfpTemplate,
	refetchErrors,
	sendMode,
}: DiversitySectionProps) => {
	const [saveSectionMutation] = useMutation(SAVE_DIVERSITY)
	const { loading } = useSectionalContainer(rfpTemplate, refetchRfpTemplate, refetchErrors)

	const diversityDefinitions = (rfpTemplate?.detail?.diversity?.diversityDefinitions ?? []).filter(
		isNotNil,
	)
	const sectionData = useMemo(
		() => ({
			diversityRequired: rfpTemplate?.detail?.diversity?.diversityRequired ?? false,
		}),
		[rfpTemplate],
	)

	if (loading) return <LoadingSpinner />

	const saveSection = async (formVariables: DiversityFormSectionData) => {
		await saveSectionMutation({
			variables: {
				payload: {
					...formVariables,
					formInstanceId: instanceId,
					rfpTemplateId: rfpTemplate._id,
				},
				validate: sendMode,
			},
		})
	}

	return (
		<>
			<PageTitle>Manage Diversity of template</PageTitle>
			<DiversityForm
				saveSection={saveSection}
				sectionData={sectionData}
				diversityDefinitions={diversityDefinitions}
			/>
		</>
	)
}
