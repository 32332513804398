import { graphql, useQuery, useSubscription } from '@persuit/ui-graphql'

const GET_UNREAD_MESSAGES = graphql(`
	query MessagingUnreadMessages($requestId: ID!) {
		channels(requestId: $requestId) {
			id
			myUnreads {
				unreadCount
			}
		}
	}
`)

const REQUEST_CHANNELS_SUBSCRIPTION = graphql(`
	subscription MessagingUnreadMessagesRequestChannels($requestId: ID!) {
		requestChannels(requestId: $requestId) {
			id
			myUnreads {
				unreadCount
			}
		}
	}
`)

export const useTotalUnreadMessages = (requestId: string): number | null => {
	const { data } = useQuery(GET_UNREAD_MESSAGES, { variables: { requestId } })
	useSubscription(REQUEST_CHANNELS_SUBSCRIPTION, { variables: { requestId } })

	if (!data) return null

	return (
		data.channels?.reduce((acc, channel) => {
			return acc + channel.myUnreads.unreadCount
		}, 0) ?? 0
	)
}
