import { Typography, Box, TypographyProps } from '@persuit/ui-components'
import { SectionalLock } from '../../sectional-form'

type TemplateLockedTextProps = {
	sectionalLock: SectionalLock
	userId: string
} & TypographyProps

export const TemplateLockedText = ({
	sectionalLock,
	userId,
	...props
}: TemplateLockedTextProps) => {
	if (sectionalLock.userId === userId)
		return <Typography {...props}>You are editing this section in a different tab</Typography>

	return (
		<Box display="flex" flexDirection="column">
			<Typography {...props}>
				This section is currently being edited by <b>{sectionalLock.displayName}</b>
			</Typography>
			<Typography variant="caption">Come back later</Typography>
		</Box>
	)
}
