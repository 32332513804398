export default (hasAnAcceptedProposal, lowestPrice, numberOfBids) => {
	return (
		// show estimated savings if a proposal has not been accepted
		!hasAnAcceptedProposal &&
		// and if there is a non-zero lowest price bid
		!!lowestPrice &&
		// and there is more than 1 proposal submitted
		numberOfBids > 1
	)
	// also the request must be closed which is done further upstream
}
