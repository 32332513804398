import allowTypes from '../../../data/allow-types'
/**
 * Return true if:
 * - The users own ID is in the resource.allow array
 * - The users own org is in the resource.allow array

 * @param {string|Object}user - User ID or user object (normalized or denormalized)
 * @param {Object} resource - Normalized or denormalized resource
 */
export default function isInAllow(user, resource) {
	if (!resource || !resource.allow || !user) {
		return false
	}

	const userId = user._id ? user._id.toString() : user.toString()

	let userOrgId = null
	if (user.org && user.org._id) {
		userOrgId = user.org._id.toString()
	} else if (user.org) {
		userOrgId = user.org.toString()
	}

	/*
	 * Iterate over each value in the allow array
	 *
	 * .find() returns true if one of the following is in the allow array checks passes:
	 *  - Users own ID is present
	 *  - Users org ID is present
	 *  - User share is not of type REVOKED or PENDING
	 */
	return !!resource.allow.find((data) => {
		if (!data.item) {
			return false
		}

		const itemId = data.item._id ? data.item._id.toString() : data.item.toString()

		if (userOrgId && itemId === userOrgId && data.kind === 'Org') {
			return true
		}

		if (userId && itemId === userId && data.kind === 'User') {
			if ((data.type && data.type === allowTypes.REVOKED) || data.type === allowTypes.PENDING) {
				return false
			}

			return true
		}

		return false
	})
}
