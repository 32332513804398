import { useState } from 'react'
import { isNotNil } from '@persuit/common-utils'
import { useMutation } from '@persuit/ui-graphql'
import { SHARE_MUTATION } from './sharing-control.graphql'
import { isEmpty } from 'lodash/fp'
import {
	useSnackbar,
	useConfirmDialog,
	Button,
	DialogDeprecated,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@persuit/ui-components'

import allowTypes from '../../../common/data/allow-types'
import unregisteredUserListState from '../../../common/data/unregistered-user-list-states'
import { Container } from '../../components/grid'
import LoadingSpinner from '../../components/loading-spinner.jsx'
import { useInvitablePeople } from '../../custom-hooks'
import { useToggleFavorites } from './use-toggle-favourites'

import { PeopleSearch, Org, Group } from './people-search/people-search'

type User = {
	_id: string
	org: Org
	// group is optional until UAM migration is executed
	group?: Group
}

export type PeopleSearchDialogProps = {
	togglePeopleSearchDialog: () => void
	isOpen: boolean
	entity: {
		_id: string
		createdBy: {
			_id: string
		}
		allow: Array<{
			kind: string
			type: string
			item: {
				_id: string
			}
		}>
	}
	showConfirmation: boolean
	postSave?: () => void
	user: User
}

export const PeopleSearchDialog = ({
	togglePeopleSearchDialog: handleCancel,
	isOpen,
	entity,
	// Whether or not to show a confirmation dialog
	showConfirmation,
	postSave,
	user,
}: PeopleSearchDialogProps) => {
	const { openSnackbar } = useSnackbar()
	const { openConfirmDialog } = useConfirmDialog()
	const { toggleFavourite: addToFavourites } = useToggleFavorites()

	const people = useInvitablePeople()
	const [share] = useMutation(SHARE_MUTATION)

	const [userIdsToBeAdded, setUserIdsToBeAdded] = useState<string[]>([])

	if (people.loading) {
		return (
			<Container fixed={true}>
				<LoadingSpinner />
			</Container>
		)
	}

	if (isEmpty(user)) return null

	const added = entity.allow
		.filter((a) => a.kind === 'User' && a.type !== allowTypes.REVOKED)
		.map((a) => a.item._id)

	const data =
		people.data?.getInvitablePeople?.users
			// filter out owner (i.e. no one should be able to add the owner)
			.filter((person) => person._id.toString() !== entity.createdBy._id)
			// filter out the current user (i.e. the current user should not be able add themselves)
			.filter((person) => person._id.toString() !== user._id)
			// filter if colleagues or all invitees
			.filter(
				(person) =>
					// only show invitable people
					person.org._id !== user.org._id,
			) ?? []

	const userGroupId = user.group?._id ?? ''
	const userOrg = user.org

	return (
		<DialogDeprecated
			open={isOpen}
			onClose={handleCancel}
			PaperProps={{ sx: { maxWidth: 'unset', width: 'min(80vw, 1100px)' } }}
		>
			<DialogTitle>Add People</DialogTitle>

			<DialogContent>
				<PeopleSearch
					onChange={(ids) => setUserIdsToBeAdded(ids)}
					data={data}
					added={added}
					addToFavourites={addToFavourites}
					defaultGroupFilter={userGroupId}
					userOrg={userOrg}
				/>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() => {
						setUserIdsToBeAdded([])
						handleCancel()
					}}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!userIdsToBeAdded.length}
					onClick={() => {
						const confirm = async () => {
							const mappedItems = userIdsToBeAdded.map((userId) => ({
								_id: userId,
								list: unregisteredUserListState.TO,
							}))

							await share({
								variables: {
									id: entity._id,
									users: mappedItems,
								},
							})

							openSnackbar('Firms succesfully added')
							handleCancel()
							postSave?.()
							setUserIdsToBeAdded([])
						}

						if (!showConfirmation) {
							confirm()
						} else {
							openConfirmDialog({
								title: 'Are you sure you want to add the selected people to this request?',
								content: (
									<>
										<div>
											The following people will be given access to this request and notified
											immediately:
										</div>
										<div>
											{userIdsToBeAdded
												.map((inviteId) => data.find((item) => inviteId === item._id)?.email)
												.filter(isNotNil)
												.join(', ')}
										</div>
									</>
								),
								actions: [
									{ label: 'Cancel', type: 'secondary', close: true, action: () => undefined },
									{ label: 'Add', action: () => confirm(), close: true },
								],
							})
						}
					}}
				>
					Confirm
				</Button>
			</DialogActions>
		</DialogDeprecated>
	)
}
