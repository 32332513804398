import { Box, Typography, useTheme, FormNumberFieldCleave, SROnly } from '@persuit/ui-components'
import { getPricingModelString } from '../../../common-util'
import { formatPriceWithCurrency, getCurrencySymbol } from '@persuit/common-utils'
import { Delta } from '../delta'
import { GRID_TEMPLATE_COLUMNS } from '../constants'
import { useStore } from '../../store'

export type PricingItemProps = {
	index: number
	originalPrice: number
	currency: string
	title: string
	id: string
	pricingModel: string
	editable?: boolean
}

export const PricingItem = ({
	index,
	originalPrice,
	currency,
	title,
	id,
	pricingModel,
	editable = true,
}: PricingItemProps) => {
	const theme = useTheme()

	const currentPrice = useStore((state) => state.pricingItemFormState[id] ?? originalPrice)

	return (
		<Box
			width="100%"
			display="grid"
			gridTemplateRows="auto"
			gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
			alignItems="center"
			role="row"
		>
			<Box role="cell">
				<Typography>{index}</Typography>
			</Box>
			<Box role="cell">
				<Typography>
					<SROnly>{`Title: `}</SROnly>
					{title}
				</Typography>
			</Box>

			<Box gridColumn={'3 /5'} role="cell" data-testid="fee-type">
				<Typography>
					<SROnly>{`Fee type: `}</SROnly>
					{getPricingModelString(pricingModel)}
				</Typography>
			</Box>

			<Box role="cell" data-testid="current-price">
				<Typography color="text.secondary">
					<SROnly>{`Current rate: `}</SROnly>
					{formatPriceWithCurrency(currency, originalPrice)}
				</Typography>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				gap={theme.spacing(0.5)}
				role="cell"
				data-testid="updated-price"
			>
				{editable ? (
					<>
						<Typography>{getCurrencySymbol(currency)}</Typography>
						<FormNumberFieldCleave
							name={id}
							id={id}
							label=""
							decimalScale={4}
							inputProps={{
								'aria-label': `${title} input`,
							}}
							emptyValue={originalPrice}
							replaceZero={true}
						/>
						<Typography>{currency}</Typography>
					</>
				) : (
					<Typography color="text.secondary">
						<SROnly>{`Updated rate: `}</SROnly>
						{formatPriceWithCurrency(currency, currentPrice)}
					</Typography>
				)}
			</Box>

			<Box role="cell" data-testid="pricing-change-delta">
				<Delta newNumber={currentPrice} originalNumber={originalPrice} currency={currency} />
			</Box>
		</Box>
	)
}
