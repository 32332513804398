// @ts-strict-ignore
import { useEffect } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { Box, Form, useTheme } from '@persuit/ui-components'
import { PhasePricingSection } from '../phase-pricing-section'
import { TotalPriceSection } from '../total-price-section'
import { PhaseDeltaSection } from '../phase-delta-section'
import { useForm } from 'react-hook-form'
import { useActions, useStore } from '../../store'
import { ComparisonValueSection } from '../comparison-value-section'

export const ProposalQuickRevisionPricingForm = () => {
	const theme = useTheme()
	const { totalPriceValue, pricingItemLookup, pricingItemFormState, totalPriceNotRequired } =
		useStore(
			(
				{ currency, pricingItemLookup, pricingItemFormState, totalPriceNotRequired },
				selectors,
			) => ({
				currency,
				pricingItemLookup,
				totalPriceValue: selectors.currentTotalPriceValue(),
				pricingItemFormState,
				totalPriceNotRequired,
			}),
		)
	const { setPricingItemFormState } = useActions()

	const defaultValues = Object.keys(pricingItemLookup).reduce((acc, pricingItem) => {
		acc[pricingItem] = pricingItemLookup[pricingItem].price
		return acc
	}, {})

	const methods = useForm({
		defaultValues: {
			...defaultValues,
			...pricingItemFormState,
			totalPriceValue,
		},
	})

	useEffect(() => {
		const { unsubscribe } = methods.watch((value) => {
			setPricingItemFormState(value)
		})
		return () => unsubscribe()
	}, [methods, setPricingItemFormState])

	return (
		<Form methods={methods}>
			<Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
				<TotalPriceSection />
				<ComparisonValueSection />
				{!isEmpty(pricingItemLookup) && <PhasePricingSection />}
				{!totalPriceNotRequired && <PhaseDeltaSection />}
			</Box>
		</Form>
	)
}
