import { ReactNode } from 'react'
import { Box, useMediaQuery } from '@persuit/ui-components'

type SectionalFormLayoutProps = {
	header: ReactNode
	navigation: ReactNode
	main: ReactNode
	sidebar: ReactNode
	global?: ReactNode
	currentSection: string
}

export const SectionalFormLayout = ({
	header,
	navigation,
	main,
	global,
	sidebar,
	currentSection,
}: SectionalFormLayoutProps) => {
	const isNotPricing = currentSection !== 'pricing'
	const isNotQuestions = currentSection !== 'questions'
	const fromLgScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				height: 'calc(100vh - 64px)',
				overflow: 'hidden',
				display: 'grid',
				gridTemplateRows: 'auto minmax(0, 1fr)',
				gridTemplateColumns: 'auto minmax(0, 1fr)',

				'@media print': {
					overflow: 'visible',
				},
			}}
		>
			{global}
			{/* Header container */}
			<Box
				sx={{
					gridRow: 1,
					gridColumnStart: 1,
					gridColumnEnd: 3,
					height: 'auto',
					width: '100%',
					borderBottom: '2px solid',
					borderBottomColor: 'form.borderResting',
				}}
				displayPrint="none"
			>
				{header}
			</Box>

			{navigation}

			{/* Main container */}
			<Box
				gridRow={2}
				gridColumn={2}
				width="100%"
				height="100%"
				padding={isNotPricing && isNotQuestions ? undefined : 1}
				sx={{
					overflowY: 'auto',
				}}
				displayPrint="none"
			>
				<Box
					display="grid"
					gridTemplateRows="auto"
					gridTemplateColumns="minmax(0, 1fr) auto"
					gap={1}
					margin={isNotPricing && isNotQuestions ? 1 : undefined}
				>
					<Box gridRow={1} gridColumn={1} maxWidth="950px" width="100%" justifySelf="center">
						<Box
							borderRadius="5px"
							// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
							bgcolor={isNotPricing && isNotQuestions ? '#fff' : undefined}
							boxShadow={
								isNotPricing && isNotQuestions
									? '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)'
									: undefined
							}
						>
							{main}
						</Box>
					</Box>
					{/* Sidebar container */}
					<Box
						display="flex"
						flexDirection="column"
						gap={1}
						gridColumn={fromLgScreenSize ? 2 : 1}
						gridRow={'auto'}
						sx={
							fromLgScreenSize
								? {
										width: '20vw',
										maxWidth: '450px',
										maxHeight: '75vh',
										top: '0px',
										position: 'sticky',
								  }
								: undefined
						}
					>
						<Box
							sx={{
								overflow: 'auto',
								flex: 1,
							}}
						>
							{sidebar}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
