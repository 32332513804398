import { useState } from 'react'
import { FileSelector, FileSelectorProps } from '../file-selector'
import axios from 'axios'
import { FileScanState } from '../types'

type UploadFileFunction<FileRepresentation> = (files: File[]) => Promise<FileRepresentation[]>

export type FileUploaderProps<FileRepresentation> = Omit<FileSelectorProps, 'onChange'> & {
	/**
	 * How to upload files and return a representation of that file.
	 * This will default to sending the files to our `/en/file/` endpoint.
	 */
	uploadFiles?: UploadFileFunction<FileRepresentation>
	onError?: (error: Error) => void
	/**
	 * Called with a list of FileRepresentations of files that have been uploaded so far.
	 */
	onChange?: (files: FileRepresentation[]) => Promise<void> | void
	files: FileRepresentation[]
}

export function FileUploader<T>({
	uploadFiles: _uploadFiles,
	onError,
	onChange,
	files,
	loading,
	...rest
}: FileUploaderProps<T>) {
	const [localLoading, setLocalLoading] = useState(false)
	const uploadFiles = (_uploadFiles ?? defaultUploadFiles) as unknown as UploadFileFunction<T>

	return (
		<FileSelector
			loading={loading || localLoading}
			{...rest}
			onChange={async (newFiles) => {
				try {
					setLocalLoading(true)
					await onChange?.(files.concat(await uploadFiles(newFiles)))
				} catch (e: any) {
					onError?.(e)
				} finally {
					setLocalLoading(false)
				}
			}}
		/>
	)
}

export type DefaultFileRepresentation = {
	chunkSize: number
	createdBy: string
	fileStatus: FileScanState
	filename: string
	length: number
	md5: string
	updatedAt: string
	updatedBy: null | unknown
	uploadDate: string
	_id: string
}

export const defaultUploadFiles = async (files: File[]): Promise<DefaultFileRepresentation[]> => {
	async function uploadFile(file: File) {
		const formData = new FormData()
		formData.append('file', file)
		return (await axios.post('/en/file/', formData)).data
	}

	return Promise.all(files.map(uploadFile))
}
