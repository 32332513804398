/**
 * Takes a promise returning function and returns another promise returning
 * function that will batch all calls so that while a promise is being resolved,
 * all subsequent calls will resolve to the same promise.
 */
export function createBatchedPromiseFunction<R>(fn: () => Promise<R>): () => Promise<R> {
	let currentPromise: Promise<R> | null = null

	return (): Promise<R> => {
		if (currentPromise) {
			return currentPromise
		}

		currentPromise = fn()
		// eslint-disable-next-line promise/catch-or-return -- presumably the initiator of the promise will catch it
		currentPromise.finally(() => {
			currentPromise = null
		})
		return currentPromise
	}
}
