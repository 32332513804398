import { Fragment } from 'react'
import * as React from 'react'
import { Rfp } from '../../../../../types'
import { Box, Typography, useTheme } from '@persuit/ui-components'
import { RfpDetailQuestion } from '../rfp-detail-question'
import { HeadlineSeparator } from '../../../headline-separator'
import { groupQuestions } from '../../../../util/group-questions'
import { DeepPick } from '@persuit/common-utils'

export type RfpDetailQuestionsSectionDocType = DeepPick<
	Rfp,
	'detail.questions' | 'detail.questionGroups'
>

type RfpDetailQuestionsSectionProps = {
	document: RfpDetailQuestionsSectionDocType
}

export const RfpDetailQuestionsSection = ({ document }: RfpDetailQuestionsSectionProps) => {
	const questions = document?.detail?.questions ?? []
	const questionGroups = document?.detail?.questionGroups ?? []

	const theme = useTheme()

	if (!questionGroups || questionGroups.length === 0) return null

	const groupedQuestions = groupQuestions(questions, questionGroups)

	return (
		<React.Fragment>
			<HeadlineSeparator headline="Questions" />
			<Box display="flex" flexDirection="column" gap={4}>
				{groupedQuestions.map((questionGroup, questionGroupIndex) => {
					if (questionGroup.hidden) {
						return questionGroup.questions.map((question, i) => (
							<Fragment key={i}>
								<RfpDetailQuestion
									index={questionGroupIndex}
									prefix={undefined}
									questionTitle={question.title ?? ''}
									questionDescription={question?.description ?? ''}
									rfpQuestionType={question.type}
									questionOptions={question.options}
								/>
							</Fragment>
						))
					}
					return (
						<Box key={questionGroup._id}>
							<Typography
								variant="h3XSmall"
								style={{
									fontStyle: questionGroup.name ? 'inherit' : 'italic',
								}}
							>
								{questionGroupIndex + 1}. {questionGroup.name || 'Untitled Group'}
							</Typography>

							<Box
								style={{ border: `1px solid ${theme.palette.grey500}` }}
								mt={1}
								p={1}
								display="flex"
								flexDirection="column"
								gap={theme.spacing(2)}
							>
								{questionGroup.questions.map((question, i) => (
									<Fragment key={i}>
										<RfpDetailQuestion
											index={i}
											prefix={`${questionGroupIndex + 1}.`}
											questionTitle={question.title ?? ''}
											questionDescription={question?.description ?? ''}
											rfpQuestionType={question.type}
											questionOptions={question.options}
										/>
									</Fragment>
								))}
							</Box>
						</Box>
					)
				})}
			</Box>
		</React.Fragment>
	)
}
