import { memo } from 'react'
import { Typography } from '@persuit/ui-components'
import useTranslation from '../../../../../custom-hooks/translation-hook'
import { totalPricePresentaionData } from '../../../presentation-data'

import { Cell, CellProps } from '../../components'

type TotalPriceCellProps = CellProps & {
	currency: string
	response: any
}

const TotalPriceCell = ({ currency, response, ...rest }: TotalPriceCellProps) => {
	const { t } = useTranslation()
	const { originalValue, currentValue, isPending } = totalPricePresentaionData(response, currency)
	const { totalPriceModel } = response

	return (
		<Cell
			{...rest}
			sx={{
				...rest.sx,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
			}}
		>
			{currentValue && (
				<div>
					<Typography
						variant="body1"
						fontSize="24px"
						sx={{ mb: 1 }}
						data-testid="response-total-price"
					>
						{currentValue}
					</Typography>

					{originalValue && (
						<Typography strikeThrough={true} color="text.secondary">
							{originalValue}
						</Typography>
					)}
				</div>
			)}

			{/* RFI pending total price */}
			{isPending && (
				<Typography color="error" fontStyle="italic" data-testid="pending-total-price">
					Pending
				</Typography>
			)}

			{/*
			 * Pricing model and currency code
			 *
			 * Only show the pricing model and currency code if there is a pricing model
			 * There is no pricing model for phases when a firm
			 * previews an incomplete draft proposal
			 */}
			{!!totalPriceModel && (
				<Typography>
					{t(`pricingModels.firm.total.${totalPriceModel}`)}
					{/* Only show the currency if we are showing the price */}
					{currentValue && <span> | {currency}</span>}
				</Typography>
			)}
		</Cell>
	)
}

export default memo(TotalPriceCell)
