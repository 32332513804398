import { Fragment } from 'react'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import { format } from 'date-fns'
import { Typography } from '@persuit/ui-components'
import { RfpSystemClarification } from '../../../../types'

type CancelAuctionClarificationProps = {
	systemClarification?: RfpSystemClarification
}

export const CancelAuctionClarification = ({
	systemClarification,
}: CancelAuctionClarificationProps) => {
	const auctionStart = getOr('', 'auctionStart', systemClarification?.previousValues)
	const content = isEmpty(auctionStart)
		? 'The reverse auction has been cancelled'
		: `The reverse auction that was scheduled to start on ${format(
				new Date(auctionStart),
				'd LLLL yyyy, hh:mma',
		  )} has been cancelled`

	return (
		<Fragment>
			<Typography variant="h4">
				<b>Auction Cancelled</b>
			</Typography>
			<Typography variant="body2">{content}</Typography>
		</Fragment>
	)
}
