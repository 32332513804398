// @ts-strict-ignore
import { useState } from 'react'
import {
	ArrowDropDownIcon,
	ArrowDropUpIcon,
	Box,
	Button,
	InfoOutlinedIcon,
	Typography,
	useTheme,
	TextField,
	Divider,
	Dialog,
	PopoverHelp,
} from '@persuit/ui-components'
import { noop } from '@persuit/common-utils'
import { OverrideButton } from '../override-button'
import formatCurrency from '../../../../../utils/format-currency'
import { TOOLTIPS } from '../tooltips'
import currencies from '../../../../../../common/data/currencies'

type OverriddenSavingsProps = {
	note: string
	adjustedSavings: number
	adjustedFee: number
	persuitSavings: number
	currency: string
	bidTypeDescription: string
	onOverrideClick?: () => void
	onRevert?: () => unknown | Promise<unknown>
	onNotesChange?: (notes: string) => void
	neverHaveSavings: boolean
	rfpGrants: string[]
}

export const OverriddenSavings = ({
	note,
	adjustedFee,
	adjustedSavings,
	persuitSavings,
	currency,
	bidTypeDescription,
	onOverrideClick,
	onRevert = noop,
	onNotesChange = noop,
	neverHaveSavings,
	rfpGrants,
}: OverriddenSavingsProps) => {
	const theme = useTheme()
	const [showCalculation, setShowCalculation] = useState(false)
	const [revertDisabled, setRevertDisabled] = useState(false)
	const [showRevertDialog, setShowRevertDialog] = useState(false)
	const canEditRfpAnalytics = rfpGrants.includes('EDIT_ANALYTICS')

	const openRevertDialog = () => setShowRevertDialog(true)
	const closeRevertDialog = () => setShowRevertDialog(false)

	const baselineFee = adjustedSavings + adjustedFee

	return (
		<Box>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Box mt={1} display="flex" flexDirection="column" alignItems="center">
					<Button color="primary" onClick={() => setShowCalculation((show) => !show)}>
						Calculation {showCalculation ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</Button>

					{showCalculation ? (
						<Box display="flex" bgcolor={theme.palette.grey100} borderRadius="4px">
							<CalculationItem
								value={
									adjustedSavings === 0
										? `${currencies[currency].symbol}0 ${currency}`
										: formatCurrency(Math.round(adjustedSavings), currency)
								}
								label="Adjusted savings"
								tooltip={TOOLTIPS.adjustedSavings}
							/>

							<Box alignSelf="center">=</Box>

							<CalculationItem
								value={
									baselineFee === 0
										? `${currencies[currency].symbol}0 ${currency}`
										: formatCurrency(Math.round(baselineFee), currency)
								}
								label="Baseline fee"
								tooltip={TOOLTIPS.baselineFee(bidTypeDescription)}
							/>

							<Box alignSelf="center">-</Box>

							<CalculationItem
								value={
									adjustedFee === 0
										? `${currencies[currency].symbol}0 ${currency}`
										: formatCurrency(Math.round(adjustedFee), currency)
								}
								label="Adjusted agreed fee"
								tooltip={TOOLTIPS.adjustedAgreedFee}
							/>
						</Box>
					) : null}
				</Box>

				<OverrideButton
					onClick={onOverrideClick}
					neverHaveSavings={false}
					canEditRfpAnalytics={canEditRfpAnalytics}
				/>

				<Box>
					<Box mt={2} display="flex" alignItems="center">
						<TextField
							defaultValue={note}
							id="notes"
							label="Notes"
							multiline={true}
							size="small"
							style={{ minWidth: 500 }}
							variant="outlined"
							onChange={(e) => onNotesChange(e.target.value)}
							disabled={!canEditRfpAnalytics}
						/>

						<Box ml={2} display="flex" alignItems="center">
							<PopoverHelp
								content={TOOLTIPS.notes}
								triggerIcon={InfoOutlinedIcon}
								triggerButtonProps={{
									size: 'small',
									'aria-label': `Notes explanation`,
								}}
							/>
						</Box>
					</Box>

					<Box my={3}>
						<Divider />
					</Box>
				</Box>

				<Box display="flex" alignItems="center">
					{Boolean(persuitSavings) && (
						<Typography style={{ color: 'text.secondary' }}>
							Estimated Savings (before override): {formatCurrency(persuitSavings, currency)}
						</Typography>
					)}

					{canEditRfpAnalytics && (
						<Button color="primary" onClick={openRevertDialog} disabled={revertDisabled}>
							Revert
						</Button>
					)}

					<Dialog
						open={showRevertDialog}
						onClose={closeRevertDialog}
						size="sm"
						title={neverHaveSavings ? 'Revert to no savings' : 'Revert to estimated savings'}
						actions={[
							{ label: 'Cancel', variant: 'text', onClick: closeRevertDialog },
							{
								label: 'Revert',
								variant: 'primary',
								onClick: async () => {
									try {
										setRevertDisabled(true)
										await onRevert()
									} finally {
										setRevertDisabled(false)
										closeRevertDialog()
									}
								},
							},
						]}
					>
						<Typography>
							{neverHaveSavings
								? 'The entered savings and agreed fee will be cleared. This will also be updated in your reports and insights.'
								: `The adjusted savings and adjusted agreed fee will be replaced with the original
									data used to estimate savings. This will also be updated in your reports and
									insights.`}
						</Typography>
					</Dialog>
				</Box>
			</Box>
		</Box>
	)
}

type CalculationItemProps = {
	value: string
	label: string
	tooltip: string
}

const CalculationItem = ({ label, tooltip, value }: CalculationItemProps) => {
	return (
		<Box py={2} px={3} flexDirection="column" alignItems="center">
			<Typography variant="body1" align="center">
				{value}
			</Typography>

			<Box display="flex" justifyContent="center">
				<Typography variant="body2" style={{ color: 'text.secondary' }}>
					{label}
				</Typography>
				<Box ml={0.5} display="flex" alignItems="center">
					<PopoverHelp
						content={tooltip}
						triggerIcon={InfoOutlinedIcon}
						triggerButtonProps={{
							size: 'small',
							'aria-label': `${label} calculation explanation`,
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}
