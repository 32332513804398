import { Fragment } from 'react'
import { Typography, Box, PopoverHelp } from '@persuit/ui-components'
import { isEmpty } from 'lodash/fp'
import { requestValidForComparisonValue } from '../../../../../common-util'
import { Deliverable, PricingGroup } from '../../../../../types'

type ComparisonValueProps = {
	totalPriceRequired: boolean
	deliverables: Array<Deliverable | PricingGroup>
	isFirmView: boolean
}

export const ComparisonValue = ({
	totalPriceRequired,
	deliverables,
	isFirmView,
}: ComparisonValueProps) => {
	if (totalPriceRequired || isEmpty(deliverables)) return null
	if (!requestValidForComparisonValue(deliverables)) return null

	return isFirmView ? (
		<Fragment>
			<Box>
				<Box display="flex" alignItems="center">
					<header>
						<Typography variant="h3XSmall">Comparison value</Typography>
					</header>
					<PopoverHelp
						role="tooltip"
						ml={1}
						content={
							<div>
								<Typography variant="caption" sx={{ fontWeight: 'bold' }}>
									This request is using comparison value
								</Typography>
								<br />
								<Typography variant="caption">
									When the total price is not requested a comparison value will be auto-calculated.
									This is provided for comparative purposes only.
								</Typography>
							</div>
						}
						triggerButtonProps={{
							'aria-label': 'Comparison value help',
						}}
						triggerButtonSize="small"
					/>
				</Box>
				<Box role="contentinfo">
					<Typography variant="body1">
						This is the auto-sum of pricing items excluding hourly rates and percentage items. This
						will be used to provide a price ranking.
					</Typography>
				</Box>
			</Box>
		</Fragment>
	) : (
		<Fragment>
			<Typography variant="h3XSmall">Comparison value</Typography>
			<Typography variant="body1">
				When the total price is not requested a comparison value will be auto-calculated. This is
				provided for comparative purposes only.
			</Typography>
		</Fragment>
	)
}
