import { RefreshIcon, useSnackbar, Button } from '@persuit/ui-components'
import useTranslation from '../../custom-hooks/translation-hook'

function ReportRefreshButton({ onRefresh }) {
	const { t } = useTranslation()
	const { openSnackbar } = useSnackbar()

	return (
		<Button
			startIcon={<RefreshIcon color="primary" />}
			onClick={async () => {
				await onRefresh()
				openSnackbar(t('analytics.reports.refreshed'))
			}}
		>
			{t('analytics.refresh')}
		</Button>
	)
}

export default ReportRefreshButton
