import { useRef } from 'react'
import { useIsFirstRender } from './use-is-first-render'

export type UseHasChangedProps = {
	/** The value to monitor */
	value: unknown

	/**
	 * The value to return on the first render. Defaults to false, set this to true to consider the
	 * first render of a component as a change in the value
	 */
	initialReturnValue?: boolean
}

/**
 * A hook that returns true if a value has changed since the last time the component was rendered
 */
export function useHasChanged({ value, initialReturnValue = false }: UseHasChangedProps): boolean {
	const isFirstRender = useIsFirstRender()
	const lastValueRef = useRef(value)
	const changed = lastValueRef.current !== value

	lastValueRef.current = value

	if (isFirstRender) {
		return initialReturnValue
	} else {
		return changed
	}
}
