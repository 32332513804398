import { useMutation } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { isBefore } from 'date-fns'
import { useState } from 'react'
import { graphql } from '@persuit/ui-graphql'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import CheckIcon from '@mui/icons-material/Check'
import { trackWithPendo } from '../../../../track-event'
import eliminateActionTypes from '../../../../../common/data/eliminate-action-types'
import rfpStates from '../../../../../common/data/rfp-states'
import { beforeAuction, duringAuction } from '../../../../../common/auction-in-progress'
import Spacer from '../../../layout/spacer.jsx'
import DialogWizardConfirmationError from '../../dialog-wizard-confirmation-error'
import DialogWizardContent from '../../dialog-wizard-content'
import SummaryList from '../../dialog-wizard-list-summary'
import { defaultSteps } from '../../dialog-wizard-steps'
import Text from '../../../text.jsx'
import { grey500 } from '../../../../theme/persuit-colors'
import notificationOptions from './notification-option-enums'
import { stepIndex } from '../../utils'
import { useSnackbar, PopoverHelp } from '@persuit/ui-components'

export const ELIMINATE_FIRM_MUTATION = graphql(`
	mutation ManageProposal_EliminateFirm($payload: EliminateInput!) {
		eliminateFirm(payload: $payload) {
			_id
		}
	}
`)

export const ACCEPT_PROPOSAL_MUTATION = graphql(`
	mutation ManageProposal_AcceptResponse(
		$responseId: ID!
		$notificationOption: AcceptResponseNotificationOption!
	) {
		acceptResponse(responseId: $responseId, notificationOption: $notificationOption) {
			_id
		}
	}
`)

export const COMPLETE_RFP = graphql(`
	mutation ManageProposal_CompleteRequest($_id: ID!) {
		completeRfp(_id: $_id) {
			_id
			status
		}
	}
`)

const notificationOptionText = new Map()
	.set(notificationOptions.SEND_TO_NONE, 'Do not send notifications')
	.set(
		notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT,
		'Notify selected firm and remaining firm(s)',
	)
	.set(notificationOptions.SUCCESSFUL_FIRM_AND_CLIENT, 'Notify selected firm only')

const MarkCompleteRfpHelp = () => {
	return (
		<Box display="flex" flexDirection="column">
			<Text type="body1">Marking requests as completed ensures your analytics are accurate.</Text>
			<Text type="body1">Completed requests will not appear on your dashboard.</Text>
			<Text type="body1">
				You can revert this at any time by selecting "Mark as Evaluating" from the menu bar.
			</Text>
		</Box>
	)
}
const DeactivatedMarkCompleteRfpHelp = ({ text }) => {
	return (
		<Box display="flex" flexDirection="column">
			<Text type="body1">{text}</Text>
		</Box>
	)
}

const MarkCompleteRfpCheckbox = ({ isMarkRequestAsCompleteChecked, setMarkRequestAsComplete }) => {
	const handleChange = (e) => {
		setMarkRequestAsComplete(e.target.checked)
	}

	return (
		<Box display="flex" marginLeft="1em">
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={isMarkRequestAsCompleteChecked}
						onChange={handleChange}
						name="markCompleteRfp"
						inputProps={{ 'data-testid': 'mark-complete-rfp-checkbox' }}
					/>
				}
				label={'Mark request as completed'}
			/>
			<PopoverHelp
				triggerButtonProps={{ 'aria-label': 'More information on marking request as complete' }}
				content={<MarkCompleteRfpHelp />}
				alignVerticalCenter={false}
				inline={false}
				data-testid="mark-complete-rfp-checkbox-help"
			/>
		</Box>
	)
}

const RequestAlreadyComplete = () => (
	<Box display="flex" style={{ alignItems: 'center' }}>
		<CheckIcon style={{ fill: grey500 }} data-testid="mark-complete-rfp-already-complete" />
		<Spacer direction="row" />
		<Text grey={true}>This request has already been completed</Text>
		<PopoverHelp message={<MarkCompleteRfpHelp />} alignVerticalCenter={false} inline={false} />
	</Box>
)

const DeactivatedMarkComplete = ({ tooltip }) => (
	<Box display="flex">
		<FormControlLabel
			control={
				<Checkbox
					color="primary"
					checked={false}
					disabled={true}
					name="markCompleteRfp"
					inputProps={{ 'data-testid': 'mark-complete-rfp-checkbox-deactivated' }}
				/>
			}
			label={'Mark request as completed'}
		/>
		<PopoverHelp
			triggerButtonProps={{ 'aria-label': 'More information on marking request as complete' }}
			message={<DeactivatedMarkCompleteRfpHelp text={tooltip} />}
			alignVerticalCenter={false}
			inline={false}
			data-testid="mark-complete-rfp-checkbox-help"
		/>
	</Box>
)

const checkIsBeforeOrDuringAuction = ({ auction, proposalsDueBy, auctionEnd }) => {
	if (beforeAuction({ auction, proposalsDueBy, auctionEnd })) {
		return true
	}

	if (duringAuction({ auction, proposalsDueBy, auctionEnd })) {
		return true
	}

	return false
}

const markCompleteCheckboxStates = {
	COMPLETED: 'COMPLETED',
	COMPLETABLE: 'COMPLETABLE',
	AUCTION_DEACTIVE: 'AUCTION_DEACTIVE',
	NONAUCTION_DEACTIVE: 'NONAUCTION_DEACTIVE',
}

const getMarkCompleteCheckboxState = ({ requestStatus, auction, proposalsDueBy, auctionEnd }) => {
	if (requestStatus === rfpStates.COMPLETED) {
		return markCompleteCheckboxStates.COMPLETED
	}

	if (!auction && isBefore(new Date(), proposalsDueBy)) {
		return markCompleteCheckboxStates.NONAUCTION_DEACTIVE
	}

	if (auction && checkIsBeforeOrDuringAuction({ auction, proposalsDueBy, auctionEnd })) {
		return markCompleteCheckboxStates.AUCTION_DEACTIVE
	}

	return markCompleteCheckboxStates.COMPLETABLE
}

const MarkCompleteCheckboxRender = ({
	markCompleteCheckboxStates,
	markCompleteCheckboxState,
	isMarkRequestAsCompleteChecked,
	setMarkRequestAsComplete,
}) => {
	const { COMPLETED, COMPLETABLE, AUCTION_DEACTIVE, NONAUCTION_DEACTIVE } =
		markCompleteCheckboxStates

	switch (markCompleteCheckboxState) {
		// is completable
		case COMPLETABLE:
			return (
				<MarkCompleteRfpCheckbox
					isMarkRequestAsCompleteChecked={isMarkRequestAsCompleteChecked}
					setMarkRequestAsComplete={setMarkRequestAsComplete}
				/>
			)

		// already completed
		case COMPLETED:
			return <RequestAlreadyComplete />

		// deactive - before/during auction
		case AUCTION_DEACTIVE:
			return (
				<DeactivatedMarkComplete tooltip="Request can be marked completed once the reverse auction ends" />
			)

		// deactive - before proposals due by
		case NONAUCTION_DEACTIVE:
			return (
				<DeactivatedMarkComplete tooltip="Request can be marked completed once the proposal due date has passed" />
			)

		// show nothing
		default:
			break
	}
}

function OptionSelectFirmConfirmMarkComplete({
	onCancel,
	onDone,
	onPrev,
	firmId,
	proposalId,
	firmName,
	requestId,
	requestStatus,
	fetchResponses,
	openProposalFeedbackFormAll = null,
	openProposalFeedbackFormSingleFirm = null,
	auction,
	proposalsDueBy,
	auctionEnd,
	value: { notificationOption },
}) {
	const markCompleteCheckboxState = getMarkCompleteCheckboxState({
		requestStatus,
		auction,
		proposalsDueBy,
		auctionEnd,
	})
	const [isMarkRequestAsCompleteChecked, setMarkRequestAsComplete] = useState(
		markCompleteCheckboxState === markCompleteCheckboxStates.COMPLETABLE,
	)
	const [acceptResponse, { loading: loadingAcceptMutation, error: errorAcceptMutation }] =
		useMutation(ACCEPT_PROPOSAL_MUTATION)
	const [eliminateFirm, { loading: loadingEliminateMutation, error: errorEliminateMutation }] =
		useMutation(ELIMINATE_FIRM_MUTATION)
	const [markCompleteRfp] = useMutation(COMPLETE_RFP)
	const { openSnackbar } = useSnackbar()
	const eliminateOtherFirms = async () => {
		await eliminateFirm({
			variables: {
				payload: {
					requestId,
					orgIds: [firmId],
					action: eliminateActionTypes.ELIMINATE_ALL_EXCEPT,
					sendFirmEmails: true,
				},
			},
		})
		openSnackbar('Remaining firms marked as unsuccessful')
	}

	const selectProposal = async () => {
		await acceptResponse({
			variables: { responseId: proposalId, notificationOption },
		}),
			openSnackbar(`Selected ${firmName}`)
	}

	const markCompleteRFP = async () => {
		await markCompleteRfp({
			variables: { _id: requestId },
		})

		openSnackbar(`Request has been marked as completed`)
	}

	return (
		<DialogWizardContent
			steps={defaultSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(defaultSteps, 'START')]: 'Select firm',
				[stepIndex(defaultSteps, 'ACTIONS')]: notificationOptionText.get(notificationOption),
			}}
			isWorking={loadingAcceptMutation || loadingEliminateMutation}
			onConfirm={async () => {
				if (
					notificationOption === notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT
				) {
					// SM: Wrapping these 2 mutations in a single promise seems to prevent the page from reloading
					// Needed for the proposal feedback dialog to launch
					await Promise.all([selectProposal(), eliminateOtherFirms()])
					onDone()

					if (isMarkRequestAsCompleteChecked) {
						await markCompleteRFP()
					}

					trackWithPendo('manage_proposal::mark_firm_successful_eliminate_others', {
						firmId,
						proposalId,
						notificationOption,
						isRfpMarkedComplete: isMarkRequestAsCompleteChecked,
					})
					await fetchResponses() // Reload value from resolver

					if (openProposalFeedbackFormAll) {
						openProposalFeedbackFormAll()
					}
				} else {
					//Select single proposal or Don't send notifications
					await selectProposal()
					onDone()

					trackWithPendo('manage_proposal::mark_firm_successful', {
						firmId,
						proposalId,
						notificationOption,
						isRfpMarkedComplete: isMarkRequestAsCompleteChecked,
					})
					await fetchResponses() // Reload value from resolver

					if (openProposalFeedbackFormSingleFirm) {
						openProposalFeedbackFormSingleFirm()
					}

					// NOTE: This condition was moved from after onDone to here because the feeddback dialog was not being rendered as the context of the dialog got lost due to re-render of the GQL call
					if (isMarkRequestAsCompleteChecked) {
						await markCompleteRFP()
					}
				}
			}}
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<DialogWizardConfirmationError error={errorAcceptMutation || errorEliminateMutation} />
			{notificationOption === notificationOptions.SUCCESSFUL_AND_UNSUCCESSFUL_FIRM_AND_CLIENT && (
				<SummaryList
					primaryText={<strong>Select firm and send notifications</strong>}
					bullets={[
						'Selected firm will be notified.',
						'Remaining firms will be marked as unsuccessful and notified of this.',
						'You will be unable to select additional firms.',
						'You will be able to provide feedback to your firm(s) after confirming',
					]}
					icon={null}
					dataTestid="notify-other-firms-confirm"
				/>
			)}
			{notificationOption === notificationOptions.SUCCESSFUL_FIRM_AND_CLIENT && (
				<SummaryList
					primaryText={<strong>Select firm and send notification</strong>}
					bullets={[
						'Remaining firms will not be notified.',
						'You will still be able to select additional firms.',
						'You will be able to provide feedback to your firm after confirming',
					]}
					icon={null}
					dataTestid="dont-notify-other-firms-confirm"
				/>
			)}
			{notificationOption === notificationOptions.SEND_TO_NONE && (
				<SummaryList
					primaryText={<strong>Select firm without sending notifications</strong>}
					bullets={[
						'Selected firm will not be notified. The firm’s dashboard will have an indication that the proposal has been selected.',
						'Remaining firm(s) will not be notified.',
						'You will still be able to select additional firms.',
						'You will be able to provide feedback to your firm after confirming',
					]}
					icon={null}
					dataTestid="dont-notify-confirm"
				/>
			)}

			<MarkCompleteCheckboxRender
				markCompleteCheckboxStates={markCompleteCheckboxStates}
				markCompleteCheckboxState={markCompleteCheckboxState}
				isMarkRequestAsCompleteChecked={isMarkRequestAsCompleteChecked}
				setMarkRequestAsComplete={setMarkRequestAsComplete}
			/>
			<Spacer />
		</DialogWizardContent>
	)
}

export default OptionSelectFirmConfirmMarkComplete
