import { usePricingListContext } from '../../../pricing-list-context'
import { Box, FormRichTextEditor } from '@persuit/ui-components'
import { PricingItemFieldPath } from '../../../pricing-form-utils'

type PricingItemFooterProps = {
	id: string
	fieldPath: PricingItemFieldPath
}

export const PricingItemFooter = ({ id, fieldPath }: PricingItemFooterProps) => {
	const { errors, getPricingItemIndex } = usePricingListContext()
	const pricingItemErrors =
		errors && errors.pricingItemErrors?.find(({ index }) => index === getPricingItemIndex(id))

	const instructionsError = pricingItemErrors?.deliverable ?? ''

	return (
		// Adding key prop to force TinyMCE to remount when fieldPath change,
		// otherwise it will lose reference to its iframe and make the editor not functional
		// See https://github.com/tinymce/tinymce-react/issues/89#issuecomment-1377030449
		<Box m="2.5em" data-testid="item-footer-container" key={`${id}-${fieldPath}`}>
			<Box m="3.8em 0 2.2em" data-testid="pricing-item-instructions">
				<FormRichTextEditor
					id={`instructions-${id}`}
					name={`${fieldPath}.deliverable`}
					label="Instructions and assumptions"
					height={200}
					error={!!instructionsError}
					helperText={instructionsError}
					containerProps={{
						'data-testid': `pricing-item-instructions-${id}`,
					}}
				/>
			</Box>
		</Box>
	)
}
