import DialogWizardContent from '../../../dialog-wizard-content'
import { stepIndex } from '../../../utils'
import {
	ProposalQuickRevisionConfirmForm,
	useProposalQuickRevisionStore,
} from '@persuit/ui-shared-components'
import { graphql, useMutation } from '@persuit/ui-graphql'

import { useSnackbar } from '@persuit/ui-components'
import { logger } from '@persuit/ui-logger'
import PROPOSAL_PAGES from '../../../../../../common/proposals/pages-viewmode'

const SUBMIT_QUICK_PRICE_REVISION = graphql(`
	mutation OptionQuickPriceRevisionConfirm_RfpProposalQuickPriceRevision(
		$payload: RfpProposalQuickPriceRevisionInput!
	) {
		rfpProposalQuickPriceRevision(payload: $payload) {
			_id
		}
	}
`)

type OptionQuickPriceRevisionConfirmProps = {
	onPrev?: () => void
	onCancel?: () => void
	onDone?: () => void
	navigateToPage: (page: string, params?: any) => void
}

export const OptionQuickPriceRevisionConfirm = ({
	onPrev,
	onCancel,
	onDone,
	navigateToPage,
}: OptionQuickPriceRevisionConfirmProps) => {
	const {
		proposal,
		currentTotalPriceValue,
		pricingItemFormState,
		rateItemFormState,
		rateItemLookup,
		wizardSteps,
		noChangesMade,
	} = useProposalQuickRevisionStore(
		(
			{ pricingItemFormState, rateItemFormState, proposal, deliverables, rateItemLookup },
			selectors,
		) => ({
			pricingItemFormState,
			rateItemFormState,
			proposal,
			currentTotalPriceValue: selectors.currentTotalPriceValue(),
			deliverables,
			rateItemLookup,
			wizardSteps: selectors.getWizardStepDetails(),
			noChangesMade: !selectors.changesMade(),
		}),
	)

	const [submitQuickPriceRevision, { loading, error }] = useMutation(SUBMIT_QUICK_PRICE_REVISION)

	const { openSnackbar } = useSnackbar()

	return (
		<DialogWizardContent
			steps={wizardSteps}
			activeStep={'CONFIRM'}
			secondaryTextForSteps={{
				[stepIndex(wizardSteps, 'START')]: 'Quick price revision',
			}}
			disableConfirmButton={noChangesMade || loading}
			onConfirm={async () => {
				if (proposal?._id) {
					try {
						const formResponse = getFormResponse()
						const { data } = await submitQuickPriceRevision({
							variables: {
								payload: {
									_id: proposal._id,
									totalPriceValue: currentTotalPriceValue,
									responseToDeliverables: formResponse,
								},
							},
						})

						const newProposalId = data?.rfpProposalQuickPriceRevision?._id

						openSnackbar('Successfully submitted quick price change')

						onDone?.()
						if (newProposalId) {
							navigateToPage?.(PROPOSAL_PAGES.PROPOSAL_PREVIEW_DETAIL, {
								proposalId: newProposalId,
							})
						} else {
							navigateToPage?.(PROPOSAL_PAGES.VIEW_REQUEST)
						}
					} catch (e) {
						logger.error(e)
					}
				}
			}}
			confirmDataTrackId="quick-price-revision-confirm"
			onCancel={onCancel}
			onPrev={onPrev}
		>
			<ProposalQuickRevisionConfirmForm error={error} noChangesMade={noChangesMade} />
		</DialogWizardContent>
	)

	type FormResponseItem = {
		deliverableId?: string
		firmSuppliedRateId?: string
		price?: number
		percentage?: number
		responseToRateId?: string
	}

	function getFormResponse(): FormResponseItem[] {
		const pricingFormResponse = Object.entries(pricingItemFormState).map(([key, value]) => ({
			deliverableId: key,
			price: value,
		}))

		const rateFormResponse = Object.entries(rateItemFormState).reduce<FormResponseItem[]>(
			(acc, [key, value]) => {
				const item = rateItemLookup[key]
				if (item?.type === 'rate') {
					return [
						...acc,
						{
							price: value,
							responseToRateId: key,
						},
					]
				}

				if (item?.type === 'firmSuppliedRate') {
					return [
						...acc,
						{
							price: value,
							firmSuppliedRateId: key,
						},
					]
				}

				if (item?.pricingModel === 'hourlyRate') {
					return [
						...acc,
						{
							price: value,
							deliverableId: key,
						},
					]
				}

				if (['contingencyPercentage', 'discountPercentage'].includes(item?.pricingModel)) {
					return [
						...acc,
						{
							percentage: value,
							deliverableId: key,
						},
					]
				}

				return acc
			},
			[],
		)

		const formResponse = [...pricingFormResponse, ...rateFormResponse].filter(
			(value) => value.deliverableId !== 'totalPriceValue',
		)

		return formResponse
	}
}
