import isEmpty from 'lodash/isEmpty'

import proposalStates from './data/rfp-proposal-states'

export default function countProposals(proposals) {
	// Bail out early if there is nothing to count
	if (!Array.isArray(proposals)) {
		return 0
	}

	const countableProposals = proposals
		// Check for any malformed or empty proposal objects. This can happen if the
		// aggregation to fetch the proposals does not find any proposals, e.g. it can return
		// [{}].
		.filter((proposal) => !isEmpty(proposal))
		.filter((proposal) => {
			// Only count the following proposal states (applies to both eliminated and
			// non-eliminated firms):
			// 	✘ DRAFT proposal
			// 	✘ WITHDRAWN proposal
			// 	✔ FINALIZED/submitted proposal
			// 	✔ UNDER REVISION proposal
			// 	✘ REVISED proposal
			// 	✔ ACCEPTED proposal
			return (
				proposal.status === proposalStates.FINALIZED ||
				proposal.status === proposalStates.ACCEPTED ||
				proposal.status === proposalStates.UNDER_REVISION
			)
		})

	return countableProposals.length
}
