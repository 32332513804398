import { useState } from 'react'
import * as React from 'react'
import { groupBy, mapValues } from 'lodash'
import Fuse from 'fuse.js'

import useTranslation from '../../../custom-hooks/translation-hook'
import { refocusBeforeRemoval } from '@persuit/ui-utils'

import {
	Box,
	List,
	TextField,
	Paper,
	ClearIcon,
	SearchIcon,
	IconButton,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	useSnackbar,
} from '@persuit/ui-components'

import { Chip } from '../chip'
import NamwolfLogo from '../../../components/namwolf/namwolf-logo'
import { PanelPreferredFirmLogo } from '../../../components/panel-preferred-firm/panel-preferred-firm-logo'
import { ApprovedFirmLogo } from '../../../components/approved-firm/approved-firm-logo'
import { generatePanelApprovedFirmSets, filterGroupPanels } from './helpers'
import { FirmLists } from './firm-lists'
import { PeopleSearchListItem } from './people-search-list-item'

// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
const HIGHLIGHT_COLOR = '#FFFF00' // Yellow
// set this explicity for parent container and direct children (bugfix for IE)
const MAX_FILTER_CONTAINER_HEIGHT = '600px'

type Person = {
	_id: string
	email?: string | null
	isFavourite?: boolean | null
	name: {
		first: string
		last: string
	}
	org: {
		_id: string
		name: string
		isNamwolfMember: boolean
	}
}

export type Group = {
	_id: string
	name: string
}

type PanelFirmTag = {
	firmId: string
	groupId: string
	type: string
}

export type Org = {
	_id: string
	groups: Group[]
	panelFirmTags: PanelFirmTag[]
}

export type PeopleSearchProps = {
	/** List of ids of people already added */
	added?: string[]
	/** List of  */
	data: Array<Person>

	addToFavourites?: (userId: string) => void
	deselectUserId?: (id: string) => void
	selectUserId?: (id: string) => void
	onChange?: (ids: string[]) => void
	defaultGroupFilter?: string
	userOrg: Org
}

export const PeopleSearch = ({
	added = [],
	addToFavourites,
	data,
	deselectUserId,
	selectUserId,
	onChange,
	defaultGroupFilter = 'all',
	userOrg,
}: PeopleSearchProps) => {
	const { t } = useTranslation()
	const { openSnackbar } = useSnackbar()
	const [selectedFirmId, setSelectedFirmId] = useState('')
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedGroup, setSelectedGroup] = useState(defaultGroupFilter)
	const [pending, _setPending] = useState<Record<string, boolean>>({})
	const setPending = (update: Parameters<typeof _setPending>[0]) => {
		const values = typeof update === 'function' ? update(pending) : update
		onChange?.(Object.keys(values))
		_setPending(update)
	}

	const panelFirmTags = userOrg.panelFirmTags
	const groups = userOrg.groups

	const groupPanelTags = filterGroupPanels(selectedGroup, panelFirmTags)

	const { panelOrPreferredFirms, approvedFirms: approvedFirmsSet } =
		generatePanelApprovedFirmSets(groupPanelTags)

	const selectUser = (id: string) => {
		setPending({
			...pending,
			[id]: true,
		})
		if (selectUserId) {
			selectUserId(id)
		}
	}

	const deselectUser = (id: string) => {
		delete pending[id]
		setPending(pending)
		if (deselectUserId) {
			deselectUserId(id)
		}
		openSnackbar('Contact unselected')
	}

	const firms = Object.values(
		mapValues(
			groupBy(data, (p) => p.org._id),
			(orgMembers) => ({ ...orgMembers[0].org, memberCount: orgMembers.length }),
		),
	)
	const otherFirms = firms.filter(
		(f) => !approvedFirmsSet.has(f._id) && !approvedFirmsSet.has(f._id) && !f.isNamwolfMember,
	)
	const namwolfFirms = firms.filter((f) => f.isNamwolfMember)
	const selectedFirm = firms.find((firm) => firm._id === selectedFirmId)

	const requestPeopleHelp = (
		<Typography variant="body2">
			Only people registered on PERSUIT will appear in this list. If the person you searched for
			cannot be found you can enter their email address in the 'To' field of the Request.
		</Typography>
	)

	const searchResults = searchFirms(data, { searchTerm, firmId: selectedFirmId })
	const hasSearchResults = searchResults.length > 0
	const showGroupSelection = groups.length > 1

	const noSelections = !Object.keys(pending).length

	return (
		<Box>
			<Box
				sx={{
					mb: 2,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-end',
					justifyContent: 'space-between',
				}}
			>
				<Box display="flex" alignItems="flex-end" sx={{ flexGrow: 2, mr: '2em' }}>
					<SearchIcon sx={{ mr: 0.5, mb: 0.5 }} />
					<TextField
						id="search-by-name"
						data-testid="search-by-name"
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
						label={t('peopleSearch.searchFieldLabel')}
						fullWidth={true}
						InputProps={{
							endAdornment: searchTerm ? (
								<IconButton
									onClick={() => setSearchTerm('')}
									size="small"
									aria-label="clear search"
								>
									<ClearIcon color="primary" />
								</IconButton>
							) : null,
						}}
					/>
				</Box>

				{/* show groups selection if this org has more than 1 group */}

				{showGroupSelection && (
					<Box sx={{ flexGrow: 1 }}>
						<FormControl fullWidth={true}>
							<InputLabel id="group-select-label">Group</InputLabel>
							<Select
								id="group-select"
								labelId="group-select-label"
								value={selectedGroup}
								label="Group"
								placeholder="Group"
								onChange={(e) => setSelectedGroup(e.target.value)}
							>
								<MenuItem value="all">All</MenuItem>
								{groups.map((group) => (
									<MenuItem key={group._id} value={group._id}>
										{group.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				)}
			</Box>

			<Paper>
				<Box
					sx={{
						display: 'flex',
						maxHeight: MAX_FILTER_CONTAINER_HEIGHT,
					}}
				>
					<Box
						sx={{
							display: 'block',
							maxWidth: '440px',
							overflowY: 'scroll',
							flexGrow: 1,
							maxHeight: MAX_FILTER_CONTAINER_HEIGHT,
							'@media (max-width: 600px)': {
								display: 'none',
							},
						}}
					>
						<FirmLists
							namwolfFirms={namwolfFirms}
							otherFirms={otherFirms}
							selectedFirmId={selectedFirmId}
							onSelectFirm={(firmId) => setSelectedFirmId(firmId)}
							onClearFirm={() => setSelectedFirmId('')}
						/>
					</Box>

					<Box
						sx={{
							flexGrow: 2,
							overflowY: 'scroll',
							maxHeight: MAX_FILTER_CONTAINER_HEIGHT,
						}}
					>
						{!hasSearchResults ? (
							<Box p={2}>
								<Typography
									sx={{ mb: 1 }}
									color="text.secondary"
									fontStyle="italic"
									aria-live="polite"
								>
									No matches found{searchTerm ? ` for "${searchTerm}"` : null}
									{selectedFirm ? ` within ${selectedFirm.name}` : null}
								</Typography>
								{requestPeopleHelp}
							</Box>
						) : (
							<>
								{searchResults.length > 0 && (
									<List aria-label="Firm contacts">
										{searchResults.map((item) => (
											<PeopleSearchListItem
												key={item._id}
												item={item}
												previouslySelected={added.includes(item._id)}
												selected={pending[item._id]}
												isPanelFirm={panelOrPreferredFirms.has(item.org.name)}
												isApprovedFirm={approvedFirmsSet.has(item.org.name)}
												addToFavourites={addToFavourites}
												onSelect={(userId) => selectUser(userId)}
												onRemove={(userId) => deselectUser(userId)}
											/>
										))}
									</List>
								)}
								<Typography sx={{ px: 2 }} color="text.secondary">
									{requestPeopleHelp}
								</Typography>
							</>
						)}
					</Box>
				</Box>
			</Paper>

			<Paper sx={{ py: 1, px: 2, mt: 2 }}>
				<Typography variant="body1Semibold" gutterBottom={true}>
					People selected
				</Typography>

				<Box
					sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}
					role={noSelections ? 'presentation' : 'list'}
					id="people-search-chip-list"
					aria-label={noSelections ? '' : 'Selected firm contacts'}
					tabIndex={-1}
				>
					{!!data.length &&
						data
							.filter((item) => pending[item._id])
							.map((item, index) => {
								const isApprovedFirm = approvedFirmsSet.has(item.org._id)
								const isPanelOrPreferredFirm = panelOrPreferredFirms.has(item.org._id)
								return (
									<Box key={item._id} role="listitem">
										<Chip
											chipProps={{
												id: `firm-chip-${index}`,
											}}
											onDelete={() => {
												refocusBeforeRemoval({
													currentIndex: index,
													fallback: document.getElementById('people-search-chip-list'),
													getItemByIndex: (index) => document.getElementById(`firm-chip-${index}`),
												})
												deselectUser(item._id)
											}}
											primaryText={`${item.name.first} ${item.name.last}`}
											secondaryText={item.org.name}
											isFavourite={!!item.isFavourite}
											userId={item._id}
											hideStar={false}
											isApprovedFirm={isApprovedFirm}
											isPanelOrPreferredFirm={isPanelOrPreferredFirm}
											icon={
												<>
													{isApprovedFirm && <ApprovedFirmLogo />}
													{isPanelOrPreferredFirm && <PanelPreferredFirmLogo />}
													{item.org.isNamwolfMember && <NamwolfLogo />}
												</>
											}
										/>
									</Box>
								)
							})}
					{noSelections && (
						<Typography variant="caption" color="text.secondary" sx={{ py: 1 }}>
							Please select at least one person to add.
						</Typography>
					)}
				</Box>
			</Paper>
		</Box>
	)
}

type SearchResult = Omit<Person, 'name'> & {
	name: string
	nameHighlight: React.ReactNode
	orgNameHighlight: React.ReactNode
}

type SearchPeopleInput = {
	searchTerm: string
	firmId: string
}

const searchFirms = (data: Person[], { searchTerm, firmId }: SearchPeopleInput): SearchResult[] => {
	const mappedData = data
		.map((item) => ({
			...item,
			name: `${item.name.first} ${item.name.last}`,
		}))
		.sort((a, b) =>
			a.org.name.toLowerCase() > b.org.name.toLowerCase()
				? 1
				: a.org.name.toLowerCase() < b.org.name.toLowerCase()
				? -1
				: a.name > b.name
				? 1
				: a.name < b.name
				? -1
				: 0,
		)
		.sort((a, b) => (a.isFavourite ? (b.isFavourite ? 0 : -1) : b.isFavourite ? 1 : 0))

	const filteredFirms = mappedData.filter((item) => !firmId || (firmId && item.org._id === firmId))

	if (!searchTerm.trim()) {
		return filteredFirms.map((f) => ({
			...f,
			nameHighlight: f.name,
			orgNameHighlight: f.org.name,
		}))
	}

	const fuse = new Fuse(filteredFirms, {
		includeMatches: true,
		findAllMatches: true,
		shouldSort: true,
		threshold: 0,
		ignoreLocation: true,
		keys: ['name', 'org.name'],
	})
	const searchResults = fuse.search(searchTerm.trim())

	type Match = {
		key?: string
		value?: string
		indices: readonly (readonly [number, number])[]
	}

	function getHighlight(match: Match | undefined, input: string): React.ReactNode {
		if (!match) return input

		const { indices, key } = match

		if (indices.length === 0 || !key) return input

		return (
			<Box component="span" color="text.secondary">
				{indices.map((location, index, array) => {
					const start = location[0]
					const end = location[1]
					const prevEnd = array[index - 1]?.[1]
					const first = index === 0
					const last = index === array.length - 1

					return (
						<>
							{input.slice(first ? 0 : prevEnd + 1, start)}
							<span style={{ color: 'black', backgroundColor: HIGHLIGHT_COLOR }}>
								{input.slice(start, end + 1)}
							</span>
							{last && input.slice(end + 1)}
						</>
					)
				})}
			</Box>
		)
	}

	// Process the Fuse matches and format the matches with highlights
	return searchResults.map((result) => {
		const matches = result.matches ?? []
		const orgNameMatch = matches.find((match) => match.key === 'orgName')
		const nameMatch = matches.find((match) => match.key === 'name')

		return {
			...result.item,
			orgNameHighlight: getHighlight(orgNameMatch, result.item.org.name),
			nameHighlight: getHighlight(nameMatch, result.item.name),
		}
	})
}
