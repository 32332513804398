import { Box, Spacer, Form } from '@persuit/ui-components'
import { map, omit } from 'lodash/fp'
import { useSectionalForm, SectionalFormContainer } from '../../sectional-form'
import { RfpQuestion, RfpQuestionError, RfpQuestionGroup, RfpQuestionGroupError } from '../../types'
import { QuestionList } from './question-list'

type QuestionFormProps = {
	questionData: RfpQuestion[]
	saveQuestions: (args: {
		questions: RfpQuestion[]
		questionGroups: RfpQuestionGroup[]
	}) => Promise<void>
	questionErrors: RfpQuestionError[] | null
	questionGroupData: RfpQuestionGroup[]
	questionGroupErrors: RfpQuestionGroupError[] | null
}

export const QuestionForm = ({
	questionData,
	questionGroupData,
	saveQuestions,
	questionErrors,
	questionGroupErrors,
}: QuestionFormProps) => {
	const methods = useSectionalForm(
		{ questions: questionData, questionGroups: questionGroupData },
		async (values) => {
			const purifiedQuestions = map((question: RfpQuestion) => {
				const updatedQuestion = omit(['__typename', 'id', '_id', 'isNewQuestion'], question)
				const options = question?.options ?? []
				const updatedOptions = map(omit(['__typename']), options)
				return {
					...updatedQuestion,
					options: updatedOptions,
				}
			}, values.questions) as RfpQuestion[]

			const purifiedQuestionGroups = map((questionGroup) => {
				const updatedQuestionGroup = omit(
					['__typename', 'id', 'isNewGroup'],
					questionGroup,
				) as RfpQuestionGroup

				return updatedQuestionGroup
			}, values.questionGroups)

			await saveQuestions({
				questions: purifiedQuestions,
				questionGroups: purifiedQuestionGroups,
			})
		},
	)

	return (
		<SectionalFormContainer label="Question section" focusOnMount={true} padding={0}>
			<Form methods={methods}>
				<Box display="flex" flexDirection="column">
					<QuestionList questionErrors={questionErrors} questionGroupErrors={questionGroupErrors} />
					<Spacer space={1} />
				</Box>
			</Form>
		</SectionalFormContainer>
	)
}
