/**
 * Return the allow type string or null if the user isn't invited

 * @param {string|Object}user - User ID or user object (normalized or denormalized)
 * @param {Object} resource - Normalized or denormalized resource
 */
export default function getAllowType(user, resource) {
	if (!resource || !resource.allow || !user) {
		return null
	}

	const userId = user.hasOwnProperty('_id') ? user._id.toString() : user.toString()

	let userOrgId = null
	if (user.org && user.org.hasOwnProperty('_id')) {
		userOrgId = user.org._id.toString()
	} else if (user.org) {
		userOrgId = user.org.toString()
	}

	/*
	 * Iterate over each value in the allow array
	 *
	 * .find() returns true if one of the following is in the allow array checks passes:
	 *  - Users own ID is present
	 *  - Users org ID is present
	 */
	const allow = resource.allow.find((data) => {
		if (!data.item) {
			return false
		}

		const itemId = data.item.hasOwnProperty('_id') ? data.item._id.toString() : data.item.toString()

		if (userOrgId && itemId === userOrgId && data.kind === 'Org') {
			return true
		}

		if (userId && itemId === userId && data.kind === 'User') {
			return true
		}

		return false
	})

	// If present return the allow type
	if (allow && allow.type) {
		return allow.type
	}

	return null
}
