// @ts-strict-ignore
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash/fp'
import { isNotNil } from '@persuit/common-utils'
import { useDispatch } from '@client/store'
import { Box, Divider, Avatar, Button, Spacer, Typography, Popover } from '@persuit/ui-components'

import { authActions } from '@client/actions'
import NamwolfLogo from '@client/components/namwolf/namwolf-logo'

export type ProfileProps = {
	firstName: string
	groupRoles: string[]
	initials: string
	isImpersonated: boolean
	isNamwolfMember: boolean
	lastName: string
	orgName: string
	orgRoles: string[]
	userEmail: string
	userGroupName: string
	userTeamName: string
}

type MyProfileMenuProps = {
	profileProps: ProfileProps
}

export const MyProfileMenu = ({ profileProps }: MyProfileMenuProps) => {
	const {
		initials,
		firstName,
		lastName,
		orgName,
		userEmail,
		isNamwolfMember,
		orgRoles,
		groupRoles,
		userGroupName,
		userTeamName,
	} = profileProps

	const dispatch = useDispatch()

	const [userMenuIsOpen, setUserMenuIsOpen] = useState(false)
	const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLButtonElement | null>(null)

	const closeUserMenu = () => {
		setUserMenuIsOpen(false)
		setUserMenuAnchor(null)
	}

	const orgRoleNames = {
		TEMPLATE_MANAGER: 'Template manager',
	}
	const groupRoleNames = {
		MANAGER: 'Group manager',
		INSIGHTS: 'Insights',
		API: 'Reporting API',
	}

	const combinedRoleNames = [
		...orgRoles.filter((r) => orgRoleNames[r]).map((r) => orgRoleNames[r]),
		...groupRoles.filter((r) => groupRoleNames[r]).map((r) => groupRoleNames[r]),
	]

	const renderAvatar = (size: 'small' | 'large') => {
		const small = size === 'small'
		return (
			<Avatar
				style={{
					height: small ? 30 : 60,
					width: small ? 30 : 60,
					fontSize: small ? 15 : 30,
					border: small ? `2px solid white` : `3px solid black`,
					background: 'transparent',
					color: small ? 'white' : 'black',
				}}
			>
				{initials}
			</Avatar>
		)
	}

	return (
		<>
			<Button
				aria-label="profile menu"
				onClick={(evt) => {
					setUserMenuIsOpen((prev) => !prev)
					setUserMenuAnchor(evt.currentTarget)
				}}
			>
				{renderAvatar('small')}
			</Button>

			<Popover
				open={userMenuIsOpen}
				anchorEl={userMenuAnchor}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				onClose={closeUserMenu}
			>
				<Box width="320px">
					<Box display="flex" alignItems="center" px={2} py={1.5}>
						<Typography>{orgName}</Typography>

						{isNamwolfMember ? (
							<>
								<Spacer shape="column" space={0.5} />
								<NamwolfLogo />
							</>
						) : null}
					</Box>

					<Divider style={{ marginTop: '0px', marginBottom: '10px' }} />

					<Box display="flex" alignItems="center" pl={2} pt={1} mb={2}>
						{renderAvatar('large')}

						<Box pl="20px">
							<Typography variant="body1">
								{[firstName, lastName].filter(isNotNil).join(' ')}
							</Typography>
							<Typography variant="body1">{userEmail}</Typography>
						</Box>
					</Box>

					<Box pl={2} mb={2}>
						<Typography>{userGroupName}</Typography>
						<Typography>{userTeamName}</Typography>
						{!isEmpty(combinedRoleNames) && (
							<ul>
								{combinedRoleNames.map((roleName) => (
									<li key={roleName}>
										<Typography>{roleName}</Typography>
									</li>
								))}
							</ul>
						)}
					</Box>

					<Divider />

					<Box px={2} py={1.5} display="flex" justifyContent="space-around">
						<Button
							component={Link}
							to="/en/myprofile/"
							color="primary"
							variant="contained"
							onClick={closeUserMenu}
						>
							My Profile
						</Button>
						{/* Aria label required on logout button to prevent screen readers spelling out button name */}
						<Button
							variant="outlined"
							color="primary"
							onClick={() => dispatch(authActions.logout()).catch(console.error)}
							aria-label="Log Out"
						>
							Log Out
						</Button>
					</Box>
				</Box>
			</Popover>
		</>
	)
}
