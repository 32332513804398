import axios from '../axios'
import makeHttpError from '../utils/make-http-error'

import languageActions from './language'

const AUTH_ERROR = 'AUTH_ERROR'
const AUTH_RECEIVED = 'AUTH_RECEIVED'
const AUTH_REQUEST = 'AUTH_REQUEST'
const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT'
const SET_AUTH_SCOPE = 'SET_AUTH_SCOPE'
const SET_USER = 'SET_USER'
const UPDATE_USER_ORG = 'UPDATE_USER_ORG'
const UPDATE_USER_FAVOURITES = 'UPDATE_USER_FAVOURITES'

function setAuthScope(scope) {
	return {
		type: SET_AUTH_SCOPE,
		scope,
	}
}

function setUser(user) {
	return (dispatch) => {
		if (!user) {
			return
		}

		return Promise.all([
			dispatch(setAuthScope(user.scope)),
			dispatch({
				type: SET_USER,
				user,
			}),
		])
	}
}

function authRequest() {
	return {
		type: AUTH_REQUEST,
	}
}

function authReceived() {
	return {
		type: AUTH_RECEIVED,
	}
}

function authError(err) {
	return {
		type: AUTH_ERROR,
		error: err,
	}
}

function passwordResetSent() {
	return {
		type: PASSWORD_RESET_SENT,
	}
}

function authenticate({ email, password, recaptchaToken, sharetoken, hcaptchaToken }) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/login/', { email, password, recaptchaToken, sharetoken, hcaptchaToken })
			.then((res) => {
				// If the user is an admin then redirect to the /admin/ pages
				if (res.data.user.roles.find((role) => role === 'admin')) {
					window.location.replace('/admin/')
				}

				dispatch(authReceived())

				dispatch(setUser(res.data.user))

				return {
					nextLocation: res.data.nextLocation,
					// Since /external-api/ doesn't live under the react app, need to
					// tell the client not to redirect using react-router
					fullReload: res.data.fullReload,
				}
			})
			.catch((err) => {
				const error = err.response ? makeHttpError(err.response) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function verifyUsername(email, sharetoken) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/login/check', { email, sharetoken })
			.then(({ data }) => data)
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function logout() {
	return () =>
		axios.post('/user/logout/').then((result) => {
			for (const key in window.sessionStorage) {
				delete window.sessionStorage[key]
			}

			const redirectTo = result?.data?.ssoLogoutUrl
			if (redirectTo) {
				window.location.href = redirectTo
				return
			}

			window.location.reload()
		})
}

function changePassword(newPassword, token = null) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/set-password/', { password: newPassword, token })
			.then(() => {
				dispatch(authReceived())
				window.location.replace('/user/login/')
			})
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function requestPasswordChangeForCurrentUser() {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/request-password-change/')
			.then(() => {
				dispatch(authReceived())
				return null
			})
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function requestPasswordChangeForEmail(email) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/recover-password/', { email })
			.then(() => {
				dispatch(authReceived())
				dispatch(passwordResetSent())
				return null
			})
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function updateUserOrg(org) {
	return {
		type: UPDATE_USER_ORG,
		org,
	}
}

function updateUserFavourites(favourites) {
	return {
		type: UPDATE_USER_FAVOURITES,
		favourites,
	}
}

function setBootstrapData(user, isImpersonated = false, languageCode = 'en', featureMap = {}) {
	return (dispatch) => {
		dispatch(
			setUser({
				...user,
				isImpersonated,
				featureMap,
			}),
		)

		dispatch(languageActions.setLanguage(languageCode))
	}
}

function registerShared(email, sharetoken) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/register-shared-user/', { email, sharetoken })
			.then(() => {
				dispatch(authReceived())
				dispatch(passwordResetSent())

				return true
			})
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

function resendActivationEmail(email, sharetoken) {
	return (dispatch) => {
		dispatch(authRequest())
		return axios
			.post('/user/resend-activation-email/', { email, sharetoken })
			.then(() => {
				dispatch(authReceived())
				dispatch(passwordResetSent())

				return true
			})
			.catch((err) => {
				const error = err.request ? makeHttpError(err) : err
				dispatch(authError(error))
				throw error
			})
	}
}

export default {
	authenticate,
	changePassword,
	logout,
	requestPasswordChangeForCurrentUser,
	requestPasswordChangeForEmail,
	setUser,
	updateUserOrg,
	updateUserFavourites,
	verifyUsername,
	setBootstrapData,
	registerShared,
	resendActivationEmail,

	AUTH_ERROR,
	AUTH_RECEIVED,
	AUTH_REQUEST,
	PASSWORD_RESET_SENT,
	SET_AUTH_SCOPE,
	SET_USER,
	UPDATE_USER_ORG,
	UPDATE_USER_FAVOURITES,
}
