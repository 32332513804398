import { useState, useEffect, MutableRefObject, RefCallback } from 'react'

import { SxProps } from '@mui/material'
import { FormControl, FormControlProps } from '../form'
import { SelectProps } from '../select'
import { Autocomplete } from '../autocomplete'
import { allCurrencies, Currency } from './config'
import { TextField, TextFieldProps } from '../text-field'
import {
	OverrideNativeElementProps,
	DataAttributes,
	RHFFormComponentProps,
	useFormComponent,
} from '../../utilities'

const getCurrencyValue = (value: string): string => {
	if (!value) {
		return ''
	}
	const found = allCurrencies.find(({ value: currencyName }) => {
		return value.toString() === currencyName.toString()
	})

	if (!found) {
		return ''
	}

	return found.label
}

const getCurrencyObj = (code: string): Currency | undefined => {
	return allCurrencies.find(({ value }) => value === code)
}

export type CurrencySelectProps = {
	id?: string
	sx?: SxProps
	onChange: (currencyCode: string) => void
	label: string
	helperText?: string
	disableClearable?: boolean
	value: string | null
	required?: boolean
	error?: boolean
	fullWidth?: boolean
	inputRef?: MutableRefObject<HTMLInputElement> | RefCallback<HTMLInputElement>
	variant?: 'filled' | 'outlined' | 'standard'
	allowedCurrencies?: string[]
	TextFieldProps?: Partial<TextFieldProps>
}

export function CurrencySelect({
	inputRef,
	id = 'currency-autocomplete',
	label = 'Payment currency',
	value = '',
	onChange,
	required,
	error,
	helperText,
	fullWidth = false,
	disableClearable = false,
	variant,
	allowedCurrencies,
	TextFieldProps,
	sx,
}: CurrencySelectProps) {
	const [inputValue, setInputValue] = useState('')

	const availableOptions = allowedCurrencies
		? allCurrencies.filter((c) => allowedCurrencies.includes(c.value))
		: allCurrencies

	useEffect(() => {
		setInputValue(getCurrencyValue(value as string))
	}, [])

	return (
		<Autocomplete
			id={id}
			sx={sx}
			data-testid="currency"
			value={getCurrencyObj(value as string)}
			onChange={(_, val) => {
				onChange(val?.value ?? '')
			}}
			getOptionLabel={(value: Currency): string => {
				return `${value.label} (${value.value})`
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant={variant}
					inputRef={inputRef}
					error={!!error}
					label={label}
					required={required}
					helperText={helperText}
					{...TextFieldProps}
				/>
			)}
			options={availableOptions}
			inputValue={inputValue}
			onInputChange={(_, newValue) => setInputValue(newValue)}
			fullWidth={fullWidth}
			clearOnBlur={true}
			disableClearable={disableClearable}
		/>
	)
}

export type CurrencySelectForHookFormProps = RHFFormComponentProps<
	unknown,
	Partial<FormControlProps> & {
		/** Unique id for accessibility purpose */
		id: string
		/** Meaningful label for accessibility purpose */
		label: string
		helperText?: string
		/** Given this component is a FormControl + Select, the root props is of FormControl component,
		 * selectProps is for Select component specifically */
		selectProps?: DataAttributes<OverrideNativeElementProps<SelectProps, 'input'>>
		children?: React.ReactNode
		disableClearable?: boolean
	}
>

export const CurrencySelectForHookForm = ({
	rules,
	id,
	name,
	defaultValue,
	helperText,
	label = '',
	selectProps,
	fullWidth,
	children,
	error: controlledError,
	disableClearable = false,
	...props
}: CurrencySelectForHookFormProps) => {
	const {
		field: { onChange, value, ref, required },
		fieldState: { error },
	} = useFormComponent({
		name,
		defaultValue,
		rules,
	})

	return (
		<FormControl error={!!error?.message || controlledError} {...props}>
			<CurrencySelect
				label={label}
				onChange={onChange}
				value={value}
				inputRef={ref}
				fullWidth={fullWidth}
				required={required}
				error={!!error}
				helperText={helperText || error?.message}
				disableClearable={disableClearable}
			/>
		</FormControl>
	)
}
