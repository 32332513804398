// @ts-strict-ignore
import querystring from 'querystring'
import { useEffect, useState } from 'react'

type FirmData = {
	firmName: string
	points: string[]
}

type IntelligenceState<Payload> = {
	intelligence?: Payload | null
	loading?: boolean
	error?: string | null
}

const openAiUri = window.__OPEN_AI_URI__

const endpoints = {
	TOTAL_PRICE: `${openAiUri}/openai/total-price`,
	PRICING: `${openAiUri}/openai/price-item`,
	QUESTIONS: `${openAiUri}/openai/question`,
}

const getData = async ({ endpoint, params }) => {
	const queryParams = querystring.stringify(params)
	const result = await fetch(`${endpoint}?${queryParams}`, { credentials: 'include' })

	if (!result.ok) {
		// Handle the case where the server responded with an error status
		const error = await result.text()

		if (error) throw new Error(error)

		throw new Error('Something went wrong')
	} else {
		const response = await result.json()
		return response
	}
}

const createIntelligenceHook =
	<A extends unknown[], P>(apiFunction: (...args: A) => Promise<P>) =>
	(...args: A) => {
		const [intelligence, setIntelligence] = useState<IntelligenceState<P>>({
			intelligence: null,
			loading: true,
			error: null,
		})

		useEffect(() => {
			const getIntelligence = async () => {
				setIntelligence({ loading: true, error: null })
				try {
					const intelligence = await apiFunction(...args)
					setIntelligence({ intelligence, loading: false })
				} catch (error) {
					setIntelligence({ loading: false, error: error.message })
				}
			}

			getIntelligence()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [JSON.stringify(args)])

		return intelligence
	}

type TotalPriceIntelligencePayload = {
	firms: FirmData[]
}

const getTotalPriceIntelligence = (rfpId: string): Promise<TotalPriceIntelligencePayload> =>
	getData({ endpoint: endpoints.TOTAL_PRICE, params: { rfpId } })

type GetDeliverableIntelligenceInput = { rfpId: string; deliverableId: string }

type DeliverableIntelligencePayload = {
	summary: string
	firms: FirmData[]
}

const getDeliverableIntelligence = ({
	rfpId,
	deliverableId,
}: GetDeliverableIntelligenceInput): Promise<DeliverableIntelligencePayload> =>
	getData({ endpoint: endpoints.PRICING, params: { rfpId, priceItemId: deliverableId } })

type GetQuestionIntelligenceInput = { rfpId: string; questionId: string }

type QuestionIntelligencePayload = {
	firms: FirmData[]
}

const getQuestionIntelligence = ({
	rfpId,
	questionId,
}: GetQuestionIntelligenceInput): Promise<QuestionIntelligencePayload> =>
	getData({ endpoint: endpoints.QUESTIONS, params: { rfpId, questionId } })

export const useTotalPriceIntelligence = createIntelligenceHook(getTotalPriceIntelligence)

export const useQuestionIntelligence = createIntelligenceHook(getQuestionIntelligence)

export const useDeliverableIntelligence = createIntelligenceHook(getDeliverableIntelligence)
