import { allCurrencies } from '@persuit/ui-components'
import { createStore, StoreApi as GenericStoreApi } from '@persuit/ui-state'
// eslint-disable-next-line no-restricted-imports
import { RfpDetailResponsesQuery, RfpDetailRfpQuery } from '@persuit/ui-graphql/hooks'
import { DeepPick, isNotNil } from '@persuit/common-utils'
import { selectors } from './selectors'
import { getRowProposals } from '../column-definitions'

type ComparisonTableResponseUnion = NonNullable<RfpDetailResponsesQuery['getResponses'][number]>

export type Proposal = Extract<ComparisonTableResponseUnion, { __typename: 'Response' }>
export type Rfp = DeepPick<
	Omit<NonNullable<RfpDetailRfpQuery['getRfp']>, 'detail'>,
	'rateReview' | '_id' | 'status' | 'invites'
>
export type RateCard = Proposal['rateCards'][number]

export type SortDirection = 'asc' | 'desc'
export type SortType = 'rate' | 'percentage' | null
export type Field = 'firmName' | 'weightedAverage' | `timekeepers[${number}]`
type SortState = {
	field: Field
	direction: SortDirection
	type?: SortType
}

export type State = {
	showProposalRevisions: boolean
	rfp: Rfp
	proposals: Proposal[]

	proposalDetailId: string | null

	currencyFilter: string
	firmFilters: string[]
	countryFilters: string[]
	regionFilters: string[]
	practiceAreaFilters: string[]

	sortState: null | SortState
}

type SetupState = {
	groupCurrency?: string | null
	rfp: Rfp
	proposals: Proposal[]
}

const store = createStore(
	(input: SetupState): State => {
		const currencies = getRowProposals(input.proposals)
			.flatMap((p) => p.proposal.rateCards.map((card) => card.currency))
			.filter(isNotNil)

		const currencyFilter =
			typeof input.groupCurrency === 'string' && currencies.includes(input.groupCurrency)
				? input.groupCurrency
				: // Find first currency that is in the list of allCurrencies
				  allCurrencies.find((c) => currencies.includes(c.value))?.value ??
				  // If there really is no match default to the first currency selected by the client
				  currencies[0]

		return {
			showProposalRevisions: false,
			rfp: input.rfp,
			proposals: input.proposals,

			proposalDetailId: null,

			sortState: { field: 'firmName', direction: 'asc', type: null },

			currencyFilter,
			firmFilters: [],
			countryFilters: [],
			regionFilters: [],
			practiceAreaFilters: [],
		}
	},
	(set, get) => ({
		openProposalDetail: (firmId: string) => set({ proposalDetailId: firmId }),
		closeProposalDetail: () => set({ proposalDetailId: null }),
		nextProposalDetail: () => {
			const state = get()
			const { proposalDetailId } = state
			const rowProposals = selectors.filteredRowProposals(state)
			const index = rowProposals.findIndex((rp) => rp.proposal.org?._id === proposalDetailId)
			const newIndex = (index + 1) % rowProposals.length

			set({ proposalDetailId: rowProposals[newIndex]?.proposal.org?._id ?? null })
		},
		previousProposalDetail: () => {
			const state = get()
			const { proposalDetailId } = state
			const rowProposals = selectors.filteredRowProposals(state)
			const index = rowProposals.findIndex((rp) => rp.proposal.org?._id === proposalDetailId)
			const newIndex = (index - 1 + rowProposals.length) % rowProposals.length

			set({ proposalDetailId: rowProposals[newIndex]?.proposal.org?._id ?? null })
		},

		toggleShowProposalRevisions: () =>
			set((state) => ({ showProposalRevisions: !state.showProposalRevisions })),

		setCurrencyFilter: (currencyFilter: string) => set({ currencyFilter }),
		setFirmFilters: (firmIds: string[]) => set({ firmFilters: firmIds }),
		setCountryFilters: (countryIds: string[]) => set({ countryFilters: countryIds }),
		setRegionFilters: (regionIds: string[]) => set({ regionFilters: regionIds }),
		setPracticeAreaFilters: (practiceAreaIds: string[]) =>
			set({ practiceAreaFilters: practiceAreaIds }),
		resetFilters: () =>
			set({ firmFilters: [], regionFilters: [], countryFilters: [], practiceAreaFilters: [] }),

		toggleSortState: (sortState: SortState) =>
			set((state) => {
				if (
					state.sortState?.field === sortState.field &&
					state.sortState?.direction === sortState.direction &&
					state.sortState?.type === sortState.type
				) {
					return { sortState: null }
				}

				return { sortState }
			}),

		setSortState: (sortState: { field: Field; direction: SortDirection } | null) =>
			set({
				sortState:
					sortState === null
						? null
						: sortState.field.startsWith('timekeepers')
						? {
								...sortState,
								type: 'rate',
						  }
						: sortState,
			}),
	}),
	selectors,
)

export const { useStore, Provider: StoreProvider, useActions, useStoreApi } = store

export type StoreApi = GenericStoreApi<State>
