import { useFileUploadXHR } from '../file-upload-xhr'
import { useFileMediator } from '../file-mediator'
import { useFileGQLAdapter } from '../file-gql-adapter'

export const FileWrapper = ({
	children,
	documentId,
	getFilesQuery,
	updateFilesMutation,
	refetchQueries,
	fetchPolicy,
}) => {
	const {
		filesForDocument,
		errorFetchingFilesForDocument,
		updateFilesForDocument,
		updatingFilesForDocument,
	} = useFileGQLAdapter({
		documentId,
		getFilesQuery,
		updateFilesMutation,
		refetchQueries,
		fetchPolicy,
	})
	const { fileList, addFiles, removeFile } = useFileMediator({
		files: filesForDocument,
		sync: updateFilesForDocument,
	})
	const { uploadFiles, uploadError, isUploading } = useFileUploadXHR({
		handleUploadedFiles: addFiles,
	})

	return children({
		fileList,
		removeFile,
		uploadFiles,
		isUploading,
		uploadError,
		errorFetchingFilesForDocument,
		updatingFilesForDocument,
	})
}
