import domready from 'domready'
import { createRoot } from 'react-dom/client'
import { initialize } from '@persuit/ui-analytics'

import Root from './containers/root'

initialize()

if (process.env.MSW === 'on') {
	console.info('Mocking with MSW')
	require('./testing/msw/worker').worker.start({ onUnhandledRequest: 'warn' })
}

const container = document.getElementById('root')
const root = createRoot(container)

const render = (Component) => {
	root.render(<Component />)
}

domready(() => {
	render(Root)
})
