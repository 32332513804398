import { flowRight, isEmpty, trim } from 'lodash/fp'

import { Fragment, useState } from 'react'

import NoteEditor from './note-editor'
import SaveNotesButtonMutation from './save-mutation-button'
import {
	useSnackbar,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
	Alert,
} from '@persuit/ui-components'

const stripHTML = (str = '') => str.replace(/<(?:.|\n)*?>/g, '')
const isContentEmpty = flowRight([isEmpty, trim, stripHTML])
const validateNoteContents = (noteContents = '') => {
	if (isContentEmpty(noteContents)) {
		return 'Note content is required'
	}

	return ''
}

const AddNoteDialog = ({
	rfpId,
	firmId,
	firmName,
	openViewNoteDialog,
	ownNoteContents,
	setOwnNoteContents,
}) => {
	const [noteContentError, setNoteContentError] = useState('')

	const { openSnackbar } = useSnackbar()

	const getSaveNoteInput = () => {
		return {
			rfpId,
			firmId,
			noteContents: ownNoteContents,
		}
	}

	return (
		<Fragment>
			<DialogTitle data-testid="add-note-dialog">{`Notes for ${firmName}`}</DialogTitle>
			<DialogContent>
				{noteContentError && (
					<Alert severity="error" data-testid="error-message" role="alert" sx={{ mt: 1 }}>
						{noteContentError}
					</Alert>
				)}

				<NoteEditor noteContents={ownNoteContents} setNoteContents={setOwnNoteContents} />

				<Typography variant="body2" display="block" fontStyle="italic" sx={{ mt: 2 }}>
					Notes are for internal purposes and visible to your colleagues
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button data-testid="back-button" onClick={openViewNoteDialog}>
					Back
				</Button>
				<SaveNotesButtonMutation
					preMutationCall={() => {
						const error = validateNoteContents(ownNoteContents)
						if (!isEmpty(error)) {
							setNoteContentError(error)
							throw new Error('NOTE_CONTENT_VALDIATION_ERROR')
						}
						setNoteContentError('')
					}}
					saveNoteInput={getSaveNoteInput()}
					postMutationCall={() => {
						openSnackbar('Note saved')
						openViewNoteDialog()
					}}
				/>
			</DialogActions>
		</Fragment>
	)
}

export default AddNoteDialog
