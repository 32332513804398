
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AverageRate": [
      "ResponseToDeliverableAverageRate",
      "RfpProposalAverageRate"
    ],
    "DeliverableV2": [
      "Deliverable",
      "PricingGroup"
    ],
    "ICustomTrackingField": [
      "CustomTrackingField",
      "CustomTrackingFieldHierarchy"
    ],
    "PricingGroupErrors": [
      "RfpPricingGroupErrors",
      "RfpTemplateDraftPricingGroupErrors",
      "RfpUpdatePricingGroupErrors"
    ],
    "ResponsesOrCompetitorResponse": [
      "CompetitorResponse",
      "Response"
    ],
    "RfpTaggerDeliverableV2": [
      "RfpPricingItem",
      "RfpTaggerPricingGroup"
    ],
    "RfpTemplate": [
      "RfpTemplateDraft",
      "RfpTemplatePublished"
    ],
    "RfpUpdateDeliverableV2": [
      "DraftDeliverable",
      "RfpUpdatePricingGroup"
    ],
    "RfprRateItem": [
      "RfprFirmSuppliedRate",
      "RfprResponseToRate"
    ],
    "SaliLocation": [
      "SaliCity",
      "SaliCountry",
      "SaliState"
    ]
  }
};
      export default result;
    