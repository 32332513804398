import { DEFAULT_PRICING_MODELS_OPTIONS, PricingModelOption } from '../../../common-components'
import { PricingModels } from '@persuit/ui-graphql/generated'

export function filterSubItemPricingModels({
	pricingModel = null,
}: {
	pricingModel?: PricingModels | string | null
}): PricingModels | null {
	const relevantPricingModel = DEFAULT_PRICING_MODELS_OPTIONS.subItem
		.reduce<PricingModelOption[]>((acc, curr) => {
			if (curr.type === 'OPTION') {
				acc = [...acc, curr]
			} else if (curr.type === 'OPTION_HEADER') {
				acc = [...acc, ...curr.children]
			}

			return acc
		}, [])
		.find((item) => item.value === pricingModel)

	return (relevantPricingModel?.value as PricingModels) ?? null
}
