import { useState, useEffect, useCallback, createContext, useContext } from 'react'
import * as React from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import {
	SearchIcon,
	Typography,
	TextField,
	Divider,
	ScrollShadows,
	Box,
	BoxProps,
	CheckBoxIcon,
	CheckBoxOutlineBlankIcon,
	List,
	ListProps,
	ListItem,
	ListItemButton,
	ListItemIcon,
	IconButton,
	ClearIcon,
} from '@persuit/ui-components'

import { isNil } from '@persuit/common-utils'

import { difference, union } from 'lodash'

const ROW_HEIGHT = 50

type FirmItem = { _id: string; name: string }

export type FirmListProps = BoxProps & {
	title: string
	searchLabel: string
	searchPlaceholder: string
	searchTerm: string
	onSearchTermChange: (searchTerm: string) => void
	options: FirmItem[]
	value: string[]
	onChange: (firms: FirmItem[]) => void
	listProps?: ListProps
}

export const FirmList = ({
	onChange,
	options,
	title,
	value,
	searchLabel,
	searchPlaceholder,
	listProps,
	searchTerm,
	onSearchTermChange,
	...rest
}: FirmListProps) => {
	const [lastSelectionIndex, setLastSelectionIndex] = useState<number | null>(null)
	const [pointerIndex, setPointerIndex] = useState<number | null>(null)

	useEffect(() => {
		setLastSelectionIndex(null)
	}, [options])

	const searchDisabled = options.length === 0

	const filteredOptions = [...options]
		.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
		.filter((firm) => firm.name.toLowerCase().includes(searchTerm.toLowerCase()))

	function firmById(id: string) {
		const firm = options.find((firm) => firm._id === id)
		if (!firm) throw new Error(`No such firm with id ${id}`)
		return firm
	}

	const [shift, setShift] = useState(false)

	useEffect(() => {
		const keydown = (e: KeyboardEvent) => setShift(e.shiftKey)
		const keyup = (e: KeyboardEvent) => setShift(e.shiftKey)
		window.addEventListener('keydown', keydown)
		window.addEventListener('keyup', keyup)
		return () => {
			window.removeEventListener('keydown', keydown)
			window.removeEventListener('keyup', keyup)
		}
	}, [])

	function inMultiselectRange(index: number) {
		if (isNil(lastSelectionIndex) || isNil(pointerIndex)) return false
		const startIndex = Math.min(lastSelectionIndex, pointerIndex)
		const endIndex = Math.max(lastSelectionIndex, pointerIndex)
		return index >= startIndex && index <= endIndex
	}

	const CustomList = useCallback(
		(props: ListProps) => <List aria-label={title} {...props} />,
		[title],
	)

	return (
		<Box {...rest}>
			<Box sx={{ p: 2, pb: 0 }}>
				<Typography variant="h3XSmall" component="p">
					{title}
				</Typography>

				<TextField
					sx={(theme) => ({
						mt: 2,
						'.Mui-disabled': {
							backgroundColor: theme.palette.action.disabledBackground,
							color: 'unset',
							'-webkit-text-fill-color': 'unset',
						},
					})}
					disabled={searchDisabled}
					fullWidth={true}
					variant="outlined"
					hiddenLabel={true}
					label={searchLabel}
					placeholder={searchPlaceholder}
					value={searchTerm}
					onChange={(e) => onSearchTermChange(e.target.value)}
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: searchTerm ? (
							<IconButton onClick={() => onSearchTermChange('')} aria-label="Clear search">
								<ClearIcon />
							</IconButton>
						) : null,
					}}
				/>

				<Divider sx={{ my: 2 }} />
			</Box>

			{value.length > 0 && (
				<Typography sx={{ px: '27px', pb: 1 }} variant="body1Semibold">
					{value.length} selected
				</Typography>
			)}

			<ScrollShadows
				disableLeftShadow={true}
				disableRightShadow={true}
				sx={{
					overflow: 'unset',
					height: `min(${(listProps?.sx as any)?.maxHeight}, ${
						filteredOptions.length * ROW_HEIGHT
					}px)`,
					...listProps?.sx,
				}}
			>
				{filteredOptions.length === 0 && searchTerm ? (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Typography>No results found</Typography>
					</Box>
				) : (
					<AutoSizer>
						{({ height, width }) => (
							<FirmListContext.Provider
								value={{
									filteredOptions,
									setPointerIndex,
									inMultiselectRange,
									lastSelectionIndex,
									onChange,
									value,
									firmById,
									shift,
									setLastSelectionIndex,
								}}
							>
								<FixedSizeList
									width={width}
									height={height}
									itemCount={filteredOptions.length}
									itemSize={ROW_HEIGHT}
									innerElementType={CustomList}
								>
									{FirmListItem}
								</FixedSizeList>
							</FirmListContext.Provider>
						)}
					</AutoSizer>
				)}
			</ScrollShadows>
		</Box>
	)
}

/**
 * We need context because for some reason react-window expects the children
 * prop to be a React Component, this is not a render prop. Therefore the
 * identity of the Component must be constant to prevent unexpected rerenders
 * and because of this we cannot pass props so we use context instead. In the
 * future we should look for a better virtualisation library.
 */
type FirmListContext = {
	setPointerIndex: (index: number) => void
	inMultiselectRange: (index: number) => boolean
	setLastSelectionIndex: (index: number) => void
	lastSelectionIndex: number | null
	onChange: (ids: FirmItem[]) => void
	value: string[]
	firmById: (id: string) => FirmItem
	shift: boolean
	filteredOptions: FirmItem[]
}

const FirmListContext = createContext(null as any as FirmListContext)

type FirmListItemProps = {
	index: number
	style: React.CSSProperties
	data: FirmItem[]
}

const FirmListItem = ({ index, style }: FirmListItemProps) => {
	const {
		filteredOptions,
		setPointerIndex,
		inMultiselectRange,
		lastSelectionIndex,
		onChange,
		value,
		firmById,
		shift,
		setLastSelectionIndex,
	} = useContext(FirmListContext)
	const { _id, name } = filteredOptions[index]
	const checked = value.includes(_id)

	return (
		<ListItem
			style={style}
			key={_id}
			disablePadding={true}
			aria-label={`${name}, ${checked ? 'selected' : 'not selected'}`}
			onPointerEnter={() => setPointerIndex(index)}
		>
			<ListItemButton
				sx={{ height: '50px' }}
				aria-label={`${checked ? 'unselect' : 'select'} ${name}`}
				selected={shift ? inMultiselectRange(index) : lastSelectionIndex === index}
				onClick={(e) => {
					setLastSelectionIndex(index)

					if (e.shiftKey && lastSelectionIndex !== null) {
						const startIndex = Math.min(lastSelectionIndex, index)
						const endIndex = Math.max(lastSelectionIndex, index)
						const options = filteredOptions.slice(startIndex, endIndex + 1)
						const allSelected = options.every((firm) => value.includes(firm._id))
						if (allSelected) {
							onChange(difference(value.map(firmById), options))
						} else {
							onChange(union(options, value.map(firmById)))
						}
					} else {
						const newValue = (checked ? difference(value, [_id]) : [...value, _id]).map(firmById)

						onChange(newValue)
					}
				}}
			>
				<ListItemIcon sx={{ minWidth: 'unset', ml: 1, mr: 2 }}>
					{checked ? (
						<CheckBoxIcon color="primary" />
					) : (
						<CheckBoxOutlineBlankIcon color="primary" />
					)}
				</ListItemIcon>
				{name}
			</ListItemButton>
		</ListItem>
	)
}
