// @ts-strict-ignore
import { useId, useState } from 'react'

import * as React from 'react'
import {
	KebabButton,
	MoreVertIcon,
	Menu,
	MenuItem,
	WrapTextIcon,
	ListItemIcon,
	ListItemText,
	LibraryAddIcon,
	useConfirmDialog,
	DeleteIcon,
	Tooltip,
	Box,
	Alert,
	useSnackbar,
} from '@persuit/ui-components'
import { usePricingListContext } from '../../pricing-list-context'
import { refocusOnRemoval, useFormContext } from '../../pricing-form-utils'
import { PricingGroup as PricingGroupType } from '../../types'

type PricingGroupMoreActionsMenuProps = { index: number; totalGroupItems: number }

export const PricingGroupMoreActionsMenu = ({
	index,
	totalGroupItems,
}: PricingGroupMoreActionsMenuProps) => {
	const {
		pricingItemsFieldArray: {
			remove: removeGroup,
			insert: insertPricingListItems,
			update: updatePricingListItems,
		},
		duplicateGroup,
	} = usePricingListContext()
	const { getValues } = useFormContext()

	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null)
	const handleActionBtnClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		setActionAnchorEl(event.currentTarget)
	}
	const handleActionMenuClose = (event?) => {
		event?.stopPropagation()
		setActionAnchorEl(null)
	}

	const getCurrentGroup = () => getValues().pricingItems[index] as PricingGroupType

	const ungroupItems = () => {
		const currentGroup = getCurrentGroup()
		const currentGroupItems = [...currentGroup.deliverables]

		updatePricingListItems(index, {
			...currentGroup,
			deliverables: [],
		})
		insertPricingListItems(index, currentGroupItems)
	}

	const removeGroupAndUngroupItems = () => {
		const currentGroup = getCurrentGroup()
		const currentGroupItems = [...currentGroup.deliverables]

		removeGroup(index)
		insertPricingListItems(index, currentGroupItems)
	}

	const isInRfpRevisionMode = getCurrentGroup().deliverables.some(
		(item) => item.originalDeliverableId,
	)

	const { openConfirmDialog } = useConfirmDialog()
	const kebabBtnId = useId()
	const menuId = useId()

	const deleteListText = (
		<ListItemText
			primary="delete"
			primaryTypographyProps={{ color: 'primary', variant: 'button' }}
		/>
	)

	const { openSnackbar } = useSnackbar()
	const menuOpen = Boolean(actionAnchorEl)
	const groupPosition = index + 1

	return (
		<>
			<KebabButton
				id={kebabBtnId}
				aria-label={`Group ${groupPosition} actions`}
				onClick={handleActionBtnClick}
				aria-controls={menuOpen ? menuId : undefined}
			>
				<MoreVertIcon />
			</KebabButton>
			<Menu
				id={menuId}
				open={menuOpen}
				onClose={handleActionMenuClose}
				anchorEl={actionAnchorEl}
				aria-labelledby={kebabBtnId}
			>
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						openConfirmDialog({
							title: 'Are you sure you want to delete this group?',
							content: totalGroupItems > 0 && (
								<Alert severity="warning" role="status">
									This will also delete item/s within this group.
								</Alert>
							),
							actions: [
								{ label: 'Cancel', type: 'secondary', close: true },
								{
									label: 'Delete',
									type: 'primary',
									icon: (
										<DeleteIcon
											sx={{
												color: 'common.white',
											}}
										/>
									),
									close: true,
									action: () => {
										refocusOnRemoval({
											currentIndex: index,
										})
										if (!isInRfpRevisionMode) {
											removeGroup(index)
										} else {
											removeGroupAndUngroupItems()
										}
										openSnackbar('Group has been deleted.')
									},
								},
							],
						})
					}}
					aria-label="Delete group"
					disabled={isInRfpRevisionMode}
				>
					<ListItemIcon>
						<DeleteIcon color="primary" fontSize="small" />
					</ListItemIcon>
					{isInRfpRevisionMode ? (
						<Tooltip
							title={'Groups with pre-existing pricing items cannot be deleted.'}
							arrow={true}
						>
							<Box>{deleteListText}</Box>
						</Tooltip>
					) : (
						deleteListText
					)}
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						openConfirmDialog({
							title: 'Are you sure you want to ungroup these items?',
							content: null,
							actions: [
								{ label: 'Cancel', type: 'secondary', close: true },
								{
									label: 'Ungroup',
									type: 'primary',
									icon: <WrapTextIcon color="primary" />,
									close: true,
									action: () => {
										ungroupItems()
									},
								},
							],
						})
					}}
					aria-label="Ungroup all"
					disabled={totalGroupItems === 0}
				>
					<ListItemIcon>
						<WrapTextIcon color="primary" fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary="ungroup all"
						primaryTypographyProps={{ color: 'primary', variant: 'button' }}
					/>
				</MenuItem>

				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						duplicateGroup(index)
						handleActionMenuClose()
					}}
					aria-label="Duplicate group"
				>
					<ListItemIcon>
						<LibraryAddIcon color="primary" fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary="duplicate"
						primaryTypographyProps={{ color: 'primary', variant: 'button' }}
					/>
				</MenuItem>
			</Menu>
		</>
	)
}
