// @ts-strict-ignore
import { useQuery, useMutation, gql } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useState, useEffect } from 'react'
import getOr from 'lodash/fp/getOr'
import { SectionalLocks, SectionalLockType } from '../types'

/*
This hook provides sectionalLocks, otherLockedSections and a tryLock function

Sectional Locks is a map that contains all the currently locked sections
If a section is not locked it is not in the map.
This is primarily for consumption by the tabs / left nav
to display who currently owns locks

sectionalLocks: {[key: string(sectionName)]: {
	formInstanceId: string - The form instance id that has the lock
	// More to come
}}

Instance ID is a UUIDv4 generated on mount and identifiest the tab.
instanceId: string

Try Lock is a function used when the section is loaded to try
and acquire a lock. The section wrapper will call this on mount.

tryLock: (sectionName: string) => Promise<void>

*/

const POLLING_INTERVAL = getOr(30000, '__SECTIONAL_LOCK_POLLING_INTERVAL_MS__', window) || 30000

const TRY_ACQUIRE_SECTIONAL_LOCK = gql`
	mutation tryAcquireSectionalLock(
		$type: String!
		$typeId: ID!
		$formInstanceId: String!
		$section: String!
	) {
		tryAcquireSectionalLock(
			type: $type
			typeId: $typeId
			formInstanceId: $formInstanceId
			section: $section
		)
	}
`

const CLEAR_INSTANCE_LOCKS = gql`
	mutation clearInstanceLocks($type: String!, $typeId: ID!, $formInstanceId: String!) {
		clearInstanceLocks(type: $type, typeId: $typeId, formInstanceId: $formInstanceId)
	}
`
const GET_SECTIONAL_LOCKS = gql`
	query getSectionalLocks($type: String!, $typeId: ID!) {
		getSectionalLocks(type: $type, typeId: $typeId) {
			section
			formInstanceId
			displayName
			userId
		}
	}
`

export function useSectionalLocks(
	type: SectionalLockType,
	typeId: string,
): {
	sectionalLocks: SectionalLocks
	instanceId: string
	tryLock: (section: string) => Promise<void>
	refetchLocks: () => Promise<void>
} {
	const [instanceId] = useState<string>(uuid())
	const [sectionalLocks, setSectionalLocks] = useState<SectionalLocks>({})
	const [tryAcquireSectionalLock] = useMutation(TRY_ACQUIRE_SECTIONAL_LOCK)
	const [clearInstanceLocks] = useMutation(CLEAR_INSTANCE_LOCKS, {
		variables: {
			type,
			typeId,
			formInstanceId: instanceId,
		},
	})
	const { startPolling, stopPolling, data, refetch } = useQuery(GET_SECTIONAL_LOCKS, {
		variables: {
			type,
			typeId,
			instanceId,
		},
	})

	useEffect(() => {
		startPolling(POLLING_INTERVAL)
		return () => {
			stopPolling()
			clearInstanceLocks()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const lockedSections = getOr([], 'getSectionalLocks', data)

		const newSectionalLocks = lockedSections.reduce(
			(acc, { section, formInstanceId, userId, displayName }) => {
				acc[section] = { formInstanceId, userId, displayName }
				return acc
			},
			{},
		)

		setSectionalLocks(newSectionalLocks)
	}, [data, instanceId])

	const tryLock = async (section: string) => {
		await tryAcquireSectionalLock({
			variables: { type, typeId, formInstanceId: instanceId, section },
		})
		await refetch()
	}

	// Wrapper function to limit the usage of the refetch
	const refetchLocks = async () => {
		await refetch()
	}

	return { sectionalLocks, instanceId, tryLock, refetchLocks }
}
