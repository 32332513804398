import { useState, useRef, useEffect } from 'react'
import {
	Box,
	FileDownloadIcon,
	LaunchIcon,
	Typography,
	IconButton,
	WiderWindowIcon,
	NarrowerWindowIcon,
	BoxProps,
	CloseIcon,
} from '@persuit/ui-components'
import { ChannelPane, ChannelsPane, ParticipantsPane } from './panes'
import { ExportDialog } from './export-dialog/export-dialog'

export type MessagingInnerProps = {
	requestId: string
	headline: string
	expanded?: boolean
	toggleExpanded?: () => void
	popOut?: () => void
	closeMessages?: () => void
} & BoxProps

export const MessagingInner = ({
	requestId,
	headline,
	expanded,
	toggleExpanded,
	popOut,
	closeMessages,
	...props
}: MessagingInnerProps) => {
	const [channelId, setChannelId] = useState<string | null>(null)
	const [showParticipantsPane, setShowParticipantsPane] = useState(false)
	const [showExportDialog, setShowExportDialog] = useState(false)

	const drawerRef = useRef<HTMLDivElement>()

	useEffect(() => {
		drawerRef.current?.focus()
	}, [])

	const openParticipantsPane = () => {
		setShowParticipantsPane(true)
	}

	const closeParticipantsPane = () => {
		setShowParticipantsPane(false)
	}

	const backToChannelListPane = () => {
		setChannelId(null)
	}

	return (
		<Box
			ref={drawerRef}
			tabIndex={-1}
			display="flex"
			flexDirection="column"
			height="100%"
			{...props}
		>
			{showExportDialog && (
				<ExportDialog
					requestId={requestId}
					show={showExportDialog}
					onClose={() => setShowExportDialog(false)}
				/>
			)}

			<Box
				p={3}
				display="flex"
				flexDirection="column"
				gap={1}
				bgcolor="primary.darkHue"
				color="primary.contrastText"
			>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box>
						<Typography variant="h2XSmall">MESSAGES</Typography>
						<Typography>{headline}</Typography>
					</Box>
					<Box display="flex" flexDirection="row">
						<IconButton color="inherit" onClick={() => setShowExportDialog(true)} title="Export">
							<FileDownloadIcon />
						</IconButton>
						{toggleExpanded && (
							<IconButton
								color="inherit"
								onClick={toggleExpanded}
								title={expanded ? 'Collapse' : 'Expand'}
							>
								{!expanded && <WiderWindowIcon />}
								{expanded && <NarrowerWindowIcon />}
							</IconButton>
						)}
						{popOut && (
							<IconButton color="inherit" onClick={popOut} title="Pop out">
								<LaunchIcon />
							</IconButton>
						)}
						{closeMessages && (
							<IconButton color="inherit" onClick={closeMessages} title="Close">
								<CloseIcon />
							</IconButton>
						)}
					</Box>
				</Box>
			</Box>

			<Box height="100%" overflow="auto">
				{channelId && showParticipantsPane && (
					<ParticipantsPane channelId={channelId} closeParticipantsPane={closeParticipantsPane} />
				)}

				{channelId && !showParticipantsPane && (
					<ChannelPane
						openParticipantsPane={openParticipantsPane}
						channelId={channelId}
						backToChannelListPane={backToChannelListPane}
						showSystemMessages={true}
					/>
				)}

				{!channelId && <ChannelsPane requestId={requestId} selectChannel={setChannelId} />}
			</Box>
		</Box>
	)
}
