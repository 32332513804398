import { Box, Typography, Avatar, ListItem } from '@persuit/ui-components'

export type UserCardProps = {
	name: {
		first: string
		last: string
	}
	email: string
	currentOrg: boolean
	currentUser: boolean
}

export const UserCard = ({
	name: { first, last },
	email,
	currentOrg,
	currentUser,
}: UserCardProps) => {
	const fullName = `${first} ${last}${currentUser ? ' (You)' : ''}`

	return (
		<ListItem sx={{ padding: 0 }} aria-label={fullName}>
			<Box
				width="100%"
				display="flex"
				flexDirection="row"
				gap={2}
				alignItems="center"
				pt={3}
				pb={3}
			>
				<Avatar
					sx={{
						// TODO Update to semantic colors
						// eslint-disable-next-line no-restricted-syntax -- TODO(DEV-11455): Dont use hardcoded color value
						bgcolor: currentOrg ? '#6251A6' : 'background.greyLabel',
						width: '36px',
						height: '36px',
					}}
					aria-hidden={true}
				>
					<Typography variant="body1Semibold">
						{first.slice(0, 1)}
						{last.slice(0, 1)}
					</Typography>
				</Avatar>
				<Box display="flex" flexDirection="column">
					<Typography variant="body1Semibold">{fullName}</Typography>
					<Typography variant="body2">{email}</Typography>
				</Box>
			</Box>
		</ListItem>
	)
}
