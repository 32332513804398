import { useState } from 'react'
import * as React from 'react'
import {
	useTheme,
	IconButton,
	KeyboardArrowUpIcon,
	KeyboardArrowDownIcon,
	Box,
} from '@persuit/ui-components'

export type TableRowProps = {
	rowData: {
		label: React.ReactNode
		data?: React.ReactNode
		subRows?: Array<React.ReactNode>
	}
	tableHasSubRowData?: boolean
}

export const TableRow = ({
	rowData: { label, data, subRows },
	tableHasSubRowData = false,
}: TableRowProps) => {
	const theme = useTheme()
	const [isExpanded, setIsExpanded] = useState(false)

	return (
		<Box
			borderBottom={`1px solid ${theme.palette.divider}`}
			padding="0.3em 0"
			minHeight="1.7em"
			height={isExpanded ? 'auto' : undefined}
		>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>{label}</div>

				<Box display="flex" alignItems="center">
					{data ? <div>{data}</div> : null}

					{/*
							If any of the rows in the table has subRow data then output a div for the expand button.
							This keeps all the data vertically aligned regardless of if it has subRow data or not
						*/}
					{tableHasSubRowData && (
						<Box pl={1}>
							{subRows && subRows.length > 0 ? (
								<IconButton
									size="small"
									onClick={() => setIsExpanded((x) => !x)}
									aria-label={`Expand ${label} row`}
								>
									{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							) : null}
						</Box>
					)}
				</Box>
			</Box>
			{isExpanded && !!subRows && (
				<Box pl="1em">
					{subRows.map((subRow, index) => (
						<div key={index}>{subRow}</div>
					))}
				</Box>
			)}
		</Box>
	)
}
