// @ts-strict-ignore
import { useId } from 'react'
import { Box, Typography, Stepper, Step, StepLabel } from '@persuit/ui-components'
import { stepIndex } from './utils'
import { StepType } from './dialog-wizard-steps'

const getOptionalLabel = (optionalLabel) => {
	if (!optionalLabel) return null

	return (
		<Box textAlign="center">
			<Typography variant="caption" color="text.secondary">
				{optionalLabel}
			</Typography>
		</Box>
	)
}

export type DialogWizardStepperProps = {
	steps: StepType[]
	activeStep: string
	secondaryTextForSteps: {
		[k: number]: string
	}
}

export const DialogWizardStepper = ({
	steps,
	activeStep,
	secondaryTextForSteps = {},
}: DialogWizardStepperProps) => {
	const activeStepIndex = stepIndex(steps, activeStep)
	const id = useId()
	return (
		<Box padding={3}>
			<Stepper activeStep={activeStepIndex} alternativeLabel={true} role="list" tabIndex={-1}>
				{steps.map((step, index) => (
					<Step
						key={index}
						completed={activeStepIndex > step.index}
						role="listitem"
						aria-labelledby={`step-label-${index}-${id}`}
						aria-current={activeStepIndex === index ? 'true' : 'false'}
					>
						<StepLabel
							optional={getOptionalLabel(secondaryTextForSteps[step.index])}
							id={`step-label-${index}-${id}`}
						>
							{step.displayName}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	)
}
