import { Component } from 'react'
// eslint-disable-next-line no-restricted-imports -- Please upgrade
import { List } from 'material-ui/List'
import Spacer from '../layout/spacer.jsx'
import FileItem from './file-item.jsx'

class FileList extends Component {
	handleCancel = (file) => () => {
		this.props.onCancel(file)
	}

	handleRemove = (file) => (evt) => {
		//to prevent the file from downloading via the parent anchor tag
		evt.preventDefault()

		this.props.onRemove(file)
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { files, disabled, onCancel, onRemove } = this.props

		return (
			<div>
				{!!files.length && !disabled && (
					<List>
						{files.map((file) => {
							if (file._id) {
								return (
									<div key={file._id}>
										<FileItem
											key={file._id}
											onCancel={onCancel ? this.handleCancel(file) : null}
											onRemove={onRemove ? !file.preventDelete && this.handleRemove(file) : null}
											{...file}
										/>
										<Spacer direction="column" key="spacer" />
									</div>
								)
							}
						})}
					</List>
				)}
			</div>
		)
	}
}

export default FileList
