import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { UserConfig } from '../hooks'

type FeatureToggleProviderConfig = {
	/**
	 * Due to PII concern, we are ONLY allowed to track the following property
	 * user id, user email, org id
	 * */
	userConfig?: UserConfig
}

export const ANONYMOUS_USER_SHARED_KEY = 'ANONYMOUS_USER_SHARED_KEY'

export function withFeatureToggleProvider(config?: FeatureToggleProviderConfig) {
	return <T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> => {
		const { user, org } = config?.userConfig ?? {}

		const userConfig =
			user && user?.id
				? {
						key: user.id,
						email: user?.email,
						orgId: org?.id ?? '',
				  }
				: {
						key: ANONYMOUS_USER_SHARED_KEY,
				  }

		const context = {
			kind: 'user',
			...userConfig,
		}

		return withLDProvider({
			clientSideID: window.__LD_CLIENT_ID__,
			context,
			reactOptions: {
				useCamelCaseFlagKeys: false,
			},
		})(WrappedComponent as any) as any
	}
}
