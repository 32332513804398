import {
	Box,
	IconButton,
	UnfoldLessIcon,
	UnfoldMoreIcon,
	useTheme,
	useExpandCollapseActions,
	useExpandCollapseStore,
} from '@persuit/ui-components'

export const ExpandCollapseButtons = () => {
	const theme = useTheme()

	const { allExpanded, allCollapsed } = useExpandCollapseStore(({ expandedState }, selectors) => ({
		expandedState,
		allExpanded: selectors.isAllExpanded(),
		allCollapsed: selectors.isAllCollapsed(),
	}))
	const { expandAll, collapseAll } = useExpandCollapseActions()

	return (
		<Box width="100%" display="flex" flexDirection="row-reverse" gap={theme.spacing(1)}>
			<IconButton
				color="primary"
				title="Expand all"
				disabled={allExpanded}
				onClick={() => expandAll()}
				size="large"
			>
				<UnfoldMoreIcon />
			</IconButton>
			<IconButton
				color="primary"
				title="Collapse all"
				disabled={allCollapsed}
				onClick={() => collapseAll()}
				size="large"
			>
				<UnfoldLessIcon />
			</IconButton>
		</Box>
	)
}
