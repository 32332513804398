import { BaseCard } from '../base-card'
import { ProgressCardProps } from './types'
import { publishRfpProgress } from '../../singleton'

export type ProgressCardSendClarificationProps = ProgressCardProps

export const CARD_TITLE = 'Consider sending clarifications'

export const ProgressCardSendClarification = ({
	index,
	step,
}: ProgressCardSendClarificationProps) => {
	const onActionAddClarification = () => publishRfpProgress('DETAIL:LAUNCH_EDIT_REQUEST_DIALOG')

	if (step.status === 'active')
		return (
			<BaseCard
				title={CARD_TITLE}
				index={index}
				status={step.status}
				guidanceText={`Review proposals and any questions in messaging channels. Consider issuing a	clarification if needed.`}
				action={{
					title: 'ADD CLARIFICATION',
					onAction: onActionAddClarification,
					dataTrackId: 'rfp-progress-add-clarification',
				}}
			/>
		)

	return <BaseCard title={CARD_TITLE} index={index} status={step.status} />
}
