// @ts-strict-ignore
import { createContext, useContext } from 'react'

import * as React from 'react'
import { Box, BoxProps, useTheme } from '@persuit/ui-components'
import { NavItemProps } from './nav-item'

type SubItemBase = {
	title: string
	error: boolean
	/** anchor to add after the parent items path */
	anchor: string
	prefix?: React.ReactNode
	placeholder?: string
}

type SubItem = SubItemBase & {
	subItems?: SubItemBase[]
}

type ChildrenProps = {
	itemProps: (
		sectionName: string,
		options?: {
			subItems?: SubItem[]
		},
	) => Pick<NavItemProps, 'sectionName' | 'selected' | 'lockInfo' | 'error' | 'to' | 'subItems'>
}

type ContextType = {
	itemProps: ChildrenProps['itemProps']
	errors: NavigationProps['errors']
}

export const NavigationContext = createContext<ContextType | undefined>(undefined)
export const useNavigationContext = () => {
	const navigationContext = useContext(NavigationContext)
	if (!navigationContext)
		throw new Error('useNavigationContext must be within a NavigiationContext')
	return navigationContext
}

export type NavigationProps = {
	currentSection: string
	sectionalLocks: SectionalLocks
	userId: string
	instanceId: string
	generatePath: (sectionName: string) => string
	errors?: Record<string, unknown> | null
	children: ({ itemProps }: ChildrenProps) => React.ReactNode
	containerProps?: BoxProps
}

export const Navigation = ({
	currentSection,
	sectionalLocks,
	userId = '',
	instanceId = '',
	errors,
	generatePath,
	children,
	containerProps,
}: NavigationProps) => {
	const theme = useTheme()

	const itemProps: ChildrenProps['itemProps'] = (sectionName: string, { subItems } = {}) => {
		const to = generatePath(sectionName)

		const modifySubItems = (items: SubItem[] = []) =>
			items.map(({ anchor, subItems, ...rest }) => ({
				to: `${to}/${anchor}`,
				subItems: modifySubItems(subItems),
				...rest,
			}))

		return {
			sectionName,
			selected: currentSection === sectionName,
			lockInfo: getLockInfo({ sectionalLocks, sectionName, userId, instanceId }),
			error: !!errors?.[sectionName],
			to,
			subItems: modifySubItems(subItems),
		}
	}

	return (
		<NavigationContext.Provider value={{ itemProps, errors }}>
			<Box
				aria-label="Form"
				component={'nav'}
				data-testid="left-navigation"
				padding="8px"
				height="100%"
				display="flex"
				flexDirection="column"
				gap="4px"
				boxSizing="border-box"
				borderRight={`2px solid ${theme.palette.grey300}`}
				overflow="auto"
				displayPrint="none"
				maxWidth="450px"
				width="25vw"
				{...containerProps}
			>
				{children({
					itemProps,
				})}
			</Box>
		</NavigationContext.Provider>
	)
}

type SectionalLocks = Record<
	string,
	{
		userId: string
		displayName: string
		formInstanceId: string
	}
>

type GetLockTextInput = {
	sectionalLocks: SectionalLocks
	sectionName: string
	userId: string
	instanceId: string
}

export function getLockInfo({ sectionalLocks, sectionName, userId, instanceId }: GetLockTextInput) {
	if (!sectionalLocks[sectionName]) return null

	const sectionLock = sectionalLocks[sectionName]
	const { displayName } = sectionLock
	const letter = displayName[0].toUpperCase()

	if (sectionLock.formInstanceId === instanceId) {
		return { letter, tooltip: 'You are currently editing' }
	}

	if (sectionLock.userId === userId) {
		return { letter, tooltip: 'You are editing in a different tab' }
	}

	return { letter, tooltip: `${displayName} is currently editing` }
}
