import { Fragment } from 'react'
import moment, { Moment } from 'moment'
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ReferenceLine,
} from 'recharts'
import { PercentageChartTooltip } from './percentage-chart-tooltip'

export type PercentageChartProps = {
	/** Aspect ratio of Rechart ResponsiveContainer */
	aspect?: number
	chartData: ReadonlyArray<object>
	currency: string
	end: Moment
	/** Height of Rechart ResponsiveContainer */
	height?: number
	orgIdToColourMapping: Record<string, string>
	orgsToChart: { orgName: string; orgId: string }[]
	start: Moment
	zoomToAuction: boolean
}

/*
 * WARNING: This doesn't behave sensibly for multiple proposals from the same firm.
 *
 * The data points are keys by firm, not by proposal
 * This can lead to strange chart behaviour
 * Not worth fixing at this point.
 * It's unlikely a firm would have two active proposals during an auction
 *
 */
export const PercentageChart = ({
	aspect = 2,
	height,
	chartData,
	zoomToAuction,
	start,
	end,
	orgsToChart,
	orgIdToColourMapping,
}: PercentageChartProps) => {
	return (
		<Fragment>
			<ResponsiveContainer aspect={height ? undefined : aspect} height={height}>
				<LineChart
					data={chartData}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 15,
					}}
				>
					<XAxis
						dataKey="time"
						height={60}
						tickMargin={18}
						type="number"
						tickFormatter={(value) => moment(value).format('h:mmA (DD MMM)')}
						domain={[
							zoomToAuction ? () => moment(start).valueOf() : 'auto',
							zoomToAuction ? () => moment(end).valueOf() : 'auto',
						]}
						allowDataOverflow={true}
					/>
					<YAxis
						tickFormatter={(value) => `${value}%`}
						width={90}
						tickMargin={18}
						domain={[
							// Add some buffer so that there is space between the lowest
							// trendline and the x-axis
							(dataMin) => dataMin - 0.1 * dataMin,
							// Leave the max as default
							'auto',
						]}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip
						isAnimationActive={false}
						content={<PercentageChartTooltip />}
						wrapperStyle={{ maxWidth: '80%' }}
						allowEscapeViewBox={{ x: false, y: true }}
					/>
					<Legend />

					{/*
						Iterate through each org and output a line for each
						The actual data is provided to the <LineChart> component above
					*/}
					{orgsToChart.map(({ orgName, orgId }) => {
						return (
							<Line
								type="stepAfter"
								key={orgName}
								dataKey={orgName}
								stroke={orgIdToColourMapping[orgId]}
								strokeWidth={2}
								activeDot={{ r: 8 }}
								connectNulls={true}
							/>
						)
					})}
					<ReferenceLine
						alwaysShow={true}
						x={start.valueOf()}
						stroke="green"
						strokeWidth={2}
						label={
							{
								position: 'top',
								value: 'Start',
								fill: 'green',
								fontSize: 14,
							} as any
						}
						strokeDasharray="3 3"
					/>
					{end && (
						<ReferenceLine
							alwaysShow={true}
							x={end.valueOf()}
							stroke="red"
							strokeWidth={2}
							label={
								{
									position: 'top',
									value: 'End',
									fill: 'red',
									fontSize: 14,
								} as any
							}
							strokeDasharray="3 3"
						/>
					)}
				</LineChart>
			</ResponsiveContainer>
		</Fragment>
	)
}
