import { forwardRef, useEffect, useState } from 'react'

import { DataGridPro as Grid, DataGridProProps as Props, GridApi } from '@mui/x-data-grid-pro'

import { DataGrid as DataGridNormal } from '@mui/x-data-grid'

export type { DataGridProps } from '@mui/x-data-grid'
/** @deprecated Use DataGridPremium instead */
export const DataGrid = DataGridNormal

export type DataGridProProps = Props & {
	// The fixed widths of the sticky columns
	stickyColumnWidths?: number[]
}
/**
 * @deprecated Use DataGridPremium instead
 * This is a temporary hacky fix for stick columns until
 * https://github.com/mui/mui-x/issues/11949 is resolved
 */
export const DataGridPro = forwardRef<HTMLDivElement, DataGridProProps>(
	({ stickyColumnWidths = [], 'aria-label': ariaLabel, ...props }: DataGridProProps, ref) => {
		const [api, setApi] = useState<GridApi | null>(null)

		// Sticky logic for the header columns. MUI data-grid uses transforms to
		// position the header cells. To make the first two cells sticky we listen to
		// the mui scroll event and apply and opposite transform to prevent the cells
		// from moving.
		const rowHeaderCellsSelector =
			`[role="rowgroup"] [role="columnheader"]:nth-child(-n + 2)`.trim()
		const headerCells = Array.from(
			document.querySelectorAll(rowHeaderCellsSelector),
		) as unknown as HTMLElement[]

		// Subscribe
		useEffect(() => {
			if (!api || stickyColumnWidths.length === 0) return

			const unsub = api.subscribeEvent('scrollPositionChange', ({ left }) => {
				headerCells.map((cell: HTMLElement) => {
					cell.style.transform = `translateX(${left}px)`
					cell.style.zIndex = '100'
				})
			})

			return unsub
		}, [api, headerCells, ...stickyColumnWidths])

		const generateColumnStyle = (width: number, index: number) => ({
			[`& [role="rowgroup"] [role="gridcell"]:nth-child(${index + 1})`]: {
				position: 'sticky',
				left: width,
				zIndex: 100,
			},
		})

		const styles = Object.assign({}, ...stickyColumnWidths.map(generateColumnStyle))

		return (
			<Grid
				ref={(container) => {
					if (container && ariaLabel) {
						const grid = container?.querySelector(`[role="grid"]`)
						grid?.setAttribute('aria-label', ariaLabel)
					}

					if (typeof ref === 'function') {
						ref(container)
					} else if (ref && typeof ref === 'object') {
						ref.current = container
					}
				}}
				apiRef={setApi as any}
				{...props}
				// The current pinned column solution we are using requires the pinned
				// columns to be in the dom at all times. Virtualisation breaks this.
				// This line, along with our custom pinned column solution can be
				// removed once MUI fixes their accessibility issues here
				// https://github.com/mui/mui-x/issues/11949
				disableVirtualization={stickyColumnWidths.length > 0}
				sx={(theme) => ({
					...styles,
					...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
				})}
			/>
		)
	},
)
