import { Fragment, useState } from 'react'

import moment from 'moment'

import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import uniqBy from 'lodash/fp/uniqBy'
import size from 'lodash/fp/size'
import {
	Box,
	Paper,
	Typography,
	Button,
	ZoomOutIcon,
	SettingsBackupRestoreIcon,
} from '@persuit/ui-components'

import DeliverableItemChart from './deliverable-item-chart'
import { TotalPriceChart } from './total-price-chart'
import SelectChartData from './select-chart-data'

/*
 * WARNING: This doesn't behave sensibly for multiple proposals from the same firm.
 *
 * The data points are keys by firm, not by proposal
 * This can lead to strange chart behaviour
 * Not worth fixing at this point.
 * It's unlikely a firm would have two active proposals during an auction
 *
 */

const totalPriceOption = {
	title: 'Total price',
	isTotalPrice: true,
	group: '', // Intentionally empty group
}

const comparisonValueOption = {
	title: 'Comparison value',
	isComparisonValue: true,
	group: '', // Intentionally empty group
}

const generateChartSelectOptions = ({ deliverables, totalPriceRequired }) => {
	const { flatDeliverables } = deliverables.reduce(
		(acc, deliverable) => {
			if (deliverable.__typename === 'PricingGroup') {
				deliverable.deliverables.forEach((groupedDeliverable) => {
					acc.flatDeliverables.push({
						id: groupedDeliverable._id,
						title: `${acc.index + 1}. ${groupedDeliverable.deliverableTitle}  (${
							deliverable.title
						})`,
						isDeliverableItem: true,
						group: 'Pricing Items',
						pricingModel: groupedDeliverable.pricingPreferences,
					})
					acc.index += 1
				})
				return acc
			}
			acc.flatDeliverables.push({
				id: deliverable._id,
				title: `${acc.index + 1}. ${deliverable.deliverableTitle}`,
				isDeliverableItem: true,
				group: 'Pricing Items',
				pricingModel: deliverable.pricingPreferences,
			})
			acc.index += 1
			return acc
		},
		{
			flatDeliverables: [],
			index: 0,
		},
	)

	return [totalPriceRequired ? totalPriceOption : comparisonValueOption, ...flatDeliverables]
}

export const AuctionActivityChartOld = ({
	responses,
	auctionEnd,
	proposalsDueBy,
	orgIdToColourMapping,
	currency,
	deliverables,
	totalPriceRequired,
}) => {
	const [zoomToAuction, setZoomToAuction] = useState(true)
	const [thingToChart, setThingToChart] = useState(
		totalPriceRequired ? totalPriceOption : comparisonValueOption,
	)

	const toggleZoomToAuction = () => {
		setZoomToAuction(!zoomToAuction)
	}

	const start = moment(proposalsDueBy)
	const end = auctionEnd ? moment(auctionEnd) : null

	// Get each unique org and map out it's ID and name
	const orgsToChart = compose(
		// Ensure this is a unique list. This accounts for when a firm submits multiple proposals
		uniqBy('orgId'),
		map((response) => {
			return {
				orgId: response.org._id,
				orgName: response.org.name,
			}
		}),
	)(responses)

	const chartSelectOptions = generateChartSelectOptions({
		deliverables,
		totalPriceRequired,
	})

	// Only show the chart selector if there are some deliverables
	const showChartSelector = size(deliverables) > 0

	return (
		<Fragment>
			<Paper elevation={1}>
				<Box m={2} pt={1}>
					<Box display="flex" mb={2} justifyContent="space-between" alignItems="center">
						<Typography gutterBottom={true} variant="h3XSmall">
							Auction activity
						</Typography>

						<Box display="flex" justifyContent="space-between">
							{showChartSelector && (
								<Box mr={4} style={{ width: '280px' }}>
									<SelectChartData
										value={thingToChart}
										onChange={setThingToChart}
										options={chartSelectOptions}
									/>
								</Box>
							)}

							<Button
								startIcon={zoomToAuction ? <ZoomOutIcon /> : <SettingsBackupRestoreIcon />}
								onClick={toggleZoomToAuction}
								variant="outlined"
							>
								{zoomToAuction ? 'Zoom out' : 'Reset'}
							</Button>
						</Box>
					</Box>

					{(thingToChart.isTotalPrice || thingToChart.isComparisonValue) && (
						<Fragment>
							<Typography gutterBottom={true}>
								{totalPriceRequired ? 'Total price chart' : 'Comparison value chart'}
							</Typography>

							<TotalPriceChart
								zoomToAuction={zoomToAuction}
								start={start}
								end={end}
								orgsToChart={orgsToChart}
								currency={currency}
								orgIdToColourMapping={orgIdToColourMapping}
								responses={responses}
								proposalsDueBy={proposalsDueBy}
								auctionEnd={auctionEnd}
								totalPriceRequired={totalPriceRequired}
							/>
						</Fragment>
					)}

					{thingToChart.isDeliverableItem && (
						<Fragment>
							<Typography gutterBottom={true}>{thingToChart.title}</Typography>

							<DeliverableItemChart
								zoomToAuction={zoomToAuction}
								start={start}
								end={end}
								orgsToChart={orgsToChart}
								currency={currency}
								orgIdToColourMapping={orgIdToColourMapping}
								responses={responses}
								proposalsDueBy={proposalsDueBy}
								auctionEnd={auctionEnd}
								deliverableId={thingToChart.id}
								deliverablePricingModel={thingToChart.pricingModel}
							/>
						</Fragment>
					)}
				</Box>
			</Paper>
		</Fragment>
	)
}
