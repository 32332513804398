import { lazy, Suspense } from 'react'
import * as React from 'react'
import { RetryConfig, retryDecorator } from 'ts-retry-promise'

export type LazyComponentOptions = {
	fallback?: React.ReactNode
	retryConfig?: Partial<RetryConfig>
}

export type LazyComponentLoader<T> = () => Promise<
	React.ComponentType<T> | { default: React.ComponentType<T> }
>

/**
 * Creates a lazy component wrapped in its own suspense wrapper so that it
 * can be used as a drop in replacement but is loaded on demand.
 */
export function lazyComponent<T>(
	importFn: LazyComponentLoader<T>,
	{
		fallback = null,
		retryConfig = {
			retries: 5,
			backoff: 'EXPONENTIAL',
			maxBackOff: 15000,
		},
	}: LazyComponentOptions = {},
): React.ComponentType<T> {
	const wrappedImportFn = retryDecorator(importFn, retryConfig)

	const LazyComponent = lazy(async () => {
		const importResult = await wrappedImportFn()
		// Allow for consumer to provide named export components easily. React.lazy only supports default exports.
		const component: React.ComponentType<T> = (importResult as any).default ?? importResult
		return { default: component }
	})

	// Wrap the lazy component in suspense so that it doesn't cause the entire app to unmount
	const WrappedLazyComponent = (props: T) => {
		return (
			<Suspense fallback={fallback}>
				<LazyComponent {...(props as any)} />
			</Suspense>
		)
	}

	return WrappedLazyComponent
}
