import isEmpty from 'lodash/fp/isEmpty'

const filterFiles = (files) => {
	if (!Array.isArray(files)) {
		return []
	}

	return files.filter((file) => {
		if (isEmpty(file)) {
			return false
		}

		if (!file._id || !file.filename) {
			return false
		}

		return true
	})
}

const getComputedState = ({ originalState, updatedState }) => {
	if (originalState !== 'queued') {
		return originalState
	}

	return updatedState
}

export const purifyFileList = (files) => {
	if (isEmpty(files)) {
		return []
	}

	return filterFiles(files)
}

export const addFiles =
	({ files, setFiles, sync }) =>
	(filesToAdd = []) => {
		if (!Array.isArray(filesToAdd)) {
			return
		}
		const updatedFiles = [...files, ...filesToAdd]
		setFiles(updatedFiles)
		sync(updatedFiles)
	}

export const removeFile =
	({ files, setFiles, sync }) =>
	(fileIdToRemove) => {
		const filteredFiles = files.filter(({ _id }) => _id !== fileIdToRemove)
		setFiles(filteredFiles)
		sync(filteredFiles)
	}

export const updateFiles =
	({ files: originalFiles, setFiles }) =>
	(updatedFiles) => {
		const updatedFilesWithResolvedStatus = purifyFileList(updatedFiles).map((updatedFile) => {
			const originalFile = originalFiles.find(
				({ _id: originalFileId }) => updatedFile._id === originalFileId,
			)
			if (!originalFile) {
				return updatedFile
			}

			const originalState = originalFile.fileStatus
			const updatedState = updatedFile.fileStatus

			const computedState = getComputedState({ originalState, updatedState })

			return {
				...updatedFile,
				fileStatus: computedState,
			}
		})

		setFiles(updatedFilesWithResolvedStatus)
	}

export const noop = () => {}
