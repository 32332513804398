import { Chip } from '@persuit/ui-components'
import useTemplateCountries from '../../rfp-template-form/detail-section/use-template-countries'

export type CountryLabelProps = {
	country?: string | null
}

export const CountryLabel = ({ country }: CountryLabelProps) => {
	const { templateCountryLookup, loading } = useTemplateCountries()

	if (loading) {
		return null
	}

	const getLabel = (countryId?: string | null) => {
		if (!countryId || !templateCountryLookup[countryId]) {
			return null
		}
		return templateCountryLookup[countryId]?.name
	}

	const label = getLabel(country)

	if (!label) {
		return null
	}
	return <Chip label={label} color="default" />
}
