// @ts-strict-ignore
import isEmpty from 'lodash/fp/isEmpty'
import {
	ErrorCardData,
	ErrorCardDataSubSection,
	ErrorCardTransformSectionHandler,
} from 'packages/ui-shared-components/src/sectional-form'
import { RfpQuestionError } from '../../../../types'

const getQuestionsErrors = (
	sectionErrors: Array<RfpQuestionError>,
	link: string,
): ErrorCardData[] => {
	return sectionErrors
		.map((questionError) => getQuestionErrors(questionError, link))
		.filter((item): item is ErrorCardData => Boolean(item))
}

const getSubSectionErrors = (questionError: RfpQuestionError): Array<ErrorCardDataSubSection> => {
	if (!questionError || !questionError.options) {
		return []
	}

	return (questionError.options || []).map((optionError) => {
		if (!optionError) {
			return {
				title: '',
				errorMessages: [],
			}
		}

		return {
			title: `Option ${(optionError.index ?? 0) + 1}`,
			errorMessages: [optionError?.value ?? ''],
		}
	})
}

const getQuestionErrors = (questionError: RfpQuestionError, link: string): ErrorCardData | null => {
	if (isEmpty(questionError)) return null
	const titleErrorMessage = questionError?.title ?? ''
	const subSectionErrors = getSubSectionErrors(questionError)

	if (!titleErrorMessage && subSectionErrors.length === 0) {
		return null
	}

	if (questionError.questionIndex === -1) {
		return {
			title: `Question ${questionError.groupIndex + 1}`,
			errorMessages: [titleErrorMessage],
			link: `${link}/question_item_${questionError.groupIndex + 1}`,
			subSections: subSectionErrors,
		}
	}
	return {
		title: `Question ${questionError.groupIndex + 1}.${questionError.questionIndex + 1}`,
		link: `${link}/question_item_${questionError.groupIndex + 1}_${
			questionError.questionIndex + 1
		}`,
		errorMessages: [titleErrorMessage],
		subSections: subSectionErrors,
	}
}

export const makeQuestionsHandlerMaker =
	({ getCardLink }) =>
	({ sectionName, path = '', templateId = '' }): ErrorCardTransformSectionHandler =>
	(sectionErrors: Array<RfpQuestionError> | null): Array<ErrorCardData> => {
		if (sectionErrors === null || isEmpty(sectionErrors)) return []
		const link = getCardLink({ path, templateId, sectionName })

		const questionsErrors = getQuestionsErrors(sectionErrors, link)

		return [...questionsErrors]
	}
