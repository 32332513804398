import { useTheme } from '../../../theme'
import { Typography } from '../../typography'

type CharacterCountProps = {
	contentLength: number
	maxChars?: number
	validateChars?: boolean
}

export const CharacterCount = ({
	contentLength,
	maxChars = 0,
	validateChars,
}: CharacterCountProps) => {
	const theme = useTheme()

	return (
		<Typography
			variant="caption"
			style={{
				color: validateChars ? theme.palette.error.main : 'inherit',
			}}
			role={validateChars ? 'alert' : ''}
		>
			{maxChars > 0 ? `${contentLength} of ${maxChars} characters` : `Unlimited characters`}
		</Typography>
	)
}
