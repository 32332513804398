import { useId } from 'react'
import { add, format } from 'date-fns'
import {
	IconButton,
	styled,
	Typography,
	Box,
	AccessTimeIcon,
	ToggleButton,
	ToggleButtonGroup,
	TimezoneAbbreviation,
	useFormContext,
	SROnly,
} from '@persuit/ui-components'
import { useFeatureToggles } from '@persuit/ui-feature-toggle'
import type { AuctionFormSectionData } from './auction-form'

export const AuctionDurationPicker = () => {
	const { toggles } = useFeatureToggles({ auth: false })

	const { watch, setValue } = useFormContext<AuctionFormSectionData>()

	const startDate = watch('proposalsDueBy')
	const timezoneId = watch('timezoneId')
	const auctionDuration = watch('auctionDuration') ?? 30

	const auctionEndDate =
		!startDate || !auctionDuration || !timezoneId
			? ''
			: format(add(new Date(startDate), { minutes: auctionDuration }), 'yyyy/MM/dd, hh:mmaa')

	const labelId = useId()

	return (
		<>
			<Box style={{ paddingLeft: 50 }}>
				<Typography variant="caption" color="text.secondary" id={labelId}>
					Auction duration * <SROnly>Required</SROnly>
				</Typography>
			</Box>
			<Box display="flex" alignItems="center">
				<IconButton size="large" disabled={true} aria-hidden={true}>
					<AccessTimeIcon color="primary" />
				</IconButton>

				<ToggleButtonGroup
					value={auctionDuration}
					onChange={(_, newSelection) => {
						// Cannot deselect a duration
						if (newSelection !== null) {
							setValue('auctionDuration', newSelection)
						}
					}}
					exclusive={true}
					aria-labelledby={labelId}
					data-testid="auction-duration-picker"
				>
					{toggles['dev-4498.5-minute-auction-duration'] && (
						<StyledToggleButton value={5} aria-label="5 minutes">
							5 MIN
						</StyledToggleButton>
					)}
					<StyledToggleButton value={30} aria-label="30 minutes">
						30 MIN
					</StyledToggleButton>
					<StyledToggleButton value={60} aria-label="1 hour">
						1 HR
					</StyledToggleButton>
					<StyledToggleButton value={120} aria-label="2 hours">
						2 HRS
					</StyledToggleButton>
					<StyledToggleButton value={1440} aria-label="24 hours">
						24 HRS
					</StyledToggleButton>
					<StyledToggleButton value={2880} aria-label="48 hours">
						48 HRS
					</StyledToggleButton>
				</ToggleButtonGroup>
			</Box>

			{auctionEndDate && (
				<Box style={{ paddingLeft: 43 }} data-testid="timeline-auctionEndDate">
					<Typography variant="caption" color="text.secondary">
						{`Auction end date: ${auctionEndDate} ${' '}`}
					</Typography>
					<Typography variant="caption" color="text.secondary">
						<TimezoneAbbreviation timezoneId={timezoneId ?? null} />
					</Typography>
				</Box>
			)}
		</>
	)
}

const StyledToggleButton = styled(ToggleButton)`
	&.Mui-selected,
	&.Mui-selected:hover {
		color: ${(props) => props.theme.palette.white};
		background-color: ${(props) => props.theme.palette.primary.main};
	}
	color: ${(props) => props.theme.palette.grey700};
`
