// eslint-disable-next-line no-restricted-imports -- Please upgrade
import ReplayIcon from '@mui/icons-material/Replay'
import DialogWizardListOption from '../../dialog-wizard-list-option'

const bullets = [
	'Proposal status will no longer be unsuccessful.',
	'You will be able to request a revision or select this proposal.',
	'The firm will be notified.',
	'Remaining firm(s) will not be notified.',
]

function OptionUneliminateFirmStart({ onNext, disableUndoMarkFirmUnsuccessfulRadio }) {
	return (
		<DialogWizardListOption
			dataTestid="undo-mark-firm-unsuccessful-option"
			icon={<ReplayIcon />}
			primaryText={`Undo "Unsuccessful" Status`}
			bullets={bullets}
			onClick={onNext}
			disabled={disableUndoMarkFirmUnsuccessfulRadio}
		/>
	)
}

export default OptionUneliminateFirmStart
