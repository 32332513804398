import find from 'lodash/fp/find'
import { getOrEmptyArray, getOrFalse } from '../../utils/lenses'

const getReopenedStatusForInvite = (request, firmOrgId) => {
	const invites = getOrEmptyArray('invites', request)
	let invite = find({ item: firmOrgId }, invites)

	if (!invite) {
		// Sometimes we have to find the org id in invites.item._id
		invite = find({ item: { _id: firmOrgId } }, invites)
	}

	return getOrFalse('isReOpened', invite)
}

export default getReopenedStatusForInvite
